import { connect } from "react-redux";
import { StoreState } from "../../_types";
import { getUserCurrencyCode } from "../../ducks/user";
import { computeSavingsAsDollars, getCurrentlySelectedExchangeRate, getExchangeRateData } from "../../ducks/pricing";
import { SavingsInDollarsBadgeFromPlan } from "../SavingsInDollarsBadgeFromPlan";
import { STRIPE_PLAN_TYPE_ANNUAL } from "../../_types/api";

export interface SavingsInDollarsBadgeFromPlanContainerProps
{
    plan: StripePlanData;
    monthlyPlan: StripePlanData;
    annualPlan: StripePlanData;
    shouldRenderForWideFormat?: boolean;
    shouldAlignToRight?: boolean;
}

const mapStateToProps = ( storeState: StoreState, ownProps: SavingsInDollarsBadgeFromPlanContainerProps ): SavingsInDollarsBadgeFromPlan =>
{
    const isAnnualPlan = ownProps.plan.type === STRIPE_PLAN_TYPE_ANNUAL;
    return {
        annualPlanSavings: isAnnualPlan && getAnnualPlanSavings( ownProps.annualPlan, ownProps.monthlyPlan ),
        currencyCode: getUserCurrencyCode( storeState ),
        currentExchangeRate: getCurrentlySelectedExchangeRate( storeState ),
        shouldShowExchangeRates: !!getExchangeRateData( storeState ),
        shouldRenderForWideFormat: ownProps.shouldRenderForWideFormat,
        shouldAlignToRight: ownProps.shouldAlignToRight,
    };
};

function getAnnualPlanSavings( annualPlan: StripePlanData, monthlyPlan: StripePlanData ): number
{
    if ( !annualPlan || !monthlyPlan )
    {
        return null;
    }
    return computeSavingsAsDollars( annualPlan, monthlyPlan );
}

export default connect(
    mapStateToProps,
)( SavingsInDollarsBadgeFromPlan );
