import { createAction } from "redux-actions";
import { ActionCreator } from "../helpers";
import { AddMediaAPIResponse } from "../_types/api";

const actionCreator = new ActionCreator( "MUSIC_CATALOG" );

const musicCatalogActionsTypes = {
    catalogMusic: {
        load: actionCreator.generateTypes( "LOAD" ),
    },
    customMusic: {
        load: actionCreator.generateType( "CUSTOM_MUSIC", "LOAD" ),
    },
};

export const musicCatalogActions = {
    loadCatalogSuccess: createAction<NormalizrData>( musicCatalogActionsTypes.catalogMusic.load.success ),
    loadCatalogFailure: createAction<string>( musicCatalogActionsTypes.catalogMusic.load.failure ),
    loadCustomMusic: createAction<AddMediaAPIResponse>( musicCatalogActionsTypes.customMusic.load ),
};
