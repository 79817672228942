import * as React from "react";
import { FileUploadButton } from "../../FileUploadButton";
import { ERROR_TITLE_OOPS, getFileExtensionsForCustomMusicAcceptProp, MAX_MUSIC_FILE_UPLOAD_SIZE_IN_BYTE, stringUtils } from "../../../helpers";
import CircularProgress from "react-md/lib/Progress/CircularProgress";
import { store } from "../../../store";
import { modalServices } from "../../../services";

export interface UploadCustomMusicButtonProps
{
    isCustomMusicUploading: boolean;
}

export interface UploadCustomMusicButtonDispatchProps
{
    onUploadCustomMusic: ( file: File ) => void;
    stopPlayingMusic();
}

export class UploadCustomMusicButton extends React.PureComponent<UploadCustomMusicButtonProps & UploadCustomMusicButtonDispatchProps>
{
    public render()
    {

        return (
            <div className="moreButtonContainer">
                <FileUploadButton
                    id="music-upload"
                    primary
                    swapTheming
                    flat
                    className="fullWidth large uploadBtn"
                    label={this.getButtonLabel()}
                    accept={getFileExtensionsForCustomMusicAcceptProp()}
                    icon={null}
                    disabled={this.props.isCustomMusicUploading}
                    maxSize={MAX_MUSIC_FILE_UPLOAD_SIZE_IN_BYTE}
                    onClick={this.handleUploadMusicClicked}
                    onSizeError={this.handleSizeError}
                    onFileUploaded={this.handleOnFileUploaded}/>
            </div>
        );
    }

    private getButtonLabel = () =>
    {
        if ( this.props.isCustomMusicUploading )
        {
            return (<CircularProgress className="spinner white verticallyCentered" id="spinner"/>);
        }
        else
        {
            return "Select from my device";
        }
    }

    private handleUploadMusicClicked = () =>
    {
        this.props.stopPlayingMusic();
    }

    private handleOnFileUploaded = ( file: File ) =>
    {
        this.props.onUploadCustomMusic( file );
    }

    private handleSizeError = ( file: any[] ) =>
    {
        const fileSizeLimit = stringUtils.convertByteToMB( MAX_MUSIC_FILE_UPLOAD_SIZE_IN_BYTE );
        store.dispatch( modalServices.openErrorDialog( ERROR_TITLE_OOPS,
            `Your uploaded file size exceeds our limit of ${fileSizeLimit}MB. Please choose a smaller music file.` ) );
    }
}
