import { connect } from "react-redux";
import { Dispatch } from "redux";
import { getCustomizableCanvas, getDesignElementProperties, getTextPropertiesSubPanelCaptionData } from "../../../ducks/ui";
import { AnimationTextOption, StoreState } from "../../../_types";
import { store } from "../../../store";
import { getCurrentBusiness } from "../../../ducks/userBusiness";
import {
    AnimationPickerTextPropertiesSubPanel,
    AnimationPickerTextPropertiesSubPanelDispatchProps,
    AnimationPickerTextPropertiesSubPanelProps,
} from "../AnimationPickerTextPropertiesSubPanel";
import { ElementPropertyChangedData } from "../../../_types/canvasUpdaterTypes";
import { DesignElementPropertyType } from "../../../_types/api";

const mapStateToProps = ( storeState: StoreState ): AnimationPickerTextPropertiesSubPanelProps =>
{
    return {
        captionData: getTextPropertiesSubPanelCaptionData( storeState ),
        designElementProperties: getDesignElementProperties( storeState ),
        currentBusiness: getCurrentBusiness( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): AnimationPickerTextPropertiesSubPanelDispatchProps =>
{
    return {
        tellDesignToApplyAnimation,
    };
};

function tellDesignToApplyAnimation( captionId: string, type: DesignElementPropertyType, animationPropertyId: string, option: AnimationTextOption )
{
    const state = store.getState();
    const canvas = getCustomizableCanvas( state );
    if ( canvas )
    {
        const payload: ElementPropertyChangedData = {
            id: captionId,
            type,
            controlId: animationPropertyId,
            value: option,
        };
        canvas.tellDesignToChangeElementProperty( payload );
        // eventTracker.logAnimationSelected( option );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( AnimationPickerTextPropertiesSubPanel );
