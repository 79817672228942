import * as facebookImg from "../assets/img/engagement-icons/facebook.png";
import * as instagramImg from "../assets/img/engagement-icons/instagram.png";
import * as twitterImg from "../assets/img/engagement-icons/twitter.png";
import * as youtubeImg from "../assets/img/engagement-icons/youtube.png";
import * as retweetImg from "../assets/img/engagement-icons/retweet@2x.png";
import * as faceBookLikeImg from "../assets/img/engagement-icons/like@2x.png";
import * as commentImg from "../assets/img/engagement-icons/comment@2x.png";
import * as clickImg from "../assets/img/engagement-icons/click@2x.png";
import * as favoriteImg from "../assets/img/engagement-icons/favorite@2x.png";
import * as youtubeLikeImg from "../assets/img/engagement-icons/youtube_like.png";
import * as youtubeViewImg from "../assets/img/engagement-icons/youtube_view.png";

export const ENGAGEMENT_ICONS = {
    facebook: facebookImg,
    instagram: instagramImg,
    twitter: twitterImg,
    youtube: youtubeImg,
    facebook_likes: faceBookLikeImg,
    facebook_comments: commentImg,
    facebook_clicks: clickImg,
    instagram_likes: favoriteImg,
    instagram_comments: commentImg,
    instagram_clicks: clickImg,
    twitter_favorites: favoriteImg,
    twitter_retweets: retweetImg,
    twitter_clicks: clickImg,
    youtube_likes: youtubeLikeImg,
    youtube_comments: commentImg,
    youtube_views: youtubeViewImg,
};
