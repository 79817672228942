import * as React from "react";
import Button from "react-md/lib/Buttons/Button";
import { UserState } from "../_types/index";

const DELETE_ACCOUNT_BUTTON_LABEL = "Delete my Ripl Account";
const SIGN_OUT_BUTTON_LABEL = "Sign out";

export interface DeleteAccountPageProps
{
    user: UserState;
}

export interface DeleteAccountPageDispatchProps
{
    handleDeleteAccount(): void;
    handleSignOut(): void;
}

export default class DeleteAccountPage extends React.PureComponent<DeleteAccountPageProps & DeleteAccountPageDispatchProps>
{
    public render()
    {
        return (
            <div className="deleteAccountPage">
                <h3>Account information</h3>
                {this.getNameSection()}
                {this.getEmailSection()}
                <br/>
                <Button flat
                        className={"deleteAccountButton"}
                        onClick={this.props.handleDeleteAccount}>
                    {DELETE_ACCOUNT_BUTTON_LABEL}
                </Button>
                <br/>
                <Button flat primary swapTheming
                        className={"deleteAccountButton"}
                        onClick={this.props.handleSignOut}>
                    {SIGN_OUT_BUTTON_LABEL}
                </Button>
            </div>
        );
    }

    private getNameSection()
    {
        if ( this.props.user.first_name && this.props.user.last_name )
        {
            return <>
                <div className="accountInfoHeader">Name</div>
                <div className="accountInfoField">
                    {this.props.user.first_name} {this.props.user.last_name}
                </div>
            </>;
        }
        return <></>;
    }

    private getEmailSection()
    {
        if ( this.props.user.valid_email )
        {
            return <>
                <div className="accountInfoHeader">Email</div>
                <div className="accountInfoField">
                    {this.props.user.valid_email}
                </div>
            </>;
        }
        return <></>;
    }
}
