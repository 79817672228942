import * as React from "react";
import { ENV_CONFIG, eventTracker } from "../../helpers";
import { TEXT_STYLE_STATUS_DEVELOPMENT, TextStyleStatus } from "../../_types/api";
import classNames from "classnames";

export interface StylizedTextChoiceProps
{
    styleId: string;
    relativeImageUrl: string;
    status?: TextStyleStatus;
    selected: boolean;
    outerRef: React.RefObject<HTMLDivElement>;
}

export interface StylizedTextChoiceDispatchProps
{
    onClick: ( styleIdentifier: string ) => void;
}

export class StylizedTextChoiceButton extends React.PureComponent<StylizedTextChoiceProps & StylizedTextChoiceDispatchProps>
{
    public render()
    {
        let content = <>{this.props.styleId}</>;
        if ( this.props.relativeImageUrl )
        {
            const imageUrl = ENV_CONFIG.cdnBaseUrl + this.props.relativeImageUrl;
            content = <img src={imageUrl} alt={this.props.styleId}/>;
        }

        const classes = classNames( "stylizedTextChoice", "clickable",
            {
                selected: this.props.selected,
                devMode: this.props.status === TEXT_STYLE_STATUS_DEVELOPMENT,
            } );

        return (<div ref={this.props.outerRef} className={classes} onClick={this.handleOnClick}>
            {content}
        </div>);
    }

    private handleOnClick = ( mouseEvent: React.MouseEvent ) =>
    {
        mouseEvent.stopPropagation();
        eventTracker.logStylizeTextChangedEvent( this.props.styleId );
        this.props.onClick( this.props.styleId );
    }

}
