import * as React from "react";
import Cell from "react-md/lib/Grids/Cell";
import Grid from "react-md/lib/Grids/Grid";
import Button from "react-md/lib/Buttons/Button";
import Toolbar from "react-md/lib/Toolbars/Toolbar";
import { eventTracker } from "../helpers";
import { DialogControlProps } from "../_types";
import DesignSwitchingCatalogSidebarContainer from "./containers/DesignSwitchingCatalogSidebar.container";
import DesignSwitchingCatalogPanelContainer from "./containers/DesignSwitchingCatalogPanel.container";

export class DesignSwitchingCatalogDialog extends React.PureComponent<DialogControlProps>
{
    public componentDidMount()
    {
        eventTracker.logCatalogOpened();
    }

    public render()
    {
        return (
            <div className="catalogDialogContainer">
                <Toolbar
                    colored
                    autoFocus
                    titleId="simple-full-page-dialog-title"
                    title="Choose a layout"
                    actions={<Button icon={true}
                                     className="backArrow"
                                     iconClassName="material-icons"
                                     iconChildren="arrow_back_ios"
                                     onClick={this.hide}/>}/>
                <Grid className="designCatalogContainer" noSpacing>
                    <Cell size={2} tabletSize={2} phoneSize={4}>
                        <DesignSwitchingCatalogSidebarContainer/>
                    </Cell>
                    <Cell className="catalogContainer" size={10} tabletSize={6}>
                        <DesignSwitchingCatalogPanelContainer closeDialog={this.props.closeDialog}/>
                    </Cell>
                </Grid>
            </div>
        );
    }

    private hide = () =>
    {
        eventTracker.logCatalogClosed();
        this.props.closeDialog();
    }
}
