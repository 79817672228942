import { createAction } from "redux-actions";
import { ActionCreator } from "../helpers";
import { PriorityData } from "../_types";

const actionCreator = new ActionCreator( "POST" );

const postActionsTypes = {
    create: actionCreator.generateTypes( "CREATE" ),
    update: actionCreator.generateTypes( "UPDATE" ),
    delete: actionCreator.generateTypes( "DELETE" ),
    finish: actionCreator.generateTypes( "FINISH" ),
    reschedule: actionCreator.generateTypes( "RESCHEDULE" ),
    unschedule: actionCreator.generateTypes( "UNSCHEDULE" ),
    load: actionCreator.generateTypes( "LOAD" ),
    loadDrafts: actionCreator.generateTypes( "LOADDRAFTS" ),
    loadMostEngagedPosts: actionCreator.generateTypes( "LOAD_MOST_ENGAGED_POSTS" ),
    loadUnscheduledPosts: actionCreator.generateTypes( "LOAD_UNSCHEDULED_POSTS" ),
    priorityData: actionCreator.generateTypes( "PRIORITY_DATA" ),
    mostEngagementsSortedData: actionCreator.generateTypes( "MOST_ENGAGEMENT_SORTED_DATA" ),
    mostRecentPostsData: actionCreator.generateTypes( "MOST_RECENT_POSTS_DATA" ),
    loadMoreMostRecentPostsData: actionCreator.generateTypes( "LOAD_MORE_MOST_RECENT_POSTS_DATA" ),
    postDataForDate: actionCreator.generateTypes( "POST_DATA_FOR_DATE" ),
    cancelNextDraftsLoad: actionCreator.generateType( "CANCEL_NEXT_DRAFTS_LOAD", "REQUESTED" ),
};

export interface PostActivityPayload
{
    activityKey: string;
    data: NormalizrData;
}

export const postActions = {
    createRequest: createAction( postActionsTypes.create.request ),
    createSuccess: createAction<NormalizrData>( postActionsTypes.create.success ),
    createFailure: createAction<string>( postActionsTypes.create.failure ),
    updateRequest: createAction( postActionsTypes.update.request ),
    updateSuccess: createAction<NormalizrData>( postActionsTypes.update.success ),
    updateFailure: createAction<string>( postActionsTypes.update.failure ),
    deleteRequest: createAction( postActionsTypes.delete.request ),
    deleteSuccess: createAction<number>( postActionsTypes.delete.success ),
    deleteFailure: createAction<string>( postActionsTypes.delete.failure ),
    finishRequest: createAction( postActionsTypes.finish.request ),
    finishSuccess: createAction<NormalizrData>( postActionsTypes.finish.success ),
    finishFailure: createAction<string>( postActionsTypes.finish.failure ),
    rescheduleRequest: createAction( postActionsTypes.reschedule.request ),
    rescheduleSuccess: createAction<NormalizrData>( postActionsTypes.reschedule.success ),
    rescheduleFailure: createAction<string>( postActionsTypes.reschedule.failure ),
    unscheduleRequest: createAction( postActionsTypes.unschedule.request ),
    unscheduleSuccess: createAction<NormalizrData>( postActionsTypes.unschedule.success ),
    unscheduleFailure: createAction<string>( postActionsTypes.unschedule.failure ),
    loadRequest: createAction( postActionsTypes.load.request ),
    loadSuccess: createAction<NormalizrData>( postActionsTypes.load.success ),
    loadFailure: createAction<string>( postActionsTypes.load.failure ),
    loadDraftRequest: createAction( postActionsTypes.loadDrafts.request ),
    loadDraftSuccess: createAction<NormalizrData>( postActionsTypes.loadDrafts.success ),
    loadDraftFailure: createAction<string>( postActionsTypes.loadDrafts.failure ),
    loadMostEngagedPostSuccess: createAction<NormalizrData>( postActionsTypes.loadMostEngagedPosts.success ),
    loadUnscheduledPostsSuccess: createAction<PostActivityPayload>( postActionsTypes.loadUnscheduledPosts.success ),
    loadUnscheduledPostsFailure: createAction<PostActivityPayload>( postActionsTypes.loadUnscheduledPosts.failure ),
    priorityDataRequest: createAction( postActionsTypes.priorityData.request ),
    priorityDataSuccess: createAction<{ month: Date, data: PriorityData }>( postActionsTypes.priorityData.success ),
    priorityDataFailure: createAction<string>( postActionsTypes.priorityData.failure ),
    mostEngagementsSortedDataSuccess: createAction<NormalizrData>( postActionsTypes.mostEngagementsSortedData.success ),
    mostEngagementsSortedDataFailure: createAction<string>( postActionsTypes.mostEngagementsSortedData.failure ),
    mostRecentPostsDataSuccess: createAction<PostActivityPayload>( postActionsTypes.mostRecentPostsData.success ),
    mostRecentPostsDataFailure: createAction<string>( postActionsTypes.mostRecentPostsData.failure ),
    loadMoreMostRecentPostsDataRequest: createAction( postActionsTypes.loadMoreMostRecentPostsData.request ),
    loadMoreMostRecentPostsDataSuccess: createAction<PostActivityPayload>( postActionsTypes.loadMoreMostRecentPostsData.success ),
    loadMoreMostRecentPostsDataFailure: createAction<string>( postActionsTypes.loadMoreMostRecentPostsData.failure ),
    postDataForDateRequest: createAction( postActionsTypes.postDataForDate.request ),
    postDataForDateSuccess: createAction<PostActivityPayload>( postActionsTypes.postDataForDate.success ),
    postDataForDateFailure: createAction<string>( postActionsTypes.postDataForDate.failure ),
    cancelNextDraftsLoad: createAction<boolean>( postActionsTypes.cancelNextDraftsLoad ),
};
