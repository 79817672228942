import { connect } from "react-redux";
import { getEditedBusinessInfo } from "../../../ducks/ui";
import { StoreState } from "../../../_types";
import { BrandSettingsFontPicker, BrandSettingsFontPickerProps } from "../BrandSettingsFontPicker";

const mapStateToProps = ( storeState: StoreState ): BrandSettingsFontPickerProps =>
{
    return {
        businessData: getEditedBusinessInfo( storeState ),
        showHeader: true,
    };
};

export default connect(
    mapStateToProps,
)( BrandSettingsFontPicker );
