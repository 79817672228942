import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../_types";
import { PostIntentsBanner, PostIntentsBannerDispatchProps, PostIntentsBannerProps } from "../../PostIntentsBanner";
import { modalServices } from "../../../services";

const mapStateToProps = ( storeState: StoreState, ownProps ): PostIntentsBannerProps =>
{
    return ownProps;
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: PostIntentsBannerProps ): PostIntentsBannerDispatchProps =>
{
    return {
        handleClick: () =>
        {
            dispatch( modalServices.openPostIntentsDialogFromHomeScreen() );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( PostIntentsBanner );
