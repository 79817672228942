import { connect } from "react-redux";
import { StoreState } from "../../../_types";
import { getCurrentBusiness } from "../../../ducks/userBusiness";
import { NoPostsPanel, NoPostsPanelProps } from "../NoPostsPanel";

const mapStateToProps = ( storeState: StoreState ): NoPostsPanelProps =>
{
    return {
        currentBusiness: getCurrentBusiness( storeState ),
    };
};

export default connect(
    mapStateToProps,
)( NoPostsPanel );
