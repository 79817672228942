import * as React from "react";
import { map } from "lodash";
import { EpidemicSoundCollectionDetailAPI } from "../../../_types/api";
import EpidemicSoundCollectionItemContainer from "./containers/EpidemicSoundCollectionItem.container";
import * as InfiniteScroll from "react-infinite-scroller";
import CircularProgress from "react-md/lib/Progress/CircularProgress";
import { EpidemicCollectionsListContainerProps } from "./containers/EpidemicCollectionsList.container";

export interface EpidemicCollectionsListProps extends EpidemicCollectionsListContainerProps
{
    isLoadingEpidemicSoundUserCollectionsInProgress: boolean;
    nextUrl: string;
    scrollTopPosition: number;
}

export interface EpidemicCollectionsListDispatchProps
{
    loadMore: ( nextUrl: string ) => void;
    updateLightboxDialogTitle();
}

export class EpidemicCollectionsList extends React.PureComponent<EpidemicCollectionsListProps & EpidemicCollectionsListDispatchProps>
{
    private scrollWrapperRef: React.RefObject<HTMLDivElement>;

    constructor( props )
    {
        super( props );
        this.scrollWrapperRef = React.createRef();
    }

    public componentDidMount()
    {
        if ( this.scrollWrapperRef.current )
        {
            this.scrollWrapperRef.current.scrollTop = this.props.scrollTopPosition;
        }
        this.props.updateLightboxDialogTitle();
    }

    public render()
    {
        const listWrapperStyle = { height: window.innerHeight * 0.7, overflow: "auto" };
        return (
            <div className="dialogListWrapper collectionsList" style={listWrapperStyle} ref={this.scrollWrapperRef}>
                <InfiniteScroll
                    loadMore={this.loadMoreCollections}
                    hasMore={!!this.props.nextUrl}
                    loader={<CircularProgress key="0" className="spinner small" id="spinner"/>}
                    useWindow={false}>
                    {
                        map( this.props.epidemicCollections, ( epidemicCollection: EpidemicSoundCollectionDetailAPI ) =>
                        {
                            return <EpidemicSoundCollectionItemContainer key={epidemicCollection.id}
                                                                         epidemicSoundCollection={epidemicCollection}
                                                                         onClick={this.onClick}/>;
                        } )
                    }
                </InfiniteScroll>
            </div>
        );
    }

    private onClick = () =>
    {
        if ( this.scrollWrapperRef.current )
        {
            const collectionScrollTopPosition = this.scrollWrapperRef.current.scrollTop;
            this.props.handleSetScrollPosition( collectionScrollTopPosition );
        }
    }

    private loadMoreCollections = () =>
    {
        if ( !this.props.isLoadingEpidemicSoundUserCollectionsInProgress )
        {
            this.props.loadMore( this.props.nextUrl );
        }
    }
}
