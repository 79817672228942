import * as React from "react";
import * as PlusRegular from "../../assets/img/newNavPlus.png";
import { throttle } from "lodash";
import { Brand, FontCssUrlFieldType, FontFieldType, FontUrlFieldType } from "../../_types/api";

export interface NoBrandFontRowContainerProps
{
    fontType: Brand;
    fontField: FontFieldType;
    fontUrlField: FontUrlFieldType;
    fontCssUrlField: FontCssUrlFieldType;
    controlId: string;
}

export interface NoBrandFontRowDispatchProps
{
    onChooseFontClicked();
}

export class NoBrandFontRow extends React.PureComponent<NoBrandFontRowContainerProps & NoBrandFontRowDispatchProps>
{
    private throttledHandleEditFontClicked: ( ...args: any[] ) => any;

    public componentDidMount()
    {
        this.createThrottledHandleEditFontClickedFunction();
    }

    public render()
    {
        return (
            <div className="noBrandFontRow" onClick={this.handleEditFontClicked}>
                <div className="inner">
                    <div className="title">
                        Click to set a {this.props.fontType} brand font
                    </div>
                    <img className="plus" src={PlusRegular} alt="add brand font"/>
                </div>
            </div>
        );
    }

    private handleEditFontClicked = () =>
    {
        if ( this.throttledHandleEditFontClicked )
        {
            this.throttledHandleEditFontClicked();
        }
    }

    private createThrottledHandleEditFontClickedFunction()
    {
        const curriedHandleEditFontClicked = () =>
        {
            if ( this.props.onChooseFontClicked )
            {
                this.props.onChooseFontClicked();
            }
        };
        this.throttledHandleEditFontClicked = throttle( curriedHandleEditFontClicked, 500, { leading: true } );
    }
}
