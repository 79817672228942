import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../../_types";
import { EpidemicSoundTrackItem, EpidemicSoundTrackItemDispatchProps, EpidemicSoundTrackItemProps } from "../EpidemicSoundTrackItem";
import { epidemicSoundCatalogServices } from "../../../../services/epidemicSoundCatalog.services";
import { EpidemicSoundTrack } from "../../../../_types/api";
import { getEpidemicSoundTrackByIdAsMusic, isEpidemicSoundTrackReady } from "../../../../ducks/epidemicSoundCatalog";
import { store } from "../../../../store";
import { getAudioPlayerMusicTrack, isProcessCollectionItemRunning, ProcessCollection } from "../../../../ducks/ui";
import { EpidemicSoundTrackItemContainerProps } from "./EpidemicSoundTrackItem.container";

const mapStateToProps = ( storeState: StoreState, ownProps: EpidemicSoundTrackItemContainerProps ): EpidemicSoundTrackItemProps =>
{
    const { epidemicSoundTrack } = ownProps;
    const music = getAudioPlayerMusicTrack( storeState );
    return {
        isSelected: true,
        isPlaying: music && music.id === epidemicSoundTrack.epidemic_id,
        isLoading: isProcessCollectionItemRunning( storeState, {
            collection: ProcessCollection.FETCH_EPIDEMIC_SOUND_TRACK,
            id: ownProps.epidemicSoundTrack.epidemic_id,
        } ),
        shouldBeUpsoldToPremium: false,
        epidemicSoundTrack,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: EpidemicSoundTrackItemContainerProps ): EpidemicSoundTrackItemDispatchProps =>
{
    return {
        handleEpidemicSoundTrackClick: async ( epidemicSoundTrack: EpidemicSoundTrack ) =>
        {
            if ( !isEpidemicSoundTrackReady( store.getState(), epidemicSoundTrack.epidemic_id ) )
            {
                await dispatch( epidemicSoundCatalogServices.downloadEpidemicSoundTrackData( epidemicSoundTrack ) );
            }

            if ( isEpidemicSoundTrackReady( store.getState(), epidemicSoundTrack.epidemic_id ) )
            {
                const music = getEpidemicSoundTrackByIdAsMusic( store.getState(), epidemicSoundTrack.epidemic_id );
                ownProps.handleMusicClick( music );
            }
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( EpidemicSoundTrackItem );
