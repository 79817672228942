import * as React from "react";
import { isEqual } from "lodash";

export interface AudioPlayerProps
{
    currentMusic: MusicTrack;
    isAnimatedOutputMode: boolean;
}

export interface AudioPlayerDispatchProps
{
    clearAudioPlayerMusicTrack();
}

interface AudioPlayerState
{
    playingAudio?: HTMLAudioElement;
    playPromise?: Promise<void>;
    pausePromise?: Promise<void>;
}

export class AudioPlayer extends React.PureComponent<AudioPlayerProps & AudioPlayerDispatchProps, AudioPlayerState>
{
    constructor( props )
    {
        super( props );
        this.state = {};
    }

    public componentWillUnmount()
    {
        this.stopPlayingAudio();
        this.props.clearAudioPlayerMusicTrack();
    }

    public componentDidUpdate( prevProps: AudioPlayerProps )
    {
        const currentMusic = this.props.currentMusic;
        if ( currentMusic && this.otherConditionsForPlaying() )
        {
            if ( !isEqual( prevProps.currentMusic, currentMusic ) )
            {
                this.stopPlayingAudio();
                this.startPlayingAudio( currentMusic );
            }
        }
        else
        {
            this.stopPlayingAudio();
        }
    }

    public render()
    {
        return "";
    }

    private otherConditionsForPlaying = () =>
    {
        return this.props.isAnimatedOutputMode;
    }

    private startPlayingAudio = ( musicTrack: MusicTrack ) =>
    {
        const theAudioElement = new Audio( musicTrack.url );
        if ( theAudioElement )
        {
            theAudioElement.addEventListener( "ended", () =>
            {
                this.props.clearAudioPlayerMusicTrack();
            } );
        }

        const playPromise = theAudioElement && theAudioElement.play();
        if ( playPromise )
        {
            playPromise.catch( ( error: any ) =>
            {
                this.setState( { playingAudio: null, playPromise: null } );
                this.props.clearAudioPlayerMusicTrack();
            } );
        }
        this.setState( {
            playingAudio: theAudioElement,
            playPromise,
        } );
    }

    private stopPlayingAudio = () =>
    {
        const { playingAudio, playPromise } = this.state;
        if ( playPromise )
        {
            playPromise.then( () => playingAudio && playingAudio.pause() );
        }
        this.setState( {
            playingAudio: null,
            playPromise: null,
        } );
    }

}
