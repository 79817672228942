import * as React from "react";
import { CUSTOMER_SUPPORT_UNBREAKING, NEED_HELP_SETTINGS_URL, NON_BREAKING_SPACE } from "../helpers";
import { isCollabraUser } from "../ducks/user";
import { useSelector } from "react-redux";

export interface ErrorPanelProps
{
    imageSrc: string;
    headline: string | React.ReactElement<any>;
    subTitle?: string;
    renderHelpLink?: boolean;
}

export const ErrorPanel = ( props: ErrorPanelProps ) =>
{
    const shouldShowCollabraUI = useSelector( isCollabraUser );
    return (
        <div className="errorPanelContainer">
            <img src={props.imageSrc} alt="error"/>
            <div className="headline">{props.headline}</div>
            {props.subTitle && <div className="subTitle">{props.subTitle}</div>}
            {props.renderHelpLink && !shouldShowCollabraUI && <div className="subTitle">If error persists,
                {NON_BREAKING_SPACE}<a className="standardLink" href={NEED_HELP_SETTINGS_URL}>contact {CUSTOMER_SUPPORT_UNBREAKING}</a>
            </div>}
        </div>
    );
};
