import * as React from "react";
import { IdPropType } from "react-md/lib";
import Switch from "react-md/lib/SelectionControls/Switch";

export interface ShareOutputToggleProps
{
    className?: string;
    label?: string;
    id: IdPropType;
    enabled: boolean;
    togglingDisabled: boolean;
}

export interface ShareOutputToggleDispatchProps
{
    onChange?: ( result: boolean | number | string, event: Event ) => void;
}

export const ShareOutputToggle: React.FC<ShareOutputToggleProps & ShareOutputToggleDispatchProps> = ( props ) =>
{
    return <Switch
        id={props.id}
        label={props.label}
        labelBefore
        name="lights"
        checked={props.enabled}
        disabled={props.togglingDisabled}
        className={"socialAccountSelectionControl " + props.className}
        onChange={props.onChange}
    />;
};
