import * as React from "react";
import { DialogControlProps } from "../_types";
import InviteTeamsContent from "./settingsPages/InviteTeamsContent";
import {
    browserUtils,
    EMAIL_LIST_TITLE,
    INVITATION_ACCEPTED_CONTENT_FIRST_PART,
    INVITATION_ACCEPTED_CONTENT_LAST_PART,
    INVITATION_ACCEPTED_CONTENT_MOBILE_WALL,
    INVITATION_ACCEPTED_TITLE,
    INVITATION_SENT,
    INVITATION_SENT_CONTENT,
    INVITE_TEAM_MEMBERS_TITLE,
} from "../helpers";
import InviteTeamMembersDialogContentContainer from "./containers/InviteTeamMembersDialogContent.container";
import TeamDialogContainer, { TeamDialogContainerProps } from "./containers/TeamDialog.container";

export const teamDialogFactory = {
    getInviteTeamMembersSuccessDialogContent,
    getInviteTeamMembersAcceptedDialogContent,
    getInviteTeamMembersDialogContent,
    getTeamDialogWithError,
};

function getTeamDialogWithError( errorTitle: string, errorsSubtitle: string )
{
    return createTeamDialogContainer( {
        title: errorTitle,
        subtitle: errorsSubtitle,
        shouldShowShortOkButton: true,
        isShortFormFactor: true,
    } );
}

function getInviteTeamMembersDialogContent( closeDialog: () => void )
{
    return createTeamDialogContainer( {
        title: INVITE_TEAM_MEMBERS_TITLE,
        subtitle: EMAIL_LIST_TITLE,
        content: <InviteTeamMembersDialogContentContainer closeDialog={closeDialog}/>,
    } );
}

function getInviteTeamMembersSuccessDialogContent()
{
    return createTeamDialogContainer( {
        title: INVITATION_SENT,
        content: <InviteTeamsContent bodyText={INVITATION_SENT_CONTENT}/>,
        shouldShowShortOkButton: true,
    } );
}

function getInviteTeamMembersAcceptedDialogContent( aBusiness: string )
{
    let theText = INVITATION_ACCEPTED_CONTENT_FIRST_PART.concat( aBusiness, INVITATION_ACCEPTED_CONTENT_LAST_PART );

    if ( browserUtils.isMobileBrowser() )
    {
        theText = theText.concat( "\n\n" + INVITATION_ACCEPTED_CONTENT_MOBILE_WALL );
    }

    return createTeamDialogContainer( {
        title: INVITATION_ACCEPTED_TITLE,
        content: <InviteTeamsContent bodyText={theText}/>,
        shouldShowShortOkButton: true,
    } );
}

function createTeamDialogContainer( settings: Partial<TeamDialogContainerProps> )
{
    return ( props: DialogControlProps ) =>
    {
        return (
            <TeamDialogContainer
                {...props}
                {...settings}
            />
        );
    };
}
