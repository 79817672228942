import { Dispatch } from "redux";
import { mixModelActions, modalActions, uiActions } from "../actions";
import { StoreState } from "../_types";
import * as React from "react";
import {
    EDIT_VIDEO_CLOSE_REASONS_CANCELLED,
    EDIT_VIDEO_CLOSE_REASONS_SAVED,
    eventTracker,
    LightboxDialogIdentifierForKey,
    stringUtils,
} from "../helpers";
import { modalServices } from "./modal.services";
import VideoTrimmerContainer from "../components/containers/VideoTrimmer.container";
import { INPUT_MEDIA_TYPE } from "../_types/api";
import { store } from "../store";
import {
    getConvertedVideoAPIData,
    getVideoOriginalFileSize,
    getVideoTrimmerCurrentData,
    shouldTrimVideo,
    updateMixModelAfterInputMediaUpload,
} from "../ducks/mixModel";
import { postServices } from "./post.services";
import { getCurrentBusiness } from "../ducks/userBusiness";
import { getControlsConfigsLookup } from "../ducks/designs";

export const videoTrimmerServices = {
    openTrimmerModal,
    removeMedia,
    saveTrimData,
    setDefaultTrimmerData,
};

function openTrimmerModal( dispatch: Dispatch<StoreState>, videoUrl?: string )
{
    if ( videoUrl )
    {
        dispatch( mixModelActions.videoTrimmerStarted( videoUrl ) );
    }

    dispatch( uiActions.videoTrimmerModalShowing( true ) );
    dispatch( modalActions.loadingDialogClose() );
    dispatch( modalServices.openLightbox( {
        identifierForKey: LightboxDialogIdentifierForKey.TRIM_VIDEO,
        title: "Trim Video",
        hideCancel: true,
        showCancelX: true,
        confirmLabel: "Save",
        width: 800,
        height: 800,
        className: "videoTrimmer",
        autosizeContent: false,
        modal: true,
        footerClassName: "videoTrimmerFooter",
        content: () => <VideoTrimmerContainer/>,
        closeDialogBeforeCallbacks: false,
        onSuccess: () =>
        {
            return handleTrimmerModalSuccess( dispatch );
        },
        onCancel: () =>
        {
            eventTracker.logEditVideoClosed( EDIT_VIDEO_CLOSE_REASONS_CANCELLED, false );
            dispatch( mixModelActions.addMediaFileFailure( INPUT_MEDIA_TYPE ) );
        },
        onClose: () =>
        {
            dispatch( mixModelActions.videoTrimmerClosed() );
            dispatch( uiActions.videoTrimmerModalShowing( false ) );
        },
    } ) );
}

function handleTrimmerModalSuccess( dispatch: Dispatch<StoreState> )
{
    const dontCloseDialog = false;
    const state = store.getState();
    const videoTrimmerCurrentData = getVideoTrimmerCurrentData( state );
    if ( !videoTrimmerCurrentData.videoTrimmerIsReady )
    {
        return dontCloseDialog;
    }

    const videoUrl = videoTrimmerCurrentData.videoUrl;
    const trimmerData = videoTrimmerCurrentData.videoTrimmerTimeData;
    const videoDurationInSeconds = videoTrimmerCurrentData.videoDurationInSeconds;

    if ( shouldTrimVideo( state, trimmerData, videoUrl, videoDurationInSeconds ) )
    {
        eventTracker.logEditVideoClosed( EDIT_VIDEO_CLOSE_REASONS_SAVED, true );
        dispatch( postServices.trimVideo( videoUrl, trimmerData ) );
    }
    else
    {
        eventTracker.logEditVideoClosed( EDIT_VIDEO_CLOSE_REASONS_SAVED, false );
        saveTrimData( dispatch, videoUrl, trimmerData );
        updateMixModelAfterTrimmerClosedWithoutTrimming( dispatch );
    }
}

function saveTrimData( dispatch: Dispatch<StoreState>, videoUrl: string, trimmerTimeData: TrimmerTimeData )
{
    const newMediaId = stringUtils.getFileName( videoUrl );
    const originalTrimmerData = {
        url: videoUrl,
        time: trimmerTimeData,
    };
    const videoTrimmerDataSavedPayload = {
        mediaId: newMediaId,
        originalTrimmerData,
    };
    dispatch( mixModelActions.videoTrimmerOriginalDataSaved( videoTrimmerDataSavedPayload ) );
}

function updateMixModelAfterTrimmerClosedWithoutTrimming( dispatch: Dispatch<StoreState> )
{
    const state = store.getState();
    const uploadFields = getConvertedVideoAPIData( state );
    const fileSize = getVideoOriginalFileSize( state );
    const videoData = {
        uploadFields,
        fileSize,
        isLowRes: false,
    };

    updateMixModelAfterInputMediaUpload( dispatch, videoData );
}

function removeMedia( dispatch: Dispatch<StoreState>, index: number )
{
    const state = store.getState();
    const currentBusiness = getCurrentBusiness( state );
    const controlsConfigsLookup = getControlsConfigsLookup( state );

    dispatch( uiActions.clearAudioPlayerMusicTrack() );
    dispatch( mixModelActions.mediaRemove( index ) );
    dispatch( mixModelActions.computeControlData( {
        currentBusiness,
        controlsConfigsLookup,
    } ) );
    dispatch( uiActions.imageSelectionCleared() );
}

function setDefaultTrimmerData( dispatch: Dispatch<StoreState>, videoUrl: string, durationInSeconds: number )
{
    const videoTrimmerTimeData: TrimmerTimeData = {
        start: 0,
        end: Math.round( durationInSeconds * 1000 ),
    };
    saveTrimData( dispatch, videoUrl, videoTrimmerTimeData );
}
