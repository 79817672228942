import * as React from "react";
import { Dispatch } from "redux";
import { store } from "../store";
import {
    CURRENCY_SELECTOR_CODES,
    ERROR_TITLE_SORRY,
    eventTracker,
    LightboxDialogIdentifierForKey,
    MOBILE_WALL_BREAKPOINT_MAX_WIDTH,
    requests,
    SUBSCRIPTION_FLOW_STEP_COMPLETED,
    SUBSCRIPTION_FLOW_STEP_TRACKING_NAME_TRY_PRO,
    UPDATE_CARD_FORM_SOURCE_ACCOUNT_SUBSCRIPTION,
    UpdateCardFormSource,
    UPSELL_DIALOG_GRADIENT_VERSION_WIDTH,
    UPSELL_DIALOG_STANDARD_VERSION_WIDTH,
    USD_CODE,
} from "../helpers";
import { pricingActions, uiActions, userActions } from "../actions";
import { getCouponCode, getExchangeRateData, getNumSubscriptionPlans } from "../ducks/pricing";
import { modalServices } from "./";
import { PaymentFormDialog } from "../components/PaymentFormDialog";
import { StoreState } from "../_types";
import { getTopMostDialogOnSuccess } from "../ducks/modals";
import { UpdateCardFormDialog } from "../components/UpdateCardFormDialog";
import classNames from "classnames";
import { isNullOrUndefined } from "util";
import * as countryToCurrency from "country-to-currency";
import { includes } from "lodash";
import { getCountryCode } from "../ducks/tracking";
import { WelcomeBackDialogContent } from "../components/WelcomeBackDialogDialogContent";
import { ProcessTrackingKey } from "../ducks/ui";
import SubscriptionConfirmationDialogContentContainer from "../components/containers/SubscriptionConfirmationDialogContent.container";

export const pricingServices = {
    load,
    retryLoad,
    setDefaultCurrency,
    showPaymentFormDialogWithTestDrive,
    showPaymentFormDialogWithoutTestDrive,
    showPurchaseConfirmationDialog,
    showUpdateCardDialogFromAccountSettings,
    showWelcomeBackDialog,
};

const PRICING_URL = "/stripe_plans";

function load()
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( uiActions.trackProcessAsRunning( ProcessTrackingKey.STRIPE_PLANS_LOADING ) );

        const state = store.getState();
        const activeCouponCode = getCouponCode( state );
        try
        {
            const data = await requests.get<ListPlanAPIData>( PRICING_URL, { cc: activeCouponCode } );
            dispatch( uiActions.trackProcessAsStopped( ProcessTrackingKey.STRIPE_PLANS_LOADING ) );

            if ( activeCouponCode === getCouponCode( store.getState() ) )
            {
                dispatch( pricingActions.loadSuccess( data ) );
                dispatch( pricingActions.setCouponCodeError( data.couponError ) );
                dispatch( setDefaultCurrency() );
            }
        }
        catch (error)
        {
            dispatch( uiActions.trackProcessAsStopped( ProcessTrackingKey.STRIPE_PLANS_LOADING ) );
            dispatch( userActions.setCurrencyCode( USD_CODE ) );
            dispatch( pricingActions.loadFailure( error ) );
            if ( activeCouponCode )
            {
                dispatch( pricingActions.setCouponCodeError( error ) );
            }
        }
    };
}

function retryLoad()
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        await dispatch( load() );
        if ( getNumSubscriptionPlans( store.getState() ) === 0 )
        {
            dispatch( modalServices.openErrorDialog( ERROR_TITLE_SORRY, "Failed to load subscription plans, please reload and try again" ) );
        }
    };
}

function showPaymentFormDialogWithTestDrive( suppressOnSuccessCallback?: boolean )
{
    return showPlanPaymentDialog( true, suppressOnSuccessCallback );
}

function showPaymentFormDialogWithoutTestDrive( suppressOnSuccessCallback?: boolean, showGradientVersion?: boolean )
{
    return showPlanPaymentDialog( false, suppressOnSuccessCallback, showGradientVersion );
}

function showPlanPaymentDialog( showsTestDriveDialogOnCancel: boolean, suppressOnSuccessCallback?: boolean, showGradientVersion?: boolean )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const eventProperties = {
            stepIndex: 1,
            stepName: SUBSCRIPTION_FLOW_STEP_TRACKING_NAME_TRY_PRO,
        };
        eventTracker.logSubscriptionEvent( SUBSCRIPTION_FLOW_STEP_COMPLETED, eventProperties );

        const normalWidth = 500;
        const windowWidth = window.innerWidth;

        const makeFullBleedLightbox = windowWidth < MOBILE_WALL_BREAKPOINT_MAX_WIDTH;
        const lightboxWidth = makeFullBleedLightbox ? windowWidth : normalWidth;

        const onSuccessCallback = suppressOnSuccessCallback ? null : getTopMostDialogOnSuccess( store.getState() );

        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.CREDIT_CARD_PAYMENT_FORM,
            showCancelX: true,
            modal: true,
            hideConfirm: true,
            hideCancel: true,
            width: lightboxWidth,
            height: 465,
            dialogContainerClassName: classNames( { fullBleed: makeFullBleedLightbox } ),
            content: () => <PaymentFormDialog showGradientVersion={showGradientVersion}/>,
            onSuccess: onSuccessCallback,
            onCancel: () =>
            {
                eventTracker.logCreditCardDialogDeclined();

                if ( showsTestDriveDialogOnCancel )
                {
                    dispatch( modalServices.openAcceptTestDriveDialog() );
                }
            },
        } ) );
    };
}

function showUpdateCardDialogFromAccountSettings( stripeCustomerId: string )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        showUpdateStripeCardDialog( dispatch, stripeCustomerId, UPDATE_CARD_FORM_SOURCE_ACCOUNT_SUBSCRIPTION );
    };
}

function showUpdateStripeCardDialog( dispatch: Dispatch<StoreState>, stripeCustomerId: string, source: UpdateCardFormSource )
{
    dispatch( modalServices.openLightbox( {
        identifierForKey: LightboxDialogIdentifierForKey.UPDATE_STRIPE_CARD,
        showCancelX: true,
        modal: true,
        hideConfirm: true,
        hideCancel: true,
        width: 500,
        className: "updateCardFormDialog",
        content: () => <UpdateCardFormDialog stripeCustomerId={stripeCustomerId} source={source}/>,
    } ) );
}

function showPurchaseConfirmationDialog( showGradientVersion: boolean, onCloseFunction?: () => void )
{
    const dialogWidth = showGradientVersion ? UPSELL_DIALOG_GRADIENT_VERSION_WIDTH : UPSELL_DIALOG_STANDARD_VERSION_WIDTH;
    const confirmButtonLabel = showGradientVersion ? "Finish" : "Continue";
    const className = showGradientVersion ? "gradientHeaderDialog" : "subscriptionConfirmationDialog";
    const footerClassName = showGradientVersion ? "gradientHeaderDialogFooter" : "subscriptionConfirmationDialogFooter";

    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const closeDialog = () =>
        {
            modalServices.closeTopLightBox( dispatch, store.getState() );
        };

        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.SUBSCRIPTION_CONFIRMATION,
            width: dialogWidth,
            confirmLabel: confirmButtonLabel,
            hideCancel: true,
            showCancelX: false,
            footerClassName,
            className,
            content: () => <SubscriptionConfirmationDialogContentContainer closeDialog={closeDialog} showGradientVersion={showGradientVersion}/>,
            onClose: () =>
            {
                if ( onCloseFunction )
                {
                    onCloseFunction();
                }
            },
        } ) );
    };
}

function showWelcomeBackDialog( dialogTitle: string, dialogDescription: string, onCloseFunction: () => void )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.WELCOME_BACK,
            width: UPSELL_DIALOG_STANDARD_VERSION_WIDTH,
            confirmLabel: "Continue",
            hideCancel: true,
            showCancelX: false,
            footerClassName: "welcomeBackDialogFooter",
            className: "welcomeBackDialog",
            content: () => <WelcomeBackDialogContent title={dialogTitle} description={dialogDescription}/>,
            onClose: () =>
            {
                if ( onCloseFunction )
                {
                    onCloseFunction();
                }
            },
        } ) );
    };
}

function setDefaultCurrency()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const state = store.getState();
        const countryCode = getCountryCode( state );
        const exchangeRateData = getExchangeRateData( state );

        const currencyFromCountryCode = countryToCurrency[countryCode];
        if ( !isNullOrUndefined( exchangeRateData ) && includes( CURRENCY_SELECTOR_CODES, currencyFromCountryCode ) )
        {
            dispatch( userActions.setCurrencyCode( currencyFromCountryCode ) );
        }
        else
        {
            dispatch( userActions.setCurrencyCode( USD_CODE ) );
        }
    };
}
