import * as React from "react";
import { padStart, startCase } from "lodash";
import { CircularProgressWithText } from "./CircularProgressWithText";
import { RecordAPIUpdate } from "../_types/api";

export interface RecordingVideoProgressProps
{
    recordProgress: RecordAPIUpdate;
}

export class RecordingVideoProgress extends React.PureComponent<RecordingVideoProgressProps>
{
    public render()
    {
        return (
            <div className="recordingWrap">
                <CircularProgressWithText className="recordingVideoElementProgress" progressText={this.generateFormattedProgressText()}/>
            </div>
        );
    }

    private generateFormattedProgressText = () =>
    {
        const completePercent = this.calculateRecordCompletionPercent();
        const formattedCompletePercent = this.formatCompletionPercent( completePercent );
        const progressStateLabel = this.formatProgressStateLabel();
        return progressStateLabel + formattedCompletePercent;
    }

    private formatCompletionPercent = ( completePercent ) =>
    {
        if ( completePercent !== undefined )
        {
            const formattedCompletePercent = padStart( completePercent + "", 3 );
            return ` - ${formattedCompletePercent}%`;
        }
        else
        {
            return "...";
        }
    }

    private calculateRecordCompletionPercent = () =>
    {
        if ( this.props.recordProgress && typeof this.props.recordProgress.progress !== "undefined" )
        {
            return Math.round( this.props.recordProgress.progress * 100 );
        }
    }

    private formatProgressStateLabel()
    {
        const progressState = this.props.recordProgress && this.props.recordProgress.state;
        switch ( progressState )
        {
            case "getting_music":
                return "Downloading Music";
            case "merging_audio":
                return "Finalizing";
            case "s3_uploading":
                return "Uploading";
            default:
                return startCase( progressState );
        }
    }
}
