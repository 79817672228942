import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../_types";
import { HomePageHeader, HomePageHeaderDispatchProps, HomePageHeaderProps } from "../HomePageHeader";
import { contentSearchActions } from "../../../actions";
import { ALL_SOURCE, browserUtils, HOMEPAGE_URL } from "../../../helpers";
import { mixModelServices } from "../../../services";
import { isCreateYourOwnBannerButtonVisible } from "../../../ducks/ui";
import { isCollabraUser } from "../../../ducks/user";

export interface HomePageHeaderContainerProps
{
    hasBusiness: boolean;
    currentPathname: string;
}

const mapStateToProps = ( storeState: StoreState, ownProps: HomePageHeaderContainerProps ): HomePageHeaderProps =>
{
    const isMobileBrowser = browserUtils.isMobileBrowser();
    const shouldShowCreateYourOwnButton = !isMobileBrowser && !isCreateYourOwnBannerButtonVisible( storeState ) && ownProps.currentPathname
                                          === HOMEPAGE_URL;
    const shouldShowStartSomethingNewButton = !isMobileBrowser && ownProps.currentPathname !== HOMEPAGE_URL;
    const isUserCollabra = isCollabraUser( storeState );
    return {
        ...ownProps,
        shouldShowCollabraUI: isUserCollabra,
        shouldShowCreateYourOwnButton,
        shouldShowStartSomethingNewButton,
        showHomeButton: !isMobileBrowser,
        showMyPostsButton: !isMobileBrowser,
        showMyBrandButton: true,
        showMyAccountButton: !isUserCollabra,
        showSearchBar: !isMobileBrowser,
        showReferAFriend: !isUserCollabra && !isMobileBrowser,
        showHelpMenu: !isMobileBrowser,
    };
};
const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): HomePageHeaderDispatchProps =>
{
    return {
        exitSearchMode: () =>
        {
            dispatch( contentSearchActions.searchModeExited() );
        },
        handleCreateYourOwnButtonClick: () =>
        {
            dispatch( mixModelServices.setupNewPostWithEventing( ALL_SOURCE ) );
        },
        handleStartSomethingNewButtonClick: () =>
        {
            dispatch( mixModelServices.setupNewPostWithEventing( ALL_SOURCE ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( HomePageHeader );
