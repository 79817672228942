import * as React from "react";
import Button from "react-md/lib/Buttons/Button";
import CircularProgress from "react-md/lib/Progress/CircularProgress";
import classNames from "classnames";
import * as SharePng from "../assets/img/share.png";
import * as MakeItAnAdPng from "../assets/img/makeItAnAdIcon_charcoal.png";
import { PullDownMenu } from "./PullDownMenu";
import DropdownMenu from "react-md/lib/Menus/DropdownMenu";
import { PullDownMenuButtonItemProps } from "./PullDownMenuButtonItem";
import { browserUtils } from "../helpers";

export interface NavigationHeaderProps
{
    pageTitle?: string;
    pageTitleImage?: string;
    getNextButton?: ( shouldDisable: boolean, onNextButtonClick: () => void ) => void;
    nextButtonText?: string;
    isWaitingToLoad?: boolean;
    shouldDisableNextButton?: boolean;
    onNextButtonClick?: () => void;
    onBackButtonClick: () => void;
    shouldShowNextButtonCoachMark?: boolean;
    shouldShowNavigationDropDownMenu?: boolean;
    hasUsedTrial?: boolean;
    isCurrentBusinessTeamMember?: boolean;
    setFacebookAdModeAndSegueNextPage?( facebookAdMode: boolean );
}

export class NavigationHeader extends React.PureComponent<NavigationHeaderProps>
{
    public render()
    {
        const isMobileBrowser = browserUtils.isMobileBrowser();
        return (
            <div className={classNames( "header", { mobileHeader: isMobileBrowser } )}>
                <div id="header-back-button"
                     className={classNames( "headerButton", { clickable: !this.props.isWaitingToLoad, mobile: isMobileBrowser } )}
                     onClick={this.onBackButtonClick}><i className="material-icons">arrow_back_ios</i>
                </div>
                {this.props.pageTitleImage && <img className="pageTitleImage" src={this.props.pageTitleImage} alt="Collabra+Ripl logo"/>}
                {this.props.pageTitle && <h2 className="pageTitle">{this.props.pageTitle}</h2>}
                {this.createNextButton()}
            </div>
        );
    }

    private onBackButtonClick = () =>
    {
        if ( this.props.isWaitingToLoad )
        {
            return false;
        }
        return this.props.onBackButtonClick();
    }

    private createNextButton = () =>
    {
        if ( this.props.getNextButton )
        {
            return this.props.getNextButton( this.props.shouldDisableNextButton, this.props.onNextButtonClick );
        }
        else if ( this.props.shouldShowNavigationDropDownMenu && !this.props.isCurrentBusinessTeamMember )
        {
            return (
                <PullDownMenu
                    id="navigationHeaderNextButtonPullDownMenu"
                    button={this.getNextButton()}
                    menuItems={this.getNextButtonMenuItems()}
                    disabled={this.props.isWaitingToLoad}
                    anchor={{
                        x: DropdownMenu.HorizontalAnchors.INNER_RIGHT,
                        y: DropdownMenu.VerticalAnchors.BOTTOM,
                    }}
                />
            );
        }
        else if ( this.props.onNextButtonClick )
        {
            return this.getNextButton( this.props.onNextButtonClick );
        }

        return (<div className="headerButton"/>);
    }

    private getNextButtonMenuItems = (): PullDownMenuButtonItemProps[] =>
    {
        return [
            {
                title: "Share as a post",
                icon: SharePng,
                onClick: () => this.props.setFacebookAdModeAndSegueNextPage( false ),
            },
            {
                title: "Submit as an ad",
                icon: MakeItAnAdPng,
                label: "new",
                onClick: () => this.props.setFacebookAdModeAndSegueNextPage( true ),
            },
        ];
    }

    private getNextButton = ( onClick?: ( event: React.MouseEvent<HTMLElement> ) => void ) =>
    {
        return (
            <Button primary flat swapTheming
                    disabled={this.props.isWaitingToLoad || this.props.shouldDisableNextButton}
                    onClick={onClick}
                    id="header-next-button">
                {this.getNextButtonContent()}
            </Button>
        );
    }

    private getNextButtonContent = () =>
    {
        if ( this.props.isWaitingToLoad )
        {
            return <CircularProgress className="spinner white small" id="spinner"/>;
        }
        return <>
            {this.props.shouldShowNextButtonCoachMark && this.createNextButtonCoachMark()}
            {this.props.nextButtonText || "Next"}
        </>;
    }

    private createNextButtonCoachMark = () =>
    {
        return (
            <div className="nextButtonCoachMark">
                <div className="arrowUp"/>
                Want to share, schedule, or save your creation?<br/>{this.getSubscriptionText()}
            </div>
        );
    }

    private getSubscriptionText = () =>
    {
        if ( this.props.hasUsedTrial )
        {
            return <>Tap Next to start your subscription.</>;
        }
        return <>Tap Next to start your free trial.</>;
    }
}
