import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../_types";
import { showBrandLogo } from "../../../ducks/user";
import { logoServices, userBusinessServices } from "../../../services";
import { BUSINESS_PROFILE_PAGE, eventTracker } from "../../../helpers";
import { LogoSwitcherPanel, LogoSwitcherPanelDispatchProps, LogoSwitcherPanelProps } from "../../editorControls/LogoSwitcherPanel";
import { getEditedBusinessInfo } from "../../../ducks/ui";
import { getCurrentBusiness } from "../../../ducks/userBusiness";
import { uiActions } from "../../../actions";

const mapStateToProps = ( storeState: StoreState ): LogoSwitcherPanelProps =>
{
    return {
        source: BUSINESS_PROFILE_PAGE,
        showBrandLogo: showBrandLogo( storeState ),
        showLogoToggleText: "Show my logo each time I start a post",
        brandLogos: getEditedBusinessInfo( storeState ).logos,
        currentBusiness: getCurrentBusiness( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): LogoSwitcherPanelDispatchProps =>
{
    return {
        refreshBrandLogos()
        {
            dispatch( uiActions.clearBusinessInfo() );
            dispatch( userBusinessServices.loadBrandLogos() );
        },
        handleShowBrandLogo: ( enabled: boolean ) =>
        {
            eventTracker.logLogoToggleSwitched( enabled, BUSINESS_PROFILE_PAGE );
            dispatch( logoServices.setEnabled( enabled ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( LogoSwitcherPanel );
