import { connect } from "react-redux";
import { Dispatch } from "redux";
import { store } from "../../store";
import { userServices } from "../../services";
import { StoreState } from "../../_types";
import { getChannelTrackingParameters } from "../../ducks/tracking";
import GradientCreateAccountForm, {
    GradientCreateAccountFormDispatchProps,
    GradientCreateAccountFormOwnProps,
    GradientCreateAccountFormProps,
} from "../GradientCreateAccountForm";
import { getThirdPartyErrorMessage, isAcceptTeamInviteFlow, shouldShowThirdPartyLoginError } from "../../ducks/ui";
import { CredentialResponse } from "@react-oauth/google";

const mapStateToProps = ( storeState: StoreState, ownProps: GradientCreateAccountFormOwnProps ): GradientCreateAccountFormProps =>
{
    return {
        ...ownProps,
        shouldShowThirdPartyLoginError: shouldShowThirdPartyLoginError( storeState ),
        thirdPartyLoginErrorMessage: getThirdPartyErrorMessage( storeState ),
        isAcceptTeamInviteFlow: isAcceptTeamInviteFlow( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: GradientCreateAccountFormProps ): GradientCreateAccountFormDispatchProps =>
{
    return {
        handleGoogleCreateAccountResponse( tokenResponse: CredentialResponse, sendEmailFlag: boolean )
        {
            const storeState = store.getState();
            const channelTrackingParameters = getChannelTrackingParameters( storeState );
            dispatch( userServices.clientGoogleCreateAccount(
                tokenResponse.credential,
                sendEmailFlag,
                channelTrackingParameters,
                isAcceptTeamInviteFlow( storeState ),
                ownProps.showBusinessTypeSelection ) );
        },
        onCreateEmailAccountAttempt: ( emailAddress: string, password: string, sendEmailFlag: boolean ) =>
        {
            const storeState = store.getState();
            const channelTrackingParameters = getChannelTrackingParameters( storeState );
            const createBusinessParameter = isAcceptTeamInviteFlow( storeState );
            dispatch(
                userServices.createEmailAccount( emailAddress, password, sendEmailFlag, channelTrackingParameters,
                    undefined, createBusinessParameter, ownProps.showBusinessTypeSelection ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( GradientCreateAccountForm );
