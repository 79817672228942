import * as React from "react";
import Button from "react-md/lib/Buttons/Button";
import classNames from "classnames";
import { upperCase } from "lodash";
import { FoundationPresetTabConfig } from "./FoundationPresetTabs";

export interface FoundationPresetTabItemProps extends FoundationPresetTabConfig
{
    onSwitchTabId: ( id: string ) => void;
    selectedTabId: string;
}

export class FoundationPresetTab extends React.PureComponent<FoundationPresetTabItemProps>
{
    public render()
    {
        return (<Button
            flat
            className={classNames( "designElementTab", { inactive: this.props.selectedTabId !== this.props.tabId } )}
            onClick={this.onClick}>
            {upperCase( this.props.displayName )}
        </Button>);
    }

    private onClick = () =>
    {
        this.props.onSwitchTabId( this.props.tabId );
    }
}
