import * as React from "react";
import { map } from "lodash";
import { TiersExplanationDesktopFeatureList } from "./TiersExplanationDesktopFeatureList";
import { TiersExplanationOfBenefitsDispatchProps, TiersExplanationOfBenefitsProps } from "./TiersExplanationOfBenefits";
import { TiersExplanationDesktopTab } from "./TiersExplanationDesktopTab";

export function TiersExplanationOfBenefitsForDesktop( props: TiersExplanationOfBenefitsProps & TiersExplanationOfBenefitsDispatchProps )
{
    const tabs = map( props.tierTabs, ( data ) => <TiersExplanationDesktopTab key={data.tier} data={data} selectTier={props.selectTier}/> );

    return <div className="selectPriceTiersContent desktop">
        <div className="selectPriceTiersDialogContent">
            <TiersExplanationDesktopFeatureList/>
            {tabs}
        </div>
    </div>;
}
