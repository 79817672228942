import * as React from "react";
import { PriceBlockSection } from "./PriceBlockSection";
import Button from "react-md/lib/Buttons/Button";
import { eventTracker, NEED_HELP_URL, pricingTiersUIHelper, stringUtils, USD_CODE } from "../helpers";
import { round } from "lodash";
import CurrencySelectorContainer from "./containers/CurrencySelector.container";
import { PurchaseProgressSpinner } from "./PurchaseProgressSpinner";
import { getCurrentProductTier } from "../ducks/user";
import { store } from "../store";

export interface CancelWinBackOfferProps
{
    monthlyPlan?: StripePlanData;
    annualPlan?: StripePlanData;
    isUpdatingSubscription: boolean;
    shouldShowExchangeRates: boolean;
    selectedCurrencyCode: CurrencyCode;
}

export interface CancelWinBackOfferDispatchProps
{
    handleWinBackOfferAccepted( selectedPlanId: string );
    handleProceedToCancellation();
}

interface CancelWinBackOfferState
{
    selectedPlan?: StripePlanData;
}

export class CancelWinBackOffer extends React.PureComponent<CancelWinBackOfferProps & CancelWinBackOfferDispatchProps, CancelWinBackOfferState>
{
    constructor( props )
    {
        super( props );
        this.state = {};
    }

    public componentDidMount()
    {
        eventTracker.logWinBackOfferDisplayed();
    }

    public render()
    {
        return (<>
            <div className="winBackOfferFrame manageSubscriptionBlock">
                {this.props.shouldShowExchangeRates && <CurrencySelectorContainer/>}
                {this.getSpinnerOverlayHtml()}
                <h2 className="headline">Want to give Ripl one more try?</h2>
                <p className="subhead">Get {this.getDiscountPercent()}% off the next month or year of your {this.getMembershipStatusText()}.</p>
                <h2 className="chooseAPlan">Choose a plan</h2>
                <PriceBlockSection
                    monthlyPlan={this.props.monthlyPlan}
                    annualPlan={this.props.annualPlan}
                    selectedPlan={this.state.selectedPlan}
                    handlePlanSelected={this.handleUpdatePlanSelection}/>

                <div className="buttonWrap">
                    <Button flat
                            className="cancelButton"
                            onClick={this.onCancelSubscriptionClicked}>Cancel subscription</Button>

                    <Button primary flat swapTheming
                            className="primaryButton"
                            disabled={!this.state.selectedPlan}
                            onClick={this.onAcceptOfferClicked}>Accept offer</Button>
                </div>
            </div>
            <p className="cancelFinePrint">
                {this.getFinePrint()} Need help? <span className="riplLinkNewNav" onClick={this.handleContactUsClicked}>Contact us</span>.
            </p>
        </>);
    }

    private getSpinnerOverlayHtml()
    {
        if ( this.props.isUpdatingSubscription )
        {
            return (
                <div className="overlay">
                    <PurchaseProgressSpinner/>
                </div>
            );
        }
        return null;
    }

    private getFinePrint = () =>
    {
        const exchangeRateDisclosure = this.getExchangeRateText( this.props.selectedCurrencyCode );
        return `Offer applies to the next month or year, and after that time you'll be charged ` +
               `${stringUtils.getUSDZeroPaddedPrice( this.props.monthlyPlan.originalAmount )} (plus tax) for the monthly plan ` +
               `or ${stringUtils.getUSDZeroPaddedPrice( this.props.annualPlan.originalAmount )} (plus tax) for the yearly plan ` +
               `on a recurring basis. ` + exchangeRateDisclosure +
               `Changes to your billing interval will take effect immediately and you will be issued a ` +
               `prorated credit for any unused time in the previous billing cycle.`;
    }

    private getExchangeRateText = ( selectedCurrencyCode: CurrencyCode ) =>
    {
        if ( selectedCurrencyCode !== USD_CODE )
        {
            return "Actual charge will be based on the equivalent exchange rate. ";
        }
        return "";
    }

    private handleContactUsClicked = () =>
    {
        window.open( NEED_HELP_URL, "_blank" );
    }

    private getDiscountPercent = () =>
    {
        const percent = 1 - (this.props.monthlyPlan.amount / this.props.monthlyPlan.originalAmount);
        return round( percent * 100, 0 );
    }

    private handleUpdatePlanSelection = ( selectedPlan: StripePlanData ) =>
    {
        this.setState( { selectedPlan } );
    }

    private onCancelSubscriptionClicked = () =>
    {
        eventTracker.logWinBackOfferDeclined();
        this.props.handleProceedToCancellation();
    }

    private onAcceptOfferClicked = () =>
    {
        const selectedPlanId = this.state.selectedPlan.id;
        eventTracker.logWinBackOfferAcceptButtonTapped( selectedPlanId );
        this.props.handleWinBackOfferAccepted( selectedPlanId );
    }

    private getMembershipStatusText()
    {
        const currentTier = getCurrentProductTier( store.getState() );
        const tierDisplayText = pricingTiersUIHelper.getTierDisplayText( currentTier );

        return ` ${tierDisplayText} Plan`;
    }
}
