import * as React from "react";
import { DesignElementProperty, SlideTransitionChoice, TransitionOption } from "../../_types";
import classNames from "classnames";
import { TEXT_STYLE_STATUS_DEVELOPMENT } from "../../_types/api";

export interface DesignElementListOptionProps
{
    designElementProperty: DesignElementProperty;
    options: TransitionOption;
    preferredSlideTransition?: SlideTransitionChoice;
    onPreferredSlideTransitionSelected( slideTransitionChoice: SlideTransitionChoice ): void;
}

export interface DesignElementListOptionDispatchProps
{
    ( designElementProperty: DesignElementProperty, designElementListOption: any );
}

export class DesignElementListOption
    extends React.PureComponent<DesignElementListOptionProps & DesignElementListOptionDispatchProps>
{
    public render()
    {
        const displayName = this.props.options.displayName;

        return (
            <div className={classNames( "item",
                {
                    selected: this.props.designElementProperty.selected === this.props.options.value,
                    devMode: this.props.options.status === TEXT_STYLE_STATUS_DEVELOPMENT,
                } )}
                 onClick={this.handleSelected}>
                <div className="description">{displayName}</div>
            </div>
        );
    }

    private handleSelected = () =>
    {
        const transitionOptions = { ...(this.props.preferredSlideTransition.transitionOptions || {}) };
        transitionOptions[this.props.designElementProperty.id] = this.props.options.value;

        const newPreferredSlideTransition = { ...this.props.preferredSlideTransition, transitionOptions };

        this.props.onPreferredSlideTransitionSelected( newPreferredSlideTransition );
    }
}
