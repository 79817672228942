import * as React from "react";

export const MyPostsIcon: React.FC<{}> = () =>
{
    return (
        <svg className="newNavStackedIcon" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 61">
            <rect x="2.45" y="2.45" width="55.09" height="55.09"/>
            <line x1="30" y1="2.45" x2="30" y2="57.55"/>
            <line x1="57.55" y1="21.27" x2="30" y2="21.27"/>
            <line x1="57.55" y1="39.51" x2="30" y2="39.51"/>
            <line x1="30" y1="30.63" x2="2.45" y2="30.63"/>
        </svg>
    );
};
