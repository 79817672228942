import * as React from "react";

interface MyAccountIconProps
{
    inactive: boolean;
}

export class MyAccountIcon extends React.PureComponent <MyAccountIconProps>
{
    public render()
    {
        if ( this.props.inactive )
        {
            return (
                /* tslint:disable:max-line-length */
                // @ts-ignore
                <svg className="newNavStackedIcon myAccount" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                    <path
                        d="M55.27,44.83c-3.75-3.49-10.13-8.46-17.82-10.67a1,1,0,0,1-.39-1.72c3.44-2.89,6.43-7.46,6.43-12.61C43.49,11.1,40.11,2,30.08,2c-10.26,0-13.4,9.1-13.4,17.83,0,5.15,3,9.72,6.44,12.6a1,1,0,0,1-.38,1.73C15,36.36,8.65,41.34,4.89,44.83a8,8,0,0,0-1.95,8.43A6.8,6.8,0,0,0,9.23,58h41.7a6.81,6.81,0,0,0,6.3-4.74A8,8,0,0,0,55.27,44.83Z"/>
                </svg>
            );
        }
        else
        {
            return (
                <svg className="newNavStackedIcon" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                    <path
                        d="M55.27,44.83c-3.75-3.49-10.13-8.46-17.82-10.67a1,1,0,0,1-.39-1.72c3.44-2.89,6.43-7.46,6.43-12.61C43.49,11.1,40.11,2,30.08,2c-10.26,0-13.4,9.1-13.4,17.83,0,5.15,3,9.72,6.44,12.6a1,1,0,0,1-.38,1.73C15,36.36,8.65,41.34,4.89,44.83a8,8,0,0,0-1.95,8.43A6.8,6.8,0,0,0,9.23,58h41.7a6.81,6.81,0,0,0,6.3-4.74A8,8,0,0,0,55.27,44.83Z"/>
                </svg>
            );
        }
    }
}
