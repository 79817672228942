import { TextPickerCaptionData } from "../../../_types";
import { includes, map } from "lodash";
import CustomizeTextInputFieldContainer from "../containers/CustomizeTextInputField.container";
import { CAPTION_TEXT_FIELD_NAME } from "../../../_types/api";
import * as React from "react";

interface TextPickerRegularCaptionsProps
{
    activeCaptionControls: string[];
    regularCaptions: TextPickerCaptionData[];
}

export function TextPickerRegularCaptions( props: TextPickerRegularCaptionsProps )
{
    const { activeCaptionControls, regularCaptions } = props;
    const captions = map( regularCaptions, ( caption ) =>
    {
        if ( includes( activeCaptionControls, caption.id ) )
        {
            return (
                <div key={caption.id} className="primaryAndSecondaryTextFieldWrap">
                    <CustomizeTextInputFieldContainer identifier={caption.id}
                                                      value={caption.value}
                                                      label="Caption"
                                                      placeholder="Add Caption"
                                                      type={CAPTION_TEXT_FIELD_NAME}/>
                </div>
            );
        }
    } );

    return <>{captions}</>;
}