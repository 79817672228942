import * as React from "react";
import { RecommendedPost } from "../_types";
import { ASPECT_RATIO_NAMES, ASPECT_RATIO_VALUE, SQUARE_ASPECT_RATIO } from "../helpers";
import VideoElementContainer from "./containers/VideoElement.container";

export interface RecommendedPostCellProps
{
    recommendedPost: RecommendedPost;
    handleCustomizePost: ( recommendedPost: RecommendedPost ) => void;
}

export class RecommendedPostCell extends React.PureComponent<RecommendedPostCellProps>
{
    public render()
    {
        const recommendedPost = this.props.recommendedPost;
        const aspectRatio = recommendedPost.aspect_ratio || SQUARE_ASPECT_RATIO;
        const aspectRatioValue = ASPECT_RATIO_VALUE[aspectRatio];
        const aspectRatioClass = ASPECT_RATIO_NAMES[aspectRatio];
        return (
            <div className={`homePostRowTemplate ${aspectRatioClass}`} onClick={this.handleOnClick}>
                <VideoElementContainer
                    videoUrl={recommendedPost.video_url}
                    imageUrl={recommendedPost.image_url}
                    posterUrl={recommendedPost.poster_url}
                    muted={true}
                    playOnMouseOver={true}
                    aspectRatio={aspectRatioValue}/>
                <div className="recommendedPostOverlay">
                    <div className="recommendedPostTitle">{recommendedPost.name}</div>
                </div>
            </div>
        );
    }

    private handleOnClick = () =>
    {
        this.props.handleCustomizePost( this.props.recommendedPost );
    }
}
