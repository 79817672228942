import { SubscriptionFlowSource } from "./";

export const UPSELL_DIALOG_GRADIENT_VERSION_WIDTH = 573;
export const UPSELL_DIALOG_STANDARD_VERSION_WIDTH = 315;

export interface UpsellConfig
{
    title: string;
    image?: string;
    message: string;
    trackingSource: SubscriptionFlowSource;
}

export const UPSELL_CHOOSE_PLAN_TO_CONTINUE_TITLE = "Choose a plan to continue";
export const UPSELL_CHOOSE_PLAN_TO_CONTINUE_SUBTITLE = "and get the first 7 days free";
