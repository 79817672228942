import * as React from "react";
import {
    BRAND_CREATIVE_PAGE_URL,
    BRAND_SETTINGS_PAGE_URL,
    browserUtils,
    eventTracker,
    history,
    HOMEPAGE_MY_BRAND_URL,
    HOMEPAGE_MY_POSTS_URL,
    HOMEPAGE_URL,
    POSTS_PAGE_URL,
    REFER_A_FRIEND_URL,
    USER_SETTINGS_URL,
} from "../../helpers";
import * as RiplWordmark from "../../assets/img/ripl_wordmark.png";
import * as CollabraPlusRiplLogo from "../../assets/img/collabra_plus_ripl_logo.png";
import * as Giftbox from "../../assets/img/giftbox-nav.png";
import Button from "react-md/lib/Buttons/Button";
import classNames from "classnames";
import { startsWith } from "lodash";
import { HomeIcon } from "../svgs/HomeIcon";
import { MyPostsIcon } from "../svgs/MyPostsIcon";
import { MyBrandIcon } from "../svgs/MyBrandIcon";
import { MyAccountIcon } from "../svgs/MyAccountIcon";
import { HelpMenu } from "../HelpMenu";
import ContentSearchBarContainer from "./containers/ContentSearchBar.container";
import { PlusIcon } from "../svgs/PlusIcon";
import { HomePageHeaderContainerProps } from "./containers/HomePageHeader.container";

export interface HomePageHeaderProps extends HomePageHeaderContainerProps
{
    shouldShowCreateYourOwnButton: boolean;
    shouldShowStartSomethingNewButton: boolean;
    showHomeButton: boolean;
    showMyPostsButton: boolean;
    showMyBrandButton: boolean;
    showMyAccountButton: boolean;
    shouldShowCollabraUI: boolean;
    showSearchBar: boolean;
    showReferAFriend: boolean;
    showHelpMenu: boolean;
}

export interface HomePageHeaderDispatchProps
{
    exitSearchMode: () => void;
    handleCreateYourOwnButtonClick: () => void;
    handleStartSomethingNewButtonClick: () => void;
}

export class HomePageHeader extends React.PureComponent<HomePageHeaderProps & HomePageHeaderDispatchProps>
{
    public render()
    {
        return (
            <div className="header homePage">
                <div className="headerBox riplIcon homePage">
                    <img className={classNames( "logo", "clickable", { collabra: this.props.shouldShowCollabraUI } )} onClick={this.showInspire}
                         src={this.getLogo()}
                         alt={this.getLogoAltText()}/>
                </div>
                {this.renderNewNavContents()}
                {this.props.shouldShowStartSomethingNewButton ? this.renderStartSomethingNew() : this.renderCreateYourOwnButton()}
                {this.props.showSearchBar && <ContentSearchBarContainer/>}
                {this.props.showReferAFriend &&
                 <img className="giftboxIcon clickable" onClick={this.showReferAFriend} src={Giftbox} alt="Refer a friend"/>}
                {this.props.showHelpMenu && this.renderHelpMenu()}
            </div>
        );
    }

    private showInspire = () =>
    {
        const isMobileBrowser = browserUtils.isMobileBrowser();
        if ( !isMobileBrowser )
        {
            this.props.exitSearchMode();
            eventTracker.logInspireDisplayed();
            history.push( HOMEPAGE_URL );
        }
    }

    private showMyPosts = () =>
    {
        this.props.exitSearchMode();
        eventTracker.logProfileDisplayed();
        history.push( POSTS_PAGE_URL );
    }

    private showMyBrand = () =>
    {
        this.props.exitSearchMode();
        eventTracker.logMyBrandDisplayed();
        const isMobileBrowser = browserUtils.isMobileBrowser();
        if ( isMobileBrowser )
        {
            history.push( BRAND_SETTINGS_PAGE_URL );
        }
        else
        {
            history.push( BRAND_CREATIVE_PAGE_URL );
        }
    }

    private showMyAccount = () =>
    {
        this.props.exitSearchMode();
        eventTracker.logMyAccountDisplayed();
        history.push( USER_SETTINGS_URL );
    }

    private showReferAFriend = () =>
    {
        this.props.exitSearchMode();
        eventTracker.logReferAFriendDisplayedEvent();
        history.push( REFER_A_FRIEND_URL );
    }

    private renderNewNavContents()
    {
        const { currentPathname } = this.props;
        return <div className="headerBox homePage">
            {this.props.showHomeButton && <Button
                flat
                className={classNames( "homeTab clickable headerStackedButton newNavButton",
                    { inactive: currentPathname !== HOMEPAGE_URL } )}
                onClick={this.showInspire}
                iconEl={<HomeIcon/>}
            >Home</Button>}
            {this.props.showMyPostsButton && <Button
                flat
                className={classNames( "homeTab clickable headerStackedButton newNavButton",
                    { inactive: !startsWith( currentPathname, HOMEPAGE_MY_POSTS_URL ) } )}
                onClick={this.showMyPosts}
                iconEl={<MyPostsIcon/>}
            >My posts</Button>}
            {this.props.showMyBrandButton && <Button
                flat
                className={classNames( "homeTab clickable headerStackedButton newNavButton",
                    { inactive: !startsWith( currentPathname, HOMEPAGE_MY_BRAND_URL ) } )}
                onClick={this.showMyBrand}
                iconEl={<MyBrandIcon/>}
            >My brand</Button>}
            {this.props.showMyAccountButton && <Button
                flat
                className={classNames( "homeTab clickable headerStackedButton newNavButton",
                    { inactive: currentPathname !== USER_SETTINGS_URL } )}
                onClick={this.showMyAccount}
                iconEl={<MyAccountIcon inactive={currentPathname !== USER_SETTINGS_URL}/>}
            >My account</Button>
            }
        </div>;
    }

    private renderHelpMenu = () =>
    {
        return (<HelpMenu {...this.props}/>);
    }

    private renderCreateYourOwnButton = () =>
    {
        return (
            <Button
                key="createYourOwn"
                flat primary
                className={classNames( "ctaMenuButton createYourOwn", { showButton: this.props.shouldShowCreateYourOwnButton } )}
                onClick={this.props.handleCreateYourOwnButtonClick}
                iconEl={<PlusIcon/>}>
                Create your own
                <div className="headerButtonHoverStateOverlay"/>
            </Button>
        );
    }

    private renderStartSomethingNew = () =>
    {
        return (
            <Button
                key="startSomethingNew"
                flat primary
                className={classNames( "ctaMenuButton startSomethingNew",
                    { showButton: this.props.shouldShowStartSomethingNewButton } )}
                onClick={this.props.handleStartSomethingNewButtonClick}
                iconEl={<PlusIcon/>}>
                Start something new
            </Button>
        );
    }

    private getLogo()
    {
        return this.props.shouldShowCollabraUI ? CollabraPlusRiplLogo : RiplWordmark;
    }

    private getLogoAltText()
    {
        return this.props.shouldShowCollabraUI ? "Collabra+Ripl logo" : "Ripl wordmark";
    }
}
