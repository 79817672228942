import * as React from "react";
import { EngagementSocialNetwork } from "./EngagementSocialNetwork";
import { EMPTY_SYMBOL, ENGAGEMENT_ICONS, stringUtils } from "../helpers";
import { find, forEach } from "lodash";

export interface EngagementDetailProps
{
    engagementData: Engagements;
    className?: string;
}

interface EngagementDetailsState
{
    isHidden: boolean;
}

export class EngagementDetail extends React.PureComponent<EngagementDetailProps, EngagementDetailsState>
{
    constructor( props )
    {
        super( props );
        this.state = { isHidden: false };
    }

    public render()
    {
        let totalScoreString = EMPTY_SYMBOL;
        if ( this.props.engagementData && this.props.engagementData.total_score && this.props.engagementData.total_score !== 0 )
        {
            totalScoreString = stringUtils.abbreviateNumber( this.props.engagementData.total_score );
        }
        const isHidden = this.state.isHidden;
        const engagementDataArray = this.createSocialNetworkEngagements();
        return (
            <div className={this.determineClassName()} onClick={this.toggleEngagementDetail}>
                <div className="engagementTotalScore">{totalScoreString}</div>
                <div className="engagementDetailAnchor">
                    {
                        (isHidden && engagementDataArray.length !== 0) &&
                        <div className="engagementDetailFlyout">
                            <div className="triangle">▲</div>
                            {
                                engagementDataArray.map( ( engagementSection, index ) =>
                                {
                                    return (
                                        <EngagementSocialNetwork
                                            key={index}
                                            engagementSection={engagementSection}
                                        />
                                    );
                                } )
                            }
                        </div>
                    }
                </div>
            </div>
        );
    }

    private determineClassName()
    {
        if ( this.props.className )
        {
            return `engagementDetail ${this.props.className}`;
        }
        return "engagementDetail";
    }

    private createSocialNetworkEngagements()
    {
        const engagementDataArray = [];
        forEach( this.props.engagementData, ( engagements, fieldName ) =>
        {
            this.addEngagement( engagementDataArray, engagements, fieldName );
        } );
        return engagementDataArray;
    }

    private addEngagement( engagementDataArray, engagements, fieldName )
    {
        const socialNetworkName = this.getSocialNetworkName( fieldName );
        if ( fieldName !== "total_score" && fieldName !== "count" )
        {
            let socialNetwork: any = find( engagementDataArray, { socialName: socialNetworkName } );
            if ( !socialNetwork )
            {
                socialNetwork = this.createNewEngagementData( engagementDataArray, socialNetworkName );
                engagementDataArray.push( socialNetwork );
            }
            socialNetwork.engagements.push(
                this.createSocialEngagements( fieldName, engagements ),
            );
        }
    }

    private getSocialNetworkName( fieldName )
    {
        return fieldName.slice( 0, fieldName.indexOf( "_" ) );
    }

    private createNewEngagementData( engagementDataArray, socialCategory )
    {
        return {
            socialIcon: ENGAGEMENT_ICONS[socialCategory],
            socialName: socialCategory,
            engagements: [],
        };
    }

    private createSocialEngagements( key, value )
    {
        return {
            image: ENGAGEMENT_ICONS[key],
            count: value,
        };
    }

    private toggleEngagementDetail = () =>
    {
        this.setState( { isHidden: !this.state.isHidden } );
    }
}
