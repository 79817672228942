import * as React from "react";
import { STRIPE_PLAN_TYPE_MONTHLY } from "../_types/api";
import { doesSubscriptionPlanHaveATrial } from "../ducks/pricing";
import { DEFAULT_TRIAL_DAYS, stringUtils, SUBSCRIPTION_TERMS_URL, USD_CODE } from "../helpers";
import { addDays, format } from "date-fns";
import { Link } from "react-router-dom";

interface SelectedStripePlan
{
    selectedPlan: StripePlanData;
    selectedCurrencyCode: CurrencyCode;
}

export const PaymentFormFinePrint: React.FC<SelectedStripePlan> = ( props ) =>
{
    return (
        <div className="finePrint">
            {getSubscriptionExplanationText( props.selectedPlan, props.selectedCurrencyCode )}
        </div>
    );
};

function getSubscriptionExplanationText( selectedPlan: StripePlanData, selectedCurrencyCode: CurrencyCode )
{
    const chargeAmountText = getChargeAmountText( selectedPlan, false );
    const trialPeriodDays = selectedPlan && selectedPlan.trial_period_days || DEFAULT_TRIAL_DAYS;

    const exchangeRateText = getExchangeRateText( selectedCurrencyCode );
    const chargeText = "Your account will be charged " + chargeAmountText + " (plus tax) " + exchangeRateText + "on ";
    const chargeDateText = getChargeDateText( selectedPlan );
    const paymentRateText = getPaymentRateText( selectedPlan );
    const reminderText = "For information on cancellation go to ";

    let trialText = ". ";
    if ( doesSubscriptionPlanHaveATrial( selectedPlan ) )
    {
        trialText = " after your " + trialPeriodDays + " day trial. ";
    }

    return (
        <div className="finePrintExplanationText">
            {chargeText + chargeDateText + trialText + paymentRateText + reminderText}
            <Link to={SUBSCRIPTION_TERMS_URL} target="_blank">Subscription Terms</Link>.
        </div>
    );
}

function getChargeDate( selectedPlan: StripePlanData )
{
    let date = new Date();
    if ( doesSubscriptionPlanHaveATrial( selectedPlan ) )
    {
        date = addDays( date, selectedPlan.trial_period_days );
    }
    return date;
}

function getChargeDateText( selectedPlan: StripePlanData )
{
    return format( getChargeDate( selectedPlan ), "MMMM Do" );
}

function getExchangeRateText( selectedCurrencyCode: CurrencyCode )
{
    if ( selectedCurrencyCode !== USD_CODE )
    {
        return "based on the equivalent exchange rate ";
    }
    return "";
}

function getPaymentRateText( selectedPlan: StripePlanData )
{
    const originalAmount = getChargeAmountText( selectedPlan, true );
    const date = getChargeDate( selectedPlan );
    let dateText;
    let subscriptionPeriod;
    if ( selectedPlan.type === STRIPE_PLAN_TYPE_MONTHLY )
    {
        dateText = `the ${format( date, "Do" )}`;
        subscriptionPeriod = "month";
    }
    else
    {
        dateText = format( date, "MMMM Do" );
        subscriptionPeriod = "year";
    }

    return ` Subsequent renewals will recur at ${originalAmount} on ${dateText} of each ${subscriptionPeriod} until you cancel. `;
}

function getChargeAmountText( selectedPlan: StripePlanData, useOriginalAmount: boolean )
{
    let amount = 0;
    let currency = "USD";
    if ( selectedPlan )
    {
        amount = useOriginalAmount ? selectedPlan.originalAmount : selectedPlan.amount;
        currency = selectedPlan.currency && selectedPlan.currency.toUpperCase();
    }
    const chargeAmountText = stringUtils.getUSDZeroPaddedPrice( amount ) + "\u00A0" + currency;
    return chargeAmountText;
}
