import * as React from "react";
import { map } from "lodash";
import { BrandLogo } from "../_types";
import ManageBrandLogoContainer from "./settingsPages/containers/ManageBrandLogo.container";

export interface ConfirmDeleteLogosDialogProps
{
    selectedLogos: BrandLogo[];
}

export class ConfirmDeleteLogosDialog extends React.PureComponent<ConfirmDeleteLogosDialogProps>
{
    public render()
    {
        return (
            <div className="manageLogosDialogContent">
                <div className="logosWrap">
                    {
                        map( this.props.selectedLogos, ( logo: BrandLogo ) =>
                            <ManageBrandLogoContainer key={logo.id}
                                                      brandLogo={logo}
                                                      isSelected={true}
                                                      onLogoClick={null}/> )
                    }
                </div>
            </div>
        );
    }

}
