import { ActionCreator } from "../helpers";
import { createAction } from "redux-actions";
import { SurveyAPIData } from "../_types/api/survey";

const actionCreator = new ActionCreator( "SURVEY" );

const surveyActionsTypes = {
    surveyLoad: actionCreator.generateTypes( "LOAD" ),
};

export const surveyActions = {
    surveyLoadSucceeded: createAction<SurveyAPIData>( surveyActionsTypes.surveyLoad.success ),
    surveyLoadFailed: createAction( surveyActionsTypes.surveyLoad.failure ),
};
