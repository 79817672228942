import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../_types";
import { InstagramFooterNeedHelpButton, InstagramFooterNeedHelpButtonDispatchProps } from "../InstagramFooterNeedMoreHelpButton";
import { URLS } from "../../helpers";

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): InstagramFooterNeedHelpButtonDispatchProps =>
{
    return {
        onNeedMoreHelpClicked: async () =>
        {
            window.open( URLS.InstagramHelp, "_blank" );
        },
    };
};

export default connect(
    () => ({}),
    mapDispatchToProps,
)( InstagramFooterNeedHelpButton );
