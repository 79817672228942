import { connect } from "react-redux";
import { Dispatch } from "redux";
import { mixModelActions } from "../../../actions";
import { AspectRatioPicker, AspectRatioPickerDispatchProps, AspectRatioPickerProps } from "../AspectRatioPicker";
import { getDesignAspectRatio, getSelectedDesign } from "../../../ducks/mixModel";
import { getAllAspectRatioSupported, SQUARE_ASPECT_RATIO } from "../../../helpers";
import { AspectRatio, StoreState } from "../../../_types";

const mapStateToProps = ( storeState: StoreState ): AspectRatioPickerProps =>
{
    const selectedDesign = getSelectedDesign( storeState );
    const aspectRatioSupported = determineSupportedAspectRatio( selectedDesign );
    return {
        aspectRatio: getDesignAspectRatio( storeState ),
        aspectRatioSupported,
    };
};

function determineSupportedAspectRatio( selectedDesign )
{
    if ( selectedDesign )
    {
        return selectedDesign.aspect_ratios_supported || [SQUARE_ASPECT_RATIO];
    }

    return getAllAspectRatioSupported();
}

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): AspectRatioPickerDispatchProps =>
{
    return {
        onAspectRatioSelected: ( aspectRatio: AspectRatio ) =>
        {
            dispatch( mixModelActions.designAspectRatioSelected( aspectRatio ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( AspectRatioPicker );
