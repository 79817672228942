import { DialogControlProps, StoreState } from "../../_types";
import { pricingTiersUIHelper, RIPL_TIER_PREMIUM } from "../../helpers";
import { SubscriptionConfirmationDialogContent, SubscriptionConfirmationDialogContentProps } from "../SubscriptionConfirmationDialogContent";
import { store } from "../../store";
import { connect } from "react-redux";
import { getSelectedTier } from "../../ducks/pricing";

interface SubscriptionConfirmationDialogContentContainerProps extends DialogControlProps
{
    showGradientVersion: boolean;
}

const mapStateToProps = ( storeState: StoreState,
                          ownProps: SubscriptionConfirmationDialogContentContainerProps ): SubscriptionConfirmationDialogContentProps =>
{
    if ( ownProps.showGradientVersion )
    {
        const selectedTier = getSelectedTier( store.getState() );
        const tierDisplayString = pricingTiersUIHelper.getTierDisplayText( selectedTier );
        const message = "Your payment has been processed and you are now a " + tierDisplayString +
                        " Plan Subscriber. Let’s create stunning posts for your business.";
        const shouldShowCrown = selectedTier === RIPL_TIER_PREMIUM;

        return {
            ...ownProps,
            title: "Success!",
            description: message,
            showCrown: shouldShowCrown,
        };
    }

    return {
        ...ownProps,
        title: "Success!",
        description: "Your payment has been processed. Let’s create stunning posts for your business.",
    };
};

export default connect(
    mapStateToProps,
)( SubscriptionConfirmationDialogContent );
