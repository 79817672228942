import * as React from "react";
import { BusinessType, UserBusiness } from "../../_types";

export interface InspirationHeaderProps
{
    currentBusiness: UserBusiness;
    businessFilter: BusinessType;
}

export class InspirationHeader extends React.Component<InspirationHeaderProps>
{
    public render()
    {
        return <h2 className="inspireHeader">{this.getInspireTitle()} Templates</h2>;
    }

    private getInspireTitle = () =>
    {
        if (this.props.businessFilter)
        {
            return this.props.businessFilter.name;
        }
        else { return "Ripl"; }
    }
}
