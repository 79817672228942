import * as React from "react";
import * as Raven from "raven-js";
import { CUSTOMER_SUPPORT_UNBREAKING, ENABLE_ERROR_REPORTING, NEED_HELP_SETTINGS_URL } from "../helpers";

export interface ErrorDialogProps
{
    errorMessage?: string | React.ReactElement<any>;
    showContactSupportUI?: boolean;
    showReportToSentryUI?: boolean;
    kbArticleUrl?: string;
    shouldShowCollabraUI: boolean;
}

export class ErrorDialog extends React.PureComponent<ErrorDialogProps>
{
    public render()
    {
        return (
            <div className="errorDialog">
                {this.props.errorMessage}
                {this.props.showContactSupportUI && !this.props.shouldShowCollabraUI && this.createContactSupportContent()}
                {this.props.showReportToSentryUI && this.createReportToSentryContent()}
                {this.props.kbArticleUrl && this.createKbArticleContent()}
            </div>
        );
    }

    private createContactSupportContent = () =>
    {
        return (
            <div className="contactSupport">
                If the problem continues, please contact <a className="standardLink" href={NEED_HELP_SETTINGS_URL}
                                                            target="_blank">{CUSTOMER_SUPPORT_UNBREAKING}</a>.
            </div>
        );
    }

    private createKbArticleContent = () =>
    {
        return (
            <div className="contactSupport">
                You can learn more about how to resolve this <a className="standardLink" href={this.props.kbArticleUrl} target="_blank">here</a>.
            </div>
        );
    }
    private createReportToSentryContent = () =>
    {
        return (
            <div className="contactSupport">
                Please report your error <span className="standardLink" onClick={this.showReportDialog}>here</span>.
            </div>
        );
    }

    private showReportDialog = () =>
    {
        if ( ENABLE_ERROR_REPORTING )
        {
            if ( Raven.lastEventId() )
            {
                Raven.showReportDialog();
            }
        }
    }
}
