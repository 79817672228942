import * as React from "react";

export const BinocularsIcon: React.FC<{}> = () =>
{
    return (
        /* tslint:disable:max-line-length */
        // @ts-ignore
        <svg className="binocularsIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 41">
            <path d="M64 27.2C64 27.2 63.8 17 55.5 5.99998C55.5 5.99998 48.5 1.69998 42.7 5.99998L34.1 4.39998C33 4.19998 32 4.19998 30.9 4.39998L22.3 5.99998C16.5 1.69998 9.5 5.99998 9.5 5.99998C1.2 17 1 27.2 1 27.2" stroke="white" strokeMiterlimit="10"/>
            <path d="M26.5 25.2999L31.8 20.4999C32.2 20.1999 32.8 20.1999 33.1 20.4999L38.4 25.2999" stroke="white" strokeMiterlimit="10"/>
            <path d="M13.8 39.9999C20.8692 39.9999 26.6 34.2691 26.6 27.1999C26.6 20.1307 20.8692 14.3999 13.8 14.3999C6.73076 14.3999 1 20.1307 1 27.1999C1 34.2691 6.73076 39.9999 13.8 39.9999Z" stroke="white" strokeMiterlimit="10"/>
            <path d="M13.8 36.9999C19.2124 36.9999 23.6 32.6123 23.6 27.1999C23.6 21.7875 19.2124 17.3999 13.8 17.3999C8.38761 17.3999 4 21.7875 4 27.1999C4 32.6123 8.38761 36.9999 13.8 36.9999Z" stroke="white" strokeMiterlimit="10"/>
            <path d="M13.8 33.1999C18.1631 33.1999 21.7 29.663 21.7 25.2999C21.7 20.9369 18.1631 17.3999 13.8 17.3999C9.43695 17.3999 5.9 20.9369 5.9 25.2999C5.9 29.663 9.43695 33.1999 13.8 33.1999Z" stroke="white" strokeMiterlimit="10"/>
            <path d="M51.2 39.9999C58.2692 39.9999 64 34.2691 64 27.1999C64 20.1307 58.2692 14.3999 51.2 14.3999C44.1308 14.3999 38.4 20.1307 38.4 27.1999C38.4 34.2691 44.1308 39.9999 51.2 39.9999Z" stroke="white" strokeMiterlimit="10"/>
            <path d="M51.2 36.9999C56.6124 36.9999 61 32.6123 61 27.1999C61 21.7875 56.6124 17.3999 51.2 17.3999C45.7876 17.3999 41.4 21.7875 41.4 27.1999C41.4 32.6123 45.7876 36.9999 51.2 36.9999Z" stroke="white" strokeMiterlimit="10"/>
            <path d="M51.2 33.1999C55.5631 33.1999 59.1 29.663 59.1 25.2999C59.1 20.9369 55.5631 17.3999 51.2 17.3999C46.837 17.3999 43.3 20.9369 43.3 25.2999C43.3 29.663 46.837 33.1999 51.2 33.1999Z" stroke="white" strokeMiterlimit="10"/>
            <path d="M32.5 20.0999C34.4882 20.0999 36.1 18.4881 36.1 16.4999C36.1 14.5117 34.4882 12.8999 32.5 12.8999C30.5118 12.8999 28.9 14.5117 28.9 16.4999C28.9 18.4881 30.5118 20.0999 32.5 20.0999Z" stroke="white" strokeMiterlimit="10"/>
            <path d="M23.5 5.8C22.3 3 19.5 1 16.3 1C12.5 1 9.4 3.6 8.6 7.2" stroke="white" strokeMiterlimit="10"/>
            <path d="M41.5 5.8C42.7 3 45.5 1 48.7 1C52.5 1 55.6 3.6 56.4 7.2" stroke="white" strokeMiterlimit="10"/>
            <path d="M32.5 12.6001V8.1001" stroke="white" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M28.9 15.7001C26.3 11.5001 21.4 8.90005 16.2 9.60005" stroke="white" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M36.1 15.7001C38.7 11.5001 43.6 8.90005 48.8 9.60005" stroke="white" strokeMiterlimit="10" strokeLinecap="round"/>
        </svg>
    );
};
