import { TextPickerCaptionData } from "../../../_types";
import { includes, map } from "lodash";
import CustomizeTextInputFieldContainer from "../containers/CustomizeTextInputField.container";
import { CAPTION_TEXT_FIELD_NAME } from "../../../_types/api";
import * as React from "react";

interface TextPickerExtraCaptionsProps
{
    activeCaptionControls: string[];
    extraCaptions: TextPickerCaptionData[];
    shouldSupportExtraCaptions: boolean;
    hasClearedStylizedTextButtonCoachMark: boolean;
    changeTextPropertiesSubPanelCaptionData( captionData: TextPickerCaptionData );
}

export function TextPickerExtraCaptions( props: TextPickerExtraCaptionsProps )
{
    const {
        activeCaptionControls,
        extraCaptions,
        shouldSupportExtraCaptions,
        hasClearedStylizedTextButtonCoachMark,
    } = props;

    const onChangeStylizeTextClicked = ( captionData: TextPickerCaptionData ) =>
    {
        if ( captionData.id )
        {
            props.changeTextPropertiesSubPanelCaptionData( captionData );
        }
    };

    if ( shouldSupportExtraCaptions )
    {
        const captions = map( extraCaptions, ( caption, index ) =>
        {
            const isFirstExtraCaption = index === 0;
            const label = isFirstExtraCaption ? "Additional text boxes" : null;
            if ( includes( activeCaptionControls, caption.id ) )
            {
                return (
                    <div key={caption.id} className="captionTextFieldWrap">
                        <CustomizeTextInputFieldContainer
                            identifier={caption.id}
                            value={caption.value}
                            styleId={caption.styleId}
                            label={label}
                            placeholder="Add your own text"
                            type={CAPTION_TEXT_FIELD_NAME}
                            supportsDeletion={true}
                            shouldEnableStylizedText={true}
                            showStylizedTextCoachMark={isFirstExtraCaption
                                                       && !hasClearedStylizedTextButtonCoachMark}
                            onChangeStylizeTextClicked={onChangeStylizeTextClicked}/>
                    </div>
                );
            }
        } );

        return <>{captions}</>;
    }
    else
    {

        return null;
    }
}