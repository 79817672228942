import { ActionCreator } from "../helpers";
import { createAction } from "redux-actions";
import { SocialCalendarState } from "../_types";

const actionCreator = new ActionCreator( "SOCIAL_CALENDAR" );

const socialCalendarActionsTypes = {
    socialCalendarLoad: actionCreator.generateTypes( "LOAD" ),
};

export const socialCalendarActions = {
    socialCalendarLoadSucceeded: createAction<SocialCalendarState>( socialCalendarActionsTypes.socialCalendarLoad.success ),
    socialCalendarLoadFailed: createAction( socialCalendarActionsTypes.socialCalendarLoad.failure ),
};
