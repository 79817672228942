import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState, UserBusiness, UserState } from "../../../_types";
import { ExtrasToggles, ExtrasTogglesDispatchProps, ExtrasTogglesProps } from "../ExtrasToggles";
import { userBusinessServices, userServices } from "../../../services";
import { getEditedBusinessInfo } from "../../../ducks/ui";

const mapStateToProps = ( storeState: StoreState ): ExtrasTogglesProps =>
{
    return {
        editedBusinessInfo: getEditedBusinessInfo( storeState ),
        user: storeState.user,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): ExtrasTogglesDispatchProps =>
{
    return {
        handleUpdateUser: ( data: Partial<UserState> ) =>
        {
            dispatch( userServices.update( data ) );
        },
        handleUpdateBusiness: ( data: Partial<UserBusiness> ) =>
        {
            dispatch( userBusinessServices.update( data ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( ExtrasToggles );
