import * as React from "react";
import * as EpidemicSoundLogo from "../../../assets/img/epidemicSoundLogo.svg";
import classNames from "classnames";

export interface MusicByEpidemicSoundProps
{
    children: React.ReactNode;
    class?: string;
}

export function MusicByEpidemicSound( props: MusicByEpidemicSoundProps )
{
    return (
        <div className={classNames( "musicByEpidemicSound", props.class )}>
            <div className="intro">
                <span className="musicBy">Music by</span>
                <div className="logoWrap">
                    <img className="logo" src={EpidemicSoundLogo} alt="Epidemic Sound logo"/>
                </div>
            </div>
            {props.children}
        </div>
    );
}
