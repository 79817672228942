import * as React from "react";
import { generateFontDataForBrandSlideFont } from "../../helpers";
import BrandSettingsFontPickerControl from "../settingsPages/containers/BrandSettingsFontPickerControl.container";
import { UserBusiness } from "../../_types";
import {
    BRAND_SLIDE_FONT_ONE_CSS_URL_FIELD,
    BRAND_SLIDE_FONT_ONE_FIELD,
    BRAND_SLIDE_FONT_ONE_URL_FIELD,
    BRAND_SLIDE_FONT_TWO_CSS_URL_FIELD,
    BRAND_SLIDE_FONT_TWO_FIELD,
    BRAND_SLIDE_FONT_TWO_URL_FIELD,
    BUSINESS_BRAND_SLIDE_FONT_ONE_TYPE,
    BUSINESS_BRAND_SLIDE_FONT_TWO_TYPE,
} from "../../_types/api";

export interface BrandSlideFontPickerProps
{
    businessData: Partial<UserBusiness>;
}

export interface BrandSlideFontPickerDispatchProps
{
    onUpdatedFont?: () => void;
}

export class BrandSlideFontPicker extends React.PureComponent<BrandSlideFontPickerProps & BrandSlideFontPickerDispatchProps>
{
    public render()
    {
        const brandSlideFontOneFontData = generateFontDataForBrandSlideFont( this.props.businessData, BRAND_SLIDE_FONT_ONE_FIELD,
            BRAND_SLIDE_FONT_ONE_CSS_URL_FIELD );
        const brandSlideFontTwoFontData = generateFontDataForBrandSlideFont( this.props.businessData, BRAND_SLIDE_FONT_TWO_FIELD,
            BRAND_SLIDE_FONT_TWO_CSS_URL_FIELD );

        return (
            <div className="brandSlideFontPicker commonFontPickerContainer">
                <div className="brandFontPicker">
                    <BrandSettingsFontPickerControl
                        key={BRAND_SLIDE_FONT_ONE_FIELD}
                        fontField={BRAND_SLIDE_FONT_ONE_FIELD}
                        fontUrlField={BRAND_SLIDE_FONT_ONE_URL_FIELD}
                        fontCssUrlField={BRAND_SLIDE_FONT_ONE_CSS_URL_FIELD}
                        customFont={brandSlideFontOneFontData}
                        customLabel="Font 1"
                        mediaType={BUSINESS_BRAND_SLIDE_FONT_ONE_TYPE}
                        onUpdatedFont={this.props.onUpdatedFont}/>

                    <BrandSettingsFontPickerControl
                        key={BRAND_SLIDE_FONT_TWO_FIELD}
                        fontField={BRAND_SLIDE_FONT_TWO_FIELD}
                        fontUrlField={BRAND_SLIDE_FONT_TWO_URL_FIELD}
                        fontCssUrlField={BRAND_SLIDE_FONT_TWO_CSS_URL_FIELD}
                        customFont={brandSlideFontTwoFontData}
                        customLabel="Font 2"
                        mediaType={BUSINESS_BRAND_SLIDE_FONT_TWO_TYPE}
                        onUpdatedFont={this.props.onUpdatedFont}/>
                </div>
            </div>
        );
    }
}
