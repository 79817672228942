import { connect } from "react-redux";
import { Dispatch } from "redux";
import { store } from "../../../store";
import { mixModelActions, uiActions } from "../../../actions";
import { getControlsConfigsLookup } from "../../../ducks/designs";
import { getCurrentBusiness } from "../../../ducks/userBusiness";
import { getCustomizableCanvas, getFocusElement } from "../../../ducks/ui";
import { CAPTION_TEXT_FIELD_NAME, COACH_MARK_NAME_KEY_STYLIZED_TEXT, PRIMARY_TEXT_FIELD_NAME, SECONDARY_TEXT_FIELD_NAME } from "../../../_types/api";
import { StoreState, TemplateInputData } from "../../../_types";
import { CustomizeTextInputField, CustomizeTextInputFieldDispatchProps, CustomizeTextInputFieldProps } from "../CustomizeTextInputField";
import { modalServices } from "../../../services";
import { apptimizeVariables, eventTracker, KEY_CODES, LightboxDialogIdentifierForKey } from "../../../helpers";
import { isCurrentDesignFoundation } from "../../../ducks/mixModel";

const mapStateToProps = ( storeState: StoreState, ownProps: CustomizeTextInputFieldProps ): CustomizeTextInputFieldProps =>
{
    return {
        ...ownProps,
        focusElement: getFocusElement( storeState ),
        shouldAllowCopying: apptimizeVariables.shouldEnablePresetText() && isCurrentDesignFoundation( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): CustomizeTextInputFieldDispatchProps =>
{
    return {
        clearStylizedTextButtonCoachMark: () =>
        {
            dispatch( uiActions.clearCoachMark( COACH_MARK_NAME_KEY_STYLIZED_TEXT ) );
        },
        onFieldChanged: ( fieldName, value, id? ) =>
        {
            const state = store.getState();
            const canvas = getCustomizableCanvas( state );
            if ( canvas )
            {
                switch ( fieldName )
                {
                    case PRIMARY_TEXT_FIELD_NAME:
                        updateTemplateData( {
                            originalHeadlineText: value,
                        }, dispatch );
                        break;
                    case SECONDARY_TEXT_FIELD_NAME:
                        updateTemplateData( {
                            originalText: value,
                        }, dispatch );
                        break;
                    case CAPTION_TEXT_FIELD_NAME:
                        dispatch( uiActions.designCaptionTextUpdated( [{ id, value }] ) );
                        canvas.tellDesignToSaveCaption( { id, text: value } );
                        store.dispatch( uiActions.updateFocusElement( id ) );
                        break;
                }
            }
        },
        onFocus: ( id: string ) =>
        {
            dispatch( uiActions.updateFocusElement( id ) );

            const state = store.getState();
            const canvas = getCustomizableCanvas( state );
            if ( canvas )
            {
                canvas.tellDesignToSelectItem( id );
            }
        },
        onBlur: ( id: string ) =>
        {
            dispatch( uiActions.updateFocusElement( null ) );
        },
        onRemoveTextBoxClicked: ( captionId: string, value: string ) =>
        {
            if ( value && value.trim() )
            {
                dispatch( modalServices.openLightbox( {
                    identifierForKey: LightboxDialogIdentifierForKey.CONFIRM_DELETE_EXTRA_CAPTION,
                    title: "Delete this text box?",
                    content: null,
                    hideAlternateAction: false,
                    hideCancel: true,
                    showCancelX: true,
                    alternateActionLabel: "No",
                    confirmLabel: "Yes",
                    width: 450,
                    enableCustomKeyboardShortcuts: true,
                    onKeyDown: ( e, lightboxSuccessHandler ) =>
                    {
                        if ( e.code === KEY_CODES.BACKSPACE ||
                             e.code === KEY_CODES.DELETE ||
                             e.code === KEY_CODES.ENTER )
                        {
                            lightboxSuccessHandler();
                        }
                    },
                    onSuccess: async () =>
                    {
                        tellDesignToRemoveExtraCaption( captionId ).then( onExtraCaptionRemoved );
                    },
                } ) );
            }
            else
            {
                tellDesignToRemoveExtraCaption( captionId ).then( onExtraCaptionRemoved );
            }
        },
        onCopyTextBoxClicked: ( captionId: string ) =>
        {
            const canvas = getCustomizableCanvas( store.getState() );
            if ( canvas )
            {
                canvas.tellDesignToDuplicateExtraCaption( captionId );
                return canvas.tellDesignToSaveSettings();
            }
        },
    };
};

async function tellDesignToRemoveExtraCaption( captionId: string )
{
    const state = store.getState();
    const canvas = getCustomizableCanvas( state );
    if ( canvas )
    {
        canvas.tellDesignToRemoveExtraCaption( captionId );
        return canvas.tellDesignToSaveSettings();
    }
    return Promise.reject();
}

function onExtraCaptionRemoved()
{
    eventTracker.logExtraCaptionRemovedEvent();
    tellDesignToRequestTextObjectsForActivePage();
}

function tellDesignToRequestTextObjectsForActivePage()
{
    const state = store.getState();
    const canvas = getCustomizableCanvas( state );
    if ( canvas )
    {
        canvas.tellDesignToRequestTextObjectsForActivePage();
    }
}

function updateTemplateData( templateInputData: Partial<TemplateInputData>, dispatch: Dispatch<StoreState> )
{
    const state = store.getState();
    const currentBusiness = getCurrentBusiness( state );
    const controlsConfigsLookup = getControlsConfigsLookup( store.getState() );
    if ( getFocusElement( state ) )
    {
        dispatch( uiActions.updateFocusElement( undefined ) );
    }
    dispatch( mixModelActions.templateInputUpdated( templateInputData ) );
    dispatch( mixModelActions.computeControlData( {
        currentBusiness,
        controlsConfigsLookup,
    } ) );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( CustomizeTextInputField );
