import { StoreMigration, UIState } from "../../_types";

export const uiMigrations: StoreMigration<UIState> = {
    0: ( state ) =>
    {
        // sample migration
        return state;
    },
    4: ( state ) =>
    {
        // @ts-ignore facebookCreateAdsPageData has been removed from the codebase; only the migration remains
        const { facebookCreateAdsPageData, ...remainingProps } = state;
        if ( facebookCreateAdsPageData )
        {
            const { adTargeting, ...remainingFacebookCreateAdsPageData } = facebookCreateAdsPageData;
            // @ts-ignore
            const { audienceLocationInfo, ...remainingAdTargeting } = adTargeting;
            const audienceLocations = audienceLocationInfo ? [audienceLocationInfo] : [];
            return {
                ...remainingProps,
                facebookCreateAdsPageData: {
                    ...remainingFacebookCreateAdsPageData,
                    adTargeting: {
                        ...remainingAdTargeting,
                        audienceLocations,
                    },
                },
            };
        }
        else
        {
            return {
                ...remainingProps,
            };
        }
    },
};
