import * as React from "react";
import { ColorValue, TemplateInputEndCardData } from "../../_types";
import { ColorBoxEditable } from "../settingsPages/ColorBoxEditable";

export interface BrandSlideColorPickerOptionProps
{
    title: string;
    colorField: keyof TemplateInputEndCardData;
    endCardData: TemplateInputEndCardData;
    openDialog: ( colorKey: keyof TemplateInputEndCardData, startValue: ColorValue ) => void;
}

export class BrandSlideColorPickerOption extends React.PureComponent<BrandSlideColorPickerOptionProps>
{
    public render()
    {
        return (
            <ColorBoxEditable title={this.props.title}
                              color={this.getColorValueFromProps()}
                              handleOpen={this.handleOpen}
                              handleColorBoxClicked={this.handleOpen}/>);
    }

    private getColorValueFromProps = () =>
    {
        const { colorField, endCardData } = this.props;
        return endCardData && (endCardData[colorField] as ColorValue);
    }

    private handleOpen = () =>
    {
        this.props.openDialog( this.props.colorField, this.getColorValueFromProps() );
    }
}
