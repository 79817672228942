import * as React from "react";

export const BackgroundOverlay: React.FC<{}> = ( props ) =>
{
    return <div className="backgroundOverlay">
        <div className="background">
            <div className="darkness">&nbsp;</div>
        </div>
        <div className="overlay">
            {props.children}
        </div>
    </div>;
};
