import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../_types";
import { userBusinessServices } from "../../../services";
import { CUSTOMIZE_PAGE, eventTracker } from "../../../helpers";
import { LogoSwitcherPanel, LogoSwitcherPanelDispatchProps, LogoSwitcherPanelProps } from "../LogoSwitcherPanel";
import { getEditedBusinessInfo } from "../../../ducks/ui";
import { getCurrentBusiness } from "../../../ducks/userBusiness";
import { mixModelActions, uiActions } from "../../../actions";
import { getHideWatermark } from "../../../ducks/mixModel";

const mapStateToProps = ( storeState: StoreState ): LogoSwitcherPanelProps =>
{
    return {
        showBrandLogo: !getHideWatermark( storeState ),
        showLogoToggleText: "Show logo",
        brandLogos: getEditedBusinessInfo( storeState ).logos,
        currentBusiness: getCurrentBusiness( storeState ),
        source: CUSTOMIZE_PAGE,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): LogoSwitcherPanelDispatchProps =>
{
    return {
        refreshBrandLogos()
        {
            dispatch( uiActions.clearBusinessInfo() );
            dispatch( userBusinessServices.loadBrandLogos() );
        },
        handleShowBrandLogo: ( enabled: boolean ) =>
        {
            eventTracker.logLogoToggleSwitched( enabled, CUSTOMIZE_PAGE );

            if ( enabled )
            {
                eventTracker.logCustomizeShowLogoButtonClicked();
                dispatch( mixModelActions.watermarkEnabled() );
            }
            else
            {
                eventTracker.logCustomizeHideLogoButtonClicked();
                dispatch( mixModelActions.watermarkRemoved() );
            }
        },
        onBrandLogoSwitched()
        {
            dispatch( mixModelActions.logoOverrideClear() );
            dispatch( mixModelActions.watermarkEnabled() );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( LogoSwitcherPanel );
