import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../_types";
import { ColorControlPicker, ColorControlPickerDispatchProps, ColorControlPickerProps } from "../../ColorControlPicker";
import { getCurrentBusiness } from "../../../ducks/userBusiness";
import { getEditorControl, getEditorControlValue } from "../../../ducks/ui";
import { mixModelActions } from "../../../actions";

const mapStateToProps = ( storeState: StoreState ): ColorControlPickerProps =>
{
    return {
        currentSelectedHexValue: getEditorControlValue( storeState ),
        control: getEditorControl( storeState ),
        currentBusiness: getCurrentBusiness( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): ColorControlPickerDispatchProps =>
{
    return {
        applyHexColorToControl: ( controlId: string, colorHex: string ) =>
        {
            dispatch( mixModelActions.controlDataUpdated( { [controlId]: colorHex } ) );
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( ColorControlPicker );
