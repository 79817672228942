import { StoreState } from "../../_types";
import { CurrencySelector, CurrencySelectorDispatchProps, CurrencySelectorProps } from "../CurrencySelector";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { userActions } from "../../actions";
import { getUserCurrencyCode } from "../../ducks/user";
import { eventTracker } from "../../helpers";

const mapStateToProps = ( storeState: StoreState ): CurrencySelectorProps =>
{
    return {
        selectedCurrencyCode: getUserCurrencyCode( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): CurrencySelectorDispatchProps =>
{
    return {
        logCurrencyInitialized( currencyCode: CurrencyCode )
        {
            eventTracker.logCurrencyInitialized( currencyCode );
        },
        currencySelected( currencyCode: CurrencyCode )
        {
            eventTracker.logCurrencyChanged( currencyCode );
            dispatch( userActions.setCurrencyCode( currencyCode ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( CurrencySelector );
