import { StoreState } from "../../_types";
import { connect } from "react-redux";
import { PriceBlock, PriceBlockProps } from "../PriceBlock";
import { getUserCurrencyCode } from "../../ducks/user";
import { getCurrentlySelectedExchangeRate, getExchangeRateData } from "../../ducks/pricing";

const mapStateToProps = ( storeState: StoreState, ownProps: PriceBlockProps ): PriceBlockProps =>
{
    return {
        ...ownProps,
        currencyCode: getUserCurrencyCode( storeState ),
        currentExchangeRate: getCurrentlySelectedExchangeRate( storeState ),
        shouldShowExchangeRates: !!getExchangeRateData( storeState ),
    };
};

export default connect(
    mapStateToProps,
    () => ({}),
)( PriceBlock );
