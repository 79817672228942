import { ActionCreator } from "../helpers";
import { createAction } from "redux-actions";
import { CollectionData } from "../_types";

const actionCreator = new ActionCreator( "COLLECTION" );

const collectionActionsTypes = {
    collectionLoad: actionCreator.generateTypes( "LOAD" ),
    collection: {
        cleared: actionCreator.generateType( "RESULTS", "CLEARED" ),
    },
    rowIndex: {
        updated: actionCreator.generateType( "ROW_INDEX", "UPDATED" ),
    },
};

export const collectionActions = {
    collectionLoadSucceeded: createAction<CollectionData>( collectionActionsTypes.collectionLoad.success ),
    collectionLoadFailed: createAction( collectionActionsTypes.collectionLoad.failure ),
    collectionCleared: createAction( collectionActionsTypes.collection.cleared ),
    collectionRowIndexUpdated: createAction<number>( collectionActionsTypes.rowIndex.updated ),
};
