import { store } from "../store";
import { getCurrentBusiness } from "../ducks/userBusiness";
import { userBusinessServices, userServices } from "./";
import { Dispatch } from "redux";
import { StoreState } from "../_types";
import { isBrandSlideTabSelected } from "../ducks/ui";
import { mixModelActions } from "../actions";

export const logoServices = {
    setEnabled,
};

function setEnabled( enabled: boolean )
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        const storeState = store.getState();
        const currentBusiness = getCurrentBusiness( storeState );
        if ( currentBusiness )
        {
            dispatch( userBusinessServices.update( { show_business_logo_flag: enabled } ) );
        }
        else
        {
            dispatch( userServices.update( { show_logo_flag: enabled } ) );
        }

        if ( isBrandSlideTabSelected( storeState ) )
        {
            dispatch( mixModelActions.brandSlideDataUpdated( { endCardData: { showLogo: enabled } } ) );
        }
    };
}
