import { toast, ToastOptions } from "react-toastify";
import { store } from "../store";
import { userActions } from "../actions";
import * as React from "react";
import { hasSeenSafariAudioSyncWarning } from "../ducks/user";
import {
    SAFARI_AUDIO_SYNC_WARNING_TOAST_MESSAGE_ONE,
    SAFARI_AUDIO_SYNC_WARNING_TOAST_MESSAGE_TWO,
    SAFARI_AUDIO_SYNC_WARNING_TOAST_TITLE,
} from "./constants";
import { browserUtils } from "./browserUtils";

const TOAST_ID_SAFARI_AUDIO_SYNC = "safari_audio_sync_toast";

export const showSafariAudioSyncWarningToast = () =>
{
    const state = store.getState();
    const dispatch = store.dispatch;
    if ( browserUtils.isSafariBrowser() && !hasSeenSafariAudioSyncWarning( state ) )
    {
        const toastOptions: ToastOptions = {
            hideProgressBar: true,
            autoClose: false,
            position: toast.POSITION.TOP_CENTER,
            toastId: TOAST_ID_SAFARI_AUDIO_SYNC,
            closeButton: false,
            onClick: () =>
            {
                dispatch( userActions.safariAudioSyncWarningDisplayed() );
            },
        };
        toast( safariAudioSyncToastContent(), toastOptions );
    }
};

function safariAudioSyncToastContent()
{
    return () => <div className="toastContent safariAudioSyncWarning">
        <h4 className="title">{SAFARI_AUDIO_SYNC_WARNING_TOAST_TITLE}</h4>
        <div className="message">
            <span>{SAFARI_AUDIO_SYNC_WARNING_TOAST_MESSAGE_ONE}</span>
            <br/>
            <br/>
            <span>{SAFARI_AUDIO_SYNC_WARNING_TOAST_MESSAGE_TWO}</span>
        </div>
    </div>;
}
