import { StoreState, TeamInvite, TeamMember } from "../../../../_types";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { modalServices, userBusinessServices } from "../../../../services";
import { DeleteTeamItemButton, DeleteTeamItemButtonDispatchProps, DeleteTeamItemButtonProps } from "../DeleteTeamItemButton";
import { LightboxDialogIdentifierForKey } from "../../../../helpers";

const mapStateToProps = ( storeState: StoreState, ownProps: { member: TeamMember } ): DeleteTeamItemButtonProps =>
{
    return {
        item: ownProps.member,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): DeleteTeamItemButtonDispatchProps =>
{
    return {
        handleDeleteItem( item: TeamMember | TeamInvite )
        {
            const member = item as TeamMember;
            dispatch( modalServices.openLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.DELETE_TEAM_MEMBERS,
                showCancelX: true,
                modal: true,
                confirmLabel: "Remove team member",
                width: 550,
                title: "Remove team member",
                content: "This team member will no longer be able to access your brand to collaborate on posts.",
                onSuccess: () =>
                {
                    dispatch( userBusinessServices.removeTeamMember( member.id ) );
                },
            } ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( DeleteTeamItemButton );
