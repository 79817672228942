import * as React from "react";
import { Music } from "../../../_types";
import { EpidemicSoundTrack } from "../../../_types/api";
import EpidemicMusicPicker from "./EpidemicMusicPicker";
import { WithMusicPlayerProps } from "../../higherOrderComponents/WithMusicPlayer";

export interface MusicPickerProps
{
    videoAudio: Music;
    musicArray: Music[];
    doesVideoContainAudio: boolean;
    isLoadingEpidemicSoundCatalog: boolean;
    isCustomMusicUploading: boolean;
    epidemicSoundTracks: EpidemicSoundTrack[];
    isCurrentProductTierPremium?: boolean;
    premiumEpidemicSoundTrackPreview?: EpidemicSoundTrack;
}

export interface MusicPickerDispatchProps
{
    onMoreClicked();
    loadFreeEpidemicSoundCatalog();
    selectPremiumEpidemicSoundTrackPreview();
    clearPremiumEpidemicSoundPreview();
    setEpidemicSoundSource();
}

export function MusicPicker( props: MusicPickerProps & MusicPickerDispatchProps & WithMusicPlayerProps )
{
    return <EpidemicMusicPicker {...props} />;
}
