import * as React from "react";

import Button from "react-md/lib/Buttons/Button";
import { modalServices, userServices } from "../services";
import { store } from "../store";
import CircularProgress from "react-md/lib/Progress/CircularProgress";
import { modalActions } from "../actions";
import * as HelpIcon from "../assets/img/helpIcon.png";
import { eventTracker } from "../helpers";

export interface EasyCaptionProps
{
    caption: string;
    headline: string;
    body: string;
    previousCaption: string;
    clicks: number;
}

export interface EasyCaptionDispatchProps
{
    handleCaptionUpdate: ( caption: string, clicks: number ) => void;
    handleNoCaptionIdeas: () => void;
}

export class EasyCaption extends React.PureComponent<EasyCaptionProps & EasyCaptionDispatchProps>
{
    public render()
    {
        return (
            <div className="easyCaption">
                <div className="easyCaptionHelp">
                    <img src={HelpIcon}/>
                    <div className="easyCaptionHelpText">An AI caption generator based on some of<br/>
                                                         the text in your post and starting caption.</div>
                </div>
                <Button flat primary swapTheming className="fullWidth large"
                        onClick={this.onEasyCaptionClicked}>Easy Caption
                </Button>
                <hr/>
            </div>
        );
    }

    private onEasyCaptionClicked = () =>
    {
        let captionIdeas = "";
        if ( this.props.headline )
        {
            captionIdeas += this.props.headline;
        }
        if ( this.props.body )
        {
            captionIdeas += " " + this.props.body;
        }
        if ( this.props.caption && this.props.caption !== this.props.previousCaption )
        {
            captionIdeas += " " + this.props.caption;
        }
        if ( captionIdeas.trim() === "" )
        {
            this.props.handleNoCaptionIdeas();
        }
        else
        {
            store.dispatch( modalServices.openLoadingDialog( {
                content: () => <CircularProgress className="spinner large" id="spinner"/>,
                className: "fullPageLoading",
                onShow: async () =>
                {
                    try
                    {
                        await userServices.easyCaption( captionIdeas.trim() ).then( ( caption ) =>
                        {
                            this.props.handleCaptionUpdate( caption, this.props.clicks );
                            eventTracker.logEasyCaptionButtonClicked(this.props.clicks);
                        } );
                    }
                    catch (error)
                    {
                        // tslint:disable-next-line:no-console
                        console.log( "error", error );
                    }
                    finally
                    {
                        store.dispatch( modalActions.loadingDialogClose() );
                    }
                },
            } ) );
        }
    }
}
