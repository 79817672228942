import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Post, StoreState } from "../../_types";
import { TemplatePreviewDialog, TemplatePreviewDialogDispatchProps, TemplatePreviewDialogProps } from "../TemplatePreviewDialog";
import { modalServices } from "../../services";
import { customizationHelper, CustomizePostOptions, eventTracker, LightboxDialogIdentifierForKey, TemplatePreviewSources } from "../../helpers";
import { store } from "../../store";
import { hasAnyCustomBrandColorsFontsOrLogo } from "../../ducks/userBusiness";
import { TEMPLATE_PREVIEW_IFRAME_ID } from "../TemplatePreviewBrandableView";
import { isProcessRunning } from "../../ducks/ui";

export interface TemplatePreviewDialogContainerProps
{
    post: Post;
    customizeOptions: CustomizePostOptions;
    source: TemplatePreviewSources;
    holidayName?: string;
    holidayDate?: string;
}

const mapStateToProps = ( storeState: StoreState, ownProps: TemplatePreviewDialogContainerProps ): TemplatePreviewDialogProps =>
{
    return {
        hasBrandStyles: hasAnyCustomBrandColorsFontsOrLogo( storeState ),
        toggleDisabled: isProcessRunning( storeState, TEMPLATE_PREVIEW_IFRAME_ID ),
        ...ownProps,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): TemplatePreviewDialogDispatchProps =>
{
    return {
        handleCustomize: async ( post, customizeOptions: CustomizePostOptions, source: TemplatePreviewSources, brandStylesApplied: boolean ) =>
        {
            const state = store.getState();
            eventTracker.logTemplatePreviewCustomizeTapped( source, post.design_slug, brandStylesApplied );
            await modalServices.encloseInLoadingDialog( async () =>
            {
                await modalServices.closeLightBoxesWithIdentifier( dispatch, state, LightboxDialogIdentifierForKey.TEMPLATE_PREVIEW );
                await customizationHelper.customizePost( post, dispatch, customizeOptions );
            } );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( TemplatePreviewDialog );
