import { Dispatch } from "redux";
import { requests } from "../helpers";
import { StoreState } from "../_types";
import { BackgroundMediaAPIData } from "../_types/api";
import { dynamicDesignDataActions } from "../actions/dynamicDesignData.actions";

export const backgroundMediaServices = {
    loadBackgroundMedia,
};

const BACKGROUND_MEDIA_URL = "/background_media";

function loadBackgroundMedia()
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        return requests.get( BACKGROUND_MEDIA_URL ).then(
            ( data: BackgroundMediaAPIData ) =>
            {
                dispatch( dynamicDesignDataActions.backgroundMediaLoadSuccess( data ) );
            },
            ( error ) =>
            {
                // TODO: do something when we can't load background media?
            },
        );
    };
}
