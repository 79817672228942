import { AssetControlConfig } from "../_types/api";
import { AssetChoice, DesignFramesJsonBlob, TemplateControlData } from "../_types";
import { compact, find, isEqual } from "lodash";

export function getInitialAssetChoice( assetControl: AssetControlConfig,
                                       initialControlDataForCurrentSelectedDesign: TemplateControlData ): AssetChoice
{
    if ( assetControl.supportsSupplementalChoices === true && initialControlDataForCurrentSelectedDesign )
    {
        const controlValue = initialControlDataForCurrentSelectedDesign[assetControl.id] as DesignFramesJsonBlob;
        if ( shouldCreateSupplementalAssetChoice( assetControl, controlValue ) )
        {
            return {
                text: controlValue.text,
                thumbnail: controlValue.thumbnail,
                jsonBlob: controlValue,
            };
        }
        else
        {
            return null;
        }
    }
    else
    {
        return null;
    }
}

function shouldCreateSupplementalAssetChoice( assetControl: AssetControlConfig, controlValue: DesignFramesJsonBlob )
{
    const matchingChoiceExists = find( assetControl.choices, ( choice ) => isEqual( choice.jsonBlob, controlValue ) );
    return !matchingChoiceExists && controlValue && controlValue.text && controlValue.thumbnail;
}

export function combineInitialAssetChoiceWithPresetChoices( initialAssetChoice: AssetChoice,
                                                            assetArray: AssetChoice[] ): AssetChoice[]
{
    const combinedChoices = [initialAssetChoice, ...assetArray];
    return compact( combinedChoices );
}
