import * as React from "react";
import DayPicker from "react-day-picker";
import classNames from "classnames";
import { getCurrentDate } from "../ducks/ui";
import { startOfDay } from "date-fns";
import { PriorityData } from "../_types";

export interface CalendarProps
{
    priorityData: PriorityData;
}

export interface CalendarState
{
    selectedDay: Date | undefined;
}

export interface CalendarDispatchProps
{
    dateSelected( selectedDate: Date );
    onMonthChange( month: Date );
}

const renderDay = ( day: Date, modifiers: any ) =>
{
    const date = day.getDate();
    return <div className="dayCell">
        <div className="date">{date}</div>
        <div className={classNames( "indicator", {
            ...modifiers,
        } )}>
        </div>
    </div>;
};

export class Calendar extends React.PureComponent<CalendarDispatchProps & CalendarProps, CalendarState>
{
    constructor( props )
    {
        super( props );
        this.state = {
            selectedDay: getCurrentDate(),
        };
    }

    public componentDidMount()
    {
        this.props.onMonthChange( this.state.selectedDay );
        this.props.dateSelected( this.state.selectedDay );
    }

    public render()
    {
        return (
            <div className="calendar center">
                <DayPicker
                    onDayClick={this.handleDayClick}
                    onMonthChange={this.props.onMonthChange}
                    selectedDays={this.state.selectedDay}
                    fixedWeeks
                    modifiers={this.props.priorityData}
                    renderDay={renderDay}
                />
            </div>
        );
    }

    private handleDayClick = ( day: Date ) =>
    {
        this.setState( { selectedDay: day } );
        this.props.dateSelected( startOfDay( day ) );
    }
}
