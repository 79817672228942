import { RIPL_TIER_PREMIUM } from "../../helpers";
import * as BaseTierFeatures from "../../assets/img/baseFeatures.png";
import * as PremiumTierFeatures from "../../assets/img/premiumFeatures.png";
import * as React from "react";

interface PremiumFocusedUpsellBenefitsProps
{
    selectedTier: ProductTier;
}

export function PremiumFocusedUpsellBenefits( props: PremiumFocusedUpsellBenefitsProps )
{
    const { selectedTier } = props;

    let benefits = (<img src={BaseTierFeatures} alt="Base Tier Features"/>);

    if ( selectedTier === RIPL_TIER_PREMIUM )
    {
        benefits = (<img src={PremiumTierFeatures} alt="Better Premium Tier Features"/>);
    }

    return (<div className="tierBenefits">
        {benefits}
    </div>);
}
