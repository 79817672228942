import * as React from "react";

export class YouTubeDetailsDialog extends React.PureComponent<{}>
{
    public render()
    {
        return (
          <div>
            <div className={"description"}>
              <p>YouTube allows you to set a title and privacy status</p>
            </div>
            <div className={"youtubeTitle"}>
              <label id="titleLabel" htmlFor="youtubeTitle">Title (Max 100 characters)</label><br/>
              <input type="text" id="youtubeTitle" maxLength={100}/>
            </div>
            <fieldset>
              <legend>Privacy Status:</legend>
              <div>
                <input type="radio" value="public" id="youtubePrivacyPublic" name="privacyRadio" defaultChecked={true}/>
                <label htmlFor="youtubePrivacyPublic">Public</label>
              </div>
              <div>
                <input type="radio" value="private" id="youtubePrivacyPrivate" name="privacyRadio"/>
                <label htmlFor="youtubePrivacyPrivate">Private</label>
              </div>
              <div>
                <input type="radio" value="unlisted" id="youtubePrivacyUnlisted" name="privacyRadio"/>
                <label htmlFor="youtubePrivacyUnlisted">Unlisted</label>
              </div>
            </fieldset>
          </div>
        );
    }
}
