import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
    getCustomizableCanvas,
    getDesignElementProperties,
    getEditorControlValue,
    getFirstFontControl,
    getFontAutocompleteData,
    getTextPropertiesSubPanelCaptionData,
} from "../../../ducks/ui";
import { FontData, FontTextOption, StoreState } from "../../../_types";
import { store } from "../../../store";
import { getCurrentBusiness } from "../../../ducks/userBusiness";
import { uiActions } from "../../../actions";
import {
    CHOOSE_A_FONT_TITLE,
    eventTracker,
    getFontCssUrlFieldForBrand,
    getFontFieldForBrand,
    getFontUrlFieldForBrand,
    LightboxDialogIdentifierForKey,
    openBrandFontPicker,
} from "../../../helpers";
import { modalServices, userBusinessServices } from "../../../services";
import MoreFontsDialogContainer, { MORE_FONTS_DIALOG_CLASS_NAME } from "./MoreFontsDialog.container";
import {
    FontPickerTextPropertiesSubPanel,
    FontPickerTextPropertiesSubPanelDispatchProps,
    FontPickerTextPropertiesSubPanelProps,
} from "../FontPickerTextPropertiesSubPanel";
import { getControlValue, getSelectedDesign } from "../../../ducks/mixModel";
import { ElementPropertyChangedData } from "../../../_types/canvasUpdaterTypes";
import { DesignElementPropertyType } from "../../../_types/api";
import { isContentContributor } from "../../../ducks/user";

const mapStateToProps = ( storeState: StoreState ): FontPickerTextPropertiesSubPanelProps =>
{
    const firstFontControl = getFirstFontControl( storeState );
    return {
        captionData: getTextPropertiesSubPanelCaptionData( storeState ),
        designElementProperties: getDesignElementProperties( storeState ),
        currentBusiness: getCurrentBusiness( storeState ),
        fontControl: firstFontControl,
        fontValue: getControlValue( storeState, getSelectedDesign( storeState ), firstFontControl.id ),
        isContentContributor: isContentContributor( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): FontPickerTextPropertiesSubPanelDispatchProps =>
{
    return {
        onMoreFontsClick: async ( captionId: string, type: DesignElementPropertyType, fontPropertyId: string ) =>
        {
            eventTracker.logMoreFontsTapped();
            const value = getEditorControlValue( store.getState() );
            await openChooseFont( dispatch, captionId, type, fontPropertyId, value as FontData );
        },
        tellDesignToApplyFont,
        handleEditFontClicked: async ( font: FontData, controlId: string ) =>
        {
            await openChooseFontAndSetBusinessFont( dispatch, font, controlId );
        },
        handleClearFontClicked: async ( font: FontData ) =>
        {
            await dispatch( uiActions.updateBusinessInfo( {
                [getFontFieldForBrand( font.type )]: null,
                [getFontUrlFieldForBrand( font.type )]: null,
                [getFontCssUrlFieldForBrand( font.type )]: null,
            } ) );
            await dispatch( userBusinessServices.update( {
                [getFontFieldForBrand( font.type )]: null,
                [getFontUrlFieldForBrand( font.type )]: null,
                [getFontCssUrlFieldForBrand( font.type )]: null,
            } ) );
        },
    };
};

async function openChooseFontAndSetBusinessFont( dispatch: Dispatch<StoreState>, font: FontData, controlId: string )
{
    await dispatch( uiActions.updateFontFontAutocomplete( font ) );
    openBrandFontPicker( dispatch, font.type, controlId );
}

async function openChooseFont( dispatch: Dispatch<StoreState>, captionId: string, type: DesignElementPropertyType, fontPropertyId: string,
                               font: FontData )
{
    await dispatch( uiActions.updateFontFontAutocomplete( font ) );
    dispatch( modalServices.openLightbox( {
        identifierForKey: LightboxDialogIdentifierForKey.CHOOSE_CUSTOM_FONT,
        title: CHOOSE_A_FONT_TITLE,
        showCancelX: true,
        hideCancel: true,
        className: MORE_FONTS_DIALOG_CLASS_NAME,
        content: MoreFontsDialogContainer,
        width: 500,
        onSuccess: () =>
        {
            const selectedFont = getFontAutocompleteData( store.getState() ).font;
            if ( selectedFont )
            {
                tellDesignToApplyFont( captionId, type, fontPropertyId, selectedFont );
                eventTracker.logFontSelectedFromMoreFonts( selectedFont );
            }
        },
    } ) );
}

function tellDesignToApplyFont( captionId: string, type: DesignElementPropertyType, fontPropertyId: string, option: FontTextOption )
{
    const state = store.getState();
    const canvas = getCustomizableCanvas( state );
    if ( canvas )
    {
        const payload: ElementPropertyChangedData = {
            id: captionId,
            type,
            controlId: fontPropertyId,
            value: option,
        };
        canvas.tellDesignToChangeElementProperty( payload );
        eventTracker.logFontSelected( option );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( FontPickerTextPropertiesSubPanel );
