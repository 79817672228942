import * as React from "react";
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import { NonStripeSubscriptionInfoData, StripeSubscriptionInfoData, UserState } from "../../_types";
import { isEmpty, map } from "lodash";
import Button from "react-md/lib/Buttons/Button";
import CircularProgress from "react-md/lib/Progress/CircularProgress";
import { eventTracker } from "../../helpers";
import SubscriptionInfoBlock from "./containers/SubscriptionInfoBlock.container";
import DefaultStripePaymentMethod from "./containers/DefaultStripePaymentMethod.container";

export interface ManageSubscriptionsContentProps
{
    user: UserState;
    isProUser: boolean;
    subscriptionInfoSpinnerEnabled: boolean;
    subscriptionInfos: Array<StripeSubscriptionInfoData | NonStripeSubscriptionInfoData>;
    isNotStripeSubscriberAndHasDefaultStripePaymentData: boolean;
}

export interface ManageSubscriptionsContentDispatchProps
{
    loadSubscriptionInfo();
    showUpsell();
}

export default class ManageSubscriptionsContent
    extends React.PureComponent<ManageSubscriptionsContentProps & ManageSubscriptionsContentDispatchProps>
{
    public componentDidMount()
    {
        eventTracker.logManageSubscriptionDisplayed();
        this.props.loadSubscriptionInfo();
    }

    public render()
    {
        return (
            <Grid className="manageSubscriptionsContainer">
                <Cell size={8} desktopOffset={2}>
                    <div className="profile">Subscriptions for {this.props.user.full_name}</div>
                    {this.getContent()}
                    {this.renderDefaultStripePaymentMethod()}
                </Cell>
            </Grid>
        );
    }

    private renderDefaultStripePaymentMethod()
    {
        if ( !this.props.isNotStripeSubscriberAndHasDefaultStripePaymentData )
        {
            return null;
        }

        return (<div className="subscriptionInfo manageSubscriptionBlock">
            <DefaultStripePaymentMethod/>
        </div>);
    }

    private getContent()
    {
        if ( this.props.subscriptionInfoSpinnerEnabled )
        {
            return (<div className="subscriptionInfo">
                <CircularProgress className="spinner" id="spinner"/>
            </div>);
        }

        if ( this.props.isProUser && this.hasSubscriptionInfo() )
        {
            return (
                map( this.props.subscriptionInfos,
                    ( subscriptionInfo, index ) =>
                    {
                        return this.getSubscriptionInfoBlock( subscriptionInfo, index );
                    } )
            );
        }
        else if ( this.props.isProUser )
        {
            return (<div className="subscriptionInfo manageSubscriptionBlock">
                <span>You are subscribed</span>
            </div>);
        }
        else
        {
            return (<div className="subscriptionInfo manageSubscriptionBlock">
                <p>You are not subscribed</p>
                <Button flat primary swapTheming className="button" onClick={this.props.showUpsell}>Get Ripl Pro</Button>
            </div>);
        }
    }

    private hasSubscriptionInfo = () =>
    {
        return !isEmpty( this.props.subscriptionInfos );
    }

    private getSubscriptionInfoBlock = ( subscriptionInfo, key ) =>
    {
        return <SubscriptionInfoBlock key={key} subscriptionInfo={subscriptionInfo}/>;
    }
}
