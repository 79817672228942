import { Action } from "redux-actions";
import { defaults, difference, filter, find, map, reduce, reverse, sortBy, uniq, values } from "lodash";
import { parse } from "date-fns";
import { postActions, UserBusinessActionData, userBusinessActions } from "../actions";
import { deleteFromLookup, postsSchema, ReducerCreator, updateLookupWithAction } from "../helpers";
import { createSelector } from "reselect";
import { getCurrentBusiness } from "./userBusiness";
import { createDefaultDataLookup } from "./dataLookup";
import { DraftState, Post, StoreState } from "../_types";

const defaultState: DraftState = {
    ...createDefaultDataLookup(),
    draftsByDate: {},
    priorityData: {
        error: [],
        sent: [],
        scheduled: [],
    },
    activity: [],
    cancelNextDraftsLoad: false,
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( postActions.loadDraftSuccess, handleLoad );
reducerCreator.addCombinedActions( [postActions.createSuccess,
                                    postActions.updateSuccess,
                                    postActions.unscheduleSuccess],
    handleDraft );
reducerCreator.addAction( postActions.deleteSuccess, handleDelete );
reducerCreator.addAction( postActions.finishSuccess, handleFinish );
reducerCreator.addCombinedActions( [userBusinessActions.businessSwitched,
                                    userBusinessActions.deleteSuccess],
    handleBusinessSwitchedOrDeleted );
reducerCreator.addAction( postActions.cancelNextDraftsLoad, handleCancelNextDraftsLoad );
export default reducerCreator.createReducer();

function handleBusinessSwitchedOrDeleted( state: DraftState, action: Action<UserBusinessActionData> ): DraftState
{
    return {
        ...defaultState,
    };
}

function handleCancelNextDraftsLoad( state: DraftState, action: Action<boolean> ): DraftState
{
    return {
        ...state,
        cancelNextDraftsLoad: action.payload,
    };
}

function handleLoad( state: DraftState, action: Action<NormalizrData> ): DraftState
{
    const updatedState = updateLookupWithAction( postsSchema, action, state, false );
    updatedState.activity = uniq( [...action.payload.result, ...state.activity] );
    return updatedState;
}

function handleDraft( state: DraftState, action: Action<NormalizrData> ): DraftState
{
    return updateLookupWithAction( postsSchema, action, state, true );
}

function handleDelete( state: DraftState, action: Action<number> ): DraftState
{
    const removedId = action.payload;
    return deleteFromStateById( state, removedId );
}

function handleFinish( state: DraftState, action: Action<NormalizrData> ): DraftState
{
    const finishedState = updateLookupWithAction( postsSchema, action, createDefaultDataLookup() );

    const updatedState = reduce( finishedState.ids, ( accumulatorState, id ) =>
    {
        return deleteFromStateById( accumulatorState, id );
    }, state );

    return updatedState;
}

function deleteFromStateById( state: DraftState, removedId: number )
{
    const updatedState = deleteFromLookup( state, removedId );
    const { activity, ...otherFields } = updatedState;
    return {
        ...otherFields,
        activity: difference( activity, [removedId] ),
    };
}

const getActivityIds = ( state: StoreState ) => state.drafts.activity;
const getIdToObj = ( state: StoreState ) => state.drafts.idToObj;

export const getDraftById = ( state: StoreState, id: number ) => state.drafts.idToObj[id];

export const getDraftPosts = createSelector(
    [getActivityIds, getIdToObj, getCurrentBusiness],
    ( activityIds, idToObj, currentBusiness ): Post[] =>
    {
        let posts = map( activityIds, ( id ) => idToObj[id] );
        posts = filter( posts );

        if ( currentBusiness && currentBusiness.id )
        {
            posts = filter( posts, ( post ) => post.user_business_id === currentBusiness.id || !post.user_business_id );
        }

        return reverse( sortBy( posts, ( post: Post ) => parse( post.created_at ).getTime() ) );
    },
);

export function getDraftFromSlug( state: StoreState, postSlug )
{
    return defaults( {}, find( values( state.drafts.idToObj ), { slug: postSlug } ) );
}

export function getPostIdFromDraftSlug( state: StoreState, postSlug: string ): number
{
    const post = getDraftFromSlug( state, postSlug );
    if ( post )
    {
        return post.id;
    }
}
