import * as React from "react";
import { generateFontDataForBrandFont, getFontFieldForBrand } from "../../helpers";
import BrandSettingsFontPickerControl from "./containers/BrandSettingsFontPickerControl.container";
import { UserBusiness } from "../../_types";
import { Brand, BRANDS, FontCssUrlFieldType, FontUrlFieldType, MEDIA_TYPES_BY_FONT_CSS_URL_FIELD } from "../../_types/api";

export interface BrandSettingsFontPickerProps
{
    businessData: Partial<UserBusiness>;
    showHeader: boolean;
}

export interface BrandSettingsFontPickerDispatchProps
{
    onUpdatedFont?: () => void;
}

export class BrandSettingsFontPicker extends React.PureComponent<BrandSettingsFontPickerProps & BrandSettingsFontPickerDispatchProps>
{
    public render()
    {
        return (
            <div className="brandSettingsFontPickerContainer commonFontPickerContainer">
                {this.props.showHeader && <h3>Brand fonts</h3>}
                <div className="brandFontPicker">
                    {
                        BRANDS.map( ( brand: Brand ) =>
                        {
                            const fontField = getFontFieldForBrand( brand );
                            const font = generateFontDataForBrandFont( this.props.businessData, brand );
                            const fontUrlField = fontField + "_url" as FontUrlFieldType;
                            const fontCssUrlField = fontField + "_css_url" as FontCssUrlFieldType;
                            return <BrandSettingsFontPickerControl
                                key={fontField}
                                fontField={fontField}
                                fontUrlField={fontUrlField}
                                fontCssUrlField={fontCssUrlField}
                                customFont={font}
                                showAdditionalEditIcon={true}
                                mediaType={MEDIA_TYPES_BY_FONT_CSS_URL_FIELD[fontCssUrlField]}
                                onUpdatedFont={this.props.onUpdatedFont}/>;
                        } )
                    }
                </div>
            </div>
        );
    }
}
