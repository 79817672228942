import * as React from "react";

export const CoachMarkPointer: React.FC<{}> = () =>
{
    return (
        <svg className="coach-mark-pointer-svg" width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.06218 0.499999C6.44708 -0.166667 7.40933 -0.166667 7.79423 0.5L13.8564 11L0 11L6.06218 0.499999Z"/>
        </svg>
    );
};
