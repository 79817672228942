import * as React from "react";
import PremiumTrackPreview from "./PremiumTrackPreview";

export const PremiumEpidemicSoundTrackDialogContent: React.FC = () =>
{
    return (
        <>
            Upgrade to a Premium plan and get this song plus access to more than 100 royalty-free music tracks available for use in all your social
            media.
            <PremiumTrackPreview/>
        </>
    );
};
