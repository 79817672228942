import { connect } from "react-redux";
import { Dispatch } from "redux";
import { CollectionPage, CollectionPageDispatchProps, CollectionPageProps } from "../CollectionPage";
import { collectionHasError, getCollectionName, getCollectionPosts } from "../../ducks/collections";
import { StoreState } from "../../_types";
import { getCollectionPageSource, isCollectionSpinnerEnabled } from "../../ducks/ui";
import { collectionActions, uiActions } from "../../actions";
import { history, HOMEPAGE_URL, POST_INTENT_SOURCE } from "../../helpers";
import { modalServices } from "../../services";
import { store } from "../../store";

const mapStateToProps = ( storeState: StoreState ): CollectionPageProps =>
{
    return {
        collectionName: getCollectionName( storeState ),
        collectionPosts: getCollectionPosts( storeState ),
        hasError: collectionHasError( storeState ),
        isLoading: isCollectionSpinnerEnabled( storeState ),
        source: getCollectionPageSource( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): CollectionPageDispatchProps =>
{
    return {
        goBackToHome: () =>
        {
            history.replace( HOMEPAGE_URL );
            dispatch( collectionActions.collectionCleared() );
            if ( getCollectionPageSource( store.getState() ) === POST_INTENT_SOURCE )
            {
                dispatch( modalServices.openPostIntentsDialog() );
            }
            dispatch( uiActions.collectionPageSourceSet( null ) );
            window.onpopstate = null;
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( CollectionPage );
