import * as React from "react";
import { createRef } from "react";

export interface ScrollingSocialCalendarRowProps
{
  contentWrapperClassName: string;
  scrollToChildIndex?: number;
}

interface ScrollingSocialCalendarRowState
{
  scrollPosition: number;
  rowWidth: number;
}

export class ScrollingSocialCalendarRow extends React.PureComponent<ScrollingSocialCalendarRowProps, ScrollingSocialCalendarRowState>
{
  private scrollableRef = createRef<HTMLDivElement>();
  private contentRef = createRef<HTMLDivElement>();

  constructor( props )
  {
    super( props );
    this.state = {
      scrollPosition: 0,
      rowWidth: 0,
    };
  }

  public componentDidMount()
  {
    this.scrollToSelectedChildPosition();
  }

  public componentDidUpdate( prevProps: Readonly<ScrollingSocialCalendarRowProps>)
  {
    if ( prevProps.scrollToChildIndex !== this.props.scrollToChildIndex )
    {
      this.scrollToSelectedChildPosition();
    }
  }

  public render()
  {
    return (
      <div className="socialCalendarPosts" ref={this.scrollableRef}>
        <div className={this.props.contentWrapperClassName} ref={this.contentRef}>
          {this.props.children}
        </div>
      </div>
    );
  }

  private getChildNodeScrollPosition( childIndex )
  {
    const contentWrapper = this.contentRef.current;
    if ( contentWrapper && "children" in contentWrapper )
    {
      const targetChildElement = contentWrapper.children[childIndex] as HTMLDivElement;
      if ( targetChildElement )
      {
        return targetChildElement.offsetLeft;
      }
    }
    return 0;
  }

  private scrollToSelectedChildPosition()
  {
    if ( this.props.scrollToChildIndex || this.props.scrollToChildIndex === 0 )
    {
      const scrollPosition = this.getChildNodeScrollPosition( this.props.scrollToChildIndex );
      if ( scrollPosition !== this.state.scrollPosition )
      {
        this.setScrollPosition( scrollPosition );
      }
    }
  }

  private setScrollPosition( aScrollPosition )
  {
    const scrollableRowContainerRef  = this.scrollableRef.current;
    if ( scrollableRowContainerRef )
    {
      scrollableRowContainerRef.scrollLeft = aScrollPosition;
      this.setState( {
        scrollPosition: aScrollPosition,
      } );
    }
  }
}
