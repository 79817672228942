import { AnimationTextOption, DesignElementProperty, TextAnimationChoices } from "../../../_types";
import { isNil, map } from "lodash";
import { AnimationPickerItem } from "../AnimationPickerTextPropertiesSubPanel";
import * as React from "react";

interface TextAnimationChoicesPanelProps
{
    textAnimationChoices?: TextAnimationChoices;
    preferredAnimation?: string;
    onPreferredAnimationSelected( animationId: string ): void;
}

export function TextAnimationChoicesPanel( props: TextAnimationChoicesPanelProps )
{
    if ( isNil( props.textAnimationChoices ) )
    {
        return <div className="stylizedTextChoicePanel">No text animations.</div>;
    }

    const onAnimationOptionSelected = ( textAnimation: AnimationTextOption, animationProperty: DesignElementProperty ) =>
    {
        props.onPreferredAnimationSelected( textAnimation.id );
    };

    const textAnimations = map( props.textAnimationChoices.textAnimations,
        ( textAnimation ) =>
        {
            const isSelected = props.preferredAnimation === textAnimation.id;
            return <AnimationPickerItem
                key={textAnimation.id}
                animationOption={textAnimation}
                animationProperty={null}
                selected={isSelected}
                onAnimationOptionSelected={onAnimationOptionSelected}
            />;
        } );

    return <div className="listPicker animationPickerSubPanel">{textAnimations}</div>;
}