import * as React from "react";
import { BackgroundChoice } from "../_types";

interface BackgroundMediaControlProps
{
    value: BackgroundChoice;
}

export interface BackgroundMediaControlDispatchProps
{
    loadBackgroundMedia?();
}

export class BackgroundMediaControl extends React.PureComponent<BackgroundMediaControlProps & BackgroundMediaControlDispatchProps>
{
    public componentDidMount()
    {
        this.props.loadBackgroundMedia();
    }

    public render()
    {
        const currentBackgroundChoice = this.props.value;
        const backgroundMedia = currentBackgroundChoice.media;
        const thumbnailUrl = backgroundMedia && (backgroundMedia.thumbnail_url || backgroundMedia.cdn_url);
        const backgroundImage = backgroundMedia && `url(${thumbnailUrl})` || "none";
        const backgroundStyle = {
            backgroundColor: currentBackgroundChoice.color && currentBackgroundChoice.color.value,
            backgroundImage,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
        };
        return (<div className="internal" style={backgroundStyle}>
        </div>);
    }
}
