import * as React from "react";
import EmailPasswordInputPanelContainer from "./containers/EmailPasswordInputPanel.container";
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import { RiplLogoSymbolMark } from "./RiplLogoSymbolMark";
import Button from "react-md/lib/Buttons/Button";

export interface ForgotPasswordFormProps
{
    showLogin: () => void;
}

export interface ForgotPasswordFormDispatchProps
{
    onForgotPasswordEmail( emailAddress: string );
}

export default class ForgotPasswordForm extends React.PureComponent<ForgotPasswordFormProps & ForgotPasswordFormDispatchProps>
{
    public render()
    {
        return (
            <Grid className="loginForm forgotPassword" noSpacing={true}>
                <Button icon={true}
                        className="closeX"
                        iconClassName="material-icons"
                        iconChildren="close"
                        onClick={this.props.showLogin}/>
                <Cell phoneSize={12} tabletSize={8} desktopSize={12} desktopOffset={12} className="content">
                    <RiplLogoSymbolMark/>
                    <h1 className="text title thin">Can't sign in?</h1>
                    <div className="instructionText">
                        Enter your email address below and we'll send your password reset instructions.
                    </div>
                    <EmailPasswordInputPanelContainer onContinueClicked={this.props.onForgotPasswordEmail} hidePassword={true}/>
                    <div className="text">
                        Remembered your password? <span className="navLink" onClick={this.props.showLogin}>Log in</span>
                    </div>
                </Cell>
            </Grid>
        );
    }
}
