import * as React from "react";
import CircularProgress from "react-md/lib/Progress/CircularProgress";
import { DesignLoadProgressOwnProps } from "./containers/DesignLoadProgress.container";

export interface DesignLoadProgressProps extends DesignLoadProgressOwnProps
{
    loading: boolean;
}

export class DesignLoadProgress extends React.PureComponent<DesignLoadProgressProps>
{

    public render()
    {
        return (
            this.props.loading && <CircularProgress id={this.props.iframeId + "-spinner"} className="spinner large"/>
        );
    }
}
