import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ExternalMediaData, StockMediaSearchType, StoreState } from "../../../_types";
import { StockMediaPicker, StockMediaPickerDispatchProps, StockMediaPickerProps } from "../StockMediaPicker";
import { modalServices, postServices, stockMediaServices } from "../../../services";
import {
    getLastSearchId,
    getSearchedStockMedia,
    getStockMediaSearchError,
    getStockMediaSearchTerm,
    getStockMediaSearchType,
    getStockMediaTotalMatchCount,
} from "../../../ducks/stockMedia";
import { AddMediaAPIResponse, MEDIA_SOURCE_STOCK_MEDIA } from "../../../_types/api";
import {
    doesDesignSupportVideo,
    eventTracker,
    guardForMinMaxMediaRules,
    LightboxDialogIdentifierForKey,
    STOCK_MEDIA_PEXELS_ATTRIBUTION,
    STOCK_MEDIA_PEXELS_ATTRIBUTION_LINK,
    STOCK_MEDIA_SEARCH_PHOTOS_TYPE,
    STOCK_MEDIA_SEARCH_VIDEOS_TYPE,
} from "../../../helpers";
import { getPostId, getSelectedDesign, updateMixModelAfterInputMediaUpload } from "../../../ducks/mixModel";
import { store } from "../../../store";
import { ImageUploadData, mixModelActions, stockMediaActions, uiActions, userActions } from "../../../actions";
import {
    getActiveDesignMediaObject,
    getExternalMediaUploadStatus,
    getShouldShowDefaultStockMediaSearchResults,
    isStockMediaUploading,
} from "../../../ducks/ui";
import { getCurrentBusinessTypeId, getDefaultSearchTerm } from "../../../ducks/userBusiness";
import { hasUserAcceptedStockMediaTermsOfUse } from "../../../ducks/user";
import StockMediaTermsDialogContainer from "./StockMediaTermsDialog.container";

const STOCK_MEDIA_TYPE_PHOTO = "photo";
const STOCK_MEDIA_TYPE_VIDEO = "video";
const STOCK_MEDIA_SOURCE = "pexels";

interface StockMediaPickerContainerProps
{
    imageTrayClassNames: {};
    onVideoEdit: () => void;
}

const mapStateToProps = ( storeState: StoreState, ownProps: StockMediaPickerContainerProps ): StockMediaPickerProps =>
{
    return {
        imageTrayClassNames: ownProps.imageTrayClassNames,
        searchResults: getSearchedStockMedia( storeState ),
        totalCount: getStockMediaTotalMatchCount( storeState ),
        externalMediaUploadStatus: getExternalMediaUploadStatus( storeState ),
        designSupportsVideo: doesDesignSupportVideo( getSelectedDesign( storeState ) ),
        hasError: getStockMediaSearchError( storeState ),
        searchTerm: getStockMediaSearchTerm( storeState ),
        defaultStockMediaSearchTerm: getDefaultSearchTerm( storeState ),
        shouldShowDefaultStockMediaSearchResults: getShouldShowDefaultStockMediaSearchResults( storeState ),
        selectedMediaType: getStockMediaSearchType( storeState ),
        stockMediaAttribution: STOCK_MEDIA_PEXELS_ATTRIBUTION,
        stockMediaAttributionLink: STOCK_MEDIA_PEXELS_ATTRIBUTION_LINK,
    };

};
const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: StockMediaPickerContainerProps ): StockMediaPickerDispatchProps =>
{
    const handleStockMediaClicked = async ( stockMediaData: ExternalMediaData ) =>
    {
        const state = store.getState();
        const postId = getPostId( state );
        const lastSearchId = getLastSearchId( state );
        if ( stockMediaData.preview_video_url )
        {
            return handleVideo( stockMediaData, postId, lastSearchId );
        }
        else
        {
            return handlePhoto( stockMediaData, postId, lastSearchId );
        }
    };

    const handleVideo = async ( stockMediaData: ExternalMediaData, postId: number, lastSearchId: string ) =>
    {
        if ( !guardForMinMaxVideoStockMediaRules( dispatch ) )
        {
            return;
        }

        eventTracker.logStockMediaClicked( stockMediaData.external_media_id, STOCK_MEDIA_TYPE_VIDEO );

        if ( !isStockMediaUploading( store.getState() ) )
        {
            await dispatch( uiActions.updateExternalMediaUploadStatus( { [stockMediaData.external_media_id]: true } ) );
            await dispatch(
                stockMediaServices.getFullSizeVideo( postId, stockMediaData.external_media_id, lastSearchId,
                    async ( data: AddMediaAPIResponse ) =>
                    {
                        try
                        {
                            await dispatch( postServices.convertVideo( data.url, STOCK_MEDIA_SOURCE, STOCK_MEDIA_TYPE_VIDEO ) );
                            ownProps.onVideoEdit();
                        }
                        finally
                        {
                            dispatch( uiActions.updateExternalMediaUploadStatus( { [stockMediaData.external_media_id]: false } ) );
                        }
                    } ) );
        }
    };

    const handlePhoto = async ( stockMediaData: ExternalMediaData, postId: number, lastSearchId: string ) =>
    {
        if ( !guardForMinMaxStockMediaRules( dispatch ) )
        {
            return;
        }

        eventTracker.logStockMediaClicked( stockMediaData.external_media_id, STOCK_MEDIA_TYPE_PHOTO );

        dispatch( uiActions.updateExternalMediaUploadStatus( { [stockMediaData.external_media_id]: true } ) );
        await dispatch(
            stockMediaServices.getFullSizePhoto( postId, stockMediaData.external_media_id, lastSearchId, ( data: AddMediaAPIResponse ) =>
            {
                if ( !guardForMinMaxStockMediaRules( dispatch ) )
                {
                    dispatch( uiActions.updateExternalMediaUploadStatus( { [stockMediaData.external_media_id]: false } ) );
                    return;
                }

                const newData: ImageUploadData = {
                    uploadFields: data,
                    isLowRes: false,
                    // TODO pass fileSize: down from server
                };
                updateMixModelAfterInputMediaUpload( dispatch, newData );
                dispatch( uiActions.updateExternalMediaUploadStatus( { [stockMediaData.external_media_id]: false } ) );
                dispatch( mixModelActions.addMediaSource( MEDIA_SOURCE_STOCK_MEDIA ) );
            } ) );
    };

    function handleStockMediaClickedWithSpinnerCheck( stockMediaData: ExternalMediaData )
    {
        const state = store.getState();
        const activeMediaObject = getActiveDesignMediaObject( state );
        if ( activeMediaObject )
        {
            return modalServices.encloseInLoadingDialog
            (
                async () => await handleStockMediaClicked( stockMediaData ),
                "Replacing your media",
                230,
                230,
                "clearBackground",
            );
        }
        else
        {
            return handleStockMediaClicked( stockMediaData );
        }
    }

    function openStockMediaTermsDialog( stockMediaData: ExternalMediaData )
    {
        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.STOCK_MEDIA_TERMS_AND_CONDITIONS,
            title: "One quick thing",
            subtitle: "Please accept our Terms & Conditions to use any of our 500,000+ stock photos and videos.",
            showCancelX: true,
            hideCancel: true,
            hideConfirm: false,
            modal: true,
            confirmLabel: "Continue",
            className: "stockMediaTermsDialog",
            content: StockMediaTermsDialogContainer,
            width: 450,
            confirmDisabled: () => !hasUserAcceptedStockMediaTermsOfUse( store.getState() ),
            onSuccess: () =>
            {
                dispatch( userActions.stockMediaTermsUpdated( true ) );
                return handleStockMediaClickedWithSpinnerCheck( stockMediaData );
            },
            onCancel: () =>
            {
                dispatch( userActions.stockMediaTermsUpdated( false ) );
            },
        } ) );
    }

    return {
        onSearchSubmitted: ( selectedMediaType: StockMediaSearchType, searchTerm: string, isUserInitiatedSearch: boolean ) =>
        {
            const storeState = store.getState();
            const currentBusinessTypeId = getCurrentBusinessTypeId( storeState );

            if ( selectedMediaType === STOCK_MEDIA_SEARCH_PHOTOS_TYPE )
            {
                dispatch( stockMediaActions.searchResultsCleared() );
                dispatch( stockMediaServices.searchPhotos( searchTerm, 1, currentBusinessTypeId ) );
                eventTracker.logStockMediaSearched( searchTerm, STOCK_MEDIA_TYPE_PHOTO, isUserInitiatedSearch );
            }
            else if ( selectedMediaType === STOCK_MEDIA_SEARCH_VIDEOS_TYPE )
            {
                dispatch( stockMediaActions.searchResultsCleared() );
                dispatch( stockMediaServices.searchVideos( searchTerm, 1, currentBusinessTypeId ) );
                eventTracker.logStockMediaSearched( searchTerm, STOCK_MEDIA_TYPE_VIDEO, isUserInitiatedSearch );
            }

        },
        onStockMediaClicked: async ( stockMediaData: ExternalMediaData ) =>
        {
            if ( hasUserAcceptedStockMediaTermsOfUse( store.getState() ) )
            {
                return handleStockMediaClickedWithSpinnerCheck( stockMediaData );
            }
            else
            {
                openStockMediaTermsDialog( stockMediaData );
            }
        },
        loadMore: async ( selectedMediaType: string, searchTerm: string, page: number ) =>
        {
            const storeState = store.getState();
            const currentBusinessTypeId = getCurrentBusinessTypeId( storeState );

            if ( selectedMediaType === STOCK_MEDIA_SEARCH_PHOTOS_TYPE )
            {
                await dispatch( stockMediaServices.searchPhotos( searchTerm, page, currentBusinessTypeId ) );
            }
            else if ( selectedMediaType === STOCK_MEDIA_SEARCH_VIDEOS_TYPE )
            {
                await dispatch( stockMediaServices.searchVideos( searchTerm, page, currentBusinessTypeId ) );
            }
        },
        updateSelectedMediaType: ( selectedMediaType: StockMediaSearchType ) =>
        {
            dispatch( stockMediaActions.changeStockMediaSearchType( selectedMediaType ) );
        },
        updateShowDefaultSearchTerm: () =>
        {
            dispatch( uiActions.shouldShowDefaultStockMediaSearchResultsUpdated( false ) );
        },
    };
};

const guardForMinMaxStockMediaRules = ( dispatch: Dispatch<StoreState> ) =>
{
    return guardForMinMaxMediaRules( dispatch, 1 );
};

const guardForMinMaxVideoStockMediaRules = ( dispatch: Dispatch<StoreState> ) =>
{
    return guardForMinMaxMediaRules( dispatch, 1, 1 );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( StockMediaPicker );
