import { Action } from "redux-actions";
import { userActions } from "../actions";
import {
    ALL_PAYING_USER_SUBSCRIBED_STATUSES,
    ALL_USER_SUBSCRIBED_STATUSES,
    browserUtils,
    CLIENT_ID,
    DEFAULT_BRAND_LOGO_URL,
    isAllowedSocialNetworkConnectType,
    ReducerCreator,
    RIPL_TIER_BASE,
    RIPL_TIER_LEGACY,
    RIPL_TIER_NONE,
    RIPL_TIER_PREMIUM,
    RIPL_TIERS_TO_UPSELL,
    SOCIAL_NETWORKS_CAN_USE_FOR_INTEGRATION,
    STRIPE_MONTHLY_SKU_SUBSTRING,
    STRIPE_YEARLY_SKU_SUBSTRING,
    SUBSCRIPTION_STATUS_PRO_PREVIEW,
    SUBSCRIPTION_STATUS_PRO_PREVIEW_ENDED,
    SUBSCRIPTION_STATUS_TRIAL,
    USD_CODE,
} from "../helpers";
import { compact, concat, filter, find, first, forEach, includes, isEmpty, isNil, map, now, omit, unionBy } from "lodash";
import {
    doesCurrentBusinessSupportTeamFeatures,
    getCurrentBusiness,
    getCurrentBusinessId,
    hasTeamMemberPermissionsForCurrentBusiness,
} from "./userBusiness";
import {
    NonStripeSubscriptionInfoData,
    SocialNetworkAccount,
    SocialNetworkAccountType,
    StoreState,
    StripeSubscriptionInfoData,
    SupportedNotice,
    UserState,
} from "../_types";
import {
    FACEBOOK_ACCOUNT_TYPE,
    FACEBOOK_GROUP_ACCOUNT_TYPE,
    FACEBOOK_INSTAGRAM_ACCOUNT_TYPE,
    FACEBOOK_PAGE_ACCOUNT_TYPE,
    INSTAGRAM_ACCOUNT_TYPE,
    LINKEDIN_ACCOUNT_TYPE,
    NOTICE_ACTION_SUPPORTED_NAMES,
    PAYMENT_PLATFORM_IOS,
    PAYMENT_PLATFORM_STRIPE,
    TWITTER_ACCOUNT_TYPE,
    YOUTUBE_ACCOUNT_TYPE,
} from "../_types/api";

const defaultState: UserState = {
    has_accepted_shutterstock_terms_of_use: false,
    currency_code: USD_CODE,
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addCombinedActions( [
    userActions.meSuccess,
    userActions.loginSuccess,
    userActions.updateSuccess,
], updateUserData );
reducerCreator.addAction( userActions.socialNetworkAccountInaccessible, handleSocialNetworkAccountInaccessible );
reducerCreator.addAction( userActions.socialNetworkAccountHasPermissions, handleSocialNetworkAccountsHavePermissions );
reducerCreator.addAction( userActions.stockMediaTermsUpdated, handleStockMediaTermsUpdated );
reducerCreator.addAction( userActions.testDriveAccepted, handleAcceptedTestDrive );
reducerCreator.addAction( userActions.seenInspirePage, handleSeenInspirePage );
reducerCreator.addAction( userActions.firstProPostShared, handleFirstProPostShared );
reducerCreator.addAction( userActions.subscriptionInfoSuccess, handleSubscriptionInfo );
reducerCreator.addAction( userActions.showYouAroundDisplayed, handleShowYouAroundDisplayed );
reducerCreator.addAction( userActions.postIntentsDisplayed, handlePostIntentsDisplayed );
reducerCreator.addAction( userActions.postIntentsClosed, handlePostIntentsClosed );
reducerCreator.addAction( userActions.safariAudioSyncWarningDisplayed, handleSafariAudioSyncWarningDisplayed );
reducerCreator.addAction( userActions.setCurrencyCode, handleSetCurrencyCode );
reducerCreator.addAction( userActions.stripeUserPaymentMethodSuccess, handleStripeUserPaymentMethodSuccess );
reducerCreator.addAction( userActions.setAuthToken, handleSetAuthToken );
reducerCreator.addAction( userActions.setClientId, handleSetClientId );
reducerCreator.addAction( userActions.epidemicSoundReauthorizationRequired, handleEpidemicSoundReauthorizationRequired );
reducerCreator.addAction( userActions.hasOptedOutOfAlkaiCrossPromo, handleHasOptedOutOfAlkaiCrossPromo);
reducerCreator.addAction( userActions.hasSeenAlkaiCrossPromoCount, handleHasSeenAlkaiCrossPromoCount);
reducerCreator.addAction( userActions.alkaiCrossPromoLastSeenDate, handleAlkaiCrossPromoLastSeenDate);

export default reducerCreator.createReducer();

function updateUserData( state: UserState, action: Action<NormalizrData> ): UserState
{
    const userId = action.payload.result;
    const user: UserState = action.payload.entities.users[userId];
    return {
        ...state,
        ...user,
    };
}

function handleSetAuthToken( state: UserState, action: Action<string> ): UserState
{
    return {
        ...state,
        auth_token: action.payload,
    };
}

function handleSetClientId( state: UserState, action: Action<string> ): UserState
{
    return {
        ...state,
        client_id: action.payload,
    };
}

function handleSocialNetworkAccountInaccessible( state: UserState, action: Action<SocialNetworkAccountType> ): UserState
{
    let facebookTokenErrorCount = state.invalid_facebook_user_token_error_count || 0;
    const accounts = state.social_network_accounts ? state.social_network_accounts.slice() : [];

    if ( action.payload === FACEBOOK_ACCOUNT_TYPE )
    {
        facebookTokenErrorCount++;
    }
    else
    {
        forEach( accounts, ( account ) =>
        {
            if ( account.account_type === action.payload )
            {
                account.invalid_token_error_count += 1;
            }
        } );
    }

    return {
        ...state,
        invalid_facebook_user_token_error_count: facebookTokenErrorCount,
        social_network_accounts: accounts,
    };
}

function handleSocialNetworkAccountsHavePermissions( state: UserState, action: Action<string[]> ): UserState
{
    const accounts = map( state.social_network_accounts, ( account ) =>
    {
        if ( includes( action.payload, account.network_id ) )
        {
            return { ...account, needsMorePermissions: false };
        }
        else
        {
            return account;
        }
    } );

    return {
        ...state,
        social_network_accounts: accounts,
    };
}

function handleStockMediaTermsUpdated( state: UserState, action: Action<boolean> ): UserState
{
    return {
        ...state,
        has_accepted_shutterstock_terms_of_use: action.payload,
    };
}

function handleAcceptedTestDrive( state: UserState ): UserState
{
    return {
        ...state,
        has_accepted_test_drive: true,
    };
}

function handleSeenInspirePage( state: UserState ): UserState
{
    return {
        ...state,
        has_seen_inspire_page: true,
    };
}

function handleFirstProPostShared( state: UserState ): UserState
{
    return {
        ...state,
        has_shared_first_pro_post: true,
    };
}

function handleSubscriptionInfo( state: UserState, action: Action<SubscriptionInfoAPIData> ): UserState
{
    return {
        ...state,
        subscription_info: {
            ...state.subscription_info,
            ...action.payload,
        },
    };
}

function handleStripeUserPaymentMethodSuccess( state: UserState, action: Action<StripePaymentMethodData> ): UserState
{
    return {
        ...state,
        subscription_info: {
            ...state.subscription_info,
            default_stripe_payment_method: action.payload,
        },
    };
}

function handleShowYouAroundDisplayed( state: UserState ): UserState
{
    return {
        ...state,
        show_you_around_displayed: true,
    };
}

function handlePostIntentsDisplayed( state: UserState ): UserState
{
    return {
        ...state,
        post_intents_displayed: true,
    };
}

function handlePostIntentsClosed( state: UserState ): UserState
{
    return {
        ...state,
        post_intents_closed: true,
    };
}

function handleSafariAudioSyncWarningDisplayed( state: UserState ): UserState
{
    return {
        ...state,
        safari_audio_sync_warning_displayed: true,
    };
}

function handleSetCurrencyCode( state: UserState, action: Action<CurrencyCode> ): UserState
{
    return {
        ...state,
        currency_code: action.payload,
    };
}

function handleEpidemicSoundReauthorizationRequired( state: UserState )
{
    return {
        ...state,
        epidemicSound: omit( state.epidemicSound, "refreshTokenValidUntil" ),
    };
}

function handleHasOptedOutOfAlkaiCrossPromo( state: UserState  )
{
    return {
        ...state,
        hasOptedOutOfAlkaiCrossNewPromo: true,
    };
}
function handleHasSeenAlkaiCrossPromoCount( state: UserState  )
{
    let seenAlkaiPromoCount = state.hasSeenAlkaiCrossPromoNewCount || 0;
    let optedOut = state.hasOptedOutOfAlkaiCrossNewPromo || false;

    if ( !optedOut )
    {
        seenAlkaiPromoCount++;
        if (seenAlkaiPromoCount >= 10)
        {
            optedOut = true;
        }
    }
    return {
        ...state,
        hasSeenAlkaiCrossPromoNewCount: seenAlkaiPromoCount,
        hasOptedOutOfAlkaiCrossNewPromo: optedOut,
    };
}

function handleAlkaiCrossPromoLastSeenDate( state: UserState, action: Action<string> )
{
    return {
        ...state,
        alkaiCrossNewPromoLastSeenDate: action.payload,
    };
}

export function getCurrentUser( state: StoreState )
{
    return state.user;
}

export function getCurrentUserId( state: StoreState )
{
    return state.user.id;
}

export function getUserFacebookId( state: StoreState ): string
{
    return state.user.facebook_user_id;
}

export function getUserFacebookInvalidTokenErrorCount( state: StoreState ): number
{
    return state.user.invalid_facebook_user_token_error_count || 0;
}

export function isUserConnectedToFacebook( state: StoreState ): boolean
{
    return !!getUserFacebookAccessToken( state );
}

export function getUserFacebookAccessToken( state: StoreState ): string
{
    return state.user.facebook_access_token;
}

export function getUserFullName( state: StoreState ): string
{
    return state.user.first_name + " " + state.user.last_name;
}

export function getUserFirstName( state: StoreState ): string
{
    return state.user.first_name;
}

export function getUserLastName( state: StoreState ): string
{
    return state.user.last_name;
}

export function getUserEmail( state: StoreState ): string
{
    return state.user.email;
}

export function getUserValidEmail( state: StoreState ): string
{
    return state.user.valid_email;
}

export function getUserClientId( state: StoreState ): string
{
    return state.user.client_id || CLIENT_ID;
}

export function getUserAuthToken( state: StoreState ): string
{
    return state.user.auth_token;
}

export function getUserSlug( state: StoreState ): string
{
    return state.user.slug;
}

export function getUserCurrencyCode( state: StoreState ): CurrencyCode
{
    return state.user.currency_code || USD_CODE;
}

export function isUserLoggedIn( state: StoreState ): boolean
{
    return !!getUserAuthToken( state );
}

export function getUserCombinedSubscriptionInfo( state: StoreState ): Array<StripeSubscriptionInfoData | NonStripeSubscriptionInfoData>
{
    if ( !state.user.subscription_info )
    {
        return [];
    }
    const subscriptionInfos: Array<StripeSubscriptionInfoData | NonStripeSubscriptionInfoData> = state.user.subscription_info.stripe_subscriptions;
    return compact( concat( subscriptionInfos, state.user.subscription_info.google_subscription, state.user.subscription_info.apple_subscription ) );
}

export function getCurrentProductTier( state: StoreState ): ProductTier
{
    return state.user.product_tier || RIPL_TIER_NONE;
}

export function isCurrentProductTierLegacy( state: StoreState ): boolean
{
    return getCurrentProductTier( state ) === RIPL_TIER_LEGACY;
}

export function isCurrentProductTierPremium( state: StoreState ): boolean
{
    return getCurrentProductTier( state ) === RIPL_TIER_PREMIUM;
}

export function isCurrentProductTierBase( state: StoreState ): boolean
{
    return getCurrentProductTier( state ) === RIPL_TIER_BASE;
}

export function isSubscribedToBaseOrPremiumTier( state: StoreState ): boolean
{
    return isCurrentProductTierBase( state ) || isCurrentProductTierPremium( state );
}

export function shouldBeUpsoldToPremium( state: StoreState ): boolean
{
    const productTier = getCurrentProductTier( state );
    return includes( RIPL_TIERS_TO_UPSELL, productTier );
}

export function canSchedulePosts( state: StoreState ): boolean
{
    return hasAccessThroughLegacyPremiumOrTeams( state );
}

export function canUseEasyCaption( state: StoreState ): boolean
{
    return hasAccessThroughLegacyPremiumOrTeams( state );
}

export function canUsePremiumMusic( state: StoreState ): boolean
{
    return hasAccessThroughLegacyPremiumOrTeams( state );
}

function hasAccessThroughLegacyPremiumOrTeams( state: StoreState ): boolean
{
    return isCurrentProductTierLegacy( state )
           || isCurrentProductTierPremium( state )
           || doesCurrentBusinessSupportTeamFeatures( state );
}

export function getStripeCustomerId( state: StoreState ): string
{
    if ( !state.user.subscription_info )
    {
        return null;
    }
    const stripeSubscriptions = state.user.subscription_info.stripe_subscriptions;
    if ( stripeSubscriptions && stripeSubscriptions.length > 0 )
    {
        // TODO how do we handle multiple stripe subscriptions?
        const stripeSubscriptionInfo = stripeSubscriptions[0];
        return stripeSubscriptionInfo.stripe_customer_id;
    }
    return null;
}

export const SOCIAL_NETWORK_ACCOUNT_FILTER_MODE_CURRENT_BUSINESS = "current";
export const SOCIAL_NETWORK_ACCOUNT_FILTER_MODE_NO_BUSINESS = "none";
export const SOCIAL_NETWORK_ACCOUNT_FILTER_MODE_NOT_CURRENT_BUSINESS = "not_current";
export const SOCIAL_NETWORK_ACCOUNT_FILTER_MODE_ALL = "all";
export declare type SocialNetworkAccountFilterMode =
    typeof SOCIAL_NETWORK_ACCOUNT_FILTER_MODE_CURRENT_BUSINESS
    | typeof SOCIAL_NETWORK_ACCOUNT_FILTER_MODE_NO_BUSINESS
    | typeof SOCIAL_NETWORK_ACCOUNT_FILTER_MODE_NOT_CURRENT_BUSINESS
    | typeof SOCIAL_NETWORK_ACCOUNT_FILTER_MODE_ALL;

function createFilterByBusinessId( state: StoreState, filterMode: SocialNetworkAccountFilterMode )
{
    if ( filterMode === SOCIAL_NETWORK_ACCOUNT_FILTER_MODE_ALL )
    {
        return ( account ) => true;
    }
    else if ( filterMode === SOCIAL_NETWORK_ACCOUNT_FILTER_MODE_CURRENT_BUSINESS )
    {
        const businessId = getCurrentBusinessId( state ) || undefined;
        return ( account ) =>
        {
            const socialNetworkAccountBusinessId = account.user_business_id || undefined;
            return socialNetworkAccountBusinessId === businessId;
        };
    }
    else if ( filterMode === SOCIAL_NETWORK_ACCOUNT_FILTER_MODE_NOT_CURRENT_BUSINESS )
    {
        const businessId = getCurrentBusinessId( state );
        return ( account ) => !account.user_business_id || account.user_business_id !== businessId;
    }
    else
    {
        return ( account ) => !account.user_business_id;
    }
}

export function getAvailableSocialNetworkAccounts( state: StoreState )
{
    const userAccounts = state.user.social_network_accounts || [];
    const businessAccounts = getCurrentBusiness( state ) && getCurrentBusiness( state ).social_network_accounts || [];
    return unionBy( userAccounts, businessAccounts, "id" );
}

export function getTwitterSocialNetworkAccounts( state: StoreState )
{
    return filterAvailableSocialNetworkAccount( state, TWITTER_ACCOUNT_TYPE );
}

export function getLinkedInSocialNetworkAccounts( state: StoreState )
{
    return filterAvailableSocialNetworkAccount( state, LINKEDIN_ACCOUNT_TYPE );
}

export function getYoutubeSocialNetworkAccounts( state: StoreState )
{
    return filterAvailableSocialNetworkAccount( state, YOUTUBE_ACCOUNT_TYPE );
}

export function getInstagramSocialNetworkAccounts( state: StoreState )
{
    return filterAvailableSocialNetworkAccount( state, INSTAGRAM_ACCOUNT_TYPE );
}

export function getFacebookInstagramSocialNetworkAccounts( state: StoreState )
{
    return filterAvailableSocialNetworkAccount( state, FACEBOOK_INSTAGRAM_ACCOUNT_TYPE );
}

export function getFacebookSocialNetworkAccounts( state: StoreState )
{
    return filterAvailableSocialNetworkAccount( state, FACEBOOK_ACCOUNT_TYPE );
}

export function getFacebookGroupSocialNetworkAccounts( state: StoreState )
{
    return filterAvailableSocialNetworkAccount( state, FACEBOOK_GROUP_ACCOUNT_TYPE );
}

export function getFacebookPageSocialNetworkAccounts( state: StoreState )
{
    return filterAvailableSocialNetworkAccount( state, FACEBOOK_PAGE_ACCOUNT_TYPE );
}

export function getAllFacebookAndFacebookInstagramTypeSocialNetworkAccounts( state: StoreState )
{
    const facebookSocialNetworkAccounts = getFacebookSocialNetworkAccounts( state );
    const facebookGroupSocialNetworkAccounts = getFacebookGroupSocialNetworkAccounts( state );
    const facebookPageSocialNetworkAccounts = getFacebookPageSocialNetworkAccounts( state );
    const facebookInstagramSocialNetworkAccounts = getFacebookInstagramSocialNetworkAccounts( state );
    const allAccounts: SocialNetworkAccount[] = concat( facebookSocialNetworkAccounts,
        facebookGroupSocialNetworkAccounts,
        facebookPageSocialNetworkAccounts,
        facebookInstagramSocialNetworkAccounts );
    return allAccounts;
}

function filterAvailableSocialNetworkAccount( state: StoreState, accountType: string )
{
    const accounts = getAvailableSocialNetworkAccounts( state );
    return filter( accounts, ( account ) => account.account_type === accountType );
}

export function getAppIntegrationUserSocialNetworkAccounts( state: StoreState ): SocialNetworkAccount[]
{
    const accounts = getAvailableSocialNetworkAccounts( state );
    const businessIdFilter = createFilterByBusinessId( state, SOCIAL_NETWORK_ACCOUNT_FILTER_MODE_CURRENT_BUSINESS );
    const accountsForBusiness = filter( accounts, businessIdFilter );
    return filter( accountsForBusiness,
        ( account ) => includes( SOCIAL_NETWORKS_CAN_USE_FOR_INTEGRATION, account.account_type ) );
}

export function getUserSocialNetworkAccounts( state: StoreState,
                                              filterMode: SocialNetworkAccountFilterMode = SOCIAL_NETWORK_ACCOUNT_FILTER_MODE_CURRENT_BUSINESS,
): SocialNetworkAccount[]
{
    const accounts = getAvailableSocialNetworkAccounts( state );
    const businessIdFilter = createFilterByBusinessId( state, filterMode );
    const accountsForBusiness = filter( accounts, businessIdFilter );
    const filteredAccounts = filter( accountsForBusiness,
        ( account ) => isAllowedSocialNetworkConnectType( account.account_type ) );

    const facebookAccessToken = getUserFacebookAccessToken( state );
    setFacebookAccessTokenIntoFacebookItemAccounts( filteredAccounts, facebookAccessToken );

    const facebookUserId = getUserFacebookId( state );
    if ( !!facebookUserId && filterMode === SOCIAL_NETWORK_ACCOUNT_FILTER_MODE_CURRENT_BUSINESS )
    {
        const fullName = getUserFullName( state );
        const invalidFacebookUserTokenErrorCount = getUserFacebookInvalidTokenErrorCount( state );
        const facebookSocialNetworkAccount = createFacebookSocialNetworkAccount( facebookUserId,
            fullName,
            facebookAccessToken,
            invalidFacebookUserTokenErrorCount,
            getCurrentUserId( state ) );
        return [...filteredAccounts, facebookSocialNetworkAccount];
    }

    return filteredAccounts.slice();
}

export function isUserSubscribedIncludingProPreview( state: StoreState ): boolean
{
    const subscriptionStatus = getSubscriptionStatus( state );
    return includes( ALL_USER_SUBSCRIBED_STATUSES, subscriptionStatus )
           || doesCurrentBusinessSupportTeamFeatures( state );
}

export function isUserTrialing( state: StoreState ): boolean
{
    const subscriptionStatus = getSubscriptionStatus( state );
    return subscriptionStatus === SUBSCRIPTION_STATUS_TRIAL;
}

export function isUserPersonallySubscribed( state: StoreState )
{
    const subscriptionStatus = getSubscriptionStatus( state );
    return includes( ALL_PAYING_USER_SUBSCRIBED_STATUSES, subscriptionStatus );
}

export function canUserAccessPaidFeatures( state: StoreState ): boolean
{
    return isUserPersonallySubscribed( state ) || doesCurrentBusinessSupportTeamFeatures( state );
}

export function isUserPaidThroughTeam( state: StoreState ): boolean
{
    return canUserAccessPaidFeatures( state ) && !isUserPersonallySubscribed( state ) && hasTeamMemberPermissionsForCurrentBusiness( state );
}

export function isSubscriptionOnHold( state: StoreState ): boolean
{
    return !!state.user.subscription_on_hold;
}

export function shouldBeShownProPreviewWelcomeDialog( state: StoreState ): boolean
{
    return !canUserAccessPaidFeatures( state ) && !hasAcceptedTestDriveTerms( state ) && !browserUtils.isMobileBrowser();
}

export function isUserProPreview( state: StoreState ): boolean
{
    const subscriptionStatus = getSubscriptionStatus( state );
    return includes( SUBSCRIPTION_STATUS_PRO_PREVIEW, subscriptionStatus );
}

export function hasUserDoneProPreview( state: StoreState ): boolean
{
    const subscriptionStatus = getSubscriptionStatus( state );
    return includes( SUBSCRIPTION_STATUS_PRO_PREVIEW_ENDED, subscriptionStatus );
}

export const getSubscriptionStatus = ( state: StoreState ) => state.user.subscription_status;
export const getProductSku = ( state: StoreState ) => state.user.last_purchased_product_id;
export const getPaymentPlatform = ( state: StoreState ): PaymentPlatformType => state.user.payment_platform;

export function isUserStripeSubscribed( state: StoreState ): boolean
{
    return isUserPersonallySubscribed( state ) && doesUserHaveStripeAsPaymentPlatform( state );
}

export function isUserIOSSubscribed( state: StoreState ): boolean
{
    const paymentPlatform = getPaymentPlatform( state );
    return isUserPersonallySubscribed( state ) && paymentPlatform === PAYMENT_PLATFORM_IOS;
}

export function isUserSubscribedMonthlyStripe( state: StoreState ): boolean
{
    return isUserPersonallySubscribed( state )
           && isMonthlyStripeSku( state )
           && doesUserHaveStripeAsPaymentPlatform( state );
}

export function isMonthlyStripeSku( state: StoreState ): boolean
{
    const productSku = getProductSku( state );
    return includes( productSku, STRIPE_MONTHLY_SKU_SUBSTRING );
}

export function isUserSubscribedYearlyStripe( state: StoreState ): boolean
{
    return isUserPersonallySubscribed( state )
           && isYearlyStripeSku( state )
           && doesUserHaveStripeAsPaymentPlatform( state );
}

export function isYearlyStripeSku( state: StoreState ): boolean
{
    const productSku = getProductSku( state );
    return includes( productSku, STRIPE_YEARLY_SKU_SUBSTRING );
}

export function doesUserHaveStripeAsPaymentPlatform( state: StoreState ): boolean
{
    return getPaymentPlatform( state ) === PAYMENT_PLATFORM_STRIPE;
}

export function isCurrentBusinessAdmin( state: StoreState )
{
    const currentBusiness = getCurrentBusiness( state );
    return currentBusiness && getCurrentUserId( state ) === currentBusiness.admin_id;
}

export const hasCreatedFirstBusiness = ( state: StoreState ) => !!getCurrentBusinessId( state );

export function getBrandLogo( state: StoreState )
{
    const currentBusiness = getCurrentBusiness( state );
    if ( currentBusiness )
    {
        return currentBusiness.profile_image_url;
    }
    return state.user.profile_image_url;
}

export function showBrandLogo( state: StoreState )
{
    const currentBusiness = getCurrentBusiness( state );
    if ( currentBusiness )
    {
        return !!currentBusiness.show_business_logo_flag;
    }
    return !!state.user.show_logo_flag;
}

export function hasLogoSetupAndOn( state: StoreState )
{
    return hasNonDefaultBrandLogo( state ) && showBrandLogo( state );
}

export function hasNonDefaultBrandLogo( state: StoreState )
{
    const logoUrl = getBrandLogo(state);
    return logoUrl != null && logoUrl !== DEFAULT_BRAND_LOGO_URL;
}

export function isFacebookAccountType( accountType ): boolean
{
    return accountType === FACEBOOK_ACCOUNT_TYPE || isFacebookChildAccountType( accountType );
}

export const isFacebookInstagramAccountType = ( accountType ) => accountType === FACEBOOK_INSTAGRAM_ACCOUNT_TYPE;

export function isFacebookChildAccountType( accountType ): boolean
{
    return includes( [FACEBOOK_PAGE_ACCOUNT_TYPE, FACEBOOK_GROUP_ACCOUNT_TYPE, FACEBOOK_INSTAGRAM_ACCOUNT_TYPE], accountType );
}

function setFacebookAccessTokenIntoFacebookItemAccounts( accounts, facebookAccessToken )
{
    forEach( accounts, ( account ) =>
    {
        if ( account && isFacebookChildAccountType( account.account_type ) )
        {
            account.token = facebookAccessToken;
        }
    } );
}

function createFacebookSocialNetworkAccount( facebookUserId: string,
                                             fullName: string,
                                             accessToken: string,
                                             invalidUserTokenErrorCount: number,
                                             userId: number ): SocialNetworkAccount
{
    return {
        network_id: facebookUserId,
        account_type: FACEBOOK_ACCOUNT_TYPE,
        enabled: true,
        name: fullName,
        profile_image_url: getFacebookProfileImageUrl( facebookUserId ),
        token: accessToken,
        invalid_token_error_count: invalidUserTokenErrorCount,
        user_id: userId,
    };
}

export function getSocialNetworkAccountForId( state: StoreState, id: number ): SocialNetworkAccount
{
    const socialNetworkAccounts = getAvailableSocialNetworkAccounts( state );
    return find( socialNetworkAccounts, { id } );
}

export function getFacebookProfileImageUrl( facebookUserId: string ): string
{
    return `https://graph.facebook.com/${facebookUserId}/picture?type=square&width=200&height=200`;
}

export const getDisableViaRiplFlag = ( state: StoreState ) => !!state.user.disable_via_ripl_flag;

export function getUserPromotionNames( state: StoreState ): string []
{
    return map( state.user.promotions, ( promotion ) => promotion.name );
}

function isSupportedNoticeAction( name: string )
{
    return includes( NOTICE_ACTION_SUPPORTED_NAMES, name );
}

export function getSupportedNotices( state: StoreState ): SupportedNotice[]
{
    return filter( state.user.notices, ( notice ) => isSupportedNoticeAction( notice.action.name ) ) as SupportedNotice[];
}

export function getPrimaryStripeSubscriptionInfo( state: StoreState ): StripeSubscriptionInfoData
{
    const primarySubscriptionId = state.user.stripe_subscription_id;
    const subscriptionInfo = state.user.subscription_info;

    if ( !subscriptionInfo )
    {
        return null;
    }

    return find( subscriptionInfo.stripe_subscriptions, ( subscription ) => subscription.stripe_subscription_id === primarySubscriptionId );
}

export const getFirstSupportedNotice = ( state: StoreState ): SupportedNotice => first( getSupportedNotices( state ) );
export const hasUserAcceptedStockMediaTermsOfUse = ( state: StoreState ): boolean => !!state.user.has_accepted_shutterstock_terms_of_use;

export const hasSeenInspirePageBefore = ( state: StoreState ): boolean => !!state.user.has_seen_inspire_page;
export const hasSharedFirstProPost = ( state: StoreState ): boolean => !!state.user.has_shared_first_pro_post;
export const getDevMode = ( state: StoreState ): boolean => !!state.user.dev_mode;
export const isContentContributor = ( state: StoreState ): boolean => !!state.user.is_content_contributor;
export const hasAcceptedTestDriveTerms = ( state: StoreState ): boolean => !!state.user.has_accepted_test_drive;

export const getUserDefaultStripePaymentMethod = ( state: StoreState ) => state.user.subscription_info
                                                                          && state.user.subscription_info.default_stripe_payment_method;
export const hasSeenShowYouAround = ( state: StoreState ): boolean => !!state.user.show_you_around_displayed;
export const hasClosedPostIntents = ( state: StoreState ): boolean => !!state.user.post_intents_closed;
export const hasSeenPostIntents = ( state: StoreState ): boolean => !!state.user.post_intents_displayed;
export const hasSeenSafariAudioSyncWarning = ( state: StoreState ): boolean => !!state.user.safari_audio_sync_warning_displayed;
export const hasUserDismissedOrBypassedShowYouAround = ( state: StoreState ): boolean => state.user.show_you_around_displayed
                                                                                         || state.user.show_you_around_displayed === undefined;
export const getStripeSubscriptionId = ( state: StoreState ): string => state.user.stripe_subscription_id;
export const isRefreshTokenValid = ( state: StoreState ): boolean =>
{
    const epidemicSound = state.user.epidemicSound;

    return epidemicSound &&
           epidemicSound.refreshTokenValidUntil &&
           new Date( epidemicSound.refreshTokenValidUntil ).getTime() > now();
};

export const hasValidConnectionToEpidemicSound = ( state: StoreState ): boolean =>
{
    const epidemicSound = state.user.epidemicSound;

    if ( isNil( epidemicSound ) )
    {
        return false;
    }
    else
    {
        return isRefreshTokenValid( state );
    }
};

export const getPartner = ( state: StoreState ): string =>
{
    return state.user.partner;
};

export const isCollabraUser = ( state: StoreState ): boolean =>
{
    return !isEmpty( getPartner( state ) );
};

export const hasOptedOutOfAlkaiCrossPromo = ( state: StoreState ): boolean =>
{
  return state.user.hasOptedOutOfAlkaiCrossNewPromo;
};

export const hasSeenAlkaiCrossPromoToday = ( state: StoreState ): boolean =>
{
    const today = new Date(Date.now()).toDateString();
    const lastSeen = state.user.alkaiCrossNewPromoLastSeenDate;
    return lastSeen === today;
};
