import * as React from "react";
import DialogContainer from "react-md/lib/Dialogs/DialogContainer";
import Avatar from "react-md/lib/Avatars/Avatar";
import { ConfirmFacebookDialogData } from "../_types";
import Button from "react-md/lib/Buttons/Button";

export interface ConfirmFacebookDialogProps
{
    confirmFacebookDialogData: ConfirmFacebookDialogData;
    buttonNameForInstructions: string;
}

export interface ConfirmFacebookDialogDispatchProps
{
    handleCloseModal: () => void;
    handleConfirm: () => void;
    handleSwitch: ( buttonNameForInstructions: string ) => void;
}

interface ConfirmFacebookDialogState
{
    actions: DialogAction[];
}

interface DialogAction
{
    onClick: () => void;
    primary: boolean;
    swapTheming: boolean;
    className: string;
    children: string;
}

/* tslint:disable:max-line-length */
export class ConfirmFacebookDialog extends React.PureComponent<ConfirmFacebookDialogProps & ConfirmFacebookDialogDispatchProps, ConfirmFacebookDialogState>
{
    private switchAccountsAction: DialogAction;
    private confirmAction: DialogAction;

    constructor( props )
    {
        super( props );

        this.confirmAction = {
            onClick: this.props.handleConfirm,
            primary: true,
            swapTheming: true,
            className: "action-button dialogConfirm",
            children: "Yes, that's me",
        };

        this.switchAccountsAction = {
            onClick: this.handleSwitch,
            primary: false,
            swapTheming: true,
            className: "action-button dialogAlternate",
            children: "Switch accounts",
        };

        this.state = {
            actions: [this.switchAccountsAction, this.confirmAction],
        };
    }

    public render()
    {
        return (
            <DialogContainer
                id="facebook-group-add-ripl"
                className="confirmFacebookDialog"
                visible={this.props.confirmFacebookDialogData.isOpen}
                modal
                width={450}
                title="Is this you?"
                onHide={this.props.handleCloseModal}
                actions={this.state.actions}
                focusOnMount={false}>
                <Button icon={true}
                        className="closeX"
                        iconClassName="material-icons"
                        iconChildren="close"
                        onClick={this.props.handleCloseModal}/>
                <h3>We want to connect you to the right account.</h3>
                <div className="profile-container">
                    <Avatar className="profile-image" src={this.props.confirmFacebookDialogData.profileImageUrl} role="presentation"/>
                    <p className="profile-name">{this.props.confirmFacebookDialogData.name}</p>
                </div>
            </DialogContainer>
        );
    }

    private handleSwitch = () =>
    {
        this.props.handleSwitch( this.props.buttonNameForInstructions );
    }
}
