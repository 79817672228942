import { format, isSameDay, parse, startOfDay, startOfMonth } from "date-fns";
import { difference, filter, map, orderBy, reduce, reject, size, uniq } from "lodash";
import { Action } from "redux-actions";
import { postActions, PostActivityPayload, scheduledActionsActions, UserBusinessActionData, userBusinessActions } from "../actions";
import {
    ACTIVITY_KEY_MOST_RECENT_POSTS,
    ACTIVITY_KEY_UNSCHEDULED_POSTS,
    deleteFromLookup,
    getFallbackDate,
    postHelper,
    postsSchema,
    ReducerCreator,
    updateLookupWithAction,
    updateLookupWithData,
} from "../helpers";
import { getSelectedDate } from "./ui";
import { createSelector } from "reselect";
import { getDesignIdFromSlug } from "./designs";
import { getCurrentBusiness } from "./userBusiness";
import { createDefaultDataLookup } from "./dataLookup";
import { isDesignIdFreeOrFreemium, isDesignIdNew } from "./catalog";
import { LEGACY_POST_TYPE_EXTERNAL, LEGACY_POST_TYPE_RIPL, POST_TYPE_RIPL, POST_TYPE_STATUS } from "../_types/api";
import { Post, PostState, PriorityData, StoreState, UserBusiness } from "../_types";
import { store } from "../store";

const defaultState: PostState = {
    ...createDefaultDataLookup(),
    priorityData: {
        error: [],
        sent: [],
        scheduled: [],
    },
    activityMap: {},
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addCombinedActions( [postActions.mostRecentPostsDataSuccess,
                                    postActions.postDataForDateSuccess,
                                    postActions.loadUnscheduledPostsSuccess], handlePostDataForUserActivity );
reducerCreator.addAction( postActions.loadMoreMostRecentPostsDataSuccess, handleLoadMorePostDataForUserActivity );
reducerCreator.addCombinedActions( [scheduledActionsActions.loadedSuccess,
                                    postActions.finishSuccess,
                                    postActions.rescheduleSuccess], handlePosts );
reducerCreator.addAction( postActions.deleteSuccess, handleDelete );
reducerCreator.addAction( postActions.unscheduleSuccess, handleUnschedule );
reducerCreator.addAction( postActions.priorityDataSuccess, handlePriorityData );
reducerCreator.addCombinedActions( [userBusinessActions.businessSwitched,
                                    userBusinessActions.deleteSuccess],
    handleBusinessSwitchedOrDeleted );
export default reducerCreator.createReducer();

function handleBusinessSwitchedOrDeleted( state: PostState, action: Action<UserBusinessActionData> ): PostState
{
    return {
        ...defaultState,
    };
}

function handlePosts( state: PostState, action: Action<NormalizrData> ): PostState
{
    return updateLookupWithAction( postsSchema, action, state, true );
}

function handlePostDataForUserActivity( state: PostState, action: Action<PostActivityPayload> ): PostState
{
    const postData = action.payload.data;
    const updatedState = updateLookupWithData( postsSchema, postData, state, true );
    const activityKey = action.payload.activityKey;

    updatedState.activityMap[activityKey] = postData.result;
    return updatedState;
}

function handleLoadMorePostDataForUserActivity( state: PostState, action: Action<PostActivityPayload> ): PostState
{
    const postData = action.payload.data;
    const updatedState = updateLookupWithData( postsSchema, postData, state, true );
    const activityKey = action.payload.activityKey;

    updatedState.activityMap[activityKey] = uniq( [...updatedState.activityMap[activityKey], ...postData.result] );
    return updatedState;
}

function handleDelete( state: PostState, action: Action<number> ): PostState
{
    const removedId = action.payload;
    return deleteFromStateById( state, removedId );
}

function handleUnschedule( state: PostState, action: Action<NormalizrData> ): PostState
{
    const unscheduledState = updateLookupWithAction( postsSchema, action, createDefaultDataLookup() );

    const updatedState = reduce( unscheduledState.ids, ( accumulatorState, id ) =>
    {
        return deleteFromStateById( accumulatorState, id );
    }, state );

    return updatedState;
}

function deleteFromStateById( state: PostState, removedId )
{
    const updatedState = deleteFromLookup( state, removedId );
    const { activityMap, ...otherFields } = updatedState;

    const updatedActivityMap = reduce( activityMap, ( result, idList, key ) =>
    {
        result[key] = difference( idList, [removedId] );
        return result;
    }, {} as { [key: string]: number[] } );

    return {
        ...otherFields,
        activityMap: updatedActivityMap,
    };
}

function handlePriorityData( state: PostState, action: Action<{ month: Date, data: PriorityData }> ): PostState
{
    const { month, data } = action.payload;
    const priorityData: PriorityData = {
        error: combinePriorityData( state.priorityData, data, month, "error" ),
        sent: combinePriorityData( state.priorityData, data, month, "sent" ),
        scheduled: combinePriorityData( state.priorityData, data, month, "scheduled" ),
    };
    return {
        ...state,
        priorityData,
    };
}

function combinePriorityData( oldData: PriorityData, newData: PriorityData, month: Date, field: keyof PriorityData ): Date[]
{
    const filteredResults: Date[] = reject( oldData[field], ( date ) => startOfMonth( date ).getTime() === month.getTime() );
    return [...filteredResults, ...newData[field]];
}

const getActivityIds = ( state: StoreState ) => reduce( state.posts.activityMap, ( result, value, key ) =>
{
    return uniq( [...result, ...value] );
}, [] );

const getIdToObj = ( state: StoreState ) => state.posts.idToObj;

export const getPostById = ( id, state: StoreState ) => id && state.posts.idToObj[id];

const filterOutDrafts = ( post: Post ) => !postHelper.isDraft( post );
const filterOutDraftsAndScheduledPosts = ( post: Post ) => !postHelper.isDraft( post ) && !postHelper.isScheduled( post );
const filterOutAllButUnscheduledPosts = ( post: Post ) => postHelper.isUnscheduled( post );

export const isExternalPost = ( post: Post ): boolean => post && post.type === LEGACY_POST_TYPE_EXTERNAL;
export const isRiplPost = ( post: Post ): boolean => post && post.type === LEGACY_POST_TYPE_RIPL;

export const isEditablePost = ( post: Post ): boolean => post && (post.post_type === POST_TYPE_RIPL || !post.post_type);
export const isDownloadablePost = ( post: Post ): boolean => post && post.post_type !== POST_TYPE_STATUS;
export const wasPostedAsFacebookAd = ( post: Post ): boolean => post && !!post.facebook_ad;

export const isPostAccessibleByFreeUser = ( post: Post, store: StoreState ): boolean =>
{
    if ( isExternalPost( post ) || !isEditablePost( post ) )
    {
        return true;
    }

    const theDesignIdFromSlug = post && getDesignIdFromSlug( store, post.design_slug );
    return isDesignIdFreeOrFreemium( store, theDesignIdFromSlug );
};

export const isPostNew = ( post: Post, store: StoreState ): boolean =>
{
    if ( isExternalPost( post ) )
    {
        return false;
    }

    const theDesignIdFromSlug = post && getDesignIdFromSlug( store, post.design_slug );
    return isDesignIdNew( store, theDesignIdFromSlug );
};

const getActivityIdsForSelectedDate = ( state: StoreState ) => state.posts.activityMap[format( startOfDay( getSelectedDate( state ) ),
    "YYYY-MM-DD" )];

export const getPostsForSelectedDate = createSelector(
    [getActivityIdsForSelectedDate, getIdToObj, getCurrentBusiness, getSelectedDate],
    ( activityIds, idToObj, currentBusiness, selectedDate ): Post[] =>
    {
        return postCombiner( activityIds, idToObj, currentBusiness, selectedDate, false, filterOutDrafts );
    },
);

export const getPosts = createSelector(
    [getActivityIds, getIdToObj, getCurrentBusiness],
    ( activityIds, idToObj, currentBusiness ): Post[] =>
    {
        return postCombiner( activityIds, idToObj, currentBusiness, null, false, filterOutDrafts );
    },
);

const getActivityIdsForMostRecentPosts = ( state: StoreState ) => state.posts.activityMap[ACTIVITY_KEY_MOST_RECENT_POSTS];

export const getMostRecentPosts = createSelector(
    [getActivityIdsForMostRecentPosts, getIdToObj, getCurrentBusiness],
    ( activityIds, idToObj, currentBusiness ): Post[] =>
    {
        return postCombiner( activityIds, idToObj, currentBusiness, null, false, filterOutDraftsAndScheduledPosts );
    },
);

const getActivityIdsForUnscheduledPosts = ( state: StoreState ) => state.posts.activityMap[ACTIVITY_KEY_UNSCHEDULED_POSTS];

export const getUnscheduledPosts = createSelector(
    [getActivityIdsForUnscheduledPosts, getIdToObj, getCurrentBusiness],
    ( activityIds, idToObj, currentBusiness ): Post[] =>
    {
        return postCombiner( activityIds, idToObj, currentBusiness, null, false, filterOutAllButUnscheduledPosts );
    },
);

export function getCountOfMostRecentPostsMadeWithRipl( state: StoreState ): number
{
    return filter( getMostRecentPosts( store.getState() ), isRiplPost ).length;
}

export function getCountOfMostRecentPostsMadeOutsideRipl( state: StoreState ): number
{
    return filter( getMostRecentPosts( store.getState() ), isExternalPost ).length;
}

function postCombiner( activityIds, idToObj: { [p: string]: Post }, currentBusiness: UserBusiness, selectedDate,
                       sortByEngagement?: boolean, sendStatusFilter?: ( post: Post ) => void )
{
    let posts = map( activityIds, ( id ) => idToObj[id] );
    posts = filter( posts );

    if ( currentBusiness && currentBusiness.id )
    {
        posts = filter( posts, ( post ) => post.user_business_id === currentBusiness.id );
    }

    if ( currentBusiness && currentBusiness.hide_external_posts_flag )
    {
        posts = filter( posts, ( post ) => !isExternalPost( post ) );
    }

    if ( sendStatusFilter )
    {
        posts = filter( posts, sendStatusFilter );
    }

    if ( selectedDate )
    {
        posts = filter( posts, ( post ) => isSameDay( parse( getFallbackDate( post ) ), selectedDate ) );
    }

    if ( sortByEngagement )
    {
        posts = orderBy( posts, "fan_score", "desc" );
    }
    else
    {
        posts = sortPostsByDate( posts );
    }

    return posts;
}

function sortPostsByDate( posts )
{
    return posts.sort( ( a: Post, b: Post ) =>
    {
        return parse( getFallbackDate( b ) ).getTime() - parse( getFallbackDate( a ) ).getTime();
    } );
}

export function getUserHasPosted( state: StoreState )
{
    return state.posts.activityMap && size( state.posts.activityMap ) > 0;
}

export const getPriorityData = ( store: StoreState ): PriorityData => store.posts.priorityData;
