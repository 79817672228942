import { connect } from "react-redux";
import { Dispatch } from "redux";
import { store } from "../../store";
import { CreateBusinessDialog, CreateBusinessDialogDispatchProps, CreateBusinessDialogProps } from "../CreateBusinessDialog";
import { userBusinessServices, userServices } from "../../services";
import { DialogControlProps, StoreState } from "../../_types";
import { getBusinessData, getNewBusinessAPIData } from "../../ducks/ui";
import { getCurrentBusiness } from "../../ducks/userBusiness";
import { history, HOMEPAGE_URL } from "../../helpers";

const mapStateToProps = ( storeState: StoreState ): CreateBusinessDialogProps =>
{
    return {
        businessData: getBusinessData( storeState ),
        shouldShowAddBusinessNameStep: !!getCurrentBusiness( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: DialogControlProps ): CreateBusinessDialogDispatchProps =>
{
    return {
        createBusiness: async () =>
        {
            const newBusinessData = getNewBusinessAPIData( store.getState() );
            await dispatch( userBusinessServices.create( newBusinessData ) );
            ownProps.closeDialog();
            if ( userServices.shouldShowProOnlyUpsellDialog() )
            {
                // redirect to home to trigger the handlePageRedirectionAndUpsell method
                history.replace( HOMEPAGE_URL );
            }
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( CreateBusinessDialog );
