import { MUSIC_TYPE_EPIDEMIC_SOUND, MUSIC_TYPE_PERSONAL_MUSIC, MUSIC_TYPE_RIPL_MUSIC, MUSIC_TYPE_VIDEO_AUDIO } from "../_types/api";
import { Music, MusicType, StoreState } from "../_types";
import { Dispatch } from "redux";
import { generateMusicSelectionData } from "../ducks/mixModel";
import { mixModelActions, uiActions } from "../actions";
import { includes, join } from "lodash";
import { ALLOWED_MUSIC_EXTENSIONS } from "./constants";
import { stringUtils } from "./stringUtils";
import { eventTracker } from "./trackingEvents";

export const musicHelper = {
    isMusicTypeVideoAudio,
    isMusicTypePersonal,
    isMusicTypeRipl,
    isMusicTypeEpidemicSound,
    stopPlayingAudioTrack,
};

export function isMusicTypeVideoAudio( musicType: MusicType ): boolean
{
    return musicType === MUSIC_TYPE_VIDEO_AUDIO;
}

export function isMusicTypePersonal( musicType: MusicType ): boolean
{
    return musicType === MUSIC_TYPE_PERSONAL_MUSIC;
}

export function isMusicTypeRipl( musicType: MusicType ): boolean
{
    return musicType === MUSIC_TYPE_RIPL_MUSIC;
}

export function isMusicTypeEpidemicSound( musicType: MusicType ): boolean
{
    return musicType === MUSIC_TYPE_EPIDEMIC_SOUND;
}

export function getFileExtensionsForCustomMusicAcceptProp(): string
{
    return join( ALLOWED_MUSIC_EXTENSIONS, "," );
}

export function isUploadedMusicFileValid( file: File ): boolean
{
    const fileExtension = "." + stringUtils.getFileExt( file.name );
    return includes( ALLOWED_MUSIC_EXTENSIONS, fileExtension );
}

export function selectMusic( state: StoreState, dispatch: Dispatch<StoreState>, music: Music )
{
    const musicSelection = generateMusicSelectionData( state, music );
    dispatch( mixModelActions.musicSelected( musicSelection ) );
    eventTracker.logMusicItemSelected();
    dispatch( uiActions.setAudioPlayerMusicTrack( music ) );
}

export function selectVideoAudio( state: StoreState, dispatch: Dispatch<StoreState> )
{
    const musicSelection = generateMusicSelectionData( state, null, true );
    dispatch( mixModelActions.musicSelected( musicSelection ) );
    stopPlayingAudioTrack( dispatch );
    eventTracker.logMusicItemSelected();
}

function stopPlayingAudioTrack( dispatch: Dispatch<StoreState> )
{
    dispatch( uiActions.setAudioPlayerMusicTrack( undefined ) );
}
