import * as React from "react";
import { ASPECT_RATIO_VALUE, CanvasUpdater, DESIGN_CANVAS_ID_PARAM, DESIGN_CANVAS_URL, railsStringify } from "../helpers";
import * as VisibilitySensor from "react-visibility-sensor";
import { PreviewOverlay } from "./PreviewOverlay";
import { AspectRatio, Design } from "../_types";
import classNames from "classnames";
import DesignLoadProgressContainer from "./containers/DesignLoadProgress.container";
import { store } from "../store";
import { uiActions } from "../actions";

export interface DesignCanvasProps extends React.HTMLAttributes<HTMLDivElement>
{
    iframeId: string;
    editable: boolean;
    animatePreviewOnHover?: boolean;
    animatePreviewOnClick?: boolean;
    isBrandSlide?: boolean;
    aspectRatio: AspectRatio;
    design: Design;
    lazyLoad?: boolean;
    watermarkUrl: string;
    hideWatermark: boolean;
    lastRefreshed?: number;
    isSelectedDesign?: boolean;
    hidePlayButton?: boolean;
    hasDataRequiredToLoad: boolean;
    catalogUpdatedAt: string;
}

export interface DesignCanvasDispatchProps
{
    registerCanvas: ( CanvasUpdater ) => void;
    onDesignClicked?: ( design: Design, aspectRatio: AspectRatio ) => void;
    startDesignLoadProgress?( iframeId: string ): void;
}

interface DesignCanvasState
{
    showIframe: boolean;
}

export default class DesignCanvas extends React.PureComponent<DesignCanvasProps & DesignCanvasDispatchProps, DesignCanvasState>
{
    private canvasUpdater: CanvasUpdater;

    constructor( props )
    {
        super( props );
        this.state = {
            showIframe: !props.lazyLoad,
        };
    }

    // Keep this around for debugging
    // public componentWillReceiveProps( nextProps: DesignCanvasProps )
    // {
    //     logPropChanges( "DesignCanvas", this.props, nextProps );
    // }

    public componentDidMount()
    {
        this.setupCanvasListenerIfNeeded();
        if ( this.props.startDesignLoadProgress )
        {
            this.props.startDesignLoadProgress( this.props.iframeId );
        }
    }

    public componentDidUpdate()
    {
        this.setupCanvasListenerIfNeeded();
    }

    public componentWillUnmount()
    {
        if ( this.canvasUpdater )
        {
            this.canvasUpdater.onRemove();
        }
    }

    public render()
    {
        const aspectRatio = this.props.aspectRatio;
        const aspectRatioValue = ASPECT_RATIO_VALUE[aspectRatio];
        const fixedAspectRatioValue = aspectRatioValue.toFixed( 2 );
        const { hideWatermark, watermarkUrl, lastRefreshed } = this.props;
        const iframeParams: any = {
            [DESIGN_CANVAS_ID_PARAM]: this.props.iframeId,
            slug: this.props.design && this.props.design.slug,
            aspectRatio: fixedAspectRatioValue,
            watermarkUrl,
            hideWatermark,
            isPro: true,
            catalogUpdatedAt: this.props.catalogUpdatedAt,
        };
        if ( lastRefreshed )
        {
            iframeParams.lastRefreshed = lastRefreshed;
        }

        const iframeUrl = DESIGN_CANVAS_URL + "?" + railsStringify( iframeParams );
        return (
            <div
                className={classNames( "designCanvas", this.props.className )}
                onMouseEnter={this.handleOnMouseEnter}
                onMouseLeave={this.handleOnMouseLeave}
                style={{ "--aspect-ratio": fixedAspectRatioValue }}>
                <VisibilitySensor
                    onChange={this.handleVisibilityChange}
                    partialVisibility={true}
                    active={!this.state.showIframe}
                    scrollThrottle={100}>
                    <div>
                        {
                            this.isIframeReadyToLoad() && <iframe id={this.props.iframeId} className="designIframe" src={iframeUrl}/>
                        }
                    </div>
                </VisibilitySensor>
                {this.shouldShowDesignLoadingProgress() && <DesignLoadProgressContainer
                    iframeId={this.props.iframeId}/>}
                {
                    this.props.onDesignClicked &&
                    <div className="clickTarget" onClick={this.handleDesignClick}>
                        {
                            this.props.isSelectedDesign
                            && <PreviewOverlay hidePlayButton={this.props.hidePlayButton}/>
                        }
                    </div>
                }

            </div>
        );
    }

    private shouldShowDesignLoadingProgress()
    {
        return this.props.animatePreviewOnHover || this.props.animatePreviewOnClick;
    }

    private handleVisibilityChange = ( isVisible ) =>
    {
        if ( isVisible )
        {
            this.setState( { showIframe: true } );
        }
    }

    private handleDesignClick = () =>
    {
        this.props.onDesignClicked( this.props.design, this.props.aspectRatio );
        if ( this.props.animatePreviewOnClick && this.canvasUpdater )
        {
            this.canvasUpdater.tellDesignToReloadDesignWithAnimation();
            store.dispatch( uiActions.templatePreviewAnimationStarted() );
        }
    }

    private handleOnMouseEnter = () =>
    {
        if ( this.props.animatePreviewOnHover && this.canvasUpdater )
        {
            this.canvasUpdater.tellDesignToReloadDesignWithAnimation();
        }
    }

    private handleOnMouseLeave = () =>
    {
        if ( this.props.animatePreviewOnHover && this.canvasUpdater )
        {
            this.canvasUpdater.tellDesignToReloadDesignWithoutAnimationNorReveal();
        }
    }

    private setupCanvasListenerIfNeeded = () =>
    {
        if ( this.canvasUpdater || !this.isIframeReadyToLoad() )
        {
            return;
        }
        const updater = new CanvasUpdater( this.props.iframeId, this.props.editable, this.props.design, this.props.aspectRatio,
            this.props.isBrandSlide, this.props.animatePreviewOnHover, this.props.animatePreviewOnClick );
        updater.createDesignEventListener();
        this.props.registerCanvas( updater );
        this.canvasUpdater = updater;
    }

    private isIframeReadyToLoad = () =>
    {
        return this.state.showIframe && this.props.hasDataRequiredToLoad;
    }
}
