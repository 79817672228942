import * as React from "react";
import Checkbox from "react-md/lib/SelectionControls/Checkbox";

export interface CreateAccountEmailSubscribeCheckboxProps
{
    checked?: boolean;
    onChange?: ( checked: boolean, event: Event ) => void;
}

export const CreateAccountEmailSubscribeCheckbox: React.FC<CreateAccountEmailSubscribeCheckboxProps> = ( props ) =>
{
    return (
        <Checkbox
            className="emailCheckbox"
            id="emailSubscribe"
            name="emailSubscribe"
            checked={props.checked}
            onChange={props.onChange}
            label="Yes, keep me in the know with newsletter emails, promotional offers, and other Ripl news."
        />
    );
};
