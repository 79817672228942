import * as React from "react";
import Button from "react-md/lib/Buttons/Button";

export interface EpidemicSoundESDisconnectButtonProps
{
    userSlug: string;
}

export interface EpidemicSoundESDisconnectButtonDispatchProps
{
    onDisconnectClicked( userSlug: string );
}

export class EpidemicSoundESDisconnectButton extends React.PureComponent<EpidemicSoundESDisconnectButtonProps
    & EpidemicSoundESDisconnectButtonDispatchProps>
{
    public render()
    {
        return (
            <Button id="disconnect" flat secondary className="epidemicSoundESDisconnectButton" onClick={this.onDisconnectClicked}>Disconnect</Button>
        );
    }

    private onDisconnectClicked = () =>
    {
        this.props.onDisconnectClicked( this.props.userSlug );
    }
}
