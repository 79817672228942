import { connect } from "react-redux";
import { StoreState } from "../../../_types";
import { SettingsTab, SettingsTabProps } from "../SettingsTab";
import { getEditedBusinessInfo } from "../../../ducks/ui";
import { canDeleteCurrentBusiness, getCurrentBusiness } from "../../../ducks/userBusiness";

const mapStateToProps = ( storeState: StoreState ): SettingsTabProps =>
{
    return {
        currentBusiness: getCurrentBusiness( storeState ),
        editedBusinessInfo: getEditedBusinessInfo( storeState ),
        canDeleteCurrentBusiness: canDeleteCurrentBusiness( storeState ),
    };
};

export default connect(
    mapStateToProps,
)( SettingsTab );
