import { createAction } from "redux-actions";
import { ActionCreator } from "../helpers";

const actionCreator = new ActionCreator( "SCHEDULED_ACTION" );

const scheduledActionsActionsTypes = {
    loaded: actionCreator.generateTypes( "LOADED" ),
};

export const scheduledActionsActions = {
    loadedSuccess: createAction<NormalizrData>( scheduledActionsActionsTypes.loaded.success ),
    loadedFailure: createAction<string>( scheduledActionsActionsTypes.loaded.failure ),
};
