import { connect } from "react-redux";
import { Dispatch } from "redux";
import { getCurrentBusiness } from "../../ducks/userBusiness";
import { CollabraSharePageDispatchProps, CollabraSharePageProps, default as CollabraSharePage } from "../CollabraSharePage";
import {
    PARTNER_NOT_LOGGED_IN_URL,
    customizationHelper,
    CUSTOMIZE_PAGE_URL,
    ERROR_TITLE_OOPS,
    errorReporting,
    eventTracker,
    getInitialShareCaption,
    history,
    HOMEPAGE_URL,
} from "../../helpers";
import { logoutServices, modalServices, NO_LONGER_DRAFT_ERROR_MESSAGE, postServices } from "../../services";
import { StoreState } from "../../_types";
import { getVideoUrl, isAnimationOutputTypeFromState } from "../../ducks/shareModel";
import { catalogActions, contentSearchActions, shareModelActions, userActions } from "../../actions";
import { store } from "../../store";
import { hasSharedFirstProPost } from "../../ducks/user";
import { getMediaSourceList, getSelectedDesignId } from "../../ducks/mixModel";
import { isPostFinishInProgress } from "../../ducks/ui";
import { getRedirectUrl } from "../../ducks/partner";

const mapStateToProps = ( storeState: StoreState ): CollabraSharePageProps =>
{
    return {
        currentBusiness: getCurrentBusiness( storeState ),
        user: storeState.user,
        isPostFinishInProgress: isPostFinishInProgress( storeState ),
        allowedToShareNow: allowedToShareNow( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): CollabraSharePageDispatchProps =>
{
    return {
        onBackButtonClick: () =>
        {
            returnToCustomize( dispatch );
        },
        onBrowserBackButtonClick: () =>
        {
            returnToCustomize( dispatch );
        },
        onShareNowButtonClick: async () =>
        {
            const storeState = store.getState();
            eventTracker.logShareButtonTapped();

            if ( allowedToShareNow( storeState ) )
            {
                await modalServices.encloseInLoadingDialog( async () =>
                {
                    await shareNow( dispatch );
                } );
            }
            else
            {
                dispatch( modalServices.openErrorDialogWithStandardFormat(
                    "",
                    "Your video is being processed, please wait until it is finished before sharing." ) );
            }
        },
        onSharingComplete: () =>
        {
            emitCompletionEventsAndRedirect( dispatch );
        },
        initializeViaText: () =>
        {
            const caption = getInitialShareCaption( store.getState() );
            dispatch( shareModelActions.contentUpdated( caption ) );
        },
    };
};

async function shareNow( dispatch: Dispatch<StoreState> )
{
    try
    {
        await dispatch( postServices.updatePostWithData() );
        await dispatch( postServices.finishAndShareToPartner() );

        emitCompletionEventsAndRedirect( dispatch );
    }
    catch (error)
    {
        if ( error === NO_LONGER_DRAFT_ERROR_MESSAGE )
        {
            store.dispatch( modalServices.openNotADraftDialog() );
            history.replace( HOMEPAGE_URL );
        }
        else
        {
            eventTracker.logSharePostFailed( error );
            errorReporting.reportErrorToSentry( error );
            dispatch( modalServices.openErrorDialogWithStandardFormat(
                ERROR_TITLE_OOPS,
                "There was a problem sharing your post.", error ) );
        }
    }
}

async function emitCompletionEventsAndRedirect( dispatch: Dispatch<StoreState> )
{
    // It doesn't hurt to always do this.
    dispatch( contentSearchActions.searchModeExited() );

    const state = store.getState();

    eventTracker.logPostCompleted( getMediaSourceList( state ) );
    eventTracker.logSharePostSucceeded( getMediaSourceList( state ) );

    if ( !hasSharedFirstProPost( state ) )
    {
        eventTracker.logFirstProSharePostSucceeded();
        await dispatch( userActions.firstProPostShared() );
    }

    await dispatch( catalogActions.addDesignToFrontOfTray( getSelectedDesignId( state ) ) );
    await dispatch( shareModelActions.shareCompleted() );
    customizationHelper.cleanupCustomizeState();

    await dispatch( logoutServices.logoutWithoutRedirect() );
    history.replace( PARTNER_NOT_LOGGED_IN_URL );
    redirectToBestPageAfterSharing( state );
}

function redirectToBestPageAfterSharing( storeState: StoreState )
{
    window.open( getRedirectUrl( storeState ), "_self" );
}

function allowedToShareNow( storeState: StoreState )
{
    if ( isAnimationOutputTypeFromState( storeState ) )
    {
        return !!getVideoUrl( storeState, true );
    }

    return true;
}

function returnToCustomize( dispatch: Dispatch<StoreState> )
{
    customizationHelper.shareCancelled( dispatch );
    history.replace( CUSTOMIZE_PAGE_URL );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( CollabraSharePage );
