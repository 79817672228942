import { connect } from "react-redux";
import { ProfitWellWrapper, ProfitWellWrapperProps } from "../ProfitWellWrapper";
import { getCurrentUser } from "../../ducks/user";
import { StoreState } from "../../_types";

const mapStateToProps = ( storeState: StoreState ): ProfitWellWrapperProps =>
{
    const user = getCurrentUser( storeState );
    return {
        userEmail: user && user.email,
        forceUpdate: new Date(),
    };
};

export default connect(
    mapStateToProps,
)( ProfitWellWrapper );
