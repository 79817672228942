import * as React from "react";
import * as androidStore from "../assets/img/badge_android.png";
import * as iosStore from "../assets/img/badge_ios.png";
import { browserUtils, USER_CLICKED_SIGN_OUT_SOURCE_MOBILE_WALL } from "../helpers";
import LogoutLink from "./containers/LogoutLink.container";
import * as MobileWallDevices from "../assets/img/mobileWallDevices.png";
import * as MobileWallDesktop from "../assets/img/mobileWallDesktop.png";
import * as RiplLogoSymbolMarkImage from "../assets/img/ripl_logo_symbol_mark.png";
import * as CollabraPlusRiplLogo from "../assets/img/collabra_plus_ripl_logo.png";
import classNames from "classnames";

interface MobileWallProps
{
    isPayingUser?: boolean;
    shouldShowCollabraUI: boolean;
}

export const MobileBrowserWall: React.FC<MobileWallProps> = ( props: MobileWallProps ) =>
{

    return (
        <div className="mobileBrowserWall">
            {getMobileWallLogo( props.shouldShowCollabraUI )}
            {!props.shouldShowCollabraUI && getHeaderText( props.isPayingUser )}
            {getDescriptionText( props.isPayingUser, props.shouldShowCollabraUI )}
            {getMobileWallImage( props.shouldShowCollabraUI )}
            <div className="appLinks">
                {!browserUtils.isAndroidBrowser() && !props.shouldShowCollabraUI &&
                 <a className="link" href="https://s.ripl.com/iphone">
                     <img src={iosStore} alt="Download on the App Store"/>
                 </a>}
                {!browserUtils.isiOSBrowser() && !props.shouldShowCollabraUI &&
                 <a className="link" href="https://s.ripl.com/android">
                     <img src={androidStore} alt="Get it on Google Play"/>
                 </a>}
            </div>
            {!props.shouldShowCollabraUI && <LogoutLink source={USER_CLICKED_SIGN_OUT_SOURCE_MOBILE_WALL}/>}
        </div>
    );
};

function getHeaderText( payingUser: boolean )
{
    if ( payingUser )
    {
        return (<h1>You're almost there</h1>);
    }
    return (<h1>Get to know us better</h1>);
}

function getDescriptionText( payingUser: boolean, shouldShowCollabraUI: boolean )
{
    const storeText = browserUtils.isAndroidBrowser() ? "Play Store" : "App Store";
    const descriptionClass = classNames( { description: true } );

    if ( shouldShowCollabraUI )
    {
        return (<span
            className={descriptionClass}>Your screen size is too small to use this app. If you are on a mobile device, you will need to
                                         use this on your desktop. If you are on your desktop, maximize your screen width to use.</span>);
    }
    else if ( payingUser )
    {
        return (<span
            className={descriptionClass}>To start using Ripl go to <b>app.ripl.com</b> on your desktop or download from the {storeText}.</span>);
    }

    return (
        <span className={descriptionClass}>Check out our premium content and create with Ripl by going to <b>app.ripl.com</b> on your
                                           desktop or by downloading Ripl on the {storeText}.
        </span>);
}

function getMobileWallImage( shouldShowCollabraUI: boolean )
{
    if ( shouldShowCollabraUI )
    {
        return (<img className="resizeDesktopImg" src={MobileWallDesktop} alt="Resize your browser"/>);
    }
    return (<img className="mobileAndDesktopImg" src={MobileWallDevices} alt="Mobile and desktop"/>);
}

function getMobileWallLogo( shouldShowCollabraUI: boolean )
{
    if ( shouldShowCollabraUI )
    {
        return (<img className="riplLogo partnerLogo" src={CollabraPlusRiplLogo} alt="Collabra+Ripl logo"/>);
    }
    return (<img className="riplLogo" src={RiplLogoSymbolMarkImage} alt="Ripl logo"/>);
}
