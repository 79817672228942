import * as React from "react";
import CircularProgress from "react-md/lib/Progress/CircularProgress";

export interface CircularProgressWithTextProps
{
    className?: string;
    textClassName?: string;
    progressText: string;
    positionTextAbove?: boolean;
}

export class CircularProgressWithText extends React.PureComponent<CircularProgressWithTextProps>
{
    public render()
    {
        const { positionTextAbove } = this.props;
        const positionTextBelow = !positionTextAbove;
        const textClassNames = "progressText center" + this.props.textClassName;

        return (
            <div className={this.props.className}>
                {positionTextAbove && <div className={textClassNames}>{this.props.progressText}</div>}
                <CircularProgress className="spinner large" id="spinner"/>
                {positionTextBelow && <div className={textClassNames}>{this.props.progressText}</div>}
            </div>
        );
    }
}
