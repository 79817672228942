import { connect } from "react-redux";
import { Dispatch } from "redux";
import FacebookLoginButton, { FacebookLoginButtonDispatchProps, FacebookLoginButtonProps } from "../FacebookLoginButton";
import { uiActions } from "../../actions";
import { getFacebookProfileImageUrl } from "../../ducks/user";
import { modalServices, userServices } from "../../services";
import { ConfirmFacebookDialogData, StoreState } from "../../_types";
import { store } from "../../store";
import { getChannelTrackingParameters } from "../../ducks/tracking";
import { ERROR_TITLE_SORRY, getFacebookAppId, THIRD_PARTY_LOGIN_ERROR_MESSAGES } from "../../helpers";
import { isAcceptTeamInviteFlow } from "../../ducks/ui";

interface FacebookCreateAccountButtonContainerProps
{
    subscribeToEmail: boolean;
    showBusinessTypeSelection?: () => void;
}

const mapStateToProps = ( state: StoreState ): FacebookLoginButtonProps =>
{
    return {
        facebookAppId: getFacebookAppId(),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>,
                             ownProps: FacebookCreateAccountButtonContainerProps ): FacebookLoginButtonDispatchProps =>
{
    return {
        onFacebookLoginSuccess: ( facebookId: string, facebookAccessToken: string, name: string ) =>
        {
            const profileImageUrl = getFacebookProfileImageUrl( facebookId.toString() );
            const confirmFacebookDialogData: ConfirmFacebookDialogData =
                {
                    profileImageUrl,
                    name,
                    onConfirm: () =>
                    {
                        const storeState = store.getState();
                        const channelTrackingParameters = getChannelTrackingParameters( storeState );
                        dispatch( userServices.clientFacebookCreateAccount(
                            facebookId,
                            facebookAccessToken,
                            ownProps.subscribeToEmail,
                            channelTrackingParameters,
                            isAcceptTeamInviteFlow( storeState ),
                            ownProps.showBusinessTypeSelection ) );
                    },
                };

            dispatch( uiActions.confirmFacebookModalOpen( confirmFacebookDialogData ) );
        },
        onFacebookLoginFailure: ( response: string ) =>
        {
            dispatch( modalServices.openErrorDialog( ERROR_TITLE_SORRY, THIRD_PARTY_LOGIN_ERROR_MESSAGES.FACEBOOK_CREATE ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( FacebookLoginButton );
