import "core-js/es6/map";
import "core-js/es6/set";
import "raf/polyfill";
import * as RejectionTracking from "promise/lib/rejection-tracking";
import * as PromisePolyfill from "promise/lib/es6-extensions.js";
import * as ObjectAssignPolyfill from "object-assign";

if (typeof Promise === "undefined") {
    RejectionTracking.enable();
    (window as any).Promise = PromisePolyfill;
}

const isEdge = /Edge\//.test(navigator.userAgent);
if (isEdge)
{
    window.fetch = undefined;
}

import "whatwg-fetch";

Object.assign = ObjectAssignPolyfill;
