import { connect } from "react-redux";
import { Dispatch } from "redux";
import { FauxConnectButton, FauxConnectButtonDispatchProps } from "../FauxConnectButton";
import { instagramBusinessServices } from "../../services";
import { StoreState } from "../../_types";
import { SocialAccountConnectButtonProps } from "../SocialAccountConnectButton";
import { eventTracker } from "../../helpers";
import { FACEBOOK_INSTAGRAM_ACCOUNT_TYPE } from "../../_types/api";

const mapStateToProps = ( storeState: StoreState, ownProps: SocialAccountConnectButtonProps ): SocialAccountConnectButtonProps =>
{
    return {
        ...ownProps,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): FauxConnectButtonDispatchProps =>
{
    return {
        onConnectClicked: () =>
        {
            eventTracker.logSocialNetworkConnectClicked( FACEBOOK_INSTAGRAM_ACCOUNT_TYPE );
            dispatch( instagramBusinessServices.displayInstagramWarmupDialog() );
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( FauxConnectButton );
