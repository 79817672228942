import { connect } from "react-redux";
import { Dispatch } from "redux";
import { CreateBlankPostButtonSmall, CreateBlankPostButtonSmallDispatchProps } from "../CreateBlankPostButtonSmall";
import { mixModelServices } from "../../services";
import { StoreState } from "../../_types";
import { ALL_SOURCE } from "../../helpers";

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): CreateBlankPostButtonSmallDispatchProps =>
{
    return {
        handleClick: () =>
        {
            dispatch( mixModelServices.setupNewPostWithEventing( ALL_SOURCE ) );
        },
    };
};

export default connect(
    () => ({}),
    mapDispatchToProps,
)( CreateBlankPostButtonSmall );
