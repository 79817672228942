import * as React from "react";
import Layover from "react-md/lib/Helpers/Layover";
import Card from "react-md/lib/Cards/Card";
import Avatar from "react-md/lib/Avatars/Avatar";
import ListItem, { ListItemProps } from "react-md/lib/Lists/ListItem";
import Subheader from "react-md/lib/Subheaders/Subheader";
import List from "react-md/lib/Lists/List";
import { DEFAULT_MISSING_BUSINESS_NAME } from "../helpers";
import { UserBusiness } from "../_types";
import classNames from "classnames";
import { DropDownIcon } from "./svgs/DropDownIcon";
import * as NewNavPlus from "../assets/img/newNavPlus.png";
import { map, size } from "lodash";
import { BusinessWithRole, getBusinessProfileImage } from "../ducks/userBusiness";
import * as Crown from "../assets/img/crown-icon.svg";
import { ListItemRightAvatar } from "./ListItemRightAvatar";

export interface BusinessSwitcherProps
{
    currentBusiness?: UserBusiness;
    businesses: BusinessWithRole[];
    shouldBeUpsoldToPremium: boolean;
}

export interface BusinessSwitcherDispatchProps
{
    handleOpenCreateBusiness(): void;
    handleSwitchBusiness( businessId: number ): void;
}

interface SwitcherState
{
    visible: boolean;
}

export class BusinessSwitcher extends React.PureComponent<BusinessSwitcherProps & BusinessSwitcherDispatchProps, SwitcherState>
{
    private static readonly layoverBelowAnchor = { x: Layover.HorizontalAnchors.INNER_LEFT, y: Layover.VerticalAnchors.BOTTOM };

    constructor( props )
    {
        super( props );
        this.state = {
            visible: false,
        };
    }

    public render()
    {
        const currentBusinessName = this.props.currentBusiness && this.props.currentBusiness.name;
        return (
            <div className="headerBox switcherContainer">
                <div className="switcher">
                    <Layover
                        visible={this.state.visible}
                        onClose={this.hideDialog}
                        toggle={
                            <div className="businessSwitcherAndNameContainer clickable" onClick={this.showDialog}>
                                <div className="businessName">{currentBusinessName}
                                    <DropDownIcon/>
                                </div>
                            </div>
                        }
                        animationPosition={Layover.Positions.BELOW}
                        belowAnchor={BusinessSwitcher.layoverBelowAnchor}
                        toggleQuery=".businessSwitcherAndNameContainer">
                        {
                            this.props.currentBusiness ? this.createBusinessCard()
                                                       : this.createUserMenu()
                        }
                    </Layover>
                </div>
            </div>
        );
    }

    private createBusinessCard = () =>
    {
        return (
            <Card className="switcherMenu">
                <List className="businessList">
                    {map( this.props.businesses, ( { business, isCurrentUserAdmin } ) =>
                    {
                        const isSelected = business.id === this.props.currentBusiness.id;
                        const shouldShowIcon = !isCurrentUserAdmin || size( business.team_members ) > 1;

                        return (
                            <BusinessListItem key={business.id}
                                              business={business}
                                              selected={isSelected}
                                              handleSwitchBusiness={this.switchToBusiness}
                                              showTeamIconAndBadge={shouldShowIcon}
                            />
                        );
                    } )}
                </List>
                <List className="addNewBrandContainer">
                    <ListItem {...this.buildAddBrandProps()} />
                </List>
            </Card>
        );
    }

    private buildAddBrandProps = (): ListItemProps =>
    {
        const listItemProps: ListItemProps = {
            primaryText: "Add new business",
            onClick: this.openBusinessCreator,
            leftAvatar: (
                <Avatar className="addBusiness">
                    <img className="plus" src={NewNavPlus} alt="Add new business"/>
                </Avatar>
            ),
        };

        if ( this.props.shouldBeUpsoldToPremium )
        {
            listItemProps.leftAvatar = (
                <div className="premiumOnly">
                    <img className="crown" src={Crown} alt="Add new business"/>
                </div>
            );
        }

        return listItemProps;
    }

    private createUserMenu = () =>
    {
        return (
            <Card className="switcherMenu">
                <List>
                    <Subheader primaryText="Create Your Business Profile" primary/>
                    <ListItem primaryText="Set up a business profile"
                              secondaryText="so we can help you set goals."
                              leftAvatar={<Avatar>+</Avatar>}
                              onClick={this.openBusinessCreator}/>
                </List>
            </Card>
        );
    }

    private hideDialog = () =>
    {
        if ( this.state.visible )
        {
            this.setState( { visible: false } );
        }
    }

    private showDialog = () =>
    {
        if ( !this.state.visible )
        {
            this.setState( { visible: true } );
        }
    }

    private openBusinessCreator = () =>
    {
        this.hideDialog();
        this.props.handleOpenCreateBusiness();
    }

    private switchToBusiness = ( businessId: number ) =>
    {
        this.hideDialog();
        this.props.handleSwitchBusiness( businessId );
    }
}

export interface BusinessListItemProps
{
    business: UserBusiness;
    selected: boolean;
    showTeamIconAndBadge: boolean;
}

export interface BusinessListItemDispatchProps
{
    handleSwitchBusiness( businessId: number ): void;
}

export class BusinessListItem extends React.PureComponent<BusinessListItemProps & BusinessListItemDispatchProps>
{
    public render()
    {
        const profileImageUrl = getBusinessProfileImage( this.props.business );
        return (
            <ListItem primaryText={this.getFormattedBusinessName()}
                      onClick={this.switchToBusiness}
                      leftAvatar={<Avatar contentClassName="businessLogoImage"
                                          src={profileImageUrl}/>}
                      rightAvatar={<ListItemRightAvatar selected={this.props.selected}
                                                        showTeamIconAndBadge={this.props.showTeamIconAndBadge}/>}
            />
        );
    }

    private switchToBusiness = () =>
    {
        this.props.handleSwitchBusiness( this.props.business.id );
    }

    private getFormattedBusinessName = () =>
    {
        const businessName = this.props.business.name || DEFAULT_MISSING_BUSINESS_NAME;
        return (
            <div className={classNames( { isSelected: this.props.selected } )}>
                {businessName}
            </div>
        );
    }
}
