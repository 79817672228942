import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../_types";
import BrandSlideEditPanel, { BrandSlideEditPanelDispatchProps, BrandSlideEditPanelProps } from "../BrandSlideEditPanel";
import { mixModelActions } from "../../../actions";
import {
    getBrandSlideContactFromMixModel,
    getBrandSlideTaglineFromMixModel,
    getShowBrandSlideFromMixModel,
    getShowBrandSlideLogoFromMixModel,
} from "../../../ducks/mixModel";
import { userBusinessServices } from "../../../services";
import { BRAND_SLIDE_CONTACT_TEXT_FIELD_NAME, BRAND_SLIDE_TAGLINE_TEXT_FIELD_NAME } from "../../../_types/api";

const mapStateToProps = ( storeState: StoreState ): BrandSlideEditPanelProps =>
{
    return {
        showBrandSlideInMixModel: getShowBrandSlideFromMixModel( storeState ),
        showBrandSlideLogoInMixModel: getShowBrandSlideLogoFromMixModel( storeState ),
        tagline: getBrandSlideTaglineFromMixModel( storeState ),
        contact: getBrandSlideContactFromMixModel( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): BrandSlideEditPanelDispatchProps =>
{
    return {
        handleToggleShowBrandSlide( enabled: boolean ): void
        {
            dispatch( mixModelActions.brandSlideDataUpdated( { showEndCard: enabled } ) );
        },
        handleToggleShowBrandSlideLogo( enabled: boolean ): void
        {
            dispatch( mixModelActions.brandSlideDataUpdated( { endCardData: { showLogo: enabled } } ) );
        },
        onFieldChanged: ( fieldName, value, id? ) =>
        {
            switch ( fieldName )
            {
                case BRAND_SLIDE_TAGLINE_TEXT_FIELD_NAME:
                    dispatch( mixModelActions.brandSlideDataUpdated( { endCardData: { tagline: value } } ) );
                    break;
                case BRAND_SLIDE_CONTACT_TEXT_FIELD_NAME:
                    dispatch( mixModelActions.brandSlideDataUpdated( { endCardData: { contact: value } } ) );
                    break;
            }
        },
        handleComponentWillUnmount(): void
        {
            dispatch( userBusinessServices.updateBrandSlide() );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( BrandSlideEditPanel );
