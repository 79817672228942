import TextField, { TextFieldProps } from "react-md/lib/TextFields/TextField";
import * as React from "react";
import { INVALID_EMAIL_ADDRESS, validateEmail } from "../helpers";
import classNames from "classnames";
import { isEmpty, isNil, toString, trim } from "lodash";

export interface EmailPickerProps extends TextFieldProps
{
    shouldSuppressErrors?: boolean;
    hideErrorText?: boolean;
}

export interface EmailPickerState
{
    email: string;
}

export class EmailPicker extends React.Component<EmailPickerProps, EmailPickerState>
{
    public constructor( props )
    {
        super( props );
        this.state = {
            email: props.email || "",
        };
    }

    public render()
    {
        const { error, hideErrorText, shouldSuppressErrors, ...propsForTextField } = this.props;
        const errorText = hideErrorText || shouldSuppressErrors ? null : INVALID_EMAIL_ADDRESS;

        return (
            <TextField
                type="text"
                placeholder="Enter Email Address"
                className={classNames( "emailPicker", { hasError: this.hasError() } )}
                error={this.hasError()}
                errorText={errorText}
                {...propsForTextField}
                onChange={this.onChange}
            />
        );
    }

    private hasError = (): boolean =>
    {
        const { error, shouldSuppressErrors } = this.props;
        const { email } = this.state;

        const isEmptyEmail = isEmpty( trim( email ) );

        if ( shouldSuppressErrors )
        {
            return false;
        }
        else if ( !isNil( error ) )
        {
            return error;
        }
        else if ( isEmptyEmail )
        {
            return false;
        }
        else
        {
            return !validateEmail( email );
        }
    }

    private onChange = ( email: number | string, event: Event ): void =>
    {
        if ( this.props.onChange )
        {
            this.props.onChange( email, event );
        }

        this.setState( {
            email: toString( email ),
        } );
    }
}
