import * as React from "react";
import { BinocularsIcon } from "./svgs/BinocularsIcon";
import Button from "react-md/lib/Buttons/Button";

export interface ShowYouAroundSequenceProps
{
    goTo: ( step: number ) => void;
    close: () => void;
}

export const ShowYouAroundIntroDialog: React.FC<ShowYouAroundSequenceProps> = ( props ) =>
{

    function handleGoClicked()
    {
        props.goTo( 1 );
    }

    function handleNotNowClicked()
    {
        props.close();
    }

    return (
        <div className="showYouAroundDialog">
            <BinocularsIcon/>
            <h2 className="showYouAroundDialogTitle">Let’s show you around!</h2>
            <div>
                Take a closer look at three key features to<br/>help you get social done!
            </div>
            <div className="showYouAroundButtons intro">
                <Button className="notNowButton" flat onClick={handleNotNowClicked}>Not now</Button>
                <Button className="nextButton" flat onClick={handleGoClicked}>Go</Button>
            </div>
        </div>
    );
};
