import * as React from "react";
import { lowerCase, map } from "lodash";
import { RIPL_TIER_PREMIUM, TierFeatureList } from "../helpers";
import classNames from "classnames";
import * as Crown from "../assets/img/crown-icon.svg";
import Button from "react-md/lib/Buttons/Button";
import { ExplanationFeatureItemFromKey } from "./ExplanationFeatureItem";
import { TiersExplanationTabDispatchProps, TiersExplanationTabProps } from "./TiersExplanationMobileTab";

export function TiersExplanationDesktopTab( props: TiersExplanationTabProps & TiersExplanationTabDispatchProps )
{
    const { data, selectTier } = props;
    const { priceFormatted, commissionRateFormatted, tierName, tier } = data;
    const tierClassName = lowerCase( tier );
    const isPremium = tier === RIPL_TIER_PREMIUM;
    const supportedFeaturesList = buildSupportedFeaturesList( data.tier, data.features );

    return (
        <div key={tier} className={classNames( `${tierClassName}Tier` )}>
            <div className={classNames( `${tierClassName}TierHeader` )}>
                <h1 className="text bold detail tierHeader">{tierName} Plan</h1>
                {isPremium &&
                 <div>
                     <img src={Crown} alt="Crown"/>
                 </div>
                }
            </div>
            <div className="tierSubtitle">from {priceFormatted}</div>

            <div className="featureList">
                {supportedFeaturesList}
                <div className={classNames( "commissionFeePercentage", { zero: isPremium } )}>{commissionRateFormatted}</div>
                {!isPremium && <div className="commissionFeeSubtitle">(first ad runs free)</div>}
            </div>

            <Button flat primary swapTheming onClick={() => selectTier( tier )}>
                Select {tierName}
                <span className="textThatSaysPlan"> Plan</span>
            </Button>
        </div>
    );
}

function buildSupportedFeaturesList( tier: ProductTier, features: TierFeatureList<boolean> )
{
    return map( features, ( isSupported, featureKey ) =>
        <ExplanationFeatureItemFromKey
            key={featureKey}
            tier={tier}
            isSupported={isSupported}
            featureKey={featureKey}
            showDescription={false}
        />,
    );
}
