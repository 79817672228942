import * as React from "react";
import { PurchaseProgressSpinner } from "./PurchaseProgressSpinner";
import { TiersExplanationOfBenefitsForDesktop } from "./TiersExplanationOfBenefitsForDesktop";
import { TierTabData } from "../helpers";
import { TiersExplanationOfBenefitsForMobile } from "./TiersExplanationOfBenefitsForMobile";

export interface TiersExplanationOfBenefitsProps
{
    isLoading: boolean;
    tierTabs: TierTabData[];
    selectedTab: ProductTier;
}

export interface TiersExplanationOfBenefitsDispatchProps
{
    selectTier( tier: ProductTier ): void;
    selectTab( tier: ProductTier ): void;
}

export class TiersExplanationOfBenefits extends React.PureComponent<TiersExplanationOfBenefitsProps & TiersExplanationOfBenefitsDispatchProps>
{
    public render()
    {
        if ( this.props.isLoading )
        {
            return <PurchaseProgressSpinner/>;
        }
        else
        {
            return <>
                <TiersExplanationOfBenefitsForMobile {...this.props} />
                <TiersExplanationOfBenefitsForDesktop {...this.props} />
            </>;
        }
    }

}
