import * as React from "react";
import BackgroundMediaColorControlPickerContainer from "./containers/BackgroundMediaColorControlPicker.container";
import BackgroundMediaGridContainer from "./containers/BackgroundMediaGrid.container";

export class BackgroundMediaPickerPanel extends React.PureComponent<{}>
{
    public render()
    {
        return (
            <div className="backgroundMediaPicker picker">
                <h3>Background settings</h3>
                <BackgroundMediaColorControlPickerContainer/>
                <h5>Add an image</h5>
                <BackgroundMediaGridContainer/>
            </div>
        );
    }
}
