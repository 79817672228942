import * as React from "react";
import {
    SUBSCRIPTION_FLOW_TO_PRIMARY_BENEFIT,
    SubscriptionFlowSource,
    TEAMS_PREMIUM_BENEFITS,
} from "../helpers";
import { first, get, map, uniq } from "lodash";
import { PremiumBenefitItem } from "./PremiumBenefitItem";

export interface PremiumBenefitsProps
{
    source: SubscriptionFlowSource;
}

export function PremiumBenefits( props: PremiumBenefitsProps )
{
    const benefitsToShow = getBenefits( props.source );

    return (
        <div className="premiumBenefits">
            <div className="benefitsList">
                {map( benefitsToShow, ( benefit ) => <PremiumBenefitItem key={benefit} description={benefit}/> )}
            </div>
        </div>
    );
}

function getBenefits( source: SubscriptionFlowSource ): string[]
{
    return uniq( [getBenefitToShowFirst( source ), ...TEAMS_PREMIUM_BENEFITS] );
}

function getBenefitToShowFirst( source: SubscriptionFlowSource ): string
{
    return get( SUBSCRIPTION_FLOW_TO_PRIMARY_BENEFIT, source, first( TEAMS_PREMIUM_BENEFITS ) );
}
