import { FIRST_RUN_PARAM, FORGOT_PASSWORD_PARAM } from "./";

export const windowLocationHelper = {
    hasFirstRunQuery,
    hasForgotPasswordQuery,
};

function hasFirstRunQuery()
{
    return window.location.search.includes( FIRST_RUN_PARAM );
}

function hasForgotPasswordQuery()
{
    return window.location.search.includes( FORGOT_PASSWORD_PARAM );
}
