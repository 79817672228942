import * as React from "react";
import { CSSProperties } from "react";
import { ChromePicker, CustomPicker } from "react-color";
import { ExportedColorProps, InjectedColorProps } from "react-color/lib/components/common/ColorWrap";
import Button from "react-md/lib/Buttons/Button";
import * as GradientPickerButton from "../../assets/img/gradientPickerButton.png";

interface GradientColorPickerState
{
    displayGradientPicker: boolean;
    gradientPickerPositionCSS: CSSProperties;
}

class GradientColorPicker extends React.PureComponent<InjectedColorProps & ExportedColorProps, GradientColorPickerState>
{
    private gradientPickerButtonRef: React.RefObject<HTMLImageElement>;

    public constructor( props )
    {
        super( props );
        this.state = { displayGradientPicker: false, gradientPickerPositionCSS: {} };
        this.gradientPickerButtonRef = React.createRef();
    }

    public render()
    {
        return (<div className="gradientColorPickerWrap">
            <img ref={this.gradientPickerButtonRef} className="gradientPickerButton" onClick={this.toggleGradientPicker} src={GradientPickerButton}/>

            {this.state.displayGradientPicker ? <div className="gradientPickerPopover" style={this.state.gradientPickerPositionCSS}>
                <div className="invisibleOverlay" onClick={this.handleClose}/>
                <Button icon
                        className="closeX tipCloseButton"
                        iconClassName="material-icons"
                        iconChildren="close"
                        onClick={this.handleClose}/>
                <ChromePicker disableAlpha={true} color={this.props.color} onChangeComplete={this.props.onChangeComplete}/>
            </div> : null}
        </div>);
    }

    private toggleGradientPicker = () =>
    {
        const newState = !this.state.displayGradientPicker;
        this.setState( { displayGradientPicker: newState } );
        if ( newState )
        {
            this.calculateTopOffsetForGradientPicker();
        }
    }

    private handleClose = () =>
    {
        this.setState( { displayGradientPicker: false } );
    };

    private calculateTopOffsetForGradientPicker = () =>
    {
        const button = this.gradientPickerButtonRef.current;
        if ( button )
        {
            const approxPickerPopoverHeight = 242;
            const buttonRect = button.getBoundingClientRect();
            const topOfTheButton = buttonRect.top;
            const leftOfButton = buttonRect.left;
            const padding = 12;
            const topValueBelowButton = topOfTheButton + button.offsetHeight + padding;

            const commonProperties: CSSProperties = {
                position: "fixed",
                left: leftOfButton + "px",
                zIndex: 2,
            };

            let top = topValueBelowButton;
            if ( (topValueBelowButton + approxPickerPopoverHeight) > window.innerHeight )
            {
                top = topOfTheButton - approxPickerPopoverHeight - padding;
            }
            this.setState( { gradientPickerPositionCSS: { ...commonProperties, top: top + "px" } } );
        }
    }
}

export default CustomPicker( GradientColorPicker );
