import { Dispatch } from "redux";
import { normalizeCollectionAPIData, requests } from "../helpers";
import { StoreState } from "../_types";
import { CollectionAPIData } from "../_types/api/collectionsData";
import { collectionActions, uiActions } from "../actions";

export const collectionsServices = {
    openCollection,
};

const COLLECTIONS_BASE_URL = "/collections";

function openCollection( slug: string, source: string )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const collectionSlugUrl = COLLECTIONS_BASE_URL + "/" + slug;

        dispatch( collectionActions.collectionCleared() );
        dispatch( uiActions.collectionSpinnerEnabled( true ) );
        dispatch( uiActions.collectionPageSourceSet( source ) );

        return requests.get( collectionSlugUrl ).then(
            ( data: CollectionAPIData ) =>
            {
                const collectionData = normalizeCollectionAPIData( data );
                dispatch( uiActions.collectionSpinnerEnabled( false ) );
                dispatch( collectionActions.collectionLoadSucceeded( collectionData ) );
            }, () =>
            {
                dispatch( uiActions.collectionSpinnerEnabled( false ) );
                dispatch( collectionActions.collectionLoadFailed() );
            },
        );
    };
}
