import * as React from "react";
import { AspectRatioPicker } from "./editorControls/AspectRatioPicker";
import { AspectRatio, CatalogCategory } from "../_types";
import { filter, isEqual, map } from "lodash";
import { CatalogSidebarItem } from "./CatalogSidebar";

export interface DesignSwitchingCatalogSidebarProps
{
    category?: CatalogCategory;
    categories: CatalogCategory[];
    aspectRatio: AspectRatio;
    aspectRatioSupported: AspectRatio[];
}

export interface DesignSwitchingCatalogSidebarDispatchProps
{
    onCategorySelected( category: CatalogCategory ): void;
    onAspectRatioSelected( aspectRatio: AspectRatio ): void;
}

export const DesignSwitchingCatalogSidebar: React.FC<DesignSwitchingCatalogSidebarProps & DesignSwitchingCatalogSidebarDispatchProps> = ( props ) =>
{
    return (
        <div className="catalogSidebar">
            <div className="catalogList">
                {
                    map( filter( props.categories ), ( category ) =>
                    {
                        const selected = isEqual( category, props.category );
                        return (
                            <CatalogSidebarItem
                                key={category.id}
                                category={category}
                                selected={selected}
                                onCategorySelected={props.onCategorySelected}
                            />
                        );
                    } )
                }
            </div>
            <AspectRatioPicker {...props} />
        </div>
    );
};
//
// export interface CatalogSidebarItemProps
// {
//     category: CatalogCategory;
//     selected: boolean;
// }
//
// export interface CatalogSidebarItemDispatchProps
// {
//     onCategorySelected( category: CatalogCategory ): void;
// }
//
// export class CatalogSidebarItem extends React.PureComponent<CatalogSidebarItemProps & CatalogSidebarItemDispatchProps>
// {
//     public render()
//     {
//         return (
//             <div
//                 className={classNames( "catalogCategory", { selected: this.props.selected } )}
//                 onClick={this.handleCategoryClicked}>
//                 {this.props.category.name}
//             </div>
//         );
//     }
//
//     private handleCategoryClicked = () =>
//     {
//         this.props.onCategorySelected( this.props.category );
//     }
// }
