import * as React from "react";
import { urlUtils } from "../helpers";

const GOOGLE_PRIVACY_POLICY = "https://policies.google.com/privacy";
const GOOGLE_TERMS = "https://policies.google.com/terms";

export const ReCaptchaFinePrint: React.FC<{}> = ( props ) =>
{
    return (
        <div className="reCaptchaFinePrint">
            <br/>This site is protected by reCAPTCHA and the Google
            <span className="standardLink" onClick={() => urlUtils.openExternalUrl( GOOGLE_PRIVACY_POLICY )}>&nbsp;Privacy Policy</span>
            <br/>and <span className="standardLink" onClick={() => urlUtils.openExternalUrl( GOOGLE_TERMS )}>&nbsp;Terms of Service</span> apply.
        </div>
    );
};
