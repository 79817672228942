import * as React from "react";
import Grid from "react-md/lib/Grids/Grid";
import { PostIntentCell } from "./PostIntentCell";
import { eventTracker } from "../helpers";

export interface PostIntentsDialogProps
{
    postIntents: PostIntentData[];
}

export interface PostIntentsDialogDispatchProps
{
    openCollection: ( slug ) => void;

}

export class PostIntentsDialog extends React.PureComponent<PostIntentsDialogProps & PostIntentsDialogDispatchProps>
{
    public render()
    {
        return (
            <div className={"postIntentsDialog"}>
                What type of post would you like to create?
                <Grid className={"postIntentsGrid"}>
                    {this.getPostIntentButtons()}
                </Grid>
            </div>
        );
    }

    private getPostIntentButtons()
    {
        const postIntents = this.props.postIntents;
        return postIntents.map( ( postIntent ) =>
        {
            return <PostIntentCell key={postIntent.id} onClick={this.handlePostIntentClicked} postIntent={postIntent}/>;
        } );
    }

    private handlePostIntentClicked = ( slug, id ) =>
    {
        eventTracker.logPostIntentTapped( slug, id );
        this.props.openCollection( slug );
    }
}
