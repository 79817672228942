import * as React from "react";
import { ListPicker } from "./ListPicker";
import { filter, isEqual, map } from "lodash";
import { AssetChoice } from "../_types";
import classNames from "classnames";
import { combineInitialAssetChoiceWithPresetChoices } from "../helpers/assetControlHelper";

export interface AssetPickerProps
{
    assetName: string;
    value: any;
    assetArray: AssetChoice[];
    initialAssetChoice?: AssetChoice;
}

export interface AssetPickerDispatchProps
{
    onAssetSelected: ( assetChoice: AssetChoice ) => void;
}

export class AssetPicker extends React.PureComponent<AssetPickerProps & AssetPickerDispatchProps>
{
    public render()
    {
        const combinedCleanedChoices = combineInitialAssetChoiceWithPresetChoices( this.props.initialAssetChoice, this.props.assetArray );
        return (
            <ListPicker>
                {
                    map( filter( combinedCleanedChoices ), ( asset ) =>
                    {
                        const selected = isEqual( asset.jsonBlob, this.props.value );
                        return (
                            <AssetPickerItem
                                key={asset.text}
                                asset={asset}
                                selected={selected}
                                onAssetSelected={this.props.onAssetSelected}
                            />
                        );
                    } )
                }
            </ListPicker>
        );
    }
}

export interface AssetPickerItemProps
{
    asset: AssetChoice;
    selected: boolean;
}

export interface AssetPickerItemDispatchProps
{
    onAssetSelected: ( assetChoice: AssetChoice ) => void;
}

export class AssetPickerItem extends React.PureComponent<AssetPickerItemProps & AssetPickerItemDispatchProps>
{
    public render()
    {
        return (
            <div className={classNames( "item", { selected: this.props.selected } )}
                 onClick={this.handleAssetSelected}>
                {
                    this.props.asset.thumbnail && <img className="thumbnail" src={this.props.asset.thumbnail}/>
                }
                <div className="description">{this.props.asset.text}</div>
            </div>
        );
    }

    private handleAssetSelected = () =>
    {
        this.props.onAssetSelected( this.props.asset );
    }
}
