import * as React from "react";
import { PartyHatDialog } from "./PartyHatDialogContent";
import GradientHeaderDialogContainer from "./containers/GradientHeaderDialog.container";
import { DialogControlProps } from "../_types";

export interface SubscriptionConfirmationDialogContentProps extends DialogControlProps
{
    title: string;
    description: string;
    showGradientVersion?: boolean;
    showCrown?: boolean;
}

export class SubscriptionConfirmationDialogContent extends React.PureComponent<SubscriptionConfirmationDialogContentProps>
{
    public render()
    {
        if ( this.props.showGradientVersion )
        {
            return (
                <GradientHeaderDialogContainer
                    title={this.props.title}
                    showCrown={this.props.showCrown}
                    closeDialog={this.props.closeDialog}
                    content={this.getGradientMessageContent()}
                    canGoBack={false}
                />
            );
        }
        return (
            <div className="subscriptionConfirmationDialogContent">
                <PartyHatDialog title={this.props.title} description={this.props.description}/>
            </div>
        );
    }

    private getGradientMessageContent()
    {
        return (
            <>{this.props.description}</>
        );
    }
}
