import { createAction } from "redux-actions";
import { ActionCreator, EpidemicSoundSource } from "../helpers";
import { ChannelTrackingParameters } from "../_types";

const actionCreator = new ActionCreator( "TRACKING" );

const trackingActionsTypes = {
    postSession: {
        started: actionCreator.generateType( "POST_SESSION", "STARTED" ),
        ended: actionCreator.generateType( "POST_SESSION", "ENDED" ),
    },
    subscriptionFlow: {
        started: actionCreator.generateType( "SUBSCRIPTION_FLOW", "STARTED" ),
        ended: actionCreator.generateType( "SUBSCRIPTION_FLOW", "ENDED" ),
    },
    apptimize: {
        cacheFeatureFlag: actionCreator.generateType( "APPTIMIZE", "CACHE_FEATURE_FLAG" ),
        cacheDynamicVariable: actionCreator.generateType( "APPTIMIZE", "CACHE_DYNAMIC_VARIABLE" ),
        cacheParticipatingExperimentAndVariantName:
            actionCreator.generateType( "APPTIMIZE", "CACHE_PARTICIPATING_EXPERIMENT_AND_VARIANT_NAME" ),
        clearFeatureFlagAndDynamicVariableCache:
            actionCreator.generateType( "APPTIMIZE", "CLEAR_FEATURE_FLAG_AND_DYNAMIC_VARIABLE_CACHE" ),
    },
    channelTrackingParameters: {
        update: actionCreator.generateType( "CHANNEL_TRACKING_PARAMETERS", "UPDATE" ),
    },
    countryCode: {
        retrieved: actionCreator.generateType( "COUNTRY_CODE", "RETRIEVED" ),
    },
    epidemicSoundSource: {
        set: actionCreator.generateType( "EPIDEMIC_SOUND_SOURCE", "SET" ),
    },
};

export interface ApptimizeCacheEntry
{
    name: string;
    value: boolean | string;
}

export const trackingActions = {
    postSessionStarted: createAction<string>( trackingActionsTypes.postSession.started ),
    postSessionEnded: createAction( trackingActionsTypes.postSession.ended ),
    subscriptionFlowStarted: createAction<string>( trackingActionsTypes.subscriptionFlow.started ),
    subscriptionFlowEnded: createAction( trackingActionsTypes.subscriptionFlow.ended ),
    cacheApptimizeFeatureFlag: createAction<ApptimizeCacheEntry>( trackingActionsTypes.apptimize.cacheFeatureFlag ),
    cacheApptimizeDynamicVariable: createAction<ApptimizeCacheEntry>( trackingActionsTypes.apptimize.cacheDynamicVariable ),
    cacheApptimizeParticipatingExperimentAndVariantName:
        createAction<string>( trackingActionsTypes.apptimize.cacheParticipatingExperimentAndVariantName ),
    clearFeatureFlagAndDynamicVariableCache:
        createAction( trackingActionsTypes.apptimize.clearFeatureFlagAndDynamicVariableCache ),
    updateChannelTrackingParameters: createAction<ChannelTrackingParameters>( trackingActionsTypes.channelTrackingParameters.update ),
    countryCodeRetrieved: createAction<string>( trackingActionsTypes.countryCode.retrieved ),
    epidemicSoundSourceSet: createAction<EpidemicSoundSource>( trackingActionsTypes.epidemicSoundSource.set ),
};
