import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../_types";
import { getInspirationPosts, getNextInspirationPage, hasLoadedAnyInspirations, hasNextInspirationPage } from "../../../ducks/inspirations";
import { InspirationGrid, InspirationGridDispatchProps, InspirationGridProps } from "../InspirationGrid";
import { inspirationsServices } from "../../../services";
import { getBusinessTypeFilter } from "../../../ducks/ui";
import { store } from "../../../store";

const mapStateToProps = ( storeState: StoreState ): InspirationGridProps =>
{
    return {
        hasFetchedData: hasLoadedAnyInspirations( storeState ),
        hasNextPage: hasNextInspirationPage( storeState ),
        nextPage: getNextInspirationPage( storeState ),
        inspirationPosts: getInspirationPosts( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): InspirationGridDispatchProps =>
{
    return {
        loadMoreInspirations: ( page: number ) =>
        {
            const businessTypeFilter = getBusinessTypeFilter( store.getState() );
            const businessTypeFilterId = businessTypeFilter ? businessTypeFilter.id : null;
            dispatch( inspirationsServices.loadInspirationsForBusinessType( businessTypeFilterId, page ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( InspirationGrid );
