import { connect } from "react-redux";
import { Dispatch } from "redux";
import { MoreMusicDialog, MoreMusicDialogDispatchProps, MoreMusicDialogProps } from "../MoreMusicDialog";
import { getCategoryListWithMusic, getCurrentMusicCategory, getCustomMusicFromMusicCatalog, getMusicByCategory } from "../../../ducks/musicCatalog";
import { isUploadedMusicFileValid } from "../../../helpers";
import { modalServices, postServices } from "../../../services";
import { musicCatalogActions, uiActions } from "../../../actions";
import { getAudioPlayerMusicTrack, getMusicCatalogSelected, isCustomMusicUploading } from "../../../ducks/ui";
import { MediaData, Music, StoreState } from "../../../_types";
import { AddMediaAPIResponse, MUSIC_INPUT_TYPE } from "../../../_types/api";

const mapStateToProps = ( storeState: StoreState ): MoreMusicDialogProps =>
{
    return {
        musicByCategory: getMusicByCategory( storeState ),
        selectedTrack: getMusicCatalogSelected( storeState ),
        selectedTrackCategory: getCurrentMusicCategory( storeState ),
        categoryList: getCategoryListWithMusic( storeState ),
        isCustomMusicUploading: isCustomMusicUploading( storeState ),
        isCustomMusicDownloaded: !!getCustomMusicFromMusicCatalog( storeState ),
        currentAudioPlayerMusicTrack: getAudioPlayerMusicTrack( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): MoreMusicDialogDispatchProps =>
{
    return {
        onUploadCustomMusic: async ( file: File ) =>
        {
            if ( !isUploadedMusicFileValid( file ) )
            {
                dispatch( modalServices.openInvalidMusicFileUploadError() );
            }
            else
            {
                await uploadCustomMusic( dispatch, file );
            }
        },
        updateMusicCatalogSelection: ( track: Music ) =>
        {
            dispatch( uiActions.updateMusicCatalogSelection( track ) );
        },
        playTrack: ( musicTrackToPlay: Music ) =>
        {
            dispatch( uiActions.setAudioPlayerMusicTrack( musicTrackToPlay ) );
        },
    };
};

async function uploadCustomMusic( dispatch: Dispatch<StoreState>, file: File )
{
    const mediaData: MediaData = {
        filename: file.name,
        file,
        type: MUSIC_INPUT_TYPE,
        fileSize: file.size,
    };
    dispatch( uiActions.toggleIsCustomMusicUploading( true ) );
    await dispatch( postServices.uploadPostMediaToS3( mediaData, afterUpload ) );
}

function afterUpload( dispatch: Dispatch<StoreState>, data: AddMediaAPIResponse )
{
    if ( data )
    {
        dispatch( musicCatalogActions.loadCustomMusic( data ) );
        dispatch( uiActions.toggleIsCustomMusicUploading( false ) );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( MoreMusicDialog );
