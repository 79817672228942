import { connect } from "react-redux";
import { Dispatch } from "redux";
import { CreativeTab, CreativeTabDispatchProps, CreativeTabProps } from "../CreativeTab";
import { getCurrentBusiness } from "../../../ducks/userBusiness";
import { showBrandLogo } from "../../../ducks/user";
import { logoServices, modalServices, userBusinessServices } from "../../../services";
import { LightboxDialogIdentifierForKey } from "../../../helpers";
import { StoreState } from "../../../_types";
import ManageLogosDialogContainer from "../../containers/ManageLogosDialog.container";
import { uiActions } from "../../../actions";
import ConfirmDeleteLogosDialogContainer from "../../containers/ConfirmDeleteLogosDialog.container";
import { store } from "../../../store";
import { getSelectedManageBusinessLogos, hasSelectedManageBusinessLogos } from "../../../ducks/ui";

const mapStateToProps = ( storeState: StoreState ): CreativeTabProps =>
{
    return {
        currentBusiness: getCurrentBusiness( storeState ),
        showBrandLogo: showBrandLogo( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): CreativeTabDispatchProps =>
{
    return {
        handleShowBrandLogo: ( enabled: boolean ) =>
        {
            dispatch( logoServices.setEnabled( enabled ) );
        },
        handleManageLogosClicked: () =>
        {
            dispatch( uiActions.manageBusinessLogosSelectionClear() );
            dispatch( modalServices.openLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.MANAGE_LOGOS,
                title: "Select logos to delete",
                width: 475,
                showCancelX: true,
                hideCancel: true,
                content: ManageLogosDialogContainer,
                confirmDisabled: () => !hasSelectedManageBusinessLogos( store.getState() ),
                confirmLabel: "Delete selected logos",
                footerClassName: "buttonFullWidthOfDialogFooter",
                onSuccess: () =>
                {
                    displayConfirmationDialog( dispatch );
                },
            } ) );
        },
    };
};

function displayConfirmationDialog( dispatch: Dispatch<StoreState> )
{
    dispatch( modalServices.openLightbox( {
        identifierForKey: LightboxDialogIdentifierForKey.CONFIRM_DELETE_LOGOS,
        title: "Are you sure?",
        subtitle: "Delete these logos from your library?",
        width: 475,
        showCancelX: false,
        content: ConfirmDeleteLogosDialogContainer,
        confirmLabel: "Delete",
        cancelLabel: "Keep them",
        footerClassName: "buttonFullWidthOfDialogFooter",
        modal: true,
        onSuccess: () =>
        {
            dispatch( userBusinessServices.hideBrandLogos( getSelectedManageBusinessLogos( store.getState() ) ) );
        },
    } ) );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( CreativeTab );
