import * as React from "react";
import { lowerCase, map, size } from "lodash";
import { DesignCaptionData, TextPickerCaptionData } from "../../../_types";
import CustomizeTextInputFieldContainer from "../containers/CustomizeTextInputField.container";
import { CAPTION_TEXT_FIELD_NAME } from "../../../_types/api";
import AddTextBoxButtonContainer from "../containers/AddTextBoxButton.container";

export interface PresetTextPickerProps
{
    globalCaptions: DesignCaptionData[];
    perSlideCaptions: DesignCaptionData[];
}

export interface PresetTextPickerDispatchProps
{
    changeTextPropertiesSubPanelCaptionData( captionData: TextPickerCaptionData );
}

export function PresetTextPicker( props: PresetTextPickerProps & PresetTextPickerDispatchProps )
{
    return (
        <div className="presetTextPicker">
            <PresetTextSection
                name={"Global Text"}
                isGlobal={true}
                hasChildren={size( props.globalCaptions ) > 0}>
                <PresetCaptionList
                    captions={props.globalCaptions}
                    changeTextPropertiesSubPanelCaptionData={props.changeTextPropertiesSubPanelCaptionData}
                />
            </PresetTextSection>
            <hr/>
            <PresetTextSection
                name={"Slide Text"}
                hasChildren={size( props.perSlideCaptions ) > 0}>
                <PresetCaptionList
                    captions={props.perSlideCaptions}
                    changeTextPropertiesSubPanelCaptionData={props.changeTextPropertiesSubPanelCaptionData}
                />
            </PresetTextSection>
        </div>
    );
}

export interface PresetCaptionListProps
{
    captions: DesignCaptionData[];
    changeTextPropertiesSubPanelCaptionData( captionData: TextPickerCaptionData );
}

export function PresetCaptionList( props: PresetCaptionListProps )
{
    const captionTextFields = map( props.captions, ( captionData ) =>
    {
        return <div key={captionData.id} className="captionTextFieldWrap">
            <CustomizeTextInputFieldContainer
                identifier={captionData.id}
                value={captionData.value}
                styleId={captionData.styleId}
                label={"Caption"}
                placeholder="Add your own text"
                type={CAPTION_TEXT_FIELD_NAME}
                supportsDeletion={true}
                shouldEnableStylizedText={true}
                showStylizedTextCoachMark={false}
                onChangeStylizeTextClicked={props.changeTextPropertiesSubPanelCaptionData}
            />
        </div>;
    } );

    return <>{captionTextFields}</>;
}

export interface PresetTextSectionProps
{
    name: string;
    isGlobal?: boolean;
    hasChildren: boolean;
    children?: React.ReactNode;
}

export function PresetTextSection( props: PresetTextSectionProps )
{
    const lowerCaseName = lowerCase( props.name );

    let contents = props.children;

    if ( !props.hasChildren )
    {
        contents = <div className="tray">
            <div className="presetTrayText">No {lowerCaseName}</div>
        </div>;
    }

    return (
        <div className="presetTextSection">
            <div className="presetTextPickerHeader">{lowerCaseName}</div>

            <div className="presetTextSectionContents">
                {contents}
            </div>

            <AddTextBoxButtonContainer
                buttonText={`Add ${lowerCaseName} box`}
                shouldCreateGlobalText={props.isGlobal}
            />
        </div>
    );
}
