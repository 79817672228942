import * as React from "react";
import Button from "react-md/lib/Buttons/Button";

export interface DeleteCurrentBrandButtonProps
{
    isBusinessAdmin: boolean;
    userBusinessesCount: number;
}

export interface DeleteCurrentBrandButtonDispatchProps
{
    onDeleteBrandClicked: () => void;
}

export class DeleteCurrentBrandButton extends React.PureComponent<DeleteCurrentBrandButtonProps & DeleteCurrentBrandButtonDispatchProps>
{
    public render()
    {
        return (
            this.shouldRenderDelete() && <Button
                className="deleteCurrentBrandButton outline"
                primary
                flat
                onClick={this.props.onDeleteBrandClicked}>
                Delete this brand</Button>
        );
    }

    private shouldRenderDelete()
    {
        return this.props.isBusinessAdmin && this.props.userBusinessesCount > 1;
    }
}
