import { Props } from "react-md/lib";
import { InjectedTooltipProps } from "react-md/lib/Tooltips/injectTooltip";
import Button from "react-md/lib/Buttons/Button";
import * as React from "react";

export interface IconButtonProps extends Props, InjectedTooltipProps
{
    children: JSX.Element;
}

export function IconButton( props: IconButtonProps )
{
    const { children, ...buttonProps } = props;

    buttonProps.tooltipClassName = props.tooltipClassName || "tooltip";
    buttonProps.tooltipPosition = props.tooltipPosition || "top";

    return (
        <Button flat
                className="iconButton"
                {...buttonProps}>
            {children}
        </Button>
    );
}
