import { TableColumn, TableRow } from "react-md";
import * as React from "react";
import { TeamMember, TeamMemberRole } from "../../../_types";
import DeleteTeamMemberButtonContainer from "./containers/DeleteTeamMemberButton.container";
import { UsersTabRowEmailColumn } from "./UsersTabRowEmailColumn";
import { UsersTabRowNameColumn } from "./UsersTabRowNameColumn";
import { TEAM_MEMBER_ROLE_ADMIN } from "../../../helpers";

interface UsersTabTableMemberRowProps
{
    member: TeamMember;
    showManageColumn: boolean;
}

export function UsersTabTableMemberRow( props: UsersTabTableMemberRowProps )
{
    const { member, showManageColumn } = props;
    return (
        <TableRow>
            <UsersTabRowNameColumn member={member}/>
            <UsersTabRowEmailColumn person={member}/>
            <TableColumn className="userRoleColumn">{getDisplayTextForRole( member.role )}</TableColumn>
            {showManageColumn && <TableColumn>
                <div className="iconButtonGroup">
                    {isTeamMemberRole( member ) && <DeleteTeamMemberButtonContainer member={member}/>}
                </div>
            </TableColumn>}
        </TableRow>
    );
}

function isTeamMemberRole( member: TeamMember )
{
    return member && member.role === "MEMBER";
}

function getDisplayTextForRole( role: TeamMemberRole )
{
    if ( role === TEAM_MEMBER_ROLE_ADMIN )
    {
        return "Owner";
    }
    else
    {
        return "Member";
    }
}
