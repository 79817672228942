import * as React from "react";
import { ASPECT_RATIO_NAMES, ASPECT_RATIO_VALUE, eventTracker } from "../../helpers";
import { entries, filter, includes, map } from "lodash";
import { AspectRatio } from "../../_types";
import classNames from "classnames";

export interface AspectRatioPickerProps
{
    aspectRatio: AspectRatio;
    aspectRatioSupported: AspectRatio[];
}

export interface AspectRatioPickerDispatchProps
{
    onAspectRatioSelected( aspectRatio: AspectRatio ): void;
}

export class AspectRatioPicker extends React.PureComponent<AspectRatioPickerProps & AspectRatioPickerDispatchProps>
{
    public render()
    {
        const aspectRatioSupported = this.props.aspectRatioSupported;
        const aspectRatioPairs: Array<[string, number]> = entries<number>( ASPECT_RATIO_VALUE );
        return (
            <div className="aspectRatioSelectorGroup">
                <div className="aspectRatioSelectorContainer">
                    {
                        map( filter( aspectRatioPairs ), ( pair: [AspectRatio, number] ) =>
                        {
                            const [aspectRatio, aspectRatioValue] = pair;
                            const aspectRatioName: string = ASPECT_RATIO_NAMES[aspectRatio];
                            const selected = aspectRatio === this.props.aspectRatio;
                            const unsupported = !includes( aspectRatioSupported, aspectRatio );

                            return (
                                <AspectRatioPickerItem key={aspectRatioName}
                                                       aspectRatio={aspectRatio}
                                                       aspectRatioValue={aspectRatioValue}
                                                       unsupported={unsupported}
                                                       selected={selected}
                                                       onAspectRatioSelected={this.handleAspectRatioClick}
                                />
                            );
                        } )
                    }
                </div>
                {
                    aspectRatioSupported.length === 1 &&
                    <div className="squareOnly"> This template only supports square format</div>
                }
            </div>
        );
    }

    private handleAspectRatioClick = ( aspectRatio ) =>
    {
        if ( includes( this.props.aspectRatioSupported, aspectRatio ) )
        {
            this.props.onAspectRatioSelected( aspectRatio as AspectRatio );
            if ( aspectRatio !== this.props.aspectRatio )
            {
                eventTracker.logChangedSizeFromCustomize( aspectRatio, this.props.aspectRatio );
            }
        }
    }
}

export interface AspectRatioPickerItemProps
{
    aspectRatio: AspectRatio;
    aspectRatioValue: number;
    unsupported: boolean;
    selected: boolean;
}

export interface AspectRatioPickerItemDispatchProps
{
    onAspectRatioSelected( aspectRatio: AspectRatio ): void;
}

export class AspectRatioPickerItem extends React.PureComponent<AspectRatioPickerItemProps & AspectRatioPickerItemDispatchProps>
{
    public render()
    {
        const { aspectRatio, aspectRatioValue, selected, unsupported } = this.props;
        const aspectRatioName: string = ASPECT_RATIO_NAMES[aspectRatio];

        return (
            <div className={classNames( "aspectRatioSelector", aspectRatioName, { selected, unSupported: unsupported } )}
                 onClick={this.handleAspectRatioClick}
                 // @ts-ignore
                 style={{ "--aspect-ratio": aspectRatioValue }}>&nbsp;</div>
        );
    }

    private handleAspectRatioClick = () =>
    {
        this.props.onAspectRatioSelected( this.props.aspectRatio );
    }
}
