import { CoachMarkNameKey, StoreState } from "../../_types";
import { getCoachMarks, isShowYouAroundTourVisible } from "../../ducks/ui";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { uiActions } from "../../actions";
import { HomePageCoachMarks, HomePageCoachMarksDispatchProps, HomePageCoachMarksProps } from "../HomePageCoachMarks";
import { store } from "../../store";
import { getUserHasPosted } from "../../ducks/post";
import { COACH_MARK_NAME_KEY_REFER_A_FRIEND_BUTTON } from "../../_types/api";

const mapStateToProps = ( storeState: StoreState ): HomePageCoachMarksProps =>
{
    return {
        coachMarks: getCoachMarks( storeState ),
        coachMarksVisible: !isShowYouAroundTourVisible( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): HomePageCoachMarksDispatchProps =>
{
    const showNextCoachMark = () =>
    {
        dispatch( uiActions.hideAllCoachMarks() );

        const state = store.getState();
        if ( !state.ui.coachMarks.referAFriendButton.cleared && getUserHasPosted( state ) )
        {
            dispatch( uiActions.showCoachMark( COACH_MARK_NAME_KEY_REFER_A_FRIEND_BUTTON ) );
        }
    };

    return {
        clearCoachMark( coachMarkName: CoachMarkNameKey ): void
        {
            dispatch( uiActions.clearCoachMark( coachMarkName ) );
            showNextCoachMark();
        },
        showNextCoachMark,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( HomePageCoachMarks );
