import * as React from "react";
import SelectionControlGroup from "react-md/lib/SelectionControls/SelectionControlGroup";
import { map } from "lodash";
import TextField from "react-md/lib/TextFields/TextField";
import { Question } from "../../_types";
import { Answer } from "../../_types/api/survey";

interface SurveyQuestionAndAnswersProps
{
    question: Question;
}

interface SurveyQuestionAndAnswersState
{
    selectedValue: string;
    otherText: string;
}

interface SurveyQuestionAndAnswersDispatchProps
{
    handleSelection( questionId: string, answers: Answer[] );
}

export default class SurveyQuestionAndAnswers
    extends React.PureComponent<SurveyQuestionAndAnswersProps & SurveyQuestionAndAnswersDispatchProps, SurveyQuestionAndAnswersState>
{
    constructor( props )
    {
        super( props );
        this.state = { selectedValue: null, otherText: null };
    }

    public render()
    {
        const controls = map( this.props.question.answers.choices, this.getControlsFromChoices );
        if ( this.props.question.answers.other )
        {
            const other = this.props.question.answers.other;
            controls.push( { label: other.text, value: other.id } );
        }

        return (<div className="questionAndAnswers">
            <SelectionControlGroup
                id="survey"
                key={this.props.question.id}
                type="radio"
                className="cancelSurveyGroup"
                defaultValue={null}
                name="cancelSurveyGroup"
                controlClassName="cancelSurveyChoice"
                label={map( this.props.question.headings, ( heading ) => heading.heading ).join( "\n" )}
                controls={controls}
                onChange={this.handleSelectionChanged}
                value={this.state.selectedValue}
            />
            {this.props.question.answers.other && <TextField
                id={`otherText_${this.props.question.answers.other.id}`}
                key={`otherText_${this.props.question.answers.other.id}`}
                className="textInput"
                placeholder={this.props.question.answers.other.error_text}
                resize={{ min: 360, max: 360 }}
                maxLength={512}
                rows={2}
                onChange={this.handleInputTextChanged}/>}
        </div>);
    }

    private getControlsFromChoices = ( choice ) =>
    {
        return { label: choice.text, value: choice.id };
    }

    private handleSelectionChanged = ( selectedValue ) =>
    {
        this.setState( { selectedValue }, () => this.sendSelectedValueToForm() );
    }

    private handleInputTextChanged = ( text ) =>
    {
        const otherId = this.props.question.answers.other.id;
        this.setState( { selectedValue: otherId, otherText: text }, () => this.sendSelectedValueToForm() );
    }

    private sendSelectedValueToForm = () =>
    {
        const answers = this.isOtherSelected() ? [{ other_id: this.state.selectedValue, text: this.state.otherText }]
                                               : [{ choice_id: this.state.selectedValue }];

        this.props.handleSelection( this.props.question.id, answers );
    }

    private isOtherSelected = () =>
    {
        const other = this.props.question.answers.other;
        return other && this.state.selectedValue === other.id;
    }
}
