import { connect } from "react-redux";
import { Dispatch } from "redux";
import { SocialCalendarDaySlotData, SocialCalendarPostData, StoreState } from "../../../_types";
import { SocialCalendarCell, SocialCalendarCellDispatchProps, SocialCalendarCellProps } from "../../SocialCalendarCell";
import { collectionsServices, modalServices, postServices } from "../../../services";
import { getPostById } from "../../../ducks/post";
import { store } from "../../../store";
import { COLLECTION_PAGE_URL, errorReporting, eventTracker, history, SOCIAL_CALENDAR_COLLECTION_SOURCE } from "../../../helpers";
import { getSocialCalendarSelectedDate } from "../../../ducks/socialCalendar";
import CircularProgress from "react-md/lib/Progress/CircularProgress";
import { modalActions } from "../../../actions";
import * as React from "react";

export interface SocialCalendarCellContainerProps
{
    postId: number;
    postData: SocialCalendarPostData;
    slotData: SocialCalendarDaySlotData;
    date: string;
}

const mapStateToProps = ( state: StoreState, ownProps: SocialCalendarCellContainerProps ): SocialCalendarCellProps =>
{
    return {
        ...ownProps,
        isSelected: getSocialCalendarSelectedDate( state ) === ownProps.date,
        post: getPostById(ownProps.postId, state),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): SocialCalendarCellDispatchProps =>
{
    return {
        openDesign: async ( id, name, date ) =>
        {
            dispatch( modalServices.openLoadingDialog( {
                content: () => <CircularProgress className="spinner large" id="spinner"/>,
                className: "fullPageLoading",
                onShow: async () =>
                {
                    try
                    {

                        await postServices.loadExamplePostWithId( dispatch, id.toString() );
                        const thePost = getPostById( id, store.getState() );

                        const customizeOptions = {
                            isRecommendedPost: true,
                            isSocialCalendarPost: true,
                        };
                        dispatch( modalServices.openTemplatePreviewDialogFromSocialCalendar( thePost, customizeOptions, name, date ) );
                    }
                    catch (error)
                    {
                        eventTracker.logOpenPostFromDeeplinkFailed( error );
                        errorReporting.reportErrorToSentry( error );
                    }
                    finally
                    {
                        dispatch( modalActions.loadingDialogClose() );
                    }
                },
            } ) );
        },
        openCollection: async ( slug ) =>
        {
            await dispatch( collectionsServices.openCollection( slug, SOCIAL_CALENDAR_COLLECTION_SOURCE ) );
            history.push( COLLECTION_PAGE_URL );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( SocialCalendarCell );
