import { connect } from "react-redux";
import { Dispatch } from "redux";
import { CoachMarkContentFlow, CoachMarkNameKey, CoachMarkPosition, StoreState } from "../../../_types";
import { uiActions } from "../../../actions";
import { CoachMark, CoachMarkDispatchProps, CoachMarkProps } from "../../CoachMark";
import { COACH_MARK_NAME_KEY_STYLIZED_TEXT } from "../../../_types/api";
import { STYLIZED_TEXT_BUTTON_CLASS } from "../../../helpers";

const mapStateToProps = ( storeState: StoreState ): CoachMarkProps =>
{
    return {
        name: COACH_MARK_NAME_KEY_STYLIZED_TEXT,
        message: "New! Apply a style to your text",
        position: CoachMarkPosition.ABOVE,
        alignBubble: CoachMarkContentFlow.LEFT,
        fixed: false,
        hideClose: true,
        additionalClass: "stylizedTextCoachMark",
        darkMode: true,
        selector: "." + STYLIZED_TEXT_BUTTON_CLASS,
        dismissOnContentTap: true,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): CoachMarkDispatchProps =>
{
    return {
        onDismiss( coachMarkName: CoachMarkNameKey )
        {
            dispatch( uiActions.clearCoachMark( coachMarkName ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( CoachMark );
