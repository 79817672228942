import * as React from "react";
import ManageSubscriptionsContent from "./containers/ManageSubscriptionsContent.container";

export default class ManageSubscriptionsPage extends React.PureComponent<{}>
{
    public render()
    {
        return (
            <div className="manageSubscriptionsPage">
                <ManageSubscriptionsContent/>
            </div>
        );
    }
}
