import * as React from "react";

export const MyBrandIcon: React.FC<{}> = () =>
{
    return (
        /* tslint:disable:max-line-length */
        // @ts-ignore
    <svg className="newNavStackedIcon brand" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 63">
        <circle cx="30" cy="30" r="28"/>
        <polygon points="21.11 43.32 22.77 33.12 15.33 25.93 25.51 24.4 29.99 15.1 34.63 24.36 44.84 25.79 37.52 33.04 39.34 43.23 30.19 38.47 21.11 43.32"/>
    </svg>
    );
};
