import * as React from "react";
import Button from "react-md/lib/Buttons/Button";
import classNames from "classnames";
import { eventTracker } from "../helpers";

interface ShowYouAroundWithFacebookAdsSequenceStepProps
{
    goTo: ( step: number ) => void;
    close: () => void;
    step: number;
}

export const ShowYouAroundSequenceStep: React.FC<ShowYouAroundWithFacebookAdsSequenceStepProps> = ( props ) =>
{
    const enum Step
    {
        TopCollection = 2,
        Search,
        CreateYourOwn,
        Last = Step.CreateYourOwn,
    }

    function getCurrentStepData()
    {
        const stepData = {
            [Step.TopCollection]: {
                title: "Start from a template",
                description: (<div>Get inspired—browse collections for any goal,<br/>from driving sales to engaging your audience.</div>),
                buttonLabel: "Next",
            },
            [Step.Search]: {
                title: "Discover more with search",
                description: (<div>Save time—use the search feature to<br/>find just the template you need.</div>),
                buttonLabel: "Next",
            },
            [Step.CreateYourOwn]: {
                title: "Create your own",
                description: (<div>Bring your idea to life—start a social post or<br/>create an ad from scratch with a click of a button.</div>),
                buttonLabel: "Done",
            },
        };

        return stepData[props.step];
    }

    function handleNextClicked()
    {
        if ( props.step >= Step.Last )
        {
            eventTracker.logShowYouAroundDoneTappedEvent();
            props.close();
            return;
        }
        props.goTo( props.step );
    }

    function getNavigationDot( dotIndex )
    {
        function handleDotClicked()
        {
            props.goTo( dotIndex - 1 );
        }

        return <button className={classNames( "dot", { selected: props.step === dotIndex } )} onClick={handleDotClicked}/>;
    }

    const arrowClassnames = classNames( "arrowUp",
        {
            collection: props.step === Step.TopCollection,
            search: props.step === Step.Search,
            createYourOwn: props.step === Step.CreateYourOwn,
        } );

    return (
        <div className="showYouAroundDialog">
            <div className={arrowClassnames}/>
            <h2 className="showYouAroundDialogTitle">{getCurrentStepData().title}</h2>
            <div>{getCurrentStepData().description}</div>
            <div className="navigation">
                {getNavigationDot( Step.TopCollection )}
                {getNavigationDot( Step.Search )}
                {getNavigationDot( Step.CreateYourOwn )}
            </div>
            <div className="showYouAroundButtons">
                <Button className="nextButton" flat onClick={handleNextClicked}>{getCurrentStepData().buttonLabel}</Button>
            </div>
        </div>
    );
};
