import { Dispatch } from "redux";
import { ERROR_TITLE_SORRY, requests } from "../helpers";
import { StoreState } from "../_types";
import { stockMediaActions, uiActions } from "../actions";
import { AddMediaAPIResponse } from "../_types/api";
import { modalServices } from "./modal.services";

export const stockMediaServices = {
    searchPhotos,
    searchVideos,
    getFullSizePhoto,
    getFullSizeVideo,
};

const STOCK_MEDIA_BASE_URL = "/stock_media";
const STOCK_MEDIA_BASE_SEARCH_URL = STOCK_MEDIA_BASE_URL + "/search";
const STOCK_MEDIA_SEARCH_PHOTOS_URL = STOCK_MEDIA_BASE_SEARCH_URL + "/photos";
const STOCK_MEDIA_SEARCH_VIDEOS_URL = STOCK_MEDIA_BASE_SEARCH_URL + "/videos";
const STOCK_MEDIA_GET_FULL_SIZE_PHOTO_URL = STOCK_MEDIA_BASE_URL + "/photo";
const STOCK_MEDIA_GET_FULL_SIZE_VIDEO_URL = STOCK_MEDIA_BASE_URL + "/video";
const STOCK_MEDIA_PROVIDER_PEXELS = "pexels";

function searchPhotos( query: string, page: number = 1, currentBusinessTypeId: number = null )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        return requests.get( STOCK_MEDIA_SEARCH_PHOTOS_URL,
            { stock_media_provider: STOCK_MEDIA_PROVIDER_PEXELS, query, page, currentBusinessTypeId } ).then(
            ( data: StockMediaAPIData ) =>
            {
                dispatch( stockMediaActions.searchPhotosLoaded( data ) );
            }, () =>
            {
                dispatch( stockMediaActions.searchPhotosFailed() );
            },
        );
    };
}

function getFullSizePhoto( postId: number, externalMediaId: string, searchId: string, onFulfilled: ( data: AddMediaAPIResponse ) => void )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        return requests.get( STOCK_MEDIA_GET_FULL_SIZE_PHOTO_URL,
            {
                stock_media_provider: STOCK_MEDIA_PROVIDER_PEXELS,
                post_id: postId,
                external_media_id: externalMediaId,
                search_id: searchId,
            } ).then(
            onFulfilled, () =>
            {
                dispatch( uiActions.updateExternalMediaUploadStatus( { [externalMediaId]: false } ) );
                dispatch( modalServices.openErrorDialog( ERROR_TITLE_SORRY, "Unable to retrieve your stock media photo" ) );
            },
        );
    };
}

function searchVideos( query: string, page: number = 1, currentBusinessTypeId: number = null )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        return requests.get( STOCK_MEDIA_SEARCH_VIDEOS_URL,
            { stock_media_provider: STOCK_MEDIA_PROVIDER_PEXELS, query, page, currentBusinessTypeId } )
            .then(
                ( data: StockMediaAPIData ) =>
                {
                    dispatch( stockMediaActions.searchVideosLoaded( data ) );
                }, () =>
                {
                    dispatch( stockMediaActions.searchVideosFailed() );
                },
            );
    };
}

function getFullSizeVideo( postId: number, externalMediaId: string, searchId: string, onFulfilled: ( data: AddMediaAPIResponse ) => void )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        return requests.get( STOCK_MEDIA_GET_FULL_SIZE_VIDEO_URL,
            { stock_media_provider: STOCK_MEDIA_PROVIDER_PEXELS, post_id: postId, external_media_id: externalMediaId, search_id: searchId } ).then(
            onFulfilled, () =>
            {
                dispatch( uiActions.updateExternalMediaUploadStatus( { [externalMediaId]: false } ) );
                dispatch( modalServices.openErrorDialog( ERROR_TITLE_SORRY, "Unable to retrieve your stock media video" ) );
            },
        );
    };
}

