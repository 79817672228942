import * as React from "react";
import Beforeunload from "react-beforeunload";
import { NavigationHeader } from "./NavigationHeader";
import { ErrorBoundary } from "./ErrorBoundary";
import { UserBusiness, UserState } from "../_types";
import RecordingVideoElement from "./containers/RecordingVideoElement.container";
import { eventTracker } from "../helpers";
import { store } from "../store";
import { uiActions } from "../actions";
import Button from "react-md/lib/Buttons/Button";

export interface CollabraSharePageProps
{
    currentBusiness: UserBusiness;
    user: UserState;
    isPostFinishInProgress: boolean;
    allowedToShareNow: boolean;
}

export interface CollabraSharePageDispatchProps
{
    onBackButtonClick(): void;
    onBrowserBackButtonClick(): void;
    onShareNowButtonClick(): void;
    onSharingComplete(): void;
    initializeViaText();
}

export default class CollabraSharePage extends React.PureComponent<CollabraSharePageProps & CollabraSharePageDispatchProps>
{
    public componentDidMount()
    {
        const dispatch = store.dispatch;
        dispatch( uiActions.confirmFacebookModalClose() );

        this.props.initializeViaText();

        window.onpopstate = () =>
        {
            this.props.onBrowserBackButtonClick();
        };
    }

    public componentWillUnmount()
    {
        const dispatch = store.dispatch;
        dispatch( uiActions.confirmFacebookModalClose() );
    }

    public render()
    {
        return (
            <Beforeunload onBeforeunload={this.onBeforeunload}>
                <div className="collabraSharePage">
                    <ErrorBoundary errorMessage="There was a problem creating the share screen. Please restart to continue."
                                   showResetButton={true}>

                        <NavigationHeader pageTitle="Review & Finish" {...this.props} />
                        <div className={"collabraShareContainer"}>
                            <div className={"collabraRecording"}>
                                <RecordingVideoElement/>
                            </div>
                            <Button flat primary swapTheming className="fullWidth large partnerRedirectBtn"
                                    disabled={!this.props.allowedToShareNow}
                                    onClick={this.props.onShareNowButtonClick}>Finish</Button>
                        </div>
                    </ErrorBoundary>
                </div>
            </Beforeunload>
        );
    }

    private onBeforeunload = () =>
    {
        eventTracker.logPostFlowCancelledByBrowserUnload();
    }
}
