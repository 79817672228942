import {
    SUBSCRIPTION_FLOW_SOURCE_ADD_NEW_BUSINESS_CLICK,
    SUBSCRIPTION_FLOW_SOURCE_SCHEDULE_POST,
    SUBSCRIPTION_FLOW_SOURCE_TEAMS_TAB_CLICKED,
} from "./trackingConstants";
import { ERROR_TITLE_OOPS, NON_BREAKING_SPACE } from "./";

export const MAX_NUMBER_OF_TEAM_MEMBERS_INCLUDING_ADMIN = 6;
export const MAX_NUMBER_OF_INVITED_TEAM_MEMBERS = MAX_NUMBER_OF_TEAM_MEMBERS_INCLUDING_ADMIN - 1;

export const TEAM_MEMBER_ACCEPTED_STATUS = "ACCEPTED";
export const TEAM_MEMBER_PENDING_STATUS = "PENDING";
export const TEAM_MEMBER_REMOVE__BUTTON_TEXT = "REMOVE";
export const TEAM_MEMBER_CANCEL_INVITE_TEXT = "CANCEL";

export const TEAM_MEMBER_ROLE_ADMIN = "ADMIN";
export const TEAM_MEMBER_ROLE_MEMBER = "MEMBER";

export const INVITE_TEAM_MEMBERS_TITLE = "Invite team members to your brand";
export const INVITATION_SENT = "Invitation sent!";
export const INVITATION_SENT_CONTENT = "New members have been invited and will receive an email link to set up a new Ripl "
                                       + "account or log in to an existing account to join your team.";
export const INVITATION_ACCEPTED_TITLE = "Welcome to the team!";
export const INVITATION_ACCEPTED_CONTENT_FIRST_PART = "You’ve successfully joined the ";
export const INVITATION_ACCEPTED_CONTENT_LAST_PART = " team.";
export const INVITATION_ACCEPTED_CONTENT_MOBILE_WALL = `Ripl Teams is only available on desktop at this${NON_BREAKING_SPACE}time.`;

export const TEAM_BENEFITS_FEATURES_DESCRIPTIONS = {
    invite_up_to_five: `Invite up to ${MAX_NUMBER_OF_INVITED_TEAM_MEMBERS} team members to each of your${NON_BREAKING_SPACE}businesses`,
    collaborate_with_members: `Collaborate on Ripl posts with your team${NON_BREAKING_SPACE}members`,
    publish_or_schedule_posts: `Allow team members to publish or schedule${NON_BREAKING_SPACE}new posts`,
};

export const EMAIL_LIST_TITLE = `Provide a valid email address and click Send${NON_BREAKING_SPACE}Invite to add team${NON_BREAKING_SPACE}members.`;

export const REMOVE_TEAM_MEMBER_MESSAGES = {
    success: {
        title: "Team member removed.",
        message: "",
    },
    failure: {
        primaryText: "There was a problem removing the team member.",
        secondaryText: "Please try again later.",
    },
};

export const REMOVE_SELF_FROM_TEAM_MESSAGES = {
    success: {
        title: "You have left this team.",
        message: "",
    },
    failure: {
        primaryText: "There was a problem leaving this team.",
        secondaryText: "Please try again later.",
    },
};

export const USERS_TAB_TEAM_DESCRIPTION_FOR = {
    admin: "Invite team members to join your brand to collaborate on posts.",
    member: "Team members can join your brand to collaborate on posts.",
};

export const PREMIUM_CUSTOMER_SUPPORT = "Premium customer support";
export const SCHEDULE_POSTS_IN_ADVANCE = "Schedule social posts in advance";
export const NO_LIMIT_ON_NUMBER_OF_BUSINESSES = "No limit on number of businesses you can run";
export const INVITE_UP_TO_N_TEAM_MEMBERS = `Invite up to ${MAX_NUMBER_OF_INVITED_TEAM_MEMBERS} team members to each of your businesses`;

export const TEAMS_PREMIUM_BENEFITS = [
    SCHEDULE_POSTS_IN_ADVANCE,
    NO_LIMIT_ON_NUMBER_OF_BUSINESSES,
    INVITE_UP_TO_N_TEAM_MEMBERS,
    PREMIUM_CUSTOMER_SUPPORT,
];

export const SUBSCRIPTION_FLOW_TO_PRIMARY_BENEFIT: { [key: string]: string } = {
    [SUBSCRIPTION_FLOW_SOURCE_ADD_NEW_BUSINESS_CLICK]: NO_LIMIT_ON_NUMBER_OF_BUSINESSES,
    [SUBSCRIPTION_FLOW_SOURCE_SCHEDULE_POST]: SCHEDULE_POSTS_IN_ADVANCE,
    [SUBSCRIPTION_FLOW_SOURCE_TEAMS_TAB_CLICKED]: INVITE_UP_TO_N_TEAM_MEMBERS,
};

interface TeamInviteMessage
{
    key: string;
    title: string;
    message: string;
}

export const TEAM_INVITE_UNKNOWN_ERROR: TeamInviteMessage = {
    key: "Unknown",
    title: ERROR_TITLE_OOPS,
    message: "There was a problem with the invitation.",
};

export const TEAM_SENDING_INVITE_ERRORS: TeamInviteMessage[] = [
    {
        key: "Team owner is not premium subscriber",
        title: "Ready for an upgrade?",
        message: "Take full advantage of Ripl Premium! Upgrade now to gain access to this feature and invite team members.",
    },
    {
        key: "User is already a team member",
        title: ERROR_TITLE_OOPS,
        message: "It looks like you have invited someone who is already a team member.",
    },
    {
        key: "Duplicate email address",
        title: ERROR_TITLE_OOPS,
        message: "It looks like you tried to invite the same email address more than once.",
    },
    {
        key: "Invalid email address",
        title: "Do you have a typo?",
        message: "Can you double-check the email addresses you've entered? One or more appear to be invalid.",
    },
    {
        key: "User Limit Reached",
        title: ERROR_TITLE_OOPS,
        message: "You have reached the limit of team members for this business. "
                 + "Contact customer support if you are interested in adding more team members.",
    },
];

export const TEAMS_ACCEPTING_INVITE_ERRORS: TeamInviteMessage[] = [
    {
        key: "Could not find Invitation",
        title: "Your invitation expired",
        message: "Your invitation to join this team has expired. Contact the team owner to resend your invitation to this team.",
    },
    {
        key: "Business was deleted",
        title: "Your invitation expired",
        message: "Your invitation to join this team has expired. Contact the team owner to resend your invitation to this team.",
    },
    {
        key: "Already Redeemed",
        title: "Possibly another email address?",
        message: "Hmm, the email address you entered hasn't been invited to this team. Double-check your invitation email or contact the team owner.",
    },
    {
        key: "Inviting user was deleted",
        title: "Upgrade needed",
        message: "This Team owner needs to be upgraded to a Premium subscription for access to the team feature. Contact the team owner for access.",
    },
    {
        key: "Team owner is not premium subscriber",
        title: "Upgrade needed",
        message: "This Team owner needs to be upgraded to a Premium subscription for access to the team feature. Contact the team owner for access.",
    },
    {
        key: "User is already a team member",
        title: "Welcome back",
        message: "It looks like you have already accepted an invitation to join this business. Get out there and start creating marketing videos!",
    },
    {
        key: "User Limit Reached",
        title: "This team is full",
        message: "The team owner for this team has reached the limit of team members for this business. "
                 + "Contact the team owner to continue with this team.",
    },
    {
        key: "User email doesn't match",
        title: "Possibly another email address?",
        message: "Hmm, the email address you entered hasn't been invited to this team. Double-check your invitation email or contact the team owner.",
    },
];
