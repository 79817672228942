import * as React from "react";
import { SortableElement } from "react-sortable-hoc";
import * as closeImage from "../assets/img/close.png";
import { MP4, MP4_VIDEO_TYPE, stringUtils, toMMSS } from "../helpers";
import * as lowResImage from "../assets/img/low_res_message.png";
import classNames from "classnames";

interface MediaProps
{
    itemIndex: number;
    src: string;
    selected: boolean;
    lowQuality: boolean;
    onSelected: ( index: number ) => void;
    onDeleted: ( index: number ) => void;
    onCaution: ( index: number ) => void;
    onMediaEdit: ( mediaUrl?: string ) => void;
}

interface MediaState
{
    duration: number;
    aspectRatio: number;
    shouldShowEditButton: boolean;
}

class MediaImpl extends React.PureComponent<MediaProps, MediaState>
{
    private playPromise;
    private pausePromise;

    constructor( props )
    {
        super( props );
        this.state = {
            duration: 0,
            aspectRatio: 1,
            shouldShowEditButton: false,
        };
    }

    public render()
    {
        return (
            <div className="sortableImageContainer">
                {this.createThumbnail()}
                {this.createLowQualityWarning()}
                <img className="delete circle" src={closeImage} onClick={this.handleOnDelete}
                     style={this.getDeleteStyle()}/>
            </div>
        );
    }

    public componentDidMount()
    {
        this.updateVideoDuration();
    }

    public componentWillReceiveProps( nextProps: Readonly<MediaProps>, nextContext: any ): void
    {
        this.updateVideoDuration();
    }

    private updateVideoDuration()
    {
        const videoElement = this.getVideoElement();
        if ( videoElement )
        {
            videoElement.onloadedmetadata = () =>
            {
                this.setState( { duration: videoElement.duration } );
            };
        }
    }

    private getDeleteStyle = () =>
    {
        if ( this.state.aspectRatio )
        {
            // This is the image size of the media picker in stockMedia.scss. Make sure they are the same for correct rendering
            const imageSize = 80;
            const isMediaLandscape = this.state.aspectRatio > 1;
            const positionY = isMediaLandscape ? (imageSize - (imageSize / this.state.aspectRatio)) / 2 : 0;
            const positionX = isMediaLandscape ? 0 : (imageSize - (imageSize * this.state.aspectRatio)) / 2;
            return {
                top: `${positionY}px`,
                right: `${positionX}px`,
            };
        }
    }

    private createThumbnail = () =>
    {
        const isVideo = this.isVideoSrc();
        const thumbSrc = stringUtils.getCDNUrl( this.props.src );
        const thumbClasses = classNames(
            "uploadMedia",
            { cover: isVideo },
            { selected: this.props.selected },
        );

        if ( isVideo )
        {
            return (
                <div className="videoThumbnailWrap"
                     onMouseEnter={this.onMouseEnter}
                     onMouseLeave={this.onMouseLeave}>
                    <video
                        muted
                        className={thumbClasses}
                        ref="videoElementRef">
                        <source
                            src={thumbSrc}
                            type={MP4_VIDEO_TYPE}/>
                    </video>
                    {this.createDurationLabelForVideo()}
                    {this.createEditButton()}
                </div>
            );
        }
        else
        {
            return (
                <div className="imageThumbnailWrap"
                     onMouseEnter={this.onMouseEnter}
                     onMouseLeave={this.onMouseLeave}>
                    <img
                        className={thumbClasses}
                        src={thumbSrc}
                        onClick={this.handleOnSelected}
                        onLoad={this.handleMediaOnload}/>
                    {this.createEditButton()}
                </div>
            );
        }
    }

    private isVideoSrc()
    {
        return stringUtils.getFileExt( this.props.src ) === MP4;
    }

    private createLowQualityWarning = () =>
    {
        if ( this.props.lowQuality )
        {
            return (
                <div className="lowres">
                    <img src={lowResImage} className="lowResImage" onClick={this.handleOnCaution}/>
                </div>
            );
        }
    }
    private createDurationLabelForVideo = () =>
    {
        return (
            <div className="videoDuration"> {toMMSS( this.state.duration )} </div>
        );
    }

    private createEditButton = () =>
    {
        if ( this.state.shouldShowEditButton )
        {
            return (
                <div className="overLay">
                    <button className="editButton" onClick={this.handleEditClicked}>Edit</button>
                </div>
            );
        }
    }

    private handleEditClicked = () =>
    {
        this.props.onMediaEdit( this.props.src );
    }

    private handleOnSelected = () =>
    {
        this.props.onSelected( this.props.itemIndex );
    }

    private handleOnDelete = () =>
    {
        this.props.onDeleted( this.props.itemIndex );
    }

    private handleOnCaution = () =>
    {
        this.props.onCaution( this.props.itemIndex );
    }

    private getVideoElement = () =>
    {
        const { videoElementRef } = this.refs;
        return videoElementRef as HTMLVideoElement;
    }

    private handleMediaOnload = ( element ) =>
    {
        if ( element && element.target )
        {
            this.setState( { aspectRatio: element.target.naturalWidth / element.target.naturalHeight } );
        }
    }

    private showEditButton = () =>
    {
        this.setState( { shouldShowEditButton: true } );
    }

    private hideEditButton = () =>
    {
        this.setState( { shouldShowEditButton: false } );
    }

    private playVideo = () =>
    {
        const htmlVideo = this.getVideoElement();
        if ( htmlVideo )
        {
            htmlVideo.currentTime = 0;
            if ( this.pausePromise )
            {
                this.playPromise = this.pausePromise.then( () => htmlVideo && htmlVideo.play() );
                this.pausePromise = null;
            }
            else
            {
                this.playPromise = htmlVideo.play();
            }
        }
    }

    private onMouseEnter = () =>
    {
        this.showEditButton();
        if ( this.isVideoSrc() )
        {
            this.playVideo();
        }
    }

    private onMouseLeave = () =>
    {
        this.hideEditButton();
        if ( this.isVideoSrc() )
        {
            this.stopVideo();
        }
    }

    private stopVideo = () =>
    {
        const htmlVideo = this.getVideoElement();
        if ( htmlVideo )
        {
            htmlVideo.currentTime = 0;
            if ( this.playPromise )
            {
                this.pausePromise = this.playPromise.then( () => htmlVideo && htmlVideo.pause() );
            }
            this.playPromise = null;
        }
    }
}

export const Media = SortableElement<MediaProps>( MediaImpl );
