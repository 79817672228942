import { connect } from "react-redux";
import { Dispatch } from "redux";
import { DialogControlProps, StoreState } from "../../_types";
import { PurchasePage, PurchasePageProps, PurchasePagePropsDispatchProps } from "../PurchasePage";
import { isEligibleToUpgradeToStripeAnnual } from "../../ducks/pricing";
import {
    history,
    HOME_PAGE_ALERT_ALREADY_SUBSCRIBED,
    HOMEPAGE_URL,
    MANAGE_SUBSCRIPTION_PAGE_INTERNAL_URL,
    MANAGE_SUBSCRIPTION_PAGE_URL,
    SUBSCRIPTION_FLOW_SOURCE_PURCHASE_PAGE,
} from "../../helpers";
import { getHasLoadedMeThisSession, getHasSubscribedSuccessfullyThisSession } from "../../ducks/ui";
import { modalServices, upsellServices } from "../../services";
import { pricingActions, uiActions } from "../../actions";
import { BotInfo, BrowserInfo, NodeInfo } from "detect-browser";
import { isUserPersonallySubscribed } from "../../ducks/user";

const mapStateToProps = ( storeState: StoreState, ownProps: DialogControlProps ): PurchasePageProps =>
{
    return {
        closeDialog: ownProps.closeDialog,
        hasLoadedMeThisSession: getHasLoadedMeThisSession( storeState ),
        isEligibleToUpgradeToStripeAnnual: isEligibleToUpgradeToStripeAnnual( storeState ),
        isUserPersonallySubscribed: isUserPersonallySubscribed( storeState ),
        didNotSubscribeThisSession: !getHasSubscribedSuccessfullyThisSession( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): PurchasePagePropsDispatchProps =>
{
    const clearPricingPresets = () =>
    {
        dispatch( pricingActions.updateCouponCode( undefined ) );
        dispatch( pricingActions.updateSrcCode( undefined ) );
        dispatch( pricingActions.updateProductType( undefined ) );
    };

    return {
        handleUnmounted: () =>
        {
            clearPricingPresets();
        },
        redirectToHome: async ( didNotSubscribeThisSession: boolean ) =>
        {
            clearPricingPresets();

            if ( didNotSubscribeThisSession )
            {
                dispatch( uiActions.setHomePageAlert( HOME_PAGE_ALERT_ALREADY_SUBSCRIBED ) );
            }

            await dispatch( modalServices.closeAllLightboxes() );
            history.replace( HOMEPAGE_URL );
        },
        redirectForAnnualPlanUpgrade: ( browser: BrowserInfo | BotInfo | NodeInfo | null ) =>
        {
            clearPricingPresets();

            if ( browser.os === "iOS" || browser.os === "Android OS" )
            {
                history.push( MANAGE_SUBSCRIPTION_PAGE_URL );
            }
            else
            {
                history.push( MANAGE_SUBSCRIPTION_PAGE_INTERNAL_URL );
            }
        },
        showPricingTiersUpsellInLightbox()
        {
            dispatch( upsellServices.displayTryProNoWarmup(
                SUBSCRIPTION_FLOW_SOURCE_PURCHASE_PAGE,
                () => history.replace( HOMEPAGE_URL ),
                null,
                () => history.replace( HOMEPAGE_URL ) ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( PurchasePage );
