import { Dispatch } from "redux";
import { store } from "../store";
import {
    ERROR_TITLE_SORRY,
    eventTracker,
    normalizeMeAPI,
    requests,
    stringUtils,
    toMonthDayYearTimeZone,
} from "../helpers";
import { pricingActions, uiActions, userActions } from "../actions";
import { StoreState } from "../_types";
import { getCouponCode } from "../ducks/pricing";
import { isUserLoggedIn } from "../ducks/user";
import { modalServices, pricingServices, userServices } from "./";

export const subscriptionServices = {
    subscribeWithStripe,
    getAccountInfo,
    cancelStripeSubscription,
    upgradeToAnnual,
    updateCard,
    switchSubscriptionForCancelWinBack,
    getCancelWinBackOffer,
    getCardInfo,
    switchSubscription,
};

const STRIPE_CHARGE_PATH = "/stripe_charge";
const SUBSCRIPTION_INFO_PATH = "/subscriptions/account_info";
const STRIPE_CANCEL_SUBSCRIPTION_PATH = "/subscriptions/stripe/cancel";
const STRIPE_UPGRADE_TO_ANNUAL_PATH = "/subscriptions/stripe/upgrade_to_annual";
const STRIPE_UPDATE_CARD_PATH = "/subscriptions/stripe/update_card";
const WIN_BACK_OFFER_PATH = "/subscriptions/stripe/promotions/cancel";
const SWITCH_SUBSCRIPTION_PATH = "/subscriptions/stripe/promotions/switch_subscription";

interface BaseStripePayload
{
    stripeToken?: string;
    stripe_token: string;
    reCaptchaToken: string;
}

interface UpdateCardPayload extends BaseStripePayload
{
    stripe_customer_id: string;
}

interface SubscribePayload extends BaseStripePayload
{
    plan: string;
    cc: string;
}

interface StripeCancelOrUpgradeAPIResponse
{
    message: string;
}

interface StripeSwitchSubscriptionPromotionResponse
{
    message: string;
    stripe_subscription_info: StripeSubscriptionInfoAPIData;
}

function subscribeWithStripe( stripeToken: string, sku: string, reCaptchaToken: string )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const state = store.getState();

        const couponCode = getCouponCode( state );
        const body: SubscribePayload = {
            stripeToken,
            stripe_token: stripeToken,
            plan: sku,
            reCaptchaToken,
            cc: couponCode,
        };

        return requests.post<SubscribePayload, MeAPIData>( STRIPE_CHARGE_PATH, body )
            .then(
                ( data ) =>
                {
                    dispatch( userActions.subscribeSucceeded() );
                    const normalizedData = normalizeMeAPI( data );
                    dispatch( userActions.meSuccess( normalizedData ) );
                    eventTracker.setUserPropertiesForCurrentUser();
                },
                ( error: string ) =>
                {
                    dispatch( userActions.subscribeFailed() );
                    throw new Error( error );
                },
            );
    };
}

function getCardInfo()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        return requests.get( "/stripe_card_info" ).then( ( results: StripePaymentMethodData ) =>
        {
            dispatch( userActions.stripeUserPaymentMethodSuccess( results ) );
        } );
    };
}

function getAccountInfo()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( uiActions.subscriptionInfoSpinnerEnabled( true ) );
        return requests.get( SUBSCRIPTION_INFO_PATH ).then(
            ( data: SubscriptionInfoAPIData ) =>
            {
                dispatch( uiActions.subscriptionInfoSpinnerEnabled( false ) );
                dispatch( userActions.subscriptionInfoSuccess( data ) );
            }, () =>
            {
                dispatch( uiActions.subscriptionInfoSpinnerEnabled( false ) );

                if ( isUserLoggedIn( store.getState() ) )
                {
                    dispatch( modalServices.openErrorDialog( ERROR_TITLE_SORRY,
                        "Failed to retrieve subscription information. Please try again later." ) );
                }
            },
        );
    };
}

function cancelStripeSubscription( stripeCustomerId: string )
{
    const body = { stripe_customer_id: stripeCustomerId };
    return sendStripeAction( STRIPE_CANCEL_SUBSCRIPTION_PATH, body );
}

function upgradeToAnnual( stripeCustomerId: string )
{
    const body = { stripe_customer_id: stripeCustomerId };
    return sendStripeAction( STRIPE_UPGRADE_TO_ANNUAL_PATH, body );
}

function updateCard( stripeCustomerId: string, stripeToken: string, reCaptchaToken: string )
{
    const body: UpdateCardPayload = { stripe_customer_id: stripeCustomerId, stripe_token: stripeToken, stripeToken, reCaptchaToken };
    return sendStripeAction( STRIPE_UPDATE_CARD_PATH, body );
}

function sendStripeAction( path: string, body: any )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        return requests.post<any, StripeCancelOrUpgradeAPIResponse>( path, body ).then(
            ( data ) =>
            {
                dispatch( subscriptionServices.getAccountInfo() );
                if ( path !== STRIPE_UPDATE_CARD_PATH )
                {
                    dispatch( modalServices.openConfirmationAlert( "Success!", data.message ) );
                }
            },
            ( error ) =>
            {
                dispatch( subscriptionServices.getAccountInfo() );
                dispatch( modalServices.openErrorDialogWithStandardFormat( "We're sorry",
                    null,
                    error ) );
            },
        );
    };
}

function switchSubscription( stripeCustomerId: string, stripePlanId: string, couponCode?: string )
{
    const onSuccess = ( dispatch: Dispatch<StoreState>, data: StripeSwitchSubscriptionPromotionResponse ) =>
    {
        const onClose = async () =>
        {
            await dispatch( userServices.loadMe() );
        };

        dispatch( pricingServices.showPurchaseConfirmationDialog( true, onClose ) );
    };

    return switchSubscriptionWithCallbacks( stripeCustomerId, stripePlanId, couponCode, onSuccess );
}

function switchSubscriptionForCancelWinBack( stripeCustomerId: string, stripePlanId: string, couponCode: string )
{
    const onSwitchSuccess = ( dispatch: Dispatch<StoreState>, data: StripeSwitchSubscriptionPromotionResponse ) =>
    {
        eventTracker.logWinBackOfferApplied( stripePlanId );
        const nextPaymentAmount = stringUtils.getUSDZeroPaddedPrice( data.stripe_subscription_info.next_payment_amount );
        const nextPaymentDate = toMonthDayYearTimeZone( data.stripe_subscription_info.subscription_expiration_date );
        const messageContent = "Offer has been added to your subscription. Your next payment of "
                               + nextPaymentAmount
                               + " is scheduled for "
                               + nextPaymentDate + ".";

        dispatch( modalServices.openConfirmationAlert( "Good choice!", messageContent ) );

    };

    const onSwitchError = ( dispatch: Dispatch<StoreState>, error ) =>
    {
        dispatch( modalServices.openErrorDialogWithStandardFormat( "We're sorry",
            null,
            error ) );
    };
    return switchSubscriptionWithCallbacks( stripeCustomerId, stripePlanId, couponCode, onSwitchSuccess, onSwitchError );
}

function switchSubscriptionWithCallbacks( stripeCustomerId: string,
                                          stripePlanId: string,
                                          couponCode: string,
                                          onSwitchSuccess?: ( dispatch: Dispatch<StoreState>,
                                                              data: StripeSwitchSubscriptionPromotionResponse ) => void,
                                          onSwitchFailed?: ( dispatch: Dispatch<StoreState>, error ) => void )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( uiActions.switchSubscriptionSpinnerEnabled( true ) );

        const body = {
            stripe_customer_id: stripeCustomerId,
            plan_id: stripePlanId,
            coupon_code: couponCode,
        };

        return requests.post<any, StripeSwitchSubscriptionPromotionResponse>( SWITCH_SUBSCRIPTION_PATH, body ).then(
            ( data ) =>
            {
                dispatch( uiActions.switchSubscriptionSpinnerEnabled( false ) );
                dispatch( subscriptionServices.getAccountInfo() );

                if ( onSwitchSuccess )
                {
                    onSwitchSuccess( dispatch, data );
                }
            },
            ( error ) =>
            {
                dispatch( uiActions.switchSubscriptionSpinnerEnabled( false ) );
                dispatch( subscriptionServices.getAccountInfo() );

                if ( onSwitchFailed )
                {
                    onSwitchFailed( dispatch, error );
                }
            },
        );
    };
}

function getCancelWinBackOffer()
{
    const winBackOfferPath = WIN_BACK_OFFER_PATH;

    return ( dispatch: Dispatch<StoreState> ) =>
    {
        return requests.get( winBackOfferPath ).then(
            ( data: CancelWinBackOfferApiData ) =>
            {
                const couponCode = data.coupon_code;
                if ( couponCode )
                {
                    dispatch( pricingActions.updateCouponCode( couponCode ) );
                    dispatch( pricingServices.load() );
                }
            },
        );
    };
}
