import * as React from "react";
import PostsPanel from "./homepages/PostsPanel";
import { Post } from "../_types";
import Toolbar from "react-md/lib/Toolbars/Toolbar";
import Button from "react-md/lib/Buttons/Button";
import CircularProgress from "react-md/lib/Progress/CircularProgress";
import { ErrorBoundary } from "./ErrorBoundary";
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import { ErrorPanel } from "./ErrorPanel";
import * as serverErrorImg from "../assets/img/server_error.png";
import { isEmpty } from "lodash";
import * as noResultsImg from "../assets/img/stock_media_no_results.png";
import { eventTracker, POST_INTENT_SOURCE } from "../helpers";

export interface CollectionPageProps
{
    collectionName: string;
    collectionPosts: Post[];
    hasError: boolean;
    isLoading: boolean;
    source: string;
}

export interface CollectionPageDispatchProps
{
    goBackToHome: () => void;
}

export class CollectionPage extends React.PureComponent<CollectionPageProps & CollectionPageDispatchProps>
{

    public componentDidMount()
    {
        window.onpopstate = () =>
        {
            this.props.goBackToHome();
        };
    }

    public render()
    {
        return (
            <div className="collectionPage">
                <Toolbar
                    colored
                    autoFocus
                    titleId="collection-title"
                    title={this.props.collectionName || "Browse collection"}
                    actions={
                        <Button icon={true}
                                className="backArrow"
                                iconClassName="material-icons"
                                iconChildren="arrow_back_ios"
                                onClick={this.handleBackButtonTapped}/>
                    }
                />
                {this.renderCollectionContents()}
            </div>);
    }

    private handleBackButtonTapped = () =>
    {
        this.props.goBackToHome();
    }

    private renderCollectionContents()
    {
        if ( this.props.isLoading )
        {
            return <CircularProgress className="spinner large" id="spinner"/>;
        }
        else if ( this.props.hasError )
        {
            return this.createErrorPanel();
        }
        else if ( isEmpty( this.props.collectionPosts ) )
        {
            return this.createNoResultsPanel();
        }
        else
        {
            if ( this.props.source === POST_INTENT_SOURCE )
            {
                eventTracker.logPostIntentTemplateScreenDisplayed( this.props.collectionName );
            }
            return this.createCollectionResultsGrid( this.props.collectionPosts );
        }
    }

    private createCollectionResultsGrid = ( posts: Post[] ) =>
    {
        return <ErrorBoundary
            errorMessage="There was a problem retrieving collection. If this continues, click Restart below."
            showResetButton={true}>
            <Grid className="collectionGrid">
                <Cell size={12} tabletSize={8} className="collectionResults">
                    <PostsPanel postData={posts}/>
                </Cell>
            </Grid>
        </ErrorBoundary>;
    }

    private createErrorPanel = () =>
    {
        const headline = "Unable to get data. Please try again.";
        return (<ErrorPanel imageSrc={serverErrorImg} headline={headline} renderHelpLink={true}/>);
    }

    private createNoResultsPanel = () =>
    {
        const headline = <span>This collection is no longer available</span>;
        const subTitle = "";
        return <ErrorPanel imageSrc={noResultsImg} headline={headline} subTitle={subTitle}/>;
    }
}
