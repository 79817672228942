import * as React from "react";
import { concat, isNil, map, size } from "lodash";
import {
    FOUNDATION_PRESET_ID_PREFERRED_BACKGROUND_ANIMATION,
    FOUNDATION_PRESET_ID_PREFERRED_GLOBAL_TEXT_ANIMATION,
    FOUNDATION_PRESET_ID_PREFERRED_GLOBAL_TEXT_STYLE,
    FOUNDATION_PRESET_ID_PREFERRED_SLIDE_TRANSITION,
    FOUNDATION_PRESET_ID_PREFERRED_TEXT_ANIMATION,
    FOUNDATION_PRESET_ID_PREFERRED_TEXT_STYLE,
} from "../../../_types/api";
import { DesignElementProperties } from "../../../_types";
import { FoundationPresetTab } from "./FoundationPresetTab";

export interface FoundationPresetTabConfig
{
    tabId: string;
    displayName: string;
}

interface FoundationPresetTabsProps
{
    slideTransitionProperties?: DesignElementProperties;
    backgroundAnimationProperties?: DesignElementProperties;
    selectedTabId: string;
    shouldEnablePresetText: boolean;
    shouldSupportPresetSlideTransitions: boolean;
    shouldSupportBackgroundAnimations: boolean;
    setSelectedTabId( id: string );
}

export class FoundationPresetTabs extends React.PureComponent<FoundationPresetTabsProps>
{
    get perSlideTextStyleTabs(): FoundationPresetTabConfig[]
    {
        return [
            {
                tabId: FOUNDATION_PRESET_ID_PREFERRED_TEXT_STYLE,
                displayName: "Text Style",
            },
            {
                tabId: FOUNDATION_PRESET_ID_PREFERRED_TEXT_ANIMATION,
                displayName: "Text Animation",
            },
        ];
    }

    get globalTextStyleTabs(): FoundationPresetTabConfig[]
    {
        return [
            {
                tabId: FOUNDATION_PRESET_ID_PREFERRED_GLOBAL_TEXT_STYLE,
                displayName: "Text Style",
            },
            {
                tabId: FOUNDATION_PRESET_ID_PREFERRED_GLOBAL_TEXT_ANIMATION,
                displayName: "Text Animation",
            },
        ];
    }

    get slideTransitionTabs(): FoundationPresetTabConfig[]
    {
        let tabs = [{
            tabId: FOUNDATION_PRESET_ID_PREFERRED_SLIDE_TRANSITION,
            displayName: "Slide Transitions",
        }];

        const slideTransitionProperties = this.props.slideTransitionProperties;
        if ( !isNil( slideTransitionProperties ) && size( slideTransitionProperties.categories ) > 0 )
        {
            const slideTransitionControlsTabs: FoundationPresetTabConfig[] = map( slideTransitionProperties.categories, ( category ) =>
            {
                return {
                    tabId: category.id,
                    displayName: category.displayName,
                };
            } );

            tabs = concat( tabs, slideTransitionControlsTabs );
        }

        return tabs;
    }

    get backgroundAnimationTabs(): FoundationPresetTabConfig[]
    {
        let tabs = [{
            tabId: FOUNDATION_PRESET_ID_PREFERRED_BACKGROUND_ANIMATION,
            displayName: "Background Animations",
        }];

        const slideTransitionProperties = this.props.backgroundAnimationProperties;
        if ( !isNil( slideTransitionProperties ) && size( slideTransitionProperties.categories ) > 0 )
        {
            const slideTransitionControlsTabs: FoundationPresetTabConfig[] = map( slideTransitionProperties.categories, ( category ) =>
            {
                return {
                    tabId: category.id,
                    displayName: category.displayName,
                };
            } );

            tabs = concat( tabs, slideTransitionControlsTabs );
        }

        return tabs;
    }

    get sections()
    {
        let perSlideTextPresetTabName = "Text Presets";
        if ( this.props.shouldEnablePresetText )
        {
            perSlideTextPresetTabName = "Per-Slide Text Presets";
        }

        const sections = [
            { name: perSlideTextPresetTabName, tabs: this.perSlideTextStyleTabs },
        ];

        if ( this.props.shouldEnablePresetText )
        {
            sections.push( { name: "Global Text Presets", tabs: this.globalTextStyleTabs } );
        }

        if ( this.props.shouldSupportPresetSlideTransitions )
        {
            sections.push( { name: "Slide Transitions", tabs: this.slideTransitionTabs } );
        }

        if ( this.props.shouldSupportBackgroundAnimations )
        {
            sections.push( { name: "Background Animations", tabs: this.backgroundAnimationTabs } );
        }

        return sections;
    }

    public render()
    {
        const renderedSections = map( this.sections, ( section ) =>
        {
            return (<div className="designElementTabSection" key={section.name}>
                <div className="designElementTabSectionName">{section.name}</div>

                <div className="designElementTabSectionItems">
                    {map( section.tabs,
                        ( foundationPresetTab, index ) =>
                            <FoundationPresetTab key={index} {...foundationPresetTab}
                                                 onSwitchTabId={this.onSwitchTabId}
                                                 selectedTabId={this.props.selectedTabId}/> )}
                </div>
            </div>);
        } );

        return (
            <div className="designElementTabContainer">
                {renderedSections}
            </div>
        );
    }

    private onSwitchTabId = ( tabId: string ) =>
    {
        this.props.setSelectedTabId( tabId );
    }
}
