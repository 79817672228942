import * as React from "react";

interface DelayedProps
{
    waitBeforeShow?: number;
}

interface DelayedState
{
    isShown: boolean;
}

export class Delayed extends React.PureComponent<DelayedProps, DelayedState>
{
    private timeout: any;

    constructor( props )
    {
        super( props );
        this.state = {
            isShown: false,
        };
    }

    public componentDidMount()
    {
        this.timeout = window.setTimeout( this.showChildren, this.props.waitBeforeShow || 500 );
    }

    public componentWillUnmount()
    {
        window.clearTimeout( this.timeout );
    }

    public render()
    {
        if ( this.state.isShown )
        {
            return this.props.children;
        }
        return null;
    }

    private showChildren = () =>
    {
        this.setState( { isShown: true } );
    }
}
