import * as React from "react";
import { getDesignFontName, getFontDisplayName, getFontFieldForBrand, scrollIntoViewIfNotAlreadyVisible, webFontLoader } from "../../helpers";
import { ListPicker } from "../ListPicker";
import { compact, filter, find, first, head, map, reject, sortBy, startCase, throttle, uniqBy } from "lodash";
import { EventData, FontData } from "../../_types";
import classNames from "classnames";
import { PencilButton } from "../PencilButton";
import NoBrandFontRowContainer from "./containers/NoBrandFontRow.container";
import { Brand, FontCssUrlFieldType, FontUrlFieldType, PRIMARY, SECONDARY } from "../../_types/api";
import { TrashButton } from "../TrashButton";
import { FontPreview } from "../settingsPages/FontPreview";

export interface FontRowProps
{
    font: FontData;
    selectedFont: FontData;
    disableEditingBrandFonts?: boolean;
    outerRef: React.RefObject<HTMLDivElement>;
}

export interface FontRowDispatchProps
{
    onFontSelected( selectedFontData: FontData );
    onEditFontClicked( currentFont: FontData );
    onClearFontClicked( font: FontData );
}

class FontRow extends React.PureComponent<FontRowProps & FontRowDispatchProps>
{
    private throttledHandleEditFontClicked: ( ...args: any[] ) => any;

    public componentDidMount()
    {
        this.createThrottledHandleEditFontClickedFunction();
    }

    public render()
    {
        const { font, selectedFont, disableEditingBrandFonts } = this.props;
        const fontDisplayName = getFontDisplayName( font );
        const designFontName = getDesignFontName( font );
        const selectedFontDisplayName = getFontDisplayName( selectedFont );
        return (
            <div ref={this.props.outerRef} className={classNames( "item", "font", { selected: (fontDisplayName === selectedFontDisplayName) } )}
                 onClick={this.handleFontSelection}>
                <div className={classNames( { brandFontSection: !!font.type } )}>
                    <FontPreview font={font}
                                 displayName={fontDisplayName}
                                 designFontName={designFontName}
                                 isCustomFont={!!font.url}/>
                    {font.type && <div className="brandLabel">{`${startCase( font.type )} brand font`}</div>}
                </div>
                {font.type && !disableEditingBrandFonts && <PencilButton onClick={this.handleEditFontClicked}/>}
                {font.type && !disableEditingBrandFonts && <TrashButton altText="clear" onClick={this.handleClearFontClicked}/>}
            </div>
        );
    }

    private handleFontSelection = () =>
    {
        this.props.onFontSelected( this.props.font );
    }

    private handleEditFontClicked = () =>
    {
        if ( this.throttledHandleEditFontClicked )
        {
            this.throttledHandleEditFontClicked();
        }
    }

    private createThrottledHandleEditFontClickedFunction()
    {
        const curriedHandleEditFontClicked = () =>
        {
            if ( this.props.onEditFontClicked )
            {
                this.props.onEditFontClicked( this.props.font );
            }
        };
        this.throttledHandleEditFontClicked = throttle( curriedHandleEditFontClicked, 500, { leading: true } );
    }

    private handleClearFontClicked = () =>
    {
        this.props.onClearFontClicked( this.props.font );
    }
}

export interface FontPickerProps
{
    selectedFont: FontData;
    designFonts: FontData[];
    brandFonts: FontData[];
    controlId: string;
    disableEditingBrandFonts?: boolean;
}

export interface FontPickerDispatchProps
{
    onFontSelected( selectedFontData: FontData );
    onMoreClicked( e: EventData );
    onEditFontClicked?( font: FontData );
    onClearFontClicked?( font: FontData );
}

export interface FontPickerState
{
    lastSelectedFont: FontData;
    selectedFontHistory: FontData[];
}

export class FontPicker extends React.PureComponent<FontPickerProps & FontPickerDispatchProps, FontPickerState>
{
    private throttledHandleEditFontClicked: ( ...args: any[] ) => any;
    private fontRowRefs: { [id: string]: React.RefObject<HTMLDivElement> };

    constructor( props )
    {
        super( props );

        this.state = {
            lastSelectedFont: null,
            selectedFontHistory: compact( [props.selectedFont] ),
        };
        this.fontRowRefs = {};
    }

    public componentDidMount()
    {
        this.createThrottledHandleEditFontClickedFunction();
        webFontLoader.loadFont( this.props.selectedFont );
        this.scrollToSelectedFont();
    }

    public componentDidUpdate( prevProps: Readonly<FontPickerProps & FontPickerDispatchProps>, prevState: Readonly<FontPickerState>, snapshot?: any )
    {
        const selectedFontChanged = prevProps.selectedFont && this.props.selectedFont && (prevProps.selectedFont.displayName
                                                                                          !== this.props.selectedFont.displayName);
        if ( selectedFontChanged )
        {
            const selectedFontHistory = this.state.selectedFontHistory;
            selectedFontHistory.push( this.props.selectedFont );
            const newSelectedFonts = uniqBy( selectedFontHistory, getFontDisplayName );
            this.setState( { selectedFontHistory: newSelectedFonts } );
        }
    }

    public render()
    {
        const { designFonts, selectedFont, brandFonts } = this.props;
        const sortedAndUniqueFonts = this.buildSortedFontList( designFonts, brandFonts, this.state.selectedFontHistory );
        return (
            <ListPicker moreLabel="More Fonts" onMoreClicked={this.props.onMoreClicked}>
                {this.renderBrandPrimaryFontSection()}
                {this.renderBrandSecondaryFontSection()}
                {
                    map( sortedAndUniqueFonts, ( fontData: FontData, index: number ) =>
                    {
                        if ( fontData )
                        {
                            const ref = React.createRef<HTMLDivElement>();
                            this.fontRowRefs[fontData.displayName] = ref;
                            return (
                                <FontRow key={index}
                                         outerRef={ref}
                                         font={fontData}
                                         selectedFont={selectedFont}
                                         onFontSelected={this.props.onFontSelected}
                                         onEditFontClicked={this.handleEditFontClicked}
                                         onClearFontClicked={this.props.onClearFontClicked}
                                         disableEditingBrandFonts={this.props.disableEditingBrandFonts}/>
                            );
                        }
                    } )
                }
            </ListPicker>
        );
    }

    private buildSortedFontList = ( designFonts: FontData[], brandFonts: FontData[], selectedFontHistory: FontData[] ): FontData[] =>
    {
        designFonts = filter( designFonts || [] );
        brandFonts = filter( brandFonts || [] );
        const selectedFontHistoryCleaned = map( selectedFontHistory,
            ( selectedFont: FontData ) => this.getSelectedFontCleanedOfBrand( selectedFont, brandFonts ) );

        const combinedFonts = filter(
            sortBy( [...designFonts, ...selectedFontHistoryCleaned], getFontDisplayName ) );
        const sortedAndUniqueFonts: FontData[] = uniqBy( [...combinedFonts], getFontDisplayName );
        const sortedAndUniqueFontsWithoutBrandFonts: FontData[] = reject( sortedAndUniqueFonts, ( fontData: FontData ) =>
        {
            return find( [...brandFonts], ( brandFont: FontData ) => brandFont.displayName === fontData.displayName );
        } );
        return [...sortedAndUniqueFontsWithoutBrandFonts];
    }

    private getSelectedFontCleanedOfBrand( selectedFont: FontData, brandFonts: FontData[] )
    {
        if ( selectedFont.type === PRIMARY )
        {
            return this.clearTypeWhenFontNoLongerInBrand( selectedFont, PRIMARY, brandFonts );
        }
        else if ( selectedFont.type === SECONDARY )
        {
            return this.clearTypeWhenFontNoLongerInBrand( selectedFont, SECONDARY, brandFonts );
        }
        return selectedFont;
    }

    private clearTypeWhenFontNoLongerInBrand = ( selectedFont: FontData, brand: Brand, brandFonts: FontData[] ): FontData =>
    {
        const currentBrandFontMatchingType = first( filter( brandFonts, ( fontData: FontData ) => fontData.type === brand ) );
        const primaryBrandFontDoesNotMatchCurrent = currentBrandFontMatchingType && (currentBrandFontMatchingType.displayName
                                                                                     !== selectedFont.displayName);
        if ( !currentBrandFontMatchingType || primaryBrandFontDoesNotMatchCurrent )
        {
            return { ...selectedFont, type: undefined };
        }
        return selectedFont;
    }

    private handleEditFontClicked = ( font: FontData ) =>
    {
        this.setState( { lastSelectedFont: font }, this.throttledHandleEditFontClicked );
    }

    private createThrottledHandleEditFontClickedFunction()
    {
        const curriedHandleEditFontClicked = () =>
        {
            if ( this.props.onEditFontClicked )
            {
                this.props.onEditFontClicked( this.state.lastSelectedFont );
            }
        };
        this.throttledHandleEditFontClicked = throttle( curriedHandleEditFontClicked, 250, { leading: true } );
    }

    private renderBrandPrimaryFontSection()
    {
        const brandFonts = this.props.brandFonts;
        const primaryFontType = "primary";
        const primaryBrandFont = head( filter( brandFonts, ( brandFont: FontData ) => brandFont.type === primaryFontType ) );
        if ( primaryBrandFont )
        {
            const ref = React.createRef<HTMLDivElement>();
            this.fontRowRefs[primaryBrandFont.displayName] = ref;
            return <FontRow key={primaryBrandFont.type}
                            outerRef={ref}
                            font={primaryBrandFont}
                            selectedFont={this.props.selectedFont}
                            onFontSelected={this.props.onFontSelected}
                            onEditFontClicked={this.handleEditFontClicked}
                            onClearFontClicked={this.props.onClearFontClicked}
                            disableEditingBrandFonts={this.props.disableEditingBrandFonts}/>;
        }
        else if ( !this.props.disableEditingBrandFonts )
        {
            const fontField = getFontFieldForBrand( primaryFontType );
            const fontUrlField = fontField + "_url" as FontUrlFieldType;
            const fontCssUrlField = fontField + "_css_url" as FontCssUrlFieldType;
            return <NoBrandFontRowContainer
                fontType={primaryFontType}
                fontField={fontField}
                fontUrlField={fontUrlField}
                fontCssUrlField={fontCssUrlField}
                controlId={this.props.controlId}/>;
        }
    }

    private renderBrandSecondaryFontSection()
    {
        const brandFonts = this.props.brandFonts;
        const secondaryFontType = "secondary";
        const secondaryBrandFont = head( filter( brandFonts, ( brandFont: FontData ) => brandFont.type === secondaryFontType ) );
        if ( secondaryBrandFont )
        {
            const ref = React.createRef<HTMLDivElement>();
            this.fontRowRefs[secondaryBrandFont.displayName] = ref;
            return <FontRow key={secondaryBrandFont.type}
                            outerRef={ref}
                            font={secondaryBrandFont}
                            selectedFont={this.props.selectedFont}
                            onFontSelected={this.props.onFontSelected}
                            onEditFontClicked={this.handleEditFontClicked}
                            onClearFontClicked={this.props.onClearFontClicked}
                            disableEditingBrandFonts={this.props.disableEditingBrandFonts}/>;
        }
        else if ( !this.props.disableEditingBrandFonts )
        {
            const fontField = getFontFieldForBrand( secondaryFontType );
            const fontUrlField = fontField + "_url" as FontUrlFieldType;
            const fontCssUrlField = fontField + "_css_url" as FontCssUrlFieldType;
            return <NoBrandFontRowContainer
                fontType={secondaryFontType}
                fontField={fontField}
                fontUrlField={fontUrlField}
                fontCssUrlField={fontCssUrlField}
                controlId={this.props.controlId}/>;
        }
    }

    private scrollToSelectedFont()
    {
        setTimeout( () =>
        {
            const selectedFont = this.props.selectedFont;

            if ( selectedFont )
            {
                const element = this.fontRowRefs[selectedFont.displayName]
                                && this.fontRowRefs[selectedFont.displayName].current;
                scrollIntoViewIfNotAlreadyVisible( element );
            }
        }, 200 );
    }
}
