import * as React from "react";
import List from "react-md/lib/Lists/List";
import Avatar from "react-md/lib/Avatars/Avatar";
import { includes } from "lodash";
import { SelectedCheckboxButtonIcon, SelectedRadioButtonIcon, UnselectedCheckboxButtonIcon, UnselectedRadioButtonIcon } from "./CheckboxIcons";
import * as errorImage from "../assets/img/errorImage.png";
import { FACEBOOK_INSTAGRAM_ACCOUNT_TYPE } from "../_types/api";
import { SocialNetworkAccount } from "../_types";

export interface FacebookThingsListProps
{
    allItems?: SocialNetworkAccount[];
    selectedIds: number[];
    singleSelect?: boolean;
}

export interface FacebookThingsListDispatchProps
{
    onChange?: ( item: SocialNetworkAccount, checked: boolean, event: Event ) => void;
}

export class FacebookThingsList extends React.PureComponent<FacebookThingsListProps & FacebookThingsListDispatchProps>
{
    public render()
    {
        const items = this.props.allItems || [];
        return (
            <List>
                {
                    items.map( ( item ) =>
                    {
                        return (
                            <FacebookThingsListItem key={item.id}
                                                    item={item}
                                                    singleSelect={this.props.singleSelect}
                                                    selectedIds={this.props.selectedIds}
                                                    onChange={this.props.onChange}/>
                        );
                    } )
                }
            </List>
        );
    }
}

export interface FacebookThingsListItemProps
{
    item: SocialNetworkAccount;
    selectedIds: number[];
    singleSelect?: boolean;
}

export interface FacebookThingsListItemDispatchProps
{
    onChange?: ( item: SocialNetworkAccount, checked: boolean, event: Event ) => void;
}

export class FacebookThingsListItem extends React.PureComponent<FacebookThingsListItemProps & FacebookThingsListItemDispatchProps>
{
    public render()
    {
        const { item, singleSelect } = this.props;
        const accountName = `${item.account_type === FACEBOOK_INSTAGRAM_ACCOUNT_TYPE ? "@" : ""}${item.name}`;

        const { selectedIcon, notSelectedIcon } = this.getIcons( singleSelect );

        return (
            <div className="facebookItem" onClick={this.handleChangeOnClick}>
                <div className="facebookItemControl">
                    {this.isChecked() ? selectedIcon : notSelectedIcon}
                </div>
                <div className="iconLabelWrapper">
                    {this.makeAccountAvatar( item )}
                    <span className="accountNameLabel">{accountName}</span>
                    {this.makeCannotConnectIcon( item )}
                </div>
            </div>
        );
    }

    private getIcons = ( isSingleSelect: boolean ): { selectedIcon: React.ReactNode, notSelectedIcon: React.ReactNode } =>
    {
        if ( isSingleSelect )
        {
            return {
                selectedIcon: <SelectedRadioButtonIcon/>,
                notSelectedIcon: <UnselectedRadioButtonIcon/>,
            };
        }
        else
        {
            return {
                selectedIcon: <SelectedCheckboxButtonIcon/>,
                notSelectedIcon: <UnselectedCheckboxButtonIcon/>,
            };
        }
    }

    private isChecked = () =>
    {
        return includes( this.props.selectedIds, this.props.item.id );
    }

    private makeAccountAvatar = ( account: SocialNetworkAccount ) =>
    {
        if ( account.profile_image_url )
        {
            return <Avatar src={account.profile_image_url} role="presentation"/>;
        }
    }

    private makeCannotConnectIcon = ( account: SocialNetworkAccount ) =>
    {
        if ( account.needsMorePermissions )
        {
            return (<img className="errorIcon" src={errorImage} height="100%"/>);
        }
    }

    private handleOnChange = ( checked: boolean, event: Event ) =>
    {
        this.props.onChange( this.props.item, checked, event );
    }

    private handleChangeOnClick = ( event: any ) =>
    {
        this.handleOnChange( !this.isChecked(), event );
    }
}
