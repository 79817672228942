import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { store } from "../../store";
import { PostSummary, PostSummaryDispatchProps, PostSummaryProps } from "../PostSummary";
import { modalServices, postServices } from "../../services";
import { customizationHelper, eventTracker, FullPageDialogIdentifierForKey, LightboxDialogIdentifierForKey, postHelper } from "../../helpers";
import PostDetailsContainer from "./PostDetails.container";
import { Post, StoreState } from "../../_types";
import { wasPostedAsFacebookAd } from "../../ducks/post";
import { getCollectionSlug } from "../../ducks/collections";

export interface PostSummaryContainerProps
{
    post: Post;
    isCalendarView?: boolean;
}

const mapStateToProps = ( storeState: StoreState, ownProps: PostSummaryContainerProps ): PostSummaryProps =>
{
    return {
        ...ownProps,
        isCalendarView: ownProps.isCalendarView || false,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): PostSummaryDispatchProps =>
{
    return {
        deletePost: ( post: Post ) =>
        {
            dispatch( modalServices.openLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.CONFIRM_DELETE_DRAFT_POST,
                title: "Confirm delete draft post",
                width: 400,
                content: `Are you sure you wish to delete this draft?`,
                onSuccess: () => dispatch( postServices.deleteDraft( post.id ) ),
                confirmLabel: "Delete",
                hideCancel: true,
                showCancelX: true,
            } ) );
        },
        primaryAction: async ( post: Post, e: React.MouseEvent<HTMLElement> ) =>
        {
            const storeState: StoreState = store.getState();
            if ( postHelper.mustCustomizePost( post ) )
            {
                if ( postHelper.isDraft( post ) )
                {
                    eventTracker.logDraftOpened( post.id );
                }
                else if ( postHelper.isContentSearchPost( post ) )
                {
                    eventTracker.logContentSearchClicked( post.aspect_ratio, post.output_type, post.id, post.design_slug );
                }
                else if ( postHelper.isCollectionPost( post ) )
                {
                    const collectionSlug = getCollectionSlug( storeState );
                    eventTracker.logCollectionPostClicked(
                        post.aspect_ratio,
                        post.output_type,
                        post.id,
                        post.design_slug,
                        collectionSlug );
                }

                const hasAdData = wasPostedAsFacebookAd( post );
                const customizeOptions = {
                    hasAdData,
                };

                if ( !postHelper.isDraft( post ) )
                {
                    dispatch( modalServices.openTemplatePreviewDialogWithDesignControls( post, customizeOptions ) );
                }
                else
                {
                    await modalServices.encloseInLoadingDialog( async () =>
                    {
                        await customizationHelper.customizePost( post, dispatch, customizeOptions );
                    } );
                }
            }
            else
            {
                dispatch( modalServices.openFullpageDialog( {
                    identifierForKey: FullPageDialogIdentifierForKey.POST_DETAILS,
                    content: ( { closeDialog } ) => <PostDetailsContainer postId={post.id} closeDialog={closeDialog}/>,
                }, e ) );
            }
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( PostSummary );
