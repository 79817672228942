import * as React from "react";
import Button from "react-md/lib/Buttons/Button";
import { getCommonTrialDays, getSelectedPlanTrialAndPricingText } from "../../ducks/pricing";
import { addCommonQueryParams, railsStringify, RIPL_TIER_PREMIUM, URLS, USER_CLICKED_SIGN_OUT_SOURCE_UPSELL } from "../../helpers";
import LogoutLinkContainer from "../containers/LogoutLink.container";
import { filter, first } from "lodash";
import { PurchaseProgressSpinner } from "../PurchaseProgressSpinner";
import PremiumFocusedUpsellPriceBlockSection from "./containers/PremiumFocusedUpsellPriceBlockSection.container";
import PremiumFocusedUpsellViewAllPlansPriceBlockSection from "./containers/PremiumFocusedUpsellWithAllPlansPriceBlockSection.container";

export interface PremiumFocusedUpsellChoosePlanSectionProps
{
  annualPlan?: StripePlanData;
  monthlyPlan?: StripePlanData;
  hasUsedTrial: boolean;
  handleStartSubscription: ( StripePlanData ) => void;
  setAllPlansVisible: ( visible: boolean ) => void;
  showAllPlans: boolean;
  selectTier: ( tier: ProductTier ) => void;
}

interface PremiumFocusedUpsellChoosePlanSectionState
{
  selectedPlan?: StripePlanData;
}

export class PremiumFocusedUpsellChoosePlanSection extends React.PureComponent<PremiumFocusedUpsellChoosePlanSectionProps,
  PremiumFocusedUpsellChoosePlanSectionState>
{
  constructor( props )
  {
    super( props );
    const defaultSelectedPlan = this.getDefaultSelectedPlan();
    this.state = {
      selectedPlan: defaultSelectedPlan,
    };
    this.props.selectTier( RIPL_TIER_PREMIUM );
  }

  public componentDidUpdate( prevProps: PremiumFocusedUpsellChoosePlanSectionProps,
                             prevState: PremiumFocusedUpsellChoosePlanSectionState,
                             prevContext: any ): void
  {
    const previouslyWaitingForAnyPlanData = !(prevProps.monthlyPlan || prevProps.annualPlan);
    const noLongerWaitingForAnyPlanData = !this.isWaitingForAnyPlanData();

    if ( previouslyWaitingForAnyPlanData && noLongerWaitingForAnyPlanData )
    {
      const defaultSelectedPlan = this.getDefaultSelectedPlan();
      this.setState( {
        selectedPlan: defaultSelectedPlan,
      } );
      this.props.selectTier( RIPL_TIER_PREMIUM );
    }
  }

  public render()
  {
    const { hasUsedTrial } = this.props;
    const waitingForAnyPlanData = this.isWaitingForAnyPlanData();
    if ( waitingForAnyPlanData )
    {
      return (
        <div className="choosePlanSection">
          <PurchaseProgressSpinner/>
        </div>);
    }

    return (
      <div className="choosePlanSection">
        {this.getPlansSection()}
        <Button primary flat swapTheming
                className="startTrialButton"
                disabled={this.shouldDisablePurchaseButton()}
                onClick={this.onStartSubscriptionClicked}>{this.getStartSubscriptionButtonText( hasUsedTrial )}</Button>
        <span className="linkStyle" onClick={this.togglePlansView}>{this.getTogglePlansText()}</span>
        <p className="startTrialSubtext">
          {this.getPricingTextWithHelpLink( hasUsedTrial )}
        </p>
      </div>
    );
  }

  private isWaitingForAnyPlanData()
  {
    return !(this.props.monthlyPlan || this.props.annualPlan);
  }

  private getTogglePlansText()
  {
    if ( this.props.showAllPlans )
    {
      return "GO BACK";
    }
    else
    {
      return "VIEW ALL PLANS";
    }
  }

  private getPlansSection()
  {
    if ( this.props.showAllPlans )
    {
      return <PremiumFocusedUpsellViewAllPlansPriceBlockSection
        selectedPlan={this.state.selectedPlan}
        handlePlanSelected={this.handleUpdatePlanSelection}/>;
    }
    else
    {
      return <PremiumFocusedUpsellPriceBlockSection
        selectedPlan={this.state.selectedPlan}
        handlePlanSelected={this.handleUpdatePlanSelection}/>;
    }
  }

  private shouldDisablePurchaseButton()
  {
    return !this.state.selectedPlan;
  }

  private getPricingTextWithHelpLink( hasUsedTrial: boolean )
  {
    return (
      <>
        {getSelectedPlanTrialAndPricingText( this.state.selectedPlan, this.getCommonTrialDays(), hasUsedTrial )}
        <br/>
        <LogoutLinkContainer source={USER_CLICKED_SIGN_OUT_SOURCE_UPSELL}/> or
        <span className="needHelpLink" onClick={this.handleNeedHelp}> Need help?</span>
      </>
    );
  }

  private getStartSubscriptionButtonText( hasUsedTrial: boolean )
  {
    const commonTrialDays = this.getCommonTrialDays();
    if ( commonTrialDays )
    {
      return `Start your ${commonTrialDays} day FREE trial`;
    }
    else if ( hasUsedTrial )
    {
      return "Subscribe now";
    }
    return "Start your FREE trial";
  }

  private getCommonTrialDays()
  {
    return getCommonTrialDays( [this.props.annualPlan, this.props.monthlyPlan] );
  }

  private handleUpdatePlanSelection = ( selectedPlan: StripePlanData, tier: ProductTier ) =>
  {
    this.setState( { selectedPlan } );
    this.props.selectTier( tier );
  }

  private getDefaultSelectedPlan = () =>
  {
    let defaultSelectedPlan = null;
    const noLongerWaitingForAnyPlanData = !this.isWaitingForAnyPlanData();
    if ( noLongerWaitingForAnyPlanData )
    {
      const availablePlans = filter( [this.props.monthlyPlan, this.props.annualPlan] );

      if ( availablePlans.length === 1 )
      {
        defaultSelectedPlan = first( availablePlans );
      }
      else if ( !!this.props.annualPlan )
      {
        defaultSelectedPlan = this.props.annualPlan;
      }
    }
    return defaultSelectedPlan;
  }

  private onStartSubscriptionClicked = () =>
  {
    const selectedPlan = this.state.selectedPlan;
    this.props.handleStartSubscription( selectedPlan );
  }

  private handleNeedHelp = () =>
  {
    const params = "?" + railsStringify( addCommonQueryParams( {} ) );
    window.open( URLS.HelpUpsell + params, "_blank" );
  }

  private togglePlansView = () =>
  {
    this.props.setAllPlansVisible( !this.props.showAllPlans );
  }
}
