import { Action, ActionFunction0, ActionFunctions, combineActions, handleAction, Reducer as ReduxActionReducer } from "redux-actions";
import { AnyAction, Reducer as ReduxReducer } from "redux";
import { reduceReducers } from "./";
import { forEach } from "lodash";

type ActionReducerPair<State, Payload> = [
    ActionFunctions<Payload>,
    ReduxActionReducer<State, Payload>
];

function createEmptyReducer<S>( defaultState: S ): ReduxReducer<S>
{
    const emptyReducer: ReduxReducer<S> = ( state: S, action: AnyAction ) =>
    {
        return defaultState;
    };
    return emptyReducer;
}

export class ReducerCreator<State>
{
    private reducers: Array<ReduxReducer<State>> = [];
    private registeredTypes: { [type: string]: boolean } = {};
    private defaultState: State;

    constructor( defaultState: State )
    {
        this.defaultState = defaultState;
    }

    public addAction = <Payload>( action: ActionFunctions<Payload>, reducer: ReduxActionReducer<State, Payload> ) =>
    {
        this.registerActions( action );
        this.reducers.push( handleAction( action, reducer, this.defaultState ) );
    }

    public addActions = <Payload>( ...actions: Array<ActionReducerPair<State, Payload>> ) =>
    {
        forEach( actions, ( pair ) => this.addAction( pair[0], pair[1] ) );
    }

    public addCombinedActions = <Payload>( actions: Array<ActionFunctions<Payload>>, reducer: ReduxActionReducer<State, Payload> ) =>
    {
        this.registerActions( ...actions );
        const combinedAction = combineActions( ...actions );
        this.reducers.push( handleAction( combinedAction, reducer, this.defaultState ) );
    }

    public createReducer = () =>
    {
        if ( this.reducers.length === 0 )
        {
            this.reducers = [createEmptyReducer<State>( this.defaultState )];
        }
        return reduceReducers( ...this.reducers );
    }

    private registerAction = <Payload>( action: ActionFunctions<Payload> ) =>
    {
        const type = (action as ActionFunction0<Action<Payload>>)().type;
        if ( this.registeredTypes[type] )
        {
            throw new Error( "Already registered the action with type " + type );
        }
        this.registeredTypes[type] = true;
    }

    private registerActions = <Payload>( ...actions: Array<ActionFunctions<Payload>> ) =>
    {
        forEach( actions, this.registerAction );
    }
}
