import * as React from "react";
import { AUD_CODE, CAD_CODE, CHF_CODE, EUR_CODE, GBP_CODE, INR_CODE, JPY_CODE, MXN_CODE, NZD_CODE, USD_CODE } from "../helpers";
import * as AudFlag from "../assets/img/flags/aud-flag.jpg";
import * as CadFlag from "../assets/img/flags/cad-flag.jpg";
import * as ChfFlag from "../assets/img/flags/chf-flag.jpg";
import * as EurFlag from "../assets/img/flags/eur-flag.jpg";
import * as GbpFlag from "../assets/img/flags/gbp-flag.jpg";
import * as InrFlag from "../assets/img/flags/inr-flag.jpg";
import * as JpyFlag from "../assets/img/flags/jpy-flag.jpg";
import * as MxnFlag from "../assets/img/flags/mxn-flag.jpg";
import * as NzdFlag from "../assets/img/flags/nzd-flag.jpg";
import * as UsdFlag from "../assets/img/flags/usd-flag.jpg";

export interface CurrencySelectorItemProps
{
    currencyCode: CurrencyCode;
}

export interface CurrencySelectorItemDispatchProps
{
    currencySelected?( currencyCode: CurrencyCode );
}

export class CurrencySelectorItem extends React.PureComponent<CurrencySelectorItemProps & CurrencySelectorItemDispatchProps>
{
    public render()
    {
        return (
                <div className="currencySelectorItem" onClick={this.handleCurrencySelected}>
                    <img src={this.getFlagSrc( this.props.currencyCode )}/>
                    <span>{this.props.currencyCode}</span>
                </div>
        );
    }

    private getFlagSrc = ( currencyCode: string ) =>
    {
        const flags = new Map();
        flags.set( AUD_CODE, AudFlag );
        flags.set( CAD_CODE, CadFlag );
        flags.set( CHF_CODE, ChfFlag );
        flags.set( EUR_CODE, EurFlag );
        flags.set( GBP_CODE, GbpFlag );
        flags.set( INR_CODE, InrFlag );
        flags.set( JPY_CODE, JpyFlag );
        flags.set( MXN_CODE, MxnFlag );
        flags.set( NZD_CODE, NzdFlag );
        flags.set( USD_CODE, UsdFlag );
        flags.set( MXN_CODE, MxnFlag );
        flags.set( USD_CODE, UsdFlag );
        return flags.get( currencyCode );
    }

    private handleCurrencySelected = () =>
    {
        if ( this.props.currencySelected )
        {
            this.props.currencySelected( this.props.currencyCode );
        }
    }

}
