import { store } from "../store";
import { getCachedApptimizeDynamicVariable, getCachedApptimizeFeatureFlag } from "../ducks/tracking";
import { ENABLE_APPTIMIZE, ENABLE_APPTIMIZE_ERROR_CONSOLE_LOGGING, errorReporting } from "./";
import { trackingActions } from "../actions";

export const apptimizeWrapper = {
    setup,
    getBool,
    getString,
    getCustomAttributes,
    getVariantInfo,
    isFeatureFlagEnabled,
    setCustomAttributes,
    setAppVersion,
    setCustomerUserId,
    setPilotTargetingId,
    setOnParticipatedInExperimentCallback,
    setOnMetadataUpdatedCallback,
    setOnApptimizeInitializedCallback,
    setOnEnrolledInExperimentCallback,
    setOnUnenrolledInExperimentCallback,
    track,
    updateApptimizeMetadataOnce,
    takeOnline,
    takeOffline,
};

let onlineMode = true;

function makeOfflineAwareCallback( callback: ( variantInfo?: ApptimizeVariantInfo ) => void )
{
    return ( variantInfo?: ApptimizeVariantInfo ) =>
    {
        if ( onlineMode )
        {
            callback( variantInfo );
        }
    };
}

function takeOnline()
{
    onlineMode = true;
}

function takeOffline()
{
    onlineMode = false;
}

function setup( key: string )
{
    if ( isEnabled() )
    {
        try
        {
            window.Apptimize.setup( key );
        }
        catch (e)
        {
            logToConsole( "ApptimizeWrapper Error calling Apptimize.setup(): ", e );
            errorReporting.reportErrorToSentry( e );
        }
    }
}

function getBool( name: string, defaultValue: boolean ): boolean
{
    if ( isOnline() )
    {
        const cachedValue = getCachedApptimizeDynamicVariable( store.getState(), name );
        if ( cachedValue !== undefined )
        {
            return cachedValue as boolean;
        }

        try
        {
            const result = window.Apptimize.getBool( name, defaultValue );
            logToConsole( "ApptimizeWrapper updating cached value for ", name, " cached: ", cachedValue, " actual: ", result );
            store.dispatch( trackingActions.cacheApptimizeDynamicVariable( { name, value: result } ) );
            return result;
        }
        catch (e)
        {
            logToConsole( `ApptimizeWrapper Error calling Apptimize.getBool() with name = ${name}: `, e );
            errorReporting.reportErrorToSentry( e, { name } );
        }
    }

    return defaultValue;
}

function getString( name: string, defaultValue: string ): string
{
    if ( isOnline() )
    {
        const cachedValue = getCachedApptimizeDynamicVariable( store.getState(), name );
        if ( cachedValue !== undefined )
        {
            return cachedValue as string;
        }

        try
        {
            const result = window.Apptimize.getString( name, defaultValue );
            logToConsole( "ApptimizeWrapper updating cached value for ", name, " cached: ", cachedValue, " actual: ", result );
            store.dispatch( trackingActions.cacheApptimizeDynamicVariable( { name, value: result } ) );
            return result;
        }
        catch (e)
        {
            logToConsole( `ApptimizeWrapper Error calling Apptimize.getString() with name = ${name}: `, e );
            errorReporting.reportErrorToSentry( e, { name } );
        }
    }

    return defaultValue;
}

function isFeatureFlagEnabled( name: string ): boolean
{
    if ( isOnline() )
    {
        const cachedValue = getCachedApptimizeFeatureFlag( store.getState(), name );
        if ( cachedValue !== undefined )
        {
            return cachedValue as boolean;
        }

        try
        {
            const result = window.Apptimize.isFeatureFlagEnabled( name );
            logToConsole( "ApptimizeWrapper updating cached value for ", name, " cached: ", cachedValue, " actual: ", result );
            store.dispatch( trackingActions.cacheApptimizeFeatureFlag( { name, value: result } ) );
            return result;
        }
        catch (e)
        {
            logToConsole( `ApptimizeWrapper Error calling Apptimize.isFeatureFlagEnabled() with name = ${name}: `, e );
            errorReporting.reportErrorToSentry( e, { name } );
        }
    }

    return false;
}

function setOnParticipatedInExperimentCallback( callback: ( variantInfo: ApptimizeVariantInfo ) => void )
{
    if ( isEnabled() )
    {
        try
        {
            window.Apptimize.setOnParticipatedInExperimentCallback( makeOfflineAwareCallback( callback ) );
        }
        catch (e)
        {
            logToConsole( "ApptimizeWrapper Error calling Apptimize.setOnParticipatedInExperimentCallback(): ", e );
            errorReporting.reportErrorToSentry( e );
        }
    }
}

function setOnMetadataUpdatedCallback( callback: () => void )
{
    if ( isEnabled() )
    {
        try
        {
            window.Apptimize.setOnMetadataUpdatedCallback( makeOfflineAwareCallback( callback ) );
        }
        catch (e)
        {
            logToConsole( "ApptimizeWrapper Error calling Apptimize.setOnMetadataUpdatedCallback(): ", e );
            errorReporting.reportErrorToSentry( e );
        }
    }
}

function setOnEnrolledInExperimentCallback( callback: () => void )
{
    if ( isEnabled() )
    {
        try
        {
            window.Apptimize.setOnEnrolledInExperimentCallback( makeOfflineAwareCallback( callback ) );
        }
        catch (e)
        {
            logToConsole( "ApptimizeWrapper Error calling Apptimize.setOnEnrolledInExperimentCallback(): ", e );
            errorReporting.reportErrorToSentry( e );
        }
    }
}

function setOnUnenrolledInExperimentCallback( callback: () => void )
{
    if ( isEnabled() )
    {
        try
        {
            window.Apptimize.setOnUnenrolledInExperimentCallback( makeOfflineAwareCallback( callback ) );
        }
        catch (e)
        {
            logToConsole( "ApptimizeWrapper Error calling Apptimize.setOnUnenrolledInExperimentCallback(): ", e );
            errorReporting.reportErrorToSentry( e );
        }
    }
}

function updateApptimizeMetadataOnce()
{
    if ( isOnline() )
    {
        try
        {
            window.Apptimize.updateApptimizeMetadataOnce();
        }
        catch (e)
        {
            logToConsole( "ApptimizeWrapper Error calling Apptimize.updateApptimizeMetadataOnce(): ", e );
            errorReporting.reportErrorToSentry( e );
        }
    }
}

function setOnApptimizeInitializedCallback( callback: () => void )
{
    if ( isEnabled() )
    {
        try
        {
            window.Apptimize.setOnApptimizeInitializedCallback( makeOfflineAwareCallback( callback ) );
            return;
        }
        catch (e)
        {
            logToConsole( "ApptimizeWrapper Error calling Apptimize.setOnApptimizeInitializedCallback(): ", e );
            errorReporting.reportErrorToSentry( e );
        }
    }

    if ( callback )
    {
        callback();
    }
}

function getCustomAttributes(): any
{
    if ( isEnabled() )
    {
        try
        {
            return window.Apptimize.getCustomAttributes();
        }
        catch (e)
        {
            logToConsole( "ApptimizeWrapper Error calling Apptimize.getCustomAttributes(): ", e );
            errorReporting.reportErrorToSentry( e );
        }
    }
}

function getVariantInfo(): ApptimizeVariantInfo[]
{
    if ( isEnabled() )
    {
        try
        {
            return window.Apptimize.getVariantInfo();
        }
        catch (e)
        {
            logToConsole( "ApptimizeWrapper Error calling Apptimize.getVariantInfo(): ", e );
            errorReporting.reportErrorToSentry( e );
        }
    }
}

function setCustomAttributes( attributes: any )
{
    if ( isEnabled() )
    {
        try
        {
            window.Apptimize.setCustomAttributes( attributes );
        }
        catch (e)
        {
            logToConsole( "ApptimizeWrapper Error calling Apptimize.setCustomAttributes() with attributes: ", attributes, e );
            errorReporting.reportErrorToSentry( e );
        }
    }
}

function setAppVersion( appVersion: string )
{
    if ( isEnabled() )
    {
        try
        {
            window.Apptimize.setAppVersion( appVersion );
        }
        catch (e)
        {
            logToConsole( `ApptimizeWrapper Error calling Apptimize.setAppVersion() with app version ${appVersion}: `, e );
            errorReporting.reportErrorToSentry( e );
        }
    }
}

function setCustomerUserId( userId: string )
{
    if ( isEnabled() )
    {
        try
        {
            window.Apptimize.setCustomerUserId( userId );
        }
        catch (e)
        {
            logToConsole( `ApptimizeWrapper Error calling Apptimize.setCustomerUserId() with user ID ${userId}: `, e );
            errorReporting.reportErrorToSentry( e );
        }
    }
}

function setPilotTargetingId( userId: string )
{
    if ( isEnabled() )
    {
        try
        {
            window.Apptimize.setPilotTargetingId( userId );
        }
        catch (e)
        {
            logToConsole( `ApptimizeWrapper Error calling Apptimize.setPilotTargetingId() with user ID ${userId}: `, e );
            errorReporting.reportErrorToSentry( e );
        }
    }
}

function track( eventName: string )
{
    if ( isEnabled() )
    {
        try
        {
            window.Apptimize.track( eventName );
        }
        catch (e)
        {
            logToConsole( `ApptimizeWrapper Error calling Apptimize.track() with event name ${eventName}: `, e );
        }
    }
}

function logToConsole( message: string, ...args )
{
    if ( ENABLE_APPTIMIZE_ERROR_CONSOLE_LOGGING )
    {
        // tslint:disable-next-line:no-console
        console.debug( message, args );
    }
}

function isOnline()
{
    return isEnabled() && onlineMode;
}

function isEnabled()
{
    return ENABLE_APPTIMIZE && window.Apptimize;
}
