import { store } from "../store";
import { getFontCssUrl, stringUtils } from "./";
import { FontData } from "../_types";
import { filterOutLoadingAndLoadedFonts, wasFontLoadRetried } from "../ducks/fonts";
import { Config } from "webfontloader";
import { fontCatalogActions } from "../actions";
import { delay } from "lodash";

export const webFontLoader = {
    loadCustomFontIfSpecified,
    loadFont,
};

function loadFont( font: FontData )
{
    const cssUrl = getFontCssUrl( font );
    if ( cssUrl )
    {
        loadCustomFontIfSpecified( cssUrl );
    }
    else
    {
        // will load font image preview for design fonts to help with loading performance
    }
}

function loadCustomFontIfSpecified( fontCssUrl: string, fontName?: string )
{
    if ( fontCssUrl )
    {
        if ( !fontName )
        {
            fontName = stringUtils.getBaseName( fontCssUrl );
        }

        if ( fontName )
        {
            loadFontsViaCss( [fontName], fontCssUrl );
        }
    }
}

function loadFontsViaCss( families: string[], fontCssUrl: string )
{
    const state = store.getState();
    const familiesToLoad = filterOutLoadingAndLoadedFonts( state, families );

    if ( fontCssUrl && familiesToLoad.length > 0 )
    {
        const webFontConfig: Config = {
            custom: {
                families: familiesToLoad,
                urls: [fontCssUrl],
            },
            fontactive: handleFontSuccessfullyLoaded,
            fontinactive: createdHandlerForFontLoadFailed( fontCssUrl ),
        };

        if ( window.WebFont )
        {
            store.dispatch( fontCatalogActions.loadFontsRequest( familiesToLoad ) );
            window.WebFont.load( webFontConfig );
        }
    }
}

function handleFontSuccessfullyLoaded( familyName: string )
{
    store.dispatch( fontCatalogActions.loadFontSuccess( familyName ) );
}

function createdHandlerForFontLoadFailed( fontCssUrl: string )
{
    return ( familyName: string ) =>
    {
        if ( !wasFontLoadRetried( store.getState(), familyName ) )
        {
            store.dispatch( fontCatalogActions.loadFontRetry( familyName ) );
            delay( () => loadFontsViaCss( [familyName], fontCssUrl ), 500 );
        }
        else
        {
            store.dispatch( fontCatalogActions.loadFontFailure( familyName ) );
        }
    };
}
