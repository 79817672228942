import * as React from "react";
import * as HelpIconActive from "../assets/img/helpIconActive.png";
import * as HelpIcon from "../assets/img/helpIcon.png";
import Card from "react-md/lib/Cards/Card";
import FontIcon from "react-md/lib/FontIcons/FontIcon";
import Layover from "react-md/lib/Helpers/Layover";
import List from "react-md/lib/Lists/List";
import ListItem from "react-md/lib/Lists/ListItem";
import { history, NEED_HELP_SETTINGS_URL, URLS } from "../helpers";
import { HomePageHeaderDispatchProps, HomePageHeaderProps } from "./homepages/HomePageHeader";

interface HelpMenuState
{
    visible: boolean;
}

export class HelpMenu extends React.Component<HomePageHeaderProps & HomePageHeaderDispatchProps, HelpMenuState>
{
    constructor( props )
    {
        super( props );
        this.state = {
            visible: false,
        };
    }

    public render()
    {
        return (
            <div className="headerBox switcherContainer helpMenu searchBarVisible">
                <div className="switcher">
                    <Layover
                        visible={this.state.visible}
                        onClose={this.hideDialog}
                        toggle={
                            <div className="helpMenuContainer clickable" onClick={this.showDialog}>
                                {this.state.visible ? <img src={HelpIconActive}/> : <img src={HelpIcon}/>}
                            </div>
                        }
                        animationPosition={Layover.Positions.BELOW}
                        belowAnchor={{ x: Layover.HorizontalAnchors.INNER_LEFT, y: Layover.VerticalAnchors.BOTTOM }}
                        toggleQuery=".helpMenuContainer">
                        {this.createHelpDropDown()}
                    </Layover>
                </div>
            </div>
        );
    }

    private createHelpDropDown = () =>
    {
        return (
            <Card className="switcherMenu">
                <List>
                    <ListItem
                        primaryText="FAQ"
                        leftIcon={<FontIcon>help</FontIcon>}
                        onClick={this.showFAQ}/>
                    {!this.props.shouldShowCollabraUI && <ListItem
                        primaryText="Submit a help ticket"
                        leftIcon={<FontIcon>comment</FontIcon>}
                        onClick={this.showSubmitAHelpTicket}/>
                    }
                </List>
            </Card>
        );
    }

    private hideDialog = () =>
    {
        this.props.exitSearchMode();
        this.setState( { visible: false } );
    }

    private showDialog = () =>
    {
        this.setState( { visible: true } );
    }

    private showFAQ = () =>
    {
        this.hideDialog();
        window.open( URLS.FAQ, "_blank" );
    }

    private showSubmitAHelpTicket = () =>
    {
        this.hideDialog();
        history.push( NEED_HELP_SETTINGS_URL );
    }
}
