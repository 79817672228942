import * as React from "react";
import { BusinessType } from "../../_types";
import { find } from "lodash";

export interface ChangeBusinessTypeProps
{
    businessTypes: BusinessType[];
    currentBusinessTypeId: number;
}

export interface ChangeBusinessTypeDispatchProps
{
    handleChangeBusinessType: () => void;
}

export class ChangeBusinessType extends React.PureComponent<ChangeBusinessTypeProps & ChangeBusinessTypeDispatchProps>
{
    public render()
    {
        const currentBusinessType = find( this.props.businessTypes, ( businessType ) =>
        {
            return businessType.id === this.props.currentBusinessTypeId;
        } );

        return (
            <div className="changeBusinessType">
                <h3>Business type</h3>
                <div className="currentBusinessType" onClick={this.props.handleChangeBusinessType}>
                    {!!currentBusinessType && <img src={currentBusinessType.icon_url}/>}
                    {!!currentBusinessType && currentBusinessType.name}
                </div>
                <span className="changeBusinessTypeLink" onClick={this.props.handleChangeBusinessType}>Change business type</span>
            </div>
        );
    }
}
