import * as React from "react";
import { useEffect } from "react";
import { Music, StoreState } from "../../_types";
import { areMusicItemsEqual, getCurrentMusic } from "../../ducks/musicCatalog";
import { useDispatch, useSelector, useStore } from "react-redux";
import { uiActions } from "../../actions";
import { selectMusic } from "../../helpers";
import { getAudioPlayerMusicTrack } from "../../ducks/ui";
import { Subtract } from "utility-types";

// With help from: https://medium.com/@jrwebdev/react-higher-order-component-patterns-in-typescript-42278f7590fb

export interface WithMusicPlayerProps
{
    currentMixModelMusic: Music;
    currentAudioPlayerMusicTrack: Music;
    handleMusicClick( music?: Music );
    onMusicSelected( music?: Music );
    playTrack( music?: Music );
    stopPlayingMusic();
    togglePlayingMusic( music?: Music );
    isSelected( music?: Music );
}

const withMusicPlayer = <P extends WithMusicPlayerProps>( Component: React.ComponentType<P> ) =>
{
    return ( props: Subtract<P, WithMusicPlayerProps> ) =>
    {
        const currentMixModelMusic = useSelector( ( state: StoreState ) => getCurrentMusic( state ) );
        const currentAudioPlayerMusicTrack = useSelector( getAudioPlayerMusicTrack );

        const dispatch = useDispatch();
        const store = useStore();

        useEffect( () =>
        {
            return () =>
            {
                stopPlayingMusic();
            };
        }, [] );

        const handleMusicClick = ( music?: Music ) =>
        {
            if ( !isSelected( music ) )
            {
                const state = store.getState();
                selectMusic( state, dispatch, music );
                dispatch( uiActions.updateMusicCatalogSelection( music ) );
                playTrack( music );
            }
            else
            {
                togglePlayingMusic( music );
            }
        };

        const playTrack = ( trackToPlay: Music ) =>
        {
            dispatch( uiActions.setAudioPlayerMusicTrack( trackToPlay ) );
        };

        const onMusicSelected = ( music: Music ) =>
        {
            const state = store.getState();
            selectMusic( state, dispatch, music );
            dispatch( uiActions.updateMusicCatalogSelection( music ) );
        };

        const isSelected = ( music?: Music ) =>
        {
            return areMusicItemsEqual( music, currentMixModelMusic );
        };

        const stopPlayingMusic = () =>
        {
            playTrack( undefined );
        };

        const togglePlayingMusic = ( music: Music ) =>
        {
            if ( currentAudioPlayerMusicTrack )
            {
                stopPlayingMusic();
            }
            else
            {
                playTrack( music );
            }
        };

        return <Component {...props as P}
                          currentMixModelMusic={currentMixModelMusic}
                          currentAudioPlayerMusicTrack={currentAudioPlayerMusicTrack}
                          handleMusicClick={handleMusicClick}
                          onMusicSelected={onMusicSelected}
                          playTrack={playTrack}
                          stopPlayingMusic={stopPlayingMusic}
                          togglePlayingMusic={togglePlayingMusic}
                          isSelected={isSelected}
        />;
    };
};

export default withMusicPlayer;
