import { connect } from "react-redux";
import { Dispatch } from "redux";
import { SchedulePostDialog, SchedulePostDialogDispatchProps, SchedulePostDialogProps } from "../SchedulePostDialog";
import { uiActions } from "../../actions";
import { postServices } from "../../services";
import { getSchedulePostDialogData } from "../../ducks/ui";
import { SchedulePostDialogData, StoreState } from "../../_types";

const RESCHEDULE_BUTTON_LABEL = "Reschedule";

export interface SelfSavingSchedulePostDialogProps
{
    scheduledSendDatetime: string;
}

const mapStateToProps = ( storeState: StoreState, ownProps: SelfSavingSchedulePostDialogProps ): SchedulePostDialogProps =>
{
    return {
        schedulePostDialogData: getSchedulePostDialogData( storeState ),
        scheduledSendDatetime: ownProps.scheduledSendDatetime,
        scheduleButtonLabel: RESCHEDULE_BUTTON_LABEL,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): SchedulePostDialogDispatchProps =>
{
    return {
        handleCloseModal: () =>
        {
            dispatch( uiActions.schedulePostModalClose() );
        },
        onSchedulePost: ( postId: number, sendDate: string ) =>
        {
            dispatch( uiActions.schedulePostModalClose() );
            dispatch( postServices.reschedulePost( postId, sendDate ) );
        },
        onUnschedulePost: ( schedulePostDialogData: SchedulePostDialogData ) =>
        {
            dispatch( uiActions.schedulePostModalClose() );
            const postId = schedulePostDialogData && schedulePostDialogData.postId;
            dispatch( postServices.unschedulePost( postId ) ).then( () =>
            {
                if ( schedulePostDialogData && schedulePostDialogData.closePostDetails )
                {
                    schedulePostDialogData.closePostDetails();
                }
            } );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( SchedulePostDialog );
