import { connect } from "react-redux";
import { Dispatch } from "redux";
import { CollectionData, Post, StoreState } from "../../../_types";
import { CollectionRow, CollectionRowDispatchProps, CollectionRowProps } from "../CollectionRow";
import { collectionsServices, modalServices } from "../../../services";
import { COLLECTION_PAGE_URL, COLLECTION_SOURCE, eventTracker, history } from "../../../helpers";
import { collectionActions } from "../../../actions";

export interface CollectionRowContainerProps
{
    collection: CollectionData;
    collectionRowIndex: number;
}

const mapStateToProps = ( state: StoreState, ownProps: CollectionRowContainerProps ): CollectionRowProps =>
{
    return { ...ownProps };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: CollectionRowContainerProps ): CollectionRowDispatchProps =>
{
    return {
        handleCustomizePost: async ( post: Post, collectionRowPostIndex: number ) =>
        {
            const collection = ownProps.collection;
            eventTracker.logCollectionRowPostClicked(
                post,
                collection.slug,
                ownProps.collectionRowIndex,
                collectionRowPostIndex,
                collection.posts.length,
            );

            const customizeOptions = {
                collectionPostId: post.id,
                collectionSlug: collection.slug,
                collectionRowIndex: ownProps.collectionRowIndex,
            };
            dispatch( collectionActions.collectionRowIndexUpdated( ownProps.collectionRowIndex ) );

            dispatch( modalServices.openTemplatePreviewDialogWithDesignControls( post, customizeOptions ) );
        },
        openCollection: async ( slug: string ) =>
        {
            dispatch( collectionActions.collectionRowIndexUpdated( ownProps.collectionRowIndex ) );
            dispatch( collectionsServices.openCollection( slug, COLLECTION_SOURCE ) );
            history.push( COLLECTION_PAGE_URL );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( CollectionRow );
