import * as React from "react";
import BrandColorsControlPickerContainer from "./containers/BrandColorsControlPicker.container";
import Divider from "react-md/lib/Dividers/Divider";
import { ColorSuggestions } from "./editorControls/ColorSuggestions";
import EditableColorInput from "./editorControls/EditableColorInput";
import { EyedropperPicker } from "./editorControls/EyedropperPicker";
import ColorInput from "./editorControls/GradientColorPicker";
import { EditorDataValue, UserBusiness } from "../_types";
import { ColorResult } from "react-color";
import { IControlConfig } from "../_types/api";
import classNames from "classnames";

export interface ColorControlPickerProps
{
    currentBusiness: UserBusiness;
    control?: IControlConfig;
    currentSelectedHexValue: EditorDataValue;
    disabled?: boolean;
}

export interface ColorControlPickerDispatchProps
{
    applyHexColorToControl( controlId: string, colorHex: string );
}

export class ColorControlPicker extends React.PureComponent<ColorControlPickerProps & ColorControlPickerDispatchProps>
{
    public render()
    {
        return (
            <div className={classNames( "picker" )}>
                {this.props.currentBusiness && <BrandColorsControlPickerContainer applyHexColor={this.applyHexColor}/>}

                <Divider className="divider"/>

                <ColorSuggestions title="Suggested colors"
                                  currentColorHex={this.props.disabled ? undefined : this.props.currentSelectedHexValue as string}
                                  onColorTapped={this.applyHexColor}/>
                <Divider className="divider"/>

                <div className="colorInput">
                    <EditableColorInput color={this.props.currentSelectedHexValue as string} onChangeComplete={this.onColorChangeComplete}/>
                    <EyedropperPicker applyHexColor={this.applyHexColor}/>
                    <ColorInput color={this.props.currentSelectedHexValue as string} onChangeComplete={this.onColorChangeComplete}/>
                </div>
            </div>
        );
    }

    private onColorChangeComplete = ( color: ColorResult ) =>
    {
        this.applyHexColor( color.hex );
    }

    private applyHexColor = ( colorHex: string ) =>
    {
        this.props.applyHexColorToControl( this.props.control.id, colorHex );
    }
}
