import { connect } from "react-redux";
import { Dispatch } from "redux";
import UserSettingsSubPage, { UserSettingsSubPageDispatchProps, UserSettingsSubPageProps } from "../UserSettingsSubPage";
import { logoutServices, modalServices, upsellServices, userServices } from "../../../services";
import {
    eventTracker,
    history,
    LightboxDialogIdentifierForKey,
    MANAGE_SUBSCRIPTION_PAGE_INTERNAL_URL,
    SUBSCRIPTION_FLOW_SOURCE_ACCOUNT_SETTINGS,
    SUBSCRIPTION_TERMS_URL,
    USER_CLICKED_SIGN_OUT_SOURCE_MY_ACCOUNT_TAB,
} from "../../../helpers";
import { StoreState, UserState } from "../../../_types";
import { getUserSlug, isUserPersonallySubscribed } from "../../../ducks/user";
import { getTryRiplProText } from "../../../ducks/pricing";
import { CredentialResponse } from "@react-oauth/google";
import { store } from "../../../store";

const mapStateToProps = ( storeState: StoreState ): UserSettingsSubPageProps =>
{
    return {
        user: storeState.user,
        isUserPersonallySubscribed: isUserPersonallySubscribed( storeState ),
        tryRiplProText: getTryRiplProText( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): UserSettingsSubPageDispatchProps =>
{
    return {
        handleUpdateUser: ( data: Partial<UserState> ) =>
        {
            dispatch( userServices.update( data ) );
        },
        handleAddPasswordAlert: () =>
        {
            dispatch( modalServices.openLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.ADD_PASSWORD,
                hideCancel: false,
                confirmLabel: "Set Password",
                cancelLabel: "Not Now",
                width: 500,
                content: "Your account doesn't have a password, would you like to set one now?",
                onSuccess: () =>
                {
                    history.push( "/account/changePassword" );
                },
            } ) );
        },
        handleManageSubscription: () =>
        {
            eventTracker.logManageSubscriptionTapped();
            history.push( MANAGE_SUBSCRIPTION_PAGE_INTERNAL_URL );
        },
        handleSubscriptionTerms: () =>
        {
            history.push( SUBSCRIPTION_TERMS_URL );
        },
        handleTryRiplPro: () =>
        {
            dispatch( upsellServices.displayTryProNoWarmup( SUBSCRIPTION_FLOW_SOURCE_ACCOUNT_SETTINGS ) );
        },
        handleSignOut: () =>
        {
            eventTracker.logUserClickedSignOutEvent( USER_CLICKED_SIGN_OUT_SOURCE_MY_ACCOUNT_TAB );
            dispatch( logoutServices.logout() );
        },
        handleGoogleConnectResponse( tokenResponse: CredentialResponse )
        {
            const storeState = store.getState();
            const userSlug = getUserSlug( storeState );
            dispatch( userServices.clientGoogleConnect( userSlug, tokenResponse.credential ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( UserSettingsSubPage );
