import { TeamInvite, TeamMember } from "../../../_types";
import { includes } from "lodash";
import { ANONYMIZED_EMAIL } from "../../../helpers";
import { TableColumn } from "react-md";
import * as React from "react";

export function UsersTabRowEmailColumn( props: { person: TeamInvite | TeamMember } )
{
    return (
        <TableColumn
            tooltipLabel={getEmailToolTip( props.person )}
            tooltipClassName="riplTooltip">
            {getEmail( props.person )}
        </TableColumn>
    );
}

function getEmailToolTip( member: TeamInvite | TeamMember )
{
    if ( isMissingEmail( member ) )
    {
        return "Each team member can add or update their email address on the My Account page";
    }
    else
    {
        return null;
    }
}

function isMissingEmail( person: TeamInvite | TeamMember )
{
    return includes( person.email, ANONYMIZED_EMAIL );
}

export function getEmail( person: TeamInvite | TeamMember )
{
    return isMissingEmail( person ) ? "" : person.email;
}
