import * as React from "react";
import { getMonthlyAmount, isAnnualPlan, isMonthlyPlan, isPlanDiscounted } from "../ducks/pricing";
import { dollarsFormattedForCurrency, LOCALE_US, USD_CODE } from "../helpers";
import { PricePlanRadioButtonContainerProps } from "./containers/PricePlanRadioButton.container";
import * as SelectedCheckMark from "../assets/img/whiteCheckInCobaltBox.svg";
import * as BestDealBadge from "../assets/img/bestDeal.svg";
import classNames from "classnames";
import { getLocaleForCurrency } from "../helpers/currencyHelper";

export interface PricePlanRadioButtonDispatchProps
{
    selectPlan: ( plan: StripePlanData ) => void;
}

export interface PricePlanRadioButtonProps extends PricePlanRadioButtonContainerProps
{
    currencyCode?: CurrencyCode;
    currentExchangeRate?: number;
    shouldShowConvertedCurrency: boolean;
}

export class PricePlanRadioButton extends React.PureComponent<PricePlanRadioButtonDispatchProps & PricePlanRadioButtonProps>
{
    public render()
    {
        const { selected, plan } = this.props;
        const shouldShowBestDealBadge = isAnnualPlan( plan );

        return (
            <div className={classNames( "pricePlanRadioButtonContainer", "clickable", { pricePlanButtonSelected: selected } )}
                 onClick={this.handleSelected}>
                {
                    shouldShowBestDealBadge &&
                    <div className="bestDealBadge">
                        <img src={BestDealBadge} alt="selected"/>
                    </div>
                }
                {
                    selected &&
                    <div className="selectedCheckMark">
                        <img src={SelectedCheckMark} alt="selected"/>
                    </div>
                }
                <div className="pricePlanRadioButtonPlanTypeText">
                    {this.getPlanTypeText()}
                </div>
                {plan.coupon_details && plan.coupon_details.percent_off &&
                 <div className="pricePlanRadioButtonPriceAmountText compareWithPlanText">
                     {this.getPriceAmountText( plan, true )}
                 </div>
                }
                <div className="pricePlanRadioButtonPriceAmountText">
                    {this.getPriceAmountText( plan, false )}
                </div>
            </div>
        );
    }

    private handleSelected = () =>
    {
        this.props.selectPlan( this.props.plan );
    }

    private getPlanTypeText()
    {
        const { plan } = this.props;

        if ( isPlanDiscounted( plan ) )
        {
            return isMonthlyPlan( plan ) ? "FIRST MONTH" : "FIRST YEAR";
        }

        return isMonthlyPlan( plan ) ? "1 MONTH" : "1 YEAR";
    }

    private getPriceAmountText = ( plan: StripePlanData, showOriginalPrice: boolean ) =>
    {
        const { monthlyAmount, annualAmount } = this.getPricesToShow( plan, showOriginalPrice );
        const localeFormattedMonthlyPrice = this.getLocaleFormattedPrice( monthlyAmount );
        const localeFormattedAnnualPrice = this.getLocaleFormattedPrice( annualAmount );
        const bigTextPrice = this.props.showAsMonthlyRate ? localeFormattedMonthlyPrice : localeFormattedAnnualPrice;
        const smallTextPrice = this.props.showAsMonthlyRate ? localeFormattedAnnualPrice : localeFormattedMonthlyPrice;

        return (
            <>
                <div>{bigTextPrice}</div>
                {
                    isAnnualPlan( plan ) &&
                    <div className="smallPriceText">
                        {this.getSmallPriceText( smallTextPrice, !this.props.showAsMonthlyRate )}
                    </div>
                }
            </>
        );
    }

    private getPricesToShow = ( plan: StripePlanData, showOriginalPrice: boolean ): { monthlyAmount: number, annualAmount: number } =>
    {
        const { amount: monthlyAmount, originalAmount: originalMonthlyAmount } = getMonthlyAmount( plan );

        if ( showOriginalPrice )
        {
            return {
                monthlyAmount: this.convertUSDAmountToSelectedCurrency( originalMonthlyAmount ),
                annualAmount: this.convertUSDAmountToSelectedCurrency( plan.originalAmount ),
            };
        }
        else
        {
            return {
                monthlyAmount: this.convertUSDAmountToSelectedCurrency( monthlyAmount ),
                annualAmount: this.convertUSDAmountToSelectedCurrency( plan.amount ),
            };
        }
    }

    private getSmallPriceText( formattedPrice: string, isMonthly: boolean )
    {

        const durationText = isMonthly ? "month" : "year";
        if ( this.props.showShortenedSmallPriceText )
        {
            return `${formattedPrice}/${durationText}`;
        }
        else
        {
            return `Billed ${durationText}ly at ${formattedPrice}`;
        }
    }

    private convertUSDAmountToSelectedCurrency = ( amountInUSD: number ): number =>
    {
        if ( this.props.shouldShowConvertedCurrency )
        {
            const conversionRate = this.props.currentExchangeRate;
            return amountInUSD * conversionRate;
        }
        return amountInUSD;
    }

    private getLocaleFormattedPrice = ( amount: number ): string =>
    {
        if ( this.props.shouldShowConvertedCurrency )
        {
            return dollarsFormattedForCurrency( getLocaleForCurrency( this.props.currencyCode ), this.props.currencyCode, amount );
        }
        return dollarsFormattedForCurrency( LOCALE_US, USD_CODE, amount );
    }
}
