import { connect } from "react-redux";
import { Dispatch } from "redux";
import { store } from "../../../store";
import {
    doesCurrentDesignSupportExtraCaptions,
    getCustomizableCanvas,
    getDesignCaptionData,
    getSelectedCaption,
    getSortedCaptionsTypes,
} from "../../../ducks/ui";
import { StoreState } from "../../../_types";
import { eventTracker } from "../../../helpers";
import { AddTextBoxButton, AddTextBoxButtonDispatchProps, AddTextBoxButtonProps } from "../AddTextBoxButton";
import { Positions } from "react-md/lib/Tooltips/injectTooltip";
import { uiActions } from "../../../actions";

export interface AddTextBoxButtonContainerProps
{
    buttonText?: string;
    shouldCreateGlobalText?: boolean;
    showPlusButton?: boolean;
    tooltipPosition?: Positions;
}

const mapStateToProps = ( storeState: StoreState, ownProps: AddTextBoxButtonContainerProps ): AddTextBoxButtonProps =>
{
    return {
        doesCurrentDesignSupportExtraCaptions: doesCurrentDesignSupportExtraCaptions( storeState ),
        sortedCaptions: getSortedCaptionsTypes( storeState ),
        designCaptionData: getDesignCaptionData( storeState ),
        ...ownProps,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: AddTextBoxButtonContainerProps ): AddTextBoxButtonDispatchProps =>
{
    return {
        onAddTextBoxClicked: async () =>
        {
            const canvas = getCustomizableCanvas( store.getState() );
            if ( canvas )
            {
                canvas.tellDesignToAddExtraCaption( { showOnAllSlides: ownProps.shouldCreateGlobalText || false } );
                await canvas.tellDesignToSaveSettings();
                eventTracker.logExtraCaptionAddedEvent();

                const selectedCaption = getSelectedCaption( store.getState() );
                if ( selectedCaption )
                {
                    dispatch( uiActions.setTextPropertiesSubPanelCaptionData( selectedCaption ) );
                    store.dispatch( uiActions.updateFocusElement( selectedCaption.id ) );
                }
            }
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( AddTextBoxButton );
