import * as React from "react";
import { ErrorBoundary } from "../ErrorBoundary";
import { CollectionData, Post, SocialCalendarState, UserBusiness } from "../../_types";
import NoticeBannerContainer from "./containers/NoticeBanner.container";
import classNames from "classnames";
import {
    apptimizeWrapper,
    CREATE_A_POST_BANNER_BUTTON_TEXT,
    CREATE_A_POST_BANNER_TITLE,
    POST_INTENT_BANNER_BUTTON_TEXT,
    POST_INTENT_BANNER_TITLE,
} from "../../helpers";
import HomePostRowsPanelContainer from "./containers/CollectionRowsPanel.container";
import CreateYourOwnBanner from "./containers/CreateYourOwnBanner.container";
import InspirationHeader from "./containers/InspirationHeader.container";
import InspirationGridContainer from "./containers/InspirationGrid.container";
import ShowYouAroundSequence from "../ShowYouAroundSequence";
import HomePageCoachMarks from "../containers/HomePageCoachMarks.container";
import SocialCalendarContainer from "./containers/SocialCalendar.container";
import PostIntentsBanner from "./containers/PostIntentsBanner.container";
import BusinessTypeFilterRowContainer from "./containers/BusinessTypeFilterRow.container";

export interface HomeDiscoverSubPageProps
{
    draftPosts: Post[];
    currentBusiness: UserBusiness;
    isUserLoggedIn: boolean;
    hasCreatedFirstBusiness: boolean;
    hasPosted: boolean;
    collectionRows: CollectionData[];
    socialCalendar: SocialCalendarState;
    shouldDisplayShowYouAround: boolean;
    shouldShowSocialCalendar: boolean;
    isCollabraUser: boolean;
    showTwoBanners: boolean;
    showBusinessTypeFilter: boolean;
}

export interface HomeDiscoverPageDispatchProps
{
    loadDashboardData: () => void;
    showYouAroundDisplayed: () => void;
    dismissShowYouAround: () => void;
}

export class HomeDiscoverSubPage extends React.PureComponent<HomeDiscoverSubPageProps & HomeDiscoverPageDispatchProps>
{
    public componentDidMount()
    {
        this.checkForApptimizeVariableChanges();
        if ( this.props.isUserLoggedIn )
        {
            this.props.loadDashboardData();
        }
    }

    public render()
    {
        return (
            <div className={classNames( "page inspire" )} ref="homeDiscoverSubPageRef">
                <ErrorBoundary
                    errorMessage="There was a problem creating the Inspire tab. If this continues, click Restart below."
                    showResetButton={true}>
                    <NoticeBannerContainer/>
                    <div className="createYourOwnBannerWrap">
                        <CreateYourOwnBanner
                            text={CREATE_A_POST_BANNER_TITLE}
                            buttonText={CREATE_A_POST_BANNER_BUTTON_TEXT}
                            twoBanners={this.props.showTwoBanners}
                        />
                        {this.props.showTwoBanners && <PostIntentsBanner
                            text={POST_INTENT_BANNER_TITLE}
                            buttonText={POST_INTENT_BANNER_BUTTON_TEXT}/>}
                    </div>
                    {this.props.shouldShowSocialCalendar && <SocialCalendarContainer/>}
                    {this.props.showBusinessTypeFilter && <BusinessTypeFilterRowContainer/>}
                    <HomePostRowsPanelContainer/>
                    {this.shouldLoadInspirations() &&
                     <>
                         <InspirationHeader/>
                         <InspirationGridContainer/>
                     </>}
                    {this.props.shouldDisplayShowYouAround &&
                     <ShowYouAroundSequence onClose={this.props.dismissShowYouAround}
                                            onAfterOpen={this.props.showYouAroundDisplayed}/>}
                    {!this.props.shouldDisplayShowYouAround && <HomePageCoachMarks/>}
                </ErrorBoundary>
            </div>
        );
    }

    private shouldLoadInspirations = () =>
    {
        return !this.props.isCollabraUser && this.props.collectionRows.length > 0;
    }

    private checkForApptimizeVariableChanges = () =>
    {
        apptimizeWrapper.updateApptimizeMetadataOnce();
    }
}
