import * as React from "react";
import * as CheckmarkWhite from "../../assets/img/checkmark_white.svg";

export class RoundedCornerCheckmark extends React.PureComponent<{}>
{
    public render()
    {
        return (
            <div className="roundedCornerCheckmark">
                <img src={CheckmarkWhite} alt="checkmark"/>
            </div>
        );
    }
}
