import * as React from "react";
import Beforeunload from "react-beforeunload";
import { NavigationHeader } from "./NavigationHeader";
import EnableShareOutputList from "./containers/EnableShareOutputList.container";
import PostMediaPreviewContainer from "./containers/PostMediaPreview.container";
import FacebookPickerDialog from "./containers/FacebookPickerDialog.container";
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import SocialAccountSettingsButton from "./SocialAccountSettingsButton";
import { ErrorBoundary } from "./ErrorBoundary";
import FacebookGroupAddRiplDialog from "./containers/FacebookGroupAddRiplDialog.container";
import ConfirmFacebookDialog from "./containers/ConfirmFacebookDialog.container";
import { UserBusiness, UserState } from "../_types";
import RecordingVideoElement from "./containers/RecordingVideoElement.container";
import { eventTracker } from "../helpers";
import { store } from "../store";
import { uiActions } from "../actions";

export interface SharePageProps
{
    currentBusiness: UserBusiness;
    user: UserState;
    hideAccountSettings: boolean;
}

export interface SharePageDispatchProps
{
    onBackButtonClick(): void;
    onBrowserBackButtonClick(): void;
    onShareNowButtonClick(): void;
    onSharingComplete(): void;
    onOpenSocialAccountSettingsDialog(): void;
    initializeViaText();
}

export default class SharePage extends React.PureComponent<SharePageProps & SharePageDispatchProps>
{
    public componentDidMount()
    {
        const dispatch = store.dispatch;
        dispatch( uiActions.confirmFacebookModalClose() );

        this.props.initializeViaText();

        window.onpopstate = () =>
        {
            this.props.onBrowserBackButtonClick();
        };
    }

    public componentWillUnmount()
    {
        const dispatch = store.dispatch;
        dispatch( uiActions.confirmFacebookModalClose() );
    }

    public render()
    {
        return (
            <Beforeunload onBeforeunload={this.onBeforeunload}>
                <div className="sharePage">
                    <ErrorBoundary errorMessage="There was a problem creating the share screen. Please restart to continue."
                                   showResetButton={true}>

                        <NavigationHeader pageTitle="Caption & Share" {...this.props} />
                        <Grid>
                            <Cell className="accountSidebar" size={4} tabletSize={8} tabletOrder={3} phoneOrder={3}>
                                <h2>Choose channels</h2>
                                <EnableShareOutputList/>
                                {!this.props.hideAccountSettings &&
                                 <SocialAccountSettingsButton onClick={this.props.onOpenSocialAccountSettingsDialog}/>}
                            </Cell>
                            <Cell size={4} tabletSize={8} tabletOrder={1} phoneOrder={1} className="recordingPanel">
                                <RecordingVideoElement/>
                            </Cell>
                            <PostMediaPreviewContainer {...this.props} />
                            <FacebookPickerDialog/>
                            <FacebookGroupAddRiplDialog/>
                            <ConfirmFacebookDialog buttonNameForInstructions="Connect"/>
                        </Grid>
                    </ErrorBoundary>
                </div>
            </Beforeunload>
        );
    }

    private onBeforeunload = () =>
    {
        eventTracker.logPostFlowCancelledByBrowserUnload();
    }
}
