import * as React from "react";
import TextField from "react-md/lib/TextFields/TextField";
import classNames from "classnames";

export interface PostMediaCaptionProps
{
    caption: string;
    suffixText: string;
    shouldShowCharacterCount: boolean;
    isShowingDialog: boolean;
    exceedsHashtagLimitError: string;
    exceedsCharacterLimitError: string;
    actualCharacterCount: number;
    maxCharacterCount: number;
}

export interface PostMediaCaptionDispatchProps
{
    onCaptionUpdated: ( value: string ) => void;
}

export class PostMediaCaption extends React.PureComponent<PostMediaCaptionProps & PostMediaCaptionDispatchProps>
{
    public render()
    {
        return (
            <>
                <TextField
                    ref="textFieldRef"
                    id="floating-multiline"
                    className="postMediaCaption"
                    floating
                    value={this.props.caption}
                    rows={8}
                    maxRows={8}
                    onChange={this.handleCaptionUpdate}
                    onFocus={this.setStartPosition}
                    disabled={this.props.isShowingDialog}
                />

                <div className="characterLimitContainer">
                    <span className="error">{this.props.exceedsCharacterLimitError || this.props.exceedsHashtagLimitError}</span>
                    {
                        this.props.shouldShowCharacterCount &&
                        <div className={classNames( "characterCount", { exceedsCharacterLimit: !!this.props.exceedsCharacterLimitError } )}>
                            <span>{this.props.actualCharacterCount} / {this.props.maxCharacterCount}</span>
                        </div>
                    }
                </div>
            </>

        );
    }

    private handleCaptionUpdate = ( value ) =>
    {
        const caption = value.toString();
        const fixedCaption = caption.length > 0 ? caption : "";
        this.props.onCaptionUpdated( fixedCaption );
    }

    private setStartPosition = () =>
    {
        setTimeout( () =>
        {
            const { textFieldRef } = this.refs;
            if ( !textFieldRef )
            {
                return;
            }
            const textField = (textFieldRef as any).getField() as HTMLTextAreaElement;
            if ( !textField )
            {
                return;
            }
            const lastValidPosition = textField.value.indexOf( this.props.suffixText );
            const isInSuffixText = lastValidPosition <= textField.selectionStart;
            if ( isInSuffixText )
            {
                textField.selectionStart = lastValidPosition;
                textField.selectionEnd = lastValidPosition;
            }
        } );
    }
}
