import { connect } from "react-redux";
import { Dispatch } from "redux";
import { userBusinessServices } from "../../../services";
import { StoreState } from "../../../_types";
import { getShowBrandSlideLogoFromMixModel } from "../../../ducks/mixModel";
import { LogoSwitcherPanel, LogoSwitcherPanelDispatchProps, LogoSwitcherPanelProps } from "../LogoSwitcherPanel";
import { getEditedBusinessInfo } from "../../../ducks/ui";
import { getCurrentBusiness } from "../../../ducks/userBusiness";
import { mixModelActions, uiActions } from "../../../actions";
import { BRAND_SLIDE_SOURCE, eventTracker } from "../../../helpers";

const mapStateToProps = ( storeState: StoreState ): LogoSwitcherPanelProps =>
{
    return {
        source: BRAND_SLIDE_SOURCE,
        showBrandLogo: getShowBrandSlideLogoFromMixModel( storeState ),
        showLogoToggleText: "Show logo in Brand Slide",
        brandLogos: getEditedBusinessInfo( storeState ).logos,
        currentBusiness: getCurrentBusiness( storeState ),
        defaultNumLogosToShow: 2,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): LogoSwitcherPanelDispatchProps =>
{
    return {
        refreshBrandLogos()
        {
            dispatch( uiActions.clearBusinessInfo() );
            dispatch( userBusinessServices.loadBrandLogos() );
        },
        handleShowBrandLogo: ( enabled: boolean ) =>
        {
            eventTracker.logLogoToggleSwitched( enabled, BRAND_SLIDE_SOURCE );
            dispatch( mixModelActions.brandSlideDataUpdated( { endCardData: { showLogo: enabled } } ) );
        },
        onBrandLogoSwitched()
        {
            dispatch( mixModelActions.brandSlideDataUpdated( { endCardData: { showLogo: true } } ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( LogoSwitcherPanel );
