let touchDevice;

export function isTouchDevice(): boolean
{
    if ( typeof touchDevice === "undefined" )
    {
        touchDevice = calculateIsTouchDevice();
    }
    return touchDevice;
}

function calculateIsTouchDevice()
{
    const types = ["-webkit-", "-moz-", "-o-", "-ms-"].map( ( prefix ) => prefix + "touch-enabled" );

    const DocumentTouch = (window as any).DocumentTouch;
    if ( ("ontouchstart" in window) || DocumentTouch && document instanceof DocumentTouch )
    {
        return true;
    }

    // include the "heartz" as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    const query = ["(", types.join( "),(" ), "heartz", ")"].join( "" );
    return mq( query );
}

const mq = ( query ) =>
{
    return window.matchMedia( query ).matches;
};
