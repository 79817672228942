import * as React from "react";
import classNames from "classnames";

export interface TeamDialogProps
{
    title?: string;
    subtitle?: string;
    content?: JSX.Element | string;
    shouldShowShortOkButton?: boolean;
    isShortFormFactor?: boolean;
}

export interface TeamDialogDispatchProps
{
    handleCancel();
}

export class TeamDialog extends React.PureComponent<TeamDialogProps & TeamDialogDispatchProps>
{
    public render()
    {
        return (
            <div className={classNames( "teamDialogBody", { shortFormFactor: this.props.isShortFormFactor } )}>
                <div className="titleWrap">
                    <div className="title">{this.props.title}</div>
                    {this.props.subtitle && <div className="subtitle">{this.props.subtitle}</div>}
                </div>
                {this.props.content}
                {this.props.shouldShowShortOkButton && <div className="shortOkButton" onClick={this.props.handleCancel}>Ok</div>}
            </div>
        );
    }
}
