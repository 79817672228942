import { Dispatch } from "redux";
import { requests } from "../helpers";
import { StoreState } from "../_types";
import { dynamicDesignDataActions } from "../actions/dynamicDesignData.actions";
import { DesignFramesAPIData } from "../_types/api";
import { catalogServices } from "./catalog.services";
import { store } from "../store";
import { isEmpty } from "lodash";

export const designFrameServices = {
    loadDesignFrames,
};

const DESIGN_FRAMES_URL = "/design_frames";

function loadDesignFrames()
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        return requests.get( DESIGN_FRAMES_URL ).then(
            ( data: DesignFramesAPIData ) =>
            {
                dispatch( dynamicDesignDataActions.designFramesLoadSuccess( data ) );
                if ( !isEmpty( data.design_frames ) )
                {
                    dispatch( catalogServices.reloadSelectedDesignControls( store.getState() ) );
                }
            },
            ( error ) =>
            {
                // TODO: do something when we can't load frame media?
            },
        );
    };
}
