import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../_types";
import { PostTab, PostTabDispatchProps, PostTabProps } from "../PostTab";
import { getMostRecentPosts } from "../../../ducks/post";
import { modalServices, postServices, userServices } from "../../../services";
import { getCurrentBusiness } from "../../../ducks/userBusiness";
import {
    getPresentAfterShareDialogStatus,
    hasLoadedAllRecentPosts,
    hasSeenRiplUserSurvey2023,
    isLoadMoreRecentPostsInProgress,
} from "../../../ducks/ui";
import { apptimizeVariables, eventTracker, RIPL_USER_SURVEY_2023_CODE } from "../../../helpers";
import { uiActions, userActions } from "../../../actions";
import { getCurrentUserId, hasOptedOutOfAlkaiCrossPromo, hasSeenAlkaiCrossPromoToday } from "../../../ducks/user";
import { store } from "../../../store";

const mapStateToProps = ( storeState: StoreState ): PostTabProps =>
{
    return {
        postData: getMostRecentPosts( storeState ),
        currentBusiness: getCurrentBusiness( storeState ),
        shouldShowRiplUserSurveyDialog: getPresentAfterShareDialogStatus( storeState )
                                        && apptimizeVariables.shouldShowRiplUserSurvey2023()
                                        && !hasSeenRiplUserSurvey2023( storeState ),
        shouldShowAlkaiCrossPromoDialog: getPresentAfterShareDialogStatus( storeState )
                                         && apptimizeVariables.shouldShowAlkaiCrossPromoDialog()
                                         && !hasOptedOutOfAlkaiCrossPromo( storeState )
                                         && !hasSeenAlkaiCrossPromoToday( storeState ),
        isLoadMoreRecentPostsInProgress: isLoadMoreRecentPostsInProgress( storeState ),
        hasLoadedAllRecentPosts: hasLoadedAllRecentPosts( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): PostTabDispatchProps =>
{
    return {
        loadData: async () =>
        {
            try
            {
                await dispatch( userServices.loadMe() );
                dispatch( postServices.loadMostRecentPostsWithLimit( 20 ) );
            }
            catch (error)
            {
                // Ignore errors here for now
            }
        },
        loadNextPage: async () =>
        {
            try
            {
                dispatch( postServices.loadNextMostRecentPostsWithLimit( 20 ) );
            }
            catch (error)
            {
                // Ignore errors here for now
            }
        },
        showRiplUserSurveyDialog: () =>
        {
            const state = store.getState();
            const userId = getCurrentUserId( state );
            dispatch( modalServices.openRiplSurveyDialog( userId ) );
            dispatch( uiActions.presentAfterShareDialogSet( false ) );
            dispatch( uiActions.hasSeenRiplUserSurvey2023Set( true ) );
            eventTracker.logSurveyShown( RIPL_USER_SURVEY_2023_CODE );
        },
        showAlkaiCrossPromoDialog: () =>
        {
            const state = store.getState();
            const userId = getCurrentUserId( state );

            const title = apptimizeVariables.getAlkaiCrossPromoDialogTitle();
            const body = apptimizeVariables.getAlkaiCrossPromoDialogBody();
            const imageUrl = apptimizeVariables.getAlkaiCrossPromoDialogImageUrl();
            const buttonText = apptimizeVariables.getAlkaiCrossPromoDialogButton();
            const cohort = apptimizeVariables.getAlkaiCrossPromoDialogCohort();
            const link = apptimizeVariables.getAlkaiCrossPromoDialogLink();

            const today = new Date( Date.now() ).toDateString();
            dispatch( modalServices.openAlkaiCrossPromoDialog( title, body, imageUrl, buttonText, cohort, link, userId ) );
            dispatch( uiActions.presentAfterShareDialogSet( false ) );
            dispatch( userActions.hasSeenAlkaiCrossPromoCount() );
            dispatch( userActions.alkaiCrossPromoLastSeenDate( today ) );
            eventTracker.logAlkaiCrossPromoDialogShown( cohort );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( PostTab );
