import { Dispatch } from "redux";
import { apptimizeWrapper, eventTracker, history, LOGIN_URL } from "../helpers";
import { StoreState } from "../_types";
import { appActions, persistor, store } from "../store";
import { uiActions } from "../actions";

export const logoutServices = {
    logout,
    logoutWithoutRedirect,
};

function logout( storedRedirectUrl?: string )
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        persistor.purge().then( () =>
        {
            dispatch( logoutWithoutRedirect() );

            if ( storedRedirectUrl )
            {
                store.dispatch( uiActions.setRedirectUrl( storedRedirectUrl ) );
            }
            history.push( LOGIN_URL );
        } );
    };
}

function logoutWithoutRedirect()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        apptimizeWrapper.takeOffline();
        eventTracker.clearApptimizeVariableCache();
        eventTracker.clearUserId();
        eventTracker.clearUserProperties();
        apptimizeWrapper.takeOnline();
        dispatch( appActions.reset() );
    };
}
