import { MusicPickerCustomItem } from "./MusicPickerCustomItem";
import { NO_AUDIO_DISPLAY_NAME } from "../../../_types/api";
import * as React from "react";
import * as NoAudioIconUnselected from "../../../assets/img/noAudioIconUnselected.svg";
import * as NoAudioIconSelected from "../../../assets/img/noAudioIconSelected.svg";

export interface NoAudioMusicItemProps
{
    isNoAudioSelected: boolean;
}

export interface NoAudioMusicItemDispatchProps
{
    onNoMusicSelected();
}

export function NoAudioMusicItem( props: NoAudioMusicItemProps & NoAudioMusicItemDispatchProps )
{
    {
        return <>
            <MusicPickerCustomItem
                text={NO_AUDIO_DISPLAY_NAME}
                selected={props.isNoAudioSelected}
                iconSrc={NoAudioIconSelected}
                iconSrcUnselected={NoAudioIconUnselected}
                onClick={props.onNoMusicSelected}/>
        </>;
    }
}
