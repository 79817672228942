import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../../_types";
import {
    EpidemicSoundUserCollectionsDialog,
    EpidemicSoundUserCollectionsDialogDispatchProps,
    EpidemicSoundUserCollectionsDialogProps,
} from "../EpidemicSoundUserCollectionsDialog";
import { getActiveCollectionTracks, getEpidemicSoundCollections } from "../../../../ducks/epidemicSoundCollections";
import { epidemicSoundCatalogServices } from "../../../../services/epidemicSoundCatalog.services";
import { isLoadingEpidemicSoundUserCollectionsInProgress } from "../../../../ducks/ui";
import { epidemicSoundCollectionActions } from "../../../../actions/epidemicSoundCollections.actions";
import withMusicPlayer from "../../../higherOrderComponents/WithMusicPlayer";
import { trackingActions } from "../../../../actions";
import { EPIDEMIC_SOUND_SOURCE_ES_CONNECT } from "../../../../helpers";

const mapStateToProps = ( storeState: StoreState ): EpidemicSoundUserCollectionsDialogProps =>
{
    return {
        epidemicCollections: getEpidemicSoundCollections( storeState ),
        isLoadingEpidemicSoundUserCollectionsInProgress: isLoadingEpidemicSoundUserCollectionsInProgress( storeState ),
        activeCollectionTracks: getActiveCollectionTracks( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): EpidemicSoundUserCollectionsDialogDispatchProps =>
{
    return {
        loadCollections()
        {
            dispatch( epidemicSoundCatalogServices.loadEpidemicUserCollections() );
        },
        clearActiveCollectionTracks()
        {
            dispatch( epidemicSoundCollectionActions.activeCollectionIdClear() );
            dispatch( epidemicSoundCollectionActions.activeCollectionTrackIdsClear() );
        },
        setEpidemicSoundSource: () =>
        {
            dispatch( trackingActions.epidemicSoundSourceSet( EPIDEMIC_SOUND_SOURCE_ES_CONNECT ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( withMusicPlayer( EpidemicSoundUserCollectionsDialog ) );
