import * as React from "react";
import * as PartyHat from "../assets/img/partyHat.png";

interface PartyHatDialogProps
{
    title: string;
    description: string;
}

export class PartyHatDialog extends React.PureComponent<PartyHatDialogProps>
{
    public render()
    {
        return (<div className="partyHatContent">
            <img className="partyHat" alt="welcome back party hat" src={PartyHat}/>
            <h2>{this.props.title}</h2>
            <h3>{this.props.description}</h3>
        </div>);
    }
}
