import * as React from "react";
import { FontData, StoreState, UserBusiness } from "../_types";
import { filter, trim } from "lodash";
import { CHOOSE_A_FONT_TITLE, eventTracker, LightboxDialogIdentifierForKey, stringUtils } from "./";
import {
    Brand,
    BRAND_SLIDE_FONT_ONE_CSS_URL_FIELD,
    BRAND_SLIDE_FONT_ONE_FIELD,
    BRAND_SLIDE_FONT_ONE_URL_FIELD,
    BRAND_SLIDE_FONT_TWO_CSS_URL_FIELD,
    BRAND_SLIDE_FONT_TWO_FIELD,
    BRAND_SLIDE_FONT_TWO_URL_FIELD,
    BUSINESS_PRIMARY_FONT_TYPE,
    BUSINESS_SECONDARY_FONT_TYPE,
    BusinessMediaType,
    FontCssUrlFieldType,
    FontFieldType,
    FontUrlFieldType,
    MIX_MODEL_BRAND_SLIDE_FONT_1,
    MIX_MODEL_BRAND_SLIDE_FONT_2,
    MIX_MODEL_BRAND_SLIDE_FONT_CSS_URL_1,
    MIX_MODEL_BRAND_SLIDE_FONT_CSS_URL_2,
    PRIMARY,
    SECONDARY,
} from "../_types/api";
import { Font, parse } from "opentype.js";
import { Dispatch } from "redux";
import { modalServices, userBusinessServices } from "../services";
import MoreFontsDialogContainer, {
    MORE_FONTS_DIALOG_CLASS_NAME,
    MORE_FONTS_DIALOG_FOOTER_CLASS_NAME,
} from "../components/editorControls/containers/MoreFontsDialog.container";
import { getEditedBusinessInfo, getFontAutocompleteData } from "../ducks/ui";
import { store } from "../store";
import { mixModelActions, uiActions } from "../actions";
import Button from "react-md/lib/Buttons/Button";
import FontFileUploadButtonContainer from "../components/containers/FontFileUploadButton.container";
import { getCurrentBusiness } from "../ducks/userBusiness";

export const getFontDisplayName = ( font: FontData ): string => font && font.displayName;
export const getDesignFontName = ( font: FontData ): string => font && font.designFontName;
export const getFontCssUrl = ( font: FontData ): string => font && font.cssUrl;
export const isUserUploadedFont = ( font: FontData ): boolean => font && !!font.cssUrl;

export function getFontFieldForBrand( brand: Brand ): FontFieldType
{
    if ( brand )
    {
        return brand + "_font" as FontFieldType;
    }
}

export function getFontUrlFieldForBrand( brand: Brand ): FontUrlFieldType
{
    if ( brand )
    {
        return brand + "_font_url" as FontUrlFieldType;
    }
}

export function getFontCssUrlFieldForBrand( brand: Brand ): FontCssUrlFieldType
{
    if ( brand )
    {
        return brand + "_font_css_url" as FontCssUrlFieldType;
    }
}

export function getMediaTypeForBrand( brand: Brand ): BusinessMediaType
{
    if ( brand )
    {
        if ( brand === PRIMARY )
        {
            return BUSINESS_PRIMARY_FONT_TYPE;
        }
        else if ( brand === SECONDARY )
        {
            return BUSINESS_SECONDARY_FONT_TYPE;
        }
    }
}

function getBrandSlideFontFieldMappedToBrand( brand: Brand ): FontFieldType
{
    if ( brand === PRIMARY )
    {
        return BRAND_SLIDE_FONT_ONE_FIELD;
    }
    else if ( brand === SECONDARY )
    {
        return BRAND_SLIDE_FONT_TWO_FIELD;
    }
}

function getBrandSlideFontUrlFieldMappedToBrand( brand: Brand ): FontUrlFieldType
{
    if ( brand === PRIMARY )
    {
        return BRAND_SLIDE_FONT_ONE_URL_FIELD;
    }
    else if ( brand === SECONDARY )
    {
        return BRAND_SLIDE_FONT_TWO_URL_FIELD;
    }
}

function getBrandSlideFontCssUrlFieldMappedToBrand( brand: Brand ): FontCssUrlFieldType
{
    if ( brand === PRIMARY )
    {
        return BRAND_SLIDE_FONT_ONE_CSS_URL_FIELD;
    }
    else if ( brand === SECONDARY )
    {
        return BRAND_SLIDE_FONT_TWO_CSS_URL_FIELD;
    }
}

function getMixModelBrandSlideFontFieldMappedToBusinessBrandSlideFont( brandSlideFontField: FontFieldType ): string
{
    if ( brandSlideFontField === BRAND_SLIDE_FONT_ONE_FIELD )
    {
        return MIX_MODEL_BRAND_SLIDE_FONT_1;
    }
    else if ( brandSlideFontField === BRAND_SLIDE_FONT_TWO_FIELD )
    {
        return MIX_MODEL_BRAND_SLIDE_FONT_2;
    }
}

function getMixModelBrandSlideFontCssUrlFieldMappedToBusinessBrandSlideFont( brandSlideFontField: FontFieldType ): string
{
    if ( brandSlideFontField === BRAND_SLIDE_FONT_ONE_FIELD )
    {
        return MIX_MODEL_BRAND_SLIDE_FONT_CSS_URL_1;
    }
    else if ( brandSlideFontField === BRAND_SLIDE_FONT_TWO_FIELD )
    {
        return MIX_MODEL_BRAND_SLIDE_FONT_CSS_URL_2;
    }
}

export function hasBrandFont( business: Partial<UserBusiness>, brand: Brand )
{
    if ( !business || !brand )
    {
        return false;
    }

    const brandFontUrl = getBusinessBrandFontUrl( business, brand );
    if ( brandFontUrl )
    {
        return true;
    }

    const brandFontName = getBusinessBrandFontName( business, brand );
    return !!brandFontName;
}

export function generateFontDataForBrandFont( business: Partial<UserBusiness>, brand: Brand ): FontData
{
    if ( business && brand )
    {
        const fontUrl = getBusinessBrandFontUrl( business, brand );
        const brandFontCssUrl = getBusinessBrandFontCssUrl( business, brand );
        const fontName = determineBrandFontName( brandFontCssUrl, business, brand );
        return generateFontDataForFontName( fontName, brandFontCssUrl, brand, fontUrl );
    }
}

export function generateFontDataForBrandSlideFont( business: Partial<UserBusiness>, brandSlideFontField: string,
                                                   brandSlideFontCssUrlField: string ): FontData
{
    if ( business )
    {
        const fontCssUrl = trim( business && business[brandSlideFontCssUrlField] ) || undefined;
        let fontName;
        if ( fontCssUrl )
        {
            fontName = stringUtils.getBaseName( fontCssUrl );
        }
        else
        {
            fontName = trim( business && business[brandSlideFontField] ) || undefined;
        }

        return generateFontDataForFontName( fontName, fontCssUrl );
    }
}

export function determineBrandFontName( brandFontCssUrl: string, business: Partial<UserBusiness>, brand: Brand ): string
{
    if ( brandFontCssUrl )
    {
        return stringUtils.getBaseName( brandFontCssUrl );
    }
    else
    {
        return getBusinessBrandFontName( business, brand );
    }
}

export function getBusinessBrandFontName( business: Partial<UserBusiness>, brand: Brand ): string
{
    const brandFontField = getFontFieldForBrand( brand );
    return trim( business && business[brandFontField] ) || undefined;
}

export function getBusinessBrandFontUrl( business: Partial<UserBusiness>, brand: Brand )
{
    const brandFontUrlField = getFontUrlFieldForBrand( brand );
    return trim( business && business[brandFontUrlField] ) || undefined;
}

export function getBusinessBrandFontCssUrl( business: Partial<UserBusiness>, brand: Brand )
{
    const brandFontCssUrlField = getFontCssUrlFieldForBrand( brand );
    return trim( business && business[brandFontCssUrlField] ) || undefined;
}

export function generateFontDataForBrandFonts( business: Partial<UserBusiness> ): FontData[]
{
    if ( !business )
    {
        return [];
    }

    const primaryFont = generateFontDataForBrandFont( business, PRIMARY );
    const secondaryFont = generateFontDataForBrandFont( business, SECONDARY );
    return filter( [primaryFont, secondaryFont] );
}

export function generateFontDataForFontNameOrCssUrl( fontName, fontCssUrl ): FontData
{
    if ( fontCssUrl || fontName )
    {
        if ( !fontName )
        {
            fontName = stringUtils.getBaseName( fontCssUrl );
        }
        return generateFontDataForFontName( fontName, fontCssUrl );
    }
}

export function generateFontDataForFontName( fontName: string, cssUrl?: string, brand?: Brand, url?: string ): FontData
{
    if ( fontName )
    {
        const displayName = stringUtils.replaceHyphensAndUnderscoresWithSpaces( fontName );
        // Existing custom fonts may have names with spaces, so make sure to remove them.
        const designFontName = stringUtils.replaceSpacesWithHyphens( fontName );
        return {
            displayName,
            designFontName,
            cssUrl,
            url,
            type: brand,
        };
    }
}

export async function readFontFromFileBuffer( file: File )
{
    const fontFileBuffer = await readFileBuffer( file );
    return extractOpenTypeFontFromBuffer( fontFileBuffer );
}

export function readFileBuffer( file: File )
{
    return new Promise( ( resolve, reject ) =>
    {
        const reader = new FileReader();
        reader.onload = () =>
        {
            const result = reader.result;
            resolve( result );
        };
        reader.onerror = () =>
        {
            reject( "error reading font" );
        };
        reader.onabort = () =>
        {
            reject( "error reading font" );
        };
        reader.readAsArrayBuffer( file );
    } );
}

export function extractOpenTypeFontFromBuffer( buffer: any ): Font
{
    return parse( buffer );
}

const applySelectedFont = async ( dispatch: Dispatch<StoreState>, brand: Brand, controlId: string ) =>
{
    const state = store.getState();
    const selectedFont = getFontAutocompleteData( state ).font;

    if ( selectedFont )
    {
        const brandFontField = getFontFieldForBrand( brand );
        const brandFontUrlField = getFontUrlFieldForBrand( brand );
        const brandFontCssUrlField = getFontCssUrlFieldForBrand( brand );

        const brandSlideFontField = getBrandSlideFontFieldMappedToBrand( brand );
        const brandSlideFontUrlField = getBrandSlideFontUrlFieldMappedToBrand( brand );
        const brandSlideFontCssUrlField = getBrandSlideFontCssUrlFieldMappedToBrand( brand );
        const currentBusiness = getCurrentBusiness( state );

        const newFontName = selectedFont.designFontName;
        const newFontUrl = selectedFont.url || null;
        const newFontCssUrl = selectedFont.cssUrl || null;

        const hasExistingBrandSlideFontSet = !!currentBusiness[brandSlideFontField];

        const brandSlideFontName = hasExistingBrandSlideFontSet ? currentBusiness[brandSlideFontField] : newFontName;
        const brandSlideFontUrl = hasExistingBrandSlideFontSet ? currentBusiness[brandSlideFontUrlField] : newFontUrl;
        const brandSlideFontCssUrl = hasExistingBrandSlideFontSet ? currentBusiness[brandSlideFontCssUrlField] : newFontCssUrl;

        const brandSlideFont = {
            [brandSlideFontField]: brandSlideFontName,
            [brandSlideFontUrlField]: brandSlideFontUrl,
            [brandSlideFontCssUrlField]: brandSlideFontCssUrl,
        };

        const newFontDataForBusinessUpdate = {
            [brandFontField]: newFontName,
            [brandFontUrlField]: newFontUrl,
            [brandFontCssUrlField]: newFontCssUrl,
            ...brandSlideFont,
        };
        await dispatch( userBusinessServices.update( newFontDataForBusinessUpdate ) );

        const mixModelBrandSlideFontField = getMixModelBrandSlideFontFieldMappedToBusinessBrandSlideFont( brandSlideFontField );
        const mixModelBrandSlideFontCssUrlField = getMixModelBrandSlideFontCssUrlFieldMappedToBusinessBrandSlideFont( brandSlideFontField );

        dispatch( mixModelActions.brandSlideDataUpdated(
            { endCardData: { [mixModelBrandSlideFontField]: brandSlideFontName, [mixModelBrandSlideFontCssUrlField]: brandSlideFontCssUrl } } ) );

        if ( controlId )
        {
            dispatch( mixModelActions.controlDataUpdated( { [controlId]: selectedFont } ) );
        }
        eventTracker.logFontSelectedFromControl( selectedFont );
        modalServices.closeTopLightBox( dispatch, state );
        dispatch( uiActions.updateFontFontAutocomplete( null ) );
    }
};

export function openBrandFontPicker( dispatch: Dispatch<StoreState>, brand: Brand, controlId: string )
{
    const fontField = getFontFieldForBrand( brand );
    const fontUrlField = getFontUrlFieldForBrand( brand );
    const fontCssUrlField = getFontCssUrlFieldForBrand( brand );
    const mediaType = getMediaTypeForBrand( brand );

    dispatch( modalServices.openLightbox( {
        identifierForKey: LightboxDialogIdentifierForKey.CHOOSE_CUSTOM_FONT_AND_SET_BUSINESS_FONT,
        title: CHOOSE_A_FONT_TITLE,
        showCancelX: true,
        hideCancel: true,
        hideConfirm: true,
        className: MORE_FONTS_DIALOG_CLASS_NAME,
        content: MoreFontsDialogContainer,
        width: 500,
        footerClassName: MORE_FONTS_DIALOG_FOOTER_CLASS_NAME,
        onCancel: () =>
        {
            dispatch( uiActions.updateFontFontAutocomplete( null ) );
        },
        customActions: [<FontFileUploadButtonContainer
                            fontField={fontField}
                            fontUrlField={fontUrlField}
                            fontCssUrlField={fontCssUrlField}
                            mediaType={mediaType}
                            label="Upload your own font"
                            icon={null}
                            additionalClassNames="dialogAlternate"
                            onUpdatedFont={() =>
                            {
                                const state = store.getState();
                                const editedBusiness = getEditedBusinessInfo( state );
                                const fontData = generateFontDataForBrandFont( editedBusiness, brand );
                                dispatch( uiActions.clearBusinessInfo() );
                                dispatch( uiActions.updateFontFontAutocomplete( fontData ) );
                            }}/>,
                        <Button flat
                                primary
                                swapTheming
                                className="dialogConfirm"
                                onClick={( event: React.MouseEvent<HTMLElement> ) =>
                                {
                                    applySelectedFont( dispatch, brand, controlId );
                                }}>Apply selected font</Button>],
    } ) );
}
