import * as React from "react";
import * as RiplLogo from "../assets/img/ripl_logo_white.png";
import TiersExplanationOfBenefits from "./containers/TiersExplanationOfBenefits.container";
import { selectTierForGradientSidebarDialog, SubscriptionFlowSource } from "../helpers";
import { DialogControlProps } from "../_types";

export interface SelectPriceTiersDialogProps
{
    source: SubscriptionFlowSource;
}

export interface SelectPriceTiersDialogDispatchProps extends DialogControlProps
{
    handleCancel(): void;
}

export class SelectPriceTiersDialog extends React.PureComponent<SelectPriceTiersDialogProps & SelectPriceTiersDialogDispatchProps>
{
    public render()
    {
        return (
            <div className="selectPriceTiersDialog">
                <div className="leftPanel">
                    <div className="callToActionBackground">
                        <img src={RiplLogo} alt="Ripl logo"/>
                    </div>
                </div>
                {this.renderContent()}
            </div>
        );
    }

    private handleSkipForNow = () =>
    {
        this.props.handleCancel();
    }

    private renderContent()
    {
        return <div className="selectPriceTiersContent">
            <div className="selectPriceTierSkipButton" onClick={this.handleSkipForNow}>Maybe later</div>
            <div className="selectPriceTiersDialogHeader">
                <h1 className="text bold xlarge">Try a week of Ripl on us!</h1>
                <div className="text detail">Choose a plan</div>
            </div>
            <TiersExplanationOfBenefits selectTier={selectTierForGradientSidebarDialog( this.props.source, this.props.closeDialog )}/>
        </div>;
    }
}
