import classNames from "classnames";
import * as React from "react";
import * as RiplCheckmark from "../assets/img/checkmark.png";

export interface PullDownMenuButtonItemProps
{
    icon?: string;
    title: string;
    label?: string | JSX.Element;
    keyName?: string | number;
    selected?: boolean;
    onClick( key: string | number ): any;
}

export interface PullDownMenuButtonItemDispatchProps
{
    hidePullDownMenu(): void;
}

export class PullDownMenuButtonItem extends React.PureComponent<PullDownMenuButtonItemProps & PullDownMenuButtonItemDispatchProps>
{
    public render()
    {
        return (
            <li className={classNames( "pullDownMenuItem", { pullDownSelected: this.props.selected } )}
                key={this.props.keyName}
                onClick={this.handlePullDownMenuItemClick}>
                <span className="pullDownIcon">{this.props.icon && <img alt={`${this.props.title} icon`} src={this.props.icon}/>}</span>
                <span className="pullDownTitle">{this.props.title}</span>
                {this.props.label &&
                 <span className="pullDownLabel">{this.props.label}</span>
                }
                {!this.props.label && (
                    <span className="pullDownLabel pullDownCheckmark">{this.props.selected && <img alt="checkmark" src={RiplCheckmark}/>}</span>
                )}
            </li>
        );
    }

    private handlePullDownMenuItemClick = () =>
    {
        this.props.hidePullDownMenu();
        this.props.onClick( this.props.keyName );
    }
}
