import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../../_types";
import { EpidemicSoundESConnectLink, EpidemicSoundESConnectLinkDispatchProps } from "../EpidemicSoundESConnectLink";
import { eventTracker, oauth, OAUTH_ACCOUNT_CONNECTED_SOURCE_MUSIC_PICKER } from "../../../../helpers";
import { EPIDEMIC_SOUND_ACCOUNT_TYPE } from "../../../../_types/api";
import { hasValidConnectionToEpidemicSound } from "../../../../ducks/user";
import { store } from "../../../../store";
import { modalServices } from "../../../../services";

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): EpidemicSoundESConnectLinkDispatchProps =>
{
    return {
        onConnectClicked()
        {
            const state = store.getState();

            eventTracker.logGetMoreMusicClicked( state );
            if ( hasValidConnectionToEpidemicSound( state ) )
            {
                dispatch( modalServices.openEpidemicUserCollectionsDialog() );
            }
            else
            {
                oauth.connectViaWindow( EPIDEMIC_SOUND_ACCOUNT_TYPE, OAUTH_ACCOUNT_CONNECTED_SOURCE_MUSIC_PICKER );
            }
        },
    };
};

export default connect(
    () => ({}),
    mapDispatchToProps,
)( EpidemicSoundESConnectLink );
