import { connect } from "react-redux";
import { Dispatch } from "redux";
import { store } from "../../store";
import { FacebookPickerDialog, FacebookPickerDialogDispatchProps, FacebookPickerDialogProps } from "../FacebookPickerDialog";
import { uiActions } from "../../actions";
import { eventTracker, LightboxDialogIdentifierForKey, SocialAccountConnectedSource } from "../../helpers";
import { getCurrentBusinessId } from "../../ducks/userBusiness";
import { instagramBusinessServices, modalServices, userServices } from "../../services";
import { getCurrentUserId, getSocialNetworkAccountForId } from "../../ducks/user";
import { difference, filter, includes, map } from "lodash";
import { FACEBOOK_INSTAGRAM_ACCOUNT_TYPE } from "../../_types/api";
import { FacebookPickerDialogData, FacebookSocialNetworkAccountType, SocialNetworkAccount, StoreState } from "../../_types";

interface FacebookPickerDialogContainerProps
{
    onComplete?: ( pickedItems: SocialNetworkAccount[] ) => Promise<any>;
}

const mapStateToProps = ( storeState: StoreState, ownProps: FacebookPickerDialogContainerProps ): FacebookPickerDialogProps =>
{
    return {
        ...ownProps,
        facebookPickerDialogData: storeState.ui.facebookPickerDialogData,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): FacebookPickerDialogDispatchProps =>
{
    function enableAndCompleteForPickedItems( userId: number, businessId: number, dialogData: FacebookPickerDialogData,
                                              pickedIds: number[], onCompleteCallback: ( pickedItems: SocialNetworkAccount[] ) => void )
    {
        const trackingSource: SocialAccountConnectedSource = dialogData.trackingSource as SocialAccountConnectedSource;
        const accountType: FacebookSocialNetworkAccountType = dialogData.accountType as FacebookSocialNetworkAccountType;

        dispatch( userServices.enableSocialNetworkAccounts( userId, pickedIds, businessId ) );
        eventTracker.logSocialAccountConnected( accountType, trackingSource );

        if ( onCompleteCallback )
        {
            const pickedItems = filter( dialogData.allItems, ( item ) => includes( pickedIds, item.id ) );

            onCompleteCallback( pickedItems );
        }
    }

    return {
        closeModal: () =>
        {
            dispatch( uiActions.facebookPickerModalClose() );
        },
        onItemsPicked: async ( dialogData: FacebookPickerDialogData,
                               pickedIds: number[],
                               onCompleteCallback?: ( pickedItems: SocialNetworkAccount[] ) => void ) =>
        {
            if ( pickedIds.length > 0 )
            {
                const state = store.getState();
                const userId = getCurrentUserId( state );
                const businessId = getCurrentBusinessId( state );
                const pickedSocialNetworkAccounts = map( pickedIds,
                    ( socialNetworkAccountId ) => getSocialNetworkAccountForId( state, socialNetworkAccountId ) );
                const picksThatHaveAnotherBusiness: SocialNetworkAccount[] = filter( pickedSocialNetworkAccounts,
                    ( pickedSocialNetworkAccount: SocialNetworkAccount ) =>
                    {
                        const businessIdForAccount = pickedSocialNetworkAccount.user_business_id;
                        return businessIdForAccount && businessIdForAccount !== businessId;
                    } );

                const accountNamesThatHaveAnotherBusiness = map( picksThatHaveAnotherBusiness,
                    ( socialNetworkAccount: SocialNetworkAccount ) => socialNetworkAccount.name ).join( ", " );

                if ( accountNamesThatHaveAnotherBusiness.length !== 0 )
                {
                    await dispatch( modalServices.openLightbox( {
                        identifierForKey: LightboxDialogIdentifierForKey.CONFIRM_MOVE_FACEBOOK_SOCIAL_NETWORK_OBJECT_TO_CURRENT_BUSINESS,
                        content: `You've already connected ${accountNamesThatHaveAnotherBusiness} to another business. ` +
                                 `Would you like to move ${accountNamesThatHaveAnotherBusiness} to your current business?`,
                        cancelLabel: "No",
                        width: 500,
                        onSuccess: () =>
                        {
                            enableAndCompleteForPickedItems( userId, businessId, dialogData, pickedIds, onCompleteCallback );
                        },
                        onCancel: () =>
                        {
                            const pickIdsThatHaveAnotherBusiness = map( picksThatHaveAnotherBusiness,
                                ( pick: SocialNetworkAccount ) => pick.id );
                            const unassignedPickIds = difference( pickedIds, pickIdsThatHaveAnotherBusiness );

                            enableAndCompleteForPickedItems( userId, businessId, dialogData, unassignedPickIds, onCompleteCallback );
                        },
                    } ) );
                }
                else
                {
                    enableAndCompleteForPickedItems( userId, businessId, dialogData, pickedIds, onCompleteCallback );
                }
            }
            dispatch( uiActions.facebookPickerModalClose() );
        },
        onItemPicked: ( item: SocialNetworkAccount ) =>
        {
            if ( item.needsMorePermissions )
            {
                const dialogData = { facebookGroupAccount: item };
                dispatch( uiActions.facebookGroupAddRiplModalOpen( dialogData ) );
            }
        },
        onItemNotFound: async ( dialogData: FacebookPickerDialogData ) =>
        {
            if ( dialogData.accountType === FACEBOOK_INSTAGRAM_ACCOUNT_TYPE )
            {
                dispatch( instagramBusinessServices.displayInstagramDontSeeBusinessDialog() );
            }
            dispatch( uiActions.facebookPickerModalClose() );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( FacebookPickerDialog );
