import { connect } from "react-redux";
import { Dispatch } from "redux";
import { store } from "../../store";
import { getCurrentBusiness, getCurrentBusinessId } from "../../ducks/userBusiness";
import { EngagementGraph, EngagementGraphDispatchProps, EngagementGraphProps } from "../EngagementGraph";
import { getWeeklyEngagements } from "../../ducks/engagements";
import { userServices } from "../../services";
import { getCurrentUserId } from "../../ducks/user";
import { StoreState } from "../../_types";

const mapStateToProps = ( storeState: StoreState ): EngagementGraphProps =>
{
    return {
        weeklyEngagements: getWeeklyEngagements( storeState ),
        currentBusiness: getCurrentBusiness( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): EngagementGraphDispatchProps =>
{
    return {
        loadData: async () =>
        {
            const state = store.getState();
            await dispatch( userServices.loadWeeklyEngagements( getCurrentUserId( state ), getCurrentBusinessId( state ) ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( EngagementGraph );
