import * as React from "react";
import { MouseEvent } from "react";
import { FontData } from "../../_types";
import ListItemControl from "react-md/lib/Lists/ListItemControl";
import { getDesignFontName, isUserUploadedFont } from "../../helpers";
import classNames from "classnames";
import { FontPreview } from "./FontPreview";

interface FontPickerItemProps
{
    font: FontData;
    currentFont: FontData;
    updateFontSelection: ( font: FontData ) => void;
}

export class FontPickerItem extends React.PureComponent<FontPickerItemProps>
{
    public render()
    {
        const { displayName, designFontName, type } = this.props.font;
        const currentDesignFontName = getDesignFontName( this.props.currentFont );
        const isUserUploaded = isUserUploadedFont( this.props.font );
        return (
            <ListItemControl
                className={classNames( "item", "font", { selected: (currentDesignFontName === designFontName) } )}
                primaryText={displayName}
                onClick={this.handleFontSelection}
                primaryAction={(
                    <div>
                        <div className={classNames( { brandFontSection: !!type } )}>
                            <FontPreview font={this.props.font}
                                         displayName={displayName}
                                         designFontName={designFontName}
                                         isCustomFont={isUserUploaded}/>
                        </div>
                    </div>
                )
                }
            />
        );
    }

    private handleFontSelection = ( event: MouseEvent<HTMLElement> ) =>
    {
        this.props.updateFontSelection( this.props.font );
    }
}
