import * as React from "react";
import { convertToUserCurrency } from "../ducks/pricing";
import { dollarsFormattedForCurrency } from "../helpers";
import classNames from "classnames";

export interface SavingsInDollarsBadgeFromPlan
{
    annualPlanSavings?: number;
    currencyCode: CurrencyCode;
    currentExchangeRate: number;
    shouldShowExchangeRates: boolean;
    shouldRenderForWideFormat?: boolean;
    shouldAlignToRight?: boolean;
}

export function SavingsInDollarsBadgeFromPlan( props: SavingsInDollarsBadgeFromPlan )
{
    const amountInDollars = props.annualPlanSavings;
    if ( !amountInDollars )
    {
        return null;
    }

    const savingsFormatted = convertToUserCurrency(
        props.currencyCode,
        props.currentExchangeRate,
        props.shouldShowExchangeRates,
        amountInDollars,
        dollarsFormattedForCurrency,
    );

    const configurableClassNamesToRender = {
        renderForWideFormat: props.shouldRenderForWideFormat,
        shouldAlignToRight: props.shouldAlignToRight,
    };

    return (
        <div className={classNames( "savingsBadgeInDollarsWrapper", configurableClassNamesToRender )}>
            <div className={classNames( "savingsBadgeInDollars", configurableClassNamesToRender )}>
                {savingsFormatted} savings!
            </div>
        </div>
    );
}
