import * as React from "react";
import * as CheckMarkSelected from "../assets/img/checkmark_selected.svg";
import * as CheckMarkNotSelected from "../assets/img/checkmark_not_selected.svg";
import * as RadioSelected from "../assets/img/radio_selected.svg";
import * as RadioNotSelected from "../assets/img/radio_not_selected.svg";

export const SelectedCheckboxButtonIcon: React.FC = () =>
{
    return <img className="checkboxIcon" alt="Checkmark Selected" src={CheckMarkSelected}/>;
};

export const UnselectedCheckboxButtonIcon: React.FC = () =>
{
    return <img className="checkboxIcon" alt="Checkmark Not Selected" src={CheckMarkNotSelected}/>;
};

export const UnselectedRadioButtonIcon: React.FC = () =>
{
    return <img className="checkboxIcon" alt="Radio Not Selected" src={RadioNotSelected}/>;
};

export const SelectedRadioButtonIcon: React.FC = () =>
{
    return <img className="checkboxIcon" alt="Radio Selected" src={RadioSelected}/>;
};
