import {
    getFilteredMediaList,
    getNumMedia,
    getSelectedDesign,
    getVideoCountFromMediaList,
    getVideoTrimmedDurationInMS,
    getVideoTrimmerVideoDuration,
    hasVideoInMediaList,
    ListIterator,
} from "../ducks/mixModel";
import { forEach, includes } from "lodash";
import {
    CANNOT_MULTI_SELECT_VIDEOS_ERROR_MESSAGE,
    ERROR_TITLE_OOPS,
    ERROR_TITLE_SORRY,
    LIMIT_TO_MAX_VIDEO_SELECTED_ERROR_MESSAGE,
    MEDIA_LIMIT,
    MP4,
    PRO_VIDEO_MAX_DURATION_SECONDS,
    stringUtils,
    urlUtils,
} from "./";
import { Dispatch } from "redux";
import { StoreState } from "../_types";
import { store } from "../store";
import { modalServices } from "../services";
import { getActiveDesignMediaObject } from "../ducks/ui";
import { PostInputMediaFile } from "../_types/api";

export const DEFAULT_PHOTO_DISPLAY_DURATION_SECONDS = 4;

export function getAvailableTimeExcludingMediaInSeconds( state, mediaOriginalFilename: string, mediaFilter?: ListIterator<PostInputMediaFile> )
{
    const mediaList = getFilteredMediaList( state, mediaFilter );
    let usedDuration = 0;
    forEach( mediaList, ( media ) =>
    {
        if ( stringUtils.getFileExt( media.url ) === MP4 )
        {
            const mediaId = urlUtils.getFilenameFromUrlString( media.url );
            usedDuration += getVideoTrimmedDurationInMS( state, mediaId ) / 1000;
        }
        else
        {
            usedDuration += DEFAULT_PHOTO_DISPLAY_DURATION_SECONDS;
        }
    } );
    return PRO_VIDEO_MAX_DURATION_SECONDS - usedDuration;
}

export function shouldShowMixedMediaMessage( state, videoFileName: string, mediaFilter?: ListIterator<PostInputMediaFile> )
{
    const selectedVideoDurationInSeconds = getVideoTrimmerVideoDuration( state );
    const availableTime = getAvailableTimeExcludingMediaInSeconds( state, videoFileName, mediaFilter );
    return availableTime < Math.min( PRO_VIDEO_MAX_DURATION_SECONDS, selectedVideoDurationInSeconds );
}

export function pickingVideoIsAllowed( designSupportsVideo: boolean, hasReachedMaxVideoCountSupportedInMediaList: boolean )
{
    return designSupportsVideo && !hasReachedMaxVideoCountSupportedInMediaList;
}

function getActiveDesignMediaObjectFilename( state: StoreState )
{
    const activeDesignMediaObject = getActiveDesignMediaObject( state );
    return activeDesignMediaObject ? activeDesignMediaObject.id : null;
}

export function getActiveDesignMediaObjectExclusionFilter( state: StoreState )
{
    const activeDesignMediaObjectFilename = getActiveDesignMediaObjectFilename( state );

    return ( media: PostInputMediaFile ) =>
    {
        return !includes( media.url, activeDesignMediaObjectFilename );
    };
}

export function getNumberOfInputMediaWithoutActiveMediaObject( state: StoreState )
{
    const excludeActiveDesignMediaObjectFilter = getActiveDesignMediaObjectExclusionFilter( state );
    return getNumMedia( state, excludeActiveDesignMediaObjectFilter );
}

export function guardForMinMaxMediaRules( dispatch: Dispatch<StoreState>, numberOfMediaBeingAdded: number, numberOfVideosBeingAdded: number = 0 )
{
    const state = store.getState();
    const errorForRules = evaluateForMinMaxMediaRules( state, numberOfMediaBeingAdded, numberOfVideosBeingAdded );
    if ( errorForRules )
    {
        dispatch( modalServices.openErrorDialog( errorForRules.title, errorForRules.message ) );
        return false;
    }

    return true;
}

export function evaluateForMinMaxMediaRules( state: StoreState, numberOfMediaBeingAdded: number, numberOfVideosBeingAdded: number = 0 )
{
    const currentDesign = getSelectedDesign( state );
    const excludeActiveDesignMediaObjectFilter = getActiveDesignMediaObjectExclusionFilter( state );
    const videoFilesCountInMixModel = getVideoCountFromMediaList( state, excludeActiveDesignMediaObjectFilter );
    const totalMediaAfterAdd = getNumberOfInputMediaWithoutActiveMediaObject( state ) + numberOfMediaBeingAdded;
    const availableTime = getAvailableTimeExcludingMediaInSeconds( state, null, excludeActiveDesignMediaObjectFilter );
    const maxVideoCountSupported = currentDesign ? currentDesign.max_video_count_supported : 1;

    if ( numberOfMediaBeingAdded < 1 )
    {
        return { title: ERROR_TITLE_OOPS, message: "No file added. Please select and re-upload." };
    }

    if ( numberOfVideosBeingAdded > 1 )
    {
        return { title: ERROR_TITLE_SORRY, message: CANNOT_MULTI_SELECT_VIDEOS_ERROR_MESSAGE };
    }

    if ( (numberOfVideosBeingAdded + videoFilesCountInMixModel) > maxVideoCountSupported )
    {
        return { title: ERROR_TITLE_SORRY, message: LIMIT_TO_MAX_VIDEO_SELECTED_ERROR_MESSAGE };
    }

    if ( totalMediaAfterAdd > MEDIA_LIMIT.PRO )
    {
        return { title: ERROR_TITLE_SORRY, message: `You can only include up to ${MEDIA_LIMIT.PRO} media items in a template.` };
    }

    if ( hasVideoInMediaList( state, excludeActiveDesignMediaObjectFilter ) && availableTime < DEFAULT_PHOTO_DISPLAY_DURATION_SECONDS )
    {
        return {
            title: ERROR_TITLE_SORRY,
            message: "This item will not fit within the maximum time of a Ripl post. Please change your selection to add more items.",
        };
    }
    return null;
}
