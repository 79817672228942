import * as React from "react";
import { compact, map, uniqBy } from "lodash";
import BackgroundMediaGridItemContainer from "./containers/BackgroundMediaGridItem.container";
import { BackgroundMediaAPIItem } from "../_types/api";

export interface BackgroundMediaGridProps
{
    initialBackgroundMediaAPIItem: BackgroundMediaAPIItem;
    backgroundMediaOptions: BackgroundMediaAPIItem[];
}

export class BackgroundMediaGrid extends React.PureComponent<BackgroundMediaGridProps>
{
    public render()
    {
        const combinedBackgroundMedia = [this.props.initialBackgroundMediaAPIItem, ...this.props.backgroundMediaOptions];
        const combinedBackgroundMediaCleaned = compact( combinedBackgroundMedia );
        const combinedBackgroundItems = uniqBy( combinedBackgroundMediaCleaned, "id" );

        return (<div className="backgroundMediaGrid">
            <BackgroundMediaGridItemContainer key="None"/>
            {
                map( combinedBackgroundItems,
                    ( backgroundMediaOption ) => <BackgroundMediaGridItemContainer key={backgroundMediaOption.id}
                                                                                   backgroundMediaOption={backgroundMediaOption}/> )
            }
        </div>);
    }
}
