import { connect } from "react-redux";
import { StoreState } from "../../../_types";
import { getCurrentBusiness } from "../../../ducks/userBusiness";
import { NoPostsPanel, NoPostsPanelProps } from "../NoPostsPanel";
import * as React from "react";

function getNoBusinessElements()
{
    return (<span>Add a new brand to start personalizing<br/>your posts with a logo, colors, and fonts.</span>);
}

const mapStateToProps = ( storeState: StoreState ): NoPostsPanelProps =>
{
    return {
        noBusinessElements: getNoBusinessElements(),
        currentBusiness: getCurrentBusiness( storeState ),
    };
};

export default connect(
    mapStateToProps,
)( NoPostsPanel );
