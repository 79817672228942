import * as React from "react";
import { URLS } from "../helpers";
import { SocialNetworkAccountType } from "../_types";
import { LINKEDIN_ACCOUNT_TYPE, TWITTER_ACCOUNT_TYPE, YOUTUBE_ACCOUNT_TYPE } from "../_types/api";

export interface RefreshWrongAccountErrorDialogContentProps
{
    accountType?: SocialNetworkAccountType;
}

export class RefreshWrongAccountAccountErrorDialogContent extends React.PureComponent<RefreshWrongAccountErrorDialogContentProps>
{
    public render()
    {
        return (
            <div>
                {`Wrong ${this.props.accountType} account detected.`}

                <div className="contactSupport">
                    {`You may have refreshed the connection for a different ${this.props.accountType} account than the one intended. `}
                    {this.getLogoutText()}
                </div>

                <div className="contactSupport">
                    To learn more about connecting accounts visit our <a className="standardLink" href={URLS.ConnectAccountsFAQ} target="_blank">FAQ page</a>.
                </div>
            </div>
        );
    }

    private getLogoutText = () =>
    {
        if ( this.props.accountType === TWITTER_ACCOUNT_TYPE )
        {
            return (
                <>
                    Please <a className="standardLink" href={URLS.LOGOUT_TWITTER_URL} target="_blank">log out of Twitter</a> in a different window and try again.
                </>
            );
        }
        else if ( this.props.accountType === LINKEDIN_ACCOUNT_TYPE )
        {
            return (
                <>
                    Please <a className="standardLink" href={URLS.LOGOUT_LINKED_IN_URL} target="_blank">log out of LinkedIn</a> in a different window and try again.
                </>
            );
        }
        else if ( this.props.accountType === YOUTUBE_ACCOUNT_TYPE )
        {
            return (
                <>
                    Please try again and choose a different Google account when prompted.
                </>
            );
        }
        return `Please log out of ${this.props.accountType} in a different window and try again`;
    }
}
