import * as React from "react";
import * as EyedropperSvg from "../../assets/img/eyedropper.svg";
import { browserSupportsEyedropper, eventTracker, getEyedropperColorHex } from "../../helpers";

interface EyedropperPickerProps
{
    applyHexColor( color: string );
}

export class EyedropperPicker extends React.PureComponent<EyedropperPickerProps>
{
    public render()
    {
        return (<>
            {browserSupportsEyedropper() && <div className="eyedropperButton" onClick={this.getColorFromEyedropper}>
                <img alt="eyedropper" src={EyedropperSvg}/>
            </div>}
        </>);
    }

    private getColorFromEyedropper = () =>
    {
        eventTracker.logEyedropperClicked();
        getEyedropperColorHex().then( ( selectedColor ) =>
        {
            if ( selectedColor )
            {
                this.props.applyHexColor( selectedColor );
            }
        } );
    }
}
