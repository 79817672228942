import * as React from "react";
import * as PlayButtonWhiteOnDarkGreyImg from "../assets/img/playButtonWhiteOnDarkGrey.png";

export interface PreviewOverlayProps
{
    hidePlayButton?: boolean;
}

export class PreviewOverlay extends React.PureComponent<PreviewOverlayProps>
{
    public render()
    {
        return (
            <div className="previewOverlay">
                <div className="previewDisabled"></div>
                {!this.props.hidePlayButton && <div className="previewPlayIndicator">
                    <img className="previewPlayIndicatorIcon" src={PlayButtonWhiteOnDarkGreyImg}/>
                </div>}
            </div>
        );
    }
}
