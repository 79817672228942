import { errorReporting, JPEG_TYPE, MAX_INPUT_IMAGE_DIMENSION, MAX_INPUT_IMAGE_SCALE_FACTOR, MIN_GOOD_IMAGE_DIMENSION, PNG_TYPE } from "./";
import * as loadImage from "blueimp-load-image";

type ImageOutputTypes = typeof PNG_TYPE | typeof JPEG_TYPE;

interface ImageResizerOptions
{
    allowPng: boolean;
}

export class ImageResizer
{
    private readonly file: File;
    private readonly outputExt: ImageOutputTypes;

    constructor( file: File, options?: ImageResizerOptions )
    {
        this.file = file;
        if ( options && options.allowPng && file.type === PNG_TYPE )
        {
            this.outputExt = file.type;
        }
        else
        {
            this.outputExt = JPEG_TYPE;
        }
    }

    public calculateScaleFactor = ( canvas: HTMLCanvasElement ): number =>
    {
        const canvasPixelArea = canvas.width * canvas.height;
        return Math.sqrt( canvasPixelArea ) / MAX_INPUT_IMAGE_DIMENSION;
    }

    public shouldImageBeResized = ( canvas: HTMLCanvasElement ): boolean =>
    {
        const scaleFactor = this.calculateScaleFactor( canvas );
        return scaleFactor > MAX_INPUT_IMAGE_SCALE_FACTOR;
    }

    public isImageLowRes = async (): Promise<boolean> =>
    {
        const canvas = await this.loadCanvasForImage( this.file );
        return !(canvas && canvas.width >= MIN_GOOD_IMAGE_DIMENSION && canvas.height >= MIN_GOOD_IMAGE_DIMENSION);
    }

    public resize = async (): Promise<Blob> =>
    {
        let canvas;

        try
        {
            canvas = await this.loadCanvasForImage( this.file );

            if ( this.shouldImageBeResized( canvas ) )
            {
                const scaleFactor = this.calculateScaleFactor( canvas );
                const newWidth = canvas.width / scaleFactor;
                const newHeight = canvas.height / scaleFactor;
                canvas = loadImage.scale( canvas, { maxWidth: newWidth, maxHeight: newHeight } );
            }

            if ( canvas )
            {
                return this.convertCanvasToBlob( canvas, this.outputExt );
            }
        }
        catch (e)
        {
            canvas = null;
            errorReporting.reportErrorToSentry( e );
        }

        if ( !canvas )
        {
            return Promise.reject( new Error( "Error resizing the photo." ) );
        }
    }

    private loadCanvasForImage( file ): Promise<HTMLCanvasElement>
    {
        return new Promise( ( resolve, reject ) =>
        {
            loadImage( file, ( result ) =>
                {
                    if ( result.type === "error" )
                    {
                        reject( result );
                    }
                    else
                    {
                        resolve( result );
                    }
                },
                { orientation: true, canvas: true },
            );
        } );
    }

    private convertCanvasToBlob( canvas: HTMLCanvasElement, type?: string ): Promise<Blob>
    {
        return new Promise( ( resolve, reject ) =>
        {
            // Note: The use of blueimp-canvas-to-blob elsewhere in the app applies a polyfill
            // for canvas.toBlob() to expand the supported browsers. You can find more info here:
            // https://github.com/blueimp/JavaScript-Canvas-to-Blob
            canvas.toBlob(
                ( blob ) =>
                {
                    if ( blob )
                    {
                        resolve( blob );
                    }
                    else
                    {
                        reject( "Unable to convert canvas to Blob" );
                    }
                },
                type,
            );
        } );
    }
}
