import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../_types";
import {
    getAnnualPlanForSelectedTier,
    getExchangeRateData,
    getMonthlyPlanForSelectedTier,
    getPricingProductType,
    getSelectedPlan,
    hasUsedTrial,
} from "../../ducks/pricing";
import { SelectPricePlanSection, SelectPricePlanSectionDispatchProps, SelectPricePlanSectionProps } from "../SelectPricePlanSection";
import { areStripePlansLoading } from "../../ducks/ui";
import { pricingTiersUIHelper, SubscriptionFlowSource } from "../../helpers";
import { getPrimaryStripeSubscriptionInfo, isUserPersonallySubscribed } from "../../ducks/user";
import { store } from "../../store";
import { modalServices, pricingServices, subscriptionServices, upsellServices } from "../../services";
import { pricingActions } from "../../actions";

export interface SelectPricePlanSectionContainerProps
{
    source: SubscriptionFlowSource;
}

const mapStateToProps = ( storeState: StoreState, ownProps: SelectPricePlanSectionContainerProps ): SelectPricePlanSectionProps =>
{
    return {
        annualPlan: getAnnualPlanForSelectedTier( storeState ),
        monthlyPlan: getMonthlyPlanForSelectedTier( storeState ),
        selectedPlan: getSelectedPlan( storeState ),
        shouldShowExchangeRates: !!getExchangeRateData( storeState ),
        arePlansLoading: areStripePlansLoading( storeState ),
        purchaseButtonText: getPurchaseButtonText( storeState, ownProps.source ),
        shouldShowBackToCompareTiersLink: pricingTiersUIHelper.shouldShowBackToCompareTiersLink( ownProps.source ),
        productType: getPricingProductType( storeState ),
    };
};

const mapStateToDispatchProps = ( dispatch: Dispatch<StoreState>,
                                  ownProps: SelectPricePlanSectionContainerProps ): SelectPricePlanSectionDispatchProps =>
{
    return {
        startPurchase: async () =>
        {
            const state = store.getState();
            await modalServices.closeTopLightBox( dispatch, state );

            if ( isUserPersonallySubscribed( state ) )
            {
                handleSwitchSubscription( dispatch );
            }
            else
            {
                handleSubscribeForProPreviewUser( dispatch );
            }
        },
        backToCompareTiers: async () =>
        {
            await modalServices.closeTopLightBox( dispatch, store.getState() );
            dispatch( upsellServices.displayTryProNoWarmup( ownProps.source ) );
        },
        selectPlan: ( plan: StripePlanData ) =>
        {
            dispatch( pricingActions.setPlan( plan.id ) );
        },
    };
};

function getPurchaseButtonText( storeState: StoreState, source: SubscriptionFlowSource ): string
{
    if ( isUserPersonallySubscribed( storeState ) )
    {
        return "Switch to this plan";
    }

    return hasUsedTrial( storeState ) ? "Subscribe now" : "Start 7-day free trial";
}

function handleSwitchSubscription( dispatch: Dispatch<StoreState> )
{
    modalServices.encloseInLoadingDialog( async () =>
    {
        await dispatch( subscriptionServices.getAccountInfo() );
        const state = store.getState();
        const primaryStripeSubscriptionInfo = getPrimaryStripeSubscriptionInfo( state );
        const selectedPlan = getSelectedPlan( state );

        await dispatch( subscriptionServices.switchSubscription( primaryStripeSubscriptionInfo.stripe_customer_id, selectedPlan.id ) );
    } );
}

function handleSubscribeForProPreviewUser( dispatch: Dispatch<StoreState> )
{
    dispatch( pricingServices.showPaymentFormDialogWithoutTestDrive( true, true ) );
}

export default connect(
    mapStateToProps,
    mapStateToDispatchProps,
)( SelectPricePlanSection );
