import { assign, chain, forEach, get, isEqual, isNull, isObjectLike, isUndefined, keys, reduce, union } from "lodash";

export function logPropChanges( logTag, currentProps, nextProps )
{
    // call this in componentWillReceiveProps in a component
    chain( keys( nextProps ) )
        .filter( ( key ) => nextProps[key] !== currentProps[key] )
        .map( ( key ) =>
        {
            // tslint:disable-next-line:no-console
            console.log( logTag, "changed property:", key, "from", currentProps[key], "to", nextProps[key] );
        } ).value();
}

export function getDifferencesBetweenObjects( objA, objB )
{
    // https://stackoverflow.com/questions/31683075/how-to-do-a-deep-comparison-between-2-objects-with-lodash
    const diffFn = ( a, b, path = "" ) => reduce( a, ( result, value, key ) =>
    {
        if ( isNull( b ) || isUndefined( b ) )
        {
            return result;
        }

        if ( isObjectLike( value ) )
        {
            if ( isEqual( value, b[key] ) )
            {
                return result;
            }
            else
            {

                return result.concat( diffFn( value, b[key], path ? (`${path}.${key}`) : key ) );
            }
        }
        else
        {
            return isEqual( value, b[key] ) ?
                   result : result.concat( path ? (`${path}.${key}`) : key );
        }

    }, [] );

    const diffKeys1 = diffFn( objA, objB );
    const diffKeys2 = diffFn( objB, objA );
    const diffKeys = union( diffKeys1, diffKeys2 );
    const diffObject = {};

    forEach( diffKeys, ( key: any ) => assign( diffObject, { [key]: { old: get( objA, key ), new: get( objB, key ) } } ) );

    return diffObject;
}
