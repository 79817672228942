import * as React from "react";
import { DesignElement, SortedCaptionsByType, TextPickerCaptionData, TextValue } from "../../../_types";
import TextPropertiesSubPanelContainer from "./containers/TextPropertiesSubPanel.container";
import AddTextBoxButtonContainer from "../containers/AddTextBoxButton.container";
import { TextPickerPerSlideCaptions } from "./TextPickerPerSlideCaptions";
import { TextPickerPrimarySecondaryFields } from "./TextPickerPrimarySecondaryFields";
import PresetTextPicker from "./containers/PresetTextPicker.container";

export interface TextPickerProps
{
    captionTextValue: TextValue;
    activeCaptionControls: string[];
    shouldSupportExtraCaptions: boolean;
    hasClearedStylizedTextButtonCoachMark: boolean;
    textPropertiesSubPanelCaptionData: TextPickerCaptionData;
    selectedDesignElement: DesignElement;
    sortedCaptionsByType: SortedCaptionsByType;
    shouldEnablePresetText: boolean;
}

export interface TextPickerDispatchProps
{
    triggerUpdateDesignSupportsExtraCaptions();
    changeTextPropertiesSubPanelCaptionData( captionData: TextPickerCaptionData );
    clearTextPropertiesSubPanelCaptionData();
}

export class TextPicker extends React.PureComponent<TextPickerProps & TextPickerDispatchProps>
{
    public componentDidMount()
    {
        this.props.triggerUpdateDesignSupportsExtraCaptions();
    }

    public componentWillUnmount()
    {
        this.props.clearTextPropertiesSubPanelCaptionData();
    }

    public componentDidUpdate( previousProps: TextPickerProps )
    {
        if ( this.props.textPropertiesSubPanelCaptionData )
        {
            const selectedDesignElementId = this.props.selectedDesignElement && this.props.selectedDesignElement.id;
            const hasSelectedDesignElementPropertyChanged = previousProps.selectedDesignElement
                                                            && previousProps.selectedDesignElement.id !== selectedDesignElementId;
            const hasStylizedTextSelectionChanged = this.props.textPropertiesSubPanelCaptionData && (this.props.textPropertiesSubPanelCaptionData.id
                                                                                                     !== selectedDesignElementId);

            if ( hasSelectedDesignElementPropertyChanged && hasStylizedTextSelectionChanged )
            {
                this.onStylizedTextBackClicked();
            }
        }
    }

    public render()
    {
        if ( this.props.textPropertiesSubPanelCaptionData )
        {
            return (
                <div className="text picker textPicker">
                    <TextPropertiesSubPanelContainer
                        onBackToTextClicked={this.onStylizedTextBackClicked}
                    />
                </div>
            );
        }
        else if ( this.props.shouldEnablePresetText )
        {
            return <PresetTextPicker/>;
        }

        return (
            <div className="text picker textPicker">
                <TextPickerPrimarySecondaryFields value={this.props.captionTextValue}/>
                {this.props.captionTextValue && this.props.captionTextValue.captions && <TextPickerPerSlideCaptions
                    activeCaptionControls={this.props.activeCaptionControls}
                    sortedCaptionsByType={this.props.sortedCaptionsByType}
                    shouldSupportExtraCaptions={this.props.shouldSupportExtraCaptions}
                    hasClearedStylizedTextButtonCoachMark={this.props.hasClearedStylizedTextButtonCoachMark}
                    changeTextPropertiesSubPanelCaptionData={this.props.changeTextPropertiesSubPanelCaptionData}
                />}
                <AddTextBoxButtonContainer/>
            </div>
        );
    }

    private onStylizedTextBackClicked = () =>
    {
        this.props.clearTextPropertiesSubPanelCaptionData();
    }
}
