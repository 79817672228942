import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../_types";
import { logoutServices } from "../../services";
import { LogoutLink, LogoutLinkDispatchProps } from "../LogoutLink";
import { eventTracker, SignOutSource } from "../../helpers";

interface LogoutLinkContainerOwnProps
{
    source: SignOutSource;
}

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: LogoutLinkContainerOwnProps ): LogoutLinkDispatchProps =>
{
    return {
        handleLogout: () =>
        {
            eventTracker.logUserClickedSignOutEvent( ownProps.source );
            dispatch( logoutServices.logout() );
        },
    };
};

export default connect(
    () => ({}),
    mapDispatchToProps,
)( LogoutLink );
