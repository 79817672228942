export const APP_LAUNCHED_3 = "appLaunched3";
export const LOGGED_IN = "accountLoggedIn";
export const ACCOUNT_CREATED = "accountCreated";
export const HOME_SCREEN_DISPLAYED = "homeScreenDisplayed";
export const CUSTOMIZED_POST_STARTED = "customizedPostStarted";
export const CUSTOMIZED_POST_FAILED = "customizedPostFailed";
export const CUSTOMIZE_NEXT_TAPPED = "customizeNextTapped";
export const CUSTOMIZE_NEXT_FOR_AD_TAPPED = "customizeNextForAdTapped";
export const DESIGN_CONTROL_CHANGED = "designControlChanged";
export const OUTPUT_CHOICE_SHOWN = "outputChoiceShown";
export const OUTPUT_CHOICE_NEXT_TAPPED = "outputChoiceNextTapped";
export const NEW_POST_FLOW_STARTED = "newPostFlowStarted";
export const INSPIRE_POST_SELECTED_EVENT = "inspirePostSelected";
export const RECOMMENDED_POST_SELECTED = "recommendedPostSelected";
export const POST_FLOW_BACK_SELECTED = "postFlowBackSelected";
export const POST_FLOW_CANCELLED = "postFlowCancelled";
export const BUSINESS_CREATED = "businessCreated";
export const BUSINESS_TYPE_SWITCHED = "businessTypeSwitched";
export const BUSINESS_TYPE_SELECTED = "businessTypeSelected";
export const BUSINESS_PICKER_DISPLAYED = "businessPickerDisplayed";
export const BUSINESS_NAME_ENTERED = "businessNameEntered";
export const SHARE_VIEW_OPENED = "shareViewOpened";
export const EASY_CAPTION_BUTTON_CLICKED = "magicCaptionButtonTapped";
export const SHARE_BUTTON_TAPPED = "shareButtonTapped";
export const SAVE_TO_DRAFT_STARTED = "saveToDraftStarted";
export const SCHEDULE_SEND_CALENDAR_DISPLAYED = "scheduleSendCalendarDisplayed";
export const SHARE_POST_SUCCEEDED = "sharePostSucceeded";
export const POST_COMPLETED = "postCompleted";
export const FIRST_PRO_SHARE_POST_SUCCEEDED = "firstProSharePostSucceeded";
export const SHARE_POST_FAILED = "sharePostFailed";
export const URL_OPENED_EVENT = "urlOpened";
export const OPEN_POST_FROM_DEEP_LINK_FAILED = "openPostFromDeeplinkFailed";
export const OPEN_DESIGN_FROM_DEEP_LINK_FAILED = "openDesignFromDeeplinkFailed";
export const CATALOG_OPENED = "catalogOpened";
export const CATALOG_CLOSED = "catalogClosed";
export const MAIN_DESIGN_MAX_ERRORS_REACHED_EVENT = "mainDesignMaxErrorsReached";
export const CONVERT_VIDEO_STARTED = "convertVideoStarted";
export const EDIT_VIDEO_OPENED = "editVideoOpened";
export const EDIT_VIDEO_CLOSED = "editVideoClosed";
export const TRIM_VIDEO_ENDED = "trimVideoEnded";
export const CONVERT_VIDEO_FAILED = "convertVideoFailed";
export const TRIM_VIDEO_FAILED = "trimVideoFailed";
export const CATALOG_SWITCHED = "catalogSwitched";
export const CATALOG_ASPECT_RATIO_SWITCHED = "catalogAspectRatioSwitched";
export const TEMPLATE_SELECTED = "templateSelected";
export const CATALOG_TEMPLATE_SELECTED = "catalogTemplateSelected";
export const SUBSCRIPTION_UP_TIER_BLOCKED = "inAppPurchaseUpTierBlocked";
export const SUBSCRIPTION_TIER_SELECTED = "inAppTierSelected";
export const SUBSCRIPTION_FLOW_STEP_COMPLETED = "subscriptionFlowStepCompleted";
export const SUBSCRIPTION_SUCCEEDED = "inAppPurchaseCompleted";
export const SUBSCRIPTION_ATTEMPT_FAILED = "subscriptionAttemptFailed";
export const ADD_CARD_ATTEMPT_FAILED = "addCardAttemptFailed";
export const ADD_CARD_SUCCEEDED = "addCardSucceeded";
export const PURCHASE_WARM_UP_DISPLAYED = "inAppPurchaseWarmupDisplayed";
export const PURCHASE_WARM_UP_ACCEPTED = "inAppPurchaseWarmupAccepted";
export const PURCHASE_WARM_UP_DECLINED = "inAppPurchaseWarmupDeclined";
export const CREDIT_CARD_DIALOG_DECLINED = "creditCardDialogDeclined";
export const NO_CREDIT_CARD_TRIAL_ACCEPTED = "noCardTrialAccepted";
export const EYEBROW_CLICKED = "eyebrowClicked";
export const SUBSCRIPTION_ON_HOLD_LOCKOUT_MESSAGE_SHOWN = "subscriptionOnHoldLockoutMessageShown";
export const SUBSCRIPTION_ON_HOLD_LOCKOUT_MANAGE_SUBSCRIPTION_BUTTON_TAPPED = "subscriptionOnHoldLockoutManageSubscriptionButtonTapped";
export const SUBSCRIPTION_ON_HOLD_LOCKOUT_REFRESH_BUTTON_TAPPED = "subscriptionOnHoldLockoutRefreshButtonTapped";
export const TRIAL_EXPIRE_DIALOG_DISPLAYED = "trialExpireDialogDisplayed";
export const TRIAL_EXPIRE_SUBSCRIBE_CLICKED = "trialExpireSubscribeClicked";
export const TEAM_INVITE_ACCEPTED = "teamInvitationAccepted";
export const TEAM_INVITE_SENT = "teamMemberInvited";
export const SERVER_SIDE_RECORDING_STARTED = "serverSideRecordingStarted";
export const SERVER_SIDE_RECORDING_FAILED = "serverSideRecordingFailed";
export const RECORDING_COMPLETED_EVENT = "recordingCompleted";
export const RECORDING_TIME_IN_SECONDS = "recordingTimeInSeconds";
export const VIDEO_DURATION_IN_SECONDS = "videoDurationInSeconds";
export const VIDEO_FILE_SIZE_EXCEED_LIMIT = "videoFileSizeExceedLimit";
export const MEDIA_UPLOAD_SUCCEEDED = "mediaUploadSucceeded";
export const MEDIA_UPLOAD_FAILED = "mediaUploadFailed";
export const PLAY_BUTTON_CLICKED = "playButtonClicked";
export const PAGINATION_BUTTON_CLICKED = "paginationButtonClicked";

export const USER_CLICKED_SIGN_OUT = "userClickedSignOut";
export const USER_CLICKED_SIGN_OUT_SOURCE_MY_ACCOUNT_TAB = "myAccountTab";
export const USER_CLICKED_SIGN_OUT_SOURCE_UPSELL = "upsell";
export const USER_CLICKED_SIGN_OUT_SOURCE_MOBILE_WALL = "mobileWall";

export const MANAGE_SUBSCRIPTION_TAPPED = "manageSubscriptionTapped";
export const MANAGE_SUBSCRIPTION_DISPLAYED = "manageSubscriptionDisplayed";
export const CANCEL_SUBSCRIPTION_TAPPED = "cancelSubscriptionTapped";
export const CANCEL_SUBSCRIPTION_SURVEY_DISPLAYED = "cancelSubscriptionSurveyDisplayed";
export const CANCEL_SUBSCRIPTION_SURVEY_RESPONSE = "cancelSubscriptionSurveyResponse";
export const CANCEL_SUBSCRIPTION_CONFIRMED = "cancelSubscriptionConfirmed";

export const CURRENCY_CHANGED = "currencyChanged";
export const CURRENCY_INITIALIZED = "currencyInitialized";

export const WIN_BACK_OFFER_DISPLAYED = "winBackOfferDisplayed";
export const WIN_BACK_OFFER_ACCEPT_BUTTON_TAPPED = "winBackOfferAcceptButtonTapped";
export const WIN_BACK_OFFER_APPLIED = "winBackOfferApplied";
export const WIN_BACK_OFFER_DECLINED = "winBackOfferDeclined";

export const FONT_SELECTED = "fontSelected";
export const MORE_FONTS_TAPPED = "moreFontsTapped";
export const FONT_SELECTED_FROM_MORE_FONTS = "fontSelectedFromMoreFonts";
export const FONT_SELECTED_FROM_CONTROL = "fontSelectedFromControl";

export const CUSTOMIZE_LOGO_CLICKED = "customizeLogoClicked";
export const CUSTOMIZE_SHOW_LOGO_BUTTON_CLICKED = "customizeShowLogoButtonClicked";
export const CUSTOMIZE_HIDE_LOGO_BUTTON_CLICKED = "customizeHideLogoButtonClicked";
export const CUSTOMIZE_LOGO_UPLOAD_CLICKED = "customizeLogoUploadClicked";
export const CUSTOMIZE_LOGO_UPLOAD_SUCCEEDED = "customizeLogoUploadSucceeded";

export const BRAND_SETTINGS_BUTTON_CLICKED = "brandSettingsButtonClicked";
export const BRAND_SETTINGS_APPLY_CHANGES_BUTTON_CLICKED = "applyChangesButtonClicked";
export const LOGO_TOGGLE_SWITCHED = "logoToggleSwitched";
export const BRAND_SETTINGS_COLOR_SQUARE_CLICKED = "brandColorSquareClicked";
export const LOGO_UPLOAD_SUCCEEDED = "logoUploadSucceeded";
export const LOGO_UPLOAD_CLICKED = "logoUploadClicked";
export const EYEDROPPER_CLICKED = "eyedropperClicked";
export const BRAND_SETTINGS_COLOR_APPLIED = "brandColorApplied";
export const BRAND_SETTINGS_FONT_APPLIED = "brandFontApplied";

export const EXTRA_CAPTION_ADDED = "extraCaptionAdded";
export const EXTRA_CAPTION_REMOVED = "extraCaptionRemoved";
export const NUMBER_OF_EXTRA_CAPTIONS = "numberOfExtraCaptions";

export const SHOW_YOU_AROUND_DISMISSED = "showYouAroundDismissed";
export const SHOW_YOU_AROUND_DISPLAYED_INTRO = "showYouAroundDisplayed";
export const SHOW_YOU_AROUND_DISPLAYED_TOP_COLLECTION = "showYouAroundP2Displayed";
export const SHOW_YOU_AROUND_DISPLAYED_SEARCH = "showYouAroundP3Displayed";
export const SHOW_YOU_AROUND_DISPLAYED_CREATE_YOUR_OWN = "showYouAroundP4Displayed";
export const SHOW_YOU_AROUND_DONE_TAPPED = "showYouAroundP4DoneTapped";

export const SHOW_YOU_AROUND_DISMISSAL_SOURCE_INTRO = "not now";
export const SHOW_YOU_AROUND_DISMISSAL_SOURCE_TOP_COLLECTION = "p2";
export const SHOW_YOU_AROUND_DISMISSAL_SOURCE_SEARCH = "p3";
export const SHOW_YOU_AROUND_DISMISSAL_SOURCE_CREATE_YOUR_OWN = "p4";

export const REFER_A_FRIEND_DISPLAYED = "referAFriendDisplayed";
export const RIPL_USER_SURVEY_2023_SHOWN = "surveyShown";
export const ALKAI_CROSS_PROMO_DIALOG_SHOWN = "alkaiCrossPromoDialogShown";
export const ALKAI_CROSS_PROMO_DIALOG_ACCEPTED = "alkaiCrossPromoDialogAccepted";
export const ALKAI_CROSS_PROMO_DIALOG_DECLINED = "alkaiCrossPromoDialogDeclined";
export const PAYMENT_FORM_STRIPE_TOKEN_FAILED = "paymentFormStripeTokenFailed";

export const CONTENT_SEARCHED = "contentSearched";
export const CONTENT_SEARCH_RESULT_CLICKED = "contentSearchResultClicked";

export const COLLECTION_POST_CLICKED = "collectionPostClicked";

export const STOCK_MEDIA_SEARCHED = "stockMediaSearched";
export const STOCK_MEDIA_CLICKED = "stockMediaClicked";

export const WEB_NAV_POST_DETAILS_DISPLAYED = "postDetailsDisplayed";
export const WEB_NAV_POST_DETAILS_COPIED = "postDetailsCopied";
export const WEB_NAV_DRAFT_OPENED = "postDraftOpened";

export const FIRST_RUN_INSPIRE_DISPLAYED = "firstRunInspireDisplayed";
export const WEB_NAV_INSPIRE_DISPLAYED = "inspireDisplayed";
export const WEB_NAV_PROFILE_DISPLAYED = "profileDisplayed";
export const WEB_NAV_BRAND_DISPLAYED = "myBrandDisplayed";
export const WEB_NAV_ACCOUNT_DISPLAYED = "myAccountDisplayed";
export const WEB_NAV_PROFILE_POST_DISPLAYED = "profilePostDisplayed";
export const WEB_NAV_DRAFTS_DISPLAYED = "draftsDisplayed";
export const WEB_NAV_CALENDAR_DISPLAYED = "calendarDisplayed";
export const WEB_NAV_LEARN_DISPLAYED = "learnDisplayed";

export const TEST_DRIVE_ACCEPTED = "testDriveAccepted";

export const COLLECTION_ROW_POST_CLICKED = "collectionRowPostClicked";
export const COLLECTION_ROW_SEE_ALL_TAPPED = "collectionRowSeeAllTapped";

export const FACEBOOK_EVENTS = {
    PURCHASE: "Purchase",
    COMPLETE_REGISTRATION: "CompleteRegistration",
    LEAD: "Lead",
    ADD_TO_CART: "AddToCart",
    INITIATE_CHECKOUT: "InitiateCheckout",
};

export const GOOGLE_CONVERSION_MONTHLY_SUBSCRIPTION_TAG = "AW-875425320/21i6CL2Dv4kBEKjct6ED";
export const GOOGLE_CONVERSION_YEARLY_SUBSCRIPTION_TAG = "AW-875425320/akSbCKKGzJ0DEKjct6ED";
export const GOOGLE_CONVERSION_PREMIUM_MONTHLY_SUBSCRIPTION_TAG = "AW-875425320/snZQCL62tZAYEKjct6ED";
export const GOOGLE_CONVERSION_PREMIUM_YEARLY_SUBSCRIPTION_TAG = "AW-875425320/ACYxCOSOuZAYEKjct6ED";

export const SOCIAL_ACCOUNT_CONNECTED_EVENT_NAME_SUFFIX = "Connected";
export const FAILED_TO_CONNECT_SOCIAL_ACCOUNT_EVENT_NAME_PREFIX = "failedToConnect";

export const BUSINESS_CREATION_STEP_TRACKING_NAME_CONNECT_INSTAGRAM = "CONNECT_INSTAGRAM";

export const BUSINESS_TYPE_SELECTED_SOURCE_CREATE = "CREATE";
export const BUSINESS_TYPE_SELECTED_SOURCE_UPDATE = "UPDATE";

export const BUSINESS_PICKER_SOURCE_ONBOARDING = "onboarding";
export const BUSINESS_PICKER_SOURCE_PROFILE = "profile";
export const BUSINESS_PICKER_SOURCE_LOGIN = "login";
export const BUSINESS_PICKER_SOURCE_DROPDOWN = "dropdown";
export const BUSINESS_PICKER_SOURCE_SETTINGS = "settings";

export const SOCIAL_ACCOUNT_CONNECTED_SOURCE_CREATE_BUSINESS = "CREATE_BUSINESS";
export const SOCIAL_ACCOUNT_CONNECTED_SOURCE_UPDATE_BUSINESS = "UPDATE_BUSINESS";
export const SOCIAL_ACCOUNT_CONNECTED_SOURCE_SHARE_POST = "SHARE_POST";
export const SOCIAL_ACCOUNT_CONNECTED_SOURCE_INSTAGRAM_SETUP_DIALOG = "INSTAGRAM_SETUP_DIALOG";
export const SOCIAL_ACCOUNT_CONNECTED_SOURCE_INSTAGRAM_DONT_SEE_BUSINESS_DIALOG = "INSTAGRAM_DONT_SEE_BUSINESS_DIALOG";
export const OAUTH_ACCOUNT_CONNECTED_SOURCE_MUSIC_PICKER = "MUSIC_PICKER";

export const EPIDEMIC_SOUND_SOURCE_CURATED = "curated";
export const EPIDEMIC_SOUND_SOURCE_ES_CONNECT = "esConnect";
export type EpidemicSoundSource = typeof EPIDEMIC_SOUND_SOURCE_CURATED |
    typeof EPIDEMIC_SOUND_SOURCE_ES_CONNECT;
export const DOWNLOAD_MUSIC_FAILED = "downloadMusicFailed";
export const MUSIC_ITEM_SELECTED = "musicItemSelected";
export const PICK_MUSIC = "pickMusic";

export const POST_INTENT_SELECTION_SCREEN_DISPLAYED = "postIntentSelectionScreenDisplayed";
export const POST_INTENT_TAPPED = "postIntentTapped";
export const POST_INTENT_TEMPLATE_SCREEN_DISPLAYED = "postIntentTemplateScreenDisplayed";

export const SUBSCRIPTION_FLOW_SOURCE_CREATE_ACCOUNT = "createAccount";
export const SUBSCRIPTION_FLOW_SOURCE_SCHEDULE_POST = "schedulePost";
export const SUBSCRIPTION_FLOW_SOURCE_ACCOUNT_SETTINGS = "accountSettings";
export const SUBSCRIPTION_FLOW_SOURCE_CUSTOMIZE_NEXT_BUTTON = "customizeNextButton";
export const SUBSCRIPTION_FLOW_SOURCE_IN_APP_NOTICE_SUBSCRIBE = "inAppNoticeSubscribe";
export const SUBSCRIPTION_FLOW_SOURCE_MANAGE_SUBSCRIPTION = "manageSubscription";
export const SUBSCRIPTION_FLOW_SOURCE_ADD_NEW_BUSINESS_CLICK = "addNewBusiness";
export const SUBSCRIPTION_FLOW_SOURCE_AD_COMMISSION_FEE = "commissionFee";
export const SUBSCRIPTION_FLOW_SOURCE_TEAMS_TAB_CLICKED = "teamsTabClicked";
export const SUBSCRIPTION_FLOW_SOURCE_PURCHASE_PAGE = "purchasePage";
export const SUBSCRIPTION_FLOW_SOURCE_EPIDEMIC_MUSIC_TRACK = "epidemicMusicTrack";

export const SUBSCRIPTION_FLOW_UPTIER_SOURCES = [
    SUBSCRIPTION_FLOW_SOURCE_AD_COMMISSION_FEE,
    SUBSCRIPTION_FLOW_SOURCE_ADD_NEW_BUSINESS_CLICK,
    SUBSCRIPTION_FLOW_SOURCE_SCHEDULE_POST,
    SUBSCRIPTION_FLOW_SOURCE_TEAMS_TAB_CLICKED,
    SUBSCRIPTION_FLOW_SOURCE_EPIDEMIC_MUSIC_TRACK,
];

export const SUBSCRIPTION_FLOW_STEP_TRACKING_NAME_PRO_WARMUP = "PRO_WARMUP";
export const SUBSCRIPTION_FLOW_STEP_TRACKING_NAME_TRY_PRO = "TRY_PRO";
export const SUBSCRIPTION_FLOW_STEP_TRACKING_NAME_PAYMENT_FORM = "PAYMENT_FORM";

export const OUTPUT_TOGGLE_BUTTON_TAPPED = "outputToggleButtonTapped";

export const LOGIN_TYPE_VIA_FACEBOOK = "viaFacebook";
export const LOGIN_TYPE_VIA_EMAIL = "viaEmail";
export const LOGIN_TYPE_FACEBOOK = "facebook";
export const LOGIN_TYPE_GOOGLE = "google";
export const LOGIN_TYPE_EMAIL = "email";

export const BRAND_SETTINGS_DIALOG = "brand setting dialog";
export const MENU = "menu";
export const BRAND_SLIDE_SOURCE = "brandSlide";

export const STYLIZE_TEXT_PANEL_OPENED_EVENT = "stylizeTextPanelOpened";
export const STYLIZE_TEXT_CHANGED_EVENT = "stylizeTextChanged";
export const TEXT_PROPERTIES_OPENED_EVENT = "textPropertiesOpened";
export const TEXT_PROPERTIES_TAB_OPENED_EVENT = "textPropertiesTabOpened";
export const TEXT_PROPERTIES_CHANGED_EVENT = "textPropertiesChanged";

export const PAYMENT_METHOD_UPDATED = "paymentMethodUpdated";
export const UPDATE_CARD_FORM_SOURCE_ACCOUNT_SUBSCRIPTION = "accountSettings";
export type UpdateCardFormSource =
    typeof UPDATE_CARD_FORM_SOURCE_ACCOUNT_SUBSCRIPTION;

export type LoginType =
    typeof LOGIN_TYPE_VIA_FACEBOOK
    | typeof LOGIN_TYPE_VIA_EMAIL
    | typeof LOGIN_TYPE_FACEBOOK
    | typeof LOGIN_TYPE_GOOGLE
    | typeof LOGIN_TYPE_EMAIL;

export type BusinessTypeSelectedSource = typeof BUSINESS_TYPE_SELECTED_SOURCE_CREATE |
    typeof BUSINESS_TYPE_SELECTED_SOURCE_UPDATE;

export type SignOutSource = typeof USER_CLICKED_SIGN_OUT_SOURCE_MY_ACCOUNT_TAB |
    typeof USER_CLICKED_SIGN_OUT_SOURCE_UPSELL |
    typeof USER_CLICKED_SIGN_OUT_SOURCE_MOBILE_WALL;

export type BusinessPickerSource = typeof BUSINESS_PICKER_SOURCE_ONBOARDING |
    typeof BUSINESS_PICKER_SOURCE_LOGIN |
    typeof BUSINESS_PICKER_SOURCE_PROFILE |
    typeof BUSINESS_PICKER_SOURCE_DROPDOWN |
    typeof BUSINESS_PICKER_SOURCE_SETTINGS;

export type SocialAccountConnectedSource = typeof SOCIAL_ACCOUNT_CONNECTED_SOURCE_CREATE_BUSINESS |
    typeof SOCIAL_ACCOUNT_CONNECTED_SOURCE_UPDATE_BUSINESS |
    typeof BUSINESS_CREATION_STEP_TRACKING_NAME_CONNECT_INSTAGRAM |
    typeof SOCIAL_ACCOUNT_CONNECTED_SOURCE_SHARE_POST |
    typeof SOCIAL_ACCOUNT_CONNECTED_SOURCE_INSTAGRAM_SETUP_DIALOG |
    typeof SOCIAL_ACCOUNT_CONNECTED_SOURCE_INSTAGRAM_DONT_SEE_BUSINESS_DIALOG |
    typeof OAUTH_ACCOUNT_CONNECTED_SOURCE_MUSIC_PICKER;

export type SubscriptionFlowSource =
    typeof SUBSCRIPTION_FLOW_SOURCE_SCHEDULE_POST |
    typeof SUBSCRIPTION_FLOW_SOURCE_CREATE_ACCOUNT |
    typeof SUBSCRIPTION_FLOW_SOURCE_ACCOUNT_SETTINGS |
    typeof SUBSCRIPTION_FLOW_SOURCE_IN_APP_NOTICE_SUBSCRIBE |
    typeof SUBSCRIPTION_FLOW_SOURCE_MANAGE_SUBSCRIPTION |
    typeof SUBSCRIPTION_FLOW_SOURCE_CUSTOMIZE_NEXT_BUTTON |
    typeof SUBSCRIPTION_FLOW_SOURCE_ADD_NEW_BUSINESS_CLICK |
    typeof SUBSCRIPTION_FLOW_SOURCE_AD_COMMISSION_FEE |
    typeof SUBSCRIPTION_FLOW_SOURCE_TEAMS_TAB_CLICKED |
    typeof SUBSCRIPTION_FLOW_SOURCE_PURCHASE_PAGE |
    typeof SUBSCRIPTION_FLOW_SOURCE_EPIDEMIC_MUSIC_TRACK;

export type SubscriptionEventName = typeof SUBSCRIPTION_FLOW_STEP_COMPLETED |
    typeof SUBSCRIPTION_SUCCEEDED |
    typeof SUBSCRIPTION_ATTEMPT_FAILED |
    typeof SUBSCRIPTION_TIER_SELECTED |
    typeof SUBSCRIPTION_UP_TIER_BLOCKED |
    typeof PURCHASE_WARM_UP_ACCEPTED |
    typeof PURCHASE_WARM_UP_DECLINED |
    typeof PURCHASE_WARM_UP_DISPLAYED;

export type ShowYouAroundDisplayedEventName = typeof SHOW_YOU_AROUND_DISPLAYED_INTRO |
    typeof SHOW_YOU_AROUND_DISPLAYED_TOP_COLLECTION |
    typeof SHOW_YOU_AROUND_DISPLAYED_SEARCH |
    typeof SHOW_YOU_AROUND_DISPLAYED_CREATE_YOUR_OWN;

export type ShowYouAroundDismissalSource = typeof SHOW_YOU_AROUND_DISMISSAL_SOURCE_INTRO |
    typeof SHOW_YOU_AROUND_DISMISSAL_SOURCE_TOP_COLLECTION |
    typeof SHOW_YOU_AROUND_DISMISSAL_SOURCE_SEARCH |
    typeof SHOW_YOU_AROUND_DISMISSAL_SOURCE_CREATE_YOUR_OWN;

export const GET_MORE_MUSIC_CLICKED = "getMoreMusicClicked";

export const TEMPLATE_PREVIEW_SHOWN = "templatePreviewShown";
export const TEMPLATE_PREVIEW_CUSTOMIZE_TAPPED = "templatePreviewCustomizeTapped";
export const TEMPLATE_PREVIEW_APPLY_BRAND_STYLES_SWITCH_TOGGLED = "templatePreviewApplyBrandStylesSwitchToggled";
export const TEMPLATE_PREVIEW_SOURCE_MERCHANDISING = "merchandising";
export const TEMPLATE_PREVIEW_SOURCE_SOCIAL_CALENDAR = "socialCalendar";

export const CHANGED_SIZE_FROM_CUSTOMIZE = "changedSizeFromCustomize";

export const PROFILE_FACEBOOK_CONNECT_TAPPED_EVENT = "facebookConnectTapped";
export const PROFILE_FACEBOOK_GROUPS_CONNECT_TAPPED_EVENT = "facebookGroupsConnectTapped";
export const PROFILE_FACEBOOK_PAGES_CONNECT_TAPPED_EVENT = "facebookPagesConnectTapped";
export const PROFILE_FACEBOOK_INSTAGRAM_CONNECT_TAPPED_EVENT = "facebookInstagramConnectTapped";
export const PROFILE_INSTAGRAM_PERSONAL_CONNECT_TAPPED_EVENT = "instagramConnectTapped";
export const PROFILE_LINKEDIN_CONNECT_TAPPED_EVENT = "linkedInConnectTapped";
export const PROFILE_TWITTER_CONNECT_TAPPED_EVENT = "twitterConnectTapped";
export const PROFILE_YOUTUBE_CONNECT_TAPPED_EVENT = "youTubeConnectTapped";

export type TemplatePreviewSources =
    typeof TEMPLATE_PREVIEW_SOURCE_MERCHANDISING
    | typeof TEMPLATE_PREVIEW_SOURCE_SOCIAL_CALENDAR;
