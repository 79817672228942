import * as React from "react";
import Checkbox from "react-md/lib/SelectionControls/Checkbox";

export interface StockMediaTermsDialogDispatchProps
{
    handleTermsAcceptedChanged: ( acceptedTerm: boolean ) => void;
}

interface StockMediaTermsDialogState
{
    hasAcceptedTerm: boolean;
}

export class StockMediaTermsDialog extends React.PureComponent<StockMediaTermsDialogDispatchProps, StockMediaTermsDialogState>
{
    constructor( props )
    {
        super( props );
        this.state = {
            hasAcceptedTerm: false,
        };
    }

    public render()
    {
        return (
            <>
                <div className="termsText">
                    <section>
                        Content is for digital use only, originating from Ripl. Content cannot be used for print purposes.
                    </section>
                    <section>
                        You may not use the content as a trademark for a business.
                    </section>
                    <section>
                        You may not portray a person in a way that may be offensive, including: in connection with adult-oriented services or ads for
                        dating services; in connection with the promotion of tobacco products; in connection with political endorsements; with
                        pornographic, defamatory, unlawful, offensive or immoral content; and as suffering from, or being treated for, a physical or
                        mental ailment.
                    </section>
                    <section>
                        You may only use the content in campaigns and content created on Ripl.
                    </section>
                </div>
                <Checkbox
                    id="termsAccepted"
                    name="termsAccepted"
                    checked={this.state.hasAcceptedTerm}
                    onChange={this.handleOnchange}
                    label={this.createCheckBoxLabel()}/>
            </>
        );
    }

    private createCheckBoxLabel = () =>
    {
        return <span>I accept the full <a target="_blank" href="https://www.ripl.com/terms-of-use">Terms & Conditions</a></span>;
    }

    private handleOnchange = async () =>
    {
        await this.setState( { hasAcceptedTerm: !this.state.hasAcceptedTerm } );
        this.props.handleTermsAcceptedChanged( this.state.hasAcceptedTerm );
    }
}
