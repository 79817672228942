// download.js v4.2, by dandavis; 2008-2016. [MIT] see http://danml.com/download.html for tests/usage
// v1 landed a FF+Chrome compat way of downloading strings to local un-named files, upgraded to use a hidden frame and optional mime
// v2 added named files via a[download], msSaveBlob, IE (10+) support, and window.URL support for larger+faster saves than dataURLs
// v3 added dataURL and Blob Input, bind-toggle arity, and legacy dataURL fallback was improved with force-download \
//     mime and base64 support. 3.1 improved safari handling.
// v4 adds AMD/UMD, commonJS, and plain browser support
// v4.1 adds url download capability via solo URL argument (same domain/CORS only)
// v4.2 adds semantic variable names, long (over 2MB) dataURL support, and hidden by default temp anchors
// https://github.com/rndme/download

// 2018-05-18: Copied and ported to TypeScript because it had errors loading other ways.
// 2022-01-13: Converted to class to handle events.

interface DownloadListener
{
    onabort?: ( ev: Event ) => any;
    onerror?: ( ev: ErrorEvent ) => any;
    onloadend?: ( ev: ProgressEvent ) => any;
    onloadstart?: ( ev: Event ) => any;
    onprogress?: ( ev: ProgressEvent ) => any;
    ontimeout?: ( ev: ProgressEvent ) => any;
}

export class Downloader
{
    private readonly url: string;
    private readonly listener: DownloadListener;

    constructor( url: string, listener?: DownloadListener )
    {
        this.url = url;
        this.listener = listener;
    }

    public download = () =>
    {
        return this.downloadImpl( this.url );
    }

    private downloadImpl = ( data, strFileName?: string, strMimeType?: string ) =>
    {
        const self = window; // this script is only for browsers anyway...
        const defaultMime = "application/octet-stream"; // this default mime also triggers iframe downloads
        const anchor = document.createElement( "a" );
        let payload = data;
        const url = !strFileName && !strMimeType && payload;
        let mimeType = strMimeType || defaultMime;
        /* tslint:disable:no-string-literal */
        let myBlob = (self.Blob || self["MozBlob"] || self["WebKitBlob"] || toString);
        let fileName = strFileName || "download";
        let blob;
        let reader;
        myBlob = myBlob.call ? myBlob.bind( self ) : Blob;

        if ( String( this ) === "true" )
        { // reverse arguments, allowing download.bind(true, "text/xml", "export.xml") to act as a callback
            payload = [payload, mimeType];
            mimeType = payload[0];
            payload = payload[1];
        }

        if ( url && url.length < 2048 )
        { // if no filename and no mime, assume a url was passed as the only argument
            fileName = url.split( "/" ).pop().split( "?" )[0];
            anchor.href = url; // assign href prop to temp anchor
            if ( anchor.href.indexOf( url ) !== -1 )
            { // if the browser determines that it's a potentially valid url path:
                const ajax = new XMLHttpRequest();
                ajax.open( "GET", url, true );
                ajax.responseType = "blob";

                setTimeout( () =>
                {
                    ajax.send();
                }, 0 ); // allows setting custom ajax headers using the return:

                ajax.onload = ( e ) =>
                {
                    this.downloadImpl( ajax.response, fileName, defaultMime );
                };

                ajax.onloadstart = ( e ) =>
                {
                    if ( this.listener && this.listener.onloadstart )
                    {
                        this.listener.onloadstart( e );
                    }
                };

                ajax.onprogress = ( e ) =>
                {
                    if ( this.listener && this.listener.onprogress )
                    {
                        this.listener.onprogress( e );
                    }
                };

                ajax.onabort = ( e ) =>
                {
                    if ( this.listener && this.listener.onabort )
                    {
                        this.listener.onabort( e );
                    }
                };

                ajax.onerror = ( e ) =>
                {
                    if ( this.listener && this.listener.onerror )
                    {
                        // @ts-ignore
                        this.listener.onerror( e );
                    }
                };

                return ajax;
            } // end if valid url?
        } // end if url?

        // go ahead and download dataURLs right away
        if ( /^data:([\w+-]+\/[\w+.-]+)?[,;]/.test( payload ) )
        {
            if ( payload.length > (1024 * 1024 * 1.999) && myBlob !== toString )
            {
                payload = dataUrlToBlob( payload );
                mimeType = payload.type || defaultMime;
            }
            else
            {
                return navigator.msSaveBlob ?  // IE10 can't do a[download], only Blobs:
                       navigator.msSaveBlob( dataUrlToBlob( payload ), fileName ) :
                       saver( payload ); // everyone else can save dataURLs un-processed
            }
        }
        else
        { // not data url, is it a string with special needs?
            if ( /([\x80-\xff])/.test( payload ) )
            {
                const tempUiArr = new Uint8Array( payload.length );
                const mx = tempUiArr.length;
                for ( let i = 0; i < mx; ++i )
                {
                    tempUiArr[i] = payload.charCodeAt( i );
                }
                payload = new myBlob( [tempUiArr], { type: mimeType } );
            }
        }
        blob = payload instanceof myBlob ?
               payload :
               new myBlob( [payload], { type: mimeType } );

        function dataUrlToBlob( strUrl )
        {
            const parts = strUrl.split( /[:;,]/ );
            const type = parts[1];
            const decoder = parts[2] === "base64" ? atob : decodeURIComponent;
            const binData = decoder( parts.pop() );
            const mx = binData.length;
            const uiArr = new Uint8Array( mx );

            for ( let i = 0; i < mx; ++i )
            {
                uiArr[i] = binData.charCodeAt( i );
            }

            return new myBlob( [uiArr], { type } );
        }

        function saver( saveUrl, winMode = false )
        {
            if ( "download" in anchor )
            { // html5 A[download]
                anchor.href = saveUrl;
                anchor.setAttribute( "download", fileName );
                anchor.className = "download-js-link";
                anchor.innerText = "downloading...";
                anchor.style.display = "none";
                document.body.appendChild( anchor );
                setTimeout( () =>
                {
                    anchor.click();
                    document.body.removeChild( anchor );
                    if ( winMode === true )
                    {
                        setTimeout( () =>
                        {
                            self.URL.revokeObjectURL( anchor.href );
                        }, 250 );
                    }
                }, 66 );
                return true;
            }

            // handle non-a[download] safari as best we can:
            if ( /(Version)\/(\d+)\.(\d+)(?:\.(\d+))?.*Safari\//.test( navigator.userAgent ) )
            {
                if ( /^data:/.test( saveUrl ) )
                {
                    saveUrl = "data:" + saveUrl.replace( /^data:([\w\/\-\+]+)/, defaultMime );
                }
                if ( !window.open( saveUrl ) )
                { // popup blocked, offer direct download:
                    if ( confirm( "Displaying New Document\n\nUse Save As... to download, then click back to return to this page." ) )
                    {
                        location.href = saveUrl;
                    }
                }
                return true;
            }

            // do iframe dataURL download (old ch+FF):
            const f = document.createElement( "iframe" );
            document.body.appendChild( f );

            if ( !winMode && /^data:/.test( saveUrl ) )
            { // force a mime that will download:
                saveUrl = "data:" + saveUrl.replace( /^data:([\w\/\-\+]+)/, defaultMime );
            }
            f.src = saveUrl;
            setTimeout( () =>
            {
                document.body.removeChild( f );
            }, 333 );

        }// end saver

        if ( navigator.msSaveBlob )
        { // IE10+ : (has Blob, but not a[download] or URL)
            return navigator.msSaveBlob( blob, fileName );
        }

        if ( self.URL )
        { // simple fast and modern way using Blob and URL:
            saver( self.URL.createObjectURL( blob ), true );
        }
        else
        {
            // handle non-Blob()+non-URL browsers:
            if ( typeof blob === "string" || blob.constructor === toString )
            {
                try
                {
                    return saver( "data:" + mimeType + ";base64," + self.btoa( blob ) );
                }
                catch (y)
                {
                    return saver( "data:" + mimeType + "," + encodeURIComponent( blob ) );
                }
            }

            // Blob but not URL support:
            reader = new FileReader();
            reader.onload = ( e ) =>
            {
                saver( reader.result );
            };
            reader.readAsDataURL( blob );
        }
        return true;
    }
}

function toString( a )
{
    return String( a );
}

/* end download() */
