import * as React from "react";

interface ControlPickerHeaderProps
{
    title: string;
}

export const ControlPickerHeader: React.FC<ControlPickerHeaderProps> = ( props ) =>
{
    return (
        <div className="controlPickerHeader">
            <div className="title">{props.title}</div>
        </div>
    );
};
