import * as React from "react";
import { InspireBackgroundOverlay } from "./InspireBackgroundOverlay";
import { DialogControlProps } from "../_types";
import { CircularProgressWithText } from "./CircularProgressWithText";
import { BotInfo, BrowserInfo, detect, NodeInfo } from "detect-browser";

export interface PurchasePageProps extends DialogControlProps
{
    hasLoadedMeThisSession: boolean;
    isEligibleToUpgradeToStripeAnnual: boolean;
    isUserPersonallySubscribed: boolean;
    didNotSubscribeThisSession: boolean;
}

export interface PurchasePagePropsDispatchProps
{
    redirectToHome( didNotSubscribeThisSession: boolean ): void;
    redirectForAnnualPlanUpgrade( browser: BrowserInfo | BotInfo | NodeInfo | null ): void;
    handleUnmounted(): void;
    showPricingTiersUpsellInLightbox(): void;
}

export class PurchasePage extends React.PureComponent<PurchasePageProps & PurchasePagePropsDispatchProps>
{
    public componentDidMount()
    {
        this.handleRedirectScenarios();
    }

    public componentDidUpdate()
    {
        this.handleRedirectScenarios();
    }

    public render()
    {
        return (
            <div className="purchasePage">
                <InspireBackgroundOverlay/>
                <div className="content">
                    {this.renderContent()}
                </div>
            </div>
        );
    }

    private renderContent = () =>
    {
        if ( !this.props.hasLoadedMeThisSession )
        {
            return this.renderSpinner();
        }

        return (
            <></>
        );
    }

    private renderSpinner = () =>
    {
        return (
            <div className="spinnerWrap">
                <CircularProgressWithText
                    positionTextAbove={true}
                    progressText="Checking your account..."/>
            </div>
        );
    }

    private handleRedirectScenarios = () =>
    {
        if ( this.props.hasLoadedMeThisSession )
        {
            if ( this.props.isEligibleToUpgradeToStripeAnnual )
            {
                const browser = detect();
                this.props.redirectForAnnualPlanUpgrade( browser );
            }
            else if ( this.props.isUserPersonallySubscribed )
            {
                this.props.redirectToHome( this.props.didNotSubscribeThisSession );
            }
            else
            {
                this.props.showPricingTiersUpsellInLightbox();
            }
        }
    }
}
