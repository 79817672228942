import * as serverErrorImg from "../assets/img/server_error.png";
import { CUSTOMER_SUPPORT_UNBREAKING, NEED_HELP_SETTINGS_URL } from "../helpers";
import * as React from "react";
import { PaymentFormError } from "../_types";

export interface CardFormErrorProps
{
    formError: PaymentFormError;
}

export class CardFormError extends React.PureComponent<CardFormErrorProps>
{
    public render()
    {
        if ( this.props.formError )
        {
            return (
                <div className="submitErrorWrap">
                    <div className="submitErrorRow">
                        <img alt="card error" src={serverErrorImg}/>
                        <div>
                            <div>{this.props.formError.message}</div>
                            {this.props.formError.showContactSupportLink
                             && <div>If the problem continues, please contact <a className="standardLink" href={NEED_HELP_SETTINGS_URL} target="_blank">
                                    {CUSTOMER_SUPPORT_UNBREAKING}
                                </a>
                             </div>}
                        </div>
                    </div>
                </div>
            );
        }
        else
        {
            return (<></>);
        }
    }
}
