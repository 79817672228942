import * as React from "react";
import { Route, Switch } from "react-router";
import { includes } from "lodash";
import {
    ACCEPT_TEAM_INVITE_PARAM,
    BRAND_SETTINGS_PAGE_URL,
    browserUtils,
    COLLECTION_ACTION_PARAM,
    COLLECTION_SLUG_KEY,
    CONTENT_SEARCH_ACTION_PARAM,
    CONTENT_SEARCH_TERM_KEY,
    DESIGN_SLUG_PARAM,
    HOMEPAGE_MY_BRAND_URL,
    HOMEPAGE_MY_POSTS_URL,
    HOMEPAGE_URL,
    MANAGE_SUBSCRIPTION_PAGE_INTERNAL_URL,
    MANAGE_SUBSCRIPTION_PARAM,
    NEED_HELP_SETTINGS_URL,
    OPEN_DESIGN_PARAM,
    OPEN_DESIGN_POST_ID_PARAM,
    REFER_A_FRIEND_URL,
    SUBSCRIPTION_TERMS_URL,
    TEAM_INVITE_TOKEN_PARAM,
    USER_SETTINGS_URL,
} from "../../helpers";
import { ErrorBoundary } from "../ErrorBoundary";
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import { MobileBrowserWall } from "../MobileBrowserWall";
import * as queryString from "querystring";
import { UserBusiness } from "../../_types";
import SubscriptionTermsPage from "../SubscriptionTermsPage";
import PostsPageContainer from "./containers/PostsPage.container";
import BrandPageContainer from "./containers/BrandPage.container";
import NeedHelpSubPageContainer from "../settingsPages/containers/NeedHelpSubPage.container";
import UserSettingsSubPageContainer from "../settingsPages/containers/UserSettingsSubPage.container";
import HomeDiscoverSubPageContainer from "./containers/HomeDiscoverSubPage.container";
import ContentSearchRouteContainer from "./containers/ContentSearchRoute.container";
import HomePageHeaderContainer from "./containers/HomePageHeader.container";
import ManageSubscriptionsPage from "../settingsPages/ManageSubscriptionsPage";
import ReferAFriendPageContainer from "../settingsPages/containers/ReferAFriendPage.container";
import classNames from "classnames";

export interface HomePageProps
{
    currentBusiness: UserBusiness;
    isUserLoggedIn: boolean;
    isPayingUserOrAcceptedProPreview: boolean;
    isPayingUser: boolean;
    shouldRenderForMobileAds: boolean;
    shouldShowCollabraUI: boolean;
}

export interface HomePageDispatchProps
{
    onPageLoaded();
    handleAcceptInvite( token: string );
    handleManageSubscription();
    handleDisplayHomePageAlert();
    handleOpenExamplePostFromDeeplink( postId: string, designSlug: string );
    handleOpenDesignFromDeeplink( designSlug: string );
    handleOpenCollectionFromDeepLink( collectionSlug: string );
    handleOpenContentSearchFromDeepLink( searchTerm: string );
    clearStateForHomeLoad();
    handleClosingAllModals();
}

export default class HomePage extends React.PureComponent<HomePageProps & HomePageDispatchProps>
{
    public componentDidMount()
    {
        this.props.clearStateForHomeLoad();
        setTimeout( this.performHomePageTasks.bind( this ), 500 );
        window.addEventListener( "resize", this.handleClosingAllModalsForMobile );
    }

    public render()
    {
        const isMobileBrowser = browserUtils.isMobileBrowser() && !this.shouldPathBeVisibleOnMobile();
        return (
            <div className={classNames( "homepage", { isMobileBrowser } )}>
                <ErrorBoundary errorMessage="There was a problem creating the home screen. Please restart to continue."
                               showResetButton={true}>
                    <Grid className="homepageGrid" noSpacing>
                        {!isMobileBrowser && this.renderDesktopApp()}
                        {isMobileBrowser && this.forceRenderMobileWall()}
                    </Grid>
                </ErrorBoundary>
            </div>
        );
    }

    private shouldPathBeVisibleOnMobile()
    {
        return includes( window.location.pathname, BRAND_SETTINGS_PAGE_URL ) ||
               includes( window.location.pathname, USER_SETTINGS_URL ) ||
               includes( window.location.pathname, MANAGE_SUBSCRIPTION_PAGE_INTERNAL_URL );
    }

    private handleClosingAllModalsForMobile = () =>
    {
        const isMobileBrowser = browserUtils.isMobileBrowser();
        if ( isMobileBrowser )
        {
            this.props.handleClosingAllModals();
        }
    }

    private renderDesktopApp = () =>
    {
        const path = window.location.pathname;
        return <>
            <Cell size={12}
                  className="homepageContent">
                {<HomePageHeaderContainer hasBusiness={!!this.props.currentBusiness}
                                          currentPathname={path}/>}
                <Switch>
                    <Route path={HOMEPAGE_URL} exact={true} key={HOMEPAGE_URL}>
                        <ContentSearchRouteContainer>
                            <HomeDiscoverSubPageContainer/>
                        </ContentSearchRouteContainer>
                    </Route>

                    <Route path={HOMEPAGE_MY_POSTS_URL} key={HOMEPAGE_MY_POSTS_URL}>
                        <ContentSearchRouteContainer>
                            <PostsPageContainer/>
                        </ContentSearchRouteContainer>
                    </Route>

                    <Route path={HOMEPAGE_MY_BRAND_URL} key={HOMEPAGE_MY_BRAND_URL}>
                        <ContentSearchRouteContainer>
                            <BrandPageContainer/>
                        </ContentSearchRouteContainer>
                    </Route>

                    <Route path={USER_SETTINGS_URL} key={USER_SETTINGS_URL}>
                        <ContentSearchRouteContainer>
                            <UserSettingsSubPageContainer/>
                        </ContentSearchRouteContainer>
                    </Route>

                    <Route path={REFER_A_FRIEND_URL} key={REFER_A_FRIEND_URL}>
                        <ContentSearchRouteContainer>
                            <ReferAFriendPageContainer/>
                        </ContentSearchRouteContainer>
                    </Route>

                    <Route path={MANAGE_SUBSCRIPTION_PAGE_INTERNAL_URL} key={MANAGE_SUBSCRIPTION_PAGE_INTERNAL_URL}>
                        <ContentSearchRouteContainer>
                            <ManageSubscriptionsPage/>
                        </ContentSearchRouteContainer>
                    </Route>

                    <Route path={SUBSCRIPTION_TERMS_URL} key={SUBSCRIPTION_TERMS_URL}>
                        <ContentSearchRouteContainer>
                            <SubscriptionTermsPage isPayingUserOrAcceptedProPreview={this.props.isPayingUserOrAcceptedProPreview}/>
                        </ContentSearchRouteContainer>
                    </Route>

                    <Route path={NEED_HELP_SETTINGS_URL} key={NEED_HELP_SETTINGS_URL}>
                        <ContentSearchRouteContainer>
                            <NeedHelpSubPageContainer/>
                        </ContentSearchRouteContainer>
                    </Route>
                </Switch>
            </Cell>
        </>;
    }

    private forceRenderMobileWall = () =>
    {
        return (
            <Cell size={12}>
                {this.getMobileWallComponent()}
            </Cell>);
    }

    private getMobileWallComponent()
    {
        if ( !this.props.shouldRenderForMobileAds )
        {
            return (<MobileBrowserWall isPayingUser={this.props.isPayingUser} shouldShowCollabraUI={this.props.shouldShowCollabraUI}/>);
        }
        else
        {
            return null;
        }

    }

    private handleTeamInvites( parsedParam )
    {
        const token = parsedParam[TEAM_INVITE_TOKEN_PARAM];
        if ( typeof token === "string" )
        {
            this.props.handleAcceptInvite( token );
        }
    }

    private handleOpenDesignDeepLinks( parsedParam )
    {
        const postId = parsedParam[OPEN_DESIGN_POST_ID_PARAM];
        const designSlug = parsedParam[DESIGN_SLUG_PARAM];

        const isValidDesignSlug = designSlug && typeof designSlug === "string";
        const isValidPostId = postId && typeof postId === "string";

        if ( isValidPostId )
        {
            this.props.handleOpenExamplePostFromDeeplink( postId, designSlug );
        }
        else if ( isValidDesignSlug )
        {
            this.props.handleOpenDesignFromDeeplink( designSlug );
        }
    }

    private handleOpenCollectionDeepLink = ( parsedParam ) =>
    {
        const collectionSlug = parsedParam[COLLECTION_SLUG_KEY];
        if ( collectionSlug )
        {
            this.props.handleOpenCollectionFromDeepLink( collectionSlug );
        }
    }

    private handleOpenContentSearchDeepLink = ( parsedParam ) =>
    {
        const searchTerm = parsedParam[CONTENT_SEARCH_TERM_KEY];
        if ( searchTerm )
        {
            this.props.handleOpenContentSearchFromDeepLink( searchTerm );
        }
    }

    private performHomePageTasks()
    {
        const queryParams = window.location.search;
        const pathName = window.location.pathname;
        if ( this.props.isUserLoggedIn && includes( pathName, HOMEPAGE_URL ) )
        {
            const parsedParam = queryString.parse( queryParams );
            if ( includes( queryParams, OPEN_DESIGN_PARAM ) )
            {
                this.handleOpenDesignDeepLinks( parsedParam );
            }
            else if ( includes( queryParams, ACCEPT_TEAM_INVITE_PARAM ) )
            {
                this.handleTeamInvites( parsedParam );
            }
            else if ( includes( queryParams, MANAGE_SUBSCRIPTION_PARAM ) )
            {
                this.props.handleManageSubscription();
            }
            else if ( includes( queryParams, COLLECTION_ACTION_PARAM ) )
            {
                this.handleOpenCollectionDeepLink( parsedParam );
            }
            else if ( includes( queryParams, CONTENT_SEARCH_ACTION_PARAM ) )
            {
                this.handleOpenContentSearchDeepLink( parsedParam );
            }

            this.props.handleDisplayHomePageAlert();
        }

        this.props.onPageLoaded();
    }
}
