import { connect } from "react-redux";
import { Dispatch } from "redux";
import { store } from "../../../store";
import { getFontAutocompleteData } from "../../../ducks/ui";
import { getFontByDisplayName, getFonts } from "../../../ducks/fonts";
import { uiActions } from "../../../actions";
import { FontData, StoreState } from "../../../_types";
import { FontAutocomplete, FontAutocompleteDispatchProps, FontAutocompleteProps } from "../FontAutocomplete";

const mapStateToProps = ( storeState: StoreState ): FontAutocompleteProps =>
{
    const { filterText, font } = getFontAutocompleteData( storeState );
    return {
        autocompleteValue: filterText,
        selectedFont: font,
        idBaseName: "more_font",
        autoCompleteFonts: getFonts( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): FontAutocompleteDispatchProps =>
{
    return {
        onChange: ( value: string ) =>
        {
            const font: FontData = getFontByDisplayName( store.getState(), value );
            if ( font )
            {
                dispatch( uiActions.updateFontFontAutocomplete( font ) );
            }
            else
            {
                dispatch( uiActions.updateTextFontAutocomplete( value ) );
            }
        },
        onAutocomplete: ( suggestion: string ) =>
        {
            const font = getFontByDisplayName( store.getState(), suggestion );
            dispatch( uiActions.updateFontFontAutocomplete( font ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( FontAutocomplete );
