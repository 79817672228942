import { StoreState } from "../_types";
import { getDisableViaRiplFlag } from "../ducks/user";
import { getCaption, getCustomMessageForCopy } from "../ducks/shareModel";
import { VIA_RIPL_TEXT, VIA_TEXT_PREFIX } from "./";
import { includes } from "lodash";

function shouldAddViaRipl( state: StoreState, caption: string, customMessageForCopy: string )
{
    return (!caption || customMessageForCopy) && !getDisableViaRiplFlag( state );
}

export function getInitialShareCaption( state: StoreState )
{
    const customMessageForCopy = getCustomMessageForCopy( state );
    const caption = customMessageForCopy || getCaption( state );
    if ( shouldAddViaRipl( state, caption, customMessageForCopy ) && !includes( caption, getDefaultViaText() ) )
    {
        return caption + getDefaultViaText();
    }
    return caption;
}

export function getDefaultViaText()
{
    return " " + VIA_TEXT_PREFIX + " " + VIA_RIPL_TEXT;
}
