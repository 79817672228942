import * as React from "react";
import DialogContainer from "react-md/lib/Dialogs/DialogContainer";
import Button from "react-md/lib/Buttons/Button";
import {
    AlertDialogContents,
    ErrorDialogContents,
    FullPageDialogContents,
    LightboxContents,
    LoadingDialogContents,
    LowResErrorDialogContents,
} from "../_types";
import { findIndex, forEach, map, some } from "lodash";
import { LowResErrorDialog } from "./LowResErrorDialog";
import classNames from "classnames";
import { history } from "../helpers";
import ErrorDialogContainer from "./containers/ErrorDialog.container";

export interface ModalDialogsProps
{
    lightboxContents: LightboxContents[];
    errorDialogContents: ErrorDialogContents;
    alertDialogContents: AlertDialogContents;
    fullPageContents: FullPageDialogContents[];
    loadingDialogContent: LoadingDialogContents;
    lowResErrorDialogContents: LowResErrorDialogContents;
    topMostLightbox?: LightboxContents;
}

export interface ModalDialogsDispatchProps
{
    handleCloseLightboxModal( modalIdx: number );
    handleCloseErrorModal();
    handleCloseLowResErrorModal();
    handleCloseAlertModal();
    handleCloseFullpageModal( modalIdx: number );
    handleCloseLoadingDialog();
    handleCloseAllFullpageModal();
}

export class ModalDialogs extends React.PureComponent<ModalDialogsProps & ModalDialogsDispatchProps>
{
    public componentDidMount()
    {
        history.listen( ( location, action ) =>
        {
            if ( this.isFullModalVisible() )
            {
                this.props.handleCloseAllFullpageModal();
            }
        } );
        document.addEventListener( "keydown", this.onKeyDown );
    }

    public componentWillUnmount()
    {
        document.removeEventListener( "keydown", this.onKeyDown );
    }

    public render()
    {
        const { fullPageContents, lightboxContents } = this.props;
        return (
            <div className="modalDialogs">
                {
                    map( fullPageContents, this.createFullpageDialog )
                }
                {
                    map( lightboxContents, this.createLightboxDialog )
                }
                {this.createErrorDialog()}
                {this.createAlertDialog()}
                {this.createLoadingDialog()}
                {this.createLowResErrorDialog()}
            </div>
        );
    }

    private createFullpageDialog = ( fullPageContent: FullPageDialogContents, idx: number ) =>
    {
        const key = "fullpage-" + fullPageContent.identifierForKey;
        return <DialogContainer
            key={key}
            id={key}
            visible={fullPageContent.visible}
            contentClassName={fullPageContent.className}
            title={fullPageContent.title}
            aria-label={fullPageContent.title || "fullpage dialog"}
            fullPage
            focusOnMount={false}
            pageX={fullPageContent.pageX}
            pageY={fullPageContent.pageY}
            onHide={this.generateFullpageCloseHandler( idx )}>
            {fullPageContent.content && React.createElement( fullPageContent.content, { closeDialog: this.generateFullpageCloseHandler( idx ) } )}
        </DialogContainer>;
    };

    private createLightboxDialog = ( lightboxContents: LightboxContents, idx: number ) =>
    {
        const { hideCancel, hideConfirm, hideAlternateAction } = lightboxContents;
        const actions = [];
        let initialFocus;

        if ( !hideCancel )
        {
            initialFocus = "#cancel";
            actions.push( <Button id="cancel" secondary flat className="dialogAlternate outline"
                                  onClick={this.generateLightboxCancelHandler( idx )}>{lightboxContents.cancelLabel}</Button> );
        }

        if ( !hideAlternateAction )
        {
            initialFocus = "#alternate";
            actions.push( <Button id="alternate" primary flat className="dialogAlternate outline"
                                  onClick={this.generateLightboxAlternateHandler( idx )}>{lightboxContents.alternateActionLabel}</Button> );
        }

        if ( !hideConfirm )
        {
            initialFocus = "#confirm";
            actions.push( this.createConfirmButton( idx, lightboxContents ) );
        }

        forEach( lightboxContents.customActions, ( item ) =>
        {
            actions.push( item );
        } );

        const containFocus = lightboxContents.containFocus === undefined || lightboxContents.containFocus === null || lightboxContents.containFocus;
        const focusOnMount = lightboxContents.focusOnMount === undefined || lightboxContents.focusOnMount === null ? false
                                                                                                                   : lightboxContents.focusOnMount;

        const key = "lightbox-" + lightboxContents.identifierForKey;
        return <DialogContainer
            key={key}
            id={key}
            className={lightboxContents.dialogContainerClassName}
            visible={lightboxContents.visible}
            autosizeContent={lightboxContents.autosizeContent}
            contentClassName={this.generateContentClassName( lightboxContents.className, lightboxContents.title, lightboxContents.showCancelX )}
            title={lightboxContents.title}
            aria-label={lightboxContents.title || "lightbox dialog"}
            containFocus={containFocus}
            focusOnMount={focusOnMount}
            width={lightboxContents.width}
            height={lightboxContents.height}
            initialFocus={initialFocus}
            onHide={this.generateLightboxCloseHandler( idx )}
            paddedContent={true}
            actions={actions}
            footerClassName={lightboxContents.footerClassName}
            modal={lightboxContents.modal}>
            {lightboxContents.showCancelX && <Button icon={true}
                                                     className="closeX"
                                                     iconClassName="material-icons"
                                                     iconChildren="close"
                                                     onClick={this.generateLightboxCancelHandler( idx )}/>}
            {lightboxContents.subtitle &&
             (typeof lightboxContents.subtitle === "string" ? <h3>{lightboxContents.subtitle}</h3> :
              React.createElement( lightboxContents.subtitle ))}
            {lightboxContents.content &&
             (typeof lightboxContents.content === "string" ?
              <h4 className={lightboxContents.subtitle ? "afterSubtitle" : ""}>{lightboxContents.content}</h4> :
              React.createElement( lightboxContents.content, { closeDialog: this.generateLightboxCloseHandler( idx ) } ))}
        </DialogContainer>;
    }

    private createConfirmButton( idx: number, lightboxContents: LightboxContents )
    {
        const { styleConfirmButtonAsSecondary, confirmLabel, confirmDisabled } = lightboxContents;
        const isDisabled: boolean = confirmDisabled && confirmDisabled();
        return styleConfirmButtonAsSecondary ? <Button id="confirm" primary flat className="dialogAlternate" disabled={isDisabled}
                                                       onClick={this.generateLightboxSuccessHandler( idx )}>{confirmLabel}</Button>
                                             : <Button id="confirm" primary flat swapTheming className="dialogConfirm" disabled={isDisabled}
                                                       onClick={this.generateLightboxSuccessHandler( idx )}>{confirmLabel}</Button>;
    }

    private generateContentClassName = ( contentClassName: string, title: string, showCancelX: boolean ) =>
    {
        const hasTitle = !!title;
        return classNames( contentClassName, { showsCancelNoTitle: !hasTitle && showCancelX } );
    }

    private createLoadingDialog = () =>
    {
        const { loadingDialogContent } = this.props;
        const actions = [];
        if ( loadingDialogContent.visible )
        {
            return <DialogContainer
                id="loadingDialog"
                className={loadingDialogContent.dialogContainerClassName}
                visible={loadingDialogContent.visible}
                contentClassName={loadingDialogContent.className}
                title={loadingDialogContent.title}
                focusOnMount={false}
                aria-label={loadingDialogContent.title || "loading dialog"}
                onShow={this.handleShowLoadingDialog}
                onHide={this.handleCloseLoadingDialog}
                width={loadingDialogContent.width}
                height={loadingDialogContent.height}
                paddedContent={true}
                actions={actions}>
                {loadingDialogContent.content &&
                 (typeof loadingDialogContent.content === "string" ? loadingDialogContent.content :
                  React.createElement( loadingDialogContent.content, { closeDialog: this.handleCloseLoadingDialog() } ))}
            </DialogContainer>;
        }
        return "";
    }

    private createErrorDialog = () =>
    {
        const { errorDialogContents } = this.props;
        return <DialogContainer
            id="errorDialog"
            visible={errorDialogContents.visible && !!errorDialogContents.errorMessage}
            width={400}
            title={errorDialogContents.errorTitle}
            aria-label="error"
            focusOnMount={false}
            onHide={this.handleCloseErrorDialog}
            contentClassName={this.generateContentClassName( "", errorDialogContents.errorTitle, true )}
            actions={[
                <Button primary flat swapTheming onClick={this.handleCloseErrorDialog}>OK</Button>,
            ]}>
            <Button icon={true}
                    className="closeX"
                    iconClassName="material-icons"
                    iconChildren="close"
                    onClick={this.handleCloseErrorDialog}/>
            <ErrorDialogContainer {...errorDialogContents}/>
        </DialogContainer>;
    }

    private createLowResErrorDialog = () =>
    {
        const { lowResErrorDialogContents } = this.props;
        return <DialogContainer
            id="lowResErrorDialog"
            visible={lowResErrorDialogContents.visible}
            width={420}
            aria-label="error"
            focusOnMount={false}
            footerClassName="lowResDialogFooter"
            onHide={this.props.handleCloseLowResErrorModal}
            stackedActions={false}
            actions={[
                <Button primary swapTheming flat onClick={this.handleChooseDifferentPhoto}>Remove photo</Button>,
            ]}>
            <Button icon={true}
                    className="closeX"
                    iconClassName="material-icons"
                    iconChildren="close"
                    onClick={this.props.handleCloseLowResErrorModal}/>
            <LowResErrorDialog/>
        </DialogContainer>;
    }

    private createAlertDialog = () =>
    {
        const { title, message, width, showCancelX, modal } = this.props.alertDialogContents;
        return <DialogContainer
            id="alertDialog"
            visible={this.props.alertDialogContents.visible}
            title={title}
            aria-label="alert"
            containFocus
            focusOnMount={false}
            onHide={this.props.handleCloseAlertModal}
            contentClassName={this.generateContentClassName( "alertDialogContent", title, showCancelX )}
            modal={modal}
            width={width || 475}
            actions={[
                <Button primary flat swapTheming className="dialogConfirm" onClick={this.props.handleCloseAlertModal}>Ok</Button>,
            ]}>
            {showCancelX && <Button icon={true}
                                    className="closeX"
                                    iconClassName="material-icons"
                                    iconChildren="close"
                                    onClick={this.props.handleCloseAlertModal}/>}
            <h4>{message}</h4>
        </DialogContainer>;
    }

    private generateLightboxSuccessHandler = ( idx: number ) =>
    {
        return () =>
        {
            let dontClose = false;
            const dialog = this.props.lightboxContents[idx];
            const closeFunc = () =>
            {
                this.props.handleCloseLightboxModal( idx );
            };

            if ( dialog && dialog.closeDialogBeforeCallbacks )
            {
                closeFunc();
            }

            if ( !dontClose && dialog && dialog.onSuccess )
            {
                const successResult = dialog.onSuccess( closeFunc ) as boolean;
                dontClose = successResult === false;
            }

            if ( !dontClose && dialog && dialog.onClose )
            {
                const closeResult = dialog.onClose( closeFunc ) as boolean;
                dontClose = closeResult === false;
            }

            if ( !dontClose && dialog && !dialog.closeDialogBeforeCallbacks )
            {
                closeFunc();
            }
        };
    }

    private handleCloseErrorDialog = () =>
    {
        const onBeforeClose = this.props.errorDialogContents.onBeforeClose;
        if ( onBeforeClose )
        {
            onBeforeClose();
        }
        this.props.handleCloseErrorModal();
    }

    private handleCloseLoadingDialog = () =>
    {
        const dialog = this.props.loadingDialogContent;
        if ( dialog.onClose )
        {
            dialog.onClose();
        }
    }

    private handleShowLoadingDialog = () =>
    {
        const dialog = this.props.loadingDialogContent;
        if ( dialog.onShow )
        {
            dialog.onShow();
        }
    }

    private generateLightboxAlternateHandler = ( idx: number ) =>
    {
        return () =>
        {
            const dialog = this.props.lightboxContents[idx];

            if ( dialog && dialog.closeDialogBeforeCallbacks )
            {
                this.props.handleCloseLightboxModal( idx );
            }

            if ( dialog && dialog.onAlternate )
            {
                dialog.onAlternate();
            }
            if ( dialog && dialog.onClose )
            {
                dialog.onClose();
            }

            if ( dialog && !dialog.closeDialogBeforeCallbacks )
            {
                this.props.handleCloseLightboxModal( idx );
            }
        };
    }

    private generateLightboxCloseHandler = ( idx: number ) =>
    {
        return () =>
        {
            const dialog = this.props.lightboxContents[idx];

            if ( dialog && dialog.onClose )
            {
                dialog.onClose();
            }

            this.props.handleCloseLightboxModal( idx );
        };
    }

    private generateLightboxCancelHandler = ( idx: number ) =>
    {
        return () =>
        {
            const dialog = this.props.lightboxContents[idx];

            if ( dialog && dialog.onCancel )
            {
                dialog.onCancel();
            }

            if ( dialog && dialog.onClose )
            {
                dialog.onClose();
            }

            this.props.handleCloseLightboxModal( idx );
        };
    }

    private generateFullpageCloseHandler = ( idx: number ) =>
    {
        return () =>
        {
            const dialog = this.props.fullPageContents[idx];
            if ( dialog && dialog.onClose )
            {
                dialog.onClose();
            }
            this.props.handleCloseFullpageModal( idx );
        };
    }

    private handleChooseDifferentPhoto = () =>
    {
        if ( this.props && this.props.lowResErrorDialogContents && this.props.lowResErrorDialogContents.onReplace )
        {
            this.props.lowResErrorDialogContents.onReplace();
        }
    }

    private isFullModalVisible = (): boolean =>
    {
        return some( this.props.fullPageContents, ( fullPageContent: FullPageDialogContents ) => fullPageContent.visible );
    };

    private onKeyDown = ( event: KeyboardEvent ) =>
    {
        const dialogHasCustomKeyboardShortcuts = this.props.topMostLightbox && this.props.topMostLightbox.enableCustomKeyboardShortcuts
                                                 && this.props.topMostLightbox.onKeyDown;
        if ( dialogHasCustomKeyboardShortcuts )
        {
            const lightboxIdentifier = this.props.topMostLightbox.identifierForKey;
            const idx = findIndex( this.props.lightboxContents,
                ( lightboxContent: LightboxContents ) => lightboxContent.identifierForKey === lightboxIdentifier );
            const lightBoxFound = idx !== -1;
            if ( lightBoxFound )
            {
                const lightboxSuccessHandler = this.generateLightboxSuccessHandler( idx );
                this.props.topMostLightbox.onKeyDown( event, lightboxSuccessHandler );
            }
        }
    }
}
