import * as React from "react";
import { size } from "lodash";
import Button from "react-md/lib/Buttons/Button";
import classNames from "classnames";
import { TEXT_PICKER_MAX_NUMBER_OF_CAPTIONS } from "../../helpers";
import { DesignCaptionData, SortedCaptionsByType } from "../../_types";
import { Positions } from "react-md/lib/Tooltips/injectTooltip";
import CircularProgress from "react-md/lib/Progress/CircularProgress";

export interface AddTextBoxButtonProps
{
    buttonText?: string;
    doesCurrentDesignSupportExtraCaptions: boolean;
    sortedCaptions: SortedCaptionsByType;
    showPlusButton?: boolean;
    tooltipPosition?: Positions;
    designCaptionData: { [id: string]: DesignCaptionData };
}

export interface AddTextBoxButtonDispatchProps
{
    onAddTextBoxClicked: () => void;
}

export class AddTextBoxButton extends React.PureComponent<AddTextBoxButtonProps & AddTextBoxButtonDispatchProps>
{
    public constructor( props )
    {
        super( props );
        this.state = {
            textPropertiesSubPanelCaptionData: null,
        };
    }

    public render()
    {
        const tooltip = this.getTooltip();
        const onClick = this.captionDataIsReady() && this.canAddMoreCaptions() ? this.props.onAddTextBoxClicked : null;
        return <Button primary
                       flat
                       swapTheming
                       onClick={onClick}
                       tooltipLabel={tooltip}
                       tooltipPosition={this.props.tooltipPosition || "top"}
                       tooltipClassName="tooltip"
                       inkDisabled={!this.captionDataIsReady() || !this.canAddMoreCaptions()}
                       className={classNames( "addTextBoxButton fullWidth",
                           {
                               loadingCaptionData: !this.captionDataIsReady(),
                               unableToAddMore: this.captionDataIsReady() && !this.canAddMoreCaptions(),
                               showPlusButton: this.props.showPlusButton,
                           } )}>{this.getButtonContent()}</Button>;
    }

    private getTooltip = () =>
    {
        if ( this.captionDataIsReady() )
        {
            if ( !this.canAddMoreCaptions() )
            {
                if ( !this.props.doesCurrentDesignSupportExtraCaptions )
                {
                    return <div>Template does not yet support this feature</div>;
                }
                return <div>Text box limit reached</div>;
            }
            return null;
        }
        return <div>Waiting for template to finish playing</div>;
    }

    private canAddMoreCaptions = () =>
    {
        const captions = this.props.sortedCaptions;
        const theTotalNumberOfCaptions = size( captions.extraCaptions ) + size( captions.regularCaptions );
        const hasLessThanMaxNumCaptions = theTotalNumberOfCaptions < TEXT_PICKER_MAX_NUMBER_OF_CAPTIONS;
        return this.props.doesCurrentDesignSupportExtraCaptions && hasLessThanMaxNumCaptions;
    }

    private getButtonContent = () =>
    {
        if ( this.captionDataIsReady() )
        {
            const buttonText = this.props.buttonText || "Add text box";

            if ( this.props.showPlusButton )
            {
                return (<><i className="material-icons">add</i><span>{buttonText}</span></>);
            }
            return buttonText;
        }
        return <CircularProgress id="spinner" className="spinner centered white"/>;
    }

    private captionDataIsReady = () =>
    {
        return !!this.props.designCaptionData;
    }
}
