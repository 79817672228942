import * as React from "react";

interface ColorControlProps
{
    value: string;
}

export const ColorControl: React.FC<ColorControlProps> = ( props ) =>
{
    return <div className="internal" style={{ backgroundColor: props.value }}/>;
};
