import * as React from "react";
import { filter, find, forEach, map, xor } from "lodash";
import Button from "react-md/lib/Buttons/Button";
import SurveyQuestionAndAnswers from "./SurveyQuestionAndAnswers";
import { eventTracker } from "../../helpers";
import { Choice, Question, StripeSubscriptionInfoData } from "../../_types";
import { Answer, QuestionResponse, SurveyAPIResponse } from "../../_types/api/survey";
import * as SadOrangeFace from "../../assets/img/sad_orange_face.png";
import CancelWinBackOfferContainer from "../containers/CancelWinBackOffer.container";
import CancelWinBackConfirm from "../containers/CancelWinBackConfirm.container";

export interface CancelSurveyProps
{
    page_id: string;
    questions: Question[];
    isEligibleForWinBackOffer: boolean;
    subscriptionInfo: StripeSubscriptionInfoData;
    isLegacyTier: boolean;
}

export interface CancelSurveyDispatchProps
{
    cancelStripeSubscription: ( stripeCustomerId: string, surveyResponse: SurveyAPIResponse,
                                eventTrackerSurveyDataArray: EventTrackerSurveyReportingData[] ) => void;
    handleCancelSurveyBackTapped();
    loadSurvey();
    loadCancelOffer();
    clearCoupon();
}

interface CancelSurveyState
{
    responses: QuestionResponse[];
    showWinBackOffer: boolean;
    showExtraConfirmation: boolean;
}

export interface EventTrackerSurveyReportingData
{
    questionText: string;
    choiceText: string;
    otherText?: string;
}

export default class CancelSurvey extends React.PureComponent<CancelSurveyProps & CancelSurveyDispatchProps, CancelSurveyState>
{
    constructor( props )
    {
        super( props );
        this.state = {
            responses: [],
            showWinBackOffer: false,
            showExtraConfirmation: false,
        };
    }

    public componentDidMount()
    {
        eventTracker.logCancelSubscriptionSurveyDisplayed();
        this.props.loadSurvey();
        this.props.loadCancelOffer();
    }

    public componentWillUnmount()
    {
        this.props.clearCoupon();
    }

    public render()
    {
        return (
            <>
                {this.showConfirmCancel() && <CancelWinBackConfirm
                    requestCancel={this.handleProceedToCancellation}
                    requestKeepSubscription={this.props.handleCancelSurveyBackTapped}
                />}
                {this.showWinBackOffer() && <CancelWinBackOfferContainer
                    handleProceedToCancellation={this.handleProceedToCancellation}
                    subscriptionInfo={this.props.subscriptionInfo}
                />}

                {!this.showWinBackOffer() && !this.showConfirmCancel() &&
                 <div className="cancelSurvey">
                     <div className="manageSubscriptionBlock">
                         <h2>We're sorry to see you&nbsp;go <img className="sad-face" src={SadOrangeFace} alt="sad face"/></h2>
                         {
                             map( this.props.questions, this.getControlsForQuestion )
                         }

                         <div className="buttonWrap">
                             <Button flat className="cancelButton" onClick={this.props.handleCancelSurveyBackTapped}>Back</Button>
                             <Button flat
                                     primary
                                     swapTheming
                                     className="primaryButton"
                                     disabled={!this.surveyIsComplete()}
                                     onClick={this.handleContinueToCancelClicked}>Continue to cancel</Button>
                         </div>
                     </div>
                     <p className="cancelFinePrint">When you cancel, you will have access to Ripl until the end of this subscription period.</p>
                 </div>}
            </>);
    }

    private showConfirmCancel = () =>
    {
        return this.state.showExtraConfirmation && !this.showWinBackOffer();
    }

    private showWinBackOffer()
    {
        return this.state.showWinBackOffer && this.props.isEligibleForWinBackOffer;
    }

    private getControlsForQuestion = ( question ) =>
    {
        return (<SurveyQuestionAndAnswers key={question.id} question={question} handleSelection={this.handleSelection}/>);
    }

    private handleSelection = ( questionId, answers: Answer[] ) =>
    {
        const response: QuestionResponse = { id: questionId, answers };
        const newResponses = this.replaceExistingResponse( response );
        this.setState( { responses: newResponses } );
    }

    private surveyIsComplete = () =>
    {
        const questionIds = map( this.props.questions, ( question ) => question.id );
        const responseQuestionIds = map( this.state.responses, ( response ) => response.id );

        const otherResponsesWithoutText = filter( this.state.responses, ( response ) =>
        {
            const answersMissingOtherText = filter( response.answers, ( answer ) =>
            {
                return answer.other_id && !answer.text;
            } );
            return answersMissingOtherText.length > 0;
        } );
        return (questionIds.length === responseQuestionIds.length) &&
               xor( questionIds, responseQuestionIds ).length === 0 &&
               (otherResponsesWithoutText.length === 0);
    }

    private replaceExistingResponse = ( responseToReplace: QuestionResponse ) =>
    {
        const newResponses = filter( this.state.responses, ( currentResponses ) => currentResponses.id !== responseToReplace.id );
        newResponses.push( responseToReplace );
        return newResponses;
    }

    private handleContinueToCancelClicked = () =>
    {
        if ( this.props.isEligibleForWinBackOffer )
        {
            this.setState( { showWinBackOffer: true } );
        }
        else
        {
            this.handleProceedToCancellation();
        }
    }

    private handleProceedToCancellation = () =>
    {
        if ( this.props.isLegacyTier && !this.showConfirmCancel() )
        {
            this.setState( { showExtraConfirmation: true, showWinBackOffer: false } );
        }
        else
        {
            const eventTrackerSurveyDataArray: EventTrackerSurveyReportingData[] = this.gatherReportingData();
            const response: SurveyAPIResponse = { page_id: this.props.page_id, responses: this.state.responses };

            this.props.cancelStripeSubscription( this.props.subscriptionInfo.stripe_customer_id, response, eventTrackerSurveyDataArray );
        }
    }

    private gatherReportingData = () =>
    {
        const eventTrackerSurveyReportingDataArray: EventTrackerSurveyReportingData[] = [];

        forEach( this.state.responses, ( response: QuestionResponse ) =>
        {
            const question: Question = find( this.props.questions, { id: response.id } );
            this.addAnswersToEventTrackerReportingData( response, question, eventTrackerSurveyReportingDataArray );
        } );

        return eventTrackerSurveyReportingDataArray;
    }

    private addAnswersToEventTrackerReportingData( response: QuestionResponse, question: Question,
                                                   surveyReportingData: EventTrackerSurveyReportingData[] )
    {
        forEach( response.answers, ( answer ) =>
        {
            let choiceText: string = "";
            let otherText = "";
            if ( answer.choice_id )
            {
                choiceText = this.findChoiceTextById( question, answer.choice_id );
            }

            if ( answer.other_id )
            {
                choiceText = this.findOtherText( question );
                otherText = answer.text;
            }

            surveyReportingData.push( { questionText: question.headings[0].heading, choiceText, otherText } );
        } );
    }

    private findChoiceTextById = ( question: Question, choiceId: string ) =>
    {
        const choices: Choice[] = question.answers.choices;
        const choice = find( choices, { id: choiceId } );
        return choice.text;
    }

    private findOtherText = ( question: Question ) =>
    {
        return question.answers.other.text;
    }
}
