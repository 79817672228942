import * as React from "react";
import Avatar from "react-md/lib/Avatars/Avatar";
import { ALL_SOCIAL_NETWORK_LABELS_FOR_TYPES, SOCIAL_NETWORK_CONNECTED_ICONS, stringUtils } from "../helpers";
import { SocialNetworkAccount } from "../_types";
import classNames from "classnames";

export interface PostDetailsOutputListItemProps
{
    account: SocialNetworkAccount;
}

export class PostDetailsOutputListItem extends React.PureComponent<PostDetailsOutputListItemProps>
{
    public render()
    {
        return (
            <div className="socialAccountListItem">
                {this.makeAccountIcon()}
            </div>
        );
    }

    private makeAccountIcon = () =>
    {
        const accountType = this.props.account.account_type;
        const connectedOutputIcon = SOCIAL_NETWORK_CONNECTED_ICONS[accountType];
        const accountName = this.props.account.name;
        let profileImageUrl = this.props.account.profile_image_url;
        if ( profileImageUrl )
        {
            profileImageUrl = stringUtils.changeHttpUrlToHttps( profileImageUrl );
        }
        const displayNameAndNetwork = accountName + " (" + ALL_SOCIAL_NETWORK_LABELS_FOR_TYPES[accountType] + ")";
        return (
            <div className="accountIcon">
                <Avatar src={profileImageUrl} role="presentation" alt={displayNameAndNetwork}/>
                <img src={connectedOutputIcon} className="network"/>
                <div className={classNames( "socialNetworkFullNameHint", "hoverableHintWhiteOnSlate" )}>{displayNameAndNetwork}</div>
            </div>

        );
    }
}
