import { connect } from "react-redux";
import { Dispatch } from "redux";
import { MoreFontsDialog, MoreFontsDialogDispatchProps, MoreFontsDialogProps } from "../MoreFontsDialog";
import { uiActions } from "../../../actions";
import { getFontByDisplayName, getFonts, getMoreFontSelected } from "../../../ducks/fonts";
import { FontData, StoreState } from "../../../_types";

export const MORE_FONTS_DIALOG_CLASS_NAME = "moreFontsDialog";
export const MORE_FONTS_DIALOG_FOOTER_CLASS_NAME = "moreFontsDialogFooter";

const mapStateToProps = ( storeState: StoreState ): MoreFontsDialogProps =>
{
    const currentFontSelectionText = getMoreFontSelected( storeState );
    return {
        fonts: getFonts( storeState ),
        currentFont: getFontByDisplayName( storeState, currentFontSelectionText ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): MoreFontsDialogDispatchProps =>
{
    return {
        clearBusinessInfo()
        {
            dispatch( uiActions.clearBusinessInfo() );
        },
        updateFontSelection: ( font: FontData ) =>
        {
            dispatch( uiActions.updateFontFontAutocomplete( font ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( MoreFontsDialog );
