import * as React from "react";
import UsersTabTable from "./containers/UsersTabTable.container";
import { UsersTabInviteButton } from "./UsersTabInviteButton";
import { RemoveSelfButton } from "./RemoveSelfButton";
import { USERS_TAB_TEAM_DESCRIPTION_FOR } from "../../../helpers";

export interface UsersTabProps
{
    isTeamAdmin: boolean;
    memberCount: number;
    maySendMoreInvitations: boolean;
}

export interface UsersTabDispatchProps
{
    handleAddUsersClicked();
    handleRemoveSelfClicked();
}

export function UsersTab( props: UsersTabProps & UsersTabDispatchProps )
{
    const { isTeamAdmin, memberCount, maySendMoreInvitations } = props;
    const description = isTeamAdmin ? USERS_TAB_TEAM_DESCRIPTION_FOR.admin : USERS_TAB_TEAM_DESCRIPTION_FOR.member;

    return <div className="subNavContent brandTabContent">
        <div className="usersTab">
            <div className="usersTabContents">
                <div className="usersTabHeader">
                    <div className="usersTabHeadingWrap">
                        <h2 className="usersTabHeading">
                            Team members <div className="userCountCircle">{memberCount}</div>
                        </h2>
                        <div className="usersTabDescription">
                            {description}
                        </div>
                    </div>
                    {isTeamAdmin && <UsersTabInviteButton onClick={props.handleAddUsersClicked} disabled={!maySendMoreInvitations}/>}
                    {!isTeamAdmin && <RemoveSelfButton onClick={props.handleRemoveSelfClicked}/>}
                </div>

                <div className="usersTableAndButton">
                    <UsersTabTable/>
                </div>
            </div>
        </div>
    </div>;
}
