import * as React from "react";
import Button from "react-md/lib/Buttons/Button";
import { StylizedTextChoiceButton } from "./StylizedTextChoiceButton";
import { DesignElementProperties, StylizedTextChoices, TextPickerCaptionData } from "../../_types";
import { map } from "lodash";
import CircularProgress from "react-md/lib/Progress/CircularProgress";
import { scrollIntoViewIfNotAlreadyVisible } from "../../helpers";

export interface StylizedTextChoicePanelContainerProps
{
    onStylizedTextBackClicked?();
}

export interface StylizedTextChoicePanelProps extends StylizedTextChoicePanelContainerProps
{
    captionData: TextPickerCaptionData;
    designElementProperties: DesignElementProperties;
    stylizedTextChoices: StylizedTextChoices;
}

export interface StylizedTextChoicePanelDispatchProps
{
    tellDesignToGetTextStyles( captionId: string );
    tellDesignToStyleTextItem( captionId: string, styleId: string );
    clearStylizedTextButtonCoachMark();
    requestTextProperties( captionId: string );
}

export class StylizedTextChoicePanel extends React.PureComponent<StylizedTextChoicePanelProps & StylizedTextChoicePanelDispatchProps>
{
    private stylizedTextButtonRefs: { [id: string]: React.RefObject<HTMLDivElement> };

    public constructor( props )
    {
        super( props );
        this.stylizedTextButtonRefs = {};
    }

    public componentDidMount()
    {
        if ( this.props.captionData )
        {
            this.props.tellDesignToGetTextStyles( this.props.captionData.id );
            this.scrollToStyleId();
        }
    }

    public render()
    {
        let contents = <CircularProgress id="spinner" className="spinner large centered"/>;
        const currentStyleId = this.getCurrentStyleId();
        if ( this.props.captionData && this.props.stylizedTextChoices && this.props.stylizedTextChoices.captionId === this.props.captionData.id
             && currentStyleId )
        {
            contents = <div className="stylizedTextChoicePanel">
                {map( this.props.stylizedTextChoices.styles, ( style ) =>
                {
                    const ref = React.createRef<HTMLDivElement>();
                    this.stylizedTextButtonRefs[style.styleId] = ref;
                    return <StylizedTextChoiceButton outerRef={ref}
                                                     styleId={style.styleId}
                                                     status={style.status}
                                                     relativeImageUrl={style.relativeImageUrl}
                                                     selected={style.styleId === currentStyleId}
                                                     onClick={this.onStylizeTextItemClicked}
                                                     key={style.styleId}/>;
                } )
                }
            </div>;
        }

        return (
            <>
                {this.props.onStylizedTextBackClicked && <Button flat
                                                                 secondary
                                                                 className="backToText secondary outline"
                                                                 iconChildren="arrow_back_ios"
                                                                 onClick={this.onStylizedTextBackClicked}>Back to Text</Button>}
                {contents}
            </>
        );

    }

    private onStylizeTextItemClicked = ( styleId: string ) =>
    {
        this.props.tellDesignToStyleTextItem( this.props.captionData.id, styleId );
    }

    private scrollToStyleId()
    {
        setTimeout( () =>
        {
            const styleId = this.getCurrentStyleId();

            if ( styleId )
            {
                const element = this.stylizedTextButtonRefs[styleId] && this.stylizedTextButtonRefs[styleId].current;
                scrollIntoViewIfNotAlreadyVisible( element );
            }
        }, 200 );
    }

    private onStylizedTextBackClicked = () =>
    {
        this.props.onStylizedTextBackClicked();
    }

    private getCurrentStyleId = (): string =>
    {
        return this.props.designElementProperties && this.props.designElementProperties.styleId;
    }
}
