import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../_types";
import { areStripePlansLoading } from "../../ducks/ui";
import { TiersExplanationOfBenefits, TiersExplanationOfBenefitsDispatchProps, TiersExplanationOfBenefitsProps } from "../TiersExplanationOfBenefits";
import { getBaseAnnualWithTrialPlan, getPremiumAnnualWithTrialPlan, getSelectedTier, getUSDPricePerMonth } from "../../ducks/pricing";
import { RIPL_TIER_BASE, RIPL_TIER_PREMIUM, TIER_BASE_SUPPORTED_FEATURES, TIER_PREMIUM_SUPPORTED_FEATURES } from "../../helpers";
import { pricingActions } from "../../actions";

interface TiersExplanationOfBenefitsPropsContainerDispatchProps
{
    selectTier( tier: ProductTier ): void;
}

const mapStateToProps = ( storeState: StoreState ): TiersExplanationOfBenefitsProps =>
{
    return {
        isLoading: areStripePlansLoading( storeState ),
        tierTabs: [
            {
                tier: RIPL_TIER_BASE,
                tierName: "Base",
                priceFormatted: getUSDPricePerMonth( getBaseAnnualWithTrialPlan( storeState ) ),
                commissionRateFormatted: "25%",
                features: TIER_BASE_SUPPORTED_FEATURES,
            },
            {
                tier: RIPL_TIER_PREMIUM,
                tierName: "Premium",
                priceFormatted: getUSDPricePerMonth( getPremiumAnnualWithTrialPlan( storeState ) ),
                commissionRateFormatted: "0%",
                features: TIER_PREMIUM_SUPPORTED_FEATURES,
            },
        ],
        selectedTab: getSelectedTier( storeState ) || RIPL_TIER_BASE,
    };
};

const mapDispatchToProps = (
    dispatch: Dispatch<StoreState>,
    ownProps: TiersExplanationOfBenefitsPropsContainerDispatchProps,
): TiersExplanationOfBenefitsDispatchProps =>
{
    return {
        selectTier: ownProps.selectTier,
        selectTab( tier: ProductTier )
        {
            dispatch( pricingActions.tierSelected( tier ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( TiersExplanationOfBenefits );
