import { ActionCreator } from "../helpers";
import { createAction } from "redux-actions";
import { BackgroundMediaAPIData, DesignFramesAPIData } from "../_types/api";

const actionCreator = new ActionCreator( "FRAME_MEDIA" );

const dynamicDesignDataActionTypes = {
    designFrames: {
        load: actionCreator.generateType( "DESIGN_FRAMES", "LOAD" ),
    },
    backgroundMedia: {
        load: actionCreator.generateType( "BACKGROUND_MEDIA", "LOAD" ),
    },
};

export const dynamicDesignDataActions = {
    designFramesLoadSuccess: createAction<DesignFramesAPIData>( dynamicDesignDataActionTypes.designFrames.load ),
    backgroundMediaLoadSuccess: createAction<BackgroundMediaAPIData>( dynamicDesignDataActionTypes.backgroundMedia.load ),
};
