import * as React from "react";
import { includes, map } from "lodash";
import { DesignZoomData } from "../_types";
import { ZoomControlSlider } from "./ZoomControlSlider";

export interface ZoomControlProps
{
    designZoomData: { [id: string]: DesignZoomData };
    activeZoomControl: string [];
}

export interface ZoomControlDispatchProps
{
    onValueChange: ( value, id ) => void;
    onAfterValueChange: ( value, id ) => void;
}

export class ZoomControl extends React.PureComponent<ZoomControlProps & ZoomControlDispatchProps>
{
    public render()
    {
        return (
            map( this.props.designZoomData, ( zoom ) =>
            {
                if ( includes( this.props.activeZoomControl, zoom.id ) )
                {
                    return <ZoomControlSlider key={zoom.id} zoom={zoom}
                                              onValueChange={this.props.onValueChange}
                                              onAfterValueChange={this.props.onAfterValueChange}/>;
                }
            } )
        );
    }
}
