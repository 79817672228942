import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState, VideoTrimmerCurrentData } from "../../_types";
import { VideoTrimmerDispatchProps, VideoTrimmerProps, VideoTrimmerWrapper } from "../VideoTrimmer";
import { mixModelActions } from "../../actions";
import { getOriginalTrimmerDataForRetrim, getVideoOriginalFileName, getVideoTrimmerCurrentData } from "../../ducks/mixModel";
import {
    getActiveDesignMediaObjectExclusionFilter,
    getAvailableTimeExcludingMediaInSeconds,
    shouldShowMixedMediaMessage,
} from "../../helpers";

const mapStateToProps = ( storeState: StoreState ): VideoTrimmerProps =>
{
    const originalTrimmerData = getOriginalTrimmerDataForRetrim( storeState );
    const videoTrimmerCurrentData = getVideoTrimmerCurrentData( storeState );
    const videoUrl = originalTrimmerData.url || videoTrimmerCurrentData.videoUrl;
    const mediaFilter = getActiveDesignMediaObjectExclusionFilter( storeState );
    const videoFileName = getVideoOriginalFileName( storeState );

    return {
        videoUrl,
        trimmerTimeData: originalTrimmerData.time,
        availableTimeExcludingMediaInSeconds: getAvailableTimeExcludingMediaInSeconds( storeState, videoFileName, mediaFilter ),
        shouldShowMixedMediaMessage: shouldShowMixedMediaMessage( storeState, videoFileName, mediaFilter ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): VideoTrimmerDispatchProps =>
{
    return {
        handleVideoTrimmerStatusChange: ( videoTrimmerCurrentData: VideoTrimmerCurrentData ) =>
        {
            dispatch( mixModelActions.videoTrimmerStatusChanged( videoTrimmerCurrentData ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( VideoTrimmerWrapper );
