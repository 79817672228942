import * as React from "react";
import DesignControlLogoSwitcherContainer from "./containers/DesignControlLogoSwitcher.container";

export class DesignControlLogoPicker extends React.PureComponent<{}>
{
    public render()
    {
        return (
            <div className="designControlLogoPicker">
                <DesignControlLogoSwitcherContainer/>
            </div>
        );
    }
}
