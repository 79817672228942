import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../_types";
import { ArtBoardControl, ArtBoardControlDispatchProps, ArtBoardControlProps } from "../ArtBoardControl";
import { store } from "../../store";
import {
    getActiveDesignMediaObject,
    getCustomizableCanvas,
    getDesignCurrentSlideIndex,
    getDesignTotalNumberOfSlides,
    getEditorType,
    isBrandSlideTabSelected,
} from "../../ducks/ui";
import { mixModelActions, uiActions } from "../../actions";
import { EDITOR_TYPES, eventTracker } from "../../helpers";
import { OUTPUT_MODE_ANIMATED, OUTPUT_MODE_STATIC } from "../../_types/api";
import { isStaticOutputMode } from "../../ducks/mixModel";
import { getCurrentMusic } from "../../ducks/musicCatalog";
import { userBusinessServices } from "../../services";
import { CustomizableCanvas } from "../../helpers/customizableCanvas";

const mapStateToProps = ( storeState: StoreState ): ArtBoardControlProps =>
{
    return {
        currentSlideIndex: getDesignCurrentSlideIndex( storeState ) + 1,
        totalNumberOfSlides: getDesignTotalNumberOfSlides( storeState ),
        isAnimatedOutputMode: !isStaticOutputMode( storeState ),
        isBrandSlideTabSelected: isBrandSlideTabSelected( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): ArtBoardControlDispatchProps =>
{
    return {
        onShowPreviousSlideClicked: () =>
        {
            const state = store.getState();
            const canvas = getCustomizableCanvas( state );
            eventTracker.logPaginationButtonClicked();

            if ( getActiveDesignMediaObject( state ) )
            {
                canvas.tellDesignToShowPreviousSlide();
                CustomizableCanvas.requestMediaObjectsForActivePage();
            }
            else
            {
                canvas.tellDesignToShowPreviousSlide();
            }
        },
        onShowNextSlideClicked: () =>
        {
            const state = store.getState();
            const canvas = getCustomizableCanvas( state );
            eventTracker.logPaginationButtonClicked();

            if ( getActiveDesignMediaObject( state ) )
            {
                canvas.tellDesignToShowNextSlide();
                CustomizableCanvas.requestMediaObjectsForActivePage();
            }
            else
            {
                canvas.tellDesignToShowNextSlide();
            }
        },
        previewDesign: () =>
        {
            eventTracker.logPlayButtonClicked();
            dispatch( uiActions.clearAudioPlayerMusicTrack() );
            dispatch( uiActions.designCanvasRefreshed() );

            const editorType = getEditorType( store.getState() );
            if ( editorType === EDITOR_TYPES.MEDIA_CONTROL || editorType === EDITOR_TYPES.TEXT_CONTROL )
            {
                dispatch( uiActions.deselectAllControls() );
            }
            dispatch( uiActions.replaceMediaModeEnded() );
        },
        outputModeChanged: () =>
        {
            const state = store.getState();
            if ( isStaticOutputMode( state ) )
            {
                const theDesignOutputType = OUTPUT_MODE_ANIMATED;
                eventTracker.logOutputToggleButtonTapped( theDesignOutputType );
                dispatch( mixModelActions.designOutputModeSet( theDesignOutputType ) );
                dispatch( uiActions.clearAudioPlayerMusicTrack() );

                const currentMusic = getCurrentMusic( state );
                dispatch( uiActions.setAudioPlayerMusicTrack( currentMusic ) );

                const canvas = getCustomizableCanvas( state );
                canvas.tellDesignToReloadDesignWithAnimation();
            }
            else
            {
                const theDesignOutputType = OUTPUT_MODE_STATIC;
                eventTracker.logOutputToggleButtonTapped( theDesignOutputType );
                dispatch( mixModelActions.designOutputModeSet( theDesignOutputType ) );
                dispatch( uiActions.clearAudioPlayerMusicTrack() );
                dispatch( uiActions.deselectAnimationControls() );
                const canvas = getCustomizableCanvas( state );
                canvas.tellDesignToReloadDesignWithoutAnimation();
            }
        },
        onClickExitBrandSlideView: () =>
        {
            dispatch( uiActions.controlEditorSwitched( { editorType: EDITOR_TYPES.TEMPLATES_CONTROL } ) );
            dispatch( userBusinessServices.updateBrandSlide() );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( ArtBoardControl );
