import { StoreState } from "../../_types";
import { connect } from "react-redux";
import { CardFormError, CardFormErrorProps } from "../CardFormError";
import { getPaymentFormError } from "../../ducks/ui";

const mapStateToProps = ( storeState: StoreState ): CardFormErrorProps =>
{
    return {
        formError: getPaymentFormError( storeState ),
    };
};

export default connect(
    mapStateToProps,
)( CardFormError );
