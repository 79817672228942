import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../_types";
import { BrandSettingsDialog, BrandSettingsDialogDispatchProps, BrandSettingsDialogProps } from "../BrandSettingsDialog";
import { logoServices, userBusinessServices, userServices } from "../../services";
import {
    BRAND_SETTINGS_DIALOG,
    BRAND_TYPE_COLOR,
    BRAND_TYPE_FONT,
    BUSINESS_PROFILE_PAGE,
    eventTracker,
    generateFontDataForFontNameOrCssUrl,
    getFontCssUrlFieldForBrand,
    getFontFieldForBrand,
    getFontUrlFieldForBrand,
} from "../../helpers";
import { getBusinessTempLogo, getEditedBusinessInfo } from "../../ducks/ui";
import { store } from "../../store";
import { getCurrentBusiness } from "../../ducks/userBusiness";
import { DesignControlsJSONData, mixModelActions, modalActions, uiActions } from "../../actions";
import { getControlIdForBrandControl, getDesignControlsConfig, getSelectedDesign } from "../../ducks/mixModel";
import {
    Brand,
    BRAND_SLIDE_COLOR_ONE,
    BRAND_SLIDE_COLOR_TWO,
    BRAND_SLIDE_FONT_ONE_CSS_URL_FIELD,
    BRAND_SLIDE_FONT_ONE_FIELD,
    BRAND_SLIDE_FONT_ONE_URL_FIELD,
    BRAND_SLIDE_FONT_TWO_CSS_URL_FIELD,
    BRAND_SLIDE_FONT_TWO_FIELD,
    BRAND_SLIDE_FONT_TWO_URL_FIELD,
    MIX_MODEL_BRAND_SLIDE_COLOR_ONE,
    MIX_MODEL_BRAND_SLIDE_COLOR_TWO,
    MIX_MODEL_BRAND_SLIDE_FONT_1,
    MIX_MODEL_BRAND_SLIDE_FONT_2,
    MIX_MODEL_BRAND_SLIDE_FONT_CSS_URL_1,
    MIX_MODEL_BRAND_SLIDE_FONT_CSS_URL_2,
    PRIMARY,
    SECONDARY,
} from "../../_types/api";
import { forEach, keys } from "lodash";
import { getControlsConfigsByDesignId } from "../../ducks/designs";

const mapStateToProps = ( storeState: StoreState, ownProps: BrandSettingsDialogProps ): BrandSettingsDialogProps =>
{
    return {
        ...ownProps,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): BrandSettingsDialogDispatchProps =>
{
    return {
        clearEditedBusinessInfo()
        {
            dispatch( uiActions.clearBusinessInfo() );
        },
        async handleApplyChanges()
        {
            eventTracker.logBrandSettingsApplyChangesButtonClicked();
            const state = store.getState();
            await handleLogoUpdate( state, dispatch );
            await handleBrandColorUpdate( state, dispatch );
            await handleBrandFontUpdate( state, dispatch );
            await dispatch( modalActions.lightboxDialogClosedAll() );
        },
    };
};

function handleLogoUpdate( state, dispatch: Dispatch<StoreState> )
{
    const businessTempLogo = getBusinessTempLogo( state );
    const promises = [];
    if ( businessTempLogo )
    {
        promises.push( dispatch( userServices.updatePhoto( businessTempLogo, BUSINESS_PROFILE_PAGE ) ) );
        eventTracker.logLogoUploadSucceeded( BRAND_SETTINGS_DIALOG );
    }

    const shouldShowLogoFlag = getEditedBusinessInfo( state ).show_business_logo_flag;
    if ( shouldShowLogoFlag !== undefined )
    {
        promises.push( dispatch( logoServices.setEnabled( shouldShowLogoFlag ) ) );
        if ( shouldShowLogoFlag )
        {
            dispatch( mixModelActions.watermarkEnabled() );
        }
        else
        {
            dispatch( mixModelActions.watermarkRemoved() );
        }
    }
    return Promise.all( promises );
}

function updateCurrentDesignWithBrandSelection( state, dispatch: Dispatch<StoreState>, brandType, newValue, brand: Brand )
{
    const controlConfig = getDesignControlsConfig( state, getSelectedDesign( state ) );
    const controlId = getControlIdForBrandControl( controlConfig.controls, brandType, brand );

    if ( controlId && newValue )
    {
        dispatch( mixModelActions.controlDataUpdated( { [controlId]: newValue } ) );
    }
}

async function updateBrandColor( state, dispatch: Dispatch<StoreState>, brand: Brand, brandSlideColorKey: string, mixModelBrandSlideKey: string )
{
    const brandColor = brand + "_color";
    const currentBusiness = getCurrentBusiness( state );
    const initialColorValue = currentBusiness[brandColor];
    const newColorValue = getEditedBusinessInfo( state )[brandColor];

    if ( newColorValue !== initialColorValue )
    {
        const newBrandSlideColor = !!currentBusiness[brandSlideColorKey] ? currentBusiness[brandSlideColorKey] : newColorValue;
        const newColorDataForBusinessUpdate = {
            [brandColor]: newColorValue,
            [brandSlideColorKey]: newBrandSlideColor,
        };

        await dispatch( userBusinessServices.update( newColorDataForBusinessUpdate ) );
        dispatch( mixModelActions.brandSlideDataUpdated( { endCardData: { [mixModelBrandSlideKey]: newBrandSlideColor } } ) );
        updateCurrentDesignWithBrandSelection( state, dispatch, BRAND_TYPE_COLOR, newColorValue, brand );
        updateAllDesignControlData( state, dispatch );
        eventTracker.logBrandSettingsColorApplied( BRAND_SETTINGS_DIALOG, brand );
    }
}

async function handleBrandColorUpdate( state, dispatch: Dispatch<StoreState> )
{
    await updateBrandColor( state, dispatch, PRIMARY, BRAND_SLIDE_COLOR_ONE, MIX_MODEL_BRAND_SLIDE_COLOR_ONE );
    await updateBrandColor( state, dispatch, SECONDARY, BRAND_SLIDE_COLOR_TWO, MIX_MODEL_BRAND_SLIDE_COLOR_TWO );
}

async function handleBrandFontUpdate( state, dispatch: Dispatch<StoreState> )
{
    await updateBrandFont( state, dispatch, PRIMARY, BRAND_SLIDE_FONT_ONE_FIELD, BRAND_SLIDE_FONT_ONE_URL_FIELD,
        BRAND_SLIDE_FONT_ONE_CSS_URL_FIELD,
        MIX_MODEL_BRAND_SLIDE_FONT_1, MIX_MODEL_BRAND_SLIDE_FONT_CSS_URL_1 );
    await updateBrandFont( state, dispatch, SECONDARY, BRAND_SLIDE_FONT_TWO_FIELD, BRAND_SLIDE_FONT_TWO_URL_FIELD,
        BRAND_SLIDE_FONT_TWO_CSS_URL_FIELD,
        MIX_MODEL_BRAND_SLIDE_FONT_2, MIX_MODEL_BRAND_SLIDE_FONT_CSS_URL_2 );
}

async function updateBrandFont( state, dispatch: Dispatch<StoreState>, brand: Brand, brandSlideFontKey: string, brandSlideFontUrlKey: string,
                                brandSlideFontCssUrlKey: string, mixModelBrandSlideFontKey: string, mixModelBrandSlideFontCssUrlKey: string )
{
    const brandFontField = getFontFieldForBrand( brand );
    const brandFontUrlField = getFontUrlFieldForBrand( brand );
    const brandFontCssUrlField = getFontCssUrlFieldForBrand( brand );

    const currentBusiness = getCurrentBusiness( state );
    const editedBusiness = getEditedBusinessInfo( state );

    const initialFontName = currentBusiness[brandFontField];
    const newFontName = editedBusiness[brandFontField];
    const newFontUrl = editedBusiness[brandFontUrlField];
    const newFontCssUrl = editedBusiness[brandFontCssUrlField];

    if ( newFontName !== initialFontName )
    {
        const hasExistingBrandSlideFontSet = !!currentBusiness[brandSlideFontKey];
        const newBrandSlideFontName = hasExistingBrandSlideFontSet ? currentBusiness[brandSlideFontKey] : newFontName;
        const newBrandSlideFontUrl = hasExistingBrandSlideFontSet ? currentBusiness[brandSlideFontUrlKey] : newFontUrl;
        const newBrandSlideFontCssUrl = hasExistingBrandSlideFontSet ? currentBusiness[brandSlideFontCssUrlKey] : newFontCssUrl;

        const brandSlideFont = {
            [brandSlideFontKey]: newBrandSlideFontName,
            [brandSlideFontUrlKey]: newBrandSlideFontUrl,
        };

        const newFontDataForBusinessUpdate = {
            [brandFontField]: newFontName,
            [brandFontUrlField]: newFontUrl,
            [brandFontCssUrlField]: newFontCssUrl,
            ...brandSlideFont,
        };
        await dispatch( userBusinessServices.update( newFontDataForBusinessUpdate ) );

        const newFontData = generateFontDataForFontNameOrCssUrl( newFontName, newFontCssUrl );
        updateCurrentDesignWithBrandSelection( state, dispatch, BRAND_TYPE_FONT, newFontData, brand );
        updateAllDesignControlData( state, dispatch );
        updateMixModelBrandSlideFontData( dispatch, mixModelBrandSlideFontKey, newBrandSlideFontName, mixModelBrandSlideFontCssUrlKey,
            newBrandSlideFontCssUrl );
        eventTracker.logBrandSettingsFontApplied( BRAND_SETTINGS_DIALOG, brand );
    }
}

function updateMixModelBrandSlideFontData( dispatch: Dispatch<StoreState>,
                                           mixModelBrandSlideKey: string,
                                           newBrandSlideFontName,
                                           mixModelBrandSlideCssUrlKey: string,
                                           newMixModelBrandSlideFontCssUrl: string )
{
    dispatch( mixModelActions.brandSlideDataUpdated(
        { endCardData: { [mixModelBrandSlideKey]: newBrandSlideFontName, [mixModelBrandSlideCssUrlKey]: newMixModelBrandSlideFontCssUrl } } ) );
}

function updateAllDesignControlData( state, dispatch: Dispatch<StoreState> )
{
    forEach( keys( state.mixModel.controlDataByDesignId ), ( designId ) =>
    {
        const currentBusiness = getCurrentBusiness( store.getState() );
        const controls = getControlsConfigsByDesignId( state, parseInt( designId, 10 ) );
        const designControlsData = {
            controls,
            designId,
            currentBusiness,
        } as DesignControlsJSONData;
        dispatch( mixModelActions.brandSettingsUpdated( designControlsData ) );
    } );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( BrandSettingsDialog );
