import { store } from "../store";
import { difference, filter, includes, inRange, map } from "lodash";
import { requests } from "../helpers";
import { stringify } from "query-string";
import { getCurrentUser, getSocialNetworkAccountForId } from "../ducks/user";

/*
Returns data from Facebook's Graph API.
Does not handle pagination.
*/

export const LOGIN_PERMISSIONS = ["public_profile"];
export const OPTIONAL_LOGIN_PERMISSIONS = ["email"];
export const PAGE_PUBLISH_PERMISSIONS = ["pages_read_engagement",
                                         "pages_manage_metadata",
                                         "pages_read_user_content",
                                         "pages_manage_posts",
                                         "pages_manage_engagement",
                                         "business_management"];
export const PAGE_PERMISSIONS = LOGIN_PERMISSIONS.concat( PAGE_PUBLISH_PERMISSIONS );

export const INSTAGRAM_BUSINESS_PERMISSIONS = PAGE_PERMISSIONS.concat( ["instagram_basic", "instagram_content_publish"] );
// Use these permissions when testing against the "ripl - test" facebook app
// export const INSTAGRAM_BUSINESS_PERMISSIONS = PAGE_PERMISSIONS.concat( ["instagram_basic"] );

export const GROUP_PERMISSIONS = LOGIN_PERMISSIONS.concat( ["publish_to_groups"] );

const GRAPH_API_VERSION = "5.0";

export const facebookServices = {
    verifyUserHasLoginPermissions,
    verifyUserHasPagePermissions,
    verifyUserHasGroupPermissions,
    verifyUserHasInstagramBusinessPermissions,
    isAccountOwnedByDifferentUser,
    GRAPH_API_VERSION,
};

async function verifyUserHasLoginPermissions( aFacebookAccessToken: string ): Promise<boolean>
{
    return await verifyUserHasPermissions( aFacebookAccessToken, LOGIN_PERMISSIONS );
}

async function verifyUserHasPagePermissions( aFacebookAccessToken: string ): Promise<boolean>
{
    return await verifyUserHasPermissions( aFacebookAccessToken, PAGE_PERMISSIONS );
}

async function verifyUserHasGroupPermissions( aFacebookAccessToken: string ): Promise<boolean>
{
    return await verifyUserHasPermissions( aFacebookAccessToken, GROUP_PERMISSIONS );
}

async function verifyUserHasInstagramBusinessPermissions( aFacebookAccessToken: string ): Promise<boolean>
{
    return await verifyUserHasPermissions( aFacebookAccessToken, INSTAGRAM_BUSINESS_PERMISSIONS );
}

async function verifyUserHasPermissions( aFacebookAccessToken: string, desiredPermissions: string[] )
{
    try
    {
        const facebookPermissions = await getFacebookPermissions( aFacebookAccessToken );
        if ( hasGrantedPermissions( facebookPermissions, desiredPermissions ) )
        {
            return true;
        }
    }
    catch (error)
    {
        // Ignore error
    }
    return false;
}

// Do the supplied Facebook permissions grant all the desired permissions?
function hasGrantedPermissions( facebookPermissions: FacebookPermission[], desiredPermissions: string[] ): boolean
{
    const granted = map( filter( facebookPermissions, ["status", "granted"] ), ( facebookPermission ) =>
    {
        return facebookPermission.permission;
    } );
    const missing = difference( desiredPermissions, granted );
    return missing.length === 0;
}

function getFacebookPermissions( accessToken: string ): Promise<FacebookPermission[]>
{
    return new Promise<FacebookPermission[]>( ( resolve, reject ) =>
    {
        if ( !accessToken )
        {
            reject( { message: "Empty FB access token" } );
            return;
        }

        return getGraphAPI( accessToken, "me/permissions" ).then(
            ( res ) => resolve( res.data ),
            ( error: FacebookErrorResponse ) =>
            {
                if ( isFacebookSessionOrTokenExpired( error ) || isFacebookMissingAppPermission( error ) )
                {
                    resolve( [] );
                }
                else
                {
                    reject( error );
                }
            },
        );
    } );
}

function isFacebookSessionOrTokenExpired( error: FacebookErrorResponse )
{
    if ( includes( [102, 190], error.code ) || error.type === "OAuthException" )
    {
        return true;
    }
    return false;
}

function isFacebookMissingAppPermission( error: FacebookErrorResponse )
{
    if ( includes( [3, 10], error.code ) || inRange( error.code, 200, 300 ) )
    {
        return true;
    }

    return false;
}

function isAccountOwnedByDifferentUser( accountId: number )
{
    const state = store.getState();
    const user = getCurrentUser( state );
    const socialNetworkAccount = getSocialNetworkAccountForId( state, accountId );
    if ( socialNetworkAccount )
    {
        const socialNetworkAccountUserId = socialNetworkAccount.user_id;
        return (socialNetworkAccountUserId !== user.id);
    }
    return false;
}

function getGraphAPI( accessToken: string, endpoint: string )
{
    const params = stringify( { access_token: accessToken } );
    const url = `https://graph.facebook.com/v${GRAPH_API_VERSION}/${endpoint}?${params}`;
    return requests.getExternalJson( url );
}
