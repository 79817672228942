import { connect } from "react-redux";
import { Dispatch } from "redux";
import { userServices } from "../../services";
import { FacebookButton, FacebookButtonDispatchProps, FacebookButtonProps } from "../FacebookButton";
import { FacebookSocialNetworkAccountType, StoreState } from "../../_types";

interface RefreshFacebookButtonContainerProps
{
    accountType: FacebookSocialNetworkAccountType;
    buttonText: string;
    label?: string;
}

const mapStateToProps = ( storeState: StoreState, ownProps: RefreshFacebookButtonContainerProps ): FacebookButtonProps =>
{
    return {
        ...ownProps,
        user: storeState.user,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): FacebookButtonDispatchProps =>
{
    return {
        onFacebookConnectSuccess: ( accountType: FacebookSocialNetworkAccountType,
                                    userId: number,
                                    facebookId: number,
                                    unusedName: string,
                                    facebookAccessToken: string ) =>
        {
            dispatch( userServices.clientFacebookConnect( userId, facebookId, facebookAccessToken ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( FacebookButton );
