import * as React from "react";
import { ContentSearchMatchData, Post } from "../../_types";
import { isEmpty, isNil, map } from "lodash";
import classNames from "classnames";
import CircularProgress from "react-md/lib/Progress/CircularProgress";
import { ErrorPanel } from "../ErrorPanel";
import * as noResultsImg from "../../assets/img/stock_media_no_results.png";
import { ErrorBoundary } from "../ErrorBoundary";
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import * as InfiniteScroll from "react-infinite-scroller";
import PostsPanel from "./PostsPanel";
import * as serverErrorImg from "../../assets/img/server_error.png";
import { SEARCH_NO_RESULTS_TEXT } from "../../helpers";

export interface ContentSearchRouteProps
{
    searchTerm: string;
    searchResults: ContentSearchMatchData[];
    isSearching: boolean;
    hasError: boolean;
    hasNextPage: boolean;
    currentPageNumber: number;
    totalCount: number;
}

export interface ContentSearchRouteDispatchProps
{
    exitSearchMode: () => void;
    loadMore: ( page: number ) => void;
}

export class ContentSearchRoute extends React.Component<ContentSearchRouteProps & ContentSearchRouteDispatchProps>
{

    public render()
    {
        if ( this.isInSearchMode() )
        {
            return this.createSearchResultLayout();
        }
        else
        {
            return this.createNormalLayout();
        }
    }

    private isInSearchMode()
    {
        return !isNil( this.props.searchTerm ) && !!this.props.searchResults;
    }

    private createNormalLayout = () =>
    {
        return this.props.children;
    }

    private createSearchResultLayout = () =>
    {
        return (
            <div className={classNames( "page searchResults" )}>
                <div className="searchResultsHeaderContainer">
                    <div id="header-back-button"
                         className="searchBackButton"
                         onClick={this.onBackButtonClick}>
                        <i className="material-icons">arrow_back_ios</i>
                        Back
                    </div>
                    {!isEmpty( this.props.searchResults ) && <div className="searchResultsHeader">
                        {this.props.totalCount} search results for “<strong>{this.props.searchTerm}</strong>"
                    </div>}
                </div>
                {this.renderSearchContents()}
            </div>
        );
    };

    private renderSearchContents()
    {
        const posts = map( this.props.searchResults, ( result ) => result.post );
        const hasNoResults = isEmpty( this.props.searchResults );

        if ( this.props.isSearching )
        {
            return <CircularProgress className="spinner large" id="spinner"/>;
        }
        else if ( this.props.hasError )
        {
            return this.createErrorPanel();
        }
        else if ( hasNoResults )
        {
            return this.createNoResultsPanel();
        }
        else
        {
            return this.createSearchResultsGrid( posts );
        }
    }

    private createErrorPanel = () =>
    {
        const headline = "Unable to get data. Please try again.";
        return (<ErrorPanel imageSrc={serverErrorImg} headline={headline} renderHelpLink={true}/>);
    }

    private createNoResultsPanel = () =>
    {
        const headline = <span>No results for "<strong>{this.props.searchTerm}</strong>"</span>;
        const subTitle = SEARCH_NO_RESULTS_TEXT;
        return <ErrorPanel imageSrc={noResultsImg} headline={headline} subTitle={subTitle}/>;
    }

    private createSearchResultsGrid = ( posts: Post[] ) =>
    {
        return <ErrorBoundary
            errorMessage="There was a problem creating the search results grid. If this continues, click Restart below."
            showResetButton={true}>
            <Grid className="searchResultsGrid">
                <Cell size={12} tabletSize={8} className="searchResults">
                    <InfiniteScroll
                        pageStart={1}
                        loadMore={this.loadMore}
                        hasMore={this.props.hasNextPage}
                        loader={<CircularProgress key="0" className="spinner small" id="spinner"/>}
                        useWindow={true}>
                        <PostsPanel postData={posts}/>
                        {
                            this.shouldShowEndOfResults() &&
                            <div className="endResults">End of results, search again</div>
                        }
                    </InfiniteScroll>
                </Cell>
            </Grid>
        </ErrorBoundary>;
    }

    private shouldShowEndOfResults()
    {
        return !this.props.hasNextPage && this.props.currentPageNumber > 1;
    }

    private onBackButtonClick = () =>
    {
        this.props.exitSearchMode();
    }

    private loadMore = ( page: number ) =>
    {
        this.props.loadMore( page );
    }
}
