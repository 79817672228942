import { includes } from "lodash";

const EU_COUNTRY_CODES = ["BE", "BG", "CZ", "DK", "DE", "EE", "IE", "EL", "ES",
                          "FR", "HR", "IT", "CY", "LV", "LT", "LU", "HU", "MT",
                          "NL", "AT", "PL", "PT", "RO", "SI", "SK", "FI", "SE",
                          "UK"];

const MINUTES_PER_HOUR = 60;

export function isUserInEU( countryCode: string )
{
    return isCountryInEU( countryCode ) || isTimezoneInEUTime();
}

function isCountryInEU( countryCode: string )
{
    return includes( EU_COUNTRY_CODES, countryCode );
}

function isTimezoneInEUTime()
{
    const minusOneHourFromUTCInMinutes = -1 * MINUTES_PER_HOUR;
    const plusThreeHoursFromUTCInMinutes = 3 * MINUTES_PER_HOUR;

    const timezoneOffsetInMinutes = new Date().getTimezoneOffset();

    return timezoneOffsetInMinutes >= minusOneHourFromUTCInMinutes && timezoneOffsetInMinutes <= plusThreeHoursFromUTCInMinutes;
}
