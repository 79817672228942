import * as React from "react";
import Button from "react-md/lib/Buttons/Button";
import { BusinessTypeIcon } from "./icons/BusinessTypeIcon";
import { BusinessType } from "../_types";

export interface BusinessTypeFilterButtonProps
{
    businessType: BusinessType;
    selectedBusinessType?: BusinessType;
}

export interface BusinessTypeFilterButtonDispatchProps
{
    handleSelectBusinessType: ( businessType: BusinessType ) => void;
}

export class BusinessTypeFilterButton extends React.PureComponent<BusinessTypeFilterButtonProps & BusinessTypeFilterButtonDispatchProps>
{
    public render()
    {
        const icon = <BusinessTypeIcon name={this.props.businessType.name} icon_url={this.props.businessType.alt_icon_url}/>;

        return (
            <Button key={this.props.businessType.id} flat iconEl={icon}
                    className={"businessTypeFilterButton" + (this.isSelected() ? " selected" : "")}
                    onClick={this.handleClick}>
                {this.props.businessType.name}
            </Button>
        );
    }

    private handleClick = () =>
    {
        this.props.handleSelectBusinessType( this.props.businessType );
    }

    private isSelected = () =>
    {
        return this.props.selectedBusinessType && this.props.selectedBusinessType.id === this.props.businessType.id;
    }
}
