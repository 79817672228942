import * as facebookUnconnectedImg from "../assets/img/socialNetworkIcons/facebookUnconnected.png";
import * as instagramUnconnectedImg from "../assets/img/socialNetworkIcons/instagramUnconnected.png";
import * as twitterUnconnectedImg from "../assets/img/socialNetworkIcons/twitterUnconnected.png";
import * as youTubeUnconnectedImg from "../assets/img/socialNetworkIcons/youTubeUnconnected.png";
import * as linkedInUnconnectedImg from "../assets/img/socialNetworkIcons/linkedInUnconnected.png";

import * as facebookConnectedImg from "../assets/img/socialNetworkIcons/facebookConnected.png";
import * as instagramConnectedImg from "../assets/img/socialNetworkIcons/instagramConnected.png";
import * as twitterConnectedImg from "../assets/img/socialNetworkIcons/twitterConnected.png";
import * as youTubeConnectedImg from "../assets/img/socialNetworkIcons/youTubeConnected.png";
import * as linkedInConnectedImg from "../assets/img/socialNetworkIcons/linkedInConnected.png";

export const SOCIAL_NETWORK_NOT_CONNECTED_ICONS = {
    Facebook: facebookUnconnectedImg,
    FacebookGroup: facebookUnconnectedImg,
    FacebookPage: facebookUnconnectedImg,
    FacebookInstagram: instagramUnconnectedImg,
    Instagram: instagramUnconnectedImg,
    Twitter: twitterUnconnectedImg,
    YouTube: youTubeUnconnectedImg,
    LinkedIn: linkedInUnconnectedImg,
};

export const SOCIAL_NETWORK_CONNECTED_ICONS = {
    Facebook: facebookConnectedImg,
    FacebookGroup: facebookConnectedImg,
    FacebookPage: facebookConnectedImg,
    FacebookInstagram: instagramConnectedImg,
    Instagram: instagramConnectedImg,
    Twitter: twitterConnectedImg,
    YouTube: youTubeConnectedImg,
    LinkedIn: linkedInConnectedImg,
};
