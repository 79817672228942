import * as React from "react";
import classNames from "classnames";
import * as MediaIconDefault from "../assets/img/controls/media_control.png";
import { SlideOptionsMenu } from "./SlideOptionsMenu";
import { SingleSlideSummary } from "../_types";
import { SortableElement, SortableElementProps } from "react-sortable-hoc";

export interface SlideSummaryItemProps extends SortableElementProps
{
    slideId: string;
    slideIndex: number;
    hasMedia: boolean;
    selected: boolean;
    slideSummary: SingleSlideSummary[];
}

// tslint:disable-next-line:no-empty-interface
export interface SlideSummaryItemDispatchProps
{
    selectSlide: ( slideId: string ) => void;
}

export class SlideSummaryItem extends React.PureComponent<SlideSummaryItemProps & SlideSummaryItemDispatchProps>
{
    public render()
    {
        return (
            <div className={classNames( "slideSummaryItem horizontalScrollChild" )}>
                <SlideOptionsMenu slideId={this.props.slideId} isLastSlide={this.props.slideSummary.length === 1}/>
                <div className={classNames( "slideBox", { selected: this.props.selected } )} onClick={this.handleClick}>
                    {this.props.hasMedia && <img className={"mediaIcon"} src={MediaIconDefault} alt={"slide has media"}/>}
                </div>
                <span onClick={this.handleClick}>{this.props.slideIndex + 1}</span>
            </div>
        );
    }

    private handleClick = () =>
    {
        this.props.selectSlide( this.props.slideId );
    }
}

export const SlideSummaryItemSortable = SortableElement<SlideSummaryItemProps & SlideSummaryItemDispatchProps>( SlideSummaryItem );
