import * as React from "react";
import { INSTAGRAM_TUTORIAL_URL, SOCIAL_ACCOUNT_CONNECTED_SOURCE_INSTAGRAM_DONT_SEE_BUSINESS_DIALOG } from "../helpers";
import { ConnectButton } from "./ConnectButton";
import { FACEBOOK_INSTAGRAM_ACCOUNT_TYPE } from "../_types/api";

export const InstagramTutorialDialogContent: React.FC = () =>
{
    return (
        <div className="iframeWrap">
            <iframe src={INSTAGRAM_TUTORIAL_URL}/>
        </div>
    );
};

export const InstagramTutorialFooterConnectButton: React.FC = () =>
{
    return (
        <ConnectButton
            className="connectBtn"
            accountType={FACEBOOK_INSTAGRAM_ACCOUNT_TYPE}
            trackingSource={SOCIAL_ACCOUNT_CONNECTED_SOURCE_INSTAGRAM_DONT_SEE_BUSINESS_DIALOG}
            showInstagramSetupModalFirst={false}
            buttonText="I did it"
        />
    );
};
