import * as React from "react";
import { injectStripe, ReactStripeElements } from "react-stripe-elements";
import * as RiplLogoLockupHorizontal from "../assets/img/ripl_logo_lockup_horizontal.png";
import { PaymentFormFinePrint } from "./PaymentFormFinePrint";
import { getSubscriptionButtonText } from "../ducks/pricing";
import CardFormError from "./containers/CardFormError.container";
import { CardForm } from "./CardForm";
import InjectedStripeProps = ReactStripeElements.InjectedStripeProps;
import StripeProps = ReactStripeElements.StripeProps;

export interface PaymentFormDispatchProps
{
    onSubscribeWithStripe( stripe: StripeProps, sku: string, reCaptchaToken: string ): void;
    clearError(): void;
}

export interface PaymentFormProps extends InjectedStripeProps
{
    selectedPlan: StripePlanData;
    isCardProcessing: boolean;
    selectedCurrencyCode: CurrencyCode;
}

class PaymentForm extends React.PureComponent<PaymentFormProps & PaymentFormDispatchProps>
{
    public componentDidMount()
    {
        this.props.clearError();
    }

    public render()
    {
        const { selectedPlan, selectedCurrencyCode } = this.props;
        const buttonText = getSubscriptionButtonText( selectedPlan );
        const disableButton = this.props.isCardProcessing;
        const finePrint = <PaymentFormFinePrint selectedPlan={selectedPlan} selectedCurrencyCode={selectedCurrencyCode}/>;

        return (
            <div className="paymentForm">
                <div className="pro">
                    <img src={RiplLogoLockupHorizontal}/>
                </div>
                <CardFormError/>
                <CardForm disabled={disableButton}
                          buttonText={buttonText}
                          finePrint={finePrint}
                          onChange={this.handleReCAPTCHAOnChange}
                          clearError={this.props.clearError}/>
            </div>);
    }

    private handleReCAPTCHAOnChange = ( reCaptchaToken: string ) =>
    {
        this.props.onSubscribeWithStripe( this.props.stripe, this.props.selectedPlan.id, reCaptchaToken );
    }
}

export default injectStripe( PaymentForm );
