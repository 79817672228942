import { Dispatch } from "redux";
import { StoreState } from "../_types";
import { requests } from "../helpers";

export const FACEBOOK_AD_CREATION_STARTED_AIRSHIP_EVENT_NAME = "facebook_ad_creation_started";
export const FACEBOOK_AD_CREATED_AIRSHIP_EVENT_NAME = "facebook_ad_created";
export const FACEBOOK_AD_AUDIENCE_EDITED_AIRSHIP_EVENT_NAME = "facebook_ad_audience_edited";
export const FACEBOOK_AD_DURATION_EDITED_AIRSHIP_EVENT_NAME = "facebook_ad_duration_edited";
export const FACEBOOK_AD_BUDGET_EDITED_AIRSHIP_EVENT_NAME = "facebook_ad_budget_edited";
export const FACEBOOK_AD_DETAILS_EDITED_AIRSHIP_EVENT_NAME = "facebook_ad_details_edited";
export const FACEBOOK_AD_WEBSITE_URL_EDITED_AIRSHIP_EVENT_NAME = "facebook_ad_website_url_edited";

export const IN_APP_PURCHASE_COMPLETED_EVENT_NAME = "in_app_purchase_completed_event";

export const airshipServices = { sendCustomEvent };

const AIRSHIP_CUSTOM_EVENT_URL = "/airship/send_custom_event";

function sendCustomEvent( eventName: string )
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        const params = { custom_event_name: eventName };
        return requests.post( AIRSHIP_CUSTOM_EVENT_URL, null, params );
    };
}
