import * as React from "react";
import { Elements } from "react-stripe-elements";
import PaymentFormContainer from "./containers/PaymentForm.container";

export interface PaymentFormDialogProps
{
    showGradientVersion: boolean;
}

export class PaymentFormDialog extends React.PureComponent<PaymentFormDialogProps>
{
    public render()
    {
        return (
            <div>
                <Elements>
                    <PaymentFormContainer showGradientVersion={this.props.showGradientVersion}/>
                </Elements>
            </div>
        );
    }
}
