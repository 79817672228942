import * as React from "react";
import BusinessTypeSelectionPageContainer from "./containers/BusinessTypeSelectionPage.container";
import { store } from "../store";
import { BUSINESS_PICKER_SOURCE_SETTINGS, eventTracker } from "../helpers";
import { getBusinessType } from "../ducks/businessTypes";
import { getSelectedBusinessTypeId } from "../ducks/ui";

export interface ChangeBusinessTypeDialogDispatchProps
{
    handleDone(): void;
}

export class ChangeBusinessTypeDialog extends React.PureComponent<ChangeBusinessTypeDialogDispatchProps>
{
    public render()
    {
        return (<BusinessTypeSelectionPageContainer handleNextClicked={this.handleBusinessTypeSelected}
                                                    primaryCtaButtonLabel="Done"
                                                    customHeaderContent={this.createHeader()}/>);
    }

    private createHeader = () =>
    {
        return (
            <div className="headerContainer">
                <p className="headerText1">Choose a business type</p>
            </div>
        );
    }

    private handleBusinessTypeSelected = () =>
    {
        const state = store.getState();
        eventTracker.logBusinessTypeSelected( BUSINESS_PICKER_SOURCE_SETTINGS, getBusinessType( state, getSelectedBusinessTypeId( state ) ) );
        this.props.handleDone();
    }
}
