import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { modalServices, userBusinessServices } from "../../../services";
import { LightboxDialogIdentifierForKey, NO_COLOR_SELECTED_DEFAULT_VALUE } from "../../../helpers";
import { ColorValue, LightboxContents, StoreState, TemplateInputEndCardData } from "../../../_types";
import SketchPicker from "react-color/lib/components/sketch/Sketch";
import { mixModelActions } from "../../../actions";
import { ColorResult } from "react-color";
import { getEndCardDataFromBrandSlideData } from "../../../ducks/mixModel";
import { BrandSlideColorPicker, BrandSlideColorPickerDispatchProps, BrandSlideColorPickerProps } from "../BrandSlideColorPicker";
import { BRAND_SLIDE_COLOR_ONE, BRAND_SLIDE_COLOR_TWO, MIX_MODEL_BRAND_SLIDE_COLOR_ONE, MIX_MODEL_BRAND_SLIDE_COLOR_TWO } from "../../../_types/api";

const mapStateToProps = ( storeState: StoreState ): BrandSlideColorPickerProps =>
{
    return {
        endCardData: getEndCardDataFromBrandSlideData( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): BrandSlideColorPickerDispatchProps =>
{
    return {
        openDialog: ( colorKey: keyof TemplateInputEndCardData, startValue: ColorValue ) =>
        {
            openSketchPicker( dispatch, colorKey, startValue );
        },
    };
};

function openSketchPicker( dispatch: Dispatch<StoreState>, userBusinessKey: keyof TemplateInputEndCardData, startValue: string )
{
    const sketchPickerLightboxProps = getSketchPickerLightboxProps( dispatch, userBusinessKey, startValue );
    dispatch( modalServices.openLightbox( sketchPickerLightboxProps ) );
}

function getSketchPickerLightboxProps( dispatch: Dispatch<StoreState>,
                                       colorKey: keyof TemplateInputEndCardData,
                                       startValue: string ): LightboxContents
{
    const dialogContents: React.FC<{}> = () =>
    {
        return <SketchPicker
            color={startValue || NO_COLOR_SELECTED_DEFAULT_VALUE}
            onChangeComplete={colorChangeListener( dispatch, colorKey )}
        />;
    };

    return {
        identifierForKey: LightboxDialogIdentifierForKey.CHOOSE_BRAND_COLOR,
        title: "Choose a color",
        content: dialogContents,
        hideCancel: true,
        showCancelX: true,
        width: 275,
        footerClassName: "brandColorPickerFooter",
    };
}

function colorChangeListener( dispatch: Dispatch<StoreState>, colorKey: keyof TemplateInputEndCardData )
{
    return ( value: ColorResult ) =>
    {
        const newValue = value.hex;
        const newColorDataForBrandSlide = { endCardData: { [colorKey]: newValue } };
        dispatch( mixModelActions.brandSlideDataUpdated( newColorDataForBrandSlide ) );

        const brandSlideColorKey = getCorrespondingBrandSlideColorKey( colorKey );
        if ( !!brandSlideColorKey )
        {
            const colorData = { [brandSlideColorKey]: newValue };
            dispatch( userBusinessServices.update( colorData ) );
        }

    };
}

function getCorrespondingBrandSlideColorKey( colorKey: string )
{
    if ( colorKey === MIX_MODEL_BRAND_SLIDE_COLOR_ONE )
    {
        return BRAND_SLIDE_COLOR_ONE;
    }
    else if ( colorKey === MIX_MODEL_BRAND_SLIDE_COLOR_TWO )
    {
        return BRAND_SLIDE_COLOR_TWO;
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( BrandSlideColorPicker );
