import { connect } from "react-redux";
import { BusinessTypeSelectionPage, BusinessTypeSelectionPageProps } from "../BusinessTypeSelectionPage";
import { getTypes } from "../../ducks/businessTypes";
import { getSelectedBusinessTypeId, isCreateBusinessRequestInFlight } from "../../ducks/ui";
import { StoreState } from "../../_types";

const mapStateToProps = ( storeState: StoreState, ownProps: BusinessTypeSelectionPageProps ): BusinessTypeSelectionPageProps =>
{
    return {
        ...ownProps,
        businessTypes: getTypes( storeState ),
        selectedBusinessType: getSelectedBusinessTypeId( storeState ),
        isCreateBusinessRequestInFlight: isCreateBusinessRequestInFlight( storeState ),
    };
};

export default connect(
    mapStateToProps,
)( BusinessTypeSelectionPage );
