import { connect } from "react-redux";
import { Dispatch } from "redux";
import { store } from "../../../store";
import { getDraftPosts } from "../../../ducks/draft";
import { hasCreatedFirstBusiness, isCollabraUser, isUserLoggedIn } from "../../../ducks/user";
import { getUserHasPosted } from "../../../ducks/post";
import { getCurrentBusiness } from "../../../ducks/userBusiness";
import { HomeDiscoverPageDispatchProps, HomeDiscoverSubPage, HomeDiscoverSubPageProps } from "../HomeDiscoverSubPage";
import { postServices, userServices } from "../../../services";
import { StoreState } from "../../../_types";
import { getCollectionRows } from "../../../ducks/collectionRows";
import { uiActions, userActions } from "../../../actions";
import { getBusinessTypeFilter, isShowYouAroundTourVisible } from "../../../ducks/ui";
import { apptimizeVariables } from "../../../helpers";
import {
    doesSocialCalendarHaveHolidays,
    doesSocialCalendarHaveTodaySlots,
    getSocialCalendarDays,
    getSocialCalendarPosts,
} from "../../../ducks/socialCalendar";

const mapStateToProps = ( storeState: StoreState ): HomeDiscoverSubPageProps =>
{
    const shouldDisplayShowYouAround = isShowYouAroundTourVisible( storeState );

    return {
        draftPosts: getDraftPosts( storeState ),
        currentBusiness: getCurrentBusiness( storeState ),
        isUserLoggedIn: isUserLoggedIn( storeState ),
        hasCreatedFirstBusiness: hasCreatedFirstBusiness( storeState ),
        hasPosted: getUserHasPosted( storeState ),
        collectionRows: getCollectionRows( storeState ),
        socialCalendar: {
            calendar: getSocialCalendarDays( storeState ),
            posts: getSocialCalendarPosts( storeState ),
        },
        shouldDisplayShowYouAround,
        shouldShowSocialCalendar: doesSocialCalendarHaveHolidays( storeState )
                                  && doesSocialCalendarHaveTodaySlots( storeState ),
        isCollabraUser: isCollabraUser( storeState ),
        showTwoBanners: apptimizeVariables.shouldShowTwoBannerHomeScreen(),
        showBusinessTypeFilter: apptimizeVariables.shouldShowBusinessTypeSelector() && getBusinessTypeFilter( storeState ) !== undefined,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): HomeDiscoverPageDispatchProps =>
{
    return {
        loadDashboardData: async () =>
        {
            try
            {
                await dispatch( userServices.loadMe() );

                const storeState = store.getState();
                const currentBusiness = getCurrentBusiness( storeState );
                dispatch( postServices.loadDraftPosts( currentBusiness ) );
            }
            catch (error)
            {
                // Ignore errors here for now
            }
        },
        showYouAroundDisplayed: () =>
        {
            dispatch( userActions.showYouAroundDisplayed() );
        },
        dismissShowYouAround: () =>
        {
            dispatch( uiActions.displayShowYouAroundTour( false ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( HomeDiscoverSubPage );
