import * as React from "react";
import { SubscriptionFlowSource } from "./trackingConstants";
import PremiumBenefitsContainer from "../components/containers/PremiumBenefits.container";
import { DialogControlProps } from "../_types";
import GradientHeaderDialogContainer from "../components/containers/GradientHeaderDialog.container";
import SelectPricePlanSectionContainer from "../components/containers/SelectPricePlanSection.container";
import { RIPL_TIER_PREMIUM } from "./tierConstants";
import SelectPricePlansDialogContainer from "../components/containers/SelectPricePlansDialog.container";
import { UPSELL_CHOOSE_PLAN_TO_CONTINUE_SUBTITLE, UPSELL_CHOOSE_PLAN_TO_CONTINUE_TITLE } from "./upsellConstants";
import {
    SUBSCRIBED_ON_ANDROID_DIALOG_TITLE,
    SUBSCRIBED_ON_IOS_DIALOG_TITLE,
    SUBSCRIBED_ON_OTHER_PLATFORM_DIALOG_TITLE,
    SUBSCRIBED_OTHER_PLATFORM_DIALOG_TEXT,
} from "./constants";
import TiersExplanationOfBenefits from "../components/containers/TiersExplanationOfBenefits.container";
import SelectPriceTiersDialogContainer from "../components/containers/SelectPriceTiersDialog.container";
import { eventTracker } from "./trackingEvents";
import { store } from "../store";
import { modalServices, upsellServices } from "../services";
import { pricingActions } from "../actions";
import PremiumFocusedUpsellDialogContainer from "../components/premiumFocusedUpsell/containers/PremiumFocusedUpsellDialog.container";
import { PremiumEpidemicSoundTrackDialogContent } from "../components/PremiumEpidemicSoundTrackDialogContent";

export const upsellDialogFactory = {
    getPremiumBenefitsDialogContent,
    getSimplifiedSelectPlanDialogContent,
    getSimplifiedTiersDialogContent,
    getStandardTiersDialogContent,
    getStandardSelectPlansDialogContent,
    getSubscribedOnOtherPlatformDialogContent,
    getSubscribedOnAndroidDialogContent,
    getSubscribedOnIOSDialogContent,
    getPremiumEpidemicSoundTrackDialogContent,
    getPremiumFocusedUpsellDialogContent,
};

export function selectTierForGradientHeaderDialog( source: SubscriptionFlowSource )
{
    return ( selectedTier: ProductTier ) =>
    {
        eventTracker.logSubscriptionTierSelected( source, selectedTier );
        store.dispatch( upsellServices.displayGradientHeaderSelectPlanDialogForTier( source, selectedTier ) );
    };
}

export function selectTierForGradientSidebarDialog( source: SubscriptionFlowSource, closeDialog: () => void )
{
    return ( selectedTier: ProductTier ) =>
    {
        closeDialog();

        eventTracker.logSubscriptionTierSelected( source, selectedTier );
        store.dispatch( pricingActions.tierSelected( selectedTier ) );
        store.dispatch( upsellServices.displaySelectPricePlansDialog(
            source,
            selectedTier,
            null,
            null,
            () =>
            {
                store.dispatch( modalServices.openAcceptTestDriveDialog() );
            } ) );
    };
}

function getPremiumBenefitsDialogContent( source: SubscriptionFlowSource, dialogTitle: string )
{
    return createGradientHeaderDialogContainer( dialogTitle, <PremiumBenefitsContainer source={source}/> );
}

function getPremiumEpidemicSoundTrackDialogContent()
{
    return createGradientHeaderDialogContainer( "Get more music with Premium", <PremiumEpidemicSoundTrackDialogContent/> );
}

function getSubscribedOnOtherPlatformDialogContent()
{
    return createGradientHeaderDialogContainer( SUBSCRIBED_ON_OTHER_PLATFORM_DIALOG_TITLE, SUBSCRIBED_OTHER_PLATFORM_DIALOG_TEXT, false );
}

function getSubscribedOnAndroidDialogContent()
{
    return createGradientHeaderDialogContainer( SUBSCRIBED_ON_ANDROID_DIALOG_TITLE, SUBSCRIBED_OTHER_PLATFORM_DIALOG_TEXT, false );
}

function getSubscribedOnIOSDialogContent()
{
    return createGradientHeaderDialogContainer( SUBSCRIBED_ON_IOS_DIALOG_TITLE, SUBSCRIBED_OTHER_PLATFORM_DIALOG_TEXT, false );
}

function getSimplifiedSelectPlanDialogContent( source: SubscriptionFlowSource, tier: ProductTier, options: GradientHeaderDialogContainerOptions = {} )
{
    const dialogTitle = tier === RIPL_TIER_PREMIUM ? "Ripl Premium Plan" : "Ripl Base Plan";
    const showCrown = tier === RIPL_TIER_PREMIUM;

    return (
        createGradientHeaderDialogContainer( dialogTitle, <SelectPricePlanSectionContainer source={source}/>, showCrown, false, options )
    );
}

function getSimplifiedTiersDialogContent( source: SubscriptionFlowSource )
{
    return (
        createGradientHeaderDialogContainer(
            UPSELL_CHOOSE_PLAN_TO_CONTINUE_TITLE,
            <TiersExplanationOfBenefits selectTier={selectTierForGradientHeaderDialog( source )}/>,
            false,
            false,
            { subtitle: UPSELL_CHOOSE_PLAN_TO_CONTINUE_SUBTITLE } )
    );
}

function getStandardTiersDialogContent( source: SubscriptionFlowSource )
{
    return ( props: DialogControlProps ) =>
    {
        return (
            <SelectPriceTiersDialogContainer
                {...props}
                source={source}
            />
        );
    };
}

function getStandardSelectPlansDialogContent( source: SubscriptionFlowSource, selectedTier: ProductTier )
{
    return ( props: DialogControlProps ) =>
    {
        return (
            <SelectPricePlansDialogContainer
                {...props}
                source={source}
                selectedTier={selectedTier}/>
        );
    };
}

interface GradientHeaderDialogContainerOptions
{
    subtitle?: string;
    width?: number;
}

function createGradientHeaderDialogContainer( title: string,
                                              content: JSX.Element | string,
                                              showCrown: boolean = true,
                                              canGoBack: boolean = false,
                                              options: GradientHeaderDialogContainerOptions = {} )
{
    return ( props: DialogControlProps ) =>
    {
        return (
            <GradientHeaderDialogContainer
                {...props}
                title={title}
                content={content}
                showCrown={showCrown}
                canGoBack={canGoBack}
                {...options}
            />
        );
    };
}

function getPremiumFocusedUpsellDialogContent()
{
    return ( props: DialogControlProps ) =>
    {
        return (
            <PremiumFocusedUpsellDialogContainer {...props} />
        );
    };
}
