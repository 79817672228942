import * as React from "react";
import { DetailListItem } from "../DetailListItem";

interface MusicPickerCustomItemProps
{
    selected: boolean;
    text: string;
    onClick: () => void;
    iconSrc: string;
    iconSrcUnselected?: string;
    iconOverlay?: JSX.Element;
}

export function MusicPickerCustomItem( props: MusicPickerCustomItemProps )
{
    const { text, selected, onClick, iconSrc, iconSrcUnselected, iconOverlay } = props;

    return (<DetailListItem
        title={text}
        iconSrc={iconSrc}
        iconSrcUnselected={iconSrcUnselected}
        selected={selected}
        onClick={onClick}
        outlineContent
        iconOverlay={iconOverlay}
    />);
}
