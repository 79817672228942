import * as React from "react";
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import FacebookLoginButton from "./containers/FacebookLoginButton.container";
import EmailPasswordInputPanelContainer from "./containers/EmailPasswordInputPanel.container";
import * as RiplLogo from "../assets/img/ripl_logo_white.png";
import { apptimizeVariables } from "../helpers";
import { GoogleOneTapLoginButton } from "./GoogleOneTapLoginButton";
import { CredentialResponse } from "@react-oauth/google";

export interface LoginFormProps
{
    showCreateAccount();
    showForgotPassword();
}

export interface LoginFormDispatchProps
{
    onEmailLoginAttempt( emailAddress: string, password: string );
    handleGoogleLoginResponse( tokenResponse: CredentialResponse );
}

export default class GradientLoginForm extends React.PureComponent<LoginFormProps & LoginFormDispatchProps>
{
    public render()
    {
        return (
            <Grid className="gradientLoginForm" noSpacing={true}>
                <Cell size={5} tabletHidden={true} phoneHidden={true} className="gradientArea">
                    <div className="clicksAwayBackground">
                        <img src={RiplLogo}/>
                        <div className="clicksAwayText">
                            You are a few clicks away from creating stunning social posts
                        </div>
                    </div>
                </Cell>
                <Cell size={7} tabletSize={8} className="content loginSection">
                    <h1 className="text title thin">Log in</h1>
                    <FacebookLoginButton/>
                    {this.renderFacebookDisabled()}
                    {this.getGoogleSignInButton()}
                    <div className="orLine">
                        <div className="line"/>
                        or
                        <div className="line"/>
                    </div>
                    <EmailPasswordInputPanelContainer
                        onContinueClicked={this.props.onEmailLoginAttempt}
                        onForgotLogin={this.props.showForgotPassword}
                        buttonText="Log in"
                        optionalClassName="loginButton"
                        showVersionInfo={true}>
                        <div className="text">
                            Don't have an account? <span className="standardLink" onClick={this.props.showCreateAccount}>Create one</span>
                        </div>
                    </EmailPasswordInputPanelContainer>
                </Cell>
            </Grid>
        );
    }

    private renderFacebookDisabled = () =>
    {
        if ( apptimizeVariables.shouldDisableFacebookConnect() )
        {
            return (
                <div className="text">
                    {/* tslint:disable-next-line:max-line-length */}
                    Logging in with Facebook is currently disabled.<br/>Please login with email & password.<br/>Reach out to feedback@ripl.com with
                    any issues
                </div>
            );
        }
        return null;
    }

    private getGoogleSignInButton = () =>
    {
        return <GoogleOneTapLoginButton isLoginButton={true} handleGoogleResponse={this.props.handleGoogleLoginResponse}/>;
    }
}
