import * as React from "react";
import { filter, map } from "lodash";
import { DesignElementProperties, DesignElementProperty } from "../../_types";
import { DESIGN_ELEMENT_PROPERTY_TYPE_COLOR } from "../../_types/api";
import ColorTextChoiceItem from "./containers/ColorTextChoiceItem.container";

export interface ColorTextChoicePanelProps
{
    designElementProperties: DesignElementProperties;
}

export class ColorTextChoicePanel extends React.PureComponent<ColorTextChoicePanelProps>
{

    public render()
    {
        const colorProperties = this.getColorPropertyData();
        return (<>
            {map( colorProperties, ( colorProperty: DesignElementProperty, index: number ) =>
            {
                return <ColorTextChoiceItem key={colorProperty.id} designElementProperty={colorProperty} defaultExpanded={index === 0}/>;
            } )}
        </>);
    }

    private getColorPropertyData(): DesignElementProperty[]
    {
        return filter( this.props.designElementProperties.properties,
            ( property ) => property.type === DESIGN_ELEMENT_PROPERTY_TYPE_COLOR );
    }
}
