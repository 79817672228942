import { connect } from "react-redux";
import { Dispatch } from "redux";
import { SchedulePostWidget, SchedulePostWidgetDispatchProps, SchedulePostWidgetProps } from "../SchedulePostWidget";
import { uiActions } from "../../actions";
import { getScheduledSendDatetime } from "../../ducks/shareModel";
import { eventTracker, NON_BREAKING_SPACE, SUBSCRIPTION_FLOW_SOURCE_SCHEDULE_POST, SubscriptionFlowSource } from "../../helpers";
import { handleUpTierForNonStripeSubscriber, upsellServices } from "../../services";
import { StoreState } from "../../_types";
import { store } from "../../store";
import { canSchedulePosts, isUserStripeSubscribed } from "../../ducks/user";

const mapStateToProps = ( storeState: StoreState ): SchedulePostWidgetProps =>
{
    return {
        scheduledSendDatetime: getScheduledSendDatetime( storeState ),
        shouldShowPremiumCrown: !canSchedulePosts( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): SchedulePostWidgetDispatchProps =>
{
    function openScheduledPostModal()
    {
        eventTracker.logScheduleSendCalendarDisplayed();
        dispatch( uiActions.schedulePostModalOpen( {} ) );
    }

    return {
        showSchedulePostDialog: () =>
        {
            const state = store.getState();
            const source: SubscriptionFlowSource = SUBSCRIPTION_FLOW_SOURCE_SCHEDULE_POST;
            if ( canSchedulePosts( state ) )
            {
                dispatch( upsellServices.ensureUserSubscribedBeforeRunningAction( source, openScheduledPostModal ) );
            }
            else if ( isUserStripeSubscribed( state ) )
            {
                const dialogTitle = `Schedule posts for later with Ripl${NON_BREAKING_SPACE}Premium!`;
                dispatch( upsellServices.displayGradientHeaderPremiumBenefitsDialog( source, dialogTitle ) );
            }
            else
            {
                handleUpTierForNonStripeSubscriber( dispatch, source );
            }
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( SchedulePostWidget );
