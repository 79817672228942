import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ManagePasswordPage, ManagePasswordPageDispatchProps, ManagePasswordPageProps } from "../ManagePasswordPage";
import { userServices } from "../../../services";
import { getResetFormData } from "../../../ducks/ui";
import { uiActions } from "../../../actions";
import { isEqual } from "lodash";
import { StoreState } from "../../../_types";

const mapStateToProps = ( storeState: StoreState ): ManagePasswordPageProps =>
{
    return {
        buttonText: "Change",
        passwordPromptText: "Change your password",
        resetFormData: getResetFormData( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): ManagePasswordPageDispatchProps =>
{
    return {
        changePassword: ( password: string, confirmPassword: string ) =>
        {
            if ( !password )
            {
                return dispatch( uiActions.setResetFormErrors( { password: "Password cannot be blank." } ) );
            }

            if ( !isEqual( password, confirmPassword ) )
            {
                return dispatch( uiActions.setResetFormErrors( { password_confirmation: "Passwords do not match." } ) );
            }

            dispatch( userServices.changePassword( password ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( ManagePasswordPage );
