import * as React from "react";
import * as PlusButton from "../assets/img/plusButton.png";
import { map } from "lodash";

export interface CreateBlankPostButtonProps
{
    startText?: string[];
    remixText?: string[];
}

export interface CreateBlankPostButtonDispatchProps
{
    handleClick?();
}

export class CreateBlankPostButton extends React.PureComponent<CreateBlankPostButtonProps & CreateBlankPostButtonDispatchProps>
{
    public render()
    {
        const { handleClick } = this.props;
        return <div className="riplMasonryItem createBlankPostButton">
            <div className="container riplMasonryBoxShadow"
                 style={{ "--aspect-ratio": 1 }}
                 onClick={handleClick}>
                <div className="innerContent">
                    {
                        map( this.props.startText, ( startText, index ) =>
                        {
                            return <div className="startText" key={`startText-${index}`}>{startText}</div>;
                        } )
                    }
                    <img className="plus" src={PlusButton}/>
                    {
                        map( this.props.remixText, ( remixText, index ) =>
                        {
                            return <div className="remixText" key={`remixText-${index}`}>{remixText}</div>;
                        } )
                    }
                </div>
            </div>
        </div>;
    }
}
