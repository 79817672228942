import { Action } from "redux-actions";
import { DESIGN_SQUARE_ASPECT_RATIO, normalizeBackgroundMediaAPI, normalizeDesignFramesAPI, ReducerCreator, updateLookup } from "../helpers";
import { AssetChoice, BackgroundMediaState, DesignFramesState, DynamicDesignDataState, StoreState } from "../_types";
import { createDefaultDataLookup } from "./dataLookup";
import { dynamicDesignDataActions } from "../actions/dynamicDesignData.actions";
import { get, join, map } from "lodash";
import { BackgroundMediaAPIData, BackgroundMediaAPIItem, DesignFramesAPIData, DesignFramesAPIItem } from "../_types/api";

const defaultState: DynamicDesignDataState = {
    backgroundMedia: {
        ...createDefaultDataLookup(),
    },
    designFrames: {
        ...createDefaultDataLookup(),
    },
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( dynamicDesignDataActions.designFramesLoadSuccess, handleDesignFramesLoadSuccess );
reducerCreator.addAction( dynamicDesignDataActions.backgroundMediaLoadSuccess, handleBackgroundMediaLoadSuccess );
export default reducerCreator.createReducer();

function handleDesignFramesLoadSuccess( state: DynamicDesignDataState, action: Action<DesignFramesAPIData> ): DynamicDesignDataState
{
    const normalizedData = normalizeDesignFramesAPI( action.payload.design_frames );
    const designFramesState = updateLookup( normalizedData.entities.designFrames, state.designFrames, false );
    restoreDesignFramesOrderingFromNormalizrData( designFramesState, normalizedData );
    return {
        ...state,
        designFrames: designFramesState,
    };
}

function restoreDesignFramesOrderingFromNormalizrData( designFramesState: DesignFramesState, data: NormalizrData )
{
    designFramesState.ids = data.result;
}

function handleBackgroundMediaLoadSuccess( state: DynamicDesignDataState, action: Action<BackgroundMediaAPIData> ): DynamicDesignDataState
{
    const normalizedData = normalizeBackgroundMediaAPI( action.payload.media );
    const backgroundMediaState = updateLookup( normalizedData.entities.backgroundMedia, state.backgroundMedia, false );
    restoreBackgroundMediaOrderingFromNormalizrData( backgroundMediaState, normalizedData );
    return {
        ...state,
        backgroundMedia: backgroundMediaState,
    };
}

function restoreBackgroundMediaOrderingFromNormalizrData( backgroundMediaState: BackgroundMediaState, data: NormalizrData )
{
    backgroundMediaState.ids = data.result;
}

export function getDesignFramesFromAPI( state: StoreState ): DesignFramesAPIItem[]
{
    const dynamicDesignData = get( state, "dynamicDesignData", {} );
    const designFrames = get( dynamicDesignData, "designFrames", {} );
    const ids = get( designFrames, "ids", [] );
    return map( ids, ( id ) => state.dynamicDesignData.designFrames.idToObj[id] );
}

export function getDesignFramesAsAssetChoices( state: StoreState ): AssetChoice[]
{
    return map( getDesignFramesFromAPI( state ), convertDesignFramesToAssetChoice );
}

function convertDesignFramesToAssetChoice( designFrames: DesignFramesAPIItem ): AssetChoice
{
    const thumbnailPath = join( ["overlays", DESIGN_SQUARE_ASPECT_RATIO, "thumbnail_url"], "." );
    return {
        text: designFrames.display_name,
        thumbnail: get( designFrames, thumbnailPath ),
        jsonBlob: {
            text: designFrames.display_name,
            thumbnail: get( designFrames, thumbnailPath ),
            frameStyle: designFrames.frame_style,
            overlays: designFrames.overlays,
        },
    };
}

export function getBackgroundMediaFromAPI( state: StoreState ): BackgroundMediaAPIItem[]
{
    const ids = state.dynamicDesignData.backgroundMedia.ids;
    return map( ids, ( id ) => state.dynamicDesignData.backgroundMedia.idToObj[id] );
}
