import * as React from "react";
import { UserBusiness } from "../../_types";
import CreativeTabColorPickerContainer from "../settingsPages/containers/CreativeTabColorPicker.container";
import CreativeTabBrandFontPickerContainer from "../settingsPages/containers/CreativeTabBrandFontPicker.container";
import BrandTabNoItemsContainer from "./containers/BrandTabNoItemsContainer";
import CreativeTabLogoPanelContainer from "./containers/CreativeTabLogoPanel.container";

export interface CreativeTabProps
{
    currentBusiness: UserBusiness;
    showBrandLogo: boolean;
}

export interface CreativeTabDispatchProps
{
    handleShowBrandLogo: ( enabled: boolean ) => void;
    handleManageLogosClicked: () => void;
}

export class CreativeTab extends React.PureComponent<CreativeTabProps & CreativeTabDispatchProps>
{
    public render()
    {
        return (
            <div className="subNavContent brandTabContent">
                {this.createContent()}
            </div>
        );
    }

    public createContent()
    {
        if ( !this.props.currentBusiness )
        {
            return (
                <div className="subNavInnerWrap centerJustify">
                    <BrandTabNoItemsContainer/>
                </div>
            );
        }
        return (
            <div className="subNavInnerWrap">
                <div className="logoSection">
                    {this.getMyLogosHeader()}
                    <p>Showing recently uploaded logos. Templates in Customize will default to the logo selected below.</p>
                    <CreativeTabLogoPanelContainer/>
                </div>
                <div className="colorsSection">
                    <h2 className="subNavSectionH2">Brand colors</h2>
                    <p>Your templates will default to your brand colors to save you time customizing your posts.</p>
                    <CreativeTabColorPickerContainer/>
                </div>
                <div className="fontsSection">
                    <h2 className="subNavSectionH2">Brand fonts</h2>
                    <p>Templates will automatically incorporate your fonts to save you time.</p>
                    <CreativeTabBrandFontPickerContainer/>
                </div>
            </div>
        );
    }

    private getMyLogosHeader = () =>
    {
        const hasLogo = this.props.currentBusiness.has_logo;
        return (
            <div className="logosHeaderRow">
                <h2 className="subNavSectionH2">My logos</h2>
                {
                    hasLogo &&
                    <div className="manageLogosLink" onClick={this.props.handleManageLogosClicked}>Manage logos</div>
                }
            </div>
        );
    }
}
