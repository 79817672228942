import * as React from "react";
import PricePlanRadioButtonContainer from "./containers/PricePlanRadioButton.container";
import { get } from "lodash";
import { PurchaseProgressSpinner } from "./PurchaseProgressSpinner";
import Button from "react-md/lib/Buttons/Button";
import { convertToStripePlanType } from "../helpers";
import CurrencySelectorContainer from "./containers/CurrencySelector.container";

export interface SelectPricePlanSectionProps
{
    monthlyPlan?: StripePlanData;
    annualPlan?: StripePlanData;
    selectedPlan?: StripePlanData;
    shouldShowExchangeRates: boolean;
    arePlansLoading: boolean;
    purchaseButtonText: string;
    shouldShowBackToCompareTiersLink: boolean;
    productType: PricingPlanType;
}

export interface SelectPricePlanSectionDispatchProps
{
    startPurchase: () => void;
    backToCompareTiers: () => void;
    selectPlan: ( plan: StripePlanData ) => void;
}

export class SelectPricePlanSection extends React.PureComponent<SelectPricePlanSectionProps & SelectPricePlanSectionDispatchProps>
{
    public componentDidMount()
    {
        this.selectPlanIfAppSessionStartedWithTypeSpecification();
    }

    public render()
    {
        return (
            <div className="selectPricePlansSection">
                {
                    this.props.arePlansLoading ? this.renderSpinner() : this.renderPricePlans()
                }
            </div>
        );
    }

    private renderPricePlans = () =>
    {
        return (
            <>
                {this.props.shouldShowExchangeRates && <CurrencySelectorContainer/>}
                <div className="priceRadioButtonList">
                    {this.renderPricePlanRadioButton( this.props.monthlyPlan )}
                    {this.renderPricePlanRadioButton( this.props.annualPlan )}
                </div>
                {this.renderGoBackToCompareTiersLink()}
                <Button flat className="purchaseButton"
                        onClick={this.onPurchaseButtonClicked}>{this.props.purchaseButtonText}</Button>
            </>
        );
    }

    private renderSpinner = () =>
    {
        return (
            <div className="purchaseProgressSpinner">
                <PurchaseProgressSpinner/>
            </div>
        );
    }

    private renderPricePlanRadioButton = ( plan: StripePlanData ) =>
    {
        return plan &&
               <PricePlanRadioButtonContainer
                   selectPlan={this.props.selectPlan}
                   plan={plan}
                   showAsMonthlyRate={true}
                   selected={plan.id === get( this.props.selectedPlan, "id" )}/>;
    }

    private renderGoBackToCompareTiersLink = () =>
    {
        if ( this.props.shouldShowBackToCompareTiersLink )
        {
            return (
                <div className="goBackText clickable"
                     onClick={this.handleBackToCompareClicked}>Go back to compare plan options</div>
            );
        }
    }

    private onPurchaseButtonClicked = () =>
    {
        this.props.startPurchase();
    }

    private handleBackToCompareClicked = () =>
    {
        if ( this.props.backToCompareTiers )
        {
            this.props.backToCompareTiers();
        }
    }

    private selectPlanIfAppSessionStartedWithTypeSpecification = () =>
    {
        const type = convertToStripePlanType( this.props.productType );
        const selectedPlan = this.props.selectedPlan;

        this.selectPlanIfMatchesProductTypeAndNotAlreadySelected( this.props.monthlyPlan, type, selectedPlan );
        this.selectPlanIfMatchesProductTypeAndNotAlreadySelected( this.props.annualPlan, type, selectedPlan );
    }

    private selectPlanIfMatchesProductTypeAndNotAlreadySelected = ( plan: StripePlanData,
                                                                    productType: StripePlanType,
                                                                    selectedPlan: StripePlanData ) =>
    {
        if ( plan && plan.type === productType && (selectedPlan && selectedPlan.id !== plan.id) )
        {
            this.props.selectPlan( plan );
        }
    }
}
