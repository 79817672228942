import { connect } from "react-redux";
import { StoreState } from "../../_types";
import { VideoElement, VideoElementContainerProps } from "../VideoElement";

const mapStateToProps = ( storeState: StoreState, ownProps: VideoElementContainerProps ): VideoElementContainerProps =>
{
    return {
        ...ownProps,
    };
};

export default connect(
    mapStateToProps,
)( VideoElement );
