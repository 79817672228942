import { createAction } from "redux-actions";
import { ActionCreator, LightboxDialogIdentifierForKey } from "../helpers";
import {
    AlertDialogContents,
    ErrorDialogContents,
    FullPageDialogContents,
    LightboxContents,
    LoadingDialogContents,
    LowResErrorDialogContents,
} from "../_types";

const actionCreator = new ActionCreator( "MODAL" );

const modalActionTypes = {
    errorDialog: {
        open: actionCreator.generateType( "ERROR_DIALOG", "OPEN" ),
        close: actionCreator.generateType( "ERROR_DIALOG", "CLOSE" ),
    },
    lowResErrorDialog: {
        open: actionCreator.generateType( "LOWRES_ERROR_DIALOG", "OPEN" ),
        close: actionCreator.generateType( "LOWRES_ERROR_DIALOG", "CLOSE" ),
    },
    alertDialog: {
        open: actionCreator.generateType( "ALERT_DIALOG", "OPEN" ),
        close: actionCreator.generateType( "ALERT_DIALOG", "CLOSE" ),
    },
    lightboxDialog: {
        opened: actionCreator.generateType( "LIGHTBOX_DIALOG", "OPENED" ),
        closed: actionCreator.generateType( "LIGHTBOX_DIALOG", "CLOSED" ),
        closedByIdentifier: actionCreator.generateType( "LIGHTBOX_DIALOG", "CLOSED_BY_IDENTIFIER" ),
        closedAll: actionCreator.generateType( "LIGHTBOX_DIALOG", "CLOSED_ALL" ),
        updated: actionCreator.generateType( "LIGHTBOX_DIALOG", "UPDATED" ),
    },
    fullpageDialog: {
        open: actionCreator.generateType( "FULLPAGE_DIALOG", "OPEN" ),
        close: actionCreator.generateType( "FULLPAGE_DIALOG", "CLOSE" ),
        closeAll: actionCreator.generateType( "FULLPAGE_DIALOG", "CLOSE_ALL" ),
    },
    loadingDialog: {
        open: actionCreator.generateType( "LOADING_DIALOG", "OPEN" ),
        close: actionCreator.generateType( "LOADING_DIALOG", "CLOSE" ),
    },
    all: {
        forceUpdate: actionCreator.generateType( "ALL", "FORCE_UPDATE" ),
    },
};

export const modalActions = {
    errorDialogOpen: createAction<ErrorDialogContents>( modalActionTypes.errorDialog.open ),
    errorDialogClose: createAction( modalActionTypes.errorDialog.close ),
    lowResErrorDialogOpen: createAction<LowResErrorDialogContents>( modalActionTypes.lowResErrorDialog.open ),
    lowResErrorDialogClose: createAction( modalActionTypes.lowResErrorDialog.close ),
    alertDialogOpen: createAction<AlertDialogContents>( modalActionTypes.alertDialog.open ),
    alertDialogClose: createAction( modalActionTypes.alertDialog.close ),
    lightboxDialogUpdate: createAction<LightboxContents>( modalActionTypes.lightboxDialog.updated ),
    lightboxDialogOpened: createAction<LightboxContents>( modalActionTypes.lightboxDialog.opened ),
    lightboxDialogClosed: createAction<number>( modalActionTypes.lightboxDialog.closed ),
    lightboxDialogClosedByIdentifier: createAction<LightboxDialogIdentifierForKey>( modalActionTypes.lightboxDialog.closedByIdentifier ),
    lightboxDialogClosedAll: createAction( modalActionTypes.lightboxDialog.closedAll ),
    fullpageDialogOpen: createAction<FullPageDialogContents>( modalActionTypes.fullpageDialog.open ),
    fullpageDialogClose: createAction<number>( modalActionTypes.fullpageDialog.close ),
    fullpageDialogCloseAll: createAction( modalActionTypes.fullpageDialog.closeAll ),
    loadingDialogOpen: createAction<LoadingDialogContents>( modalActionTypes.loadingDialog.open ),
    loadingDialogClose: createAction( modalActionTypes.loadingDialog.close ),
    allForceUpdate: createAction( modalActionTypes.all.forceUpdate ),
};
