import { connect } from "react-redux";
import { Dispatch } from "redux";
import { MediaData, StoreState } from "../../_types";
import { FontFileUploadButton, FontFileUploadButtonDispatchProps, FontFileUploadButtonProps } from "../settingsPages/FontFileUploadButton";
import { ALLOWED_FONT_EXTENSIONS, ERROR_TITLE_SORRY, errorReporting, readFontFromFileBuffer, stringUtils } from "../../helpers";
import { includes } from "lodash";
import { modalServices, userBusinessServices } from "../../services";
import { Font } from "opentype.js";
import { uiActions } from "../../actions";

const mapStateToProps = ( storeState: StoreState, ownProps: FontFileUploadButtonProps ): FontFileUploadButtonProps =>
{
    return {
        ...ownProps,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): FontFileUploadButtonDispatchProps =>
{
    return {
        onUploadCustomFont: async ( file: File, ownProps: FontFileUploadButtonProps ) =>
        {
            const filename = file.name;
            const dotFileExtension = "." + stringUtils.getFileExt( filename );
            if ( !includes( ALLOWED_FONT_EXTENSIONS, dotFileExtension ) )
            {
                dispatch( modalServices.openErrorDialog( ERROR_TITLE_SORRY,
                    `Can only upload the following types of font files: (${ALLOWED_FONT_EXTENSIONS.join( ", " )})` ),
                );
                return;
            }

            try
            {
                const font = await readFontFromFileBuffer( file ) as Font;
                const bestFontName = font.names.postScriptName.en || font.names.fontFamily.en;
                const sanitizedFontName = stringUtils.sanitizeFileName( bestFontName );

                const mediaData: MediaData = {
                    filename: sanitizedFontName + dotFileExtension,
                    file,
                    type: ownProps.mediaType,
                };
                const remoteFileData = await dispatch( userBusinessServices.uploadFont( mediaData ) );

                await dispatch( uiActions.updateBusinessInfo( {
                    [ownProps.fontField]: sanitizedFontName,
                    [ownProps.fontUrlField]: remoteFileData.s3_direct_url,
                    [ownProps.fontCssUrlField]: remoteFileData.media_object && remoteFileData.media_object.css_url,
                } ) );

                if ( ownProps.onUpdatedFont )
                {
                    await ownProps.onUpdatedFont();
                }
            }
            catch (e)
            {
                dispatch( modalServices.openErrorDialog( ERROR_TITLE_SORRY, `There was an error uploading the selected font.` ) );
                errorReporting.reportErrorToSentry( e );
            }
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( FontFileUploadButton );
