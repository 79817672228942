import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
    getAnnualPlanForSelectedTier,
    getExchangeRateData,
    getMonthlyPlanForSelectedTier,
    getSelectedPlan,
    hasUsedTrial,
} from "../../ducks/pricing";
import { modalServices, pricingServices, upsellServices } from "../../services";
import { SelectPricePlansDialog, SelectPricePlansDialogDispatchProps, SelectPricePlansDialogProps } from "../SelectPricePlansDialog";
import { DialogControlProps, StoreState } from "../../_types";
import { SubscriptionFlowSource } from "../../helpers";

export interface SelectPricePlansDialogContainerProps extends DialogControlProps
{
    source: SubscriptionFlowSource;
    selectedTier: ProductTier;
}

const mapStateToProps = ( storeState: StoreState, ownProps: SelectPricePlansDialogContainerProps ): SelectPricePlansDialogProps =>
{
    return {
        annualPlan: getAnnualPlanForSelectedTier( storeState ),
        monthlyPlan: getMonthlyPlanForSelectedTier( storeState ),
        selectedPlan: getSelectedPlan( storeState ),
        selectedTier: ownProps.selectedTier,
        hasUsedTrial: hasUsedTrial( storeState ),
        shouldShowExchangeRates: !!getExchangeRateData( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: SelectPricePlansDialogContainerProps ): SelectPricePlansDialogDispatchProps =>
{
    return {
        showPaymentDialog: () =>
        {
            ownProps.closeDialog();
            dispatch( pricingServices.showPaymentFormDialogWithTestDrive() );
        },
        backToTierSelection: () =>
        {
            ownProps.closeDialog();
            dispatch( upsellServices.displaySelectPriceTiersDialog( ownProps.source ) );
        },
        handleCancel()
        {
            ownProps.closeDialog();
            dispatch( modalServices.openAcceptTestDriveDialog() );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( SelectPricePlansDialog );
