import * as React from "react";
import { SingleSlideSummary } from "../_types";
import { SortableContainerProps, SortEndHandler } from "react-sortable-hoc";
import { SlideSummaryItemGridSortable } from "./SlideSummaryItemGrid";
import CircularProgress from "react-md/lib/Progress/CircularProgress";

export interface SlideSummaryPanelProps extends SortableContainerProps
{
    slideSummary: SingleSlideSummary[];
}

export interface SlideSummaryPanelDispatchProps
{
    selectSlide: ( slideId: string ) => void;
    handleOnSortEnd: SortEndHandler;
}

export class SlideSummaryPanel extends React.PureComponent<SlideSummaryPanelProps & SlideSummaryPanelDispatchProps>
{
    public render()
    {
        return (
            <div className="slideSummaryPanelContainer">
                {this.props.slideSummary && <SlideSummaryItemGridSortable slideSummary={this.props.slideSummary}
                                                                          selectSlide={this.props.selectSlide}
                                                                          onSortEnd={this.props.handleOnSortEnd}
                                                                          distance={5}
                                                                          axis="x"/>}
                {!this.props.slideSummary &&
                 <CircularProgress id={"slideSummaryPanelSpinner"} className={"spinner medium"}/>}
            </div>);
    }
}
