import { filter, isEmpty, isNil, map, mapValues, union, values } from "lodash";
import { Action } from "redux-actions";
import { mixModelActions, musicCatalogActions } from "../actions";
import { isMusicTypeEpidemicSound, musicHelper, musicSchema, ReducerCreator, stringUtils, updateLookupWithAction } from "../helpers";
import { isVideoAudioSelected, safeParseData } from "./mixModel";
import { createDefaultDataLookup } from "./dataLookup";
import { Design, Music, MusicCatalogState, Post, StoreState } from "../_types";
import { AddMediaAPIResponse, CUSTOM_MUSIC_CATEGORY, EpidemicSoundTrack, VIDEO_AUDIO_DISPLAY_NAME } from "../_types/api";
import { getEpidemicSoundTrackByIdAsMusic } from "./epidemicSoundCatalog";
import { isPlayingAnyAudio } from "./ui";

const defaultState: MusicCatalogState = {
    ...createDefaultDataLookup(),
    musicIdByCategory: {},
    musicCategories: [],
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( musicCatalogActions.loadCatalogSuccess, updateMusicData );
reducerCreator.addAction( musicCatalogActions.loadCustomMusic, handleLoadCustomMusic );
reducerCreator.addAction( mixModelActions.importPostDataFromUserPost, handleLoadCustomMusicFromPostData );
reducerCreator.addAction( mixModelActions.resumeDraft, handleLoadCustomMusicFromPostData );
export default reducerCreator.createReducer();

function updateMusicData( state: MusicCatalogState, action: Action<NormalizrData> ): MusicCatalogState
{
    const updatedState = updateLookupWithAction( musicSchema, action, state );
    const catalog = action.payload.entities.musics as { [id: string]: Music };
    const musicWithCategories = filter( values( catalog ), ( music ) => music.category );
    const musicIdByCategory = {};
    musicWithCategories.forEach( ( music ) =>
    {
        const existingIds = musicIdByCategory[music.category] || [];
        musicIdByCategory[music.category] = [...existingIds, music.id];
    } );
    return {
        ...updatedState,
        musicCategories: union( updatedState.musicCategories, Object.keys( musicIdByCategory ) ).sort(),
        musicIdByCategory: {
            ...updatedState.musicIdByCategory,
            ...musicIdByCategory,
        },
    };
}

function handleLoadCustomMusic( state: MusicCatalogState, action: Action<AddMediaAPIResponse> ): MusicCatalogState
{
    return {
        ...state,
        customMusic: {
            display_name: decodeURIComponent( stringUtils.getFileName( action.payload.s3_direct_url ) ),
            audio_url: action.payload.s3_direct_url,
            category: CUSTOM_MUSIC_CATEGORY,
        },
    };
}

function handleLoadCustomMusicFromPostData( state: MusicCatalogState, action: Action<Post> ): MusicCatalogState
{
    const musicType = action.payload.music_type;
    const musicData = safeParseData( action.payload.music_settings_data );
    return {
        ...state,
        customMusic: musicHelper.isMusicTypePersonal( musicType ) ? { ...musicData, category: CUSTOM_MUSIC_CATEGORY } : null,
    };
}

export function getMusicForDesigns( state: StoreState, design: Design ): Music[]
{
    if ( !design )
    {
        return [];
    }
    return [
        ...buildMusicArray( state, design.free_music_ids, false ),
        ...buildMusicArray( state, design.premium_music_ids, true ),
    ];
}

function buildMusicArray( state: StoreState, musicIds: number[], isPro: boolean )
{
    return filter( musicIds.map<Music>( ( musicId ) =>
    {
        const music = state.musicCatalog.idToObj[musicId];
        if ( music )
        {
            return {
                ...music,
                isPro,
            };
        }
        return null;
    } ) );
}

export function getCatalogMusicById( state: StoreState, musicId: number ): Music
{
    return state.musicCatalog.idToObj[musicId];
}

export function getCurrentMusic( state: StoreState ): Music
{
    const { mixModel } = state;
    if ( isMusicTypeEpidemicSound( mixModel.musicType ) )
    {
        return getEpidemicSoundTrackByIdAsMusic( state, mixModel.musicId as string );
    }
    else if ( typeof mixModel.musicId === "number" )
    {
        return getCatalogMusicById( state, mixModel.musicId as number );
    }
    return mixModel.customMusic;
}

export function isEpidemicSoundTrackSelected( state: StoreState, epidemicSoundTrack: EpidemicSoundTrack ): boolean
{
    const currentMusic = getCurrentMusic( state );

    if ( !isNil( currentMusic ) )
    {
        return isMusicTypeEpidemicSound( currentMusic.type ) && (currentMusic.id === epidemicSoundTrack.epidemic_id);
    }
    else
    {
        return false;
    }
}

export function isEpidemicSoundTrackPlaying( state: StoreState, epidemicSoundTrack: EpidemicSoundTrack ): boolean
{
    const isSelected = isEpidemicSoundTrackSelected( state, epidemicSoundTrack );
    return isSelected && isPlayingAnyAudio( state );
}

export function getCurrentMusicCategory( state: StoreState ): string
{
    const theCurrentMusic = getCurrentMusic( state );
    return theCurrentMusic ? theCurrentMusic.category : undefined;
}

export function getMusicTitle( state: StoreState )
{
    const musicId = state.mixModel.musicId;
    if ( musicId )
    {
        const music = state.musicCatalog.idToObj[musicId];
        if ( music )
        {
            return music.display_name;
        }
        return "NoMusicForId";
    }
    else if ( isVideoAudioSelected( state ) )
    {
        return VIDEO_AUDIO_DISPLAY_NAME;
    }
}

export function getCategoryListWithMusic( state: StoreState ): string[]
{
    const { musicCategories } = state.musicCatalog;
    const musicByCategory = getMusicByCategory( state );
    const customMusic = getCustomMusicFromMusicCatalog( state );
    const categoriesWithMusic = filter( musicCategories, ( category ) =>
    {
        const music = musicByCategory[category];
        return !isEmpty( music );
    } );

    if ( customMusic )
    {
        categoriesWithMusic.unshift( customMusic.category );
    }
    return categoriesWithMusic;
}

export function getMusicByCategory( state: StoreState ): { [category: string]: Music[] }
{
    const catalogMusic = mapValues( state.musicCatalog.musicIdByCategory, ( musicIds ) =>
    {
        return filter( map( musicIds, ( musicId ) => state.musicCatalog.idToObj[musicId.toString()] ) );
    } );
    return { [CUSTOM_MUSIC_CATEGORY]: [getCustomMusicFromMusicCatalog( state )], ...catalogMusic };
}

export function getCustomMusicFromMusicCatalog( state: StoreState ): Music
{
    return state.musicCatalog.customMusic;
}

export function areMusicItemsEqual( item1: Music, item2: Music )
{
    if ( item1 && item2 )
    {
        return item1.display_name === item2.display_name &&
               item1.category === item2.category &&
               item1.audio_url === item2.audio_url;
    }
    return !(item1 || item2);
}
