import { connect } from "react-redux";
import { Dispatch } from "redux";
import { CantRefreshButton, CantRefreshButtonDispatchProps, CantRefreshButtonProps } from "../CantRefreshButton";
import { modalServices } from "../../services";
import { StoreState } from "../../_types";
import { LightboxDialogIdentifierForKey } from "../../helpers";

const mapStateToProps = ( storeState: StoreState, ownProps: CantRefreshButtonProps ): CantRefreshButtonProps =>
{
    return {
        ...ownProps,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): CantRefreshButtonDispatchProps =>
{
    return {
        onInfoClick: () =>
        {
            dispatch( modalServices.openLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.RECONNECT_FACEBOOK,
                title: "We Need Help to Reconnect",
                width: 400,
                content: `Please ask the Facebook account owner to reconnect the account in Ripl so you can post to it.`,
                confirmLabel: "Okay",
                hideCancel: true,
            } ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( CantRefreshButton );
