import { StoreState } from "../../../_types";
import { connect } from "react-redux";
import SubscriptionTermsPage, { SubscriptionTermsPageProps } from "../../SubscriptionTermsPage";
import { canUserAccessPaidFeatures, hasAcceptedTestDriveTerms } from "../../../ducks/user";

const mapStateToProps = ( storeState: StoreState ): SubscriptionTermsPageProps =>
{
    return {
        isPayingUserOrAcceptedProPreview: canUserAccessPaidFeatures( storeState ) || hasAcceptedTestDriveTerms( storeState ),
    };
};

export default connect(
    mapStateToProps,
)( SubscriptionTermsPage );
