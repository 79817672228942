import { Dispatch } from "redux";
import { errorReporting, requests } from "../helpers";
import { StoreState } from "../_types";
import { trackingActions } from "../actions";
import { pricingServices } from "./";

const GEOLOCATION_API_URL = "https://ipgeolocation.abstractapi.com/v1/?api_key=47085b7099aa4db987b584a9a202a99b";

export const contentBannerServices = {
    retrieveCountryCode,
};

function retrieveCountryCode()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        return requests.getExternalJson( GEOLOCATION_API_URL ).then(
            ( data ) =>
            {
                if ( data )
                {
                    const countryCode = data.country_code;
                    dispatch( trackingActions.countryCodeRetrieved( countryCode ) );
                    dispatch( pricingServices.setDefaultCurrency() );
                }
            },
        ).catch( ( error ) =>
        {
            errorReporting.reportErrorToSentry( `Failed to lookup Country Code at ${GEOLOCATION_API_URL} (${error})` );
        } );
    };
}
