import { connect } from "react-redux";
import { Dispatch } from "redux";
import { uiActions } from "../../actions";
import { LoginPage, LoginPageDispatchProps, LoginPageProps } from "../LoginPage";
import {
    ACCOUNT_CREATED,
    BUSINESS_PICKER_SOURCE_LOGIN,
    BUSINESS_PICKER_SOURCE_ONBOARDING,
    eventTracker,
    LOGGED_IN,
    windowLocationHelper,
} from "../../helpers";
import { getSelectedBusinessTypeId, getThirdPartyErrorMessage, isAcceptTeamInviteFlow, shouldShowThirdPartyLoginError } from "../../ducks/ui";
import { StoreState } from "../../_types";
import { store } from "../../store";
import { getBusinessType } from "../../ducks/businessTypes";
import { hasLoggedInBefore } from "../../ducks/login";
import { businessTypesServices, pricingServices, userServices } from "../../services";
import { getCurrentBusiness } from "../../ducks/userBusiness";

const mapStateToProps = ( storeState: StoreState ): LoginPageProps =>
{
    return {
        hasLoggedInBefore: hasLoggedInBefore( storeState ),
        firstRun: windowLocationHelper.hasFirstRunQuery(),
        showForgotPassword: windowLocationHelper.hasForgotPasswordQuery(),
        hasSelectedBusinessType: !!getSelectedBusinessTypeId( storeState ),
        shouldShowThirdPartyLoginError: shouldShowThirdPartyLoginError( storeState ),
        thirdPartyLoginErrorMessage: getThirdPartyErrorMessage( storeState ),
        doesUserHaveABusiness: !!getCurrentBusiness( storeState ),
        isAcceptTeamInviteFlow: isAcceptTeamInviteFlow( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): LoginPageDispatchProps =>
{
    return {
        resetErrors: () =>
        {
            dispatch( uiActions.setEmailFormErrors( {} ) );
            dispatch( uiActions.setThirdPartyLoginError( { message: "", shouldShowError: false } ) );
        },
        clearSelectedBusinessInfo: () =>
        {
            dispatch( uiActions.clearBusinessInfo() );
        },
        trackInitialBusinessPickerDisplayed: () =>
        {
            eventTracker.logBusinessPickerDisplayed(
                windowLocationHelper.hasFirstRunQuery() ? BUSINESS_PICKER_SOURCE_ONBOARDING : BUSINESS_PICKER_SOURCE_LOGIN );
        },
        trackInitialBusinessTypeSelected: () =>
        {
            const state = store.getState();
            eventTracker.logBusinessTypeSelected(
                windowLocationHelper.hasFirstRunQuery() ? BUSINESS_PICKER_SOURCE_ONBOARDING : BUSINESS_PICKER_SOURCE_LOGIN,
                getBusinessType( state, getSelectedBusinessTypeId( state ) ) );
        },
        finishCreatingAccount: async () =>
        {
            await userServices.createBusinessAndFinish( ACCOUNT_CREATED, dispatch );
        },
        finishLoggingIn: async () =>
        {
            await userServices.createBusinessAndFinish( LOGGED_IN, dispatch );
        },
        loadServices: () =>
        {
            dispatch( businessTypesServices.load() );
            dispatch( pricingServices.load() );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( LoginPage );
