import { connect } from "react-redux";
import { UsersTabTable, UsersTabTableProps } from "../UsersTabTable";
import { StoreState } from "../../../../_types";
import {
    getTeamInvitesForCurrentBusiness,
    getTeamMembersForCurrentBusiness,
    hasTeamAdminPermissionsForCurrentBusiness,
} from "../../../../ducks/userBusiness";

const mapStateToProps = ( storeState: StoreState ): UsersTabTableProps =>
{
    return {
        showManageColumn: hasTeamAdminPermissionsForCurrentBusiness( storeState ),
        members: getTeamMembersForCurrentBusiness( storeState ),
        invitations: getTeamInvitesForCurrentBusiness( storeState ),
    };
};

export default connect(
    mapStateToProps,
)( UsersTabTable );
