import { connect } from "react-redux";
import { Dispatch } from "redux";
import { getCurrentBusiness } from "../../../ducks/userBusiness";
import {
    COLLECTION_PAGE_URL,
    customizationHelper,
    DEEP_LINK_SOURCE,
    eventTracker,
    history,
    HOME_PAGE_ALERT_ALREADY_SUBSCRIBED,
    HOME_SCREEN_DISPLAYED,
} from "../../../helpers";
import HomePage, { HomePageDispatchProps, HomePageProps } from "../HomePage";
import { canUserAccessPaidFeatures, hasAcceptedTestDriveTerms, hasSeenInspirePageBefore, isCollabraUser, isUserLoggedIn } from "../../../ducks/user";
import { collectionsServices, contentSearchServices, modalServices, pricingServices, userBusinessServices } from "../../../services";
import { collectionActions, contentSearchActions, uiActions, userActions } from "../../../actions";
import { StoreState } from "../../../_types";
import { store } from "../../../store";
import { getHomePageAlert, isFacebookAdMobileWebView } from "../../../ducks/ui";

const mapStateToProps = ( storeState: StoreState ): HomePageProps =>
{
    return {
        currentBusiness: getCurrentBusiness( storeState ),
        isUserLoggedIn: isUserLoggedIn( store.getState() ),
        isPayingUserOrAcceptedProPreview: canUserAccessPaidFeatures( storeState ) || hasAcceptedTestDriveTerms( storeState ),
        isPayingUser: canUserAccessPaidFeatures( storeState ),
        shouldRenderForMobileAds: isFacebookAdMobileWebView( storeState ),
        shouldShowCollabraUI: isCollabraUser( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): HomePageDispatchProps =>
{
    return {
        onPageLoaded: () =>
        {
            const state = store.getState();
            if ( isUserLoggedIn( state ) )
            {
                eventTracker.logEvent( HOME_SCREEN_DISPLAYED );

                if ( !hasSeenInspirePageBefore( state ) )
                {
                    eventTracker.logFirstRunInspireDisplayed();
                    dispatch( userActions.seenInspirePage() );
                }
            }
        },
        handleAcceptInvite: ( token: string ) =>
        {
            dispatch( userBusinessServices.acceptTeamInvite( token ) );
        },
        handleManageSubscription: () =>
        {
            dispatch( modalServices.showManageSubscriptionMessage( "Subscription Status",
                "Click the button below to check the details of your subscription",
                "Manage Subscription" ) );
        },
        handleDisplayHomePageAlert: () =>
        {
            const homePageAlert = getHomePageAlert( store.getState() );
            if ( homePageAlert === HOME_PAGE_ALERT_ALREADY_SUBSCRIBED )
            {
                dispatch( pricingServices.showWelcomeBackDialog( "Welcome Back",
                    "We can't wait to see you do more with Ripl",
                    () =>
                    {
                        dispatch( uiActions.setHomePageAlert( undefined ) );
                    } ) );
            }
        },
        handleOpenExamplePostFromDeeplink: ( postId: string, designSlugFromUrl: string ) =>
        {
            customizationHelper.openExamplePostFromDeeplink( dispatch, postId, designSlugFromUrl );
        },
        handleOpenDesignFromDeeplink: ( designSlug: string ) =>
        {
            customizationHelper.openDesignFromDeeplink( designSlug );
        },
        handleOpenCollectionFromDeepLink: ( collectionSlug: string ) =>
        {
            eventTracker.logOpenUrlForCollection( collectionSlug );
            dispatch( collectionsServices.openCollection( collectionSlug, DEEP_LINK_SOURCE ) );
            history.push( COLLECTION_PAGE_URL );
        },
        handleOpenContentSearchFromDeepLink: ( searchTerm: string ) =>
        {
            eventTracker.logOpenUrlForContentSearch( searchTerm );
            dispatch( contentSearchServices.searchContent( searchTerm ) );
        },
        clearStateForHomeLoad: () =>
        {
            dispatch( contentSearchActions.searchModeExited() );
            dispatch( collectionActions.collectionCleared() );
        },
        handleClosingAllModals: () =>
        {
            dispatch( modalServices.closeAllLightboxes() );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( HomePage );
