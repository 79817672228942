import * as React from "react";
import { DetailListItem } from "../DetailListItem";
import { EpidemicSoundCollectionDetailAPI } from "../../../_types/api";

export interface EpidemicSoundCollectionItemProps
{
    epidemicSoundCollection: EpidemicSoundCollectionDetailAPI;
    onClick();
}

export interface EpidemicSoundCollectionItemDispatchProps
{
    loadCollectionDetails( epidemicCollectionId: string );
}

export class EpidemicSoundCollectionItem extends React.PureComponent<EpidemicSoundCollectionItemProps & EpidemicSoundCollectionItemDispatchProps>
{
    public render()
    {
        return (<DetailListItem
            title={this.props.epidemicSoundCollection.name}
            iconSrc={this.props.epidemicSoundCollection.image}
            onClick={this.onClick}
            showNextChevron
            outlineImage
        />);
    }

    private onClick = () =>
    {
        this.props.onClick();
        this.props.loadCollectionDetails( this.props.epidemicSoundCollection.id );
    }
}
