import * as React from "react";
import TextField from "react-md/lib/TextFields/TextField";
import SelectionControlGroup from "react-md/lib/SelectionControls/SelectionControlGroup";
import FontIcon from "react-md/lib/FontIcons/FontIcon";
import { map, startCase, trim } from "lodash";
import { ExternalMediaData, StockMediaSearchType } from "../../_types";
import { ExternalMediaCell } from "./ExternalMediaCell";
import { SEARCH_NO_RESULTS_TEXT, STOCK_MEDIA_SEARCH_PHOTOS_TYPE, STOCK_MEDIA_SEARCH_VIDEOS_TYPE } from "../../helpers";
import CircularProgress from "react-md/lib/Progress/CircularProgress";
import classNames from "classnames";
import { ErrorPanel } from "../ErrorPanel";
import * as noResultsImg from "../../assets/img/stock_media_no_results.png";
import * as videoNotSupportedImg from "../../assets/img/stock_media_video_not_supported.png";
import * as serverErrorImg from "../../assets/img/server_error.png";
import * as InfiniteScroll from "react-infinite-scroller";

export interface StockMediaPickerProps
{
    imageTrayClassNames: {};
    searchResults: ExternalMediaData[];
    totalCount: number;
    externalMediaUploadStatus: { [id: string]: boolean };
    designSupportsVideo: boolean;
    hasError?: boolean;
    searchTerm?: string;
    defaultStockMediaSearchTerm: string;
    shouldShowDefaultStockMediaSearchResults: boolean;
    selectedMediaType: StockMediaSearchType;
    stockMediaAttribution?: string;
    stockMediaAttributionLink?: string;
}

export interface StockMediaPickerDispatchProps
{
    onSearchSubmitted: ( selectedMediaType: StockMediaSearchType, searchTerm: string, isUserInitiatedSearch: boolean ) => void;
    onStockMediaClicked: ( stockMediaData: ExternalMediaData ) => void;
    loadMore: ( selectedMediaType: StockMediaSearchType, searchTerm: string, page: number ) => void;
    updateSelectedMediaType: ( StockMediaSearchType: string ) => void;
    updateShowDefaultSearchTerm: () => void;
}

export interface StockMediaPickerState
{
    searchTerm: string;
    lastSearchTerm: string;
}

export class StockMediaPicker extends React.PureComponent<StockMediaPickerProps & StockMediaPickerDispatchProps, StockMediaPickerState>
{
    constructor( props )
    {
        super( props );
        this.state = {
            searchTerm: this.props.searchTerm,
            lastSearchTerm: this.props.searchTerm,
        };
    }

    public componentDidMount(): void
    {
        if ( this.props.shouldShowDefaultStockMediaSearchResults )
        {
            this.initialSearch();
        }
    }

    public componentDidUpdate( previousProps: StockMediaPickerProps ): void
    {
        if ( this.props.shouldShowDefaultStockMediaSearchResults !== previousProps.shouldShowDefaultStockMediaSearchResults )
        {
            if ( this.props.shouldShowDefaultStockMediaSearchResults )
            {
                this.initialSearch();
            }
        }
    }

    public render()
    {
        return (
            <div className={classNames( "stockMediaContainer", { ...this.props.imageTrayClassNames } )}>
                {this.createSearchBar()}
                <div className="stockMediaAttribution">
                    <a className="standardLink" href={this.props.stockMediaAttributionLink} target="_blank">{this.props.stockMediaAttribution}</a>
                </div>
                {this.createNoResults()}
                {this.createSearchResults()}
            </div>
        );
    }

    private createSearchBar = () =>
    {
        return <form className="searchBarContainer" onSubmit={this.handleOnSubmit}>
            <div className="inputWrap">
                <TextField
                    id="search-bar"
                    className="searchBar"
                    placeholder="Search Stock Media"
                    value={this.props.shouldShowDefaultStockMediaSearchResults ? "" : this.state.searchTerm}
                    onChange={this.handleOnChange}/>
                <FontIcon className="search">search</FontIcon>
                {this.showBannerOrClear()}
            </div>
            <SelectionControlGroup
                id="search-types"
                name="stockMediaSearchType"
                className="stockMediaSearchType"
                type="radio"
                defaultValue={this.props.selectedMediaType}
                onChange={this.handleOnSelect}
                controls={[
                    { label: startCase( STOCK_MEDIA_SEARCH_PHOTOS_TYPE ), value: STOCK_MEDIA_SEARCH_PHOTOS_TYPE },
                    { label: startCase( STOCK_MEDIA_SEARCH_VIDEOS_TYPE ), value: STOCK_MEDIA_SEARCH_VIDEOS_TYPE },
                ]}/>
        </form>;
    }

    private loadMore = ( page: number ) =>
    {
        this.props.loadMore( this.props.selectedMediaType, this.state.searchTerm, page );
    }

    private createSearchResults = () =>
    {
        if ( this.shouldDisplaySearchResults() )
        {
            return <div className="stockMediaImageContainer externalMediaGridContainer">
                {this.isSearchTermUpToDate() &&
                 <InfiniteScroll
                     pageStart={1}
                     loadMore={this.loadMore}
                     hasMore={this.props.searchResults && this.props.searchResults.length < this.props.totalCount}
                     loader={<CircularProgress key="0" className="spinner small" id="spinner"/>}
                     useWindow={false}>
                     {this.shouldShowProgressSpinner() && <CircularProgress className="searchSpinner small" id="spinner"/>}
                     {
                         map( this.props.searchResults, ( item ) =>
                         {
                             return <ExternalMediaCell key={item.external_media_id}
                                                       externalMediaData={item}
                                                       onExternalMediaClicked={this.props.onStockMediaClicked}
                                                       externalMediaUploadStatus={this.props.externalMediaUploadStatus}/>;
                         } )
                     }
                     {
                         this.shouldShowNoMoreSearchResults() &&
                         <div className="endResults">End of results, search again</div>
                     }
                 </InfiniteScroll>
                }
            </div>;
        }
    }

    private shouldShowNoMoreSearchResults()
    {
        const resultCount = this.props.searchResults && this.props.searchResults.length;
        return resultCount >= this.props.totalCount && resultCount > 0 && this.props.searchResults !== null;
    }

    private shouldShowProgressSpinner = () =>
    {
        return !!this.state.lastSearchTerm && this.props.searchResults === null && !this.props.hasError;
    };

    private showBannerOrClear = () =>
    {
        if ( trim( this.state.searchTerm ) && !this.props.shouldShowDefaultStockMediaSearchResults )
        {
            return <FontIcon className="clear" onClick={this.handleClearClicked}>clear</FontIcon>;
        }
    }

    private shouldDisplaySearchResults = () =>
    {
        return this.hasSearchResults() && (this.props.designSupportsVideo && this.isVideoTypeSelected() || this.isPhotoTypeSelected());
    }

    private isPhotoTypeSelected = () =>
    {
        return this.props.selectedMediaType === STOCK_MEDIA_SEARCH_PHOTOS_TYPE;
    }

    private isVideoTypeSelected = () =>
    {
        return this.props.selectedMediaType === STOCK_MEDIA_SEARCH_VIDEOS_TYPE;
    }

    private hasSearchResults = () =>
    {
        return this.props.searchResults && !!this.state.lastSearchTerm;
    }

    private handleOnChange = ( searchTerm: string | number ) =>
    {
        this.props.updateShowDefaultSearchTerm();
        this.setState( { searchTerm: searchTerm as string } );
    }

    private isSearchTermUpToDate = () =>
    {
        return this.state.searchTerm === this.state.lastSearchTerm || this.state.lastSearchTerm === "";
    }

    private handleOnSubmit = ( e ) =>
    {
        e.preventDefault();
        if ( this.state.lastSearchTerm !== this.state.searchTerm )
        {
            this.search();
        }
    }

    private handleOnSelect = async ( selectedMediaType: StockMediaSearchType ) =>
    {
        await this.props.updateSelectedMediaType( selectedMediaType );
        if ( this.state.searchTerm !== "" )
        {
            this.search();
        }
    }

    private search = () =>
    {
        this.props.onSearchSubmitted( this.props.selectedMediaType, this.state.searchTerm, true );
        this.setState( {
            lastSearchTerm: this.state.searchTerm,
        } );
    }

    private initialSearch = () =>
    {
        this.props.onSearchSubmitted( this.props.selectedMediaType, this.props.defaultStockMediaSearchTerm, false );
        this.setState( {
            lastSearchTerm: this.props.defaultStockMediaSearchTerm,
            searchTerm: this.props.defaultStockMediaSearchTerm,
        } );
    }

    private handleClearClicked = () =>
    {
        this.setState( { searchTerm: "" } );
    }

    private createNoResults = () =>
    {
        if ( this.props.hasError )
        {
            const headline = "Unable to get data. Please try again.";
            return <ErrorPanel imageSrc={serverErrorImg} headline={headline} renderHelpLink={true}/>;
        }
        else if ( !this.props.designSupportsVideo && this.isVideoTypeSelected() )
        {
            const headline = "This template does not support videos.";
            const subTitle = "Please choose a different template in the controls menu.";
            return <ErrorPanel imageSrc={videoNotSupportedImg} headline={headline} subTitle={subTitle}/>;
        }
        else if ( this.hasSearchResults() && this.props.searchResults.length === 0 )
        {
            const headline = `No results for "${this.state.lastSearchTerm}"`;
            const subTitle = SEARCH_NO_RESULTS_TEXT;
            return <ErrorPanel imageSrc={noResultsImg} headline={headline} subTitle={subTitle}/>;
        }
    }
}
