import { ENABLE_APPSFLYER, errorReporting } from "./";

export const appsflyerWrapper = {
    identifyUser,
    trackRegistrationEvent,
};

const AppsFlyerProductValue: AppsFlyerProduct = "pba";
const AppsFlyerTypeValue: AppsFlyerType = "event";

const AppsFlyerStandardEventTypeValue: AppsFlyerStandardEventType = "EVENT";
const AppsFlyerIdentifyEventTypeValue: AppsFlyerIdentifyEventType = "IDENTIFY";

function identifyUser( riplUserId: string )
{
    if ( ENABLE_APPSFLYER && window.AF )
    {
        try
        {
            const eventDetails: AppsFlyerIdentifyEventProperties = { eventType: AppsFlyerIdentifyEventTypeValue, customUserId: riplUserId };
            window.AF( AppsFlyerProductValue, AppsFlyerTypeValue, eventDetails );
        }
        catch (e)
        {
            logToConsole( `unable to identify user id ${riplUserId} to appsflyer`, e );
            errorReporting.reportErrorToSentry( e );
        }
    }
}

function trackRegistrationEvent()
{
    trackStandardEvent( { eventName: "registration" } );
}

export function trackStandardEvent( eventProperties: Partial<AppsFlyerStandardEventProperties> )
{
    if ( ENABLE_APPSFLYER && window.AF )
    {
        try
        {
            const { eventName, ...otherProperties } = eventProperties;
            const eventDetails: AppsFlyerStandardEventProperties = { eventType: AppsFlyerStandardEventTypeValue, eventName, ...otherProperties };
            window.AF( AppsFlyerProductValue, AppsFlyerTypeValue, eventDetails );
        }
        catch (e)
        {
            logToConsole( `unable to track event with name ${eventProperties.eventName} on appsflyer`, e );
            errorReporting.reportErrorToSentry( e );
        }
    }
}

function logToConsole( message: string, ...args )
{
    // tslint:disable-next-line:no-console
    console.error( message, args );
}
