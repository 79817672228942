import * as React from "react";
import { VideoTrimmer } from "../videoTrimmer/video-trimmer";
import CircularProgress from "react-md/lib/Progress/CircularProgress";
import * as audioMuted from "../assets/img/audioMuted.png";
import * as audioOn from "../assets/img/audioOn.png";
import * as audioNone from "../assets/img/audioNone.png";
import { convertToNumber, eventTracker, PRO_VIDEO_MAX_DURATION_IN_MS, PRO_VIDEO_MAX_DURATION_SECONDS, stringUtils } from "../helpers";
import { VideoTrimmerCurrentData } from "../_types";
import Button from "react-md/lib/Buttons/Button";

export interface VideoTrimmerProps
{
    videoUrl: string;
    trimmerTimeData: TrimmerTimeData;
    availableTimeExcludingMediaInSeconds: number;
    shouldShowMixedMediaMessage: boolean;
}

export interface VideoTrimmerDispatchProps
{
    handleVideoTrimmerStatusChange: ( videoTrimmerCurrentData: VideoTrimmerCurrentData ) => void;
}

interface TrimmerWarningState
{
    showWarning: boolean;
    showMixedMediaMessage: boolean;
    isLoadingVideo: boolean;
    showErrorMessage: boolean;
}

export class VideoTrimmerWrapper extends React.PureComponent<VideoTrimmerProps & VideoTrimmerDispatchProps, TrimmerWarningState>
{
    private videoTrimmer: VideoTrimmer;

    constructor( props )
    {
        super( props );
        this.state = {
            showWarning: false,
            isLoadingVideo: true,
            showMixedMediaMessage: false,
            showErrorMessage: null,
        };
    }

    public componentDidMount(): void
    {
        this.videoTrimmer = this.instantiateVideoTrimmer();
        this.checkAndUpdateTrimmerStatus( this.videoTrimmer, false );
    }

    public componentWillUnmount(): void
    {
        window.removeEventListener( "resize", this.updateTrimmerOnResize );
        this.videoTrimmer.cleanUp();
    }

    public render()
    {
        return (
            <div id="trimmerModal">
                {
                    this.state.isLoadingVideo &&
                    <CircularProgress className="spinner" id="spinner"/>
                }
                <div id="trimmerContainer">
                </div>
                {this.createMixedMediaMessage()}
                {
                    this.createErrorMessage()
                }
            </div>
        );
    }

    private createErrorMessage()
    {
        return this.state.showErrorMessage &&
               <div className="errorMessage">Failed to load video<br/>
                   <Button
                       flat
                       primary
                       swapTheming
                       onClick={this.reloadVideo}
                   >Reload</Button>
               </div>;
    }

    private instantiateVideoTrimmer(): VideoTrimmer
    {
        const trimmerContainer = document.getElementById( "trimmerContainer" );
        const defaultMaxDuration = PRO_VIDEO_MAX_DURATION_IN_MS;
        const videoUrl = stringUtils.getCORSFriendlyUrl( this.props.videoUrl );
        const trimStartTimeMs = this.props.trimmerTimeData.start;
        const trimEndTimeMs = this.props.trimmerTimeData.end;

        const onTrimSelectionDragged = ( duration ) =>
        {
            const showWarning = duration > defaultMaxDuration;
            this.setState( { showWarning } );
        };

        const trimmerObj = new VideoTrimmer(
            videoUrl,
            trimmerContainer,
            {
                defaultMaxDuration,
                trimmerControlsHeight: 100,
                layoutPadding: 20,
                trimStartTimeMs,
                trimEndTimeMs,
                audioMutedImageUrl: audioMuted,
                audioOnImageUrl: audioOn,
                audioNoneImageUrl: audioNone,
                onTrimSelectionDragged,
            },
        );

        trimmerObj.onVideoTrimmerReady = () =>
        {
            this.checkAndUpdateTrimmerStatus( trimmerObj, true );
            this.onVideoSourceLoaded( trimmerObj, trimStartTimeMs, trimEndTimeMs );
        };

        trimmerObj.onVideoTrimmerFailed = () =>
        {
            this.setState(
                {
                    isLoadingVideo: false,
                    showErrorMessage: true,
                },
            );
        };

        trimmerObj.onTrimSelectionCompleted = () =>
        {
            this.checkAndUpdateTrimmerStatus( trimmerObj, true );
        };

        return trimmerObj;
    }

    private onVideoSourceLoaded = ( videoTrimmer: VideoTrimmer, trimStartTimeMs, trimEndTimeMs ) =>
    {
        eventTracker.logEditVideoOpened();

        const videoDurationInSeconds = convertToNumber( videoTrimmer.getFullVideoDurationInSeconds() );

        const maxClipDuration = Math.min( this.props.availableTimeExcludingMediaInSeconds, videoDurationInSeconds );
        const defaultMaxClipDuration = Math.min( maxClipDuration, PRO_VIDEO_MAX_DURATION_SECONDS );
        const trimStartTimeInSec = trimStartTimeMs / 1000;
        const trimEndTimeInSec = trimEndTimeMs / 1000;
        videoTrimmer.updateClipDuration( maxClipDuration, defaultMaxClipDuration, trimStartTimeInSec, trimEndTimeInSec );

        this.setState(
            {
                isLoadingVideo: false,
                showMixedMediaMessage: this.props.shouldShowMixedMediaMessage,
            },
        );
        this.checkAndUpdateTrimmerStatus( this.videoTrimmer, true );

        window.addEventListener( "resize", this.updateTrimmerOnResize );
        this.updateTrimmerOnResize();
    }

    private updateTrimmerOnResize = () =>
    {
        if ( this.videoTrimmer )
        {
            this.videoTrimmer.updateOnWindowResize();
        }
    }

    private checkAndUpdateTrimmerStatus = ( videoTrimmer: VideoTrimmer, videoTrimmerIsReady: boolean ) =>
    {
        const videoDurationInSeconds = convertToNumber( videoTrimmerIsReady && videoTrimmer.getFullVideoDurationInSeconds() );
        const videoTrimmerTimeData = videoTrimmerIsReady && videoTrimmer.getTrimmerData();

        this.props.handleVideoTrimmerStatusChange( {
            videoTrimmerIsReady,
            videoDurationInSeconds,
            videoTrimmerTimeData,
        } );
    }

    private createMixedMediaMessage = () =>
    {
        return this.state.showMixedMediaMessage && <div className="message mixedMediaMsg">Maximum video duration has been reduced to allow time
                                                                                          for your selected media</div>;
    }

    private reloadVideo = () =>
    {
        this.setState(
            {
                isLoadingVideo: true,
                showErrorMessage: false,
            },
        );
        this.checkAndUpdateTrimmerStatus( this.videoTrimmer, false );
        this.videoTrimmer.reloadVideo();
    }
}
