import * as React from "react";
import PostsPanel from "./PostsPanel";
import { Post, UserBusiness } from "../../_types";
import { ErrorBoundary } from "../ErrorBoundary";
import NoPostsPanelContainer from "./containers/NoPostsPanelContainer";
import { POST_BUTTON_MAP } from "../../helpers";
import CircularProgress from "react-md/lib/Progress/CircularProgress";
import Button from "react-md/lib/Buttons/Button";

export interface PostTabProps
{
    postData: Post[];
    currentBusiness: UserBusiness;
    isLoadMoreRecentPostsInProgress: boolean;
    hasLoadedAllRecentPosts: boolean;
    shouldShowRiplUserSurveyDialog: boolean;
    shouldShowAlkaiCrossPromoDialog: boolean;
}

export interface PostTabDispatchProps
{
    loadData: () => void;
    loadNextPage: () => void;
    showRiplUserSurveyDialog: () => void;
    showAlkaiCrossPromoDialog: () => void;
}

export class PostTab extends React.PureComponent<PostTabProps & PostTabDispatchProps>
{
    public componentDidMount()
    {
        this.props.loadData();
        if (this.props.shouldShowAlkaiCrossPromoDialog)
        {
            this.props.showAlkaiCrossPromoDialog();
        }
        else if ( this.props.shouldShowRiplUserSurveyDialog )
        {
            this.props.showRiplUserSurveyDialog();
        }
    }

    public render()
    {
        return (
            <div className="subNavContent postTabContent">
                <div className="subNavInnerWrap">
                    {this.createContent()}
                </div>
            </div>
        );
    }

    private createContent = () =>
    {
        const postData = this.props.postData;
        if ( postData.length > 0 )
        {
            return (
                <ErrorBoundary errorMessage="There was a problem creating the Post tab. If this continues, click Restart below."
                               showResetButton={true}>
                    <PostsPanel postData={postData}
                                showCreateBlank={true}
                                postButtonTextMap={POST_BUTTON_MAP.MY_POSTS}/>
                    <div className="loadMoreSection">
                        {this.getLoadMoreSection()}
                    </div>
                </ErrorBoundary>
            );
        }
        return <NoPostsPanelContainer/>;
    }

    private getLoadMoreSection()
    {
        if ( this.props.hasLoadedAllRecentPosts )
        {
            return null;
        }
        if ( this.props.isLoadMoreRecentPostsInProgress )
        {
            return <CircularProgress key="0" className="spinner small" id="loadMoreRcentPostsSpinner"/>;
        }
        else
        {
            return <Button flat primary className="outline loadMoreButton" onClick={this.loadNextPage}>Load more</Button>;
        }
    }

    private loadNextPage = () =>
    {
        this.props.loadNextPage();
    }

}
