import { Action } from "redux-actions";
import { normalizePostAPIForMultiplePosts, ReducerCreator, updateLookup } from "../helpers";
import { inspirationsActions, userBusinessActions } from "../actions";
import { filter, forEach, includes, map, merge, pickBy, take, uniq } from "lodash";
import { createDefaultDataLookup } from "./dataLookup";
import { InspirationsState, Post, StoreState } from "../_types";
import { InspirationsAPI } from "../_types/api";

const INSPIRATIONS_PAGE_SIZE = 100;

const defaultState: InspirationsState = {
    ...createDefaultDataLookup(),
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( inspirationsActions.loadDataSuccess, handleLoadInspirationPosts );
reducerCreator.addAction( inspirationsActions.loadedDataClearToPageOne, handleClearLoadedDataToPageOne );
reducerCreator.addAction( inspirationsActions.clearInspirations, handleBusinessDataChanged );
reducerCreator.addCombinedActions( [userBusinessActions.businessSwitched,
                                    userBusinessActions.businessTypeChanged,
                                    userBusinessActions.deleteSuccess],
    handleBusinessDataChanged );
export default reducerCreator.createReducer();

function handleBusinessDataChanged( state: InspirationsState, action: Action<any> ): InspirationsState
{
    return {
        ...defaultState,
    };
}

function restoreOrderingFromNormalizrData( inspirationsState, data: NormalizrData )
{
    inspirationsState.ids = data.result;
}

function handleLoadInspirationPosts( state: InspirationsState, action: Action<InspirationsAPI> ): InspirationsState
{
    const { posts, next_page, total_count } = action.payload;

    const normalizedInspirationsData = normalizePostAPIForMultiplePosts( posts );
    const inspirationsState = updateLookup( normalizedInspirationsData.entities.posts, state, false );
    restoreOrderingFromNormalizrData( inspirationsState, normalizedInspirationsData );
    const uniqIds = uniq( [...state.ids, ...inspirationsState.ids] );

    return {
        ids: uniqIds,
        idToObj: merge( state.idToObj, inspirationsState.idToObj ),
        next_page,
        total_count,
    };
}

function handleClearLoadedDataToPageOne( state: InspirationsState ): InspirationsState
{
    const { ids, idToObj } = state;
    const newIds = take( ids, INSPIRATIONS_PAGE_SIZE );
    const newIdToObj = pickBy( idToObj, ( value, key ) => includes( newIds, Number( key ) ) );

    return {
        ...defaultState,
        ids: newIds,
        idToObj: newIdToObj,
    };
}

export function getInspirationPosts( state: StoreState ): Post[]
{
    const ids = state.inspirations.ids;
    let posts = map( ids, ( id ) => state.inspirations.idToObj[id] );
    posts = filter( posts );

    forEach( posts, ( post ) =>
    {
        // TODO This should be changed to an on-render-only flag, so that the creator of an inspirational post
        // can work with it properly.
        post.is_inspirational_post = true;
    } );

    return posts;
}

export const hasLoadedAnyInspirations = ( state: StoreState ): boolean => !!getInspirationTotalCount( state );
export const getNextInspirationPage = ( state: StoreState ): number => state.inspirations.next_page;
export const hasNextInspirationPage = ( state: StoreState ): boolean => !!getNextInspirationPage( state );
export const getInspirationTotalCount = ( state: StoreState ): number => state.inspirations.total_count;
