import Button from "react-md/lib/Buttons/Button";
import * as React from "react";

interface RemoveSelfButtonProps
{
    onClick: () => any;
}

export function RemoveSelfButton( props: RemoveSelfButtonProps )
{
    return (
        <Button primary flat swapTheming className="addUsersButton" onClick={props.onClick}>
            Leave this team
        </Button>
    );
}
