import { connect } from "react-redux";
import { Dispatch } from "redux";
import { MediaData, StoreState } from "../../../../_types";
import { UploadCustomMusicButton, UploadCustomMusicButtonDispatchProps, UploadCustomMusicButtonProps } from "../UploadCustomMusicButton";
import { musicCatalogActions, uiActions } from "../../../../actions";
import { isUploadedMusicFileValid, selectMusic } from "../../../../helpers";
import { modalServices, postServices } from "../../../../services";
import { AddMediaAPIResponse, MUSIC_INPUT_TYPE } from "../../../../_types/api";
import { isCustomMusicUploading } from "../../../../ducks/ui";
import { store } from "../../../../store";
import { getCustomMusicFromMusicCatalog } from "../../../../ducks/musicCatalog";

const mapStateToProps = ( storeState: StoreState ): UploadCustomMusicButtonProps =>
{
    return {
        isCustomMusicUploading: isCustomMusicUploading( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): UploadCustomMusicButtonDispatchProps =>
{
    return {
        onUploadCustomMusic: async ( file: File ) =>
        {
            if ( !isUploadedMusicFileValid( file ) )
            {
                dispatch( modalServices.openInvalidMusicFileUploadError() );
            }
            else
            {
                await uploadCustomMusic( dispatch, file );
            }
        },
        stopPlayingMusic: () =>
        {
            dispatch( uiActions.setAudioPlayerMusicTrack( undefined ) );
        },
    };
};

async function uploadCustomMusic( dispatch: Dispatch<StoreState>, file: File )
{
    const mediaData: MediaData = {
        filename: file.name,
        file,
        type: MUSIC_INPUT_TYPE,
        fileSize: file.size,
    };
    dispatch( uiActions.toggleIsCustomMusicUploading( true ) );
    await dispatch( postServices.uploadPostMediaToS3( mediaData, afterUpload ) );
}

function afterUpload( dispatch: Dispatch<StoreState>, data: AddMediaAPIResponse )
{
    if ( data )
    {
        dispatch( musicCatalogActions.loadCustomMusic( data ) );
        dispatch( uiActions.toggleIsCustomMusicUploading( false ) );

        const state = store.getState();
        const customMusic = getCustomMusicFromMusicCatalog( state );
        if ( customMusic )
        {
            selectMusic( state, dispatch, customMusic );
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( UploadCustomMusicButton );
