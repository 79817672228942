import * as React from "react";
import { LIST_INNER_WRAP_ID, LIST_ITEM_SELECTED_CLASS, ListPicker } from "../../ListPicker";
import { Music } from "../../../_types";
import { LegacyAudioTracksList } from "./LegacyAudioTracksList";
import { EpidemicAudioTracksList } from "./EpidemicAudioTracksList";
import { MusicPickerDispatchProps, MusicPickerProps } from "./MusicPicker";
import { EpidemicBottomTray } from "./EpidemicBottomTray";
import { Cancelable, debounce, isEqual } from "lodash";
import { eventTracker, scrollIntoViewIfNotAlreadyVisible } from "../../../helpers";
import Divider from "react-md/lib/Dividers/Divider";
import VideoAudioMusicItemContainer from "./containers/VideoAudioMusicItem.container";
import NoAudioMusicItemContainer from "./containers/NoAudioMusicItem.container";
import withMusicPlayer, { WithMusicPlayerProps } from "../../higherOrderComponents/WithMusicPlayer";

class EpidemicMusicPicker extends React.PureComponent<MusicPickerProps & MusicPickerDispatchProps & WithMusicPlayerProps>
{
    private selfRef: React.RefObject<HTMLDivElement>;
    private debounceScrollToSelected: (() => void) & Cancelable;

    constructor( props )
    {
        super( props );
        this.state = {};
        this.selfRef = React.createRef();
        this.debounceScrollToSelected = debounce( this.scrollToSelected, 200 );
    }

    public componentWillUnmount()
    {
        this.props.clearPremiumEpidemicSoundPreview();

        if ( this.debounceScrollToSelected )
        {
            this.debounceScrollToSelected.cancel();
            this.debounceScrollToSelected = null;
        }
    }

    public componentDidUpdate( prevProps: Readonly<MusicPickerProps & MusicPickerDispatchProps & WithMusicPlayerProps>,
                               prevState: Readonly<{}>, snapshot?: any )
    {
        if ( this.selfRef &&
             this.props.currentMixModelMusic &&
             !isEqual( prevProps.currentMixModelMusic, this.props.currentMixModelMusic )
             && this.debounceScrollToSelected )
        {
            this.debounceScrollToSelected();
        }

        if ( this.props.isCurrentProductTierPremium && !prevProps.isCurrentProductTierPremium )
        {
            if ( this.props.premiumEpidemicSoundTrackPreview )
            {
                this.props.selectPremiumEpidemicSoundTrackPreview().then( ( music ) =>
                {
                    if ( music )
                    {
                        this.props.handleMusicClick( music );
                        this.props.clearPremiumEpidemicSoundPreview();
                    }
                } );
            }
        }
    }

    public componentDidMount()
    {
        eventTracker.logPickMusic();
        this.props.loadFreeEpidemicSoundCatalog();
    }

    public render()
    {
        const isLoading = this.props.isLoadingEpidemicSoundCatalog || this.props.isCustomMusicUploading;
        const currentlyPlayingTrack: Music = this.props.currentAudioPlayerMusicTrack && this.props.currentMixModelMusic;

        return (
            <ListPicker ref={this.selfRef} loading={isLoading} bottomTray={<EpidemicBottomTray/>}>
                <NoAudioMusicItemContainer/>
                {this.props.doesVideoContainAudio && <VideoAudioMusicItemContainer/>}
                <LegacyAudioTracksList musicList={this.props.musicArray}
                                       isSelected={this.props.isSelected}
                                       handleMusicClick={this.setTrackingSourceAndHandleMusicClick}
                                       currentlyPlayingTrack={currentlyPlayingTrack}/>
                <Divider className="divider"/>
                <EpidemicAudioTracksList musicList={this.props.epidemicSoundTracks}
                                         handleMusicClick={this.setTrackingSourceAndHandleMusicClick}/>
            </ListPicker>
        );
    }

    private setTrackingSourceAndHandleMusicClick = ( music?: Music ) =>
    {
        this.props.setEpidemicSoundSource();
        this.props.handleMusicClick( music );
    }

    private scrollToSelected = () =>
    {
        const current = this.selfRef.current;
        if ( current )
        {
            const listElement = current.querySelector( "#" + LIST_INNER_WRAP_ID );
            if ( listElement )
            {
                const selectedElement = listElement.querySelector( "." + LIST_ITEM_SELECTED_CLASS ) as HTMLElement;
                scrollIntoViewIfNotAlreadyVisible( selectedElement );
            }
        }
    }
}

export default withMusicPlayer( EpidemicMusicPicker );
