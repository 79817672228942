import { connect } from "react-redux";
import { Dispatch } from "redux";
import { DialogControlProps, StoreState } from "../../_types";
import { GradientHeaderDialog, GradientHeaderDialogDispatchProps, GradientHeaderDialogProps } from "../GradientHeaderDialog";
import { upsellServices } from "../../services";
import { SUBSCRIPTION_FLOW_SOURCE_CUSTOMIZE_NEXT_BUTTON } from "../../helpers";

interface GradientDialogContainerProps extends DialogControlProps
{
    title: string;
    subtitle?: string;
    showCrown: boolean;
    content: JSX.Element | string;
    canGoBack: boolean;
    width?: number;
}

const mapStateToProps = ( storeState: StoreState, ownProps: GradientDialogContainerProps ): GradientHeaderDialogProps =>
{
    return {
        title: ownProps.title,
        subtitle: ownProps.subtitle,
        showCrown: ownProps.showCrown,
        content: ownProps.content,
        canGoBack: ownProps.canGoBack,
        width: ownProps.width,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: GradientDialogContainerProps ): GradientHeaderDialogDispatchProps =>
{
    return {
        handleCancel()
        {
            ownProps.closeDialog();
        },
        backToTierSelection()
        {
            ownProps.closeDialog();
            dispatch( upsellServices.displayTryProNoWarmup( SUBSCRIPTION_FLOW_SOURCE_CUSTOMIZE_NEXT_BUTTON ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( GradientHeaderDialog );
