import { StoreState } from "../../../_types";
import { connect } from "react-redux";
import CollabraCompletePage, { CollabraCompletePageProps } from "../../CollabraCompletePage";

const mapStateToProps = ( storeState: StoreState ): CollabraCompletePageProps =>
{
    return {
        finishedPost: true,
    };
};

export default connect(
    mapStateToProps,
)( CollabraCompletePage );
