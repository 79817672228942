import businessTypes from "./businessTypes";
import catalog from "./catalog";
import catalogCategories from "./catalogCategories";
import designs from "./designs";
import engagements from "./engagements";
import inspirations from "./inspirations";
import fonts from "./fonts";
import mixModel from "./mixModel";
import shareModel from "./shareModel";
import modals from "./modals";
import user from "./user";
import userBusinesses from "./userBusiness";
import musicCatalog from "./musicCatalog";
import posts from "./post";
import drafts from "./draft";
import scheduledActions from "./scheduledAction";
import ui from "./ui";
import designPreviews from "./designPreviews";
import tracking from "./tracking";
import pricing from "./pricing";
import mostEngagedPosts from "./mostEngagedPost";
import login from "./login";
import stockMedia from "./stockMedia";
import contentSearch from "./contentSearch";
import collection from "./collections";
import survey from "./survey";
import collectionRows from "./collectionRows";
import socialCalendar from "./socialCalendar";
import dynamicDesignData from "./dynamicDesignData";
import epidemicSoundCatalog from "./epidemicSoundCatalog";
import epidemicSoundCollections from "./epidemicSoundCollections";
import partner from "./partner";

export const reducers = {
    businessTypes,
    catalog,
    catalogCategories,
    designs,
    designPreviews,
    drafts,
    engagements,
    epidemicSoundCatalog,
    epidemicSoundCollections,
    inspirations,
    fonts,
    mixModel,
    shareModel,
    user,
    modals,
    mostEngagedPosts,
    musicCatalog,
    posts,
    pricing,
    partner,
    scheduledActions,
    userBusinesses,
    login,
    ui,
    tracking,
    stockMedia,
    contentSearch,
    collection,
    collectionRows,
    survey,
    dynamicDesignData,
    socialCalendar,
};
