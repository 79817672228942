import { connect } from "react-redux";
import { Dispatch } from "redux";
import { getEmailForm } from "../../ducks/ui";
import { EmailPasswordInputPanel, EmailPasswordInputPanelDispatchProps, EmailPasswordInputPanelProps } from "../EmailPasswordInputPanel";
import { uiActions } from "../../actions";
import { StoreState } from "../../_types";

interface EmailPasswordInputPanelContainerProps
{
    onContinueClicked: ( emailAddress: string, password: string ) => void;
    onForgotLogin?: () => void;
    hidePassword?: boolean;
    buttonText?: string;
    optionalClassName?: string;
    showVersionInfo?: boolean;
}

const mapStateToProps = ( state: StoreState, ownProps: EmailPasswordInputPanelContainerProps ): EmailPasswordInputPanelProps =>
{
    return {
        ...getEmailForm( state ),
        ...ownProps,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): EmailPasswordInputPanelDispatchProps =>
{
    return {
        handleEmailUpdate: ( value: string ) =>
        {
            dispatch( uiActions.updateEmailFormEmail( value ) );
        },
        handlePasswordUpdate: ( value: string ) =>
        {
            dispatch( uiActions.updateEmailFormPassword( value ) );
        },
        handleEmailError: ( value: string ) =>
        {
            const emailError = { error: value };
            dispatch( uiActions.setEmailFormErrors( emailError ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( EmailPasswordInputPanel );
