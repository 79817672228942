import * as React from "react";
import { BrandLogo } from "../../_types";
import classNames from "classnames";
import { RoundedCornerCheckmark } from "../homepages/RoundedCornerCheckmark";

export interface RecentBrandLogoProps
{
    brandLogo: BrandLogo;
    isSelected: boolean;
}

export interface RecentBrandLogoDispatchProps
{
    handleLogoClicked( brandLogo: BrandLogo );
}

export class RecentBrandLogo extends React.PureComponent<RecentBrandLogoProps & RecentBrandLogoDispatchProps>
{
    public render()
    {
        return (
            <div className={classNames( "brandLogo", { current: this.props.isSelected } )}>
                <div className="imageContainer" style={{ "--aspect-ratio": 1 }} onClick={this.onLogoClick}>
                    <img className="logoImage" src={this.props.brandLogo.url} alt="brand logo"/>
                    {this.props.isSelected && <RoundedCornerCheckmark/>}
                </div>
            </div>
        );
    }

    private onLogoClick = () =>
    {
        this.props.handleLogoClicked( this.props.brandLogo );
    }
}
