import { Dispatch } from "redux";
import { store } from "../store";
import { createNewPost } from "./";
import { EDITOR_TYPES, eventTracker } from "../helpers";
import { StartingMixType, StoreState, UIEditorType } from "../_types";
import { mixModelActions, uiActions } from "../actions";
import { CustomizableCanvas } from "../helpers/customizableCanvas";

export const mixModelServices = {
    handleWatermarkClick,
    changeDesignControl,
    setupNewPostWithEventing,
};

function handleWatermarkClick()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        eventTracker.logCustomizeLogoClicked();
        dispatch( uiActions.controlEditorSwitched( { editorType: EDITOR_TYPES.LOGO_CONTROL } ) );
    };
}

function changeDesignControl( editorType: UIEditorType, controlId?: string )
{
    eventTracker.logDesignControlChanged( editorType );
    CustomizableCanvas.requestStopAnimation();
    store.dispatch( uiActions.controlEditorSwitched( { editorType, controlId } ) );
}

function setupNewPostWithEventing( source: StartingMixType )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( mixModelActions.mixModelCancelled() );
        dispatch( mixModelActions.setStartingMixTypeAndRelatedData( source ) );
        eventTracker.logNewPostFlowStartedEvent( source );
        dispatch( createNewPost() );
    };
}
