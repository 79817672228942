import * as React from "react";
import classNames from "classnames";
import Cell from "react-md/lib/Grids/Cell";
import { BusinessType } from "../_types";

export interface BusinessTypeBlockProps
{
    businessType: BusinessType;
    selectedBusinessTypeId: number;
}

export interface BusinessTypeBlockDispatchProps
{
    handleSelectBusinessType: ( id: number ) => void;
}

export class BusinessTypeBlock extends React.PureComponent<BusinessTypeBlockProps & BusinessTypeBlockDispatchProps>
{
    public render()
    {
        return (
            <Cell phoneSize={2} tabletSize={2} desktopSize={3} className="businessTypeContainer" onClick={this.selectBusinessType}>
                <img className={classNames( "businessTypeImgColor",
                    { selected: this.props.selectedBusinessTypeId === this.props.businessType.id } )}
                     src={this.props.businessType.icon_url} alt={this.props.businessType.name}/>
                <p>
                    {this.props.businessType.name}
                </p>
            </Cell>
        );
    }

    private selectBusinessType = () =>
    {
        this.props.handleSelectBusinessType( this.props.businessType.id );
    }
}
