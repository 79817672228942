import * as Raven from "raven-js";
import { isError } from "lodash";
import { ENABLE_ERROR_REPORTING } from "./";

export const errorReporting = {
    reportErrorToSentry,
};

export const CUSTOMIZE_PAGE_NO_SNAPHOT_CANVAS = "CUSTOMIZE_PAGE_NO_SNAPHOT_CANVAS";

export const POST_CREATE_RETURNED_INVALID_JSON = "POST_CREATE_RETURNED_INVALID_JSON";
export const POST_UPDATE_RETURNED_INVALID_JSON = "POST_UPDATE_RETURNED_INVALID_JSON";
export const POST_FINISH_RETURNED_INVALID_JSON = "POST_FINISH_RETURNED_INVALID_JSON";
export const POST_RESCHEDULE_RETURNED_INVALID_JSON = "POST_RESCHEDULE_RETURNED_INVALID_JSON";
export const POST_UNSCHEDULE_RETURNED_INVALID_JSON = "POST_UNSCHEDULE_RETURNED_INVALID_JSON";

function reportErrorToSentry( error, extra?: any )
{
    if ( ENABLE_ERROR_REPORTING )
    {
        try
        {
            if ( isError( error ) )
            {
                Raven.captureException( error, extra ? { extra } : undefined );
            }
            else
            {
                Raven.captureMessage( error, extra ? { extra } : undefined );
            }
        }
        catch (e)
        {
            // ignore any errors when reporting errors
        }
    }
}
