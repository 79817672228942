import { StoreState } from "../../_types";
import { connect } from "react-redux";
import { PricePlanRadioButton, PricePlanRadioButtonProps } from "../PricePlanRadioButton";
import { getUserCurrencyCode } from "../../ducks/user";
import { getCurrentlySelectedExchangeRate, getExchangeRateData } from "../../ducks/pricing";

export interface PricePlanRadioButtonContainerProps
{
    selectPlan: ( plan: StripePlanData ) => void;
    plan: StripePlanData;
    selected: boolean;
    isSimplifiedUpsell?: boolean;
    showShortenedSmallPriceText?: boolean;
    showAsMonthlyRate?: boolean;
}

const mapStateToProps = ( storeState: StoreState, ownProps: PricePlanRadioButtonContainerProps ): PricePlanRadioButtonProps =>
{
    return {
        ...ownProps,
        currencyCode: getUserCurrencyCode( storeState ),
        currentExchangeRate: getCurrentlySelectedExchangeRate( storeState ),
        shouldShowConvertedCurrency: !!getExchangeRateData( storeState ),
    };
};

export default connect(
    mapStateToProps,
)( PricePlanRadioButton );
