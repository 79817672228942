import { connect } from "react-redux";
import { Dispatch } from "redux";
import { store } from "../../store";
import { mixModelActions, uiActions } from "../../actions";
import { CanvasUpdater, eventTracker } from "../../helpers";
import DesignCanvas, { DesignCanvasDispatchProps, DesignCanvasProps } from "../DesignCanvas";
import { canDesignSupportVideoCount, getHideWatermark, getSelectedDesignId, getWatermarkUrl, hasDataRequiredToLoad } from "../../ducks/mixModel";
import { AspectRatio, Design, StoreState } from "../../_types";
import { modalServices } from "../../services";
import { COACH_MARK_NAME_KEY_MORE_LAYOUTS } from "../../_types/api";
import { getCatalogUpdatedAtCacheBuster } from "../../ducks/catalog";

interface DesignCanvasContainerProps
{
    iframeId: string;
    design: Design;
    aspectRatio: AspectRatio;
    lazyLoad: boolean;
    isSelectedDesign: boolean;
    hidePlayButton: boolean;
}

const mapStateToProps = ( state: StoreState, ownProps: DesignCanvasContainerProps ): DesignCanvasProps =>
{
    return ({
        ...ownProps,
        editable: false,
        watermarkUrl: getWatermarkUrl( state ),
        hideWatermark: getHideWatermark( state ),
        hasDataRequiredToLoad: hasDataRequiredToLoad( state, ownProps.design ),
        catalogUpdatedAt: getCatalogUpdatedAtCacheBuster( state ),
    });
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): DesignCanvasDispatchProps =>
{
    return {
        registerCanvas: ( canvasUpdater: CanvasUpdater ) =>
        {
            dispatch( uiActions.registerCanvasUpdater( canvasUpdater ) );
        },
        onDesignClicked: ( design: Design, aspectRatio: AspectRatio ) =>
        {
            if ( design )
            {
                if ( design.id === getSelectedDesignId( store.getState() ) )
                {
                    dispatch( mixModelActions.designHasChanged() );
                    dispatch( uiActions.designCanvasRefreshed() );
                }
                else if ( !canDesignSupportVideoCount( store.getState(), design ) )
                {
                    dispatch( modalServices.openDesignCanNotSupportVideoCountErrorDialog( store.getState(), design ) );
                }
                else
                {
                    dispatch( mixModelActions.designHasChanged() );
                    dispatch( mixModelActions.designSelected( { design, aspectRatio } ) );
                }

                dispatch( uiActions.clearAudioPlayerMusicTrack() );
                dispatch( uiActions.designZoomControlsSet( null ) );
                dispatch( uiActions.clearCoachMark( COACH_MARK_NAME_KEY_MORE_LAYOUTS ) );
                eventTracker.logTemplateSelected();
            }
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( DesignCanvas );
