export const scrollIntoViewIfNotAlreadyVisible = ( element: HTMLElement ) =>
{
    if ( element && !isVerticallyVisibleInViewport( element ) )
    {
        element.scrollIntoView( { behavior: "smooth", block: "center" } );
    }
};

export const isVerticallyVisibleInViewport = ( element: HTMLElement ): boolean =>
{
    if ( element )
    {
        const boundingClientRect = element.getBoundingClientRect();
        const top = boundingClientRect.top;
        const bottom = boundingClientRect.bottom;

        return top >= 0 && bottom <= window.innerHeight;
    }
    return false;
};

export const scrollElementToTopOfViewportWithOffset = ( element: HTMLElement, yOffset: number = 0 ) =>
{
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({top: y, behavior: "smooth"});
};
