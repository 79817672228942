import * as React from "react";
import { store } from "../../store";
import TextField from "react-md/lib/TextFields/TextField";
import Button from "react-md/lib/Buttons/Button";
import { BackgroundOverlay } from "../BackgroundOverlay";
import { RiplLogoSymbolMark } from "../RiplLogoSymbolMark";
import CircularProgress from "react-md/lib/Progress/CircularProgress";
import { uiActions } from "../../actions";
import { ResetFormData } from "../../_types";
import { history, USER_SETTINGS_URL } from "../../helpers";

export interface ManagePasswordPageProps
{
    buttonText: string;
    passwordPromptText: string;
    resetFormData: ResetFormData;
}

export interface ManagePasswordPageDispatchProps
{
    changePassword( password: string, confirmPassword: string );
}

interface ManagePasswordPageState
{
    password: string;
    confirmPassword: string;
}

export class ManagePasswordPage extends React.PureComponent<ManagePasswordPageProps & ManagePasswordPageDispatchProps, ManagePasswordPageState>
{
    constructor( props )
    {
        super( props );
        this.state = {
            password: "",
            confirmPassword: "",
        };
    }

    public componentWillUnmount()
    {
        store.dispatch( uiActions.setResetFormProcessing( false ) );
        store.dispatch( uiActions.clearResetFormErrors() );
    }

    public render()
    {
        const { error, tokenError, passwordError, passwordConfirmationError, isProcessingChange } = this.props.resetFormData;
        const shownError = error || tokenError || passwordError;
        const { password, confirmPassword } = this.state;
        return <BackgroundOverlay>
            <div className="managePasswordPage">
                <RiplLogoSymbolMark/>
                <div className="text title center">{this.props.passwordPromptText}</div>
                <form className="accountForm">
                    <TextField
                        id="newPassword"
                        type="password"
                        placeholder="New Password"
                        className="passwordInput"
                        required
                        error={shownError ? true : undefined}
                        errorText={shownError}
                        value={password}
                        onChange={this.updatePassword}
                        disabled={isProcessingChange}
                    />
                    <TextField
                        id="confirmPassword"
                        type="password"
                        placeholder="Confirm Password"
                        className="passwordInput"
                        required
                        value={confirmPassword}
                        error={passwordConfirmationError ? true : undefined}
                        errorText={passwordConfirmationError}
                        onChange={this.updatePasswordConfirmation}
                        disabled={isProcessingChange}
                    />

                    <div className="dialogButtons">
                        <Button flat className="cancelButton" onClick={this.cancel}>Cancel</Button>
                        <Button flat primary swapTheming className="button" onClick={this.handleChange}>{this.getContinueButtonContents()}</Button>
                    </div>
                </form>
            </div>
        </BackgroundOverlay>;
    }

    private updatePassword = ( password: string ) =>
    {
        this.setState( { password } );
    }

    private updatePasswordConfirmation = ( confirmPassword: string ) =>
    {
        this.setState( { confirmPassword } );
    }

    private handleChange = () =>
    {
        if ( !this.props.resetFormData.isProcessingChange )
        {
            this.props.changePassword( this.state.password, this.state.confirmPassword );

        }
    }

    private cancel = () =>
    {
        // > 2 means user has navigated so it is safe to go back
        if ( history.length > 2)
        {
            history.goBack();
        }
        else
        {
            history.push( USER_SETTINGS_URL );
        }
    }

    private getContinueButtonContents = () =>
    {
        if ( this.props.resetFormData.isProcessingChange )
        {
            return (<CircularProgress className="spinner" id="spinner"/>);
        }
        else
        {
            return this.props.buttonText;
        }
    }
}
