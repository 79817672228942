import { connect } from "react-redux";
import { DialogControlProps, StoreState } from "../../_types";
import { DesignSwitchingCatalogDialog } from "../DesignSwitchingCatalogDialog";

const mapStateToProps = ( storeState: StoreState, ownProps: DialogControlProps ): DialogControlProps =>
{
    return {
        ...ownProps,
    };
};

export default connect(
    mapStateToProps,
)( DesignSwitchingCatalogDialog );
