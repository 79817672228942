import { RIPL_TIER_BASE, RIPL_TIER_PREMIUM, STRIPE_MONTHLY_SKU_SUBSTRING, STRIPE_YEARLY_SKU_SUBSTRING } from "./";
import { toNumber } from "lodash";

export const convertToNumber = ( value: any ): number =>
{
    if ( typeof value === "number" )
    {
        return value;
    }
    if ( !value )
    {
        return 0;
    }
    return toNumber( value );
};

export const convertToBoolean = ( value: any ): boolean =>
{
    return !!value;
};

export const convertToPricingPlanType = ( value: any ): PricingPlanType =>
{
    if ( isPricingPlanType( value ) )
    {
        return value;
    }
    return undefined;
};

function isPricingPlanType( value: any ): value is PricingPlanType
{
    return convertToBoolean( value as PricingPlanType );
}

export const convertToStripePlanType = ( value: any ): StripePlanType =>
{
    if ( isStripePlanType( value ) )
    {
        return value;
    }
    return undefined;
};

function isStripePlanType( value: any ): value is StripePlanType
{
    return convertToBoolean( value as StripePlanType );
}

export const convertToPricingPlanTier = ( value: any ): PricingPlanTier =>
{
    if ( isPricingPlanTier( value ) )
    {
        return value;
    }
    return undefined;
};

function isPricingPlanTier( value: any ): value is PricingPlanTier
{
    return convertToBoolean( value as PricingPlanTier );
}

export const convertToProductTier = ( value: any ): ProductTier =>
{
    if ( isProductTier( value ) )
    {
        return value;
    }
    return undefined;
};

function isProductTier( value: any ): value is ProductTier
{
    return convertToBoolean( value as ProductTier );
}

export function convertPricingPlanTierToProductTier( value: PricingPlanTier ): ProductTier
{
    switch ( value )
    {
        case "base":
        {
            return RIPL_TIER_BASE;
        }
        case "premium":
        {
            return RIPL_TIER_PREMIUM;
        }
    }
}

export function convertPricingPlanTypeToSkuDuration( value: PricingPlanType ): StripeSkuDuration
{
    switch ( value )
    {
        case "annual":
        {
            return STRIPE_YEARLY_SKU_SUBSTRING;
        }
        case "monthly":
        {
            return STRIPE_MONTHLY_SKU_SUBSTRING;
        }
    }
}
