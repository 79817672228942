import { connect } from "react-redux";
import { StoreState } from "../../_types";
import { SubscriptionFlowSource } from "../../helpers";
import { PremiumBenefits, PremiumBenefitsProps } from "../PremiumBenefits";

interface PremiumBenefitsContainerProps
{
    source: SubscriptionFlowSource;
}

const mapStateToProps = ( storeState: StoreState, ownProps: PremiumBenefitsContainerProps ): PremiumBenefitsProps =>
{
    return {
        source: ownProps.source,
    };
};

export default connect(
    mapStateToProps,
    () => ({}),
)( PremiumBenefits );
