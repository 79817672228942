import { format, parse } from "date-fns";
import { concat, Dictionary, filter, forEach, head, includes, keyBy, map, replace, omit } from "lodash";
import { normalize, schema } from "normalizr";
import { ASSET_CONTROL_TYPES, CATALOG_VERSION, SQUARE_ASPECT_RATIO, stringUtils, USER_PICK_CONTROL_TYPES } from "./";
import {
    AssetControlConfig,
    BackgroundMediaAPIItem,
    CatalogAPIData,
    CatalogCategoriesAPIData,
    ContentSearchAPIData,
    ControlsConfig,
    ControlsConfigAPI,
    DesignAPIData,
    DesignFramesAPIItem,
    DesignSlugAPIData,
    EngagementsAPI,
    EpidemicSoundCollectionDetailAPI,
    EpidemicSoundTrack,
    IMAGE_OUTPUT_TYPE,
    PostAPIWrapper,
    PRIORITY_ERROR,
    PRIORITY_NORMAL,
    PRIORITY_SCHEDULED,
    PriorityDataAPI,
    ScheduledActionForDateAPI,
    SingleCategoryAPIData,
    UserActivityAPIData,
    UserPickAssetControlConfig,
} from "../_types/api";
import { CollectionData, ContentSearchData, PriorityData, StoreState, UserState } from "../_types";
import { CollectionAPIData } from "../_types/api/collectionsData";

const preprocessUser = ( entity ) =>
{
    return {
        ...entity,
        dev_mode: !!entity.dev_mode,
    };
};

const preprocessContentSearch = ( entity ) =>
{
    return {
        ...entity,
        next_page: !!entity.next_page ? entity.next_page : null,
    };
};

export const usersSchema = new schema.Entity( "users", {}, { processStrategy: preprocessUser } );
export const postsSchema = new schema.Entity( "posts" );
export const businessesSchema = new schema.Entity( "userBusinesses" );
export const teamBusinessesSchema = new schema.Entity( "teamBusinesses" );

export const scheduledActionsSchema = new schema.Entity( "scheduledActions" );
export const catalogsSchema = new schema.Entity( "catalogs" );
export const musicSchema = new schema.Entity( "musics" );
export const epidemicSoundTracksSchema = new schema.Entity( "epidemicSoundTracks", {}, { idAttribute: "epidemic_id" } );
export const epidemicSoundCollectionsSchema = new schema.Entity( "epidemicSoundCollections" );
export const designsSchema = new schema.Entity( "designs" );
export const designPreviewsSchema = new schema.Entity( "designPreviews" );
export const catalogCategoriesSchema = new schema.Entity( "catalogCategories" );
export const businessTypesSchema = new schema.Entity( "businessTypes" );
export const contentSearchSchema = new schema.Entity( "contentSearch", {}, { processStrategy: preprocessContentSearch } );
export const contentSearchMatchSchema = new schema.Entity( "contentSearchMatch" );
export const backgroundMediaSchema = new schema.Entity( "backgroundMedia" );
export const designFramesSchema = new schema.Entity( "designFrames" );

usersSchema.define( {
    businesses: [businessesSchema],
    team_businesses: [teamBusinessesSchema],
} );

postsSchema.define( {
    user: usersSchema,
} );

scheduledActionsSchema.define( {
    posts: [postsSchema],
} );

catalogsSchema.define( {
    catalog: [designsSchema],
    categories: [catalogCategoriesSchema],
} );

designsSchema.define( {
    design_previews: [designPreviewsSchema],
} );

contentSearchSchema.define( {
    matches: [contentSearchMatchSchema],
} );

contentSearchMatchSchema.define( {
    post: postsSchema,
} );

export function normalizeMeAPI( data: MeAPIData ): NormalizrData
{
    return normalize( { ...data.user, auth_token: data.auth_token }, usersSchema );
}

export function normalizeMusicAPI( data: MusicAPI[] ): NormalizrData
{
    return normalize( data, [musicSchema] );
}

export function normalizeEpidemicSoundTracks( data: EpidemicSoundTrack[] ): NormalizrData
{
    return normalize( data, [epidemicSoundTracksSchema] );
}

export function normalizeEpidemicCollections( data: EpidemicSoundCollectionDetailAPI[] ): NormalizrData
{
    return normalize( data, [epidemicSoundCollectionsSchema] );
}

export function normalizeBusinessTypeAPI( data: BusinessTypeAPI[] ): NormalizrData
{
    const convertedData = map( data, ( businessType ) => convertPostIntentData( businessType ) );
    return normalize( convertedData, [businessTypesSchema] );
}

function convertPostIntentData( businessType: BusinessTypeAPI )
{
    const originalPostIntents = businessType.post_intents;

    if ( !originalPostIntents )
    {
        return businessType;
    }

    const convertedPostIntents = {
        ...omit( originalPostIntents, ["internal-name"] ),
        internal_name: originalPostIntents["internal-name"],
        intents: map( originalPostIntents.intents, ( intentData ) =>
        {
            return {
                ...omit( intentData, ["collection-slug", "icon-url"] ),
                collection_slug: intentData["collection-slug"],
                icon_url: intentData["icon-url"],
            };
        } ),
    };

    return {
        ...businessType,
        post_intents: convertedPostIntents,
    };
}

export function normalizeBackgroundMediaAPI( data: BackgroundMediaAPIItem[] ): NormalizrData
{
    return normalize( data, [backgroundMediaSchema] );
}

export function normalizeDesignFramesAPI( data: DesignFramesAPIItem[] ): NormalizrData
{
    return normalize( data, [designFramesSchema] );
}

export function normalizeUserFromActivityAPI( data: UserActivityAPIData ): NormalizrData
{
    return normalize( data.user, usersSchema );
}

export function normalizeCatalogAPI( data: CatalogAPIData ): NormalizrData
{
    const { autoDownloadDesigns, catalog, catalogUpdatedAt, categories, endCards, freemiumDesigns } = data;
    const designSlugDictionary = keyBy( catalog, "slug" );
    const convertedData = {
        id: CATALOG_VERSION,
        categories: convertCatalogCategories( categories, designSlugDictionary ),
        catalog: concat( catalog, endCards ),
        catalogUpdatedAt,
        newDesigns: map( filter( catalog, "new_flag" ), ( design ) => design.id ),
        freeDesigns: map( filter( catalog, "free_flag" ), ( design ) => design.id ),
        freemiumDesigns: convertDesignSlugsToIds( freemiumDesigns, designSlugDictionary ),
        autoDownloadDesigns: convertDesignSlugsToIds( autoDownloadDesigns, designSlugDictionary ),
        endCards: map( endCards, ( design ) => design.id ),
    };
    return normalize( convertedData, catalogsSchema );
}

export function normalizeSingleCategoryAPI( data: SingleCategoryAPIData, state: StoreState ): NormalizrData
{
    const { category } = data;
    const designSlugDictionary = keyBy( state.designs.idToObj, "slug" );
    const convertedData = {
        categories: convertCatalogCategories( [category], designSlugDictionary ),
    };
    return normalize( convertedData, catalogsSchema );
}

export function convertDesignSlugsToIds( designs: DesignSlugAPIData[], designSlugDictionary: Dictionary<DesignAPIData> ): number[]
{
    return filter( map( designs, ( design ) =>
    {
        return designSlugDictionary[design.slug] && designSlugDictionary[design.slug].id;
    } ) );
}

function convertSingleCategory( category, designSlugDictionary )
{
    return {
        ...category,
        designs: convertDesignSlugsToIds( category.designs, designSlugDictionary ),
    };
}

export function convertCatalogCategories( categories: CatalogCategoriesAPIData[], designSlugDictionary: Dictionary<DesignAPIData> )
{
    return map( categories, ( category ) =>
    {
        return convertSingleCategory( category, designSlugDictionary );
    } );
}

export function normalizeContentSearchAPIData( data: ContentSearchAPIData ): ContentSearchData
{
    const normalizedData = normalize( data, contentSearchSchema );
    return {
        ...normalizedData.entities.contentSearch[normalizedData.result],
        matches: map( data.matches, ( match ) =>
        {
            const matchToConvert = {
                ...match,
                post: convertPost( match.post ),
            };
            const normalizedMatch = normalize( matchToConvert, contentSearchMatchSchema );
            const postId = normalizedMatch.entities.contentSearchMatch[normalizedMatch.result].post;
            const post = normalizedMatch.entities.posts[postId];

            return {
                ...match,
                post,
            };
        } ),
    };
}

export function normalizeCollectionAPIData( data: CollectionAPIData ): CollectionData
{
    return {
        ...data,
        posts: map( data.posts, ( post ) =>
        {
            const normalizedPost = normalizePostAPIForSinglePost( post );
            return normalizedPost.entities.posts[post.id];
        } ),
    };
}

export function normalizeScheduledActionsAPI( data: ScheduledActionForDateAPI[], today: string ): NormalizrData
{
    const flattenedData = map( data, ( apiData, index ) =>
    {
        const posts = filter( map( apiData.business_action.posts, convertPost ) );
        const actionPost = head( apiData.business_action.posts );
        const dateIsToday = apiData.date === today;
        return {
            ...apiData.business_action,
            image_url: stringUtils.getCORSFriendlyUrl( actionPost.image_url ),
            video_url: stringUtils.getCORSFriendlyUrl( actionPost.element.video_url ),
            poster_url: actionPost.poster_url,
            aspect_ratio: actionPost.element.aspect_ratio,
            name: apiData.business_action.name,
            date: format( apiData.date ),
            isToday: dateIsToday,
            business_type_id: apiData.business_type_id,
            orderedIndex: index,
            posts,
        };
    } );
    return normalize( flattenedData, [scheduledActionsSchema] );
}

export function normalizePostAPIForSinglePost( data: PostAPIWrapper ): NormalizrData
{
    const post = convertPost( data );
    if ( !post )
    {
        return;
    }
    return normalize( post, postsSchema );
}

export function normalizePostAPIForMultiplePosts( data: PostAPIWrapper[] ): NormalizrData
{
    const flattenPosts = filter( map( data, convertPost ) );
    return normalize( flattenPosts, [postsSchema] );
}

export function normalizeControlConfigs( controlConfigs: ControlsConfigAPI[], designTemplateUrl: string ): ControlsConfig[]
{
    return map( controlConfigs, ( controlConfig ) =>
    {
        const { inputs, controls } = controlConfig;
        const supportedInputs = {};
        inputs.forEach( ( input ) =>
        {
            const key = convertInputsArrayToKey( input );
            supportedInputs[key] = true;
        } );
        controls.forEach( ( control ) =>
        {
            if ( includes( ASSET_CONTROL_TYPES, control.type ) )
            {
                const { choices, genericThumbnail } = control as AssetControlConfig;
                choices.forEach( ( choice ) =>
                {
                    const thumbnail = choice.thumbnail || genericThumbnail;
                    choice.thumbnail = designTemplateUrl + replace( "/" + thumbnail, "//", "/" );
                } );
            }
            if ( includes( USER_PICK_CONTROL_TYPES, control.type ) )
            {
                const { userPickChoice } = control as UserPickAssetControlConfig;
                userPickChoice.thumbnail = designTemplateUrl + userPickChoice.thumbnail;
            }
        } );
        return {
            inputs: supportedInputs,
            controls,
        };
    } );
}

export function convertInputsArrayToKey( input )
{
    return `${input.sort().join( "," )}`;
}

function convertPost( postWrapper: PostAPIWrapper )
{
    const { element, user_id, ...wrapper } = postWrapper;
    if ( !element )
    {
        return;
    }

    const { user, ...postData } = element;

    const convertedUser = convertUser( user, user_id );
    if ( !convertedUser )
    {
        return;
    }

    const post = {
        ...wrapper,
        ...postData,
        user: convertedUser,
        engagement: convertEngagements( postData.engagement ),
    };

    if ( !post.aspect_ratio )
    {
        post.aspect_ratio = SQUARE_ASPECT_RATIO;
    }
    if ( post.input_body_text === null )
    {
        post.input_body_text = undefined;
    }
    if ( post.input_headline_text === null )
    {
        post.input_headline_text = undefined;
    }
    if ( post.output_type === IMAGE_OUTPUT_TYPE )
    {
        post.video_url = undefined;
    }
    return post;
}

function convertUser( user: UserActivityAPIUser, userId: string ): Partial<UserState>
{
    return {
        ...user,
        id: Number( userId ),
    };
}

function convertEngagements( engagementData: EngagementsAPI ): Engagements
{
    if ( !engagementData )
    {
        return;
    }
    const { created_at, facebook_page_comments, facebook_page_likes, ...engagement } = engagementData;
    return engagement;
}

export function convertPriorityData( data: PriorityDataAPI ): PriorityData
{
    const priorityData = {
        error: [],
        sent: [],
        scheduled: [],
    };
    forEach( data.posts, ( priorityDay ) =>
    {
        const date = parse( priorityDay.date );
        switch ( priorityDay.priority )
        {
            case PRIORITY_NORMAL:
                priorityData.sent.push( date );
                break;
            case PRIORITY_SCHEDULED:
                priorityData.scheduled.push( date );
                break;
            case PRIORITY_ERROR:
                priorityData.error.push( date );
                break;
        }
    } );
    return priorityData;
}
