import { STRIPE_PLAN_TYPE_ANNUAL, STRIPE_PLAN_TYPE_MONTHLY } from "../_types/api";
import { computeCouponSavings, computeSavings } from "../ducks/pricing";
import { BestDealBadge } from "./BestDealBadge";
import * as React from "react";
import { SavingsInPercentBadge } from "./SavingsInPercentBadge";

interface SavingsInPercentBadgeFromPlanProps
{
    plan: StripePlanData;
    monthlyPlan: StripePlanData;
    annualPlan: StripePlanData;
    shouldRenderForWideFormat?: boolean;
}

export function SavingsInPercentBadgeFromPlan( props: SavingsInPercentBadgeFromPlanProps )
{
    if ( props.plan.type === STRIPE_PLAN_TYPE_ANNUAL && props.monthlyPlan )
    {
        const isDiscountedPlan = props.plan.amount !== props.plan.originalAmount;
        const savings = computeSavings( props.annualPlan, props.monthlyPlan );
        if ( isDiscountedPlan )
        {
            return (<BestDealBadge/>);
        }

        return (
            <SavingsInPercentBadge savings={savings} shouldRenderForWideFormat={props.shouldRenderForWideFormat}/>
        );
    }
    else if ( props.plan.type === STRIPE_PLAN_TYPE_MONTHLY && !props.annualPlan )
    {
        const isDiscountedPlan = props.plan.amount !== props.plan.originalAmount;
        if ( isDiscountedPlan )
        {
            const savings = computeCouponSavings( props.plan );

            return (
                <SavingsInPercentBadge savings={savings} shouldRenderForWideFormat={props.shouldRenderForWideFormat}/>
            );
        }
    }

    return null;
}
