import * as React from "react";
import { MusicPickerCustomItem } from "./MusicPickerCustomItem";
import { VIDEO_AUDIO_DISPLAY_NAME } from "../../../_types/api";
import * as VideoAudioIconSelected from "../../../assets/img/videoAudioIconSelected.svg";
import * as VideoAudioIconUnselected from "../../../assets/img/videoAudioIconUnselected.svg";

export interface VideoAudioMusicItemProps
{
    isVideoAudioSelected: boolean;
}

export interface VideoAudioMusicItemDispatchProps
{
    onVideoAudioClicked();
}

export function VideoAudioMusicItem( props: VideoAudioMusicItemProps & VideoAudioMusicItemDispatchProps )
{
    {
        return (<MusicPickerCustomItem
            text={VIDEO_AUDIO_DISPLAY_NAME}
            selected={props.isVideoAudioSelected}
            iconSrc={VideoAudioIconSelected}
            iconSrcUnselected={VideoAudioIconUnselected}
            onClick={props.onVideoAudioClicked}/>);
    }
}
