import { Dispatch } from "redux";
import { normalizeContentSearchAPIData, requests } from "../helpers";
import { StoreState } from "../_types";
import { ContentSearchAPIData } from "../_types/api";
import { contentSearchActions, uiActions } from "../actions";
import { getCurrentBusinessTypeId } from "../ducks/userBusiness";
import { store } from "../store";

export const contentSearchServices = {
    searchContent,
};

const CONTENT_LIBRARY_BASE_URL = "/content_library_posts";
const CONTENT_LIBRARY_BASE_SEARCH_URL = CONTENT_LIBRARY_BASE_URL + "/search";

function searchContent( query: string, page: number = 1 )
{
    const currentBusinessTypeId = getCurrentBusinessTypeId( store.getState() );
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        return requests.get( CONTENT_LIBRARY_BASE_SEARCH_URL, { query, page, currentBusinessTypeId } ).then(
            ( data: ContentSearchAPIData ) =>
            {
                const normalizedData = normalizeContentSearchAPIData( data );
                dispatch( uiActions.contentSearchSpinnerEnabled( false ) );
                dispatch( contentSearchActions.searchResultsLoaded( normalizedData ) );
            }, () =>
            {
                dispatch( uiActions.contentSearchSpinnerEnabled( false ) );
                dispatch( (contentSearchActions.searchResultsFailed()) );
            },
        );
    };
}
