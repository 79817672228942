import { connect } from "react-redux";
import { Dispatch } from "redux";
import { store } from "../../store";
import { ShareOutputList, ShareOutputListDispatchProps, ShareOutputListProps } from "../ShareOutputList";
import {
    ALL_SOCIAL_NETWORK_LABELS_FOR_TYPES, apptimizeVariables,
    EDIT_MODE,
    generateShareOutputDisplayProps,
    LightboxDialogIdentifierForKey,
    oauth, SOCIAL_NETWORKS_FACEBOOK_INSTAGRAM,
    SocialAccountConnectedSource,
} from "../../helpers";
import { modalServices, userServices } from "../../services";
import { getCurrentUserId } from "../../ducks/user";
import { getCurrentBusinessId, hasTeamMemberPermissionsForCurrentBusiness } from "../../ducks/userBusiness";
import { SocialNetworkAccountType, StoreState } from "../../_types";
import { includes } from "lodash";

interface EditShareOutputListProps
{
    trackingSource: SocialAccountConnectedSource;
}

const mapStateToProps = ( storeState: StoreState, ownProps: EditShareOutputListProps ): ShareOutputListProps =>
{
    return {
        ...ownProps,
        accounts: generateShareOutputDisplayProps( storeState, EDIT_MODE ),
        onlyShowConnectedAccounts: hasTeamMemberPermissionsForCurrentBusiness( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: EditShareOutputListProps ): ShareOutputListDispatchProps =>
{
    function isFacebookSocialNetwork( accountType: SocialNetworkAccountType )
    {
        return includes( SOCIAL_NETWORKS_FACEBOOK_INSTAGRAM, accountType );
    }

    function showFacebookConnectDisabledAlert()
    {
        const title = "Facebook / Instagram Temporarily Disabled";
        // tslint:disable-next-line:max-line-length
        const message = "Connecting to Facebook is temporarily disabled while we investigate a service issue. At this time, we recommend saving this post to your device and sharing directly.";
        dispatch( modalServices.openAlertDialog( { title, message } ) );
    }

    return {
        onSocialAccountConnectRequested: ( accountType: SocialNetworkAccountType ) =>
        {
            if ( isFacebookSocialNetwork( accountType ) && apptimizeVariables.shouldDisableFacebookConnect() )
            {
                showFacebookConnectDisabledAlert();
                return;
            }

            oauth.connectViaWindow( accountType, ownProps.trackingSource );
        },
        onSocialAccountDisconnectRequested: ( accountType: SocialNetworkAccountType, accountId: number, profileId: string ) =>
        {
            const state = store.getState();
            const userId = getCurrentUserId( state );
            const businessId = getCurrentBusinessId( state );
            dispatch( modalServices.openLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.DISCONNECT_SOCIAL_NETWORK,
                title: "Disconnect " + ALL_SOCIAL_NETWORK_LABELS_FOR_TYPES[accountType],
                width: 400,
                showCancelX: true,
                hideCancel: true,
                content: `Posts scheduled for this account will be canceled and engagement tracking on previous posts will no longer update`,
                onSuccess: () => dispatch( userServices.clientSocialAccountDisconnect( userId, accountType, accountId, profileId, businessId ) ),
                confirmLabel: "Disconnect",
            } ) );
        },
        onSocialAccountRefreshRequested: ( accountType: SocialNetworkAccountType, accountId: number ) =>
        {
            // TODO do we need to track account Id or do anything other than a second connect?
            oauth.refreshViaWindow( accountId, accountType, ownProps.trackingSource );
        },
        showConnectDisabledAlert: () =>
        {
            showFacebookConnectDisabledAlert();
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( ShareOutputList );
