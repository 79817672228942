import { StoreState } from "../../../_types";
import { NoBrandFontRow, NoBrandFontRowContainerProps, NoBrandFontRowDispatchProps } from "../NoBrandFontRow";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { openBrandFontPicker } from "../../../helpers";

const mapStateToProps = ( storeState: StoreState, ownProps: NoBrandFontRowContainerProps ): NoBrandFontRowContainerProps =>
{
    return {
        ...ownProps,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: NoBrandFontRowContainerProps ): NoBrandFontRowDispatchProps =>
{
    return {
        async onChooseFontClicked()
        {
            openBrandFontPicker( dispatch, ownProps.fontType, ownProps.controlId );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( NoBrandFontRow );
