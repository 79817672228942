import * as React from "react";
import BrandSettingsLogoContainer from "./settingsPages/containers/BrandSettingsLogo.container";
import BrandSettingsColorPickerContainer from "./settingsPages/containers/BrandSettingsColorPicker.container";
import BrandSettingsFontPickerContainer from "./settingsPages/containers/BrandSettingsFontPicker.container";
import Button from "react-md/lib/Buttons/Button";
import classNames from "classnames";
import { EDITOR_TYPES } from "../helpers";

export interface BrandSettingsDialogProps
{
    source: string;
}

export interface BrandSettingsDialogDispatchProps
{
    handleApplyChanges();
    clearEditedBusinessInfo();
}

export class BrandSettingsDialog extends React.PureComponent<BrandSettingsDialogProps & BrandSettingsDialogDispatchProps>
{
    public componentWillUnmount()
    {
        this.props.clearEditedBusinessInfo();
    }

    public render()
    {
        const fontSectionSelectedClass = { selected: this.props.source === EDITOR_TYPES.FONT_CONTROL };
        const colorSectionSelectedClass = { selected: this.props.source === EDITOR_TYPES.COLOR_CONTROL };
        return (
            <div>
                <div className="brandSettingsHeader">Brand settings</div>
                <section className="brandSettingsSection">
                    <BrandSettingsLogoContainer/>
                </section>
                <section className={classNames( "brandSettingsSection", colorSectionSelectedClass )}>
                    <h3>Brand colors</h3>
                    <BrandSettingsColorPickerContainer/>
                </section>
                <section className={classNames( "brandSettingsSection", fontSectionSelectedClass )}>
                    <BrandSettingsFontPickerContainer/>
                </section>
                <Button className="applyChanges" primary flat swapTheming onClick={this.props.handleApplyChanges}>Apply changes</Button>
            </div>
        );
    }
}
