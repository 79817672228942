import * as React from "react";
import classNames from "classnames";
import Button from "react-md/lib/Buttons/Button";

export interface ScrollingRowProps
{
    contentWrapperClassName: string;
    scrollToChildIndex?: number;
}

interface ScrollingRowState
{
    scrollPosition: number;
    rowWidth: number;
}

const SQUARE_POST_WIDTH = 200;

export class ScrollingRow extends React.PureComponent<ScrollingRowProps, ScrollingRowState>
{
    constructor( props )
    {
        super( props );
        this.state = {
            scrollPosition: 0,
            rowWidth: 0,
        };
    }

    public componentDidMount()
    {
        window.addEventListener( "resize", this.updateRowWidth );
        this.updateRowWidth();
        this.scrollToSelectedChildPosition();
    }

    public componentWillUnmount()
    {
        window.removeEventListener( "resize", this.updateRowWidth );
    }

    public render()
    {
        return <div className="homePostRowWrap">
            <div className="homePostRowTray" ref="scrollableRowContainerRef" onScroll={this.updateScrollPositionState}>
                <div className={this.props.contentWrapperClassName} ref="contentWrapper">
                    {this.props.children}
                </div>
            </div>
            {this.createGradients()}
        </div>;
    }

    private getChildNodeScrollPosition( childIndex )
    {
        const { contentWrapper } = this.refs;
        if ( contentWrapper && "children" in contentWrapper )
        {
            const targetChildElement = contentWrapper.children[childIndex] as HTMLDivElement;
            if ( targetChildElement )
            {
                const SCROLL_MARGIN = 60;
                const postScrollPosition = targetChildElement.offsetLeft;
                return postScrollPosition - SCROLL_MARGIN;
            }
        }
        return 0;
    }

    private scrollToSelectedChildPosition()
    {
        if ( this.props.scrollToChildIndex )
        {
            const scrollPosition = this.getChildNodeScrollPosition( this.props.scrollToChildIndex );
            if ( scrollPosition !== this.state.scrollPosition )
            {
                this.setScrollPosition( scrollPosition );
            }
        }
    }

    private createGradients()
    {
        return <>
            <div className={classNames( "homePostRowGradientContainer left", { canScroll: this.shouldShowLeftScroll( this.state.scrollPosition ) } )}>
                <div className="homePostRowGradient leftGradient"/>
                <div className="homePostRowGradient leftGradient extended"/>
                <div className="homePostRowButton">
                    <Button icon={true}
                            className={classNames( "homePostRowArrow leftArrow",
                                { canScroll: this.shouldShowLeftScroll( this.state.scrollPosition ) } )}
                            iconClassName="material-icons"
                            iconChildren="arrow_forward_ios"
                            onClick={this.handleScrollLeft}/>
                </div>
            </div>
            <div className={classNames( "homePostRowGradientContainer right",
                { canScroll: this.shouldShowRightScroll( this.state.scrollPosition ) } )}>
                <div className="homePostRowGradient rightGradient"/>
                <div className="homePostRowGradient rightGradient extended"/>
                <div className="homePostRowButton">
                    <Button icon={true}
                            className={classNames( "homePostRowArrow rightArrow",
                                { canScroll: this.shouldShowRightScroll( this.state.scrollPosition ) } )}
                            iconClassName="material-icons"
                            iconChildren="arrow_forward_ios"
                            onClick={this.handleScrollRight}/>
                </div>
            </div>
        </>;
    }

    private setScrollPosition( aScrollPosition )
    {
        const { scrollableRowContainerRef } = this.refs;
        if ( scrollableRowContainerRef && "scrollLeft" in scrollableRowContainerRef )
        {
            scrollableRowContainerRef.scrollLeft = aScrollPosition;
            this.setState( {
                scrollPosition: scrollableRowContainerRef.scrollLeft,
            } );
        }
    }

    private modifyScrollPosition( aScrollPositionDelta )
    {
        const { scrollableRowContainerRef } = this.refs;
        if ( scrollableRowContainerRef && "scrollLeft" in scrollableRowContainerRef )
        {
            scrollableRowContainerRef.scrollLeft += aScrollPositionDelta;
            this.setState( {
                scrollPosition: scrollableRowContainerRef.scrollLeft,
            } );
        }
    }

    private updateScrollPositionState = () =>
    {
        const { scrollableRowContainerRef } = this.refs;
        if ( scrollableRowContainerRef && "scrollLeft" in scrollableRowContainerRef )
        {
            this.setState( {
                scrollPosition: scrollableRowContainerRef.scrollLeft,
            } );
        }
    }

    private updateRowWidth = () =>
    {
        const { scrollableRowContainerRef } = this.refs;
        if ( scrollableRowContainerRef && "clientWidth" in scrollableRowContainerRef )
        {
            this.setState( {
                rowWidth: scrollableRowContainerRef.clientWidth,
            } );
        }
    }

    private handleScrollLeft = () =>
    {
        const { rowWidth } = this.state;
        const scrollDelta = (rowWidth - SQUARE_POST_WIDTH) * -1;
        this.modifyScrollPosition( scrollDelta );
    }

    private handleScrollRight = () =>
    {
        const { rowWidth } = this.state;
        const scrollDelta = rowWidth - SQUARE_POST_WIDTH;
        this.modifyScrollPosition( scrollDelta );
    }

    private shouldShowLeftScroll = ( scrollPosition: number ) =>
    {
        return scrollPosition > 0;
    }
    private shouldShowRightScroll = ( scrollPosition: number ) =>
    {
        const { scrollableRowContainerRef } = this.refs;
        const { rowWidth } = this.state;
        if ( scrollableRowContainerRef && "scrollLeft" in scrollableRowContainerRef )
        {
            return scrollPosition < scrollableRowContainerRef.scrollWidth - rowWidth - 10;
        }
        return false;
    }
}
