import * as React from "react";
import Button from "react-md/lib/Buttons/Button";
import TextField from "react-md/lib/TextFields/TextField";

export interface BusinessNameProps
{
    handleBackClicked?: () => void;
    handleNextClicked?: () => void;
    handleClose?: () => void;
    businessName?: string;
    nextButtonText?: string;
}

export interface BusinessNameDispatchProps
{
    updateBusinessName: ( businessName: string ) => void;
}

export class BusinessName extends React.PureComponent<BusinessNameProps & BusinessNameDispatchProps>
{
    public render()
    {
        return (
            <div className="businessNameDialog">
                <Button className="closeX"
                        icon={true}
                        iconClassName="material-icons"
                        iconChildren="close"
                        onClick={this.props.handleClose}/>
                <div className="dialogContainer">
                    <div className="headerContainer">
                        <p className="headerText1">What is the name of your business?</p>
                        <p className="headerText2">Don't worry, you can change it later.</p>
                    </div>
                    <TextField
                        required
                        floating
                        id="business-name"
                        className="businessTextField"
                        placeholder="Business Name"
                        label="Business Name"
                        value={this.props.businessName || ""}
                        onChange={this.handleOnChange}/>
                    <div className="buttonContainer">
                        <Button flat primary className="dialogBack"
                                onClick={this.props.handleBackClicked}>Back</Button>
                        <Button flat primary swapTheming className="dialogConfirm"
                                disabled={!this.props.businessName}
                                onClick={this.props.handleNextClicked}>{this.props.nextButtonText || "Next"}</Button>
                    </div>
                </div>
            </div>
        );
    }

    private handleOnChange = ( content: string | number ) =>
    {
        this.props.updateBusinessName( content as string );
    }
}
