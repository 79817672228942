import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../_types";
import { getActiveDesignMediaObject } from "../../ducks/ui";
import { SingleMediaView, SingleMediaViewDispatchProps, SingleMediaViewProps } from "../SingleMediaView";
import { uiActions } from "../../actions";
import { modalServices } from "../../services";
import { videoTrimmerServices } from "../../services/videoTrimmer.services";

const mapStateToProps = ( storeState: StoreState ): SingleMediaViewProps =>
{
    return {
        activeDesignMediaObject: getActiveDesignMediaObject( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): SingleMediaViewDispatchProps =>
{
    return {
        onGoBack(): void
        {
            dispatch( uiActions.replaceMediaModeEnded() );
        },
        onReplaceClicked(): void
        {
            dispatch( modalServices.openReplaceMediaPickerDialog() );
        },
        onTrimVideoClicked( videoUrl: string ): void
        {
            videoTrimmerServices.openTrimmerModal( dispatch, videoUrl );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( SingleMediaView );
