import * as React from "react";
import DesignTrayDesignCanvasContainer from "../containers/DesignTrayDesignCanvas.container";
import { ASPECT_RATIO_VALUE, DEVELOPMENT_STATUS, doesDesignSupportVideo, EDITOR_TYPES_TITLES } from "../../helpers";
import { ErrorBoundary } from "../ErrorBoundary";
import Button from "react-md/lib/Buttons/Button";
import { AspectRatio, Design } from "../../_types";
import { filter, includes, map, reject, startCase } from "lodash";
import classNames from "classnames";
import MoreLayoutsCoachMarkContainer from "./containers/MoreLayoutsCoachMark.container";
import { Delayed } from "../Delayed";
import { ControlPickerHeader } from "../ControlPickerHeader";

export interface DesignTrayProps
{
    designs: Design[];
    brandSlideDesign: Design;
    aspectRatio: AspectRatio;
    selectedDesignId: number;
    hasVideoInMediaList: boolean;
    hidePlayButton: boolean;
    shouldShowMoreLayoutsCoachMark: boolean;
}

export interface DesignTrayDispatchProps
{
    onShowCatalogClicked( e ): void;
}

export class DesignTray extends React.PureComponent<DesignTrayProps & DesignTrayDispatchProps>
{
    public render()
    {
        let designs = filter( this.props.designs, this.shouldShowDesign );
        designs = reject( designs, this.isBrandSlideDesign );
        const aspectRatio = this.props.aspectRatio;
        const aspectRatioValue = ASPECT_RATIO_VALUE[aspectRatio];
        const catalogButtonText = "More layouts";
        return (
            <>
                <ControlPickerHeader title={startCase( EDITOR_TYPES_TITLES.TEMPLATES )}/>
                <div className="designTray">
                    <div className="designList">
                        {
                            map( designs, ( design ) =>
                            {
                                const designId = design.id;
                                const isSelectedDesign = designId === this.props.selectedDesignId;
                                return (
                                    <div className={classNames( "designContainer", { selected: isSelectedDesign },
                                        { devModeBorder: design.status === DEVELOPMENT_STATUS } )}
                                         key={`${designId}-${aspectRatioValue}`}>
                                        <ErrorBoundary errorMessage="There was a problem loading this template">
                                            <DesignTrayDesignCanvasContainer
                                                design={design}
                                                aspectRatio={aspectRatio}
                                                lazyLoad={true}
                                                iframeId={`design-tray-${designId}-${aspectRatioValue}`}
                                                isSelectedDesign={isSelectedDesign}
                                                hidePlayButton={this.props.hidePlayButton}
                                            />
                                        </ErrorBoundary>
                                    </div>
                                );
                            } )
                        }
                    </div>
                    <div className="moreTemplates">
                        <div className="riplGradientWrap">
                            <Button primary flat swapTheming className="large fullWidth moreLayoutsButton" onClick={this.props.onShowCatalogClicked}>
                                {catalogButtonText}
                            </Button>
                            {this.props.shouldShowMoreLayoutsCoachMark && <Delayed waitBeforeShow={1500}><MoreLayoutsCoachMarkContainer/></Delayed>}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    private shouldShowDesign = ( design ) =>
    {
        const aspectRatio = this.props.aspectRatio;
        if ( design && includes( design.aspect_ratios_supported, aspectRatio ) )
        {
            const doesMixModelContainVideo = this.props.hasVideoInMediaList;
            if ( doesMixModelContainVideo )
            {
                return doesDesignSupportVideo( design );
            }
            else
            {
                return true;
            }
        }
        else
        {
            return false;
        }
    }

    private isBrandSlideDesign = ( design ) =>
    {
        return !!this.props.brandSlideDesign && design.id === this.props.brandSlideDesign.id;
    }
}
