import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../../_types";
import { modalServices, userServices } from "../../../../services";
import {
    EpidemicSoundESDisconnectButton,
    EpidemicSoundESDisconnectButtonDispatchProps,
    EpidemicSoundESDisconnectButtonProps,
} from "../EpidemicSoundESDisconnectButton";
import { getUserSlug } from "../../../../ducks/user";

const mapStateToProps = ( storeState: StoreState ): EpidemicSoundESDisconnectButtonProps =>
{
    return {
        userSlug: getUserSlug( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): EpidemicSoundESDisconnectButtonDispatchProps =>
{
    return {
        onDisconnectClicked( userSlug: string )
        {
            dispatch( userServices.clientEpidemicSoundDisconnect( userSlug ) );
            dispatch( modalServices.closeAllLightboxes() );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( EpidemicSoundESDisconnectButton );
