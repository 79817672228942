import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../../_types";
import {
    EpidemicSoundCollectionItem,
    EpidemicSoundCollectionItemDispatchProps,
    EpidemicSoundCollectionItemProps,
} from "../EpidemicSoundCollectionItem";
import { epidemicSoundCatalogServices } from "../../../../services/epidemicSoundCatalog.services";

const mapStateToProps = ( storeState: StoreState, ownProps: EpidemicSoundCollectionItemProps ): EpidemicSoundCollectionItemProps =>
{
    return {
        ...ownProps,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): EpidemicSoundCollectionItemDispatchProps =>
{
    return {
        loadCollectionDetails: ( epidemicCollectionId: string ) =>
        {
            dispatch( epidemicSoundCatalogServices.loadEpidemicCollectionDetails( epidemicCollectionId ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( EpidemicSoundCollectionItem );
