import { connect } from "react-redux";
import { PostMediaPreview, PostMediaPreviewProps } from "../PostMediaPreview";
import { getExceedsCharacterLimitError, getExceedsHashtagLimitError, getScheduledSendDatetime } from "../../ducks/shareModel";
import { isPostFinishInProgress } from "../../ducks/ui";
import { StoreState } from "../../_types";
import { canUseEasyCaption } from "../../ducks/user";

const mapStateToProps = ( storeState: StoreState ): PostMediaPreviewProps =>
{
    return {
        scheduledSendDatetime: getScheduledSendDatetime( storeState ),
        isPostFinishInProgress: isPostFinishInProgress( storeState ),
        exceedsHashtagLimitError: getExceedsHashtagLimitError( storeState ),
        exceedsCharacterLimitError: getExceedsCharacterLimitError( storeState ),
        canUseEasyCaption: canUseEasyCaption( storeState ),
    };
};

export default connect(
    mapStateToProps,
    () => ({}),
)( PostMediaPreview );
