import * as React from "react";
import * as CheckmarkGreen from "../assets/img/checkmark_green.svg";
import * as CrossRed from "../assets/img/cross_red.svg";
import { RIPL_TIER_PREMIUM, TIER_BASE_SUPPORTED_FEATURES, TIER_PREMIUM_SUPPORTED_FEATURES, TIER_SUPPORTED_FEATURES_DESCRIPTIONS } from "../helpers";
import classNames from "classnames";

interface ExplanationFeatureItemProps
{
    isSupported: boolean;
    options?: {
        description?: string;
        key?: string;
        isBold?: boolean;
    };
}

interface ExplanationFeatureItemFromKeyProps
{
    tier: ProductTier;
    isSupported: boolean;
    featureKey: string;
    showDescription?: boolean;
}

export function ExplanationFeatureItemFromKey( props: ExplanationFeatureItemFromKeyProps )
{
    const { tier, featureKey, isSupported } = props;
    const description = props.showDescription ? TIER_SUPPORTED_FEATURES_DESCRIPTIONS[featureKey] : null;
    const isBold = shouldBoldItem( tier, featureKey );
    return <ExplanationFeatureItem isSupported={isSupported} options={{ description, key: featureKey, isBold }}/>;
}

export function ExplanationFeatureItem( props: ExplanationFeatureItemProps )
{
    const { isSupported } = props;
    const options = props.options || {};
    const checkmark = getFeatureItemCheckmark( isSupported );

    return (<div className="featureItem" key={options.key || options.description}>
        <div className="checkmark">{checkmark}</div>
        {options.description &&
         <div className={classNames( "description", { notSupported: !isSupported, bold: options.isBold } )}>
             {options.description}
         </div>
        }
    </div>);
}

function getFeatureItemCheckmark( isSupported: boolean )
{
    if ( isSupported )
    {
        return <img src={CheckmarkGreen} alt="Is Supported"/>;
    }
    else
    {
        return <img src={CrossRed} alt="Is Not Supported"/>;
    }
}

function shouldBoldItem( tier: ProductTier, featureKey: string )
{
    if ( tier !== RIPL_TIER_PREMIUM )
    {
        return false;
    }

    return TIER_PREMIUM_SUPPORTED_FEATURES[featureKey] && !TIER_BASE_SUPPORTED_FEATURES[featureKey];
}
