import { CollectionData, Post } from "../../_types";
import * as React from "react";
import { map } from "lodash";
import { CollectionRowPost } from "./CollectionRowPost";
import { COLLECTION_TYPE_USER_RECENT_POSTS } from "../../_types/api";
import { eventTracker, history, POSTS_PAGE_URL } from "../../helpers";
import { HomePostRow } from "./HomePostRow";

export interface CollectionRowProps
{
    collection: CollectionData;
    collectionRowIndex: number;
}

export interface CollectionRowDispatchProps
{
    handleCustomizePost: ( post: Post, collectionRowPostIndex: number ) => void;
    openCollection: ( slug ) => void;
}

export class CollectionRow extends React.PureComponent<CollectionRowProps & CollectionRowDispatchProps>
{
    public render()
    {
        const { id, name } = this.props.collection;
        const renderModel = {
            id,
            name,
            shouldRenderSeeAll: true,
            handleSeeAllClicked: this.handleOpenCollection,
        };

        const collectionPosts = this.props.collection.posts;
        if ( collectionPosts.length )
        {
            return (
                <HomePostRow {...renderModel}>
                    {map( collectionPosts, ( collectionPost, index ) =>
                    {
                        return <CollectionRowPost
                            key={collectionPost.id}
                            post={collectionPost}
                            index={index}
                            handleCustomizePost={this.props.handleCustomizePost}/>;
                    } )}
                </HomePostRow>
            );
        }
        return "";
    }

    private handleOpenCollection = () =>
    {
        const { collection, collectionRowIndex } = this.props;
        const { collection_type, slug } = collection;
        eventTracker.logCollectionRowSeeAllTapped( collectionRowIndex, slug );
        if ( collection_type === COLLECTION_TYPE_USER_RECENT_POSTS )
        {
            history.push( POSTS_PAGE_URL );
        }
        else
        {
            this.props.openCollection( slug );
        }
    }
}
