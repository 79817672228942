import * as React from "react";
import PostsPanel from "./PostsPanel";
import { Post } from "../../_types";
import { ErrorBoundary } from "../ErrorBoundary";
import NoPostsPanelContainer from "./containers/NoPostsPanelContainer";
import { POST_BUTTON_MAP } from "../../helpers";

export interface DraftTabProps
{
    postData: Post[];
}

export interface DraftTabDispatchProps
{
    loadData: () => void;
}

export class DraftTab extends React.PureComponent<DraftTabProps & DraftTabDispatchProps>
{
    public componentDidMount()
    {
        this.props.loadData();
    }

    public render()
    {
        return (
            <div className="subNavContent draftTabContent">
                <div className="subNavInnerWrap">
                    {this.createContent()}
                </div>
            </div>
        );
    }

    private createContent = () =>
    {
        const postData = this.props.postData;
        if ( postData.length > 0 )
        {
            return (
                <ErrorBoundary errorMessage="There was a problem creating the Drafts tab. If this continues, click Restart below."
                               showResetButton={true}>
                    <PostsPanel postData={postData}
                                showCreateBlank={true}
                                postButtonTextMap={POST_BUTTON_MAP.MY_POSTS}/>
                </ErrorBoundary>
            );
        }
        return <NoPostsPanelContainer/>;
    }
}
