import * as React from "react";
import classNames from "classnames";
import { getMonthlyAmount, isAnnualPlan, isMonthlyPlan, isPlanDiscounted } from "../ducks/pricing";
import { dollarsFormattedForCurrency, LOCALE_US, USD_CODE } from "../helpers";
import { getLocaleForCurrency } from "../helpers/currencyHelper";

export interface PriceBlockProps
{
    plan: StripePlanData;
    selected: boolean;
    updatePlanSelection: ( plan: StripePlanData ) => void;
    shouldShowSimplifiedUpsell: boolean;
    currencyCode?: CurrencyCode;
    currentExchangeRate?: number;
    shouldShowExchangeRates?: boolean;
}

export class PriceBlock extends React.PureComponent<PriceBlockProps>
{
    public render()
    {
        const isDiscountedPlan = isPlanDiscounted( this.props.plan );
        const priceBlockClassNames = classNames( "priceBlock", {
            priceBlockSelected: this.props.selected,
            testDriveMargin: this.props.shouldShowSimplifiedUpsell,
        } );

        return (
            <div className={priceBlockClassNames} onClick={this.handleClick}>
                <div className={classNames( "planDescription", { discountedPlanDescription: isDiscountedPlan } )}>
                    {this.props.children}
                    {this.getLabelAbovePricing()}
                    {this.renderStrikeThroughOnOriginalAmountIfDiscounted()}
                    {this.getPriceAmountText()}
                </div>
            </div>
        );
    }

    private getLabelAbovePricing = () =>
    {
        const isDiscountedPlan = isPlanDiscounted( this.props.plan );
        const planTypeText = this.getPlanTypeText( isDiscountedPlan );

        return (<div className={classNames( "priceBlockTypeText", { selectedPriceBlockTypeText: this.props.selected } )}>{planTypeText}</div>);
    }

    private renderStrikeThroughOnOriginalAmountIfDiscounted = () =>
    {
        if ( isPlanDiscounted( this.props.plan ) )
        {
            const convertedMonthlyAmount = this.convertUSDAmountToSelectedCurrency( this.props.plan.originalAmount );
            const localeFormattedPrice = this.getLocaleFormattedPrice( convertedMonthlyAmount );
            return (<div><span className="originalAmount">{localeFormattedPrice}</span></div>);
        }
        return null;
    }

    private getPlanTypeText = ( isDiscountedPlan: boolean ) =>
    {
        if ( isDiscountedPlan )
        {
            return this.getDiscountDuration();
        }
        return isMonthlyPlan( this.props.plan ) ? "MONTHLY" : "YEARLY";
    }

    private getDiscountDuration = () =>
    {
        const plan = this.props.plan;
        const { coupon_details } = plan;

        const couponRepeatsMoreThanOnce = coupon_details && coupon_details.duration_in_months > 1;
        if ( isMonthlyPlan( plan ) && couponRepeatsMoreThanOnce )
        {
            return "MONTHLY";
        }
        return isMonthlyPlan( plan ) ? "FIRST MONTH" : "FIRST YEAR";
    }

    private getPriceAmountText = () =>
    {
        const monthlyDetails: { amount: number; originalAmount: number } = getMonthlyAmount( this.props.plan );
        const convertedMonthlyAmount = this.convertUSDAmountToSelectedCurrency( monthlyDetails.amount );
        const convertedAnnualAmount = this.convertUSDAmountToSelectedCurrency( this.props.plan.amount );
        const localeFormattedMonthlyPrice = this.getLocaleFormattedPrice( convertedMonthlyAmount );

        const isAnnual = isAnnualPlan( this.props.plan );
        const isNonUSD = this.props.currencyCode !== USD_CODE;
        const priceBlockAmountTextClassNames = classNames( "priceBlockAmountText", { nonUSD: isNonUSD } );
        if ( isAnnual )
        {
            const localeFormattedAnnualPrice = this.getLocaleFormattedPrice( convertedAnnualAmount );
            const classNamesString = classNames( "priceBlockBreakdownText", { selectedBreakdownText: this.props.selected } );
            return (
                <>
                    <div className={priceBlockAmountTextClassNames}>{localeFormattedMonthlyPrice}<span
                        className="finePrint">/mo</span></div>
                    <div className={classNamesString}>
                        {localeFormattedAnnualPrice}/year
                    </div>
                </>
            );
        }
        else
        {
            return (
                <div className={priceBlockAmountTextClassNames}>{localeFormattedMonthlyPrice}</div>
            );
        }

    }

    private convertUSDAmountToSelectedCurrency = ( amountInUSD: number ): number =>
    {
        if ( this.shouldShowConvertedCurrency() )
        {
            const conversionRate = this.props.currentExchangeRate;
            return amountInUSD * conversionRate;
        }
        return amountInUSD;
    }

    private handleClick = () =>
    {
        this.props.updatePlanSelection( this.props.plan );
    }

    private getLocaleFormattedPrice = ( amount: number ): string =>
    {
        if ( this.shouldShowConvertedCurrency() )
        {
            return dollarsFormattedForCurrency( getLocaleForCurrency( this.props.currencyCode ), this.props.currencyCode, amount );
        }
        return dollarsFormattedForCurrency( LOCALE_US, USD_CODE, amount );
    }

    private shouldShowConvertedCurrency = () =>
    {
        return this.props.shouldShowExchangeRates;
    }
}
