import * as React from "react";
import * as EmailIconUnselected from "../../assets/img/email-icon.svg";

export function EmailIcon(): JSX.Element
{
    return (
        <div className="selectableIcon">
            <img src={EmailIconUnselected} alt="Email Icon"/>
        </div>
    );
}
