import { connect } from "react-redux";
import { Dispatch } from "redux";
import { userBusinessServices } from "../../../services";
import { eventTracker, MENU } from "../../../helpers";
import { ColorValue, StoreState, UserBusiness } from "../../../_types";
import { CreativeTabColorPicker, CreativeTabColorPickerDispatchProps } from "../CreativeTabColorPicker";
import { store } from "../../../store";
import { getEditedBusinessInfo } from "../../../ducks/ui";
import {
    BRAND_SLIDE_COLOR_ONE,
    BRAND_SLIDE_COLOR_TWO,
    BUSINESS_PRIMARY_COLOR_KEY,
    BUSINESS_SECONDARY_COLOR_KEY,
    PRIMARY,
    SECONDARY,
} from "../../../_types/api";
import { getCurrentBusiness } from "../../../ducks/userBusiness";

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): CreativeTabColorPickerDispatchProps =>
{
    return {
        handleClearColor: ( userBusinessKey: keyof UserBusiness ) =>
        {
            dispatch( userBusinessServices.update( { [userBusinessKey]: null } ) );
            sendBrandColorAppliedTrackingEvent( userBusinessKey );
        },
        onColorPickedSuccess: ( userBusinessKey: keyof UserBusiness, startValue: ColorValue ) =>
        {
            updateColor( dispatch, userBusinessKey, startValue );
        },
    };
};

function updateColor( dispatch: Dispatch<StoreState>, userBusinessKey: keyof UserBusiness, startValue: ColorValue )
{
    const state = store.getState();
    const newValue = getEditedBusinessInfo( state )[userBusinessKey];
    if ( newValue && newValue !== startValue )
    {
        const brandSlideColorData = getBrandSlideColorData( state, userBusinessKey, newValue as string );

        const newColorDataForBusinessUpdate = {
            [userBusinessKey]: newValue,
            ...brandSlideColorData,
        };
        dispatch( userBusinessServices.update( newColorDataForBusinessUpdate ) );
        sendBrandColorAppliedTrackingEvent( userBusinessKey );
    }
}

function getCorrespondingBrandSlideColorKey( userBusinessKey: keyof UserBusiness )
{
    if ( userBusinessKey === BUSINESS_PRIMARY_COLOR_KEY )
    {
        return BRAND_SLIDE_COLOR_ONE;
    }
    else if ( userBusinessKey === BUSINESS_SECONDARY_COLOR_KEY )
    {
        return BRAND_SLIDE_COLOR_TWO;
    }
}

function getBrandSlideColorData( state: StoreState, userBusinessKey: keyof UserBusiness, newColorValue: string )
{
    const currentBusiness = getCurrentBusiness( state );
    const brandSlideColorKey = getCorrespondingBrandSlideColorKey( userBusinessKey );

    if ( !!brandSlideColorKey )
    {
        const brandSlideColorValue = !!currentBusiness[brandSlideColorKey] ? currentBusiness[brandSlideColorKey] : newColorValue;
        return { [brandSlideColorKey]: brandSlideColorValue };
    }

    return {};
}

function sendBrandColorAppliedTrackingEvent( userBusinessKey: keyof UserBusiness )
{
    if ( userBusinessKey.startsWith( PRIMARY ) )
    {
        eventTracker.logBrandSettingsColorApplied( MENU, PRIMARY );
    }
    else if ( userBusinessKey.startsWith( SECONDARY ) )
    {
        eventTracker.logBrandSettingsColorApplied( MENU, SECONDARY );
    }
}

export default connect(
    () => ({}),
    mapDispatchToProps,
)( CreativeTabColorPicker );
