import * as React from "react";
import { ChangeEvent } from "react";
import { PencilButton } from "../PencilButton";

export interface BrandLogoProps
{
    brandLogo: string;
    showEditPencil: boolean;
}

export interface BrandLogoDispatchProps
{
    handleLogoUpload( file: File );
    handleLogoClicked( imageUploadClicked: () => void );
}

export class BrandLogo extends React.PureComponent<BrandLogoProps & BrandLogoDispatchProps>
{
    private fileInputRef: React.RefObject<HTMLInputElement>;

    constructor( props )
    {
        super( props );
        this.fileInputRef = React.createRef();
    }

    public render()
    {
        const { brandLogo } = this.props;
        return (
            <div className="brandLogo">
                <div className="imageContainer" style={{ "--aspect-ratio": 1 }} onClick={this.onLogoClick}>
                    <img className="logoImage" src={brandLogo} alt="brand logo"/>
                </div>
                <input
                    type="file"
                    ref={this.fileInputRef}
                    accept="image/*"
                    id="image-file"
                    className="hidden"
                    onChange={this.onFileInputChanged}
                />
                {this.props.showEditPencil && <PencilButton onClick={this.onLogoClick}/>}
            </div>
        );
    }

    private onFileInputChanged = ( e: ChangeEvent<HTMLInputElement> ) =>
    {
        const file = e.target.files[0];
        if ( file )
        {
            this.props.handleLogoUpload( file );
        }
    }

    private onLogoClick = () =>
    {
        this.props.handleLogoClicked( this.clickImageUpload );
    }

    private clickImageUpload = () =>
    {
        if ( this.fileInputRef && this.fileInputRef.current )
        {
            this.fileInputRef.current.click();
        }
    }
}
