import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../_types";
import {
    TemplatePreviewBrandableView,
    TemplatePreviewBrandableViewDispatchProps,
    TemplatePreviewBrandableViewProps,
} from "../TemplatePreviewBrandableView";
import { Post } from "../../_types/index";
import { mixModelActions, uiActions } from "../../actions";
import { getTemplatePreviewAnimationComplete } from "../../ducks/ui";
import { getControlsConfigsLookup, getDesignFromSlug } from "../../ducks/designs";
import { store } from "../../store";
import { getCurrentBusiness } from "../../ducks/userBusiness";
import { updateInputMediaFilesWithoutCreatingDraft } from "../../helpers/index";

export interface TemplatePreviewBrandableViewContainerProps
{
    post: Post;
    showDesignPreview: boolean;
}

const mapStateToProps = ( storeState: StoreState, ownProps: TemplatePreviewBrandableViewContainerProps ): TemplatePreviewBrandableViewProps =>
{
    return {
        hidePlayButton: !getTemplatePreviewAnimationComplete( storeState ),
        ...ownProps,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>,
                             ownProps: TemplatePreviewBrandableViewContainerProps ): TemplatePreviewBrandableViewDispatchProps =>
{
    return {

        async setSelectedDesign()
        {
            const storeState = store.getState();

            const { user } = storeState;
            const currentBusiness = getCurrentBusiness( storeState );
            const controlsConfigsLookup = getControlsConfigsLookup( storeState );
            dispatch( mixModelActions.mixModelStarted( {
                currentBusiness,
                controlsConfigsLookup,
                user,
            } ) );

            const design = getDesignFromSlug( storeState, ownProps.post.design_slug );
            await dispatch( mixModelActions.designSelected( { design, aspectRatio: ownProps.post.aspect_ratio } ) );

            const newPostData = updateInputMediaFilesWithoutCreatingDraft( ownProps.post );
            await dispatch( mixModelActions.importPostDataFromCuratedPost( newPostData ) );
        },
        onVideoStarted: () =>
        {
            dispatch( uiActions.templatePreviewAnimationStarted() );
        },
        onVideoStopped: () =>
        {
            dispatch( uiActions.templatePreviewAnimationCompleted() );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( TemplatePreviewBrandableView );
