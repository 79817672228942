import { ActionCreator } from "../helpers";
import { createAction } from "redux-actions";

const actionCreator = new ActionCreator( "BUSINESS_TYPES" );

const businessTypeTypes = {
    load: actionCreator.generateTypes( "LOAD" ),
};

export const businessTypeActions = {
    loadSuccess: createAction<NormalizrData>( businessTypeTypes.load.success ),
    loadFailure: createAction<string>( businessTypeTypes.load.failure ),
};
