import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../_types";
import { customizationHelper, history, HOMEPAGE_URL } from "../../helpers";
import { getHasLoadedMeThisSession, hasPartnerLoginError } from "../../ducks/ui";
import { modalServices } from "../../services";
import { isUserPersonallySubscribed } from "../../ducks/user";
import { PartnerUserLoginPage, PartnerUserLoginPageProps, PartnerUserLoginPagePropsDispatchProps } from "../PartnerUserLoginPage";
import { partnerServices } from "../../services/partner.services";

const mapStateToProps = ( storeState: StoreState ): PartnerUserLoginPageProps =>
{
    return {
        hasLoadedMeThisSession: getHasLoadedMeThisSession( storeState ),
        isUserPersonallySubscribed: isUserPersonallySubscribed( storeState ),
        hasLoginError: hasPartnerLoginError( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): PartnerUserLoginPagePropsDispatchProps =>
{
    return {
        redirectToHome: async () =>
        {
            history.replace( HOMEPAGE_URL );
            await dispatch( modalServices.closeAllLightboxes() );
        },
        handleCreatePostAction: async ( queryParams: string ) =>
        {
            const templatePostSlug = partnerServices.getTemplateSlugFromQueryParams( queryParams );

            await customizationHelper.openExamplePostFromPartnerSiteWithSlug( dispatch, templatePostSlug );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( PartnerUserLoginPage );
