import { ReducerCreator } from "../helpers";
import { Action } from "redux-actions";
import { ContentSearchData, ContentSearchState, Post, StoreState } from "../_types";
import { concat, find, forEach } from "lodash";
import { contentSearchActions } from "../actions";

const defaultState: ContentSearchState = {
    matches: null,
    query: "",
    error: false,
    total_count: 0,
    current_page: 1,
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( contentSearchActions.searchResultsLoaded, handleSearchResultsLoaded );
reducerCreator.addAction( contentSearchActions.searchResultsFailed, handleSearchResultsFailed );
reducerCreator.addAction( contentSearchActions.searchResultsCleared, handleSearchResultsCleared );
reducerCreator.addAction( contentSearchActions.searchModeExited, handleExitSearchMode );

export default reducerCreator.createReducer();

function handleSearchResultsLoaded( state: ContentSearchState, action: Action<ContentSearchData> ): ContentSearchState
{
    const matches = forEach( concat( state.matches || [], action.payload.matches ), ( match ) =>
    {
        match.post.is_content_search_post = true;
    } );

    return {
        ...state,
        ...action.payload,
        matches,
        error: false,
    };
}

function handleSearchResultsFailed( state: ContentSearchState ): ContentSearchState
{
    return {
        ...state,
        error: true,
    };
}

function handleSearchResultsCleared( state: ContentSearchState ): ContentSearchState
{
    return {
        ...defaultState,
        matches: [],
    };
}

function handleExitSearchMode( state: ContentSearchState ): ContentSearchState
{
    return {
        ...defaultState,
        query: null,
    };
}

export function getSearchedPostById( postId: number, state: StoreState ): Post
{
    const matchedPost =  find( state.contentSearch.matches, ( match ) =>
    {
        return match.post.id === postId;
    } );
    return matchedPost && matchedPost.post;
}

export const getContentSearchMatches = ( state: StoreState ) => state.contentSearch.matches;
export const getContentSearchTerm = ( state: StoreState ) => state.contentSearch.query;
export const getContentSearchNextPage = ( state: StoreState ) => state.contentSearch.next_page;
export const getContentSearchCurrentPageNumber = ( state: StoreState ) => state.contentSearch.current_page;
export const getContentSearchTotalMatches = ( state: StoreState ) => state.contentSearch.total_count;
export const contentSearchHasError = ( state: StoreState ) => state.contentSearch.error;
