import * as React from "react";
import { store } from "../store";
import VideoElementContainer from "./containers/VideoElement.container";
import { ASPECT_RATIO_VALUE, browserUtils } from "../helpers";
import { RecordingVideoProgress } from "./RecordingVideoProgress";
import { uiActions } from "../actions";
import Button from "react-md/lib/Buttons/Button";
import { RecordAPIUpdate } from "../_types/api";

export interface RecordingVideoElementProps
{
    isAnimationOutputType: boolean;
    videoUrl: string;
    imageUrl: string;
    aspectRatio: string;
    recordProgress: RecordAPIUpdate;
    recordError: boolean;
    shouldRenderStaticPreview: boolean;
    shouldRenderForCollabraPreview: boolean;
}

export interface RecordingVideoElementDispatchProps
{
    recordVideo: () => void;
}

export class RecordingVideoElement extends React.PureComponent<RecordingVideoElementProps & RecordingVideoElementDispatchProps>
{
    public componentDidMount()
    {
        if ( this.shouldRequestRecording() )
        {
            this.props.recordVideo();
        }
    }

    public componentWillUnmount(): void
    {
        store.dispatch( uiActions.mixModelRecordError( false ) );
        store.dispatch( uiActions.mixModelRecordProgress( undefined ) );
    }

    public render()
    {
        return (
            <>
                {!browserUtils.isMobileBrowser() &&
                 !this.props.isAnimationOutputType &&
                 !this.props.shouldRenderForCollabraPreview && <div className="staticOutputDescription">1 image</div>}
                <div className="recordingVideoElement">
                    <VideoElementContainer
                        {...this.props}
                        muted={false}
                        aspectRatio={ASPECT_RATIO_VALUE[this.props.aspectRatio]}
                        autoplay={true}
                        controls={true}
                    />
                    {this.createProgressIndicatorIfRecording()}
                    {this.createRetryContentIfError()}
                </div>
            </>
        );
    }

    private shouldRequestRecording = () =>
    {
        if ( this.props.isAnimationOutputType && !this.props.shouldRenderStaticPreview )
        {
            return !this.props.videoUrl;
        }
        return false;
    }

    private createProgressIndicatorIfRecording = () =>
    {
        if ( this.shouldRequestRecording() && !this.props.recordError )
        {
            return (
                <RecordingVideoProgress recordProgress={this.props.recordProgress}/>
            );
        }
    }

    private createRetryContentIfError = () =>
    {
        if ( this.props.recordError )
        {
            return (
                <div className="recordingVideoError">
                    <div className="darkness">&nbsp;</div>
                    <Button primary
                            flat
                            swapTheming
                            className="retryBtn"
                            onClick={this.props.recordVideo}>Retry recording</Button>
                </div>
            );
        }
    }
}
