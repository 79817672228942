import * as React from "react";
import { EditableInput } from "react-color/lib/components/common";
import { CustomPicker } from "react-color";
import { ExportedColorProps, InjectedColorProps } from "react-color/lib/components/common/ColorWrap";

class EditableColorInput extends React.PureComponent<InjectedColorProps & ExportedColorProps>
{
    public render()
    {
        let value = this.props.color;
        if ( value instanceof Object )
        {
            value = "";
        }
        return <div className="editableInputWrapper">
            <EditableInput
                value={value}
                onChange={this.props.onChange}/>
        </div>;
    }
}

export default CustomPicker( EditableColorInput );
