import { connect } from "react-redux";
import { DesignLoadProgress, DesignLoadProgressProps } from "../DesignLoadProgress";
import { StoreState } from "../../_types";
import { isProcessRunning } from "../../ducks/ui";

export interface DesignLoadProgressOwnProps
{
    iframeId: string;
}

const mapStateToProps = ( storeState: StoreState, ownProps: DesignLoadProgressOwnProps ): DesignLoadProgressProps =>
{
    return {
        loading: isProcessRunning( storeState, ownProps.iframeId ),
        ...ownProps,
    };
};

export default connect(
    mapStateToProps,
)( DesignLoadProgress );
