import * as React from "react";
import FontIcon from "react-md/lib/FontIcons/FontIcon";
import ListItemControl from "react-md/lib/Lists/ListItemControl";
import SelectionControl from "react-md/lib/SelectionControls/SelectionControl";
import { areMusicItemsEqual } from "../../../ducks/musicCatalog";
import { Music } from "../../../_types";

export interface MusicDialogTrackingItemProps
{
    track: Music;
    selectedTrack: Music;
    currentAudioPlayerMusicTrack: Music;
}

export interface MusicDialogTrackingItemDispatchProps
{
    updateMusicCatalogSelection: ( track: Music ) => void;
    playTrack: ( musicTrackToPlay: Music ) => void;
}

export class MusicDialogTrackingItem extends React.PureComponent<MusicDialogTrackingItemProps & MusicDialogTrackingItemDispatchProps>
{
    public render()
    {
        const { track, selectedTrack, currentAudioPlayerMusicTrack } = this.props;
        const fontIcon = areMusicItemsEqual( currentAudioPlayerMusicTrack, track ) ? "pause" : "play_arrow";
        return (
            <ListItemControl key={track.id}
                             className="track"
                             leftIcon={
                                 <FontIcon className="playPause" onClick={this.handleSelectedTrack}>{fontIcon}</FontIcon>
                             }
                             primaryAction={(
                                 <SelectionControl id={track.audio_url}
                                                   type="radio"
                                                   name="track[]"
                                                   className="playTrack"
                                                   label={track.display_name}
                                                   labelBefore={true}
                                                   checked={areMusicItemsEqual( track, selectedTrack )}
                                                   value={track.audio_url}
                                                   onChange={this.handleSelectedTrack}/>)}/>
        );
    }

    private handleSelectedTrack = () =>
    {
        if ( !areMusicItemsEqual( this.props.track, this.props.selectedTrack ) )
        {
            this.props.updateMusicCatalogSelection( this.props.track );
            this.props.playTrack( this.props.track );
        }
        else
        {
            if ( this.props.currentAudioPlayerMusicTrack )
            {
                this.props.playTrack( undefined );
            }
            else
            {
                this.props.playTrack( this.props.track );
            }
        }
    }
}
