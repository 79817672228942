import { createAction } from "redux-actions";
import { ActionCreator } from "../helpers";

const actionCreator = new ActionCreator( "LOCAL_SETTINGS" );

const localSettingsActionsTypes = {
    shouldShowLogoHint: {
        shouldShow: actionCreator.generateType( "LOGO_HINT", "SHOULD_SHOW" ),
    },
};

export const localSettingsActions = {
    shouldShowLogoHint: createAction<boolean>( localSettingsActionsTypes.shouldShowLogoHint.shouldShow ),
};
