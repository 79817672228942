import { SortedCaptionsByType, TextPickerCaptionData } from "../../../_types";
import { TextPickerRegularCaptions } from "./TextPickerRegularCaptions";
import { TextPickerExtraCaptions } from "./TextPickerExtraCaptions";
import * as React from "react";

interface TextPickerPerSlideCaptionProps
{
    sortedCaptionsByType: SortedCaptionsByType;
    activeCaptionControls: string[];
    shouldSupportExtraCaptions: boolean;
    hasClearedStylizedTextButtonCoachMark: boolean;
    changeTextPropertiesSubPanelCaptionData( captionData: TextPickerCaptionData );
}

export function TextPickerPerSlideCaptions( props: TextPickerPerSlideCaptionProps )
{
    const captions = props.sortedCaptionsByType;
    return (
        <>
            <TextPickerRegularCaptions
                activeCaptionControls={props.activeCaptionControls}
                regularCaptions={captions.regularCaptions}
            />
            <TextPickerExtraCaptions
                activeCaptionControls={props.activeCaptionControls}
                extraCaptions={captions.extraCaptions}
                shouldSupportExtraCaptions={props.shouldSupportExtraCaptions}
                hasClearedStylizedTextButtonCoachMark={props.hasClearedStylizedTextButtonCoachMark}
                changeTextPropertiesSubPanelCaptionData={props.changeTextPropertiesSubPanelCaptionData}
            />
        </>
    );
}