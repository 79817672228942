import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../_types";
import {
    canUserAccessPaidFeatures,
    getCurrentUser,
    getUserCombinedSubscriptionInfo,
    getUserDefaultStripePaymentMethod,
    isUserStripeSubscribed,
} from "../../../ducks/user";
import { subscriptionServices, upsellServices } from "../../../services";
import ManageSubscriptionsContent, { ManageSubscriptionsContentDispatchProps, ManageSubscriptionsContentProps } from "../ManageSubscriptionsContent";
import { SUBSCRIPTION_FLOW_SOURCE_MANAGE_SUBSCRIPTION } from "../../../helpers";
import { isSubscriptionInfoSpinnerEnabled } from "../../../ducks/ui";

const mapStateToProps = ( storeState: StoreState ): ManageSubscriptionsContentProps =>
{
    return {
        user: getCurrentUser( storeState ),
        isProUser: canUserAccessPaidFeatures( storeState ),
        subscriptionInfoSpinnerEnabled: isSubscriptionInfoSpinnerEnabled( storeState ),
        subscriptionInfos: getUserCombinedSubscriptionInfo( storeState ),
        isNotStripeSubscriberAndHasDefaultStripePaymentData: !isUserStripeSubscribed( storeState ) && !!getUserDefaultStripePaymentMethod(
            storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): ManageSubscriptionsContentDispatchProps =>
{
    return {
        loadSubscriptionInfo: () =>
        {
            dispatch( subscriptionServices.getAccountInfo() );
        },
        showUpsell: () =>
        {
            dispatch(
                upsellServices.displayTryProNoWarmup( SUBSCRIPTION_FLOW_SOURCE_MANAGE_SUBSCRIPTION,
                    () =>
                    {
                        dispatch( subscriptionServices.getAccountInfo() );
                    },
                ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( ManageSubscriptionsContent );
