import { connect } from "react-redux";
import { Dispatch } from "redux";
import { store } from "../../../../store";
import { TextPicker, TextPickerDispatchProps, TextPickerProps } from "../TextPicker";
import {
    getCoachMarkStylizedTextButtonCleared,
    getCustomizableCanvas,
    getDesignCaptionData,
    getSelectedDesignElement,
    getSortedCaptionsTypes,
    getTextPropertiesSubPanelCaptionData,
} from "../../../../ducks/ui";
import { getPrimaryText, getSecondaryText, isCurrentDesignFoundation } from "../../../../ducks/mixModel";
import { StoreState, TextPickerCaptionData } from "../../../../_types";
import { uiActions } from "../../../../actions";
import { apptimizeVariables } from "../../../../helpers";

const mapStateToProps = ( storeState: StoreState ): TextPickerProps =>
{
    return {
        captionTextValue: {
            primaryText: getPrimaryText( storeState ),
            secondaryText: getSecondaryText( storeState ),
            captions: getDesignCaptionData( storeState ),
        },
        sortedCaptionsByType: getSortedCaptionsTypes( storeState ),
        activeCaptionControls: storeState.ui.activeCaptionControls,
        shouldSupportExtraCaptions: storeState.ui.designSupportsExtraCaptions,
        hasClearedStylizedTextButtonCoachMark: getCoachMarkStylizedTextButtonCleared( storeState ),
        textPropertiesSubPanelCaptionData: getTextPropertiesSubPanelCaptionData( storeState ),
        selectedDesignElement: getSelectedDesignElement( storeState ),
        shouldEnablePresetText: apptimizeVariables.shouldEnablePresetText() &&
                                isCurrentDesignFoundation( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): TextPickerDispatchProps =>
{
    return {
        changeTextPropertiesSubPanelCaptionData: ( captionData: TextPickerCaptionData ) =>
        {
            dispatch( uiActions.setTextPropertiesSubPanelCaptionData( captionData ) );
        },
        clearTextPropertiesSubPanelCaptionData: () =>
        {
            dispatch( uiActions.clearTextPropertiesSubPanelCaptionData() );
        },
        triggerUpdateDesignSupportsExtraCaptions: () =>
        {
            tellDesignToRequestTextObjectsForActivePage();
        },
    };
};

function tellDesignToRequestTextObjectsForActivePage()
{
    const state = store.getState();
    const canvas = getCustomizableCanvas( state );
    if ( canvas )
    {
        canvas.tellDesignToRequestTextObjectsForActivePage();
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( TextPicker );
