import * as React from "react";
import Button from "react-md/lib/Buttons/Button";
import {
    eventTracker,
    pricingTiersUIHelper,
    stringUtils,
    SUBSCRIPTION_INFO_BILLING_INTERVAL_LIFETIME,
    SUBSCRIPTION_INFO_BILLING_INTERVAL_MONTHLY,
    SUBSCRIPTION_INFO_BILLING_SOURCE_APPLE,
    SUBSCRIPTION_INFO_BILLING_SOURCE_GOOGLE,
    SUBSCRIPTION_INFO_BILLING_SOURCE_STRIPE,
    toMonthDayYearTimeZone,
} from "../../helpers";
import { NonStripeSubscriptionInfoData, StripeSubscriptionInfoData, UserState } from "../../_types";
import { capitalize, includes } from "lodash";
import CancelSurveyContainer from "./containers/CancelSurvey.container";
import { PaymentMethodInfo } from "../PaymentMethodInfo";
import { getCurrentProductTier } from "../../ducks/user";
import { store } from "../../store";

export interface SubscriptionInfoBlockProps
{
    user: UserState;
    subscriptionInfo: StripeSubscriptionInfoData | NonStripeSubscriptionInfoData;
}

export interface SubscriptionInfoBlockDispatchProps
{
    upgradeStripeSubscriptionToAnnual: ( stripeCustomerId: string ) => void;
    showUpdateCardForm: ( stripeCustomerId: string ) => void;
}

export interface SubscriptionInfoBlockState
{
    isCancelling: boolean;
}

export default class SubscriptionInfoBlock
    extends React.PureComponent<SubscriptionInfoBlockProps & SubscriptionInfoBlockDispatchProps, SubscriptionInfoBlockState>
{
    constructor( props )
    {
        super( props );
        this.state = {
            isCancelling: false,
        };
    }

    public render()
    {
        return (
            <>
                {this.state.isCancelling && <CancelSurveyContainer handleCancelSurveyBackTapped={this.handleCancelSurveyBackTapped}
                                                                   subscriptionInfo={this.getSubscriptionInfoAsStripeSubscriptionInfo()}
                />}
                {!this.state.isCancelling &&
                 <div className="subscriptionInfo manageSubscriptionBlock">
                     <h2 className="account">{this.getBillingSourceDisplay()}</h2>
                     <div className="subscriptionDetails">
                         <div>
                             <div className="membershipStatusHeader">{this.getMembershipStatusText()}</div>
                             <p>{this.getBillingIntervalDisplay()}</p>
                             {this.getSwitchSubscriptionActions()}
                         </div>
                         <div>
                             <div className="subscriptionStatusHeader">Status</div>
                             <p>{this.getSubscriptionStatusText()}</p>
                             {this.paymentMethodInfo()}
                         </div>
                     </div>

                     {this.getSubscriptionActions()}
                 </div>
                }

            </>
        );
    }

    private getBillingSourceDisplay = () =>
    {
        const billingSource = capitalize( this.props.subscriptionInfo.billing_source );
        if ( this.props.subscriptionInfo.billing_source === SUBSCRIPTION_INFO_BILLING_SOURCE_STRIPE )
        {
            return `${billingSource} Account for ${this.props.user.email}`;
        }
        return `${billingSource} Subscription`;
    }

    private getSwitchSubscriptionActions = () =>
    {
        if ( this.props.subscriptionInfo.billing_source === SUBSCRIPTION_INFO_BILLING_SOURCE_STRIPE &&
             this.props.subscriptionInfo.billing_interval === SUBSCRIPTION_INFO_BILLING_INTERVAL_MONTHLY )
        {
            return (<Button flat primary swapTheming className="button" onClick={this.handleStripeUpgradeToAnnualClicked}>Switch to yearly</Button>);
        }
        return "";
    }

    private getBillingIntervalDisplay = () =>
    {
        if ( this.props.subscriptionInfo.billing_interval === SUBSCRIPTION_INFO_BILLING_INTERVAL_LIFETIME )
        {
            return "";
        }
        return `Billed ${this.props.subscriptionInfo.billing_interval}`;
    }

    private getSubscriptionStatusText = () =>
    {
        if ( this.props.subscriptionInfo.billing_interval === SUBSCRIPTION_INFO_BILLING_INTERVAL_LIFETIME )
        {
            return "Does not expire";
        }
        else if ( this.props.subscriptionInfo.will_auto_renew )
        {
            return this.getNextPaymentDisplay();
        }
        else
        {
            return `Subscription will cancel on ${this.getSubscriptionExpirationDate()}`;
        }
    }

    private getNextPaymentDisplay = () =>
    {
        let nextPaymentPart = "Next payment";
        const isScheduledForPart = ` is scheduled for ${this.getSubscriptionExpirationDate()}`;
        if ( this.props.subscriptionInfo.billing_source === SUBSCRIPTION_INFO_BILLING_SOURCE_STRIPE )
        {
            const stripeSubscriptionInfoData = this.getSubscriptionInfoAsStripeSubscriptionInfo();
            nextPaymentPart += ` of ${stringUtils.getUSDZeroPaddedPrice( stripeSubscriptionInfoData.next_payment_amount )}`;
        }
        return nextPaymentPart + isScheduledForPart;
    }

    private getSubscriptionInfoAsStripeSubscriptionInfo()
    {
        return this.props.subscriptionInfo as StripeSubscriptionInfoData;
    }

    private getSubscriptionExpirationDate = () =>
    {
        return toMonthDayYearTimeZone( this.props.subscriptionInfo.subscription_expiration_date );
    }

    private getSubscriptionActions = () =>
    {
        if ( this.props.subscriptionInfo.billing_source === SUBSCRIPTION_INFO_BILLING_SOURCE_STRIPE )
        {
            return (<div className="subscription-actions buttonWrap">
                {this.props.subscriptionInfo.will_auto_renew &&
                 (<>
                     <Button flat className="cancelButton" onClick={this.handleStripeCancelButtonClicked}>Cancel subscription</Button>
                     <Button flat primary swapTheming className="button" onClick={this.handleStripeUpdateCardClicked}>Change card</Button>
                 </>)}
            </div>);
        }
        else if ( includes( [SUBSCRIPTION_INFO_BILLING_SOURCE_GOOGLE, SUBSCRIPTION_INFO_BILLING_SOURCE_APPLE],
            this.props.subscriptionInfo.billing_source ) )
        {
            const nonStripeSubscriptionInfoData = this.props.subscriptionInfo as NonStripeSubscriptionInfoData;
            return (<Button flat
                            primary
                            swapTheming
                            className="button"
                            target="_blank"
                            href={nonStripeSubscriptionInfoData.manage_subscription_url}>Manage subscription</Button>);
        }
        return <></>;
    }

    private handleStripeCancelButtonClicked = () =>
    {
        eventTracker.logCancelSubscriptionTapped();
        this.setState( { isCancelling: true } );
    }

    private handleCancelSurveyBackTapped = () =>
    {
        this.setState( { isCancelling: false } );
    }

    private handleStripeUpgradeToAnnualClicked = () =>
    {
        const stripeSubscriptionInfoData = this.getSubscriptionInfoAsStripeSubscriptionInfo();
        this.props.upgradeStripeSubscriptionToAnnual( stripeSubscriptionInfoData.stripe_customer_id );
    }

    private handleStripeUpdateCardClicked = () =>
    {
        const stripeSubscriptionInfoData = this.getSubscriptionInfoAsStripeSubscriptionInfo();
        this.props.showUpdateCardForm( stripeSubscriptionInfoData.stripe_customer_id );
    }

    private paymentMethodInfo()
    {
        const isStripe = this.props.subscriptionInfo.billing_source === SUBSCRIPTION_INFO_BILLING_SOURCE_STRIPE;
        const willAutoRenew = this.props.subscriptionInfo.will_auto_renew;
        if ( isStripe && willAutoRenew )
        {
            const stripeSubscriptionInfo = this.getSubscriptionInfoAsStripeSubscriptionInfo();
            const brand = stripeSubscriptionInfo.brand;
            const last_four_digits = stripeSubscriptionInfo.last_four_digits;
            return (<PaymentMethodInfo brand={brand} last_four_digits={last_four_digits}/>);
        }
        else
        {
            return <></>;
        }
    }

    private getMembershipStatusText()
    {
        const currentTier = getCurrentProductTier( store.getState() );
        const tierDisplayText = pricingTiersUIHelper.getTierDisplayText( currentTier );

        return `${tierDisplayText} Plan member`;
    }
}
