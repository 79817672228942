import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState, UserBusiness } from "../../_types";
import { BrandColorsControlPicker, BrandColorsControlPickerDispatchProps, BrandColorsControlPickerProps } from "../BrandColorsControlPicker";
import { getUserBusinessPrimaryBrandColor, getUserBusinessSecondaryBrandColor } from "../../ducks/userBusiness";
import { store } from "../../store";
import { uiActions } from "../../actions";
import { eventTracker, LightboxDialogIdentifierForKey, NO_COLOR_SELECTED_DEFAULT_VALUE } from "../../helpers";
import { modalServices, userBusinessServices } from "../../services";
import SketchPicker from "react-color/lib/components/sketch/Sketch";
import * as React from "react";
import { ColorResult } from "react-color";
import { getBusinessColor } from "../../ducks/ui";

export interface BrandColorsControlPickerOwnProps
{
    applyHexColor( colorHex: string );
}

const mapStateToProps = ( storeState: StoreState, ownProps: BrandColorsControlPickerOwnProps ): BrandColorsControlPickerProps =>
{
    return {
        ...ownProps,
        primaryColor: getUserBusinessPrimaryBrandColor( storeState ),
        secondaryColor: getUserBusinessSecondaryBrandColor( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: BrandColorsControlPickerOwnProps ): BrandColorsControlPickerDispatchProps =>
{
    return {
        handlePrimaryColorClicked: () =>
        {
            const primaryColor = getUserBusinessPrimaryBrandColor( store.getState() );
            if ( primaryColor )
            {
                eventTracker.logBrandSettingsColorSquareClicked();
                ownProps.applyHexColor( primaryColor );
            }
        },
        handleSecondaryColorClicked: () =>
        {
            const secondaryColor = getUserBusinessSecondaryBrandColor( store.getState() );
            if ( secondaryColor )
            {
                eventTracker.logBrandSettingsColorSquareClicked();
                ownProps.applyHexColor( secondaryColor );
            }
        },
        handleEditColorClicked: ( userBusinessColorKey, startValue ) =>
        {
            dispatch( modalServices.openLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.EDIT_COLOR,
                content: () => <SketchPicker
                    color={startValue || NO_COLOR_SELECTED_DEFAULT_VALUE}
                    onChangeComplete={colorChangeListener( dispatch, userBusinessColorKey )
                    }/>,
                onSuccess: () =>
                {
                    const value: string = getBusinessColor( store.getState(), userBusinessColorKey );
                    dispatch( userBusinessServices.update( { [userBusinessColorKey]: value } ) );
                    ownProps.applyHexColor( value );
                },
                onCancel: () => dispatch( uiActions.updateBusinessInfo( { [userBusinessColorKey]: startValue } ) ),
                showCancelX: true,
                hideCancel: true,
            } ) );
        },
        handleClear: ( userBusinessColorKey ) =>
        {
            dispatch( userBusinessServices.update( { [userBusinessColorKey]: null } ) );
        },
    };

};

function colorChangeListener( dispatch: Dispatch<StoreState>, userBusinessKey: keyof UserBusiness )
{
    return ( value: ColorResult ) =>
    {
        dispatch( uiActions.updateBusinessInfo( { [userBusinessKey]: value.hex } ) );
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( BrandColorsControlPicker );
