import { Action } from "redux-actions";
import { ReducerCreator } from "../helpers";
import { engagementsActions, userBusinessActions } from "../actions";
import { EngagementState, StoreState } from "../_types";

const defaultState: EngagementState = {
    weekly: {
        average_recent_engagements: 0,
        engagement_goal: 1,
        most_engagements: 0,
        weekly_breakdown: {},
    },
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( engagementsActions.weeklyBusinessSuccess, updateUserData );
reducerCreator.addCombinedActions( [userBusinessActions.businessSwitched,
                                    userBusinessActions.businessTypeChanged,
                                    userBusinessActions.deleteSuccess],
    handleBusinessDataChanged );
export default reducerCreator.createReducer();

function handleBusinessDataChanged( state: EngagementState, action: Action<any> ): EngagementState
{
    return {
        ...defaultState,
    };
}

function updateUserData( state: EngagementState, action: Action<WeeklyEngagementData> ): EngagementState
{
    return {
        ...state,
        weekly: action.payload,
    };
}

export function getWeeklyEngagements( state: StoreState )
{
    return state.engagements.weekly;
}

export function getWeeklyEngagementsGoal( state: StoreState )
{
    return state.engagements.weekly.engagement_goal;
}
