import * as React from "react";
import { compact } from "lodash";
import { ColorObject, DesignElementProperty, TextPickerCaptionData } from "../../_types";
import { ColorResult } from "react-color";
import { DESIGN_COLOR_TYPE_HEX, DesignElementPropertyType } from "../../_types/api";
import { ColorSuggestions } from "./ColorSuggestions";
import Divider from "react-md/lib/Dividers/Divider";
import ColorInput from "./GradientColorPicker";
import ExpansionList from "react-md/lib/ExpansionPanels/ExpansionList";
import ExpansionPanel from "react-md/lib/ExpansionPanels/ExpansionPanel";
import { ColorSwatch } from "./ColorSwatch";
import { EyedropperPicker } from "./EyedropperPicker";
import EditableColorInput from "./EditableColorInput";

export interface ColorTextChoiceItemProps
{
    captionData: TextPickerCaptionData;
    designElementProperty: DesignElementProperty;
    primaryBrandColor: string;
    secondaryBrandColor: string;
    defaultExpanded?: boolean;
}

export interface ColorTextChoiceItemDispatchProps
{
    tellDesignToColorTextItem( captionId: string, type: DesignElementPropertyType, propertyId: string, value: ColorObject );
}

export class ColorTextChoiceItem extends React.PureComponent<ColorTextChoiceItemProps & ColorTextChoiceItemDispatchProps>
{

    public render()
    {
        const colorPropertyData = this.props.designElementProperty;
        if ( colorPropertyData )
        {
            const selected = colorPropertyData.selected as ColorObject;
            const commonExpansionPanelProps = {
                headerClassName: "colorTextChoiceItemHeader",
                defaultExpanded: this.props.defaultExpanded,
                footer: null,
            };
            return (<div className="colorTextChoiceItem">

                <ExpansionList>
                    <ExpansionPanel label={this.getLabelWithSwatch()}
                                    {...commonExpansionPanelProps}>
                        {(this.props.primaryBrandColor || this.props.secondaryBrandColor) &&
                         <ColorSuggestions title="Brand colors"
                                           currentColorHex={selected.value}
                                           colors={compact( [this.props.primaryBrandColor, this.props.secondaryBrandColor] )}
                                           onColorTapped={this.applyHexColor}/>}
                        <Divider className="divider"/>

                        <ColorSuggestions title="Suggested colors"
                                          currentColorHex={selected.value}
                                          onColorTapped={this.applyHexColor}/>
                        <Divider className="divider"/>

                        <div className="colorInput">
                            <EditableColorInput color={selected.value} onChangeComplete={this.onColorChangeComplete}/>
                            <EyedropperPicker applyHexColor={this.applyHexColor}/>
                            <ColorInput color={selected.value} onChangeComplete={this.onColorChangeComplete}/>
                        </div>
                    </ExpansionPanel>
                </ExpansionList>
            </div>);
        }
        return null;
    }

    private getLabelWithSwatch = () =>
    {
        const selectedColor = this.props.designElementProperty.selected as ColorObject;
        return (<div className="headerLabelContent">
            <ColorSwatch swatchHex={selectedColor.value}/><span>{this.props.designElementProperty.displayName}</span>
        </div>);
    }

    private onColorChangeComplete = ( color: ColorResult ) =>
    {
        this.applyHexColor( color.hex );
    }

    private applyHexColor = ( hex: string ) =>
    {
        const colorPropertyData = this.props.designElementProperty;
        const designColorElementPayload: ColorObject = { type: DESIGN_COLOR_TYPE_HEX, value: hex };
        this.props.tellDesignToColorTextItem( this.props.captionData.id, colorPropertyData.type, colorPropertyData.id, designColorElementPayload );
    }
}
