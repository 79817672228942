import * as React from "react";
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import Divider from "react-md/lib/Dividers/Divider";
import TextField from "react-md/lib/TextFields/TextField";
import Button from "react-md/lib/Buttons/Button";
import { ENV_CONFIG, history, INVALID_EMAIL_ADDRESS, validateEmail } from "../../helpers";
import { UserState } from "../../_types";
import { PRIMARY_TEXT_FIELD_NAME } from "../../_types/api";
import GoogleDisconnectButton from "../containers/GoogleDisconnectButton.container";
import { GoogleOneTapLoginButton } from "../GoogleOneTapLoginButton";
import { CredentialResponse } from "@react-oauth/google";

export interface UserSettingsSubPageProps
{
    user: UserState;
    isUserPersonallySubscribed: boolean;
    tryRiplProText: string;
}

export interface UserSettingsSubPageDispatchProps
{
    handleUpdateUser( data: Partial<UserState> ): void;
    handleAddPasswordAlert(): void;
    handleManageSubscription(): void;
    handleSubscriptionTerms(): void;
    handleTryRiplPro(): void;
    handleSignOut(): void;
    handleGoogleConnectResponse( tokenResponse: CredentialResponse ): void;
}

interface UserSettingsSubPageState
{
    emailError: string;
    newEmail: string;
    newFirstName: string;
    newLastName: string;
}

/* tslint:disable:max-line-length */
export default class UserSettingsSubPage extends React.PureComponent<UserSettingsSubPageProps & UserSettingsSubPageDispatchProps, UserSettingsSubPageState>
{
    constructor( props )
    {
        super( props );
        this.state = {
            emailError: undefined,
            newEmail: props.user.email,
            newFirstName: props.user.first_name,
            newLastName: props.user.last_name,
        };
    }

    public render()
    {
        return (
            <div className="userSettingsSubPage pageNewNav">
                <div className="subNavContent">
                    <div className="subNavInnerWrap">
                        <Grid>
                            <Cell size={7}>
                                <Grid>
                                    <Cell size={12}>
                                        <h1>Account information</h1>
                                    </Cell>
                                    <Cell size={12}>
                                        <h3>First name</h3>
                                        <div className="textFieldUpdateButton">
                                            <TextField
                                                className="settingTextField"
                                                id="firstName"
                                                defaultValue={this.props.user.first_name}
                                                fullWidth={false}
                                                onChange={( content ) =>
                                                {
                                                    this.setState( {
                                                        ...this.state,
                                                        newFirstName: content.toString(),
                                                    } );
                                                }}
                                            />
                                            <Button flat primary={true} disabled={!this.hasFirstNameChanges()}
                                                    onClick={this.handleFirstNameUpdate}>Update</Button>
                                        </div>
                                    </Cell>
                                    <Cell size={12}>
                                        <h3>Last name</h3>
                                        <div className="textFieldUpdateButton">
                                            <TextField
                                                className="settingTextField"
                                                id="lastName"
                                                defaultValue={this.props.user.last_name}
                                                fullWidth={false}
                                                onChange={( content ) =>
                                                {
                                                    this.setState( {
                                                        ...this.state,
                                                        newLastName: content.toString(),
                                                    } );
                                                }}
                                            />
                                            <Button flat primary={true} disabled={!this.hasLastNameChanges()}
                                                    onClick={this.handleLastNameUpdate}>Update</Button>
                                        </div>
                                    </Cell>
                                    <Cell size={12}>
                                        <h3>Email</h3>
                                        <div className="textFieldUpdateButton">
                                            <TextField
                                                className="settingTextField"
                                                id={PRIMARY_TEXT_FIELD_NAME}
                                                defaultValue={this.props.user.valid_email ? this.props.user.valid_email : "No email"}
                                                fullWidth={false}
                                                error={this.state.emailError ? true : undefined}
                                                errorText={this.state.emailError}
                                                onChange={( content ) =>
                                                {
                                                    this.setState( {
                                                        emailError: undefined,
                                                        newEmail: content.toString(),
                                                    } );
                                                }}
                                            />
                                            <Button flat primary={true} disabled={!this.hasEmailChanges()}
                                                    onClick={this.handleEmailUpdate}>Update</Button>
                                        </div>
                                    </Cell>
                                    {
                                        this.props.user.valid_email ? this.showPasswordRow() : null
                                    }
                                    {this.createGoogleAccountSection()}
                                    {this.createEmailPreferencesSection()}
                                    {this.createSubscriptionSection()}
                                </Grid>
                            </Cell>
                            <Cell size={12}>
                                <Divider/>
                            </Cell>
                            <Cell size={8}>
                                <Button flat primary swapTheming
                                        onClick={this.props.handleSignOut}
                                        className="signOutButton">Sign out</Button>
                                <div className="text footnote">
                                    version: {APP_VERSION} {this.props.user.dev_mode && "DEV MODE"}
                                </div>
                            </Cell>
                        </Grid>
                    </div>
                </div>
            </div>
        );
    }

    private createGoogleAccountSection = () =>
    {
        return (this.props.user.has_google_authentication ? this.createDisconnectGoogleAccountSection() : this.createConnectGoogleAccountSection());
    }

    private createConnectGoogleAccountSection = () =>
    {
        return (
            <Cell className="googleAccountSection" size={12}>
                {this.getGoogleSignInButton()}
            </Cell>
        );
    }

    private getGoogleSignInButton = () =>
    {
        return <GoogleOneTapLoginButton buttonHeader={<h3 id="settingTitle">Connect your Google account</h3>}
                                        isLoginButton={false}
                                        isConnectButton={true}
                                        handleGoogleResponse={this.props.handleGoogleConnectResponse}/>;
    }

    private createDisconnectGoogleAccountSection = () =>
    {
        return (
            <Cell className="googleAccountSection" size={12}>
                <h3 id="settingTitle">Disconnect your Google account</h3>
                <GoogleDisconnectButton/>
            </Cell>
        );
    }

    private createEmailPreferencesSection = () =>
    {
        return (
            <Cell size={12}>
                <div className="emailPreferencesRow">
                    <h3 className="emailPreferencesHeader">Email preferences</h3>
                    <span className="standardLink" onClick={this.manageEmailPreferences}>Edit</span>
                </div>
                <div className="emailPreferencesBody">Manage emails around tips and tricks, content, and promotions that suit my business</div>
            </Cell>
        );
    }

    private createSubscriptionSection = () =>
    {
        if ( this.props.isUserPersonallySubscribed )
        {
            return (
                <Cell size={12}>
                    <h1 className="subscriptionTitle">Subscription</h1>
                    <span className="standardLink manageSubscriptionsLink" onClick={this.props.handleManageSubscription}>Manage subscription</span>
                    <span className="standardLink handleSubscriptionTermsLink" onClick={this.props.handleSubscriptionTerms}>Subscription terms</span>
                </Cell>
            );
        }

        return (
            <Cell size={12}>
                <span className="standardLink tryRiplProLink" onClick={this.props.handleTryRiplPro}>{this.props.tryRiplProText}</span>
            </Cell>
        );
    }

    private resetPassword = () =>
    {
        history.push( "/account/changePassword" );
    }

    private showPasswordRow = () =>
    {
        return <Cell size={12}>
            <div className="passwordRow">
                <h3 className="passwordHeader">Password</h3>
                <Button flat primary={true}
                        onClick={this.resetPassword}>Change</Button>
            </div>
        </Cell>;
    }

    private handleFirstNameUpdate = () =>
    {
        this.props.handleUpdateUser( { first_name: this.state.newFirstName } );
    }

    private handleLastNameUpdate = () =>
    {
        this.props.handleUpdateUser( { last_name: this.state.newLastName } );
    }

    private hasEmailChanges = (): boolean =>
    {
        const theNewEmail: string = this.state.newEmail;
        return theNewEmail !== this.props.user.email;
    }

    private hasLastNameChanges = (): boolean =>
    {
        const theNewLastName: string = this.state.newLastName;
        return theNewLastName !== this.props.user.last_name;
    }

    private hasFirstNameChanges = (): boolean =>
    {
        const theNewFirstName: string = this.state.newFirstName;
        return theNewFirstName !== this.props.user.first_name;
    }

    private handleEmailUpdate = () =>
    {
        let emailValidateError: string = null;
        const theNewEmail: string = this.state.newEmail;

        if ( !validateEmail( theNewEmail ) )
        {
            emailValidateError = INVALID_EMAIL_ADDRESS;
        }

        if ( !emailValidateError )
        {
            this.props.handleUpdateUser( { email: theNewEmail } );
            this.showAddPasswordAlert();
        }
        else
        {
            this.setState( {
                emailError: emailValidateError,
                newEmail: theNewEmail,
            } );
        }
    }

    private showAddPasswordAlert = () =>
    {
        if ( this.props.user.valid_email && !this.props.user.has_password )
        {
            this.props.handleAddPasswordAlert();
        }
    }

    private manageEmailPreferences = () =>
    {
        const theRoute = ENV_CONFIG.baseUrl + "/airship/manage_email_groups";
        const theUrl = theRoute + "?" + this.props.user.manage_email_groups_token;

        window.open( theUrl, "_blank" );
    }
}
