import * as React from "react";
import classNames from "classnames";
import PremiumFocusedWidePriceBlock from "./containers/PremiumFocusedWidePriceBlock.container";
import { get, map } from "lodash";
import { RIPL_TIER_PREMIUM } from "../../helpers";
import { isAnnualPlan } from "../../ducks/pricing";
import SavingsInDollarsBadgeFromPlan from "../containers/SavingsInDollarsBadgeFromPlan.container";

export interface PremiumFocusedUpsellWithAllPlansPriceBlockSectionProps
{
  shouldShowSimplifiedUpsell?: boolean;
  premiumPlans: PlanWithTier[];
  basePlans: PlanWithTier[];
  monthlyPremiumPlan: StripePlanData;
  selectedPlan: StripePlanData;
}

export interface PlanWithTier
{
  tier: ProductTier;
  plan: StripePlanData;
}

export interface PremiumFocusedUpsellWithAllPlansPriceBlockSectionDispatchProps
{
  handlePlanSelected( StripePlanData, ProductTier );
}

export class PremiumFocusedUpsellWithAllPlansPriceBlockSection extends React.Component<PremiumFocusedUpsellWithAllPlansPriceBlockSectionProps &
  PremiumFocusedUpsellWithAllPlansPriceBlockSectionDispatchProps>
{
  public render()
  {
    return (
      <div>
        <div className={"stackedPriceBlockSectionHeader"}>Premium Plans</div>
        {this.renderPriceBlockForPlans( this.props.premiumPlans )}
        <div className={"stackedPriceBlockSectionHeader"}>Base Plans</div>
        {this.renderPriceBlockForPlans( this.props.basePlans )}
      </div>
    );
  }

  private renderPriceBlockForPlans( plans: PlanWithTier[] )
  {
    return <div className={classNames( "stackedPriceBlockSection" )}>
      {map( plans, ( plan ) =>
      {
        const shouldShowSavings = plan.tier === RIPL_TIER_PREMIUM && isAnnualPlan( plan.plan );
        const isSelected = plan.plan.id === get( this.props.selectedPlan, "id" );

        return (<PremiumFocusedWidePriceBlock plan={plan.plan}
                                              key={plan.plan.id}
                                              selected={isSelected}
                                              updatePlanSelection={this.props.handlePlanSelected}
                                              tier={plan.tier}>
          {shouldShowSavings && <SavingsInDollarsBadgeFromPlan plan={plan.plan}
                                                               monthlyPlan={this.props.monthlyPremiumPlan}
                                                               annualPlan={plan.plan}
                                                               shouldRenderForWideFormat={true}
                                                               shouldAlignToRight={!isSelected}
          />}
        </PremiumFocusedWidePriceBlock>);
      } )}
    </div>;
  }
}
