import * as React from "react";
import DialogContainer from "react-md/lib/Dialogs/DialogContainer";
import Button from "react-md/lib/Buttons/Button";
import { addMinutes, format, getHours, getMinutes, isBefore, parse, startOfDay } from "date-fns";
import DayPicker from "react-day-picker";
import { TimePicker } from "./TimePicker";
import { SchedulePostDialogData } from "../_types";

export interface SchedulePostDialogProps
{
    schedulePostDialogData: SchedulePostDialogData;
    scheduledSendDatetime: string;
    scheduleButtonLabel: string;
}

export interface SchedulePostDialogDispatchProps
{
    handleCloseModal: () => void;
    onSchedulePost: ( postId: number, sendDate: string ) => void;
    onUnschedulePost: ( schedulePostDialogData: SchedulePostDialogData ) => void;
}

interface DialogAction
{
    onClick: () => void;
    primary: boolean;
    children: string;
    swapTheming?: boolean;
    className?: string;
}

interface SchedulePostDialogState
{
    sendDate: Date;
    actions: DialogAction[];
    showTooEarlyTimeError: boolean;
}

const CLOSE_BUTTON_LABEL = "Close";
const UNSCHEDULE_BUTTON_LABEL = "Unschedule";

export class SchedulePostDialog extends React.PureComponent<SchedulePostDialogProps & SchedulePostDialogDispatchProps, SchedulePostDialogState>
{

    private primaryAction: DialogAction;
    private unscheduleAction: DialogAction;

    constructor( props )
    {
        super( props );
        this.primaryAction = {
            onClick: this.handleSchedulePost,
            primary: true,
            swapTheming: true,
            className: "dialogConfirm",
            children: CLOSE_BUTTON_LABEL,
        };
        this.unscheduleAction = {
            onClick: this.handleUnschedulePost,
            primary: true,
            className: "dialogAlternate outline",
            children: UNSCHEDULE_BUTTON_LABEL,
        };

        this.state = this.determineInitialState( this.props, this.primaryAction, this.unscheduleAction );
    }

    public render()
    {
        return (
            <DialogContainer
                id="schedule-post"
                className="schedulePostDialog"
                visible={this.isModalOpen( this.props )}
                modal
                width={450}
                height={null}
                dialogStyle={{
                    minHeight: 300,
                    maxHeight: 550,
                }}
                title={this.getTitle()}
                onHide={this.handleCloseModal}
                actions={this.determineActions()}
                focusOnMount={false}
            >
                <Button icon={true}
                        className="closeX"
                        iconClassName="material-icons"
                        iconChildren="close"
                        onClick={this.handleCloseModal}/>
                {this.createErrorMessageIfNeeded()}
                {this.createDatePickerComponent()}
            </DialogContainer>
        );
    }

    private handleSchedulePost = () =>
    {
        if ( isBefore( new Date(), this.state.sendDate ) )
        {
            this.props.onSchedulePost(
                this.props.schedulePostDialogData.postId,
                format( this.state.sendDate ) );
        }
        else
        {
            this.resetState();
            this.props.handleCloseModal();
        }
    }

    private handleUnschedulePost = () =>
    {
        this.resetState( new Date() );
        this.props.onUnschedulePost( this.props.schedulePostDialogData );
    }

    private handleCloseModal = () =>
    {
        this.resetState();
        this.props.handleCloseModal();
    }

    private resetState = ( sendDateOverride?: Date ) =>
    {
        const sendDate = this.getResetDateForState( sendDateOverride );
        this.setState( {
            sendDate,
            actions: [this.primaryAction],
            showTooEarlyTimeError: false,
        } );
    }

    private getResetDateForState = ( sendDateOverride: Date ) =>
    {
        if ( sendDateOverride )
        {
            return sendDateOverride;
        }
        else if ( this.props.scheduledSendDatetime )
        {
            return parse( this.props.scheduledSendDatetime );
        }
        else
        {
            return new Date();
        }
    }

    private determineInitialState( props: SchedulePostDialogProps,
                                   primaryAction: DialogAction,
                                   unscheduleAction: DialogAction ): SchedulePostDialogState
    {
        if ( props.scheduledSendDatetime )
        {
            return {
                sendDate: parse( props.scheduledSendDatetime ),
                actions: [unscheduleAction, primaryAction],
                showTooEarlyTimeError: false,
            };
        }
        else
        {
            return {
                sendDate: new Date(),
                actions: [primaryAction],
                showTooEarlyTimeError: false,
            };
        }
    }

    private getTitle = () =>
    {
        return (<div>Select when to send<br/>your post</div>);
    }

    private isModalOpen = ( props ) =>
    {
        return props.schedulePostDialogData
               && props.schedulePostDialogData.hasOwnProperty( "isOpen" )
               && props.schedulePostDialogData.isOpen;
    }

    private handleDayChange = ( date: Date ) =>
    {
        const minutesToAdd = getMinutes( this.state.sendDate ) + getHours( this.state.sendDate ) * 60;
        this.handleChange( addMinutes( startOfDay( date ), minutesToAdd ) );
    }

    private handleChange = ( date: Date ) =>
    {

        const showTooEarlyTimeError = date && isBefore( date, new Date() );

        this.setState( {
            sendDate: date,
            showTooEarlyTimeError,
        } );
    }

    private determineActions = (): DialogAction[] =>
    {
        const saveButtonLabel = this.determineActionButtonLabel( this.state.sendDate );
        const primaryAction = {
            ...this.primaryAction,
            children: saveButtonLabel,
        };

        if ( this.props.scheduledSendDatetime )
        {
            return [this.unscheduleAction, primaryAction];
        }
        else
        {
            return [primaryAction];
        }
    }

    private createErrorMessageIfNeeded = () =>
    {
        if ( this.state.showTooEarlyTimeError )
        {
            return (
                <div className="md-text--error md-text-field-message md-text-field-message--active" style={{
                    margin: 8,
                    marginTop: 0,
                }}>Please select a date and time that is in the future.</div>
            );
        }
    }

    private createDatePickerComponent = () =>
    {
        if ( this.isModalOpen( this.props ) )
        {
            return (
                <div className="datepickerContainer">
                    <DayPicker
                        fixedWeeks
                        selectedDays={this.state.sendDate}
                        onDayClick={this.handleDayChange}
                        renderDay={renderDay}
                    />
                    <TimePicker
                        value={this.state.sendDate}
                        onChange={this.handleChange}
                    />
                </div>
            );
        }
    }

    private determineActionButtonLabel = ( date: Date ): string =>
    {
        if ( isBefore( new Date(), date ) )
        {
            return this.props.scheduleButtonLabel;
        }
        else
        {
            return CLOSE_BUTTON_LABEL;
        }
    }
}

const renderDay = ( day: Date, modifiers: any ) =>
{
    const date = day.getDate();
    return <div className="dayCell">
        <div className="date">{date}</div>
    </div>;
};
