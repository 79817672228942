import { BackgroundAnimationChoices, SlideTransitionChoice } from "../../../_types";
import { map } from "lodash";
import { SlideTransitionChoiceButton } from "../SlideTransitionChoice";
import * as React from "react";

interface BackgroundAnimationChoicesPanelProps
{
    backgroundAnimationChoices?: BackgroundAnimationChoices;
    preferredBackgroundAnimation?: SlideTransitionChoice;
    onPreferredBackgroundAnimationSelected( slideTransitionChoice: SlideTransitionChoice ): void;
}

export function BackgroundAnimationChoicesPanel( props: BackgroundAnimationChoicesPanelProps )
{
    const choices = map( props.backgroundAnimationChoices.backgroundAnimations, ( choice ) =>
        <SlideTransitionChoiceButton key={choice.id}
                                     choice={choice}
                                     onPreferredSlideTransitionSelected={props.onPreferredBackgroundAnimationSelected}
                                     selected={props.preferredBackgroundAnimation.id === choice.id}/> );

    return <div className="listPicker">{choices}</div>;
}