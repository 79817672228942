import * as React from "react";
import { UserBusiness } from "../_types";
import BusinessTypeSelectionPageContainer from "./containers/BusinessTypeSelectionPage.container";
import BusinessNameContainer from "./containers/BusinessName.container";
import { BUSINESS_PICKER_SOURCE_PROFILE, eventTracker } from "../helpers";
import { getBusinessType } from "../ducks/businessTypes";
import { getSelectedBusinessTypeId } from "../ducks/ui";
import { store } from "../store";

export interface CreateBusinessDialogProps
{
    businessData: Partial<UserBusiness>;
    shouldShowAddBusinessNameStep: boolean;
}

export interface CreateBusinessDialogDispatchProps
{
    createBusiness(): void;
}

interface CreateBusinessDialogState
{
    showAddBusinessNameDialog: boolean;
}

export class CreateBusinessDialog extends React.PureComponent<CreateBusinessDialogProps & CreateBusinessDialogDispatchProps,
    CreateBusinessDialogState>
{
    constructor( props )
    {
        super( props );
        this.state = {
            showAddBusinessNameDialog: false,
        };
    }

    public render()
    {
        return this.state.showAddBusinessNameDialog ?
               <BusinessNameContainer handleBackClicked={this.showBusinessTypeDialog}
                                      handleNextClicked={this.createBusiness}
                                      nextButtonText="Finish"/> :
               <BusinessTypeSelectionPageContainer handleNextClicked={this.handleBusinessTypeSelected}
                                                   trackBusinessPickerDisplayed={this.trackBusinessPickerDisplayed}/>;
    }

    private trackBusinessPickerDisplayed = () =>
    {
        eventTracker.logBusinessPickerDisplayed( BUSINESS_PICKER_SOURCE_PROFILE );
    }

    private createBusiness = () =>
    {
        eventTracker.logBusinessNameEntered( BUSINESS_PICKER_SOURCE_PROFILE );
        this.props.createBusiness();
    }

    private handleBusinessTypeSelected = () =>
    {
        const state = store.getState();
        eventTracker.logBusinessTypeSelected( BUSINESS_PICKER_SOURCE_PROFILE, getBusinessType( state, getSelectedBusinessTypeId( state ) ) );

        if ( this.props.shouldShowAddBusinessNameStep )
        {
            this.setState( { showAddBusinessNameDialog: true } );
        }
        else
        {
            this.createBusiness();
        }
    }

    private showBusinessTypeDialog = () =>
    {
        this.setState( { showAddBusinessNameDialog: false } );
    }
}
