import { connect } from "react-redux";
import { StoreState } from "../../../_types/index";
import { getAnnualPlanForTier, getMonthlyPlanForTier } from "../../../ducks/pricing";
import { PremiumFocusedUpsellPriceBlockSection, PremiumFocusedUpsellPriceBlockSectionProps } from "../PremiumFocusedUpsellPriceBlockSection";
import { RIPL_TIER_BASE, RIPL_TIER_PREMIUM } from "../../../helpers";

export interface PremiumFocusedUpsellPriceBlockSectionContainerProps
{
    selectedPlan: StripePlanData;
    handlePlanSelected( StripePlanData, ProductTier );
}

const mapStateToProps = ( storeState: StoreState,
                          ownProps: PremiumFocusedUpsellPriceBlockSectionContainerProps ):
    PremiumFocusedUpsellPriceBlockSectionProps =>
{
    return {
        plans: [
            { plan: getAnnualPlanForTier( storeState, RIPL_TIER_BASE ), tier: RIPL_TIER_BASE },
            { plan: getAnnualPlanForTier( storeState, RIPL_TIER_PREMIUM ), tier: RIPL_TIER_PREMIUM },
            { plan: getMonthlyPlanForTier( storeState, RIPL_TIER_PREMIUM ), tier: RIPL_TIER_PREMIUM },
        ],
        monthlyPremiumPlan: getMonthlyPlanForTier( storeState, RIPL_TIER_PREMIUM ),
        ...ownProps,
    };
};

export default connect(
    mapStateToProps,
    {},
)( PremiumFocusedUpsellPriceBlockSection );
