import { PremiumFocusedUpsellDialog, PremiumFocusedUpsellDialogProps, PremiumFocusedUpsellDispatchProps } from "../PremiumFocusedUpsellDialog";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { DialogControlProps, StoreState } from "../../../_types/index";
import { eventTracker, RIPL_TIER_PREMIUM, SUBSCRIPTION_FLOW_SOURCE_PURCHASE_PAGE } from "../../../helpers/index";
import { pricingActions } from "../../../actions/index";
import { pricingServices } from "../../../services/index";
import {
    getAnnualPlanForTier,
    getExchangeRateData,
    getNumSubscriptionPlans,
    getSelectedTier,
    hasUsedTrial,
    shouldShowAllPlans,
} from "../../../ducks/pricing";
import { areStripePlansLoading } from "../../../ducks/ui";
import { getUserEmail } from "../../../ducks/user";

const mapStateToProps = ( storeState: StoreState, ownProps: DialogControlProps ): PremiumFocusedUpsellDialogProps =>
{
    return {
        numPlans: getNumSubscriptionPlans( storeState ),
        annualPlan: getAnnualPlanForTier( storeState, RIPL_TIER_PREMIUM ),
        monthlyPlan: getAnnualPlanForTier( storeState, RIPL_TIER_PREMIUM ),
        closeDialog: ownProps.closeDialog,
        hasUsedTrial: hasUsedTrial( storeState ),
        arePlansLoading: areStripePlansLoading( storeState ),
        shouldShowExchangeRates: !!getExchangeRateData( storeState ),
        email: getUserEmail( storeState ),
        showAllPlans: shouldShowAllPlans( storeState ),
        selectedTier: getSelectedTier(storeState),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: PremiumFocusedUpsellDialogProps ): PremiumFocusedUpsellDispatchProps =>
{
    return {
        setAllPlansVisible( visible: boolean ): void
        {
            dispatch( pricingActions.setAllPlansVisible( visible ) );
        },
        selectTier: ( tier: ProductTier ) =>
        {
            eventTracker.logSubscriptionTierSelected( SUBSCRIPTION_FLOW_SOURCE_PURCHASE_PAGE, tier );
            dispatch( pricingActions.tierSelected( tier ) );
        },
        handleStartSubscription: async ( plan: StripePlanData ) =>
        {
            ownProps.closeDialog();
            await dispatch( pricingActions.setPlan( plan.id ) );
            dispatch( pricingServices.showPaymentFormDialogWithTestDrive() );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( PremiumFocusedUpsellDialog );
