import * as React from "react";
import { DesignMediaObject } from "../_types";
import Button from "react-md/lib/Buttons/Button";
import { MP4_VIDEO_TYPE, urlUtils } from "../helpers";

export interface SingleMediaViewProps
{
    activeDesignMediaObject: DesignMediaObject;
}

export interface SingleMediaViewDispatchProps
{
    onGoBack();
    onReplaceClicked();
    onTrimVideoClicked( videoUrl: string );
}

export class SingleMediaView extends React.PureComponent<SingleMediaViewProps & SingleMediaViewDispatchProps>
{
    private playPromise;
    private pausePromise;

    public render()
    {
        return (
            <div className="singleMediaView picker">
                <div className="topButtons">
                    <Button flat
                            secondary
                            className="backToManageMedia secondary outline"
                            iconChildren="arrow_back_ios"
                            onClick={this.onBackClicked}>Manage media</Button>
                </div>

                <div className="singleMediaPreview">
                    {this.createThumbnail()}
                </div>

                <div className="bottomButtons">
                    <Button flat
                            primary
                            swapTheming
                            onClick={this.onReplaceClicked}>Replace</Button>
                    {this.isVideoSrc() && <Button flat
                                                  primary
                                                  swapTheming
                                                  onClick={this.handleTrimVideoClicked}>Trim video</Button>}
                </div>
            </div>
        );
    }

    private handleTrimVideoClicked = () =>
    {
        const src = this.props.activeDesignMediaObject.url;
        this.props.onTrimVideoClicked( src );
    }

    private createThumbnail = () =>
    {
        const thumbSrc = this.props.activeDesignMediaObject.url;
        const isVideo = this.isVideoSrc();

        if ( isVideo )
        {
            return (
                <div onMouseEnter={this.onMouseEnter}
                     onMouseLeave={this.onMouseLeave}>
                    <video key={thumbSrc} muted ref="videoElementRef">
                        <source
                            src={thumbSrc}
                            type={MP4_VIDEO_TYPE}/>
                    </video>
                </div>
            );
        }
        return (
            <img alt="media to replace" src={thumbSrc}/>
        );
    }

    private isVideoSrc = () =>
    {
        const src = this.props.activeDesignMediaObject.url;
        return urlUtils.isMp4VideoUrl( src );
    }

    private onMouseEnter = () =>
    {
        this.playVideo();
    }

    private onMouseLeave = () =>
    {
        this.stopVideo();
    }

    private playVideo = () =>
    {
        const htmlVideo = this.getVideoElement();
        if ( htmlVideo )
        {
            htmlVideo.currentTime = 0;
            if ( this.pausePromise )
            {
                this.playPromise = this.pausePromise.then( () => htmlVideo && htmlVideo.play() );
                this.pausePromise = null;
            }
            else
            {
                this.playPromise = htmlVideo.play();
            }
        }
    }

    private stopVideo = () =>
    {
        const htmlVideo = this.getVideoElement();
        if ( htmlVideo )
        {
            htmlVideo.currentTime = 0;
            if ( this.playPromise )
            {
                this.pausePromise = this.playPromise.then( () => htmlVideo && htmlVideo.pause() );
            }
            this.playPromise = null;
        }
    }

    private getVideoElement = () =>
    {
        const { videoElementRef } = this.refs;
        return videoElementRef as HTMLVideoElement;
    }

    private onBackClicked = () =>
    {
        this.props.onGoBack();
    }

    private onReplaceClicked = () =>
    {
        this.props.onReplaceClicked();
    }
}
