import { SavingsBadge } from "./SavingsBadge";
import * as React from "react";
import classNames from "classnames";

interface SavingsInPercentBadgeProps
{
    savings: number;
    shouldRenderForWideFormat?: boolean;
}

export function SavingsInPercentBadge( props: SavingsInPercentBadgeProps )
{
    if ( props.savings <= 0 )
    {
        return null;
    }

    return (<SavingsBadge renderForWideFormat={props.shouldRenderForWideFormat}>
        <div className={classNames( "savingsPercent normalScale", { renderForWideFormat: props.shouldRenderForWideFormat } )}>
            {props.savings}%
        </div>
        <div className={classNames( "savingsPercent normalScale", { renderForWideFormat: props.shouldRenderForWideFormat } )}>OFF</div>
    </SavingsBadge>);
}
