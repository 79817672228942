import { connect } from "react-redux";
import { Dispatch } from "redux";
import { store } from "../../../store";
import {
    getCustomizableCanvas,
    getDesignCaptionDataById,
    getDesignElementProperties,
    getStylizedTextChoices,
    getTextPropertiesSubPanelCaptionData,
} from "../../../ducks/ui";
import { StoreState } from "../../../_types";
import {
    StylizedTextChoicePanel,
    StylizedTextChoicePanelContainerProps,
    StylizedTextChoicePanelDispatchProps,
    StylizedTextChoicePanelProps,
} from "../StylizedTextChoicePanel";
import { uiActions } from "../../../actions";
import { COACH_MARK_NAME_KEY_STYLIZED_TEXT } from "../../../_types/api";

const mapStateToProps = ( storeState: StoreState, ownProps: StylizedTextChoicePanelContainerProps ): StylizedTextChoicePanelProps =>
{
    const textPropertiesSubPanelCaptionData = getTextPropertiesSubPanelCaptionData( storeState );
    return {
        ...ownProps,
        captionData: textPropertiesSubPanelCaptionData ? getDesignCaptionDataById( storeState, textPropertiesSubPanelCaptionData.id ) : null,
        designElementProperties: getDesignElementProperties( storeState ),
        stylizedTextChoices: getStylizedTextChoices( storeState),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): StylizedTextChoicePanelDispatchProps =>
{
    return {
        tellDesignToGetTextStyles,
        tellDesignToStyleTextItem,
        clearStylizedTextButtonCoachMark: () =>
        {
            dispatch( uiActions.clearCoachMark( COACH_MARK_NAME_KEY_STYLIZED_TEXT ) );
        },
        requestTextProperties: ( captionId: string ) =>
        {
            const state = store.getState();
            const canvas = getCustomizableCanvas( state );
            if ( canvas )
            {
                canvas.tellDesignToRequestElementProperties( captionId );
            }
        },
    };
};

function tellDesignToGetTextStyles( captionId: string )
{
    const state = store.getState();
    const canvas = getCustomizableCanvas( state );
    if ( canvas )
    {
        canvas.tellDesignToGetTextStyles( captionId );
    }
}

function tellDesignToStyleTextItem( captionId: string, styleId: string )
{
    const state = store.getState();
    const canvas = getCustomizableCanvas( state );
    if ( canvas )
    {
        canvas.tellDesignToStyleTextItem( captionId, styleId );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( StylizedTextChoicePanel );
