import * as React from "react";
import { history, HOMEPAGE_URL } from "../helpers";

export const PageNotFound: React.FC<{}> = () =>
{
    return (
        <div className="pageNotFound">
            <div className="text title center">404 - Page not found</div>
            <div className="text subtitle center">
                <span>Please </span>
                <span className="link" onClick={goBack}>go back</span>
                <span> or return to the </span>
                <span className="link" onClick={returnToHomepage}>homepage</span>
            </div>
        </div>
    );
};

function goBack()
{
    history.goBack();
}

function returnToHomepage()
{
    history.replace( HOMEPAGE_URL );
}
