import { ActionCreator } from "../helpers";
import { createAction } from "redux-actions";
import { ContentSearchData } from "../_types";

const actionCreator = new ActionCreator( "CONTENT_SEARCH" );

const contentSearchActionsTypes = {
    resultsLoaded: actionCreator.generateTypes( "RESULTS_LOADED" ),
    results: {
        cleared: actionCreator.generateType( "RESULTS", "CLEARED" ),
    },
    searchMode: {
        exited: actionCreator.generateType( "SEARCH_MODE", "EXITED" ),
    },
};

export const contentSearchActions = {
    searchResultsLoaded: createAction<ContentSearchData>( contentSearchActionsTypes.resultsLoaded.success ),
    searchResultsFailed: createAction( contentSearchActionsTypes.resultsLoaded.failure ),
    searchResultsCleared: createAction( contentSearchActionsTypes.results.cleared ),
    searchModeExited: createAction( contentSearchActionsTypes.searchMode.exited ),
};
