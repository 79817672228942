import { DesignPresets, SlideTransitionChoice, StoreState } from "../../../_types";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { FoundationPresets, FoundationPresetsDispatchProps, FoundationPresetsProps } from "../foundationPresets/FoundationPresets";
import { store } from "../../../store";
import {
    getBackgroundAnimationChoices,
    getBackgroundAnimationProperties,
    getCustomizableCanvas,
    getDesignPresets,
    getGlobalTextAnimationChoices,
    getSlideTransitionChoices,
    getSlideTransitionProperties,
    getStylizedTextChoices,
    getTextAnimationChoices,
    isProcessRunning,
} from "../../../ducks/ui";
import { CUSTOMIZABLE_DESIGN_ID } from "../../containers/CustomizableDesign.container";
import { isAnimatedOutputMode } from "../../../ducks/mixModel";
import { apptimizeVariables } from "../../../helpers";

const mapStateToProps = ( storeState: StoreState ): FoundationPresetsProps =>
{
    return {
        shouldEnablePresetText: apptimizeVariables.shouldEnablePresetText(),
        shouldSupportPresetSlideTransitions: apptimizeVariables.shouldSupportPresetSlideTransitions(),
        shouldSupportBackgroundAnimations: apptimizeVariables.shouldSupportBackgroundAnimations(),
        stylizedTextChoices: getStylizedTextChoices( storeState ),
        textAnimationChoices: getTextAnimationChoices( storeState ),
        globalTextAnimationChoices: getGlobalTextAnimationChoices( storeState ),
        slideTransitionChoices: getSlideTransitionChoices( storeState ),
        slideTransitionProperties: getSlideTransitionProperties( storeState ),
        backgroundAnimationChoices: getBackgroundAnimationChoices( storeState ),
        backgroundAnimationProperties: getBackgroundAnimationProperties( storeState ),
        designPresets: getDesignPresets( storeState ),
        isDesignLoading: isProcessRunning( storeState, CUSTOMIZABLE_DESIGN_ID ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): FoundationPresetsDispatchProps =>
{
    return {
        onDesignLoaded: () =>
        {
            tellDesignToRequestPresets();
        },
        onPreferredKeySelected: ( key: keyof DesignPresets, value: any ) =>
        {
            tellDesignToUpdatePresets( { [key]: value } );
        },
        onPreferredSlideTransitionSelected: ( slideTransitionChoice: SlideTransitionChoice ) =>
        {
            tellDesignToUpdatePresets( { preferredSlideTransition: slideTransitionChoice } );

            const storeState = store.getState();
            if ( isAnimatedOutputMode( storeState ) )
            {
                getCustomizableCanvas( storeState ).tellDesignToReloadDesignWithAnimationAndNoReveal();
            }
        },
        onPreferredBackgroundAnimationSelected: ( slideTransitionChoice: SlideTransitionChoice ) =>
        {
            tellDesignToUpdatePresets( { preferredBackgroundAnimation: slideTransitionChoice } );

            const storeState = store.getState();
            if ( isAnimatedOutputMode( storeState ) )
            {
                getCustomizableCanvas( storeState ).tellDesignToReloadDesignWithAnimationAndNoReveal();
            }
        },
    };
};

function tellDesignToRequestPresets()
{
    const state = store.getState();
    const canvas = getCustomizableCanvas( state );
    if ( canvas )
    {
        canvas.tellDesignToRequestPresets();
    }
}

function tellDesignToUpdatePresets( partialPreset: Partial<DesignPresets> )
{
    const state = store.getState();
    const canvas = getCustomizableCanvas( state );
    const designPresets = getDesignPresets( state );
    const newPreset = { ...designPresets, ...partialPreset };
    if ( canvas )
    {
        canvas.tellDesignToUpdatePresets( newPreset );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( FoundationPresets );
