import { connect } from "react-redux";
import { Dispatch } from "redux";
import { NonStripeSubscriptionInfoData, StoreState, StripeSubscriptionInfoData } from "../../../_types";
import SubscriptionInfoBlock, { SubscriptionInfoBlockDispatchProps, SubscriptionInfoBlockProps } from "../SubscriptionInfoBlock";
import { modalServices, pricingServices, subscriptionServices } from "../../../services";
import { getCurrentUser } from "../../../ducks/user";
import { LightboxDialogIdentifierForKey } from "../../../helpers";

export interface SubscriptionInfoBlockContainerProps
{
    subscriptionInfo: StripeSubscriptionInfoData | NonStripeSubscriptionInfoData;
}

const mapStateToProps = ( storeState: StoreState, ownProps: SubscriptionInfoBlockContainerProps ): SubscriptionInfoBlockProps =>
{
    return {
        ...ownProps,
        user: getCurrentUser( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): SubscriptionInfoBlockDispatchProps =>
{
    return {
        upgradeStripeSubscriptionToAnnual: ( stripeCustomerId: string ) =>
        {
            dispatch( modalServices.openLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.UPGRADE_STRIPE_SUBSCRIPTION_TO_ANNUAL,
                title: "Confirm upgrade",
                width: 400,
                content: `Are you sure you want to upgrade your subscription to yearly? The amount will be pro-rated based on your current payment.`,
                onSuccess: () => dispatch( subscriptionServices.upgradeToAnnual( stripeCustomerId ) ),
                confirmLabel: "Upgrade now",
                hideCancel: true,
                showCancelX: true,
            } ) );
        },
        showUpdateCardForm: ( stripeCustomerId: string ) =>
        {
            dispatch( pricingServices.showUpdateCardDialogFromAccountSettings( stripeCustomerId ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( SubscriptionInfoBlock );
