import { DesignElementProperty, SlideTransitionChoice, TransitionOption } from "../../../_types";
import { map } from "lodash";
import { DESIGN_ELEMENT_PROPERTY_TYPE_LIST } from "../../../_types/api";
import { ListPicker } from "../../ListPicker";
import { DesignElementListOption } from "../DesignElementListOption";
import * as React from "react";

interface SlideTransitionPropertiesPanelProps
{
    properties: DesignElementProperty[];
    preferredSlideTransition?: SlideTransitionChoice;
    onPreferredSlideTransitionSelected( slideTransitionChoice: SlideTransitionChoice ): void;
}

export function SlideTransitionPropertiesPanel( props: SlideTransitionPropertiesPanelProps )
{
    const choices = map( props.properties, ( designElementProperty ) =>
    {

        switch ( designElementProperty.type )
        {
            case DESIGN_ELEMENT_PROPERTY_TYPE_LIST:
                return (<ListPicker key={designElementProperty.id}>
                    <h5 className="sectionTitle">{designElementProperty.displayName}</h5>
                    {
                        map( designElementProperty.options, ( transitionOption: TransitionOption ) =>
                        {
                            return (
                                <DesignElementListOption
                                    key={transitionOption.displayName}
                                    options={transitionOption}
                                    designElementProperty={designElementProperty}
                                    preferredSlideTransition={props.preferredSlideTransition}
                                    onPreferredSlideTransitionSelected={props.onPreferredSlideTransitionSelected}/>);
                        } )
                    }
                </ListPicker>);
            default:
                return <>unknown type</>;
        }
    } );

    return <div className="listPicker">{choices}</div>;
}
