import { StoreState } from "../../../_types";
import { connect } from "react-redux";
import DeleteAccountPage, { DeleteAccountPageDispatchProps, DeleteAccountPageProps } from "../../DeleteAccountPage";
import { Dispatch } from "redux";
import { logoutServices, modalServices, userServices } from "../../../services";
import { LightboxDialogIdentifierForKey } from "../../../helpers/index";
import * as React from "react";
import { getCurrentUser } from "../../../ducks/user";

const DELETE_ACCOUNT_CONFIRMATION_TITLE = "Delete your Ripl account?";
const DELETE_ACCOUNT_CONFIRMATION_MESSAGE_1 = "You are about to permanently delete your Ripl account. You will lose access to the Ripl app,"
                                            + " including any images created and saved in your account.";
const DELETE_ACCOUNT_CONFIRMATION_MESSAGE_2 =  "Deleting your account will not stop an auto-renewable subscription with Ripl. You will be"
                                            + " responsible for canceling your subscription.";
const DELETE_ACCOUNT_CONFIRMATION_MESSAGE_3 = "The account deletion process may take up to 60 days.";

const CANCEL_BUTTON_LABEL = "Cancel";
const CONFIRM_BUTTON_LABEL = "Delete";

const mapStateToProps = ( storeState: StoreState ): DeleteAccountPageProps =>
{
    return {
        user: getCurrentUser( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): DeleteAccountPageDispatchProps =>
{
    return {
        handleDeleteAccount: () =>
        {
            const getContent: React.FC = () =>
            {
                return (
                    <div className={"deleteAccountConfirmation"}>
                        {DELETE_ACCOUNT_CONFIRMATION_MESSAGE_1}
                        <br/>
                        <br/>
                        {DELETE_ACCOUNT_CONFIRMATION_MESSAGE_2}
                        <br/>
                        <br/>
                        {DELETE_ACCOUNT_CONFIRMATION_MESSAGE_3}
                    </div>
                );
            };

            dispatch( modalServices.openLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.DELETE_ACCOUNT_CONFIRMATION,
                title: DELETE_ACCOUNT_CONFIRMATION_TITLE,
                content: getContent,
                hideAlternateAction: false,
                hideCancel: true,
                showCancelX: true,
                alternateActionLabel: CANCEL_BUTTON_LABEL,
                confirmLabel: CONFIRM_BUTTON_LABEL,
                width: 350,
                className: "deleteAccountConfirmation",
                onSuccess: async () =>
                {
                    await modalServices.encloseInLoadingDialog( async () =>
                    {
                        await dispatch( userServices.requestDeleteAccount() );
                    } );
                },
            } ) );
        },
        handleSignOut: () =>
        {
            dispatch( logoutServices.logout() );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( DeleteAccountPage );
