import * as React from "react";

export const DropDownIcon: React.FC<{}> = () =>
{
    return (
        /* tslint:disable:max-line-length */
        // @ts-ignore
        <svg className="businessNameDropdownIcon" width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2L8 8L14 2"/>
        </svg>
    );
};
