import * as React from "react";
import { Redirect, Route, RouteProps, Switch, withRouter } from "react-router";
import {
    ACCEPT_TEAM_INVITE_PARAM,
    browserUtils,
    CLIENT_ID_PARAM,
    COLLABRA_BLOCKED_URLS,
    COLLECTION_PAGE_URL,
    convertPricingPlanTierToProductTier,
    convertToPricingPlanTier,
    convertToPricingPlanType,
    COUPON_CODE_PARAM,
    CUSTOMIZE_PAGE_URL,
    DELETE_ACCOUNT_URL,
    ENABLE_ERROR_REPORTING,
    ENV_CONFIG,
    errorReporting,
    eventTracker,
    getSentryEnvironmentString,
    GRACE_PERIOD_END_URL,
    history,
    HOMEPAGE_URL,
    IOS_CLIENT_ID,
    LOGIN_URL,
    MANAGE_SUBSCRIPTION_PAGE_URL,
    NEED_HELP_SETTINGS_URL,
    NEED_HELP_URL,
    PARTNER_NOT_LOGGED_IN_URL,
    PARTNER_USER_LOGIN_URL,
    PRO_ONLY_WHITELISTED_URLS,
    PRO_PREVIEW_END_URL,
    PRODUCT_TIER_PARAM,
    PRODUCT_TYPE_PARAM,
    PURCHASE_PAGE_URL,
    SCOPED_USER_PARAM,
    SHAREPAGE_URL,
    SKIP_LOGIN_URLS,
    SRC_PARAM,
    STRIPE_PUBLISHABLE_KEY,
    SUBSCRIPTION_FLOW_SOURCE_CREATE_ACCOUNT,
    SUBSCRIPTION_ON_HOLD_URL,
    SUBSCRIPTION_TERMS_URL,
    URLS,
} from "../helpers";
import { logoutServices, modalServices, startupServices, upsellServices, userServices } from "../services";
import "./../assets/scss/App.scss";
import CustomizePageContainer from "./containers/CustomizePage.container";
import HomePageContainer from "./homepages/containers/HomePage.container";
import LoginPageContainer from "./containers/LoginPage.container";
import SharePageContainer from "./containers/SharePage.container";
import PasswordResetPageContainer from "./account/containers/PasswordResetPage.container";
import ChangePasswordPageContainer from "./account/containers/ChangePasswordPage.container";
import ModalDialogsContainer from "./containers/ModalDialogs.container";
import { StripeProvider } from "react-stripe-elements";
import { includes } from "lodash";
import * as Raven from "raven-js";
import * as path from "path";
import { getCurrentUserId, hasClosedPostIntents, hasSeenShowYouAround, isCollabraUser, isUserLoggedIn } from "../ducks/user";
import { contentSearchActions, inspirationsActions, modalActions, pricingActions, trackingActions, uiActions } from "../actions";
import { PageNotFound } from "./PageNotFound";
import PurchasePageContainer from "./containers/PurchasePage.container";
import { parse } from "query-string";
import { AmplitudeWrapper } from "./AmplitudeWrapper";
import ProfitWellWrapper from "./containers/ProfitWellWrapper.container";
import { store } from "../store";
import ApptimizeWrapper from "./ApptimizeWrapper";
import CollectionPageContainer from "./containers/CollectionPage.container";
import NeedHelpSubPage from "./settingsPages/containers/NeedHelpSubPage.container";
import ManageSubscriptionsPage from "./settingsPages/ManageSubscriptionsPage";
import SubscriptionOnHoldLockoutMessageContainer from "./containers/SubscriptionOnHoldLockout.container";
import { getCurrentBusiness } from "../ducks/userBusiness";
import ConsentBannerContainer from "./containers/ConsentBanner.container";
import { getSelectedTier, getSkuForTierAndDuration } from "../ducks/pricing";
import SubscriptionTermsPageContainer from "./homepages/containers/SubscriptionTermsPage.container";
import { getRedirectUrl, isFacebookAdMobileWebView } from "../ducks/ui";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PartnerUserLoginPageContainer from "./containers/PartnerUserLoginPage.container";
import CollabraSharePageContainer from "./containers/CollabraSharePage.container";
import CollabraCompletePageContainer from "./homepages/containers/CollabraCompletePage.container";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Helmet } from "react-helmet";
import DeleteAccountPageContainer from "./homepages/containers/DeleteAccountPage.container";

interface StripeState
{
    stripe: any;
}

class App extends React.Component<RouteProps, StripeState>
{
    constructor( props )
    {
        super( props );
        this.state = { stripe: null };

        eventTracker.initializeAppVersion();

        eventTracker.initializePinterestTracking();
        eventTracker.initializeFacebookTracking();
        eventTracker.initializeGoogleAnalyticsTracking();
        eventTracker.initializeApptimizeParticipatedEvents();

        if ( ENABLE_ERROR_REPORTING )
        {
            const root = global.__rootdir__;
            Raven.config( "https://eb217ec53ab14e53842e3d85705a43a1@sentry.io/1241702", {
                release: APP_VERSION,
                environment: getSentryEnvironmentString(),
                // From https://docs.sentry.io/clients/node/typescript/
                dataCallback: ( data ) =>
                {
                    function getStacktrace()
                    {
                        const exception = data.exception;
                        if ( exception && exception.values && exception.values[0] )
                        {
                            return exception.values[0].stacktrace;
                        }
                    }

                    const stacktrace = getStacktrace();
                    if ( stacktrace && stacktrace.frames )
                    {
                        stacktrace.frames.forEach( ( frame ) =>
                        {
                            if ( frame.filename.startsWith( "/" ) )
                            {
                                frame.filename = "app:///" + path.relative( root, frame.filename );
                            }
                        } );
                    }

                    return data;
                },
            } ).install();
        }
    }

    public componentDidCatch( error, info )
    {
        errorReporting.reportErrorToSentry( error, { extra: info } );
    }

    public componentDidMount()
    {
        this.handleLogoutAndStoreRedirectForIncorrectUser();
        this.configureStripeInstance();
        this.parseCouponCode();
        this.parseSrcCode();
        this.parseClientId();
        this.parseProductTier();
        this.parseProductType();
        this.parseTeamInvite();
        this.parseChannelTrackingParameters();
        this.doStartupServicesStuffOnFirstAppLoad();
    }

    public componentDidUpdate( prevProps: RouteProps, prevState: Readonly<StripeState>, prevContext: any )
    {
        // this can get called multiple times per route change
        this.handlePageRedirectionAndUpsell( prevProps );
    }

    public render()
    {
        return (
            <StripeProvider stripe={this.state.stripe}>
                <AmplitudeWrapper>
                    <ApptimizeWrapper>
                        <GoogleOAuthProvider clientId={ENV_CONFIG.googleClientId}>
                            <div className="app">
                                {isCollabraUser( store.getState() ) && this.overrideHeaderFieldsForCollabraUsers()}
                                <ProfitWellWrapper/>
                                <ModalDialogsContainer/>
                                <ToastContainer/>
                                <Switch>
                                    <Redirect exact from="/" to={HOMEPAGE_URL}/>
                                    <Route path={SUBSCRIPTION_TERMS_URL} component={SubscriptionTermsPageContainer}/>
                                    <Route path={HOMEPAGE_URL} component={HomePageContainer}/>
                                    <Route path={LOGIN_URL} component={LoginPageContainer}/>
                                    <Route path={URLS.PAGES.RESET_PASSWORD} component={PasswordResetPageContainer}/>
                                    <Route path={URLS.PAGES.CHANGE_PASSWORD} component={ChangePasswordPageContainer}/>
                                    <Route path={CUSTOMIZE_PAGE_URL} component={CustomizePageContainer}/>
                                    <Route path={SHAREPAGE_URL} component={this.sharePageContainer()}/>
                                    <Route path={PARTNER_NOT_LOGGED_IN_URL} component={CollabraCompletePageContainer}/>
                                    <Route path={PURCHASE_PAGE_URL} component={PurchasePageContainer}/>
                                    <Route path={COLLECTION_PAGE_URL} component={CollectionPageContainer}/>
                                    <Route path={MANAGE_SUBSCRIPTION_PAGE_URL} component={ManageSubscriptionsPage}/>
                                    <Route path={SUBSCRIPTION_ON_HOLD_URL} component={SubscriptionOnHoldLockoutMessageContainer}/>
                                    <Route path={NEED_HELP_URL} component={NeedHelpSubPage}/>
                                    <Route path={PARTNER_USER_LOGIN_URL} component={PartnerUserLoginPageContainer}/>
                                    <Route path={DELETE_ACCOUNT_URL} component={DeleteAccountPageContainer}/>

                                    <Route component={PageNotFound}/>
                                </Switch>
                            </div>
                            {!isFacebookAdMobileWebView( store.getState() ) && <ConsentBannerContainer/>}
                        </GoogleOAuthProvider>
                    </ApptimizeWrapper>
                </AmplitudeWrapper>
            </StripeProvider>
        );
    }

    private overrideHeaderFieldsForCollabraUsers()
    {
        return (
            <Helmet>
                <title>Spherebuilder + Ripl</title>
                <link id="favicon" rel="shortcut icon" type="image/x-icon" href="/collabra-favicon.ico"/>
            </Helmet>
        );
    }

    private sharePageContainer = () =>
    {
        const state = store.getState();

        if ( isCollabraUser( state ) )
        {
            return CollabraSharePageContainer;
        }
        return SharePageContainer;
    }

    private doStartupServicesStuffOnFirstAppLoad = () =>
    {
        store.dispatch( startupServices.initialize() );
        const state = store.getState();
        const redirectUrl = getRedirectUrl( state );
        const isOnOrIsGoingToPartnerUserLoginPage = history.location.pathname === PARTNER_USER_LOGIN_URL || includes( redirectUrl,
            PARTNER_USER_LOGIN_URL );
        if ( isUserLoggedIn( state ) && !isOnOrIsGoingToPartnerUserLoginPage )
        {
            eventTracker.setUserPropertiesForCurrentUser();
            store.dispatch( startupServices.initializeUser() );
            store.dispatch( inspirationsActions.loadedDataClearToPageOne() );
        }
    }

    private handleLogoutAndStoreRedirectForIncorrectUser = () =>
    {
        const redirectUrl = window.location.pathname;
        const scopedUserIdString = parse( window.location.search )[SCOPED_USER_PARAM];
        const userLoggedIn = isUserLoggedIn( store.getState() );
        const currentUserId = getCurrentUserId( store.getState() );
        const currentUserIdString = currentUserId && currentUserId.toString();

        if ( userLoggedIn && scopedUserIdString && scopedUserIdString !== currentUserIdString )
        {
            store.dispatch( logoutServices.logout( redirectUrl ) );
        }
    }

    private parseCouponCode()
    {
        const couponCode = parse( window.location.search )[COUPON_CODE_PARAM];
        store.dispatch( pricingActions.updateCouponCode( couponCode ) );
    }

    private parseProductType()
    {
        const productType = parse( window.location.search )[PRODUCT_TYPE_PARAM];
        const convertedProductType = convertToPricingPlanType( productType );
        store.dispatch( pricingActions.updateProductType( convertedProductType ) );

        const sku = getSkuForTierAndDuration( getSelectedTier( store.getState() ), productType );
        store.dispatch( pricingActions.setPlan( sku ) );
    }

    private parseProductTier()
    {
        const productTier = parse( window.location.search )[PRODUCT_TIER_PARAM];
        const convertedProductTier = convertToPricingPlanTier( productTier );
        const convertedPricingPlanTier = convertPricingPlanTierToProductTier( convertedProductTier );

        store.dispatch( pricingActions.updateProductTier( convertedProductTier ) );
        store.dispatch( pricingActions.tierSelected( convertedPricingPlanTier ) );
    }

    private parseSrcCode()
    {
        const srcCode = parse( window.location.search )[SRC_PARAM];
        store.dispatch( pricingActions.updateSrcCode( srcCode ) );
    }

    private parseClientId()
    {
        const clientIdParam = parse( window.location.search )[CLIENT_ID_PARAM];
        if ( includes( clientIdParam, IOS_CLIENT_ID ) )
        {
            store.dispatch( uiActions.facebookAdMobileWebViewOpened( true ) );
        }
    }

    private parseTeamInvite()
    {
        const queryParams = window.location.search;
        if ( includes( queryParams, ACCEPT_TEAM_INVITE_PARAM ) )
        {
            store.dispatch( uiActions.setIsAcceptTeamInviteFlow( true ) );
        }
    }

    private parseChannelTrackingParameters()
    {
        const paramDictionary = parse( window.location.search );

        const { utm_source, utm_medium, utm_campaign, utm_term, utm_content, gclid, fbclid } = paramDictionary;
        const channelTrackingParameters = {
            utm_source,
            utm_medium,
            utm_campaign,
            utm_term,
            utm_content,
            gclid,
            fbclid,
        };
        store.dispatch( trackingActions.updateChannelTrackingParameters( channelTrackingParameters ) );
    }

    private handlePageRedirectionAndUpsell = ( prevProps: RouteProps ) =>
    {
        const state = store.getState();
        const redirectRoute = window.location.href.substr( window.location.origin.length );
        const pathname = window.location.pathname;
        const userLoggedIn = isUserLoggedIn( state );
        const previousPathname = prevProps.location.pathname;

        if ( !userLoggedIn )
        {
            if ( !includes( [LOGIN_URL, ...SKIP_LOGIN_URLS], pathname ) )
            {
                const loginUrl = LOGIN_URL + window.location.search;
                this.gotoUrlWithStoredRedirectUrl( loginUrl, redirectRoute );
            }
        }
        else if ( isCollabraUser( state ) && includes( COLLABRA_BLOCKED_URLS, window.location.pathname ) )
        {
            history.replace( HOMEPAGE_URL );
        }
        else if ( userServices.shouldShowSubscriptionOnHoldLockoutMessage() )
        {
            if ( !includes( [SUBSCRIPTION_ON_HOLD_URL, LOGIN_URL], pathname ) )
            {
                const subscriptionOnHoldUrl = SUBSCRIPTION_ON_HOLD_URL + window.location.search;
                history.replace( subscriptionOnHoldUrl );
            }
        }
        else if ( includes( [SUBSCRIPTION_ON_HOLD_URL], pathname ) && !userServices.shouldShowSubscriptionOnHoldLockoutMessage() )
        {
            history.replace( HOMEPAGE_URL );
        }
        else if ( this.shouldShowCreateBusinessDialog() )
        {
            if ( HOMEPAGE_URL !== pathname )
            {
                this.gotoUrlWithStoredRedirectUrl( HOMEPAGE_URL, redirectRoute );
            }
            else
            {
                store.dispatch( modalActions.lightboxDialogClosedAll() );
                store.dispatch( modalServices.openForceCreateNewBusinessDialog() );
            }
        }
        else if ( userServices.shouldShowProOnlyUpsellDialog() )
        {
            if ( HOMEPAGE_URL !== pathname && !includes( PRO_ONLY_WHITELISTED_URLS, pathname ) )
            {
                this.gotoUrlWithStoredRedirectUrl( HOMEPAGE_URL, redirectRoute );
            }

            if ( previousPathname === PURCHASE_PAGE_URL )
            {
                this.showProPreviewWelcomeDialog();
            }
            else if ( !includes( PRO_ONLY_WHITELISTED_URLS, pathname ) )
            {
                store.dispatch( modalActions.lightboxDialogClosedAll() );
                store.dispatch(
                    upsellServices.displayTryProNoWarmup(
                        SUBSCRIPTION_FLOW_SOURCE_CREATE_ACCOUNT,
                        null,
                        null,
                        () =>
                        {
                            this.showProPreviewWelcomeDialog();
                        },
                    ),
                );
            }
        }
        else if ( this.shouldShowThemAround( pathname ) )
        {
            store.dispatch( uiActions.displayShowYouAroundTour( true ) );
        }
        else if ( includes( [NEED_HELP_SETTINGS_URL], pathname ) )
        {
            store.dispatch( contentSearchActions.searchModeExited() );
        }
        else if ( includes( [LOGIN_URL, GRACE_PERIOD_END_URL, PRO_PREVIEW_END_URL], pathname ) )
        {
            history.replace( HOMEPAGE_URL );
        }
    }

    private showProPreviewWelcomeDialog = () =>
    {
        store.dispatch( modalServices.openAcceptTestDriveDialog() );
    }

    private gotoUrlWithStoredRedirectUrl = ( newUrl: string, redirectRoute: string ) =>
    {
        store.dispatch( uiActions.setRedirectUrl( redirectRoute ) );
        history.replace( newUrl );
    }

    private configureStripeInstance = () =>
    {
        if ( window.Stripe )
        {
            this.setStripeInstanceIntoState();
        }
        else
        {
            document.querySelector( "#stripe-js" ).addEventListener( "load", () =>
            {
                // Create Stripe instance once Stripe.js loads
                this.setStripeInstanceIntoState();
            } );
        }
    }

    private setStripeInstanceIntoState = () =>
    {
        if ( window && window.Stripe )
        {
            this.setState( { stripe: window.Stripe( STRIPE_PUBLISHABLE_KEY ) } );
        }
    }

    private shouldShowCreateBusinessDialog = () =>
    {
        const state = store.getState();
        const redirectUrl = getRedirectUrl( state );
        const isOnOrIsGoingToPartnerUserLoginPage = history.location.pathname === PARTNER_USER_LOGIN_URL || includes( redirectUrl,
            PARTNER_USER_LOGIN_URL );
        return !getCurrentBusiness( state ) && !browserUtils.isMobileBrowser() && !isOnOrIsGoingToPartnerUserLoginPage;
    }

    private shouldShowThemAround( pathname: string )
    {
        const state = store.getState();
        const canBeShownAround = HOMEPAGE_URL === pathname && !hasSeenShowYouAround( state );
        return canBeShownAround && hasClosedPostIntents( state );
    }
}

export default withRouter( App );
