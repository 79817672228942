import * as React from "react";
import { BACKGROUND_MEDIA_STATUS_DEVELOPMENT, BackgroundMediaAPIItem, IControlConfig } from "../_types/api";
import { BackgroundMediaGridItemContainerProps } from "./containers/BackgroundMediaGridItem.container";
import classNames from "classnames";
import { BackgroundChoice } from "../_types";

export interface BackgroundMediaGridItemProps extends BackgroundMediaGridItemContainerProps
{
    control: IControlConfig;
    currentValue: BackgroundChoice;
}

export interface BackgroundMediaGridItemDispatchProps
{
    applyBackground?( control: IControlConfig, backgroundMediaItem: BackgroundMediaAPIItem );
}

export class BackgroundMediaGridItem extends React.PureComponent<BackgroundMediaGridItemProps & BackgroundMediaGridItemDispatchProps>
{
    public render()
    {
        const additionalClasses = {
            selected: this.isControlSelected(),
            development: this.isBackgroundMediaInDevelopment(),
            none: !this.isBackgroundMediaInDevelopment(),
        };
        const backgroundImage = this.isBackgroundMediaOption() ? `url(${this.getThumbnailUrl()}` : "none";

        const backgroundMediaOptionStyle = {
            backgroundImage,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
        };

        return (
            <div className={classNames( "backgroundMediaChoice", additionalClasses )} onClick={this.handleClick} style={backgroundMediaOptionStyle}>
                {this.shouldRenderNoneOption() && <span>None</span>}
            </div>);
    }

    private getThumbnailUrl = () =>
    {
        return this.props.backgroundMediaOption.thumbnail_url || this.props.backgroundMediaOption.cdn_url;
    }

    private isBackgroundMediaOption = () =>
    {
        return !this.shouldRenderNoneOption();
    }

    private shouldRenderNoneOption = () =>
    {
        return !this.props.backgroundMediaOption;
    }

    private isBackgroundMediaInDevelopment = () =>
    {
        if ( this.isBackgroundMediaOption() )
        {
            const backgroundMediaOption = this.props.backgroundMediaOption as BackgroundMediaAPIItem;
            const status = backgroundMediaOption.status;
            return status === BACKGROUND_MEDIA_STATUS_DEVELOPMENT;
        }
        return false;
    }

    private handleClick = () =>
    {
        this.props.applyBackground( this.props.control, this.props.backgroundMediaOption );
    }

    private isControlSelected = () =>
    {
        if ( !!this.props.currentValue.media && this.isBackgroundMediaOption() )
        {
            return this.props.currentValue.media.id === this.props.backgroundMediaOption.id;
        }
        return !this.props.currentValue.media && this.shouldRenderNoneOption();
    }
}
