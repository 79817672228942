import { connect } from "react-redux";
import { Dispatch } from "redux";
import { store } from "../../../../store";
import { trackingActions, uiActions } from "../../../../actions";
import { filter, find, size, uniqBy } from "lodash";
import MoreMusicDialogContainer from "../../containers/MoreMusicDialog.container";
import { getCurrentMusic, getCustomMusicFromMusicCatalog } from "../../../../ducks/musicCatalog";
import {
    getMusicCatalogSelected,
    getPremiumEpidemicSoundTrackPreview,
    isCustomMusicUploading,
    isLoadingEpidemicSoundCatalogInProgress,
} from "../../../../ducks/ui";
import { doesAnyVideoContainAudio, getOriginalMixModelMusic, getVideoAudioMusicData } from "../../../../ducks/mixModel";
import { modalServices } from "../../../../services";
import { MusicPicker, MusicPickerDispatchProps, MusicPickerProps } from "../MusicPicker";
import { epidemicSoundCatalogServices } from "../../../../services/epidemicSoundCatalog.services";
import { EPIDEMIC_SOUND_SOURCE_CURATED, LightboxDialogIdentifierForKey, selectMusic } from "../../../../helpers";
import { getEpidemicSoundTrackByIdAsMusic, getFreeEpidemicSoundTracks, isEpidemicSoundTrackReady } from "../../../../ducks/epidemicSoundCatalog";
import { Music, StoreState } from "../../../../_types";
import { EpidemicSoundTrack, MUSIC_TYPE_EPIDEMIC_SOUND, MUSIC_TYPE_VIDEO_AUDIO } from "../../../../_types/api";
import { isCurrentProductTierPremium } from "../../../../ducks/user";
import withMusicPlayer from "../../../higherOrderComponents/WithMusicPlayer";

const mapStateToProps = ( storeState: StoreState ): MusicPickerProps =>
{
    const currentMixModelMusic = getCurrentMusic( storeState );
    const originalMixModelMusic = getOriginalMixModelMusic( storeState );
    const epidemicSoundTracks = getFreeEpidemicSoundTracks( storeState );
    const mixModelMusicAboveCatalog = getMusicToShowAboveCatalog( originalMixModelMusic, currentMixModelMusic, epidemicSoundTracks );

    const musicArray: Music[] = [
        ...mixModelMusicAboveCatalog,
        getCustomMusicFromMusicCatalog( storeState ),
    ];

    const musicArrayFiltered = uniqBy( filter( musicArray ), "display_name" );
    return {
        musicArray: musicArrayFiltered,
        videoAudio: getVideoAudioMusicData( storeState ),
        doesVideoContainAudio: doesAnyVideoContainAudio( storeState ),
        isLoadingEpidemicSoundCatalog: isLoadingEpidemicSoundCatalogInProgress( storeState ),
        isCustomMusicUploading: isCustomMusicUploading( storeState ),
        epidemicSoundTracks,
        isCurrentProductTierPremium: isCurrentProductTierPremium( storeState ),
        premiumEpidemicSoundTrackPreview: getPremiumEpidemicSoundTrackPreview( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): MusicPickerDispatchProps =>
{
    return {
        onMoreClicked: () =>
        {
            openChooseMusic( dispatch );
        },
        loadFreeEpidemicSoundCatalog: async () =>
        {
            await dispatch( epidemicSoundCatalogServices.loadFreeEpidemicSoundCatalog() );
            await dispatch( epidemicSoundCatalogServices.refreshCurrentMixMusicIfEpidemicSoundTrack() );
        },
        selectPremiumEpidemicSoundTrackPreview: async () =>
        {
            const epidemicSoundTrack = getPremiumEpidemicSoundTrackPreview( store.getState() );
            if ( !isEpidemicSoundTrackReady( store.getState(), epidemicSoundTrack.epidemic_id ) )
            {
                await dispatch( epidemicSoundCatalogServices.downloadEpidemicSoundTrackData( epidemicSoundTrack ) );
            }

            if ( isEpidemicSoundTrackReady( store.getState(), epidemicSoundTrack.epidemic_id ) )
            {
                dispatch( trackingActions.epidemicSoundSourceSet( EPIDEMIC_SOUND_SOURCE_CURATED ) );
                return getEpidemicSoundTrackByIdAsMusic( store.getState(), epidemicSoundTrack.epidemic_id );
            }
        },
        clearPremiumEpidemicSoundPreview: () =>
        {
            dispatch( uiActions.clearPremiumEpidemicSoundPreview() );
        },
        setEpidemicSoundSource: () =>
        {
            dispatch( trackingActions.epidemicSoundSourceSet( EPIDEMIC_SOUND_SOURCE_CURATED ) );
        },
    };
};

function openChooseMusic( dispatch: Dispatch<StoreState> )
{
    dispatch( modalServices.openLightbox( {
        identifierForKey: LightboxDialogIdentifierForKey.CHOOSE_MUSIC,
        title: "Choose Music",
        className: "moreMusicDialog",
        showCancelX: true,
        hideCancel: true,
        confirmLabel: "Save",
        content: MoreMusicDialogContainer,
        width: 500,
        onSuccess: () =>
        {
            const state = store.getState();
            const music = getMusicCatalogSelected( state );
            if ( music )
            {
                selectMusic( state, dispatch, music );
            }
        },
        onCancel: () =>
        {
            const state = store.getState();
            const currentMusic = getCurrentMusic( state );
            if ( currentMusic )
            {
                dispatch( uiActions.updateMusicCatalogSelection( currentMusic ) );
            }
        },
    } ) );
}

function getMusicToShowAboveCatalog( originalMixModelMusic: Music, currentMixModelMusic: Music, epidemicSoundTracks: any[] )
{
    return filter( [originalMixModelMusic, currentMixModelMusic], ( music: Music ) =>
    {
        if ( music && music.type === MUSIC_TYPE_VIDEO_AUDIO )
        {
            // video audio always appears in the list via separate mechanism so filter it out
            return false;
        }
        else if ( music && music.type === MUSIC_TYPE_EPIDEMIC_SOUND )
        {
            const tracksAlreadyShowingInCatalog = find( epidemicSoundTracks,
                ( epidemicSoundTrack: EpidemicSoundTrack ) => music.id === epidemicSoundTrack.epidemic_id );
            return size( tracksAlreadyShowingInCatalog ) === 0;
        }
        return true;
    } );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( withMusicPlayer( MusicPicker ) );
