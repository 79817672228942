import * as React from "react";
import { concat, filter, includes, isEqual, uniq, without } from "lodash";
import { FacebookThingsList } from "./FacebookThingsList";
import DialogContainer from "react-md/lib/Dialogs/DialogContainer";
import DialogTitle from "react-md/lib/Dialogs/DialogTitle";
import Button from "react-md/lib/Buttons/Button";
import { FACEBOOK_GROUP_ACCOUNT_TYPE, FACEBOOK_INSTAGRAM_ACCOUNT_TYPE, FACEBOOK_PAGE_ACCOUNT_TYPE } from "../_types/api";
import { FacebookPickerDialogData, SocialNetworkAccount } from "../_types";

export interface FacebookPickerDialogProps
{
    facebookPickerDialogData: FacebookPickerDialogData;
    onComplete?: ( pickedItems: SocialNetworkAccount[] ) => Promise<any>;
    singleSelect?: boolean;
    saveActionLabel?: string;
    dialogLabelBuilder?: ( props: FacebookPickerDialogProps ) => string;
}

export interface FacebookPickerDialogDispatchProps
{
    closeModal: () => void;
    onItemsPicked: ( dialogData: FacebookPickerDialogData,
                     pickedIds: number[],
                     onComplete?: ( pickedItems: SocialNetworkAccount[] ) => Promise<any> ) => void;
    onItemPicked: ( item: SocialNetworkAccount ) => void;
    onItemNotFound: ( dialogData: FacebookPickerDialogData ) => void;
}

interface DialogAction
{
    onClick: () => void;
    primary?: boolean;
    secondary?: boolean;
    disabled?: boolean;
    swapTheming: boolean;
    children: string;
}

interface FacebookPickerDialogState
{
    selectedIds: number[];
    desiredIds: number[];
    actions: DialogAction[];
}

const ADD_BUTTON_LABEL = "Add";
const DONT_SEE_INSTAGRAM_LABEL = "Don't see your business?";

/* tslint:disable:max-line-length */
export class FacebookPickerDialog extends React.PureComponent<FacebookPickerDialogProps & FacebookPickerDialogDispatchProps, FacebookPickerDialogState>
{

    private itemNotFoundAction: DialogAction;
    private saveAction: DialogAction;

    constructor( props )
    {
        super( props );
        this.itemNotFoundAction = {
            onClick: this.handleItemNotFound,
            secondary: true,
            swapTheming: false,
            children: DONT_SEE_INSTAGRAM_LABEL,
        };

        this.saveAction = {
            onClick: this.handleItemsPicked,
            primary: true,
            disabled: true,
            swapTheming: true,
            children: props.saveActionLabel || ADD_BUTTON_LABEL,
        };

        this.state = {
            selectedIds: [],
            desiredIds: [],
            actions: this.getActions( true ),
        };
    }

    public componentDidUpdate( prevProps: Readonly<FacebookPickerDialogProps & FacebookPickerDialogDispatchProps>,
                               prevState: Readonly<FacebookPickerDialogState>, prevContext: any ): void
    {
        if ( prevProps.facebookPickerDialogData.accountType !== this.props.facebookPickerDialogData.accountType )
        {
            this.setState( {
                actions: this.getActions( this.isSaveDisabled( this.state.selectedIds ) ),
            } );
        }
    }

    public render()
    {
        // TODO: This is triggering a render(), inside of the render() function... scary!
        this.filterDesiredIds();

        return (
            <DialogContainer
                id="facebook-picker"
                className="facebookPicker"
                visible={this.isModalOpen( this.props )}
                modal
                width={450}
                title={this.createTitleComponent( this.props )}
                onHide={this.props.closeModal}
                actions={this.state.actions}
                focusOnMount={false}>
                <FacebookThingsList allItems={this.props.facebookPickerDialogData.allItems}
                                    singleSelect={this.props.singleSelect}
                                    onChange={this.handleListChange}
                                    selectedIds={this.state.selectedIds}/>
            </DialogContainer>
        );
    }

    private filterDesiredIds = () =>
    {
        if ( this.props.facebookPickerDialogData && this.props.facebookPickerDialogData.allItems && this.state.selectedIds )
        {
            const accountsWithPermissions = filter( this.props.facebookPickerDialogData.allItems, ( account ) => !account.needsMorePermissions );
            const accountIdsWithPermissions = accountsWithPermissions.map( ( account ) => account.id );
            const selectedIds = filter( this.state.desiredIds, ( id ) => includes( accountIdsWithPermissions, id ) );
            if ( !isEqual( selectedIds, this.state.selectedIds ) )
            {
                const buttonDisabledFlag = this.isSaveDisabled( selectedIds );

                this.setState( {
                    selectedIds,
                    actions: this.getActions( buttonDisabledFlag ),
                } );
            }
        }
    }

    private handleItemNotFound = () =>
    {
        this.props.onItemNotFound( this.props.facebookPickerDialogData );
    }

    private handleItemsPicked = () =>
    {
        this.props.onItemsPicked( this.props.facebookPickerDialogData, this.state.selectedIds, this.props.onComplete );
    }

    // This approach is a hack that results in two nested `H2` elements, one for the DialogTitle below and another
    // produced by the Dialog component. The `padding: 0` set in the `titleStyle` property of the `DialogContainer`,
    // above, is used to remove the extra spacing of the outer `H2`.
    private createTitleComponent = ( props ) =>
    {
        return (
            <div>
                <DialogTitle children={this.determineContentLabel( props )}/>
                <Button icon={true}
                        className="closeX"
                        iconClassName="material-icons"
                        iconChildren="close"
                        onClick={this.props.closeModal}/>
            </div>
        );
    }

    private determineContentLabel = ( props ) =>
    {
        if ( props.dialogLabelBuilder )
        {
            return props.dialogLabelBuilder( props );
        }

        // TODO Make this handle the conditionals more elegantly
        switch ( props.facebookPickerDialogData.accountType )
        {
            case FACEBOOK_PAGE_ACCOUNT_TYPE:
            {
                const target = this.props.singleSelect ? "page" : "page(s)";
                return "Select Facebook " + target;
            }
            case FACEBOOK_GROUP_ACCOUNT_TYPE:
            {
                const target = this.props.singleSelect ? "group" : "group(s)";
                return "Select Facebook " + target;
            }
            case FACEBOOK_INSTAGRAM_ACCOUNT_TYPE:
            {
                const target = this.props.singleSelect ? "Business" : "Business(es)";
                return "Connect Instagram " + target;
            }
            default:
            // TODO Handle an invalid account type
        }
    }

    private showDontSeeAccountAction = () =>
    {
        return this.props.facebookPickerDialogData.accountType === FACEBOOK_INSTAGRAM_ACCOUNT_TYPE;
    }

    private isModalOpen = ( props ) =>
    {
        return props.facebookPickerDialogData
               && props.facebookPickerDialogData.hasOwnProperty( "isOpen" )
               && props.facebookPickerDialogData.isOpen;
    }

    private handleListChange = ( item: SocialNetworkAccount, checked: boolean, event: Event ) =>
    {
        this.props.onItemPicked( item );

        const selectedIds = this.updateSelectedIds( this.state.selectedIds, item.id, checked );
        const desiredIds = selectedIds.slice();
        const saveButtonDisabledFlag = this.isSaveDisabled( selectedIds );

        this.setState( {
            selectedIds,
            desiredIds,
            actions: this.getActions( saveButtonDisabledFlag ),
        } );
    }

    private getActions = ( saveActionDisabled: boolean ) =>
    {
        const actions = [];

        if ( this.showDontSeeAccountAction() )
        {
            actions.push( this.itemNotFoundAction );
        }
        actions.push( {
            ...this.saveAction,
            disabled: saveActionDisabled,
        } );
        return actions;
    }

    private updateSelectedIds = ( selectedIds: number[], id: number, checked: boolean ): number[] =>
    {
        if ( checked )
        {
            if ( this.props.singleSelect )
            {
                return [id];
            }
            else
            {
                return uniq( concat( selectedIds, [id] ) );
            }
        }
        else
        {
            return uniq( without( selectedIds, id ) );
        }
    }

    private isSaveDisabled = ( selectedIds: number[] ): boolean =>
    {
        return selectedIds.length === 0;
    }
}
