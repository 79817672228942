import { indexOf, isNil } from "lodash";
import { EMPTY_SYMBOL, errorReporting, LOCALE_US, MODAL_DIALOG_SMALL_HEIGHT_MATCHING_SCSS_VAR, USD_CODE } from "./";

type ProgressState = ConvertVideoState | TrimVideoState;
type ProgressAPI = ConvertVideoAPIUpdate | TrimVideoAPIUpdate;

export function calculateCombinedProgress( progressStates: ProgressState[], currentProgress: ProgressAPI )
{
    if ( progressStates[0] === currentProgress.state )
    {
        return 0;
    }
    const aggregate = indexOf( progressStates, currentProgress.state );
    const combinedProgress = (currentProgress.progress / progressStates.length) + (aggregate / progressStates.length);
    return Math.min( Math.round( combinedProgress * 100 ), 100 );
}

export function windowLargerThanSmallDialogHeight()
{
    return window.innerHeight > MODAL_DIALOG_SMALL_HEIGHT_MATCHING_SCSS_VAR;
}

export const centsFormattedForCurrency = ( locale: string, currencyCode: CurrencyCode, amountInCents: number, notation?: string ): string =>
{
    return dollarsFormattedForCurrency( locale, currencyCode, amountInCents / 100, notation );
};

export const dollarsFormattedForCurrency = ( locale: string, currencyCode: CurrencyCode, amountInDollars: number, notation?: string ): string =>
{
    const options: Intl.NumberFormatOptions = { style: "currency", currency: currencyCode };
    if ( notation === "compact" )
    {
        options.minimumFractionDigits = amountInDollars > 1000 ? 0 : 2;
        return formatCurrencyAmountForCompactness( locale, amountInDollars, options );
    }
    return Intl.NumberFormat( locale, options ).format( amountInDollars );
};

export type CurrencyPartType =
    "currency"
    | "decimal"
    | "fraction"
    | "group"
    | "infinity"
    | "literal"
    | "minusSign"
    | "nan"
    | "plusSign"
    | "percentSign"
    | "unit"
    | "integer";

export interface CurrencyPart
{
    type: CurrencyPartType;
    value: string;
}

export function partsFormattedForCurrency( locale: string, currencyCode: CurrencyCode, amountInDollars: number ): CurrencyPart[]
{
    const options: Intl.NumberFormatOptions = { style: "currency", currency: currencyCode };
    const numberFormat = Intl.NumberFormat( locale, options );

    // @ts-ignore
    if ( numberFormat.formatToParts )
    {
        // @ts-ignore
        return numberFormat.formatToParts( amountInDollars );
    }
    else
    {
        return null;
    }
}

export const formatCurrencyAmountForCompactness = ( locale: string, amount: number, options: Intl.NumberFormatOptions = {} ): string =>
{
    // Typescript version must be upgraded to maybe 4.0 or higher for notation to be found.
    // The following links may help you with resolving this:
    // https://bitbucket.org/riplapp/ripl-windows-react/pull-requests/4085
    // https://github.com/microsoft/TypeScript/issues/36533
    // https://github.com/microsoft/TypeScript/blob/cec2fda9a53620dc545a2c4d7b0156446ab145b4/lib/lib.es5.d.ts
    // https://github.com/microsoft/TypeScript/commit/70a5d27292e83e61fcf6defb9ea83e91654d6509
    // Good luck!
    try
    {
        // @ts-ignore
        return Intl.NumberFormat( locale, { notation: "compact", ...options } ).format( amount );
    }
    catch (e)
    {
        // browser doesn't support notation
        if ( !(e instanceof TypeError) )
        {
            errorReporting.reportErrorToSentry( e );
        }
        return Intl.NumberFormat( locale, options ).format( amount );
    }
};

export const numberFormattedOrEmpty = ( locale: string, amount: number ) =>
{
    if ( amount <= 0 || isNil( amount ) || isNaN( amount ) )
    {
        return EMPTY_SYMBOL;
    }

    return numberFormatted( locale, amount );
};

export const numberFormattedForCompactnessOrEmpty = ( locale: string, amount: number ) =>
{
    if ( amount <= 0 || isNil( amount ) || isNaN( amount ) )
    {
        return EMPTY_SYMBOL;
    }

    return formatCurrencyAmountForCompactness( locale, amount );
};

export const numberFormatted = ( locale: string, amount: number ) =>
{
    return Intl.NumberFormat( locale ).format( amount );
};

export const currencyOrEmptySymbolForAmountInDollars = ( amountInDollars: number, notation?: string ) =>
{
    amountInDollars = Number( amountInDollars ) || 0;

    if ( amountInDollars <= 0 )
    {
        return EMPTY_SYMBOL;
    }
    else
    {
        return dollarsFormattedForCurrency( LOCALE_US, USD_CODE, amountInDollars, notation );
    }
};

export function convertUSDAmountToSelectedCurrency( shouldShowConvertedCurrency: boolean, currentExchangeRate: number, amountInUSD: number ): number
{
    if ( shouldShowConvertedCurrency )
    {
        return amountInUSD * currentExchangeRate;
    }

    return amountInUSD;
}
