import * as React from "react";
import { AnimationTextOption, DesignElementProperties, DesignElementProperty, TextPickerCaptionData, UserBusiness } from "../../_types";
import classNames from "classnames";
import { chain, filter, isEqual, map } from "lodash";
import { DESIGN_ELEMENT_PROPERTY_TYPE_ANIMATION, TEXT_STYLE_STATUS_DEVELOPMENT } from "../../_types/api";
import { ListPicker } from "../ListPicker";

export interface AnimationPickerTextPropertiesSubPanelProps
{
    captionData: TextPickerCaptionData;
    designElementProperties: DesignElementProperties;
    currentBusiness: UserBusiness;
}

export interface AnimationPickerTextPropertiesSubPanelDispatchProps
{
    tellDesignToApplyAnimation( captionId: string, type: string, animationPropertyId: string, option: AnimationTextOption );
}

export class AnimationPickerTextPropertiesSubPanel
    extends React.PureComponent<AnimationPickerTextPropertiesSubPanelProps & AnimationPickerTextPropertiesSubPanelDispatchProps>
{
    public render()
    {
        const animations = this.getAnimationPropertiesData();
        return (
            <div className={classNames( "listPicker", "animationPickerSubPanel" )}>
                {map( animations, ( animationProperty: DesignElementProperty, animationPropertyIndex: number ) =>
                {
                    return <div key={animationProperty.id}>{animationProperty.displayName}
                        <ListPicker>{
                            map( animationProperty.options, ( animationOption: AnimationTextOption, animationOptionIndex: number ) =>
                            {
                                const selected = isEqual( animationOption.id, animationProperty.selected );
                                return (
                                    <AnimationPickerItem
                                        key={animationOption.id}
                                        animationOption={animationOption}
                                        animationProperty={animationProperty}
                                        selected={selected}
                                        onAnimationOptionSelected={this.onAnimationOptionSelected}
                                    />
                                );
                            } )
                        }</ListPicker>
                    </div>;
                } )}
            </div>
        );
    }

    private getAnimationPropertiesData = (): DesignElementProperty[] =>
    {
        const animationProperties = filter( this.props.designElementProperties.properties,
            ( property ) => property.type === DESIGN_ELEMENT_PROPERTY_TYPE_ANIMATION );
        return animationProperties;
    }

    private onAnimationOptionSelected = ( selectedAnimationOption: AnimationTextOption, animationProperty: DesignElementProperty ) =>
    {
        this.props.tellDesignToApplyAnimation( this.props.captionData.id, animationProperty.type, animationProperty.id, selectedAnimationOption );
    }

}

export interface AnimationPickerItemProps
{
    animationOption: AnimationTextOption;
    animationProperty: DesignElementProperty;
    selected: boolean;
}

export interface AnimationPickerItemDispatchProps
{
    onAnimationOptionSelected: ( animationOption: AnimationTextOption, animationProperty: DesignElementProperty ) => void;
}

export class AnimationPickerItem extends React.PureComponent<AnimationPickerItemProps & AnimationPickerItemDispatchProps>
{
    public render()
    {
        const formattedDisplayName = chain( this.props.animationOption.displayName )
            .replace( /-/gmi, " " )
            .capitalize()
            .value();

        return (
            <div className={classNames( "item",
                { selected: this.props.selected, devMode: this.props.animationOption.status === TEXT_STYLE_STATUS_DEVELOPMENT } )}
                 onClick={this.handleSelected}>
                <div className="description">{formattedDisplayName}</div>
            </div>
        );
    }

    private handleSelected = () =>
    {
        this.props.onAnimationOptionSelected( this.props.animationOption, this.props.animationProperty );
    }
}
