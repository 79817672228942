import * as React from "react";
import Switch from "react-md/lib/SelectionControls/Switch";
import Divider from "react-md/lib/Dividers/Divider";
import BrandSlideColorPickerContainer from "./containers/BrandSlideColorPicker.container";
import BrandSlideFontPickerContainer from "./containers/BrandSlideFontPicker.container";
import classNames from "classnames";
import { TextPickerField } from "./textPicker/TextPickerField";
import { BRAND_SLIDE_CONTACT_TEXT_FIELD_NAME, BRAND_SLIDE_TAGLINE_TEXT_FIELD_NAME } from "../../_types/api";
import BrandSlideRecentLogosSummaryContainer from "./containers/BrandSlideRecentlyLogosSummary.container";

export interface BrandSlideEditPanelProps
{
    showBrandSlideInMixModel: boolean;
    showBrandSlideLogoInMixModel: boolean;
    tagline: string;
    contact: string;
}

export interface BrandSlideEditPanelDispatchProps
{
    handleToggleShowBrandSlide: ( enabled: boolean ) => void;
    handleToggleShowBrandSlideLogo: ( enabled: boolean ) => void;
    onFieldChanged: ( fieldName: string, value: string, id?: string ) => void;
    handleComponentWillUnmount: () => void;
}

export default class BrandSlideEditPanel extends React.PureComponent<BrandSlideEditPanelProps & BrandSlideEditPanelDispatchProps>
{
    public componentWillUnmount()
    {
        this.props.handleComponentWillUnmount();
    }

    public render()
    {
        return (
            <div className="brandSlideEditPanel">
                <div className="showBrandSlideToggle">
                    <span className="brandSlideEditorToggleTitle">Show Brand Slide in my post</span>
                    <Switch id="brandSlideSwitch"
                            className="settingSwitch"
                            aria-label="brand slide toggle"
                            name="brandSlideToggle"
                            checked={this.props.showBrandSlideInMixModel}
                            onChange={this.props.handleToggleShowBrandSlide}
                    />
                </div>
                <Divider className="divider"/>
                <div className={classNames( "editControls", { disabled: !this.props.showBrandSlideInMixModel } )}>
                    <div className="logoSection">
                        <BrandSlideRecentLogosSummaryContainer/>
                    </div>
                    <div className="taglineSection">
                        <h3 className="brandSlideEditorTitle">Tagline</h3>
                        <TextPickerField identifier={BRAND_SLIDE_TAGLINE_TEXT_FIELD_NAME}
                                         value={this.props.tagline}
                                         label={null}
                                         placeholder={null}
                                         onFieldChanged={this.props.onFieldChanged}
                                         type={BRAND_SLIDE_TAGLINE_TEXT_FIELD_NAME}/>
                    </div>
                    <div className="contactSection">
                        <h3 className="brandSlideEditorTitle">Contact info</h3>
                        <TextPickerField identifier={BRAND_SLIDE_CONTACT_TEXT_FIELD_NAME}
                                         value={this.props.contact}
                                         label={null}
                                         placeholder={null}
                                         onFieldChanged={this.props.onFieldChanged}
                                         type={BRAND_SLIDE_CONTACT_TEXT_FIELD_NAME}
                                         rows={3}/>
                    </div>
                    <div className="colorSection">
                        <BrandSlideColorPickerContainer/>
                    </div>
                    <div className="fontSection">
                        <BrandSlideFontPickerContainer/>
                    </div>
                </div>
            </div>
        );
    }
}
