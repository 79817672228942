import { Action } from "redux-actions";
import { difference, get, keys, map, omitBy, union } from "lodash";
import { schema } from "normalizr";
import { createDefaultDataLookup } from "../ducks/dataLookup";
import { DataLookup } from "../_types";

export function updateLookupWithAction<T, S extends DataLookup<number, T>>( dataSchema: schema.Entity,
                                                                            action: Action<any>,
                                                                            state: S,
                                                                            preservePrevious?: boolean ): S
{
    const newData: Record<string, T> = get( action, `payload.entities.${dataSchema.key}` );
    return updateLookup( newData, state, preservePrevious );
}

export function updateLookupWithActionForStringIds<T, S extends DataLookup<any, T>>( dataSchema: schema.Entity,
                                                                                     action: Action<any>,
                                                                                     state: S,
                                                                                     preservePrevious?: boolean ): S
{
    const newData: Record<string, T> = get( action, `payload.entities.${dataSchema.key}` );
    return updateLookupForStringIds( newData, state, preservePrevious );
}

export function updateLookupWithNormalizrDataForStringIds<T, S extends DataLookup<any, T>>( dataSchema: schema.Entity,
                                                                                            data: NormalizrData,
                                                                                            state: S,
                                                                                            preservePrevious?: boolean ): S
{
    const newData: Record<string, T> = get( data, `entities.${dataSchema.key}` );
    return updateLookupForStringIds( newData, state, preservePrevious );
}

export function updateLookupWithData<T, S extends DataLookup<any, T>>( dataSchema: schema.Entity,
                                                                       data: NormalizrData,
                                                                       state: S,
                                                                       preservePrevious?: boolean ): S
{
    const newData: Record<string, T> = get( data, `entities.${dataSchema.key}` );
    return updateLookup( newData, state, preservePrevious );
}

export function updateLookup<T, S extends DataLookup<any, T>>( newData: Record<string, T>,
                                                               state: S,
                                                               preservePrevious?: boolean ): S
{
    return updateLookupWithOptionalIdConversion( newData, state, preservePrevious, true );
}

export function updateLookupForStringIds<T, S extends DataLookup<any, T>>( newData: Record<string, T>,
                                                                           state: S,
                                                                           preservePrevious?: boolean ): S
{
    return updateLookupWithOptionalIdConversion( newData, state, preservePrevious, false );
}

export function updateLookupWithOptionalIdConversion<T, S extends DataLookup<any, T>>( newData: Record<string, T>,
                                                                                       state: S,
                                                                                       preservePrevious: boolean,
                                                                                       convertIdsToNumbers: boolean ): S
{
    if ( newData )
    {
        const newDataIdsWithoutConversion = keys( newData );
        const newDataIds = convertIdsToNumbers ? newDataIdsWithoutConversion.map( Number ) : newDataIdsWithoutConversion;
        const idToObj = Object.assign( preservePrevious ? { ...state.idToObj } : {}, newData );
        const ids = preservePrevious ? union( newDataIds, state.ids ) : newDataIds;

        return Object.assign( {}, state, {
            idToObj,
            ids,
        } );
    }
    else
    {
        if ( preservePrevious )
        {
            return state;
        }

        return Object.assign( {}, state, createDefaultDataLookup() );
    }
}

export function deleteFromLookup<S extends DataLookup<any, any>>( state: S, removedId: number )
{
    const { ids, idToObj } = state;
    return Object.assign( {}, state, {
        ids: difference( ids, [removedId] ),
        idToObj: omitBy( idToObj, ( value, key ) => key === String( removedId ) ),
    } );
}

// Loosely copied from lodash.
type ArrayIterator<T, TResult> = ( value: T, index?: number, collection?: T[] ) => TResult;

// conditionalMap is loosely based on the definition of map in lodash.
export function conditionalMap<T>( collection: T[] | null | undefined, iteratee: ArrayIterator<T, T>, predicate: ArrayIterator<T, boolean> ): T[]
{
    return map( collection, ( value: T, index: number ): T =>
    {
        if ( value && predicate( value, index, collection ) )
        {
            return iteratee( value, index, collection );
        }
        else
        {
            return value;
        }
    } );
}
