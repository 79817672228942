import { connect } from "react-redux";
import { getDesignElementProperties } from "../../../ducks/ui";
import { StoreState } from "../../../_types";
import { ColorTextChoicePanel, ColorTextChoicePanelProps } from "../ColorTextChoicePanel";

const mapStateToProps = ( storeState: StoreState ): ColorTextChoicePanelProps =>
{
    return {
        designElementProperties: getDesignElementProperties( storeState ),
    };
};

export default connect(
    mapStateToProps,
)( ColorTextChoicePanel );
