import { Action } from "redux-actions";
import {
    EPIDEMIC_SOUND_TRACK_EXPIRATION_WINDOW_MS,
    epidemicSoundTracksSchema,
    musicHelper,
    ReducerCreator,
    updateLookupForStringIds,
    updateLookupWithActionForStringIds,
} from "../helpers";
import { createDefaultDataLookup } from "./dataLookup";
import { EpidemicSoundCatalogState, Music, Post, StoreState } from "../_types";
import { epidemicSoundCatalogActions } from "../actions/epidemicSoundCatalog.actions";
import { includes, isNil, map, now, omit, without } from "lodash";
import { EpidemicSoundTrack, MUSIC_TYPE_EPIDEMIC_SOUND } from "../_types/api";
import { mixModelActions } from "../actions";
import { getMusicId, safeParseData } from "./mixModel";

const defaultState: EpidemicSoundCatalogState = {
    ...createDefaultDataLookup(),
    freeTrackIds: [],
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( epidemicSoundCatalogActions.loadTracksSuccess, updateEpidemicSoundData );
reducerCreator.addAction( mixModelActions.importPostDataFromUserPost, handleImportPostDataFromUserPost );
reducerCreator.addAction( mixModelActions.importPostDataFromCuratedPost, handleImportPostDataFromCuratedPost );
reducerCreator.addAction( mixModelActions.resumeDraft, handleResumeDraft );
reducerCreator.addAction( epidemicSoundCatalogActions.clearCatalog, handleClearCatalog );
reducerCreator.addAction( epidemicSoundCatalogActions.clearSoundTrack, handleClearSoundTrack );
reducerCreator.addAction( epidemicSoundCatalogActions.freeTrackIdsSet, handleFreeTrackIdsSet );

export default reducerCreator.createReducer();

function handleFreeTrackIdsSet( state: EpidemicSoundCatalogState, action: Action<string[]> ): EpidemicSoundCatalogState
{
    return {
        ...state,
        freeTrackIds: action.payload,
    };
}

function updateEpidemicSoundData( state: EpidemicSoundCatalogState, action: Action<NormalizrData> ): EpidemicSoundCatalogState
{
    return updateLookupWithActionForStringIds( epidemicSoundTracksSchema, action, state, true );
}

export const getFreeEpidemicSoundTracks = ( state: StoreState ) =>
{
    const freeTrackIds = state.epidemicSoundCatalog.freeTrackIds;
    return map( freeTrackIds, ( id ) =>
    {
        return state.epidemicSoundCatalog.idToObj[id];
    } );
};

export const getAllEpidemicSoundTracks = ( state: StoreState ) =>
{
    return map( state.epidemicSoundCatalog.ids, ( id ) =>
    {
        return state.epidemicSoundCatalog.idToObj[id];
    } );
};

export function getEpidemicSoundTrackById( state: StoreState, epidemicId: string ): EpidemicSoundTrack
{
    if ( hasEpidemicSoundTrackById( state, epidemicId ) )
    {
        return state.epidemicSoundCatalog.idToObj[epidemicId];
    }
    else
    {
        return null;
    }
}

export function hasEpidemicSoundTrackById( state: StoreState, epidemicId: string ): boolean
{
    return includes( state.epidemicSoundCatalog.ids, epidemicId );
}

export function getEpidemicSoundTrackByIdAsMusic( state: StoreState, epidemicId: string ): Music
{
    const epidemicSoundTrackWithUrl = getEpidemicSoundTrackById( state, epidemicId );

    if ( !isNil( epidemicSoundTrackWithUrl ) )
    {
        return {
            id: epidemicSoundTrackWithUrl.epidemic_id,
            display_name: epidemicSoundTrackWithUrl.display_name,
            audio_url: epidemicSoundTrackWithUrl.url,
            type: MUSIC_TYPE_EPIDEMIC_SOUND,
            metadata: epidemicSoundTrackWithUrl,
        };
    }
    else
    {
        return null;
    }
}

function handleClearCatalog( state: EpidemicSoundCatalogState ): EpidemicSoundCatalogState
{
    return { ...defaultState };
}

function handleClearSoundTrack( state: EpidemicSoundCatalogState, action: Action<string> ): EpidemicSoundCatalogState
{
    const id = action.payload;

    return {
        ...state,
        ids: without( state.ids, id ),
        idToObj: omit( state.idToObj, id ),
    };
}

function handleImportPostDataFromUserPost( state: EpidemicSoundCatalogState, action: Action<Post> ): EpidemicSoundCatalogState
{
    return handleImportEpidemicSoundFromPostData( state, action );
}

function handleImportPostDataFromCuratedPost( state: EpidemicSoundCatalogState, action: Action<Post> ): EpidemicSoundCatalogState
{
    return handleImportEpidemicSoundFromPostData( state, action );
}

function handleResumeDraft( state: EpidemicSoundCatalogState, action: Action<Post> ): EpidemicSoundCatalogState
{
    return handleImportEpidemicSoundFromPostData( state, action );
}

function handleImportEpidemicSoundFromPostData( state: EpidemicSoundCatalogState, action: Action<Post> ): EpidemicSoundCatalogState
{
    const postData: Post = action.payload;
    const musicSettingsData: Music = safeParseData( postData.music_settings_data );
    if ( musicHelper.isMusicTypeEpidemicSound( musicSettingsData.type ) )
    {
        const epidemicSoundTrack: EpidemicSoundTrack = musicSettingsData.metadata as EpidemicSoundTrack;
        const epidemicSoundTrackPayload = { [epidemicSoundTrack.epidemic_id]: epidemicSoundTrack };
        return updateLookupForStringIds( epidemicSoundTrackPayload, state, true );
    }
    return {
        ...state,
    };
}

export function isEpidemicSoundTrackExpired( soundTrack: EpidemicSoundTrack, currentPosixTimestampMs: number = now() ): boolean
{
    if ( isNil( soundTrack.url ) || isNil( soundTrack.expires ) )
    {
        return true;
    }
    else
    {
        const adjustedSoundTrackExpirationMs = new Date( soundTrack.expires ).getTime() - EPIDEMIC_SOUND_TRACK_EXPIRATION_WINDOW_MS;
        return currentPosixTimestampMs >= adjustedSoundTrackExpirationMs;
    }
}

export function isEpidemicSoundTrackReady( state: StoreState, soundTrackId: string ): boolean
{
    const soundTrack = getEpidemicSoundTrackById( state, soundTrackId );

    if ( isNil( soundTrack ) )
    {
        return false;
    }
    else
    {
        return !isEpidemicSoundTrackExpired( soundTrack );
    }
}

export const getEpidemicSoundTrackName = ( state: StoreState ) =>
{
    const epidemicSoundCatalog = state.epidemicSoundCatalog;
    const musicId = getMusicId( state );
    const trackDetails = epidemicSoundCatalog && musicId && epidemicSoundCatalog.idToObj[musicId];

    return trackDetails && trackDetails.display_name;
};
