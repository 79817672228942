import * as React from "react";
import { BusinessType } from "../_types";
import BusinessTypeFilterButtonContainer from "./homepages/containers/BusinessTypeFilterButton.container";
import Button from "react-md/lib/Buttons/Button";

export interface BusinessTypeFilterRowProps
{
    businessTypes?: BusinessType[];
    selectedBusinessType?: BusinessType;
    showAllBusinessTypes?: boolean;
}

export interface BusinessTypeFilterRowDispatchProps
{
    handleShowAllClicked?: () => void;
}

export class BusinessTypeFilterRow extends React.PureComponent<BusinessTypeFilterRowProps & BusinessTypeFilterRowDispatchProps>
{
    public render()
    {
        return (
            <div className="businessTypeFilterRow">
                <h3 className="businessTypeFilterTitle">Show templates for: </h3>
                {this.getBusinessTypeFilter()}
            </div>
        );
    }

    private getShowAllButtonText = () =>
    {
        return this.props.showAllBusinessTypes ? "Show Less" : "Show All";
    }

    private getBusinessTypeFilter = () =>
    {
        const alphabetizedBusinessTypes = this.props.businessTypes.sort( ( a, b ) => (a.name > b.name) ? 1 : -1 );
        if ( this.props.showAllBusinessTypes )
        {
            return (
                 <div className="businessTypeFilterButtonWrapper">
                    <BusinessTypeFilterButtonContainer key={this.props.selectedBusinessType.id} businessType={this.props.selectedBusinessType}/>
                    {
                        alphabetizedBusinessTypes.map( ( businessType ) =>
                        {
                            if ( businessType !== this.props.selectedBusinessType )
                            {
                                return <BusinessTypeFilterButtonContainer key={businessType.id} businessType={businessType}/>;
                            }
                        } )
                    }
                    <Button flat onClick={this.props.handleShowAllClicked} className="showAllButton">
                        {this.getShowAllButtonText()}</Button>
                </div>
            );
        }
        else
        {
            return (
                <div className="businessTypeFilterButtonWrapper">
                    <BusinessTypeFilterButtonContainer key={this.props.selectedBusinessType.id} businessType={this.props.selectedBusinessType}/>
                    {
                        alphabetizedBusinessTypes.map( ( businessType ) =>
                        {
                            if ( this.isMostPopularBusinessType( businessType ) )
                            {
                                return <BusinessTypeFilterButtonContainer key={businessType.id} businessType={businessType}/>;
                            }
                        } )
                    }
                    <Button flat onClick={this.props.handleShowAllClicked} className="showAllButton">
                        {this.getShowAllButtonText()}</Button>
                </div>
            )
                ;

        }
    }

    private isMostPopularBusinessType = ( businessType: BusinessType ) =>
    {
        if ( this.props.selectedBusinessType === businessType )
        {
            return false;
        }
        else if ( businessType.name === "Real Estate" || businessType.name === "Professional Services" || businessType.name === "General"
                  || businessType.name === "Food & Beverage" )
        {
            return true;
        }
    }
}
