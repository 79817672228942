import * as React from "react";
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import TextField from "react-md/lib/TextFields/TextField";
import { UserState } from "../../_types";
import { PRIMARY_TEXT_FIELD_NAME } from "../../_types/api";
import Button from "react-md/lib/Buttons/Button";
import { INVALID_EMAIL_ADDRESS, validateEmail } from "../../helpers";

export interface NeedHelpSubPageProps
{
    user: UserState;
}

export interface NeedHelpSubPageDispatchProps
{
    handleSubmitTicket: ( emailAddress, description ) => void;
}

interface NeedHelpSubPageState
{
    email: string;
    description: string;
    emailError: string;
    alreadySubmitted: boolean;
}

/* tslint:disable:max-line-length */
export default class NeedHelpSubPage extends React.PureComponent<NeedHelpSubPageProps & NeedHelpSubPageDispatchProps, NeedHelpSubPageState>
{
    constructor( props )
    {
        super( props );
        this.state = {
            email: props.user.email,
            description: "",
            emailError: undefined,
            alreadySubmitted: false,
        };
    }

    public render()
    {
        return (
            <div className="needHelpSubPage">
                <Grid>
                    <Cell size={12}>
                        <h1>Submit a ticket for help</h1>
                    </Cell>
                    <Cell size={12}>
                        <h3>Email Address</h3>
                        <TextField
                            className="emailTextField"
                            id={PRIMARY_TEXT_FIELD_NAME}
                            defaultValue={this.props.user.valid_email ? this.props.user.valid_email : ""}
                            placeholder="email address required"
                            fullWidth={true}
                            required={true}
                            error={this.state.emailError ? true : undefined}
                            errorText={this.state.emailError}
                            onChange={( content ) =>
                            {
                                this.setState( {
                                    ...this.state,
                                    email: content.toString(),
                                } );
                            }}
                        />
                    </Cell>
                    <Cell size={12}>
                        <h3>Description</h3>
                        <TextField
                            className="descriptionTextField"
                            id="floating-multiline"
                            floating
                            placeholder="description required"
                            required={true}
                            rows={8}
                            maxRows={8}
                            fullWidth={false}
                            onChange={( content ) =>
                            {
                                this.setState( {
                                    ...this.state,
                                    description: content.toString(),
                                } );
                            }}
                        />
                    </Cell>
                    <Cell size={12}>
                        <Button
                            flat
                            primary
                            swapTheming
                            disabled={this.state.description.trim().length === 0 || this.state.email.length === 0 || this.state.alreadySubmitted}
                            onClick={!this.state.alreadySubmitted ? this.submitTicket : null}>Submit Ticket
                        </Button>
                    </Cell>
                </Grid>
            </div>
        );
    }

    private submitTicket = () =>
    {
        const theEmail = this.state.email;
        if ( validateEmail( theEmail ) )
        {
            this.setState( {
                alreadySubmitted: true,
            } );
            const theDescription = this.state.description;
            this.props.handleSubmitTicket( theEmail, theDescription );
        }
        else
        {
            this.setState( {
                emailError: INVALID_EMAIL_ADDRESS,
            } );
        }
    }
}
