import * as React from "react";

export interface BusinessTypeIconProps {
    name: string;
    icon_url: string;
}

export const BusinessTypeIcon: React.FC<BusinessTypeIconProps> = ( props ) =>
{
    const altText = props.name ? "Business Icon for " + props.name : "Business Type Icon";
    return (<img alt={altText} className={"businessTypeIcon"} src={props.icon_url}/>);
};
