import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../_types";
import { BusinessName, BusinessNameDispatchProps, BusinessNameProps } from "../BusinessName";
import { uiActions } from "../../actions";
import { getBusinessName } from "../../ducks/ui";

const mapStateToProps = ( storeState: StoreState, ownProps: BusinessNameProps ): BusinessNameProps =>
{
    return {
        ...ownProps,
        businessName: getBusinessName( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): BusinessNameDispatchProps =>
{
    return {
        updateBusinessName: ( businessName: string ) =>
        {
            dispatch( uiActions.updateBusinessInfo( { name: businessName } ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( BusinessName );
