import * as React from "react";
import { EpidemicAudioTracksList, EpidemicAudioTracksListDispatchProps, EpidemicAudioTracksListProps } from "./EpidemicAudioTracksList";
import * as InfiniteScroll from "react-infinite-scroller";
import CircularProgress from "react-md/lib/Progress/CircularProgress";

export interface EpidemicAudioTracksListWithInfiniteScrollProps extends EpidemicAudioTracksListProps, EpidemicAudioTracksListDispatchProps
{
    isLoadingEpidemicSoundUserCollectionDetailsInProgress: boolean;
    activeCollectionId: string;
    nextUrl: string;
}

export interface EpidemicAudioTracksListWithInfiniteScrollDispatchProps
{
    loadMore: ( collectionId: string, nextUrl: string ) => void;
    updateLightDialogTitle();
}

export class EpidemicAudioTracksListWithInfiniteScroll extends React.PureComponent<EpidemicAudioTracksListWithInfiniteScrollProps
    & EpidemicAudioTracksListWithInfiniteScrollDispatchProps>
{
    public componentDidMount()
    {
        this.props.updateLightDialogTitle();
    }
    public render()
    {
        const listWrapperStyle = { height: window.innerHeight * 0.7, overflow: "auto" };
        return (
            <div className="dialogListWrapper" style={listWrapperStyle}>
                <InfiniteScroll
                    loadMore={this.loadMoreTracks}
                    hasMore={!!this.props.nextUrl}
                    loader={<CircularProgress key="0" className="spinner small" id="spinner"/>}
                    useWindow={false}>
                    <EpidemicAudioTracksList musicList={this.props.musicList} handleMusicClick={this.props.handleMusicClick}/>
                </InfiniteScroll>
            </div>
        );
    }

    private loadMoreTracks = () =>
    {
        if ( !this.props.isLoadingEpidemicSoundUserCollectionDetailsInProgress )
        {
            this.props.loadMore( this.props.activeCollectionId, this.props.nextUrl );
        }
    }
}
