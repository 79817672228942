import * as React from "react";
import { TeamInvite, TeamMember } from "../../../_types";
import { IconButton } from "../../IconButton";
import { TrashIcon } from "../../svgs/TrashIcon";

export interface DeleteTeamItemButtonProps
{
    item: TeamMember | TeamInvite;
}

export interface DeleteTeamItemButtonDispatchProps
{
    handleDeleteItem( item: TeamMember | TeamInvite );
}

export class DeleteTeamItemButton extends React.PureComponent<DeleteTeamItemButtonProps & DeleteTeamItemButtonDispatchProps>
{
    public render()
    {
        return (
            <IconButton onClick={this.onButtonClicked} tooltipLabel={<>Remove team member</>}>
                <TrashIcon/>
            </IconButton>
        );
    }

    private onButtonClicked = () =>
    {
        this.props.handleDeleteItem( this.props.item );
    }
}
