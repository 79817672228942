import { StoreState, TeamInvite, TeamMember } from "../../../../_types";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { modalServices, userBusinessServices } from "../../../../services";
import { LightboxDialogIdentifierForKey } from "../../../../helpers";
import { ResendTeamItemButton, ResendTeamItemButtonDispatchProps, ResendTeamItemButtonProps } from "../ResendTeamItemButton";

interface ResendTeamInvitationButtonContainerProps
{
    invitation: TeamInvite;
}

const mapStateToProps = ( storeState: StoreState, ownProps: ResendTeamInvitationButtonContainerProps ): ResendTeamItemButtonProps =>
{
    return {
        item: ownProps.invitation,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): ResendTeamItemButtonDispatchProps =>
{
    return {
        handleResendItem( item: TeamMember | TeamInvite )
        {
            const invite = item as TeamInvite;

            dispatch( modalServices.openLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.RESEND_TEAM_INVITES,
                showCancelX: true,
                modal: true,
                confirmLabel: "Resend invite",
                width: 550,
                title: "Resend team member invite?",
                content: `This invitation to join your team will be sent to ${item.email}.`,
                onSuccess: () =>
                {
                    dispatch( userBusinessServices.sendTeamInvites( [invite.email] ) );
                },
            } ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( ResendTeamItemButton );
