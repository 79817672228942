import { ActionCreator } from "../helpers";
import { createAction } from "redux-actions";
import { StockMediaSearchType } from "../_types";

const actionCreator = new ActionCreator( "STOCK_MEDIA" );

const stockMediaActionsTypes = {
    photosLoaded: actionCreator.generateTypes( "PHOTOS_LOADED" ),
    videosLoaded: actionCreator.generateTypes( "VIDEOS_LOADED" ),
    resultsCleared: actionCreator.generateTypes( "RESULTS_CLEARED" ),
    stockMediaSearchType: {
        changed: actionCreator.generateType( "STOCK_MEDIA_SEARCH_TYPE", "CHANGED" ),
    },
};

export const stockMediaActions = {
    searchPhotosLoaded: createAction<StockMediaAPIData>( stockMediaActionsTypes.photosLoaded.success ),
    searchVideosLoaded: createAction<StockMediaAPIData>( stockMediaActionsTypes.videosLoaded.success ),
    searchPhotosFailed: createAction( stockMediaActionsTypes.photosLoaded.failure ),
    searchVideosFailed: createAction( stockMediaActionsTypes.videosLoaded.failure ),
    searchResultsCleared: createAction( stockMediaActionsTypes.resultsCleared.success ),
    changeStockMediaSearchType: createAction<StockMediaSearchType>( stockMediaActionsTypes.stockMediaSearchType.changed ),
};
