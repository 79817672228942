import { connect } from "react-redux";
import { Dispatch } from "redux";
import { store } from "../../store";
import { modalServices, postServices } from "../../services";
import { getDisplayShareImage, getImageUrl, getShareCachebuster, getVideoUrl, isAnimationOutputTypeFromState } from "../../ducks/shareModel";
import { getRecordError, getRecordProgress } from "../../ducks/ui";
import { ERROR_TITLE_SORRY, errorReporting } from "../../helpers";
import { RecordingVideoElement, RecordingVideoElementDispatchProps, RecordingVideoElementProps } from "../RecordingVideoElement";
import { getDesignAspectRatio, getPostId } from "../../ducks/mixModel";
import { getUserAuthToken, isCollabraUser } from "../../ducks/user";
import { uiActions } from "../../actions";
import { StoreState } from "../../_types";

interface RecordingVideoElementContainerProps
{
    shouldRenderStaticPreview?: boolean;
}

const mapStateToProps = ( storeState: StoreState, ownProps: RecordingVideoElementContainerProps ): RecordingVideoElementProps =>
{
    return {
        isAnimationOutputType: isAnimationOutputTypeFromState( storeState ),
        videoUrl: !ownProps.shouldRenderStaticPreview && getVideoUrl( storeState, true ),
        imageUrl: getDisplayShareImage( storeState, true ),
        aspectRatio: getDesignAspectRatio( storeState ),
        recordProgress: getRecordProgress( storeState ),
        recordError: getRecordError( storeState ),
        shouldRenderStaticPreview: ownProps.shouldRenderStaticPreview,
        shouldRenderForCollabraPreview: isCollabraUser( storeState),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): RecordingVideoElementDispatchProps =>
{
    return {
        recordVideo: () =>
        {
            dispatch( uiActions.mixModelRecordError( false ) );
            dispatch( uiActions.mixModelRecordProgress( undefined ) );

            const state = store.getState();
            const authToken = getUserAuthToken( state );
            const postId = getPostId( state );
            const imageUrl = getImageUrl( state );

            if ( authToken && postId && imageUrl )
            {
                const recordingVersion = getShareCachebuster( state );
                dispatch( postServices.recordDesignAndUpdateProgress( recordingVersion ) );
            }
            else
            {
                handleMissingArguments( !!authToken, !!postId, !!imageUrl, dispatch );
            }

        },
    };
};

function handleMissingArguments( authTokenPresent: boolean, postIdPresent: boolean, imageUrlPresent: boolean, dispatch: Dispatch<StoreState> )
{
    const errorMessage = buildErrorMessageForMissingRecordingParameters( authTokenPresent, postIdPresent, imageUrlPresent );
    errorReporting.reportErrorToSentry( errorMessage );

    const userErrorMessage = `Your video could not be recorded.

Please try going back to the Customize screen, double checking your post details, and then returning here.`;

    dispatch( modalServices.openErrorDialog( ERROR_TITLE_SORRY, userErrorMessage, true ) );
    dispatch( uiActions.mixModelRecordError( true ) );
}

function buildErrorMessageForMissingRecordingParameters( authTokenPresent: boolean, postIdPresent: boolean, imageUrlPresent: boolean )
{
    const missingParameters = authTokenPresent ? [] : ["auth_token"];
    if ( !postIdPresent )
    {
        missingParameters.push( "post_id" );
    }

    if ( !imageUrlPresent )
    {
        missingParameters.push( "image_url" );
    }
    return "Missing or invalid recording parameters: " + missingParameters.join();
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( RecordingVideoElement );
