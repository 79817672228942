import { CancelWinBackConfirm, CancelWinBackConfirmDispatchProps } from "../CancelWinBackConfirm";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../_types";
import { URLS } from "../../helpers";

export interface CancelWinBackConfirmDispatchContainerProps
{
    requestCancel();
    requestKeepSubscription();
}

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>,
                             ownProps: CancelWinBackConfirmDispatchContainerProps ): CancelWinBackConfirmDispatchProps =>
{
    return {
        ...ownProps,
        handleFAQ: () =>
        {
            window.open( URLS.HelpCancel );
        },
    };
};

export default connect(
    mapDispatchToProps,
)( CancelWinBackConfirm );
