import { connect } from "react-redux";
import { Dispatch } from "redux";
import { uiActions } from "../../../actions";
import { AddBusinessName, AddBusinessNameContainerProps, AddBusinessNameDispatchProps, AddBusinessNameProps } from "../AddBusinessName";
import { StoreState } from "../../../_types";
import { store } from "../../../store";
import { getEditedBusinessInfo, isBusinessNameChanged } from "../../../ducks/ui";
import { userBusinessServices } from "../../../services";

const mapStateToProps = ( storeState: StoreState, ownProps: AddBusinessNameContainerProps ): AddBusinessNameProps =>
{
    return {
        ...ownProps,
        isBrandNameChanged: isBusinessNameChanged( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): AddBusinessNameDispatchProps =>
{
    return {
        onFieldChanged: ( name: string ) =>
        {
            dispatch( uiActions.updateBusinessInfo( { name } ) );
        },
        handleBrandNameUpdate: () =>
        {
            const state = store.getState();
            const editedBusinessInfo = getEditedBusinessInfo( state );

            dispatch( userBusinessServices.update( editedBusinessInfo ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( AddBusinessName );
