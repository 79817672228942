import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../_types";
import { CustomizeTipBanner, CustomizeTipBannerDispatchProps, CustomizeTipBannerProps } from "../CustomizeTipBanner";
import { CUSTOMIZE_TIP_BANNER_TYPES, getCustomizeTipsBanners, getEditorType } from "../../ducks/ui";
import { EDITOR_TYPES } from "../../helpers";
import { uiActions } from "../../actions";
import { getPostId } from "../../ducks/mixModel";
import { store } from "../../store";

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): CustomizeTipBannerDispatchProps =>
{
    return {
        onDismissClicked: ( tipBannerType: string ) =>
        {
            dispatch( uiActions.dismissCustomizeTip( tipBannerType ) );
        },
        onTipBannerShown: () =>
        {
            const postId = getPostId( store.getState() );
            dispatch( uiActions.registerCustomizeTipShown( postId ) );
        },
    };
};

const mapStateToProps = ( storeState: StoreState ): CustomizeTipBannerProps =>
{
    let tipBannerProps;
    let newTipBannerType;
    const editorType = getEditorType( storeState );
    const dismissedTipBanners = getCustomizeTipsBanners( storeState );
    switch ( editorType )
    {
        case EDITOR_TYPES.TEMPLATES_CONTROL:
            tipBannerProps = {
                newTitle: "Tip: Simplicity is key",
                newMessage: "When creating an ad focus on one business goal: brand awareness, a new product or service, or an announcement.",
            };
            newTipBannerType = CUSTOMIZE_TIP_BANNER_TYPES.templatesSimple;
            break;
        case EDITOR_TYPES.MEDIA_CONTROL:
            tipBannerProps = {
                newTitle: "Tip: Use a high-resolution image",
                newMessage: "People scroll through Facebook and Instagram quickly. Show off your product, service or brand.",
            };
            newTipBannerType = CUSTOMIZE_TIP_BANNER_TYPES.mediaHiRez;
            break;
        case EDITOR_TYPES.TEXT_CONTROL:
            tipBannerProps = {
                newTitle: "Tip: Avoid too much text on the image itself",
                newMessage: "We've found that images with less than 20% text perform better.",
            };
            newTipBannerType = CUSTOMIZE_TIP_BANNER_TYPES.textWordy;
            break;
        case EDITOR_TYPES.ASPECT_RATIO_CONTROL:
            tipBannerProps = {
                newTitle: "Tip: Square for the win",
                newMessage: "Facebook suggests using a square sized ad to ensure it looks its best across all placements.",
            };
            newTipBannerType = CUSTOMIZE_TIP_BANNER_TYPES.aspectSquare;
            break;
        default:
            return { newTitle: "", newMessage: "", dismissedTipBanners };
    }

    return { ...tipBannerProps, dismissedTipBanners, newTipBannerType };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( CustomizeTipBanner );
