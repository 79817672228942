import { Dispatch } from "redux";
import { businessTypesServices, catalogServices, fontCatalogServices, musicCatalogServices, pricingServices, userServices } from "./";
import { StoreState } from "../_types";

export const startupServices = {
    initialize,
    initializeUser,
};

function initializeUser()
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        try
        {
            await dispatch( userServices.loadMe() );
        }
        catch (error)
        {
            // TODO Handle this error properly
        }
    };
}

function initialize()
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        try
        {
            window.catalogLoadPromise = dispatch( catalogServices.loadCatalog() );
            dispatch( musicCatalogServices.load() );
            dispatch( fontCatalogServices.load() );
            dispatch( businessTypesServices.load() );
            dispatch( pricingServices.load() );
        }
        catch (error)
        {
            // TODO Handle this error properly
        }
    };
}
