import { connect } from "react-redux";
import { Dispatch } from "redux";
import { CoachMarkContentFlow, CoachMarkNameKey, CoachMarkPosition, StoreState } from "../../../_types";
import { uiActions } from "../../../actions";
import { CoachMark, CoachMarkDispatchProps, CoachMarkProps } from "../../CoachMark";
import { COACH_MARK_NAME_KEY_MORE_LAYOUTS } from "../../../_types/api";
import { MORE_LAYOUTS_BUTTON_CLASS } from "../../../helpers";

const mapStateToProps = ( storeState: StoreState ): CoachMarkProps =>
{
    return {
        name: COACH_MARK_NAME_KEY_MORE_LAYOUTS,
        message: "Find and try even more layouts here, along with different sizes",
        position: CoachMarkPosition.ABOVE,
        alignBubble: CoachMarkContentFlow.RIGHT,
        fixed: true,
        hideClose: true,
        darkMode: true,
        selector: "." + MORE_LAYOUTS_BUTTON_CLASS,
        dismissOnContentTap: true,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): CoachMarkDispatchProps =>
{
    return {
        onDismiss( coachMarkName: CoachMarkNameKey )
        {
            dispatch( uiActions.clearCoachMark( coachMarkName ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( CoachMark );
