import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../_types";
import { ContentSearchBar, ContentSearchBarDispatchProps, ContentSearchBarProps } from "../ContentSearchBar";
import { contentSearchServices } from "../../../services";
import { contentSearchActions, uiActions } from "../../../actions";
import { getContentSearchTerm, getContentSearchTotalMatches } from "../../../ducks/contentSearch";
import { isContentSearchSpinnerEnabled } from "../../../ducks/ui";
import { eventTracker } from "../../../helpers";
import { store } from "../../../store";

const mapStateToProps = ( state: StoreState ): ContentSearchBarProps =>
{
    return {
        lastRequestedSearchTerm: getContentSearchTerm( state ),
        isSearching: isContentSearchSpinnerEnabled( state ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): ContentSearchBarDispatchProps =>
{
    return {
        onSearchSubmitted: async ( searchTerm: string ) =>
        {
            // Should turning on the spinner be inside searchContent(), where the spinner is later turned off?
            dispatch( uiActions.contentSearchSpinnerEnabled( true ) );
            dispatch( contentSearchActions.searchResultsCleared() );
            await dispatch( contentSearchServices.searchContent( searchTerm ) );

            eventTracker.logContentSearched( searchTerm, getContentSearchTotalMatches( store.getState() ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( ContentSearchBar );
