import * as React from "react";
import FacebookLogin, { ReactFacebookFailureResponse } from "react-facebook-login";
import { facebookServices } from "../services";
import { log } from "util";
import { apptimizeVariables } from "../helpers/index";

const FACEBOOK_SCOPE = "email,public_profile";

export interface FacebookLoginButtonProps
{
    facebookAppId: string;
}

export interface FacebookLoginButtonDispatchProps
{
    onFacebookLoginSuccess: ( facebookId: string, facebookAccessToken: string, name: string ) => void;
    onFacebookLoginFailure: ( status: string ) => void;
}

export default class FacebookLoginButton extends React.PureComponent<FacebookLoginButtonProps & FacebookLoginButtonDispatchProps>
{

    public render()
    {
        return (
            <FacebookLogin
                appId={this.props.facebookAppId}
                autoLoad={false}
                disableMobileRedirect={true}
                reAuthenticate={true}
                textButton="Continue with Facebook"
                icon="fa-facebook-square"
                size="medium"
                containerStyle={{ width: "100%", textAlign: "center" }}
                scope={FACEBOOK_SCOPE}
                fields="name,email,picture"
                version={facebookServices.GRAPH_API_VERSION}
                callback={this.handleFacebookResponse}
                onFailure={this.handleFacebookError}
                isDisabled={this.isDisabled()}/>
        );
    }

    private isDisabled()
    {
        return apptimizeVariables.shouldDisableFacebookConnect();
    }

    private handleFacebookResponse = ( response ) =>
    {
        if ( response && response.id && response.accessToken )
        {
            this.props.onFacebookLoginSuccess( response.id, response.accessToken, response.name );
        }
    }

    private handleFacebookError = ( response: ReactFacebookFailureResponse ) =>
    {
        if ( response )
        {
            this.props.onFacebookLoginFailure( response.status );
        }
        log( `Logging error from FacebookLoginButton: handleFacebookError  response.status: ` + response.status );
    }
}
