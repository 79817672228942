import * as React from "react";
import Button from "react-md/lib/Buttons/Button";
import { EventData } from "../_types";
import classNames from "classnames";
import CircularProgress from "react-md/lib/Progress/CircularProgress";

export const LIST_INNER_WRAP_ID = "listInnerWrap";
export const LIST_ITEM_SELECTED_CLASS = "selected";

interface ListPickerProps
{
    children: React.ReactNode;
    moreLabel?: string;
    bottomTray?: JSX.Element;
    loading?: boolean;
    hasCustomListInnerWrap?: boolean;
    onMoreClicked?( e: EventData ): void;
}

export const ListPicker = React.forwardRef<HTMLDivElement, ListPickerProps>( ( props: ListPickerProps, ref ) =>
{
    const { moreLabel, onMoreClicked, bottomTray, loading } = props;
    return (
        <div ref={ref} className={classNames( "listPicker", { loading } )}>
            <CircularProgress className="spinner listPickerSpinner verticallyCentered" id="spinner"/>
            {!props.hasCustomListInnerWrap && <div id={LIST_INNER_WRAP_ID} className="list">
                {props.children}
            </div>}
            {props.hasCustomListInnerWrap && props.children}
            <div className="bottomTray">
                {bottomTray}
                {
                    moreLabel &&
                    <div className=" moreButtonContainer">
                        <Button primary flat swapTheming onClick={onMoreClicked} className=" large fullWidth">{moreLabel}</Button>
                    </div>
                }
            </div>
        </div>);
} );
