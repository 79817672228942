import * as React from "react";
import Cell from "react-md/lib/Grids/Cell";
import Grid from "react-md/lib/Grids/Grid";
import { browserUtils, urlUtils } from "../helpers";
import { InspireBackgroundOverlay } from "./InspireBackgroundOverlay";
import ForgotPasswordFormContainer from "./containers/ForgotPasswordForm.container";
import ConfirmFacebookDialog from "./containers/ConfirmFacebookDialog.container";
import BusinessTypeSelectionPageContainer from "./containers/BusinessTypeSelectionPage.container";
import GradientLoginFormContainer from "./containers/GradientLoginForm.container";
import GradientCreateAccountFormContainer from "./containers/GradientCreateAccountForm.container";
import { uiActions } from "../actions";
import { store } from "../store";

// This is implemented in state so that it is never persisted when refreshing.
export interface LoginPageState
{
    displayForm: LoginFormToShow;
}

export interface LoginPageProps
{
    hasLoggedInBefore: boolean;
    firstRun: boolean;
    showForgotPassword: boolean;
    hasSelectedBusinessType: boolean;
    shouldShowThirdPartyLoginError: boolean;
    thirdPartyLoginErrorMessage: string;
    doesUserHaveABusiness: boolean;
    isAcceptTeamInviteFlow: boolean;
}

export interface LoginPageDispatchProps
{
    resetErrors: () => void;
    clearSelectedBusinessInfo: () => void;
    trackInitialBusinessPickerDisplayed: () => void;
    trackInitialBusinessTypeSelected: () => void;
    finishCreatingAccount: () => void;
    finishLoggingIn: () => void;
    loadServices: () => void;
}

// Forms for the flow that DOES require selecting a biz type when creating an account.
const BUSINESS_TYPE_SELECTION_FORM = "businessTypes";

// Forms for gradient branded login form before create business
const GRADIENT_LOGIN_FORM_WITHOUT_BUSINESS_CREATION = "gradientLogin";
const GRADIENT_CREATE_ACCOUNT_FORM_WITHOUT_BUSINESS_CREATION = "gradientCreate";

const FORGOT_PASSWORD_FORM = "forgotPassword";
type LoginFormToShow =
    typeof FORGOT_PASSWORD_FORM
    | typeof BUSINESS_TYPE_SELECTION_FORM
    | typeof GRADIENT_LOGIN_FORM_WITHOUT_BUSINESS_CREATION
    | typeof GRADIENT_CREATE_ACCOUNT_FORM_WITHOUT_BUSINESS_CREATION;

export class LoginPage extends React.PureComponent<LoginPageProps & LoginPageDispatchProps, LoginPageState>
{
    constructor( props )
    {
        super( props );
        this.state = {
            displayForm: this.getDisplayForm( props.hasLoggedInBefore, props.firstRun, props.isAcceptTeamInviteFlow, props.showForgotPassword ),
        };
    }

    public componentDidMount(): void
    {
        const dispatch = store.dispatch;
        dispatch( uiActions.confirmFacebookModalClose() );

        this.props.loadServices();
    }

    public componentWillUnmount()
    {
        const dispatch = store.dispatch;
        dispatch( uiActions.confirmFacebookModalClose() );
    }

    public render()
    {
        return (
            <Grid noSpacing={true}>
                <Cell size={12}>
                    {this.createLoginFlow()}
                </Cell>
            </Grid>
        );
    }

    private handleBusinessTypeNext = () =>
    {
        this.props.trackInitialBusinessTypeSelected();
        this.props.finishCreatingAccount();
    }

    private createLoginFlow = () =>
    {
        if ( this.state.displayForm === BUSINESS_TYPE_SELECTION_FORM )
        {
            return (
                <InspireBackgroundOverlay>
                    <BusinessTypeSelectionPageContainer handleNextClicked={this.handleBusinessTypeNext}
                                                        handleClose={this.showLoginFormWithoutBusinessCreation}
                                                        trackBusinessPickerDisplayed={this.props.trackInitialBusinessPickerDisplayed}
                                                        shouldHideClose={true}
                                                        customHeaderContent={this.createBusinessTypesHeaderContent()}/>
                </InspireBackgroundOverlay>
            );
        }
        else
        {
            return (
                <div className="loginPage">
                    <InspireBackgroundOverlay>
                        {this.createForm()}
                    </InspireBackgroundOverlay>
                    <ConfirmFacebookDialog buttonNameForInstructions="Continue with Facebook"/>
                </div>
            );
        }
    }

    private getDisplayForm = ( hasLoggedInBefore: boolean,
                               firstRun: boolean,
                               acceptTeamInviteFlow: boolean,
                               showForgotPassword: boolean ): LoginFormToShow =>
    {
        if ( showForgotPassword )
        {
            return FORGOT_PASSWORD_FORM;
        }

        const showCreateAccountFlow = firstRun === true && hasLoggedInBefore !== true;

        if ( showCreateAccountFlow || acceptTeamInviteFlow )
        {
            return GRADIENT_CREATE_ACCOUNT_FORM_WITHOUT_BUSINESS_CREATION;
        }
        else
        {
            return GRADIENT_LOGIN_FORM_WITHOUT_BUSINESS_CREATION;
        }
    }

    private createForm = () =>
    {
        switch ( this.state.displayForm )
        {
            case GRADIENT_LOGIN_FORM_WITHOUT_BUSINESS_CREATION:
                return <GradientLoginFormContainer showCreateAccount={this.showCreateAccount}
                                                   showForgotPassword={this.showForgotPassword}/>;
            case GRADIENT_CREATE_ACCOUNT_FORM_WITHOUT_BUSINESS_CREATION:
                return <GradientCreateAccountFormContainer showLogin={this.showLoginFormWithoutBusinessCreation}
                                                           showBusinessTypeSelection={this.showBusinessTypeSelectionIfNecessary}/>;
            case FORGOT_PASSWORD_FORM:
                return <ForgotPasswordFormContainer
                    showLogin={urlUtils.hasAcceptTeamInviteParam() ? this.showLogin : this.showLoginFormWithoutBusinessCreation}/>;
        }
    }

    private showBusinessTypeSelectionIfNecessary = () =>
    {
        if ( this.props.doesUserHaveABusiness || browserUtils.isMobileBrowser() )
        {
            this.props.finishLoggingIn();
        }
        else
        {
            this.showBusinessTypeSelectionDialog();
        }
    }

    private showLogin = () => this.updateShownForm( GRADIENT_LOGIN_FORM_WITHOUT_BUSINESS_CREATION );

    private showCreateAccount = () => this.updateShownForm( GRADIENT_CREATE_ACCOUNT_FORM_WITHOUT_BUSINESS_CREATION );

    private showForgotPassword = () => this.updateShownForm( FORGOT_PASSWORD_FORM );

    private showLoginFormWithoutBusinessCreation = () => this.updateShownForm( GRADIENT_LOGIN_FORM_WITHOUT_BUSINESS_CREATION );

    private showBusinessTypeSelectionDialog = () => this.updateShownForm( BUSINESS_TYPE_SELECTION_FORM );

    private updateShownForm = ( form: LoginFormToShow ) =>
    {
        this.setState( { displayForm: form } );
        this.props.resetErrors();
    };

    private createBusinessTypesHeaderContent = () =>
    {
        return (
            <div className="headerContainer">
                <p className="headerText1">Show me ready-made templates for...</p>
                <p className="headerText2">Choose which business type represents you the best.<br/>You can always change it later.</p>
            </div>
        );
    }
}
