import { Dispatch } from "redux";
import { store } from "../store";
import {
    BRAND_CREATIVE_PAGE_URL,
    BRAND_USERS_PAGE_URL,
    BUSINESS_CREATED,
    ERROR_TITLE_SORRY,
    errorReporting,
    eventTracker,
    history,
    HOMEPAGE_URL,
    LightboxDialogIdentifierForKey,
    normalizeMeAPI,
    REMOVE_SELF_FROM_TEAM_MESSAGES,
    REMOVE_TEAM_MEMBER_MESSAGES,
    requests,
    s3Uploader,
    stringUtils,
    TEAM_INVITE_UNKNOWN_ERROR,
    TEAM_SENDING_INVITE_ERRORS,
    TEAMS_ACCEPTING_INVITE_ERRORS,
} from "../helpers";
import { doesCurrentBusinessSupportTeamFeatures, getAllBusinessIds, getBusinessById, getCurrentBusiness } from "../ducks/userBusiness";
import { getCurrentUserId, hasSeenShowYouAround } from "../ducks/user";
import { uiActions, userActions, userBusinessActions } from "../actions";
import { getCurrentDate, getSelectedDate, ProcessTrackingKey } from "../ducks/ui";
import { LOAD_POST_LIMIT, modalServices, postServices, userServices } from "./";
import { BrandLogo, MediaData, StoreState, UserBusiness } from "../_types";
import { AddMediaAPIResponse, RequestAddMediaAPI } from "../_types/api";
import { DesignInputDataParameters, getBrandSlideInputDataParameters } from "../ducks/mixModel";
import { find, first, includes, isEmpty, map } from "lodash";
import { teamDialogFactory } from "../components/teamDialogFactory";

export const userBusinessServices = {
    update,
    create,
    delete: _delete,
    updateInternalDataAfterBusinessChange,
    uploadFont,
    acceptTeamInvite,
    removeTeamMember,
    cancelInvitation,
    updateBrandSlide,
    loadBrandLogos,
    hideBrandLogos,
    displayInviteTeamsMembersDialog,
    sendTeamInvites,
};

const BUSINESS_URL_PREFIX = "/businesses";

const REQUEST_ADD_FILE_TO_BUSINESS_PATH = "/request_add_file";
const INVITE_TEAM_MEMBERS_PATH = "/invite_team_members";
const REMOVE_TEAM_MEMBER_PATH = "/remove_team_member";
const CANCEL_TEAM_MEMBER_PATH = "/cancel_team_member_invite";
const BRAND_LOGOS_PATH = "/brand_logos";
const HIDE_BRAND_LOGOS_PATH = "/hide_brand_logos";

function buildBusinessUrl( business: Partial<UserBusiness> )
{
    return BUSINESS_URL_PREFIX + "/" + business.id;
}

function buildAcceptInviteUrl( token: string )
{
    return BUSINESS_URL_PREFIX + "/accept_invitation/" + token;
}

function update( newData: Partial<UserBusiness> )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const state = store.getState();
        const currentBusiness = getCurrentBusiness( state );
        if ( !currentBusiness || newData.name === "" )
        {
            return;
        }

        dispatch( uiActions.updateBusinessInfo( newData ) );

        const previousBusinessTypeId = currentBusiness.business_type_id;
        const url = buildBusinessUrl( currentBusiness );
        const theBusinessAPIData: Partial<UserBusinessAPIData> = getBusinessAPIData( newData );

        dispatch( userBusinessActions.updateBusinessRequest() );
        return requests.patch<UserBusinessUpdateAPIData, MeAPIData>( url, { user_business: theBusinessAPIData } ).then(
            ( data ) =>
            {
                dispatch( userBusinessActions.updateBusinessSuccess() );

                const normalizedData = normalizeMeAPI( data );
                dispatch( userActions.meSuccess( normalizedData ) );

                if ( newData.business_type_id && newData.business_type_id !== previousBusinessTypeId )
                {
                    dispatch( uiActions.clearBusinessTypeFilter() );
                    dispatch( updateInternalDataAfterBusinessChange() );
                }
            },
            ( error: string ) =>
            {
                dispatch( userBusinessActions.updateBusinessFailure() );
                dispatch( userBusinessActions.updateFailure( error ) );
            },
        );
    };
}

function updateBrandSlide()
{
    const state = store.getState();
    const inputDataParameters: DesignInputDataParameters = getBrandSlideInputDataParameters( state );
    const templateInputData = inputDataParameters[0];
    const globalSetting = inputDataParameters[2];
    const showBusinessEndCardFlag = templateInputData.showEndCard === "false" ? false : !!templateInputData.showEndCard;
    const showLogo = templateInputData.endCardData.showLogo === "false" ? false : !!templateInputData.endCardData.showLogo;

    const newBrandSlideBusinessSettings: Partial<UserBusiness> = {
        contact: templateInputData.endCardData.contact,
        tagline: templateInputData.endCardData.tagline,
        logo_flag: showLogo,
        end_card_position_data: !!globalSetting.endCardLayoutPositionData ? JSON.stringify( globalSetting.endCardLayoutPositionData ) : null,
        show_business_end_card_flag: showBusinessEndCardFlag,
    };

    return update( newBrandSlideBusinessSettings );
}

function getBusinessAPIData( newData: Partial<UserBusiness> ): Partial<UserBusinessAPIData>
{
    const { social_network_accounts, business_type_name, team_members, team_invites, tempLogo, logos, ...businessData } = newData;
    return {
        ...businessData,
    };
}

function create( businessData: NewBusinessAPIData )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( uiActions.trackProcessAsRunning( ProcessTrackingKey.CREATING_BUSINESS ) );

        return requests.post<NewBusinessAPIData, CreateNewBusinessAPI>( BUSINESS_URL_PREFIX, businessData ).then(
            ( data ) =>
            {
                dispatch( uiActions.trackProcessAsStopped( ProcessTrackingKey.CREATING_BUSINESS ) );

                eventTracker.logEvent( BUSINESS_CREATED );
                const normalizedData = normalizeMeAPI( data );
                dispatch( userActions.meSuccess( normalizedData ) );

                const state = store.getState();
                const userBusinessData = { businessId: data.created_user_business.id, userId: getCurrentUserId( state ) };

                const userBusinessIds = getAllBusinessIds( state );
                if ( !includes( userBusinessIds, userBusinessData.businessId ) )
                {
                    userBusinessData.businessId = first( userBusinessIds );
                }

                dispatch( userBusinessActions.businessSwitched( userBusinessData ) );
                dispatch( uiActions.clearBusinessTypeFilter() );
                return dispatch( updateInternalDataAfterBusinessChange() );
            },
            ( error: string ) =>
            {
                dispatch( uiActions.trackProcessAsStopped( ProcessTrackingKey.CREATING_BUSINESS ) );

                dispatch( userBusinessActions.createFailure( error ) );
                dispatch( modalServices.openErrorDialogWithStandardFormat(
                    "Unable to create the business.",
                    "Please try again." ) );
            },
        );
    };
}

function _delete()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const state = store.getState();
        const currentBusiness = getCurrentBusiness( state );
        if ( !currentBusiness )
        {
            return;
        }

        const url = buildBusinessUrl( currentBusiness );
        return requests.delete<{}, MeAPIData>( url ).then(
            ( data ) =>
            {
                const normalizedData = normalizeMeAPI( data );
                const userBusinessData = { businessId: currentBusiness.id, userId: getCurrentUserId( store.getState() ) };
                dispatch( userBusinessActions.deleteSuccess( userBusinessData ) );
                dispatch( uiActions.clearBusinessTypeFilter() );
                dispatch( userActions.meSuccess( normalizedData ) );
                dispatch( updateInternalDataAfterBusinessChange() );
                dispatch( modalServices.openAlertDialog( { message: "Your business was deleted." } ) );
                history.push( HOMEPAGE_URL );
            },
            ( error: string ) => dispatch( userBusinessActions.deleteFailure( error ) ),
        );
    };
}

function updateInternalDataAfterBusinessChange()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        eventTracker.setUserPropertiesForCurrentUser();

        const storeState = store.getState();
        storeState.posts.ids = [];
        storeState.posts.idToObj = {};

        const currentUserId = getCurrentUserId( storeState );
        const currentBusiness = getCurrentBusiness( storeState );

        // TODO This currently reloads ALL data used by the home pages. Make it more intelligent
        // so server calls are only made for the data that was previously loaded.

        const date = getSelectedDate( storeState ) || getCurrentDate();
        const businessId = currentBusiness ? currentBusiness.id : undefined;

        dispatch( userServices.loadWeeklyEngagements( currentUserId, businessId ) );
        dispatch( postServices.loadDraftPosts( currentBusiness ) );
        dispatch( postServices.loadPriorityData( date ) );
        dispatch( postServices.loadMostEngagedPostsSorted() );
        dispatch( postServices.loadMostRecentPostsWithLimit( LOAD_POST_LIMIT ) );
        dispatch( postServices.loadUnscheduledPosts() );
    };
}

function uploadFont( aMediaData: MediaData )
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        const state = store.getState();
        const currentBusiness = getCurrentBusiness( state );
        if ( !currentBusiness )
        {
            return;
        }

        // The font file names cannot have spaces because the design code breaks for some reason.
        const filename = stringUtils.sanitizeFileName( aMediaData.filename );
        const theUrl = buildBusinessUrl( currentBusiness ) + REQUEST_ADD_FILE_TO_BUSINESS_PATH;
        const queryParams: RequestAddMediaAPI = {
            media_type: aMediaData.type,
            file_name: filename,
        };

        try
        {
            const data = await requests.get<AddMediaAPIResponse>( theUrl, queryParams );
            await s3Uploader.uploadMedia( data, aMediaData.file );
            const mediaData = { media_type: aMediaData.type, original_file_name: filename };
            eventTracker.logMediaUploadSucceededEvent( state, mediaData, aMediaData.file.size );
            return data;
        }
        catch (error)
        {
            errorReporting.reportErrorToSentry( `Failed to upload font (${error})` );
            const errorReason = eventTracker.getUploadSourceForMediaType( aMediaData.type );
            eventTracker.logMediaUploadFailedEvent( state, aMediaData.type, aMediaData.fileSize, error, errorReason );
            dispatch( modalServices.openErrorDialogWithStandardFormat(
                "Unable to upload the font file.",
                "Please try again later.",
                error ) );

            return Promise.reject( "Failed to upload font" );
        }
    };
}

function displayInviteTeamsMembersDialog( onSuccess?: () => void,
                                          onClose?: () => void,
                                          onCancel?: () => void )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const closeDialog = () =>
        {
            modalServices.closeLightBoxesWithIdentifier( dispatch,
                store.getState(),
                LightboxDialogIdentifierForKey.INVITE_TEAM_MEMBERS );
        };

        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.INVITE_TEAM_MEMBERS,
            showCancelX: true,
            modal: true,
            hideConfirm: true,
            hideCancel: true,
            width: 692,
            content: teamDialogFactory.getInviteTeamMembersDialogContent( closeDialog ),
            onSuccess,
            onClose,
            onCancel: () =>
            {
                if ( onCancel )
                {
                    onCancel();
                }
            },

        } ) );
    };
}

function sendTeamInvites( emails: string[] )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const state = store.getState();
        const currentBusiness = getCurrentBusiness( state );
        if ( !currentBusiness || isEmpty( emails ) )
        {
            return;
        }

        const url = buildBusinessUrl( currentBusiness ) + INVITE_TEAM_MEMBERS_PATH;
        return requests.post<InviteTeamMemberAPIData, MeAPIData>( url, { invitee_emails: emails } ).then(
            ( data ) =>
            {
                const normalizedData = normalizeMeAPI( data );
                dispatch( userActions.meSuccess( normalizedData ) );
                dispatch( userBusinessActions.inviteTeamMemberSuccess( normalizedData ) );
                dispatch( modalServices.openLightbox( {
                    identifierForKey: LightboxDialogIdentifierForKey.TEAM_INVITE_SENT,
                    showCancelX: true,
                    modal: true,
                    hideConfirm: true,
                    hideCancel: true,
                    width: 515,
                    content: teamDialogFactory.getInviteTeamMembersSuccessDialogContent(),
                } ) );
                eventTracker.logTeamInviteSent();
            },
            ( error: any ) =>
            {
                dispatch( userBusinessActions.inviteTeamMemberFailure( error ) );
                errorReporting.reportErrorToSentry( "Failed to invite the team members" );
                const { title, message } = find( TEAM_SENDING_INVITE_ERRORS, ( item ) => item.key === error.message ) || TEAM_INVITE_UNKNOWN_ERROR;
                dispatch( modalServices.openTeamsErrorDialog( title, message ) );
                eventTracker.logTeamInviteSent( error ? error.message : "unknown error" );
            },
        );
    };
}

function cancelInvitation( inviteId: number )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const state = store.getState();
        const currentBusiness = getCurrentBusiness( state );
        if ( !currentBusiness || !inviteId )
        {
            return;
        }

        const url = buildBusinessUrl( currentBusiness ) + CANCEL_TEAM_MEMBER_PATH;
        return requests.post<CancelTeamInviteAPIData, MeAPIData>( url, { invite_id: inviteId } ).then(
            ( data ) =>
            {
                const normalizedData = normalizeMeAPI( data );
                dispatch( userActions.meSuccess( normalizedData ) );
                dispatch( userBusinessActions.cancelTeamMemberInviteSuccess( normalizedData ) );
                dispatch( modalServices.openConfirmationAlert( "Invite cancelled.", "" ) );
            },
            ( error: any ) =>
            {
                dispatch( userBusinessActions.cancelTeamMemberInviteFailure( error ) );
                errorReporting.reportErrorToSentry( "Failed to cancel the team member invitation" );
                dispatch( modalServices.openTeamsErrorDialog(
                    "There was a problem cancelling the team member invitation.",
                    "Please try again later." ) );
            },
        );
    };
}

function removeTeamMember( teamMemberId: number )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const state = store.getState();
        const currentUserId = getCurrentUserId( state );
        const currentBusiness = getCurrentBusiness( state );

        if ( !currentBusiness || !teamMemberId )
        {
            return;
        }

        let dialogText = REMOVE_TEAM_MEMBER_MESSAGES;

        if ( teamMemberId === currentUserId )
        {
            dialogText = REMOVE_SELF_FROM_TEAM_MESSAGES;
        }

        const url = buildBusinessUrl( currentBusiness ) + REMOVE_TEAM_MEMBER_PATH;
        return requests.post<RemoveTeamMemberAPIData, MeAPIData>( url, { team_member_id: teamMemberId } ).then(
            ( data ) =>
            {
                const normalizedData = normalizeMeAPI( data );
                dispatch( userActions.meSuccess( normalizedData ) );
                dispatch( userBusinessActions.removeTeamMemberSuccess( normalizedData ) );
                dispatch( modalServices.openConfirmationAlert(
                    dialogText.success.title,
                    dialogText.success.message,
                ) );

                if ( !doesCurrentBusinessSupportTeamFeatures( store.getState() ) && includes( window.location.pathname, BRAND_USERS_PAGE_URL ) )
                {
                    history.push( BRAND_CREATIVE_PAGE_URL );
                }
            },
            ( error: any ) =>
            {
                dispatch( userBusinessActions.removeTeamMemberFailure( error ) );
                errorReporting.reportErrorToSentry( "Failed to remove the team member" );
                dispatch( modalServices.openTeamsErrorDialog(
                    dialogText.failure.primaryText,
                    dialogText.failure.secondaryText,
                ) );
            },
        );
    };
}

function acceptTeamInvite( token: string )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        if ( !token )
        {
            return;
        }

        const url = buildAcceptInviteUrl( token );
        return requests.get<AcceptInviteAPI>( url ).then(
            ( data ) =>
            {
                const normalizedData = normalizeMeAPI( data );
                const businessId = data.id;
                dispatch( userActions.meSuccess( normalizedData ) );
                const userBusinessData = { businessId, userId: getCurrentUserId( store.getState() ) };
                dispatch( userBusinessActions.businessSwitched( userBusinessData ) );
                dispatch( uiActions.clearBusinessTypeFilter() );
                dispatch( userBusinessActions.acceptTeamMemberInviteSuccess( normalizedData ) );
                const business = getBusinessById( store.getState(), data.id );
                dispatch( modalServices.openLightbox( {
                    identifierForKey: LightboxDialogIdentifierForKey.TEAM_INVITE_ACCEPTED,
                    showCancelX: true,
                    modal: true,
                    hideConfirm: true,
                    hideCancel: true,
                    width: 515,
                    content: teamDialogFactory.getInviteTeamMembersAcceptedDialogContent( business.name ),
                    onClose: () =>
                    {
                        if ( !hasSeenShowYouAround( store.getState() ) )
                        {
                            dispatch( uiActions.displayShowYouAroundTour( true ) );
                        }
                    },
                } ) );
                eventTracker.logTeamInviteAccepted();
            },
            ( error: any ) =>
            {
                dispatch( userBusinessActions.acceptTeamMemberInviteFailure( error ) );
                errorReporting.reportErrorToSentry( "Failed to accept invitation" );

                const { title, message } = find( TEAMS_ACCEPTING_INVITE_ERRORS, ( item ) => item.key === error.message ) || TEAM_INVITE_UNKNOWN_ERROR;
                dispatch( modalServices.openTeamsErrorDialog( title, message ) );
                eventTracker.logTeamInviteAccepted( error ? error.message : "unknown error" );
            },
        );
    };
}

function loadBrandLogos()
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        const state = store.getState();

        const currentBusiness = getCurrentBusiness( state );
        if ( !currentBusiness )
        {
            return;
        }

        const url = buildBusinessUrl( currentBusiness ) + BRAND_LOGOS_PATH;
        return requests.get<BrandLogosAPIData>( url ).then(
            ( data ) =>
            {
                dispatch( uiActions.updateBusinessInfo( { logos: data.logos } ) );
            },
            ( error: any ) =>
            {
                // console.log( error );
            },
        );
    };
}

function refreshMeDataIfCurrentLogoWasHidden( data: BrandLogosAPIData, dispatch: Dispatch<StoreState> )
{
    const logoUrls = map( data.logos, ( brandLogo ) => brandLogo.url );
    const currentLogoUrl = getCurrentBusiness( store.getState() ).profile_image_url;
    if ( !includes( logoUrls, currentLogoUrl ) )
    {
        dispatch( userServices.loadMe() );
    }
}

function hideBrandLogos( brandLogos: BrandLogo[] )
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        const state = store.getState();

        const currentBusiness = getCurrentBusiness( state );
        if ( !currentBusiness )
        {
            return;
        }

        const logoIds = map( brandLogos, ( brandLogo ) => brandLogo.id );
        const url = buildBusinessUrl( currentBusiness ) + HIDE_BRAND_LOGOS_PATH;
        return requests.post<HideBrandLogosAPIData, BrandLogosAPIData>( url, { ids: logoIds } ).then(
            ( data ) =>
            {

                const logos = data.logos;
                dispatch( uiActions.updateBusinessInfo( { logos } ) );
                refreshMeDataIfCurrentLogoWasHidden( data, dispatch );
            },
            ( error: any ) =>
            {
                dispatch( modalServices.openErrorDialog(
                    ERROR_TITLE_SORRY,
                    "Unable to delete logos.",
                    true,
                ) );
            },
        );
    };
}
