import { StoreState, TeamInvite, TeamMember } from "../../../../_types";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { modalServices, userBusinessServices } from "../../../../services";
import { DeleteTeamItemButton, DeleteTeamItemButtonDispatchProps, DeleteTeamItemButtonProps } from "../DeleteTeamItemButton";
import { LightboxDialogIdentifierForKey } from "../../../../helpers";

const mapStateToProps = ( storeState: StoreState, ownProps: { invitation: TeamInvite } ): DeleteTeamItemButtonProps =>
{
    return {
        item: ownProps.invitation,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): DeleteTeamItemButtonDispatchProps =>
{
    return {
        handleDeleteItem( item: TeamMember | TeamInvite )
        {
            const invite = item as TeamInvite;

            dispatch( modalServices.openLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.DELETE_TEAM_INVITES,
                showCancelX: true,
                modal: true,
                confirmLabel: "Cancel invite",
                width: 550,
                title: "Cancel team member invite?",
                content: "This invitation to join your team will no longer be valid.",
                onSuccess: () =>
                {
                    dispatch( userBusinessServices.cancelInvitation( invite.id ) );
                },
            } ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( DeleteTeamItemButton );
