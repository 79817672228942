import * as React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export interface LoadingProgressProps
{
    progress: number;
    message: string;
}

export class LoadingProgress extends React.PureComponent<LoadingProgressProps>
{
    public render()
    {
        const progress = Math.round( this.props.progress );
        return (
            <div className="circularProgress">
                <CircularProgressbarWithChildren
                    value={progress}
                    strokeWidth={10}
                    className="circularProgress"
                    background={true}
                    styles={{
                        path: {
                            stroke: "#464DE1",
                            strokeLinecap: "butt",
                            transition: progress === 0 ? "none" : "stroke-dashoffset 0.5s ease 0s",
                            transformOrigin: "center center",
                        },
                        trail: {
                            stroke: "#F5F5F5",
                            strokeLinecap: "butt",
                            transformOrigin: "center center",
                        },
                        background: {
                            fill: "#FFFFFF",
                        },
                    }}
                >
                    <span id="percentage">{`${progress} %`}</span>
                    <span id="message">{this.props.message}</span>
                </CircularProgressbarWithChildren>
            </div>
        );
    }
}
