import * as React from "react";
import {
    AMEX_CARD_TYPE,
    DINERS_CLUB_CARD_TYPE,
    DISCOVER_CARD_TYPE,
    JCB_CARD_TYPE,
    MASTERCARD_CARD_TYPE,
    UNION_PAY_CARD_TYPE,
    VISA_CARD_TYPE,
} from "../helpers";
import * as GenericCardLogo from "../assets/img/creditCardLogos/generic.svg";
import * as UnionPayCardLogo from "../assets/img/creditCardLogos/unionpay.svg";
import * as JCBCardLogo from "../assets/img/creditCardLogos/jcb.svg";
import * as DinersClubCardLogo from "../assets/img/creditCardLogos/diners.svg";
import * as DiscoverCardLogo from "../assets/img/creditCardLogos/discover.svg";
import * as AmexCardLogo from "../assets/img/creditCardLogos/amex.svg";
import * as MastercardCardLogo from "../assets/img/creditCardLogos/mastercard.svg";
import * as VisaCardLogo from "../assets/img/creditCardLogos/visa.svg";

export interface PaymentMethodInfoProps
{
    brand: string;
    last_four_digits: string;
}

export class PaymentMethodInfo extends React.PureComponent<PaymentMethodInfoProps>
{
    public render()
    {
        if ( this.props.brand && this.props.last_four_digits )
        {
            const cardLogo = this.getCardLogo( this.props.brand );
            return (
                <div className="paymentInfo">
                    {cardLogo ? this.renderCardLogo( cardLogo ) : "Card "}
                    ending in {this.props.last_four_digits} {this.props.children}
                </div>
            );
        }
        else
        {
            return <></>;
        }
    }

    private renderCardLogo( cardLogo )
    {
        return <img className="card-logo"
                    src={cardLogo}
                    alt="card logo"/>;
    }

    private getCardLogo = ( brand: string ) =>
    {
        const cardLogos = new Map();
        cardLogos.set( VISA_CARD_TYPE, VisaCardLogo );
        cardLogos.set( MASTERCARD_CARD_TYPE, MastercardCardLogo );
        cardLogos.set( AMEX_CARD_TYPE, AmexCardLogo );
        cardLogos.set( DISCOVER_CARD_TYPE, DiscoverCardLogo );
        cardLogos.set( DINERS_CLUB_CARD_TYPE, DinersClubCardLogo );
        cardLogos.set( JCB_CARD_TYPE, JCBCardLogo );
        cardLogos.set( UNION_PAY_CARD_TYPE, UnionPayCardLogo );
        const logo = cardLogos.get( brand );
        return logo ? logo : GenericCardLogo;
    }

}
