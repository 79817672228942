import { Action } from "redux-actions";
import {
    FONT_LOAD_COMPLETED_STATUS,
    FONT_LOAD_REQUESTED_STATUS,
    FONT_LOAD_RETRYING_STATUS,
    getFontDisplayName,
    ReducerCreator,
    stringUtils,
} from "../helpers";
import { fontCatalogActions } from "../actions";
import { difference, filter, find, fromPairs, keys, map, pickBy, sortBy } from "lodash";
import { FontCatalogState, FontData, FontLoadState, FontLoadStatusType, StoreState } from "../_types";

const defaultState: FontCatalogState = {
    catalog: [],
    fontLoadState: {},
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( fontCatalogActions.loadSuccess, updateFontData );
reducerCreator.addAction( fontCatalogActions.loadFontsRequest, updateFontLoadStatusToRequested );
reducerCreator.addAction( fontCatalogActions.loadFontSuccess, updateFontLoadStatusToCompleted );
reducerCreator.addAction( fontCatalogActions.loadFontFailure, updateFontLoadStatusForFailure );
reducerCreator.addAction( fontCatalogActions.loadFontRetry, updateFontLoadStatusForRetry );
export default reducerCreator.createReducer();

function updateFontData( state: FontCatalogState, action: Action<object> ): FontCatalogState
{
    const fontData = map( Object.keys( action.payload ), ( fontName ) =>
    {
        const displayName = stringUtils.replaceHyphensAndUnderscoresWithSpaces( fontName );
        return {
            displayName,
            designFontName: fontName,
            characterSets: action.payload[fontName],
        };
    } );

    return {
        ...state,
        catalog: fontData,
        fontLoadState: {},
    };
}

function updateFontLoadStatusToRequested( state: FontCatalogState, action: Action<string[]> ): FontCatalogState
{
    const fontStateForRequestedFonts = fromPairs(
        map( action.payload, ( fontFamily: FontLoadStatusType ) => [fontFamily, FONT_LOAD_REQUESTED_STATUS as FontLoadStatusType] ) );
    return {
        ...state,
        fontLoadState: {
            ...copyFontLoadState( state ),
            ...fontStateForRequestedFonts,
        },
    };
}

function updateFontLoadStatusToCompleted( state: FontCatalogState, action: Action<string> ): FontCatalogState
{
    return updateFontToFontLoadStatus( state, action.payload, FONT_LOAD_COMPLETED_STATUS );
}

function updateFontLoadStatusForFailure( state: FontCatalogState, action: Action<string> ): FontCatalogState
{
    const fontLoadState = copyFontLoadState( state );

    const fontFamily = action.payload;
    fontLoadState[fontFamily] = undefined;
    delete fontLoadState[fontFamily];

    return {
        ...state,
        fontLoadState,
    };
}

function updateFontLoadStatusForRetry( state: FontCatalogState, action: Action<string> ): FontCatalogState
{
    return updateFontToFontLoadStatus( state, action.payload, FONT_LOAD_RETRYING_STATUS );
}

function updateFontToFontLoadStatus( state: FontCatalogState, fontFamily: string, fontLoadStatus: FontLoadStatusType )
{
    const fontLoadState = copyFontLoadState( state );
    fontLoadState[fontFamily] = fontLoadStatus;

    return {
        ...state,
        fontLoadState,
    };
}

function copyFontLoadState( state: FontCatalogState ): FontLoadState
{
    const fontLoadState = state.fontLoadState || {};
    return {
        ...fontLoadState,
    };
}

export function getFonts( state: StoreState ): FontData[]
{
    return filter( sortBy( state.fonts.catalog, getFontDisplayName ) );
}

export function getFontByDisplayName( state: StoreState, displayName: string ): FontData
{
    return find( filter( state.fonts.catalog ), { displayName } );
}

export function filterOutLoadingAndLoadedFonts( state: StoreState, fontFamilies: string[] ): string[]
{
    const loadingAndLoadedFonts = getLoadingAndLoadedFonts( state );
    return difference( fontFamilies, loadingAndLoadedFonts );
}

export function getLoadingAndLoadedFonts( state: StoreState ): string[]
{
    const loadingAndLoadedFontLoadState = pickBy( state.fonts.fontLoadState, ( value ) => isLoadingOrLoaded( value ) );
    return keys( loadingAndLoadedFontLoadState );
}

function isLoadingOrLoaded( fontLoadStatus: FontLoadStatusType ): boolean
{
    return isRequested( fontLoadStatus ) || isCompleted( fontLoadStatus ) || isRetrying( fontLoadStatus );
}

function isRequested( fontLoadStatus: FontLoadStatusType ): boolean
{
    return FONT_LOAD_REQUESTED_STATUS === fontLoadStatus;
}

function isCompleted( fontLoadStatus: FontLoadStatusType ): boolean
{
    return FONT_LOAD_COMPLETED_STATUS === fontLoadStatus;
}

function isRetrying( fontLoadStatus: FontLoadStatusType ): boolean
{
    return FONT_LOAD_RETRYING_STATUS === fontLoadStatus;
}

export function wasFontLoadRetried( state: StoreState, fontFamily: string ): boolean
{
    return doesFontMatchFontLoadStatus( state, fontFamily, FONT_LOAD_RETRYING_STATUS );
}

function doesFontMatchFontLoadStatus( state: StoreState, fontFamily: string, fontLoadStatus: FontLoadStatusType )
{
    const fontLoadState = state.fonts.fontLoadState || {};
    const actualFontLoadStatus: "" | FontLoadStatusType = fontFamily && fontLoadState[fontFamily];
    return actualFontLoadStatus && actualFontLoadStatus === fontLoadStatus;
}

export function getMoreFontSelected( state: StoreState ): string
{
    return state.ui.fontAutocompleteData.filterText;
}
