import { Action } from "redux-actions";
import {
    ANNUAL_PRODUCT_TYPE,
    ANNUAL_SKU_WITH_30_DAY_TRIAL,
    BASE_MONTHLY_SKU_12_99_NO_TRIAL,
    BASE_MONTHLY_SKU_12_99_TRIAL,
    BASE_YEARLY_SKU_99_99_NO_TRIAL,
    BASE_YEARLY_SKU_99_99_TRIAL,
    convertPricingPlanTypeToSkuDuration,
    convertUSDAmountToSelectedCurrency,
    COUPON_CODE_PARAM,
    DEFAULT_ANNUAL_SKU,
    DEFAULT_ANNUAL_SKU_NO_TRIAL,
    DEFAULT_MONTHLY_SKU,
    DEFAULT_MONTHLY_SKU_NO_TRIAL,
    DEFAULT_PAYMENT_AMOUNT,
    DEFAULT_PAYMENT_CURRENCY,
    FREE_30_DAY_SRC_PARAMS,
    MONTHLY_SKU_12_99,
    MONTHLY_SKU_12_99_NO_TRIAL,
    MONTHLY_SKU_19_99,
    MONTHLY_SKU_19_99_NO_TRIAL,
    MONTHLY_SKU_WITH_30_DAY_TRIAL,
    PREMIUM_MONTHLY_SKU_19_99_NO_TRIAL,
    PREMIUM_MONTHLY_SKU_19_99_TRIAL,
    PREMIUM_YEARLY_SKU_164_99_NO_TRIAL,
    PREMIUM_YEARLY_SKU_164_99_TRIAL,
    ReducerCreator,
    RIPL_TIER_BASE,
    RIPL_TIER_LEGACY,
    RIPL_TIER_PREMIUM,
    stringUtils,
    YEARLY_SKU_95_88,
    YEARLY_SKU_95_88_NO_TRIAL,
} from "../helpers";
import { pricingActions } from "../actions";
import { filter, find, head, includes, lowerCase, map, reduce, reject, round, size, uniq } from "lodash";
import { parse } from "query-string";
import { STRIPE_NO_TRIAL_COUPON_CODE_TOKEN, STRIPE_PLAN_TRIAL_NO_DAYS, STRIPE_PLAN_TYPE_ANNUAL, STRIPE_PLAN_TYPE_MONTHLY } from "../_types/api";
import { PricingState, StoreState } from "../_types";
import { getUserCurrencyCode, isUserSubscribedMonthlyStripe } from "./user";
import { getLocaleForCurrency } from "../helpers/currencyHelper";

const defaultState: PricingState = {
    plans: [],
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( pricingActions.updateCouponCode, handleCouponCode );
reducerCreator.addAction( pricingActions.updateProductType, handleProductType );
reducerCreator.addAction( pricingActions.updateProductTier, handleProductTier );
reducerCreator.addAction( pricingActions.setPlan, handleSetPlan );
reducerCreator.addAction( pricingActions.loadSuccess, updatePricing );
reducerCreator.addAction( pricingActions.setCouponCodeError, handleCouponCodeError );
reducerCreator.addAction( pricingActions.updateSrcCode, handleSrcCode );
reducerCreator.addAction( pricingActions.tierSelected, handleTierSelected );
reducerCreator.addAction( pricingActions.setAllPlansVisible, handleSetAllPlansVisible );

export default reducerCreator.createReducer();

const VALID_TRIAL_SKUS = [BASE_MONTHLY_SKU_12_99_TRIAL,
                          BASE_YEARLY_SKU_99_99_TRIAL,
                          PREMIUM_MONTHLY_SKU_19_99_TRIAL,
                          PREMIUM_YEARLY_SKU_164_99_TRIAL,
                          DEFAULT_MONTHLY_SKU,
                          DEFAULT_ANNUAL_SKU];

function handleSrcCode( state: PricingState, action: Action<string> ): PricingState
{
    return {
        ...state,
        srcCode: action.payload,
    };
}

function handleCouponCode( state: PricingState, action: Action<string> ): PricingState
{
    return {
        ...state,
        couponCode: action.payload,
        couponError: null,
    };
}

function handleProductType( state: PricingState, action: Action<PricingPlanType> ): PricingState
{
    return {
        ...state,
        productType: action.payload,
    };
}

function handleProductTier( state: PricingState, action: Action<PricingPlanTier> ): PricingState
{
    return {
        ...state,
        productTier: action.payload,
    };
}

function handleSetPlan( state: PricingState, action: Action<string> ): PricingState
{
    return {
        ...state,
        selectedPlan: action.payload,
    };
}

function updatePricing( state: PricingState, action: Action<ListPlanAPIData> ): PricingState
{
    let exchangeRateData = null;
    if ( action.payload.exchangeRateData )
    {
        exchangeRateData = action.payload.exchangeRateData;
    }
    return {
        ...state,
        couponError: action.payload.couponError,
        plans: action.payload.plans,
        exchangeRateData,
    };
}

function handleCouponCodeError( state: PricingState, action: Action<string> ): PricingState
{
    return {
        ...state,
        couponError: action.payload,
    };
}

function handleTierSelected( state: PricingState, action: Action<ProductTier> ): PricingState
{
    return {
        ...state,
        selectedTier: action.payload,
        selectedPlan: null,
    };
}

function handleSetAllPlansVisible(state: PricingState, action: Action<boolean> ): PricingState
{
    return {
        ...state,
        showAllPlans: action.payload,
    };
}

export function getNumSubscriptionPlans( state: StoreState )
{
    return size( state.pricing.plans );
}

export function getAnnualPlanForSelectedTier( state: StoreState ): StripePlanData
{
    return getAnnualPlanForTier( state, getSelectedTier( state ) );
}

export function getAnnualPlanForTier( state: StoreState, tier: ProductTier ): StripePlanData
{
    const code: string = getCouponCode( state );
    if ( has30DayFreeTrialSource( state ) )
    {
        return getAnnualWith30DayTrialPlan( state );
    }
    else if ( getNoTrialCouponCode( code ) || hasUsedTrial( state ) )
    {
        return getAnnualNoTrialPlan( state, tier );
    }

    return getAnnualWithTrialPlan( state, tier );
}

function getAnnualNoTrialPlan( state: StoreState, tier: ProductTier ): StripePlanData
{
    if ( tier === RIPL_TIER_BASE )
    {
        return getBaseAnnualWithNoTrialPlan( state );
    }
    else if ( tier === RIPL_TIER_PREMIUM )
    {
        return getPremiumAnnualWithNoTrialPlan( state );
    }
    else
    {
        return getDefaultAnnualNoTrialPlan( state );
    }
}

function getAnnualWithTrialPlan( state: StoreState, tier: ProductTier ): StripePlanData
{
    if ( tier === RIPL_TIER_BASE )
    {
        return getBaseAnnualWithTrialPlan( state );
    }
    else if ( tier === RIPL_TIER_PREMIUM )
    {
        return getPremiumAnnualWithTrialPlan( state );
    }
    else
    {
        return getDefaultAnnualWithTrialPlan( state );
    }
}

export function getSkuForTierAndDuration( tier: ProductTier, plan: PricingPlanType )
{
    const isMatchingTierAndDurationSku = ( sku: string ) => (isTierSku( sku, tier ) && isDurationSku( sku, plan ));
    return head( filter( VALID_TRIAL_SKUS, isMatchingTierAndDurationSku ) );
}

function isTierSku( sku: string, tier: ProductTier )
{
    if ( includes( sku, lowerCase( tier ) ) )
    {
        return true;
    }
    else if ( tier === RIPL_TIER_LEGACY &&
              !includes( sku, lowerCase( RIPL_TIER_BASE ) ) &&
              !includes( sku, lowerCase( RIPL_TIER_PREMIUM ) ) )
    {
        return true;
    }
    return false;
}

function isDurationSku( sku: string, plan: PricingPlanType )
{
    const skuDurationString: StripeSkuDuration = convertPricingPlanTypeToSkuDuration( plan );
    return includes( sku, lowerCase( skuDurationString ) );
}

export function getMonthlyPlanForSelectedTier( state: StoreState ): StripePlanData
{
    return getMonthlyPlanForTier( state, getSelectedTier( state ) );
}

export function getMonthlyPlanForTier( state: StoreState, tier: ProductTier ): StripePlanData
{
    const code: string = getCouponCode( state );
    if ( has30DayFreeTrialSource( state ) )
    {
        return getMonthlyWith30DayTrialPlan( state );
    }
    else if ( getNoTrialCouponCode( code ) || hasUsedTrial( state ) )
    {
        return getMonthlyNoTrialPlan( state, tier );
    }
    return getMonthlyWithTrialPlan( state, tier );
}

function getMonthlyNoTrialPlan( state: StoreState, tier: ProductTier ): StripePlanData
{
    if ( tier === RIPL_TIER_BASE )
    {
        return getBaseMonthlyWithNoTrialPlan( state );
    }
    else if ( tier === RIPL_TIER_PREMIUM )
    {
        return getPremiumMonthlyWithNoTrialPlan( state );
    }
    else
    {
        return getDefaultMonthlyNoTrialPlan( state );
    }
}

function getMonthlyWithTrialPlan( state: StoreState, tier: ProductTier ): StripePlanData
{
    if ( tier === RIPL_TIER_BASE )
    {
        return getBaseMonthlyWithTrialPlan( state );
    }
    else if ( tier === RIPL_TIER_PREMIUM )
    {
        return getPremiumMonthlyWithTrialPlan( state );
    }
    else
    {
        return getDefaultMonthlyWithTrialPlan( state );
    }
}

type FilterFunction<T> = ( arr: T[] ) => T[];
const monthly: FilterFunction<StripePlanData> = ( plans: StripePlanData[] ): StripePlanData[] => filter( plans, { type: STRIPE_PLAN_TYPE_MONTHLY } );
const annual: FilterFunction<StripePlanData> = ( plans: StripePlanData[] ): StripePlanData[] => filter( plans, { type: STRIPE_PLAN_TYPE_ANNUAL } );
const hasTrial: FilterFunction<StripePlanData> = ( plans: StripePlanData[] ): StripePlanData[] => filter( plans, doesSubscriptionPlanHaveATrial );
const noTrial: FilterFunction<StripePlanData> = ( plans: StripePlanData[] ): StripePlanData[] => reject( plans, doesSubscriptionPlanHaveATrial );

function findMatchingPlan( plans: StripePlanData[], filters: Array<FilterFunction<StripePlanData>>, planId?: string ): StripePlanData
{
    const reducer = ( intermediate: StripePlanData[], nthFilter: FilterFunction<StripePlanData> ): StripePlanData[] =>
    {
        return nthFilter( intermediate );
    };

    const filteredPlans: StripePlanData[] = reduce( filters, reducer, plans );
    return planId ? find( filteredPlans, { id: planId } ) : head( filteredPlans );
}

export function getDefaultAnnualWithTrialPlan( state: StoreState ): StripePlanData
{
    return findMatchingPlan( state.pricing.plans, [hasTrial, annual], DEFAULT_ANNUAL_SKU );
}

export function getBaseAnnualWithTrialPlan( state: StoreState ): StripePlanData
{
    return findMatchingPlan( state.pricing.plans, [hasTrial, annual], BASE_YEARLY_SKU_99_99_TRIAL );
}

export function getPremiumAnnualWithTrialPlan( state: StoreState ): StripePlanData
{
    return findMatchingPlan( state.pricing.plans, [hasTrial, annual], PREMIUM_YEARLY_SKU_164_99_TRIAL );
}

export function getAnnualWith30DayTrialPlan( state: StoreState ): StripePlanData
{
    return findMatchingPlan( state.pricing.plans, [hasTrial, annual], ANNUAL_SKU_WITH_30_DAY_TRIAL );
}

export function getMonthlyWith30DayTrialPlan( state: StoreState ): StripePlanData
{
    return findMatchingPlan( state.pricing.plans, [hasTrial, monthly], MONTHLY_SKU_WITH_30_DAY_TRIAL );
}

export function getDefaultMonthlyWithTrialPlan( state: StoreState ): StripePlanData
{
    return findMatchingPlan( state.pricing.plans, [hasTrial, monthly], DEFAULT_MONTHLY_SKU );
}

export function getBaseMonthlyWithTrialPlan( state: StoreState ): StripePlanData
{
    return findMatchingPlan( state.pricing.plans, [hasTrial, monthly], BASE_MONTHLY_SKU_12_99_TRIAL );
}

export function getPremiumMonthlyWithTrialPlan( state: StoreState ): StripePlanData
{
    return findMatchingPlan( state.pricing.plans, [hasTrial, monthly], PREMIUM_MONTHLY_SKU_19_99_TRIAL );
}

export function getDefaultAnnualNoTrialPlan( state: StoreState ): StripePlanData
{
    return findMatchingPlan( state.pricing.plans, [noTrial, annual], DEFAULT_ANNUAL_SKU_NO_TRIAL ) || getDefaultAnnualWithTrialPlan( state );
}

export function getDefaultMonthlyNoTrialPlan( state: StoreState ): StripePlanData
{
    return findMatchingPlan( state.pricing.plans, [noTrial, monthly], DEFAULT_MONTHLY_SKU_NO_TRIAL ) || getDefaultMonthlyWithTrialPlan( state );
}

export function getBaseMonthlyWithNoTrialPlan( state: StoreState ): StripePlanData
{
    return findMatchingPlan( state.pricing.plans, [noTrial, monthly], BASE_MONTHLY_SKU_12_99_NO_TRIAL );
}

export function getPremiumMonthlyWithNoTrialPlan( state: StoreState ): StripePlanData
{
    return findMatchingPlan( state.pricing.plans, [noTrial, monthly], PREMIUM_MONTHLY_SKU_19_99_NO_TRIAL );
}

export function getBaseAnnualWithNoTrialPlan( state: StoreState ): StripePlanData
{
    return findMatchingPlan( state.pricing.plans, [noTrial, annual], BASE_YEARLY_SKU_99_99_NO_TRIAL );
}

export function getPremiumAnnualWithNoTrialPlan( state: StoreState ): StripePlanData
{
    return findMatchingPlan( state.pricing.plans, [noTrial, annual], PREMIUM_YEARLY_SKU_164_99_NO_TRIAL );
}

export function getMonthly1299WithTrialPlan( state: StoreState ): StripePlanData
{
    return findMatchingPlan( state.pricing.plans, [hasTrial, monthly], MONTHLY_SKU_12_99 );
}

export function getMonthly1299NoTrialPlan( state: StoreState ): StripePlanData
{
    return findMatchingPlan( state.pricing.plans, [noTrial, monthly], MONTHLY_SKU_12_99_NO_TRIAL );
}

export function getMonthly1999WithTrialPlan( state: StoreState ): StripePlanData
{
    return findMatchingPlan( state.pricing.plans, [hasTrial, monthly], MONTHLY_SKU_19_99 );
}

export function getMonthly1999NoTrialPlan( state: StoreState ): StripePlanData
{
    return findMatchingPlan( state.pricing.plans, [noTrial, monthly], MONTHLY_SKU_19_99_NO_TRIAL );
}

export function getAnnual9588WithTrialPlan( state: StoreState ): StripePlanData
{
    return findMatchingPlan( state.pricing.plans, [hasTrial, annual], YEARLY_SKU_95_88 );
}

export function getAnnual9588NoTrialPlan( state: StoreState ): StripePlanData
{
    return findMatchingPlan( state.pricing.plans, [noTrial, annual], YEARLY_SKU_95_88_NO_TRIAL );
}

function getNoTrialCouponCode( code: string )
{
    return code !== null && code.startsWith( STRIPE_NO_TRIAL_COUPON_CODE_TOKEN );
}

export function hasUsedTrial( state: StoreState )
{
    const user = state.user;
    return user && (user.has_had_stripe_trial || user.has_had_ios_trial || user.has_had_android_trial);
}

export function getCouponCode( state: StoreState )
{
    const urlCouponCode = parse( window.location.search )[COUPON_CODE_PARAM] || "";
    return state.pricing.couponCode === undefined ? urlCouponCode : state.pricing.couponCode;
}

export function getSelectedPlan( state: StoreState ): StripePlanData
{
    const selectedPlan = find( state.pricing.plans, { id: state.pricing.selectedPlan } );
    return selectedPlan ? selectedPlan : getAnnualPlanForSelectedTier( state );
}

export function getSelectedPaymentAmount( state: StoreState )
{
    const plan = getSelectedPlan( state );
    if ( plan )
    {
        return plan.amount;
    }
    return DEFAULT_PAYMENT_AMOUNT;
}

export function getSelectedPaymentCurrency( state: StoreState )
{
    const plan = getSelectedPlan( state );
    if ( plan )
    {
        return plan.currency.toUpperCase();
    }
    return DEFAULT_PAYMENT_CURRENCY;
}

export function doesSubscriptionPlanHaveATrial( selectedPlan: StripePlanData )
{
    return selectedPlan && selectedPlan.trial_period_days > STRIPE_PLAN_TRIAL_NO_DAYS;
}

function has30DayFreeTrialSource( state: StoreState )
{
    const src = getPricingSource( state );
    return includes( FREE_30_DAY_SRC_PARAMS, src );
}

export const getPricingCouponCode = ( state: StoreState ) => state.pricing.couponCode;
export const getPricingProductType = ( state: StoreState ) => state.pricing.productType;
export const getPricingProductTier = ( state: StoreState ) => state.pricing.productTier;
export const getPricingSource = ( state: StoreState ) => state.pricing.srcCode;
export const getExchangeRateData = ( state: StoreState ): ExchangeRateData => state.pricing.exchangeRateData;
export const shouldShowAllPlans = ( state: StoreState ): boolean => !!state.pricing.showAllPlans;

export const getCurrentlySelectedExchangeRate = ( state: StoreState ): number =>
{
    const exchangeRateData = getExchangeRateData( state );
    if ( exchangeRateData )
    {
        const userCurrencyCode = getUserCurrencyCode( state );
        return exchangeRateData.rates[userCurrencyCode] || 1;
    }
    return 1;
};

export function isEligibleToUpgradeToStripeAnnual( state: StoreState ): boolean
{
    const productType = getPricingProductType( state );
    return productType === ANNUAL_PRODUCT_TYPE && isUserSubscribedMonthlyStripe( state );
}

export function getSubscriptionButtonText( selectedPlan: StripePlanData )
{
    if ( selectedPlan && doesSubscriptionPlanHaveATrial( selectedPlan ) )
    {
        return `Start ${getTrialDurationText( selectedPlan.trial_period_days )} Free Trial`;
    }

    return "Get Started";
}

function getTrialDurationText( trialPeriodDays: number )
{
    if ( trialPeriodDays === 30 )
    {
        return "One Month";
    }
    return `${trialPeriodDays} day`;
}

export function getTryRiplProText( state: StoreState )
{
    // Use the monthly plan to get the trial period days, because it should match the annual plan.
    const monthlyPlan = getMonthlyPlanForSelectedTier( state );
    return monthlyPlan && doesSubscriptionPlanHaveATrial( monthlyPlan )
           ? `Try Ripl Pro with a free ${getTrialDurationText( monthlyPlan.trial_period_days )} trial`
           : "Try Ripl Pro";
}

export function getUSDPricePerPeriod( selectedPlan )
{
    if ( !selectedPlan )
    {
        return "";
    }

    const amount = selectedPlan && selectedPlan.amount || 0;
    const billingPeriod = getBillingPeriod( selectedPlan );

    return stringUtils.getUSDZeroPaddedPrice( amount ) + "/" + billingPeriod;
}

export function getUSDPricePerMonth( selectedPlan )
{
    if ( !selectedPlan )
    {
        return "";
    }

    const { amount } = getMonthlyAmount( selectedPlan );

    return stringUtils.getUSDZeroPaddedPrice( amount ) + "/month";
}

function getBillingPeriod( plan: StripePlanData )
{
    if ( plan )
    {
        if ( plan.type === STRIPE_PLAN_TYPE_ANNUAL )
        {
            return "year";
        }
        else if ( plan.type === STRIPE_PLAN_TYPE_MONTHLY )
        {
            return "month";
        }
    }
}

export function getSelectedPlanTrialAndPricingText( selectedPlan: StripePlanData, commonTrialDays: string, hasUserUsedTrial: boolean )
{
    if ( selectedPlan && doesSubscriptionPlanHaveATrial( selectedPlan ) )
    {
        return `${selectedPlan.trial_period_days} days free. Cancel anytime.`;
    }
    else
    {
        const freeTrialText = `${commonTrialDays} days free. Cancel anytime.`;
        return commonTrialDays && !hasUserUsedTrial ? freeTrialText : "Cancel anytime.";
    }
}

export const getSelectedPaymentSku = ( state: StoreState ) => state.pricing.selectedPlan;
export const getCouponCodeError = ( state: StoreState ) => state.pricing.couponError;
export const getSelectedTier = ( state: StoreState ) => state.pricing.selectedTier;

export function getCommonTrialDays( plans: StripePlanData[] ): string
{
    const validPlans = filter( plans, doesSubscriptionPlanHaveATrial );
    if ( validPlans.length !== plans.length )
    {
        return;
    }

    const planDurations = map( validPlans, ( item ) => item.trial_period_days );
    const uniqPlanDurations = uniq( planDurations );

    return uniqPlanDurations.length === 1 ? uniqPlanDurations[0].toString() : null;
}

export const computeSavings = ( annualPlan: StripePlanData, monthlyPlan: StripePlanData ) =>
{
    const monthlyDetails = getMonthlyAmount( monthlyPlan );
    const annualDetails = getMonthlyAmount( annualPlan );
    if ( !monthlyDetails.amount )
    {
        return 0;
    }
    const percent = 1 - (annualDetails.amount / monthlyDetails.amount);
    return round( percent * 100, 0 );
};

export const computeSavingsAsDollars = ( annualPlan: StripePlanData, monthlyPlan: StripePlanData ) =>
{
    const annualPlanPricePerYear = getAnnualAmount( annualPlan ).amount;
    const monthlyPlanPricePerYear = getAnnualAmount( monthlyPlan ).amount;

    return Math.abs( monthlyPlanPricePerYear - annualPlanPricePerYear );
};

export function constructPlanAmount( amount: number, originalAmount: number ): PlanAmount
{
    return { amount, originalAmount };
}

export const getMonthlyAmount = ( plan: StripePlanData ): PlanAmount =>
{
    const planAmount: PlanAmount = constructPlanAmount( 0, 0 );
    if ( plan )
    {
        const isAnnual = isAnnualPlan( plan );
        const divisor = isAnnual ? 12 : 1;
        planAmount.amount = plan.amount / divisor;
        planAmount.originalAmount = plan.originalAmount / divisor;
    }
    return planAmount;
};

export const getAnnualAmount = ( plan: StripePlanData ): PlanAmount =>
{
    const planAmount: PlanAmount = getMonthlyAmount( plan );
    return constructPlanAmount( planAmount.amount * 12, planAmount.originalAmount * 12 );
};

export const computeCouponSavings = ( plan: PlanAmount ) =>
{
    const percent = 1 - (plan.amount / plan.originalAmount);
    return round( percent * 100, 0 );
};

export const isPlanDiscounted = ( plan: PlanAmount ) =>
{
    const amount = plan.amount;
    const originalAmount = plan.originalAmount;
    return amount !== originalAmount;
};

export const isAnnualPlan = ( plan: StripePlanData ) =>
{
    return plan.type === STRIPE_PLAN_TYPE_ANNUAL;
};

export const isMonthlyPlan = ( plan: StripePlanData ) =>
{
    return plan.type === STRIPE_PLAN_TYPE_MONTHLY;
};

export type ConvertToCurrencyFormatter = ( locale: string, currencyCode: CurrencyCode, amountInDollars: number ) => any;

export function convertToUserCurrency( currencyCode: CurrencyCode, currentExchangeRate: number, shouldShowConvertedCurrency: boolean,
                                       amountInDollars: number, formatter: ConvertToCurrencyFormatter ): any
{
    const convertedAmount = convertUSDAmountToSelectedCurrency(
        shouldShowConvertedCurrency,
        currentExchangeRate,
        amountInDollars,
    );
    const locale = getLocaleForCurrency( currencyCode );

    return formatter(
        locale,
        currencyCode,
        convertedAmount,
    );
}
