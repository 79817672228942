import { connect } from "react-redux";
import { PremiumFocusedWidePriceBlock, PremiumFocusedWidePriceBlockProps } from "../PremiumFocusedWidePriceBlock";
import { StoreState } from "../../../_types";
import { getUserCurrencyCode } from "../../../ducks/user";
import { getCurrentlySelectedExchangeRate, getExchangeRateData } from "../../../ducks/pricing";

const mapStateToProps = ( storeState: StoreState, ownProps: PremiumFocusedWidePriceBlockProps ): PremiumFocusedWidePriceBlockProps =>
{
    return {
        ...ownProps,
        currencyCode: getUserCurrencyCode( storeState ),
        currentExchangeRate: getCurrentlySelectedExchangeRate( storeState ),
        shouldShowExchangeRates: !!getExchangeRateData( storeState ),
    };
};

export default connect(
    mapStateToProps,
    () => ({}),
)( PremiumFocusedWidePriceBlock );
