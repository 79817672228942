import * as React from "react";
import { TeamInvite, TeamMember } from "../../../_types";
import { IconButton } from "../../IconButton";
import { EmailIcon } from "../../svgs/EmailIcon";

export interface ResendTeamItemButtonProps
{
    item: TeamMember | TeamInvite;
}

export interface ResendTeamItemButtonDispatchProps
{
    handleResendItem( item: TeamMember | TeamInvite );
}

export class ResendTeamItemButton extends React.PureComponent<ResendTeamItemButtonProps & ResendTeamItemButtonDispatchProps>
{
    public render()
    {
        return (
            <IconButton onClick={this.onButtonClicked} tooltipLabel={<>Re-send invite</>}>
                <EmailIcon/>
            </IconButton>
        );
    }

    private onButtonClicked = () =>
    {
        this.props.handleResendItem( this.props.item );
    }
}
