import * as React from "react";
import { store } from "../../store";
import { map } from "lodash";
import ExpansionList from "react-md/lib/ExpansionPanels/ExpansionList";
import ExpansionPanel from "react-md/lib/ExpansionPanels/ExpansionPanel";
import List from "react-md/lib/Lists/List";
import { FileUploadButton } from "../FileUploadButton";
import CircularProgress from "react-md/lib/Progress/CircularProgress";
import { ERROR_TITLE_OOPS, getFileExtensionsForCustomMusicAcceptProp, MAX_MUSIC_FILE_UPLOAD_SIZE_IN_BYTE, stringUtils } from "../../helpers";
import { modalServices } from "../../services";
import { MusicDialogTrackingItem } from "./musicPicker/MusicDialogTrackItem";
import { Music } from "../../_types";
import { CUSTOM_MUSIC_CATEGORY } from "../../_types/api";

export interface MoreMusicDialogProps
{
    musicByCategory: { [category: string]: Music[] };
    selectedTrack: Music;
    selectedTrackCategory: string;
    categoryList: string[];
    isCustomMusicUploading: boolean;
    isCustomMusicDownloaded: boolean;
    currentAudioPlayerMusicTrack: Music;
}

export interface MoreMusicDialogDispatchProps
{
    onUploadCustomMusic: ( file: File ) => void;
    updateMusicCatalogSelection: ( track: Music ) => void;
    playTrack: ( musicTrackToPlay: Music ) => void;
}

export class MoreMusicDialog extends React.PureComponent<MoreMusicDialogProps & MoreMusicDialogDispatchProps>
{
    public componentWillUnmount()
    {
        this.props.playTrack( undefined );
    }

    public render()
    {
        const { musicByCategory, categoryList } = this.props;
        return (
            <div className="moreMusicDialogContents">
                <div>
                    <FileUploadButton
                        id="music-upload"
                        primary
                        swapTheming
                        flat
                        className="fullWidth large uploadBtn"
                        label="Select track from my music..."
                        accept={getFileExtensionsForCustomMusicAcceptProp()}
                        icon={null}
                        disabled={this.props.isCustomMusicUploading}
                        maxSize={MAX_MUSIC_FILE_UPLOAD_SIZE_IN_BYTE}
                        onSizeError={this.handleSizeError}
                        onFileUploaded={this.handleOnFileUploaded}/>
                    {
                        this.props.isCustomMusicUploading &&
                        <CircularProgress className="spinner" id="spinner"/>
                    }
                </div>
                <ExpansionList className="categoryList">
                    {
                        map( categoryList, ( category: string ) =>
                        {
                            const shouldExpand = (this.props.isCustomMusicDownloaded && category === CUSTOM_MUSIC_CATEGORY) ||
                                                 category === this.props.selectedTrackCategory;
                            return (
                                <ExpansionPanel label={category} key={category} footer={null} defaultExpanded={shouldExpand}>
                                    <List className="trackList">
                                        {
                                            map( musicByCategory[category], ( track: Music ) =>
                                            {
                                                return <MusicDialogTrackingItem track={track}
                                                                                key={track.id || 0}
                                                                                selectedTrack={this.props.selectedTrack}
                                                                                currentAudioPlayerMusicTrack={this.props.currentAudioPlayerMusicTrack}
                                                                                updateMusicCatalogSelection={this.props.updateMusicCatalogSelection}
                                                                                playTrack={this.props.playTrack}/>;
                                            } )}
                                    </List>
                                </ExpansionPanel>);
                        } )
                    }
                </ExpansionList>
            </div>
        );
    }

    private handleOnFileUploaded = ( file: File ) =>
    {
        this.props.onUploadCustomMusic( file );
    }

    private handleSizeError = ( file: any[] ) =>
    {
        const fileSizeLimit = stringUtils.convertByteToMB( MAX_MUSIC_FILE_UPLOAD_SIZE_IN_BYTE );
        store.dispatch( modalServices.openErrorDialog( ERROR_TITLE_OOPS,
            `Your uploaded file size exceeds our limit of ${fileSizeLimit}MB. Please choose a smaller music file.` ) );
    }
}
