import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Music, StoreState } from "../../../../_types";
import { EpidemicSoundTrackItem, EpidemicSoundTrackItemDispatchProps, EpidemicSoundTrackItemProps } from "../EpidemicSoundTrackItem";
import { epidemicSoundCatalogServices } from "../../../../services/epidemicSoundCatalog.services";
import { EpidemicSoundTrack } from "../../../../_types/api";
import { getEpidemicSoundTrackByIdAsMusic, isEpidemicSoundTrackReady } from "../../../../ducks/epidemicSoundCatalog";
import { store } from "../../../../store";
import { isEpidemicSoundTrackPlaying, isEpidemicSoundTrackSelected } from "../../../../ducks/musicCatalog";
import { isProcessCollectionItemRunning, ProcessCollection } from "../../../../ducks/ui";
import { canUsePremiumMusic, isUserPersonallySubscribed, isUserStripeSubscribed } from "../../../../ducks/user";
import { handleUpTierForNonStripeSubscriber, upsellServices } from "../../../../services";
import { uiActions } from "../../../../actions";
import { SUBSCRIPTION_FLOW_SOURCE_EPIDEMIC_MUSIC_TRACK } from "../../../../helpers";

export interface EpidemicSoundTrackItemContainerProps
{
    epidemicSoundTrack: EpidemicSoundTrack;
    handleMusicClick( music?: Music );
}

const mapStateToProps = ( storeState: StoreState, ownProps: EpidemicSoundTrackItemContainerProps ): EpidemicSoundTrackItemProps =>
{
    const { epidemicSoundTrack } = ownProps;
    return {
        isSelected: isEpidemicSoundTrackSelected( storeState, epidemicSoundTrack ),
        isPlaying: isEpidemicSoundTrackPlaying( storeState, epidemicSoundTrack ),
        isLoading: isProcessCollectionItemRunning( storeState, {
            collection: ProcessCollection.FETCH_EPIDEMIC_SOUND_TRACK,
            id: ownProps.epidemicSoundTrack.epidemic_id,
        } ),
        shouldBeUpsoldToPremium: !canUsePremiumMusic( store.getState() ),
        epidemicSoundTrack,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: EpidemicSoundTrackItemContainerProps ): EpidemicSoundTrackItemDispatchProps =>
{
    return {
        handleEpidemicSoundTrackClick: async ( epidemicSoundTrack: EpidemicSoundTrack ) =>
        {
            if ( !epidemicSoundTrack.is_premium || (epidemicSoundTrack.is_premium && canUsePremiumMusic( store.getState() )) )
            {
                if ( !isEpidemicSoundTrackReady( store.getState(), epidemicSoundTrack.epidemic_id ) )
                {
                    await dispatch( epidemicSoundCatalogServices.downloadEpidemicSoundTrackData( epidemicSoundTrack ) );
                }

                if ( isEpidemicSoundTrackReady( store.getState(), epidemicSoundTrack.epidemic_id ) )
                {
                    const music = getEpidemicSoundTrackByIdAsMusic( store.getState(), epidemicSoundTrack.epidemic_id );
                    ownProps.handleMusicClick( music );
                }
            }
            else
            {
                const storeState = store.getState();
                if ( !isUserPersonallySubscribed( storeState ) || isUserStripeSubscribed( storeState ) )
                {
                    dispatch( uiActions.setPremiumEpidemicSoundPreview( epidemicSoundTrack ) );
                    dispatch( upsellServices.displayGradientHeaderEpidemicTrackPremiumDialog() );
                }
                else
                {
                    handleUpTierForNonStripeSubscriber( dispatch, SUBSCRIPTION_FLOW_SOURCE_EPIDEMIC_MUSIC_TRACK );
                }
            }
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( EpidemicSoundTrackItem );
