import { AddMediaAPIResponse } from "../_types/api";

export const s3Uploader = {
    uploadMedia,
};

function uploadMedia( addMediaData: AddMediaAPIResponse, file: Blob )
{
    const body = new FormData();

    Object.keys( addMediaData.fields ).forEach( ( key ) =>
    {
        body.append( key, addMediaData.fields[key] );
    } );
    body.append( "file", file );

    return fetch( addMediaData.url, {
        method: "POST",
        body,
    } );
}
