import CustomizeTextInputFieldContainer from "../containers/CustomizeTextInputField.container";
import { PRIMARY_TEXT_FIELD_NAME, SECONDARY_TEXT_FIELD_NAME } from "../../../_types/api";
import * as React from "react";
import { TextValue } from "../../../_types";

interface TextPickerPrimarySecondaryFieldsProps
{
    value: TextValue;
}

export function TextPickerPrimarySecondaryFields( props: TextPickerPrimarySecondaryFieldsProps )
{
    const { value } = props;
    return (<>
        <div className="primaryAndSecondaryTextFieldWrap">
            <CustomizeTextInputFieldContainer identifier={PRIMARY_TEXT_FIELD_NAME}
                                              value={value.primaryText}
                                              label="Primary"
                                              placeholder="Add Primary Text"
                                              type={PRIMARY_TEXT_FIELD_NAME}/>
        </div>

        <div className="primaryAndSecondaryTextFieldWrap">
            <CustomizeTextInputFieldContainer identifier={SECONDARY_TEXT_FIELD_NAME}
                                              value={value.secondaryText}
                                              label="Secondary"
                                              placeholder="Add Secondary Text"
                                              type={SECONDARY_TEXT_FIELD_NAME}/>
        </div>
    </>);
}