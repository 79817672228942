import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../_types";
import { ConsentBanner, ConsentBannerDispatchProps, ConsentBannerProps } from "../ConsentBanner";
import { contentBannerServices } from "../../services";
import { getCountryCode } from "../../ducks/tracking";

const mapStateToProps = ( storeState: StoreState ): ConsentBannerProps =>
{
    return {
        countryCode: getCountryCode( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): ConsentBannerDispatchProps =>
{
    return {
        loadUserCountry: () =>
        {
            dispatch( contentBannerServices.retrieveCountryCode() );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( ConsentBanner );
