import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { modalServices } from "../../../services";
import { LightboxDialogIdentifierForKey, NO_COLOR_SELECTED_DEFAULT_VALUE } from "../../../helpers";
import { getEditedBusinessInfo } from "../../../ducks/ui";
import { ColorValue, LightboxContents, StoreState, UserBusiness } from "../../../_types";
import { BrandColorPicker, BrandColorPickerDispatchProps, BrandColorPickerProps } from "../BrandColorPicker";
import SketchPicker from "react-color/lib/components/sketch/Sketch";
import { uiActions } from "../../../actions";
import { ColorResult } from "react-color";

interface BrandColorPickerCommonOwnProps
{
    handleClearColor: ( userBusinessKey: keyof UserBusiness ) => void;
    onColorPickedSuccess?: ( userBusinessKey: keyof UserBusiness, startValue: ColorValue ) => void;
    containerClassName?: string;
}

const mapStateToProps = ( storeState: StoreState ): BrandColorPickerProps =>
{
    return {
        businessData: getEditedBusinessInfo( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: BrandColorPickerCommonOwnProps ): BrandColorPickerDispatchProps =>
{
    return {
        openDialog: ( userBusinessKey: keyof UserBusiness, startValue: ColorValue ) =>
        {
            openSketchPicker( dispatch, userBusinessKey, startValue, ownProps );
        },
        clearColor: ( userBusinessKey: keyof UserBusiness ) =>
        {
            ownProps.handleClearColor( userBusinessKey );
        },
    };
};

function openSketchPicker( dispatch: Dispatch<StoreState>, userBusinessKey: keyof UserBusiness, startValue: string,
                           ownProps: BrandColorPickerCommonOwnProps )
{
    const sketchPickerLightboxProps = getSketchPickerLightboxProps( dispatch, userBusinessKey, startValue, ownProps );
    dispatch( modalServices.openLightbox( sketchPickerLightboxProps ) );
}

function getSketchPickerLightboxProps( dispatch: Dispatch<StoreState>,
                                       userBusinessKey: keyof UserBusiness,
                                       startValue: string,
                                       ownProps: BrandColorPickerCommonOwnProps ): LightboxContents
{
    const dialogContents: React.FC<{}> = () =>
    {
        return <SketchPicker
            color={startValue || NO_COLOR_SELECTED_DEFAULT_VALUE}
            onChangeComplete={colorChangeListener( dispatch, userBusinessKey )}
        />;
    };

    return {
        identifierForKey: LightboxDialogIdentifierForKey.CHOOSE_BRAND_COLOR,
        title: "Choose a color",
        content: dialogContents,
        hideCancel: true,
        showCancelX: true,
        width: 275,
        hideAlternateAction: false,
        alternateActionLabel: "Clear",
        footerClassName: "brandColorPickerFooter",
        onSuccess: () => ownProps.onColorPickedSuccess && ownProps.onColorPickedSuccess( userBusinessKey, startValue ),
        onCancel: () => dispatch( uiActions.updateBusinessInfo( { [userBusinessKey]: startValue } ) ),
        onAlternate: () =>
        {
            ownProps.handleClearColor( userBusinessKey );
        },
    };
}

function colorChangeListener( dispatch: Dispatch<StoreState>, userBusinessKey: keyof UserBusiness )
{
    return ( value: ColorResult ) =>
    {
        dispatch( uiActions.updateBusinessInfo( { [userBusinessKey]: value.hex } ) );
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( BrandColorPicker );
