import * as React from "react";
import { useEffect, useState } from "react";
import { FoundationPresetTabs } from "./FoundationPresetTabs";
import {
    FOUNDATION_PRESET_ID_PREFERRED_BACKGROUND_ANIMATION,
    FOUNDATION_PRESET_ID_PREFERRED_GLOBAL_TEXT_ANIMATION,
    FOUNDATION_PRESET_ID_PREFERRED_GLOBAL_TEXT_STYLE,
    FOUNDATION_PRESET_ID_PREFERRED_SLIDE_TRANSITION,
    FOUNDATION_PRESET_ID_PREFERRED_TEXT_ANIMATION,
    FOUNDATION_PRESET_ID_PREFERRED_TEXT_STYLE,
} from "../../../_types/api";
import { curry, filter, isNil, size } from "lodash";
import {
    BackgroundAnimationChoices,
    DesignElementProperties,
    DesignPresets,
    SlideTransitionChoice,
    SlideTransitionChoices,
    StylizedTextChoices,
    TextAnimationChoices,
} from "../../../_types";
import CircularProgress from "react-md/lib/Progress/CircularProgress";
import { TextAnimationChoicesPanel } from "./TextAnimationChoicesPanel";
import { StylizedTextChoicesPanel } from "./StylizedTextChoicesPanel";
import { SlideTransitionChoicesPanel } from "./SlideTransitionChoicesPanel";
import { SlideTransitionPropertiesPanel } from "./SlideTransitionPropertiesPanel";
import { BackgroundAnimationChoicesPanel } from "./BackgroundAnimationsChoicesPanel";
import { BackgroundAnimationPropertiesPanel } from "./BackgroundAnimationPropertiesPanel";

export interface FoundationPresetsProps
{
    stylizedTextChoices: StylizedTextChoices;
    textAnimationChoices?: TextAnimationChoices;
    globalTextAnimationChoices?: TextAnimationChoices;
    slideTransitionChoices?: SlideTransitionChoices;
    slideTransitionProperties?: DesignElementProperties;
    backgroundAnimationChoices?: BackgroundAnimationChoices;
    backgroundAnimationProperties?: DesignElementProperties;
    designPresets?: DesignPresets;
    isDesignLoading: boolean;
    shouldEnablePresetText: boolean;
    shouldSupportPresetSlideTransitions: boolean;
    shouldSupportBackgroundAnimations: boolean;
}

export interface FoundationPresetsDispatchProps
{
    onDesignLoaded(): void;
    onPreferredKeySelected( key: keyof DesignPresets, value: any ): void;
    onPreferredSlideTransitionSelected( slideTransitionChoice: SlideTransitionChoice ): void;
    onPreferredBackgroundAnimationSelected( slideTransitionChoice: SlideTransitionChoice ): void;
}

export function FoundationPresets( props: FoundationPresetsProps & FoundationPresetsDispatchProps )
{
    const [selectedTabId, setSelectedTabId] = useState<string>( FOUNDATION_PRESET_ID_PREFERRED_TEXT_STYLE );

    useEffect( () =>
    {
        if ( !props.isDesignLoading )
        {
            props.onDesignLoaded();
        }
    }, [props.isDesignLoading] );

    const updateForKey = curry( props.onPreferredKeySelected );

    const preferredAnimation = props.designPresets && props.designPresets.preferredAnimation;
    const preferredTextStyle = props.designPresets && props.designPresets.preferredTextStyle;

    const preferredGlobalTextAnimation = props.designPresets && props.designPresets.preferredGlobalTextAnimation;
    const preferredGlobalTextStyle = props.designPresets && props.designPresets.preferredGlobalTextStyle;

    const preferredSlideTransition = props.designPresets && props.designPresets.preferredSlideTransition;
    const preferredBackgroundAnimation = props.designPresets && props.designPresets.preferredBackgroundAnimation;

    const tabs = {
        [FOUNDATION_PRESET_ID_PREFERRED_TEXT_STYLE]:
            <StylizedTextChoicesPanel preferredTextStyle={preferredTextStyle}
                                      stylizedTextChoices={props.stylizedTextChoices}
                                      onPreferredTextStyleSelected={updateForKey( "preferredTextStyle" )}/>,
        [FOUNDATION_PRESET_ID_PREFERRED_TEXT_ANIMATION]:
            <TextAnimationChoicesPanel preferredAnimation={preferredAnimation}
                                       textAnimationChoices={props.textAnimationChoices}
                                       onPreferredAnimationSelected={updateForKey( "preferredAnimation" )}/>,
        [FOUNDATION_PRESET_ID_PREFERRED_GLOBAL_TEXT_STYLE]:
            <StylizedTextChoicesPanel preferredTextStyle={preferredGlobalTextStyle}
                                      stylizedTextChoices={props.stylizedTextChoices}
                                      onPreferredTextStyleSelected={updateForKey( "preferredGlobalTextStyle" )}/>,
        [FOUNDATION_PRESET_ID_PREFERRED_GLOBAL_TEXT_ANIMATION]:
            <TextAnimationChoicesPanel preferredAnimation={preferredGlobalTextAnimation}
                                       textAnimationChoices={props.globalTextAnimationChoices}
                                       onPreferredAnimationSelected={updateForKey( "preferredGlobalTextAnimation" )}/>,
        [FOUNDATION_PRESET_ID_PREFERRED_SLIDE_TRANSITION]:
            <SlideTransitionChoicesPanel preferredSlideTransition={preferredSlideTransition}
                                         slideTransitionChoices={props.slideTransitionChoices}
                                         onPreferredSlideTransitionSelected={props.onPreferredSlideTransitionSelected}/>,
        [FOUNDATION_PRESET_ID_PREFERRED_BACKGROUND_ANIMATION]:
            <BackgroundAnimationChoicesPanel preferredBackgroundAnimation={preferredBackgroundAnimation}
                                             backgroundAnimationChoices={props.backgroundAnimationChoices}
                                             onPreferredBackgroundAnimationSelected={props.onPreferredBackgroundAnimationSelected}/>,
    };

    if ( !isNil( props.slideTransitionProperties ) && size( props.slideTransitionProperties.categories ) > 0 )
    {
        for ( const category of props.slideTransitionProperties.categories )
        {
            const properties = filter( props.slideTransitionProperties.properties, ( property ) => property.categoryId === category.id );
            tabs[category.id] = <SlideTransitionPropertiesPanel properties={properties} preferredSlideTransition={preferredSlideTransition}
                                                                onPreferredSlideTransitionSelected={props.onPreferredSlideTransitionSelected}/>;
        }
    }

    if ( !isNil( props.backgroundAnimationProperties ) && size( props.backgroundAnimationProperties.categories ) > 0 )
    {
        for ( const category of props.backgroundAnimationProperties.categories )
        {
            const properties = filter( props.backgroundAnimationProperties.properties, ( property ) => property.categoryId === category.id );
            tabs[category.id] = <BackgroundAnimationPropertiesPanel
                properties={properties}
                preferredBackgroundAnimation={preferredBackgroundAnimation}
                onPreferredBackgroundAnimationSelected={props.onPreferredBackgroundAnimationSelected}/>;
        }
    }

    if ( props.isDesignLoading )
    {
        return <CircularProgress className="spinner listPickerSpinner verticallyCentered" id="spinner"/>;
    }
    else
    {
        return <div className={"foundationPresets picker"}>
            <FoundationPresetTabs selectedTabId={selectedTabId}
                                  setSelectedTabId={setSelectedTabId}
                                  slideTransitionProperties={props.slideTransitionProperties}
                                  shouldEnablePresetText={props.shouldEnablePresetText}
                                  shouldSupportPresetSlideTransitions={props.shouldSupportPresetSlideTransitions}
                                  shouldSupportBackgroundAnimations={props.shouldSupportBackgroundAnimations}
                                  backgroundAnimationProperties={props.backgroundAnimationProperties}
            />
            <div className="selectedTabContainer">
                {tabs[selectedTabId]}
            </div>
        </div>;
    }
}
