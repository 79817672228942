import { connect } from "react-redux";
import { Dispatch } from "redux";
import { CreateBlankPostButton, CreateBlankPostButtonDispatchProps } from "../CreateBlankPostButton";
import { mixModelServices } from "../../services";
import { StoreState } from "../../_types";
import { ALL_SOURCE } from "../../helpers";

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): CreateBlankPostButtonDispatchProps =>
{
    return {
        handleClick: () =>
        {
            dispatch( mixModelServices.setupNewPostWithEventing( ALL_SOURCE ) );
        },
    };
};

export default connect(
    () => ({}),
    mapDispatchToProps,
)( CreateBlankPostButton );
