import * as React from "react";
import SchedulePostDialog from "./containers/SchedulePostDialog.container";
import Button from "react-md/lib/Buttons/Button";
import { format } from "date-fns";
import { CrownIcon } from "./svgs/CrownIcon";

export interface SchedulePostWidgetProps
{
    scheduledSendDatetime: string;
    shouldShowPremiumCrown: boolean;
}

export interface SchedulePostWidgetDispatchProps
{
    showSchedulePostDialog: () => void;
}

export class SchedulePostWidget extends React.PureComponent<SchedulePostWidgetProps & SchedulePostWidgetDispatchProps>
{

    public render()
    {
        return (
            <div className="schedulePostWidget">
                <SchedulePostDialog/>
                {this.createButtonAndMaybeDisplayScheduledSendDate()}
            </div>
        );
    }

    private createButtonAndMaybeDisplayScheduledSendDate()
    {
        const { scheduledSendDatetime } = this.props;
        const textValue = scheduledSendDatetime ? format( scheduledSendDatetime, "MMM DD h:mm a" ) : "Schedule for later";
        return this.renderScheduleButton( textValue );
    }

    private showScheduleDialog = () =>
    {
        this.props.showSchedulePostDialog();
    }

    private renderScheduleButton( textValue )
    {
        if ( this.props.shouldShowPremiumCrown )
        {
            return (
                <Button
                    flat secondary swapTheming
                    id="scheduled-date"
                    iconBefore={false}
                    className="large fullWidth scheduleBtn"
                    iconClassName="crownIcon"
                    iconEl={<CrownIcon/>}
                    onClick={this.showScheduleDialog}
                >{textValue}</Button>
            );
        }
        return (
            <Button
                flat secondary swapTheming
                id="scheduled-date"
                className="large fullWidth scheduleBtn"
                onClick={this.showScheduleDialog}
            >{textValue}</Button>
        );
    }
}
