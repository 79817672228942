import { store } from "../store";
import { Dispatch } from "redux";
import {
    apptimizeVariables,
    BUSINESS_ACTION_SOURCE,
    COLLECTION_SOURCE,
    COPY_POST_SOURCE,
    CUSTOMIZE_PAGE_URL,
    DEEP_LINK_SOURCE,
    DESIGN_DESIGN_UNABLE_TO_BE_SUPPORT_VIDEO_COUNT_MESSAGE,
    DESIGN_UNABLE_TO_BE_EDITED_DIALOG_DRAFT_MESSAGE,
    DRAFT_SOURCE,
    ERROR_TITLE_SORRY,
    errorReporting,
    eventTracker,
    history,
    INSPIRE_CATALOG_SOURCE,
    LightboxDialogIdentifierForKey,
    PARTNER_SITE_SOURCE,
    POST_INTENT_SOURCE,
    POST_UNABLE_TO_START,
    postHelper,
    SEARCH_SOURCE,
    urlUtils,
    webFontLoader,
} from "./";
import { getCurrentBusiness } from "../ducks/userBusiness";
import { designsHaveLoaded, getControlsConfigsLookup, getDesign, getDesignFromSlug, getDesignIdFromSlug } from "../ducks/designs";
import { mixModelActions, modalActions, postActions, shareModelActions, StartingMixTypeAndRelatedData, trackingActions, uiActions } from "../actions";
import { catalogServices, createNewPost, modalServices, postServices } from "../services";
import { filter, has, map, size } from "lodash";
import { getSessionIdForPostCustomization } from "../ducks/tracking";
import { getPostById } from "../ducks/post";
import { Design, MixModelState, Post, StartingMixType, StoreState } from "../_types";
import { getDraftById } from "../ducks/draft";
import { getCollectionRowIndex, getCollectionSlug } from "../ducks/collections";
import { getVideoUrlsFromMediaUrlList } from "../ducks/mixModel";
import { getAllFacebookAndFacebookInstagramTypeSocialNetworkAccounts } from "../ducks/user";
import { epidemicSoundCatalogActions } from "../actions/epidemicSoundCatalog.actions";
import { getCollectionPageSource } from "../ducks/ui";
import { PostInputMediaFile } from "../_types/api";

export const customizationHelper = {
    customizePost,
    cleanupCustomizeState,
    openExamplePostFromDeeplink,
    openDesignFromDeeplink,
    shareCancelled,
    openExamplePostFromPartnerSiteWithSlug,
};

function cleanupCustomizeState()
{
    if ( getSessionIdForPostCustomization( store.getState() ) )
    {
        store.dispatch( trackingActions.postSessionEnded() );
        eventTracker.clearUserProperty( "postSessionId" );
        store.dispatch( mixModelActions.mixModelCancelled() );
    }
    store.dispatch( uiActions.designCaptionCleared() );
    store.dispatch( uiActions.slideSummaryClear() );
}

export interface CustomizePostOptions
{
    isRecommendedPost?: boolean;
    isDeepLinkPost?: boolean;
    collectionPostId?: number;
    collectionSlug?: string;
    collectionRowIndex?: number;
    hasAdData?: boolean;
    isFromPartnerSite?: boolean;
    hasAppliedBrandStyles?: boolean;
    isSocialCalendarPost?: boolean;
}

export function shouldBlockCopyingPostWithMoreVideosThanAllowedByDesign( design: Design, postData: Post )
{
    const videoUrlsInPost = getVideoUrlsFromMediaUrlList( postData.input_media_url_list );
    const numberOfVideos = size( videoUrlsInPost );
    return design && numberOfVideos > 0 && numberOfVideos > design.max_video_count_supported;
}

export function shouldBlockCopyingPostWithRetiredDesign( postData: Post )
{
    return !has( getDesignFromSlug( store.getState(), postData.design_slug ), "id" );
}

async function customizePost( postData: Post, dispatch: Dispatch<StoreState>, options: CustomizePostOptions = {} )
{
    if ( !postData )
    {
        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.UNABLE_TO_START_NEW_POST,
            content: POST_UNABLE_TO_START,
            hideCancel: true,
        } ) );
        dispatch( modalActions.loadingDialogClose() );
        eventTracker.logCustomizedPostFailedEvent( { message: "postData for customize was undefined" } );
        return;
    }

    const state = store.getState();

    if ( shouldBlockCopyingPostWithRetiredDesign( postData ) )
    {
        dispatch( modalServices.openErrorDialog( ERROR_TITLE_SORRY, DESIGN_UNABLE_TO_BE_EDITED_DIALOG_DRAFT_MESSAGE ) );
        dispatch( modalActions.loadingDialogClose() );
        eventTracker.logCustomizedPostFailedEvent( { message: "postData contains retired design" } );
        return;
    }

    const designSlug = postData.design_slug;
    const design = getDesign( state, getDesignIdFromSlug( state, designSlug ) );
    if ( shouldBlockCopyingPostWithMoreVideosThanAllowedByDesign( design, postData ) )
    {
        dispatch( modalServices.openErrorDialog( ERROR_TITLE_SORRY, DESIGN_DESIGN_UNABLE_TO_BE_SUPPORT_VIDEO_COUNT_MESSAGE ) );
        dispatch( modalActions.loadingDialogClose() );
        eventTracker.logCustomizedPostFailedEvent( { message: "postData contains more videos than post's design allows" } );
        return;
    }

    const currentBusiness = getCurrentBusiness( state );

    if ( currentBusiness )
    {
        webFontLoader.loadCustomFontIfSpecified( currentBusiness.primary_font_css_url );
        webFontLoader.loadCustomFontIfSpecified( currentBusiness.secondary_font_css_url );
    }

    const { user } = state;

    const controlsConfigsLookup = getControlsConfigsLookup( state );

    if ( apptimizeVariables.shouldDisableFacebookShare() )
    {
        const facebookAndInstagramSocialNetworkAccounts = getAllFacebookAndFacebookInstagramTypeSocialNetworkAccounts( state );
        dispatch( shareModelActions.facebookInstagramSocialNetworksDisabled( { facebookAndInstagramSocialNetworkAccounts } ) );
    }

    if ( !options.hasAppliedBrandStyles )
    {
        dispatch( mixModelActions.mixModelStarted( {
            currentBusiness,
            controlsConfigsLookup,
            user,
        } ) );
    }

    dispatch( epidemicSoundCatalogActions.clearCatalog() );
    await dispatch( catalogServices.selectDesign( design, postData.aspect_ratio ) );

    if ( postHelper.isDraft( postData ) )
    {
        dispatch( mixModelActions.setStartingMixTypeAndRelatedData( DRAFT_SOURCE ) );
        eventTracker.logNewPostFlowStartedEvent( DRAFT_SOURCE );
        dispatch( mixModelActions.resumeDraft( postData ) );
        dispatch( mixModelActions.facebookAdModeSet( false ) );
        history.push( CUSTOMIZE_PAGE_URL );
    }
    else
    {
        try
        {
            await handleEventTrackingAndStartCustomize( dispatch, postData, options );
        }
        catch (e)
        {
            dispatch( modalServices.openLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.UNABLE_TO_START_NEW_POST,
                content: "There was a problem starting your post. Please check your network connection, " +
                         "and contact feedback@ripl.com if problems continue.",
                hideCancel: true,
            } ) );
            eventTracker.logCustomizedPostFailedEvent( e );
        }
    }
}

async function handleEventTrackingAndStartCustomize( dispatch: Dispatch<StoreState>,
                                                     postData: Post,
                                                     options: CustomizePostOptions )
{
    const startingPostId = postData.id;
    const newDraftPostId = await dispatch( postServices.createDraft( startingPostId, false ) );
    const newPostData = updateInputMediaFilesInSourcePostData( postData, newDraftPostId );

    if ( postHelper.isUserPost( newPostData, options ) )
    {
        handleStartingMixType( dispatch, COPY_POST_SOURCE );
        await importUserPostDataIfNecessary( options, dispatch, newPostData );
    }
    else
    {
        handleStartingMixTypeAndRelatedDataForCuratedPost( newPostData, dispatch, startingPostId, options );
        await importCuratedPostDataIfNecessary( options, dispatch, newPostData );
    }

    dispatch( mixModelActions.facebookAdModeSet( false ) );
    history.push( CUSTOMIZE_PAGE_URL );
}

function handleStartingMixTypeAndRelatedDataForCuratedPost( newPostData: Post,
                                                            dispatch: Dispatch<StoreState>,
                                                            startingPostId: number,
                                                            options: CustomizePostOptions )
{
    if ( postHelper.isInspirationPost( newPostData ) )
    {
        const startingMixType = INSPIRE_CATALOG_SOURCE;
        dispatch( mixModelActions.setStartingMixTypeAndRelatedData( startingMixType ) );
        eventTracker.logInspirePostSelectedEvent( startingMixType, newPostData.id );
    }
    else if ( postHelper.isCollectionPost( newPostData ) )
    {
        let startingMixType: StartingMixType = COLLECTION_SOURCE;
        if ( getCollectionPageSource( store.getState() ) === POST_INTENT_SOURCE )
        {
            startingMixType = POST_INTENT_SOURCE;
        }
        const collectionMixModelProperties = buildCollectionMixModelProperties( startingPostId, options );
        handleStartingMixTypeAndRelatedData( dispatch, startingMixType, collectionMixModelProperties );
    }
    else if ( postHelper.isContentSearchPost( newPostData ) )
    {
        const startingMixType = SEARCH_SOURCE;
        const contentSearchMixModelProperties = buildContentSearchMixModelProperties( startingPostId );
        handleStartingMixTypeAndRelatedData( dispatch, startingMixType, contentSearchMixModelProperties );
    }
    else if ( options.isDeepLinkPost )
    {
        handleStartingMixType( dispatch, DEEP_LINK_SOURCE );
    }
    else if ( options.isRecommendedPost )
    {
        handleStartingMixType( dispatch, BUSINESS_ACTION_SOURCE );
    }
    else if ( options.isFromPartnerSite )
    {
        handleStartingMixType( dispatch, PARTNER_SITE_SOURCE );
    }
}

async function importCuratedPostDataIfNecessary( options: CustomizePostOptions, dispatch: Dispatch<StoreState>, newPostData: Post )
{
    if ( !options.hasAppliedBrandStyles )
    {
        await dispatch( mixModelActions.importPostDataFromCuratedPost( newPostData ) );
    }
    else
    {
        await dispatch( mixModelActions.importMediaFilesFromPost( newPostData ) );
    }
}

async function importUserPostDataIfNecessary( options: CustomizePostOptions, dispatch: Dispatch<StoreState>, newPostData: Post )
{
    if ( !options.hasAppliedBrandStyles )
    {
        await dispatch( mixModelActions.importPostDataFromUserPost( newPostData ) );
    }
    else
    {
        await dispatch( mixModelActions.importMediaFilesFromPost( newPostData ) );
    }
}

function handleStartingMixType( dispatch: Dispatch<StoreState>, startingMixType: StartingMixType )
{
    dispatch( mixModelActions.setStartingMixTypeAndRelatedData( startingMixType ) );
    eventTracker.logNewPostFlowStartedEvent( startingMixType );
}

function handleStartingMixTypeAndRelatedData( dispatch: Dispatch<StoreState>,
                                              startingMixType: StartingMixType,
                                              relatedData: Partial<MixModelState> )
{
    const startingMixTypeAndRelatedData: StartingMixTypeAndRelatedData = {
        startingMixType,
        relatedData,
    };
    dispatch( mixModelActions.setStartingMixTypeAndRelatedData( startingMixTypeAndRelatedData ) );
    eventTracker.logNewPostFlowStartedEvent( startingMixType );
}

function buildCollectionMixModelProperties( startingPostId: number, options: CustomizePostOptions ): Partial<MixModelState>
{
    const state = store.getState();
    const collectionPostId = startingPostId || options.collectionPostId;
    const collectionSlug = getCollectionSlug( state ) || options.collectionSlug;
    const collectionRowIndex = options.collectionRowIndex || getCollectionRowIndex( state );

    return {
        collectionPostId,
        collectionSlug,
        collectionRowIndex,
    };
}

function buildContentSearchMixModelProperties( startingPostId: number )
{
    return {
        contentSearchPostId: startingPostId,
    };
}

export function updateInputMediaFilesInSourcePostData( sourcePostData: Post, newDraftPostId: number ): Post
{
    const state = store.getState();
    const newDraft = getDraftById( state, newDraftPostId );
    return {
        ...sourcePostData,
        input_media_files: newDraft.input_media_files,
    };
}

export function updateInputMediaFilesWithoutCreatingDraft( sourcePostData: Post ): Post
{
    const inputMediaUrlList = sourcePostData.input_media_url_list;

    const newMediaFiles: PostInputMediaFile[] = map( inputMediaUrlList, ( inputMediaUrl, index ) =>
    {
        return {
            id: index,
            url: inputMediaUrl,
            isLowRes: false,
            original_file_name: null,
            metadata: null,
        };
    } );

    return {
        ...sourcePostData,
        input_media_files: newMediaFiles,
    };
}

export function containsVideoInputMedia( postData: Post ): boolean
{
    const theImageUrlList = postData.input_media_url_list;
    return filter( theImageUrlList, ( theImageUrl ) => urlUtils.looselyCheckForMP4VideoExtensionInString( theImageUrl ) ).length > 0;
}

export function containsPhotoInputMedia( postData: Post ): boolean
{
    const theImageUrlList = postData.input_media_url_list;
    return filter( theImageUrlList, ( theImageUrl ) => !urlUtils.looselyCheckForMP4VideoExtensionInString( theImageUrl ) ).length > 0;
}

async function openExamplePostFromDeeplink( dispatch, aPostId: string, designSlugFromUrl: string )
{
    try
    {
        await postServices.loadExamplePostWithId( dispatch, aPostId );
        const thePost = getPostById( aPostId, store.getState() );
        eventTracker.logOpenUrlForDesign( aPostId, designSlugFromUrl );

        const customizeOptions = {
            isRecommendedPost: true,
            isDeepLinkPost: true,
        };
        customizationHelper.customizePost( thePost, dispatch, customizeOptions );
    }
    catch (error)
    {
        eventTracker.logOpenPostFromDeeplinkFailed( error );
        errorReporting.reportErrorToSentry( error );
    }
}

async function openExamplePostFromPartnerSiteWithSlug( dispatch, postSlug: string )
{
    try
    {
        const areDesignBeingLoaded = !designsHaveLoaded( store.getState() ) && window.catalogLoadPromise;

        if ( areDesignBeingLoaded )
        {
            await window.catalogLoadPromise;
        }

        const thePostId = await postServices.loadExamplePostWithId( dispatch, postSlug );
        const thePost = getPostById( thePostId, store.getState() );

        const customizeOptions = {
            isFromPartnerSite: true,
        };

        customizationHelper.customizePost( thePost, dispatch, customizeOptions );
    }
    catch (error)
    {
        errorReporting.reportErrorToSentry( error );
        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.UNABLE_TO_START_NEW_POST,
            content: "There was a problem starting your post.",
            hideCancel: true,
        } ) );
    }
}

async function openDesignFromDeeplink( designSlugFromUrl: string )
{
    try
    {
        const designId = getDesignIdFromSlug( store.getState(), designSlugFromUrl );
        const design = getDesign( store.getState(), designId );
        eventTracker.logOpenUrlForDesign( null, designSlugFromUrl );
        store.dispatch( mixModelActions.mixModelCancelled() );
        store.dispatch( postActions.cancelNextDraftsLoad( true ) );
        await store.dispatch( createNewPost( design ) );
        store.dispatch( uiActions.designCanvasRefreshed() );
    }
    catch (error)
    {
        eventTracker.logOpenDesignFromDeeplinkFailed( error );
        errorReporting.reportErrorToSentry( error );
    }
}

function shareCancelled( dispatch: Dispatch<StoreState> )
{
    eventTracker.logPostFlowBackSelectedEvent();

    // dispatch an action to either stop listening or stop server side rendering.
    dispatch( shareModelActions.shareCancelled() );
}
