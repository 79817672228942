import * as React from "react";
import { stringUtils } from "../helpers";

export interface EngagementTypeCountProps
{
    title: string;
    image: string;
    count: number;
}

export class EngagementTypeCount extends React.PureComponent<EngagementTypeCountProps>
{
    public render()
    {
        return (
            <div className="engagementTypeCount">
                <img className="engagementIcon icon small" src={this.props.image}/>
                <div className="engagementNumber text detail">{stringUtils.numberWithCommas( this.props.count )}</div>
            </div>
        );
    }
}
