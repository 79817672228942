import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../_types";
import { getUserDefaultStripePaymentMethod } from "../../../ducks/user";
import DefaultStripePaymentMethod, { DefaultStripePaymentMethodDispatchProps, DefaultStripePaymentMethodProps } from "../DefaultStripePaymentMethod";
import { pricingServices } from "../../../services";
import { store } from "../../../store";

const mapStateToProps = ( storeState: StoreState ): DefaultStripePaymentMethodProps =>
{
    return {
        defaultStripePaymentMethod: getUserDefaultStripePaymentMethod( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): DefaultStripePaymentMethodDispatchProps =>
{
    return {
        onEditDefaultPaymentMethodClicked()
        {
            const { stripe_customer_id } = getUserDefaultStripePaymentMethod( store.getState() );
            dispatch( pricingServices.showUpdateCardDialogFromAccountSettings( stripe_customer_id ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( DefaultStripePaymentMethod );
