import * as React from "react";
import BrandSlideIntro from "./BrandSlideIntro";
import BrandSlideEditPanelContainer from "./containers/BrandSlideEditPanel.container";

export interface BrandSlidePickerProps
{
    hasBrandSlideData: boolean;
}

interface BrandSlidePickerState
{
    hasClickedIntroCTA: boolean;
}

export class BrandSlidePicker extends React.PureComponent<BrandSlidePickerProps, BrandSlidePickerState>
{
    constructor( props )
    {
        super( props );
        this.state = {
            hasClickedIntroCTA: false,
        };
    }

    public render()
    {
        return (
            <div className="brandSlidePicker">
                {this.shouldShowIntro() &&
                 <BrandSlideIntro handleCreateBrandSlideButtonClick={this.handleIntroCTAClicked}/>}
                {!this.shouldShowIntro() && <BrandSlideEditPanelContainer/>}
            </div>
        );
    }

    private handleIntroCTAClicked = () =>
    {
        this.setState(
            {
                hasClickedIntroCTA: true,
            },
        );
    }

    private shouldShowIntro()
    {
        return !this.props.hasBrandSlideData && !this.state.hasClickedIntroCTA;
    }
}
