import { StoreState } from "../../../_types";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import ReferAFriendPage, { ReferAFriendPageDispatchProps } from "../ReferAFriendPage";
import { uiActions } from "../../../actions";
import { COACH_MARK_NAME_KEY_REFER_A_FRIEND_BUTTON } from "../../../_types/api";

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): ReferAFriendPageDispatchProps =>
{
    return {
        clearReferAFriendCoachMark()
        {
            dispatch( uiActions.clearCoachMark( COACH_MARK_NAME_KEY_REFER_A_FRIEND_BUTTON ) );
        },
    };
};

export default connect(
    () => ({}),
    mapDispatchToProps,
)( ReferAFriendPage );
