import * as React from "react";
import FileUpload from "react-md/lib/FileInputs/FileUpload";
import { IdPropType } from "react-md/lib";

export interface FileUploadButtonProps
{
    id: IdPropType;
    style?: React.CSSProperties;
    className?: string;
    accept?: string;
    primary?: boolean;
    secondary?: boolean;
    flat?: boolean;
    raised?: boolean;
    disabled?: boolean;
    multiple?: boolean;
    maxSize?: number;
    label?: React.ReactNode;
    icon?: React.ReactElement<any>;
    iconBefore?: boolean;
    swapTheming?: boolean;
    onSizeError?: ( errorFiles: any[] ) => void;
    onFileUploaded?: ( file: File, result: string ) => void;
    onFilesChanged?: ( files: File | File[] | null ) => void;
    onClick?: ( event: React.MouseEvent<HTMLElement> ) => void;
}

export class FileUploadButton extends React.PureComponent<FileUploadButtonProps>
{
    public render()
    {
        return (
            <FileUpload
                swapTheming={this.props.swapTheming}
                flat={this.props.flat}
                primary={this.props.primary}
                multiple={this.props.multiple}
                id={this.props.id}
                className={this.props.className}
                allowDuplicates
                maxSize={this.props.maxSize}
                label={this.props.label}
                accept={this.props.accept}
                iconBefore={this.props.iconBefore}
                icon={this.props.icon}
                onLoad={this.handleLoad}
                onChange={this.handleChange}
                onClick={this.handleClick}
                disabled={this.props.disabled}
                onSizeError={this.props.onSizeError}
            />
        );
    }

    public handleLoad = ( file: File, result: string ) =>
    {
        if ( this.props.onFileUploaded )
        {
            this.props.onFileUploaded( file, result );
        }
    }

    private handleChange = ( files: File | File[] | null ) =>
    {
        if ( this.props.onFilesChanged )
        {
            this.props.onFilesChanged( files );
        }
    }

    private handleClick = ( event ) =>
    {
        if ( this.props.onClick )
        {
            this.props.onClick( event );
        }
    }
}
