import * as React from "react";
import { SocialCalendarDayData, SocialCalendarPostData } from "../_types";
import { map } from "lodash";
import SocialCalendarCellContainer from "./homepages/containers/SocialCalendarCell.container";
import { convertYYYYMMDDtoDate, getTodayYYYYMMDD } from "../helpers";
import { ScrollingSocialCalendarRow } from "./homepages/ScrollableSocialCalendarRow";
import SocialCalendarDayContainer from "./homepages/containers/socialCalendarDay.container";

export interface SocialCalendarProps
{
    title: string;
    calendar: SocialCalendarDayData[];
    posts: SocialCalendarPostData[];
    scrollToDate?: string;
}

export class SocialCalendar extends React.PureComponent<SocialCalendarProps>
{
    public render()
    {
        return (
            <div className="socialCalendarWrap">
                <div className="socialCalendarTitle">{this.props.title}</div>
                {this.renderSocialCalendar()}
            </div>
        );
    }

    private renderSocialCalendar = () =>
    {
        return (
            <div className="socialCalendar">
                {this.getSocialCalendarDays()}
                {this.getSocialCalendarPosts()}
            </div>
        );
    }

    private getSocialCalendarPosts()
    {
        const posts = this.props.posts;
        const slots = this.getTodayCalendarSlots();
        return <div className="socialCalendarCellWrapper">
            <ScrollingSocialCalendarRow contentWrapperClassName="socialCalendarCells"
                                        scrollToChildIndex={this.getIndexForSelectedDate( this.props.scrollToDate )}>
                {map( slots, ( day, index ) =>
                {
                    const renderModel = {
                        postId: slots[index].id,
                        postData: posts[day.id],
                        slotData: slots[index],
                        date: day.date,
                    };
                    return <SocialCalendarCellContainer key={index} {...renderModel}/>;
                } )}
            </ScrollingSocialCalendarRow>
        </div>;
    }

    private getSocialCalendarDays()
    {
        const days = this.props.calendar;
        return <div className="socialCalendarDayWrapper">
            {map( days, ( day, index ) =>
            {
                const date = convertYYYYMMDDtoDate( index.toString() );
                const dayOfWeek = { weekday: "short" };
                const renderModel = {
                    dayOfWeek: date.getDate().toString(),
                    day: date.toLocaleDateString( "en-US", dayOfWeek ),
                    isHoliday: day.holiday,
                    date,
                };
                return (
                    <SocialCalendarDayContainer key={index}
                                                {...renderModel}/>
                );
            } )}
        </div>;
    }

    private getTodayCalendarSlots = () =>
    {
        const today = getTodayYYYYMMDD();
        const calendar = this.props.calendar;
        return calendar[today].slots;
    }

    private getIndexForSelectedDate = ( date: string ) =>
    {
        const slots = this.getTodayCalendarSlots();
        if ( !slots || !date )
        {
            return 0;
        }
        return slots.findIndex( ( slot ) => convertYYYYMMDDtoDate( slot.date ) >= convertYYYYMMDDtoDate( date ) );
    }
}
