import { ReducerCreator } from "../helpers";
import { Action } from "redux-actions";
import { CollectionRowsState, StoreState } from "../_types";
import { collectionRowsActions } from "../actions";

const defaultState: CollectionRowsState = {
    rows: [],
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( collectionRowsActions.collectionRowsLoadSucceeded, handleCollectionRowsLoadSucceeded );

export default reducerCreator.createReducer();

function handleCollectionRowsLoadSucceeded( state: CollectionRowsState, action: Action<CollectionRowsState> ): CollectionRowsState
{
    return {
        ...state,
        ...action.payload,
    };
}

export const getCollectionRows = ( state: StoreState ) => state.collectionRows.rows;
