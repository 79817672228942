import * as React from "react";
import { BusinessColorKey } from "../_types";
import { BUSINESS_PRIMARY_COLOR_KEY, BUSINESS_SECONDARY_COLOR_KEY } from "../_types/api";
import { ColorBoxEditable } from "./settingsPages/ColorBoxEditable";

export interface BrandColorsControlPickerProps
{
    primaryColor: string;
    secondaryColor: string;
    applyHexColor( colorHex: string );
}

export interface BrandColorsControlPickerDispatchProps
{
    handlePrimaryColorClicked: () => void;
    handleSecondaryColorClicked: () => void;
    handleEditColorClicked: ( userBusinessColorKey: BusinessColorKey, startValue: string ) => void;
    handleClear: ( userBusinessColorKey: BusinessColorKey ) => void;
}

export class BrandColorsControlPicker extends React.PureComponent<BrandColorsControlPickerProps & BrandColorsControlPickerDispatchProps>
{
    public render()
    {
        return (
            <div className="brandColorsControlPickerContainer colorRows">
                <ColorBoxEditable title="Primary brand color"
                                  color={this.props.primaryColor}
                                  handleOpen={this.onEditPrimaryColor}
                                  handleClear={this.onClearPrimaryColor}
                                  handleColorBoxClicked={this.props.primaryColor ? this.props.handlePrimaryColorClicked : this.onEditPrimaryColor}
                                  showColorHex={true}
                                  compact={true}
                                  showAdditionalEditIcon={true}/>
                <ColorBoxEditable title="Secondary brand color"
                                  color={this.props.secondaryColor}
                                  handleOpen={this.onEditSecondaryColor}
                                  handleClear={this.onClearSecondaryColor}
                                  handleColorBoxClicked={this.props.secondaryColor ? this.props.handleSecondaryColorClicked
                                                                                   : this.onEditSecondaryColor}
                                  showColorHex={true}
                                  compact={true}
                                  showAdditionalEditIcon={true}/>
            </div>
        );
    }

    private onEditPrimaryColor = () =>
    {
        this.props.handleEditColorClicked( BUSINESS_PRIMARY_COLOR_KEY, this.props.primaryColor );
    }

    private onEditSecondaryColor = () =>
    {
        this.props.handleEditColorClicked( BUSINESS_SECONDARY_COLOR_KEY, this.props.secondaryColor );
    }

    private onClearPrimaryColor = () =>
    {
        this.props.handleClear( BUSINESS_PRIMARY_COLOR_KEY );
    }

    private onClearSecondaryColor = () =>
    {
        this.props.handleClear( BUSINESS_SECONDARY_COLOR_KEY );
    }

}
