import { ReducerCreator } from "../helpers";
import { Action } from "redux-actions";
import { CollectionData, CollectionState, Post, StoreState } from "../_types";
import { collectionActions } from "../actions";
import { concat, find, forEach } from "lodash";

const defaultState: CollectionState = {
    id: null,
    name: "",
    slug: null,
    description: "",
    posts: [],
    error: false,
    collection_type: "",
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( collectionActions.collectionLoadSucceeded, handleCollectionLoadSucceeded );
reducerCreator.addAction( collectionActions.collectionLoadFailed, handleCollectionLoadFailed );
reducerCreator.addAction( collectionActions.collectionCleared, handleCollectionLoadCleared );
reducerCreator.addAction( collectionActions.collectionRowIndexUpdated, handleCollectionRowIndexUpdated );

export default reducerCreator.createReducer();

function handleCollectionLoadSucceeded( state: CollectionState, action: Action<CollectionData> ): CollectionState
{
    const posts = concat( state.posts || [], action.payload.posts );

    forEach( posts, ( post ) =>
    {
        post.is_collection_post = true;
    } );

    return {
        ...state,
        ...action.payload,
        posts,
        error: false,
    };
}

function handleCollectionLoadFailed( state: CollectionState ): CollectionState
{
    return {
        ...state,
        error: true,
    };
}

function handleCollectionLoadCleared( state: CollectionState ): CollectionState
{
    return {
        ...state,
        ...defaultState,
        error: false,
    };
}

function handleCollectionRowIndexUpdated( state: CollectionState, action: Action<number> ): CollectionState
{
    return {
        ...state,
        collectionRowIndex: action.payload,
    };
}

export function getCollectionPostById( postId: number, state: StoreState ): Post
{
    return find( state.collection.posts, ( post ) => post.id === postId );
}

export const getCollectionPosts = ( state: StoreState ) => state.collection.posts;
export const getCollectionName = ( state: StoreState ) => state.collection.name;
export const getCollectionSlug = ( state: StoreState ) => state.collection.slug;
export const getCollectionRowIndex = ( state: StoreState ) => state.collection.collectionRowIndex;
export const collectionHasError = ( state: StoreState ) => state.collection.error;
