import * as React from "react";
import * as BrandSlideIntroImage from "../../assets/img/brand_slide_intro.png";
import Button from "react-md/lib/Buttons/Button";

interface BrandSlideIntroProps
{
    handleCreateBrandSlideButtonClick: () => void;
}

export default class BrandSlideIntro extends React.PureComponent<BrandSlideIntroProps>
{
    public render()
    {
        return (
            <div className="brandSlideIntro">
                <div className="brandSlideIntroImageWrap">
                    <img className="brandSlideIntroImage" alt="brand slide intro" src={BrandSlideIntroImage}/>
                </div>
                <Button primary flat swapTheming
                        className="createBrandSlideButton"
                        onClick={this.props.handleCreateBrandSlideButtonClick}>
                    Create Brand Slide
                </Button>
            </div>
        );
    }
}
