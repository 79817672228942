import { connect } from "react-redux";
import { Dispatch } from "redux";
import { modalServices } from "../../../services";
import { getFonts } from "../../../ducks/fonts";
import {
    CHOOSE_A_FONT_TITLE,
    getDesignFontName,
    getFontCssUrl,
    LightboxDialogIdentifierForKey,
} from "../../../helpers";
import { FontData, LightboxContents, StoreState } from "../../../_types";
import {
    BrandSettingsFontPickerControl,
    BrandSettingsFontPickerControlDispatchProps,
    BrandSettingsFontPickerControlProps,
} from "../BrandSettingsFontPickerControl";
import { uiActions } from "../../../actions";
import BrandSettingsFontPickerDialog from "./BrandSettingsFontPickerDialog.container";
import { getFontAutocompleteData } from "../../../ducks/ui";
import { store } from "../../../store";
import { BusinessMediaType, FontCssUrlFieldType, FontFieldType, FontUrlFieldType } from "../../../_types/api";

export interface FontUploadProps
{
    fontField: FontFieldType;
    fontUrlField: FontUrlFieldType;
    fontCssUrlField: FontCssUrlFieldType;
    mediaType: BusinessMediaType;
}

export interface BrandSettingsFontPickerControlContainerProps extends FontUploadProps
{
    customFont: FontData;
    customLabel?: string;
    showAdditionalEditIcon?: boolean;
    onUpdatedFont?: () => void;
}

const mapStateToProps = ( storeState: StoreState, ownProps: BrandSettingsFontPickerControlContainerProps ): BrandSettingsFontPickerControlProps =>
{
    const {
        customFont,
        customLabel,
        fontCssUrlField,
        fontField,
        fontUrlField,
        mediaType,
        showAdditionalEditIcon,
    } = ownProps;

    return {
        fontField,
        fontUrlField,
        fontCssUrlField,
        customFont,
        customLabel,
        mediaType,
        showAdditionalEditIcon,
        fonts: getFonts( storeState ),
    };
};

function getLightboxContent( dispatch: Dispatch<StoreState>, ownProps: BrandSettingsFontPickerControlContainerProps ): LightboxContents
{
    return {
        identifierForKey: LightboxDialogIdentifierForKey.CHOOSE_FONT_BRAND_SETTINGS,
        title: CHOOSE_A_FONT_TITLE,
        showCancelX: true,
        hideCancel: true,
        className: "moreFontsDialog",
        content: BrandSettingsFontPickerDialog,
        width: 400,
        hideAlternateAction: false,
        alternateActionLabel: "Clear",
        onSuccess: () =>
        {
            const selectedFont = getFontAutocompleteData( store.getState() ).font;
            return updateFont( dispatch, ownProps, selectedFont );
        },
        onAlternate: async () =>
        {
            return clearFont( dispatch, ownProps );
        },
    };
}

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>,
                             ownProps: BrandSettingsFontPickerControlContainerProps ): BrandSettingsFontPickerControlDispatchProps =>
{
    return {
        openDialog: async () =>
        {
            await dispatch( uiActions.updateFontFontAutocomplete( ownProps.customFont ) );
            dispatch( modalServices.openLightbox( getLightboxContent( dispatch, ownProps ) ) );
        },
        handleFontClicked: ( fontClicked: () => void ) =>
        {
            fontClicked();
        },
    };
};

async function updateFont( dispatch: Dispatch<StoreState>, ownProps: BrandSettingsFontPickerControlContainerProps, font: FontData )
{
    const designFontName = getDesignFontName( font ) || null;
    const fontCssUrl = getFontCssUrl( font ) || null;
    await dispatch( uiActions.updateBusinessInfo( {
        [ownProps.fontField]: designFontName,
        [ownProps.fontUrlField]: null,
        [ownProps.fontCssUrlField]: fontCssUrl,
    } ) );
    return onUpdatedFont( ownProps );
}

function onUpdatedFont( ownProps: BrandSettingsFontPickerControlContainerProps )
{
    if ( ownProps.onUpdatedFont )
    {
        return ownProps.onUpdatedFont();
    }
}

async function clearFont( dispatch: Dispatch<StoreState>, ownProps: BrandSettingsFontPickerControlContainerProps )
{
    return updateFont( dispatch, ownProps, null );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( BrandSettingsFontPickerControl );
