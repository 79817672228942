import { ACCEPT_TEAM_INVITE_PARAM, MP4, MP4_VIDEO_TYPE } from "./";
import { parse, stringify } from "query-string";
import { chain } from "lodash";

const getFilenameFromUrlString = ( urlString: string ): string =>
{
    try
    {
        const url = new URL( urlString );
        return chain( url.pathname ).split( "/" ).last().value();
    }
    catch (e)
    {
        return null;
    }
};

const isMp4VideoUrl = ( urlString: string ): boolean =>
{
    const filenameFromUrlString = getFilenameFromUrlString( urlString );
    return chain( filenameFromUrlString ).toLower().endsWith( MP4 ).value();
};

const looselyCheckForMP4VideoExtensionInString = ( someString: string ): boolean =>
{
    if ( someString )
    {
        return someString.includes( ".mp4" );
    }
    return false;
};

const isLocalhostUrl = ( url: string ): boolean =>
{
    try
    {
        const { hostname } = new URL( url );
        return hostname === "localhost";
    }
    catch (e)
    {
        return false;
    }
};

const isInputFileTypeMP4 = ( inputFileType: string ): boolean =>
{
    return inputFileType === MP4_VIDEO_TYPE;
};

const hasAcceptTeamInviteParam = () =>
{
    return window.location.search.includes( ACCEPT_TEAM_INVITE_PARAM );
};

const clearCouponQueryParam = ( locationSearch ) =>
{
    const params = { code: undefined };
    return modifyQueryParamsInUrlSearch( locationSearch, params );
};

const modifyQueryParamsInUrlSearch = ( locationSearch, modifiedQueryParamsAsObject: object ) =>
{
    const currentQueryParams = parse( locationSearch );
    const newQueryParamsObj = { ...currentQueryParams, ...modifiedQueryParamsAsObject };
    return stringify( newQueryParamsObj );
};

const openExternalUrl = ( url: string ) =>
{
    window.open( url, "_blank" );
};

export const urlUtils = {
    getFilenameFromUrlString,
    isMp4VideoUrl,
    looselyCheckForMP4VideoExtensionInString,
    isLocalhostUrl,
    isInputFileTypeMP4,
    hasAcceptTeamInviteParam,
    clearCouponQueryParam,
    modifyQueryParamsInUrlSearch,
    openExternalUrl,
};
