import { createAction } from "redux-actions";
import { ActionCreator } from "../helpers";
import {
    AspectRatio,
    CommonInputData,
    Design,
    DesignOutputMode,
    DesignSelectedData,
    DesignTemplateSettings,
    GlobalSettings,
    MediaSourceType,
    MixModelState,
    MusicSelection,
    OriginalTrimmerData,
    Post,
    StartingMixType,
    TemplateControlData,
    TemplateInputBrandSlideData,
    TemplateInputData,
    UserBusiness,
    UserState,
    VideoTrimmerCurrentData,
} from "../_types";
import { AddMediaAPIResponse, BusinessMediaType, ControlsConfig, PostMediaType } from "../_types/api";
import { DesignControlsJSONData } from "./";

const actionCreator = new ActionCreator( "MIX_MODEL" );

const mixModelActionsTypes = {
    controlData: {
        updated: actionCreator.generateType( "CONTROL_DATA", "UPDATED" ),
        compute: actionCreator.generateType( "CONTROL_DATA", "COMPUTED" ),
    },
    templateInput: {
        updated: actionCreator.generateType( "TEMPLATE_INPUT", "UPDATED" ),
    },
    brandSlideData: {
        updated: actionCreator.generateType( "BRAND_SLIDE_DATA", "UPDATED" ),
    },
    brandSettings: {
        updated: actionCreator.generateType( "BRAND_SETTINGS", "UPDATED" ),
        apply: actionCreator.generateType( "BRAND_SETTINGS", "APPLY" ),
    },
    templateSettings: {
        saved: actionCreator.generateType( "TEMPLATE_SETTINGS", "SAVED" ),
    },
    globalSettings: {
        saved: actionCreator.generateType( "GLOBAL_SETTINGS", "SAVED" ),
    },
    commonInputData: {
        updated: actionCreator.generateType( "COMMON_INPUT_DATA", "UPDATED" ),
    },
    mixModel: {
        started: actionCreator.generateType( "POST", "STARTED" ),
        cancelled: actionCreator.generateType( "POST", "CANCELLED" ),
    },
    postData: {
        resumeDraft: actionCreator.generateType( "POST_DATA", "RESUME_DRAFT" ),
        userPost: actionCreator.generateType( "POST_DATA", "USER_POST_IMPORTED" ),
        curatedPost: actionCreator.generateType( "POST_DATA", "CURATED_POST_IMPORTED" ),
        populateShareModelFromPost: actionCreator.generateType( "POST_DATA", "POPULATE_SHARE_MODEL_FROM_POST" ),
        mediaFilesFromPost: actionCreator.generateType( "POST_DATA", "MEDIA_FILES_FROM_POST" ),
    },
    design: {
        hasChanged: actionCreator.generateType( "DESIGN", "HAS_CHANGED" ),
        selected: actionCreator.generateType( "DESIGN", "SELECTED" ),
        aspectRatioSelected: actionCreator.generateType( "DESIGN", "ASPECT_RATIO_SELECTED" ),
    },
    startingDesign: {
        set: actionCreator.generateType( "STARTING_DESIGN", "SET" ),
    },
    music: {
        selected: actionCreator.generateType( "MUSIC", "SELECTED" ),
        cleared: actionCreator.generateType( "MUSIC", "CLEARED" ),
    },
    addMediaFile: actionCreator.generateTypes( "MEDIA_FILE" ),
    watermark: {
        removed: actionCreator.generateType( "WATERMARK", "REMOVED" ),
        enabled: actionCreator.generateType( "WATERMARK", "ENABLED" ),
    },
    media: {
        move: actionCreator.generateType( "MEDIA", "MOVE" ),
        remove: actionCreator.generateType( "MEDIA", "REMOVE" ),
    },
    videoConvertedData: {
        uploaded: actionCreator.generateType( "VIDEO_CONVERTED_DATA", "UPLOADED" ),
        cleared: actionCreator.generateType( "VIDEO_CONVERTED_DATA", "CLEARED" ),
    },
    videoAudio: {
        selectionUpdated: actionCreator.generateType( "VIDEO_AUDIO", "SELECTION_UPDATED" ),
    },
    videoTrimmer: {
        videoUploaded: actionCreator.generateType( "VIDEO_TRIMMER", "VIDEO_UPLOADED" ),
        started: actionCreator.generateType( "VIDEO_TRIMMER", "STARTED" ),
        statusChanged: actionCreator.generateType( "VIDEO_TRIMMER", "STATUS_CHANGED" ),
        closed: actionCreator.generateType( "VIDEO_TRIMMER", "CLOSED" ),
        originalDataSaved: actionCreator.generateType( "VIDEO_TRIMMER", "ORIGINAL_DATA_SAVED" ),
    },
    startingMixType: {
        set: actionCreator.generateType( "STARTING_MIX_TYPE", "SET" ),
    },
    designOutputMode: {
        set: actionCreator.generateType( "DESIGN_OUTPUT_MODE", "SET" ),
    },
    facebookAdMode: {
        set: actionCreator.generateType( "FACEBOOK_AD_MODE", "SET" ),
    },
    addMediaSource: actionCreator.generateType( "MEDIA_SOURCE", "ADDED" ),
    logoOverride: {
        clear: actionCreator.generateType( "LOGO_OVERRIDE", "CLEAR" ),
    },
    originalMusic: {
        clear: actionCreator.generateType( "ORIGINAL_MUSIC", "CLEAR" ),
    },
};

export interface MixModelStartPayload extends ComputeControlPayload
{
    user: UserState;
}

export interface ComputeControlPayload
{
    currentBusiness: UserBusiness;
    controlsConfigsLookup: ControlsConfigsLookup;
}

export interface ControlsConfigsLookup
{
    [designId: number]: ControlsConfig[];
}

export interface MediaSwapPayload
{
    oldIndex: number;
    newIndex: number;
}

export interface DesignSettingsSavePayload
{
    design: Design;
    settings: DesignTemplateSettings;
    designAspectRatio: AspectRatio;
}

export interface ImageUploadData
{
    uploadFields: AddMediaAPIResponse;
    fileSize?: number;
    isLowRes: boolean;
    insertAtIndex?: number;
    replaceAtIndex?: number;
}

export interface StartingMixTypeAndRelatedData
{
    startingMixType: StartingMixType;
    relatedData?: Partial<MixModelState>;
}

export declare type SetStartingMixTypeAndRelatedDataPayload = StartingMixType | StartingMixTypeAndRelatedData;

export interface VideoTrimmerDataSavedPayload
{
    originalTrimmerData: OriginalTrimmerData;
    mediaId: string;
}

export const mixModelActions = {
    templateInputUpdated: createAction<Partial<TemplateInputData>>( mixModelActionsTypes.templateInput.updated ),
    brandSlideDataUpdated: createAction<Partial<TemplateInputBrandSlideData>>( mixModelActionsTypes.brandSlideData.updated ),
    controlDataUpdated: createAction<TemplateControlData>( mixModelActionsTypes.controlData.updated ),
    brandSettingsUpdated: createAction<DesignControlsJSONData>( mixModelActionsTypes.brandSettings.updated ),
    applyBrandSettings: createAction<UserBusiness>( mixModelActionsTypes.brandSettings.apply ),
    templateSettingsSaved: createAction<DesignSettingsSavePayload>( mixModelActionsTypes.templateSettings.saved ),
    globalSettingsSaved: createAction<GlobalSettings>( mixModelActionsTypes.globalSettings.saved ),
    commonInputDataUpdated: createAction<CommonInputData>( mixModelActionsTypes.commonInputData.updated ),
    computeControlData: createAction<ComputeControlPayload>( mixModelActionsTypes.controlData.compute ),
    mixModelStarted: createAction<MixModelStartPayload>( mixModelActionsTypes.mixModel.started ),
    mixModelCancelled: createAction( mixModelActionsTypes.mixModel.cancelled ),
    importPostDataFromCuratedPost: createAction<Post>( mixModelActionsTypes.postData.curatedPost ),
    populateShareModelFromPost: createAction<Post>( mixModelActionsTypes.postData.populateShareModelFromPost ),
    importPostDataFromUserPost: createAction<Post>( mixModelActionsTypes.postData.userPost ),
    importMediaFilesFromPost: createAction<Post>( mixModelActionsTypes.postData.mediaFilesFromPost ),
    resumeDraft: createAction<Post>( mixModelActionsTypes.postData.resumeDraft ),
    designSelected: createAction<DesignSelectedData>( mixModelActionsTypes.design.selected ),
    designHasChanged: createAction( mixModelActionsTypes.design.hasChanged ),
    startingDesignSet: createAction<Design>( mixModelActionsTypes.startingDesign.set ),
    designAspectRatioSelected: createAction<AspectRatio>( mixModelActionsTypes.design.aspectRatioSelected ),
    addMediaFileRequest: createAction<PostMediaType | BusinessMediaType>( mixModelActionsTypes.addMediaFile.request ),
    addMediaFileSuccess: createAction<ImageUploadData>( mixModelActionsTypes.addMediaFile.success ),
    addMediaFileFailure: createAction<PostMediaType | BusinessMediaType>( mixModelActionsTypes.addMediaFile.failure ),
    musicSelected: createAction<MusicSelection>( mixModelActionsTypes.music.selected ),
    musicCleared: createAction( mixModelActionsTypes.music.cleared ),
    watermarkRemoved: createAction( mixModelActionsTypes.watermark.removed ),
    watermarkEnabled: createAction( mixModelActionsTypes.watermark.enabled ),
    mediaMove: createAction<MediaSwapPayload>( mixModelActionsTypes.media.move ),
    mediaRemove: createAction<number>( mixModelActionsTypes.media.remove ),
    videoAudioSelectionUpdated: createAction<boolean>( mixModelActionsTypes.videoAudio.selectionUpdated ),
    videoConvertedDataUploaded: createAction<AddMediaAPIResponse>( mixModelActionsTypes.videoConvertedData.uploaded ),
    videoConvertedDataCleared: createAction( mixModelActionsTypes.videoConvertedData.cleared ),
    videoTrimmerStarted: createAction<string>( mixModelActionsTypes.videoTrimmer.started ),
    videoTrimmerStatusChanged: createAction<VideoTrimmerCurrentData>( mixModelActionsTypes.videoTrimmer.statusChanged ),
    videoTrimmerClosed: createAction( mixModelActionsTypes.videoTrimmer.closed ),
    videoTrimmerOriginalDataSaved: createAction<VideoTrimmerDataSavedPayload>( mixModelActionsTypes.videoTrimmer.originalDataSaved ),
    setStartingMixTypeAndRelatedData: createAction<SetStartingMixTypeAndRelatedDataPayload>( mixModelActionsTypes.startingMixType.set ),
    designOutputModeSet: createAction<DesignOutputMode>( mixModelActionsTypes.designOutputMode.set ),
    facebookAdModeSet: createAction<boolean>( mixModelActionsTypes.facebookAdMode.set ),
    addMediaSource: createAction<MediaSourceType>( mixModelActionsTypes.addMediaSource ),
    logoOverrideClear: createAction( mixModelActionsTypes.logoOverride.clear ),
    originalMusicClear: createAction( mixModelActionsTypes.originalMusic.clear ),
};
