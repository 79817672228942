import * as React from "react";
import * as starImg from "../assets/img/star.png";
import { stringUtils } from "../helpers";

export interface GraphBarProps
{
    value: number;
    metGoal: boolean;
    current: boolean;
    maxValue: number;
    label: string;
}

export class GraphBar extends React.PureComponent<GraphBarProps, undefined>
{
    public render()
    {
        const classNames = ["bar"];
        if ( this.props.current )
        {
            classNames.push( "current" );
        }
        if ( this.props.metGoal )
        {
            classNames.push( "metGoal" );
        }
        const heightPct = this.props.value * 100 / (this.props.maxValue || 1);
        return (
            <div className="graphBar">
                <div className="barContainer">
                    <div className="count detail center" style={{ bottom: heightPct + "%" }}>{stringUtils.abbreviateNumber( this.props.value )}</div>
                    <div className={classNames.join( " " )} style={{ top: (100 - heightPct) + "%" }}>
                        {
                            this.props.metGoal && (
                                <img className="star" src={starImg}/>
                            )
                        }
                    </div>
                </div>
                <div className="xLabel detail center">
                    {this.props.label}
                </div>
            </div>
        );
    }
}
