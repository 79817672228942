import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../_types";
import { NoticeBanner, NoticeBannerDispatchProps, NoticeBannerProps } from "../NoticeBanner";
import { getFirstSupportedNotice } from "../../../ducks/user";
import { NOTICE_ACTION_NAME_OPEN_URL, NOTICE_ACTION_NAME_SUBSCRIBE, SupportedNoticeActionName } from "../../../_types/api";
import { upsellServices } from "../../../services";
import { eventTracker, SUBSCRIPTION_FLOW_SOURCE_IN_APP_NOTICE_SUBSCRIBE } from "../../../helpers";

const mapStateToProps = ( storeState: StoreState ): NoticeBannerProps =>
{
    return {
        notice: getFirstSupportedNotice( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): NoticeBannerDispatchProps =>
{
    return {
        handleNoticeAction: ( noticeActionName: SupportedNoticeActionName, noticeActionUrl?: string ) =>
        {
            eventTracker.logEyebrowClicked( noticeActionName );

            if ( NOTICE_ACTION_NAME_SUBSCRIBE === noticeActionName )
            {
                dispatch( upsellServices.displayTryProNoWarmup( SUBSCRIPTION_FLOW_SOURCE_IN_APP_NOTICE_SUBSCRIBE ) );
            }

            if ( NOTICE_ACTION_NAME_OPEN_URL === noticeActionName )
            {
                const newWindow = window.open( noticeActionUrl, "_blank", "noopener,noreferrer" );
                if ( newWindow )
                {
                    newWindow.opener = null;
                }
            }
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( NoticeBanner );
