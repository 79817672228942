import * as React from "react";
import classNames from "classnames";
import { getMonthlyAmount, isAnnualPlan, isMonthlyPlan } from "../../ducks/pricing";
import { dollarsFormattedForCurrency, LOCALE_US, NON_BREAKING_SPACE, RIPL_TIER_PREMIUM, USD_CODE } from "../../helpers";
import { getLocaleForCurrency } from "../../helpers/currencyHelper";
import { RoundedCornerCheckmark } from "../homepages/RoundedCornerCheckmark";
import { lowerCase, upperFirst } from "lodash";
import { CrownIcon } from "../svgs/CrownIcon";

export interface PremiumFocusedWidePriceBlockProps
{
  tier: ProductTier;
  plan: StripePlanData;
  selected: boolean;
  updatePlanSelection: ( plan: StripePlanData, tier: ProductTier ) => void;
  currencyCode?: CurrencyCode;
  currentExchangeRate?: number;
  shouldShowExchangeRates?: boolean;
}

export class PremiumFocusedWidePriceBlock extends React.PureComponent<PremiumFocusedWidePriceBlockProps>
{
  public render()
  {
    const priceBlockClassNames = classNames( "premiumFocusedWidePriceBlock", {
      priceBlockSelected: this.props.selected,
      testDriveMargin: false,
    } );

    return (
      <div className={priceBlockClassNames} onClick={this.handleClick}>
        <div className="planDescription">
          {this.props.children}
          {this.getDurationLabel()}
          {this.getPriceAmountText()}
          {this.props.selected && <RoundedCornerCheckmark/>}
        </div>
      </div>
    );
  }

  private getDurationLabel = () =>
  {
    const isNonUSD = this.getIsNonUSD();
    const convertedMonthlyAmount = this.convertUSDAmountToSelectedCurrency( this.props.plan.originalAmount );
    const localeFormattedPrice = this.getLocaleFormattedPrice( convertedMonthlyAmount );
    let durationText = `12 MO - ${localeFormattedPrice}`;

    const isCouponApplied = this.props.plan.coupon_details != null;
    const couponAppliedConvertedMonthlyAmount = this.convertUSDAmountToSelectedCurrency( this.props.plan.amount );
    const couponAppliedLocaleFormattedPrice = this.getLocaleFormattedPrice( couponAppliedConvertedMonthlyAmount );
    let couponAppliedDurationText = `12 MO - ${couponAppliedLocaleFormattedPrice}`;
    const showsPriceInDurationLabel = !isMonthlyPlan( this.props.plan );

    if ( isMonthlyPlan( this.props.plan ) )
    {
      durationText = "1 MONTH";
      couponAppliedDurationText = "1 MONTH";
    }

    const planName = `${upperFirst( lowerCase( this.props.tier ) )}${NON_BREAKING_SPACE}Plan`;

    return (
      <div>
        <div className="priceBlockTypeText">
          {planName}
          {this.props.tier === RIPL_TIER_PREMIUM && <CrownIcon/>}
        </div>
        <div className="yearlyPrices">
          <div className={classNames( "annualPrice", { nonUSD: isNonUSD, strikethrough: isCouponApplied && showsPriceInDurationLabel } )}>
            {durationText}
          </div>
        </div>
        {
          isCouponApplied && showsPriceInDurationLabel
          && <div className="yearlyPrices">
            <div className={classNames( "annualPrice", { nonUSD: isNonUSD } )}>
              {couponAppliedDurationText}
            </div>
          </div>
        }
      </div>
    );
  }

  private getIsNonUSD = () =>
  {
    return this.props.currencyCode !== USD_CODE;
  }

  private getPriceAmountText = () =>
  {
    const monthlyDetails: {
      amount: number;
      originalAmount: number
    } = getMonthlyAmount( this.props.plan );
    const convertedMonthlyAmount = this.convertUSDAmountToSelectedCurrency( monthlyDetails.amount );
    const localeFormattedMonthlyPrice = this.getLocaleFormattedPrice( convertedMonthlyAmount );

    const isNonUSD = this.getIsNonUSD();
    const isAnnual = isAnnualPlan( this.props.plan );
    const priceBlockAmountTextClassNames = classNames( "priceBlockAmountText",
      { nonUSD: isNonUSD, annual: isAnnual } );

    return (
      <>
        <div className={priceBlockAmountTextClassNames}>{localeFormattedMonthlyPrice}/mo</div>
      </>
    );
  }

  private convertUSDAmountToSelectedCurrency = ( amountInUSD: number ): number =>
  {
    if ( this.shouldShowConvertedCurrency() )
    {
      const conversionRate = this.props.currentExchangeRate;
      return amountInUSD * conversionRate;
    }
    return amountInUSD;
  }

  private handleClick = () =>
  {
    this.props.updatePlanSelection( this.props.plan, this.props.tier );
  }

  private getLocaleFormattedPrice = ( amount: number ): string =>
  {
    if ( this.shouldShowConvertedCurrency() )
    {
      return dollarsFormattedForCurrency( getLocaleForCurrency( this.props.currencyCode ), this.props.currencyCode, amount );
    }
    return dollarsFormattedForCurrency( LOCALE_US, USD_CODE, amount );
  }

  private shouldShowConvertedCurrency = () =>
  {
    return this.props.shouldShowExchangeRates;
  }
}
