import * as React from "react";
import { ExternalMediaData } from "../../_types";
import CircularProgress from "react-md/lib/Progress/CircularProgress";

export interface ExternalMediaCellProps
{
    externalMediaData: ExternalMediaData;
    onExternalMediaClicked: ( data: ExternalMediaData ) => void;
    externalMediaUploadStatus: { [id: string]: boolean };
}

export class ExternalMediaCell extends React.PureComponent<ExternalMediaCellProps>
{
    private playPromise;
    private pausePromise;

    public render()
    {
        const { preview_video_url, thumbnail_url } = this.props.externalMediaData;
        const shouldShowProgress = this.props.externalMediaUploadStatus
                                   && this.props.externalMediaUploadStatus[this.props.externalMediaData.external_media_id];
        return (
            <div className="externalMediaCellContainer" onContextMenu={this.handleOnContextMenu}>
                {shouldShowProgress && this.createProgressSpinner()}
                {!!preview_video_url ? <video
                    ref="videoRef"
                    src={preview_video_url}
                    muted={true}
                    autoPlay={false}
                    preload="none"
                    loop
                    style={{
                        backgroundImage: `url(${thumbnail_url}`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                    }}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                    onClick={this.onExternalMediaClicked}
                    draggable={false}>
                    Sorry, your browser doesn't support videos.
                </video> : <img draggable={false}
                                src={thumbnail_url}
                                onClick={this.onExternalMediaClicked}/>}
            </div>
        );
    }

    private createProgressSpinner = () =>
    {
        return (
            <>
                <CircularProgress className="cellSpinner" id="spinner"/>
                <div className="overlay"/>
            </>
        );
    }

    private handleOnContextMenu = ( e ) => e.preventDefault();

    private onExternalMediaClicked = () =>
    {
        return this.props.onExternalMediaClicked( this.props.externalMediaData );
    }

    private onMouseEnter = () =>
    {
        const { videoRef } = this.refs;
        if ( videoRef )
        {
            const htmlVideo = videoRef as HTMLVideoElement;
            htmlVideo.currentTime = 0;
            if ( this.pausePromise )
            {
                this.playPromise = this.pausePromise.then( () => htmlVideo && htmlVideo.play() );
                this.pausePromise = null;
            }
            else
            {
                this.playPromise = htmlVideo.play();
            }
        }
    }

    private onMouseLeave = () =>
    {
        if ( this.refs.videoRef )
        {
            this.stopVideo();
        }
    }

    private stopVideo = () =>
    {
        const { videoRef } = this.refs;
        const htmlVideo = videoRef as HTMLVideoElement;
        if ( htmlVideo )
        {
            htmlVideo.currentTime = 0;
            if ( this.playPromise )
            {
                this.pausePromise = this.playPromise.then( () => htmlVideo && htmlVideo.pause() );
            }
            this.playPromise = null;
        }
    }

}
