import { connect } from "react-redux";
import { Dispatch } from "redux";
import { userServices } from "../../services";
import ForgotPasswordForm, { ForgotPasswordFormDispatchProps, ForgotPasswordFormProps } from "../ForgotPasswordForm";
import { StoreState } from "../../_types";

const mapStateToProps = ( state: StoreState, ownProps: ForgotPasswordFormProps ): ForgotPasswordFormProps =>
{
    return ownProps;
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): ForgotPasswordFormDispatchProps =>
{
    return {
        onForgotPasswordEmail: ( emailAddress: string ) =>
        {
            dispatch( userServices.forgotPassword( emailAddress ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( ForgotPasswordForm );
