import { Dispatch } from "redux";
import { StoreState } from "../_types";
import { requests } from "../helpers";
import { surveyActions } from "../actions";
import { SurveyAPIData, SurveyAPIResponse } from "../_types/api/survey";

export const surveyServices = { loadCancelSurvey, sendSurveyResponse};

const CANCEL_SURVEY_URL = "/survey/cancel";
const CANCEL_SURVEY_RESPONSE_URL = "/survey/cancel/response";

function loadCancelSurvey()
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        return requests.get( CANCEL_SURVEY_URL ).then(
            ( data: SurveyAPIData ) =>
            {
                dispatch( surveyActions.surveyLoadSucceeded( data ) );
            }, () =>
            {
                dispatch( surveyActions.surveyLoadFailed() );
            },
        );
    };
}

function sendSurveyResponse(response: SurveyAPIResponse)
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        return requests.post<SurveyAPIResponse, any>( CANCEL_SURVEY_RESPONSE_URL, response ).then(
            ( data ) =>
            {
                // dispatch
            }, () =>
            {
                // dispatch
            },
        );
    };
}
