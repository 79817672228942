import * as React from "react";
import * as RiplCheckmark from "../assets/img/checkmark.png";
import * as TeamIcon from "../assets/img/team-icon.svg";

export interface ListItemRightAvatarProps
{
    selected: boolean;
    showTeamIconAndBadge: boolean;
}

export class ListItemRightAvatar extends React.PureComponent<ListItemRightAvatarProps>
{
    public render()
    {
        return (
            <div className="ListItemRightAvatar">
                {this.props.showTeamIconAndBadge && <img className="teamIcon" src={TeamIcon} alt="team Icon"/>}
                {this.props.showTeamIconAndBadge && <div className="teamLabel">TEAM</div>}
                {this.props.selected && <img className="checkmark" src={RiplCheckmark} alt="business selected"/>}
            </div>
        );
    }
}
