import * as React from "react";
import { injectStripe, ReactStripeElements } from "react-stripe-elements";
import {
    UPDATE_CARD_FORM_SOURCE_ACCOUNT_SUBSCRIPTION,
    UpdateCardFormSource,
} from "../helpers";
import CardFormError from "./containers/CardFormError.container";
import { CardForm } from "./CardForm";
import InjectedStripeProps = ReactStripeElements.InjectedStripeProps;
import StripeProps = ReactStripeElements.StripeProps;

export interface UpdateCardFormProps extends InjectedStripeProps
{
    stripeCustomerId: string;
    isCardProcessing: boolean;
    source: UpdateCardFormSource;
    isStripeSubscriber: boolean;
}

export interface UpdateCardFormDispatchProps
{
    onUpdateCardClicked( stripeCustomerId: string, stripe: StripeProps, reCaptchaToken: string ): void;
    clearError(): void;
}

class UpdateCardForm extends React.PureComponent<UpdateCardFormProps & UpdateCardFormDispatchProps>
{
    public componentDidMount()
    {
        this.props.clearError();
    }

    public render()
    {
        const buttonText = this.buttonText();
        return (
            <div className="paymentForm">
                <div>
                    <h2>Payment method</h2>
                    {this.getDialogDescription()}
                </div>
                <CardFormError/>
                <CardForm disabled={this.props.isCardProcessing}
                          buttonText={buttonText}
                          onChange={this.handleReCAPTCHAOnChange}
                          clearError={this.props.clearError}/>
            </div>);
    }

    private buttonText = () =>
    {
        return "Change card";
    }

    private handleReCAPTCHAOnChange = ( reCaptchaToken: string ) =>
    {
        this.props.onUpdateCardClicked( this.props.stripeCustomerId, this.props.stripe, reCaptchaToken );
    }

    private getDialogDescription = () =>
    {
        if ( this.props.isStripeSubscriber )
        {
            if ( this.props.source === UPDATE_CARD_FORM_SOURCE_ACCOUNT_SUBSCRIPTION )
            {
                return <p>This card will become the payment source for your subscription.</p>;
            }
        }
    }
}

export default injectStripe( UpdateCardForm );
