import * as React from "react";
import { APP_LAUNCHED_3, ENABLE_USAGE_TRACKING, ENV_CONFIG } from "../helpers";
import * as amplitude from "amplitude-js";
import { AmplitudeProvider, LogOnMount } from "@amplitude/react-amplitude";
import * as queryString from "querystring";

export const AmplitudeWrapper: React.FC<any> = ( props ) =>
{
    if ( ENABLE_USAGE_TRACKING )
    {
        return (
            <AmplitudeProvider
                amplitudeInstance={amplitude.getInstance()}
                apiKey={ENV_CONFIG.amplitudeKey}
                deviceId={getDeviceId()}>
                <LogOnMount eventType={APP_LAUNCHED_3}/>
                {props.children}
            </AmplitudeProvider>
        );
    }
    else
    {
        return React.Children.only( props.children );
    }
};

function getDeviceId()
{
    const theParsedParams = queryString.parse( window.location.search );
    return theParsedParams.amplitudeDeviceId;
}
