import { StoreMigration } from "../../_types";

// @ts-ignore
// FacebookAdsState has been removed from the codebase; only the migration remains
export const facebookAdsMigrations: StoreMigration<FacebookAdsState> = {
    5: ( state ) =>
    {
        if ( state.adRowsSort )
        {
            return {
                ...state,
            };
        }
        else
        {
            const defaultAdRowsSort = {
                isAscending: false,
                sortType: "status",
            };
            return {
                ...state,
                adRowsSort: defaultAdRowsSort,
            };
        }
    },
};
