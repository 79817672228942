import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../_types";
import { SlideSummaryPanel, SlideSummaryPanelDispatchProps, SlideSummaryPanelProps } from "../SlideSummaryPanel";
import { getCustomizableCanvas, getSlideSummary } from "../../ducks/ui";
import { store } from "../../store";
import { SortEnd, SortEvent } from "react-sortable-hoc";

const mapStateToProps = ( storeState: StoreState ): SlideSummaryPanelProps =>
{
    return {
        slideSummary: getSlideSummary( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): SlideSummaryPanelDispatchProps =>
{
    return {
        selectSlide: ( slideId: string ) =>
        {
            const state = store.getState();
            const canvas = getCustomizableCanvas( state );
            if ( canvas )
            {
                canvas.tellDesignToJumpToSlide( slideId );
            }
        },
        handleOnSortEnd: ( sort: SortEnd, event: SortEvent ) =>
        {
            const state = store.getState();
            const canvas = getCustomizableCanvas( state );
            if ( canvas )
            {
                canvas.tellDesignToMoveSlideToIndex( sort.oldIndex, sort.newIndex );
            }
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( SlideSummaryPanel );
