import { connect } from "react-redux";
import { Dispatch } from "redux";
import { isVideoAudioSelected } from "../../../../ducks/mixModel";
import { selectVideoAudio } from "../../../../helpers";
import { StoreState } from "../../../../_types";
import { store } from "../../../../store";
import { VideoAudioMusicItem, VideoAudioMusicItemDispatchProps, VideoAudioMusicItemProps } from "../VideoAudioMusicItem";

const mapStateToProps = ( storeState: StoreState ): VideoAudioMusicItemProps =>
{
    const videoAudioSelected = isVideoAudioSelected( storeState );

    return {
        isVideoAudioSelected: videoAudioSelected,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): VideoAudioMusicItemDispatchProps =>
{
    return {
        onVideoAudioClicked()
        {
            const state = store.getState();

            const videoAudioSelected = isVideoAudioSelected( state );
            if ( !videoAudioSelected )
            {
                selectVideoAudio( state, dispatch );
            }
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( VideoAudioMusicItem );
