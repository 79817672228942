import { CancelWinBackOffer, CancelWinBackOfferDispatchProps, CancelWinBackOfferProps } from "../CancelWinBackOffer";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState, StripeSubscriptionInfoData } from "../../_types";
import { getAnnualPlanForTier, getCouponCode, getExchangeRateData, getMonthlyPlanForTier } from "../../ducks/pricing";
import { store } from "../../store";
import { subscriptionServices } from "../../services";
import { switchSubscriptionSpinnerEnabled } from "../../ducks/ui";
import { getCurrentProductTier, getUserCurrencyCode } from "../../ducks/user";

export interface CancelWinBackOfferContainerProps
{
    subscriptionInfo: StripeSubscriptionInfoData;
    handleProceedToCancellation();
}

const mapStateToProps = ( storeState: StoreState ): CancelWinBackOfferProps =>
{
    const userTier = getCurrentProductTier( storeState );

    return {
        annualPlan: getAnnualPlanForTier( storeState, userTier ),
        monthlyPlan: getMonthlyPlanForTier( storeState, userTier ),
        isUpdatingSubscription: switchSubscriptionSpinnerEnabled( storeState ),
        shouldShowExchangeRates: !!getExchangeRateData( storeState ),
        selectedCurrencyCode: getUserCurrencyCode( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: CancelWinBackOfferContainerProps ): CancelWinBackOfferDispatchProps =>
{
    return {
        handleProceedToCancellation: () =>
        {
            ownProps.handleProceedToCancellation();
        },
        handleWinBackOfferAccepted: ( selectedPlanId: string ) =>
        {
            const stripeCustomerId = ownProps.subscriptionInfo.stripe_customer_id;
            const couponCode = getCouponCode( store.getState() );
            dispatch( subscriptionServices.switchSubscriptionForCancelWinBack( stripeCustomerId, selectedPlanId, couponCode ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( CancelWinBackOffer );
