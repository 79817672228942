import { createAction } from "redux-actions";
import { ActionCreator } from "../helpers";

const actionCreator = new ActionCreator( "PRICING" );

const pricingTypes = {
    load: actionCreator.generateTypes( "LOAD" ),
    couponCode: {
        update: actionCreator.generateType( "COUPON_CODE", "UPDATE" ),
        errored: actionCreator.generateType( "COUPON_CODE", "ERRORED" ),
    },
    srcCode: {
        update: actionCreator.generateType( "SRC_CODE", "UPDATE" ),
    },
    productType: {
        update: actionCreator.generateType( "PRODUCT_TYPE", "UPDATE" ),
    },
    productTier: {
        update: actionCreator.generateType( "PRODUCT_TIER", "UPDATE" ),
    },
    plan: { set: actionCreator.generateType( "PLAN", "SET" ) },
    tier: { selected: actionCreator.generateType( "TIER", "SELECTED" ) },
    viewAllPlans: { set: actionCreator.generateType( "VIEW_ALL_PLANS", "SET" ) },
};

export const pricingActions = {
    loadSuccess: createAction<ListPlanAPIData>( pricingTypes.load.success ),
    loadFailure: createAction<string>( pricingTypes.load.failure ),
    updateCouponCode: createAction<string>( pricingTypes.couponCode.update ),
    updateSrcCode: createAction<string>( pricingTypes.srcCode.update ),
    updateProductType: createAction<PricingPlanType>( pricingTypes.productType.update ),
    updateProductTier: createAction<PricingPlanTier>( pricingTypes.productTier.update ),
    setPlan: createAction<string>( pricingTypes.plan.set ),
    setCouponCodeError: createAction<string>( pricingTypes.couponCode.errored ),
    tierSelected: createAction<ProductTier>( pricingTypes.tier.selected ),
    setAllPlansVisible: createAction<boolean>( pricingTypes.viewAllPlans.set ),
};
