import { Action } from "redux-actions";
import { store } from "../store";
import { differenceInCalendarDays, format, isSameDay, startOfDay } from "date-fns";
import { defaults, filter, find, first, flatten, forIn, get, includes, isNil, keyBy, map, mapValues, merge, some, uniq, without } from "lodash";
import { ControlSwitchData, mixModelActions, postActions, uiActions, userActions, UserBusinessActionData, userBusinessActions } from "../actions";
import { CUSTOMIZABLE_DESIGN_ID } from "../components/containers/CustomizableDesign.container";
import {
    calculateCombinedProgress,
    CanvasUpdater,
    EDITOR_TYPES,
    getFontDisplayName,
    getSelectedDesignCatalogCategory,
    MAX_NUMBER_OF_TIMES_TO_SHOW_CUSTOMIZE_TIP_BANNERS,
    ReducerCreator,
} from "../helpers";
import { getControlValue, getDesignAspectRatio, getDesignControlsConfig, getSelectedDesign, isFacebookAd } from "./mixModel";
import { getDesignPreviewsForDesignAndAspectRatio } from "./designPreviews";
import { getBusinessById, getCurrentBusiness, getCurrentBusinessType } from "./userBusiness";
import { getUserSocialNetworkAccounts, showBrandLogo, SOCIAL_NETWORK_ACCOUNT_FILTER_MODE_NO_BUSINESS } from "./user";
import {
    AspectRatio,
    BackgroundAnimationChoices,
    BrandLogo,
    BusinessColorKey,
    BusinessType,
    CatalogCategory,
    CoachMarkNameKey,
    CoachMarks,
    CoachMarkState,
    ConfirmFacebookDialogData,
    DesignCaptionData,
    DesignElement,
    DesignElementProperties,
    DesignMediaObject,
    DesignPresets,
    DesignPreview,
    DesignSelectedData,
    DesignZoomData,
    EditorDataValue,
    FacebookGroupAddRiplDialogData,
    FacebookPickerDialogData,
    FontAutoCompleteData,
    FontData,
    Music,
    PaymentFormError,
    SchedulePostDialogData,
    SlideIndexData,
    SlideSummaryResponse,
    SlideTransitionChoices,
    SortedCaptionsByType,
    StoreState,
    StylizedTextChoices,
    TextAnimationChoices,
    TextPickerCaptionData,
    UIState,
    UserBusiness,
} from "../_types";
import {
    COACH_MARK_NAME_KEY_MORE_LAYOUTS,
    EpidemicSoundTrack,
    FontControlConfig,
    IControlConfig,
    PRIMARY_TEXT_FIELD_NAME,
    RecordAPIUpdate,
    VIDEO_CONVERSION_PROGRESS_STATES,
    VIDEO_TRIM_PROGRESS_STATES,
} from "../_types/api";
import { BRAND_SLIDE_DESIGN_CANVAS_ID } from "../components/containers/BrandSlideDesign.container";

const defaultState: UIState = {
    canvasUpdaters: {},
    customizePage: {
        editorType: EDITOR_TYPES.TEMPLATES_CONTROL,
    },
    customizeTipBanners: {
        postsShownTipBanners: [],
        dismissedBanners: {
            templatesSimple: false,
            mediaHiRez: false,
            textWordy: false,
            aspectSquare: false,
        },
    },
    designCatalogDialog: {},
    businessData: {
        tempLogo: null,
    },
    manageBusinessLogos: {
        selectedLogos: [],
    },
    facebookPickerDialogData: {
        isOpen: false,
    },
    facebookGroupAddRiplDialogData: {
        isOpen: false,
    },
    confirmFacebookDialogData: {
        isOpen: false,
        name: "",
        profileImageUrl: "",
    },
    schedulePostDialogData: {
        isOpen: false,
    },
    fontAutocompleteData: { filterText: "" },
    designCaptionData: {},
    stylizedTextChoices: { captionId: null, styles: [] },
    designZoomData: {},
    designSlideIndexData: {
        currentSlideIndex: 0,
        totalNumberOfSlides: 1,
    },
    activeCaptionControls: [],
    activeZoomControls: [],
    emailForm: {
        email: "",
        password: "",
    },
    thirdPartyLoginError: {
        errorMessage: "",
        showError: false,
    },
    resetForm: {},
    selectedImageIndex: undefined,
    hasLoadedMeThisSession: false,
    hasSubscribedSuccessfullyThisSession: false,
    isAnimatedOutputMode: true,
    isCreateYourOwnBannerButtonVisible: true,
    showYouAroundTourVisible: false,
    shouldShowDefaultStockMediaSearchResults: true,
    presentAfterShareDialog: false,
    hasSeenAfterShareDialog: false,
    hasSeenRiplUserSurvey2023: false,
    coachMarks: {
        referAFriendButton: {
            isVisible: false,
            cleared: false,
        },
        stylizedTextButton: {
            isVisible: false,
            cleared: false,
        },
        [COACH_MARK_NAME_KEY_MORE_LAYOUTS]: {
            isVisible: false,
            cleared: false,
        },
    },
    isAcceptTeamInviteFlow: false,
    isTemplatePreviewApplyBrandStylesSwitchToggled: false,
    businessTypeFilterSelected: null,
    showAllBusinessTypeFilters: false,
};

export const enum ProcessTrackingKey
{
    CARD = "card", // payment form, add card and update card form processing
    SWITCH_SUBSCRIPTION = "switchSubscription", // cancel winback processing
    SUBSCRIPTION_INFO = "subscriptionInfo", // loading subs info for Manage Subs page
    COLLECTION_LOAD = "collectionLoad", // loading a collection
    CONTENT_SEARCH = "contentSearch", // performing a (design) content search
    CUSTOM_MUSIC_UPLOADING = "customMusicUpload", // uploading custom music to the backend
    FINISH_POST = "finishPost", // finish post on backend
    UPDATE_POST = "updatePost", // update draft post on backend
    UPDATE_BUSINESS = "updateBusiness", // update business on backend
    UPDATE_LOGO = "updateLogo", // update business logo on backend
    FETCHING_ME_DATA = "fetchingMeData", // fetching user me data processing
    CREATING_BUSINESS = "creatingBusiness", // creating business on backend
    STRIPE_PLANS_LOADING = "stripePlansLoading", // fetching the list of stripe plans
    LOAD_MORE_MOST_RECENT_POSTS = "loadMoreMostRecentPosts", // fetching more post in my posts
    LOAD_FREE_EPIDEMIC_SOUND_CATALOG = "loadFreeEpidemicSoundCatalog", // fetching Epidemic Sound catalog
    LOAD_EPIDEMIC_SOUND_USER_COLLECTIONS = "loadEpidemicSoundUserCollections", // fetching Epidemic Sound collections
    LOAD_EPIDEMIC_SOUND_USER_COLLECTION_DETAILS = "loadEpidemicSoundUserCollectionDetails", // fetching Epidemic Sound collection tracks
}

export type ProcessCollectionItemId = string | number;

export const enum ProcessCollection
{
    FETCH_EPIDEMIC_SOUND_TRACK = "fetchEpidemicSoundTrack", // fetching epidemic sound track
}

export interface ProcessCollectionItem
{
    collection: ProcessCollection;
    id: ProcessCollectionItemId;
}

export const CUSTOMIZE_TIP_BANNER_TYPES = {
    templatesSimple: "templatesSimple",
    mediaHiRez: "mediaHiRez",
    textWordy: "textWordy",
    aspectSquare: "aspectSquare",
};

const reducerCreator = new ReducerCreator( defaultState );

reducerCreator.addAction( userActions.meSuccess, handleMeSuccess );
reducerCreator.addAction( userActions.subscribeSucceeded, handleSubscribeSucceeded );
reducerCreator.addAction( uiActions.calendarDatePicked, handleCalendarDatePicked );
reducerCreator.addAction( uiActions.registerCanvasUpdater, handleRegisterCanvasUpdater );
reducerCreator.addAction( uiActions.controlEditorSwitched, handleControlSwitched );
reducerCreator.addAction( uiActions.deselectAllControls, handleDeselectAllControls );
reducerCreator.addAction( uiActions.deselectAnimationControls, handleDeselectAnimationControls );
reducerCreator.addAction( uiActions.dismissCustomizeTip, handleDismissCustomizeTip );
reducerCreator.addAction( uiActions.registerCustomizeTipShown, handleRegisterTipBannerShown );
reducerCreator.addAction( uiActions.mixModelRecordProgress, handleMixModelRecordProgress );
reducerCreator.addAction( uiActions.mixModelConvertVideoProgress, handleMixModelConvertVideoProgress );
reducerCreator.addAction( uiActions.mixModelTrimVideoProgress, handleMixModelTrimVideoProgress );
reducerCreator.addAction( uiActions.mixModelRecordError, handleMixModelRecordError );
reducerCreator.addAction( mixModelActions.designSelected, handleDesignSelected );
reducerCreator.addAction( uiActions.designCatalogAspectRatioSet, handleDesignCatalogAspectRatioSet );
reducerCreator.addAction( uiActions.designCatalogCategorySelected, handleDesignCatalogCategorySelected );
reducerCreator.addAction( userBusinessActions.businessSwitched, handleBusinessSwitched );
reducerCreator.addAction( uiActions.updateBusinessInfo, handleUpdateBusiness );
reducerCreator.addAction( uiActions.clearBusinessInfo, handleClearBusiness );
reducerCreator.addAction( uiActions.clearBusinessInfoBrandSlideFontData, handleClearBusinessFontData );
reducerCreator.addAction( uiActions.facebookPickerModalOpen, handleFacebookPickerModalOpen );
reducerCreator.addAction( uiActions.facebookPickerModalUpdateAccount, handleFacebookPickerModalUpdate );
reducerCreator.addAction( uiActions.facebookPickerModalClose, handleFacebookPickerModalClose );
reducerCreator.addAction( uiActions.facebookGroupAddRiplModalOpen, handleFacebookGroupAddRiplModalOpen );
reducerCreator.addAction( uiActions.facebookGroupAddRiplModalClose, handleFacebookGroupAddRiplModalClose );
reducerCreator.addAction( uiActions.confirmFacebookModalOpen, handleConfirmFacebookModalOpen );
reducerCreator.addAction( uiActions.confirmFacebookModalClose, handleConfirmFacebookModalClose );
reducerCreator.addAction( uiActions.schedulePostModalOpen, handleSchedulePostModalOpen );
reducerCreator.addAction( uiActions.schedulePostModalClose, handleSchedulePostModalClose );
reducerCreator.addAction( uiActions.updateTextFontAutocomplete, handleUpdateTextFontAutocomplete );
reducerCreator.addAction( uiActions.updateFontFontAutocomplete, handleUpdateFontFontAutocomplete );
reducerCreator.addAction( uiActions.designCanvasRefreshed, handleDesignCanvasRefreshed );
reducerCreator.addAction( uiActions.designCaptionCleared, handleDesignCaptionCleared );
reducerCreator.addAction( uiActions.designCaptionUpdated, handleDesignCaptionUpdated );
reducerCreator.addAction( uiActions.designCaptionTextUpdated, handleDesignCaptionTextUpdated );
reducerCreator.addAction( uiActions.designCaptionControlsSet, handleDesignCaptionControlsSet );
reducerCreator.addAction( uiActions.designSupportsExtraCaptions, handleDesignSupportsExtraCaptions );
reducerCreator.addAction( uiActions.stylizedTextChoicesSet, handleStylizedTextChoicesSet );
reducerCreator.addAction( uiActions.designZoomUpdated, handleDesignZoomUpdated );
reducerCreator.addAction( uiActions.designZoomControlsSet, handleDesignZoomControlsSet );
reducerCreator.addAction( uiActions.cardProcessingSpinnerEnabled, handleCardProcessingStatusToggle );
reducerCreator.addAction( uiActions.updateEmailFormEmail, handledUpdateEmailFormEmail );
reducerCreator.addAction( uiActions.updateEmailFormPassword, handledUpdateEmailFormPassword );
reducerCreator.addAction( uiActions.updateMusicCatalogSelection, handledUpdateMusicCatalogSelection );
reducerCreator.addAction( uiActions.setEmailFormErrors, handledSetEmailFormErrors );
reducerCreator.addAction( uiActions.setPartnerLoginError, handledSetPartnerLoginError );
reducerCreator.addAction( uiActions.setThirdPartyLoginError, handledSetThirdPartyLoginError );
reducerCreator.addAction( uiActions.setResetFormErrors, handledSetResetFormErrors );
reducerCreator.addAction( uiActions.setResetFormProcessing, handledSetResetFormProcessing );
reducerCreator.addAction( uiActions.clearResetFormErrors, handledClearResetFormErrors );
reducerCreator.addAction( uiActions.updateFocusElement, handleUpdateFocusElement );
reducerCreator.addAction( postActions.updateRequest, handlePostUpdateStarted );
reducerCreator.addAction( postActions.updateSuccess, handlePostUpdateComplete );
reducerCreator.addAction( postActions.updateFailure, handlePostUpdateComplete );
reducerCreator.addAction( userBusinessActions.updateBusinessRequest, handleBusinessUpdateStarted );
reducerCreator.addAction( userBusinessActions.updateBusinessSuccess, handleBusinessUpdateComplete );
reducerCreator.addAction( userBusinessActions.updateBusinessFailure, handleBusinessUpdateComplete );
reducerCreator.addAction( uiActions.updateDesignLoadProgressStarted, handleDesignLoadProgressStarted );
reducerCreator.addAction( uiActions.updateDesignLoadProgressCancelled, handleDesignLoadProgressCompleted );
reducerCreator.addAction( uiActions.updateDesignLoadProgressCompleted, handleDesignLoadProgressCompleted );
reducerCreator.addAction( uiActions.imageSelected, handleImageSelected );
reducerCreator.addAction( uiActions.imageSelectionCleared, handleImageSelectionCleared );
reducerCreator.addAction( uiActions.setRedirectUrl, handleSetRedirectUrl );
reducerCreator.addAction( uiActions.setHomePageAlert, handleSetHomePageAlert );
reducerCreator.addAction( uiActions.toggleIsCustomMusicUploading, handleIsCustomMusicUploading );
reducerCreator.addAction( postActions.finishFailure, handleFinishComplete );
reducerCreator.addAction( postActions.finishSuccess, handleFinishComplete );
reducerCreator.addAction( postActions.finishRequest, handleFinishStarted );
reducerCreator.addAction( postActions.loadMoreMostRecentPostsDataRequest, handleLoadMoreRecentPostsStarted );
reducerCreator.addAction( postActions.loadMoreMostRecentPostsDataSuccess, handleLoadMoreRecentPostsComplete );
reducerCreator.addAction( postActions.loadMoreMostRecentPostsDataFailure, handleLoadMoreRecentPostsComplete );
reducerCreator.addAction( uiActions.setAudioPlayerMusicTrack, handleAudioPlayerMusicTrackSet );
reducerCreator.addAction( uiActions.clearAudioPlayerMusicTrack, handleAudioPlayerMusicTrackCleared );
reducerCreator.addAction( uiActions.setTeamMemberInviteEmail, handleTeamMemberInviteEmailChanged );
reducerCreator.addAction( userBusinessActions.inviteTeamMemberSuccess, handleInviteTeamMemberSuccess );
reducerCreator.addAction( uiActions.videoTrimmerModalShowing, handleVideoTrimmerModalShowing );
reducerCreator.addAction( uiActions.designSlideIndexDataUpdated, handleDesignSlideIndexDataUpdated );
reducerCreator.addAction( uiActions.updateExternalMediaUploadStatus, handleExternalMediaUploadStatusUpdated );
reducerCreator.addAction( uiActions.shouldShowDefaultStockMediaSearchResultsUpdated, handleShouldShowDefaultStockMediaSearchResultsUpdated );
reducerCreator.addAction( uiActions.contentSearchSpinnerEnabled, handleContentSearchSpinnerEnabled );
reducerCreator.addAction( uiActions.collectionSpinnerEnabled, handleCollectionSpinnerEnabled );
reducerCreator.addAction( uiActions.collectionPageSourceSet, handleCollectionPageSourceSet );
reducerCreator.addAction( uiActions.subscriptionInfoSpinnerEnabled, handleSubscriptionInfoSpinnerEnabled );
reducerCreator.addAction( uiActions.switchSubscriptionSpinnerEnabled, handleSwitchSubscriptionSpinnerEnabled );
reducerCreator.addAction( uiActions.showUnscheduledPostsInScheduleTabUpdated, handleShowUnscheduledPostsInScheduleTab );
reducerCreator.addAction( uiActions.trackProcessAsRunning, handleTrackProcessAsRunning );
reducerCreator.addAction( uiActions.trackProcessAsStopped, handleTrackProcessAsStopped );
reducerCreator.addAction( uiActions.trackProcessCollectionItemAsRunning, handleTrackProcessCollectionItemAsRunning );
reducerCreator.addAction( uiActions.trackProcessCollectionItemAsStopped, handleTrackProcessCollectionItemAsStopped );
reducerCreator.addAction( uiActions.updateCreateYourOwnBannerButtonVisibility, handleCreateYourOwnBannerButtonVisibility );
reducerCreator.addAction( mixModelActions.mixModelCancelled, handleDeselectAllControls );
reducerCreator.addAction( uiActions.displayShowYouAroundTour, handleShowYouAroundTourVisibility );
reducerCreator.addAction( uiActions.presentAfterShareDialogSet, handlePresentAfterShareDialog );
reducerCreator.addAction( uiActions.hasSeenAfterShareDialogSet, handleHasSeenAfterShareDialog );
reducerCreator.addAction( uiActions.hasSeenRiplUserSurvey2023Set, handleHasSeenRiplUserSurvey2023 );
reducerCreator.addAction( uiActions.showCoachMark, handleShowCoachMark );
reducerCreator.addAction( uiActions.hideAllCoachMarks, handleHideAllCoachMarks );
reducerCreator.addAction( uiActions.clearCoachMark, handleClearCoachMark );
reducerCreator.addAction( uiActions.paymentMethodErrorSet, handlePaymentMethodError );
reducerCreator.addAction( uiActions.paymentFormErrorSet, handlePaymentFormError );
reducerCreator.addAction( uiActions.paymentFormErrorClear, handlePaymentFormClear );
reducerCreator.addAction( uiActions.facebookAdsSubmitErrorSet, handleFacebookAdsSubmitError );
reducerCreator.addAction( uiActions.selectedDesignElementSet, handleSelectedDesignElementSet );
reducerCreator.addAction( uiActions.designElementPropertiesReceived, handleDesignElementPropertiesReceived );
reducerCreator.addAction( uiActions.slideTransitionPropertiesReceived, handleSlideTransitionPropertiesReceived );
reducerCreator.addAction( uiActions.backgroundAnimationPropertiesReceived, handleBackgroundAnimationPropertiesReceived );
reducerCreator.addAction( uiActions.designPresetsReceived, handleDesignPresetsReceived );
reducerCreator.addAction( uiActions.textAnimationChoicesSet, handleTextAnimationChoicesSet );
reducerCreator.addAction( uiActions.globalTextAnimationChoicesSet, handleGlobalTextAnimationChoicesSet );
reducerCreator.addAction( uiActions.slideTransitionChoicesSet, handleSlideTransitionChoicesSet );
reducerCreator.addAction( uiActions.backgroundAnimationChoicesSet, handleBackgroundAnimationChoicesSet );
reducerCreator.addAction( uiActions.slideSummarySet, handleSlideSummarySet );
reducerCreator.addAction( uiActions.slideSummaryClear, handleSlideSummaryClear );
reducerCreator.addAction( uiActions.setTextPropertiesSubPanelCaptionData, handleSetTextPropertiesSubPanelCaptionData );
reducerCreator.addAction( uiActions.clearTextPropertiesSubPanelCaptionData, handleClearTextPropertiesSubPanelCaptionData );
reducerCreator.addAction( uiActions.facebookAdMobileWebViewOpened, handleFacebookAdMobileWebViewOpened );
reducerCreator.addAction( uiActions.manageBusinessLogosSelectionSet, handleManageBusinessLogosSelectionSet );
reducerCreator.addAction( uiActions.manageBusinessLogosSelectionClear, handleManageBusinessLogosSelectionClear );
reducerCreator.addAction( uiActions.setIsAcceptTeamInviteFlow, handleSetIsAcceptTeamInviteFlow );
reducerCreator.addAction( uiActions.designMediaObjectsResponseReceived, handleDesignMediaObjectsResponseReceived );
reducerCreator.addAction( uiActions.replaceMediaModeEnded, handleReplaceMediaModeEnded );
reducerCreator.addAction( uiActions.loadedAllRecentPostsChanged, handleHasLoadedAllRecentPosts );
reducerCreator.addAction( uiActions.setPremiumEpidemicSoundPreview, handleSetPremiumEpidemicSoundPreview );
reducerCreator.addAction( uiActions.clearPremiumEpidemicSoundPreview, handleClearPremiumEpidemicSoundPreview );
reducerCreator.addAction( uiActions.templatePreviewAnimationStarted, handleTemplatePreviewAnimationStarted );
reducerCreator.addAction( uiActions.templatePreviewAnimationCompleted, handleTemplatePreviewAnimationCompleted );
reducerCreator.addAction( uiActions.setTemplatePreviewApplyBrandStylesSwitchToggled, handleSetTemplatePreviewApplyBrandStylesSwitchToggled );
reducerCreator.addAction( uiActions.socialCalendarDayClicked, handleSocialCalendarDayClicked );
reducerCreator.addAction( uiActions.updateBusinessTypeFilter, handleBusinessTypeFilterUpdated );
reducerCreator.addAction( uiActions.clearBusinessTypeFilter, handleBusinessTypeFilterCleared );
reducerCreator.addAction( uiActions.setBusinessTypeFilterShowAll, handleShowAllBusinessTypeFiltersToggled );

export default reducerCreator.createReducer();

function handleTrackProcessAsRunning( state: UIState, action: Action<string> ): UIState
{
    return handleTrackProcessKeyAsRunning( state, action.payload );
}

function handleTrackProcessKeyAsRunning( state: UIState, processKey: string ): UIState
{
    const currentlyRunningProcesses = state.currentlyRunningProcesses || [];
    return {
        ...state,
        currentlyRunningProcesses: uniq( [...currentlyRunningProcesses, processKey] ),
    };
}

function handleTrackProcessAsStopped( state: UIState, action: Action<string> ): UIState
{
    return handleTrackProcessKeyAsStopped( state, action.payload );
}

function handleTrackProcessKeyAsStopped( state: UIState, processKey: string ): UIState
{
    return {
        ...state,
        currentlyRunningProcesses: without( state.currentlyRunningProcesses, processKey ),
    };
}

type ProcessCollectionReducer = ( runningItems: ProcessCollectionItemId[] ) => ProcessCollectionItemId[];

function updateProcessCollectionWithPredicate( state: UIState, collection: ProcessCollection, predicate: ProcessCollectionReducer ): UIState
{
    const currentlyRunningProcessesGroups = state.currentlyRunningProcessesGroups || {};
    const currentlyRunningItemsInGroup = get( currentlyRunningProcessesGroups, collection, [] );

    return {
        ...state,
        currentlyRunningProcessesGroups: {
            ...currentlyRunningProcessesGroups,
            [collection]: predicate( currentlyRunningItemsInGroup ),
        },
    };
}

function handleTrackProcessCollectionItemAsRunning( state: UIState, action: Action<ProcessCollectionItem> ): UIState
{
    const { collection, id } = action.payload;
    const predicate = ( runningItems ) => uniq( [...runningItems, id] );
    return updateProcessCollectionWithPredicate( state, collection, predicate );
}

function handleTrackProcessCollectionItemAsStopped( state: UIState, action: Action<ProcessCollectionItem> ): UIState
{
    const { collection, id } = action.payload;
    const predicate = ( runningItems ) => without( runningItems, id );
    return updateProcessCollectionWithPredicate( state, collection, predicate );
}

function handleExternalMediaUploadStatusUpdated( state: UIState, action: Action<{ [id: string]: boolean }> ): UIState
{
    return {
        ...state,
        externalMediaUploadStatus: {
            ...state.externalMediaUploadStatus,
            ...action.payload,
        },
    };
}

function handleDesignSlideIndexDataUpdated( state: UIState, action: Action<SlideIndexData> ): UIState
{
    return {
        ...state,
        designSlideIndexData: action.payload,
    };
}

function handleVideoTrimmerModalShowing( state: UIState, action: Action<boolean> ): UIState
{
    return {
        ...state,
        videoTrimmerModalOpen: action.payload,
    };
}

function handleInviteTeamMemberSuccess( state: UIState, action: Action<NormalizrData> ): UIState
{
    return {
        ...state,
        teamMemberInviteEmail: "",
    };
}

function handleTeamMemberInviteEmailChanged( state: UIState, action: Action<string> ): UIState
{
    return {
        ...state,
        teamMemberInviteEmail: action.payload,
    };
}

function handleAudioPlayerMusicTrackSet( state: UIState, action: Action<Music> ): UIState
{
    return {
        ...state,
        audioPlayerMusicTrack: action.payload,
    };
}

function handleAudioPlayerMusicTrackCleared( state: UIState ): UIState
{
    return {
        ...state,
        audioPlayerMusicTrack: undefined,
    };
}

function handleFinishStarted( state: UIState ): UIState
{
    return handleTrackProcessKeyAsRunning( state, ProcessTrackingKey.FINISH_POST );
}

function handleFinishComplete( state: UIState ): UIState
{
    return handleTrackProcessKeyAsStopped( state, ProcessTrackingKey.FINISH_POST );
}

function handleLoadMoreRecentPostsStarted( state: UIState ): UIState
{
    return handleTrackProcessKeyAsRunning( state, ProcessTrackingKey.LOAD_MORE_MOST_RECENT_POSTS );
}

function handleLoadMoreRecentPostsComplete( state: UIState ): UIState
{
    return handleTrackProcessKeyAsStopped( state, ProcessTrackingKey.LOAD_MORE_MOST_RECENT_POSTS );
}

function handlePostUpdateStarted( state: UIState ): UIState
{
    return handleTrackProcessKeyAsRunning( state, ProcessTrackingKey.UPDATE_POST );
}

function handleHasLoadedAllRecentPosts( state: UIState, action: Action<boolean> ): UIState
{
    return {
        ...state,
        hasLoadedAllRecentPosts: action.payload,
    };
}

function handlePostUpdateComplete( state: UIState ): UIState
{
    return handleTrackProcessKeyAsStopped( state, ProcessTrackingKey.UPDATE_POST );
}

function handleBusinessUpdateStarted( state: UIState ): UIState
{
    return handleTrackProcessKeyAsRunning( state, ProcessTrackingKey.UPDATE_BUSINESS );
}

function handleBusinessUpdateComplete( state: UIState ): UIState
{
    return handleTrackProcessKeyAsStopped( state, ProcessTrackingKey.UPDATE_BUSINESS );
}

function handleDesignLoadProgressStarted( state: UIState, action: Action<string> ): UIState
{
    return handleTrackProcessKeyAsRunning( state, action.payload as ProcessTrackingKey );
}

function handleDesignLoadProgressCompleted( state: UIState, action: Action<string> ): UIState
{
    return handleTrackProcessKeyAsStopped( state, action.payload as ProcessTrackingKey );
}

function handleCardProcessingStatusToggle( state: UIState, action: Action<boolean> ): UIState
{
    return updateCurrentlyRunningProcessesFromBoolean( state, action.payload, ProcessTrackingKey.CARD );
}

function handleMixModelRecordProgress( state: UIState, action: Action<RecordAPIUpdate> ): UIState
{
    return {
        ...state,
        recordProgress: action.payload,
    };
}

function handleMixModelConvertVideoProgress( state: UIState, action: Action<ConvertVideoAPIUpdate> ): UIState
{
    const combinedProgress = calculateCombinedProgress( VIDEO_CONVERSION_PROGRESS_STATES, action.payload );
    return {
        ...state,
        convertVideoProgress: {
            ...action.payload,
            combinedProgress,
        },
    };
}

function handleMixModelTrimVideoProgress( state: UIState, action: Action<TrimVideoAPIUpdate> ): UIState
{
    const combinedProgress = calculateCombinedProgress( VIDEO_TRIM_PROGRESS_STATES, action.payload );
    return {
        ...state,
        trimVideoProgress: {
            ...action.payload,
            combinedProgress,
        },
    };
}

function handleMixModelRecordError( state: UIState, action: Action<boolean> ): UIState
{
    return {
        ...state,
        recordError: action.payload,
    };
}

function handlePresentAfterShareDialog( state: UIState, action: Action<boolean> ): UIState
{
    return {
        ...state,
        presentAfterShareDialog: action.payload,
    };
}

function handleHasSeenAfterShareDialog( state: UIState, action: Action<boolean> ): UIState
{
    return {
        ...state,
        hasSeenAfterShareDialog: action.payload,
    };
}

function handleHasSeenRiplUserSurvey2023( state: UIState, action: Action<boolean> ): UIState
{
    return {
        ...state,
        hasSeenRiplUserSurvey2023: action.payload,
    };
}

function handleFacebookPickerModalOpen( state: UIState, action: Action<FacebookPickerDialogData> ): UIState
{
    return {
        ...state,
        facebookPickerDialogData: {
            ...action.payload,
            isOpen: true,
        },
    };
}

function handleFacebookPickerModalUpdate( state: UIState, action: Action<string> ): UIState
{
    const socialNetworkAccounts = getUserSocialNetworkAccounts( store.getState(), SOCIAL_NETWORK_ACCOUNT_FILTER_MODE_NO_BUSINESS );
    const facebookItemsToPickFrom = filter( socialNetworkAccounts, ( storeAccount ) => storeAccount.account_type === action.payload );

    return {
        ...state,
        facebookPickerDialogData: {
            ...state.facebookPickerDialogData,
            allItems: facebookItemsToPickFrom,
        },
    };
}

function handleFacebookPickerModalClose( state: UIState, action: Action<void> ): UIState
{
    return {
        ...state,
        facebookPickerDialogData: {
            isOpen: false,
        },
    };
}

function handleFacebookGroupAddRiplModalOpen( state: UIState, action: Action<FacebookGroupAddRiplDialogData> ): UIState
{
    return {
        ...state,
        facebookGroupAddRiplDialogData: {
            ...action.payload,
            isOpen: true,
        },
    };
}

function handleConfirmFacebookModalOpen( state: UIState, action: Action<ConfirmFacebookDialogData> ): UIState
{
    return {
        ...state,
        confirmFacebookDialogData: {
            ...action.payload,
            isOpen: true,
        },
    };
}

function handleConfirmFacebookModalClose( state: UIState ): UIState
{
    return {
        ...state,
        confirmFacebookDialogData: {
            ...state.confirmFacebookDialogData,
            isOpen: false,
        },
    };
}

function handleFacebookGroupAddRiplModalClose( state: UIState, action: Action<void> ): UIState
{
    return {
        ...state,
        facebookGroupAddRiplDialogData: {
            isOpen: false,
        },
    };
}

function handleSchedulePostModalOpen( state: UIState, action: Action<SchedulePostDialogData> ): UIState
{
    return {
        ...state,
        schedulePostDialogData: {
            isOpen: true,
            ...action.payload,
        },
    };
}

function handleSchedulePostModalClose( state: UIState, action: Action<void> ): UIState
{
    return {
        ...state,
        schedulePostDialogData: {
            isOpen: false,
            closePostDetails: undefined,
        },
    };
}

function handleBusinessSwitched( state: UIState, action: Action<UserBusinessActionData> ): UIState
{
    const switchedBusinessData = getBusinessById( store.getState(), action.payload.businessId );
    return {
        ...state,
        businessData: {
            ...state.businessData,
            ...switchedBusinessData,
        },
    };
}

function handleUpdateBusiness( state: UIState, action: Action<Partial<UserBusiness>> ): UIState
{
    return {
        ...state,
        businessData: {
            ...state.businessData,
            ...action.payload,
        },
    };
}

function handleClearBusiness( state: UIState ): UIState
{
    return {
        ...state,
        businessData: {},
    };
}

function handleClearBusinessFontData( state: UIState ): UIState
{
    const {
        end_card_font_one,
        end_card_font_one_css_url,
        end_card_font_two,
        end_card_font_two_css_url,
        ...businessDataWithoutBrandSlideFontData
    } = state.businessData;

    return {
        ...state,
        businessData: businessDataWithoutBrandSlideFontData,
    };
}

function handleDesignCanvasRefreshed( state: UIState ): UIState
{
    return {
        ...state,
        customizePage: {
            ...state.customizePage,
            lastRefreshed: new Date().getTime(),
        },
    };
}

function handleDesignCaptionCleared( state: UIState ): UIState
{
    return {
        ...state,
        designCaptionData: null,
        designSupportsExtraCaptions: false,
    };
}

function handleDesignCaptionUpdated( state: UIState, action: Action<DesignCaptionData[]> ): UIState
{
    const captionLookup = keyBy( action.payload, "id" );
    return {
        ...state,
        designCaptionData: captionLookup,
    };
}

function handleDesignCaptionTextUpdated( state: UIState, action: Action<DesignCaptionData[]> ): UIState
{
    const captionLookup = keyBy( action.payload, "id" );
    return {
        ...state,
        designCaptionData: {
            ...merge( state.designCaptionData, captionLookup ),
        },
    };
}

function handleDesignCaptionControlsSet( state: UIState, action: Action<DesignCaptionData[]> ): UIState
{
    const activeCaptionControls = map( action.payload, "id" );
    return {
        ...state,
        activeCaptionControls,
    };
}

function handleDesignSupportsExtraCaptions( state: UIState, action: Action<boolean> ): UIState
{
    return {
        ...state,
        ...{
            designSupportsExtraCaptions: action.payload,
        },
    };
}

function handleStylizedTextChoicesSet( state: UIState, action: Action<StylizedTextChoices> ): UIState
{
    return {
        ...state,
        stylizedTextChoices: action.payload,
    };
}

function handleDesignZoomUpdated( state: UIState, action: Action<DesignZoomData[]> ): UIState
{
    const captionLookup = keyBy( action.payload, "id" );
    return {
        ...state,
        designZoomData: {
            ...state.designZoomData,
            ...captionLookup,
        },
    };
}

function handleDesignZoomControlsSet( state: UIState, action: Action<DesignZoomData[]> ): UIState
{
    const activeZoomControls = map( action.payload, "id" );
    return {
        ...state,
        activeZoomControls,
    };
}

function handleUpdateTextFontAutocomplete( state: UIState, action: Action<string> ): UIState
{
    return {
        ...state,
        fontAutocompleteData: {
            ...state.fontAutocompleteData,
            filterText: action.payload,
        },
    };
}

function handleUpdateFontFontAutocomplete( state: UIState, action: Action<FontData> ): UIState
{
    return {
        ...state,
        fontAutocompleteData: {
            ...state.fontAutocompleteData,
            font: action.payload,
            filterText: getFontDisplayName( action.payload ),
        },
    };
}

function handleMeSuccess( state: UIState ): UIState
{
    return {
        ...state,
        hasLoadedMeThisSession: true,
    };
}

function handleSubscribeSucceeded( state: UIState ): UIState
{
    return {
        ...state,
        hasSubscribedSuccessfullyThisSession: true,
    };
}

function handleCalendarDatePicked( state: UIState, action: Action<Date> ): UIState
{
    return {
        ...state,
        selectedDate: action.payload,
    };
}

function handleRegisterCanvasUpdater( state: UIState, action: Action<CanvasUpdater> ): UIState
{
    return {
        ...state,
        canvasUpdaters: {
            ...state.canvasUpdaters,
            [action.payload.getId()]: action.payload,
        },
    };
}

function handleControlSwitched( state: UIState, action: Action<ControlSwitchData> ): UIState
{
    const { controlId, editorType } = action.payload;
    let deselectControl = true;
    if ( controlId )
    {
        deselectControl = controlId === state.customizePage.controlId;
    }
    else
    {
        deselectControl = editorType === state.customizePage.editorType;
    }
    return {
        ...state,
        customizePage: {
            ...state.customizePage,
            controlId: deselectControl ? undefined : controlId,
            editorType: deselectControl ? undefined : editorType,
            focusElement: undefined,
        },
    };
}

function handleDeselectAllControls( state: UIState ): UIState
{
    return {
        ...state,
        customizePage: {
            ...state.customizePage,
            controlId: undefined,
            editorType: undefined,
            focusElement: undefined,
        },
    };
}

function handleDeselectAnimationControls( state: UIState ): UIState
{
    const isAudioEditor = state.customizePage.editorType === EDITOR_TYPES.AUDIO_CONTROL;
    const isBrandSlideEditor = state.customizePage.editorType === EDITOR_TYPES.BRAND_SLIDE_CONTROL;
    const isAnimationOnlyControl = isAudioEditor || isBrandSlideEditor;

    return {
        ...state,
        customizePage: {
            ...state.customizePage,
            controlId: isAnimationOnlyControl ? undefined : state.customizePage.controlId,
            editorType: isAnimationOnlyControl ? undefined : state.customizePage.editorType,
            focusElement: undefined,
        },
    };
}

function handleDismissCustomizeTip( state: UIState, action: Action<string> ): UIState
{
    const tipBannerKey = action.payload;
    const newDismissedBannersState = { ...state.customizeTipBanners.dismissedBanners };
    newDismissedBannersState[tipBannerKey] = true;
    const newCustomizeTipBannersState = {
        ...state.customizeTipBanners,
        dismissedBanners: newDismissedBannersState,
    };

    return {
        ...state,
        customizeTipBanners: newCustomizeTipBannersState,
    };
}

function handleRegisterTipBannerShown( state: UIState, action: Action<number> ): UIState
{
    const postId = action.payload;
    const currentPostsShownTipBanners = state.customizeTipBanners.postsShownTipBanners || [];
    if ( currentPostsShownTipBanners.indexOf( postId ) === -1 )
    {
        const newPostsShownTipBannersState = [...currentPostsShownTipBanners, postId];
        const newCustomizeTipBannersState = {
            ...state.customizeTipBanners,
            postsShownTipBanners: newPostsShownTipBannersState,
        };
        return {
            ...state,
            customizeTipBanners: newCustomizeTipBannersState,
        };
    }
    return state;
}

function handleDesignSelected( state: UIState, action: Action<DesignSelectedData> ): UIState
{
    if ( !action.payload.design )
    {
        return state;
    }

    return {
        ...state,
        customizePage: {
            ...state.customizePage,
            controlId: state.customizePage.controlId,
            editorType: EDITOR_TYPES.TEMPLATES_CONTROL,
            focusElement: PRIMARY_TEXT_FIELD_NAME,
        },
        designCaptionData: null,
    };
}

function handleDesignCatalogAspectRatioSet( state: UIState, action: Action<AspectRatio> ): UIState
{
    return {
        ...state,
        designCatalogDialog: {
            ...state.designCatalogDialog,
            selectedAspectRatio: action.payload,
        },
    };
}

function handleDesignCatalogCategorySelected( state: UIState, action: Action<CatalogCategory> ): UIState
{
    return {
        ...state,
        designCatalogDialog: {
            ...state.designCatalogDialog,
            selectedCategory: action.payload.id,
        },
    };
}

function handledUpdateEmailFormEmail( state: UIState, action: Action<string> ): UIState
{
    return {
        ...state,
        emailForm: {
            ...state.emailForm,
            email: action.payload,
            emailError: undefined,
            error: undefined,
        },
        thirdPartyLoginError: {
            errorMessage: "",
            showError: false,
        },
    };
}

function handledUpdateEmailFormPassword( state: UIState, action: Action<string> ): UIState
{
    return {
        ...state,
        emailForm: {
            ...state.emailForm,
            password: action.payload,
            passwordError: undefined,
            error: undefined,
        },
        thirdPartyLoginError: {
            errorMessage: "",
            showError: false,
        },
    };
}

function handledUpdateMusicCatalogSelection( state: UIState, action: Action<Music> ): UIState
{
    return {
        ...state,
        musicCatalogSelection: action.payload,
    };
}

function handledSetEmailFormErrors( state: UIState, action: Action<{ email?: string, password?: string, error?: string }> ): UIState
{
    return {
        ...state,
        emailForm: {
            ...state.emailForm,
            emailError: action.payload.email,
            passwordError: action.payload.password,
            error: action.payload.error,
        },
    };
}

function handledSetPartnerLoginError( state: UIState, action: Action<string> ): UIState
{
    return {
        ...state,
        partnerLoginError: action.payload,
    };
}

function handledSetThirdPartyLoginError( state: UIState, action: Action<{ message: string, shouldShowError: boolean }> ): UIState
{
    return {
        ...state,
        thirdPartyLoginError:
            {
                errorMessage: action.payload.message,
                showError: action.payload.shouldShowError,
            },
    };
}

function handledSetResetFormProcessing( state: UIState, action: Action<boolean> ): UIState
{
    return {
        ...state,
        resetForm: {
            ...state.resetForm,
            isProcessingChange: action.payload,
        },
    };
}

function handledSetResetFormErrors( state: UIState, action: Action<ResetPasswordErrorResponse> ): UIState
{
    return {
        ...state,
        resetForm: {
            ...state.resetForm,
            passwordError: action.payload.password,
            passwordConfirmationError: action.payload.password_confirmation,
            tokenError: action.payload.reset_password_token,
            error: action.payload.error,
        },
    };
}

function handledClearResetFormErrors( state: UIState ): UIState
{
    return {
        ...state,
        resetForm: {},
    };
}

function handleUpdateFocusElement( state: UIState, action: Action<string> ): UIState
{
    return {
        ...state,
        customizePage: {
            ...state.customizePage,
            focusElement: action.payload,
        },
    };
}

function handleImageSelected( state: UIState, action: Action<number> ): UIState
{
    return {
        ...state,
        selectedImageIndex: action.payload,
    };
}

function handleImageSelectionCleared( state: UIState ): UIState
{
    return {
        ...state,
        selectedImageIndex: undefined,
    };
}

function handleSetRedirectUrl( state: UIState, action: Action<string> ): UIState
{
    return {
        ...state,
        redirectUrl: action.payload,
    };
}

function handleSetHomePageAlert( state: UIState, action: Action<string> ): UIState
{
    return {
        ...state,
        homePageAlert: action.payload,
    };
}

function handleCollectionPageSourceSet( state: UIState, action: Action<string> ): UIState
{
    return {
        ...state,
        collectionPageSource: action.payload,
    };
}

function handleIsCustomMusicUploading( state: UIState, action: Action<boolean> ): UIState
{
    return updateCurrentlyRunningProcessesFromBoolean( state, action.payload, ProcessTrackingKey.CUSTOM_MUSIC_UPLOADING );
}

function handleShouldShowDefaultStockMediaSearchResultsUpdated( state: UIState, action: Action<boolean> ): UIState
{
    return {
        ...state,
        shouldShowDefaultStockMediaSearchResults: action.payload,
    };
}

function handleContentSearchSpinnerEnabled( state: UIState, action: Action<boolean> ): UIState
{
    return updateCurrentlyRunningProcessesFromBoolean( state, action.payload, ProcessTrackingKey.CONTENT_SEARCH );
}

function handleCollectionSpinnerEnabled( state: UIState, action: Action<boolean> ): UIState
{
    return updateCurrentlyRunningProcessesFromBoolean( state, action.payload, ProcessTrackingKey.COLLECTION_LOAD );
}

function handleSwitchSubscriptionSpinnerEnabled( state: UIState, action: Action<boolean> ): UIState
{
    return updateCurrentlyRunningProcessesFromBoolean( state, action.payload, ProcessTrackingKey.SWITCH_SUBSCRIPTION );
}

function handleSubscriptionInfoSpinnerEnabled( state: UIState, action: Action<boolean> ): UIState
{
    return updateCurrentlyRunningProcessesFromBoolean( state, action.payload, ProcessTrackingKey.SUBSCRIPTION_INFO );
}

function handleShowUnscheduledPostsInScheduleTab( state: UIState, action: Action<boolean> ): UIState
{
    return {
        ...state,
        showUnscheduledPostsInScheduleTab: action.payload,
    };
}

function handleCreateYourOwnBannerButtonVisibility( state: UIState, action: Action<boolean> ): UIState
{
    return {
        ...state,
        isCreateYourOwnBannerButtonVisible: action.payload,
    };
}

function handleShowYouAroundTourVisibility( state: UIState, action: Action<boolean> ): UIState
{
    return {
        ...state,
        showYouAroundTourVisible: action.payload,
    };
}

function handleShowCoachMark( state: UIState, action: Action<CoachMarkNameKey> ): UIState
{
    const coachMarks: CoachMarks = state.coachMarks;
    const coachMarkName: CoachMarkNameKey = action.payload;
    const coachMark: CoachMarkState = coachMarks[coachMarkName];
    const newCoachMarkState: CoachMarkState = {
        ...coachMark,
        isVisible: true,
    };
    const newCoachMarks: CoachMarks = {
        ...coachMarks,
        [`${coachMarkName}`]: newCoachMarkState,
    };
    return {
        ...state,
        coachMarks: newCoachMarks,
    };
}

function handleClearCoachMark( state: UIState, action: Action<CoachMarkNameKey> ): UIState
{
    const coachMarks: CoachMarks = state.coachMarks;
    const coachMarkName: CoachMarkNameKey = action.payload;
    const coachMark: CoachMarkState = coachMarks[coachMarkName];
    const newCoachMarkState: CoachMarkState = {
        ...coachMark,
        cleared: true,
        isVisible: false,
    };
    const newCoachMarks = {
        ...coachMarks,
        [`${coachMarkName}`]: newCoachMarkState,
    };
    return {
        ...state,
        coachMarks: newCoachMarks,
    };
}

function handleHideAllCoachMarks( state: UIState ): UIState
{
    const coachMarks: CoachMarks = state.coachMarks;
    const newCoachMarks = mapValues( coachMarks, ( coachMarkState: CoachMarkState ) =>
    {
        return {
            ...coachMarkState,
            isVisible: false,
        };
    } );

    return {
        ...state,
        coachMarks: newCoachMarks as CoachMarks,
    };
}

function handlePaymentMethodError( state: UIState, action: Action<string> ): UIState
{
    return {
        ...state,
        paymentMethodError: action.payload,
    };
}

function handlePaymentFormError( state: UIState, action: Action<PaymentFormError> ): UIState
{
    return {
        ...state,
        paymentFormError: action.payload,
    };
}

function handlePaymentFormClear( state: UIState ): UIState
{
    return {
        ...state,
        paymentFormError: null,
    };
}

function handleFacebookAdsSubmitError( state: UIState, action: Action<string> ): UIState
{
    return {
        ...state,
        facebookAdsSubmitError: action.payload,
    };
}

function handleSelectedDesignElementSet( state: UIState, action: Action<DesignElement> ): UIState
{
    return {
        ...state,
        selectedDesignElement: action.payload,
    };
}

function handleDesignElementPropertiesReceived( state: UIState, action: Action<DesignElementProperties> ): UIState
{
    return {
        ...state,
        designElementProperties: action.payload,
    };
}

function handleSlideTransitionPropertiesReceived( state: UIState, action: Action<DesignElementProperties> ): UIState
{
    return {
        ...state,
        slideTransitionProperties: action.payload,
    };
}

function handleBackgroundAnimationPropertiesReceived( state: UIState, action: Action<DesignElementProperties> ): UIState
{
    return {
        ...state,
        backgroundAnimationProperties: action.payload,
    };
}

function handleDesignPresetsReceived( state: UIState, action: Action<DesignPresets> ): UIState
{
    return {
        ...state,
        designPresets: action.payload,
    };
}

function handleTextAnimationChoicesSet( state: UIState, action: Action<TextAnimationChoices> ): UIState
{
    return {
        ...state,
        textAnimationChoices: action.payload,
    };
}

function handleGlobalTextAnimationChoicesSet( state: UIState, action: Action<TextAnimationChoices> ): UIState
{
    return {
        ...state,
        globalTextAnimationChoices: action.payload,
    };
}

function handleBackgroundAnimationChoicesSet( state: UIState, action: Action<BackgroundAnimationChoices> ): UIState
{
    return {
        ...state,
        backgroundAnimationChoices: action.payload,
    };
}

function handleSlideTransitionChoicesSet( state: UIState, action: Action<SlideTransitionChoices> ): UIState
{
    return {
        ...state,
        slideTransitionChoices: action.payload,
    };
}

export function handleSlideSummarySet( state: UIState, action: Action<SlideSummaryResponse> ): UIState
{
    return {
        ...state,
        slideSummary: action.payload.slideSummary,
    };
}

export function handleSlideSummaryClear( state: UIState ): UIState
{
    return {
        ...state,
        slideSummary: null,
    };
}

function handleSetTextPropertiesSubPanelCaptionData( state: UIState, action: Action<TextPickerCaptionData> ): UIState
{
    return {
        ...state,
        textPropertiesSubPanelCaptionData: action.payload,
    };
}

function handleClearTextPropertiesSubPanelCaptionData( state: UIState ): UIState
{
    return {
        ...state,
        textPropertiesSubPanelCaptionData: null,
        designElementProperties: null,
    };
}

function updateCurrentlyRunningProcessesFromBoolean( state: UIState, value: boolean, processKey: ProcessTrackingKey )
{
    if ( value )
    {
        return handleTrackProcessKeyAsRunning( state, processKey );
    }
    else
    {
        return handleTrackProcessKeyAsStopped( state, processKey );
    }
}

export function getCustomizableCanvas( state: StoreState )
{
    return state.ui.canvasUpdaters[CUSTOMIZABLE_DESIGN_ID];
}

export function getBrandSlideCanvas( state: StoreState )
{
    return state.ui.canvasUpdaters[BRAND_SLIDE_DESIGN_CANVAS_ID];
}

export function getCurrentDate(): Date
{
    return startOfDay( new Date() );
}

export const getSelectedDate = ( state: StoreState ) => state.ui.selectedDate;
export const shouldShowUnscheduledPostsInScheduleTab = ( state: StoreState ) => !!state.ui.showUnscheduledPostsInScheduleTab;

export const getRedirectUrl = ( state: StoreState, defaultUrl?: string ) => state.ui.redirectUrl || defaultUrl;

export const getHomePageAlert = ( state: StoreState ) => state.ui.homePageAlert;

export const getCollectionPageSource = ( state: StoreState ) => state.ui.collectionPageSource;

export const getCoachMarks = ( state: StoreState ) =>
{
    return state.ui.coachMarks;
};

export function getSelectedDayString( state: StoreState )
{
    const { selectedDate } = state.ui;
    const currentDate = getCurrentDate();
    if ( !selectedDate || isSameDay( currentDate, selectedDate ) )
    {
        return "today";
    }

    const dayDifference = differenceInCalendarDays( selectedDate, currentDate );
    if ( dayDifference === 1 )
    {
        return "tomorrow";
    }
    else if ( dayDifference === -1 )
    {
        return "yesterday";
    }

    return format( selectedDate, "M/DD" );
}

export function getEditorControl( state: StoreState ): IControlConfig
{
    const controls = getDesignControlsConfig( state, getSelectedDesign( state ) );
    if ( !controls )
    {
        return;
    }
    return find( controls.controls, { id: getControlId( state ) } );
}

export function getEditorControlValue( state: StoreState ): EditorDataValue
{
    const { editorType } = state.ui.customizePage;
    const { commonInputData } = state.mixModel;
    switch ( editorType )
    {
        case EDITOR_TYPES.TEXT_CONTROL:
            return {
                primaryText: commonInputData.originalHeadlineText,
                secondaryText: commonInputData.originalText,
            };
        case EDITOR_TYPES.MEDIA_CONTROL:
            return commonInputData.originalImageList;
        case EDITOR_TYPES.AUDIO_CONTROL:
            return state.musicCatalog.idToObj[state.mixModel.musicId];
        case EDITOR_TYPES.CONTROL:
            return getControlValue( state, getSelectedDesign( state ), getControlId( state ) );
        case EDITOR_TYPES.ASPECT_RATIO_CONTROL:
            return getDesignAspectRatio( state );
        case EDITOR_TYPES.TEMPLATES_CONTROL:
            return null;
    }
}

export function getDesignCatalogAspectRatio( state: StoreState ): AspectRatio
{
    return state.ui.designCatalogDialog.selectedAspectRatio;
}

export function getFontAutocompleteData( state: StoreState ): FontAutoCompleteData
{
    return state.ui.fontAutocompleteData;
}

export function getFilteredDesignPreviews( state: StoreState, categories: CatalogCategory[] ): DesignPreview[]
{
    const selectedCategory = getSelectedDesignCatalogCategory( state, categories );
    const aspectRatio = getDesignCatalogAspectRatio( state );
    if ( !selectedCategory )
    {
        return [];
    }

    const selectedCategoryDesigns = filter( selectedCategory.designs );
    const designPreviews = map( selectedCategoryDesigns, ( designId ) => getDesignPreviewsForDesignAndAspectRatio( state, designId, aspectRatio ) );
    return filter( flatten( designPreviews ) );
}

export function getEditedBusinessInfo( state: StoreState ): Partial<UserBusiness>
{
    const currentBusiness: Partial<UserBusiness> = getCurrentBusiness( state ) || {};
    return defaults( {}, state.ui.businessData, currentBusiness );
}

export function getNewBusinessAPIData( state: StoreState ): NewBusinessAPIData
{
    const { name, business_type_id } = getEditedBusinessInfo( state );
    return {
        business_name: name,
        business_type_id,
    };
}

export function isBusinessInfoChanged( state: StoreState ): boolean
{
    return isBusinessTypeChanged( state ) || isBusinessNameChanged( state );
}

export function isBusinessTypeChanged( state: StoreState ): boolean
{
    const currentBusiness: Partial<UserBusiness> = getCurrentBusiness( state ) || {};
    const editedBusiness = getEditedBusinessInfo( state ) || {};

    return currentBusiness.business_type_id !== editedBusiness.business_type_id;
}

export function isBusinessNameChanged( state: StoreState ): boolean
{
    const currentBusiness: Partial<UserBusiness> = getCurrentBusiness( state ) || {};
    const editedBusiness = getEditedBusinessInfo( state ) || {};

    return currentBusiness.name !== editedBusiness.name;
}

export function showUiBrandLogo( state: StoreState )
{
    const editedBusinessInfo = getEditedBusinessInfo( state );
    if ( editedBusinessInfo )
    {
        return !!editedBusinessInfo.show_business_logo_flag;
    }
    return showBrandLogo( state );
}

function handleFacebookAdMobileWebViewOpened( state: UIState, action: Action<boolean> ): UIState
{
    return {
        ...state,
        facebookAdMobileWebView: action.payload,
    };
}

function handleManageBusinessLogosSelectionSet( state: UIState, action: Action<BrandLogo[]> ): UIState
{
    const newManageBusinessLogos = {
        ...state.manageBusinessLogos,
        selectedLogos: action.payload,
    };
    return {
        ...state,
        manageBusinessLogos: newManageBusinessLogos,
    };
}

function handleManageBusinessLogosSelectionClear( state: UIState ): UIState
{
    const newManageBusinessLogos = {
        ...state.manageBusinessLogos,
        selectedLogos: [],
    };
    return {
        ...state,
        manageBusinessLogos: newManageBusinessLogos,
    };
}

function handleSetIsAcceptTeamInviteFlow( state: UIState, action: Action<boolean> ): UIState
{
    return {
        ...state,
        isAcceptTeamInviteFlow: action.payload,
    };
}

function handleDesignMediaObjectsResponseReceived( state: UIState, action: Action<DesignMediaObject> ): UIState
{
    return {
        ...state,
        activeDesignMediaObject: action.payload,
    };
}

function handleReplaceMediaModeEnded( state: UIState ): UIState
{
    const { activeDesignMediaObject, ...newState } = state;
    return newState;
}

function handleSetPremiumEpidemicSoundPreview( state: UIState, action: Action<EpidemicSoundTrack> ): UIState
{
    return {
        ...state,
        premiumEpidemicSoundTrackPreview: action.payload,
    };
}

function handleClearPremiumEpidemicSoundPreview( state: UIState ): UIState
{
    const { premiumEpidemicSoundTrackPreview, ...newState } = state;
    return newState;
}

function handleTemplatePreviewAnimationCompleted( state: UIState ): UIState
{
    return {
        ...state,
        templatePreviewAnimationComplete: true,
    };
}

function handleTemplatePreviewAnimationStarted( state: UIState ): UIState
{
    return {
        ...state,
        templatePreviewAnimationComplete: false,
    };
}

function handleSetTemplatePreviewApplyBrandStylesSwitchToggled( state: UIState ): UIState
{
    return {
        ...state,
        isTemplatePreviewApplyBrandStylesSwitchToggled: true,
    };
}

function handleSocialCalendarDayClicked( state: UIState, action: Action<string> ): UIState
{
    return {
        ...state,
        socialCalendarDateSelected: action.payload,
    };
}

function handleBusinessTypeFilterUpdated( state: UIState, action: Action<BusinessType> ): UIState
{
    return {
        ...state,
        businessTypeFilterSelected: action.payload,
    };
}

function handleBusinessTypeFilterCleared( state: UIState ): UIState
{
    return {
        ...state,
        businessTypeFilterSelected: null,
    };
}

function handleShowAllBusinessTypeFiltersToggled( state: UIState, action: Action<boolean> ): UIState
{
    return {
        ...state,
        showAllBusinessTypeFilters: action.payload,
    };
}

export const getTemplatePreviewAnimationComplete = ( state: StoreState ) => state.ui.templatePreviewAnimationComplete;
export const isFacebookAdMobileWebView = ( state: StoreState ) => state.ui.facebookAdMobileWebView;
export const getDesignCurrentSlideIndex = ( state: StoreState ) => state.ui.designSlideIndexData.currentSlideIndex;
export const getDesignTotalNumberOfSlides = ( state: StoreState ) => state.ui.designSlideIndexData.totalNumberOfSlides;
export const getRecordProgress = ( state: StoreState ) => state.ui.recordProgress || { state: "loading" };
export const getRecordError = ( state: StoreState ) => state.ui.recordError;
export const getEmailForm = ( state: StoreState ) => state.ui.emailForm;
export const getThirdPartyErrorMessage = ( state: StoreState ) => state.ui.thirdPartyLoginError.errorMessage;
export const shouldShowThirdPartyLoginError = ( state: StoreState ) => state.ui.thirdPartyLoginError.showError;
export const getFocusElement = ( state: StoreState ) => state.ui.customizePage.focusElement;
export const getResetFormData = ( state: StoreState ) => state.ui.resetForm;
export const getEditorType = ( state: StoreState ) => state.ui.customizePage.editorType;
export const isBrandSlideTabSelected = ( state: StoreState ) => getEditorType( state ) === EDITOR_TYPES.BRAND_SLIDE_CONTROL;
export const isTextTabSelected = ( state: StoreState ) => getEditorType( state ) === EDITOR_TYPES.TEXT_CONTROL;
export const isMediaTabSelected = ( state: StoreState ) => getEditorType( state ) === EDITOR_TYPES.MEDIA_CONTROL;
export const isBackgroundMediaTabSelected = ( state: StoreState ) => getEditorType( state ) === EDITOR_TYPES.CONTROL
                                                                     && getControlId( state ) === EDITOR_TYPES.BACKGROUND_MEDIA_CONTROL;
export const shouldCurrentControlShowSlideSummaryPanel = ( state: StoreState ) => isMediaTabSelected( state ) || isBackgroundMediaTabSelected( state )
                                                                                  || isTextTabSelected( state );
export const getControlId = ( state: StoreState ) => state.ui.customizePage.controlId;
export const getFirstFontControl = ( state: StoreState ) =>
{
    const controls = getDesignControlsConfig( state, getSelectedDesign( state ) );
    if ( !controls )
    {
        return;
    }
    return find( controls.controls, { type: EDITOR_TYPES.FONT_CONTROL } ) as FontControlConfig;
};
export const getCustomizeTipsBanners = ( state: StoreState ) => (state.ui.customizeTipBanners && state.ui.customizeTipBanners.dismissedBanners);
export const shouldShowTipBannersForPost = ( state: StoreState, postId: number ): boolean =>
{
    if ( isFacebookAd( state ) && state.ui.customizeTipBanners )
    {
        const { postsShownTipBanners } = state.ui.customizeTipBanners;
        return postsShownTipBanners &&
               (postsShownTipBanners.indexOf( postId ) !== -1 ||
                postsShownTipBanners.length < MAX_NUMBER_OF_TIMES_TO_SHOW_CUSTOMIZE_TIP_BANNERS);
    }
    return false;
};

export function getCurrentMusicTrack( state: StoreState ): MusicTrack
{
    const musicTrack = getAudioPlayerMusicTrack( state );

    if ( isNil( musicTrack ) )
    {
        return null;
    }
    else
    {
        return { url: musicTrack.audio_url };
    }
}

export const isPlayingAnyAudio = ( state: StoreState ) => !isNil( getAudioPlayerMusicTrack( state ) );
export const getAudioPlayerMusicTrack = ( state: StoreState ) => state.ui.audioPlayerMusicTrack;

export const getMusicCatalogSelected = ( state: StoreState ) => state.ui.musicCatalogSelection;
export const getSchedulePostDialogData = ( state: StoreState ) => state.ui.schedulePostDialogData;
export const getHasLoadedMeThisSession = ( state: StoreState ) => state.ui.hasLoadedMeThisSession;
export const getHasSubscribedSuccessfullyThisSession = ( state: StoreState ) => state.ui.hasSubscribedSuccessfullyThisSession;
export const getConfirmFacebookDialogData = ( state: StoreState ) => state.ui.confirmFacebookDialogData;
export const getBusinessData = ( state: StoreState ) => state.ui.businessData;
export const getBusinessName = ( state: StoreState ) => state.ui.businessData.name;
export const getBusinessTempLogo = ( state: StoreState ) => state.ui.businessData.tempLogo;
export const getSelectedBusinessTypeId = ( state: StoreState ) => state.ui.businessData && state.ui.businessData.business_type_id;
export const getTeamMemberInviteEmail = ( state: StoreState ) => state.ui.teamMemberInviteEmail;
export const isVideoTrimmerModalOpen = ( state: StoreState ) => state.ui.videoTrimmerModalOpen;
export const getTrimVideoCombinedProgress = ( state: StoreState ) => state.ui.trimVideoProgress && state.ui.trimVideoProgress.combinedProgress;
export const getConvertVideoCombinedProgress = ( state: StoreState ) => state.ui.convertVideoProgress
                                                                        && state.ui.convertVideoProgress.combinedProgress;
export const getExternalMediaUploadStatus = ( state: StoreState ) => state.ui.externalMediaUploadStatus;
export const isStockMediaUploading = ( state: StoreState ) => state.ui.externalMediaUploadStatus && some( state.ui.externalMediaUploadStatus,
    ( mediaUploadStatus ) => mediaUploadStatus );
export const getShouldShowDefaultStockMediaSearchResults = ( state: StoreState ) => !!state.ui.shouldShowDefaultStockMediaSearchResults;
export const getBusinessColor = ( state: StoreState, userBusinessColorKey: BusinessColorKey ) => state.ui.businessData[userBusinessColorKey];
export const isShowYouAroundTourVisible = ( state: StoreState ) => state.ui.showYouAroundTourVisible;
export const isCreateYourOwnBannerButtonVisible = ( state: StoreState ): boolean => state.ui.isCreateYourOwnBannerButtonVisible;

export const areStripePlansLoading = ( state: StoreState ) => isProcessRunning( state, ProcessTrackingKey.STRIPE_PLANS_LOADING );

export const getPresentAfterShareDialogStatus = ( state: StoreState ): boolean => state.ui.presentAfterShareDialog;
export const hasSeenAfterShareDialog = ( state: StoreState ): boolean => state.ui.hasSeenAfterShareDialog;
export const hasSeenRiplUserSurvey2023 = ( state: StoreState ): boolean => state.ui.hasSeenRiplUserSurvey2023;

export const getPaymentFormError = ( state: StoreState ): PaymentFormError => state.ui.paymentFormError && state.ui.paymentFormError;

export const isProcessRunning = ( state: StoreState, processKey: string ) => includes( state.ui.currentlyRunningProcesses, processKey );
export const isProcessCollectionItemRunning = ( state: StoreState, item: ProcessCollectionItem ) =>
{
    const currentlyRunningItemsInGroup = get( state.ui.currentlyRunningProcessesGroups || {}, item.collection, [] );
    return includes( currentlyRunningItemsInGroup, item.id );
};
export const switchSubscriptionSpinnerEnabled = ( state: StoreState ) => isProcessRunning( state, ProcessTrackingKey.SWITCH_SUBSCRIPTION );

export const isCardProcessing = ( state: StoreState ) => isProcessRunning( state, ProcessTrackingKey.CARD );
export const isSubscriptionInfoSpinnerEnabled = ( state: StoreState ) => isProcessRunning( state, ProcessTrackingKey.SUBSCRIPTION_INFO );
export const isCollectionSpinnerEnabled = ( state: StoreState ) => isProcessRunning( state, ProcessTrackingKey.COLLECTION_LOAD );
export const isContentSearchSpinnerEnabled = ( state: StoreState ) => isProcessRunning( state, ProcessTrackingKey.CONTENT_SEARCH );
export const isCustomMusicUploading = ( state: StoreState ) => isProcessRunning( state, ProcessTrackingKey.CUSTOM_MUSIC_UPLOADING );
export const isPostFinishInProgress = ( state: StoreState ) => isProcessRunning( state, ProcessTrackingKey.FINISH_POST );
export const isPostUpdateInProgress = ( state: StoreState ) => isProcessRunning( state, ProcessTrackingKey.UPDATE_POST );
export const isBusinessUpdateInProgress = ( state: StoreState ) => isProcessRunning( state, ProcessTrackingKey.UPDATE_BUSINESS );
export const isFetchingMeData = ( state: StoreState ) => isProcessRunning( state, ProcessTrackingKey.FETCHING_ME_DATA );
export const isCreateBusinessRequestInFlight = ( state: StoreState ) => isProcessRunning( state, ProcessTrackingKey.CREATING_BUSINESS );
export const isLogoUpdateInProgress = ( state: StoreState ) => isProcessRunning( state, ProcessTrackingKey.UPDATE_LOGO );
export const isLoadMoreRecentPostsInProgress = ( state: StoreState ) => isProcessRunning( state, ProcessTrackingKey.LOAD_MORE_MOST_RECENT_POSTS );
export const isLoadingEpidemicSoundCatalogInProgress = ( state: StoreState ) => isProcessRunning( state,
    ProcessTrackingKey.LOAD_FREE_EPIDEMIC_SOUND_CATALOG );
export const isLoadingEpidemicSoundUserCollectionsInProgress = ( state: StoreState ) => isProcessRunning( state,
    ProcessTrackingKey.LOAD_EPIDEMIC_SOUND_USER_COLLECTIONS );
export const isLoadingEpidemicSoundUserCollectionDetailsInProgress = ( state: StoreState ) => isProcessRunning( state,
    ProcessTrackingKey.LOAD_EPIDEMIC_SOUND_USER_COLLECTION_DETAILS );
export const hasLoadedAllRecentPosts = ( state: StoreState ) => state.ui.hasLoadedAllRecentPosts;
export const getCoachMarkStylizedTextButtonCleared = ( state: StoreState ) => state.ui.coachMarks.stylizedTextButton
                                                                              && state.ui.coachMarks.stylizedTextButton.cleared;
export const shouldShowCoachMarkMoreLayouts = ( state: StoreState ) => state.ui.coachMarks.moreLayoutsButton
                                                                       && !state.ui.coachMarks.moreLayoutsButton.cleared;
export const getSelectedDesignElement = ( state: StoreState ) => state.ui.selectedDesignElement;
export const getDesignElementProperties = ( state: StoreState ) => state.ui.designElementProperties;
export const getSlideTransitionProperties = ( state: StoreState ) => state.ui.slideTransitionProperties;
export const getBackgroundAnimationProperties = ( state: StoreState ) => state.ui.backgroundAnimationProperties;
export const getTextPropertiesSubPanelCaptionData = ( state: StoreState ) => state.ui.textPropertiesSubPanelCaptionData;
export const getSelectedManageBusinessLogos = ( state: StoreState ) => state.ui.manageBusinessLogos.selectedLogos;
export const hasSelectedManageBusinessLogos = ( state: StoreState ): boolean => !!state.ui.manageBusinessLogos.selectedLogos.length;
export const doesCurrentDesignSupportExtraCaptions = ( state: StoreState ): boolean => !!state.ui.designSupportsExtraCaptions;
export const getDesignCaptionData = ( state: StoreState ): { [id: string]: DesignCaptionData } => state.ui.designCaptionData;
export const getDesignCaptionDataById = ( state: StoreState, id: string ): DesignCaptionData =>
{
    const captionData = getDesignCaptionData( state );
    return captionData ? captionData[id] : null;
};

export const getSortedCaptionsTypes = ( state: StoreState ): SortedCaptionsByType =>
{

    const captions = getDesignCaptionData( state );
    const regularCaptions: DesignCaptionData[] = [];
    const extraCaptions: DesignCaptionData[] = [];
    if ( captions )
    {
        forIn( captions, ( captionData ) =>
        {
            if ( captionData.canDelete )
            {
                extraCaptions.push( captionData );
            }
            else
            {
                regularCaptions.push( captionData );
            }
        } );
    }
    return {
        regularCaptions,
        extraCaptions,
    };
};

export const getGlobalExtraCaptions = ( state: StoreState ): DesignCaptionData[] =>
{
    const extraCaptions = getSortedCaptionsTypes( state ).extraCaptions;
    return filter( extraCaptions, ( captionData: DesignCaptionData ) => captionData.showOnAllSlides );
};

export const getPerSlideExtraCaptions = ( state: StoreState ): DesignCaptionData[] =>
{
    const extraCaptions = getSortedCaptionsTypes( state ).extraCaptions;
    return filter( extraCaptions, ( captionData: DesignCaptionData ) => !captionData.showOnAllSlides );
};

export const getSelectedCaption = ( state: StoreState ): DesignCaptionData =>
{
    const captions = getDesignCaptionData( state );
    return first( filter( captions, ( captionData: DesignCaptionData ) => captionData.isSelected ) );
};
export const isAcceptTeamInviteFlow = ( state: StoreState ): boolean => state.ui.isAcceptTeamInviteFlow;
export const getActiveDesignMediaObject = ( state: StoreState ) => state.ui.activeDesignMediaObject;
export const hasPartnerLoginError = ( state: StoreState ) => !!state.ui.partnerLoginError;
export const getPremiumEpidemicSoundTrackPreview = ( state: StoreState ) => state.ui.premiumEpidemicSoundTrackPreview;
export const hasTemplatePreviewApplyBrandStylesSwitchToggled = ( state: StoreState ) => state.ui.isTemplatePreviewApplyBrandStylesSwitchToggled;
export const getStylizedTextChoices = ( state: StoreState ) => state.ui.stylizedTextChoices;
export const getTextAnimationChoices = ( state: StoreState ) => state.ui.textAnimationChoices;
export const getGlobalTextAnimationChoices = ( state: StoreState ) => state.ui.globalTextAnimationChoices;
export const getSlideTransitionChoices = ( state: StoreState ) => state.ui.slideTransitionChoices;
export const getBackgroundAnimationChoices = ( state: StoreState ) => state.ui.backgroundAnimationChoices;
export const getDesignPresets = ( state: StoreState ) => state.ui.designPresets;
export const getSlideSummary = ( state: StoreState ) => state.ui.slideSummary;
export const getBusinessTypeFilter = ( state: StoreState ) =>
{
    if ( state.ui.businessTypeFilterSelected )
    {
        return state.ui.businessTypeFilterSelected;
    }
    else
    {
        return getCurrentBusinessType( state );
    }
};

export const getBusinessTypeFilterShowAllFlag = ( state: StoreState ) => state.ui.showAllBusinessTypeFilters;
