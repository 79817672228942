import { SlideTransitionChoice, SlideTransitionChoices } from "../../../_types";
import { map } from "lodash";
import { SlideTransitionChoiceButton } from "../SlideTransitionChoice";
import * as React from "react";

interface SlideTransitionChoicesPanelProps
{
    slideTransitionChoices?: SlideTransitionChoices;
    preferredSlideTransition?: SlideTransitionChoice;
    onPreferredSlideTransitionSelected( slideTransitionChoice: SlideTransitionChoice ): void;
}

export function SlideTransitionChoicesPanel( props: SlideTransitionChoicesPanelProps )
{
    const choices = map( props.slideTransitionChoices.slideTransitions, ( choice ) =>
        <SlideTransitionChoiceButton key={choice.id}
                                     choice={choice}
                                     onPreferredSlideTransitionSelected={props.onPreferredSlideTransitionSelected}
                                     selected={props.preferredSlideTransition.id === choice.id}/> );

    return <div className="listPicker">{choices}</div>;
}