import * as React from "react";
import { DesignElementProperty, SlideTransitionChoice, TransitionOption } from "../../_types";
import classNames from "classnames";
import { TEXT_STYLE_STATUS_DEVELOPMENT } from "../../_types/api";

export interface DesignElementListOptionProps
{
    designElementProperty: DesignElementProperty;
    options: TransitionOption;
    preferredBackgroundAnimation?: SlideTransitionChoice;
    onPreferredBackgroundAnimationSelected( slideTransitionChoice: SlideTransitionChoice ): void;
}

export interface BackgroundAnimationListOptionDispatchProps
{
    ( designElementProperty: DesignElementProperty, designElementListOption: any );
}

export class BackgroundAnimationListOption
    extends React.PureComponent<DesignElementListOptionProps & BackgroundAnimationListOptionDispatchProps>
{
    public render()
    {
        const displayName = this.props.options.displayName;

        return (
            <div className={classNames( "item",
                {
                    selected: this.props.designElementProperty.selected === this.props.options.value,
                    devMode: this.props.options.status === TEXT_STYLE_STATUS_DEVELOPMENT,
                } )}
                 onClick={this.handleSelected}>
                <div className="description">{displayName}</div>
            </div>
        );
    }

    private handleSelected = () =>
    {
        const animationOptions = { ...(this.props.preferredBackgroundAnimation.animationOptions || {}) };
        animationOptions[this.props.designElementProperty.id] = this.props.options.value;

        const newPreferredBackgroundAnimation = { ...this.props.preferredBackgroundAnimation, animationOptions };

        this.props.onPreferredBackgroundAnimationSelected( newPreferredBackgroundAnimation );
    }
}
