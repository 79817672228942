import * as React from "react";
import classNames from "classnames";
import Button from "react-md/lib/Buttons/Button";
import { getCustomizableCanvas, getSlideSummary } from "../ducks/ui";
import { useSelector } from "react-redux";

export function AddSlideButton()
{
    const canvas = useSelector( getCustomizableCanvas );
    const slideSummary = useSelector( getSlideSummary );

    function canAddMoreSlides()
    {
        return slideSummary && slideSummary.length < 12;
    }

    function handleAddSlide()
    {
        if ( canvas && canAddMoreSlides() )
        {
            canvas.tellDesignToHandleAddSlide();
        }
    }

    return (<div className="addSlideButtonContainer horizontalScrollChild">
        <Button flat
                onClick={handleAddSlide}
                tooltipLabel={canAddMoreSlides() ? "Add a slide" : "Max slides reached"}
                tooltipPosition="right"
                tooltipClassName="tooltip"
                inkDisabled={!canAddMoreSlides()}
                className={classNames( "addSlideButton fullWidth",
                    {
                        unableToAddMore: !canAddMoreSlides(),
                    } )}>
            <i className="material-icons">add</i>
        </Button>
    </div>);
}
