import * as React from "react";
import RefreshFacebookButton from "./containers/RefreshFacebookButton.container";
import { SocialAccountConnectButton } from "./SocialAccountConnectButton";
import { isFacebookAccountType } from "../ducks/user";
import { facebookServices } from "../services";
import CantRefreshButtonContainer from "./containers/CantRefreshButton.container";
import { FacebookSocialNetworkAccountType, SocialNetworkAccountType } from "../_types";

export interface RefreshButtonProps
{
    label?: string;
    className?: string;
    accountType: SocialNetworkAccountType;
    buttonText?: string;
    accountId: number;
}

export interface RefreshButtonDispatchProps
{
    onClick?: ( event: React.MouseEvent<HTMLElement> ) => void;
}

const REFRESH_BUTTON_LABEL = "Refresh";

// TODO Determine if this and ConnectButton can be unified.
export class RefreshButton extends React.PureComponent<RefreshButtonProps & RefreshButtonDispatchProps>
{
    public render()
    {
        const buttonText = this.props.buttonText || REFRESH_BUTTON_LABEL;
        if ( isFacebookAccountType( this.props.accountType ) )
        {
            const { onClick, accountType, ...props } = this.props;
            if ( facebookServices.isAccountOwnedByDifferentUser( this.props.accountId ) )
            {
                return <CantRefreshButtonContainer label={this.props.label} className={this.props.className}/>;
            }
            else
            {

                return <RefreshFacebookButton {...props}
                                              accountType={accountType as FacebookSocialNetworkAccountType}
                                              buttonText={buttonText}/>;
            }
        }
        else
        {
            return <SocialAccountConnectButton {...this.props} buttonText={buttonText}/>;
        }
    }
}
