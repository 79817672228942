import { connect } from "react-redux";
import { Dispatch } from "redux";
import { BackgroundChoice, StoreState } from "../../../_types";
import { ColorControlPicker, ColorControlPickerDispatchProps, ColorControlPickerProps } from "../../ColorControlPicker";
import { getCurrentBusiness } from "../../../ducks/userBusiness";
import { getEditorControl, getEditorControlValue } from "../../../ducks/ui";
import { mixModelActions } from "../../../actions";
import { store } from "../../../store";
import { getControlDataForCurrentSelectedDesign } from "../../../ducks/mixModel";

const mapStateToProps = ( storeState: StoreState ): ColorControlPickerProps =>
{
    const backgroundChoice = getEditorControlValue( storeState ) as BackgroundChoice;
    return {
        currentSelectedHexValue: backgroundChoice.color && backgroundChoice.color.value,
        control: getEditorControl( storeState ),
        currentBusiness: getCurrentBusiness( storeState ),
        disabled: backgroundChoice.media && !backgroundChoice.media.transparent,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): ColorControlPickerDispatchProps =>
{
    return {
        applyHexColorToControl: ( controlId: string, colorHex: string ) =>
        {
            const storeState = store.getState();
            const currentControlData = getControlDataForCurrentSelectedDesign( storeState );

            const currentBackgroundMediaControlData = currentControlData[controlId] as BackgroundChoice;

            const colorValue = { type: "hex", value: colorHex };
            const backgroundChoice = currentBackgroundMediaControlData.media && currentBackgroundMediaControlData.media.transparent
                                     ? { ...currentBackgroundMediaControlData, color: colorValue }
                                     : { media: undefined, color: colorValue };

            dispatch( mixModelActions.controlDataUpdated( { [controlId]: backgroundChoice } ) );
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( ColorControlPicker );
