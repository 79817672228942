import * as React from "react";
import { Link } from "react-router-dom";
import { USER_SETTINGS_URL } from "../helpers";

export interface SubscriptionTermsPageProps
{
    isPayingUserOrAcceptedProPreview: boolean;
}

export default class SubscriptionTermsPage extends React.PureComponent<SubscriptionTermsPageProps>
{
    public render()
    {
        return (
            <div className="page subscriptionPage">
                <div className="title">
                    <h1> Subscription Terms </h1>
                </div>
                <div className="body">
                    <ul>
                        <li>Subscription automatically renews unless auto-renew is turned off at least 24-hours before the end of the current period
                        </li>
                        <br/>
                        <li>Account will be charged for renewal within 24-hours prior to the end of the current period, and identify the cost of the
                            renewal
                        </li>
                        <br/>
                        <li>Subscriptions may be managed by the user and auto-renewal may be turned off by going to the
                            user's {this.getAccountSettingsLink()} after
                            purchase
                        </li>
                        <br/>
                        <li>Any unused portion of a free trial period, if offered, will be forfeited when the user purchases a subscription,
                            where
                            applicable
                        </li>
                        <br/>
                        Read more about our <a className="standardLink" href="https://www.ripl.com/privacypolicy.html">Privacy Policy </a> and
                        <a className="riplLink" href="https://www.ripl.com/termsofuse.html"> Terms of Use </a>
                    </ul>
                </div>
            </div>
        );
    }

    private getAccountSettingsLink = () =>
    {
        if ( this.props.isPayingUserOrAcceptedProPreview )
        {
            return <Link to={USER_SETTINGS_URL}>Account Settings</Link>;
        }
        return "Account Settings";
    }
}
