import { map } from "lodash";
import { DataTable, TableBody, TableColumn, TableHeader, TableRow } from "react-md";
import * as React from "react";
import { UsersTabTableMemberRow } from "./UsersTabTableMemberRow";
import { TeamInvite, TeamMember } from "../../../_types";
import { UsersTabTableInvitationRow } from "./UsersTabTableInvitationRow";

export interface UsersTabTableProps
{
    showManageColumn: boolean;
    members: TeamMember[];
    invitations: TeamInvite[];
}

export function UsersTabTable( props: UsersTabTableProps )
{
    const { showManageColumn, members, invitations } = props;

    const memberRows = map( members,
        ( member ) => <UsersTabTableMemberRow key={member.id} member={member} showManageColumn={showManageColumn}/> );
    const inviteRows = map( invitations,
        ( invite ) => <UsersTabTableInvitationRow key={invite.id} invitation={invite} showManageColumn={showManageColumn}/> );

    return <DataTable plain className="usersTabTable">

        <TableHeader>
            <TableRow>
                <TableColumn>Name</TableColumn>
                <TableColumn>Email</TableColumn>
                <TableColumn>Role</TableColumn>
                {showManageColumn && <TableColumn>Manage</TableColumn>}
            </TableRow>
        </TableHeader>

        <TableBody>
            {[...memberRows, ...inviteRows]}
        </TableBody>
    </DataTable>;
}
