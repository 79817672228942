import * as React from "react";

export const SadfaceIcon: React.FC<{}> = () =>
{
    return (
        /* tslint:disable:max-line-length */
        // @ts-ignore
        <svg className="dialogIcon" width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.5 47C36.9264 47 47 36.9264 47 24.5C47 12.0736 36.9264 2 24.5 2C12.0736 2 2 12.0736 2 24.5C2 36.9264 12.0736 47 24.5 47Z"
                  stroke="#464DE1" strokeWidth="3" strokeMiterlimit="10"/>
            <path
                d="M17.5893 19.1964C19.3201 19.1964 20.7232 17.7933 20.7232 16.0625C20.7232 14.3316 19.3201 12.9285 17.5893 12.9285C15.8585 12.9285 14.4554 14.3316 14.4554 16.0625C14.4554 17.7933 15.8585 19.1964 17.5893 19.1964Z"
                fill="#464DE1"/>
            <path
                d="M30.9285 19.1964C32.6594 19.1964 34.0625 17.7933 34.0625 16.0625C34.0625 14.3316 32.6594 12.9285 30.9285 12.9285C29.1977 12.9285 27.7946 14.3316 27.7946 16.0625C27.7946 17.7933 29.1977 19.1964 30.9285 19.1964Z"
                fill="#464DE1"/>
            <path d="M34.0625 32.7768C33.1786 28.1964 29.1607 24.7411 24.2589 24.7411C19.4375 24.7411 15.3393 28.1964 14.4554 32.7768"
                  stroke="#464DE1" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
        </svg>
    );
};
