import * as React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { apptimizeVariables, getFacebookAppId } from "../helpers";
import {
    facebookServices,
    GROUP_PERMISSIONS,
    INSTAGRAM_BUSINESS_PERMISSIONS,
    LOGIN_PERMISSIONS,
    OPTIONAL_LOGIN_PERMISSIONS,
    PAGE_PERMISSIONS,
} from "../services";
import { SocialAccountConnectButton } from "./SocialAccountConnectButton";
import Button from "react-md/lib/Buttons/Button";
import { concat, join } from "lodash";
import {
    FACEBOOK_ACCOUNT_TYPE,
    FACEBOOK_GROUP_ACCOUNT_TYPE,
    FACEBOOK_INSTAGRAM_ACCOUNT_TYPE,
    FACEBOOK_PAGE_ACCOUNT_TYPE,
} from "../_types/api";
import { FacebookSocialNetworkAccountType, UserState } from "../_types";

export interface FacebookButtonProps
{
    label?: string;
    buttonText?: string;
    accountType: FacebookSocialNetworkAccountType;
    user: UserState;
    className?: string;
    buttonImage?: string;
}

export interface FacebookButtonDispatchProps
{
    onFacebookConnectSuccess: ( accountType: FacebookSocialNetworkAccountType,
                                userId: number,
                                facebookId: number,
                                name: string,
                                facebookAccessToken: string ) => void;
    onButtonClick?: ( accountType: FacebookSocialNetworkAccountType ) => void;
    onFacebookConnectFailure?: ( accountType: FacebookSocialNetworkAccountType,
                                 userId: number,
                                 failureData?: {} ) => void;
}

interface ReactFacebookRenderProps
{
    isSdkLoaded?: boolean;
    isProcessing?: boolean;
    isDisabled?: boolean;
    onClick?( event: React.MouseEvent<HTMLDivElement> ): void;
}

export class FacebookButton extends React.PureComponent<FacebookButtonProps & FacebookButtonDispatchProps>
{
    public render()
    {
        return (
            <FacebookLogin
                appId={getFacebookAppId()}
                className={this.props.className}
                autoLoad={false}
                reAuthenticate={true}
                disableMobileRedirect={true}
                scope={this.determineScope( this.props )}
                fields="name,email,picture"
                version={facebookServices.GRAPH_API_VERSION}
                render={( renderProps: ReactFacebookRenderProps ) =>
                {
                    const { isDisabled, onClick } = renderProps;
                    const { buttonText, label, className } = this.props;
                    return (
                        this.props.buttonImage ?
                        <Button
                            flat
                            className="facebookButton center"
                            onClick={onClick}
                            disabled={this.isDisabled( isDisabled )}>
                            <img src={this.props.buttonImage}/>
                        </Button>
                                               :
                        <SocialAccountConnectButton
                            onClick={onClick}
                            buttonText={buttonText}
                            label={label}
                            className={className}/>
                    );
                }}
                onClick={this.handleClick}
                callback={this.handleFacebookResponse}/>
        );
    }

    private isDisabled( isDisabled: boolean )
    {
        return apptimizeVariables.shouldDisableFacebookConnect() ? true : isDisabled;
    }

    private handleClick = ( e: React.MouseEvent<HTMLDivElement> ) =>
    {
        if ( this.props.onButtonClick )
        {
            this.props.onButtonClick( this.props.accountType );
        }
    }

    // TODO This should be factored into a better form
    private determineScope = ( props ): string =>
    {
        switch ( props.accountType )
        {
            case FACEBOOK_ACCOUNT_TYPE:
                return join( concat( LOGIN_PERMISSIONS, OPTIONAL_LOGIN_PERMISSIONS ), "," );
            case FACEBOOK_PAGE_ACCOUNT_TYPE:
                return join( PAGE_PERMISSIONS, "," );
            case FACEBOOK_INSTAGRAM_ACCOUNT_TYPE:
                return join( INSTAGRAM_BUSINESS_PERMISSIONS, "," );
            case FACEBOOK_GROUP_ACCOUNT_TYPE:
                return join( GROUP_PERMISSIONS, "," );
            default:
            // TODO Handle invalid accountTypes
        }
    }

    private handleFacebookResponse = async ( response ) =>
    {
        if ( response && response.id && response.accessToken )
        {
            const accessToken = response.accessToken;
            const facebookIsAccessible = await this.determineIfPermissionsHaveBeenGranted( this.props.accountType, accessToken );
            // TODO Handle situations when the user doesn't give us permission!
            if ( facebookIsAccessible )
            {
                this.props.onFacebookConnectSuccess( this.props.accountType, this.props.user.id, response.id, response.name, accessToken );
            }
            else if ( this.props.onFacebookConnectFailure )
            {
                this.props.onFacebookConnectFailure(
                    this.props.accountType,
                    this.props.user.id,
                    { permissionsDenied: true, errorText: "User did not grant required Facebook permissions." },
                );
            }
        }
        else if ( this.props.onFacebookConnectFailure )
        {
            this.props.onFacebookConnectFailure( this.props.accountType, this.props.user.id, { response, errorText: "Facebook connect failed." } );
        }
    }

    // TODO This should be factored into a better form
    private determineIfPermissionsHaveBeenGranted = async ( accountType, accessToken ) =>
    {
        switch ( accountType )
        {
            case FACEBOOK_ACCOUNT_TYPE:
                return await facebookServices.verifyUserHasLoginPermissions( accessToken );
            case FACEBOOK_PAGE_ACCOUNT_TYPE:
                return await facebookServices.verifyUserHasPagePermissions( accessToken );
            case FACEBOOK_GROUP_ACCOUNT_TYPE:
                return await facebookServices.verifyUserHasGroupPermissions( accessToken );
            case FACEBOOK_INSTAGRAM_ACCOUNT_TYPE:
                return await facebookServices.verifyUserHasInstagramBusinessPermissions( accessToken );
            default:
            // TODO Handle invalid accountTypes
        }
    }
}
