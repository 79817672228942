import * as React from "react";
import { FormEvent } from "react";
import TextField from "react-md/lib/TextFields/TextField";
import Button from "react-md/lib/Buttons/Button";
import { EmailFormData } from "../_types";
import classNames from "classnames";
import { INVALID_EMAIL_ADDRESS, validateEmail } from "../helpers";

export interface EmailPasswordInputPanelProps extends EmailFormData
{
    onContinueClicked: ( emailAddress: string, password: string ) => void;
    onForgotLogin?: () => void;
    hidePassword?: boolean;
    buttonText?: string;
    optionalClassName?: string;
    showVersionInfo?: boolean;
}

export interface EmailPasswordInputPanelDispatchProps
{
    handlePasswordUpdate( value: string );
    handleEmailUpdate( value: string );
    handleEmailError( value: string );
}

export class EmailPasswordInputPanel extends React.PureComponent<EmailPasswordInputPanelProps & EmailPasswordInputPanelDispatchProps>
{
    public render()
    {
        const emailError = this.props.emailError || this.props.error;
        return (
            <form className="emailPasswordInputPanel" onSubmit={this.handleEmailLogin}>
                <TextField
                    id="log-in-email"
                    type="email"
                    placeholder="Your Email Address"
                    className="loginInput"
                    required
                    error={emailError ? true : undefined}
                    errorText={emailError}
                    value={this.props.email}
                    onChange={this.updateEmail}/>
                {
                    this.props.hidePassword || <TextField
                        id="log-in-pwd"
                        type="password"
                        placeholder="Enter Your Password"
                        className="loginInput"
                        required
                        error={this.props.passwordError ? true : undefined}
                        errorText={this.props.passwordError}
                        value={this.props.password}
                        onChange={this.updatePassword}/>
                }
                {
                    this.props.onForgotLogin &&
                    <div className="text center forgotPassword"><span className="standardLink" onClick={this.props.onForgotLogin}>Forgot password?</span>
                    </div>
                }
                {
                    this.props.children
                }
                <div className={classNames( "emailPasswordButtons", this.props.optionalClassName )}>
                    {this.props.showVersionInfo &&
                     <div className="text footnote version">
                         version: {APP_VERSION}
                     </div>}
                    <Button flat primary swapTheming
                            className="large continueBtn continue100"
                            type="submit">{this.props.buttonText ? this.props.buttonText : "Continue"}</Button>
                </div>
            </form>
        );
    }

    private handleEmailLogin = ( e: FormEvent<HTMLFormElement> ) =>
    {
        e.preventDefault();
        if ( !validateEmail( this.props.email ) )
        {
            this.props.handleEmailError( INVALID_EMAIL_ADDRESS );
        }
        else
        {
            this.props.onContinueClicked( this.props.email, this.props.password );
        }
    }

    private updateEmail = ( content: string | number ) =>
    {
        this.props.handleEmailUpdate( content as string );
    }

    private updatePassword = ( content: string | number ) =>
    {
        this.props.handlePasswordUpdate( content as string );
    }
}
