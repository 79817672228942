import * as CheckmarkGreen from "../assets/img/checkmark_green.svg";
import * as React from "react";

interface PremiumBenefitItemProps
{
    description: string;
}

export function PremiumBenefitItem( props: PremiumBenefitItemProps )
{
    return <div className="benefitsItem">
        <div><img src={CheckmarkGreen} alt="Green Checkmark"/></div>
        <div className="benefitDescription">{props.description}</div>
    </div>;
}
