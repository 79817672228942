import * as React from "react";
import classNames from "classnames";
import CircularProgress from "react-md/lib/Progress/CircularProgress";
import { LIST_ITEM_SELECTED_CLASS } from "../ListPicker";
import { CrownIcon } from "../svgs/CrownIcon";

export interface DetailListItemProps
{
    title: string;
    description?: string;
    iconSrc: string;
    iconSrcUnselected?: string;
    selected?: boolean;
    iconOverlay?: JSX.Element;
    loading?: boolean;
    outlineContent?: boolean;
    outlineImage?: boolean;
    showNextChevron?: boolean;
    showCrown?: boolean;
}

export interface DetailListItemDispatchProps
{
    onClick: () => void;
}

export function DetailListItem( props: DetailListItemProps & DetailListItemDispatchProps )
{
    const { selected, loading, outlineContent, outlineImage } = props;
    return (
        <div
            className={classNames( "item detailListItem", { [LIST_ITEM_SELECTED_CLASS]: selected, loading, outlineContent } )}
            onClick={props.onClick}>
            <div className="detailIcon">
                <img alt="cover art" src={getIconSrc( props )} className={classNames( { outlineImage } )}/>
                {props.iconOverlay && <div className={classNames( "overlayBackground", { selected } )}/>}
                {props.iconOverlay && <span className={classNames( "overlay", { selected } )}> {props.iconOverlay}</span>}
            </div>
            <div className="detailInfo">
                <span className="detailTitle">{props.title}</span>
                {props.description && <span className="detailDescription">{props.description}</span>}
            </div>
            {props.showCrown && <CrownIcon/>}
            {props.showNextChevron && <div className="detailNextChevron"><i className="material-icons">arrow_forward_ios</i></div>}

            <div className="detailSpinner">
                <CircularProgress className="spinner" id="spinner"/>
            </div>
        </div>
    );
}

function getIconSrc( props: DetailListItemProps & DetailListItemDispatchProps )
{
    if ( props.selected )
    {
        return props.iconSrc;
    }
    return props.iconSrcUnselected || props.iconSrc;
}
