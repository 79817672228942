import { Action } from "redux-actions";
import { CATALOG_VERSION, getDesignSwitchingCatalogCategoriesToDisplay, getSelectedDesignCatalogCategory, ReducerCreator } from "../helpers";
import { catalogActions } from "../actions";
import { dropRight, filter, includes, isEmpty, map, uniq, without } from "lodash";
import { createSelector } from "reselect";
import { CatalogState, Design, StoreState } from "../_types";

const defaultState: CatalogState = {
    version: CATALOG_VERSION,
    designTray: [],
    autoDownloadDesigns: [],
    categories: [],
    freemiumDesigns: [],
    freeDesigns: [],
    newDesigns: [],
    endCards: [],
};

const MAX_DESIGN_TRAY_LENGTH = 10;

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( catalogActions.loadCatalogSuccess, updateDesignData );
reducerCreator.addAction( catalogActions.addDesignToFrontOfTray, addDesignToFrontOfTray );
export default reducerCreator.createReducer();

export function updateDesignData( state: CatalogState, action: Action<NormalizrData> ): CatalogState
{
    const version = action.payload.result;
    const catalog = action.payload.entities.catalogs[version] as CatalogState;
    const designTrayIdsLimitedToMax = addDefaultDesignsIfRoomInDesignTray( catalog, state );
    return {
        version,
        designTray: designTrayIdsLimitedToMax,
        ...catalog,
    };
}

function hasRoomForAddingNewDefaultDesigns( state: CatalogState, newDefaultDesigns: number[] )
{
    return (state.designTray.length < MAX_DESIGN_TRAY_LENGTH) && (newDefaultDesigns.length > 0);
}

function addDefaultDesignsIfRoomInDesignTray( catalog: CatalogState, state: CatalogState )
{
    const newDefaultDesigns = without( catalog.autoDownloadDesigns, ...state.designTray );
    if ( hasRoomForAddingNewDefaultDesigns( state, newDefaultDesigns ) )
    {
        const designIds = uniq( [...newDefaultDesigns, ...state.designTray] );
        return limitToMaxNumberForDesignTray( designIds );
    }
    else
    {
        return [...state.designTray];
    }
}

function limitToMaxNumberForDesignTray( designIds: number[] ): number[]
{
    return dropRight( designIds, Math.max( designIds.length - MAX_DESIGN_TRAY_LENGTH, 0 ) );
}

export function addDesignToFrontOfTray( state: CatalogState, action: Action<number> ): CatalogState
{
    const designId = action.payload;
    const designIds = uniq( [designId, ...state.designTray] );
    const designTrayIdsLimitedToMax = limitToMaxNumberForDesignTray( designIds );
    return {
        ...state,
        designTray: designTrayIdsLimitedToMax,
    };
}

export const getCatalogUpdatedAtCacheBuster = ( state: StoreState ) =>
{
    try
    {
        const dateToUseForCacheBuster = state.catalog.catalogUpdatedAt;
        const updatedAtDate = new Date( dateToUseForCacheBuster );
        const msSinceEpoch = updatedAtDate.getTime();
        return msSinceEpoch.toString() || "";
    }
    catch (e)
    {
        return "";
    }
};

export const getNewDesignIds = ( state: StoreState ) => state.catalog.newDesigns || [];
export const getFreeDesignIds = ( state: StoreState ) => state.catalog.freeDesigns || [];
export const getFreemiumDesignIds = ( state: StoreState ) => state.catalog.freemiumDesigns || [];
export const getDesignTrayIds = ( state: StoreState ) => state.catalog.designTray || [];
export const getDesignLookup = ( state: StoreState ) => state.designs.idToObj || {};

export const numberOfDesignsInLocalStore = ( state: StoreState ) =>
{
    const designLookup = getDesignLookup( state );
    return isEmpty( designLookup ) ? 0 : Object.keys( designLookup ).length;
};

export const getDesignTray = createSelector(
    [getDesignTrayIds, getDesignLookup],
    ( designTrayIds, designLookup ): Design[] =>
    {
        return filter( map( designTrayIds, ( designId ) => designLookup[designId] ) );
    } );

export const getDesignSwitchingDesignsForSelectedCategory = createSelector(
    [getDesignSwitchingSelectedCategoryDesignIdsToDisplay, getDesignLookup],
    ( designTrayIds, designLookup ): Design[] =>
    {
        return filter( map( designTrayIds, ( designId ) => designLookup[designId] ) );
    } );

export function getDesignSwitchingSelectedCategoryDesignIdsToDisplay( storeState: StoreState ): number[]
{
    const categories = getDesignSwitchingCatalogCategoriesToDisplay( storeState );
    const selectedCategory = getSelectedDesignCatalogCategory( storeState, categories );
    if ( !selectedCategory )
    {
        return [];
    }
    return filter( selectedCategory.designs );
}

export const getAllFreeAndFreemiumDesignIds = createSelector(
    [getFreeDesignIds, getFreemiumDesignIds],
    ( freeDesignIds, freemiumDesignIds ): number[] =>
    {
        freeDesignIds = freeDesignIds || [];
        freemiumDesignIds = freemiumDesignIds || [];
        return filter( [...freeDesignIds, ...freemiumDesignIds] );
    } );

export const isDesignIdFreeOrFreemium = ( state: StoreState, designId: number ): boolean =>
{
    return includes( getAllFreeAndFreemiumDesignIds( state ), designId );
};

export const isDesignIdNew = ( state: StoreState, designId: number ): boolean =>
{
    return includes( getNewDesignIds( state ), designId );
};
