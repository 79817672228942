import * as React from "react";
import { ChangeEvent } from "react";
import FontIcon from "react-md/lib/FontIcons/FontIcon";
import { addMinutes, format, getHours, setHours, setMinutes } from "date-fns";

interface TimePickerProps
{
    value: Date;
    onChange( value: Date );
}

interface TimePickerState
{
    hour?: string;
    minute?: string;
}

const MINUTES_PER_HOUR = 60;
const HOURS_PER_HALF_DAY = 12;

export class TimePicker extends React.PureComponent<TimePickerProps, TimePickerState>
{
    constructor( props )
    {
        super( props );
        this.state = {
            hour: null,
            minute: null,
        };
    }

    public render()
    {
        const { value } = this.props;
        const selectValue = isAfternoon( value ) ? "pm" : "am";
        const minute = this.state.minute === null ? format( value, "mm" ) : this.state.minute;
        const hour = this.state.hour === null ? format( value, "hh" ) : this.state.hour;
        return <div className="timePicker">
            <div className="arrowRow">
                <span className="arrowButton" onClick={this.increaseHour}>
                    <FontIcon>keyboard_arrow_up</FontIcon>
                </span>
                <span className="arrowButton" onClick={this.increaseMinute}>
                    <FontIcon>keyboard_arrow_up</FontIcon>
                </span>
            </div>
            <div className="timeAndAmPmContainer">
                <div className="timeInputRow">
                    <input className="timeInput"
                           onKeyDown={this.handleHourKeyDown}
                           onBlur={this.handleHourBlur}
                           onChange={this.handleHourChange}
                           maxLength={2}
                           value={hour}/>
                    <span>:</span>
                    <input className="timeInput"
                           onKeyDown={this.handleMinuteKeyDown}
                           onBlur={this.handleMinuteBlur}
                           onChange={this.handleMinuteChange}
                           maxLength={2}
                           value={minute}/>
                </div>
                <select className="amPmSelect" onChange={this.changeAmPm} value={selectValue}>
                    <option value="am">AM</option>
                    <option value="pm">PM</option>
                </select>
            </div>
            <div className="arrowRow">
                <span className="arrowButton timePicker-hour" onClick={this.decreaseHour}>
                    <FontIcon>keyboard_arrow_down</FontIcon>
                </span>
                <span className="arrowButton timePicker-minute" onClick={this.decreaseMinute}>
                    <FontIcon>keyboard_arrow_down</FontIcon>
                </span>
            </div>
        </div>;
    }

    private increaseHour = () =>
    {
        this.changeMinutes( MINUTES_PER_HOUR );
    }

    private decreaseHour = () =>
    {
        this.changeMinutes( -MINUTES_PER_HOUR );
    }

    private increaseMinute = () =>
    {
        this.changeMinutes( 1 );
    }

    private decreaseMinute = () =>
    {
        this.changeMinutes( -1 );
    }

    private changeMinutes = ( minutes: number ) =>
    {
        this.setState( { hour: null, minute: null } );
        this.props.onChange( addMinutes( this.props.value, minutes ) );
    }

    private handleHourChange = ( event: ChangeEvent<HTMLInputElement> ) =>
    {
        const { value: hour } = event.target;
        this.setState( { hour } );
    }

    private handleMinuteChange = ( event: ChangeEvent<HTMLInputElement> ) =>
    {
        const { value: minute } = event.target;
        this.setState( { minute } );
    }

    private handleHourBlur = ( event: ChangeEvent<HTMLInputElement> ) =>
    {
        let hours = parseInt( event.target.value, 10 );
        if ( !isNaN( hours ) && hours > 0 && hours <= HOURS_PER_HALF_DAY )
        {
            hours %= HOURS_PER_HALF_DAY;
            if ( isAfternoon( this.props.value ) )
            {
                hours += HOURS_PER_HALF_DAY;
            }
            this.props.onChange( setHours( this.props.value, hours ) );
        }
        this.setState( { hour: null, minute: null } );
    }

    private handleMinuteBlur = ( event: ChangeEvent<HTMLInputElement> ) =>
    {
        const minutes = parseInt( event.target.value, 10 );
        if ( !isNaN( minutes ) && minutes >= 0 && minutes < MINUTES_PER_HOUR )
        {
            this.props.onChange( setMinutes( this.props.value, minutes ) );
        }
        this.setState( { hour: null, minute: null } );
    }

    private handleHourKeyDown = ( e: React.KeyboardEvent<HTMLInputElement> ) =>
    {
        this.handleKeyChange( e, MINUTES_PER_HOUR );
    }

    private handleMinuteKeyDown = ( e: React.KeyboardEvent<HTMLInputElement> ) =>
    {
        this.handleKeyChange( e, 1 );
    }

    private handleKeyChange = ( e: React.KeyboardEvent<HTMLInputElement>, minuteMultiplier: number ) =>
    {
        switch ( e.key )
        {
            case "ArrowUp":
                this.changeMinutes( minuteMultiplier );
                break;
            case "ArrowDown":
                this.changeMinutes( -minuteMultiplier );
                break;
            case "Enter":
                (e.currentTarget as HTMLInputElement).blur();
                break;
        }
    }

    private changeAmPm = ( event: ChangeEvent<HTMLSelectElement> ) =>
    {
        const hourChange = event.target.value === "pm" ? HOURS_PER_HALF_DAY : -HOURS_PER_HALF_DAY;
        this.changeMinutes( hourChange * MINUTES_PER_HOUR );
    }
}

const isAfternoon = ( date: Date ) => getHours( date ) >= HOURS_PER_HALF_DAY;
