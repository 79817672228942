import * as React from "react";
import { TiersExplanationOfBenefitsDispatchProps, TiersExplanationOfBenefitsProps } from "./TiersExplanationOfBenefits";
import { TiersExplanationMobileTab } from "./TiersExplanationMobileTab";
import { filter, first } from "lodash";
import { TierTabData } from "../helpers";
import { TiersExplanationTabs } from "./TiersExplanationTabs";

export function TiersExplanationOfBenefitsForMobile( props: TiersExplanationOfBenefitsProps & TiersExplanationOfBenefitsDispatchProps )
{
    const { tierTabs, selectTab, selectTier, selectedTab } = props;
    const selectedTabData = getSelectedTabData( tierTabs, selectedTab );

    return <div className="selectPriceTiersContent mobile">
        <div className="tierTabs">
            <TiersExplanationTabs tierTabs={tierTabs} selectedTab={selectedTab} selectTab={selectTab}/>
        </div>
        <div className="selectPriceTiersDialogContent">
            <TiersExplanationMobileTab
                data={selectedTabData}
                selectTier={selectTier}/>
        </div>
    </div>;
}

function getSelectedTabData( tierTabs: TierTabData[], selectedTab: ProductTier )
{
    return first( filter( tierTabs, ( tab ) => tab.tier === selectedTab ) ) || tierTabs[0];
}
