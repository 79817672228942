import { Dispatch } from "redux";
import { ENV_CONFIG, requests } from "../helpers";
import { fontCatalogActions } from "../actions";
import { StoreState } from "../_types";

export const fontCatalogServices = {
    load,
};

const FONT_URL = ENV_CONFIG.cdnBaseUrl + "/common/fontLanguageMappings.json";

function load()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        return requests.getExternalJson( FONT_URL ).then(
            ( data ) =>
            {
                dispatch( fontCatalogActions.loadSuccess( data ) );
            },
            ( error ) => dispatch( fontCatalogActions.loadFailure( error ) ),
        );
    };
}
