import { createAction } from "redux-actions";
import { ActionCreator } from "../helpers";
import { InspirationsAPI } from "../_types/api";

const actionCreator = new ActionCreator( "INSPIRATIONS" );

const inspirationsActionsTypes = {
    loadData: actionCreator.generateTypes( "LOAD_DATA" ),
    loadedData: {
        // This clears inspiration load metadata so that fresh inspirations will be loaded from the server.
        clearToPageOne: actionCreator.generateType( "LOADED_POSTS", "CLEAR_TO_PAGE_ONE" ),
    },
    clearInspirations: actionCreator.generateType( "INSPIRATIONS", "CLEARED" ),
};

export const inspirationsActions = {
    loadDataRequest: createAction( inspirationsActionsTypes.loadData.request ),
    loadDataSuccess: createAction<InspirationsAPI>( inspirationsActionsTypes.loadData.success ),
    loadDataFailure: createAction<string>( inspirationsActionsTypes.loadData.failure ),
    loadedDataClearToPageOne: createAction( inspirationsActionsTypes.loadedData.clearToPageOne ),
    clearInspirations: createAction( inspirationsActionsTypes.clearInspirations ),
};
