import * as React from "react";
import { DesignZoomData } from "../_types";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

export interface ZoomControlSliderProps
{
    zoom: DesignZoomData;
    onValueChange: ( value: number, id: string ) => void;
    onAfterValueChange: ( value: number, id: string ) => void;
}

export class ZoomControlSlider extends React.PureComponent<ZoomControlSliderProps>
{
    public render()
    {
        return (
            <div className="zoomSliderContainer">
                <span className="plus"> + </span>
                <Slider className="zoomSlider" vertical={true}
                        min={0} max={100} step={1} value={this.props.zoom.value}
                        onChange={this.handleZoomValueChange}
                        onAfterChange={this.handleAfterZoomValueChange}/>
                <span className="minus"> – </span>
            </div>
        );
    }

    private handleZoomValueChange = ( zoomValue ) =>
    {
        this.props.onValueChange( zoomValue, this.props.zoom.id );
    }

    private handleAfterZoomValueChange = ( zoomValue ) =>
    {
        this.props.onAfterValueChange( zoomValue, this.props.zoom.id );
    }
}
