import { store } from "../store";
import { isArray, isEmpty, replace } from "lodash";
import { ENABLE_ERROR_REPORTING, ENV_CONFIG } from "./";
import { getDevMode, getUserAuthToken, getUserClientId, getUserSlug, isUserLoggedIn } from "../ducks/user";
import { stringify } from "query-string";
import * as objectToFormData from "object-to-formdata";
import * as Raven from "raven-js";

type HTTPMethod = "POST" | "GET" | "PATCH" | "PUT" | "DELETE";

export interface ExtendedErrorInfo
{
    redirected?: boolean;
    status?: number;
    statusText?: string;
    type?: string;
    reason?: string;
    reasons?: string[];
}

function get<R>( url: string, queryParams: any = {}, includeCredentials?: boolean, returnExtendedErrorInfo?: boolean ): Promise<R>
{
    return makeJsonRequest( url, queryParams, "GET", {}, includeCredentials, returnExtendedErrorInfo );
}

function post<T, R>( url: string, body?: T, queryParams: any = {} ): Promise<R>
{
    return makeJsonRequest( url, queryParams, "POST", body );
}

function patch<T, R>( url: string, body: T, queryParams: any = {} ): Promise<R>
{
    return makeJsonRequest( url, queryParams, "PATCH", body );
}

function put<T, R>( url: string, body: T, queryParams: any = {} ): Promise<R>
{
    return makeJsonRequest( url, queryParams, "PUT", body );
}

function _delete<T, R>( url: string, body?: T, queryParams: any = {} ): Promise<R>
{
    return makeJsonRequest( url, queryParams, "DELETE", body );
}

function postFile<T extends object, R>( url: string, body: T, queryParams: any = {} ): Promise<R>
{
    const requestBody = objectToFormData( body );
    return makeRequest( url, queryParams, "POST", requestBody );
}

function patchFile<T extends object, R>( url: string, body: T, queryParams: any = {} ): Promise<R>
{
    const requestBody = objectToFormData( body );
    return makeRequest( url, queryParams, "PATCH", requestBody );
}

export function railsStringify( queryParams )
{
    return stringify( queryParams, { arrayFormat: "bracket" } );
}

export function addCommonQueryParams( queryParams )
{
    const storeState = store.getState();
    const params = {
        ...queryParams,
        client_id: getUserClientId( storeState ),
        client_version: APP_VERSION,
        time_zone_offset: new Date().getTimezoneOffset() * -60,
        dev_mode: getDevMode( storeState ),
    };

    if ( isUserLoggedIn( storeState ) )
    {
        const authToken = getUserAuthToken( storeState );
        if ( authToken )
        {
            params.auth_token = authToken;
        }

        const userSlug = getUserSlug( storeState );
        if ( userSlug )
        {
            params.u = userSlug;
        }
    }

    return params;
}

export const requests = {
    get,
    post,
    patch,
    put,
    delete: _delete,
    postFile,
    patchFile,
    getExternalJson,
    postAndAcceptJson,
    postFileAndAcceptJson,
};

export function addCommonAndPostQueryParams( postID, queryParams = {} )
{
    return addCommonQueryParams( {
        ...queryParams,
        post_id: postID,
    } );
}

function makeJsonRequest<T, R>( uri: string, queryParams: any, methodType: HTTPMethod, body: T, includeCredentials?: boolean,
                                returnExtendedErrorInfo?: boolean ): Promise<R>
{
    const headers = {};
    let requestBody;
    if ( !isEmpty( body ) )
    {
        requestBody = JSON.stringify( body );
        headers["Content-Type"] = "application/json";
    }

    return makeRequest<R>( uri + ".json", queryParams, methodType, requestBody, headers, includeCredentials, returnExtendedErrorInfo );
}

function makeRequest<R>( uri: string, queryParams: any, methodType: HTTPMethod, body: string | FormData, headers: any = {},
                         includeCredentials: boolean = false, returnExtendedErrorInfo?: boolean ): Promise<R>
{
    const finalQueryParams = addCommonQueryParams( queryParams );
    const requestOptions: RequestInit = {
        method: methodType,
        headers: addAuthorizationHeader( headers ),
        body,
    };

    if ( includeCredentials )
    {
        requestOptions.credentials = "include";
    }

    return fetch( ENV_CONFIG.baseUrl + uri + "?" + railsStringify( finalQueryParams ), requestOptions )
        .then( ( response ) =>
        {
            return handleResponse( response, returnExtendedErrorInfo );
        } );
}

function handleResponse( response: Response, returnExtendedErrorInfo: boolean = false ): Promise<any>
{
    if ( !response.ok )
    {
        const contentType = response.headers.get( "content-type" );
        if ( contentType && contentType.indexOf( "application/json" ) !== -1 )
        {
            return response.json().then( ( data ) =>
            {
                if (data.error && data.code)
                {
                    return rejectPromise( data, response, returnExtendedErrorInfo);
                }
                if ( data.error )
                {
                    return rejectPromise( data.error, response, returnExtendedErrorInfo );
                }
                if ( data.errors )
                {
                    return rejectPromise( data.errors, response, returnExtendedErrorInfo );
                }
                return rejectPromise( data, response, returnExtendedErrorInfo );
            } );
        }
        else
        {
            return response.text().then( ( text ) =>
            {
                return rejectPromise( text || response.statusText, response, returnExtendedErrorInfo );
            } );
        }
    }

    if ( response.status === 204 )
    {
        return Promise.resolve( undefined );
    }

    return response.json();
}

function rejectPromise( reason, response: Response, returnExtendedErrorInfo: boolean ): Promise<never>
{
    const extendedErrorInfo: ExtendedErrorInfo = {};
    const extra: any = {};
    const tags: { [id: string]: string; } = {};
    const options = { extra, tags };
    if ( isArray( reason ) )
    {
        if ( reason.length > 0 )
        {
            const reasons = reason;
            reason = reason[0];
            options.extra = { reasons, ...options.extra };
            options.tags = { serverReason: reason, ...options.tags };
            extendedErrorInfo.reason = reason;
            extendedErrorInfo.reasons = reasons;
        }
    }
    else
    {
        options.extra = { reason, ...options.extra };
        options.tags = { serverReason: reason, ...options.tags };
        extendedErrorInfo.reason = reason;
    }

    let sentryError = reason;

    if ( response )
    {
        const url = new URL( response.url );
        const search = sanitizeUrlForLogging( url.search );
        options.extra = {
            headers: response.headers,
            redirected: response.redirected,
            status: response.status,
            statusText: response.statusText,
            type: response.type,
            url: {
                protocol: url.protocol,
                hostname: url.hostname,
                port: url.port,
                pathname: url.pathname,
                search,
            },
            ...options.extra,
        };

        extendedErrorInfo.redirected = response.redirected;
        extendedErrorInfo.status = response.status;
        extendedErrorInfo.statusText = response.statusText;
        extendedErrorInfo.type = response.type;

        const entityId = getEntityIdFromURLPath( url.pathname );

        options.tags = {
            serverStatus: response.status.toString(),
            serverStatusText: response.statusText,
            entityId,
            ...options.tags,
        };

        const pathname = replace( url.pathname, entityId, "nnn" );
        sentryError = `${response.statusText} at ${pathname}`;
    }

    if ( ENABLE_ERROR_REPORTING )
    {
        // TODO This does not record the stacktrace of the calling code; consider another approach that will do that.
        Raven.captureMessage( sentryError, options );
    }

    return Promise.reject( returnExtendedErrorInfo ? extendedErrorInfo : reason );
}

function sanitizeUrlForLogging( aUrl )
{
    aUrl = aUrl || "";
    return aUrl.replace( /([?&])auth_token=[^&]*(.*)/, "$1masked_token=***$2" );
}

function getEntityIdFromURLPath( path: string )
{
    if ( path )
    {
        const found = path.match( /\/(\d+)/ );
        if ( found && found.length >= 2 )
        {
            return found[1];
        }
    }
}

function getExternalJson( url: string ): Promise<any>
{
    return fetch( url, {
        method: "GET",
    } ).then( ( response ) =>
    {
        return handleResponse( response, true );
    } );
}

function postAndAcceptJson<T>( url, body: T ): Promise<Response>
{
    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json",
    };

    return fetch( url,
        {
            method: "POST",
            headers: addAuthorizationHeader( headers ),
            body: JSON.stringify( body ),
        } );
}

function postFileAndAcceptJson( url, body: File ): Promise<Response>
{
    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/octet-stream",
    };

    return fetch( url,
        {
            method: "POST",
            headers: addAuthorizationHeader( headers ),
            body,
        } );
}

function addAuthorizationHeader( queryParams )
{
    const storeState = store.getState();
    if ( isUserLoggedIn( storeState ) )
    {
        const authorizationHeaderValue = determineAuthorizationHeaderValue( storeState );
        return {
            ...queryParams,
            Authorization: authorizationHeaderValue,
        };
    }
    else
    {
        return queryParams;
    }
}

function determineAuthorizationHeaderValue( storeState )
{
    const authToken = getUserAuthToken( storeState );
    if ( authToken )
    {
        return `ripl ${authToken}`;
    }
    return "";
}
