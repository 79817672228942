import { connect } from "react-redux";
import { Dispatch } from "redux";
import { store } from "../../../store";
import { getCustomizableCanvas, getDesignElementProperties, getTextPropertiesSubPanelCaptionData } from "../../../ducks/ui";
import { AlignTextOption, StoreState } from "../../../_types";
import { AlignTextChoicePanel, AlignTextChoicePanelDispatchProps, AlignTextChoicePanelProps } from "../AlignTextChoicePanel";
import { DesignElementPropertyType } from "../../../_types/api";
import { ElementPropertyChangedData } from "../../../_types/canvasUpdaterTypes";

const mapStateToProps = ( storeState: StoreState ): AlignTextChoicePanelProps =>
{
    return {
        captionData: getTextPropertiesSubPanelCaptionData( storeState ),
        designElementProperties: getDesignElementProperties( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): AlignTextChoicePanelDispatchProps =>
{
    return {
        tellDesignToAlignTextItem,
    };
};

function tellDesignToAlignTextItem( captionId: string, type: DesignElementPropertyType, alignPropertyId: string, option: AlignTextOption )
{
    const state = store.getState();
    const canvas = getCustomizableCanvas( state );
    if ( canvas )
    {
        const payload: ElementPropertyChangedData = {
            id: captionId,
            type,
            controlId: alignPropertyId,
            value: option,
        };
        canvas.tellDesignToChangeElementProperty( payload );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( AlignTextChoicePanel );
