import { getTodayYYYYMMDD, ReducerCreator } from "../helpers";
import { Action } from "redux-actions";
import { SocialCalendarState, StoreState } from "../_types";
import { socialCalendarActions } from "../actions";
import { keys } from "lodash";

const defaultState: SocialCalendarState = {
    calendar: [],
    posts: [],
};

const reducerCreator = new ReducerCreator(defaultState);
reducerCreator.addAction(socialCalendarActions.socialCalendarLoadSucceeded, handleCalendarLoadSucceeded);

export default reducerCreator.createReducer();

function handleCalendarLoadSucceeded( state: SocialCalendarState, action: Action<SocialCalendarState>): SocialCalendarState
{
    return {
        ...state,
        ...action.payload,
    };
}

export const getSocialCalendarDays = (state: StoreState ) => state.socialCalendar.calendar;
export const getSocialCalendarPosts = (state: StoreState ) => state.socialCalendar.posts;

export const getSocialCalendarSelectedDate = (state: StoreState ) => state.ui.socialCalendarDateSelected;

export const doesSocialCalendarHaveHolidays = (state: StoreState ) =>
{
    return keys(state.socialCalendar.posts).length > 0;
};

export const doesSocialCalendarHaveTodaySlots = (state: StoreState ) =>
{
    const today = getTodayYYYYMMDD();
    return (state.socialCalendar.calendar[today] && keys(state.socialCalendar.calendar[today]).length > 0);
};
