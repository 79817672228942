import { ReducerCreator } from "../helpers";
import { LoginState, StoreState } from "../_types";

const defaultState: LoginState = {};

const reducerCreator = new ReducerCreator( defaultState );
export default reducerCreator.createReducer();

export function hasLoggedInBefore( state: StoreState ): boolean
{
    return !!(state.login && state.login.hasLoggedInBefore);
}
