import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ManagePasswordPage, ManagePasswordPageDispatchProps, ManagePasswordPageProps } from "../ManagePasswordPage";
import { userServices } from "../../../services";
import { parse } from "query-string";
import { get, isEqual } from "lodash";
import { getResetFormData } from "../../../ducks/ui";
import { uiActions } from "../../../actions";
import { StoreState } from "../../../_types";

const mapStateToProps = ( storeState: StoreState ): ManagePasswordPageProps =>
{
    return {
        buttonText: "Reset",
        passwordPromptText: "Reset your password",
        resetFormData: getResetFormData( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): ManagePasswordPageDispatchProps =>
{
    return {
        changePassword: ( password: string, confirmPassword: string ) =>
        {
            if ( !isEqual( password, confirmPassword ) )
            {
                return dispatch( uiActions.setResetFormErrors( { password_confirmation: "Passwords do not match" } ) );
            }

            const resetPasswordToken = get( parse( window.location.search ), "token" );
            dispatch( userServices.resetPassword( password, confirmPassword, resetPasswordToken ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( ManagePasswordPage );
