import * as React from "react";
import { FileUploadButton } from "../FileUploadButton";
import { ALLOWED_FONT_EXTENSIONS } from "../../helpers";
import { FontUploadProps } from "./containers/BrandSettingsFontPickerControl.container";
import classNames from "classnames";

export interface FontFileUploadButtonProps extends FontUploadProps
{
    label?: React.ReactNode;
    icon?: React.ReactElement<any>;
    iconBefore?: boolean;
    onFileUploaded?: ( file: File, result: string ) => void;
    onUpdatedFont?: () => void;
    additionalClassNames?: string;
}

export interface FontFileUploadButtonDispatchProps
{
    onUploadCustomFont( file: File, ownProps: FontFileUploadButtonProps );
}

export class FontFileUploadButton extends React.PureComponent<FontFileUploadButtonProps & FontFileUploadButtonDispatchProps>
{
    public render()
    {
        const { fontField, ...remainingProps } = this.props;
        return (
            <div className={classNames( "selectFont", this.props.additionalClassNames )}>
                <FileUploadButton
                    id={this.props.fontField + "-upload"}
                    flat
                    multiple={false}
                    className="fontUploadBtn"
                    accept={ALLOWED_FONT_EXTENSIONS.join( "," )}
                    {...remainingProps}
                    onFileUploaded={this.handleFontUploaded}
                />
            </div>
        );
    }

    private handleFontUploaded = ( file: File ) =>
    {
        this.props.onUploadCustomFont( file, this.props );
    }
}
