import { BrandLogo, StoreState } from "../../_types";
import { ManageLogosDialog, ManageLogosDialogDispatchProps, ManageLogosDialogProps } from "../ManageLogosDialog";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { getEditedBusinessInfo, getSelectedManageBusinessLogos } from "../../ducks/ui";
import { modalActions, uiActions } from "../../actions";

const mapStateToProps = ( storeState: StoreState ): ManageLogosDialogProps =>
{
    return {
        logos: getEditedBusinessInfo( storeState ).logos,
        selectedLogos: getSelectedManageBusinessLogos( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): ManageLogosDialogDispatchProps =>
{
    return {
        onSelectionChange: ( selectedBrandLogos: BrandLogo[] ) =>
        {
            dispatch( uiActions.manageBusinessLogosSelectionSet( selectedBrandLogos ) );
            dispatch( modalActions.allForceUpdate() );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( ManageLogosDialog );
