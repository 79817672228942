import * as React from "react";
import DropdownMenu from "react-md/lib/Menus/DropdownMenu";
import { CURRENCY_SELECTOR_CODES } from "../helpers";
import { CurrencySelectorItem } from "./CurrencySelectorItem";
import { map } from "lodash";
import FontIcon from "react-md/lib/FontIcons/FontIcon";

export interface CurrencySelectorProps
{
    selectedCurrencyCode: CurrencyCode;
}

export interface CurrencySelectorDispatchProps
{
    currencySelected( currencyCode: CurrencyCode );
    logCurrencyInitialized( currencyCode: CurrencyCode );
}

interface CurrencySelectorState
{
    dropdownVisible: boolean;
}

export class CurrencySelector extends React.PureComponent<CurrencySelectorProps & CurrencySelectorDispatchProps, CurrencySelectorState>
{
    private elementId = "currencySelectorMenu";

    constructor( props )
    {
        super( props );
        this.state = {
            dropdownVisible: false,
        };
    }

    public componentDidMount()
    {
        this.props.logCurrencyInitialized( this.props.selectedCurrencyCode );
        document.addEventListener( "mousedown", this.handleClickOutside );
    }

    public componentWillUnmount()
    {
        document.removeEventListener( "mousedown", this.handleClickOutside );
    }

    public render()
    {
        return (
            // @ts-ignore
            <DropdownMenu
                id={this.elementId}
                ref="currencyDropDownRef"
                menuItems={this.getMenuItems()}
                toggleQuery="span"
                visible={this.state.dropdownVisible}
                defaultVisible={false}
                onVisibilityChange={this.handleVisibilityChange}
                anchor={{
                    x: DropdownMenu.HorizontalAnchors.INNER_LEFT,
                    y: DropdownMenu.VerticalAnchors.BOTTOM,
                }}
                position={DropdownMenu.Positions.BELOW}
                className="currencySelector"
                listClassName="currencySelectorListContainer">
                <span onClick={this.toggleDropdownVisibility} className="currencySelectorToggler">
                    <CurrencySelectorItem currencyCode={this.props.selectedCurrencyCode}/>
                    <FontIcon className="downArrow">expand_more</FontIcon>
                </span>
            </DropdownMenu>
        );
    }

    private handleVisibilityChange = () =>
    {
        // having this no-op method prevents a console warning
        return;
    }

    private getMenuItems = () =>
    {
        return map( CURRENCY_SELECTOR_CODES, ( code: CurrencyCode ) => this.createCurrencySelectorItem( code ) );
    }

    private createCurrencySelectorItem = ( currencyCode: CurrencyCode ) =>
    {
        return (
            <CurrencySelectorItem key={currencyCode} currencyCode={currencyCode} currencySelected={this.handleCurrencyItemSelected}/>
        );
    }

    private handleCurrencyItemSelected = ( selectedCurrency: CurrencyCode ) =>
    {
        this.toggleDropdownVisibility();
        this.props.currencySelected( selectedCurrency );
    }

    private toggleDropdownVisibility = () =>
    {
        this.setState(
            {
                dropdownVisible: !this.state.dropdownVisible,
            },
        );
    }

    private handleClickOutside = ( event ) =>
    {
        const { currencyDropDownRef } = this.refs;

        const htmlElement = currencyDropDownRef as HTMLElement;
        if ( htmlElement && !event.target.closest( "#" + this.elementId ) )
        {
            this.setState( { dropdownVisible: false } );
        }
    }
}
