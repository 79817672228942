import { createAction } from "redux-actions";
import { ActionCreator } from "../helpers";

const actionCreator = new ActionCreator( "USER_BUSINESS" );

export interface UserBusinessActionData
{
    businessId: number;
    userId: number;
    shouldShowDefaultSearchTerm?: boolean;
}

const userBusinessActionsTypes = {
    business: {
        switched: actionCreator.generateType( "BUSINESS", "SWITCHED" ),
    },
    businessType: {
        changed: actionCreator.generateType( "BUSINESS_TYPE", "CHANGED" ),
    },
    businessUpdate: {
        request: actionCreator.generateType( "BUSINESS_UPDATE", "REQUEST" ),
        success: actionCreator.generateType( "BUSINESS_UPDATE", "SUCCESS" ),
        failure: actionCreator.generateType( "BUSINESS_UPDATE", "FAILURE" ),
    },
    update: actionCreator.generateTypes( "UPDATE" ),
    create: actionCreator.generateTypes( "CREATE" ),
    delete: actionCreator.generateTypes( "DELETE" ),
    inviteTeamMember: actionCreator.generateTypes( "INVITE_TEAM_MEMBER" ),
    acceptTeamMemberInvite: actionCreator.generateTypes( "ACCEPT_TEAM_MEMBER_INVITE" ),
    removeTeamMember: actionCreator.generateTypes( "REMOVE_TEAM_MEMBER" ),
    cancelTeamMemberInvite: actionCreator.generateTypes( "CANCEL_TEAM_MEMBER_INVITE" ),
};

export const userBusinessActions = {
    createSuccess: createAction<NormalizrData>( userBusinessActionsTypes.create.success ),
    createFailure: createAction<string>( userBusinessActionsTypes.create.failure ),
    businessTypeChanged: createAction( userBusinessActionsTypes.businessType.changed ),
    businessSwitched: createAction<UserBusinessActionData>( userBusinessActionsTypes.business.switched ),
    updateSuccess: createAction<NormalizrData>( userBusinessActionsTypes.update.success ),
    updateFailure: createAction<string>( userBusinessActionsTypes.update.failure ),
    updateBusinessRequest: createAction( userBusinessActionsTypes.businessUpdate.request ),
    updateBusinessSuccess: createAction( userBusinessActionsTypes.businessUpdate.success ),
    updateBusinessFailure: createAction( userBusinessActionsTypes.businessUpdate.failure ),
    deleteSuccess: createAction<UserBusinessActionData>( userBusinessActionsTypes.delete.success ),
    deleteFailure: createAction<string>( userBusinessActionsTypes.delete.failure ),
    inviteTeamMemberSuccess: createAction<NormalizrData>( userBusinessActionsTypes.inviteTeamMember.success ),
    inviteTeamMemberFailure: createAction<string>( userBusinessActionsTypes.inviteTeamMember.failure ),
    acceptTeamMemberInviteSuccess: createAction<NormalizrData>( userBusinessActionsTypes.acceptTeamMemberInvite.success ),
    acceptTeamMemberInviteFailure: createAction<string>( userBusinessActionsTypes.acceptTeamMemberInvite.failure ),
    removeTeamMemberSuccess: createAction<NormalizrData>( userBusinessActionsTypes.removeTeamMember.success ),
    removeTeamMemberFailure: createAction<string>( userBusinessActionsTypes.removeTeamMember.failure ),
    cancelTeamMemberInviteSuccess: createAction<NormalizrData>( userBusinessActionsTypes.cancelTeamMemberInvite.success ),
    cancelTeamMemberInviteFailure: createAction<string>( userBusinessActionsTypes.cancelTeamMemberInvite.failure ),
};
