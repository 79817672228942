import { connect } from "react-redux";
import { Dispatch } from "redux";
import { getActiveDesignMediaObject, getEditorControl, getEditorType } from "../../ducks/ui";
import { ControlBlock, ControlBlockDispatchProps, ControlBlockProps } from "../ControlBlock";
import { DesignControlIconImages, StoreState, UIEditorType } from "../../_types";
import { IControlConfig } from "../../_types/api";
import { isStaticOutputMode } from "../../ducks/mixModel";
import { mixModelServices } from "../../services";
import { store } from "../../store";
import { CustomizableCanvas } from "../../helpers/customizableCanvas";
import { EDITOR_TYPES } from "../../helpers";

interface ControlBlockContainerProps
{
    editorType: UIEditorType;
    designControlIconImages?: DesignControlIconImages;
    title?: string;
    control?: IControlConfig;
    dynamicImage?: string;
    disabled?: boolean;
    showAnimatedOnlyTipWhenDisabled?: boolean;
}

const mapStateToProps = ( storeState: StoreState, ownProps: ControlBlockContainerProps ): ControlBlockProps =>
{
    const control = getEditorControl( storeState );
    const editorType = getEditorType( storeState );
    const selected = editorType === ownProps.editorType && control === ownProps.control;
    return {
        ...ownProps,
        selected,
        isStaticOutputMode: isStaticOutputMode( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): ControlBlockDispatchProps =>
{
    return {
        onControlClicked: ( editorType: UIEditorType, controlId?: string ) =>
        {
            mixModelServices.changeDesignControl( editorType, controlId );
            if ( getActiveDesignMediaObject( store.getState() ) && editorType === EDITOR_TYPES.MEDIA_CONTROL )
            {
                CustomizableCanvas.requestMediaObjectsForActivePage();
            }
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( ControlBlock );
