import * as React from "react";
import { isNil, map } from "lodash";
import FontAutocompleteContainer from "./containers/FontAutocomplete.container";
import { FontData } from "../../_types";
import List from "react-md/lib/Lists/List";
import { FontPickerItem } from "../settingsPages/FontPickerItem";
import { getDesignFontName } from "../../helpers";
import ExpansionList from "react-md/lib/ExpansionPanels/ExpansionList";

export interface MoreFontsDialogProps
{
    fonts: FontData[];
    currentFont?: FontData;
}

export interface MoreFontsDialogDispatchProps
{
    updateFontSelection: ( font: FontData ) => void;
    clearBusinessInfo?();
}

export class MoreFontsDialog extends React.PureComponent<MoreFontsDialogProps & MoreFontsDialogDispatchProps>
{
    public componentWillUnmount()
    {
        // used to clean up any uploaded font data
        if ( !isNil( this.props.clearBusinessInfo ) )
        {
            this.props.clearBusinessInfo();
        }
    }

    public render()
    {
        const { fonts, currentFont, updateFontSelection } = this.props;
        return (
            <div className="moreFontsDialogContents">
                <FontAutocompleteContainer/>
                <h2>Or Browse Below:</h2>
                <ExpansionList className="categoryList">
                    <List className="fontList">
                        {map( fonts, ( font: FontData ) =>
                        {
                            return (
                                <FontPickerItem key={getDesignFontName( font )}
                                                font={font}
                                                currentFont={currentFont}
                                                updateFontSelection={updateFontSelection}/>
                            );
                        } )}
                    </List>
                </ExpansionList>
            </div>
        );
    }
}
