import * as React from "react";
import PostsPanel from "./PostsPanel";
import { Post } from "../../_types";
import CalendarContainer from "../containers/Calendar.container";
import { ErrorBoundary } from "../ErrorBoundary";
import CreateBlankPostButtonSmall from "../containers/CreateBlankPostButtonSmall.container";
import Button from "react-md/lib/Buttons/Button";

export interface ScheduleTabProps
{
    postData: Post[];
    showUnscheduledPostsViewButton: boolean;
    showUnscheduledPostsInScheduleTab: boolean;
}

export interface ScheduleTabDispatchProps
{
    loadData();
    loadUnscheduledPosts();
    updateShowUnscheduledPostsInScheduleTab( value: boolean );
}

export class ScheduleTab extends React.PureComponent<ScheduleTabProps & ScheduleTabDispatchProps>
{
    public componentDidMount()
    {
        this.props.loadData();
        this.props.loadUnscheduledPosts();
        this.props.updateShowUnscheduledPostsInScheduleTab( false );
    }

    public render()
    {
        const { showUnscheduledPostsInScheduleTab, postData } = this.props;

        return (
            <div className="subNavContent scheduleTabContent">
                <div className="subNavInnerWrap twoPanel">
                    <ErrorBoundary errorMessage="There was a problem creating the Schedule tab. If this continues, click Restart below.">
                        <div className="scheduleTabCalendar">
                            {
                                !showUnscheduledPostsInScheduleTab && this.createCalendarView()
                            }
                            {
                                showUnscheduledPostsInScheduleTab && this.createUnscheduledView()
                            }
                        </div>

                        <div className="rightSectionWrap">
                            {
                                postData.length > 0 ? <PostsPanel postData={postData} isCalendarView={true}/>
                                                    : this.createNoPostPanel( showUnscheduledPostsInScheduleTab )
                            }
                        </div>
                    </ErrorBoundary>
                </div>
            </div>
        );
    }

    private createCalendarView = () =>
    {
        const { showUnscheduledPostsViewButton } = this.props;
        return (
            <>
                <h2 className="subNavSectionH2">Scheduled posts</h2>
                <CalendarContainer/>
                <div className="buttonSection">
                    {
                        showUnscheduledPostsViewButton &&
                        <Button primary
                                flat
                                className="showUnscheduledPostsButtonSmall outline "
                                onClick={this.toggleShowUnscheduledPosts}>
                            Unscheduled posts view
                        </Button>
                    }
                    <CreateBlankPostButtonSmall/>
                </div>
            </>
        );
    }

    private createUnscheduledView = () =>
    {
        return (
            <>
                <h2 className="subNavSectionH2">Unscheduled posts</h2>
                <div className="buttonSection">
                    <Button primary
                            flat
                            className="showUnscheduledPostsButtonSmall outline "
                            onClick={this.toggleShowUnscheduledPosts}>
                        Return to calendar view
                    </Button>
                </div>
            </>
        );
    }

    private createNoPostPanel = ( showUnscheduledPostsInScheduleTab: boolean ) =>
    {
        const noPostsText = showUnscheduledPostsInScheduleTab ? "You have no unscheduled posts"
                                                              : "Click on date to preview a scheduled or shared post";
        return (
            <div className="noPostsPanel">
                <span>{noPostsText}</span>
            </div>
        );
    }

    private toggleShowUnscheduledPosts = () =>
    {
        const { showUnscheduledPostsInScheduleTab } = this.props;
        this.props.updateShowUnscheduledPostsInScheduleTab( !showUnscheduledPostsInScheduleTab );
        if ( !showUnscheduledPostsInScheduleTab )
        {
            this.props.loadUnscheduledPosts();
        }
    }
}
