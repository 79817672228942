import * as React from "react";
import { SlideSummaryItemSortable } from "./SlideSummaryItem";
import { SingleSlideSummary } from "../_types";
import { map, size } from "lodash";
import { AddSlideButton } from "./AddSlideButton";
import { SortableContainer, SortableContainerProps } from "react-sortable-hoc";
import { SlideSummaryPanelDispatchProps, SlideSummaryPanelProps } from "./SlideSummaryPanel";

export interface SlideSummaryItemGridProps extends SortableContainerProps
{
    slideSummary: SingleSlideSummary[];
    selectSlide: ( slideId: string ) => void;
}

export class SlideSummaryItemGrid extends React.PureComponent<SlideSummaryItemGridProps>
{
    private scrollWrapperRef: React.RefObject<HTMLDivElement>;

    constructor( props )
    {
        super( props );
        this.scrollWrapperRef = React.createRef();
    }

    public componentDidUpdate( prevProps: Readonly<SlideSummaryPanelProps & SlideSummaryPanelDispatchProps> )
    {
        const wasSlideAdded = prevProps.slideSummary && (size( prevProps.slideSummary ) + 1 === size( this.props.slideSummary ));
        if ( wasSlideAdded && this.scrollWrapperRef.current )
        {
            const exceedinglyFarScrollLocation = document.body.scrollWidth;
            this.scrollWrapperRef.current.scrollTo( { left: exceedinglyFarScrollLocation } );
        }
    }

    public render()
    {
        return (
            <div className="slideSummaryItemGrid" ref={this.scrollWrapperRef}>
                {map( this.props.slideSummary, ( slideData, index ) =>
                    <SlideSummaryItemSortable key={index}
                                              index={index}
                                              slideIndex={index}
                                              hasMedia={slideData.mediaIds.length > 0}
                                              selected={slideData.selected}
                                              selectSlide={this.props.selectSlide}
                                              slideSummary={this.props.slideSummary}
                                              slideId={slideData.id}/> )}
                {this.props.slideSummary && <AddSlideButton/>}
            </div>
        );
    }
}

export const SlideSummaryItemGridSortable = SortableContainer<SlideSummaryItemGridProps>( SlideSummaryItemGrid );
