import * as React from "react";
import Button from "react-md/lib/Buttons/Button";
import { PlusIcon } from "./svgs/PlusIcon";
import * as VisibilitySensor from "react-visibility-sensor";
import classNames from "classnames";

export interface CreateYourOwnBannerProps
{
    text: string;
    buttonText: string;
    twoBanners: boolean;
}

export interface CreateYourOwnBannerDispatchProps
{
    handleClick();
    handleBannerVisibilityChange( isVisible: boolean );
}

export class CreateYourOwnBanner extends React.PureComponent<CreateYourOwnBannerDispatchProps & CreateYourOwnBannerProps>
{
    public render()
    {
        const { text, buttonText } = this.props;
        return (
            <div className={classNames( "createYourOwnBanner", { twoBanners: this.props.twoBanners } )}>
                <div className="bannerFrame">
                    <div className="bannerText">{text}</div>
                    <VisibilitySensor
                        onChange={this.props.handleBannerVisibilityChange}
                        offset={{ top: 30 }}>
                        <Button
                            flat
                            className="createYourOwnBannerButton"
                            onClick={this.props.handleClick}
                            iconEl={<PlusIcon/>}>
                            {buttonText}
                        </Button>
                    </VisibilitySensor>
                </div>
            </div>
        );
    }
}
