import * as React from "react";
import { RIPL_TIER_PREMIUM, TierFeatureList, TierTabData } from "../helpers";
import Button from "react-md/lib/Buttons/Button";
import { map } from "lodash";
import { ExplanationFeatureItem, ExplanationFeatureItemFromKey } from "./ExplanationFeatureItem";

export interface TiersExplanationTabProps
{
    data: TierTabData;
}

export interface TiersExplanationTabDispatchProps
{
    selectTier( tier: ProductTier ): void;
}

export function TiersExplanationMobileTab( props: TiersExplanationTabProps & TiersExplanationTabDispatchProps )
{
    const { data, selectTier } = props;
    const { commissionRateFormatted, priceFormatted, tierName, tier } = data;
    const isPremium = tier === RIPL_TIER_PREMIUM;
    const supportedFeaturesList = buildSupportedFeaturesList( data.tier, data.features );

    return (
        <div key={tier} className="tierTab">
            <div className="tierSubtitle">from {priceFormatted}</div>

            <div className="featureList">
                {supportedFeaturesList}
                {buildFeatureItemForCommission( isPremium, commissionRateFormatted )}
            </div>

            <Button flat primary swapTheming onClick={() => selectTier( tier )}>
                Select {tierName}
                <span className="textThatSaysPlan"> Plan</span>
            </Button>
        </div>
    );
}

function buildSupportedFeaturesList( tier: ProductTier, features: TierFeatureList<boolean> )
{
    return map( features, ( isSupported, featureKey ) =>
        <ExplanationFeatureItemFromKey
            key={featureKey}
            tier={tier}
            isSupported={isSupported}
            featureKey={featureKey}
            showDescription={true}
        />,
    );
}

function buildFeatureItemForCommission( isPremium: boolean, commissionRateFormatted: string )
{
    const description = `${commissionRateFormatted} commission fee on ads`;
    return <ExplanationFeatureItem isSupported={isPremium} options={{ description, isBold: isPremium }}/>;
}
