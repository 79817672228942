import { ControlsConfig, IControlConfig, ModelLinkedPhotoAssetControlConfig } from "../_types/api";
import { concat, filter, forEach, isEqual, keys } from "lodash";
import { EDITOR_TYPES } from "./";
import { AssetChoice, DesignState, MixModelState } from "../_types";
import { getDefaultValue } from "../ducks/mixModel";

export function getModelLinkedPhotoAssetControls( controls: IControlConfig[] )
{
    return filter( controls,
        ( aControl: IControlConfig ) => aControl.type === EDITOR_TYPES.MODEL_LINKED_PHOTO_CONTROL ) as ModelLinkedPhotoAssetControlConfig[];
}

export function controlDataWithUserChoicesUpdated( state: MixModelState, newFileList, designs: DesignState )
{
    const newData = {};
    if ( newFileList.length > 0 )
    {
        return newData;
    }

    const designsWithUserChoices = designIdsWithUserChoicesInModelLinkedPhotoAssetControls( state, designs );

    for ( const designId of designsWithUserChoices )
    {
        const templateControlData = state.controlDataByDesignId[designId];
        const controlsConfigForDesign = designs.controlsConfigsByDesignId[designId];

        let photoControls = [];
        forEach( controlsConfigForDesign, ( currentControlsConfig ) =>
        {
            photoControls = concat( photoControls, getModelLinkedPhotoAssetControls( currentControlsConfig.controls ) );
        } );
        const newControlData = { ...templateControlData };
        forEach( photoControls, ( control ) =>
        {
            if ( isUserChoiceSelectedInDesign( control, templateControlData ) )
            {
                const defaultValue = getDefaultValue( control, null ) as AssetChoice;
                newControlData[control.id] = defaultValue.jsonBlob;
            }
        } );
        newData[designId] = newControlData;
    }

    return newData;
}

export function designIdsWithUserChoicesInModelLinkedPhotoAssetControls( state: MixModelState, designs: DesignState )
{
    const allDesignIds = keys( state.controlDataByDesignId );
    const designIdsWithControls = filter( allDesignIds, ( designId: number ) =>
    {
        const controlsConfigForDesign: ControlsConfig[] = designs.controlsConfigsByDesignId[designId];
        const controlsWithModelLinkedPhotoAssetControls = filter( controlsConfigForDesign,
            ( currentControlsConfig ) => getModelLinkedPhotoAssetControls( currentControlsConfig.controls ).length > 0 );
        return controlsWithModelLinkedPhotoAssetControls.length > 0;
    } );
    return designIdsWithControls;
}

export function isUserChoiceSelected( modelLinkedPhotoControl: ModelLinkedPhotoAssetControlConfig, currentData )
{
    const controlChoices = modelLinkedPhotoControl.choices;
    let foundMatch = false;
    for ( const choice of controlChoices )
    {
        if ( isEqual( choice.jsonBlob, currentData ) )
        {
            foundMatch = true;
        }
    }
    return !foundMatch;
}

export function isUserChoiceSelectedInDesign( modelLinkedPhotoControl: ModelLinkedPhotoAssetControlConfig, templateControlData )
{
    const currentData = templateControlData[modelLinkedPhotoControl.id];
    return isUserChoiceSelected( modelLinkedPhotoControl, currentData );
}

export const hasMediaUrlsInList = ( mixModelState: MixModelState ): boolean =>
{
    return mixModelState.commonInputData.originalImageList.length > 0;
};
