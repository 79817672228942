import { DesignMediaObject } from "../_types";
import { store } from "../store";
import { getIndexInMediaList, isMediaUploading } from "../ducks/mixModel";
import { isStockMediaUploading } from "../ducks/ui";
import { uiActions } from "../actions";
import { modalServices } from "../services";
import { PostInputMediaFile } from "../_types/api";
import { urlUtils } from "./urlUtils";

const dispatchAugmentedMediaObject = ( mediaObject: DesignMediaObject, shouldOpenReplaceMediaPicker?: boolean ) =>
{
    const storeState = store.getState();
    if ( !isMediaUploading( storeState ) && !isStockMediaUploading( storeState ) )
    {
        const activeMediaObject = augmentMediaObject( mediaObject );
        store.dispatch( uiActions.designMediaObjectsResponseReceived( activeMediaObject ) );
        if ( activeMediaObject && shouldOpenReplaceMediaPicker )
        {
            store.dispatch( modalServices.openReplaceMediaPickerDialog() );
        }
        else if ( !activeMediaObject )
        {
            store.dispatch( uiActions.replaceMediaModeEnded() );
        }
    }
};

const augmentMediaObject = ( mediaObject: DesignMediaObject ) =>
{
    if ( mediaObject )
    {
        return {
            ...mediaObject,
            index: getIndexInMediaList( store.getState(), mediaObject.id ),
        };
    }

    return undefined;
};

const setPostInputMediaFileAsActiveDesignMediaObject = ( postInputMediaFile: PostInputMediaFile ) =>
{
    if ( postInputMediaFile )
    {
        const url = postInputMediaFile.url;
        const filenameFromUrlString = urlUtils.getFilenameFromUrlString( url );
        const designMediaObject: DesignMediaObject = {
            id: filenameFromUrlString,
            url,
            index: getIndexInMediaList( store.getState(), filenameFromUrlString ),
        };
        dispatchAugmentedMediaObject( designMediaObject );
    }
};

export const designMediaObjectHelper = {
    dispatchAugmentedMediaObject,
    setPostInputMediaFileAsActiveDesignMediaObject,
};
