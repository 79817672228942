import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
    EpidemicAudioTracksListWithInfiniteScroll,
    EpidemicAudioTracksListWithInfiniteScrollDispatchProps,
    EpidemicAudioTracksListWithInfiniteScrollProps,
} from "../EpidemicAudioTracksListWithInfiniteScroll";
import { StoreState } from "../../../../_types";
import { epidemicSoundCatalogServices } from "../../../../services/epidemicSoundCatalog.services";
import { EpidemicAudioTracksListDispatchProps, EpidemicAudioTracksListProps } from "../EpidemicAudioTracksList";
import { getActiveCollectionDetails, getActiveCollectionId, getNextTracksPageUrl } from "../../../../ducks/epidemicSoundCollections";
import { isLoadingEpidemicSoundUserCollectionDetailsInProgress } from "../../../../ducks/ui";
import { store } from "../../../../store";
import { modalServices } from "../../../../services";

const mapStateToProps = ( storeState: StoreState, ownProps: EpidemicAudioTracksListProps & EpidemicAudioTracksListDispatchProps ):
    EpidemicAudioTracksListWithInfiniteScrollProps =>
{
    return {
        ...ownProps,
        isLoadingEpidemicSoundUserCollectionDetailsInProgress: isLoadingEpidemicSoundUserCollectionDetailsInProgress( storeState ),
        activeCollectionId: getActiveCollectionId( storeState ),
        nextUrl: getNextTracksPageUrl( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): EpidemicAudioTracksListWithInfiniteScrollDispatchProps =>
{
    return {
        updateLightDialogTitle()
        {
            const storeState = store.getState();
            const activeCollectionDetails = getActiveCollectionDetails( storeState );
            dispatch(modalServices.updateEpidemicLightboxTitle( activeCollectionDetails.name ));
        },
        loadMore( collectionId: string, nextUrl: string ): void
        {
            if ( !!collectionId && !!nextUrl )
            {
                dispatch( epidemicSoundCatalogServices.loadEpidemicCollectionDetails( collectionId, nextUrl ) );
            }
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( EpidemicAudioTracksListWithInfiniteScroll );
