import { StoreState, StripeSubscriptionInfoData } from "../../../_types";
import { getPageId, getSurveyQuestions } from "../../../ducks/survey";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import CancelSurvey, { CancelSurveyDispatchProps, CancelSurveyProps, EventTrackerSurveyReportingData } from "../CancelSurvey";
import { modalServices, subscriptionServices, surveyServices } from "../../../services";
import { SurveyAPIResponse } from "../../../_types/api/survey";
import { pricingActions } from "../../../actions";
import { getCouponCode } from "../../../ducks/pricing";
import { eventTracker, LightboxDialogIdentifierForKey } from "../../../helpers";
import { isCurrentProductTierLegacy } from "../../../ducks/user";

interface CancelSurveyContainerProps
{
    subscriptionInfo: StripeSubscriptionInfoData;
    handleCancelSurveyBackTapped();
}

const mapStateToProps = ( storeState: StoreState, ownProps: CancelSurveyContainerProps ): CancelSurveyProps =>
{
    const { subscriptionInfo } = ownProps;
    return {
        subscriptionInfo,
        page_id: getPageId( storeState ),
        questions: getSurveyQuestions( storeState ),
        isEligibleForWinBackOffer: !!getCouponCode( storeState ),
        isLegacyTier: isCurrentProductTierLegacy( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: CancelSurveyContainerProps ): CancelSurveyDispatchProps =>
{
    const { handleCancelSurveyBackTapped } = ownProps;
    return {
        handleCancelSurveyBackTapped,
        loadSurvey: () =>
        {
            dispatch( surveyServices.loadCancelSurvey() );
        },
        loadCancelOffer: () =>
        {
            dispatch( pricingActions.updateCouponCode( null ) );
            dispatch( subscriptionServices.getCancelWinBackOffer() );
        },
        clearCoupon: () =>
        {
            dispatch( pricingActions.updateCouponCode( null ) );
        },
        cancelStripeSubscription: ( stripeCustomerId: string, surveyResponse: SurveyAPIResponse,
                                    eventTrackerSurveyReportingDataArray: EventTrackerSurveyReportingData[] ) =>
        {
            dispatch( modalServices.openLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.CANCEL_STRIPE_SUBSCRIPTION,
                title: "Cancelling today means",
                width: 476,
                content: "You will lose access to ready-to-go templates, stock media, one-tap sharing, and performance tracking. "
                         + "You will still have access to Ripl until your subscription ends.",
                onSuccess: () =>
                {
                    eventTracker.logCancelSubscriptionSurveyMultipleResponses( eventTrackerSurveyReportingDataArray );
                    eventTracker.logCancelSubscriptionConfirmed();

                    dispatch( subscriptionServices.cancelStripeSubscription( stripeCustomerId ) );

                    if ( surveyResponse )
                    {
                        dispatch( surveyServices.sendSurveyResponse( surveyResponse ) );
                    }
                },
                confirmLabel: "Cancel now",
                hideCancel: true,
                showCancelX: true,
            } ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( CancelSurvey );
