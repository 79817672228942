import * as React from "react";
import FacebookCreateAccountButton from "./containers/FacebookCreateAccountButton.container";
import { apptimizeVariables, isEUTimezone, PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from "../helpers";
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import { CreateAccountEmailSubscribeCheckbox } from "./CreateAccountEmailSubscribeCheckbox";
import * as RiplLogo from "../assets/img/ripl_logo_white.png";
import * as RiplMark from "../assets/img/ripl_logo_symbol_mark.png";
import * as MailIcon from "../assets/img/mail_icon.png";
import BusinessNameEmailPasswordInputPanelContainer from "./containers/BusinessNameEmailPasswordInputPanel.container";
import { GoogleOneTapLoginButton } from "./GoogleOneTapLoginButton";
import { CredentialResponse } from "@react-oauth/google";

export interface GradientCreateAccountFormOwnProps
{
    showLogin: () => void;
    showBusinessTypeSelection: () => void;
}

export interface GradientCreateAccountFormProps extends GradientCreateAccountFormOwnProps
{
    shouldShowThirdPartyLoginError: boolean;
    thirdPartyLoginErrorMessage: string;
    isAcceptTeamInviteFlow: boolean;
}

export interface GradientCreateAccountFormDispatchProps
{
    onCreateEmailAccountAttempt: ( emailAddress: string, password: string, sendEmailFlag: boolean ) => void;
    handleGoogleCreateAccountResponse( tokenResponse: CredentialResponse, sendEmailFlag: boolean );
}

export interface GradientCreateAccountFormState
{
    sendEmail: boolean;
    shouldDisplayEmailForm: boolean;
}

export default class GradientCreateAccountForm extends React.PureComponent<GradientCreateAccountFormProps &
    GradientCreateAccountFormDispatchProps, GradientCreateAccountFormState>
{
    constructor( props )
    {
        super( props );
        const sendEmail = this.getDefaultSendEmailState();
        this.state = {
            sendEmail,
            shouldDisplayEmailForm: this.props.isAcceptTeamInviteFlow,
        };
    }

    public render()
    {
        return (
            <Grid className="gradientLoginForm newCreateAccountForm" noSpacing={true}>
                <Cell size={5} tabletHidden={true} phoneHidden={true} className="gradientArea">
                    <div className="clicksAwayBackground">
                        <img src={RiplLogo}/>
                        <div className="clicksAwayText">
                            <>{this.getCTAPanelText()}</>
                        </div>
                    </div>
                </Cell>
                <Cell size={7} tabletSize={8} className="content">
                    <img className="mobileLogo" src={RiplMark}/>
                    <h1 className="text title thin">Create account</h1>
                    {!this.state.shouldDisplayEmailForm ? this.renderThirdPartyCreateForm() : this.renderEmailForm()}
                </Cell>
            </Grid>
        );
    }

    private renderThirdPartyCreateForm = () =>
    {
        return (
            <>
                <div className="thirdPartyErrorMessage"
                     hidden={!this.props.shouldShowThirdPartyLoginError}>{this.props.thirdPartyLoginErrorMessage}</div>
                <FacebookCreateAccountButton subscribeToEmail={this.state.sendEmail}
                                             showBusinessTypeSelection={this.props.showBusinessTypeSelection}/>
                {this.renderFacebookDisabled()}
                {this.getGoogleCreateAccountButton()}
                <div className="orLine">
                    <div className="line"/>
                    or
                    <div className="line"/>
                </div>
                {this.renderSignUpWithEmail()}
                {this.renderFooter()}
            </>
        );
    }

    private renderEmailForm = () =>
    {
        return (
            <BusinessNameEmailPasswordInputPanelContainer
                handleBackClicked={this.toggleDisplayEmailForm}
                onContinueClicked={this.handleEmailContinueClicked}
                primaryButtonText="Continue">
                {this.renderFooter()}
            </BusinessNameEmailPasswordInputPanelContainer>
        );
    }

    private renderFooter = () =>
    {
        return (
            <div className="loginFooter">
                {this.renderShowLogin()}
                <CreateAccountEmailSubscribeCheckbox
                    checked={this.state.sendEmail}
                    onChange={this.handleEmailCheckbox}/>
                <div className="text detail footnote">By continuing, you agree to our
                    <a className="standardLink" href={TERMS_OF_USE_URL} target="_blank"> terms </a>
                                                      and
                    <a className="standardLink" href={PRIVACY_POLICY_URL} target="_blank"> privacy policy</a>.
                </div>
            </div>
        );
    }

    private renderFacebookDisabled = () =>
    {
        if ( apptimizeVariables.shouldDisableFacebookConnect() )
        {
            return (
                <div className="text">
                    Creating an account using Facebook is currently disabled.<br/>Please create an account using email and password.
                </div>
            );
        }
        return null;
    }

    private renderShowLogin = () =>
    {
        if ( !this.state.shouldDisplayEmailForm )
        {
            return (
                <div className="text">
                    Already have an account? <span onClick={this.props.showLogin} className="navLink">Log in</span>
                </div>
            );
        }
        return null;
    }

    private renderSignUpWithEmail = () =>
    {
        return <div className="emailSignUpLink" onClick={this.toggleDisplayEmailForm}><img src={MailIcon} alt="emailIcon"/>Sign up with email</div>;
    }

    private handleEmailContinueClicked = ( emailAddress: string, password: string ) =>
    {
        this.props.onCreateEmailAccountAttempt( emailAddress, password, this.state.sendEmail );
    }

    private getDefaultSendEmailState = () =>
    {
        const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
        return !isEUTimezone( timezoneOffsetInMinutes );
    }

    private handleEmailCheckbox = ( value ) => this.setState( { sendEmail: value } );

    private toggleDisplayEmailForm = () =>
    {
        this.setState( {
            shouldDisplayEmailForm: !this.state.shouldDisplayEmailForm,
        } );
    }

    private getCTAPanelText()
    {
        if ( this.props.isAcceptTeamInviteFlow )
        {
            return "You are a few clicks away from joining your Ripl team to create stunning social posts";
        }
        return "You are a few clicks away from creating stunning social posts";
    }

    private getGoogleCreateAccountButton = () =>
    {
        return <GoogleOneTapLoginButton isLoginButton={true} handleGoogleResponse={this.handleGoogleResponse}/>;
    }

    private handleGoogleResponse = ( tokenResponse: CredentialResponse ) =>
    {
        this.props.handleGoogleCreateAccountResponse( tokenResponse, this.state.sendEmail );
    }
}
