import { BackgroundChoice, StoreState } from "../../../_types";
import { connect } from "react-redux";
import { BackgroundMediaGrid, BackgroundMediaGridProps } from "../../BackgroundMediaGrid";
import { getInitialControlDataForCurrentSelectedDesign } from "../../../ducks/mixModel";
import { getEditorControl } from "../../../ducks/ui";
import { getBackgroundMediaFromAPI } from "../../../ducks/dynamicDesignData";

const mapStateToProps = ( storeState: StoreState ): BackgroundMediaGridProps =>
{
    const initialControlDataForCurrentSelectedDesign = getInitialControlDataForCurrentSelectedDesign( storeState );
    const control = getEditorControl( storeState );
    const initialControlDataForCurrentSelectedDesignElement = initialControlDataForCurrentSelectedDesign[control.id] as BackgroundChoice;

    return {
        initialBackgroundMediaAPIItem: initialControlDataForCurrentSelectedDesignElement
                                       && initialControlDataForCurrentSelectedDesignElement.media,
        backgroundMediaOptions: getBackgroundMediaFromAPI( storeState ),
    };
};
export default connect(
    mapStateToProps,
)
( BackgroundMediaGrid );
