import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
    InviteTeamMembersDialogContent,
    InviteTeamMembersDialogContentDispatchProps,
    InviteTeamMembersDialogContentProps,
} from "../InviteTeamMembersDialogContent";
import { DialogControlProps, StoreState } from "../../_types";
import { getRemainingTeamInvites } from "../../ducks/userBusiness";
import { userBusinessServices } from "../../services";
import { map, trim } from "lodash";

const mapStateToProps = ( storeState: StoreState ): InviteTeamMembersDialogContentProps =>
{
    return {
        numberOfRemainingInvites: getRemainingTeamInvites( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: DialogControlProps ): InviteTeamMembersDialogContentDispatchProps =>
{
    return {
        async sendInvites( emails: string[] )
        {
            const whitespaceTrimmedEmails = map( emails, ( email: string ) => trim( email ) );
            await dispatch( userBusinessServices.sendTeamInvites( whitespaceTrimmedEmails ) );
            ownProps.closeDialog();
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( InviteTeamMembersDialogContent );
