import * as React from "react";
import { capitalize } from "lodash";
import { DesignControlIconImages, UIEditorType } from "../_types";
import { IControlConfig } from "../_types/api";
import classNames from "classnames";
import { EDITOR_TYPES } from "../helpers";

export interface ControlBlockProps
{
    editorType: UIEditorType;
    designControlIconImages?: DesignControlIconImages;
    title?: string;
    control?: IControlConfig;
    selected: boolean;
    dynamicImage?: string;
    disabled?: boolean;
    isStaticOutputMode: boolean;
    showAnimatedOnlyTipWhenDisabled?: boolean;
}

export interface ControlBlockDispatchProps
{
    onControlClicked( editorType: UIEditorType, controlId?: string ): void;
}

interface DisabledTipState
{
    top: number;
    left: number;
    visible: boolean;
}

export class ControlBlock extends React.PureComponent<ControlBlockProps & ControlBlockDispatchProps, DisabledTipState>
{
    private selfRef: React.RefObject<HTMLDivElement>;

    constructor( props: Readonly<ControlBlockProps & ControlBlockDispatchProps> )
    {
        super( props );
        this.state = {
            top: 0,
            left: 0,
            visible: false,
        };
        this.selfRef = React.createRef();
    }

    public render()
    {
        const { editorType, control, selected, title, designControlIconImages, disabled } = this.props;
        const controlType = (control && control.type) || "";

        return (
            <div ref={this.selfRef} className={classNames( "controlBlock", { selected, disabled } )}
                 onClick={this.handleControlClicked} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
                <div className={`control ${editorType} ${controlType}`}>
                    {this.props.children}
                    {designControlIconImages && <img alt="control image"
                                                     src={selected ? designControlIconImages.selectedSrc : designControlIconImages.defaultSrc}/>}
                </div>
                <div className="controlTitle">
                    {title || capitalize( editorType )}
                    <img className="playButton"
                         src={this.props.dynamicImage}
                         hidden={this.shouldHidePlayButton()}
                         alt="play button"/>
                </div>
                {this.renderAnimatedOnlyTipWhenDisabled()}
            </div>
        );
    }

    private shouldHidePlayButton = () =>
    {
        const shouldHideForStaticOutputMode = this.props.isStaticOutputMode;
        const isAudioControl = this.props.dynamicImage && this.props.editorType === EDITOR_TYPES.AUDIO_CONTROL;
        return !isAudioControl || shouldHideForStaticOutputMode;
    }

    private handleControlClicked = () =>
    {
        const { editorType, control, onControlClicked, disabled } = this.props;
        if ( !disabled )
        {
            const controlId = control && control.id;
            onControlClicked( editorType, controlId );
        }
    }

    private renderAnimatedOnlyTipWhenDisabled = () =>
    {
        if ( this.props.showAnimatedOnlyTipWhenDisabled && this.props.disabled )
        {
            return (<div className="animated-only-disabled-hover-tip"
                         style={{
                             left: this.state.left + "px",
                             top: this.state.top + "px",
                             display: this.state.visible ? "flex" : "none",
                         }}><span>Make your post Animated to enable this control</span></div>);
        }
        return null;
    }

    private calculateAnimationOnlyTipPosition = () =>
    {
        const selfElement: HTMLDivElement = this.selfRef && this.selfRef.current;

        if ( selfElement )
        {
            const selfTop = selfElement.getBoundingClientRect().top;
            const selfHeight = selfElement.getBoundingClientRect().height;

            const tooltipTopDesired = selfTop + selfHeight - 10;
            const tooltipHeightApprox = 30;
            const tooltipTopWhenFixedToBottomOfWindow = window.innerHeight - tooltipHeightApprox;
            this.setState( {
                left: 14,
                top: (tooltipTopDesired + tooltipHeightApprox) > window.innerHeight ? tooltipTopWhenFixedToBottomOfWindow : tooltipTopDesired,
            } );
        }
    }

    private onMouseEnter = ( e: React.MouseEvent ) =>
    {
        this.setState( { visible: true } );
        this.calculateAnimationOnlyTipPosition();
    }
    private onMouseLeave = ( e: React.MouseEvent ) =>
    {
        this.setState( { visible: false } );
        this.calculateAnimationOnlyTipPosition();
    }
}
