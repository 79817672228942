import * as React from "react";
import classNames from "classnames";
import { CoachMarkContentFlow, CoachMarkNameKey, CoachMarkPosition } from "../_types";
import { CoachMarkPointer } from "./svgs/CoachMarkPointer";

export interface CoachMarkProps
{
    name: CoachMarkNameKey;
    title?: string;
    message?: string;
    selector: string;
    position: CoachMarkPosition;
    alignBubble: CoachMarkContentFlow;
    zIndex?: number;
    fixed?: boolean;
    hideClose?: boolean;
    additionalClass?: string;
    darkMode?: boolean;
    dismissOnContentTap?: boolean;
}

export interface CoachMarkDispatchProps
{
    onDismiss: ( coachMarkName: CoachMarkNameKey ) => void;
}

interface CoachMarkState
{
    top: number;
    left: number;
    offsetTop: number;
    offsetLeft: number;
    visible: boolean;
}

export class CoachMark extends React.PureComponent<CoachMarkProps & CoachMarkDispatchProps, CoachMarkState>
{
    constructor( props: Readonly<CoachMarkProps & CoachMarkDispatchProps> )
    {
        super( props );
        this.state = {
            top: 0,
            left: 0,
            offsetLeft: 0,
            offsetTop: 0,
            visible: false,
        };
    }

    public componentDidMount()
    {
        this.updateOffsets();
        window.addEventListener( "resize", this.calculatePosition );
    }

    public componentWillUnmount()
    {
        window.removeEventListener( "resize", this.calculatePosition );
    }

    public render()
    {
        const { additionalClass, alignBubble, darkMode, dismissOnContentTap, hideClose, message, position, title } = this.props;
        const positionClassName = position === CoachMarkPosition.ABOVE ? "arrowDown" : "arrowUp";
        const flowClassName = alignBubble === CoachMarkContentFlow.LEFT ? "flowLeft" : "flowRight";
        return (<>
            <div className={classNames( "coachMark", positionClassName, flowClassName, additionalClass,
                { visible: this.state.visible, darkMode: darkMode === true } )}
                 style={{
                     left: this.state.left + "px",
                     top: this.state.top + "px",
                     zIndex: this.props.zIndex || 1,
                     position: this.props.fixed ? "fixed" : "absolute",
                 }}>
                <CoachMarkPointer/>
                <div className="content" onClick={dismissOnContentTap ? this.handleDismiss : null}>
                    {!hideClose && <div className="closeButton" onClick={this.handleDismiss}>×</div>}
                    {title && <div className="title">{title}</div>}
                    {message && <div className="message">{message}</div>}
                </div>
            </div>
        </>);
    }

    private handleDismiss = ( e: React.MouseEvent<HTMLElement> ) =>
    {
        e.stopPropagation();
        this.props.onDismiss( this.props.name );
    }

    private updateOffsets = async () =>
    {
        const selfElement = document.querySelector( ".coachMark" ) as HTMLElement;
        if ( selfElement )
        {
            const boundingClientRect = selfElement.getBoundingClientRect();
            await this.setState( {
                offsetLeft: boundingClientRect.left,
                offsetTop: boundingClientRect.top,
            } );
            this.calculatePosition();
        }
    }

    private calculatePosition = () =>
    {
        const targetElement = document.querySelector( this.props.selector ) as HTMLElement;

        if ( targetElement )
        {
            const { left, top, width, height } = targetElement.getBoundingClientRect();
            const { offsetLeft, offsetTop } = this.state;

            const targetCenterX = left + width / 2;
            let targetBottom = top;

            if ( this.props.position === CoachMarkPosition.BELOW )
            {
                targetBottom = top + height;
            }

            this.setState( {
                left: targetCenterX - offsetLeft,
                top: targetBottom - offsetTop,
                visible: true,
            } );
        }
    }
}
