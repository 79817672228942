import * as React from "react";
import classNames from "classnames";

export interface OutputModeToggleControlProps
{
    isAnimatedOutputMode: boolean;
}

export interface OutputModeToggleControlDispatchProps
{
    outputModeChanged: () => void;
}

export class OutputModeToggleControl extends React.PureComponent<OutputModeToggleControlProps & OutputModeToggleControlDispatchProps>
{
    public render()
    {
        const isAnimatedOutputMode = this.props.isAnimatedOutputMode;
        const isImageOutputMode = !isAnimatedOutputMode;
        return (
            <div className="outputToggleGroup" onClick={this.props.outputModeChanged}>
                <div className="outputToggleControlContainer">
                    <div className={classNames( "outputModeToggleControl", { checked: isImageOutputMode } )}>
                        Static
                    </div>
                    <div className={classNames( "outputModeToggleControl", { checked: isAnimatedOutputMode } )}>
                        Animated
                    </div>
                </div>
                <div className={classNames( "outputModeToggleBackground", { animatedSelected: isAnimatedOutputMode } )}/>
            </div>
        );
    }

}
