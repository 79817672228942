import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../_types";
import { InstagramFooterNeedHelpButton, InstagramFooterNeedHelpButtonDispatchProps } from "../InstagramFooterNeedMoreHelpButton";
import { instagramBusinessServices, modalServices } from "../../services";

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): InstagramFooterNeedHelpButtonDispatchProps =>
{
    return {
        onNeedMoreHelpClicked: async () =>
        {
            dispatch( modalServices.openNextLightbox( instagramBusinessServices.displayInstagramTutorialDialog( async () =>
            {
                await dispatch( modalServices.openNextLightbox( instagramBusinessServices.displayInstagramWarmupDialog() ) );
            } ) ) );
        },
    };
};

export default connect(
    () => ({}),
    mapDispatchToProps,
)( InstagramFooterNeedHelpButton );
