import * as React from "react";
import { Music } from "../../../_types";
import { DetailListItem } from "../DetailListItem";
import * as playOverlayIcon from "../../../assets/img/playOverlayIcon.svg";
import * as pauseOverlayIcon from "../../../assets/img/pauseOverlayIcon.svg";
import * as GenericMusicIconSelected from "../../../assets/img/genericMusicIconSelected.svg";
import * as GenericMusicIconUnselected from "../../../assets/img/genericMusicIconUnselected.svg";

export interface MusicSelectionItemProps
{
    selected: boolean;
    isPlaying: boolean;
    musicItem: Music;
}

export interface MusicSelectionItemDispatchProps
{
    handleMusicClick( selectedMusic: Music );
}

export function MusicSelectionItem( props: MusicSelectionItemProps & MusicSelectionItemDispatchProps )
{
    const { musicItem, selected, isPlaying } = props;

    const onClick = () => props.handleMusicClick( musicItem );

    const iconOverlay = <img alt="play-pause" src={isPlaying ? pauseOverlayIcon : playOverlayIcon}/>;
    return (<DetailListItem
        title={musicItem.display_name}
        iconSrc={GenericMusicIconSelected}
        iconSrcUnselected={GenericMusicIconUnselected}
        selected={selected}
        onClick={onClick}
        iconOverlay={iconOverlay}
        outlineContent
    />);
}
