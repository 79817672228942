import * as React from "react";
import Button from "react-md/lib/Buttons/Button";

export interface CreateBlankPostButtonSmallDispatchProps
{
    handleClick: () => void;
}

export class CreateBlankPostButtonSmall extends React.PureComponent<CreateBlankPostButtonSmallDispatchProps>
{
    public render()
    {
        return (
            <Button primary flat swapTheming className="createPostButtonSmall"
                    onClick={this.props.handleClick}>
                Create new post
            </Button>
        );
    }
}
