import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../_types";
import { ScheduleTab, ScheduleTabDispatchProps, ScheduleTabProps } from "../ScheduleTab";
import { getPostsForSelectedDate, getUnscheduledPosts } from "../../../ducks/post";
import { postServices, userServices } from "../../../services";
import { shouldShowUnscheduledPostsInScheduleTab } from "../../../ducks/ui";
import { uiActions } from "../../../actions";
import { apptimizeVariables } from "../../../helpers";

const mapStateToProps = ( storeState: StoreState ): ScheduleTabProps =>
{
    const showUnscheduledPostsInScheduleTab = shouldShowUnscheduledPostsInScheduleTab( storeState );
    const postData = showUnscheduledPostsInScheduleTab ? getUnscheduledPosts( storeState )
                                                       : getPostsForSelectedDate( storeState );
    return {
        postData,
        showUnscheduledPostsViewButton: apptimizeVariables.shouldShowUnscheduledPostsViewButton(),
        showUnscheduledPostsInScheduleTab,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): ScheduleTabDispatchProps =>
{
    return {
        loadData: async () =>
        {
            try
            {
                await dispatch( userServices.loadMe() );
            }
            catch (error)
            {
                // Ignore errors here for now
            }
        },
        updateShowUnscheduledPostsInScheduleTab: ( showUnscheduledPostsInScheduleTab: boolean ) =>
        {
            dispatch( uiActions.showUnscheduledPostsInScheduleTabUpdated( showUnscheduledPostsInScheduleTab ) );
        },
        loadUnscheduledPosts: () =>
        {
            dispatch( postServices.loadUnscheduledPosts() );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( ScheduleTab );
