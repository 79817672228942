import { Action } from "redux-actions";
import { catalogCategoriesSchema, ReducerCreator, updateLookupWithAction } from "../helpers";
import { catalogActions } from "../actions";
import { filter, includes, map } from "lodash";
import { createDefaultDataLookup } from "./dataLookup";
import { CatalogCategoriesState, CatalogCategory, StoreState } from "../_types";

const defaultState: CatalogCategoriesState = {
    ...createDefaultDataLookup(),
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( catalogActions.loadCatalogSuccess, updateCatalogCategoryData );
export default reducerCreator.createReducer();

export function updateCatalogCategoryData( state: CatalogCategoriesState, action: Action<NormalizrData> ): CatalogCategoriesState
{
    const catalogCategoriesState = updateLookupWithAction( catalogCategoriesSchema, action, state );
    restoreOrderingFromNormalizrData( catalogCategoriesState, action.payload );
    return catalogCategoriesState;
}

function restoreOrderingFromNormalizrData( catalogCategoriesState: CatalogCategoriesState, normalizeCatalogAPIData: NormalizrData )
{
    catalogCategoriesState.ids = normalizeCatalogAPIData.entities.catalogs[normalizeCatalogAPIData.result].categories;
}

export function getCategories( state: StoreState ): CatalogCategory[]
{
    const ids = state.catalogCategories.ids;
    return map( ids, ( id ) => state.catalogCategories.idToObj[id] );
}

export function getCategoriesNameByDesignId( state: StoreState, id: number )
{
    const designCategoriesNameArray = map( filter( state.catalogCategories.idToObj, ( category ) =>
    {
        return includes( category.designs, id );
    } ), "name" );
    return designCategoriesNameArray;
}
