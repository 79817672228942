import * as React from "react";

export interface TeamInviteSentContentProps
{
    bodyText: string;
}

export default class InviteTeamsContent extends React.PureComponent<TeamInviteSentContentProps>
{
    public render()
    {
        return (
            <div className="teamInviteSentContent">
                <div className="teamInviteSentBodyText">{this.props.bodyText}</div>
            </div>
        );
    }
}
