import { connect } from "react-redux";
import { StoreState } from "../../../_types";
import { SocialCalendar, SocialCalendarProps } from "../../SocialCalendar";
import { getSocialCalendarDays, getSocialCalendarPosts, getSocialCalendarSelectedDate } from "../../../ducks/socialCalendar";

function getSocialCalendarTitle()
{
    return "Social Ideas Calendar";
}

const mapStateToProps = ( state: StoreState ): SocialCalendarProps => ({
    title: getSocialCalendarTitle(),
    calendar: getSocialCalendarDays( state ),
    posts: getSocialCalendarPosts( state ),
    scrollToDate: getSocialCalendarSelectedDate( state ),
});

export default connect( mapStateToProps )( SocialCalendar );
