import { connect } from "react-redux";
import { Dispatch } from "redux";
import { BusinessTypeBlock, BusinessTypeBlockDispatchProps, BusinessTypeBlockProps } from "../BusinessTypeBlock";
import { uiActions } from "../../actions";
import { getSelectedBusinessTypeId } from "../../ducks/ui";
import { BusinessType, StoreState } from "../../_types";

interface BusinessTypeBlockContainerProps
{
    businessType: BusinessType;
}

const mapStateToProps = ( storeState: StoreState, ownProps: BusinessTypeBlockContainerProps ): BusinessTypeBlockProps =>
{
    return {
        ...ownProps,
        selectedBusinessTypeId: getSelectedBusinessTypeId( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): BusinessTypeBlockDispatchProps =>
{
    return {
        handleSelectBusinessType: ( id: number ) =>
        {
            dispatch( uiActions.updateBusinessInfo( { business_type_id: id } ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( BusinessTypeBlock );
