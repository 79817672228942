import * as React from "react";
import TextField from "react-md/lib/TextFields/TextField";
import * as TrashIcon from "../../../assets/img/trashIcon.png";
import * as StylizeIcon from "../../../assets/img/stylizeIcon.png";
import * as CopyIcon from "../../../assets/copy.svg";
import { STYLIZED_TEXT_BUTTON_CLASS, TEXT_PICKER_CAPTION_UPDATE_DEBOUNCE_TIME_MS, TEXT_PICKER_MAX_ROWS_PER_TEXT_FIELD } from "../../../helpers";
import { Cancelable, debounce } from "lodash";
import StylizedTextCoachMarkContainer from "../containers/StylizedTextCoachMark.container";
import classNames from "classnames";
import { TextPickerCaptionData } from "../../../_types";

export interface TextPickerFieldStylizeTextProps
{
    shouldEnableStylizedText?: boolean;
    styleId?: string;
    showStylizedTextCoachMark?: boolean;
    shouldAllowCopying?: boolean;
}

export interface TextPickerFieldProps extends TextPickerFieldStylizeTextProps
{
    identifier: string;
    value: string;
    label?: string;
    placeholder: string;
    type: string;
    rows?: number;
    focusElement?: string;
}

export interface TextPickerFieldDispatchProps
{
    onFieldChanged: ( fieldName: string, value: string, id?: string ) => void;
    onFocus?: ( id: string ) => void;
    onBlur?: ( id: string ) => void;
    onRemoveTextBoxClicked?: ( captionId: string, value: string ) => void;
    onCopyTextBoxClicked?: ( captionId: string ) => void;
    onChangeStylizeTextClicked?: ( captionData: TextPickerCaptionData ) => void;
}

interface TextPickerFieldState
{
    value: string;
}

export class TextPickerField extends React.PureComponent<TextPickerFieldProps & TextPickerFieldDispatchProps, TextPickerFieldState>
{
    private executeOnFieldChangedDebounced: (( fieldName: string, value: string, id?: string ) => void) & Cancelable;
    private textFieldRef;

    constructor( props )
    {
        super( props );
        this.executeOnFieldChangedDebounced = debounce( this.props.onFieldChanged, TEXT_PICKER_CAPTION_UPDATE_DEBOUNCE_TIME_MS );
        this.state = {
            value: this.props.value,
        };
        this.textFieldRef = React.createRef();
    }

    public componentDidMount()
    {
        if ( this.props.focusElement && this.textFieldRef.current && (this.props.focusElement === this.props.identifier) )
        {
            this.textFieldRef.current.focus();
        }
    }

    public componentDidUpdate( prevProps: TextPickerFieldProps )
    {
        const { focusElement } = this.props;
        const focusElementDidChange = focusElement !== prevProps.focusElement;
        if ( focusElementDidChange && this.props.focusElement && (this.props.focusElement === this.props.identifier) && this.textFieldRef.current )
        {
            this.textFieldRef.current.focus();
        }
    }

    public componentWillUnmount()
    {
        this.cancelDebounceApplyingChangedImmediately();
    }

    public render()
    {
        return (<><TextField
            label={this.props.label}
            ref={this.textFieldRef}
            id={this.props.identifier}
            key={this.props.identifier}
            value={this.state.value}
            placeholder={this.props.placeholder}
            rows={this.props.rows || 1}
            maxRows={TEXT_PICKER_MAX_ROWS_PER_TEXT_FIELD}
            floating
            onChange={this.onFieldChanged}
            onFocus={this.props.onFocus ? this.onFocus : null}
            onBlur={this.props.onBlur ? this.onBlur : null}
        />
            {this.props.shouldEnableStylizedText && this.props.onChangeStylizeTextClicked && <>
                <div className={classNames( "extraCaptionButton", STYLIZED_TEXT_BUTTON_CLASS )}
                     onClick={this.handleChangeStylizeTextClicked}>
                    <img src={StylizeIcon} alt="stylize"/>
                    {this.props.showStylizedTextCoachMark && <StylizedTextCoachMarkContainer/>}
                </div>
            </>}
            {this.props.shouldAllowCopying && this.props.onCopyTextBoxClicked && <>
                <div className="extraCaptionButton"
                     onClick={this.handleCopyTextBoxClicked}>
                    <img src={CopyIcon} alt="copy"/></div>
            </>}
            {this.props.onRemoveTextBoxClicked && <>
                <div className="extraCaptionButton"
                     onClick={this.handleRemoveTextBoxClicked}>
                    <img src={TrashIcon} alt="delete"/></div>
            </>}
        </>);
    }

    private onFocus = () =>
    {
        this.props.onFocus( this.props.identifier );
    }

    private onBlur = () =>
    {
        this.props.onBlur( this.props.identifier );
    }

    private onFieldChanged = ( content: number | string ) =>
    {
        const value = content.toString();
        this.setState( { value } );
        if ( this.executeOnFieldChangedDebounced )
        {
            this.executeOnFieldChangedDebounced( this.props.type, value, this.props.identifier );
        }
    }

    private handleRemoveTextBoxClicked = () =>
    {
        this.cancelDebounceApplyingChangedImmediately();
        this.props.onRemoveTextBoxClicked( this.props.identifier, this.state.value );
    }

    private handleCopyTextBoxClicked = () =>
    {
        this.cancelDebounceApplyingChangedImmediately();
        this.props.onCopyTextBoxClicked( this.props.identifier );
    }

    private handleChangeStylizeTextClicked = () =>
    {
        this.cancelDebounceApplyingChangedImmediately();
        const captionData: TextPickerCaptionData = {
            id: this.props.identifier,
            styleId: this.props.styleId,
            value: this.props.value,
        };
        this.props.onChangeStylizeTextClicked( captionData );
    }

    private cancelDebounceApplyingChangedImmediately()
    {
        if ( this.executeOnFieldChangedDebounced )
        {
            this.executeOnFieldChangedDebounced.flush();
            this.executeOnFieldChangedDebounced.cancel();
            this.executeOnFieldChangedDebounced = null;
        }
    }
}
