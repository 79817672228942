import { ReducerCreator, STOCK_MEDIA_SEARCH_PHOTOS_TYPE } from "../helpers";
import { stockMediaActions } from "../actions";
import { Action } from "redux-actions";
import { StockMediaSearchType, StockMediaState, StoreState } from "../_types";
import { concat, uniq } from "lodash";

const defaultState: StockMediaState = {
    matches: null,
    query: "",
    search_id: "",
    error: false,
    total_count: 0,
    next_page: 1,
    stockMediaSearchType: STOCK_MEDIA_SEARCH_PHOTOS_TYPE,
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addCombinedActions( [stockMediaActions.searchPhotosLoaded, stockMediaActions.searchVideosLoaded], handleSearchMediaLoaded );
reducerCreator.addCombinedActions( [stockMediaActions.searchPhotosFailed, stockMediaActions.searchVideosFailed], handleSearchMediaFailed );
reducerCreator.addAction( stockMediaActions.searchResultsCleared, handleSearchResultsCleared );
reducerCreator.addAction( stockMediaActions.changeStockMediaSearchType, handleStockMediaSearchTypeChanged );

export default reducerCreator.createReducer();

function handleStockMediaSearchTypeChanged( state: StockMediaState, action: Action<StockMediaSearchType> ): StockMediaState
{
    return {
        ...state,
        stockMediaSearchType: action.payload,
    };
}

function handleSearchMediaLoaded( state: StockMediaState, action: Action<StockMediaAPIData> ): StockMediaState
{
    const matches = uniq( concat( state.matches || [], action.payload.matches ) );
    return {
        ...state,
        ...action.payload,
        matches,
        error: false,
    };
}

function handleSearchResultsCleared( state: StockMediaState ): StockMediaState
{
    return {
        ...state,
        query: "",
        matches: null,
        error: false,
        total_count: 0,
        next_page: 1,
    };
}

function handleSearchMediaFailed( state: StockMediaState ): StockMediaState
{
    return {
        ...state,
        error: true,
    };
}

export const getSearchedStockMedia = ( state: StoreState ) => state.stockMedia.matches;
export const getStockMediaSearchTerm = ( state: StoreState ) => state.stockMedia.query;
export const getLastSearchId = ( state: StoreState ) => state.stockMedia.search_id;
export const getStockMediaSearchError = ( state: StoreState ) => state.stockMedia.error;
export const getStockMediaTotalMatchCount = ( state: StoreState ) => state.stockMedia.total_count;
export const getStockMediaSearchType = ( state: StoreState ) => state.stockMedia.stockMediaSearchType;
