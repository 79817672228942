import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../../_types";
import { modalServices, userBusinessServices } from "../../../../services";
import {
    getTeamMemberCountForCurrentBusiness,
    hasRemainingTeamInvites,
    hasTeamAdminPermissionsForCurrentBusiness,
} from "../../../../ducks/userBusiness";
import { UsersTab, UsersTabDispatchProps, UsersTabProps } from "../UsersTab";
import { getCurrentUserId } from "../../../../ducks/user";
import { store } from "../../../../store";
import { LightboxDialogIdentifierForKey, NON_BREAKING_SPACE } from "../../../../helpers";

const mapStateToProps = ( storeState: StoreState ): UsersTabProps =>
{
    return {
        isTeamAdmin: hasTeamAdminPermissionsForCurrentBusiness( storeState ),
        memberCount: getTeamMemberCountForCurrentBusiness( storeState ),
        maySendMoreInvitations: hasRemainingTeamInvites( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): UsersTabDispatchProps =>
{
    return {
        handleAddUsersClicked()
        {
            dispatch( userBusinessServices.displayInviteTeamsMembersDialog() );
        },
        handleRemoveSelfClicked()
        {
            const currentUserId = getCurrentUserId( store.getState() );

            dispatch( modalServices.openLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.DELETE_SELF_FROM_TEAM,
                showCancelX: true,
                modal: true,
                confirmLabel: "Leave team",
                width: 550,
                title: "Leave this team?",
                content: `You will no longer be able to access this brand to collaborate on${NON_BREAKING_SPACE}posts.`,
                onSuccess: () =>
                {
                    dispatch( userBusinessServices.removeTeamMember( currentUserId ) );
                },
            } ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( UsersTab );
