import { Post } from "../../_types";
import * as React from "react";
import { ASPECT_RATIO_NAMES, ASPECT_RATIO_VALUE } from "../../helpers";
import VideoElementContainer from "../containers/VideoElement.container";

export interface CollectionRowPostProps
{
    post: Post;
    index: number;
    handleCustomizePost: ( post: Post, index: number ) => void;
}

export class CollectionRowPost extends React.PureComponent<CollectionRowPostProps>
{
    public render()
    {
        const { post } = this.props;
        const aspectRatioValue = ASPECT_RATIO_VALUE[post.aspect_ratio];
        const aspectRatioClass = ASPECT_RATIO_NAMES[post.aspect_ratio];
        return (
            <div className={`homePostRowTemplate ${aspectRatioClass}`}>
                <VideoElementContainer
                    videoUrl={post.video_url}
                    imageUrl={post.image_url}
                    posterUrl={post.poster_url}
                    muted={true}
                    playOnMouseOver={true}
                    onClick={this.handleClick}
                    aspectRatio={aspectRatioValue}/>
            </div>
        );
    }

    private handleClick = () =>
    {
        this.props.handleCustomizePost( this.props.post, this.props.index );
    }
}
