import { createAction } from "redux-actions";
import { ActionCreator, CanvasUpdater } from "../helpers";
import {
    AspectRatio,
    BackgroundAnimationChoices,
    BrandLogo,
    BusinessType,
    CatalogCategory,
    CoachMarkNameKey,
    ConfirmFacebookDialogData,
    DesignContextualData,
    DesignElement,
    DesignElementProperties,
    DesignMediaObject,
    DesignPresets,
    FacebookGroupAddRiplDialogData,
    FacebookPickerDialogData,
    FontData,
    Music,
    PaymentFormError,
    Post,
    ScheduledAction,
    SchedulePostDialogData,
    SlideIndexData,
    SlideSummaryResponse,
    SlideTransitionChoices,
    StylizedTextChoices,
    TextAnimationChoices,
    TextPickerCaptionData,
    UIEditorType,
    UserBusiness,
} from "../_types";
import { EpidemicSoundTrack, RecordAPIUpdate } from "../_types/api";
import { ProcessCollectionItem, ProcessTrackingKey } from "../ducks/ui";

const actionCreator = new ActionCreator( "UI" );

const uiActionsTypes = {
    calendarDate: {
        picked: actionCreator.generateType( "CALENDAR_DATE", "PICKED" ),
    },
    register: {
        canvasUpdater: actionCreator.generateType( "REGISTER", "CANVAS_UPDATER" ),
    },
    controlEditor: {
        switched: actionCreator.generateType( "CONTROL_EDITOR", "SWITCHED" ),
        focusElementUpdate: actionCreator.generateType( "CONTROL_EDITOR", "FOCUS_ELEMENT_UPDATE" ),
        deselectAllControls: actionCreator.generateType( "CONTROL_EDITOR", "DESELECT_ALL_CONTROLS" ),
        deselectAnimationControls: actionCreator.generateType( "CONTROL_EDITOR", "DESELECT_ANIMATION_CONTROLS" ),
    },
    customizeTips: {
        dismiss: actionCreator.generateType( "CUSTOMIZE_TIPS", "DISMISS" ),
        registerShown: actionCreator.generateType( "CUSTOMIZE_TIPS", "REGISTER_SHOWN" ),
    },
    mixModel: {
        recordProgress: actionCreator.generateType( "MIX_MODEL", "RECORD_PROGRESS" ),
        recordError: actionCreator.generateType( "MIX_MODEL", "RECORD_ERROR" ),
        convertVideoProgress: actionCreator.generateType( "MIX_MODEL", "CONVERT_VIDEO_PROGRESS" ),
        trimVideoProgress: actionCreator.generateType( "MIX_MODEL", "TRIM_VIDEO_PROGRESS" ),
    },
    designSlide: {
        indexDataUpdated: actionCreator.generateType( "DESIGN_SLIDE", "INDEX_DATA_UPDATED" ),
    },
    designCatalog: {
        aspectRatioSet: actionCreator.generateType( "DESIGN_CATALOG", "ASPECT_RATIO_SET" ),
        categorySelected: actionCreator.generateType( "DESIGN_CATALOG", "CATEGORY_SELECTED" ),
    },
    businessInfo: {
        update: actionCreator.generateType( "CREATE_BUSINESS", "UPDATE" ),
        clear: actionCreator.generateType( "CREATE_BUSINESS", "CLEAR" ),
        clearBrandSlideFontData: actionCreator.generateType( "CREATE_BUSINESS", "CLEAR_BRAND_SLIDE_FONT_DATA" ),
    },
    facebookPicker: {
        modalOpened: actionCreator.generateType( "FACEBOOK_PICKER", "MODAL_OPENED" ),
        modalUpdated: actionCreator.generateType( "FACEBOOK_PICKER", "MODAL_UPDATED" ),
        modalClosed: actionCreator.generateType( "FACEBOOK_PICKER", "MODAL_CLOSED" ),
    },
    facebookGroupAddRipl: {
        modalOpened: actionCreator.generateType( "FACEBOOK_GROUP_ADD_RIPL", "MODAL_OPENED" ),
        modalClosed: actionCreator.generateType( "FACEBOOK_GROUP_ADD_RIPL", "MODAL_CLOSED" ),
    },
    confirmFacebookModal: {
        modalOpened: actionCreator.generateType( "CONFIRM_FACEBOOK", "MODAL_OPENED" ),
        modalClosed: actionCreator.generateType( "CONFIRM_FACEBOOK", "MODAL_CLOSED" ),
    },
    schedulePost: {
        modalOpened: actionCreator.generateType( "SCHEDULE_POST", "MODAL_OPENED" ),
        modalClosed: actionCreator.generateType( "SCHEDULE_POST", "MODAL_CLOSED" ),
    },
    fontAutocomplete: {
        updateText: actionCreator.generateType( "FONT_AUTOCOMPLETE", "UPDATE_TEXT" ),
        updateFont: actionCreator.generateType( "FONT_AUTOCOMPLETE", "UPDATE_FONT" ),
    },
    designCanvas: {
        refreshed: actionCreator.generateType( "DESIGN_CANVAS", "REFRESHED" ),
    },
    designSwitchingCanvas: {
        refreshed: actionCreator.generateType( "DESIGN_SWITCHING_CANVAS", "REFRESHED" ),
    },
    designCaption: {
        updated: actionCreator.generateType( "DESIGN_CAPTION", "UPDATED" ),
        controlsSet: actionCreator.generateType( "DESIGN_CAPTION", "CONTROLS_SET" ),
        textUpdated: actionCreator.generateType( "DESIGN_CAPTION", "TEXT_UPDATED" ),
        cleared: actionCreator.generateType( "DESIGN_CAPTION", "CLEARED" ),
    },
    designExtraCaptionSupport: {
        setSupported: actionCreator.generateType( "DESIGN_EXTRA_CAPTIONS", "SET_SUPPORTED" ),
    },
    stylizedTextChoices: {
        set: actionCreator.generateType( "STYLIZED_TEXT_CHOICES", "SET" ),
    },
    designZoom: {
        updated: actionCreator.generateType( "DESIGN_ZOOM", "UPDATED" ),
        controlsSet: actionCreator.generateType( "DESIGN_ZOOM", "CONTROLS_SET" ),
    },
    paymentSku: {
        selected: actionCreator.generateType( "PAYMENT_SKU", "SELECTED" ),
        cleared: actionCreator.generateType( "PAYMENT_SKU", "CLEARED" ),
    },
    emailForm: {
        emailUpdate: actionCreator.generateType( "EMAIL_FORM", "EMAIL_UPDATE" ),
        passwordUpdate: actionCreator.generateType( "EMAIL_FORM", "PASSWORD_UPDATE" ),
        setError: actionCreator.generateType( "EMAIL_FORM", "SET_ERROR" ),
    },
    partnerLogin: {
        setError: actionCreator.generateType( "PARTNER_LOGIN", "SET_ERROR" ),
    },
    thirdPartyLogin: {
        setError: actionCreator.generateType( "THIRD_PARTY_LOGIN", "SET_ERROR" ),
    },
    resetForm: {
        setProcessing: actionCreator.generateType( "RESET_FORM", "SET_PROCESSING" ),
        setError: actionCreator.generateType( "RESET_FORM", "SET_ERROR" ),
        clearErrors: actionCreator.generateType( "RESET_FORM", "CLEAR_ERRORS" ),
    },
    scheduledAction: {
        selected: actionCreator.generateType( "SCHEDULED_ACTION", "SELECTED" ),
        cleared: actionCreator.generateType( "SCHEDULED_ACTION", "CLEARED" ),
    },
    recommendedPost: {
        selected: actionCreator.generateType( "RECOMMENDED_POST", "SELECTED" ),
        cleared: actionCreator.generateType( "RECOMMENDED_POST", "CLEARED" ),
    },
    image: {
        selected: actionCreator.generateType( "IMAGE", "SELECTED" ),
        cleared: actionCreator.generateType( "IMAGE", "SELECTION_CLEARED" ),
    },
    cardProcessingSpinner: {
        enabled: actionCreator.generateType( "CARD_PROCESSING_SPINNER", "ENABLED" ),
    },
    redirectUrl: {
        set: actionCreator.generateType( "REDIRECT_URL", "SET" ),
    },
    homePageAlert: {
        set: actionCreator.generateType( "HOME_PAGE_ALERT", "SET" ),
    },
    musicCatalog: {
        trackSelected: actionCreator.generateType( "MUSIC_CATALOG", "TRACK_SELECTED" ),
    },
    isCustomMusicUploading: {
        toggled: actionCreator.generateType( "CUSTOM_MUSIC_UPLOADING", "TOGGLED" ),
    },
    music: {
        audioPlayerMusicTrack: actionCreator.generateType( "MUSIC", "AUDIO_PLAYER_MUSIC_TRACK_SET" ),
        audioPlayerMusicTrackCleared: actionCreator.generateType( "MUSIC", "AUDIO_PLAYER_MUSIC_TRACK_CLEARED" ),
    },
    teamMemberInvite: {
        email: actionCreator.generateType( "TEAM_MEMBER_INVITE", "EMAIL" ),
    },
    videoTrimmerModal: {
        showing: actionCreator.generateType( "VIDEO_TRIMMER", "MODAL_SHOWING" ),
    },
    externalMediaUploadStatus: {
        updated: actionCreator.generateType( "STOCK_MEDIA_UPLOAD_STATUS", "UPDATED" ),
    },
    shouldShowDefaultStockMediaSearchResults: {
        update: actionCreator.generateType( "SHOULD_SHOW_DEFAULT_STOCK_MEDIA_SEARCH_RESULTS", "UPDATED" ),
    },
    contentSearchSpinner: {
        enabled: actionCreator.generateType( "CONTENT_SEARCH_SPINNER", "ENABLED" ),
    },
    collectionSpinner: {
        enabled: actionCreator.generateType( "COLLECTION_SPINNER", "ENABLED" ),
    },
    collectionPageSource: {
        set: actionCreator.generateType( "COLLECTION_PAGE_SOURCE", "SET" ),
    },
    subscriptionInfoSpinner: {
        enabled: actionCreator.generateType( "SUBSCRIPTION_INFO_SPINNER", "ENABLED" ),
    },
    switchSubscriptionSpinner: {
        enabled: actionCreator.generateType( "SWITCH_SUBSCRIPTION_SPINNER", "ENABLED" ),
    },
    showUnscheduledPostsInScheduleTab: {
        update: actionCreator.generateType( "SHOW_UNSCHEDULED_IN_SCHEDULE_TAB", "UPDATED" ),
    },
    processTracking: {
        running: actionCreator.generateType( "PROCESS_TRACKING", "RUNNING" ),
        stopped: actionCreator.generateType( "PROCESS_TRACKING", "STOPPED" ),
    },
    processCollectionTracking: {
        running: actionCreator.generateType( "PROCESS_COLLECTION_TRACKING", "RUNNING" ),
        stopped: actionCreator.generateType( "PROCESS_COLLECTION_TRACKING", "STOPPED" ),
    },
    createYourOwnBannerButtonVisibility: {
        update: actionCreator.generateType( "CREATE_YOUR_OWN_BANNER_BUTTON_VISIBILITY", "UPDATE" ),
    },
    showYouAroundTourVisibility: {
        update: actionCreator.generateType( "SHOW_YOU_AROUND_TOUR_VISIBILITY", "UPDATE" ),
    },
    presentAfterShareDialog: {
        set: actionCreator.generateType( "PRESENT_AFTER_SHARE_DIALOG", "SET" ),
    },
    hasSeenAfterShareDialog: {
        set: actionCreator.generateType( "HAS_SEEN_AFTER_SHARE_DIALOG", "SET" ),
    },
    hasSeenRiplUserSurvey2023: {
        set: actionCreator.generateType( "HAS_SEEN_RIPL_USER_SURVEY_2023", "SET" ),
    },
    coachMark: {
        hideAll: actionCreator.generateType( "COACH_MARKS", "HIDE_ALL" ),
        show: actionCreator.generateType( "COACH_MARKS", "SHOW" ),
        clear: actionCreator.generateType( "COACH_MARKS", "CLEAR" ),
    },
    paymentMethodError: {
        set: actionCreator.generateType( "PAYMENT_METHOD_ERROR", "SET" ),
    },
    paymentFormError: {
        set: actionCreator.generateType( "PAYMENT_FORM_ERROR", "SET" ),
        clear: actionCreator.generateType( "PAYMENT_FORM_ERROR", "CLEAR" ),
    },
    facebookAdsSubmitError: {
        set: actionCreator.generateType( "FACEBOOK_ADS_SUBMIT_ERROR", "SET" ),
    },
    selectedDesignElement: {
        set: actionCreator.generateType( "SELECTED_DESIGN_ELEMENT", "SET" ),
    },
    designElementProperties: {
        received: actionCreator.generateType( "DESIGN_ELEMENT_PROPERTIES", "RECEIVED" ),
    },
    slideTransitionProperties: {
        received: actionCreator.generateType( "SLIDE_TRANSITION_PROPERTIES", "RECEIVED" ),
    },
    backgroundAnimationProperties: {
        received: actionCreator.generateType( "BACKGROUND_ANIMATION_PROPERTIES", "RECEIVED" ),
    },
    designPresets: {
        received: actionCreator.generateType( "DESIGN_PRESETS", "RECEIVED" ),
    },
    textAnimationChoices: {
        set: actionCreator.generateType( "TEXT_ANIMATION_CHOICES", "SET" ),
    },
    globalTextAnimationChoices: {
        set: actionCreator.generateType( "GLOBAL_TEXT_ANIMATION_CHOICES", "SET" ),
    },
    slideTransitionChoices: {
        set: actionCreator.generateType( "SLIDE_TRANSITION_CHOICES", "SET" ),
    },
    backgroundAnimationChoices: {
        set: actionCreator.generateType( "BACKGROUND_ANIMATION_CHOICES", "SET" ),
    },
    slideSummary: {
        set: actionCreator.generateType( "SLIDE_SUMMARY", "SET" ),
        clear: actionCreator.generateType( "SLIDE_SUMMARY", "CLEAR" ),
    },
    textPropertiesSubPanelCaptionData: {
        set: actionCreator.generateType( "TEXT_PROPERTIES_SUB_PANEL_CAPTION_DATA", "SET" ),
        clear: actionCreator.generateType( "TEXT_PROPERTIES_SUB_PANEL_CAPTION_DATA", "CLEAR" ),
    },
    facebookAdMobileWebView: {
        opened: actionCreator.generateType( "FACEBOOK_AD_MOBILE_WEB_VIEW", "OPENED" ),
    },
    manageBusinessLogos: {
        setSelected: actionCreator.generateType( "MANAGE_BUSINESS_LOGOS", "SET_SELECTED" ),
        clearSelected: actionCreator.generateType( "MANAGE_BUSINESS_LOGOS", "CLEAR_SELECTED" ),
    },
    isAcceptTeamInviteFlow: {
        set: actionCreator.generateType( "IS_ACCEPT_TEAM_INVITE_FLOW", "SET" ),
    },
    designMediaObjectsResponse: {
        received: actionCreator.generateType( "DESIGN_MEDIA_OBJECTS_RESPONSE", "RECEIVED" ),
    },
    replaceMediaMode: {
        ended: actionCreator.generateType( "REPLACE_MEDIA_MODE", "ENDED" ),
    },
    loadedAllRecentPosts: {
        changed: actionCreator.generateType( "LOADED_ALL_RECENT_POSTS", "CHANGED" ),
    },
    designLoadProgress: {
        started: actionCreator.generateType( "DESIGN_LOAD_PROGRESS", "STARTED" ),
        cancelled: actionCreator.generateType( "DESIGN_LOAD_PROGRESS", "CANCELLED" ),
        completed: actionCreator.generateType( "DESIGN_LOAD_PROGRESS", "COMPLETED" ),
    },
    premiumEpidemicSoundPreview: {
        set: actionCreator.generateType( "PREMIUM_EPIDEMIC_SOUND_PREVIEW", "SET" ),
        clear: actionCreator.generateType( "PREMIUM_EPIDEMIC_SOUND_PREVIEW", "CLEAR" ),
    },
    templatePreviewAnimation: {
        completed: actionCreator.generateType( "TEMPLATE_PREVIEW_ANIMATION", "COMPLETED" ),
        started: actionCreator.generateType( "TEMPLATE_PREVIEW_ANIMATION", "STARTED" ),
    },
    templatePreviewApplyBrandStylesSwitchToggled: {
        set: actionCreator.generateType( "TEMPLATE_PREVIEW_APPLY_BRAND_STYLES_SWITCH_TOGGLED", "SET" ),
    },
    socialCalendarDay: {
        selected: actionCreator.generateType( "SOCIAL_CALENDAR_DAY", "DAY_SELECTED" ),
    },
    businessTypeFilter: {
        set: actionCreator.generateType( "BUSINESS_TYPE_FILTER", "SET" ),
        cleared: actionCreator.generateType( "BUSINESS_TYPE_FILTER", "CLEARED" ),
    },
    businessTypeFilterShowAll: {
        set: actionCreator.generateType( "BUSINESS_TYPE_FILTER_SHOW_ALL", "SET" ),
    },
};

export interface ControlSwitchData
{
    controlId?: string;
    editorType: UIEditorType;
}

export const uiActions = {
    calendarDatePicked: createAction<Date>( uiActionsTypes.calendarDate.picked ),
    registerCanvasUpdater: createAction<CanvasUpdater>( uiActionsTypes.register.canvasUpdater ),
    controlEditorSwitched: createAction<ControlSwitchData>( uiActionsTypes.controlEditor.switched ),
    deselectAllControls: createAction( uiActionsTypes.controlEditor.deselectAllControls ),
    deselectAnimationControls: createAction( uiActionsTypes.controlEditor.deselectAnimationControls ),
    dismissCustomizeTip: createAction<string>( uiActionsTypes.customizeTips.dismiss ),
    registerCustomizeTipShown: createAction<number>( uiActionsTypes.customizeTips.registerShown ),
    mixModelRecordProgress: createAction<RecordAPIUpdate>( uiActionsTypes.mixModel.recordProgress ),
    mixModelRecordError: createAction<boolean>( uiActionsTypes.mixModel.recordError ),
    mixModelConvertVideoProgress: createAction<ConvertVideoAPIUpdate>( uiActionsTypes.mixModel.convertVideoProgress ),
    mixModelTrimVideoProgress: createAction<TrimVideoAPIUpdate>( uiActionsTypes.mixModel.trimVideoProgress ),
    designCatalogAspectRatioSet: createAction<AspectRatio>( uiActionsTypes.designCatalog.aspectRatioSet ),
    designCatalogCategorySelected: createAction<CatalogCategory>( uiActionsTypes.designCatalog.categorySelected ),
    updateBusinessInfo: createAction<Partial<UserBusiness>>( uiActionsTypes.businessInfo.update ),
    clearBusinessInfo: createAction( uiActionsTypes.businessInfo.clear ),
    clearBusinessInfoBrandSlideFontData: createAction( uiActionsTypes.businessInfo.clearBrandSlideFontData ),
    facebookPickerModalOpen: createAction<FacebookPickerDialogData>( uiActionsTypes.facebookPicker.modalOpened ),
    facebookPickerModalUpdateAccount: createAction<string>( uiActionsTypes.facebookPicker.modalUpdated ),
    facebookPickerModalClose: createAction( uiActionsTypes.facebookPicker.modalClosed ),
    facebookGroupAddRiplModalOpen: createAction<FacebookGroupAddRiplDialogData>( uiActionsTypes.facebookGroupAddRipl.modalOpened ),
    facebookGroupAddRiplModalClose: createAction( uiActionsTypes.facebookGroupAddRipl.modalClosed ),
    confirmFacebookModalOpen: createAction<ConfirmFacebookDialogData>( uiActionsTypes.confirmFacebookModal.modalOpened ),
    confirmFacebookModalClose: createAction( uiActionsTypes.confirmFacebookModal.modalClosed ),
    schedulePostModalOpen: createAction<SchedulePostDialogData>( uiActionsTypes.schedulePost.modalOpened ),
    schedulePostModalClose: createAction( uiActionsTypes.schedulePost.modalClosed ),
    updateTextFontAutocomplete: createAction<string>( uiActionsTypes.fontAutocomplete.updateText ),
    updateFontFontAutocomplete: createAction<FontData>( uiActionsTypes.fontAutocomplete.updateFont ),
    designCanvasRefreshed: createAction( uiActionsTypes.designCanvas.refreshed ),
    designCaptionCleared: createAction( uiActionsTypes.designCaption.cleared ),
    designCaptionUpdated: createAction<DesignContextualData[]>( uiActionsTypes.designCaption.updated ),
    designCaptionTextUpdated: createAction<DesignContextualData[]>( uiActionsTypes.designCaption.textUpdated ),
    designCaptionControlsSet: createAction<DesignContextualData[]>( uiActionsTypes.designCaption.controlsSet ),
    designSupportsExtraCaptions: createAction<boolean>( uiActionsTypes.designExtraCaptionSupport.setSupported ),
    stylizedTextChoicesSet: createAction<StylizedTextChoices>( uiActionsTypes.stylizedTextChoices.set ),
    designZoomUpdated: createAction<DesignContextualData[]>( uiActionsTypes.designZoom.updated ),
    designZoomControlsSet: createAction<DesignContextualData[]>( uiActionsTypes.designZoom.controlsSet ),
    designSlideIndexDataUpdated: createAction<SlideIndexData>( uiActionsTypes.designSlide.indexDataUpdated ),
    cardProcessingSpinnerEnabled: createAction<boolean>( uiActionsTypes.cardProcessingSpinner.enabled ),
    updateEmailFormEmail: createAction<string>( uiActionsTypes.emailForm.emailUpdate ),
    updateEmailFormPassword: createAction<string>( uiActionsTypes.emailForm.passwordUpdate ),
    updateMusicCatalogSelection: createAction<Music>( uiActionsTypes.musicCatalog.trackSelected ),
    setEmailFormErrors: createAction<{ email?: string, password?: string, error?: string }>( uiActionsTypes.emailForm.setError ),
    setPartnerLoginError: createAction<string>( uiActionsTypes.partnerLogin.setError ),
    setThirdPartyLoginError: createAction<{ message: string, shouldShowError: boolean }>( uiActionsTypes.thirdPartyLogin.setError ),
    setResetFormErrors: createAction<ResetPasswordErrorResponse>( uiActionsTypes.resetForm.setError ),
    setResetFormProcessing: createAction<boolean>( uiActionsTypes.resetForm.setProcessing ),
    clearResetFormErrors: createAction( uiActionsTypes.resetForm.clearErrors ),
    updateFocusElement: createAction<string>( uiActionsTypes.controlEditor.focusElementUpdate ),
    scheduledActionSelected: createAction<ScheduledAction>( uiActionsTypes.scheduledAction.selected ),
    scheduledActionCleared: createAction( uiActionsTypes.scheduledAction.cleared ),
    recommendedPostSelected: createAction<Post>( uiActionsTypes.recommendedPost.selected ),
    recommendedPostCleared: createAction( uiActionsTypes.recommendedPost.cleared ),
    imageSelected: createAction<number>( uiActionsTypes.image.selected ),
    imageSelectionCleared: createAction( uiActionsTypes.image.cleared ),
    setRedirectUrl: createAction<string>( uiActionsTypes.redirectUrl.set ),
    setHomePageAlert: createAction<string>( uiActionsTypes.homePageAlert.set ),
    toggleIsCustomMusicUploading: createAction<boolean>( uiActionsTypes.isCustomMusicUploading.toggled ),
    setAudioPlayerMusicTrack: createAction<Music>( uiActionsTypes.music.audioPlayerMusicTrack ),
    clearAudioPlayerMusicTrack: createAction( uiActionsTypes.music.audioPlayerMusicTrackCleared ),
    setTeamMemberInviteEmail: createAction<string>( uiActionsTypes.teamMemberInvite.email ),
    videoTrimmerModalShowing: createAction<boolean>( uiActionsTypes.videoTrimmerModal.showing ),
    updateExternalMediaUploadStatus: createAction<{ [id: string]: boolean }>( uiActionsTypes.externalMediaUploadStatus.updated ),
    shouldShowDefaultStockMediaSearchResultsUpdated: createAction<boolean>( uiActionsTypes.shouldShowDefaultStockMediaSearchResults.update ),
    contentSearchSpinnerEnabled: createAction<boolean>( uiActionsTypes.contentSearchSpinner.enabled ),
    collectionSpinnerEnabled: createAction<boolean>( uiActionsTypes.collectionSpinner.enabled ),
    collectionPageSourceSet: createAction<string>( uiActionsTypes.collectionPageSource.set ),
    subscriptionInfoSpinnerEnabled: createAction<boolean>( uiActionsTypes.subscriptionInfoSpinner.enabled ),
    showUnscheduledPostsInScheduleTabUpdated: createAction<boolean>( uiActionsTypes.showUnscheduledPostsInScheduleTab.update ),
    switchSubscriptionSpinnerEnabled: createAction<boolean>( uiActionsTypes.switchSubscriptionSpinner.enabled ),
    trackProcessAsRunning: createAction<ProcessTrackingKey>( uiActionsTypes.processTracking.running ),
    trackProcessAsStopped: createAction<ProcessTrackingKey>( uiActionsTypes.processTracking.stopped ),
    trackProcessCollectionItemAsRunning: createAction<ProcessCollectionItem>( uiActionsTypes.processCollectionTracking.running ),
    trackProcessCollectionItemAsStopped: createAction<ProcessCollectionItem>( uiActionsTypes.processCollectionTracking.stopped ),
    updateCreateYourOwnBannerButtonVisibility: createAction<boolean>( uiActionsTypes.createYourOwnBannerButtonVisibility.update ),
    displayShowYouAroundTour: createAction<boolean>( uiActionsTypes.showYouAroundTourVisibility.update ),
    presentAfterShareDialogSet: createAction<boolean>( uiActionsTypes.presentAfterShareDialog.set ),
    hasSeenAfterShareDialogSet: createAction<boolean>( uiActionsTypes.hasSeenAfterShareDialog.set ),
    hasSeenRiplUserSurvey2023Set: createAction<boolean>( uiActionsTypes.hasSeenRiplUserSurvey2023.set ),
    hideAllCoachMarks: createAction( uiActionsTypes.coachMark.hideAll ),
    showCoachMark: createAction<CoachMarkNameKey>( uiActionsTypes.coachMark.show ),
    clearCoachMark: createAction<CoachMarkNameKey>( uiActionsTypes.coachMark.clear ),
    paymentMethodErrorSet: createAction<string>( uiActionsTypes.paymentMethodError.set ),
    paymentFormErrorSet: createAction<PaymentFormError>( uiActionsTypes.paymentFormError.set ),
    paymentFormErrorClear: createAction( uiActionsTypes.paymentFormError.clear ),
    facebookAdsSubmitErrorSet: createAction<string>( uiActionsTypes.facebookAdsSubmitError.set ),
    selectedDesignElementSet: createAction<DesignElement>( uiActionsTypes.selectedDesignElement.set ),
    designElementPropertiesReceived: createAction<DesignElementProperties>( uiActionsTypes.designElementProperties.received ),
    slideTransitionPropertiesReceived: createAction<DesignElementProperties>( uiActionsTypes.slideTransitionProperties.received ),
    backgroundAnimationPropertiesReceived: createAction<DesignElementProperties>( uiActionsTypes.backgroundAnimationProperties.received ),
    designPresetsReceived: createAction<DesignPresets>( uiActionsTypes.designPresets.received ),
    textAnimationChoicesSet: createAction<TextAnimationChoices>( uiActionsTypes.textAnimationChoices.set ),
    globalTextAnimationChoicesSet: createAction<TextAnimationChoices>( uiActionsTypes.globalTextAnimationChoices.set ),
    slideTransitionChoicesSet: createAction<SlideTransitionChoices>( uiActionsTypes.slideTransitionChoices.set ),
    backgroundAnimationChoicesSet: createAction<BackgroundAnimationChoices>( uiActionsTypes.backgroundAnimationChoices.set ),
    slideSummarySet: createAction<SlideSummaryResponse>( uiActionsTypes.slideSummary.set ),
    slideSummaryClear: createAction( uiActionsTypes.slideSummary.clear ),
    setTextPropertiesSubPanelCaptionData: createAction<TextPickerCaptionData>( uiActionsTypes.textPropertiesSubPanelCaptionData.set ),
    clearTextPropertiesSubPanelCaptionData: createAction( uiActionsTypes.textPropertiesSubPanelCaptionData.clear ),
    facebookAdMobileWebViewOpened: createAction<boolean>( uiActionsTypes.facebookAdMobileWebView.opened ),
    manageBusinessLogosSelectionSet: createAction<BrandLogo[]>( uiActionsTypes.manageBusinessLogos.setSelected ),
    manageBusinessLogosSelectionClear: createAction( uiActionsTypes.manageBusinessLogos.clearSelected ),
    setIsAcceptTeamInviteFlow: createAction<boolean>( uiActionsTypes.isAcceptTeamInviteFlow.set ),
    designMediaObjectsResponseReceived: createAction<DesignMediaObject>( uiActionsTypes.designMediaObjectsResponse.received ),
    replaceMediaModeEnded: createAction( uiActionsTypes.replaceMediaMode.ended ),
    loadedAllRecentPostsChanged: createAction<boolean>( uiActionsTypes.loadedAllRecentPosts.changed ),
    updateDesignLoadProgressStarted: createAction<string>( uiActionsTypes.designLoadProgress.started ),
    updateDesignLoadProgressCancelled: createAction<string>( uiActionsTypes.designLoadProgress.cancelled ),
    updateDesignLoadProgressCompleted: createAction<string>( uiActionsTypes.designLoadProgress.completed ),
    setPremiumEpidemicSoundPreview: createAction<EpidemicSoundTrack>( uiActionsTypes.premiumEpidemicSoundPreview.set ),
    clearPremiumEpidemicSoundPreview: createAction( uiActionsTypes.premiumEpidemicSoundPreview.clear ),
    templatePreviewAnimationStarted: createAction( uiActionsTypes.templatePreviewAnimation.started ),
    templatePreviewAnimationCompleted: createAction( uiActionsTypes.templatePreviewAnimation.completed ),
    setTemplatePreviewApplyBrandStylesSwitchToggled: createAction( uiActionsTypes.templatePreviewApplyBrandStylesSwitchToggled.set ),
    socialCalendarDayClicked: createAction<string>( uiActionsTypes.socialCalendarDay.selected ),
    updateBusinessTypeFilter: createAction<BusinessType>( uiActionsTypes.businessTypeFilter.set ),
    clearBusinessTypeFilter: createAction( uiActionsTypes.businessTypeFilter.cleared ),
    setBusinessTypeFilterShowAll: createAction<boolean>( uiActionsTypes.businessTypeFilterShowAll.set ),
};
