import * as React from "react";
import { CollectionData } from "../../_types";
import { map } from "lodash";
import CollectionRowContainer from "./containers/CollectionRow.container";
import RecommendedPostsContainer from "../containers/RecommendedPostsRow.container";

export interface HomePostRowsPanelProps
{
    collectionRows: CollectionData[];
    isCollabraUser: boolean;
}

export interface HomePostRowsPanelDispatchProps
{
    loadCollectionRows: () => void;
}

export class HomePostRowsPanel extends React.PureComponent<HomePostRowsPanelProps & HomePostRowsPanelDispatchProps>
{
    public componentDidMount()
    {
        this.props.loadCollectionRows();
    }

    public render()
    {
        return (
            <div className="homePostRowsPanel">
                {map( this.props.collectionRows, ( collection, index ) =>
                {
                    return <CollectionRowContainer key={collection.id} collection={collection} collectionRowIndex={index}/>;
                } )}
                {this.shouldLoadAndShowWeeklyRecommendations() && <RecommendedPostsContainer/>}
            </div>
        );
    }

    private shouldLoadAndShowWeeklyRecommendations = () =>
    {
        return !this.props.isCollabraUser && this.props.collectionRows.length > 0;
    }
}
