import * as React from "react";
import * as TrashIconUnselected from "../../assets/img/trashIcon.svg";

export function TrashIcon(): JSX.Element
{
    return (
        <div className="selectableIcon">
            <img src={TrashIconUnselected} alt="Trash Icon"/>
        </div>
    );
}
