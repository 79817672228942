import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AudioPlayer, AudioPlayerDispatchProps, AudioPlayerProps } from "../AudioPlayer";
import { uiActions } from "../../actions";
import { getCurrentMusicTrack } from "../../ducks/ui";
import { StoreState } from "../../_types";
import { isStaticOutputMode } from "../../ducks/mixModel";

const mapStateToProps = ( storeState: StoreState ): AudioPlayerProps =>
{
    return {
        currentMusic: getCurrentMusicTrack( storeState ),
        isAnimatedOutputMode: !isStaticOutputMode( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): AudioPlayerDispatchProps =>
    {
        return {
            clearAudioPlayerMusicTrack: () =>
            {
                dispatch( uiActions.clearAudioPlayerMusicTrack() );
            },
        };
    }
;

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( AudioPlayer );
