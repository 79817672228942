import * as React from "react";
import EditShareOutputList from "./containers/EditShareOutputList.container";
import { SOCIAL_ACCOUNT_CONNECTED_SOURCE_SHARE_POST } from "../helpers";

export class SocialAccountSettingsDialog extends React.PureComponent<{}>
{

    public render()
    {
        return (
            <div className="editSocialAccountListContainer socialAccountSettingsDialog">
                <EditShareOutputList trackingSource={SOCIAL_ACCOUNT_CONNECTED_SOURCE_SHARE_POST}/>
            </div>
        );
    }
}
