import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../_types";
import { getMostEngagedPostsSorted } from "../../../ducks/mostEngagedPost";
import { postServices, userServices } from "../../../services";
import { EngagementsTab, EngagementsTabDispatchProps, EngagementsTabProps } from "../EngagementsTab";

const mapStateToProps = ( storeState: StoreState ): EngagementsTabProps =>
{
    return {
        postData: getMostEngagedPostsSorted( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): EngagementsTabDispatchProps =>
{
    return {
        loadData: async () =>
        {
            try
            {
                await dispatch( userServices.loadMe() );
                dispatch( postServices.loadMostEngagedPostsSorted() );
            }
            catch (error)
            {
                // Ignore errors here for now
            }
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( EngagementsTab );
