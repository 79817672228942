import * as React from "react";
import { InspireBackgroundOverlay } from "./InspireBackgroundOverlay";
import { CircularProgressWithText } from "./CircularProgressWithText";
import { partnerServices } from "../services/partner.services";
import { store } from "../store";
import { PARTNER_USER_LOGIN_ACTION_CREATE_POST } from "../helpers";

export interface PartnerUserLoginPageProps
{
    hasLoadedMeThisSession: boolean;
    isUserPersonallySubscribed: boolean;
    hasLoginError: boolean;
}

export interface PartnerUserLoginPagePropsDispatchProps
{
    redirectToHome(): void;
    handleCreatePostAction( queryParams: string ): void;
}

export class PartnerUserLoginPage extends React.PureComponent<PartnerUserLoginPageProps & PartnerUserLoginPagePropsDispatchProps>
{
    public componentDidMount()
    {
        if ( partnerServices.isValidLoginUrl( window.location ) )
        {
            const token = partnerServices.getTokenFromQueryParams( window.location.search );
            store.dispatch( partnerServices.login( token ) );
        }
    }

    public componentDidUpdate()
    {
        this.handleRedirectScenarios();
    }

    public render()
    {
        return (
            <div className="partnerUserLoginPage">
                <InspireBackgroundOverlay/>
                <div className="content">
                    {this.renderContent()}
                </div>
            </div>
        );
    }

    private renderContent = () =>
    {
        if ( !this.props.hasLoadedMeThisSession )
        {
            if ( !this.props.hasLoginError )
            {
                return this.renderSpinner();
            }
            else
            {
                return (
                    <div className="statusWrap">
                        Unable to log you in
                    </div>
                );
            }
        }

        return (
            <></>
        );
    }

    private renderSpinner = () =>
    {
        return (
            <div className="statusWrap">
                <CircularProgressWithText
                    positionTextAbove={true}
                    progressText="Checking your account..."/>
            </div>
        );
    }

    private handleRedirectScenarios = () =>
    {
        if ( this.props.hasLoadedMeThisSession )
        {
            const action = partnerServices.getActionFromQueryParams( window.location.search );

            if ( this.props.isUserPersonallySubscribed )
            {
                if ( action === PARTNER_USER_LOGIN_ACTION_CREATE_POST )
                {
                    this.props.handleCreatePostAction( window.location.search );
                }
                else
                {
                    this.props.redirectToHome();
                }
            }
            else
            {
                // TODO: load_me completed but user is not subscribed.
            }
        }
    }
}
