import * as React from "react";
import { Music } from "../../../_types";
import { map } from "lodash";
import { EpidemicSoundTrack } from "../../../_types/api";
import EpidemicSoundTrackItemContainer from "./containers/EpidemicSoundTrackItem.container";

export interface EpidemicAudioTracksListProps
{
    musicList: EpidemicSoundTrack[];
}

export interface EpidemicAudioTracksListDispatchProps
{
    handleMusicClick: ( music?: Music ) => void;
}

export function EpidemicAudioTracksList( props: EpidemicAudioTracksListProps & EpidemicAudioTracksListDispatchProps )
{
    return (<>
        {map( props.musicList, ( epidemicSoundTrack ) => <EpidemicSoundTrackItemContainer key={epidemicSoundTrack.epidemic_id}
                                                                                          epidemicSoundTrack={epidemicSoundTrack}
                                                                                          handleMusicClick={props.handleMusicClick}/> )}
    </>);
}
