import * as React from "react";
import { ColorValue, UserBusiness } from "../../_types";
import BrandColorPickerCommonContainer from "./containers/BrandColorPickerCommon.container";

export interface CreativeTabColorPickerDispatchProps
{
    handleClearColor: ( userBusinessKey: keyof UserBusiness ) => void;
    onColorPickedSuccess: ( userBusinessKey: keyof UserBusiness, startValue: ColorValue ) => void;
}

export class CreativeTabColorPicker extends React.PureComponent<CreativeTabColorPickerDispatchProps>
{
    public render()
    {
        return (<BrandColorPickerCommonContainer handleClearColor={this.props.handleClearColor}
                                                 onColorPickedSuccess={this.props.onColorPickedSuccess}/>);
    }
}
