import * as React from "react";

export const Chevron: React.FC<{}> = () =>
{
    return (
        <svg className="chevron-svg" width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.999999 1L5 5L9 1" stroke="#3A3A3A" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    );
};
