import * as React from "react";
import { Button } from "react-md";
import classNames from "classnames";

export interface UsersTabInviteButtonProps
{
    disabled: boolean;
}

export interface UsersTabInviteButtonDispatchProps
{
    onClick(): ( event: React.MouseEvent<HTMLElement, MouseEvent> ) => void;
}

export class UsersTabInviteButton extends React.PureComponent<UsersTabInviteButtonProps & UsersTabInviteButtonDispatchProps>
{
    public render()
    {
        return (
            <Button primary flat swapTheming
                    className={classNames( "addUsersButton",
                        {
                            sendDisabled: this.props.disabled,
                        } )}
                    tooltipLabel={this.props.disabled && this.getToolTip()}
                    tooltipPosition={"top"}
                    tooltipClassName="tooltip"
                    inkDisabled={this.props.disabled}
                    onClick={this.getOnClick()}>Invite team members</Button>
        );
    }

    private getToolTip(): JSX.Element
    {
        const tooltipDiv = <div>You have reached the maximum number of team members.</div>;
        return this.props.disabled ? tooltipDiv : null;
    }

    private getOnClick(): ( event: React.MouseEvent<HTMLElement, MouseEvent> ) => void
    {
        return !this.props.disabled ? this.props.onClick : null;
    }
}
