import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { store } from "../../store";
import { uiActions } from "../../actions";
import { ConfirmFacebookDialog, ConfirmFacebookDialogDispatchProps, ConfirmFacebookDialogProps } from "../ConfirmFacebookDialog";
import { getConfirmFacebookDialogData } from "../../ducks/ui";
import { modalServices } from "../../services";
import { HowToLogoutOfFacebookDialog } from "../HowToLogoutOfFacebookDialog";
import { StoreState } from "../../_types";
import { LightboxDialogIdentifierForKey } from "../../helpers";

interface ConfirmFacebookDialogContainerProps
{
    buttonNameForInstructions: string;
}

const mapStateToProps = ( storeState: StoreState, ownProps: ConfirmFacebookDialogContainerProps ): ConfirmFacebookDialogProps =>
{
    return {
        ...ownProps,
        confirmFacebookDialogData: getConfirmFacebookDialogData( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): ConfirmFacebookDialogDispatchProps =>
{
    return {
        handleCloseModal: () =>
        {
            dispatch( uiActions.confirmFacebookModalClose() );
        },
        handleConfirm: () =>
        {
            const storeState = store.getState();
            const theConfirmFacebookDialogData = getConfirmFacebookDialogData( storeState );
            if ( theConfirmFacebookDialogData && theConfirmFacebookDialogData.onConfirm )
            {
                theConfirmFacebookDialogData.onConfirm();
            }
            dispatch( uiActions.confirmFacebookModalClose() );
        },
        handleSwitch: ( buttonNameForInstructions: string ) =>
        {
            window.FB.logout();
            dispatch( uiActions.confirmFacebookModalClose() );

            dispatch( modalServices.openLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.HOW_TO_SWITCH_FACEBOOK_USER,
                hideCancel: true,
                showCancelX: true,
                width: 500,
                title: "How to switch Facebook user",
                subtitle: "In order to continue with a different Facebook account, please log out of Facebook from your browser:",
                content: () => <HowToLogoutOfFacebookDialog buttonNameForInstructions={buttonNameForInstructions}/>,
            } ) );

        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( ConfirmFacebookDialog );
