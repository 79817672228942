import { connect } from "react-redux";
import { Dispatch } from "redux";
import { getEmailForm } from "../../ducks/ui";
import {
    BusinessNameEmailPasswordInputPanel,
    BusinessNameEmailPasswordInputPanelDispatchProps,
    BusinessNameEmailPasswordInputPanelProps,
} from "../BusinessNameEmailPasswordInputPanel";
import { uiActions } from "../../actions";
import { StoreState } from "../../_types";

interface BusinessNameEmailPasswordInputPanelContainerProps
{
    onContinueClicked: ( emailAddress: string, password: string ) => void;
    onForgotLogin?: () => void;
    handleBackClicked?: () => void;
    hidePassword?: boolean;
    primaryButtonText?: string;
}

const mapStateToProps = ( state: StoreState,
                          ownProps: BusinessNameEmailPasswordInputPanelContainerProps ): BusinessNameEmailPasswordInputPanelProps =>
{
    return {
        ...getEmailForm( state ),
        ...ownProps,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): BusinessNameEmailPasswordInputPanelDispatchProps =>
{
    return {
        handleEmailUpdate: ( value: string ) =>
        {
            dispatch( uiActions.updateEmailFormEmail( value ) );
        },
        handlePasswordUpdate: ( value: string ) =>
        {
            dispatch( uiActions.updateEmailFormPassword( value ) );
        },
        handleEmailError: ( value: string ) =>
        {
            const emailError = { error: value };
            dispatch( uiActions.setEmailFormErrors( emailError ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( BusinessNameEmailPasswordInputPanel );
