import * as React from "react";
import { getDesignFontName, webFontLoader } from "../helpers";
import { FontData } from "../_types";

interface FontControlProps
{
    value: FontData;
}

export class FontControl extends React.PureComponent<FontControlProps>
{
    public componentDidMount()
    {
        webFontLoader.loadFont( this.props.value );
    }

    public render()
    {
        return <div style={{ fontFamily: getDesignFontName( this.props.value ) }}>Aa</div>;
    }
}
