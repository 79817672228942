import { connect } from "react-redux";
import { Dispatch } from "redux";
import { mixModelActions, uiActions } from "../../../../actions";
import { isVideoAudioSelected } from "../../../../ducks/mixModel";
import { getCurrentMusic } from "../../../../ducks/musicCatalog";
import { eventTracker } from "../../../../helpers";
import { StoreState } from "../../../../_types";
import { NoAudioMusicItem, NoAudioMusicItemDispatchProps, NoAudioMusicItemProps } from "../NoAudioMusicItem";

const mapStateToProps = ( storeState: StoreState ): NoAudioMusicItemProps =>
{
    const videoAudioSelected = isVideoAudioSelected( storeState );
    const currentMusic = getCurrentMusic( storeState );
    const isNoAudioSelected = !(videoAudioSelected || !!currentMusic);

    return {
        isNoAudioSelected,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): NoAudioMusicItemDispatchProps =>
{
    return {
        onNoMusicSelected()
        {
            dispatch( mixModelActions.musicCleared() );
            eventTracker.logMusicItemSelected();
            dispatch( uiActions.updateMusicCatalogSelection( null ) );
            dispatch( uiActions.clearAudioPlayerMusicTrack() );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( NoAudioMusicItem );
