import { connect } from "react-redux";
import { Dispatch } from "redux";
import { getEditedBusinessInfo } from "../../../ducks/ui";
import { StoreState } from "../../../_types";
import { store } from "../../../store";
import {
    BRAND_SLIDE_FONT_ONE_CSS_URL_FIELD,
    BRAND_SLIDE_FONT_ONE_FIELD,
    BRAND_SLIDE_FONT_ONE_URL_FIELD,
    BRAND_SLIDE_FONT_TWO_CSS_URL_FIELD,
    BRAND_SLIDE_FONT_TWO_FIELD,
    BRAND_SLIDE_FONT_TWO_URL_FIELD,
    MIX_MODEL_BRAND_SLIDE_FONT_1,
    MIX_MODEL_BRAND_SLIDE_FONT_2,
    MIX_MODEL_BRAND_SLIDE_FONT_CSS_URL_1,
    MIX_MODEL_BRAND_SLIDE_FONT_CSS_URL_2,
} from "../../../_types/api";
import { getCurrentBusiness } from "../../../ducks/userBusiness";
import { userBusinessServices } from "../../../services";
import { mixModelActions, uiActions } from "../../../actions";
import { BrandSlideFontPicker, BrandSlideFontPickerDispatchProps, BrandSlideFontPickerProps } from "../BrandSlideFontPicker";

const mapStateToProps = ( storeState: StoreState ): BrandSlideFontPickerProps =>
{
    return {
        businessData: getEditedBusinessInfo( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): BrandSlideFontPickerDispatchProps =>
{
    return {
        onUpdatedFont: async () =>
        {
            const state = store.getState();
            await handleBrandFontUpdate( state, dispatch );
            dispatch( uiActions.clearBusinessInfoBrandSlideFontData() );
        },
    };
};

async function handleBrandFontUpdate( state, dispatch: Dispatch<StoreState> )
{
    await updateBrandSlideFont(
        state,
        dispatch,
        BRAND_SLIDE_FONT_ONE_FIELD,
        BRAND_SLIDE_FONT_ONE_URL_FIELD,
        BRAND_SLIDE_FONT_ONE_CSS_URL_FIELD,
        MIX_MODEL_BRAND_SLIDE_FONT_1,
        MIX_MODEL_BRAND_SLIDE_FONT_CSS_URL_1 );

    await updateBrandSlideFont(
        state,
        dispatch,
        BRAND_SLIDE_FONT_TWO_FIELD,
        BRAND_SLIDE_FONT_TWO_URL_FIELD,
        BRAND_SLIDE_FONT_TWO_CSS_URL_FIELD,
        MIX_MODEL_BRAND_SLIDE_FONT_2,
        MIX_MODEL_BRAND_SLIDE_FONT_CSS_URL_2 );
}

async function updateBrandSlideFont( state,
                                     dispatch: Dispatch<StoreState>,
                                     brandSlideFontField: string,
                                     brandSlideFontUrlField: string,
                                     brandSlideFontCssUrlField: string,
                                     brandSlideFontKey: string,
                                     brandSlideFontCssUrlKey: string )
{
    const currentBusiness = getCurrentBusiness( state );
    const editedBusiness = getEditedBusinessInfo( state );

    const initialFontName = currentBusiness[brandSlideFontField];
    const newFontName = editedBusiness[brandSlideFontField];
    const newFontUrl = editedBusiness[brandSlideFontUrlField];
    const newFontCssUrl = editedBusiness[brandSlideFontCssUrlField];

    if ( newFontName !== initialFontName )
    {
        const newFontDataForBusinessUpdate = {
            [brandSlideFontField]: newFontName,
            [brandSlideFontUrlField]: newFontUrl,
            [brandSlideFontCssUrlField]: newFontCssUrl,
        };

        const newTemplateInputBrandSlideData = { endCardData: { [brandSlideFontKey]: newFontName, [brandSlideFontCssUrlKey]: newFontCssUrl } };
        dispatch( mixModelActions.brandSlideDataUpdated( newTemplateInputBrandSlideData ) );
        await dispatch( userBusinessServices.update( newFontDataForBusinessUpdate ) );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( BrandSlideFontPicker );
