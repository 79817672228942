import { connect } from "react-redux";
import { Dispatch } from "redux";
import { SchedulePostDialog, SchedulePostDialogDispatchProps, SchedulePostDialogProps } from "../SchedulePostDialog";
import { shareModelActions, uiActions } from "../../actions";
import { getScheduledSendDatetime } from "../../ducks/shareModel";
import { getSchedulePostDialogData } from "../../ducks/ui";
import { SchedulePostDialogData, StoreState } from "../../_types";

const SCHEDULE_BUTTON_LABEL = "Schedule";

const mapStateToProps = ( storeState: StoreState ): SchedulePostDialogProps =>
{
    return {
        schedulePostDialogData: getSchedulePostDialogData( storeState ),
        scheduledSendDatetime: getScheduledSendDatetime( storeState ),
        scheduleButtonLabel: SCHEDULE_BUTTON_LABEL,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): SchedulePostDialogDispatchProps =>
{
    return {
        handleCloseModal: () =>
        {
            dispatch( uiActions.schedulePostModalClose() );
        },
        onSchedulePost: ( postId: number, sendDate: string ) =>
        {
            dispatch( shareModelActions.scheduledSendDateUpdated( sendDate ) );
            dispatch( uiActions.schedulePostModalClose() );
        },
        onUnschedulePost: ( schedulePostDialogData: SchedulePostDialogData ) =>
        {
            dispatch( shareModelActions.scheduledSendDateUpdated( undefined ) );
            dispatch( uiActions.schedulePostModalClose() );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( SchedulePostDialog );
