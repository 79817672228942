export const pinterestTag = {
    init,
    trackAddToCart,
    trackSignUp,
    pageView,
};

function init(pinterestId: string)
{
    // tslint:disable
    // @ts-ignore
    !function(e){if(!window.pintrk){window.pintrk = function () {
        window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
        n=window.pintrk;n.queue=[],n.version="3.0";var
        t=document.createElement("script");t.async=!0,t.src=e;var
        r=document.getElementsByTagName("script")[0];
        r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
    // tslint:enable
    window.pintrk("load", pinterestId);
}

function trackAddToCart(value: number, orderQuantity: number, currency: string )
{
    window.pintrk("track", "addtocart", {
        value,
        order_quantity: orderQuantity,
        currency,
    });
}

function trackSignUp()
{
    window.pintrk("track", "signup");
}

function pageView()
{
    window.pintrk("page");
}
