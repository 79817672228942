import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../_types";
import { ContentSearchRoute, ContentSearchRouteDispatchProps, ContentSearchRouteProps } from "../ContentSearchRoute";
import {
    contentSearchHasError,
    getContentSearchCurrentPageNumber,
    getContentSearchMatches,
    getContentSearchNextPage,
    getContentSearchTerm,
    getContentSearchTotalMatches,
} from "../../../ducks/contentSearch";
import { isContentSearchSpinnerEnabled } from "../../../ducks/ui";
import { contentSearchActions } from "../../../actions";
import { contentSearchServices } from "../../../services";
import { store } from "../../../store";

const mapStateToProps = ( storeState: StoreState ): ContentSearchRouteProps =>
{
    return {
        searchTerm: getContentSearchTerm( storeState ),
        searchResults: getContentSearchMatches( storeState ),
        isSearching: isContentSearchSpinnerEnabled( storeState ),
        hasError: contentSearchHasError( storeState ),
        hasNextPage: !!getContentSearchNextPage( storeState ),
        currentPageNumber: getContentSearchCurrentPageNumber( storeState ),
        totalCount: getContentSearchTotalMatches( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): ContentSearchRouteDispatchProps =>
{
    return {
        exitSearchMode: () =>
        {
            dispatch( contentSearchActions.searchModeExited() );
        },
        loadMore: ( page: number ) =>
        {
            const state = store.getState();
            dispatch( contentSearchServices.searchContent( getContentSearchTerm( state ), page ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( ContentSearchRoute );
