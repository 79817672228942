import { Dispatch } from "redux";
import { CATALOG_VERSION, requests } from "../helpers";
import { modalServices } from "./";
import { inspirationsActions } from "../actions";
import { StoreState } from "../_types";
import { InspirationsAPI, PostForBusinessTypeAPI } from "../_types/api";
import { format } from "date-fns";

export const inspirationsServices = {
    loadInspirationsForBusinessType,
};

const INSPIRATIONS_URL_PREFIX = "/inspirations";

const INSPIRATIONS_FOR_BUSINESS_TYPE_URL = INSPIRATIONS_URL_PREFIX + "/posts_for_business_type";

function loadInspirationsForBusinessType( businessTypeId: number, page: number )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( inspirationsActions.loadDataRequest );
        const date = format( new Date(), "YYYY-MM-DD" );
        const queryParams: PostForBusinessTypeAPI =
            {
                include_daily_example_posts: true,
                date,
                catalog_id: CATALOG_VERSION,
                page,
            };

        if ( businessTypeId )
        {
            queryParams.business_type_id = businessTypeId;
        }

        return requests.get<InspirationsAPI>( INSPIRATIONS_FOR_BUSINESS_TYPE_URL, queryParams ).then(
            ( data ) =>
            {
                dispatch( inspirationsActions.loadDataSuccess( data ) );
            },
            ( error: string ) =>
            {
                dispatch( inspirationsActions.loadDataFailure( error ) );
                dispatch( modalServices.openErrorDialogWithStandardFormat(
                    "Unable to load the inspiration posts.",
                    "Please try again later.",
                    error ) );
            },
        );
    };
}
