import { store } from "../store";
import { Dispatch } from "redux";
import { catalogActions, designsActions, mixModelActions } from "../actions";
import { CATALOG_VERSION, ENV_CONFIG, normalizeCatalogAPI, normalizeControlConfigs, requests, retry } from "../helpers";
import { getDesignTemplateURI, getSelectedDesign } from "../ducks/mixModel";
import { getCurrentBusiness } from "../ducks/userBusiness";
import { modalServices } from "./";
import { getDesignSwitchingDesignsForSelectedCategory, getDesignTrayIds, numberOfDesignsInLocalStore } from "../ducks/catalog";
import { AspectRatio, Catalog, Design, StoreState } from "../_types";
import { AssetControlConfig, CatalogAPIData, ControlsConfig, ControlsConfigAPI, IControlConfig } from "../_types/api";
import { concat, filter, forEach, head, uniq } from "lodash";
import { getDevMode } from "../ducks/user";
import { getDesignFramesAsAssetChoices } from "../ducks/dynamicDesignData";

export const catalogServices = {
    loadCatalog,
    selectDesign,
    loadDesignControlData,
    reloadSelectedDesignControls,
    loadDesignControlsByCategory,
    designIdsToUpdate,
};

const CATALOGS_URL_PREFIX = "/catalogs";

const CATALOG_DESIGNS_URL = `${CATALOGS_URL_PREFIX}/${CATALOG_VERSION}/designs`;

const MAX_CATALOG_LOAD_TRIES = 3;

function loadCatalog()
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        const load = () => requests.get<CatalogAPIData>( CATALOG_DESIGNS_URL );

        try
        {
            const data = await retry( MAX_CATALOG_LOAD_TRIES, load );
            const normalizedData = normalizeCatalogAPI( data );
            dispatch( catalogActions.loadCatalogSuccess( normalizedData ) );
            const catalog = normalizedData.entities.catalogs[normalizedData.result];
            await dispatch( autoloadDesignControls( catalog, normalizedData.entities.designs ) );
        }
        catch (error)
        {
            dispatch( catalogActions.loadCatalogFailure( error ) );

            if ( !areDesignsInLocalStore() )
            {
                dispatch( modalServices.openErrorDialogWithStandardFormat(
                    "Unable to load catalog.",
                    "Please reload the application again." ) );
            }
        }
    };
}

function areDesignsInLocalStore()
{
    return numberOfDesignsInLocalStore( store.getState() ) > 0;
}

function autoloadDesignControls( catalog: Catalog, designLookup: { [id: string]: Design } )
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        const designIds = designIdsToUpdate( catalog, store.getState() );
        if ( !designIds )
        {
            return;
        }

        for ( const designId of designIds )
        {
            const design = designLookup[designId.toString()];
            if ( design )
            {
                await dispatch( loadDesignControls( design ) );
            }
            else
            {
                // TODO Report this error to Sentry
            }
        }
    };
}

function designIdsToUpdate( catalog: Catalog, state: StoreState )
{
    const designTrayIds = getDesignTrayIds( state );
    const { autoDownloadDesigns } = catalog;
    return uniq( concat( designTrayIds, autoDownloadDesigns ) );
}

function selectDesign( design: Design, aspectRatio: AspectRatio )
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        if ( !design )
        {
            return;
        }

        dispatch( catalogActions.addDesignToFrontOfTray( design.id ) );
        await dispatch( loadDesignControls( design ) );
        dispatch( mixModelActions.designSelected( { design, aspectRatio } ) );
    };
}

function reloadSelectedDesignControls( state: StoreState )
{
    const design = getSelectedDesign( state );
    return loadDesignControls( design );
}

function loadDesignControls( design: Design )
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        try
        {
            await loadDesignControlData( dispatch, design );
        }
        catch (error)
        {
            dispatch( designsActions.loadControlsFailure( error ) );
        }
    };
}

async function loadDesignControlData( dispatch: Dispatch<StoreState>, design: Design )
{
    const state = store.getState();
    const currentBusiness = getCurrentBusiness( state );

    dispatch( designsActions.loadControlsRequest( design.id ) );

    const designTemplateFolder = ENV_CONFIG.cdnBaseUrl + getDesignTemplateURI( design );
    const normalizedData = await getDesignControlData( state, designTemplateFolder );

    dispatch( designsActions.loadControlsSuccess( {
        currentBusiness,
        controls: normalizedData,
        designId: design.id.toString(),
    } ) );
}

async function getDesignControlData( state: StoreState, designTemplateFolder: string )
{
    const response = await getControlsConfig( state, designTemplateFolder );
    const data = await response.json() as ControlsConfigAPI[];
    const normalizedData = normalizeControlConfigs( data, designTemplateFolder );
    addSupplementalControlData( normalizedData, state );
    return normalizedData;
}

function addSupplementalControlData( normalizedData: ControlsConfig[], state: StoreState )
{
    const frameControlConfigToSupplement = head( filter( normalizedData[0].controls, ( controlConfig: IControlConfig ) =>
    {
        return controlConfig.supportsSupplementalChoices && controlConfig.id === "frame";
    } ) );

    if ( frameControlConfigToSupplement )
    {
        const mediaFrameChoices = getDesignFramesAsAssetChoices( state );
        const frameAssetControlConfig = frameControlConfigToSupplement as AssetControlConfig;

        frameAssetControlConfig.choices = concat( frameAssetControlConfig.choices, mediaFrameChoices );
    }
}

function loadDesignControlsByCategory()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const state = store.getState();

        const designs = getDesignSwitchingDesignsForSelectedCategory( state );
        forEach( designs, async ( design ) =>
        {
            dispatch( loadDesignControls( design ) );
        } );
    };
}

async function getControlsConfig( state: StoreState, designTemplateFolder: string )
{
    const url = designTemplateFolder + "/controlsConfig.json";

    const devMode = getDevMode( state );
    if ( devMode )
    {
        const devModeControlsConfigUrl = designTemplateFolder + "/controlsConfigDevMode.json";
        const devModeControlsConfigResponse = await fetch( devModeControlsConfigUrl );
        if ( devModeControlsConfigResponse.ok )
        {
            return devModeControlsConfigResponse;
        }
    }
    return await fetch( url );
}
