import { addDays, differenceInDays, distanceInWords, format } from "date-fns";
import { padStart, toString } from "lodash";
import { stringUtils } from "./";

export const formatDate = ( dateString, dateFormat? ) =>
{
    const now = new Date();
    return differenceInDays( now, dateString ) < 1 ?
           distanceInWords( now, dateString, { addSuffix: true } ) :
           format( dateString, dateFormat || "DD MMM" );
};

export const formatDifferenceInDays = ( startDateString, endDateString ): string =>
{
    const difference = differenceInDays( endDateString, startDateString );

    return `${difference} ${difference > 1 ? "days" : "day"}`;
};

export const getFallbackDate = ( post ) =>
{
    return post.scheduled_send_at || post.last_sent_at || post.created_at;
};

export const toMMSS = ( totalSeconds ) =>
{
    const minutes = Math.floor( totalSeconds / 60 );
    const seconds = padStart( toString( Math.floor( totalSeconds % 60 ) ), 2, "0" );
    return `${minutes}:${seconds}`;
};

export const isEUTimezone = ( timezoneOffsetInMinutes ) =>
{
    // UTC -1 to UTC +4
    const timezoneOffsetInHours = timezoneOffsetInMinutes / 60;
    return timezoneOffsetInHours >= -1 && timezoneOffsetInHours <= 4;
};

export const toMonthDayYearTimeZone = ( dateString ) =>
{
    return format( dateString, "MMMM Do, YYYY" );
};

export const getBeginningOfTodayAsUnixEpoch = (): DateEpoch =>
{
    return new Date().setHours( 0, 0, 0, 0 );
};

export const getBeginningOfToday = (): Date =>
{
    return new Date( getBeginningOfTodayAsUnixEpoch() );
};

export const isoDateStringRelativeToDateWithOffset = ( beginningDate: Date, daysOffset: number ): string =>
{
    const adjustedDate = addDays( beginningDate, daysOffset );
    return adjustedDate.toISOString();
};

export const getCurrentIsoDateTimeWithoutMilliseconds = (): string =>
{
    const currentIsoDateString = new Date().toISOString();
    return stringUtils.removeMillisecondsFromISODateString( currentIsoDateString );
};

export const clearTimeParts = ( date: Date ) =>
{
    date.setHours( 0, 0, 0, 0 );
    return date;
};

export const convertDateToYYYYMMDD = (date: Date): string =>
{
    let month = "" + (date.getMonth() + 1);
    let day = "" + date.getDate();
    const year = date.getFullYear();

    if ( month.length < 2 )
    {
        month = "0" + month;
    }
    if ( day.length < 2 )
    {
        day = "0" + day;
    }
    return [year, month, day].join( "-" );
};

export const getTodayYYYYMMDD = (): string =>
{
    return convertDateToYYYYMMDD( new Date() );
};

export const convertYYYYMMDDtoDate = (dateString: string)  =>
{
    const [year, month, day] = dateString.split( "-" );
    return new Date(  month + "/" + day + "/" + year);
};
