import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../_types";
import SubscriptionOnHoldLockoutMessage, {
    SubscriptionOnHoldLockoutMessageDispatchProps,
    SubscriptionOnHoldLockoutMessageProps,
} from "../SubscriptionOnHoldLockoutMessage";
import { userServices } from "../../services";
import { isSubscriptionOnHold } from "../../ducks/user";
import { store } from "../../store";
import { history, HOMEPAGE_URL } from "../../helpers";
import { isFetchingMeData } from "../../ducks/ui";

const mapStateToProps = ( storeState: StoreState ): SubscriptionOnHoldLockoutMessageProps =>
{
    return {
        isRefreshingData: isFetchingMeData( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): SubscriptionOnHoldLockoutMessageDispatchProps =>
{
    return {
        refreshSubscriptionData: async () =>
        {
            try
            {
                await dispatch( userServices.loadMe() );
                if ( !isSubscriptionOnHold( store.getState() ) )
                {
                    history.push( HOMEPAGE_URL );
                }
            }
            catch (error)
            {
                // TODO: handle this error properly
            }
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( SubscriptionOnHoldLockoutMessage );
