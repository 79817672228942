import { createAction } from "redux-actions";
import { ActionCreator } from "../helpers";

const actionCreator = new ActionCreator( "ENGAGEMENTS" );

const engagementsActionsTypes = {
    weeklyBusiness: actionCreator.generateTypes( "WEEKLY_BUSINESS" ),
};

export const engagementsActions = {
    weeklyBusinessSuccess: createAction<WeeklyEngagementData>( engagementsActionsTypes.weeklyBusiness.success ),
    weeklyBusinessFailure: createAction<string>( engagementsActionsTypes.weeklyBusiness.failure ),
};
