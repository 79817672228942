import * as React from "react";
import * as TrashIcon from "../assets/img/trashIcon.svg";

export interface TrashButtonProps
{
    altText: string;
    onClick: () => void;
}

export class TrashButton extends React.PureComponent<TrashButtonProps>
{
    public render()
    {
        return (
            <img className="trashButton"
                 src={TrashIcon}
                 alt={this.props.altText}
                 onClick={this.handleOnClick}/>
        );
    }

    private handleOnClick = ( mouseEvent: React.MouseEvent<HTMLImageElement> ) =>
    {
        mouseEvent.stopPropagation();
        this.props.onClick();
    }
}
