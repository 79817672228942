import * as React from "react";
import { LogoToggle } from "./LogoToggle";
import BrandLogoUploadButtonContainer from "./containers/BrandLogoUploadButton.container";
import { BrandLogo, UserBusiness } from "../../_types";
import { map, size, take } from "lodash";
import RecentBrandLogoContainer from "./containers/RecentBrandLogo.container";
import Button from "react-md/lib/Buttons/Button";

export interface LogoSwitcherPanelProps
{
    showBrandLogo: boolean;
    showLogoToggleText: string;
    brandLogos: BrandLogo[];
    currentBusiness: UserBusiness;
    defaultNumLogosToShow?: number;
    source: string;
}

export interface LogoSwitcherPanelDispatchProps
{
    handleShowBrandLogo: ( enabled: boolean ) => void;
    refreshBrandLogos();
    onBrandLogoSwitched?();
}

interface LogoSwitcherPanelState
{
    seeAll: boolean;
}

export class LogoSwitcherPanel extends React.PureComponent<LogoSwitcherPanelProps & LogoSwitcherPanelDispatchProps, LogoSwitcherPanelState>
{
    constructor( props )
    {
        super( props );
        this.state = {
            seeAll: false,
        };
    }

    public componentDidMount()
    {
        this.props.refreshBrandLogos();
    }

    public componentDidUpdate( prevProps: Readonly<LogoSwitcherPanelProps & LogoSwitcherPanelDispatchProps>, prevState: Readonly<{}>, snapshot?: any )
    {
        if ( prevProps.currentBusiness && this.props.currentBusiness )
        {
            if ( (prevProps.currentBusiness.id !== this.props.currentBusiness.id) )
            {
                this.props.refreshBrandLogos();
            }
        }
    }

    public render()
    {
        let filteredBrandLogos: BrandLogo[] = this.props.brandLogos;
        if ( this.props.defaultNumLogosToShow )
        {
            filteredBrandLogos = take( filteredBrandLogos, this.getNumLogosToFilterTo() );
        }
        return (
            <div className="logoSwitcherPanel">
                <LogoToggle logoToggleText={this.props.showLogoToggleText}
                            showBrandLogo={this.props.showBrandLogo}
                            handleShowBrandLogo={this.props.handleShowBrandLogo}/>
                <div className="logoSwitcher">
                    <BrandLogoUploadButtonContainer source={this.props.source}/>
                    {
                        map( filteredBrandLogos, ( logo: BrandLogo ) =>
                            <RecentBrandLogoContainer key={logo.id}
                                                      brandLogo={logo}
                                                      onBrandLogoSwitched={this.props.onBrandLogoSwitched}/> )
                    }
                </div>

                {this.enableSeeAllButton() &&
                 <Button flat
                         swapTheming
                         onClick={this.handleSeeAllClicked}
                         className="seeAllButton"
                         id="see-all-recently-used-logos-button">
                     See all
                 </Button>}

                {this.enableSeeLessButton() &&
                 <Button flat
                         swapTheming
                         onClick={this.handleSeeLessClicked}
                         className="seeLessButton"
                         id="see-less-recently-used-logos-button">
                     See less
                 </Button>}
            </div>
        );
    }

    private enableSeeAllButton()
    {
        return !this.state.seeAll && this.props.defaultNumLogosToShow && this.doesBusinessHaveMoreLogosThanInitialToDisplay();
    }

    private enableSeeLessButton()
    {
        return this.state.seeAll && this.areLogosFilterable();
    }

    private areLogosFilterable()
    {
        return this.props.defaultNumLogosToShow && this.doesBusinessHaveMoreLogosThanInitialToDisplay();
    }

    private doesBusinessHaveMoreLogosThanInitialToDisplay()
    {
        return size( this.props.brandLogos ) > this.props.defaultNumLogosToShow;
    }

    private getNumLogosToFilterTo()
    {
        if ( this.state.seeAll )
        {
            return size( this.props.brandLogos );
        }
        else
        {
            return this.props.defaultNumLogosToShow;
        }
    }

    private handleSeeAllClicked = () =>
    {
        this.setState( { seeAll: true } );
    }

    private handleSeeLessClicked = () =>
    {
        this.setState( { seeAll: false } );
    }
}
