import * as React from "react";
import AddBusinessNameContainer from "../settingsPages/containers/AddBusinessName.container";
import { UserBusiness } from "../../_types";
import ChangeBusinessTypeContainer from "../settingsPages/containers/ChangeBusinessType.container";
import DeleteCurrentBrandButton from "../settingsPages/containers/DeleteCurrentBrandButton.container";
import ExtrasToggles from "../settingsPages/containers/ExtrasToggles.container";
import {
    BRAND_SETTINGS_SOCIAL_NETWORK_SETTINGS_ANCHOR_ID,
    scrollElementToTopOfViewportWithOffset,
    SOCIAL_ACCOUNT_CONNECTED_SOURCE_UPDATE_BUSINESS,
} from "../../helpers";
import EditShareOutputList from "../containers/EditShareOutputList.container";
import FacebookGroupAddRiplDialog from "../containers/FacebookGroupAddRiplDialog.container";
import FacebookPickerDialog from "../containers/FacebookPickerDialog.container";
import ConfirmFacebookDialog from "../containers/ConfirmFacebookDialog.container";
import BrandTabNoItemsContainer from "./containers/BrandTabNoItemsContainer";
import { store } from "../../store";
import { uiActions } from "../../actions";
import { nth, split } from "lodash";

export interface SettingsTabProps
{
    currentBusiness: UserBusiness;
    editedBusinessInfo: Partial<UserBusiness>;
    canDeleteCurrentBusiness: boolean;
}

export class SettingsTab extends React.PureComponent<SettingsTabProps>
{
    private socialNetworksSectionRef;

    constructor( props )
    {
        super( props );
        this.socialNetworksSectionRef = React.createRef();
    }

    public componentDidMount()
    {
        const dispatch = store.dispatch;
        dispatch( uiActions.confirmFacebookModalClose() );

        const scrollToAnchorId = nth( split( window.location.hash, "#" ), 1 );
        if ( scrollToAnchorId === BRAND_SETTINGS_SOCIAL_NETWORK_SETTINGS_ANCHOR_ID )
        {
            const element = this.socialNetworksSectionRef.current;
            if ( element )
            {
                scrollElementToTopOfViewportWithOffset( element );
            }
        }
    }

    public componentWillUnmount()
    {
        const dispatch = store.dispatch;
        dispatch( uiActions.confirmFacebookModalClose() );
    }

    public render()
    {
        return (
            <div className="subNavContent brandTabContent">
                {this.createContent()}
            </div>
        );
    }

    public createContent()
    {
        const { editedBusinessInfo } = this.props;

        if ( !this.props.currentBusiness )
        {
            return (
                <div className="subNavInnerWrap centerJustify">
                    <BrandTabNoItemsContainer/>
                </div>
            );
        }
        return (
            <div className="subNavInnerWrap">
                <div className="brandInformationSection">
                    <h2 className="subNavSectionH2">Brand information</h2>
                    <AddBusinessNameContainer {...editedBusinessInfo} />
                    <ChangeBusinessTypeContainer/>
                    {this.props.canDeleteCurrentBusiness && <DeleteCurrentBrandButton/>}
                </div>

                <div ref={this.socialNetworksSectionRef} className="socialAccountsSection">
                    <h2 className="subNavSectionH2">Social accounts</h2>
                    <div className="editSocialAccountListContainer">
                        <EditShareOutputList trackingSource={SOCIAL_ACCOUNT_CONNECTED_SOURCE_UPDATE_BUSINESS}/>
                        <FacebookGroupAddRiplDialog/>
                        <FacebookPickerDialog/>
                        <ConfirmFacebookDialog buttonNameForInstructions="Connect"/>
                    </div>
                </div>

                <div className="extrasSection">
                    <h2 className="subNavSectionH2">Extras</h2>
                    <ExtrasToggles/>
                </div>
            </div>
        );
    }
}
