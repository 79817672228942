import {
    AUD_CODE,
    CAD_CODE,
    CHF_CODE,
    EUR_CODE,
    GBP_CODE,
    INR_CODE,
    JPY_CODE,
    LOCALE_AUSTRALIA,
    LOCALE_CANADA,
    LOCALE_GERMANY,
    LOCALE_GREAT_BRITAIN,
    LOCALE_INDIA,
    LOCALE_JAPAN,
    LOCALE_MEXICO,
    LOCALE_NEW_ZEALAND,
    LOCALE_SWITZERLAND,
    LOCALE_US,
    MXN_CODE,
    NZD_CODE,
    USD_CODE,
} from "./constants";
import { get } from "lodash";

export function getLocaleForCurrency( currencyCode: CurrencyCode )
{
    const localeMap = {
        [AUD_CODE]: LOCALE_AUSTRALIA,
        [CAD_CODE]: LOCALE_CANADA,
        [CHF_CODE]: LOCALE_SWITZERLAND,
        [EUR_CODE]: LOCALE_GERMANY,
        [GBP_CODE]: LOCALE_GREAT_BRITAIN,
        [INR_CODE]: LOCALE_INDIA,
        [JPY_CODE]: LOCALE_JAPAN,
        [MXN_CODE]: LOCALE_MEXICO,
        [NZD_CODE]: LOCALE_NEW_ZEALAND,
        [USD_CODE]: LOCALE_US,
    };
    return get( localeMap, currencyCode ) || LOCALE_US;
}
