import * as React from "react";

export const PencilIcon: React.FC<{}> = () =>
{
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_895_1913)">
                <path d="M13.8337 3.89813L3.13028 14.6016C2.96622 14.7656 2.83825 14.9625 2.76278 15.1692L1.35513 18.9788C1.18122 19.448 1.552 19.8188 2.02122 19.6449L5.83075 18.2372C6.03747 18.1617 6.23435 18.0338 6.39841 17.8697L17.1018 7.16626L13.8337 3.89813Z" stroke="white" strokeMiterlimit="10"/>
                <path d="M13.8337 3.89814L17.1018 7.16626L19.3528 4.91532C19.7859 4.4822 19.8023 3.7997 19.3889 3.38626L17.6104 1.60782C17.197 1.19439 16.5145 1.21079 16.0814 1.64392L13.8304 3.89486L13.8337 3.89814Z" stroke="white" strokeMiterlimit="10"/>
                <path d="M2.95312 14.7787L6.22125 18.0469" stroke="white" strokeMiterlimit="10"/>
            </g>
            <defs>
                <clipPath id="clip0_895_1913">
                    <rect width="21" height="21" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};
