import * as React from "react";
import Button from "react-md/lib/Buttons/Button";
import * as lightningIcon from "../assets/img/lightning_icon.png";
import { DismissedTipBanners } from "../_types";

export interface CustomizeTipBannerProps
{
    newTitle: string;
    newMessage: string;
    dismissedTipBanners: DismissedTipBanners;
    newTipBannerType?: string;
}

export interface CustomizeTipBannerDispatchProps
{
    onDismissClicked: ( tipBannerType: string ) => void;
    onTipBannerShown: () => void;
}

interface CustomizeTipBannerState
{
    title: string;
    message: string;
    tipBannerType: string;
}

export class CustomizeTipBanner extends React.PureComponent<CustomizeTipBannerProps & CustomizeTipBannerDispatchProps, CustomizeTipBannerState>
{
    constructor( props )
    {
        super( props );
        this.state = { title: props.title, message: props.message, tipBannerType: props.tipBannerType };
    }

    // componentWillUpdate is deprecated. Should be re-implemented with componentDidUpdate.
    public componentWillUpdate( nextProps: Readonly<CustomizeTipBannerProps>, nextState: Readonly<CustomizeTipBannerState>, nextContext: any )
    {
        if ( nextProps.newTitle && nextProps.newTitle !== this.state.title
             && nextProps.newTipBannerType && this.shouldShow( nextProps.newTipBannerType ) )
        {
            this.setState( {
                title: nextProps.newTitle,
                message: nextProps.newMessage,
                tipBannerType: nextProps.newTipBannerType,
            } );
            this.props.onTipBannerShown();
        }
    }

    public render()
    {
        return this.shouldShow( this.state.tipBannerType ) ?
               (<div className="tipFrame">
                   <div>
                       <img className="icon" src={lightningIcon} alt="lightning"/>
                   </div>
                   <div className="tipText">
                       <div className="tipTitle">
                           {this.getTitle()}
                       </div>
                       <div className="tipMessage">
                           {this.getMessage()}
                       </div>
                   </div>
                   <Button icon
                           className="closeX tipCloseButton"
                           iconClassName="material-icons"
                           iconChildren="close"
                           onClick={this.handleDismiss}/>
               </div>) :
               null;
    }

    private handleDismiss = () =>
    {
        this.props.onDismissClicked( this.state.tipBannerType );
    }

    private shouldShow = ( tipBannerType: string ) =>
    {
        return tipBannerType && !this.props.dismissedTipBanners[tipBannerType];
    }

    private getTitle = () =>
    {
        return this.state.title;
    }

    private getMessage()
    {
        return this.state.message;
    }
}
