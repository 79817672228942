import { Music } from "../../../_types";
import EpidemicSoundTrackItemContainer from "./containers/EpidemicSoundTrackItem.container";
import { MusicSelectionItem } from "./MusicSelectionItem";
import * as React from "react";
import { EpidemicSoundTrack, MUSIC_TYPE_EPIDEMIC_SOUND } from "../../../_types/api";

interface LegacyAudioTracksItemProps
{
    isCurrentlyPlaying: boolean;
    music: Music;
}

interface LegacyAudioTracksItemDispatchProps
{
    isSelected: ( music?: Music ) => boolean;
    handleMusicClick: ( music?: Music ) => void;
}

export function LegacyAudioTrackItem( props: LegacyAudioTracksItemProps & LegacyAudioTracksItemDispatchProps )
{
    const { music } = props;

    if ( music.type === MUSIC_TYPE_EPIDEMIC_SOUND )
    {
        const epidemicSoundTrack = music.metadata as EpidemicSoundTrack;
        return <EpidemicSoundTrackItemContainer key={epidemicSoundTrack.epidemic_id}
                                                epidemicSoundTrack={epidemicSoundTrack}
                                                handleMusicClick={props.handleMusicClick}/>;
    }

    const selected = props.isSelected( music );

    if ( music.audio_url )
    {
        return (
            <MusicSelectionItem selected={selected}
                                isPlaying={props.isCurrentlyPlaying}
                                musicItem={music}
                                handleMusicClick={props.handleMusicClick}/>
        );
    }
    else
    {
        return null;
    }
}
