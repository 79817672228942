import { connect } from "react-redux";
import { StoreState } from "../../../_types";
import { hasBrandSlideData } from "../../../ducks/userBusiness";
import { BrandSlidePicker, BrandSlidePickerProps } from "../BrandSlidePicker";

const mapStateToProps = ( storeState: StoreState ): BrandSlidePickerProps =>
{
    return {
        hasBrandSlideData: hasBrandSlideData( storeState ),
    };
};

export default connect(
    mapStateToProps,
)( BrandSlidePicker );
