import { Action } from "redux-actions";
import { businessTypesSchema, ReducerCreator, updateLookupWithAction } from "../helpers";
import { businessTypeActions } from "../actions";
import { filter, map, sortBy } from "lodash";
import { createDefaultDataLookup } from "./dataLookup";
import { BusinessType, BusinessTypeState, StoreState } from "../_types";
import { find } from "tslint/lib/utils";

const defaultState: BusinessTypeState = {
    ...createDefaultDataLookup(),
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( businessTypeActions.loadSuccess, handleLoadSuccess );
export default reducerCreator.createReducer();

function handleLoadSuccess( state: BusinessTypeState, action: Action<NormalizrData> ): BusinessTypeState
{
    return updateLookupWithAction( businessTypesSchema, action, state );
}

export function getTypes( state: StoreState ): BusinessType[]
{
    const { ids, idToObj } = state.businessTypes;
    return sortBy( filter( map( ids, ( id ) => idToObj[id] ) ), ( type ) => type.rank );
}

export function getBusinessType( state: StoreState, typeId: number ): BusinessType
{
    return state.businessTypes.idToObj[typeId];
}

export function getBusinessTypeIdByName( state: StoreState, name: string ): number
{
    return find( getTypes( state ), ( businessType ) =>
    {
        if ( businessType.name === name )
        {
            return businessType.id;
        }
    } );
}
