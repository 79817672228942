import { connect } from "react-redux";
import { LoadingProgress, LoadingProgressProps } from "../LoadingProgress";
import { StoreState } from "../../_types";
import { getConvertVideoCombinedProgress } from "../../ducks/ui";

const mapStateToProps = ( state: StoreState ): LoadingProgressProps =>
{
    return {
        progress: getConvertVideoCombinedProgress( state ),
        message: "Loading Video",
    };
};

export default connect(
    mapStateToProps,
    {},
)( LoadingProgress );
