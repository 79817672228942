// From https://docs.sentry.io/clients/node/typescript/
// This allows TypeScript to detect our global value
import * as React from "react";
import * as ReactDOM from "react-dom";
import Root from "./components/Root";
import { history } from "./helpers";
import { persistor, store } from "./store";

declare global
{
    namespace NodeJS
    {
        interface Global
        {
            __rootdir__: string;
        }
    }
}

global.__rootdir__ = __dirname || process.cwd();

const rootEl = document.getElementById( "root" );

ReactDOM.render(
    <Root store={store} history={history} persistor={persistor}/>,
    rootEl,
);
