import { connect } from "react-redux";
import { StoreState } from "../../../_types";
import { BusinessTypeFilterRow, BusinessTypeFilterRowProps } from "../../BusinessTypeFilterRow";
import { getTypes } from "../../../ducks/businessTypes";
import { getBusinessTypeFilter, getBusinessTypeFilterShowAllFlag } from "../../../ducks/ui";
import { Dispatch } from "redux";
import { uiActions } from "../../../actions";
import { store } from "../../../store";

const mapStateToProps = ( state: StoreState ): BusinessTypeFilterRowProps => ({
    businessTypes: getTypes( state ),
    selectedBusinessType: getBusinessTypeFilter( state ),
    showAllBusinessTypes: getBusinessTypeFilterShowAllFlag( state ),
});

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ) => {
    return {
        handleShowAllClicked: () =>
        {
            const showAll = getBusinessTypeFilterShowAllFlag( store.getState() );
            dispatch( uiActions.setBusinessTypeFilterShowAll(!showAll) );
        },
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( BusinessTypeFilterRow );
