import { connect } from "react-redux";
import { Dispatch } from "redux";
import { getCustomizableCanvas, getTextPropertiesSubPanelCaptionData } from "../../../ducks/ui";
import { ColorObject, DesignElementProperty, StoreState } from "../../../_types";
import { store } from "../../../store";
import { getUserBusinessPrimaryBrandColor, getUserBusinessSecondaryBrandColor } from "../../../ducks/userBusiness";
import { ColorTextChoiceItem, ColorTextChoiceItemDispatchProps, ColorTextChoiceItemProps } from "../ColorTextChoiceItem";
import { DesignElementPropertyType } from "../../../_types/api";
import { ElementPropertyChangedData } from "../../../_types/canvasUpdaterTypes";

export interface ColorTextChoiceItemContainerProps
{
    designElementProperty: DesignElementProperty;
    defaultExpanded: boolean;
}

const mapStateToProps = ( storeState: StoreState, ownProps: ColorTextChoiceItemContainerProps ): ColorTextChoiceItemProps =>
{
    return {
        ...ownProps,
        captionData: getTextPropertiesSubPanelCaptionData( storeState ),
        primaryBrandColor: getUserBusinessPrimaryBrandColor( storeState ),
        secondaryBrandColor: getUserBusinessSecondaryBrandColor( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): ColorTextChoiceItemDispatchProps =>
{
    return {
        tellDesignToColorTextItem( captionId: string, type: DesignElementPropertyType, propertyId: string, value: ColorObject )
        {
            const state = store.getState();
            const canvas = getCustomizableCanvas( state );
            if ( canvas )
            {
                const payload: ElementPropertyChangedData = {
                    id: captionId,
                    type,
                    controlId: propertyId,
                    value,
                };
                canvas.tellDesignToChangeElementProperty( payload );
            }
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( ColorTextChoiceItem );
