import * as React from "react";
import { AssetPicker } from "./AssetPicker";
import { FontPicker } from "./editorControls/FontPicker";
import MusicPickerContainer from "./editorControls/musicPicker/containers/MusicPicker.container";
import AspectRatioContainer from "./editorControls/containers/AspectRatioPicker.container";
import { filter, head, map, sortBy } from "lodash";
import {
    AspectRatio,
    AssetChoice,
    ControlDataValue,
    EditorDataValue,
    EventData,
    FontData,
    TemplateControlData,
    UIEditorType,
    UserBusiness,
} from "../_types";
import { AssetControlConfig, FontControlConfig, IControlConfig, UserPickAssetControlConfig } from "../_types/api";
import DesignTrayContainer from "./editorControls/containers/DesignTray.container";
import { EDITOR_TYPES, eventTracker, generateFontDataForBrandFonts, generateFontDataForFontName, getFontDisplayName } from "../helpers";
import BrandSlidePickerContainer from "./editorControls/containers/BrandSlidePicker.container";
import { DesignControlLogoPicker } from "./editorControls/DesignControlLogoPicker";
import MediaControlContainer from "./containers/MediaControl.container";
import ColorControlPickerContainer from "./settingsPages/containers/ColorControlPicker.container";
import { BackgroundMediaPickerPanel } from "./settingsPages/BackgroundMediaPickerPanel";
import { getInitialAssetChoice } from "../helpers/assetControlHelper";
import FoundationPresetsContainer from "./editorControls/containers/FoundationPresets.container";
import TextPickerContainer from "./editorControls/textPicker/containers/TextPicker.container";

export interface ControlPickerProps
{
    value: EditorDataValue;
    editorType: UIEditorType;
    control?: IControlConfig;
    aspectRatio: AspectRatio;
    currentBusiness: UserBusiness;
    initialControlDataForCurrentSelectedDesign: TemplateControlData;
    isDesignLoading: boolean;
}

export interface ControlPickerDispatchProps
{
    onValueSelected( controlId: string, value: ControlDataValue );
    onAspectRatioSelected( aspectRatio: AspectRatio );
    onMoreFontsClick( controlId: string );
    onShowBrandSettingsDialog( source: string );
    handleEditFontClicked( font: FontData, controlId: string );
    handleClearFontClicked( font: FontData );
}

export class ControlPicker
    extends React.PureComponent<ControlPickerProps & ControlPickerDispatchProps>
{
    public render()
    {
        return (
            <div className="controlPicker">
                {this.createEditorPicker()}
            </div>
        );
    }

    private createEditorPicker = () =>
    {
        const { editorType } = this.props;

        switch ( editorType )
        {
            case EDITOR_TYPES.TEXT_CONTROL:
                return <TextPickerContainer/>;
            case EDITOR_TYPES.MEDIA_CONTROL:
                return <MediaControlContainer/>;
            case EDITOR_TYPES.AUDIO_CONTROL:
                return <MusicPickerContainer/>;
            case EDITOR_TYPES.CONTROL:
                return this.createControlPicker();
            case EDITOR_TYPES.ASPECT_RATIO_CONTROL:
                return <AspectRatioContainer/>;
            case EDITOR_TYPES.BRAND_SLIDE_CONTROL:
                return <BrandSlidePickerContainer/>;
            case EDITOR_TYPES.LOGO_CONTROL:
                return <DesignControlLogoPicker/>;
            case EDITOR_TYPES.TEMPLATES_CONTROL:
                return <DesignTrayContainer/>;
            case EDITOR_TYPES.FOUNDATION_PRESETS_CONTROL:
                return <FoundationPresetsContainer/>;
        }
    }

    private createControlPicker = () =>
    {
        const { control, value, currentBusiness } = this.props;

        if ( !control )
        {
            return;
        }

        switch ( control.type )
        {
            case EDITOR_TYPES.COLOR_CONTROL:
                return (
                    <ColorControlPickerContainer/>
                );
            case EDITOR_TYPES.FONT_CONTROL:
                const selectedFontData = value as FontData;
                const designFontData = this.generateFontDataForDesignFonts( control as FontControlConfig );
                const brandFonts = generateFontDataForBrandFonts( currentBusiness );
                return (
                    <div className="listPicker">
                        <FontPicker
                            selectedFont={selectedFontData}
                            designFonts={designFontData}
                            brandFonts={brandFonts}
                            controlId={control.id}
                            onFontSelected={this.onFontSelected}
                            onMoreClicked={this.onMoreClicked}
                            onEditFontClicked={this.onEditFontClicked}
                            onClearFontClicked={this.onClearFontClicked}/>
                    </div>
                );
            case EDITOR_TYPES.ASSET_CONTROL:
                const assetControl = control as AssetControlConfig;
                const assetArray = assetControl.choices;
                const initialAssetChoice: AssetChoice = getInitialAssetChoice( assetControl, this.props.initialControlDataForCurrentSelectedDesign );

                return <AssetPicker
                    assetName={control.title}
                    value={value as AssetChoice}
                    assetArray={assetArray}
                    initialAssetChoice={initialAssetChoice}
                    onAssetSelected={this.onAssetSelected}/>;
            case EDITOR_TYPES.MODEL_LINKED_PHOTO_CONTROL:
            case EDITOR_TYPES.MODEL_LINKED_TEXT_CONTROL:
                return <AssetPicker
                    assetName={control.title}
                    value={value as AssetChoice}
                    assetArray={(control as UserPickAssetControlConfig).choices}
                    onAssetSelected={this.onAssetSelected}/>;
            case EDITOR_TYPES.BACKGROUND_MEDIA_CONTROL:
                return (<BackgroundMediaPickerPanel/>);
        }
        return <div>{control.title} Picker</div>;
    }

    private onAssetSelected = ( asset: AssetChoice ) =>
    {
        this.props.onValueSelected( this.props.control.id, asset.jsonBlob );
    }

    private onEditFontClicked = ( font: FontData ) =>
    {
        this.props.handleEditFontClicked( font, this.props.control.id );
    }

    private onClearFontClicked = ( font: FontData ) =>
    {
        const selectedFontData = this.props.value as FontData;
        if ( selectedFontData && font.designFontName === selectedFontData.designFontName )
        {
            this.selectFirstValidFont();
        }
        this.props.handleClearFontClicked( font );
    }

    private selectFirstValidFont()
    {
        const designFontData = this.generateFontDataForDesignFonts( this.props.control as FontControlConfig );
        const sortedFontData = sortBy( designFontData, getFontDisplayName );
        const selectedFont = head( sortedFontData );
        this.onFontSelected( selectedFont );
    }

    private onMoreClicked = ( e: EventData ) =>
    {
        this.props.onMoreFontsClick( this.props.control.id );
    }

    private onFontSelected = ( selectedFont: FontData ) =>
    {
        this.props.onValueSelected( this.props.control.id, selectedFont );
        eventTracker.logFontSelected( selectedFont );
    }

    private generateFontDataForDesignFonts( fontControlConfig: FontControlConfig ): FontData[]
    {
        const fonts: string[] = filter( fontControlConfig.fonts );
        const fontData: FontData[] = map( fonts, ( f ) => generateFontDataForFontName( f ) );
        return filter( fontData );
    }
}
