import { store } from "../store";
import { debounce, filter, first, head, includes, isArray, isEqual, size, throttle } from "lodash";
import {
    DesignInputDataParameters,
    doesSelectedDesignSupportHD,
    getAnimatedPreviewInputDataParameters,
    getBrandSlideInputDataParameters,
    getDesignAspectRatio,
    getEditorInputDataParameters,
    getPreviewInputDataParameters,
    getSelectedDesign,
    getStartingMixType,
    getVideoAudioMusicData,
    isCurrentDesignFoundation,
    isStaticOutputMode,
    isVideoAudioSelected,
    setAspectRatioToTemplateSetting,
    shouldUpdateCanvas,
    shouldUpdateTemplateSettings,
} from "../ducks/mixModel";
import {
    ALL_SOURCE,
    apptimizeVariables,
    CANVAS_RENDER_DELAY_MS,
    EDITOR_TYPES,
    ENABLE_DESIGN_COMMUNICATION_CONSOLE_LOGGING,
    errorReporting,
    eventTracker,
    getSnapshotDimensions,
    LightboxDialogIdentifierForKey,
} from "./";
import { DesignSettingsSavePayload, mixModelActions, shareModelActions, uiActions } from "../actions";
import { mixModelServices, modalServices } from "../services";
import { getActiveDesignMediaObject, getEditorType, getTextPropertiesSubPanelCaptionData, isMediaTabSelected, isTextTabSelected } from "../ducks/ui";
import { getCurrentMusic } from "../ducks/musicCatalog";
import { CUSTOMIZABLE_DESIGN_ID } from "../components/containers/CustomizableDesign.container";
import { isShowingFullpageDialog, moreMusicBeingShown } from "../ducks/modals";
import {
    AspectRatio,
    CommandToDesignCanvas,
    CommonInputDataUpdateTextResponse,
    Design,
    DesignCanvasMessage,
    DesignCaptionData,
    DesignContextualData,
    DesignElement,
    DesignElementProperties,
    DesignInputVideoAudioCue,
    DesignMediaObjectsResponse,
    DesignPresets,
    DesignPresetsResponse,
    DesignTemplateSettings,
    DesignUseKey,
    GlobalSettings,
    MixModelState,
    PickPhotoResponse,
    RequestEditTextObjectsPayload,
    SlideIndexData,
    SlideSummaryResponse,
    StoreState,
    TextPickerCaptionData,
} from "../_types";

import {
    DESIGN_ELEMENT_PROPERTY_TYPE_STYLE,
    DESIGN_USE_KEY_EXTRA_CAPTION,
    DESIGN_USE_KEY_SECONDARY,
    PRIMARY_TEXT_FIELD_NAME,
    SECONDARY_TEXT_FIELD_NAME,
} from "../_types/api";
import { isAnimationOutputTypeFromState, isHDAnimationOutputType } from "../ducks/shareModel";
import MaxDesignErrorReached from "../components/settingsPages/MaxDesignErrorReached";
import { ElementPropertyChangedData, ExtraCaptionCreationArgs, ICanvasUpdater, MediaZoomData } from "../_types/canvasUpdaterTypes";
import { designMediaObjectHelper } from "./designMediaObjectHelper";
import { showSafariAudioSyncWarningToast } from "./toastPresenter";
import { TEMPLATE_PREVIEW_IFRAME_ID } from "../components/TemplatePreviewBrandableView";
import { DesignMessageAwaiter } from "./designMessageAwaiter";

const MAX_NUMBER_OF_SNAPSHOT_TRIES = 3;
const MAX_RELOAD_DESIGN_COUNT = 3;

export class CanvasUpdater implements ICanvasUpdater
{
    public static parseEventData( event ): DesignCanvasMessage
    {
        if ( isArray( event.data ) && event.data.length >= 3 )
        {
            return {
                messageName: event.data[0],
                arguments: event.data[1],
                iframeId: event.data[2],
            };
        }
    }

    private static shouldSnapBeHD()
    {
        const state = store.getState();
        const imageOutputCanBeHD = !isAnimationOutputTypeFromState( state ) && doesSelectedDesignSupportHD( state );
        return isHDAnimationOutputType( state ) || imageOutputCanBeHD;
    }

    private myIframeId: string;
    private getInputDataParameters: ( state: StoreState ) => DesignInputDataParameters;
    private myIframe: HTMLIFrameElement;
    private myLastMixModel: MixModelState;
    private myStoreSubscriber: () => void;
    private myDesignSettingsSavedResolve: ( data: any ) => void;
    private myGlobalSettingsSavedResolve: ( data: any ) => void;
    private mySnapShotResolve: ( data: string ) => void;
    private mySnapShotReject: () => void;
    private myNumberOfSnapshotTries = 0;
    private myRetrySnapshotDebounced: any = undefined;
    private myInitializedResolve: ( data: any ) => void;
    private myInitializedPromise: Promise<boolean>;
    private myMessageListener: EventListener;
    private myAspectRatio: AspectRatio;
    private myDesign: Design;
    private myErrorCount: number = 0;
    private isMainDesign: boolean;
    private isBrandSlide: boolean;
    private hasCapturedInitialDesignSettings: boolean;

    private sendInputData = throttle( ( command: CommandToDesignCanvas ) =>
    {
        const inputDataParameters = this.getInputDataParameters( store.getState() );
        this.sendMessageToIframe( command, ...inputDataParameters );
    }, CANVAS_RENDER_DELAY_MS, { leading: true, trailing: true } );

    constructor( iframeId: string, isEditable: boolean, design: Design, aspectRatio: AspectRatio, isBrandSlide: boolean,
                 animatePreviewOnHover: boolean, animatePreviewOnClick: boolean )
    {
        this.myIframeId = iframeId;
        this.myAspectRatio = aspectRatio;
        this.myIframe = document.getElementById( this.myIframeId ) as HTMLIFrameElement;
        this.myDesign = design;
        this.isMainDesign = (isBrandSlide || isEditable);
        this.isBrandSlide = isBrandSlide;
        if ( isBrandSlide )
        {
            this.getInputDataParameters = getBrandSlideInputDataParameters;
        }
        else if ( isEditable )
        {
            this.getInputDataParameters = getEditorInputDataParameters;
        }
        else
        {
            if ( animatePreviewOnHover || animatePreviewOnClick )
            {
                this.getInputDataParameters = ( state: StoreState ) => getAnimatedPreviewInputDataParameters( state, this.myDesign );
            }
            else
            {
                this.getInputDataParameters = ( state: StoreState ) => getPreviewInputDataParameters( state, this.myDesign );
            }
        }
        this.myInitializedPromise = new Promise( ( resolve ) =>
        {
            this.myInitializedResolve = resolve;
        } );
        this.myLastMixModel = store.getState().mixModel;
        this.myStoreSubscriber = store.subscribe( throttle( async () =>
        {
            const state = store.getState();
            if ( shouldUpdateCanvas( state, this.myLastMixModel, this.myDesign, isEditable ) )
            {
                await this.updateContent();
            }
            if ( isEditable )
            {
                this.myAspectRatio = getDesignAspectRatio( state );
                this.myDesign = getSelectedDesign( state );
            }
            this.myLastMixModel = state.mixModel;
        }, CANVAS_RENDER_DELAY_MS, { leading: true, trailing: true } ).bind( this ) );
    }

    public getId()
    {
        return this.myIframeId;
    }

    public onRemove()
    {
        if ( this.myStoreSubscriber )
        {
            this.myStoreSubscriber();
            this.myStoreSubscriber = undefined;
        }
        if ( this.myMessageListener )
        {
            window.removeEventListener( "message", this.myMessageListener );
            this.myMessageListener = undefined;
        }
    }

    public createDesignEventListener()
    {
        this.myMessageListener = ( event ) =>
        {
            if ( !event )
            {
                return;
            }

            const eventData = CanvasUpdater.parseEventData( event );
            if ( !eventData || eventData.iframeId !== this.myIframeId )
            {
                return;
            }

            if ( this.myRetrySnapshotDebounced )
            {
                this.myRetrySnapshotDebounced();
            }

            if ( ENABLE_DESIGN_COMMUNICATION_CONSOLE_LOGGING )
            {
                // tslint:disable-next-line:no-console
                console.log( "CanvasUpdater", this.myIframeId, "received event", eventData.messageName, eventData );
            }

            switch ( eventData.messageName )
            {
                case "onJavascriptReady":
                    this.handleJavascriptReady();
                    break;
                case "onInitializationComplete":
                    this.handleInitializationComplete();
                    break;
                case "onDesignReloadSucceeded":
                    this.handleDesignReloadSucceeded();
                    break;
                case "saveDesignSettings":
                    this.saveDesignSettings( eventData.arguments );
                    break;
                case "saveGlobalSettings":
                    this.saveGlobalSettings( eventData.arguments );
                    break;
                case "onSnapImageRecieved":
                    this.handleSnapshotData( eventData );
                    break;
                case "updatePageControls":
                    this.handlePageControlsData( eventData.arguments[0] );
                    break;
                case "updateActiveElementControls":
                    this.handleActiveElementControlsData( eventData.arguments[0] );
                    break;
                case "onRequestEditModelText":
                case "onRequestEditText":
                    this.handleEditTextClick( eventData.arguments );
                    break;
                case "onRequestEditTextObjects":
                    this.onRequestEditTextObjects( eventData.arguments );
                    break;
                case "onRequestPickPhoto":
                    this.onRequestPickPhoto( eventData.arguments );
                    break;
                case "textObjectsResponse":
                    this.handleTextObjectsResponse( eventData.arguments );
                    break;
                case "onWatermarkTapped":
                    store.dispatch( mixModelServices.handleWatermarkClick() );
                    break;
                case "onReadyForSnap":
                    const isHD = CanvasUpdater.shouldSnapBeHD();
                    const { width, height } = getSnapshotDimensions( this.myAspectRatio, isHD );
                    this.sendMessageToIframe( "getSnapByMessage", width, height );
                    break;
                case "onAnimationComplete":
                    this.handleOnAnimationComplete();
                    break;
                case "startedPlayingInputVideo":
                    this.playInputVideoAudio( eventData.arguments as DesignInputVideoAudioCue );
                    break;
                case "stoppedPlayingInputVideo":
                    break;
                case "updateSlideIndexData":
                    this.handleSlideIndexData( eventData.arguments[0] );
                    break;
                case "designJsError":
                    this.handleDesignError( eventData.arguments[0] );
                    break;
                case "displayTextStyles":
                    this.handleTextStyles( eventData.arguments );
                    break;
                case "designElementSelectionChanged":
                    this.handleDesignElementSelectionChanged( eventData.arguments );
                    break;
                case "elementPropertiesResponse":
                    this.handleElementPropertiesResponse( eventData.arguments );
                    break;
                case "mediaObjectsResponse":
                    this.handleMediaObjectsResponse( eventData.arguments );
                    break;
                case "presetsResponse":
                    this.handlePresetsResponse( eventData.arguments );
                    break;
                case "updateCommonInputDataText":
                    this.handleUpdateCommonInputDataText( eventData.arguments );
                    break;
                case "slideSummaryResponse":
                    this.handleSlideSummaryResponse( eventData.arguments );
                    break;
            }
        };
        window.addEventListener( "message", this.myMessageListener );
    }

    public async getSnapshot(): Promise<string>
    {
        await this.myInitializedPromise;
        this.myNumberOfSnapshotTries = 0;
        const promise = new Promise<string>( ( resolve, reject ) =>
        {
            this.mySnapShotResolve = resolve;
            this.mySnapShotReject = reject;
        } );
        store.dispatch( shareModelActions.snapshotRequest() );
        this.sendMessageToIframe( "prepareForSnap" );
        this.myRetrySnapshotDebounced = debounce( this.retrySnapshot, 2000 );
        this.myRetrySnapshotDebounced();
        return promise;
    }

    public async tellDesignToSaveSettings()
    {
        await this.myInitializedPromise;
        const promise = this.createSaveDesignSettingsPromise();
        this.sendMessageToIframe( "saveAndPostBackAllSettings" );
        return promise;
    }

    public tellDesignToSaveCaption( captionData )
    {
        this.sendMessageToIframe( "editTextChanged", captionData );
    }

    public tellDesignToUpdateZoomData( zoomData: MediaZoomData )
    {
        this.sendMessageToIframe( "scaleItem", zoomData );
    }

    public tellDesignToSaveZoomData( zoomData: MediaZoomData )
    {
        this.sendMessageToIframe( "afterItemScaled", zoomData );
    }

    public tellDesignToShowPreviousSlide()
    {
        this.sendMessageToIframe( "showPreviousSlide" );
    }

    public tellDesignToShowNextSlide()
    {
        this.sendMessageToIframe( "showNextSlide" );
    }

    public tellDesignToJumpToSlide( slideId: string )
    {
        this.sendMessageToIframe( "handleSelectSlide", slideId );
    }

    public tellDesignToMoveSlideToIndex( oldSlideIndex: number, newSlideIndex: number )
    {
        this.sendMessageToIframe( "moveSlideToIndex", { oldSlideIndex, newSlideIndex } );
    }

    public tellDesignToReloadDesignWithAnimation()
    {
        this.sendMessageToIframe( "reloadDesignWithAnimation" );
    }

    public tellDesignToReloadDesignWithAnimationAndNoReveal()
    {
        this.sendMessageToIframe( "reloadDesignWithAnimation", { shouldAnimateReveal: false } );
    }

    public tellDesignToReloadDesignWithoutAnimationNorReveal()
    {
        this.sendMessageToIframe( "reloadDesignWithoutAnimation", { shouldAnimateReveal: false } );
    }

    public tellDesignToReloadDesignWithoutAnimation()
    {
        this.sendMessageToIframe( "reloadDesignWithoutAnimation" );
    }

    public tellDesignHandleWindowResize()
    {
        this.sendMessageToIframe( "handleWindowResize" );
    }

    public tellDesignToAddExtraCaption( settings: ExtraCaptionCreationArgs )
    {
        this.sendMessageToIframe( "handleAddTextField", settings );
    }

    public tellDesignToRemoveExtraCaption( captionId: string )
    {
        this.sendMessageToIframe( "deleteItem", { id: captionId } );
    }

    public tellDesignToDuplicateExtraCaption( captionId: string )
    {
        this.sendMessageToIframe( "duplicateTextItem", { id: captionId } );
    }

    public tellDesignToGetTextStyles( captionId: string )
    {
        this.sendMessageToIframe( "requestTextStyles", { id: captionId } );
    }

    public tellDesignToStyleTextItem( captionId: string, styleId: string )
    {
        eventTracker.logTextPropertiesChanged( DESIGN_ELEMENT_PROPERTY_TYPE_STYLE );
        this.sendMessageToIframe( "styleTextItem", { id: captionId, styleId } );
    }

    public tellDesignToChangeElementProperty( updateData: ElementPropertyChangedData )
    {
        eventTracker.logTextPropertiesChanged( updateData.type );
        this.sendMessageToIframe( "elementPropertyChanged", updateData );
    }

    public tellDesignToRequestTextObjectsForActivePage()
    {
        this.sendMessageToIframe( "requestTextObjectsForActivePage" );
    }

    public tellDesignToRequestMediaObjectsForActivePage()
    {
        this.sendMessageToIframe( "requestMediaObjectsForActivePage" );
    }

    public async tellDesignToSelectItem( id: string )
    {
        await this.myInitializedPromise;
        this.sendMessageToIframe( "setSelectedItem", { id } );
    }

    public tellDesignToRequestElementProperties( captionId: string )
    {
        this.sendMessageToIframe( "requestElementProperties", captionId );
    }

    public tellDesignToReplaceMedia( oldMediaId: string, newMediaId: string ): void
    {
        this.sendMessageToIframe( "replaceMedia", { oldMediaId, newMediaId } );
    }

    public tellDesignToStopAnimation(): void
    {
        this.sendMessageToIframe( "requestStopAnimation" );
    }

    public tellDesignToRequestPresets(): void
    {
        this.sendMessageToIframe( "requestPresets" );
    }

    public tellDesignToUpdatePresets( presets: DesignPresets ): void
    {
        this.sendMessageToIframe( "updatePresets", presets );
    }

    public tellDesignToRequestSlideSummary(): void
    {
        this.sendMessageToIframe( "requestSlideSummary" );
    }

    public tellDesignToHandleAddSlide(): void
    {
        this.sendMessageToIframe( "handleAddSlide" );
    }

    public tellDesignToHandleRemoveSlide( slideId: string ): void
    {
        this.sendMessageToIframe( "handleRemoveSlide", slideId );
    }

    private handleSnapshotData( eventData: DesignCanvasMessage )
    {
        const snapshotDataUrl = eventData.arguments;
        if ( !snapshotDataUrl && this.myNumberOfSnapshotTries < MAX_NUMBER_OF_SNAPSHOT_TRIES )
        {
            this.retrySnapshot();
            if ( this.myRetrySnapshotDebounced )
            {
                this.myRetrySnapshotDebounced();
            }
            return;
        }

        this.cancelRetrySnapshotDebounced();

        if ( snapshotDataUrl )
        {
            this.saveSnapshot( snapshotDataUrl );
        }
        else
        {
            this.rejectSnapshot();
        }

        this.tellDesignFinishedSnappingAndClearSnap();
    }

    private cancelRetrySnapshotDebounced()
    {
        if ( this.myRetrySnapshotDebounced )
        {
            this.myRetrySnapshotDebounced.cancel();
            this.myRetrySnapshotDebounced = undefined;
        }
    }

    private rejectSnapshot()
    {
        if ( this.mySnapShotReject )
        {
            this.mySnapShotReject();
        }
        this.mySnapShotReject = undefined;
    }

    private tellDesignFinishedSnappingAndClearSnap()
    {
        this.sendMessageToIframe( "finishedSnap" );
        this.sendMessageToIframe( "clearSnap" );
    }

    private retrySnapshot()
    {
        if ( this.myNumberOfSnapshotTries < MAX_NUMBER_OF_SNAPSHOT_TRIES )
        {
            this.myNumberOfSnapshotTries++;
            this.sendMessageToIframe( "prepareForSnap" );
        }
    }

    private handlePageControlsData( pageControlsData: DesignContextualData[] )
    {
        const focusElementId = this.getFocusElementIdFromTextItemObjectList( pageControlsData );
        this.clearTextPropertiesIfNeeded( pageControlsData );
        store.dispatch( uiActions.designCaptionControlsSet( pageControlsData ) );
        store.dispatch( uiActions.designCaptionUpdated( pageControlsData ) );
        store.dispatch( uiActions.updateFocusElement( focusElementId ) );
    }

    private clearTextPropertiesIfNeeded( pageControlsData: DesignContextualData[] )
    {
        const prevCaptionData = getTextPropertiesSubPanelCaptionData( store.getState() );
        if ( prevCaptionData && prevCaptionData.id )
        {
            const matchingCaptionData = filter( pageControlsData, ( data: DesignContextualData ) => data.id === prevCaptionData.id );
            if ( size( matchingCaptionData ) === 0 )
            {
                store.dispatch( uiActions.clearTextPropertiesSubPanelCaptionData() );
            }
        }
        else
        {
            store.dispatch( uiActions.clearTextPropertiesSubPanelCaptionData() );
        }
    }

    private handleActiveElementControlsData( activeElementControlsData: DesignContextualData[] )
    {
        store.dispatch( uiActions.designZoomControlsSet( activeElementControlsData ) );
        store.dispatch( uiActions.designZoomUpdated( activeElementControlsData ) );
    }

    private changeToDesignTextControl()
    {
        if ( getEditorType( store.getState() ) !== EDITOR_TYPES.TEXT_CONTROL )
        {
            mixModelServices.changeDesignControl( EDITOR_TYPES.TEXT_CONTROL );
        }
    }

    private changeToMediaControl()
    {
        if ( getEditorType( store.getState() ) !== EDITOR_TYPES.MEDIA_CONTROL )
        {
            mixModelServices.changeDesignControl( EDITOR_TYPES.MEDIA_CONTROL );
        }
    }

    private handleEditTextClick( useKeyOrCaptionData?: DesignUseKey | DesignCaptionData )
    {
        this.changeToDesignTextControl();
        const focusElement = this.getFocusElementIdForUseKey( useKeyOrCaptionData );
        store.dispatch( uiActions.updateFocusElement( focusElement ) );
    }

    private onRequestEditTextObjects( params: RequestEditTextObjectsPayload )
    {
        this.changeToDesignTextControl();
        this.setFocusOnSelectedTextObject( params, true );
        const designSupportsExtraCaptions = params && params.displayAddExtraCaptionButton;
        store.dispatch( uiActions.designSupportsExtraCaptions( designSupportsExtraCaptions ) );
    }

    private onRequestPickPhoto( params: PickPhotoResponse )
    {
        this.changeToMediaControl();
        designMediaObjectHelper.dispatchAugmentedMediaObject( params.mediaObject, true );
    }

    private handleTextObjectsResponse( params: RequestEditTextObjectsPayload )
    {
        this.setFocusOnSelectedTextObject( params );
        const designSupportsExtraCaptions = params && params.displayAddExtraCaptionButton;
        store.dispatch( uiActions.designSupportsExtraCaptions( designSupportsExtraCaptions ) );
    }

    private setFocusOnSelectedTextObject( params: RequestEditTextObjectsPayload, shouldOpenInTextSubPanel: boolean = false )
    {
        const focusElementId = this.getFocusElementIdFromRequestEditTextObjectsPayload( params );
        if ( focusElementId )
        {
            store.dispatch( uiActions.updateFocusElement( focusElementId ) );
            const textItem = this.textItemWithId( params.textItemObjects, focusElementId );
            if ( shouldOpenInTextSubPanel && this.canStylizeText( textItem ) )
            {
                const data: TextPickerCaptionData = { id: textItem.id, value: textItem.text, styleId: textItem.styleId };
                store.dispatch( uiActions.setTextPropertiesSubPanelCaptionData( data ) );
            }
        }
    }

    private canStylizeText( textItem ): boolean
    {
        const textItemsWithProperties = [DESIGN_USE_KEY_EXTRA_CAPTION];
        return textItem && includes( textItemsWithProperties, textItem.useKey );
    }

    private getFocusElementIdForUseKey( useKeyOrCaptionData: DesignUseKey | DesignCaptionData )
    {
        let focusElementId = PRIMARY_TEXT_FIELD_NAME;
        if ( useKeyOrCaptionData )
        {
            if ( typeof useKeyOrCaptionData === "string" )
            {
                focusElementId = useKeyOrCaptionData === DESIGN_USE_KEY_SECONDARY ?
                                 SECONDARY_TEXT_FIELD_NAME :
                                 PRIMARY_TEXT_FIELD_NAME;
            }
            else
            {
                focusElementId = useKeyOrCaptionData.id;
            }
        }
        return focusElementId;
    }

    private getFocusElementIdFromRequestEditTextObjectsPayload( params: RequestEditTextObjectsPayload )
    {
        let focusElementId = null;
        if ( params.isHeadlineTextSelected )
        {
            focusElementId = PRIMARY_TEXT_FIELD_NAME;
        }
        else if ( params.isBodyTextSelected )
        {
            focusElementId = SECONDARY_TEXT_FIELD_NAME;
        }
        else
        {
            focusElementId = this.getFocusElementIdFromTextItemObjectList( params.textItemObjects );
        }
        return focusElementId;
    }

    private getFocusElementIdFromTextItemObjectList( textObjects: any[] )
    {
        for ( const textItemData of textObjects )
        {
            const isSelected = textItemData.selected || textItemData.isSelected;
            if ( isSelected )
            {
                return textItemData.id;
            }
        }
        return null;
    }

    private textItemWithId( textObjects: any[], id: string )
    {
        return first( filter( textObjects, ( textItemData ) => textItemData.id === id ) );
    }

    private handleJavascriptReady()
    {
        store.dispatch( uiActions.updateDesignLoadProgressStarted( this.myIframeId ) );
        this.setFeatureFlags();
        this.setContent();
    }

    private handleInitializationComplete()
    {
        const isMainCustomizeCanvas = this.isMainCustomizeCanvas();
        if ( isMainCustomizeCanvas )
        {
            if ( isShowingFullpageDialog( store.getState() ) )
            {
                this.tellDesignToStopAnimation();
            }
            else
            {
                const currentMusic = getCurrentMusic( store.getState() );
                if ( currentMusic )
                {
                    store.dispatch( uiActions.setAudioPlayerMusicTrack( currentMusic ) );
                }
            }
            this.syncSelectedDesignControlDataAfterInitComplete();
            if ( this.shouldSaveDesignSettingsOnInitialDesignLoad() )
            {
                this.saveAllDesignSettingsOnInitialDesignLoad();
            }

            if ( isCurrentDesignFoundation( store.getState() )
                 && apptimizeVariables.shouldEnableDynamicSlides() )
            {
                this.tellDesignToRequestSlideSummary();
            }
            else
            {
                store.dispatch( uiActions.slideSummaryClear() );
            }
        }

        if ( this.isTemplatePreviewCanvas() )
        {
            this.tellDesignToReloadDesignWithAnimation();
            store.dispatch( uiActions.templatePreviewAnimationStarted() );
        }

        if ( this.myInitializedResolve )
        {
            this.myInitializedResolve( true );
            this.myInitializedResolve = undefined;
        }
        store.dispatch( uiActions.updateDesignLoadProgressCompleted( this.myIframeId ) );
    }

    private handleDesignReloadSucceeded()
    {
        const storeState = store.getState();
        this.syncActiveMedia( storeState );
    }

    private syncSelectedDesignControlDataAfterInitComplete()
    {
        const storeState = store.getState();
        if ( isStaticOutputMode( storeState ) )
        {
            this.syncActiveTextObjects( storeState );
        }
    }

    private syncSelectedDesignControlDataAfterAnimationComplete()
    {
        const storeState = store.getState();
        if ( !isStaticOutputMode( storeState ) )
        {
            // must do this after animation is complete because requesting text objects will inadvertently stop the animation
            this.syncActiveTextObjects( storeState );
        }
    }

    private syncActiveTextObjects( storeState: StoreState )
    {
        if ( isTextTabSelected( storeState ) )
        {
            // addresses an issue where switching templates too fast can cause the text objects to be out of sync
            // (shows the last template's text objects)
            this.tellDesignToRequestTextObjectsForActivePage();
        }
    }

    private syncActiveMedia( storeState: StoreState )
    {
        if ( isMediaTabSelected( storeState ) && getActiveDesignMediaObject( storeState ) )
        {
            this.tellDesignToRequestMediaObjectsForActivePage();
        }
    }

    private startingMixTypeQualifiesForInitialSave = (): boolean =>
    {
        const staringMixTypesThatDisqualifyInitialSave = [
            ALL_SOURCE,
        ];
        const startingMixType = getStartingMixType( store.getState() );
        return !includes( staringMixTypesThatDisqualifyInitialSave, startingMixType );
    }

    private shouldSaveDesignSettingsOnInitialDesignLoad()
    {
        return this.isMainDesign && !this.isBrandSlide && !this.hasCapturedInitialDesignSettings && this.startingMixTypeQualifiesForInitialSave();
    }

    private saveAllDesignSettingsOnInitialDesignLoad()
    {
        this.createSaveDesignSettingsPromise().then( () =>
        {
            this.hasCapturedInitialDesignSettings = true;

            if ( !isStaticOutputMode( store.getState() ) )
            {
                this.tellDesignToReloadDesignWithAnimation();
            }
        } );
        this.sendMessageToIframe( "saveAndPostBackAllSettings" );
    }

    private createSaveDesignSettingsPromise()
    {
        return Promise.all( [
            new Promise( ( resolve ) =>
            {
                this.myDesignSettingsSavedResolve = resolve;
            } ),
            new Promise( ( resolve ) =>
            {
                this.myGlobalSettingsSavedResolve = resolve;
            } ),
        ] );
    }

    private isMainCustomizeCanvas()
    {
        return this.myIframeId === CUSTOMIZABLE_DESIGN_ID;
    }

    private isTemplatePreviewCanvas()
    {
        return this.myIframeId === TEMPLATE_PREVIEW_IFRAME_ID;
    }

    private setFeatureFlags()
    {
        // Below is a sample usage for passing in Apptimize variables into design.
        // this.sendMessageToIframe( "setFeatureFlags", [{}] );
    }

    private setContent()
    {
        this.sendInputData( "setRemixInputData" );
    }

    private async updateContent()
    {
        // this is a "soft reload"
        await this.myInitializedPromise;
        this.sendInputData( "updateRemixInputData" );
        try
        {
            await new DesignMessageAwaiter().awaitMessage( this.myIframeId, "templateDataUpdateSucceeded" );
        }
        catch (e)
        {
            // tslint:disable-next-line:no-console
            console.log( e );
        }
    }

    private saveSnapshot( dataUrl: string )
    {
        if ( this.mySnapShotResolve )
        {
            store.dispatch( shareModelActions.snapshotSuccess( dataUrl ) );
            this.mySnapShotResolve( dataUrl );
        }
        this.mySnapShotResolve = undefined;
    }

    private saveGlobalSettings( aSettings: GlobalSettings )
    {
        if ( !isEqual( this.myLastMixModel.globalSettings, aSettings ) )
        {
            store.dispatch( mixModelActions.globalSettingsSaved( aSettings ) );
        }
        if ( this.myGlobalSettingsSavedResolve )
        {
            this.myGlobalSettingsSavedResolve( aSettings );
        }
        this.myGlobalSettingsSavedResolve = undefined;
    }

    private saveDesignSettings( aSettings: DesignTemplateSettings )
    {
        const designAspectRatio = setAspectRatioToTemplateSetting( aSettings );
        if ( shouldUpdateTemplateSettings( store.getState(), this.myLastMixModel, aSettings, this.myDesign, designAspectRatio ) )
        {
            const payload: DesignSettingsSavePayload = { design: this.myDesign, settings: aSettings, designAspectRatio };
            store.dispatch( mixModelActions.templateSettingsSaved( payload ) );
        }
        if ( this.myDesignSettingsSavedResolve )
        {
            this.myDesignSettingsSavedResolve( aSettings );
        }
        this.myDesignSettingsSavedResolve = undefined;
    }

    private sendMessageToIframe( methodName: CommandToDesignCanvas, ...args: any[] )
    {
        if ( this.myIframe && this.myIframe.contentWindow )
        {
            if ( ENABLE_DESIGN_COMMUNICATION_CONSOLE_LOGGING )
            {
                // tslint:disable-next-line:no-console
                console.log( "CanvasUpdater", this.myIframeId, "sendMessageToIframe", methodName, args );
            }
            this.myIframe.contentWindow.postMessage( [methodName, args], "*" );
        }
    }

    private playInputVideoAudio( startPlayingInputVideoAudioData: DesignInputVideoAudioCue )
    {
        const state = store.getState();
        const isMainCustomizeCanvas = this.isMainCustomizeCanvas();
        if ( isVideoAudioSelected( state ) && isMainCustomizeCanvas )
        {
            showSafariAudioSyncWarningToast();
            const mediaId = startPlayingInputVideoAudioData.mediaId;
            const videoAudioMusicData = getVideoAudioMusicData( state, mediaId );
            store.dispatch( uiActions.setAudioPlayerMusicTrack( videoAudioMusicData ) );
        }
    }

    private handleSlideIndexData( slideIndexData: SlideIndexData )
    {
        store.dispatch( uiActions.designSlideIndexDataUpdated( slideIndexData ) );
    }

    private handleDesignError( error: any )
    {
        this.myErrorCount++;
        if ( this.myErrorCount < MAX_RELOAD_DESIGN_COUNT )
        {
            store.dispatch( uiActions.designCanvasRefreshed() );
        }
        else if ( this.myErrorCount === MAX_RELOAD_DESIGN_COUNT && this.isMainDesign )
        {
            let errorAsJsonString;
            if ( !!error )
            {
                errorAsJsonString = JSON.stringify( error );
            }

            eventTracker.logMainDesignMaxErrorsReached( errorAsJsonString );
            errorReporting.reportErrorToSentry( "mainDesignMaxErrorsReachedWeb", error );

            store.dispatch( modalServices.openLightbox( {
                    identifierForKey: LightboxDialogIdentifierForKey.MAX_ERRORS_FOR_DESIGN,
                    title: "Oops! Something went wrong",
                    content: MaxDesignErrorReached,
                    hideAlternateAction: true,
                    hideCancel: true,
                    showCancelX: true,
                    confirmLabel: "Ok",
                    width: 450,
                    footerClassName: "buttonFullWidthOfDialogFooter",
                },
            ) );
        }

        if ( this.myRetrySnapshotDebounced )
        {
            this.cancelRetrySnapshotDebounced();
            this.rejectSnapshot();
            this.tellDesignFinishedSnappingAndClearSnap();
        }
    }

    private handleTextStyles( argument: any )
    {
        store.dispatch( uiActions.stylizedTextChoicesSet( { captionId: argument.id, styles: argument.styles } ) );
    }

    private handleDesignElementSelectionChanged( argument: DesignElement )
    {
        store.dispatch( uiActions.selectedDesignElementSet( argument ) );
    }

    private handleElementPropertiesResponse( argument: DesignElementProperties )
    {
        store.dispatch( uiActions.designElementPropertiesReceived( argument ) );
    }

    private handleMediaObjectsResponse( argument: DesignMediaObjectsResponse )
    {
        const mediaObject = head( argument.mediaObjects );
        designMediaObjectHelper.dispatchAugmentedMediaObject( mediaObject );
    }

    private handlePresetsResponse( argument: DesignPresetsResponse )
    {
        store.dispatch( uiActions.stylizedTextChoicesSet( { captionId: null, styles: argument.textStyles } ) );
        store.dispatch( uiActions.textAnimationChoicesSet( { textAnimations: argument.textAnimations } ) );
        store.dispatch( uiActions.globalTextAnimationChoicesSet( { textAnimations: argument.globalTextAnimations } ) );
        store.dispatch( uiActions.designPresetsReceived( argument.presets ) );
        store.dispatch( uiActions.slideTransitionPropertiesReceived( argument.slideTransitionOptions ) );
        store.dispatch( uiActions.backgroundAnimationPropertiesReceived( argument.backgroundAnimationOptions ) );

        if ( apptimizeVariables.shouldSupportPresetSlideTransitions() )
        {
            store.dispatch( uiActions.slideTransitionChoicesSet( { slideTransitions: argument.slideTransitions } ) );
        }
        if ( apptimizeVariables.shouldSupportBackgroundAnimations() )
        {
            store.dispatch( uiActions.backgroundAnimationChoicesSet( { backgroundAnimations: argument.backgroundAnimations } ) );
        }
    }

    private handleSlideSummaryResponse( argument: SlideSummaryResponse )
    {
        if ( apptimizeVariables.shouldEnableDynamicSlides() )
        {
            store.dispatch( uiActions.slideSummarySet( argument ) );
        }
    }

    private handleUpdateCommonInputDataText( argument: CommonInputDataUpdateTextResponse )
    {
        if ( apptimizeVariables.shouldEnablePresetText() )
        {
            store.dispatch( mixModelActions.commonInputDataUpdated( argument ) );
        }
    }

    private handleOnAnimationComplete()
    {
        const isMainCustomizeCanvas = this.isMainCustomizeCanvas();
        if ( isMainCustomizeCanvas )
        {
            this.syncSelectedDesignControlDataAfterAnimationComplete();
            if ( !moreMusicBeingShown( store.getState() ) )
            {
                store.dispatch( uiActions.clearAudioPlayerMusicTrack() );
            }
        }
        else if ( this.isTemplatePreviewCanvas() )
        {
            // tell the brandable view to show the play button
            store.dispatch( uiActions.templatePreviewAnimationCompleted() );
        }
    }
}
