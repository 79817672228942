import { Action } from "redux-actions";
import { epidemicSoundCollectionsSchema, ReducerCreator, updateLookupWithNormalizrDataForStringIds } from "../helpers";
import { createDefaultDataLookup } from "./dataLookup";
import { EpidemicSoundCollectionsState, StoreState } from "../_types";
import { map, merge, uniq } from "lodash";
import { epidemicSoundCollectionActions, EpidemicSoundCollectionsPayload } from "../actions/epidemicSoundCollections.actions";

const defaultState: EpidemicSoundCollectionsState = {
    ...createDefaultDataLookup(),
    activeCollectionId: null,
    activeCollectionTrackIds: [],
    links: {
        next: "",
        prev: "",
    },
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( epidemicSoundCollectionActions.loadCollectionsSuccess, updateEpidemicSoundCollectionsData );
reducerCreator.addAction( epidemicSoundCollectionActions.clearCollections, handleClearEpidemicSoundCollections );
reducerCreator.addAction( epidemicSoundCollectionActions.activeCollectionIdSet, handleActiveCollectionIdSet );
reducerCreator.addAction( epidemicSoundCollectionActions.activeCollectionIdClear, handleActiveCollectionIdClear );
reducerCreator.addAction( epidemicSoundCollectionActions.activeCollectionTrackIdsSet, handleActiveCollectionTrackIdsSet );
reducerCreator.addAction( epidemicSoundCollectionActions.activeCollectionTrackIdsClear, handleActiveCollectionTrackIdsClear );

export default reducerCreator.createReducer();

function handleActiveCollectionIdSet( state: EpidemicSoundCollectionsState, action: Action<string> ): EpidemicSoundCollectionsState
{
    return {
        ...state,
        activeCollectionId: action.payload,
    };
}

function handleActiveCollectionIdClear( state: EpidemicSoundCollectionsState ): EpidemicSoundCollectionsState
{
    return {
        ...state,
        activeCollectionId: null,
    };
}

function handleActiveCollectionTrackIdsSet( state: EpidemicSoundCollectionsState, action: Action<string[]> ): EpidemicSoundCollectionsState
{
    return {
        ...state,
        activeCollectionTrackIds: uniq( [...state.activeCollectionTrackIds, ...action.payload] ),
    };
}

function handleActiveCollectionTrackIdsClear( state: EpidemicSoundCollectionsState ): EpidemicSoundCollectionsState
{
    return {
        ...state,
        activeCollectionTrackIds: [],
    };
}

function updateEpidemicSoundCollectionsData( state: EpidemicSoundCollectionsState,
                                             action: Action<EpidemicSoundCollectionsPayload> ): EpidemicSoundCollectionsState
{
    const collectionsData = action.payload.data;
    const updatedState = updateLookupWithNormalizrDataForStringIds( epidemicSoundCollectionsSchema, collectionsData, state, false );
    const uniqIds = uniq( [...state.ids, ...updatedState.ids] );
    const links = action.payload.links;

    return {
        ...state,
        ids: uniqIds,
        idToObj: merge( state.idToObj, updatedState.idToObj ),
        links,
    };
}

function handleClearEpidemicSoundCollections( state: EpidemicSoundCollectionsState ): EpidemicSoundCollectionsState
{
    return { ...defaultState };
}

export const getEpidemicSoundCollections = ( state: StoreState ) =>
{
    return map( state.epidemicSoundCollections.ids, ( id ) =>
    {
        return state.epidemicSoundCollections.idToObj[id];
    } );
};

export const getActiveCollectionTracks = ( state: StoreState ) =>
{
    const activeCollectionTrackIds = state.epidemicSoundCollections.activeCollectionTrackIds;
    return map( activeCollectionTrackIds, ( id ) =>
    {
        return state.epidemicSoundCatalog.idToObj[id];
    } );
};

export const getNextCollectionsPageUrl = ( state: StoreState ) => state.epidemicSoundCollections.links.next;
export const getCollectionsLinks = ( state: StoreState ) => state.epidemicSoundCollections.links;
export const getActiveCollectionId = ( state: StoreState ) => state.epidemicSoundCollections.activeCollectionId;
export const getActiveCollectionDetails = ( state: StoreState ) =>
{
    const activeCollectionId = getActiveCollectionId( state );
    return state.epidemicSoundCollections.idToObj[activeCollectionId];
};
export const getNextTracksPageUrl = ( state: StoreState ) =>
{
    const collectionDetail = getActiveCollectionDetails( state );
    return collectionDetail && collectionDetail.links && collectionDetail.links.next;
};
