import * as React from "react";
import { replace, upperFirst } from "lodash";
import { getDesignFontName, getFontCssUrl, getFontDisplayName, isUserUploadedFont, webFontLoader } from "../../helpers";
import { FontData } from "../../_types";
import Button from "react-md/lib/Buttons/Button";
import classNames from "classnames";
import { BrandSettingsFontPickerControlContainerProps } from "./containers/BrandSettingsFontPickerControl.container";
import * as NewNavPlus from "../../assets/img/newNavPlus.png";
import { PencilButton } from "../PencilButton";
import FontFileUploadButtonContainer from "../containers/FontFileUploadButton.container";
import { FontPreview } from "./FontPreview";

export interface BrandSettingsFontPickerControlProps extends BrandSettingsFontPickerControlContainerProps
{
    fonts: FontData[];
    customLabel?: string;
    showAdditionalEditIcon?: boolean;
}

export interface BrandSettingsFontPickerControlDispatchProps
{
    handleFontClicked( fontClicked: () => void );
    openDialog();
}

export class BrandSettingsFontPickerControl
    extends React.PureComponent<BrandSettingsFontPickerControlProps & BrandSettingsFontPickerControlDispatchProps>
{
    constructor( props )
    {
        super( props );
        webFontLoader.loadFont( this.props.customFont );
    }

    public componentDidUpdate( prevProps: BrandSettingsFontPickerControlProps )
    {
        this.loadCustomFontIfChanged( this.props.customFont, prevProps.customFont );
    }

    public render()
    {
        const hasFontSet = getDesignFontName( this.props.customFont );
        return (
            <div className="fontContainer">
                <span className="fontTitle">{this.createLabel()}</span>
                <div className="selectFont">
                    <Button
                        className={classNames( "fontAuto", { noFont: !hasFontSet } )}
                        id={this.props.fontField + "_selector"}
                        flat
                        onClick={this.onFontClick}>
                        {this.fontDisplay()}
                    </Button>
                    {
                        this.props.showAdditionalEditIcon && hasFontSet && <PencilButton onClick={this.onFontClick}/>
                    }
                </div>

                {this.getUploadCustomFontLink()}
            </div>
        );
    }

    private getUploadCustomFontLink()
    {
        return (
            <FontFileUploadButtonContainer
                fontField={this.props.fontField}
                fontUrlField={this.props.fontUrlField}
                fontCssUrlField={this.props.fontCssUrlField}
                mediaType={this.props.mediaType}
                label="Upload your own font"
                icon={null}
                onUpdatedFont={this.props.onUpdatedFont}
            />
        );
    }

    private fontDisplay()
    {
        const fontDisplayName = getFontDisplayName( this.props.customFont );
        if ( fontDisplayName )
        {
            const designFontName = getDesignFontName( this.props.customFont );
            const isUserUploaded = isUserUploadedFont( this.props.customFont );
            return <FontPreview font={this.props.customFont}
                                displayName={fontDisplayName}
                                designFontName={designFontName}
                                isCustomFont={isUserUploaded}/>;
        }
        else
        {
            return <img className="plus" src={NewNavPlus} alt="add brand font"/>;
        }
    }

    private loadCustomFontIfChanged = ( customFont: FontData, prevCustomFont?: FontData ) =>
    {
        const customFontCssUrl = getFontCssUrl( customFont );
        const prevCustomFontCssUrl = getFontCssUrl( prevCustomFont );
        const isFontCssUrlSet = !!customFontCssUrl;
        const hasFontCssUrlChanged = customFontCssUrl !== prevCustomFontCssUrl;
        if ( isFontCssUrlSet && hasFontCssUrlChanged )
        {
            this.loadCustomFont( customFontCssUrl );
        }
    }

    private loadCustomFont( customFontCssUrl: string )
    {
        webFontLoader.loadCustomFontIfSpecified( customFontCssUrl );
    }

    private onFontClick = () =>
    {
        this.props.handleFontClicked( this.props.openDialog );
    }

    private createLabel()
    {
        if ( this.props.customLabel )
        {
            return this.props.customLabel;
        }
        const label = upperFirst( replace( this.props.fontField, "_font", "" ) );
        return `${label} font`;
    }
}
