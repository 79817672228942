import * as React from "react";
import Cell from "react-md/lib/Grids/Cell";
import Button from "react-md/lib/Buttons/Button";
import SchedulePostWidget from "./containers/SchedulePostWidget.container";
import PostMediaCaptionContainer from "./containers/PostMediaCaption.container";
import EasyCaptionContainer from "./containers/EasyCaption.container";

export interface PostMediaPreviewProps
{
    scheduledSendDatetime: string;
    isPostFinishInProgress: boolean;
    exceedsHashtagLimitError: string;
    exceedsCharacterLimitError: string;
    canUseEasyCaption: boolean;
}

export interface PostMediaPreviewCallbackProps
{
    onShareNowButtonClick: () => void;
}

export class PostMediaPreview extends React.PureComponent<PostMediaPreviewProps & PostMediaPreviewCallbackProps>
{
    public render()
    {
        return (
            <Cell className="captionPanel" size={4} tabletSize={8} tabletOrder={2} phoneOrder={2}>
                <h2>Add your caption and share</h2>
                <PostMediaCaptionContainer/>
              {this.props.canUseEasyCaption && <EasyCaptionContainer />}
                <Button flat primary swapTheming className="fullWidth large shareNowBtn"
                        disabled={this.getDisabled()}
                        onClick={this.props.onShareNowButtonClick}>{this.props.scheduledSendDatetime ? "Schedule" : "Share now"}</Button>
                <SchedulePostWidget/>
            </Cell>
        );
    }

    private getDisabled = () =>
    {
        return this.props.isPostFinishInProgress || !!this.props.exceedsCharacterLimitError;
    }
}
