import * as React from "react";
import * as RiplLogo from "../assets/img/ripl_logo_white.png";
import * as Crown from "../assets/img/crown-icon.svg";

export interface GradientHeaderDialogProps
{
    title: string;
    subtitle?: string;
    showCrown: boolean;
    content: JSX.Element | string;
    canGoBack: boolean;
    width?: number;
}

export interface GradientHeaderDialogDispatchProps
{
    handleCancel();
    backToTierSelection();
}

export class GradientHeaderDialog extends React.PureComponent<GradientHeaderDialogProps & GradientHeaderDialogDispatchProps>
{
    public render()
    {
        const style = this.props.width ? { width: this.props.width } : {};

        return (
            <div style={style}>
                <div className="gradientDialogHeader">
                    <img src={RiplLogo} className="riplLogo" alt="ripl logo"/>
                </div>
                <div className="gradientDialogBody">
                    <div className="titleWrap">
                        {this.props.showCrown && <div className="crown"><img src={Crown} alt="Crown"/></div>}
                        <div className="title">{this.props.title}</div>
                        {this.props.subtitle && <div className="subtitle">{this.props.subtitle}</div>}
                    </div>
                    <div className="contentWrap">
                        {this.props.content}
                    </div>
                    {this.renderCanGoBackLink()}
                </div>
            </div>
        );
    }

    private renderCanGoBackLink()
    {
        if ( this.props.canGoBack )
        {
            return (
                <div className="goBackText clickable riplLink" onClick={this.handleBackToCompareClicked}>
                    Go back to compare plan options
                </div>
            );
        }
    }

    private handleBackToCompareClicked = () =>
    {
        this.props.backToTierSelection();
    }
}
