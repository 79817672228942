import * as React from "react";
import { DESIGN_FONTS_PREVIEW_IMAGES_FOLDER_URL, webFontLoader } from "../../helpers";
import { FontData } from "../../_types";

interface FontPreviewProps
{
    font: FontData;
    displayName: string;
    designFontName: string;
    isCustomFont: boolean;
}

export class FontPreview extends React.PureComponent<FontPreviewProps>
{
    public componentDidMount()
    {
        if ( this.props.isCustomFont )
        {
            webFontLoader.loadFont( this.props.font );
        }
    }

    public render()
    {
        const { displayName, designFontName, isCustomFont } = this.props;
        if ( isCustomFont )
        {
            return (
                <div className="fontPreviewCustomFont" style={{ fontFamily: designFontName }}>{displayName}</div>
            );
        }
        return (
            <div className="fontPreviewImage">
                <img className="fontImage"
                     alt={displayName}
                     src={DESIGN_FONTS_PREVIEW_IMAGES_FOLDER_URL + "/" + designFontName + ".png"}/>
            </div>
        );
    }
}
