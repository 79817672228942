import { connect } from "react-redux";
import { Dispatch } from "redux";
import { store } from "../../store";
import { ShareOutputList, ShareOutputListDispatchProps, ShareOutputListProps } from "../ShareOutputList";
import {
    apptimizeVariables,
    ENABLE_MODE,
    generateShareOutputDisplayProps,
    LightboxDialogIdentifierForKey,
    oauth,
    SOCIAL_ACCOUNT_CONNECTED_SOURCE_SHARE_POST,
    SOCIAL_NETWORKS_FACEBOOK_INSTAGRAM,
} from "../../helpers";
import { shareModelActions } from "../../actions";
import { modalServices } from "../../services";
import { cantShareWithInstagramBusiness, getOutputType } from "../../ducks/shareModel";
import { ShareOutputType, SocialNetworkAccountType, StoreState } from "../../_types";
import {
    ANIMATION_OUTPUT_TYPE,
    FACEBOOK_ACCOUNT_TYPE,
    FACEBOOK_INSTAGRAM_ACCOUNT_TYPE,
    HD_ANIMATION_OUTPUT_TYPE,
    SAVE_TO_COMPUTER_ACCOUNT_TYPE,
    YOUTUBE_ACCOUNT_TYPE,
} from "../../_types/api";
import { hasTeamMemberPermissionsForCurrentBusiness } from "../../ducks/userBusiness";
import { includes } from "lodash";

const mapStateToProps = ( storeState: StoreState ): ShareOutputListProps =>
{
    return {
        accounts: generateShareOutputDisplayProps( storeState, ENABLE_MODE ),
        trackingSource: SOCIAL_ACCOUNT_CONNECTED_SOURCE_SHARE_POST,
        onlyShowConnectedAccounts: hasTeamMemberPermissionsForCurrentBusiness( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): ShareOutputListDispatchProps =>
{
    function isFacebookSocialNetwork( accountType: SocialNetworkAccountType )
    {
        return includes( SOCIAL_NETWORKS_FACEBOOK_INSTAGRAM, accountType );
    }

    function showFacebookConnectDisabledAlert()
    {
        const title = "Facebook / Instagram Temporarily Disabled";
        // tslint:disable-next-line:max-line-length
        const message = "Connecting to Facebook is temporarily disabled while we investigate a service issue. At this time, we recommend saving this post to your device and sharing directly.";
        dispatch( modalServices.openAlertDialog( { title, message } ) );
    }

    return {
        onSocialAccountConnectRequested: ( accountType: SocialNetworkAccountType ) =>
        {
            if ( isFacebookSocialNetwork( accountType ) && apptimizeVariables.shouldDisableFacebookConnect() )
            {
                showFacebookConnectDisabledAlert();
                return;
            }
            oauth.connectViaWindow( accountType as SocialNetworkAccountType, SOCIAL_ACCOUNT_CONNECTED_SOURCE_SHARE_POST );
        },
        onShareOutputEnableChanged: ( accountType: ShareOutputType, accountId: number, accountEnabled: boolean ) =>
        {
            showIfShareNotAvailable( dispatch, accountType );
            const facebookEnabled = accountType === FACEBOOK_ACCOUNT_TYPE ? accountEnabled : undefined;
            const saveToComputerEnabled = accountType === SAVE_TO_COMPUTER_ACCOUNT_TYPE ? accountEnabled : undefined;
            dispatch( shareModelActions.shareOutputEnableChanged( { facebookEnabled, saveToComputerEnabled, accountId, accountEnabled } ) );
        },
        onSocialAccountRefreshRequested: ( accountType: SocialNetworkAccountType, accountId: number ) =>
        {
            // TODO do we need to track account Id or do anything other than a second connect?
            oauth.refreshViaWindow( accountId, accountType as SocialNetworkAccountType, SOCIAL_ACCOUNT_CONNECTED_SOURCE_SHARE_POST );
        },
        showConnectDisabledAlert: () =>
        {
            showFacebookConnectDisabledAlert();
        },
    };
};

function showIfShareNotAvailable( dispatch: Dispatch<StoreState>, accountType: ShareOutputType )
{
    if ( accountType === YOUTUBE_ACCOUNT_TYPE )
    {
        const outputType = getOutputType( store.getState() );
        if ( (outputType !== ANIMATION_OUTPUT_TYPE) && (outputType !== HD_ANIMATION_OUTPUT_TYPE) )
        {
            dispatch( modalServices.openLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.YOUTUBE_SHARE_IMAGE_NOT_AVAILABLE,
                cancelLabel: "OK",
                hideConfirm: true,
                width: 700,
                content: "YouTube is disabled when sharing images. YouTube only allows videos to be posted.",
            } ) );
        }
    }
    else if ( accountType === FACEBOOK_INSTAGRAM_ACCOUNT_TYPE )
    {
        const dontShareWithInstagramBusiness = cantShareWithInstagramBusiness( store.getState() );
        if ( dontShareWithInstagramBusiness )
        {
            dispatch( modalServices.openLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.FACEBOOK_INSTAGRAM_PORTRAIT_SHARE_NOT_AVAILABLE,
                cancelLabel: "OK",
                hideConfirm: true,
                width: 700,
                content: "Instagram Business is disabled when sharing a portrait format. Instagram Business does not support this size",
            } ) );
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( ShareOutputList );
