import * as React from "react";
import { Music } from "../../../_types";
import { map } from "lodash";
import { LegacyAudioTrackItem } from "./LegacyAudioTrackItem";
import { areMusicItemsEqual } from "../../../ducks/musicCatalog";

interface LegacyAudioTracksListProps
{
    musicList: Music[];
    currentlyPlayingTrack?: Music;
}

interface LegacyAudioTracksListDispatchProps
{
    isSelected: ( music?: Music ) => boolean;
    handleMusicClick: ( music?: Music ) => void;
}

export function LegacyAudioTracksList( props: LegacyAudioTracksListProps & LegacyAudioTracksListDispatchProps )
{
    return (<>
        {map( props.musicList, ( music ) =>
            <LegacyAudioTrackItem key={music.id + music.display_name}
                                  music={music}
                                  isCurrentlyPlaying={areMusicItemsEqual( props.currentlyPlayingTrack, music )}
                                  {...props}/>,
        )
        }
    </>);
}
