import * as React from "react";
import * as ellipse from "../assets/img/ellipse.png";
import Layover from "react-md/lib/Helpers/Layover";
import { useState } from "react";
import Card from "react-md/lib/Cards/Card";
import List from "react-md/lib/Lists/List";
import ListItem from "react-md/lib/Lists/ListItem";
import FontIcon from "react-md/lib/FontIcons/FontIcon";
import { getCustomizableCanvas } from "../ducks/ui";
import { useSelector } from "react-redux";

interface SlideOptionsMenuProps
{
    slideId: string;
    isLastSlide: boolean;
}

export function SlideOptionsMenu( props: SlideOptionsMenuProps )
{
    const canvas = useSelector( getCustomizableCanvas );
    const [state, setState] = useState( { visible: false } );

    function handleOpenMenu()
    {
        setState( { visible: true } );
    }

    function handleCloseMenu()
    {
        setState( { visible: false } );
    }

    function handleDeleteSlide()
    {
        canvas.tellDesignToHandleRemoveSlide( props.slideId );
        handleCloseMenu();
    }

    function createOverlayMenu()
    {
        return (
            <Card>
                <List>
                    <ListItem
                        disabled={props.isLastSlide}
                        primaryText="Delete slide"
                        leftIcon={<FontIcon>delete</FontIcon>}
                        onClick={handleDeleteSlide}/>
                </List>
            </Card>
        );
    }

    return (
        <div>
            <Layover
                className={"slideOptionsMenu"}
                visible={state.visible}
                onClose={handleCloseMenu}
                toggle={
                    <div className="helpMenuContainer clickable" onClick={handleOpenMenu}>
                        <img className={"ellipse"} src={ellipse} alt={"slide options menu"}/>
                    </div>
                }
                animationPosition={Layover.Positions.TOP_LEFT}
                aboveAnchor={{ x: Layover.HorizontalAnchors.INNER_LEFT, y: Layover.VerticalAnchors.TOP }}
                toggleQuery=".ellipse">
                {createOverlayMenu()}
            </Layover>
        </div>
    );
}
