import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../../_types";
import { epidemicSoundCatalogServices } from "../../../../services/epidemicSoundCatalog.services";
import { getNextCollectionsPageUrl } from "../../../../ducks/epidemicSoundCollections";
import { EpidemicCollectionsList, EpidemicCollectionsListDispatchProps, EpidemicCollectionsListProps } from "../EpidemicCollectionsList";
import { isLoadingEpidemicSoundUserCollectionsInProgress } from "../../../../ducks/ui";
import { EpidemicSoundCollectionDetailAPI } from "../../../../_types/api";
import { modalServices } from "../../../../services";
import { EPIDEMIC_COLLECTIONS_DIALOG_TITLE } from "../../../../helpers";

export interface EpidemicCollectionsListContainerProps
{
    epidemicCollections: EpidemicSoundCollectionDetailAPI[];
    scrollTopPosition: number;
    handleSetScrollPosition( collectionScrollTopPosition: number );
}

const mapStateToProps = ( storeState: StoreState, ownProps: EpidemicCollectionsListContainerProps ): EpidemicCollectionsListProps =>
{
    return {
        ...ownProps,
        isLoadingEpidemicSoundUserCollectionsInProgress: isLoadingEpidemicSoundUserCollectionsInProgress( storeState ),
        nextUrl: getNextCollectionsPageUrl( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): EpidemicCollectionsListDispatchProps =>
{
    return {
        updateLightboxDialogTitle()
        {
            dispatch( modalServices.updateEpidemicLightboxTitle( EPIDEMIC_COLLECTIONS_DIALOG_TITLE ) );
        },
        loadMore( nextUrl: string ): void
        {
            if ( !!nextUrl )
            {
                dispatch( epidemicSoundCatalogServices.loadEpidemicUserCollections( nextUrl ) );
            }
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( EpidemicCollectionsList );
