import * as React from "react";
import { AssetChoice } from "../_types";

export interface AssetControlProps
{
    value: AssetChoice;
}

export const AssetControl: React.FC<AssetControlProps> = ( props ) =>
{
    const thumbnail = props.value && props.value.thumbnail || "http://via.placeholder.com/50x50";
    return <img src={thumbnail}/>;
};
