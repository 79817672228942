import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RecommendedPost, StoreState } from "../../_types";
import { getOrderedRecommendedPosts } from "../../ducks/scheduledAction";
import { getPostById } from "../../ducks/post";
import { store } from "../../store";
import { eventTracker } from "../../helpers";
import { modalServices, scheduledActionServices } from "../../services";
import { head } from "lodash";
import { getCurrentBusiness } from "../../ducks/userBusiness";
import { RecommendedPostsRow, RecommendedPostsRowDispatchProps, RecommendedPostsRowProps } from "../homepages/RecommendedPostsRow";

const mapStateToProps = ( storeState: StoreState ): RecommendedPostsRowProps =>
{
    return {
        recommendedPosts: getOrderedRecommendedPosts( storeState ),
        hasCurrentBusiness: !!getCurrentBusiness( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): RecommendedPostsRowDispatchProps =>
{
    async function startCustomize( postData, recommendedPost: RecommendedPost )
    {
        const customizeOptions = {
            isRecommendedPost: true,
        };

        eventTracker.logRecommendedPostSelected( recommendedPost.date, recommendedPost.name );
        dispatch( modalServices.openTemplatePreviewDialogWithDesignControls( postData, customizeOptions ) );
    }

    return {
        handleCustomizePost: async ( recommendedPost: RecommendedPost ) =>
        {
            const state = store.getState();
            const postData = getPostById( head( recommendedPost.posts ), state );
            await startCustomize( postData, recommendedPost );
        },
        handleOpenCreateBusiness: () =>
        {
            dispatch( modalServices.openNewBusinessDialog() );
        },
        loadRecommendations: () =>
        {
            const state = store.getState();
            if ( !!getCurrentBusiness( state ) )
            {
                dispatch( scheduledActionServices.fetch( state, new Date() ) );
            }
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( RecommendedPostsRow );
