import * as React from "react";
import Masonry from "react-masonry-component";
import PostSummaryContainer from "../containers/PostSummary.container";
import CreateBlankPostButtonContainer from "../containers/CreateBlankPostButton.container";
import { Post } from "../../_types";
import { MASONRY_GUTTER, POST_BUTTON_MAP, PostButtonTextMap } from "../../helpers";

export interface PostsPanelProps
{
    postData: Post[];
    isCalendarView?: boolean;
    showCreateBlank?: boolean;
    firstItem?: React.ReactNode;
    postButtonTextMap?: PostButtonTextMap;
}

export default class PostsPanel extends React.PureComponent<PostsPanelProps>
{
    public render()
    {
        const { isCalendarView, postData, showCreateBlank, postButtonTextMap } = this.props;
        let textMapObject = POST_BUTTON_MAP.INSPIRE;
        if ( postButtonTextMap )
        {
            textMapObject = postButtonTextMap;
        }

        return (<div className="postsContainer">
            <Masonry options={{ gutter: MASONRY_GUTTER }}>
                {
                    showCreateBlank && <CreateBlankPostButtonContainer
                                        startText={textMapObject.startText}
                                        remixText={textMapObject.remixText}/>
                }

                {
                    this.props.firstItem
                }

                {
                    postData.map( ( post ) =>
                    {
                        // TODO While the post ID will usually be unique, there are
                        // some instances where it won't be. We should consider another
                        // approach to key generation.
                        return <PostSummaryContainer key={post.id}
                                                     post={post}
                                                     isCalendarView={isCalendarView}/>;
                    } )
                }
            </Masonry>
        </div>);
    }

}
