import * as React from "react";
import Button from "react-md/lib/Buttons/Button";
import CardText from "react-md/lib/Cards/CardText";
import CardTitle from "react-md/lib/Cards/CardTitle";
import Card from "react-md/lib/Cards/Card";
import { cleanUpAndRestartAfterError, ENABLE_ERROR_REPORTING, errorReporting, history, IS_PROD, IS_STAGING, LOGIN_URL } from "../helpers";
import * as Raven from "raven-js";
import { isEmpty } from "lodash";

interface ErrorBoundaryProps
{
    errorMessage?: string;
    showResetButton?: boolean;
}

interface ErrorBoundaryState
{
    hasError: boolean;
}

export class ErrorBoundary extends React.PureComponent<ErrorBoundaryProps, ErrorBoundaryState>
{
    constructor( props )
    {
        super( props );
        this.state = { hasError: false };
    }

    public componentDidCatch( error, info )
    {
        if ( IS_PROD || IS_STAGING )
        {
            this.setState( { hasError: true } );
            if ( this.props.showResetButton )
            {
                cleanUpAndRestartAfterError();
            }
        }
        errorReporting.reportErrorToSentry( error, info );
    }

    public render()
    {
        if ( this.state.hasError )
        {
            if ( isEmpty( this.props.errorMessage ) )
            {
                return <></>;
            }
            return (
                <div className="errorBoundary">
                    <Card className="md-block-centered errorCard">
                        <CardTitle title="We're very sorry" subtitle="Something went wrong"/>
                        <CardText>
                            {this.props.errorMessage}
                        </CardText>
                        {this.props.showResetButton && this.resetButton()}
                    </Card>
                </div>
            );
        }
        return this.props.children;
    }

    private resetButton = () =>
    {
        return (
            <Button className="resetButton md-block-centered"
                    flat
                    primary
                    onClick={this.handleReset}>Restart</Button>
        );
    }

    private handleReset = () =>
    {
        if ( ENABLE_ERROR_REPORTING )
        {
            if ( Raven.lastEventId() )
            {
                Raven.showReportDialog();
            }
        }
        history.push( LOGIN_URL );
    }
}
