const SEPARATOR = "_";
const REQUEST_SUFFIX = "REQUESTED";
const SUCCESS_SUFFIX = "SUCCEEDED";
const FAILED_SUFFIX = "FAILED";

const namesGenerated = {};

export class ActionCreator
{
    private prefix: string;

    constructor( prefix: string )
    {
        this.prefix = prefix;
    }

    public generateTypes = ( methodName: string ) =>
    {
        return {
            request: this.generateType( methodName, REQUEST_SUFFIX ),
            success: this.generateType( methodName, SUCCESS_SUFFIX ),
            failure: this.generateType( methodName, FAILED_SUFFIX ),
        };
    }

    public generateType = ( methodName: string, suffix: string ) =>
    {
        const name = [this.prefix, methodName, suffix].join( SEPARATOR );
        if ( namesGenerated[name] )
        {
            throw new Error( "Already generated a type called " + name );
        }
        namesGenerated[name] = true;
        return name;
    }
}
