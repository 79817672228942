import { ActionCreator } from "../helpers";
import { createAction } from "redux-actions";
import { CollectionRowsState } from "../_types";

const actionCreator = new ActionCreator( "COLLECTION_ROWS" );

const collectionRowsActionsTypes = {
    collectionRowsLoad: actionCreator.generateTypes( "LOAD" ),
};

export const collectionRowsActions = {
    collectionRowsLoadSucceeded: createAction<CollectionRowsState>( collectionRowsActionsTypes.collectionRowsLoad.success ),
    collectionRowsLoadFailed: createAction( collectionRowsActionsTypes.collectionRowsLoad.failure ),
};
