import { connect } from "react-redux";
import { Dispatch } from "redux";
import { uiActions } from "../../actions";
import { CanvasUpdater } from "../../helpers";
import DesignCanvas, { DesignCanvasDispatchProps, DesignCanvasProps } from "../DesignCanvas";
import { getDesignAspectRatio, getHideWatermark, getWatermarkUrl } from "../../ducks/mixModel";
import { StoreState } from "../../_types";
import { getBrandSlideDesign } from "../../ducks/designs";
import { getCatalogUpdatedAtCacheBuster } from "../../ducks/catalog";

export const BRAND_SLIDE_DESIGN_CANVAS_ID = "brandSlideCanvas";

const mapStateToProps = ( storeState: StoreState, ownProps: React.HTMLAttributes<HTMLDivElement> ): DesignCanvasProps =>
{
    return {
        ...ownProps,
        iframeId: BRAND_SLIDE_DESIGN_CANVAS_ID,
        design: getBrandSlideDesign( storeState ),
        editable: true,
        isBrandSlide: true,
        aspectRatio: getDesignAspectRatio( storeState ),
        watermarkUrl: getWatermarkUrl( storeState ),
        hideWatermark: getHideWatermark( storeState ),
        lastRefreshed: storeState.ui.customizePage.lastRefreshed,
        hasDataRequiredToLoad: true,
        catalogUpdatedAt: getCatalogUpdatedAtCacheBuster( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): DesignCanvasDispatchProps =>
{
    return {
        registerCanvas: ( canvasUpdater: CanvasUpdater ) =>
        {
            dispatch( uiActions.registerCanvasUpdater( canvasUpdater ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( DesignCanvas );
