import * as React from "react";
import Button from "react-md/lib/Buttons/Button";
import classNames from "classnames";
import { map, upperCase } from "lodash";
import { DesignElementPropertyCategoryId } from "../../_types/api";
import { DesignElementPropertyCategory } from "../../_types";
import { eventTracker } from "../../helpers";

export interface DesignElementTabsProps
{
    designElementCategories: DesignElementPropertyCategory[];
    selectedCategoryId: DesignElementPropertyCategoryId;
    onSwitchCategoryId( id: DesignElementPropertyCategoryId );
}

export class DesignElementTabs extends React.PureComponent<DesignElementTabsProps>
{
    public render()
    {
        return (
            <div className="designElementTabContainer">
                {map( this.props.designElementCategories,
                    ( designElementCategory, index ) => <DesignElementCategoryTab key={index} {...designElementCategory}
                                                                                  {...this.props}/> )}
            </div>
        );
    }
}

export interface DesignElementCategoryTabItemProps extends DesignElementPropertyCategory
{
    onSwitchCategoryId: ( id: DesignElementPropertyCategoryId ) => void;
    selectedCategoryId: DesignElementPropertyCategoryId;
}

class DesignElementCategoryTab extends React.PureComponent<DesignElementCategoryTabItemProps>
{
    public render()
    {
        return (<Button
            flat
            className={classNames( "designElementTab", { inactive: this.props.selectedCategoryId !== this.props.id } )}
            onClick={this.onClick}>
            {upperCase( this.props.displayName )}
        </Button>);
    }

    private onClick = () =>
    {
        eventTracker.logTextPropertiesTabOpened( this.props.id );
        this.props.onSwitchCategoryId( this.props.id );
    }
}
