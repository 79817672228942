import * as React from "react";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { log } from "util";
import { ErrorBoundary } from "./ErrorBoundary";

export interface GoogleOneTapLoginButtonProps
{
    buttonHeader?: JSX.Element;
    isLoginButton: boolean;
    isConnectButton?: boolean;
    handleGoogleResponse( tokenResponse: CredentialResponse ): void;
}

export function GoogleOneTapLoginButton( props: GoogleOneTapLoginButtonProps )
{
    return (
        <div className={getClassName()}>
            <ErrorBoundary>
                {props.buttonHeader}
                <GoogleLogin
                    onSuccess={( credentialResponse ) =>
                    {
                        props.handleGoogleResponse( credentialResponse );
                    }}
                    onError={() =>
                    {
                        log( "Logging error from GoogleOneTapLoginButton" );
                    }}
                    size="large"
                    width={getWidth()}
                    text={getLabel()}
                    logo_alignment="center"
                    use_fedcm_for_prompt
                />
            </ErrorBoundary>
        </div>
    );

    function getClassName(): string
    {
        return props.isLoginButton ? "googleOneTapLoginButton" : "";
    }

    function getLabel(): "continue_with" | "signin_with"
    {
        return props.isLoginButton ? "continue_with" : "signin_with";
    }

    function getWidth(): number
    {
        return props.isConnectButton ? 300 : 400;
    }
}
