import * as React from "react";
import { ColorValue, TemplateInputEndCardData } from "../../_types";
import { BrandSlideColorPickerOption } from "./BrandSlideColorPickerOption";

export interface BrandSlideColorPickerProps
{
    endCardData: TemplateInputEndCardData;
}

export interface BrandSlideColorPickerDispatchProps
{
    openDialog( colorKey: keyof TemplateInputEndCardData, value: ColorValue );
}

interface BrandSlideColorConfig
{
    title: string;
    colorField: keyof TemplateInputEndCardData;
}

const brandSlideColorConfigs: BrandSlideColorConfig[] = [{
    title: "Background color",
    colorField: "color1",
}, {
    title: "Text color",
    colorField: "color2",
}];

export class BrandSlideColorPicker extends React.PureComponent<BrandSlideColorPickerProps & BrandSlideColorPickerDispatchProps>
{
    public render()
    {
        return (
            <div className="brandSlideColorPicker">
                {
                    brandSlideColorConfigs.map( ( config ) =>
                    {
                        return (<BrandSlideColorPickerOption key={config.colorField} title={config.title}
                                                             colorField={config.colorField} {...this.props}/>);
                    } )
                }
            </div>
        );
    }
}
