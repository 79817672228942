import * as queryString from "query-string";
import { PARTNER_USER_LOGIN_ACTION_PARAM, PARTNER_USER_LOGIN_AUTH_TOKEN_PARAM, PARTNER_USER_LOGIN_TEMPLATE_SLUG_PARAM } from "../helpers";
import { isEmpty, trim } from "lodash";
import { userServices } from "./user.services";
import { persistor } from "../store";
import { StoreState } from "../_types";
import { Dispatch } from "redux";
import { logoutServices } from "./logout.services";

export const partnerServices = {
    isValidLoginUrl,
    getTokenFromQueryParams,
    getActionFromQueryParams,
    getTemplateSlugFromQueryParams,
    login,
};

function isValidLoginUrl( location )
{
    const queryParams = location.search;

    const parsedParams = queryString.parse( queryParams );
    const authToken = parsedParams[PARTNER_USER_LOGIN_AUTH_TOKEN_PARAM] as string;

    return !isEmpty( trim( authToken ) );
}

function getTokenFromQueryParams( params )
{
    const parsedParams = queryString.parse( params );
    return parsedParams[PARTNER_USER_LOGIN_AUTH_TOKEN_PARAM] as string;
}

function getActionFromQueryParams( params )
{
    const parsedParams = queryString.parse( params );
    return parsedParams[PARTNER_USER_LOGIN_ACTION_PARAM] as string;
}

function getTemplateSlugFromQueryParams( params )
{
    const parsedParams = queryString.parse( params );
    return parsedParams[PARTNER_USER_LOGIN_TEMPLATE_SLUG_PARAM] as string;
}

function login( token )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        persistor.purge().then( async () =>
        {
            await dispatch( logoutServices.logoutWithoutRedirect() );
            await dispatch( userServices.loginWithLoginToken( token, false ) );
        } );
    };
}
