import { chain, isEmpty, isNil, trim } from "lodash";

export function getAllNonEmptyStrings( list: string[] ): string[]
{
    return chain( list )
        .reject( isNil )
        .map( trim )
        .reject( isEmpty )
        .value();
}

export function hasAnyMatchingPredicateZipped( lists: any[], predicate: ( value: any[] ) => boolean ): boolean
{
    const [firstList, ...otherLists] = lists;
    return chain( firstList )
        .zip( ...otherLists )
        .filter( predicate )
        .some()
        .value();
}
