import { Brand } from "./controlsConfig";

export const PRIORITY_NORMAL = "3";
export const PRIORITY_SCHEDULED = "2";
export const PRIORITY_ERROR = "1";

export const STRIPE_PLAN_TYPE_ANNUAL: StripePlanType = "annual";
export const STRIPE_PLAN_TYPE_MONTHLY: StripePlanType = "monthly";
export const STRIPE_PLAN_TRIAL_NO_DAYS = 0;
export const STRIPE_NO_TRIAL_COUPON_CODE_TOKEN = "thanks";

export const STRIPE_COUPON_DURATION_REPEATING = "repeating";

export const PAYMENT_PLATFORM_NOT_APPLICABLE = "N/A" as PaymentPlatformType;
export const PAYMENT_PLATFORM_ANDROID = "ANDROID" as PaymentPlatformType;
export const PAYMENT_PLATFORM_STRIPE = "STRIPE" as PaymentPlatformType;
export const PAYMENT_PLATFORM_APPSTACK = "APPSTACK" as PaymentPlatformType;
export const PAYMENT_PLATFORM_SHOPIFY = "SHOPIFY" as PaymentPlatformType;
export const PAYMENT_PLATFORM_IOS = "IOS" as PaymentPlatformType;

export const INPUT_MEDIA_TYPE = "InputMedia";
export const PHOTO_OUTPUT_TYPE = "Photo";
export const VIDEO_OUTPUT_TYPE = "Video";
export const MUSIC_INPUT_TYPE = "Music";
export const IMAGE_TYPE = "image";
export const VIDEO_TYPE = "video";

export const PRIMARY = "primary";
export const SECONDARY = "secondary";

export const BRANDS: Brand[] = [PRIMARY, SECONDARY];

export const PRIMARY_FONT_FIELD = "primary_font";
export const PRIMARY_FONT_URL_FIELD = "primary_font_url";
export const PRIMARY_FONT_CSS_URL_FIELD = "primary_font_css_url";
export const SECONDARY_FONT_FIELD = "secondary_font";
export const SECONDARY_FONT_URL_FIELD = "secondary_font_url";
export const SECONDARY_FONT_CSS_URL_FIELD = "secondary_font_css_url";

export const BRAND_SLIDE_FONT_ONE_FIELD = "end_card_font_one";
export const BRAND_SLIDE_FONT_ONE_URL_FIELD = "end_card_font_one_url";
export const BRAND_SLIDE_FONT_ONE_CSS_URL_FIELD = "end_card_font_one_css_url";
export const BRAND_SLIDE_FONT_TWO_FIELD = "end_card_font_two";
export const BRAND_SLIDE_FONT_TWO_URL_FIELD = "end_card_font_two_url";
export const BRAND_SLIDE_FONT_TWO_CSS_URL_FIELD = "end_card_font_two_css_url";
export const BRAND_SLIDE_COLOR_ONE = "end_card_color_one";
export const BRAND_SLIDE_COLOR_TWO = "end_card_color_two";

export const MIX_MODEL_BRAND_SLIDE_COLOR_ONE = "color1";
export const MIX_MODEL_BRAND_SLIDE_COLOR_TWO = "color2";
export const MIX_MODEL_BRAND_SLIDE_FONT_1 = "font1";
export const MIX_MODEL_BRAND_SLIDE_FONT_CSS_URL_1 = "fontCSSUrl1";
export const MIX_MODEL_BRAND_SLIDE_FONT_2 = "font2";
export const MIX_MODEL_BRAND_SLIDE_FONT_CSS_URL_2 = "fontCSSUrl2";

export const FONT_FIELDS: FontFieldType[] = [PRIMARY_FONT_FIELD, SECONDARY_FONT_FIELD];

export declare type FontFieldType =
    typeof PRIMARY_FONT_FIELD
    | typeof SECONDARY_FONT_FIELD
    | typeof BRAND_SLIDE_FONT_ONE_FIELD
    | typeof BRAND_SLIDE_FONT_TWO_FIELD;

export declare type FontUrlFieldType =
    typeof PRIMARY_FONT_URL_FIELD
    | typeof SECONDARY_FONT_URL_FIELD
    | typeof BRAND_SLIDE_FONT_ONE_URL_FIELD
    | typeof BRAND_SLIDE_FONT_TWO_URL_FIELD;

export declare type FontCssUrlFieldType =
    typeof PRIMARY_FONT_CSS_URL_FIELD
    | typeof SECONDARY_FONT_CSS_URL_FIELD
    | typeof BRAND_SLIDE_FONT_ONE_CSS_URL_FIELD
    | typeof BRAND_SLIDE_FONT_TWO_CSS_URL_FIELD;

export const BUSINESS_PRIMARY_FONT_TYPE = "BusinessPrimaryFont";
export const BUSINESS_SECONDARY_FONT_TYPE = "BusinessSecondaryFont";
export const BUSINESS_BRAND_SLIDE_FONT_ONE_TYPE = "BusinessBrandSlideFontOne";
export const BUSINESS_BRAND_SLIDE_FONT_TWO_TYPE = "BusinessBrandSlideFontTwo";
export type BusinessMediaType =
    typeof BUSINESS_PRIMARY_FONT_TYPE
    | typeof BUSINESS_SECONDARY_FONT_TYPE
    | typeof BUSINESS_BRAND_SLIDE_FONT_ONE_TYPE
    | typeof BUSINESS_BRAND_SLIDE_FONT_TWO_TYPE;

export const MEDIA_TYPES_BY_FONT_CSS_URL_FIELD: { [s: string]: BusinessMediaType } = {
    primary_font_css_url: BUSINESS_PRIMARY_FONT_TYPE,
    secondary_font_css_url: BUSINESS_SECONDARY_FONT_TYPE,
};

export const BUSINESS_PRIMARY_COLOR_KEY = "primary_color";
export const BUSINESS_SECONDARY_COLOR_KEY = "secondary_color";

export const PRIMARY_TEXT_FIELD_NAME = "primary";
export const SECONDARY_TEXT_FIELD_NAME = "secondary";
export const CAPTION_TEXT_FIELD_NAME = "caption";
export const BRAND_SLIDE_TAGLINE_TEXT_FIELD_NAME = "brandSlideTagline";
export const BRAND_SLIDE_CONTACT_TEXT_FIELD_NAME = "brandSlideContact";

export const DESIGN_USE_KEY_SECONDARY = "bodyUse";
export const DESIGN_USE_KEY_PRIMARY = "headlineUse";
export const DESIGN_USE_KEY_EXTRA_CAPTION = "extraCaptionTextUseKey";

export const DESIGN_ELEMENT_PROPERTY_CATEGORY_ID_STYLE = "STYLE";
export const DESIGN_ELEMENT_PROPERTY_CATEGORY_ID_ALIGNMENT = "ALIGNMENT";
export const DESIGN_ELEMENT_PROPERTY_CATEGORY_ID_COLOR = "COLOR";
export const DESIGN_ELEMENT_PROPERTY_CATEGORY_ID_FONT = "FONT";
export const DESIGN_ELEMENT_PROPERTY_CATEGORY_ID_ANIMATION = "ANIMATION";

export type DesignElementPropertyCategoryId = typeof DESIGN_ELEMENT_PROPERTY_CATEGORY_ID_STYLE
    | typeof DESIGN_ELEMENT_PROPERTY_CATEGORY_ID_ALIGNMENT
    | typeof DESIGN_ELEMENT_PROPERTY_CATEGORY_ID_COLOR
    | typeof DESIGN_ELEMENT_PROPERTY_CATEGORY_ID_FONT
    | typeof DESIGN_ELEMENT_PROPERTY_CATEGORY_ID_ANIMATION;

export type DesignElementPropertyType = typeof DESIGN_ELEMENT_PROPERTY_TYPE_STYLE
    | typeof DESIGN_ELEMENT_PROPERTY_TYPE_ALIGN
    | typeof DESIGN_ELEMENT_PROPERTY_TYPE_COLOR
    | typeof DESIGN_ELEMENT_PROPERTY_TYPE_FONT
    | typeof DESIGN_ELEMENT_PROPERTY_TYPE_ANIMATION
    | typeof DESIGN_ELEMENT_PROPERTY_TYPE_LIST;
export const DESIGN_ELEMENT_PROPERTY_TYPE_STYLE = "STYLE";
export const DESIGN_ELEMENT_PROPERTY_TYPE_ALIGN = "ALIGNMENT";
export const DESIGN_ELEMENT_PROPERTY_TYPE_COLOR = "COLOR";
export const DESIGN_ELEMENT_PROPERTY_TYPE_FONT = "FONT";
export const DESIGN_ELEMENT_PROPERTY_TYPE_ANIMATION = "ANIMATION";
export const DESIGN_ELEMENT_PROPERTY_TYPE_LIST = "LIST";
export const DESIGN_COLOR_TYPE_HEX = "hex";

export const FOUNDATION_PRESET_ID_PREFERRED_TEXT_STYLE = "STYLE";
export const FOUNDATION_PRESET_ID_PREFERRED_TEXT_ANIMATION = "TEXT_ANIMATION";
export const FOUNDATION_PRESET_ID_PREFERRED_GLOBAL_TEXT_STYLE = "GLOBAL_TEXT_STYLE";
export const FOUNDATION_PRESET_ID_PREFERRED_GLOBAL_TEXT_ANIMATION = "GLOBAL_TEXT_ANIMATION";
export const FOUNDATION_PRESET_ID_PREFERRED_SLIDE_TRANSITION = "SLIDE_TRANSITION";
export const FOUNDATION_PRESET_ID_PREFERRED_BACKGROUND_ANIMATION = "BACKGROUND_ANIMATION";

export const SEND_STATUS_DRAFT = "draft";
export const SEND_STATUS_ERROR = "errored";
export const SEND_STATUS_UNSCHEDULED = "unscheduled";
export const SEND_STATUS_SCHEDULED = "scheduled";
export const SEND_STATUS_SENDING = "sending";
export const SEND_STATUS_POSTED = "posted";
export const SEND_STATUS_SENT = "sent";
export const SEND_STATUS_UPLOADED = "uploaded";
export const SEND_STATUS_PROCESSING = "mediaProcessing";

export const LEGACY_POST_TYPE_EXTERNAL = "external_post";
export const LEGACY_POST_TYPE_RIPL = "fanzo_post";

export const POST_TYPE_RIPL = "ripl";
export const POST_TYPE_PHOTO = "photo";
export const POST_TYPE_VIDEO = "video";
export const POST_TYPE_STATUS = "status";

export const SAVE_TO_COMPUTER_ACCOUNT_TYPE = "SaveToComputer";
export const FACEBOOK_ACCOUNT_TYPE = "Facebook";
export const FACEBOOK_INSTAGRAM_FEED_SHARE_METHOD_NAME = "facebookInstagramFeed";
export const FACEBOOK_GROUP_ACCOUNT_TYPE = "FacebookGroup";
export const FACEBOOK_PAGE_ACCOUNT_TYPE = "FacebookPage";
export const FACEBOOK_INSTAGRAM_ACCOUNT_TYPE = "FacebookInstagram";
export const INSTAGRAM_ACCOUNT_TYPE = "Instagram";
export const LINKEDIN_ACCOUNT_TYPE = "LinkedIn";
export const TWITTER_ACCOUNT_TYPE = "Twitter";
export const YOUTUBE_ACCOUNT_TYPE = "YouTube";
export const SQUARE_ACCOUNT_TYPE = "Square";
export const EPIDEMIC_SOUND_ACCOUNT_TYPE = "EpidemicSound";

export const COULD_NOT_UPDATE_EMAIL_ERROR_MESSAGE = "Error could not update email";

export const ANIMATION_OUTPUT_TYPE = "animation";
export const HD_ANIMATION_OUTPUT_TYPE = "HDanimation";
export const IMAGE_OUTPUT_TYPE = "image";
export const OUTPUT_MODE_STATIC = "static";
export const OUTPUT_MODE_ANIMATED = "animated";

export const MUSIC_TYPE_RIPL_MUSIC = "ripl";
export const MUSIC_TYPE_PERSONAL_MUSIC = "personal";
export const MUSIC_TYPE_VIDEO_AUDIO = "videoAudio";
export const MUSIC_TYPE_EPIDEMIC_SOUND = "epidemicSound";
export const NO_AUDIO_DISPLAY_NAME = "No audio";
export const VIDEO_AUDIO_DISPLAY_NAME = "Video audio";
export const CUSTOM_MUSIC_CATEGORY = "Custom";

export const DESIGN_DISPLAY_MODE_ANIMATED_PREVIEW = "animatedPreview";
export const DESIGN_DISPLAY_MODE_EDITOR = "editor";
export const DESIGN_DISPLAY_MODE_PREVIEW = "preview";

export const MEDIA_SOURCE_STOCK_MEDIA = "pickPhotoStockMedia";

export const VIDEO_CONVERSION_STARTING: ConvertVideoState = "starting";
export const VIDEO_CONVERSION_CONNECTING: ConvertVideoState = "connecting";
export const VIDEO_CONVERSION_RECEIVING_FILE: ConvertVideoState = "receiving_file";
export const VIDEO_CONVERTING: ConvertVideoState = "converting";
export const VIDEO_CONVERSION_S3_UPLOADING: ConvertVideoState = "s3_uploading";
export const VIDEO_CONVERSION_MEDIA_DOWNLOADING: ConvertVideoState = "s3_downloading";

export const VIDEO_TRIM_STARTING: TrimVideoState = "starting";
export const VIDEO_TRIM_CONNECTING: TrimVideoState = "connecting";
export const VIDEO_TRIM_DOWNLOADING: TrimVideoState = "s3_downloading";
export const VIDEO_TRIMMING: TrimVideoState = "trimming";
export const VIDEO_TRIM_UPLOADING: TrimVideoState = "s3_uploading";
export const VIDEO_DURATION_NOT_FOUND: number = -1;

export const VIDEO_CONVERSION_PROGRESS_STATES: ConvertVideoState[] = [
    VIDEO_CONVERSION_STARTING,
    VIDEO_CONVERSION_CONNECTING,
    VIDEO_CONVERSION_RECEIVING_FILE,
    VIDEO_CONVERSION_MEDIA_DOWNLOADING,
    VIDEO_CONVERTING,
    VIDEO_CONVERSION_S3_UPLOADING,
];
export const VIDEO_TRIM_PROGRESS_STATES: TrimVideoState[] = [
    VIDEO_TRIM_STARTING,
    VIDEO_TRIM_CONNECTING,
    VIDEO_TRIM_DOWNLOADING,
    VIDEO_TRIMMING,
    VIDEO_TRIM_UPLOADING,
];

export const NOTICE_ACTION_NAME_SUBSCRIBE = "subscribe";
export const NOTICE_ACTION_NAME_NO_ACTION = "noAction";
export const NOTICE_ACTION_NAME_OPEN_URL = "openUrl";

export const NOTICE_ACTION_SUPPORTED_NAMES = [NOTICE_ACTION_NAME_NO_ACTION,
                                              NOTICE_ACTION_NAME_SUBSCRIBE,
                                              NOTICE_ACTION_NAME_OPEN_URL,
];
export type SupportedNoticeActionName =
    typeof NOTICE_ACTION_NAME_NO_ACTION
    | typeof NOTICE_ACTION_NAME_SUBSCRIBE
    | typeof NOTICE_ACTION_NAME_OPEN_URL;

export const COLLECTION_TYPE_USER_RECENT_POSTS = "user_recent_posts";

export const COACH_MARK_NAME_KEY_REFER_A_FRIEND_BUTTON = "referAFriendButton";
export const COACH_MARK_NAME_KEY_STYLIZED_TEXT = "stylizedTextButton";
export const COACH_MARK_NAME_KEY_MORE_LAYOUTS = "moreLayoutsButton";

export const BACKGROUND_MEDIA_STATUS_LIVE = "LIVE";
export const BACKGROUND_MEDIA_STATUS_DEVELOPMENT = "DEVELOPMENT";
export const BACKGROUND_MEDIA_STATUS_RETIRED = "RETIRED";

export type BackgroundMediaStatus =
    typeof BACKGROUND_MEDIA_STATUS_LIVE
    | typeof BACKGROUND_MEDIA_STATUS_DEVELOPMENT
    | typeof BACKGROUND_MEDIA_STATUS_RETIRED;

export const TEXT_STYLE_STATUS_LIVE = "LIVE";
export const TEXT_STYLE_STATUS_DEVELOPMENT = "DEVELOPMENT";

export type TextStyleStatus =
    typeof TEXT_STYLE_STATUS_LIVE
    | typeof TEXT_STYLE_STATUS_DEVELOPMENT;
