import * as React from "react";
import { PaymentMethodInfo } from "../PaymentMethodInfo";

export interface DefaultStripePaymentMethodProps
{
    defaultStripePaymentMethod: StripePaymentMethodData;
}

export interface DefaultStripePaymentMethodDispatchProps
{
    onEditDefaultPaymentMethodClicked(): void;
}

export default class DefaultStripePaymentMethod extends React.PureComponent<DefaultStripePaymentMethodProps & DefaultStripePaymentMethodDispatchProps>
{
    public render()
    {
        return <div className="subscriptionDetails">
            <h2 className="account defaultStripePaymentMethod">Payment Methods for Ad Purchases</h2>
            <PaymentMethodInfo
                brand={this.props.defaultStripePaymentMethod.brand}
                last_four_digits={this.props.defaultStripePaymentMethod.last_four_digits}
                children={this.renderEditButton()}
            />
        </div>;
    }

    private renderEditButton(): JSX.Element
    {
        return (<span className="editButton"
                      onClick={this.props.onEditDefaultPaymentMethodClicked}>
            Edit
        </span>);
    }
}
