import * as React from "react";

export interface LogoutLinkDispatchProps
{
    handleLogout();
}

export class LogoutLink extends React.PureComponent<LogoutLinkDispatchProps>
{
    public render()
    {
        return (
            <span className="logoutLink" onClick={this.props.handleLogout}>Log out</span>
        );
    }
}
