import * as React from "react";

export interface AlkaiCrossPromoDialogProps
{
    imageUrl: string;
}

export class AlkaiCrossPromoDialogContent extends React.PureComponent<AlkaiCrossPromoDialogProps>
{
    public render()
    {
        return (
            <div>
                <img className={"alkaiImage"} src={this.props.imageUrl} alt={"Meet Alkai by Ripl"}/>
                <div className={"alkaiCheckbox"}>
                    <input type="checkbox" id="alkaiCheckbox" name="alkaiCheckbox" value="false"/>
                    <label htmlFor="alkaiCheckbox">Don't show this again</label>
                </div>
            </div>
        );
    }
}
