import * as React from "react";
import { map } from "lodash";
import { ColorSwatch } from "./ColorSwatch";
import { DEFAULT_COLOR_SUGGESTIONS } from "../../helpers";

export interface ColorSuggestionsProps
{
    title?: string;
    colors?: string[];
    currentColorHex: string;
}

export interface ColorSuggestionsDispatchProps
{
    onColorTapped( hex: string );
}

export class ColorSuggestions extends React.PureComponent<ColorSuggestionsProps & ColorSuggestionsDispatchProps>
{
    public render()
    {
        return (<div className="colorSuggestions">
            {this.props.title && <h5 className="title">{this.props.title}</h5>}
            <div className="swatches">
                {map( this.getColorSuggestions(),
                    ( swatchHex ) => <ColorSwatch {...this.props} key={swatchHex} swatchHex={swatchHex}/> )}
            </div>
        </div>);
    }

    private getColorSuggestions = () =>
    {
        return this.props.colors || DEFAULT_COLOR_SUGGESTIONS;
    }
}
