import * as React from "react";
import { SocialAccountConnectButton, SocialAccountConnectButtonProps } from "./SocialAccountConnectButton";

export interface FauxConnectButtonDispatchProps
{
    onConnectClicked();
}

export class FauxConnectButton extends React.PureComponent<SocialAccountConnectButtonProps & FauxConnectButtonDispatchProps>
{
    public render()
    {
        return (
            <SocialAccountConnectButton
                onClick={this.onClick}
                buttonText={this.props.buttonText}
                label={this.props.label}
                className={this.props.className}/>
        );
    }

    private onClick = () =>
    {
        this.props.onConnectClicked();
    };
}
