import { createAction } from "redux-actions";
import { ActionCreator } from "../helpers";

const actionCreator = new ActionCreator( "LOGIN" );

const loginActionsTypes = {
    hasLoggedInBefore: {
        set: actionCreator.generateType( "HAS_LOGGED_IN_BEFORE", "SET" ),
    },
};

export const loginActions = {
    setHasLoggedInBefore: createAction<boolean>( loginActionsTypes.hasLoggedInBefore.set ),
};
