import { connect } from "react-redux";
import { StoreState } from "../../../_types/index";
import { getAnnualPlanForTier, getMonthlyPlanForTier } from "../../../ducks/pricing";
import {
    PremiumFocusedUpsellWithAllPlansPriceBlockSection,
    PremiumFocusedUpsellWithAllPlansPriceBlockSectionProps,
} from "../PremiumFocusedUpsellWithAllPlansPriceBlockSection";
import { RIPL_TIER_BASE, RIPL_TIER_PREMIUM } from "../../../helpers";

export interface PremiumFocusedUpsellWithAllPlansPriceBlockSectionContainerProps
{
    selectedPlan: StripePlanData;
    handlePlanSelected( StripePlanData, ProductTier );
}

const mapStateToProps = ( storeState: StoreState,
                          ownProps: PremiumFocusedUpsellWithAllPlansPriceBlockSectionContainerProps ):
    PremiumFocusedUpsellWithAllPlansPriceBlockSectionProps =>
{
    return {
        premiumPlans: [
            { plan: getAnnualPlanForTier( storeState, RIPL_TIER_PREMIUM ), tier: RIPL_TIER_PREMIUM },
            { plan: getMonthlyPlanForTier( storeState, RIPL_TIER_PREMIUM ), tier: RIPL_TIER_PREMIUM },
        ],
        basePlans: [
            { plan: getAnnualPlanForTier( storeState, RIPL_TIER_BASE ), tier: RIPL_TIER_BASE },
            { plan: getMonthlyPlanForTier( storeState, RIPL_TIER_BASE ), tier: RIPL_TIER_BASE },
        ],
        monthlyPremiumPlan: getMonthlyPlanForTier( storeState, RIPL_TIER_PREMIUM ),
        ...ownProps,
    };
};

export default connect(
    mapStateToProps,
    {},
)( PremiumFocusedUpsellWithAllPlansPriceBlockSection );
