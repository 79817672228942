import { Action } from "redux-actions";
import { ReducerCreator, scheduledActionsSchema, updateLookupWithAction } from "../helpers";
import { scheduledActionsActions } from "../actions";
import { createDefaultDataLookup } from "./dataLookup";
import { ScheduledActionsState, StoreState } from "../_types";
import { sortBy } from "lodash";

const defaultState: ScheduledActionsState = {
    ...createDefaultDataLookup(),
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( scheduledActionsActions.loadedSuccess, scheduledActionsUpdate );
export default reducerCreator.createReducer();

function scheduledActionsUpdate( state: ScheduledActionsState, action: Action<NormalizrData> ): ScheduledActionsState
{
    return updateLookupWithAction( scheduledActionsSchema, action, state, false );
}

export function getOrderedRecommendedPosts( state: StoreState )
{
    return sortBy( state.scheduledActions.idToObj, ["orderedIndex"] );
}
