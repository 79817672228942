import * as React from "react";
import { TIER_SUPPORTED_FEATURES_DESCRIPTIONS } from "../helpers";
import { map } from "lodash";

export function TiersExplanationDesktopFeatureList()
{
    const tierFeatureDescriptionList = map(
        TIER_SUPPORTED_FEATURES_DESCRIPTIONS,
        ( featureDescription, featureKey ) => <div key={featureKey} className="featureItem">{featureDescription}</div>,
    );

    return (
        <div className="featureList featureDescription">
            {tierFeatureDescriptionList}
            <div className="featureItem">Commission fee on ads</div>
        </div>
    );
}
