import * as React from "react";
import { ConnectButton } from "./ConnectButton";
import { SOCIAL_ACCOUNT_CONNECTED_SOURCE_INSTAGRAM_DONT_SEE_BUSINESS_DIALOG } from "../helpers";
import { FACEBOOK_INSTAGRAM_ACCOUNT_TYPE } from "../_types/api";
import * as InstagramHaveYouSetUpImage from "../assets/img/instagramSetup/instagramHaveYouSetUp.png";

export const InstagramDontSeeBusinessDialogContent: React.FC = () =>
{
    return (
        <div>
            <img src={InstagramHaveYouSetUpImage}/>
            <div className="content-wrap">
                <h2 className="title">Have you set up your Instagram Business account?</h2>
                <p className="body">To connect to Instagram Business:</p>
                <ol>
                    <li>Make sure your Instagram is set to a business account.</li>
                    <li>Make sure you have given permission to the connected Facebook Business page.</li>
                </ol>
            </div>
        </div>
    );
};

export const InstagramDontSeeBusinessFooterConnectButton: React.FC = () =>
{
    return (
        <ConnectButton
            className="connectBtn"
            accountType={FACEBOOK_INSTAGRAM_ACCOUNT_TYPE}
            trackingSource={SOCIAL_ACCOUNT_CONNECTED_SOURCE_INSTAGRAM_DONT_SEE_BUSINESS_DIALOG}
            showInstagramSetupModalFirst={false}
            buttonText="Connect"
        />
    );
};
