import { connect } from "react-redux";
import { Dispatch } from "redux";
import { store } from "../../store";
import { uiActions } from "../../actions";
import { getCustomizableCanvas } from "../../ducks/ui";
import { ZoomControl, ZoomControlDispatchProps, ZoomControlProps } from "../ZoomControl";
import { StoreState } from "../../_types";

const mapStateToProps = ( storeState: StoreState ): ZoomControlProps =>
{
    return {
        activeZoomControl: storeState.ui.activeZoomControls,
        designZoomData: storeState.ui.designZoomData,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): ZoomControlDispatchProps =>
{
    return {
        onValueChange: ( value, id ) =>
        {
            const state = store.getState();
            const canvas = getCustomizableCanvas( state );
            dispatch( uiActions.designZoomUpdated( [{ id, value }] ) );
            canvas.tellDesignToUpdateZoomData( { id, value } );
        },

        onAfterValueChange: ( value, id ) =>
        {
            const state = store.getState();
            const canvas = getCustomizableCanvas( state );
            canvas.tellDesignToSaveZoomData( { id, value } );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( ZoomControl );
