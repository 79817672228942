import "../profileReactRendering";
import * as React from "react";
import { hot } from "react-hot-loader";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";

interface RootProps
{
    store: any;
    history: any;
    persistor: any;
}

class Root extends React.PureComponent<RootProps>
{
    public render()
    {
        const { store, history, persistor } = this.props;
        return (
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <ConnectedRouter history={history}>
                        <App/>
                    </ConnectedRouter>
                </PersistGate>
            </Provider>
        );
    }
}

export default hot( module )( Root );
