import * as tinycolor from "tinycolor2";

export const browserSupportsEyedropper = () =>
{
    return "EyeDropper" in window;
};

export const convertColorToHexString = ( color ) =>
{
    return tinycolor( color ).toHexString();
};

export const getEyedropperColorHex = async () =>
{
    try
    {
        // @ts-ignore
        const eyeDropper = new EyeDropper();
        const selectedColor = await eyeDropper.open();
        return convertColorToHexString( selectedColor.sRGBHex );
    }
    catch (err)
    {
        return null;
    }
};
