import { connect } from "react-redux";
import { Dispatch } from "redux";
import { store } from "../../store";
import { getDesignCatalogAspectRatio } from "../../ducks/ui";
import { catalogServices, modalServices } from "../../services";
import { getDesignSwitchingDesignsForSelectedCategory, getNewDesignIds } from "../../ducks/catalog";
import { AspectRatio, Design, DialogControlProps, StoreState } from "../../_types";
import { canDesignSupportVideoCount, isStaticOutputMode } from "../../ducks/mixModel";
import { eventTracker } from "../../helpers";
import {
    DesignSwitchingCatalogPanel,
    DesignSwitchingCatalogPanelDispatchProps,
    DesignSwitchingCatalogPanelProps,
} from "../DesignSwitchingCatalogPanel";
import { mixModelActions } from "../../actions";

const mapStateToProps = ( storeState: StoreState ): DesignSwitchingCatalogPanelProps =>
{
    const aspectRatio = getDesignCatalogAspectRatio( storeState );
    return {
        designs: getDesignSwitchingDesignsForSelectedCategory( storeState ),
        aspectRatio,
        newDesigns: getNewDesignIds( storeState ),
        isStaticOutputMode: isStaticOutputMode( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: DialogControlProps ): DesignSwitchingCatalogPanelDispatchProps =>
{
    return {
        onDesignSelected: ( design: Design, aspectRatio: AspectRatio ) =>
        {
            const state = store.getState();

            if ( !canDesignSupportVideoCount( state, design ) )
            {
                dispatch( modalServices.openDesignCanNotSupportVideoCountErrorDialog( state, design ) );
            }
            else
            {
                loadDesign( dispatch, design, aspectRatio, ownProps.closeDialog );
                dispatch( mixModelActions.designHasChanged() );
            }
        },
    };
};

function loadDesign( dispatch: Dispatch<StoreState>, design: Design, aspectRatio: AspectRatio, closeDialog: () => void )
{
    dispatch( catalogServices.selectDesign( design, aspectRatio ) );
    eventTracker.logCatalogTemplateSelected( design );
    closeDialog();
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( DesignSwitchingCatalogPanel );
