import { createAction } from "redux-actions";
import { ActionCreator } from "../helpers";
import { SocialNetworkAccountType, SubscriptionInfoData } from "../_types";

const actionCreator = new ActionCreator( "USER" );

const userActionsTypes = {
    login: actionCreator.generateTypes( "LOGIN" ),
    connect: actionCreator.generateTypes( "CONNECT" ),
    me: actionCreator.generateTypes( "ME" ),
    update: actionCreator.generateTypes( "UPDATE" ),
    disconnect: {
        failure: actionCreator.generateType( "DISCONNECT", "FAILURE" ),
    },
    socialNetworkAccount: {
        inaccessible: actionCreator.generateType( "SOCIAL_NETWORK_ACCOUNT", "INACCESSIBLE" ),
        hasAllPermissions: actionCreator.generateType( "SOCIAL_NETWORK_ACCOUNT", "HAS_ALL_PERMISSIONS" ),
    },
    subscribe: actionCreator.generateTypes( "SUBSCRIBE" ),
    subscriptionInfo: actionCreator.generateTypes( "SUBSCRIPTION_INFO" ),
    stockMediaTerms: {
        updated: actionCreator.generateType( "STOCK_MEDIA_TERMS", "UPDATED" ),
    },
    testDrive: {
        accepted: actionCreator.generateType( "TEST_DRIVE", "ACCEPTED" ),
    },
    newBusinessDialog: {
        shown: actionCreator.generateType( "NEW_BUSINESS_DIALOG", "SHOWN" ),
    },
    inspirePage: {
        seen: actionCreator.generateType( "INSPIRE_PAGE", "SEEN" ),
    },
    firstProPost: {
        shared: actionCreator.generateType( "FIRST_PRO_POST", "SHARED" ),
    },
    showYouAround: {
        displayed: actionCreator.generateType( "SHOW_YOU_AROUND", "DISPLAYED" ),
    },
    postIntents: {
        displayed: actionCreator.generateType( "POST_INTENTS", "DISPLAYED" ),
        closed: actionCreator.generateType( "POST_INTENTS", "CLOSED" ),
    },
    safariAudioSyncWarning: {
        displayed: actionCreator.generateType( "SAFARI_AUDIO_SYNC_WARNING", "DISPLAYED" ),
    },
    currencyCode: {
        set: actionCreator.generateType( "CURRENCY_CODE", "SET" ),
    },
    stripeUserPaymentMethod: {
        success: actionCreator.generateType( "STRIPE_USER_PAYMENT_METHOD", "SUCCESS" ),
    },
    authToken: {
        set: actionCreator.generateType( "AUTH_TOKEN", "SET" ),
    },
    clientId: {
        set: actionCreator.generateType( "CLIENT_ID", "SET" ),
    },
    epidemicSound: {
        reauthorizationRequired: actionCreator.generateType( "EPIDEMIC_SOUND", "REAUTHORIZATION_REQUIRED" ),
    },
    hasOptedOutOfAlkaiCrossPromo: {
        set: actionCreator.generateType( "HAS_OPTED_OUT_OF_ALKAI_CROSS_PROMO", "SET" ),
    },
    hasSeenAlkaiCrossPromoCount: {
        updated: actionCreator.generateType("HAS_SEEN_ALKAI_CROSS_PROMO_COUNT", "UPDATED"),
    },
    alkaiCrossPromoLastSeenDate: {
        updated: actionCreator.generateType( "ALKAI_CROSS_PROMO_LAST_SEEN_DATE", "UPDATED"),
    },
};

export const userActions = {
    loginSuccess: createAction<NormalizrData>( userActionsTypes.login.success ),
    loginFailure: createAction<string>( userActionsTypes.login.failure ),
    connectSuccess: createAction<NormalizrData>( userActionsTypes.connect.success ),
    connectFailure: createAction<string>( userActionsTypes.connect.failure ),
    disconnectFailure: createAction<string>( userActionsTypes.disconnect.failure ),
    meSuccess: createAction<NormalizrData>( userActionsTypes.me.success ),
    meFailure: createAction<string>( userActionsTypes.me.failure ),
    socialNetworkAccountInaccessible: createAction<SocialNetworkAccountType>( userActionsTypes.socialNetworkAccount.inaccessible ),
    socialNetworkAccountHasPermissions: createAction<string[]>( userActionsTypes.socialNetworkAccount.hasAllPermissions ),
    updateSuccess: createAction<NormalizrData>( userActionsTypes.update.success ),
    updateFailure: createAction<string>( userActionsTypes.update.failure ),
    subscribeSucceeded: createAction( userActionsTypes.subscribe.success ),
    subscribeFailed: createAction( userActionsTypes.subscribe.failure ),
    subscriptionInfoSuccess: createAction<SubscriptionInfoData>( userActionsTypes.subscriptionInfo.success ),
    subscriptionInfoFailure: createAction<string>( userActionsTypes.subscriptionInfo.failure ),
    stockMediaTermsUpdated: createAction<boolean>( userActionsTypes.stockMediaTerms.updated ),
    stripeUserPaymentMethodSuccess: createAction<StripePaymentMethodData>( userActionsTypes.stripeUserPaymentMethod.success ),
    setAuthToken: createAction<string>( userActionsTypes.authToken.set ),
    setClientId: createAction<string>( userActionsTypes.clientId.set ),
    testDriveAccepted: createAction( userActionsTypes.testDrive.accepted ),
    seenInspirePage: createAction( userActionsTypes.inspirePage.seen ),
    firstProPostShared: createAction( userActionsTypes.firstProPost.shared ),
    showYouAroundDisplayed: createAction( userActionsTypes.showYouAround.displayed ),
    postIntentsDisplayed: createAction( userActionsTypes.postIntents.displayed ),
    postIntentsClosed: createAction( userActionsTypes.postIntents.closed ),
    safariAudioSyncWarningDisplayed: createAction( userActionsTypes.safariAudioSyncWarning.displayed ),
    setCurrencyCode: createAction<CurrencyCode>( userActionsTypes.currencyCode.set ),
    epidemicSoundReauthorizationRequired: createAction( userActionsTypes.epidemicSound.reauthorizationRequired ),
    hasOptedOutOfAlkaiCrossPromo: createAction(userActionsTypes.hasOptedOutOfAlkaiCrossPromo.set),
    hasSeenAlkaiCrossPromoCount: createAction(userActionsTypes.hasSeenAlkaiCrossPromoCount.updated),
    alkaiCrossPromoLastSeenDate: createAction<string>(userActionsTypes.alkaiCrossPromoLastSeenDate.updated),
};
