import { connect } from "react-redux";
import { Dispatch } from "redux";
import GradientLoginForm, { LoginFormDispatchProps, LoginFormProps } from "../GradientLoginForm";
import { StoreState } from "../../_types";
import { userServices } from "../../services";
import { getChannelTrackingParameters } from "../../ducks/tracking";
import { store } from "../../store";
import { CredentialResponse } from "@react-oauth/google";

const mapStateToProps = ( state: StoreState, ownProps: LoginFormProps ): LoginFormProps =>
{
    return ownProps;
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): LoginFormDispatchProps =>
{
    const storeState = store.getState();
    return {
        handleGoogleLoginResponse( tokenResponse: CredentialResponse )
        {
            const channelTrackingParameters = getChannelTrackingParameters( storeState );
            const createBusinessIfNoneExist = true;
            dispatch( userServices.clientGoogleLogin( tokenResponse.credential, channelTrackingParameters, createBusinessIfNoneExist ) );
        },
        onEmailLoginAttempt: ( emailAddress: string, password: string ) =>
        {
            const channelTrackingParameters = getChannelTrackingParameters( storeState );
            dispatch( userServices.loginToEmailAccount( emailAddress, password, channelTrackingParameters ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( GradientLoginForm );
