import * as React from "react";
import { ConnectButton } from "./ConnectButton";
import { SOCIAL_ACCOUNT_CONNECTED_SOURCE_INSTAGRAM_SETUP_DIALOG } from "../helpers";
import * as InstagramBusinessSetupImage from "../assets/img/instagramSetup/instagramBusinessSetupImage.png";
import { FACEBOOK_INSTAGRAM_ACCOUNT_TYPE } from "../_types/api";

export const InstagramWarmupDialogContent: React.FC = () =>
{
    return (
        <div>
            <img src={InstagramBusinessSetupImage}/>
            <div className="content-wrap">
                <h2 className="title">One-tap share to Instagram Business</h2>
                <p className="body">Since Facebook owns Instagram, we need your Facebook permission to share to your Instagram Business account. Make
                                    sure your Instagram account is set to Business.
                </p>
            </div>
        </div>
    );
};

export const InstagramWarmupFooterConnectButton: React.FC = () =>
{
    return (
        <ConnectButton
            className="connectBtn"
            accountType={FACEBOOK_INSTAGRAM_ACCOUNT_TYPE}
            trackingSource={SOCIAL_ACCOUNT_CONNECTED_SOURCE_INSTAGRAM_SETUP_DIALOG}
            showInstagramSetupModalFirst={false}
            buttonText="Connect"
        />
    );
};
