import * as React from "react";
import { PostPreview } from "./PostPreview";
import Button from "react-md/lib/Buttons/Button";
import Toolbar from "react-md/lib/Toolbars/Toolbar";
import Divider from "react-md/lib/Dividers/Divider";
import { eventTracker, postHelper } from "../helpers";
import SelfSavingSchedulePostDialogContainer from "./containers/SelfSavingSchedulePostDialog.container.tsx";
import { isDownloadablePost, isEditablePost, isExternalPost, wasPostedAsFacebookAd } from "../ducks/post";
import { DialogControlProps, Post } from "../_types";
import { ScheduleIconImage } from "./icons/ScheduleIconImage";
import { EditIconImage } from "./icons/EditIconImage";
import { DownloadIconImage } from "./icons/DownloadIconImage";
import { DeleteIconImage } from "./icons/DeleteIconImage";
import { PostDetailsOutputListItem } from "./PostDetailsOutputListItem";
import classNames from "classnames";

export interface PostDetailsDialogProps extends DialogControlProps
{
    post: Post;
}

export interface PostDetailsDialogDispatchProps
{
    deletePost: ( post: Post ) => void;
    editPost: ( post: Post, hasAdData: boolean ) => void;
    downloadPost: ( post: Post ) => void;
    reschedulePost: ( post: Post ) => void;
    hideExternalPost: ( post: Post ) => void;
}

export class PostDetailsDialog extends React.PureComponent<PostDetailsDialogProps & PostDetailsDialogDispatchProps>
{
    constructor( props )
    {
        super( props );
        const postId = this.props.post ? this.props.post.id : null;
        eventTracker.logPostDetailsDisplayed( postId );
    }

    public render()
    {
        const { post } = this.props;

        if ( !post )
        {
            return (<div></div>);
        }
        return (
            <div className="postDetailsDialog">
                <Toolbar
                    fixed
                    colored
                    titleId="simple-full-page-dialog-title"
                    title="Post details"
                    titleClassName="postDetailTitle"
                    actions={<Button icon={true}
                                     className="backArrow"
                                     iconClassName="material-icons"
                                     iconChildren="arrow_back_ios"
                                     onClick={this.props.closeDialog}/>}/>
                <div className="md-toolbar-relative">
                    <div className="actions">
                        <SelfSavingSchedulePostDialogContainer scheduledSendDatetime={post.scheduled_send_at}/>
                        {this.showRescheduleButton() &&
                         <Button flat
                                 iconEl={<ScheduleIconImage/>}
                                 onClick={this.handleReschedule}>Reschedule</Button>
                        }
                        {isEditablePost( post ) &&
                         <Button flat
                                 iconEl={<EditIconImage/>}
                                 onClick={this.handleEdit}>Edit</Button>
                        }
                        {isDownloadablePost( post ) &&
                         <Button flat
                                 iconEl={<DownloadIconImage/>}
                                 onClick={this.handleDownload}>Download</Button>
                        }
                        {!isExternalPost( post ) &&
                         <Button flat
                                 iconEl={<DeleteIconImage/>}
                                 onClick={this.handleDelete}>Delete</Button>
                        }
                        {isExternalPost( post ) &&
                         <Button flat
                                 iconEl={<DeleteIconImage/>}
                                 onClick={this.handleHide}>Hide</Button>
                        }
                    </div>
                    <Divider className="divider"/>
                    <div className="detailsColumns">
                        <div className="detailsPreview">
                            <div className="postStatus">{postHelper.getStatusText( post )}</div>
                            <div className="postDateTime">{postHelper.getDisplayablePostDateBasedOnStatus( post )}</div>
                            <div className={classNames( "postShareToAccounts", "socialAccountList" )}>
                                {post.social_network_accounts.map( ( account ) =>
                                {
                                    return <PostDetailsOutputListItem account={account} key={account.name + account.account_type}/>;
                                } )}
                            </div>
                            <div className="video">
                                <PostPreview controls={true} autoplay={false} playOnMouseOver={false} post={post}/>
                            </div>
                            <div className="caption">{post.content}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private showRescheduleButton = () =>
    {
        return postHelper.isScheduled( this.props.post );
    }

    private handleReschedule = () =>
    {
        this.props.reschedulePost( this.props.post );
    }

    private handleEdit = () =>
    {
        const post = this.props.post;
        const hasAdData = wasPostedAsFacebookAd( post );
        this.props.editPost( post, hasAdData );
    }

    private handleDelete = () =>
    {
        this.props.deletePost( this.props.post );
    }

    private handleHide = () =>
    {
        this.props.hideExternalPost( this.props.post );
    }

    private handleDownload = () =>
    {
        this.props.downloadPost( this.props.post );
    }

}
