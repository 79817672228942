import * as React from "react";
import { CSSProperties } from "react";
import { has } from "lodash";

export interface FlagProps
{
    flagTypeConfig: FlagTypeConfig;
    optionalClass?: string;
    inlineStyle?: CSSProperties;
}

export interface FlagTypeConfig
{
    text: string;
    class: string;
    icon?: string;
}

export class Flag extends React.PureComponent<FlagProps>
{
    public render()
    {
        const { optionalClass, inlineStyle, flagTypeConfig } = this.props;
        return (
            <div className={`flag ${optionalClass} ${flagTypeConfig.class}`}
                 style={inlineStyle}>
                <div className="innerWrap">
                    {has( flagTypeConfig, "icon" ) &&
                     <img className="flagIcon" src={flagTypeConfig.icon}/>
                    }
                    <span className="flagText">
                    {flagTypeConfig.text}
                    </span>
                </div>
            </div>
        );
    }
}
