import { Dispatch } from "redux";
import { mixModelActions, uiActions, userActions } from "../actions";
import {
    EDITOR_TYPES,
    EPIDEMIC_SOUND_TRACK_DOWNLOAD_ERRORS,
    EPIDEMIC_SOUND_TRACK_NOT_FOUND,
    EPIDEMIC_SOUND_TRACK_UNABLE_TO_LOAD,
    ERROR_TITLE_EPIDEMIC,
    eventTracker,
    musicHelper,
    normalizeEpidemicCollections,
    normalizeEpidemicSoundTracks,
    oauth,
    OAUTH_ACCOUNT_CONNECTED_SOURCE_MUSIC_PICKER,
    requests,
} from "../helpers";
import { StoreState } from "../_types";
import { ProcessCollection, ProcessCollectionItem, ProcessTrackingKey } from "../ducks/ui";
import { epidemicSoundCatalogActions } from "../actions/epidemicSoundCatalog.actions";
import {
    EPIDEMIC_SOUND_ACCOUNT_TYPE,
    EpidemicSoundCatalogAPI,
    EpidemicSoundCollectionDetailAPI,
    EpidemicSoundCollectionsAPI,
    EpidemicSoundTrack,
    EpidemicSoundTrackAPI,
    EpidemicSoundTrackDownloadAPI,
} from "../_types/api";
import {
    getAllEpidemicSoundTracks,
    getEpidemicSoundTrackById,
    hasEpidemicSoundTrackById,
    isEpidemicSoundTrackReady,
} from "../ducks/epidemicSoundCatalog";
import { store } from "../store";
import { eq, get, isNil, map, uniq } from "lodash";
import { getCurrentMusic } from "../ducks/musicCatalog";
import { modalServices } from "./modal.services";
import { getOriginalMixModelMusic } from "../ducks/mixModel";
import { epidemicSoundCollectionActions } from "../actions/epidemicSoundCollections.actions";
import { getCollectionsLinks } from "../ducks/epidemicSoundCollections";

export const epidemicSoundCatalogServices = {
    loadFreeEpidemicSoundCatalog,
    downloadEpidemicSoundTrackData,
    refreshCurrentMixMusicIfEpidemicSoundTrack,
    loadEpidemicUserCollections,
    loadEpidemicCollectionDetails,
};

const EPIDEMIC_SOUND_CATALOG_URL = "/musics/epidemic_sound_catalog";
const EPIDEMIC_SOUND_USER_COLLECTIONS_URL = "/musics/epidemic_sound_user_collections";
const EPIDEMIC_SOUND_COLLECTIONS_DETAILS_URL = "/musics/epidemic_sound_collection_details";

function loadFreeEpidemicSoundCatalog()
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        try
        {
            dispatch( uiActions.trackProcessAsRunning( ProcessTrackingKey.LOAD_FREE_EPIDEMIC_SOUND_CATALOG ) );
            const data = await requests.get<EpidemicSoundCatalogAPI>( EPIDEMIC_SOUND_CATALOG_URL );
            const normalizedData = normalizeEpidemicSoundTracks( data.tracks );
            dispatch( epidemicSoundCatalogActions.loadTracksSuccess( normalizedData ) );

            const freeTrackIds = uniq( map( data.tracks, ( track: EpidemicSoundTrackAPI ) => track.epidemic_id ) );
            dispatch( epidemicSoundCatalogActions.freeTrackIdsSet( freeTrackIds ) );

            dispatch( uiActions.trackProcessAsStopped( ProcessTrackingKey.LOAD_FREE_EPIDEMIC_SOUND_CATALOG ) );
            // TODO: handle success
        }
        catch (error)
        {
            dispatch( uiActions.trackProcessAsStopped( ProcessTrackingKey.LOAD_FREE_EPIDEMIC_SOUND_CATALOG ) );
        }
    };
}

function loadEpidemicUserCollections( nextCollectionUrl: string = null )
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        try
        {
            dispatch( uiActions.trackProcessAsRunning( ProcessTrackingKey.LOAD_EPIDEMIC_SOUND_USER_COLLECTIONS ) );
            const params = { next: nextCollectionUrl };
            const data = await requests.get<EpidemicSoundCollectionsAPI>( EPIDEMIC_SOUND_USER_COLLECTIONS_URL, params );
            const normalizedCollectionsData = normalizeEpidemicCollections( data.collections );
            const epidemicSoundCollectionsData = {
                data: normalizedCollectionsData,
                links: data.links,
            };
            dispatch( epidemicSoundCollectionActions.loadCollectionsSuccess( epidemicSoundCollectionsData ) );
            dispatch( uiActions.trackProcessAsStopped( ProcessTrackingKey.LOAD_EPIDEMIC_SOUND_USER_COLLECTIONS ) );
        }
        catch (error)
        {
            dispatch( uiActions.trackProcessAsStopped( ProcessTrackingKey.LOAD_EPIDEMIC_SOUND_USER_COLLECTIONS ) );
            dispatch( userActions.epidemicSoundReauthorizationRequired() );

            await dispatch( modalServices.closeAllLightboxes() );
            oauth.connectViaWindow( EPIDEMIC_SOUND_ACCOUNT_TYPE, OAUTH_ACCOUNT_CONNECTED_SOURCE_MUSIC_PICKER );
        }
    };
}

function loadEpidemicCollectionDetails( collectionId: string, nextUrl: string = null )
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        try
        {
            dispatch( uiActions.trackProcessAsRunning( ProcessTrackingKey.LOAD_EPIDEMIC_SOUND_USER_COLLECTION_DETAILS ) );
            const storeState = store.getState();
            const params = { next: nextUrl };
            const data = await requests.get<EpidemicSoundCollectionDetailAPI>( EPIDEMIC_SOUND_COLLECTIONS_DETAILS_URL + "/" + collectionId, params );

            const normalizedCollectionsData = normalizeEpidemicCollections( [data] );
            const epidemicSoundCollectionsData = {
                data: normalizedCollectionsData,
                links: getCollectionsLinks( storeState ),
            };
            dispatch( epidemicSoundCollectionActions.loadCollectionsSuccess( epidemicSoundCollectionsData ) );

            const normalizedData = normalizeEpidemicSoundTracks( data.tracks );
            dispatch( epidemicSoundCatalogActions.loadTracksSuccess( normalizedData ) );

            const activeCollectionTrackIds = map( data.tracks, ( track: EpidemicSoundTrackAPI ) => track.epidemic_id );
            dispatch( epidemicSoundCollectionActions.activeCollectionIdSet( data.id ) );
            dispatch( epidemicSoundCollectionActions.activeCollectionTrackIdsSet( activeCollectionTrackIds ) );
            dispatch( uiActions.trackProcessAsStopped( ProcessTrackingKey.LOAD_EPIDEMIC_SOUND_USER_COLLECTION_DETAILS ) );
        }
        catch (error)
        {
            dispatch( uiActions.trackProcessAsStopped( ProcessTrackingKey.LOAD_EPIDEMIC_SOUND_USER_COLLECTION_DETAILS ) );
            dispatch( userActions.epidemicSoundReauthorizationRequired() );

            await dispatch( modalServices.closeAllLightboxes() );
            await oauth.connectViaWindow( EPIDEMIC_SOUND_ACCOUNT_TYPE, OAUTH_ACCOUNT_CONNECTED_SOURCE_MUSIC_PICKER );
        }
    };
}

function downloadEpidemicSoundTrackData( epidemicSoundTrack: EpidemicSoundTrack )
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        const processCollectionTrackingKey: ProcessCollectionItem = {
            collection: ProcessCollection.FETCH_EPIDEMIC_SOUND_TRACK,
            id: epidemicSoundTrack.epidemic_id,
        };

        try
        {
            await dispatch( uiActions.trackProcessCollectionItemAsRunning( processCollectionTrackingKey ) );

            const url = `${EPIDEMIC_SOUND_CATALOG_URL}/${epidemicSoundTrack.epidemic_id}/download`;
            const data = await requests.get<EpidemicSoundTrackDownloadAPI>( url );

            const newEpidemicSoundTrack: EpidemicSoundTrack = {
                ...epidemicSoundTrack,
                ...data,
            };
            const existingEpidemicSoundTracks = getAllEpidemicSoundTracks( store.getState() );
            const newEpidemicSoundTracks = map( existingEpidemicSoundTracks, ( item: EpidemicSoundTrack ) =>
            {
                const matchedExistingItem = item.epidemic_id === newEpidemicSoundTrack.epidemic_id;
                return matchedExistingItem ? newEpidemicSoundTrack : item;
            } );
            const normalizedData = normalizeEpidemicSoundTracks( newEpidemicSoundTracks );
            await dispatch( epidemicSoundCatalogActions.loadTracksSuccess( normalizedData ) );
            await dispatch( uiActions.trackProcessCollectionItemAsStopped( processCollectionTrackingKey ) );
        }
        catch (error)
        {
            await dispatch( uiActions.trackProcessCollectionItemAsStopped( processCollectionTrackingKey ) );

            let errorMessage = EPIDEMIC_SOUND_TRACK_UNABLE_TO_LOAD;
            let onBeforeClose = null;
            if ( eq( error, EPIDEMIC_SOUND_TRACK_DOWNLOAD_ERRORS.TRACK_NOT_FOUND ) )
            {
                // this must be logged before we clear the music from the store
                eventTracker.logDownloadMusicFailed();

                errorMessage = EPIDEMIC_SOUND_TRACK_NOT_FOUND;
                dispatch( epidemicSoundCatalogActions.clearSoundTrack( epidemicSoundTrack.epidemic_id ) );
                onBeforeClose = () =>
                {
                    dispatch( uiActions.controlEditorSwitched( { editorType: EDITOR_TYPES.AUDIO_CONTROL } ) );
                };
            }

            dispatch( modalServices.openErrorDialog( ERROR_TITLE_EPIDEMIC, errorMessage, false, onBeforeClose ) );
            dispatch( userActions.epidemicSoundReauthorizationRequired() );

            await dispatch( mixModelActions.musicCleared() );
            await dispatch( uiActions.updateMusicCatalogSelection( null ) );
            await dispatch( uiActions.clearAudioPlayerMusicTrack() );

            const originalMixModelMusicId = get( getOriginalMixModelMusic( store.getState() ), "id", null );
            if ( eq( originalMixModelMusicId, epidemicSoundTrack.epidemic_id ) )
            {
                dispatch( mixModelActions.originalMusicClear() );
            }
        }
    };
}

function refreshCurrentMixMusicIfEpidemicSoundTrack()
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        try
        {
            const state = store.getState();
            const currentMusic = getCurrentMusic( state );
            if ( currentMusic && musicHelper.isMusicTypeEpidemicSound( currentMusic.type ) )
            {
                const id = currentMusic.id as string;
                let epidemicSoundTrackWithUrl = null;

                if ( !hasEpidemicSoundTrackById( state, id ) )
                {
                    epidemicSoundTrackWithUrl = getEpidemicSoundTrackById( state, currentMusic.id as string );
                }
                else if ( !isNil( currentMusic.metadata ) )
                {
                    epidemicSoundTrackWithUrl = currentMusic.metadata as EpidemicSoundTrack;
                }

                if ( !isEpidemicSoundTrackReady( store.getState(), epidemicSoundTrackWithUrl.epidemic_id ) )
                {
                    return dispatch( downloadEpidemicSoundTrackData( epidemicSoundTrackWithUrl ) );
                }
            }
        }
        catch (error)
        {
            // todo
        }
    };
}
