import { concat, filter, includes, keyBy, map, some, uniq, without } from "lodash";
import { Action } from "redux-actions";
import { EasyCaptionData, OutputType, Post, RecordingCompleteData, ShareModelState, SocialNetworkAccount, StoreState } from "../_types";
import {
    FacebookAndInstagramSocialNetworkAccountsDisabledPayload,
    ImageUploadData,
    mixModelActions,
    shareModelActions,
    ShareOutputEnabledPayload,
    SocialNetworkAccountDisconnectedPayload,
    UserBusinessActionData,
    userBusinessActions,
} from "../actions";
import {
    isAllowedSocialNetworkConnectType,
    isAllowedSocialNetworkShareType,
    MAX_CHAR_COUNT_FACEBOOK,
    MAX_CHAR_COUNT_INSTAGRAM,
    MAX_CHAR_COUNT_LINKED_IN,
    MAX_CHAR_COUNT_TWITTER,
    MAX_CHAR_COUNT_YOUTUBE,
    MAX_HASHTAG_COUNT_INSTAGRAM,
    PORTRAIT_ASPECT_RATIO,
    ReducerCreator,
    stringUtils,
} from "../helpers";
import {
    getFacebookGroupSocialNetworkAccounts,
    getFacebookInstagramSocialNetworkAccounts,
    getFacebookPageSocialNetworkAccounts,
    getFacebookSocialNetworkAccounts,
    getInstagramSocialNetworkAccounts,
    getLinkedInSocialNetworkAccounts,
    getTwitterSocialNetworkAccounts,
    getUserSocialNetworkAccounts,
    getYoutubeSocialNetworkAccounts,
} from "./user";
import { createSelector } from "reselect";
import {
    ANIMATION_OUTPUT_TYPE,
    FACEBOOK_ACCOUNT_TYPE,
    FACEBOOK_INSTAGRAM_ACCOUNT_TYPE,
    HD_ANIMATION_OUTPUT_TYPE,
    PHOTO_OUTPUT_TYPE,
    YOUTUBE_ACCOUNT_TYPE,
} from "../_types/api";

const defaultState: ShareModelState = {
    outputType: undefined,
    caption: "",
    cacheBuster: 0,
    enabledSocialNetworkIds: [],
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( mixModelActions.mixModelStarted, resetStateToDefaults );
reducerCreator.addCombinedActions( [mixModelActions.mixModelCancelled, shareModelActions.shareCompleted], resetStateToDefaults );
reducerCreator.addAction( mixModelActions.addMediaFileSuccess, handleAddMediaFileSuccess );
reducerCreator.addAction( shareModelActions.shareCancelled, handleShareCancelled );
reducerCreator.addAction( shareModelActions.snapshotSuccess, handleSnapshotSuccess );
reducerCreator.addAction( shareModelActions.videoRecordingCompleted, handleVideoRecordingCompleted );
reducerCreator.addAction( shareModelActions.contentUpdated, handleContentUpdated );
reducerCreator.addAction( shareModelActions.easyCaptionUpdated, handleEasyCaptionUpdated );
reducerCreator.addAction( shareModelActions.shareOutputEnableChanged, handleSocialNetworkAccountEnableChanged );
reducerCreator.addAction( shareModelActions.scheduledSendDateUpdated, handleScheduledSendDateUpdated );
reducerCreator.addAction( shareModelActions.outputTypeUpdated, handleOutputTypeUpdated );
reducerCreator.addAction( shareModelActions.socialNetworkAccountDisconnected, handleSocialNetworkAccountDisconnected );
reducerCreator.addAction( shareModelActions.facebookInstagramSocialNetworksDisabled, handleFacebookInstagramSocialNetworksDisabled );
reducerCreator.addAction( shareModelActions.youtubeTitleUpdated, handleYoutubeTitleUpdated );
reducerCreator.addAction( shareModelActions.youtubePrivacyUpdated, handleYoutubePrivacyUpdated );
reducerCreator.addAction( mixModelActions.importPostDataFromUserPost, handleImportPostDataFromUserPost );
reducerCreator.addAction( mixModelActions.resumeDraft, handleResumeDraft );
reducerCreator.addAction( mixModelActions.importPostDataFromCuratedPost, handleImportPostDataFromCuratedPost );
reducerCreator.addAction( mixModelActions.populateShareModelFromPost, handlePopulateShareModelFromPost );
reducerCreator.addCombinedActions( [userBusinessActions.businessSwitched,
                                    userBusinessActions.deleteSuccess],
    handleBusinessSwitchedOrDeleted );

export default reducerCreator.createReducer();

function handleShareCancelled( state: ShareModelState ): ShareModelState
{
    const { videoUrl, imageUrl, ...stateToRetain } = state;
    return {
        ...stateToRetain,
        cacheBuster: state.cacheBuster + 1,
    };
}

function handleVideoRecordingCompleted( state: ShareModelState, action: Action<RecordingCompleteData> ): ShareModelState
{
    const recordingData = action.payload;
    return {
        ...state,
        videoUrl: recordingData.url,
    };
}

function handleContentUpdated( state: ShareModelState, action: Action<string> ): ShareModelState
{
    return {
        ...state,
        caption: action.payload,
    };
}

function handleEasyCaptionUpdated( state: ShareModelState, action: Action<EasyCaptionData> ): ShareModelState
{
    const easyCaption = action.payload;
    return {
        ...state,
        previousCaption: easyCaption.caption,
        easyButtonClicks: easyCaption.clicks + 1,
    };
}

function handleSnapshotSuccess( state: ShareModelState, action: Action<string> ): ShareModelState
{
    return {
        ...state,
        snapshotUrl: action.payload,
    };
}

function handleBusinessSwitchedOrDeleted( state: ShareModelState, action: Action<UserBusinessActionData> ): ShareModelState
{
    const { enabledSocialNetworkIds, ...stateToRetain } = state;
    return {
        ...stateToRetain,
    };
}

function resetStateToDefaults( state: ShareModelState ): ShareModelState
{
    const { enabledSocialNetworkIds, facebookEnabled, saveToComputerEnabled } = state;
    return {
        ...defaultState,
        enabledSocialNetworkIds,
        facebookEnabled,
        saveToComputerEnabled,
    };
}

function handleFacebookInstagramSocialNetworksDisabled( state: ShareModelState,
                                                        action: Action<FacebookAndInstagramSocialNetworkAccountsDisabledPayload> ): ShareModelState
{
    const socialNetworkAccountIdsToRemove = map( action.payload.facebookAndInstagramSocialNetworkAccounts,
        ( socialNetworkAccount: SocialNetworkAccount ) =>
        {
            return socialNetworkAccount.id;
        } );
    const idsExcludingFacebookAndInstagram = filter( state.enabledSocialNetworkIds, ( socialNetworkId ) =>
    {
        return !includes( socialNetworkAccountIdsToRemove, socialNetworkId );
    } );
    return {
        ...state,
        facebookEnabled: false,
        enabledSocialNetworkIds: idsExcludingFacebookAndInstagram,
    };
}

function handleScheduledSendDateUpdated( state: ShareModelState, action: Action<string> ): ShareModelState
{
    return {
        ...state,
        scheduledSendDatetime: action.payload,
    };
}

function handleOutputTypeUpdated( state: ShareModelState, action: Action<OutputType> ): ShareModelState
{
    return {
        ...state,
        outputType: action.payload,
    };
}

function handleSocialNetworkAccountEnableChanged( state: ShareModelState, action: Action<ShareOutputEnabledPayload> ): ShareModelState
{
    const updatedValues: Partial<ShareModelState> = {};
    if ( action.payload.facebookEnabled !== undefined )
    {
        updatedValues.facebookEnabled = action.payload.facebookEnabled;
    }
    else if ( action.payload.saveToComputerEnabled !== undefined )
    {
        updatedValues.saveToComputerEnabled = action.payload.saveToComputerEnabled;
    }
    else
    {
        const accountId = action.payload.accountId;
        const enabledSocialNetworkIds = state.enabledSocialNetworkIds || [];
        updatedValues.enabledSocialNetworkIds = action.payload.accountEnabled ? uniq( concat( enabledSocialNetworkIds, [accountId] ) )
                                                                              : enabledSocialNetworkIds.filter( ( id ) => id !== accountId );
    }

    return {
        ...state,
        ...updatedValues,
    };
}

function handleSocialNetworkAccountDisconnected( state: ShareModelState, action: Action<SocialNetworkAccountDisconnectedPayload> ): ShareModelState
{
    const updatedValues: Partial<ShareModelState> = {};
    if ( action.payload.accountType === FACEBOOK_ACCOUNT_TYPE )
    {
        updatedValues.facebookEnabled = undefined;
    }
    else
    {
        const enabledSocialNetworkIds = state.enabledSocialNetworkIds || [];
        updatedValues.enabledSocialNetworkIds = without( enabledSocialNetworkIds, action.payload.accountId );
    }

    return {
        ...state,
        ...updatedValues,
    };
}

function handleAddMediaFileSuccess( state: ShareModelState, action: Action<ImageUploadData> ): ShareModelState
{
    if ( action.payload && action.payload.uploadFields )
    {
        const { s3_direct_url, media_type } = action.payload.uploadFields;
        if ( media_type === PHOTO_OUTPUT_TYPE )
        {
            const { snapshotUrl, ...updatedState } = state;
            return {
                ...updatedState,
                imageUrl: s3_direct_url,
            };
        }
    }
    return state;
}

function handleImportPostDataFromUserPost( state: ShareModelState, action: Action<Post> ): ShareModelState
{
    return {
        ...state,
        caption: action.payload.content,
        scheduledSendDatetime: action.payload.scheduled_send_at,
        saveToComputerEnabled: action.payload.save_to_photos_flag,
    };
}

function handleResumeDraft( state: ShareModelState, action: Action<Post> ): ShareModelState
{
    return {
        ...state,
        caption: action.payload.content,
        scheduledSendDatetime: action.payload.scheduled_send_at,
    };
}

function handleImportPostDataFromCuratedPost( state: ShareModelState, action: Action<Post> ): ShareModelState
{
    return {
        ...state,
        customMessageForCopy: action.payload.custom_message_for_copy,
    };
}

function handlePopulateShareModelFromPost( state: ShareModelState, action: Action<Post> ): ShareModelState
{
    const { output_type, poster_url, image_url, video_url } = action.payload;
    return {
        ...state,
        imageUrl: image_url,
        videoUrl: video_url,
        snapshotUrl: poster_url,
        outputType: output_type as OutputType,
    };
}

function handleYoutubeTitleUpdated( state: ShareModelState, action: Action<string> ): ShareModelState
{
    return {
        ...state,
        youtubeTitle: action.payload,
    };
}

function handleYoutubePrivacyUpdated( state: ShareModelState, action: Action<string> ): ShareModelState
{
    return {
        ...state,
        youtubePrivacyStatus: action.payload,
    };
}

export function getOutputType( state: StoreState )
{
    return state.shareModel.outputType;
}

export function cantShareWithInstagramBusiness( state: StoreState )
{
    return state.mixModel.aspectRatio === PORTRAIT_ASPECT_RATIO;
}

export function isAnimationOutputTypeFromState( state: StoreState )
{
    const outputType = getOutputType( state );
    return isAnimationOutputType( outputType );
}

export function isAnimationOutputType( outputType: OutputType )
{
    return outputType === ANIMATION_OUTPUT_TYPE || outputType === HD_ANIMATION_OUTPUT_TYPE;
}

export function isHDAnimationOutputType( state: StoreState )
{
    const outputType = getOutputType( state );
    return outputType === HD_ANIMATION_OUTPUT_TYPE;
}

export function getDisplayShareImage( state: StoreState, bustCache?: boolean )
{
    return getSnapshotUrl( state ) || getImageUrl( state, bustCache );
}

export function getImageUrl( state: StoreState, bustCache?: boolean )
{
    const { imageUrl } = state.shareModel;
    if ( bustCache )
    {
        return addCacheBuster( state, imageUrl );
    }
    return imageUrl;
}

export const getSnapshotUrl = ( state: StoreState ) => state.shareModel.snapshotUrl;

export function getVideoUrl( state: StoreState, bustCache?: boolean )
{
    const { videoUrl } = state.shareModel;
    if ( bustCache )
    {
        return addCacheBuster( state, videoUrl );
    }
    return videoUrl;
}

function addCacheBuster( state: StoreState, url: string )
{
    if ( !url )
    {
        return;
    }
    return `${url}?cacheBuster=${getShareCachebuster( state )}`;
}

export function getCaption( state: StoreState )
{
    return state.shareModel.caption;
}

export function getPreviousCaption( state: StoreState )
{
    return state.shareModel.previousCaption;
}

export function getEasyButtonClicks( state: StoreState )
{
    if ( !state.shareModel.easyButtonClicks )
    {
        return 1;
    }
    return state.shareModel.easyButtonClicks;
}

export function getCustomMessageForCopy( state: StoreState )
{
    return state.shareModel.customMessageForCopy;
}

export const getScheduledSendDatetime = ( state: StoreState ) => state.shareModel.scheduledSendDatetime;
export const isPostScheduled = ( state: StoreState ) => !!state.shareModel.scheduledSendDatetime;

export function getFacebookEnabled( state: StoreState )
{
    if ( !isAllowedSocialNetworkShareType( FACEBOOK_ACCOUNT_TYPE ) )
    {
        return false;
    }
    return !!state.shareModel.facebookEnabled;
}

export const getSaveToComputerEnabled = ( state: StoreState ) => !!state.shareModel.saveToComputerEnabled;
const getEnabledSocialNetworkIdsFromState = ( state: StoreState ) => state.shareModel.enabledSocialNetworkIds || [];

export const getEnabledSocialNetworkIds = createSelector( [
        getUserSocialNetworkAccounts,
        getOutputType,
        cantShareWithInstagramBusiness,
        getEnabledSocialNetworkIdsFromState,
    ],
    ( socialNetworkAccounts, outputType, notValidInstagramBusiness, enabledSocialNetworkIds ): number[] =>
    {
        const socialNetworkMap = keyBy( socialNetworkAccounts, ( account ) => account.id );
        return filter( enabledSocialNetworkIds, ( id ) => id && isValidNetworkId( id, outputType, notValidInstagramBusiness, socialNetworkMap ) );
    } );

export const isAnyTwitterAccountEnabled = areAnyAccountsEnabled( getTwitterSocialNetworkAccounts );
export const isAnyLinkedInAccountEnabled = areAnyAccountsEnabled( getLinkedInSocialNetworkAccounts );
export const isAnyYoutubeAccountEnabled = areAnyAccountsEnabled( getYoutubeSocialNetworkAccounts );
export const isAnyInstagramAccountEnabled = areAnyAccountsEnabled( getInstagramSocialNetworkAccounts );
export const isAnyFacebookInstagramAccountEnabled = areAnyAccountsEnabled( getFacebookInstagramSocialNetworkAccounts );
export const isAnyFacebookAccountEnabled = areAnyAccountsEnabled( getFacebookSocialNetworkAccounts );
export const isAnyFacebookGroupAccountEnabled = areAnyAccountsEnabled( getFacebookGroupSocialNetworkAccounts );
export const isAnyFacebookPageAccountEnabled = areAnyAccountsEnabled( getFacebookPageSocialNetworkAccounts );

function areAnyAccountsEnabled( getterFunctionForAllAccountsOfType )
{
    return createSelector( [getterFunctionForAllAccountsOfType, getEnabledSocialNetworkIds],
        ( allAccountsOfType: SocialNetworkAccount[], enabledSocialNetworkIds ): boolean =>
        {
            return some( allAccountsOfType, ( account: SocialNetworkAccount ) => includes( enabledSocialNetworkIds, account.id ) );
        } );
}

function isValidNetworkId( id: number, outputType, notValidInstagramBusiness, socialNetworkMap: { [id: string]: SocialNetworkAccount } )
{
    const socialNetwork = socialNetworkMap[id];
    if ( socialNetwork && socialNetwork.account_type === YOUTUBE_ACCOUNT_TYPE && !isAnimationOutputType( outputType ) )
    {
        return false;
    }
    else if ( socialNetwork && socialNetwork.account_type === FACEBOOK_INSTAGRAM_ACCOUNT_TYPE && notValidInstagramBusiness )
    {
        return false;
    }

    return socialNetwork && isAllowedSocialNetworkConnectType( socialNetwork.account_type )
           && isNetworkAuthorizedToShare( socialNetwork );
}

export const isNetworkAuthorizedToShare = ( socialNetwork: SocialNetworkAccount ) => !socialNetwork.invalid_token_error_count;

export const getShareCachebuster = ( state: StoreState ) => state.shareModel.cacheBuster;

export const getExceedsHashtagLimitError = ( state: StoreState ) =>
{
    if ( isHashtagLimitExceeded( state ) )
    {
        return "Instagram hashtag limit is 30";
    }
    return null;
};

export const getExceedsCharacterLimitError = ( state: StoreState ) =>
{
    if ( getEnabledSocialNetworkIds( state ).length === 0 )
    {
        return null;
    }

    if ( getActualCharacterCount( state ) > getMaxCharacterCount( state ) )
    {
        return "Character limit exceeded";
    }
    return null;
};

export const getActualCharacterCount = ( state: StoreState ) =>
{
    const caption = state.shareModel.caption || "";

    if ( isAnyTwitterAccountEnabled( state ) )
    {
        return stringUtils.getTwitterWeightedLength( caption );
    }
    else if ( isAnyLinkedInAccountEnabled( state ) )
    {
        // weighted unicode length
        return caption.length;
    }
    // logical length
    return getLogicalLength( caption );
};

export function getLogicalLength( caption: string ): number
{
    // counts emojis as 1 char
    const matchedText = caption.match( /(.|\s)/gu );
    return matchedText ? matchedText.length : 0;
}

export const getMaxCharacterCount = ( state: StoreState ) =>
{
    if ( isAnyTwitterAccountEnabled( state ) )
    {
        return MAX_CHAR_COUNT_TWITTER;
    }
    else if ( isAnyLinkedInAccountEnabled( state ) )
    {
        return MAX_CHAR_COUNT_LINKED_IN;
    }
    else if ( isAnyInstagramAccountEnabled( state ) || isAnyFacebookInstagramAccountEnabled( state ) )
    {
        return MAX_CHAR_COUNT_INSTAGRAM;
    }
    else if ( isAnyYoutubeAccountEnabled( state ) )
    {
        return MAX_CHAR_COUNT_YOUTUBE;
    }
    return MAX_CHAR_COUNT_FACEBOOK;
};

export const isHashtagLimitExceeded = ( state: StoreState ) =>
{
    if ( isAnyInstagramAccountEnabled( state ) || isAnyFacebookInstagramAccountEnabled( state ) )
    {
        const caption = state.shareModel.caption || "";
        const matchedText = caption.match( /#[^#\s]/g );
        if ( matchedText && matchedText.length > MAX_HASHTAG_COUNT_INSTAGRAM )
        {
            return true;
        }
    }
    return false;
};

export const shouldShowCharacterCount = ( state: StoreState ) =>
{
    const remainingCharactersCount = getRemainingCharactersCount( state );

    if ( isAnyTwitterAccountEnabled( state ) )
    {
        return remainingCharactersCount < MAX_CHAR_COUNT_TWITTER;
    }
    return remainingCharactersCount < 0;
};

export const getRemainingCharactersCount = ( state: StoreState ) =>
{
    return getMaxCharacterCount( state ) - getActualCharacterCount( state );
};

export const getYouTubeTitle = ( state: StoreState ) =>
{
    return state.shareModel.youtubeTitle;
};

export const getYouTubePrivacy = (state: StoreState ) =>
{
    return state.shareModel.youtubePrivacyStatus;
};
