import { createAction } from "redux-actions";
import { ActionCreator } from "../helpers";

const actionCreator = new ActionCreator( "FONT_CATALOG" );

const fontCatalogActionsTypes = {
    load: actionCreator.generateTypes( "LOAD" ),
    loadFont: {
        ...actionCreator.generateTypes( "LOAD_FONT" ),
        retry: actionCreator.generateType( "LOAD_FONT", "RETRY" ),
    },
    loadFonts: {
        request: actionCreator.generateType( "LOAD_FONTS", "REQUEST" ),
    },
};

export const fontCatalogActions = {
    loadSuccess: createAction<string[]>( fontCatalogActionsTypes.load.success ),
    loadFailure: createAction<string>( fontCatalogActionsTypes.load.failure ),
    loadFontsRequest: createAction<string[]>( fontCatalogActionsTypes.loadFonts.request ),
    loadFontSuccess: createAction<string>( fontCatalogActionsTypes.loadFont.success ),
    loadFontFailure: createAction<string>( fontCatalogActionsTypes.loadFont.failure ),
    loadFontRetry: createAction<string>( fontCatalogActionsTypes.loadFont.retry ),
};
