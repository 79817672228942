import { CanvasUpdater } from "./";

export class DesignMessageAwaiter
{
    private windowMessageListener: EventListener;
    private WINDOW_EVENT_MESSAGE: string = "message";
    private DEFAULT_MAX_WAIT_TIME_MS: number = 3000;
    private ENABLE_LOGGING: boolean = false;
    private iFrameId: string;
    private maxWaitTimerId: any;
    private messageReceivedPromise: Promise<boolean>;
    private promiseResolve: ( value?: (PromiseLike<boolean> | boolean) ) => void;
    private promiseReject: ( reason?: any ) => void;

    public async awaitMessage( iFrameId, messageName, maxWaitMs = this.DEFAULT_MAX_WAIT_TIME_MS )
    {
        this.iFrameId = iFrameId;
        this.messageReceivedPromise = new Promise( ( resolve, reject ) =>
        {
            this.promiseResolve = resolve;
            this.promiseReject = reject;
        } );

        this.maxWaitTimerId = setTimeout( () =>
        {
            // tslint:disable-next-line:no-console no-unused-expression
            this.ENABLE_LOGGING && console.log( "DesignMessageAwaiter", this.iFrameId, "did not get target message ", messageName, " after ",
                maxWaitMs, "ms." );
            this.cleanup();
            this.promiseReject("Design did not update in time.");
        }, maxWaitMs );

        this.windowMessageListener = ( event ) =>
        {
            if ( !event )
            {
                return;
            }

            const eventData = CanvasUpdater.parseEventData( event );
            if ( !eventData || eventData.iframeId !== this.iFrameId )
            {
                return;
            }

            if ( eventData.messageName === messageName )
            {
                // tslint:disable-next-line:no-console no-unused-expression
                this.ENABLE_LOGGING && console.log( "DesignMessageAwaiter", this.iFrameId, "got target message ", messageName );
                this.cleanup();
                this.promiseResolve();
            }
        };

        window.addEventListener( this.WINDOW_EVENT_MESSAGE, this.windowMessageListener );

        return this.messageReceivedPromise;
    }

    private cleanup()
    {
        window.clearTimeout( this.maxWaitTimerId );
        window.removeEventListener( this.WINDOW_EVENT_MESSAGE, this.windowMessageListener );
    }
}
