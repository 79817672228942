import { connect } from "react-redux";
import { StoreState } from "../../_types";
import { MediaControl, MediaControlProps } from "../MediaControl";
import { getActiveDesignMediaObject } from "../../ducks/ui";

const mapStateToProps = ( storeState: StoreState ): MediaControlProps =>
{
    return {
        activeDesignMediaObject: getActiveDesignMediaObject( storeState ),
    };
};

export default connect(
    mapStateToProps,
    () => ({}),
)( MediaControl );
