import { ActionCreator } from "../helpers";
import { createAction } from "redux-actions";

const actionCreator = new ActionCreator( "PARTNER" );

const partnerActionsTypes = {
    login_datum_id: {
        set: actionCreator.generateType( "LOGIN_DATUM_ID", "SET" ),
    },
    redirect_url: {
        set: actionCreator.generateType( "REDIRECT_URL", "SET" ),
    },
    history_referrer_index: {
        set: actionCreator.generateType( "HISTORY_REFERRER_INDEX", "SET" ),
    },
};

export const partnerActions = {
    loginDatumIdSet: createAction<string>( partnerActionsTypes.login_datum_id.set ),
    redirectUrlSet: createAction<string>( partnerActionsTypes.redirect_url.set ),
    historyReferrerIndexSet: createAction<number>( partnerActionsTypes.history_referrer_index.set ),
};
