import { createAction } from "redux-actions";
import { ActionCreator } from "../helpers";
import { UserBusiness } from "../_types";
import { ControlsConfig } from "../_types/api";

const actionCreator = new ActionCreator( "DESIGNS" );

const designActionsTypes = {
    loadControls: actionCreator.generateTypes( "LOAD_CONTROLS" ),
};

export interface DesignControlsJSONData
{
    designId: string;
    controls: ControlsConfig[];
    currentBusiness: UserBusiness;
}

export const designsActions = {
    loadControlsRequest: createAction<number>( designActionsTypes.loadControls.request ),
    loadControlsSuccess: createAction<DesignControlsJSONData>( designActionsTypes.loadControls.success ),
    loadControlsFailure: createAction<string>( designActionsTypes.loadControls.failure ),
};
