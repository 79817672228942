import * as React from "react";

interface HowToLogoutOfFacebookDialogProps
{
    buttonNameForInstructions: string;
}

export const HowToLogoutOfFacebookDialog: React.FC<HowToLogoutOfFacebookDialogProps> = ( props ) =>
{
    const { buttonNameForInstructions } = props;
    return (
        <div className="howToLogoutOfFacebook">
            <ol className="instructions">
                <li>Open a new tab in your browser and go to <a className="standardLink" href="https://www.facebook.com/" target="_blank">facebook.com</a>.</li>
                <li>Click the drop-down arrow in the upper right corner.</li>
                <li>Click "Log Out."</li>
                <li>Return to Ripl and click on the "{buttonNameForInstructions}" button.</li>
            </ol>
        </div>
    );
};
