import * as React from "react";

export const PlusIcon: React.FC<{}> = () =>
{
    return (
        <svg className="createYourOwnPlusIcon" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="7" x2="7" y1="0" y2="14" stroke="#3A3A3A" strokeWidth="2"/>
            <line x1="0" x2="14" y1="7" y2="7" stroke="#3A3A3A" strokeWidth="2"/>
        </svg>
    );
};
