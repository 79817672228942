import * as React from "react";
import { DesignMediaObject } from "../_types";
import MediaPickerContainer from "./containers/MediaPicker.container";
import SingleMediaViewContainer from "./containers/SingleMediaView.container";

export interface MediaControlProps
{
    activeDesignMediaObject?: DesignMediaObject;
}

export class MediaControl extends React.PureComponent<MediaControlProps>
{
    public render()
    {
        return (
            <div className="mediaControl">
                {this.getView()}
            </div>
        );
    }

    private getView()
    {
        if ( this.props.activeDesignMediaObject )
        {
            return (<SingleMediaViewContainer/>);
        }
        else
        {
            return (<MediaPickerContainer/>);
        }
    }
}
