import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../_types";
import { HomePostRowsPanel, HomePostRowsPanelDispatchProps, HomePostRowsPanelProps } from "../HomePostRowsPanel";
import { store } from "../../../store";
import { collectionRowsServices } from "../../../services";
import { getCurrentBusinessId } from "../../../ducks/userBusiness";
import { getCollectionRows } from "../../../ducks/collectionRows";
import { isCollabraUser } from "../../../ducks/user";
import { getBusinessTypeFilter } from "../../../ducks/ui";

const mapStateToProps = ( state: StoreState ): HomePostRowsPanelProps =>
{
    return {
        collectionRows: getCollectionRows( state ),
        isCollabraUser: isCollabraUser( state ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): HomePostRowsPanelDispatchProps =>
{
    return {
        loadCollectionRows: () =>
        {
            const state = store.getState();
            const businessTypeFilter = getBusinessTypeFilter( state );
            const businessTypeFilterId = businessTypeFilter ? businessTypeFilter.id : null;
            dispatch( collectionRowsServices.loadCollectionRowsForBusinessType( businessTypeFilterId, getCurrentBusinessId( state ) ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( HomePostRowsPanel );
