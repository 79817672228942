import * as React from "react";
import * as CollabraPlusRiplLogo from "../assets/img/collabra_plus_ripl_logo.png";
import CircularProgress from "react-md/lib/Progress/CircularProgress";

export interface CollabraCompletePageProps
{
    finishedPost: boolean;
}

export default class CollabraCompletePage extends React.PureComponent<CollabraCompletePageProps>
{
    public render()
    {
        return (
            <div className="page subscriptionPage spherebuilderRedirect">
                <div className="logo">
                    <img src={CollabraPlusRiplLogo} alt="Collabra+Ripl logo"/>
                </div>
                <div className="title">
                    <h1>Saving your content</h1>
                </div>
                <CircularProgress className="spinner large" id="spinner"/>
            </div>
        );
    }
}
