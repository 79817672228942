import * as React from "react";
import classNames from "classnames";
import { NOTICE_ACTION_NAME_NO_ACTION, NOTICE_ACTION_NAME_OPEN_URL, SupportedNoticeActionName } from "../../_types/api";
import { SupportedNotice } from "../../_types";

export interface NoticeBannerProps
{
    notice: SupportedNotice;
}

export interface NoticeBannerDispatchProps
{
    handleNoticeAction: ( noticeAction: SupportedNoticeActionName, url?: string ) => void;
}

export class NoticeBanner extends React.PureComponent<NoticeBannerProps & NoticeBannerDispatchProps>
{
    public render()
    {
        const notice = this.props.notice;
        if ( notice )
        {
            return this.createBanner( notice );
        }
        return null;
    }

    private createBanner( notice: SupportedNotice )
    {
        const { title, description, action } = notice;
        return (
            <div className={classNames( "noticeBannerContainer", { noticeClickable: action.name !== NOTICE_ACTION_NAME_NO_ACTION } )}
                 onClick={this.handleAction}>
                <div className="noticeBannerTitle">{title}</div>
                <div className="noticeBannerDescription">{description}</div>
            </div>
        );
    }

    private handleAction = () =>
    {
        const notice = this.props.notice;
        if ( notice && notice.action )
        {
            const action = notice.action;
            if (action.name === NOTICE_ACTION_NAME_OPEN_URL)
            {
                this.props.handleNoticeAction( action.name, action.url);
            }
            else if ( action.name !== NOTICE_ACTION_NAME_NO_ACTION )
            {
                this.props.handleNoticeAction( action.name );
            }
        }
    }
}
