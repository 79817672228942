import { connect } from "react-redux";
import { Dispatch } from "redux";
import { BrandLogo, BrandLogoDispatchProps, BrandLogoProps } from "../../settingsPages/BrandLogo";
import { logoServices, userServices } from "../../../services";
import { StoreState } from "../../../_types";
import { CUSTOMIZE_PAGE, eventTracker } from "../../../helpers";
import * as ClickToAddLogo from "../../../assets/img/click-to-add-logo.png";

export interface BrandLogoUploadButtonContainerProps
{
    source: string;
}

const mapStateToProps = ( storeState: StoreState ): BrandLogoProps =>
{
    return {
        brandLogo: ClickToAddLogo,
        showEditPencil: false,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: BrandLogoUploadButtonContainerProps ): BrandLogoDispatchProps =>
{
    return {
        handleLogoUpload: ( file: File ) =>
        {
            dispatch( userServices.updatePhoto( file, ownProps.source ) );
            dispatch( logoServices.setEnabled( true ) );
            eventTracker.logLogoUploadSucceeded( ownProps.source );
        },
        handleLogoClicked: ( imageUploadClicked: () => void ) =>
        {
            if ( ownProps.source === CUSTOMIZE_PAGE )
            {
                eventTracker.logCustomizeLogoUploadClicked();
            }
            eventTracker.logLogoUploadClicked( ownProps.source );
            imageUploadClicked();
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( BrandLogo );
