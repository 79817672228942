import { connect } from "react-redux";
import { Dispatch } from "redux";
import { uiActions } from "../../actions";
import { CanvasUpdater } from "../../helpers";
import DesignCanvas, { DesignCanvasDispatchProps, DesignCanvasProps } from "../DesignCanvas";
import { getDesignAspectRatio, getHideWatermark, getSelectedDesign, getWatermarkUrl, hasDataRequiredToLoad } from "../../ducks/mixModel";
import { StoreState } from "../../_types";
import { getCatalogUpdatedAtCacheBuster } from "../../ducks/catalog";

export const CUSTOMIZABLE_DESIGN_ID = "customizeCanvas";

const mapStateToProps = ( storeState: StoreState ): DesignCanvasProps =>
{
    return {
        iframeId: CUSTOMIZABLE_DESIGN_ID,
        design: getSelectedDesign( storeState ),
        editable: true,
        aspectRatio: getDesignAspectRatio( storeState ),
        watermarkUrl: getWatermarkUrl( storeState ),
        hideWatermark: getHideWatermark( storeState ),
        lastRefreshed: storeState.ui.customizePage.lastRefreshed,
        hasDataRequiredToLoad: hasDataRequiredToLoad( storeState, getSelectedDesign( storeState ) ),
        catalogUpdatedAt: getCatalogUpdatedAtCacheBuster( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): DesignCanvasDispatchProps =>
{
    return {
        registerCanvas: ( canvasUpdater: CanvasUpdater ) =>
        {
            dispatch( uiActions.registerCanvasUpdater( canvasUpdater ) );
        },
        startDesignLoadProgress( iframeId: string )
        {
            dispatch( uiActions.updateDesignLoadProgressStarted( iframeId ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( DesignCanvas );
