import * as React from "react";
import { Dispatch } from "redux";
import { InstagramWarmupDialogContent, InstagramWarmupFooterConnectButton } from "../components/InstagramWarmupDialog";
import { modalServices } from "./";
import { InstagramTutorialDialogContent, InstagramTutorialFooterConnectButton } from "../components/InstagramTutorialDialog";
import { StoreState } from "../_types";
import InstagramFooterNeedHelpButton from "../components/containers/InstagramFooterNeedHelpButton.container";
import InstagramFooterDontSeeBusinessHelpButton from "../components/containers/InstagramFooterDontSeeBusinessHelpButton.container";
import InstagramFooterWarmupHelpButton from "../components/containers/InstagramFooterWarmupHelpButton.container";
import { InstagramDontSeeBusinessDialogContent, InstagramDontSeeBusinessFooterConnectButton } from "../components/InstagramDontSeeBusinessDialog";
import { LightboxDialogIdentifierForKey } from "../helpers";

export const instagramBusinessServices = {
    displayInstagramTutorialDialog,
    displayInstagramWarmupDialog,
    displayInstagramDontSeeBusinessDialog,
};

function displayInstagramWarmupDialog()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.FACEBOOK_INSTAGRAM_WARMUP,
            hideCancel: true,
            hideConfirm: true,
            confirmLabel: "",
            width: 600,
            className: "instagramDialog instagramWarmupDialog",
            footerClassName: "instagramDialogFooter instagramWarmupDialogFooter",
            content: () => <InstagramWarmupDialogContent/>,
            closeDialogBeforeCallbacks: true,
            showCancelX: true,
            customActions: [<InstagramFooterWarmupHelpButton/>, <InstagramWarmupFooterConnectButton/>],
        } ) );
    };
}

function displayInstagramDontSeeBusinessDialog()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.FACEBOOK_INSTAGRAM_MISSING_BUSINESS_PAGE,
            hideCancel: true,
            hideConfirm: true,
            width: 600,
            className: "instagramDialog instagramDontSeeBusinessDialog",
            footerClassName: "instagramDialogFooter instagramDontSeeBusinessDialogFooter",
            content: () => <InstagramDontSeeBusinessDialogContent/>,
            closeDialogBeforeCallbacks: true,
            containFocus: false,
            showCancelX: true,
            customActions: [<InstagramFooterDontSeeBusinessHelpButton/>, <InstagramDontSeeBusinessFooterConnectButton/>],
        } ) );
    };
}

function displayInstagramTutorialDialog( onDialogCancel: () => void )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.FACEBOOK_INSTAGRAM_TUTORIAL,
            width: 1000,
            height: 675,
            hideCancel: true,
            hideAlternateAction: true,
            hideConfirm: true,
            className: "instagramDialog instagramTutorialDialog",
            footerClassName: "instagramDialogFooter instagramTutorialDialogFooter",
            showCancelX: true,
            onCancel: onDialogCancel,
            content: () => <InstagramTutorialDialogContent/>,
            customActions: [<InstagramFooterNeedHelpButton/>, <InstagramTutorialFooterConnectButton/>],
        } ) );
    };
}
