import * as React from "react";
import { MediaPickerGrid } from "./MediaPickerGrid";
import { concat, filter, isEmpty } from "lodash";
import Dropzone from "react-dropzone";
import classNames from "classnames";
import { ALLOWED_VIDEO_INPUT_TYPE, MEDIA_LIMIT, MEDIA_PICKER_NUMBER_OF_ITEMS_PER_ROW, pickingVideoIsAllowed } from "../helpers";
import { FileUploadButton } from "./FileUploadButton";
import StockMediaPickerContainer from "./editorControls/containers/StockMediaPicker.container";
import Tooltipped from "react-md/lib/Tooltips/Tooltipped";

export interface MediaInfoProps
{
    mediaUrlString: string;
    lowQuality: boolean;
}

export interface MediaPickerProps
{
    media: MediaInfoProps[];
    mediaUploads: number;
    selectedIndex: number;
    includesVideo: boolean;
    hasDesignReachedMaxVideoCountSupported: boolean;
    designSupportsVideo: boolean;
    inReplaceMediaMode: boolean;
    numberOfMediaWithoutActiveDesignMediaObject: number;
}

export interface MediaPickerDispatchProps
{
    onMediaAdded: ( files: File[] ) => void;
    onMediaRejected: ( files: File[] ) => void;
    onMediaMoved: ( oldIndex: number, newIndex: number ) => void;
    onMediaRemoved: ( index: number ) => void;
    onCaution: ( index: number ) => void;
    onMediaSelected: ( index: number ) => void;
    onMediaDeselected: () => void;
    onVideoEdit: ( videoUrl?: string ) => void;
    onMediaEdit: ( mediaUrl: string ) => void;
}

interface MediaPickerState
{
    dragging: boolean;
}

export class MediaPicker extends React.PureComponent<MediaPickerProps & MediaPickerDispatchProps, MediaPickerState>
{
    constructor( props )
    {
        super( props );
        this.state = {
            dragging: false,
        };
    }

    public render()
    {
        const dropzoneClassNames = classNames( "fileDropArea", { isEmpty: isEmpty( this.props.media ) } );
        return (
            <div className="image picker">
                <Dropzone
                    disableClick
                    disabled={this.state.dragging}
                    preventDropOnDocument={false}
                    accept={this.getAcceptedFileTypes()}
                    className={dropzoneClassNames}
                    activeClassName="active"
                    onDrop={this.handleOnDrop}>
                    {this.createMediaTray()}
                    {this.createAddMediaBtn()}
                    <StockMediaPickerContainer imageTrayClassNames={this.getImageTrayClassNames()}
                                               onVideoEdit={this.props.onVideoEdit}/>
                </Dropzone>
            </div>
        );
    }

    private shouldShowVideoOption = () =>
    {
        return pickingVideoIsAllowed( this.props.designSupportsVideo, this.props.hasDesignReachedMaxVideoCountSupported );
    }

    private createAddMediaBtn = () =>
    {
        const addMediaBtnClassNames = classNames(
            "mediaTrayBtn",
            {
                mediaTrayBtnWithMedia: this.hasMediaAdded(),
            },
        );

        const tooltipLabel = this.shouldDisableUploadButton() ? "You've reached the maximum allowed." : null;
        return (
            <Tooltipped label={tooltipLabel} position="bottom" tooltipClassName="tooltip">
                <div className="mediaTrayBtnWrap">
                    <FileUploadButton
                        id="media-upload"
                        swapTheming
                        flat
                        primary
                        multiple={!this.props.inReplaceMediaMode}
                        className={addMediaBtnClassNames}
                        disabled={this.shouldDisableUploadButton()}
                        label="Upload from computer"
                        accept={this.getAcceptedFileTypes()}
                        icon={null}
                        onFilesChanged={this.handleSelectedMediaInDialog}/>
                </div>
            </Tooltipped>);
    }

    private shouldDisableUploadButton = () =>
    {
        return this.props.numberOfMediaWithoutActiveDesignMediaObject >= MEDIA_LIMIT.PRO;
    };

    private createMediaTray = () =>
    {
        const gridClassNames = classNames( "imageContainer", { ...this.getImageTrayClassNames() } );
        const mediaTrayItems = this.props.inReplaceMediaMode ? [] : this.props.media;
        return (
            <div className={gridClassNames}>
                <MediaPickerGrid
                    items={mediaTrayItems}
                    selectedIndex={this.props.selectedIndex}
                    onSortStart={this.handleOnSortStart}
                    onSortEnd={this.handleOnSortEnd}
                    distance={2}
                    axis="xy"
                    onDeleted={this.handleOnDeleted}
                    onCaution={this.handleOnCaution}
                    onSelected={this.handleOnSelected}
                    onMediaEdit={this.props.onMediaEdit}
                />
            </div>
        );
    }

    private setDragging( dragging: boolean )
    {
        if ( this.state.dragging !== dragging )
        {
            this.setState( {
                dragging,
            } );
        }
    }

    private getAcceptedFileTypes = () =>
    {
        return this.shouldShowVideoOption() ? `image/*, ${ALLOWED_VIDEO_INPUT_TYPE.join( ", " )}` : "image/*";
    }

    private getImageTrayClassNames = () =>
    {
        return {
            ...this.getRowCountClassNames(),
        };
    }

    private getRowCountClassNames = () =>
    {
        const numMediaSelected = this.props.media.length;
        return {
            noRows: this.props.inReplaceMediaMode || (numMediaSelected === 0),
            oneRow: !this.props.inReplaceMediaMode && (numMediaSelected > 0) && numMediaSelected <= MEDIA_PICKER_NUMBER_OF_ITEMS_PER_ROW,
            twoRows: !this.props.inReplaceMediaMode && numMediaSelected > MEDIA_PICKER_NUMBER_OF_ITEMS_PER_ROW,
        };
    }

    private handleOnSortStart = () =>
    {
        this.setDragging( true );
    }

    private handleOnDeleted = ( index ) =>
    {
        this.props.onMediaRemoved( index );
    }

    private handleOnCaution = ( index: number ) =>
    {
        this.props.onCaution( index );
    }

    private handleOnSelected = ( index ) =>
    {
        if ( this.props.selectedIndex === index )
        {
            this.props.onMediaDeselected();
        }
        else
        {
            this.props.onMediaSelected( index );
        }
    }

    private handleOnSortEnd = ( { oldIndex, newIndex } ) =>
    {
        this.props.onMediaMoved( oldIndex, newIndex );
        this.setDragging( false );
    }

    private handleOnDrop = ( acceptedFiles: File[], rejectedFiles: File[] ) =>
    {
        this.props.onMediaAdded( acceptedFiles );
        if ( !isEmpty( rejectedFiles ) )
        {
            this.props.onMediaRejected( rejectedFiles );
        }
    }

    private handleSelectedMediaInDialog = ( files: File | File[] | null ) =>
    {
        const theFiles = filter( concat( [], files ) );
        this.props.onMediaAdded( theFiles );
    }

    private hasMediaAdded = () =>
    {
        return this.props.media.length !== 0;
    }
}
