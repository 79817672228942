import * as React from "react";
import { PartyHatDialog } from "./PartyHatDialogContent";

interface WelcomeBackDialogContentProps
{
    title: string;
    description: string;
}

export class WelcomeBackDialogContent extends React.PureComponent<WelcomeBackDialogContentProps>
{
    public render()
    {
        return (
            <div className="subscriptionConfirmationDialogContent">
                <PartyHatDialog title={this.props.title} description={this.props.description}/>
            </div>
        );
    }
}
