import classNames from "classnames";
import { map } from "lodash";
import * as React from "react";
import DropdownMenu from "react-md/lib/Menus/DropdownMenu";
import { Chevron } from "./svgs/chevron";
import { LayoverAnchor, LayoverPositions } from "react-md/lib/Helpers";
import { PullDownMenuButtonItem, PullDownMenuButtonItemProps } from "./PullDownMenuButtonItem";

export interface PullDownMenuProps
{
    id: string;
    button: JSX.Element;
    menuItems: PullDownMenuButtonItemProps[];
    disabled?: boolean;
    position?: LayoverPositions;
    anchor?: LayoverAnchor;
    hasChevron?: boolean;
    useSimplifiedMenu?: boolean;
}

interface PullDownMenuState
{
    visible: boolean;
}

export class PullDownMenu extends React.PureComponent<PullDownMenuProps, PullDownMenuState>
{
    constructor( props )
    {
        super( props );
        this.state = {
            visible: false,
        };
    }

    public componentDidMount()
    {
        document.addEventListener( "mousedown", this.handleClickOutside );
    }

    public componentWillUnmount()
    {
        document.removeEventListener( "mousedown", this.handleClickOutside );
    }

    public render()
    {
        return (
            // @ts-ignore
            <DropdownMenu
                id={this.props.id}
                ref="pullDownMenuRef"
                className="pullDownMenu"
                listClassName="pullDownMenuItems"
                menuItems={this.getMenuItems()}
                toggleQuery="span"
                visible={this.state.visible}
                defaultVisible={false}
                onVisibilityChange={this.onVisibilityChange}
                simplifiedMenu={this.props.useSimplifiedMenu || false}
                position={this.props.position || DropdownMenu.Positions.BELOW}
                anchor={this.props.anchor || {
                    x: DropdownMenu.HorizontalAnchors.CENTER,
                    y: DropdownMenu.VerticalAnchors.TOP,
                }}>
                <span className="pullDownMenuButton">
                    {this.props.button}{this.props.hasChevron && this.renderChevron()}
                </span>
            </DropdownMenu>
        );
    }

    private onVisibilityChange = ( visible: boolean ) =>
    {
        this.setState( {
            visible,
        } );
    }

    private renderChevron = () =>
    {
        return (
            <div className={classNames( "chevronWrap", { up: this.state.visible } )}>
                <Chevron/>
            </div>
        );
    }

    private getMenuItems = () =>
    {
        return map( this.props.menuItems, ( item: PullDownMenuButtonItemProps, idx: number ) =>
        {
            return <PullDownMenuButtonItem {...item} key={item.keyName || idx} hidePullDownMenu={this.hidePullDownMenu}/>;
        } );
    }

    private handleClickOutside = ( event ) =>
    {
        const { pullDownMenuRef } = this.refs;

        const htmlElement = pullDownMenuRef as HTMLElement;
        if ( htmlElement && !event.target.closest( "#" + this.props.id ) )
        {
            this.hidePullDownMenu();
        }
    }

    private hidePullDownMenu = () =>
    {
        this.onVisibilityChange( false );
    }
}
