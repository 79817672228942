import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../_types";
import { BrandSettingsLogo, BrandSettingsLogoDispatchProps, BrandSettingsLogoProps } from "../BrandSettingsLogo";
import { uiActions } from "../../../actions";
import { getBusinessTempLogo, isLogoUpdateInProgress, showUiBrandLogo } from "../../../ducks/ui";
import { BRAND_SETTINGS_DIALOG, eventTracker } from "../../../helpers";
import { getWatermarkUrl } from "../../../ducks/mixModel";

const mapStateToProps = ( storeState: StoreState ): BrandSettingsLogoProps =>
{
    return {
        brandLogo: getWatermarkUrl( storeState ),
        tempLogo: getBusinessTempLogo( storeState ),
        showBrandLogo: showUiBrandLogo( storeState ),
        isLogoUpdating: isLogoUpdateInProgress( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): BrandSettingsLogoDispatchProps =>
{
    return {
        handleShowBrandLogoFlag: ( enabled: boolean ) =>
        {
            updateShowBrandLogoFlag( dispatch, enabled );
        },
        handleSaveTempLogo: ( file: File ) =>
        {
            dispatch( uiActions.updateBusinessInfo( { tempLogo: file } ) );
        },
        guardLogoClicked: ( onSubscribe: () => void ) =>
        {
            onSubscribe();
        },
    };
};

function updateShowBrandLogoFlag( dispatch: Dispatch<StoreState>, enabled: boolean )
{
    eventTracker.logLogoToggleSwitched( enabled, BRAND_SETTINGS_DIALOG );
    dispatch( uiActions.updateBusinessInfo( { show_business_logo_flag: enabled } ) );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( BrandSettingsLogo );
