import { connect } from "react-redux";
import { Dispatch } from "redux";
import { store } from "../../store";
import {
    ALLOWED_VIDEO_INPUT_TYPE,
    DESIGN_DOES_NOT_SUPPORT_ANY_VIDEO_ERROR_MESSAGE,
    doesDesignSupportVideo,
    ERROR_TITLE_OOPS,
    eventTracker,
    getActiveDesignMediaObjectExclusionFilter,
    getNumberOfInputMediaWithoutActiveMediaObject,
    guardForMinMaxMediaRules,
    ImageResizer,
    JPEG_TYPE,
    LIMIT_TO_MAX_VIDEO_SELECTED_ERROR_MESSAGE,
    MAX_VIDEO_FILE_UPLOAD_SIZE_IN_BYTE,
    PNG_TYPE,
    stringUtils,
} from "../../helpers";
import {
    getMediaList,
    getMediaUploads,
    getOriginalImageList,
    getSelectedDesign,
    hasDesignReachedMaxVideoCountSupported,
    hasVideoInMediaList,
    updateMixModelAfterInputMediaUpload,
} from "../../ducks/mixModel";
import { modalServices, postServices } from "../../services";
import { MediaInfoProps, MediaPicker, MediaPickerDispatchProps, MediaPickerProps } from "../MediaPicker";
import { filter, find, forEach, head, includes, map, some, startsWith } from "lodash";
import { ImageUploadData, mixModelActions, uiActions } from "../../actions";
import { v4 as uuid } from "uuid";
import { AddMediaAPIResponse, IMAGE_TYPE, INPUT_MEDIA_TYPE, PostInputMediaFile, VIDEO_TYPE } from "../../_types/api";
import { MediaData, StoreState } from "../../_types";
import { getActiveDesignMediaObject } from "../../ducks/ui";
import { designMediaObjectHelper } from "../../helpers/designMediaObjectHelper";
import { videoTrimmerServices } from "../../services/videoTrimmer.services";
import { CustomizableCanvas } from "../../helpers/customizableCanvas";

const mapStateToProps = ( storeState: StoreState ): MediaPickerProps =>
{
    return {
        media: getImageInfoProps( storeState ),
        mediaUploads: getMediaUploads( storeState ),
        selectedIndex: storeState.ui.selectedImageIndex,
        includesVideo: hasVideoInMediaList( storeState, getActiveDesignMediaObjectExclusionFilter( storeState ) ),
        hasDesignReachedMaxVideoCountSupported: hasDesignReachedMaxVideoCountSupported( storeState,
            getActiveDesignMediaObjectExclusionFilter( storeState ) ),
        designSupportsVideo: doesDesignSupportVideo( getSelectedDesign( storeState ) ),
        inReplaceMediaMode: !!getActiveDesignMediaObject( storeState ),
        numberOfMediaWithoutActiveDesignMediaObject: getNumberOfInputMediaWithoutActiveMediaObject( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): MediaPickerDispatchProps =>
{
    return {
        onMediaAdded: async ( files: File[] ) =>
        {
            const shouldUpload = guardForMinMaxMediaRulesWithFiles( dispatch, files )
                                 && guardForVideoTooLargeRules( dispatch, files );
            if ( !shouldUpload )
            {
                return;
            }
            await handleAddMediaFiles( files, dispatch );
        },
        onMediaRejected: ( files: File[] ) =>
        {
            handleRejectedFiles( files, dispatch );
        },
        onMediaMoved: ( oldIndex: number, newIndex: number ) =>
        {
            dispatch( mixModelActions.mediaMove( { oldIndex, newIndex } ) );
            dispatch( uiActions.imageSelectionCleared() );
        },
        onMediaRemoved: ( index: number ) =>
        {
            videoTrimmerServices.removeMedia( dispatch, index );
        },
        onCaution: ( index: number ) =>
        {
            const onReplace = () =>
            {
                videoTrimmerServices.removeMedia( dispatch, index );
                dispatch( modalServices.closeLowResErrorDialog() );
            };

            dispatch( modalServices.openLowResErrorDialog( index, onReplace ) );
        },
        onMediaSelected: ( newIndex: number ) =>
        {
            dispatch( uiActions.imageSelected( newIndex ) );
        },
        onMediaDeselected: () =>
        {
            dispatch( uiActions.imageSelectionCleared() );
        },
        onVideoEdit: ( videoUrl?: string ) =>
        {
            videoTrimmerServices.openTrimmerModal( dispatch, videoUrl );
        },
        onMediaEdit: ( mediaUrl: string ) =>
        {
            CustomizableCanvas.requestStopAnimation();

            const state = store.getState();
            const postInputMediaFiles = getMediaList( state );
            const matchingPostInputMediaFile = head( filter( postInputMediaFiles, ( postInputMediaFile ) => postInputMediaFile.url === mediaUrl ) );

            designMediaObjectHelper.setPostInputMediaFileAsActiveDesignMediaObject( matchingPostInputMediaFile );
        },
    };
};

function hasVideoFile( files: File[] )
{
    return some( files, ( file ) => file && startsWith( file.type, VIDEO_TYPE ) );
}

function handleRejectedFiles( files: File[], dispatch: Dispatch<StoreState> )
{
    const warningText = [];
    const state = store.getState();
    if ( hasVideoInMediaList( store.getState() ) && getVideoFilesCount( files ) > 0 )
    {
        warningText.push( LIMIT_TO_MAX_VIDEO_SELECTED_ERROR_MESSAGE );
    }
    if ( !filesContainValidType( files ) )
    {
        warningText.push( "You can only upload valid image files (.png, .jpeg, etc.), \n or valid video files (.mp4, .mov, .avi, etc. )." );
    }
    if ( !doesDesignSupportVideo( getSelectedDesign( state ) ) && hasVideoFile( files ) )
    {
        warningText.push( DESIGN_DOES_NOT_SUPPORT_ANY_VIDEO_ERROR_MESSAGE );
    }

    const errorFileNames = map( files, ( file ) => file.name );
    dispatch( modalServices.openErrorDialogWithStandardFormat(
        "Unable to upload:\n" + errorFileNames.join( "\n" ) + "\n",
        warningText.join( "\n\n" ) ) );
}

function filesContainValidType( files: File[] ): boolean
{
    const validTypes = [...ALLOWED_VIDEO_INPUT_TYPE, PNG_TYPE, JPEG_TYPE];
    return some( files, ( file ) => file && includes( validTypes, file.type ) );
}

async function handleAddMediaFiles( files: File[], dispatch: Dispatch<StoreState> )
{
    forEach( files, async ( file ) =>
    {
        if ( startsWith( file.type, IMAGE_TYPE ) )
        {
            await handleEachImageAdded( dispatch, file );
        }
        else if ( startsWith( file.type, VIDEO_TYPE ) )
        {
            await handleEachVideoAdded( dispatch, file );
        }
    } );
}

function getVideoFilesCount( files: File[] ): number
{
    const videoFiles = filter( files, ( file ) => file && startsWith( file.type, VIDEO_TYPE ) );
    return videoFiles.length;
}

function guardForMinMaxMediaRulesWithFiles( dispatch: Dispatch<StoreState>, files: File[] )
{
    const numberOfMediaBeingAdded = files.length;
    const videoFilesCount = getVideoFilesCount( files );
    return guardForMinMaxMediaRules( dispatch, numberOfMediaBeingAdded, videoFilesCount );
}

function getImageInfoProps( storeState: StoreState ): MediaInfoProps[]
{
    return map( getOriginalImageList( storeState ), ( file: PostInputMediaFile ): MediaInfoProps =>
    {
        return { mediaUrlString: file.url, lowQuality: file.isLowRes };
    } );
}

function guardForVideoTooLargeRules( dispatch: Dispatch<StoreState>, files )
{
    const videoTooBigFile = find( files, ( file ) => file && file.size > MAX_VIDEO_FILE_UPLOAD_SIZE_IN_BYTE );

    if ( videoTooBigFile )
    {
        const fileSizeLimit = stringUtils.convertByteToMB( MAX_VIDEO_FILE_UPLOAD_SIZE_IN_BYTE );
        const userUploadedFileSize = stringUtils.convertByteToMB( videoTooBigFile.size );
        eventTracker.logVideoFileSizeExceedLimit( fileSizeLimit, userUploadedFileSize );

        dispatch( modalServices.openErrorDialog( ERROR_TITLE_OOPS,
            `Your uploaded file size exceeds our limit of ${fileSizeLimit}MB. Please choose a smaller video file.` ) );
        return false;
    }

    return true;
}

async function handleEachImageAdded( dispatch, file )
{
    const imageResizer = new ImageResizer( file, { allowPng: true } );
    const lowResImage = await imageResizer.isImageLowRes();
    const fileName = "mix_photo" + uuid() + ".jpg";
    const mediaBlob = await imageResizer.resize();
    const mediaData: MediaData = {
        filename: fileName,
        file: mediaBlob,
        fileSize: mediaBlob && mediaBlob.size,
        type: INPUT_MEDIA_TYPE,
        isLowRes: lowResImage,
    };
    await dispatch( postServices.uploadPostMediaToS3( mediaData, afterUpload ) );
}

function afterUpload( dispatch, data: AddMediaAPIResponse, mediaData: MediaData )
{
    if ( data )
    {
        const newData: ImageUploadData = {
            uploadFields: data,
            isLowRes: mediaData.isLowRes,
            fileSize: mediaData.fileSize,
        };
        updateMixModelAfterInputMediaUpload( dispatch, newData );
    }
}

async function handleEachVideoAdded( dispatch: Dispatch<StoreState>, file )
{
    dispatch( mixModelActions.addMediaFileRequest( INPUT_MEDIA_TYPE ) );
    await dispatch( postServices.convertVideo( file ) );
    videoTrimmerServices.openTrimmerModal( dispatch );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( MediaPicker );
