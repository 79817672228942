import { RIPL_TIER_BASE, RIPL_TIER_LEGACY, RIPL_TIER_PREMIUM } from "./tierConstants";
import { SUBSCRIPTION_FLOW_SOURCE_PURCHASE_PAGE, SUBSCRIPTION_FLOW_UPTIER_SOURCES, SubscriptionFlowSource } from "./trackingConstants";
import { includes } from "lodash";

export const pricingTiersUIHelper = {
    getTierDisplayText,
    shouldShowBackToCompareTiersLink,
};

function getTierDisplayText( tier: ProductTier )
{
    switch ( tier )
    {
        case RIPL_TIER_BASE:
            return "Base";
        case RIPL_TIER_PREMIUM:
            return "Premium";
        case RIPL_TIER_LEGACY:
            return "Pro";
        default:
            return "";
    }
}

function shouldShowBackToCompareTiersLink( source: SubscriptionFlowSource )
{
    return !includes( [SUBSCRIPTION_FLOW_SOURCE_PURCHASE_PAGE, ...SUBSCRIPTION_FLOW_UPTIER_SOURCES], source );
}
