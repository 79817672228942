import * as React from "react";
import { ScrollingRow } from "./ScrollableRow";

export interface HomePostRowProps
{
    id: number;
    name: string;
    shouldRenderSeeAll?: boolean;
    initialScrollToIndex?: number;
    handleSeeAllClicked?: () => void;
}

export class HomePostRow extends React.PureComponent<HomePostRowProps>
{
    public render()
    {
        const initialScrollToChildIndex = this.props.initialScrollToIndex || 0;
        return (
            <div className="homePostRow" key={this.props.id}>
                <div className="homePostRowContents">
                    <div className="homePostRowHeader">

                        <div className="homePostRowNameAndLinkContainer">
                            <div className="homePostRowName">{this.props.name}</div>

                            {this.shouldDisplaySeeAll() && <div className="homePostRowSeeAllLink"
                                                                onClick={this.props.handleSeeAllClicked}>See all</div>}
                        </div>
                    </div>
                    <ScrollingRow contentWrapperClassName="homePostRowTemplates" scrollToChildIndex={initialScrollToChildIndex}>
                        {this.props.children}
                    </ScrollingRow>
                </div>
            </div>
        );
    }

    private shouldDisplaySeeAll = () =>
    {
        return this.props.shouldRenderSeeAll && this.props.handleSeeAllClicked;
    }
}
