import * as React from "react";
import { SocialAccountConnectButton } from "./SocialAccountConnectButton";
import { SocialNetworkAccountType } from "../_types";

export interface DisconnectButtonProps
{
    label?: string;
    className?: string;
    accountType: SocialNetworkAccountType;
    buttonText?: string;
}

export interface DisconnectButtonDispatchProps
{
    onClick?: ( event: React.MouseEvent<HTMLElement> ) => void;
}

const DISCONNECT_BUTTON_LABEL = "Disconnect";

export class DisconnectButton extends React.PureComponent<DisconnectButtonProps & DisconnectButtonDispatchProps>
{
    public render()
    {
        const buttonText = this.props.buttonText || DISCONNECT_BUTTON_LABEL;
        return <SocialAccountConnectButton {...this.props}
                                           buttonText={buttonText}/>;
    }
}
