import { BoundingRect } from "react-measure";
import { ASPECT_RATIO_NAMES, ASPECT_RATIO_VALUE, HD_IMAGE_DIMENSION, MAX_CANVAS_SIZE, STANDARD_IMAGE_DIMENSION } from "./";
import { keys } from "lodash";
import { AspectRatio } from "../_types";

export function getSnapshotDimensions( aspectRatio: AspectRatio, isHD: boolean )
{
    let width = getBaseResolution( isHD );
    let height = getBaseResolution( isHD );
    const ratioValue = ASPECT_RATIO_VALUE[aspectRatio];
    if ( ratioValue )
    {
        if ( ratioValue > 1 )
        {
            width = height * ratioValue;
        }
        else
        {
            height = width / ratioValue;
        }
    }
    else
    {
        throw new Error( "Unknown aspect ratio detected: " + aspectRatio );
    }
    return { width, height };
}

export function getBaseResolution( isHD: boolean )
{
    return isHD ? HD_IMAGE_DIMENSION : STANDARD_IMAGE_DIMENSION;
}

// TODO make this responsive in SCSS rather than hard coded...
const ART_BOARD_CONTROL_HEIGHT_INCLUDING_VERTICAL_MARGINS = 95;
const BRAND_SETTINGS_BUTTON_HEIGHT_INCLUDING_VERTICAL_MARGINS = 46;
const DESIGN_TRAY_TAB_GRABBER_WIDTH = 28;
const ZOOM_SLIDER_WIDTH = 50;

export function getCanvasDimensions( aspectRatio: AspectRatio, parentRect?: BoundingRect ): Partial<BoundingRect>
{
    if ( !parentRect )
    {
        return null;
    }

    let bounds = { width: 1, height: 1 };
    const calcHeight = parentRect.height - (ART_BOARD_CONTROL_HEIGHT_INCLUDING_VERTICAL_MARGINS
                                            + BRAND_SETTINGS_BUTTON_HEIGHT_INCLUDING_VERTICAL_MARGINS);
    const calcWidth = parentRect.width - (DESIGN_TRAY_TAB_GRABBER_WIDTH + ZOOM_SLIDER_WIDTH);
    const height = Math.max( calcHeight, 100 );
    const width = Math.max( calcWidth, 100 );
    const parentRatio = width / height;
    const desiredRatio = ASPECT_RATIO_VALUE[aspectRatio];

    if ( parentRatio > desiredRatio )
    {
        bounds = { width: height * desiredRatio, height };
    }
    else
    {
        bounds = { width, height: width / desiredRatio };
    }

    if ( bounds.width > MAX_CANVAS_SIZE )
    {
        const shrink = MAX_CANVAS_SIZE / bounds.width;
        bounds.width = MAX_CANVAS_SIZE;
        bounds.height = bounds.height * shrink;
    }

    if ( bounds.height > MAX_CANVAS_SIZE )
    {
        const shrink = MAX_CANVAS_SIZE / bounds.height;
        bounds.height = MAX_CANVAS_SIZE;
        bounds.width = bounds.width * shrink;
    }

    return bounds;
}

export function getAllAspectRatioSupported(): AspectRatio[]
{
    return keys( ASPECT_RATIO_NAMES ) as AspectRatio[];
}
