import { Dispatch } from "redux";
import { normalizeCollectionAPIData, requests } from "../helpers";
import { CollectionRowsState, SocialCalendarState, StoreState } from "../_types";
import { CollectionRowsAPIData } from "../_types/api/collectionRowsData";
import { collectionRowsActions, socialCalendarActions } from "../actions";
import { isUserLoggedIn } from "../ducks/user";
import { store } from "../store";
import { forEach, map } from "lodash";
import { CollectionAPIData } from "../_types/api/collectionsData";
import { COLLECTION_TYPE_USER_RECENT_POSTS } from "../_types/api";
import { postServices } from "./post.services";

export const collectionRowsServices = {
    loadCollectionRowsForBusinessType,
};

const COLLECTIONS_ROWS_BASE_URL = "/collections";

const COLLECTION_ROWS_FOR_BUSINESS_TYPE_URL = COLLECTIONS_ROWS_BASE_URL + "/collection_rows";

function loadCollectionRowsForBusinessType( businessTypeId: number, businessId: number )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        if ( !isUserLoggedIn( store.getState() ) )
        {
            return;
        }

        const queryParams =
            {
                business_type_id: businessTypeId,
                business_id: businessId,
                client_timestamp: new Date(),
                social_calendar_show_collection: true,
                social_calendar_mode: "specific_day",
            };

        return requests.get<CollectionRowsAPIData>( COLLECTION_ROWS_FOR_BUSINESS_TYPE_URL, queryParams ).then(
            async ( data: CollectionRowsAPIData ) =>
            {
                const collectionState = convertCollectionRowsAPIDataToState( data );
                const calendarState = await convertSocialCalendarAPIDataToState(dispatch, data );
                dispatch( collectionRowsActions.collectionRowsLoadSucceeded( collectionState ) );
                dispatch( socialCalendarActions.socialCalendarLoadSucceeded( calendarState ) );
            } );
    };
}

function convertCollectionRowsAPIDataToState( data: CollectionRowsAPIData )
{
    const { rows} = data;

    const normalizedRows = map( rows, ( collectionRow: CollectionAPIData ) =>
    {
        return normalizeCollectionAPIData( collectionRow );
    } );

    const collectionRows = forEach( normalizedRows, ( row ) =>
    {
        if ( row.collection_type !== COLLECTION_TYPE_USER_RECENT_POSTS )
        {
            forEach( row.posts, ( post ) =>
            {
                post.is_collection_post = true;
            } );
        }
    } );

    const newState: CollectionRowsState = {
        rows: collectionRows,
    };
    return newState;
}

function convertSocialCalendarAPIDataToState(dispatch, data: CollectionRowsAPIData )
{
    const { social_calendar } = data;
    if ( !social_calendar )
    {
        return;
    }
    map( social_calendar.posts, async ( post, id ) =>
    {
        await postServices.loadExamplePostWithId(dispatch, id.toString());
    });

    const socialCalendarState: SocialCalendarState = {
        posts: social_calendar.posts,
        calendar: social_calendar.calendar,
    };
    return socialCalendarState;
}
