import * as React from "react";
import { Post } from "../_types/index";
import * as playButtonWhiteOnDarkGreyImg from "../assets/img/playButtonWhiteOnDarkGrey.png";
import { ErrorBoundary } from "./ErrorBoundary";
import DesignSwitchingDesignCanvasContainer from "./containers/DesignSwitchingDesignCanvas.container";
import { getDesignFromSlug } from "../ducks/designs";
import { store } from "../store";
import VideoElementContainer from "./containers/VideoElement.container";
import { ASPECT_RATIO_VALUE, SQUARE_ASPECT_RATIO } from "../helpers";
import classNames from "classnames";

export interface TemplatePreviewBrandableViewProps
{
    post: Post;
    hidePlayButton: boolean;
    showDesignPreview: boolean;
}

export interface TemplatePreviewBrandableViewDispatchProps
{
    setSelectedDesign(): void;
    onVideoStarted(): void;
    onVideoStopped(): void;
}

export const TEMPLATE_PREVIEW_IFRAME_ID = "templatePreviewIframe";

export class TemplatePreviewBrandableView extends React.Component<TemplatePreviewBrandableViewProps & TemplatePreviewBrandableViewDispatchProps>
{

    public componentDidMount(): void
    {
        this.props.setSelectedDesign();
    }

    public render()
    {
        return <div className="templatePreviewBrandableView">
            {this.renderPreview()}
            <img className="playButtonImage" src={playButtonWhiteOnDarkGreyImg} hidden={this.shouldHidePlayButton()}/>
        </div>;
    }

    private renderPreview()
    {
        if ( this.props.showDesignPreview )
        {
            const width = this.calculateWidthStyleForAspectRatio( this.props.post.aspect_ratio );
            const isSquareAspectRatio = this.props.post.aspect_ratio === SQUARE_ASPECT_RATIO;

            return <div className={classNames( "designCanvasWrapper", { square: isSquareAspectRatio } )}
                        onClick={this.getOnDesignClicked}
                        style={{ width }}>
                <ErrorBoundary errorMessage="There was a problem loading this template">
                    <DesignSwitchingDesignCanvasContainer
                        design={getDesignFromSlug( store.getState(), this.props.post.design_slug )}
                        aspectRatio={this.props.post.aspect_ratio}
                        lazyLoad={true}
                        iframeId={TEMPLATE_PREVIEW_IFRAME_ID}
                        isSelectedDesign={false}
                        hidePlayButton={true}
                        onDesignClicked={this.getOnDesignClicked}
                        animatePreviewOnHover={false}
                        animatePreviewOnClick={true}
                    />
                </ErrorBoundary>
            </div>;
        }
        else
        {
            return <VideoElementContainer
                videoUrl={this.props.post.video_url}
                imageUrl={this.props.post.image_url}
                posterUrl={this.props.post.poster_url}
                muted={true}
                autoplay={true}
                loop={false}
                playOnMouseOver={false}
                playOnClick={true}
                onVideoStarted={this.onVideoStarted}
                onVideoStopped={this.onVideoStopped}
                aspectRatio={ASPECT_RATIO_VALUE[this.props.post.aspect_ratio]}>
            </VideoElementContainer>;
        }
    }

    private shouldHidePlayButton()
    {
        return this.props.hidePlayButton || !this.props.post.video_url;
    }

    private getOnDesignClicked = () =>
    {
        // hide the play button
    }

    private onVideoStarted = () =>
    {
        this.props.onVideoStarted();
    }

    private onVideoStopped = () =>
    {
        this.props.onVideoStopped();
    }

    private calculateWidthStyleForAspectRatio( aspectRatio )
    {
        const aspectRatioValue = parseFloat( ASPECT_RATIO_VALUE[aspectRatio].toFixed( 2 ) );
        const widthValue = (Math.min( 1.0, aspectRatioValue ));
        return `${(100 * widthValue)}%`;
    }
}
