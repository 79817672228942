import * as React from "react";
import Button from "react-md/lib/Buttons/Button";

export interface SocialAccountConnectButtonProps
{
    label?: string;
    buttonText: string;
    className?: string;
}

export interface SocialAccountConnectButtonDispatchProps
{
    onClick?: ( event: React.MouseEvent<HTMLElement> ) => void;
}

export class SocialAccountConnectButton extends React.PureComponent<SocialAccountConnectButtonProps & SocialAccountConnectButtonDispatchProps>
{
    public render()
    {
        return <div className={this.props.className}>
            <label className="md-selection-control-label">{this.props.label}
                <Button flat secondary
                        onClick={this.props.onClick}
                        className="socialAccountConnectButton outline"
                >{this.props.buttonText}</Button>
            </label>
        </div>;
    }
}
