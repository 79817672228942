import * as React from "react";
import Button from "react-md/lib/Buttons/Button";
import { negate, range, some } from "lodash";
import { EmailPicker } from "./EmailPicker";
import { getAllNonEmptyStrings, hasAnyMatchingPredicateZipped, validateEmail } from "../helpers";
import classNames from "classnames";

export interface MultipleEmailPickerProps
{
    maxEmailsToPick: number;
    submitButtonText?: string;
}

export interface MultipleEmailPickerDispatchProps
{
    onSubmit( emails: string[] ): void;
}

export interface MultipleEmailPickerState
{
    emails: string[];
    emailsEditedSinceLastSubmission: boolean[];
    shouldShowErrors: boolean;
}

export class MultipleEmailPicker extends React.PureComponent<MultipleEmailPickerProps & MultipleEmailPickerDispatchProps, MultipleEmailPickerState>
{
    public constructor( props )
    {
        super( props );
        this.state = {
            emails: [],
            emailsEditedSinceLastSubmission: [],
            shouldShowErrors: false,
        };
    }

    public render()
    {
        const submitButtonText = this.props.submitButtonText || "Submit";
        const shouldShowErrorText = this.state.shouldShowErrors && this.hasAnyEmailValidationErrors();
        return (
            <div className="emails">
                <div className="emailList">
                    {this.renderEmailPickers( this.props.maxEmailsToPick )}
                </div>
                <div className={classNames( "errorText", { hidden: !shouldShowErrorText } )}>
                    Invalid Email Address
                </div>
                <Button primary flat swapTheming
                        className={classNames( "submitButton", { shorten: shouldShowErrorText } )}
                        onClick={this.submitButtonPressed}
                        disabled={this.state.shouldShowErrors || !this.hasAtLeastOneEmailEntered()}
                >
                    {submitButtonText}
                </Button>
            </div>
        );
    }

    private renderEmailPickers = ( maxEmailsToPick: number ) =>
    {
        return range( maxEmailsToPick )
            .map( ( idx ) =>
                {
                    const { shouldShowErrors } = this.state;
                    let shouldSuppressErrors = this.state.emailsEditedSinceLastSubmission[idx];

                    if ( !shouldShowErrors )
                    {
                        shouldSuppressErrors = true;
                    }

                    return (
                        <EmailPicker
                            id={`EmailPicker{${idx}}`}
                            hideErrorText={true}
                            key={idx}
                            shouldSuppressErrors={shouldSuppressErrors}
                            onChange={( value ) =>
                            {
                                this.updateEmailState( idx, value as string );
                            }}
                        />
                    );
                },
            );
    }

    private hasAtLeastOneEmailEntered = (): boolean =>
    {
        const emails = getAllNonEmptyStrings( this.state.emails );
        return some( emails );
    }

    private hasAnyEmailValidationErrors = (): boolean =>
    {
        const emails = getAllNonEmptyStrings( this.state.emails );
        return some( emails, negate( validateEmail ) );
    }

    private hasNotEditedAllEmailsWithErrors = ( emails: string[], hasEditedEmailsList: boolean[] ): boolean =>
    {
        const isEmailInvalidAndUnedited = ( [email, hasEdited] ) => !validateEmail( email ) && !hasEdited;
        return hasAnyMatchingPredicateZipped( [emails, hasEditedEmailsList], isEmailInvalidAndUnedited );
    }

    private updateEmailState = ( index: number, value: string ) =>
    {
        const emails = [...this.state.emails];
        emails[index] = value;

        const emailsEditedSinceLastSubmission = [...this.state.emailsEditedSinceLastSubmission];
        emailsEditedSinceLastSubmission[index] = true;

        this.setState( {
            emails,
            emailsEditedSinceLastSubmission,
            shouldShowErrors: this.state.shouldShowErrors && this.hasNotEditedAllEmailsWithErrors( emails, emailsEditedSinceLastSubmission ),
        } );
    }

    private submitButtonPressed = () =>
    {
        if ( this.hasAnyEmailValidationErrors() )
        {
            this.setState( {
                shouldShowErrors: true,
                emailsEditedSinceLastSubmission: [],
            } );
        }
        else
        {
            this.props.onSubmit( this.state.emails );
        }
    }
}
