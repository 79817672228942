import { connect } from "react-redux";
import { Dispatch } from "redux";
import { uiActions } from "../../actions";
import { getDesignCatalogAspectRatio } from "../../ducks/ui";
import {
    eventTracker,
    getAllAspectRatioSupported,
    getDesignSwitchingCatalogCategoriesToDisplay,
    getSelectedDesignCatalogCategory,
} from "../../helpers";
import { AspectRatio, CatalogCategory, StoreState } from "../../_types";
import { store } from "../../store";
import {
    DesignSwitchingCatalogSidebar,
    DesignSwitchingCatalogSidebarDispatchProps,
    DesignSwitchingCatalogSidebarProps,
} from "../DesignSwitchingCatalogSidebar";
import { catalogServices } from "../../services";

const mapStateToProps = ( storeState: StoreState ): DesignSwitchingCatalogSidebarProps =>
{
    const categories = getDesignSwitchingCatalogCategoriesToDisplay( storeState );
    return {
        category: getSelectedDesignCatalogCategory( storeState, categories ),
        categories,
        aspectRatio: getDesignCatalogAspectRatio( storeState ),
        aspectRatioSupported: getAllAspectRatioSupported(),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): DesignSwitchingCatalogSidebarDispatchProps =>
{
    return {
        onCategorySelected: ( category: CatalogCategory ) =>
        {
            dispatch( uiActions.designCatalogCategorySelected( category ) );
            eventTracker.logCatalogSwitched( category );
            dispatch( catalogServices.loadDesignControlsByCategory() );
        },
        onAspectRatioSelected: ( aspectRatio: AspectRatio ) =>
        {
            const state = store.getState();
            if ( getDesignCatalogAspectRatio( state ) === aspectRatio )
            {
                aspectRatio = undefined;
            }
            dispatch( uiActions.designCatalogAspectRatioSet( aspectRatio ) );
            eventTracker.logCatalogAspectRatioSwitched( aspectRatio );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( DesignSwitchingCatalogSidebar );
