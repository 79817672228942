import { apptimizeWrapper } from "./";

let injectableApptimizeWrapper: typeof apptimizeWrapper;

const FEATURE_FLAG_SUFFIX = "FeatureFlag";
const SHOULD_SHOW_UNSCHEDULED_FILTER_BUTTON = "shouldShowUnscheduledFilterButton";
const SHOULD_DISABLE_FACEBOOK_CONNECT = "shouldDisableFacebookConnect";
const SHOULD_DISABLE_FACEBOOK_SHARE = "shouldDisableFacebookShare";
const SHOULD_ENABLE_TEXT_ANIMATIONS = "shouldEnableTextAnimations";
const SHOULD_SHOW_RIPL_USER_SURVEY_2023 = "shouldShowRiplUserSurvey2023";
const SHOULD_SUPPORT_PRESET_SLIDE_TRANSITIONS = "shouldSupportPresetSlideTransitions";
const SHOULD_SUPPORT_BACKGROUND_ANIMATIONS = "shouldSupportBackgroundAnimations";
const SHOULD_ENABLE_PRESET_TEXT = "shouldEnablePresetText";
const SHOULD_ENABLE_DYNAMIC_SLIDES = "shouldEnableDynamicSlides";
const SHOULD_SHOW_ALKAI_CROSS_PROMO = "shouldShowAlkaiCrossPromoDialog";
const ALKAI_DIALOG_TITLE = "alkaiDialogTitleText";
const ALKAI_DIALOG_BODY = "alkaiDialogBodyText";
const ALKAI_DIALOG_IMAGE_URL: string = "alkaiDialogImageUrl";
const ALKAI_DIALOG_BUTTON = "alkaiDialogButtonText";
const ALKAI_DIALOG_COHORT = "alkaiDialogCohort";
const ALKAI_DIALOG_LINK = "alkaiDialogLinkText";
const SHOULD_SHOW_TWO_BANNER_HOME_SCREEN = "shouldShowTwoBannerHomeScreen";
const SHOULD_SHOW_BUSINESS_TYPE_SELECTOR = "shouldShowBusinessTypeSelector";

function shouldShowUnscheduledPostsViewButton()
{
    // This function is named differently than the Apptimize variable because
    // the implementation approach changed to an unscheduled posts VIEW
    // rather than an unscheduled posts FILTER.
    return isFeatureFlagOrVariableSet( SHOULD_SHOW_UNSCHEDULED_FILTER_BUTTON );
}

function shouldDisableFacebookConnect()
{
    return isFeatureFlagOrVariableSet( SHOULD_DISABLE_FACEBOOK_CONNECT );
}

function shouldDisableFacebookShare()
{
    return isFeatureFlagOrVariableSet( SHOULD_DISABLE_FACEBOOK_SHARE );
}

function shouldEnableTextAnimations()
{
    return isFeatureFlagOrVariableSet( SHOULD_ENABLE_TEXT_ANIMATIONS );
}

function shouldShowRiplUserSurvey2023()
{
    return isFeatureFlagOrVariableSet( SHOULD_SHOW_RIPL_USER_SURVEY_2023 );
}

function shouldShowAlkaiCrossPromoDialog()
{
    return isFeatureFlagOrVariableSet( SHOULD_SHOW_ALKAI_CROSS_PROMO );
}

function getAlkaiCrossPromoDialogTitle()
{
    return getString( ALKAI_DIALOG_TITLE, "Wondering what to post next?" );
}

function getAlkaiCrossPromoDialogBody()
{
    return getString( ALKAI_DIALOG_BODY, "Meet Alkai, your new AI assistant from Ripl! It creates custom videos "
                                         + "and captions making your social media presence shine." );
}

function getAlkaiCrossPromoDialogImageUrl()
{
    return getString( ALKAI_DIALOG_IMAGE_URL, "https://fanzo-static.s3.amazonaws.com/assets/alkai_dialog2b.png" );
}

function getAlkaiCrossPromoDialogButton()
{
    return getString( ALKAI_DIALOG_BUTTON, "Try now" );
}

function getAlkaiCrossPromoDialogCohort()
{
    return getString( ALKAI_DIALOG_COHORT, "Default" );
}

function getAlkaiCrossPromoDialogLink()
{
    return getString( ALKAI_DIALOG_LINK, "https://app.heyalkai.com/?s=riplForWeb&userId=" );
}

function shouldSupportBackgroundAnimations()
{
    return isFeatureFlagOrVariableSet( SHOULD_SUPPORT_BACKGROUND_ANIMATIONS );
}

function shouldSupportPresetSlideTransitions()
{
    return isFeatureFlagOrVariableSet( SHOULD_SUPPORT_PRESET_SLIDE_TRANSITIONS );
}

function shouldEnablePresetText()
{
    return isFeatureFlagOrVariableSet( SHOULD_ENABLE_PRESET_TEXT );
}

function shouldEnableDynamicSlides()
{
    return isFeatureFlagOrVariableSet( SHOULD_ENABLE_DYNAMIC_SLIDES );
}

function shouldShowTwoBannerHomeScreen()
{
    return isFeatureFlagOrVariableSet( SHOULD_SHOW_TWO_BANNER_HOME_SCREEN );
}

function shouldShowBusinessTypeSelector()
{
    return isFeatureFlagOrVariableSet( SHOULD_SHOW_BUSINESS_TYPE_SELECTOR );
}

function getBool( name: string, defaultValue: boolean ): boolean
{
    return getApptimizeWrapper().getBool( name, defaultValue );
}

function getString( name: string, defaultValue: string ): string
{
    return getApptimizeWrapper().getString( name, defaultValue );
}

function isFeatureEnabled( name: string ): boolean
{
    const theVariableFeatureFlag = name + FEATURE_FLAG_SUFFIX;
    return getApptimizeWrapper().isFeatureFlagEnabled( theVariableFeatureFlag );
}

function isFeatureFlagOrVariableSet( key )
{
    return isFeatureEnabled( key ) || getBool( key, false );
}

function getApptimizeWrapper()
{
    return injectableApptimizeWrapper ? injectableApptimizeWrapper : apptimizeWrapper;
}

// this is only exposed to be overridden for testing
function setApptimizeWrapper( wrapper: typeof apptimizeWrapper )
{
    injectableApptimizeWrapper = wrapper;
}

export const apptimizeVariables = {
    shouldShowUnscheduledPostsViewButton,
    shouldDisableFacebookConnect,
    shouldDisableFacebookShare,
    setApptimizeWrapper,
    shouldEnableTextAnimations,
    shouldShowRiplUserSurvey2023,
    shouldShowAlkaiCrossPromoDialog,
    getAlkaiCrossPromoDialogTitle,
    getAlkaiCrossPromoDialogBody,
    getAlkaiCrossPromoDialogImageUrl,
    getAlkaiCrossPromoDialogButton,
    getAlkaiCrossPromoDialogCohort,
    getAlkaiCrossPromoDialogLink,
    shouldSupportPresetSlideTransitions,
    shouldEnablePresetText,
    shouldEnableDynamicSlides,
    shouldSupportBackgroundAnimations,
    shouldShowTwoBannerHomeScreen,
    shouldShowBusinessTypeSelector,
    SHOULD_ENABLE_TEXT_ANIMATIONS,
    SHOULD_SHOW_RIPL_USER_SURVEY_2023,
    SHOULD_SUPPORT_PRESET_SLIDE_TRANSITIONS,
    SHOULD_ENABLE_PRESET_TEXT,
    SHOULD_ENABLE_DYNAMIC_SLIDES,
};
