import * as React from "react";
import { Post } from "../_types";
import Button from "react-md/lib/Buttons/Button";
import { PencilIcon } from "./svgs/PencilIcon";
import {
    BRAND_CREATIVE_PAGE_URL,
    convertYYYYMMDDtoDate,
    CustomizePostOptions,
    eventTracker,
    history,
    TemplatePreviewSources,
    toMonthDayYearTimeZone,
} from "../helpers";
import * as magicWand from "../assets/img/magical_wand_icon.png";
import Switch from "react-md/lib/SelectionControls/Switch";
import TemplatePreviewBrandableViewContainer from "./containers/TemplatePreviewBrandableView.container";
import { store } from "../store";
import { mixModelActions, uiActions } from "../actions";
import { hasTemplatePreviewApplyBrandStylesSwitchToggled } from "../ducks/ui";
import { getCurrentBusiness } from "../ducks/userBusiness";
import { TemplatePreviewDialogContainerProps } from "./containers/TemplatePreviewDialog.container";
import { modalServices } from "../services";

interface TemplatePreviewDialogState
{
    toggleChecked: boolean;
}

export interface TemplatePreviewDialogProps extends TemplatePreviewDialogContainerProps
{
    hasBrandStyles: boolean;
    toggleDisabled: boolean;
    holidayName?: string;
    holidayDate?: string;
}

export interface TemplatePreviewDialogDispatchProps
{
    handleCustomize: ( post: Post, customizeOptions: CustomizePostOptions, source: TemplatePreviewSources, brandStylesApplied: boolean ) => void;
}

export class TemplatePreviewDialog extends React.PureComponent<TemplatePreviewDialogProps
    & TemplatePreviewDialogDispatchProps, TemplatePreviewDialogState>
{
    constructor( props )
    {
        super( props );
        this.state = { toggleChecked: false };
    }

    public render()
    {
        return (
            <div className="templatePreviewDialog">
                <div className="templatePreviewImageWrap">
                    <TemplatePreviewBrandableViewContainer post={this.props.post} showDesignPreview={this.state.toggleChecked}/>
                </div>
                <div className="templatePreviewControls">
                    <div className="templatePreviewControlsTop">
                        {this.getTitleOrHolidayNameAndDate()}
                    </div>
                    <div className="templatePreviewControlsBottom">
                        {this.getBrandStylesSection()}
                        <Button primary flat swapTheming className="customizeButton"
                                iconEl={<PencilIcon/>}
                                onClick={this.onCustomizeTapped}>Customize this template</Button>
                    </div>
                </div>
            </div>
        );
    }

    private getTitleOrHolidayNameAndDate() {
        const { holidayName, holidayDate } = this.props;
        if (!holidayName || !holidayDate)
        {
            return <div className="templatePreviewTitle">Preview</div>;
        }
        const date = toMonthDayYearTimeZone(convertYYYYMMDDtoDate(holidayDate));
        return (
          <div className="holidayNameAndDate">
                <div className="holidayName">{holidayName}</div>
                <div className="holidayDate">{date}</div>
            </div>
        );
    }

    private getBrandStylesSection()
    {
        if ( this.props.hasBrandStyles )
        {
            return <div className="brandStyleToggleSection">
                <div className="brandStyleToggleLabelGroup">
                    <img src={magicWand} alt="magic wand"/>
                    <div className="brandStyleToggleLabel">Apply my brand styles</div>
                </div>
                <Switch id="brandStyleSwitch"
                        className="brandStyleToggleSwitch"
                        aria-label="brand styles toggle"
                        name="brandStylesToggle"
                        checked={this.state.toggleChecked}
                        onChange={this.handleToggleChanged}
                        disabled={this.props.toggleDisabled}
                />
            </div>;

        }
        else
        {
            return <div className="brandStyleHint">
                Finish setting up your <span className="brandStyleHintLinkText" onClick={this.handleSetupBrandStyleHintClicked}>Brand Styles</span>
                &nbsp;and come back again to apply your fonts, colors, and logos to this preview.
            </div>;
        }

    }

    private onCustomizeTapped = () =>
    {
        const templatePreviewCustomizeOptions = {
            ...this.props.customizeOptions,
            hasAppliedBrandStyles: this.state.toggleChecked,
        };
        return this.props.handleCustomize( this.props.post, templatePreviewCustomizeOptions, this.props.source, this.state.toggleChecked );
    }

    private handleToggleChanged = () =>
    {
        const state = store.getState();
        if ( !hasTemplatePreviewApplyBrandStylesSwitchToggled( state ) )
        {
            store.dispatch( uiActions.setTemplatePreviewApplyBrandStylesSwitchToggled() );
            eventTracker.logTemplatePreviewApplyBrandStylesSwitchToggled( this.props.source, this.props.post.design_slug );
        }

        if ( !this.state.toggleChecked )
        {
            store.dispatch( mixModelActions.applyBrandSettings( getCurrentBusiness( state ) ) );
        }

        const currentValue = this.state.toggleChecked;
        this.setState( { toggleChecked: !currentValue } );
    }

    private handleSetupBrandStyleHintClicked = () =>
    {
        store.dispatch(modalServices.closeAllLightboxes());
        history.push( BRAND_CREATIVE_PAGE_URL );
    }
}
