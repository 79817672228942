import * as React from "react";
import Button from "react-md/lib/Buttons/Button";

export interface InstagramFooterNeedHelpButtonDispatchProps
{
    onNeedMoreHelpClicked: () => void;
}

export const InstagramFooterNeedHelpButton: React.FC<InstagramFooterNeedHelpButtonDispatchProps> = ( props ) =>
{
    return (
        <Button flat primary className="outline" onClick={props.onNeedMoreHelpClicked}>Help</Button>
    );
};
