import * as React from "react";
import Switch from "react-md/lib/SelectionControls/Switch";

export interface LogoToggleProps
{
    logoToggleText: string;
    showBrandLogo: boolean;
    handleShowBrandLogo: ( enabled: boolean ) => void;
}

export class LogoToggle extends React.PureComponent<LogoToggleProps>
{
    public render()
    {
        return (
            <div className="logoToggleRow">
                <span>{this.props.logoToggleText}</span>
                <Switch id="logoSwitch"
                        className="settingSwitch"
                        aria-label="logo enabled"
                        name="logoEnabled"
                        checked={this.props.showBrandLogo}
                        onChange={this.props.handleShowBrandLogo}/>
            </div>
        );
    }
}
