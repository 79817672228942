import * as React from "react";
import * as PencilIcon from "../assets/img/pencilIcon.png";

export interface PencilButtonProps
{
    onClick: () => void;
}

export class PencilButton extends React.PureComponent<PencilButtonProps>
{
    public render()
    {
        return (
            <img className="pencilButton"
                 src={PencilIcon}
                 alt="edit"
                 onClick={this.handleOnClick}/>
        );
    }

    private handleOnClick = ( mouseEvent: React.MouseEvent<HTMLImageElement> ) =>
    {
        mouseEvent.stopPropagation();
        this.props.onClick();
    }
}
