import { connect } from "react-redux";
import { Dispatch } from "redux";
import { PostDetailsDialog, PostDetailsDialogDispatchProps, PostDetailsDialogProps } from "../PostDetails";
import { modalServices, postServices, upsellServices } from "../../services";
import {
    customizationHelper,
    CustomizePostOptions,
    Downloader,
    eventTracker,
    LightboxDialogIdentifierForKey,
    SUBSCRIPTION_FLOW_SOURCE_SCHEDULE_POST,
} from "../../helpers";
import { uiActions } from "../../actions";
import { getPostById } from "../../ducks/post";
import { DialogControlProps, Post, StoreState } from "../../_types";

interface PostDetailsDialogContainerProps extends DialogControlProps
{
    postId: number;
}

const mapStateToProps = ( storeState: StoreState, ownProps: PostDetailsDialogContainerProps ): PostDetailsDialogProps =>
{
    const post = getPostById( ownProps.postId, storeState );
    return {
        post,
        closeDialog: ownProps.closeDialog,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: PostDetailsDialogContainerProps ): PostDetailsDialogDispatchProps =>
{
    function openScheduledPostModal( aPostId: number, closePostDetails: () => void )
    {
        eventTracker.logScheduleSendCalendarDisplayed();
        dispatch( uiActions.schedulePostModalOpen( { postId: aPostId, closePostDetails } ) );
    }

    async function transitionToCustomize( post: Post, props: PostDetailsDialogContainerProps, customizePostOptions: CustomizePostOptions )
    {
        await customizationHelper.customizePost( post, dispatch, customizePostOptions );
        props.closeDialog();
    }

    return {
        editPost: ( post: Post, hasAdData: boolean ) =>
        {
            const postDetailsCopiedParams = { postId: post.id, isAd: hasAdData };
            eventTracker.logPostDetailsCopied( postDetailsCopiedParams );
            modalServices.encloseInLoadingDialog( async () =>
            {
                await transitionToCustomize( post, ownProps, { hasAdData } );
            } );
        },
        deletePost: ( post: Post ) =>
        {
            dispatch( modalServices.openLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.CONFIRM_DELETE_FINISHED_POST,
                title: "Delete this Post",
                width: 400,
                showCancelX: true,
                hideCancel: true,
                content: `Are you sure you want to delete this post?`,
                onSuccess: () =>
                {
                    dispatch( postServices.deletePost( post.id ) );
                    ownProps.closeDialog();
                },
                confirmLabel: "Delete",
            } ) );
        },
        downloadPost: ( post: Post ) =>
        {
            const { image_url, video_url } = post;
            const url = video_url || image_url;
            const downloader = new Downloader( url );
            downloader.download();
        },
        reschedulePost: ( post: Post ) =>
        {
            dispatch(
                upsellServices.ensureUserSubscribedBeforeRunningAction(
                    SUBSCRIPTION_FLOW_SOURCE_SCHEDULE_POST,
                    () =>
                    {
                        openScheduledPostModal( post.id, ownProps.closeDialog );
                    } ) );
        },
        hideExternalPost: ( post: Post ) =>
        {
            dispatch( modalServices.openLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.CONFIRM_DELETE_FINISHED_POST,
                title: "Hide this Post",
                width: 400,
                showCancelX: true,
                hideCancel: true,
                content: `Are you sure you want to hide this post made outside of Ripl?`,
                onSuccess: () =>
                {
                    dispatch( postServices.deletePost( post.id ) );
                    ownProps.closeDialog();
                },
                confirmLabel: "Hide",
            } ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( PostDetailsDialog );
