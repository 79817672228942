import { TeamMember } from "../../../_types";
import { TableColumn } from "react-md";
import * as React from "react";
import { ANONYMIZED_NAME, TEAM_MEMBER_ROLE_ADMIN } from "../../../helpers";
import { includes } from "lodash";

export function UsersTabRowNameColumn( props: { member: TeamMember } )
{
    return (
        <TableColumn
            tooltipLabel={getNameToolTip( props.member )}
            tooltipClassName="riplTooltip">
            {getFullName( props.member )}
        </TableColumn>
    );
}

function getNameToolTip( member: TeamMember )
{
    if ( isMissingName( member ) )
    {
        return "Each team member can add or update their name on the My Account page";
    }
    else
    {
        return null;
    }
}

function isMissingName( member: TeamMember )
{
    return member.full_name === member.email || includes( member.full_name, ANONYMIZED_NAME );
}

function getFullName( member: TeamMember )
{
    if ( isMissingName( member ) )
    {
        return getDefaultNameWhenMissing( member );
    }
    return member.full_name;
}

function getDefaultNameWhenMissing( member )
{
    if ( member.role === TEAM_MEMBER_ROLE_ADMIN )
    {
        return "Team owner";
    }
    else
    {
        return "Team member";
    }
}
