import { Dispatch } from "redux";
import { store } from "../store";
import {
    DesignInputDataParameters,
    getDesignAspectRatio,
    getEditorInputDataParameters,
    getMixModelMusicSelection,
    getMusicSettingsData,
    getPostAPIData,
    getPostId,
    getVideoOriginalFileName,
    getVideoOriginalFileSize,
    getVideoOriginalMediaType,
    getVideoOriginalSource,
    updateMixModelAfterInputMediaUpload,
    updateVideoTrimmerUrlInState,
} from "../ducks/mixModel";
import { ImageUploadData, mixModelActions, modalActions, postActions, shareModelActions, uiActions } from "../actions";
import { getSelectedDate } from "../ducks/ui";
import { designsHaveLoaded, getControlsConfigsLookup } from "../ducks/designs";
import { getCurrentBusiness, getCurrentBusinessId } from "../ducks/userBusiness";
import { getImageUrl, getShareCachebuster, getVideoUrl, getYouTubePrivacy, getYouTubeTitle, isHDAnimationOutputType } from "../ducks/shareModel";
import { isEmpty, isObject, throttle } from "lodash";
import { endOfMonth, format, startOfDay, startOfMonth } from "date-fns";
import {
    ACTIVITY_KEY_MOST_RECENT_POSTS,
    ACTIVITY_KEY_UNSCHEDULED_POSTS,
    addCommonAndPostQueryParams,
    addCommonQueryParams,
    apptimizeVariables,
    convertPriorityData,
    CUSTOMIZE_PAGE_URL,
    ENV_CONFIG,
    ERROR_TITLE_SORRY,
    errorReporting,
    eventTracker,
    FAILED_TO_START_UPLOAD_ERROR_SOURCE_MAP,
    getBaseResolution,
    getFallbackDate,
    history,
    HOMEPAGE_URL,
    LightboxDialogIdentifierForKey,
    LoadingDialogIdentifierForKey,
    normalizePostAPIForMultiplePosts,
    normalizePostAPIForSinglePost,
    POST_CREATE_RETURNED_INVALID_JSON,
    POST_FINISH_RETURNED_INVALID_JSON,
    POST_RESCHEDULE_RETURNED_INVALID_JSON,
    POST_UNSCHEDULE_RETURNED_INVALID_JSON,
    POST_UPDATE_RETURNED_INVALID_JSON,
    railsStringify,
    requests,
    retry,
    s3Uploader,
    SQUARE_ASPECT_RATIO,
    stringUtils,
} from "../helpers";
import { catalogServices, modalServices, userServices } from "./";
import { getCountOfMostRecentPostsMadeOutsideRipl, getCountOfMostRecentPostsMadeWithRipl, getPostById } from "../ducks/post";
import { Design, MediaData, Post, StoreState, UserBusiness } from "../_types";
import {
    AddMediaAPIResponse,
    CreateDraftAPI,
    DeleteExternalPostAPI,
    INPUT_MEDIA_TYPE,
    LEGACY_POST_TYPE_EXTERNAL,
    LoadDraftPostsRequest,
    LoadDraftPostsResultsAPI,
    PostAPI,
    PostAPIWrapper,
    PriorityDataAPI,
    PriorityDataRequest,
    RecordAPIRequestData,
    RecordAPIUpdate,
    RequestAddMediaAPI,
    ReschedulePostAPI,
    UserActivityAPIData,
    VIDEO_CONVERSION_CONNECTING,
    VIDEO_CONVERSION_STARTING,
    VIDEO_OUTPUT_TYPE,
    VIDEO_TRIM_CONNECTING,
    VIDEO_TRIM_STARTING,
} from "../_types/api";
import LoadingConvertVideoProgressContainer from "../components/containers/LoadingConvertVideoProgress.container";
import LoadingTrimVideoProgressContainer from "../components/containers/LoadingTrimVideoProgress.container";
import { getAllFacebookAndFacebookInstagramTypeSocialNetworkAccounts, isCollabraUser } from "../ducks/user";
import { getLoginDatumId } from "../ducks/partner";
import { partnerActions } from "../actions/partner.actions";

export const postServices = {
    createDraft,
    deletePost,
    deleteDraft,
    loadPriorityData,
    uploadPostMediaToS3,
    updatePostWithData,
    recordDesignAndUpdateProgress,
    finishAndShare,
    finishAndShareToPartner,
    loadDraftPosts,
    loadMostEngagedPostsSorted,
    loadMostRecentPostsWithLimit,
    loadNextMostRecentPostsWithLimit,
    loadPostsForDate,
    loadExamplePostWithId,
    loadUnscheduledPosts,
    reschedulePost,
    unschedulePost,
    convertVideo,
    trimVideo,
    loadPostById,
    loadDraftPost,
};

const MAX_DATA_LOAD_TRIES = 3;
const RECORD_MESSAGE_THROTTLE_MS = 500;
const CONVERT_VIDEO_THROTTLE_MS = 100;
const TRIM_VIDEO_THROTTLE_MS = 100;
export const LOAD_POST_LIMIT = 20;
const POST_URL_PREFIX = "/posts";

const CONVERT_VIDEO_API_PATH = "/convertVideoAndUploadToS3";
const TRIM_VIDEO_API_PATH = "/trimVideoAndUploadToS3";
const CREATE_EMPTY_POST_URL = POST_URL_PREFIX + "/api_create_empty_post";
const PRIORITY_DATA_URL = POST_URL_PREFIX + "/dates_with_ripl_and_external_post_activity_in_date_range_and_priority";
const REQUEST_ADD_FILE_TO_POST_PATH = "/request_add_file";
const UPDATE_DRAFT_SUFFIX = "/update_draft";
const DELETE_POST_SUFFIX = "/hide";
const RESCHEDULE_POST_SUFFIX = "/reschedule";
const UNSCHEDULE_POST_SUFFIX = "/unschedule";
const FINISH_AND_SHARE_SUFFIX = "/finish_and_share";
const FINISH_AND_SHARE_TO_PARTNER_SUFFIX = "/finish_and_share_to_partner";
const DRAFT_POSTS_URL = POST_URL_PREFIX + "/draft_posts";
const TOP_ENGAGED_POSTS_SORTED_URL = POST_URL_PREFIX + "/posts_for_date_ripl_and_external_sorted_by_engagement";
const MOST_RECENT_POSTS_URL = POST_URL_PREFIX + "/most_recent_posts";
const POSTS_FOR_DATE_URL = POST_URL_PREFIX + "/posts_for_date_ripl_and_external";
const UNSCHEDULED_POSTS_URL = POST_URL_PREFIX + "/unscheduled_posts";

const RIPL_ERROR_CODE_POST_NO_LONGER_DRAFT = 10000;
export const NO_LONGER_DRAFT_ERROR_MESSAGE = "This is not a draft.";

export function createNewPost( design?: Design )
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        try
        {
            if ( designsHaveLoaded( store.getState() ) || !window.catalogLoadPromise )
            {
                await proceedToCreateNewPost( dispatch, design );
            }
            else
            {
                await modalServices.encloseInLoadingDialog( async () =>
                {
                    await window.catalogLoadPromise;
                    await proceedToCreateNewPost( dispatch, design );
                } );
            }
        }
        catch (e)
        {
            dispatch( modalServices.openLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.UNABLE_TO_START_NEW_POST,
                content: "There was a problem starting your post. Please check your network connection, " +
                         "and contact feedback@ripl.com if problems continue.",
                hideCancel: true,
            } ) );
        }
    };
}

async function proceedToCreateNewPost( dispatch: Dispatch<StoreState>, design?: Design )
{
    const storeState = store.getState();
    const { user } = storeState;
    const controlsConfigsLookup = getControlsConfigsLookup( storeState );
    const currentBusiness = getCurrentBusiness( storeState );
    if ( apptimizeVariables.shouldDisableFacebookShare() )
    {
        const facebookAndInstagramSocialNetworkAccounts = getAllFacebookAndFacebookInstagramTypeSocialNetworkAccounts( storeState );
        dispatch( shareModelActions.facebookInstagramSocialNetworksDisabled( { facebookAndInstagramSocialNetworkAccounts } ) );
    }
    dispatch( mixModelActions.mixModelStarted( {
        currentBusiness,
        controlsConfigsLookup,
        user,
    } ) );

    if ( design )
    {
        await dispatch( catalogServices.selectDesign( design, SQUARE_ASPECT_RATIO ) );
    }
    await dispatch( postServices.createDraft() );

    history.push( CUSTOMIZE_PAGE_URL );
}

function createDraft( postIdToCopyMediaFrom?: number, isCopyOfPostForAd?: boolean )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( postActions.createRequest() );

        const queryParams: CreateDraftAPI = {};
        if ( postIdToCopyMediaFrom )
        {
            queryParams.post_id_to_copy_media_from = postIdToCopyMediaFrom;
            queryParams.copy_output_media = isCopyOfPostForAd;
        }

        const requestPromise = requests.post<CreateDraftAPI, PostAPIWrapper>( CREATE_EMPTY_POST_URL, {}, queryParams );
        return handleRequestResultForSinglePost( dispatch, requestPromise,
            postActions.createSuccess, postActions.createFailure,
            POST_CREATE_RETURNED_INVALID_JSON,
            "Failed to create draft." );
    };
}

function loadPriorityData( month: Date )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( postActions.priorityDataRequest() );
        const params: PriorityDataRequest = {
            begin_date: format( startOfMonth( month ) ),
            end_date: format( endOfMonth( month ) ),
            business_id: getCurrentBusinessId( store.getState() ),
        };
        const load = () => requests.get<PriorityDataAPI>( PRIORITY_DATA_URL, params );

        return retry( MAX_DATA_LOAD_TRIES, load ).then(
            ( data ) =>
            {
                const priorityData = convertPriorityData( data );
                dispatch( postActions.priorityDataSuccess( { month: startOfMonth( month ), data: priorityData } ) );
            },
            ( error: string ) =>
            {
                dispatch( postActions.priorityDataFailure( error ) );
                dispatch( modalServices.openErrorDialogWithStandardFormat(
                    "Unable to load post history.",
                    "Please try again later.",
                    error ) );
            },
        );
    };
}

type AfterUploadFunc = ( dispatch: Dispatch<StoreState>, data: AddMediaAPIResponse, mediaData?: MediaData ) => void;

function uploadPostMediaToS3( mediaData: MediaData, afterUpload: AfterUploadFunc = null )
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        const postId = getPostId( store.getState() );
        if ( !postId )
        {
            const noPostError = "Unable to upload media: Couldn't generate a post. Please try again later.";
            handleNoPostId( mediaData, noPostError, dispatch );
            return Promise.reject( noPostError );
        }

        dispatch( mixModelActions.addMediaFileRequest( mediaData.type ) );

        const url = POST_URL_PREFIX + "/" + postId + REQUEST_ADD_FILE_TO_POST_PATH;
        const queryParams: RequestAddMediaAPI = {
            media_type: mediaData.type,
            file_name: mediaData.filename,
        };

        try
        {
            const data = await requests.get<AddMediaAPIResponse>( url, queryParams );
            await s3Uploader.uploadMedia( data, mediaData.file );

            if ( afterUpload )
            {
                await afterUpload( dispatch, data, mediaData );
            }
        }
        catch (error)
        {
            errorReporting.reportErrorToSentry( `Failed to upload media (${error})` );
            dispatch( mixModelActions.addMediaFileFailure( mediaData.type ) );
            const errorSource = eventTracker.getUploadSourceForMediaType( mediaData.type );
            eventTracker.logMediaUploadFailedEvent( store.getState(), mediaData, mediaData.fileSize, error, errorSource );
            return Promise.reject( "Failed to upload media" );
        }
    };
}

function handleConvertVideoError( dispatch: Dispatch<StoreState>, fileName: string, fileSize?: number, errorDetail?: any )
{
    const errorMessage = "Failed to convert video file";
    eventTracker.logConvertVideoFailed();
    return handleAddMediaFileFailureAndShowErrorDialog( errorMessage, dispatch, fileName, fileSize, errorDetail );
}

function handleTrimVideoError( dispatch: Dispatch<StoreState> )
{
    const errorMessage = "Failed to trim video file";
    eventTracker.logTrimVideoFailed();
    return handleAddMediaFileFailureAndShowErrorDialog( errorMessage, dispatch );
}

function handleAddMediaFileFailureAndShowErrorDialog( errorMessage: string,
                                                      dispatch: Dispatch<StoreState>,
                                                      fileName?: string,
                                                      fileSize?: number,
                                                      errorDetail?: any )
{
    errorReporting.reportErrorToSentry( errorMessage, errorDetail );
    dispatch( mixModelActions.addMediaFileFailure( INPUT_MEDIA_TYPE ) );
    const errorSource = FAILED_TO_START_UPLOAD_ERROR_SOURCE_MAP[INPUT_MEDIA_TYPE];
    const mediaData = {
        media_type: INPUT_MEDIA_TYPE,
        original_file_name: fileName,
        s3_direct_url: null,
    };
    eventTracker.logMediaUploadFailedEvent( store.getState(), mediaData, fileSize, errorMessage, errorSource );
    dispatch( modalActions.loadingDialogClose() );
    dispatch( modalServices.openErrorDialog( ERROR_TITLE_SORRY, errorMessage ) );
    return Promise.reject( errorMessage );
}

function convertVideo( video: File | string, source?: string, mediaType?: string )
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        if ( !video )
        {
            const noVideoError = "Unable to convert video: No video file or URL was supplied. Please try again later.";
            return Promise.reject( noVideoError );
        }
        const state = store.getState();
        const postId = getPostId( state );
        const fileName = getVideoFileName( video );
        const fileSize = getVideoFileSize( video );
        if ( !postId )
        {
            const noPostError = "Unable to convert video: The containing post wasn't found. Please try again later.";
            dispatch( modalActions.loadingDialogClose() );
            dispatch( modalServices.openErrorDialog( ERROR_TITLE_SORRY, noPostError ) );
            return Promise.reject( noPostError );
        }

        let timer;
        try
        {
            dispatch( modalServices.openLoadingDialog( {
                content: LoadingConvertVideoProgressContainer,
            } ) );

            eventTracker.logConvertVideoStarted();

            dispatch( uiActions.mixModelConvertVideoProgress( { state: VIDEO_CONVERSION_STARTING, progress: 0 } ) );
            timer = startConnectionProgressTimer( dispatch, true );

            const isUrl = typeof video === "string";
            const queryParams = addCommonAndPostQueryParams( postId, { isUrl } );
            const ssrConvertUrlPath = ENV_CONFIG.recordBaseUrl + CONVERT_VIDEO_API_PATH + "?" + railsStringify( queryParams );

            let response;
            if ( isUrl )
            {
                response = await requests.postAndAcceptJson( ssrConvertUrlPath, { url: video, source, mediaType } );
            }
            else if ( video instanceof File )
            {
                response = await requests.postFileAndAcceptJson( ssrConvertUrlPath, video );
            }
            if ( response.body )
            {
                endConnectionProgressTimer( timer );
                // If the browser can handle updates, then help show intermediate data.
                await listenToConvertVideoProgress( response, dispatch, fileName, fileSize );
            }
        }
        catch (error)
        {
            // TODO: May be nice to show the error message from SSR if it's available
            return handleConvertVideoError( dispatch, fileName, fileSize, error );
        }
            // tslint:disable-next-line:one-line
        finally
        {
            endConnectionProgressTimer( timer );
            dispatch( modalActions.loadingDialogClose() );
        }
    };
}

function getVideoFileName( video: File | string )
{
    if ( video )
    {
        if ( video instanceof File )
        {
            return video.name;
        }
        else
        {
            return stringUtils.getFileName( video );
        }
    }
    return "";
}

function getVideoFileSize( video: File | string )
{
    if ( video && video instanceof File )
    {
        return video.size;
    }
    else
    {
        // TODO: We may need to pass the file size back from SSR
        return 0;
    }
}

function startConnectionProgressTimer( dispatch, isConversionFlag )
{
    let theProgress = 0.1;
    return setInterval( () =>
    {
        if ( theProgress < 1 )
        {
            updateProgressForConvertOrTrim( dispatch, isConversionFlag, theProgress );

            theProgress += 0.1; // ( ( 1 - theProgress ) / 5 );
        }
    }, 500 );
}

function updateProgressForConvertOrTrim( dispatch, isConversionFlag, progress )
{
    if ( isConversionFlag === true )
    {
        dispatch( uiActions.mixModelConvertVideoProgress( { state: VIDEO_CONVERSION_CONNECTING, progress } ) );
    }
    else
    {
        dispatch( uiActions.mixModelTrimVideoProgress( { state: VIDEO_TRIM_CONNECTING, progress } ) );
    }
}

function endConnectionProgressTimer( timer )
{
    clearInterval( timer );
}

function trimVideo( videoUrl: string, trimmerData: TrimmerTimeData )
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        const state = store.getState();
        const thePostId = getPostId( state );
        if ( !thePostId )
        {
            const noPostError = "Unable to trim video: The containing post wasn't found. Please try again later.";
            dispatch( modalServices.openErrorDialog( ERROR_TITLE_SORRY, noPostError ) );
            return Promise.reject( noPostError );
        }

        let theTimer;
        try
        {
            dispatch( modalServices.openLoadingDialog( {
                identifierForKey: LoadingDialogIdentifierForKey.TRIMMING,
                content: LoadingTrimVideoProgressContainer,
            } ) );

            const source = getVideoOriginalSource( state );
            const mediaType = getVideoOriginalMediaType( state );

            const theQueryParams = addCommonAndPostQueryParams( thePostId, {
                media_source: source,
                source_media_type: mediaType,
            } );

            const videoTrimData: TrimVideoAPIRequest = {
                trimData: trimmerData,
                url: videoUrl,
            };

            theTimer = startConnectionProgressTimer( dispatch, false );

            dispatch( uiActions.mixModelTrimVideoProgress( { state: VIDEO_TRIM_STARTING, progress: 0 } ) );

            const theUrl = ENV_CONFIG.recordBaseUrl + TRIM_VIDEO_API_PATH + "?" + railsStringify( theQueryParams );
            const theResponse = await requests.postAndAcceptJson<TrimVideoAPIRequest>( theUrl, videoTrimData );
            if ( theResponse.body )
            {
                endConnectionProgressTimer( theTimer );
                // If the browser can handle updates, then help show intermediate data.
                await listenToTrimVideoProgress( theResponse, dispatch, videoTrimData );
            }
        }
        catch (error)
        {
            return handleTrimVideoError( dispatch );
        }
            // tslint:disable-next-line:one-line
        finally
        {
            endConnectionProgressTimer( theTimer );
            dispatch( modalActions.loadingDialogClose() );
        }
    };
}

function loadPostById( postId: number )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( postActions.loadRequest() );
        return requests.get<PostAPIWrapper>( POST_URL_PREFIX + "/" + postId ).then(
            ( data ) =>
            {
                const normalizedPostData = normalizePostAPIForSinglePost( data );
                dispatch( postActions.finishSuccess( normalizedPostData ) );
            },
            ( error: string ) =>
            {
                dispatch( postActions.loadFailure( error ) );
            },
        );
    };
}

function loadDraftPost( postIdOrSlug: number | string )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( postActions.loadDraftRequest() );
        return requests.get<PostAPIWrapper>( POST_URL_PREFIX + "/" + postIdOrSlug ).then(
            ( data ) =>
            {
                const normalizedPostData = normalizePostAPIForSinglePost( data );
                dispatch( postActions.updateSuccess( normalizedPostData ) );
            },
            ( error: string ) =>
            {
                dispatch( postActions.loadDraftFailure( error ) );
            },
        );
    };
}

function handleNoPostId( aMediaData: MediaData, noPostError: string, dispatch: Dispatch<StoreState> )
{
    dispatch( mixModelActions.addMediaFileFailure( aMediaData.type ) );
    const errorSource = aMediaData.type && FAILED_TO_START_UPLOAD_ERROR_SOURCE_MAP[aMediaData.type];
    eventTracker.logMediaUploadFailedEvent( store.getState(), aMediaData, aMediaData.fileSize, noPostError, errorSource );
    dispatch( modalServices.openErrorDialog( ERROR_TITLE_SORRY, noPostError ) );
}

function updatePostWithData()
{
    return async ( dispatch: Dispatch<StoreState> ): Promise<void> =>
    {
        dispatch( postActions.updateRequest() );
        const postId = getPostId( store.getState() );
        if ( !postId )
        {
            const noPostErrorMessage = "Unable to update post: failed to generate a post";
            dispatch( postActions.updateFailure( noPostErrorMessage ) );
            return Promise.reject( noPostErrorMessage );
        }

        const postData = getPostAPIData( store.getState() );
        const url = POST_URL_PREFIX + "/" + postId + UPDATE_DRAFT_SUFFIX;
        const requestPromise = requests.patch<{ post: Partial<PostAPI> }, PostAPIWrapper>( url, { post: postData } );

        return handleRequestResultForSinglePost( dispatch, requestPromise,
            postActions.updateSuccess, postActions.updateFailure,
            POST_UPDATE_RETURNED_INVALID_JSON,
            "Failed to update draft." );
    };
}

function dispatchRecordingError( error, dispatch: Dispatch<StoreState>, serverError: boolean )
{
    const errorMessage = constructRecordingErrorText( error, serverError );
    eventTracker.logRecordingFailed( error, serverError );
    dispatch( modalServices.openErrorDialog( ERROR_TITLE_SORRY, errorMessage, true ) );
    dispatch( uiActions.mixModelRecordError( true ) );
}

function constructRecordingErrorText( error, serverError: boolean )
{
    const errorMessage = error && error !== "Unknown Error" ? " " + error : "";
    const userAlertStartText = "There was an issue recording your video.";

    if ( errorMessage )
    {
        if ( serverError )
        {
            return userAlertStartText + "\n\nThe server reported the following error: " + errorMessage;
        }
        else
        {
            return userAlertStartText + "\n\n" + errorMessage;
        }
    }
    else
    {
        return userAlertStartText;
    }
}

function recordDesignAndUpdateProgress( recordingVersion: number )
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        try
        {
            const state = store.getState();
            const postId = getPostId( store.getState() );
            const isHD = isHDAnimationOutputType( state );
            const queryParams = addCommonAndPostQueryParams( postId, {
                aspect_ratio: getDesignAspectRatio( state ),
                resolution: getBaseResolution( isHD ),
            } );

            const url = ENV_CONFIG.recordBaseUrl + "/record?" + railsStringify( queryParams );
            const inputDataParameters: DesignInputDataParameters = getEditorInputDataParameters( state );
            const imageUrl = getImageUrl( state );

            const bodyJson: RecordAPIRequestData = {
                templateInputData: inputDataParameters[0],
                templateSettings: inputDataParameters[1],
                globalSettings: inputDataParameters[2],
                musicSettings: loadMusicSettingsData( state ),
                imageUrl,
            };

            const response = await requests.postAndAcceptJson<RecordAPIRequestData>( url, bodyJson );
            eventTracker.logRecordingStarted();

            if ( response.body )
            {
                // If the browser can handle updates, then help show intermediate data.
                return listenToProgress( response, dispatch, recordingVersion );
            }

            const text = await response.text();
            return parseContent( text, true, dispatch, recordingVersion );
        }
        catch (error)
        {
            dispatchRecordingError( error, dispatch, false );
        }
    };
}

async function listenToProgress( aResponse, dispatch, recordingVersion: number ): Promise<void>
{
    const bodyReader = aResponse.body.getReader();
    const decoder = new TextDecoder( "utf-8" );
    let partialJson = "";
    while ( true )
    {
        const { done, value } = await bodyReader.read();
        partialJson += decoder.decode( value || new Uint8Array( 0 ), {
            stream: !done,
        } );

        if ( isRecordingCanceled( recordingVersion ) )
        {
            await bodyReader.cancel();
            return;
        }
        partialJson = parseContent( partialJson, done, dispatch, recordingVersion );
        if ( done )
        {
            return;
        }
    }
}

async function listenToConvertVideoProgress( aResponse, dispatch, fileName: string, fileSize: number ): Promise<void>
{
    const bodyReader = aResponse.body.getReader();
    const decoder = new TextDecoder( "utf-8" );
    let partialJson = "";
    while ( true )
    {
        const { done, value } = await bodyReader.read();
        partialJson += decoder.decode( value || new Uint8Array( 0 ), {
            stream: !done,
        } );

        partialJson = parseConvertVideoContent( partialJson, done, dispatch, fileName, fileSize );
        if ( done )
        {
            return;
        }
    }
}

async function listenToTrimVideoProgress( aResponse, dispatch, videoTrimData: TrimVideoAPIRequest ): Promise<void>
{
    const bodyReader = aResponse.body.getReader();
    const decoder = new TextDecoder( "utf-8" );
    let partialJson = "";
    while ( true )
    {
        const { done, value } = await bodyReader.read();
        partialJson += decoder.decode( value || new Uint8Array( 0 ), {
            stream: !done,
        } );

        partialJson = await parseTrimVideoContent( partialJson, done, dispatch, videoTrimData );
        if ( done )
        {
            return;
        }
    }
}

function isRecordingCanceled( recordingVersion: number )
{
    return getShareCachebuster( store.getState() ) !== recordingVersion;
}

function parseContent( partialJson, done, dispatch, recordingVersion: number )
{
    if ( isRecordingCanceled( recordingVersion ) )
    {
        return;
    }

    const completeObjects = partialJson.split( "\n" );
    if ( !done )
    {
        partialJson = completeObjects.pop();
        if ( completeObjects.length > 0 )
        {
            const mostRecentProgress = completeObjects.pop();
            const mostRecentProgressJson: RecordAPIUpdate = JSON.parse( mostRecentProgress );
            updateProgress( dispatch, mostRecentProgressJson );
        }
    }
    else
    {
        const lastObject = completeObjects.pop();
        const recordResult = JSON.parse( lastObject );
        if ( recordResult.url )
        {
            dispatch( shareModelActions.videoRecordingCompleted( recordResult ) );
            const mediaData = { media_type: VIDEO_OUTPUT_TYPE, s3_direct_url: recordResult.url };
            eventTracker.logMediaUploadSucceededEvent( store.getState(), mediaData, null );
            // tslint:disable-next-line:radix
            eventTracker.logRecordingCompleted( Number.parseInt( recordResult.recording_time_millis ) / 1000 );
        }
        else
        {
            const { error } = recordResult;
            dispatchRecordingError( error, dispatch, true );
        }
    }
    return partialJson;
}

function parseConvertVideoContent( partialJson, done, dispatch, fileName: string, fileSize: number )
{

    const completeObjects = partialJson.split( "\n" );
    if ( !done )
    {
        partialJson = completeObjects.pop();
        if ( completeObjects.length > 0 )
        {
            const mostRecentProgress = completeObjects.pop();
            const mostRecentProgressJson: ConvertVideoAPIUpdate = JSON.parse( mostRecentProgress );
            updateConvertVideoProgress( dispatch, mostRecentProgressJson );
        }
    }
    else
    {
        const convertedMP4S3UrlObject = JSON.parse( completeObjects.pop() );
        if ( convertedMP4S3UrlObject && convertedMP4S3UrlObject.s3_direct_url )
        {
            convertedMP4S3UrlObject.original_file_name = fileName;
            convertedMP4S3UrlObject.file_size = fileSize;
            updateVideoTrimmerUrlInState( dispatch, convertedMP4S3UrlObject );
            dispatch( mixModelActions.videoConvertedDataUploaded( convertedMP4S3UrlObject ) );
        }
        else
        {
            return handleConvertVideoError( dispatch, fileName, fileSize, "s3 url for converted video not found" );
        }
    }
    return partialJson;
}

async function parseTrimVideoContent( partialJson, done, dispatch, videoTrimData: TrimVideoAPIRequest )
{

    const completeObjects = partialJson.split( "\n" );
    if ( !done )
    {
        partialJson = completeObjects.pop();
        if ( completeObjects.length > 0 )
        {
            const mostRecentProgress = completeObjects.pop();
            const mostRecentProgressJson: TrimVideoAPIUpdate = JSON.parse( mostRecentProgress );
            updateTrimVideoProgress( dispatch, mostRecentProgressJson );
        }
    }
    else
    {
        const state = store.getState();
        const trimmedMP4S3UrlObject = JSON.parse( completeObjects.pop() );
        if ( trimmedMP4S3UrlObject )
        {
            trimmedMP4S3UrlObject.original_file_name = getVideoOriginalFileName( state );

            const newMediaId = stringUtils.getFileName( trimmedMP4S3UrlObject.url );
            const originalTrimmerData = {
                url: videoTrimData.url,
                time: videoTrimData.trimData,
            };
            const videoTrimmerDataSavedPayload = {
                mediaId: newMediaId,
                originalTrimmerData,
            };
            dispatch( mixModelActions.videoTrimmerOriginalDataSaved( videoTrimmerDataSavedPayload ) );
            eventTracker.logTrimVideoEnded( newMediaId );
            updateVideoTrimmerUrlInState( dispatch, trimmedMP4S3UrlObject );

            const newData: ImageUploadData = {
                uploadFields: trimmedMP4S3UrlObject,
                isLowRes: false,
                fileSize: getVideoOriginalFileSize( state ),
            };

            updateMixModelAfterInputMediaUpload( dispatch, newData );
        }
        else
        {
            return handleTrimVideoError( dispatch );
        }
    }
    return partialJson;
}

const updateProgress = throttle( ( dispatch: Dispatch<StoreState>, update: RecordAPIUpdate ) =>
{
    dispatch( uiActions.mixModelRecordProgress( update ) );
}, RECORD_MESSAGE_THROTTLE_MS, { trailing: true } );

const updateConvertVideoProgress = throttle( ( dispatch: Dispatch<StoreState>, update: ConvertVideoAPIUpdate ) =>
{
    dispatch( uiActions.mixModelConvertVideoProgress( update ) );
}, CONVERT_VIDEO_THROTTLE_MS, { trailing: true } );

const updateTrimVideoProgress = throttle( ( dispatch: Dispatch<StoreState>, update: TrimVideoAPIUpdate ) =>
{
    dispatch( uiActions.mixModelTrimVideoProgress( update ) );
}, TRIM_VIDEO_THROTTLE_MS, { trailing: true } );

function finishAndShare()
{
    return async ( dispatch: Dispatch<StoreState> ): Promise<void> =>
    {
        const storeState = store.getState();
        if ( isCollabraUser( storeState ) )
        {
            const errorMessage = "Unable to finish post: user is affiliated with partner";
            return Promise.reject( errorMessage );
        }

        dispatch( postActions.finishRequest() );
        const postId = getPostId( store.getState() );
        if ( !postId )
        {
            const noPostErrorMessage = "Unable to finish post: missing post id";
            dispatch( postActions.finishFailure( noPostErrorMessage ) );
            return Promise.reject( noPostErrorMessage );
        }

        const ytTitle = getYouTubeTitle( storeState );
        const ytPrivacy = getYouTubePrivacy( storeState );
        const postData: Partial<PostAPI> = {
            image_url: getImageUrl( storeState ),
            video_url: getVideoUrl( storeState ),
            youtube_title: ytTitle,
            youtube_privacy_status: ytPrivacy,
        };

        const url = POST_URL_PREFIX + "/" + postId + FINISH_AND_SHARE_SUFFIX;

        const body = { post: postData };
        return requests.patch<{ post: Partial<PostAPI> }, PostAPIWrapper>( url, body ).then(
            ( data ) =>
            {
                const normalizedData = normalizePostAPIForSinglePost( data );
                if ( normalizedData )
                {
                    dispatch( postActions.finishSuccess( normalizedData ) );
                }
                else
                {
                    errorReporting.reportErrorToSentry( POST_FINISH_RETURNED_INVALID_JSON );
                    dispatch( postActions.finishFailure( POST_FINISH_RETURNED_INVALID_JSON ) );
                    return Promise.reject( "Failed to finish post." );
                }
            },
            ( error ) =>
            {
                if ( error.should_reload_user )
                {
                    dispatch( userServices.loadMe() );
                }
                dispatch( postActions.finishFailure( error ) );
                return Promise.reject( "Failed to finish post: " + error.message );
            },
        );
    };
}

function finishAndShareToPartner()
{
    return async ( dispatch: Dispatch<StoreState> ): Promise<void> =>
    {
        const storeState = store.getState();
        if ( !isCollabraUser( storeState ) )
        {
            const errorMessage = "Unable to finish post and share to partner: user not affiliated with partner";
            return Promise.reject( errorMessage );
        }

        dispatch( postActions.finishRequest() );
        const postId = getPostId( store.getState() );
        if ( !postId )
        {
            const noPostErrorMessage = "Unable to finish post: missing post id";
            dispatch( postActions.finishFailure( noPostErrorMessage ) );
            return Promise.reject( noPostErrorMessage );
        }

        const postData: Partial<PostAPI> = {
            image_url: getImageUrl( storeState ),
            video_url: getVideoUrl( storeState ),
        };

        const url = POST_URL_PREFIX + "/" + postId + FINISH_AND_SHARE_TO_PARTNER_SUFFIX;

        const body = { post: postData };
        body["login_datum_id"] = getLoginDatumId( storeState );

        return requests.patch<{ post: Partial<PostAPI> }, PostAPIWrapper>( url, body ).then(
            ( data ) =>
            {
                const redirectUrl = data["redirect_url"];
                if ( redirectUrl )
                {
                    dispatch( partnerActions.redirectUrlSet( redirectUrl ) );
                }
                else
                {
                    return Promise.reject( "Failed to retrieve redirect URL." );
                }
            },
            ( error ) =>
            {
                dispatch( postActions.finishFailure( error ) );
                return Promise.reject( "Failed to finish post: " + error.message );
            },
        );
    };
}

function loadDraftPosts( business: UserBusiness )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( postActions.loadDraftRequest() );

        const queryParams: LoadDraftPostsRequest = business ? { business_id: business.id, pageSize: LOAD_POST_LIMIT } : { pageSize: LOAD_POST_LIMIT };
        const load = () => requests.get<LoadDraftPostsResultsAPI>( DRAFT_POSTS_URL, queryParams );

        return retry( MAX_DATA_LOAD_TRIES, load ).then(
            ( data ) =>
            {
                if ( store.getState().drafts.cancelNextDraftsLoad )
                {
                    dispatch( postActions.cancelNextDraftsLoad( false ) );
                    return;
                }
                const posts = isEmpty( data ) ? [] : data.posts;
                const normalizedActivityData = normalizePostAPIForMultiplePosts( posts );
                dispatch( postActions.loadDraftSuccess( normalizedActivityData ) );
            },
            ( error: string ) =>
            {
                dispatch( postActions.loadDraftFailure( error ) );
                dispatch( modalServices.openErrorDialogWithStandardFormat(
                    "Unable to load draft posts.",
                    "Please try again later.",
                    error ) );
            },
        );
    };
}

function loadMostEngagedPostsSorted()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const params = {
            business_id: getCurrentBusinessId( store.getState() ),
        };
        const load = () => requests.get<UserActivityAPIData>( TOP_ENGAGED_POSTS_SORTED_URL, params );

        return retry( MAX_DATA_LOAD_TRIES, load ).then(
            ( data ) =>
            {
                const posts = isEmpty( data ) ? [] : data.posts;
                const normalizedActivityData = normalizePostAPIForMultiplePosts( posts );
                dispatch( postActions.loadMostEngagedPostSuccess( normalizedActivityData ) );
                dispatch( postActions.mostEngagementsSortedDataSuccess( normalizedActivityData ) );
            },
            ( error: string ) =>
            {
                dispatch( postActions.mostEngagementsSortedDataFailure( error ) );
                dispatch( modalServices.openErrorDialogWithStandardFormat(
                    "Unable to load most engaged posts.",
                    "Please try again later.",
                    error ) );
            },
        );
    };
}

function updateHasLoadedAllRecentPosts( dispatch: Dispatch<StoreState>, posts, limit: number )
{
    dispatch( uiActions.loadedAllRecentPostsChanged( posts.length < limit ) );
}

function loadMostRecentPostsWithLimit( limit: number )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const params = {
            business_id: getCurrentBusinessId( store.getState() ),
            limit,
        };
        const load = () => requests.get<UserActivityAPIData>( MOST_RECENT_POSTS_URL, params );

        return retry( MAX_DATA_LOAD_TRIES, load ).then(
            ( data ) =>
            {
                const posts = isEmpty( data ) ? [] : data.posts;
                const normalizedActivityData = normalizePostAPIForMultiplePosts( posts );
                const actionPayload = {
                    activityKey: ACTIVITY_KEY_MOST_RECENT_POSTS,
                    data: normalizedActivityData,
                };
                dispatch( postActions.mostRecentPostsDataSuccess( actionPayload ) );
                updateHasLoadedAllRecentPosts( dispatch, posts, limit );
            },
            ( error: string ) =>
            {
                dispatch( postActions.mostRecentPostsDataFailure( error ) );
                dispatch( modalServices.openErrorDialogWithStandardFormat(
                    "Unable to load most recent posts.",
                    "Please try again later.",
                    error ) );
            },
        );
    };
}

function loadNextMostRecentPostsWithLimit( limit: number )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( postActions.loadMoreMostRecentPostsDataRequest() );
        const params = {
            business_id: getCurrentBusinessId( store.getState() ),
            limit,
            ripl_posts_offset: getCountOfMostRecentPostsMadeWithRipl( store.getState() ),
            external_posts_offset: getCountOfMostRecentPostsMadeOutsideRipl( store.getState() ),
        };
        const load = () => requests.get<UserActivityAPIData>( MOST_RECENT_POSTS_URL, params );

        return retry( MAX_DATA_LOAD_TRIES, load ).then(
            ( data ) =>
            {
                const posts = isEmpty( data ) ? [] : data.posts;
                const normalizedActivityData = normalizePostAPIForMultiplePosts( posts );
                const actionPayload = {
                    activityKey: ACTIVITY_KEY_MOST_RECENT_POSTS,
                    data: normalizedActivityData,
                };
                dispatch( postActions.loadMoreMostRecentPostsDataSuccess( actionPayload ) );
                updateHasLoadedAllRecentPosts( dispatch, posts, limit );
            },
            ( error: string ) =>
            {
                dispatch( postActions.loadMoreMostRecentPostsDataFailure( error ) );
                dispatch( modalServices.openErrorDialogWithStandardFormat(
                    "Unable to load more posts.",
                    "Please try again later.",
                    error ) );
            },
        );
    };
}

function loadPostsForDate( date: Date )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        if ( !date )
        {
            return;
        }

        const dateString = format( startOfDay( date ), "YYYY-MM-DD" );
        const params = {
            business_id: getCurrentBusinessId( store.getState() ),
            date: dateString,
        };
        const load = () => requests.get<UserActivityAPIData>( POSTS_FOR_DATE_URL, params );

        return retry( MAX_DATA_LOAD_TRIES, load ).then(
            ( data ) =>
            {
                const posts = isEmpty( data ) ? [] : data.posts;
                const normalizedData = normalizePostAPIForMultiplePosts( posts );
                const actionPayload = {
                    activityKey: dateString,
                    data: normalizedData,
                };
                dispatch( postActions.postDataForDateSuccess( actionPayload ) );
            },
            ( error: string ) =>
            {
                dispatch( postActions.postDataForDateFailure( error ) );
                dispatch( modalServices.openErrorDialogWithStandardFormat(
                    "Unable to load posts.",
                    "Please try again later.",
                    error ) );
            },
        );
    };
}

function loadUnscheduledPosts()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const params = {
            business_id: getCurrentBusinessId( store.getState() ),
            pageSize: 100,
        };
        const load = () => requests.get<UserActivityAPIData>( UNSCHEDULED_POSTS_URL, params );

        return retry( MAX_DATA_LOAD_TRIES, load ).then(
            ( data ) =>
            {
                const posts = isEmpty( data ) ? [] : data.posts;
                const normalizedData = normalizePostAPIForMultiplePosts( posts );
                const actionPayload = {
                    activityKey: ACTIVITY_KEY_UNSCHEDULED_POSTS,
                    data: normalizedData,
                };

                dispatch( postActions.loadUnscheduledPostsSuccess( actionPayload ) );
            },
            ( error: string ) =>
            {
                dispatch( modalServices.openErrorDialogWithStandardFormat(
                    "Unable to load posts.",
                    "Please try again later.",
                    error ) );
            },
        );
    };
}

function deletePost( aPostId: number )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const post = getPostById( aPostId, store.getState() );
        const fallbackDate = post && getFallbackDate( post );
        return deletePostOrDraft( dispatch, aPostId )
            .then( () =>
            {
                if ( fallbackDate )
                {
                    dispatch( loadPriorityData( fallbackDate ) );
                }
            } );
    };
}

function deleteDraft( aPostId: number )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        return deletePostOrDraft( dispatch, aPostId );
    };
}

function deletePostOrDraft( dispatch, aPostId: number )
{
    dispatch( postActions.deleteRequest() );

    if ( !aPostId )
    {
        const noPostErrorMessage = "Unable to delete post: Missing post id.";
        dispatch( postActions.deleteFailure( noPostErrorMessage ) );
        return Promise.reject( noPostErrorMessage );
    }

    const url = POST_URL_PREFIX + "/" + aPostId + DELETE_POST_SUFFIX;
    const post = getPostById( aPostId, store.getState() );
    const body = getOptionalBodyForDelete( post );
    return requests.post<DeleteExternalPostAPI, PostAPIWrapper>( url, body ).then(
        ( data ) =>
        {
            dispatch( postActions.deleteSuccess( aPostId ) );
        },
        ( error: string ) =>
        {
            dispatch( postActions.deleteFailure( error ) );
            dispatch( modalServices.openErrorDialogWithStandardFormat(
                "Unable to delete the post.",
                "Please try again later.",
                error ) );
        },
    );
}

function loadExamplePostWithId( dispatch, postId: string )
{
    if ( !postId )
    {
        const noPostErrorMessage = "Unable to load example post: It no longer exists.";
        dispatch( postActions.loadFailure( noPostErrorMessage ) );
        return Promise.reject( noPostErrorMessage );
    }

    const url = POST_URL_PREFIX + "/" + postId;
    const params = addCommonQueryParams( {} );

    const requestPromise = requests.get<PostAPIWrapper>( url, params );
    return handleRequestResultForSinglePost( dispatch, requestPromise,
        postActions.finishSuccess, postActions.loadFailure,
        "Failed to load example post from deep link",
        "Unable to load the post." );
}

function getOptionalBodyForDelete( post: Post ): DeleteExternalPostAPI
{
    if ( post && post.type === LEGACY_POST_TYPE_EXTERNAL )
    {
        return {
            post_type: post.type,
            social_network_account_id: post.social_network_accounts[0].id,
        };
    }
}

function reschedulePost( postId: number, scheduledDate: string )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( postActions.rescheduleRequest() );

        if ( !postId )
        {
            const noPostErrorMessage = "Unable to reschedule post";
            dispatch( postActions.rescheduleFailure( noPostErrorMessage ) );
            return Promise.reject( noPostErrorMessage );
        }

        const url = POST_URL_PREFIX + "/" + postId + RESCHEDULE_POST_SUFFIX;
        const body: ReschedulePostAPI = {
            new_scheduled_date: scheduledDate,
        };

        const requestPromise = requests.post<ReschedulePostAPI, PostAPIWrapper>( url, body );
        // TODO Display an error dialog to the user
        return handleRequestResultForSinglePost( dispatch, requestPromise,
            postActions.rescheduleSuccess, postActions.rescheduleFailure,
            POST_RESCHEDULE_RETURNED_INVALID_JSON,
            "Failed to reschedule the post." ).then( () =>
        {
            return dispatch( loadPriorityData( getSelectedDate( store.getState() ) ) );
        } );
    };
}

function unschedulePost( postId: number )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( postActions.unscheduleRequest() );

        if ( !postId )
        {
            const noPostErrorMessage = "Unable to unschedule post";
            dispatch( postActions.unscheduleFailure( noPostErrorMessage ) );
            return Promise.reject( noPostErrorMessage );
        }

        const url = POST_URL_PREFIX + "/" + postId + UNSCHEDULE_POST_SUFFIX;
        const queryParams = {
            make_draft: true,
        };
        const requestPromise = requests.post<void, PostAPIWrapper>( url, undefined, queryParams );
        return handleRequestResultForSinglePost( dispatch, requestPromise,
            postActions.unscheduleSuccess, postActions.unscheduleFailure,
            POST_UNSCHEDULE_RETURNED_INVALID_JSON,
            "Failed to unschedule the post." ).then( () =>
        {
            const state = store.getState();
            dispatch( loadPriorityData( getSelectedDate( state ) ) );
            dispatch( loadDraftPosts( getCurrentBusiness( state ) ) );

            const message = "Your post has been unscheduled. It will now show up as a draft on the Drafts tab.";
            dispatch( modalServices.openAlertDialog( { message } ) );
            history.push( HOMEPAGE_URL );
        } );
    };
}

function handleRequestResultForSinglePost( dispatch: Dispatch<StoreState>,
                                           requestPromise: Promise<PostAPIWrapper>,
                                           success,
                                           failure,
                                           invalidJsonErrorMessage,
                                           promiseRejectionReason: string )
{
    function createRejectionHandler()
    {
        return ( errorResponse ) =>
        {
            if ( isObject( errorResponse ) && errorResponse.code && errorResponse.code === RIPL_ERROR_CODE_POST_NO_LONGER_DRAFT )
            {
                dispatch( failure( errorResponse.error && errorResponse.error.toString() ) );
                return Promise.reject( NO_LONGER_DRAFT_ERROR_MESSAGE );
            }
            else
            {
                errorReporting.reportErrorToSentry( errorResponse );
                dispatch( failure( errorResponse && errorResponse.toString() ) );
                return Promise.reject( promiseRejectionReason );
            }
        };
    }

    const onrejected = createRejectionHandler();

    function createFulfilledHandler()
    {
        return ( data: PostAPIWrapper ): number | Promise<any> =>
        {
            const normalizedData = normalizePostAPIForSinglePost( data );
            if ( normalizedData )
            {
                dispatch( success( normalizedData ) );
                return normalizedData.result as number;
            }
            else
            {
                return onrejected( invalidJsonErrorMessage );
            }
        };
    }

    const onfulfilled = createFulfilledHandler();

    return requestPromise.then(
        onfulfilled,
        onrejected,
    );
}

function loadMusicSettingsData( state )
{
    const musicSelection = getMixModelMusicSelection( state );
    return getMusicSettingsData( musicSelection, state );
}
