import * as React from "react";
import { DialogControlProps } from "../../_types";
import { PremiumFocusedUpsellBenefits } from "./PremiumFocusedUpsellBenefits";
import { PurchaseProgressSpinner } from "../PurchaseProgressSpinner";
import { PremiumFocusedUpsellChoosePlanSection } from "./PremiumFocusedUpsellChoosePlanSection";
import CurrencySelectorContainer from "../containers/CurrencySelector.container";

export interface PremiumFocusedUpsellDialogProps extends DialogControlProps
{
  numPlans: number;
  annualPlan: StripePlanData;
  monthlyPlan: StripePlanData;
  hasUsedTrial?: boolean;
  arePlansLoading: boolean;
  shouldShowExchangeRates?: boolean;
  email: string;
  showAllPlans: boolean;
  selectedTier?: ProductTier;
}

export interface PremiumFocusedUpsellDispatchProps
{
  selectTier( tier: ProductTier ): void;
  handleStartSubscription( plan: StripePlanData ): void;
  setAllPlansVisible( visible: boolean ): void;
}

export class PremiumFocusedUpsellDialog extends React.Component<PremiumFocusedUpsellDispatchProps & PremiumFocusedUpsellDialogProps>
{

  public render()
  {
    const subtitleText = this.props.hasUsedTrial ? "" : "& get the first 7 days free!";

    return (
      <div className="premiumFocusedUpsellDialog">
        <div className="contentWrap">
          {this.props.shouldShowExchangeRates && <CurrencySelectorContainer/>}
          <div className="titleWrap">
            <div className="title">Choose a plan</div>
            <div className="subtitle">{subtitleText}</div>
          </div>
          {!this.props.showAllPlans && <PremiumFocusedUpsellBenefits selectedTier={this.props.selectedTier}/>}
          {this.renderChoosePlanSectionOrShowLoader()}
        </div>
      </div>
    );
  }

  private renderChoosePlanSectionOrShowLoader = () =>
  {
    if ( this.props.numPlans > 0 )
    {
      const {
        annualPlan,
        monthlyPlan,
        hasUsedTrial,
      } = this.props;

      return (
        <PremiumFocusedUpsellChoosePlanSection annualPlan={annualPlan}
                                               monthlyPlan={monthlyPlan}
                                               hasUsedTrial={hasUsedTrial}
                                               handleStartSubscription={this.props.handleStartSubscription}
                                               showAllPlans={this.props.showAllPlans}
                                               setAllPlansVisible={this.props.setAllPlansVisible}
                                               selectTier={this.props.selectTier}/>
      );
    }
    else
    {
      return (
        <div className="choosePlanSection">
          <PurchaseProgressSpinner/>
        </div>
      );
    }
  }
}
