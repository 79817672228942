import * as React from "react";
import { ColorControl } from "../ColorControl";
import { ColorValue } from "../../_types";
import { PencilButton } from "../PencilButton";
import * as PlusSmall from "../../assets/img/plus_small.svg";
import * as PlusRegular from "../../assets/img/newNavPlus.png";
import classNames from "classnames";
import { TrashButton } from "../TrashButton";

export interface ColorBoxEditableProps
{
    title: string;
    color: ColorValue;
    showColorHex?: boolean;
    showAdditionalEditIcon?: boolean;
    compact?: boolean;
    handleColorBoxClicked();
    handleOpen?();
    handleClear?();
}

export class ColorBoxEditable extends React.PureComponent<ColorBoxEditableProps>
{
    public render()
    {
        return (
            <div className={classNames( "selectColor", { compact: this.props.compact } )}>
                <div className="colorTitle">{this.props.title}</div>
                <div className="colorBoxAndButton">
                    <div className="colorBox" onClick={this.onColorBoxClicked}>
                        {
                            this.getControlContents()
                        }
                    </div>
                    {this.props.color && this.props.showColorHex && <span className="colorHex">{this.props.color}</span>}
                    {this.props.color && this.props.handleOpen && this.props.showAdditionalEditIcon && <PencilButton
                        onClick={this.props.handleOpen}/>}
                    {this.props.color && this.props.handleClear && <TrashButton altText="clear" onClick={this.props.handleClear}/>}
                </div>
            </div>
        );
    }

    private onColorBoxClicked = () =>
    {
        this.props.handleColorBoxClicked();
    }

    private getControlContents = () =>
    {
        const color = this.props.color;
        if ( color )
        {
            return <ColorControl value={color}/>;
        }
        else
        {
            const image = this.props.compact ? PlusSmall : PlusRegular;
            return <img className="plus" src={image} alt="add color"/>;
        }
    }
}
