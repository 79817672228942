import * as React from "react";
import { EngagementTypeCount } from "./EngagementTypeCount";

export interface EngagementSocialNetworkProps
{
    engagementSection: any;
}

export class EngagementSocialNetwork extends React.PureComponent<EngagementSocialNetworkProps>
{
    public render()
    {
        const socialIconImg = this.props.engagementSection.socialIcon;
        const engagements = this.props.engagementSection.engagements;
        return (
            <div className="engagementSocialNetwork">
                <img className="socialIcon icon large" src={socialIconImg}/>
                <div className="engagementSub">
                    {
                        engagements.map( ( socialCounts, index ) =>
                        {
                            return (
                                <EngagementTypeCount
                                    key={index}
                                    title={socialCounts.title}
                                    image={socialCounts.image}
                                    count={socialCounts.count}
                                />
                            );
                        } )
                    }
                </div>
            </div>
        );
    }
}
