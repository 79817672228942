import { StoreMigration, StoreState } from "../../_types";

export const rootMigrations: StoreMigration<StoreState> = {
    0: ( state ) =>
    {
        // sample migration
        return state;
    },
    2: ( state ) =>
    {
        return {
            ...state,
            posts: {
                ...state.posts,
                priorityData: {
                    error: [],
                    sent: [],
                    scheduled: [],
                },
            },
        };
    },
    3: ( state ) =>
    {
        // @ts-ignore
        const { videoCatalogCategories, ...remainingState } = state;
        return {
            ...remainingState,
        };
    },
    7: ( state ) =>
    {
        // @ts-ignore
        const { activity, ...remainingPostState } = state.posts;
        const updatedPostState = {
            activityMap: {},
            ...remainingPostState,
        };

        const { posts, ...remainingRootState } = state;
        return {
            ...remainingRootState,
            posts: {
                ...updatedPostState,
            },
        };
    },
    8: ( state ) =>
    {
        // @ts-ignore
        const { backgroundMedia, dynamicDesignData, ...theRestOfTheState } = state;

        return {
            ...theRestOfTheState,
            dynamicDesignData: {
                ...dynamicDesignData,
                backgroundMedia,
            },
        };
    },
};
