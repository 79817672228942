import { connect } from "react-redux";
import { Dispatch } from "redux";
import { getAllKnownBusinessesAndCurrentUserRole, getCurrentBusiness } from "../../ducks/userBusiness";
import { store } from "../../store";
import { uiActions, userBusinessActions } from "../../actions";
import {
    canUserAccessPaidFeatures,
    getCurrentUserId,
    isUserPaidThroughTeam,
    isUserStripeSubscribed,
    shouldBeUpsoldToPremium,
} from "../../ducks/user";
import { handleUpTierForNonStripeSubscriber, modalServices, upsellServices, userBusinessServices } from "../../services";
import { StoreState } from "../../_types";
import { BusinessSwitcher, BusinessSwitcherDispatchProps, BusinessSwitcherProps } from "../BusinessSwitcher";
import { BUSINESS_PICKER_SOURCE_DROPDOWN, eventTracker, NON_BREAKING_SPACE, SUBSCRIPTION_FLOW_SOURCE_ADD_NEW_BUSINESS_CLICK } from "../../helpers";

const mapStateToProps = ( storeState: StoreState ): BusinessSwitcherProps =>
{
    return {
        businesses: getAllKnownBusinessesAndCurrentUserRole( storeState ),
        currentBusiness: getCurrentBusiness( storeState ),
        shouldBeUpsoldToPremium: shouldBeUpsoldToPremium( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): BusinessSwitcherDispatchProps =>
{
    return {
        handleOpenCreateBusiness: () =>
        {
            eventTracker.logBusinessPickerDisplayed( BUSINESS_PICKER_SOURCE_DROPDOWN );

            const state = store.getState();

            if ( !shouldBeUpsoldToPremium( state ) )
            {
                dispatch( modalServices.openNewBusinessDialog() );
                return;
            }

            if ( !canUserAccessPaidFeatures( state ) || isUserPaidThroughTeam( state ) || isUserStripeSubscribed( state ) )
            {
                const dialogTitle = `Add more than one business with Ripl${NON_BREAKING_SPACE}Premium${NON_BREAKING_SPACE}plan!`;
                dispatch(
                    upsellServices.displayGradientHeaderPremiumBenefitsDialog( SUBSCRIPTION_FLOW_SOURCE_ADD_NEW_BUSINESS_CLICK,
                        dialogTitle ) );
            }
            else
            {
                handleUpTierForNonStripeSubscriber( dispatch, SUBSCRIPTION_FLOW_SOURCE_ADD_NEW_BUSINESS_CLICK );
            }
        },
        handleSwitchBusiness: ( businessId: number ) =>
        {
            const userBusinessData = { businessId, userId: getCurrentUserId( store.getState() ) };
            dispatch( userBusinessActions.businessSwitched( userBusinessData ) );
            dispatch( uiActions.clearBusinessTypeFilter() );
            dispatch( userBusinessServices.updateInternalDataAfterBusinessChange() );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( BusinessSwitcher );
