import * as React from "react";
import { EngagementDetail } from "./EngagementDetail";
import { FLAG_TYPE_CONFIG, formatDate, getFallbackDate, postHelper } from "../helpers";
import { PostPreview } from "./PostPreview";
import classNames from "classnames";

import * as deleteIcon from "../assets/img/deleteIcon.png";
import { isPostNew } from "../ducks/post";
import { Post } from "../_types";
import { store } from "../store";
import { Flag, FlagTypeConfig } from "./Flag";

export interface PostSummaryProps
{
    post: Post;
    isCalendarView: boolean;
}

export interface PostSummaryDispatchProps
{
    primaryAction?: ( post: Post, e: React.MouseEvent<HTMLElement> ) => void;
    deletePost?: ( post: Post ) => void;
}

export class PostSummary extends React.PureComponent<PostSummaryProps & PostSummaryDispatchProps>
{
    public render()
    {
        const post = this.props.post;
        if ( postHelper.isInspirationPost( post ) )
        {
            return this.createInspirationalPostView( post );
        }
        else if ( postHelper.isContentSearchPost( post ) || postHelper.isCollectionPost( post ) )
        {
            return this.createContentSearchPostView( post );
        }
        else if ( postHelper.isDraft( post ) )
        {
            return this.createDraftPostView( post );
        }
        else if ( this.props.isCalendarView )
        {
            return this.createCalendarPostView( post );
        }
        else
        {
            return this.createSentPostView( post );
        }
    }

    private createContentSearchPostView( post: Post )
    {
        return this.wrapInOuterContainersWithVideo( null, post );
    }

    private createInspirationalPostView = ( post ) =>
    {
        return this.wrapInOuterContainersWithVideo( this.createInspirationTopRightFlag( post ), post );
    }

    private createDraftPostView = ( post ) =>
    {
        const children = [this.createTopRightFlag( post ),
                          this.createFooter( post )];
        post.video_url = null;
        return this.wrapInOuterContainersWithVideo( children, post );
    }

    private createCalendarPostView = ( post ) =>
    {
        const children = [this.createTopRightFlag( post ),
                          this.createCalendarFooter( post )];
        return this.wrapInOuterContainersWithVideo( children, post );
    }

    private createSentPostView = ( post ) =>
    {
        const children = [this.createTopRightFlag( post ),
                          this.createFooter( post )];
        return this.wrapInOuterContainersWithVideo( children, post );
    }

    private wrapInOuterContainersWithVideo( children, post )
    {
        return (
            <div className="postSummary riplMasonryItem">
                <div className="container riplMasonryBoxShadow">
                    <PostPreview post={post} primaryAction={this.handlePrimaryActionClick} contentBelow={true}/>
                    {children}
                </div>
            </div>
        );
    }

    private handlePrimaryActionClick = ( post: Post, e: React.MouseEvent<HTMLElement> ) =>
    {
        this.props.primaryAction( post, e );
    }

    private createInspirationTopRightFlag = ( post ) =>
    {
        const storeState = store.getState();
        if ( isPostNew( post, storeState ) )
        {
            return this.createFlag( post, FLAG_TYPE_CONFIG.FLAG_NEW );
        }
    }

    private createTopRightFlag = ( post ) =>
    {
        if ( postHelper.isDraft( post ) )
        {
            return this.createFlag( post, FLAG_TYPE_CONFIG.FLAG_EDIT );
        }
        else if ( postHelper.isScheduled( post ) )
        {
            return this.createFlag( post, FLAG_TYPE_CONFIG.FLAG_SCHEDULED );
        }
        else
        {
            return (<EngagementDetail key={post.id + "-engagement"} className="positionTopRight"
                                      engagementData={post.engagement || { total_score: 0 }}/>);
        }
    }

    private createFlag = ( post: Post, flagTypeConfig: FlagTypeConfig ) =>
    {
        const isEditFlag = flagTypeConfig.text === FLAG_TYPE_CONFIG.FLAG_EDIT.text;
        const isScheduledFlag = flagTypeConfig.text === FLAG_TYPE_CONFIG.FLAG_SCHEDULED.text;
        return (
            <Flag key={post.id + "-flag"}
                  optionalClass={"onDesign " + flagTypeConfig.class}
                  inlineStyle={{ "--aspect-ratio": isEditFlag || isScheduledFlag ? 1 : 2.6 }}
                  flagTypeConfig={flagTypeConfig}/>
        );
    }

    private createFooter = ( post: Post ) =>
    {
        return (
            <div key={post.id + "-footer"} className="footer">
                {this.createFooterContents( post )}
            </div>
        );
    }

    private createFooterContents = ( post ) =>
    {
        if ( postHelper.isDraft( post ) )
        {
            return this.createDraftFooterContents( post );
        }
        else
        {
            return this.createStandardFooterContents( post );
        }
    }

    private createCalendarFooter = ( post: Post ) =>
    {
        return (
            <div key={post.id + "-calendar-footer"} className="footer">
                {this.createCalendarFooterContents( post )}
            </div>
        );
    }

    private createDraftFooterContents = ( post ) =>
    {
        return (
            <div className="fullWidth">
                <img className="deleteIcon clickable"
                     src={deleteIcon}
                     onClick={this.handleDeleteOnClick}/>
                <div className="labels">
                    <div className="content">
                        {post.content || " "}
                    </div>
                </div>
            </div>
        );
    }

    private createStandardFooterContents = ( post ) =>
    {
        return (
            <div className="labels">
                <div className="postDate">
                    {formatDate( getFallbackDate( post ) )}
                </div>
                <div className="content">
                    {post.content || " "}
                </div>
            </div>
        );
    }

    private createCalendarFooterContents = ( post ) =>
    {
        return (
            <div className="labels">
                <div className={classNames( "status", postHelper.getStatusClassFromPostModel( post ) )}>
                    <div className="circle">&nbsp;</div>
                    <div className="text">{postHelper.getStatusText( post )}</div>
                </div>
                <div className="postDate">
                    {postHelper.getDisplayablePostDateBasedOnStatus( post )}
                </div>
            </div>
        );
    }

    private handleDeleteOnClick = () =>
    {
        if ( postHelper.isDraft( this.props.post ) )
        {
            this.props.deletePost( this.props.post );
        }
    }
}
