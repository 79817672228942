import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../_types";
import { CreateYourOwnBanner, CreateYourOwnBannerDispatchProps, CreateYourOwnBannerProps } from "../../CreateYourOwnBanner";
import { mixModelActions, uiActions } from "../../../actions";
import { mixModelServices } from "../../../services";
import { ALL_SOURCE } from "../../../helpers";

const mapStateToProps = ( storeState: StoreState, ownProps ): CreateYourOwnBannerProps =>
{
    return ownProps;
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: CreateYourOwnBannerProps ): CreateYourOwnBannerDispatchProps =>
{
    return {
        handleClick: () =>
        {
            dispatch( mixModelServices.setupNewPostWithEventing( ALL_SOURCE ) );
            dispatch( mixModelActions.facebookAdModeSet( false ) );
        },
        handleBannerVisibilityChange: ( isVisible: boolean ) =>
        {
            dispatch( uiActions.updateCreateYourOwnBannerButtonVisibility( isVisible ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( CreateYourOwnBanner );
