import { connect } from "react-redux";
import { Dispatch } from "redux";
import { store } from "../../store";
import { instagramBusinessServices, modalServices, userServices } from "../../services";
import { FacebookButton, FacebookButtonDispatchProps, FacebookButtonProps } from "../FacebookButton";
import { uiActions } from "../../actions";
import {
    getFacebookProfileImageUrl,
    getUserSocialNetworkAccounts,
    isUserConnectedToFacebook,
    SOCIAL_NETWORK_ACCOUNT_FILTER_MODE_NOT_CURRENT_BUSINESS,
} from "../../ducks/user";
import { eventTracker, SocialAccountConnectedSource } from "../../helpers";
import { filter } from "lodash";
import { getCurrentBusiness } from "../../ducks/userBusiness";
import { FACEBOOK_ACCOUNT_TYPE, FACEBOOK_INSTAGRAM_ACCOUNT_TYPE, FACEBOOK_PAGE_ACCOUNT_TYPE } from "../../_types/api";
import { ConfirmFacebookDialogData, FacebookPickerDialogData, FacebookSocialNetworkAccountType, StoreState } from "../../_types";

interface ConnectFacebookButtonContainerProps
{
    accountType: FacebookSocialNetworkAccountType;
    buttonText?: string;
    label?: string;
    className?: string;
    buttonImage?: string;
    trackingSource: SocialAccountConnectedSource;
}

interface ConnectFacebookButtonContainerDispatchProps
{
    onButtonClick?: () => void;
}

const mapStateToProps = ( storeState: StoreState,
                          ownProps: ConnectFacebookButtonContainerProps & ConnectFacebookButtonContainerDispatchProps,
): FacebookButtonProps =>
{
    const { trackingSource, onButtonClick, ...newProps } = ownProps;
    return {
        ...newProps,
        user: storeState.user,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>,
                             ownProps: ConnectFacebookButtonContainerProps & ConnectFacebookButtonContainerDispatchProps,
): FacebookButtonDispatchProps =>
{
    return {
        onButtonClick: ( accountType: FacebookSocialNetworkAccountType ) =>
        {
            eventTracker.logSocialNetworkConnectClicked( accountType );
            if ( ownProps.onButtonClick )
            {
                ownProps.onButtonClick();
            }

            if ( accountType === FACEBOOK_INSTAGRAM_ACCOUNT_TYPE )
            {
                dispatch( modalServices.closeAllLightboxes() );
            }
        },
        onFacebookConnectSuccess: async ( accountType: FacebookSocialNetworkAccountType,
                                          userId: number,
                                          facebookId: number,
                                          name: string,
                                          facebookAccessToken: string ) =>
        {
            const profileImageUrl = getFacebookProfileImageUrl( facebookId.toString() );
            const confirmFacebookDialogData: ConfirmFacebookDialogData =
                {
                    profileImageUrl,
                    name,
                    onConfirm: async () =>
                    {
                        modalServices.encloseInLoadingDialog( async () =>
                        {
                            const success = await dispatch( userServices.clientFacebookConnect( userId, facebookId, facebookAccessToken ) );
                            if ( success )
                            {
                                handleAfterFacebookConnect( dispatch, accountType, ownProps.trackingSource );
                            }
                            else
                            {
                                eventTracker.logSocialAccountFailedToConnect( FACEBOOK_ACCOUNT_TYPE, ownProps.trackingSource );
                            }
                        } );
                    },
                };

            if ( !isUserConnectedToFacebook( store.getState() ) )
            {
                dispatch( uiActions.confirmFacebookModalOpen( confirmFacebookDialogData ) );
            }
            else
            {
                confirmFacebookDialogData.onConfirm();
            }
        },
        onFacebookConnectFailure: ( accountType: FacebookSocialNetworkAccountType,
                                    userId: number,
                                    failureData?: { permissionsDenied?: boolean } ) =>
        {
            eventTracker.logSocialAccountFailedToConnect( FACEBOOK_ACCOUNT_TYPE, ownProps.trackingSource );
        },
    };
};

function handleAfterFacebookConnect( dispatch: Dispatch<StoreState>,
                                     accountType: FacebookSocialNetworkAccountType,
                                     trackingSource: SocialAccountConnectedSource )
{
    const state = store.getState();

    if ( accountType === FACEBOOK_ACCOUNT_TYPE )
    {
        eventTracker.logSocialAccountConnected( FACEBOOK_ACCOUNT_TYPE, trackingSource );
    }
    else
    {
        const socialNetworkAccounts = getUserSocialNetworkAccounts( state, SOCIAL_NETWORK_ACCOUNT_FILTER_MODE_NOT_CURRENT_BUSINESS );
        const facebookItemsToPickFrom = filter( socialNetworkAccounts, ( account ) => account.account_type === accountType );

        if ( facebookItemsToPickFrom.length > 0 )
        {
            const dialogData: FacebookPickerDialogData = {
                accountType,
                allItems: facebookItemsToPickFrom,
                trackingSource,
            };
            dispatch( uiActions.facebookPickerModalOpen( dialogData ) );
        }
        else
        {
            if ( accountType === FACEBOOK_INSTAGRAM_ACCOUNT_TYPE )
            {
                dispatch( instagramBusinessServices.displayInstagramDontSeeBusinessDialog() );
            }
            else
            {
                const message = composeNoAvailableAccountsMessage( state, accountType );
                dispatch( modalServices.openAlertDialog( { message } ) );
            }
        }
    }
}

function composeNoAvailableAccountsMessage( state, accountType: FacebookSocialNetworkAccountType )
{
    const socialTypeNoun = accountType === FACEBOOK_PAGE_ACCOUNT_TYPE ? "pages" : "groups";
    const currentBusiness = getCurrentBusiness( state );
    const accountTypeNoun = currentBusiness ? "business" : "account";
    return `There are no available ${socialTypeNoun} to connect with your ${accountTypeNoun}.`;
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( FacebookButton );
