import * as React from "react";
import Button from "react-md/lib/Buttons/Button";
import { DiscoverIconImage } from "./icons/DiscoverIconImage";

export interface PostIntentsBannerProps
{
    text: string;
    buttonText: string;
}

export interface PostIntentsBannerDispatchProps
{
    handleClick();
}

export class PostIntentsBanner extends React.PureComponent<PostIntentsBannerDispatchProps & PostIntentsBannerProps>
{
    public render()
    {
        const { text, buttonText } = this.props;
        return (
            <div className= "postIntentsBanner twoBanners">
                <div className= "bannerFrame postIntents">
                    <div className="bannerText">{text}</div>
                        <Button
                            flat
                            className="postIntentsBannerButton"
                            onClick={this.props.handleClick}
                            iconEl={<DiscoverIconImage/>}>
                            {buttonText}
                        </Button>
                </div>
            </div>
        );
    }
}
