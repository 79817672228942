import { Dispatch } from "redux";
import { pricingActions, trackingActions } from "../actions";
import {
    eventTracker,
    LightboxDialogIdentifierForKey,
    MOBILE_WALL_BREAKPOINT_MAX_WIDTH,
    RIPL_TIER_BASE,
    RIPL_TIER_PREMIUM,
    SUBSCRIPTION_FLOW_SOURCE_ACCOUNT_SETTINGS,
    SUBSCRIPTION_FLOW_SOURCE_CREATE_ACCOUNT,
    SUBSCRIPTION_FLOW_SOURCE_CUSTOMIZE_NEXT_BUTTON,
    SUBSCRIPTION_FLOW_SOURCE_EPIDEMIC_MUSIC_TRACK,
    SUBSCRIPTION_FLOW_SOURCE_PURCHASE_PAGE,
    SubscriptionFlowSource,
    UPSELL_DIALOG_GRADIENT_VERSION_WIDTH,
    upsellDialogFactory,
    URLS,
} from "../helpers";
import { modalServices, pricingServices } from "./";
import { StoreState } from "../_types";
import { getSelectedTier } from "../ducks/pricing";
import { store } from "../store";
import { getPaymentPlatform, hasAcceptedTestDriveTerms, isUserPersonallySubscribed, isUserSubscribedIncludingProPreview } from "../ducks/user";
import classNames from "classnames";
import { includes } from "lodash";
import { PAYMENT_PLATFORM_ANDROID, PAYMENT_PLATFORM_IOS, PAYMENT_PLATFORM_STRIPE } from "../_types/api";

export const upsellServices = {
    displayTryProNoWarmup,
    displaySelectPriceTiersDialog,
    displaySelectPricePlansDialog,
    ensureUserSubscribedBeforeRunningAction,
    displayGradientHeaderPremiumBenefitsDialog,
    displayGradientHeaderEpidemicTrackPremiumDialog,
    displaySubscribedOnNonMobilePlatformDialog,
    displaySubscribedOnAndroidDialog,
    displaySubscribedOnIOSDialog,
    displayGradientHeaderSelectPlanDialogForTier,
    sourceShouldSeeTierWaveUpsell: sourceShouldSeePremiumFocusedUpsell,
};

interface TierUpsellDialogParams
{
    source: SubscriptionFlowSource;
    onUpsellSuccess?: () => void;
    onUpsellClose?: () => void;
    onUpsellCancel?: () => void;
}

function displaySelectPlansDialogContainer( selectedTier: ProductTier,
                                            onUpsellSuccess?: () => void,
                                            onUpsellClose?: () => void,
                                            onUpsellCancel?: () => void,
                                            source?: SubscriptionFlowSource )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const shouldShowSimplifiedUpsell = determineShouldShowSimplifiedUpsell( source );
        const makeFullBleedLightbox = window.innerWidth < MOBILE_WALL_BREAKPOINT_MAX_WIDTH;

        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.SELECT_PRICE_PLANS,
            showCancelX: false,
            modal: true,
            hideConfirm: true,
            hideCancel: true,
            width: 1000,
            height: 700,
            dialogContainerClassName: classNames( { fullBleed: makeFullBleedLightbox } ),
            className: classNames( "selectPriceTiersCommon", { simplifiedUpsell: shouldShowSimplifiedUpsell } ),
            footerClassName: "tryProFooter",
            closeDialogBeforeCallbacks: true,
            content: upsellDialogFactory.getStandardSelectPlansDialogContent( source, selectedTier ),
            onSuccess: onUpsellSuccess,
            onClose: onUpsellClose,
            onCancel: () =>
            {
                if ( source )
                {
                    eventTracker.logPurchaseWarmupDeclined( source );
                }

                if ( onUpsellCancel )
                {
                    onUpsellCancel();
                }
            },
        } ) );
    };
}

function displaySelectPriceTiersDialogContainer( onUpsellSuccess?: () => void,
                                                 onUpsellClose?: () => void,
                                                 onUpsellCancel?: () => void,
                                                 source?: SubscriptionFlowSource )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.SELECT_PRICE_TIERS,
            showCancelX: false,
            modal: true,
            hideConfirm: true,
            hideCancel: true,
            width: 1000,
            height: 700,
            className: "selectPriceTiersCommon selectPriceTiersDialog",
            footerClassName: "tryProFooter",
            closeDialogBeforeCallbacks: true,
            content: upsellDialogFactory.getStandardTiersDialogContent( source ),
            onSuccess: onUpsellSuccess,
            onClose: onUpsellClose,
            onCancel: () =>
            {
                if ( source )
                {
                    eventTracker.logPurchaseWarmupDeclined( source );
                }

                if ( onUpsellCancel )
                {
                    onUpsellCancel();
                }
            },
        } ) );
    };
}

function displayGradientHeaderSimplifiedTiersDialogContainer( onUpsellSuccess?: () => void,
                                                              onUpsellClose?: () => void,
                                                              onUpsellCancel?: () => void,
                                                              source?: SubscriptionFlowSource )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.SELECT_PRICE_TIERS,
            showCancelX: true,
            modal: true,
            hideConfirm: true,
            hideCancel: true,
            width: 802,
            className: "gradientHeaderDialog",
            footerClassName: "gradientHeaderDialogFooter",
            content: upsellDialogFactory.getSimplifiedTiersDialogContent( source ),
            onSuccess: onUpsellSuccess,
            onClose: onUpsellClose,
            onCancel: () =>
            {
                if ( source )
                {
                    eventTracker.logPurchaseWarmupDeclined( source );
                }

                if ( onUpsellCancel )
                {
                    onUpsellCancel();
                }
            },

        } ) );
    };
}

function determineShouldShowSimplifiedUpsell( source: SubscriptionFlowSource )
{
    const hasSeenProPreviewDialog = hasAcceptedTestDriveTerms( store.getState() );
    const isForCustomizeNextButton = wasUpsellDialogLaunchedByCustomizeNextButton( source );
    return hasSeenProPreviewDialog && isForCustomizeNextButton;
}

function wasUpsellDialogLaunchedByCustomizeNextButton( source: SubscriptionFlowSource )
{
    return source === SUBSCRIPTION_FLOW_SOURCE_CUSTOMIZE_NEXT_BUTTON;
}

function ensureUserSubscribedBeforeRunningAction( source: SubscriptionFlowSource, action: () => void )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const state = store.getState();
        if ( isUserSubscribedIncludingProPreview( state ) )
        {
            action();
        }
        else
        {
            dispatch( displayTryProNoWarmup( source, action ) );
        }
    };
}

function displayTryProNoWarmup( source: SubscriptionFlowSource,
                                onUpsellSuccess?: () => void,
                                onUpsellClose?: () => void,
                                onUpsellCancel?: () => void )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( trackingActions.subscriptionFlowStarted( source ) );
        eventTracker.logPurchaseWarmupDisplayed( source );

        if ( sourceShouldSeePremiumFocusedUpsell( source ) )
        {
            if ( !getSelectedTier( store.getState() ) )
            {
                dispatch( pricingActions.tierSelected( RIPL_TIER_BASE ) );
            }

            dispatch( displayPremiumFocusedUpsellDialog( { source, onUpsellSuccess, onUpsellClose, onUpsellCancel } ) );
        }
        else
        {
            dispatch( displayGradientHeaderSimplifiedTiersDialogContainer( onUpsellSuccess, onUpsellClose, onUpsellCancel, source ) );
        }
    };
}

function sourceShouldSeePremiumFocusedUpsell( source: SubscriptionFlowSource ): boolean
{
    const sourcesShouldSeeTierWaveUpsell: SubscriptionFlowSource[] = [
        SUBSCRIPTION_FLOW_SOURCE_CREATE_ACCOUNT,
        SUBSCRIPTION_FLOW_SOURCE_CUSTOMIZE_NEXT_BUTTON,
        SUBSCRIPTION_FLOW_SOURCE_ACCOUNT_SETTINGS,
        SUBSCRIPTION_FLOW_SOURCE_PURCHASE_PAGE,
    ];
    return includes<SubscriptionFlowSource>( sourcesShouldSeeTierWaveUpsell, source );
}

function displayPremiumFocusedUpsellDialog( upsellParams: TierUpsellDialogParams )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.TIER_WAVE_UPSELL,
            dialogContainerClassName: "premiumFocusedUpsellDialogContainer",
            width: null,
            showCancelX: true,
            hideConfirm: true,
            hideCancel: true,
            modal: true,
            content: upsellDialogFactory.getPremiumFocusedUpsellDialogContent(),
            onSuccess: upsellParams.onUpsellSuccess,
            onClose: upsellParams.onUpsellClose,
            onCancel: () =>
            {
                if ( upsellParams.source )
                {
                    eventTracker.logPurchaseWarmupDeclined( upsellParams.source );
                }

                if ( upsellParams.onUpsellCancel )
                {
                    upsellParams.onUpsellCancel();
                }
            },
        } ) );
    };
}

function displaySelectPriceTiersDialog( source: SubscriptionFlowSource,
                                        onUpsellSuccess?: () => void,
                                        onUpsellClose?: () => void,
                                        onUpsellCancel?: () => void )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( displaySelectPriceTiersDialogContainer( onUpsellSuccess, onUpsellClose, onUpsellCancel, source ) );
    };
}

function displaySelectPricePlansDialog( source: SubscriptionFlowSource,
                                        selectedTier: ProductTier,
                                        onUpsellSuccess?: () => void,
                                        onUpsellClose?: () => void,
                                        onUpsellCancel?: () => void )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( displaySelectPlansDialogContainer( selectedTier, onUpsellSuccess, onUpsellClose, onUpsellCancel, source ) );
    };
}

function displayGradientHeaderPremiumBenefitsDialog( source: SubscriptionFlowSource, dialogTitle: string )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const handleOpenSelectPremiumPlan = () =>
        {
            dispatch( upsellServices.displayGradientHeaderSelectPlanDialogForTier( source, RIPL_TIER_PREMIUM ) );
        };

        const confirmLabelText = isUserPersonallySubscribed( store.getState() ) ? "Switch to Ripl Premium" : "Choose Ripl Premium Plan";
        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.WARMUP_ADD_NEW_BUSINESS,
            showCancelX: true,
            modal: true,
            hideConfirm: false,
            confirmLabel: confirmLabelText,
            hideCancel: true,
            width: UPSELL_DIALOG_GRADIENT_VERSION_WIDTH,
            className: "gradientHeaderDialog",
            footerClassName: "gradientHeaderDialogFooter",
            closeDialogBeforeCallbacks: true,
            content: upsellDialogFactory.getPremiumBenefitsDialogContent( source, dialogTitle ),
            onSuccess: handleOpenSelectPremiumPlan,
        } ) );
    };
}

function displayGradientHeaderEpidemicTrackPremiumDialog()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const handleOpenSelectPremiumPlan = () =>
        {
            dispatch(
                upsellServices.displayGradientHeaderSelectPlanDialogForTier( SUBSCRIPTION_FLOW_SOURCE_EPIDEMIC_MUSIC_TRACK, RIPL_TIER_PREMIUM ) );
        };

        const confirmLabelText = "Check out Premium";
        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.WARMUP_ADD_NEW_BUSINESS,
            showCancelX: true,
            modal: true,
            hideConfirm: false,
            confirmLabel: confirmLabelText,
            hideCancel: true,
            width: UPSELL_DIALOG_GRADIENT_VERSION_WIDTH,
            className: "gradientHeaderDialog",
            footerClassName: "gradientHeaderDialogFooter",
            closeDialogBeforeCallbacks: true,
            content: upsellDialogFactory.getPremiumEpidemicSoundTrackDialogContent(),
            onSuccess: handleOpenSelectPremiumPlan,
        } ) );
    };
}

function displaySubscribedOnNonMobilePlatformDialog()
{
    return displaySubscribedOnOtherPlatformDialog( LightboxDialogIdentifierForKey.SUBSCRIBED_ON_OTHER_PLATFORM,
        upsellDialogFactory.getSubscribedOnOtherPlatformDialogContent() );

}

function displaySubscribedOnAndroidDialog()
{
    return displaySubscribedOnOtherPlatformDialog( LightboxDialogIdentifierForKey.SUBSCRIBED_ON_ANDROID,
        upsellDialogFactory.getSubscribedOnAndroidDialogContent() );

}

function displaySubscribedOnIOSDialog()
{
    return displaySubscribedOnOtherPlatformDialog( LightboxDialogIdentifierForKey.SUBSCRIBED_ON_IOS,
        upsellDialogFactory.getSubscribedOnIOSDialogContent() );
}

function displaySubscribedOnOtherPlatformDialog( identifier: LightboxDialogIdentifierForKey,
                                                 content: React.ComponentClass<any> | React.StatelessComponent<any> | string )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalServices.openLightbox( {
            identifierForKey: identifier,
            showCancelX: true,
            modal: true,
            hideConfirm: false,
            confirmLabel: "Learn more",
            hideCancel: true,
            width: UPSELL_DIALOG_GRADIENT_VERSION_WIDTH,
            className: "gradientHeaderDialog",
            footerClassName: "gradientHeaderDialogFooter",
            closeDialogBeforeCallbacks: true,
            onSuccess: openCrossPlatformUpgradeUrl,
            content,
        } ) );
    };
}

function displayGradientHeaderSelectPlanDialogForTier( source: SubscriptionFlowSource, tier: ProductTier )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( pricingActions.tierSelected( tier ) );

        modalServices.closeTopLightBox( dispatch, store.getState() );
        dispatch( pricingServices.load() );

        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.SELECT_PLAN_SECTION,
            showCancelX: true,
            modal: true,
            hideConfirm: true,
            hideCancel: true,
            width: UPSELL_DIALOG_GRADIENT_VERSION_WIDTH,
            className: "gradientHeaderDialog",
            footerClassName: "gradientHeaderDialogFooter",
            closeDialogBeforeCallbacks: true,
            content: upsellDialogFactory.getSimplifiedSelectPlanDialogContent( source, tier ),
        } ) );
    };
}

export function handleUpTierForIOSSubscriber( dispatch: Dispatch<StoreState>, source: SubscriptionFlowSource )
{
    eventTracker.logSubscriptionUpTierBlocked( source );
    dispatch( upsellServices.displaySubscribedOnIOSDialog() );
}

export function handleUpTierForAndroidSubscriber( dispatch: Dispatch<StoreState>, source: SubscriptionFlowSource )
{
    eventTracker.logSubscriptionUpTierBlocked( source );
    dispatch( upsellServices.displaySubscribedOnAndroidDialog() );
}

export function handleUpTierForOtherPlatformSubscriber( dispatch: Dispatch<StoreState>, source: SubscriptionFlowSource )
{
    eventTracker.logSubscriptionUpTierBlocked( source );
    dispatch( upsellServices.displaySubscribedOnNonMobilePlatformDialog() );
}

export function handleUpTierForNonStripeSubscriber( dispatch: Dispatch<StoreState>, source: SubscriptionFlowSource )
{
    const state = store.getState();
    if ( isUserPersonallySubscribed( state ) )
    {
        switch ( getPaymentPlatform( state ) )
        {
            case PAYMENT_PLATFORM_STRIPE:
                break;
            case PAYMENT_PLATFORM_ANDROID:
                handleUpTierForAndroidSubscriber( dispatch, source );
                break;
            case PAYMENT_PLATFORM_IOS:
                handleUpTierForIOSSubscriber( dispatch, source );
                break;
            default:
                handleUpTierForOtherPlatformSubscriber( dispatch, source );
        }
    }
}

function openCrossPlatformUpgradeUrl()
{
    window.open( URLS.HelpCrossPlatformUpgrade );
}
