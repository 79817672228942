import UploadCustomMusicButtonContainer from "./containers/UploadCustomMusicButton.container";
import * as React from "react";
import { MusicByEpidemicSound } from "./MusicByEpidemicSound";
import EpidemicSoundESConnectLinkContainer from "./containers/EpidemicSoundESConnectLink.container";

export const EpidemicBottomTray: React.FC = () =>
{

    return (
        <>
            <UploadCustomMusicButtonContainer/>
            <MusicByEpidemicSound children={getEpidemicSoundESConnectLink()}/>
        </>
    );

};

function getEpidemicSoundESConnectLink()
{
    return <EpidemicSoundESConnectLinkContainer/>;
}
