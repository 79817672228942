import * as React from "react";
import { ShareOutputListItem, ShareOutputListItemDispatchProps, ShareOutputListItemProps } from "./ShareOutputListItem";
import List from "react-md/lib/Lists/List";
import { oauth, SocialAccountConnectedSource } from "../helpers";

export interface ShareOutputDisplayProps extends ShareOutputListItemProps
{
    key: string;
}

export interface ShareOutputListProps
{
    accounts: ShareOutputDisplayProps[];
    trackingSource: SocialAccountConnectedSource;
    onlyShowConnectedAccounts: boolean;
}

export type ShareOutputListDispatchProps = ShareOutputListItemDispatchProps;

export class ShareOutputList extends React.PureComponent<ShareOutputListProps & ShareOutputListDispatchProps>
{
    public componentWillUnmount()
    {
        oauth.cleanupListener();
    }

    public render()
    {
        return (
            <List className="socialAccountList">
                {this.props.accounts.map( ( account ) =>
                {
                    if ( this.props.onlyShowConnectedAccounts && !account.accountConnected )
                    {
                        return <></>;

                    }
                    return <ShareOutputListItem
                        {...account}
                        {...this.props}
                    />;
                } )}
            </List>
        );
    }
}
