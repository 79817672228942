import * as React from "react";
import { ConnectButtonDispatchProps, ConnectButtonProps } from "./ConnectButton";
import ConnectFacebookButton from "./containers/ConnectFacebookButton.container";
import { SocialAccountConnectButton } from "./SocialAccountConnectButton";
import { isFacebookAccountType, isFacebookInstagramAccountType } from "../ducks/user";
import { apptimizeVariables, SocialAccountConnectedSource } from "../helpers";
import InstagramFauxConnectButton from "./containers/InstagramFauxConnectButton.container";
import { FacebookSocialNetworkAccountType, SocialNetworkAccountType } from "../_types";

export interface ConnectButtonProps
{
    label?: string;
    className?: string;
    accountType: SocialNetworkAccountType;
    buttonText?: string;
    trackingSource: SocialAccountConnectedSource;
    showInstagramSetupModalFirst: boolean;
}

export interface ConnectButtonDispatchProps
{
    onClick?: ( event: React.MouseEvent<HTMLElement> ) => void;
}

const CONNECT_BUTTON_LABEL = "Connect";

// TODO Determine if this and RefreshButton can be unified.
export class ConnectButton extends React.PureComponent<ConnectButtonProps & ConnectButtonDispatchProps>
{
    public render()
    {
        const buttonText = this.props.buttonText || CONNECT_BUTTON_LABEL;

        if ( this.shouldShowInstagramSetupModal() )
        {
            if ( this.shouldOverrideConnectFacebookButton() )
            {
                return <SocialAccountConnectButton {...this.props}
                                                   buttonText={buttonText}/>;
            }

            return <InstagramFauxConnectButton buttonText={buttonText} className={this.props.className} label={this.props.label}/>;
        }
        else if ( isFacebookAccountType( this.props.accountType ) || isFacebookInstagramAccountType( this.props.accountType ) )
        {
            if ( this.shouldOverrideConnectFacebookButton() )
            {
                return <SocialAccountConnectButton {...this.props}
                                                   buttonText={buttonText}/>;
            }

            const { onClick, accountType, trackingSource, ...props } = this.props;
            return <ConnectFacebookButton {...props}
                                          accountType={accountType as FacebookSocialNetworkAccountType}
                                          trackingSource={trackingSource}
                                          buttonText={buttonText}/>;
        }
        else
        {
            return <SocialAccountConnectButton {...this.props}
                                               buttonText={buttonText}/>;
        }
    }

    public shouldShowInstagramSetupModal()
    {
        return this.props.showInstagramSetupModalFirst && isFacebookInstagramAccountType( this.props.accountType );
    }

    private shouldOverrideConnectFacebookButton()
    {
        return apptimizeVariables.shouldDisableFacebookConnect();
    }
}
