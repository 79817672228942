import { connect } from "react-redux";
import { Dispatch } from "redux";
import { BackgroundChoice, StoreState } from "../../_types";
import { BackgroundMediaGridItem, BackgroundMediaGridItemDispatchProps, BackgroundMediaGridItemProps } from "../BackgroundMediaGridItem";
import { getEditorControl, getEditorControlValue } from "../../ducks/ui";
import { BackgroundMediaAPIItem, IControlConfig } from "../../_types/api";
import { mixModelActions } from "../../actions";
import { getControlDataForCurrentSelectedDesign } from "../../ducks/mixModel";
import { store } from "../../store";

export interface BackgroundMediaGridItemContainerProps
{
    backgroundMediaOption?: BackgroundMediaAPIItem;
}

const mapStateToProps = ( storeState: StoreState, ownProps: BackgroundMediaGridItemContainerProps ): BackgroundMediaGridItemProps =>
{
    return {
        ...ownProps,
        control: getEditorControl( storeState ),
        currentValue: getEditorControlValue( storeState ) as BackgroundChoice,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): BackgroundMediaGridItemDispatchProps =>
{
    return {
        applyBackground: ( control: IControlConfig, backgroundMediaAPIItem: BackgroundMediaAPIItem ) =>
        {
            const storeState = store.getState();
            const currentControlData = getControlDataForCurrentSelectedDesign( storeState );

            const controlId = control.id;
            const currentBackgroundMediaControlData = currentControlData[controlId] as BackgroundChoice;

            const media = backgroundMediaAPIItem;
            const backgroundChoice = { ...currentBackgroundMediaControlData, media };

            dispatch( mixModelActions.controlDataUpdated( { [controlId]: backgroundChoice } ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( BackgroundMediaGridItem );
