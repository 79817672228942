import { connect } from "react-redux";
import { eventTracker, history, POSTS_PAGE_DRAFTS_URL, POSTS_PAGE_ENGAGEMENTS_URL, POSTS_PAGE_SCHEDULE_URL, POSTS_PAGE_URL } from "../../../helpers";
import PostTabContainer from "./PostTab.container";
import ScheduleTabContainer from "./ScheduleTab.container";
import DraftTabContainer from "./DraftTab.container";
import EngagementsTabContainer from "./EngagementsTab.container";
import { PageWithTabbedSubNav, PageWithTabbedSubNavProps } from "../PageWithTabbedSubNav";
import { PageTabItem } from "../PageTabs";
import { chain } from "lodash";
import { isCollabraUser } from "../../../ducks/user";
import { StoreState } from "../../../_types";

const additionalContainerClass = "myPostsPage";

const myPostsTab: PageTabItem = {
    path: POSTS_PAGE_URL,
    component: PostTabContainer,
    name: "Posts",
    onClick: () =>
    {
        eventTracker.logProfilePostDisplayed();
        history.push( POSTS_PAGE_URL );
    },
};

const myDraftsTab: PageTabItem = {
    path: POSTS_PAGE_DRAFTS_URL,
    component: DraftTabContainer,
    name: "Drafts",
    onClick: () =>
    {
        eventTracker.logDraftsDisplayed();
        history.push( POSTS_PAGE_DRAFTS_URL );
    },
};

const myScheduleTab: PageTabItem = {
    path: POSTS_PAGE_SCHEDULE_URL,
    component: ScheduleTabContainer,
    name: "Schedule",
    onClick: () =>
    {
        eventTracker.logCalendarDisplayed();
        history.push( POSTS_PAGE_SCHEDULE_URL );
    },
};

const myEngagementsTab: PageTabItem = {
    path: POSTS_PAGE_ENGAGEMENTS_URL,
    component: EngagementsTabContainer,
    name: "Engagements",
    onClick: () =>
    {
        eventTracker.logLearnDisplayed();
        history.push( POSTS_PAGE_ENGAGEMENTS_URL );
    },
};

function getTabItems( storeState: StoreState ): PageTabItem[]
{
    const shouldShowCollabraUI = isCollabraUser( storeState );

    const tabs: Array<{ tab: PageTabItem, shouldShow: boolean }> = [
        {
            tab: myPostsTab,
            shouldShow: true,
        },
        {
            tab: myDraftsTab,
            shouldShow: true,
        },
        {
            tab: myScheduleTab,
            shouldShow: !shouldShowCollabraUI,
        },
        {
            tab: myEngagementsTab,
            shouldShow: !shouldShowCollabraUI,
        },
    ];

    return chain( tabs )
        .filter( ( tabFilter ) => tabFilter.shouldShow )
        .map( ( tabFilter ) => tabFilter.tab )
        .value();
}

const mapStateToProps = ( storeState: StoreState ): PageWithTabbedSubNavProps =>
{
    return {
        tabItems: getTabItems( storeState ),
        additionalContainerClass,
        path: window.location.pathname,
        shouldShowCollabraUI: isCollabraUser( storeState ),
    };
};

export default connect(
    mapStateToProps,
)( PageWithTabbedSubNav );
