import { connect } from "react-redux";
import { Dispatch } from "redux";
import FacebookLoginButton, { FacebookLoginButtonDispatchProps, FacebookLoginButtonProps } from "../FacebookLoginButton";
import { modalServices, userServices } from "../../services";
import { uiActions } from "../../actions";
import { getFacebookProfileImageUrl } from "../../ducks/user";
import { ConfirmFacebookDialogData, StoreState } from "../../_types";
import { ERROR_TITLE_SORRY, getFacebookAppId, THIRD_PARTY_LOGIN_ERROR_MESSAGES } from "../../helpers";
import { store } from "../../store";
import { getChannelTrackingParameters } from "../../ducks/tracking";

const mapStateToProps = ( state: StoreState ): FacebookLoginButtonProps =>
{
    return {
        facebookAppId: getFacebookAppId(),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): FacebookLoginButtonDispatchProps =>
{
    const storeState = store.getState();
    return {
        onFacebookLoginSuccess: ( facebookId: string, facebookAccessToken: string, name: string ) =>
        {
            const profileImageUrl = getFacebookProfileImageUrl( facebookId.toString() );
            const createBusinessIfNoneExist = true;
            const channelTrackingParameters = getChannelTrackingParameters( storeState );
            const theConfirmFacebookDialogData: ConfirmFacebookDialogData =
                {
                    profileImageUrl,
                    name,
                    onConfirm: () =>
                    {
                        dispatch( userServices.clientFacebookLogin( facebookId,
                            facebookAccessToken,
                            channelTrackingParameters,
                            createBusinessIfNoneExist ) );
                    },
                };

            dispatch( uiActions.confirmFacebookModalOpen( theConfirmFacebookDialogData ) );
        },
        onFacebookLoginFailure: ( response: string ) =>
        {
            dispatch( modalServices.openErrorDialog( ERROR_TITLE_SORRY, THIRD_PARTY_LOGIN_ERROR_MESSAGES.FACEBOOK_LOGIN ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( FacebookLoginButton );
