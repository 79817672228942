import * as React from "react";
import FontIcon from "react-md/lib/FontIcons/FontIcon";
import * as playButtonWhiteOnDarkGreyImg from "../assets/img/playButtonWhiteOnDarkGrey.png";
import { OutputModeToggleControl } from "./OutputModeToggleControl";
import classNames from "classnames";

export interface ArtBoardControlProps
{
    currentSlideIndex: number;
    totalNumberOfSlides: number;
    isAnimatedOutputMode: boolean;
    isBrandSlideTabSelected: boolean;
}

export interface ArtBoardControlDispatchProps
{
    onShowPreviousSlideClicked: () => void;
    onShowNextSlideClicked: () => void;
    previewDesign: () => void;
    outputModeChanged: () => void;
    onClickExitBrandSlideView: () => void;
}

export class ArtBoardControl extends React.PureComponent<ArtBoardControlProps & ArtBoardControlDispatchProps>
{
    public render()
    {

        return (
            <div className={
                classNames( "artBoardControlContainer",
                    { brandSlideArtBoardControl: this.props.isBrandSlideTabSelected } )}>
                <div className="artBoardControl">
                    {!this.props.isBrandSlideTabSelected && this.createMainArtBoardControl()}
                    {this.props.isBrandSlideTabSelected && this.createBrandSlideArtBoardControl()}
                </div>
            </div>
        );
    }

    private createMainArtBoardControl = () =>
    {
        const shouldShowArrows = this.props.totalNumberOfSlides > 1;

        const toggleOutputControlContent = this.createToggleOutputControlContent();
        return <>
            <div className="paginationContainer">
                {shouldShowArrows &&
                 <FontIcon className="backArrow" onClick={this.props.onShowPreviousSlideClicked}>chevron_left</FontIcon>
                }
                <span className="pageNumbers"> {this.props.currentSlideIndex} of {this.props.totalNumberOfSlides}</span>
                {shouldShowArrows &&
                 <FontIcon className="forwardArrow" onClick={this.props.onShowNextSlideClicked}>chevron_right</FontIcon>
                }
            </div>
            <div className="artBoardDivider"/>
            {this.createPlayButtonContent()}
            {this.props.isAnimatedOutputMode && <div className="artBoardDivider"/>}
            {toggleOutputControlContent}
        </>;
    }

    private createBrandSlideArtBoardControl = () =>
    {
        return <>
            {this.createPlayButtonContent()}
            <div className="artBoardDivider"/>
            <div className="exitBrandSlide" onClick={this.props.onClickExitBrandSlideView}>Exit Brand Slide view</div>
        </>;
    }

    private createPlayButtonContent()
    {
        if ( this.props.isAnimatedOutputMode )
        {
            return <img className="artBoardControlPlayButton"
                        src={playButtonWhiteOnDarkGreyImg}
                        onClick={this.props.previewDesign}
            />;
        }
        return null;
    }

    private createToggleOutputControlContent()
    {
        return <div>
            <OutputModeToggleControl
                isAnimatedOutputMode={this.props.isAnimatedOutputMode}
                outputModeChanged={this.props.outputModeChanged}/>
        </div>;
    }
}
