import * as React from "react";
import { URLS } from "../helpers";
import { SocialNetworkAccountType } from "../_types";
import { LINKEDIN_ACCOUNT_TYPE, TWITTER_ACCOUNT_TYPE, YOUTUBE_ACCOUNT_TYPE } from "../_types/api";

export interface AddAccountErrorDialogContentProps
{
    accountType?: SocialNetworkAccountType;
}

export class AddAccountErrorDialogContent extends React.PureComponent<AddAccountErrorDialogContentProps>
{
    public render()
    {
        return (
            <div>
                Account already connected.

                <div className="contactSupport">
                    {this.renderAccountAlreadyConnectedMessage()}
                </div>

                <div className="contactSupport">
                    To learn more about connecting accounts visit our <a className="standardLink" href={URLS.ConnectAccountsFAQ} target="_blank">FAQ page</a>.
                </div>
            </div>
        );
    }

    private renderAccountAlreadyConnectedMessage = () =>
    {
        if ( this.props.accountType === TWITTER_ACCOUNT_TYPE )
        {
            return (
                <>
                    To connect a different account, please <a className="standardLink" href={URLS.LOGOUT_TWITTER_URL} target="_blank">log out of
                                                                                                             Twitter</a> and
                    try again.
                </>
            );
        }
        else if ( this.props.accountType === LINKEDIN_ACCOUNT_TYPE )
        {
            return (
                <>
                    To connect a different account, please <a className="standardLink" href={URLS.LOGOUT_LINKED_IN_URL} target="_blank">log out of
                                                                                                               LinkedIn</a> and
                    try again.
                </>
            );
        }
        else if ( this.props.accountType === YOUTUBE_ACCOUNT_TYPE )
        {
            return (
                <>
                    Please try again and choose a different Google account when prompted.
                </>
            );
        }
        return `To connect to a different ${this.props.accountType} account,`
               + ` please visit ${this.props.accountType} in a different window, log out, and try connecting it to Ripl again`;
    }
}
