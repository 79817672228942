import { store } from "../store";
import {
    ACCEPT_TEAM_INVITE_PARAM,
    ACCOUNT_CREATED,
    COLLECTION_ACTION_PARAM,
    COLLECTION_PAGE_URL,
    CUSTOMIZE_PAGE,
    CUSTOMIZE_PAGE_URL,
    DEFAULT_LOGIN_REDIRECT_URL,
    ERROR_TITLE_SORRY,
    eventTracker,
    ExtendedErrorInfo,
    history,
    HOMEPAGE_URL,
    ImageResizer,
    LightboxDialogIdentifierForKey,
    LOGGED_IN,
    LOGIN_TYPE_EMAIL,
    LOGIN_TYPE_FACEBOOK,
    LOGIN_TYPE_GOOGLE,
    LOGIN_TYPE_VIA_EMAIL,
    LOGIN_TYPE_VIA_FACEBOOK,
    LOGIN_URL,
    LoginType,
    MISSING_USER_ID_IN_REQUEST,
    normalizeMeAPI,
    OPEN_DESIGN_PARAM,
    PARTNER_USER_LOGIN_URL,
    PASSWORD_RESET_SENT_TO_EMAIL,
    PASSWORD_RESET_SENT_TO_EMAIL_TITLE,
    PRO_PREVIEW_ENABLED,
    PURCHASE_PAGE_URL,
    requests,
    THIRD_PARTY_LOGIN_ERROR_MESSAGES,
    validateEmail,
} from "../helpers";
import { Dispatch } from "redux";
import { engagementsActions, mixModelActions, shareModelActions, ShareOutputEnabledPayload, uiActions, userActions } from "../actions";
import { facebookServices, logoutServices, modalServices, startupServices, userBusinessServices } from "./";
import { filter, forEach, get, head, includes, isEmpty, mapValues, toLower, upperFirst, values } from "lodash";
import {
    getCurrentUserId,
    getUserSlug,
    getUserSocialNetworkAccounts,
    hasAcceptedTestDriveTerms,
    isSubscriptionOnHold,
    isUserPersonallySubscribed,
} from "../ducks/user";
import { getCurrentBusinessId, isUserMemberOfAnyTeamBusiness } from "../ducks/userBusiness";
import { getEditedBusinessInfo, getNewBusinessAPIData, getRedirectUrl, ProcessTrackingKey } from "../ducks/ui";
import { ChannelTrackingParameters, SocialNetworkAccount, SocialNetworkAccountType, StoreState, UserState } from "../_types";
import {
    COULD_NOT_UPDATE_EMAIL_ERROR_MESSAGE,
    FACEBOOK_ACCOUNT_TYPE,
    FACEBOOK_GROUP_ACCOUNT_TYPE,
    FACEBOOK_INSTAGRAM_ACCOUNT_TYPE,
    FACEBOOK_PAGE_ACCOUNT_TYPE,
    GetConnectedGroupDataRequest,
} from "../_types/api";
import { detect } from "detect-browser";
import { partnerActions } from "../actions/partner.actions";

export const userServices = {
    loginToEmailAccount,
    loginWithLoginToken,
    createEmailAccount,
    forgotPassword,
    resetPassword,
    clientFacebookCreateAccount,
    clientFacebookLogin,
    clientFacebookConnect,
    clientGoogleCreateAccount,
    clientGoogleLogin,
    clientGoogleConnect,
    clientGoogleDisconnect,
    clientEpidemicSoundDisconnect,
    getRiplConnectedFacebookGroups,
    clientSocialAccountDisconnect,
    enableSocialNetworkAccounts,
    loadMe,
    loadWeeklyEngagements,
    update,
    updatePhoto,
    changePassword,
    convertAccountTypeToUrlPart,
    requestDeleteAccount,
    submitTicket,
    easyCaption,
    shouldShowProOnlyUpsellDialog,
    shouldShowSubscriptionOnHoldLockoutMessage,
    createBusinessAndFinish,
};

const USER_URL_PREFIX = "/users";
const AUTH_URL_PREFIX = "/auth";

const FACEBOOK_LOGIN_URL = USER_URL_PREFIX + "/client_facebook_login";
const FACEBOOK_CREATE_ACCOUNT_URL = USER_URL_PREFIX + "/client_facebook_create";
const GET_CONNECTED_GROUPS_URL = "get_connected_facebook_groups";

const GOOGLE_LOGIN_URL = USER_URL_PREFIX + "/client_google_login";
const GOOGLE_CONNECT_SUFFIX = "client_google_connect";
const GOOGLE_DISCONNECT_SUFFIX = "client_google_disconnect";

const EPIDEMIC_SOUND_DISCONNECT_ERROR_MESSAGE = "We were unable to disconnect your Epidemic Sound account";
const EPIDEMIC_SOUND_DISCONNECT_SUFFIX = "client_epidemicsound_disconnect";

const TOKEN_LOGIN_URL = USER_URL_PREFIX + "/client_token_login";

const ME_URL = USER_URL_PREFIX + "/me";

const EMAIL_CREATE_ACCOUNT_URL = AUTH_URL_PREFIX;
const CHANGE_PASSWORD_URL = AUTH_URL_PREFIX;
const EMAIL_LOGIN_URL = AUTH_URL_PREFIX + "/sign_in";
const FORGOT_PASSWORD_URL = AUTH_URL_PREFIX + "/password";
const RESET_PASSWORD_URL = AUTH_URL_PREFIX + "/password";

const FACEBOOK_CONNECT_SUFFIX = "client_facebook_connect";
const ADD_BUSINESS_ACCOUNT_SUFFIX = "add_business_account";
const RECENT_ENGAGEMENTS_INFO_SUFFIX = "recent_engagements_info";
const SUBMIT_TICKET_SUFFIX = "submit_ticket";
const REQUEST_DELETE_ACCOUNT = "request_account_deletion";
const EMAIL_CUSTOMER_SUPPORT_MESSAGE = "Email feedback@ripl.com if you continue to have trouble.";
const GOOGLE_CONNECT_ERROR_MESSAGE = "Could not connect your Google account";
const GOOGLE_DISCONNECT_ERROR_MESSAGE = "Could not disconnect your Google account";
const GOOGLE_CONNECT_SUCCEEDED = "googleConnectSucceeded";
const GOOGLE_CONNECT_FAILED = "googleConnectFailed";

const DELETE_ACCOUNT_ERROR_MESSAGE = "We had a problem delivering your request. Please try again. If the error persists, contact Customer Support";

const EASY_CAPTION_URL: string = "/open_ai/get_caption";

const browser = detect();

export const CLIENT_CONNECT_URL_PART_FACEBOOK_PAGE = "facebook_page";
export const CLIENT_CONNECT_URL_PART_FACEBOOK_GROUP = "facebook_group";
export const CLIENT_CONNECT_URL_PART_FACEBOOK_INSTAGRAM = "facebook_instagram";

function loginToEmailAccount( emailAddress: string, password: string, channelTrackingParameters?: ChannelTrackingParameters )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const body = {
            user: {
                email: emailAddress,
                password,
            },
            channel_tracking_parameters: channelTrackingParameters,
            pro_preview: PRO_PREVIEW_ENABLED,
        };

        return requests.post<any, MeAPIData>( EMAIL_LOGIN_URL, body )
            .then(
                ( data ) =>
                {
                    handleSuccessfulLogin( data, dispatch, LOGGED_IN, LOGIN_TYPE_VIA_EMAIL );
                },
                ( error: DeviseErrorResponse ) => dispatch( uiActions.setEmailFormErrors( parseDeviseErrors( error ) ) ),
            );
    };
}

function loginWithLoginToken( token: string, redirectAfterLogin: boolean )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const body = {
            token,
        };

        return requests.post<any, MeAPIData>( TOKEN_LOGIN_URL, body )
            .then(
                ( data ) =>
                {
                    if ( redirectAfterLogin )
                    {
                        handleSuccessfulLogin( data, dispatch, LOGGED_IN, LOGIN_TYPE_VIA_EMAIL );
                    }
                    else
                    {
                        handleSuccessfulLoginWithoutRedirect( data, dispatch, LOGGED_IN, LOGIN_TYPE_VIA_EMAIL );
                    }
                    store.dispatch( partnerActions.historyReferrerIndexSet( history.length - 1 ) );
                    dispatch( partnerActions.loginDatumIdSet( data.user_partner_data.login_datum_id ) );
                },
                ( error: DeviseErrorResponse ) => dispatch( uiActions.setPartnerLoginError( parseDeviseErrors( error ).error ) ),
            );
    };
}

function changePassword( aPassword: string )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const body = {
            user: {
                password: aPassword,
            },
        };

        dispatch( uiActions.setResetFormProcessing( true ) );

        return requests.put<any, MeAPIData>( CHANGE_PASSWORD_URL, body )
            .then(
                ( data ) =>
                {
                    dispatch( uiActions.setResetFormProcessing( false ) );
                    dispatch( modalServices.openLightbox( {
                        identifierForKey: LightboxDialogIdentifierForKey.PASSWORD_CHANGED,
                        content: "Your password has been changed!",
                        hideCancel: true,
                        showCancelX: true,
                    } ) );
                    handleSuccessfulLogin( data, dispatch, LOGGED_IN, LOGIN_TYPE_VIA_EMAIL );
                },
                ( error: DeviseErrorResponse ) =>
                {
                    dispatch( uiActions.setResetFormProcessing( false ) );
                    dispatch( uiActions.setResetFormErrors( parseDeviseErrors( error ) ) );
                },
            );
    };
}

function createEmailAccount( emailAddress: string,
                             password: string,
                             sendEmailFlag: boolean,
                             channelTrackingParameters?: ChannelTrackingParameters,
                             thirdPartyParameters?: ThirdPartyInstallParameters,
                             createBusinessParameter?: boolean,
                             onAccountCreateSuccess?: () => void )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const body = {
            user: {
                email: emailAddress,
                password,
                email_opt_out: !sendEmailFlag,
                create_business: createBusinessParameter,
            },
            channel_tracking_parameters: channelTrackingParameters,
            third_party_parameters: thirdPartyParameters,
            pro_preview: PRO_PREVIEW_ENABLED,
        };

        if ( !validateEmail( emailAddress ) )
        {
            const emailError = { email: "Please enter a valid email address" };
            dispatch( uiActions.setEmailFormErrors( emailError ) );
            return;
        }

        return requests.post<AccountCreationAPI, MeAPIData>( EMAIL_CREATE_ACCOUNT_URL, body )
            .then(
                ( data ) =>
                {
                    eventTracker.trackAccountCreated();
                    if ( onAccountCreateSuccess )
                    {
                        handleSuccessfulLoginWithoutRedirect( data, dispatch, ACCOUNT_CREATED, LOGIN_TYPE_VIA_EMAIL );
                        onAccountCreateSuccess();
                    }
                    else
                    {
                        handleSuccessfulLogin( data, dispatch, ACCOUNT_CREATED, LOGIN_TYPE_VIA_EMAIL );
                    }
                },
                ( error: DeviseErrorResponse ) =>
                {
                    const deviseError = parseDeviseErrors( error );
                    dispatch( uiActions.setEmailFormErrors( deviseError ) );
                },
            );
    };
}

function parseDeviseErrors( error: DeviseErrorResponse )
{
    if ( typeof error === "string" )
    {
        return { error };
    }
    return mapValues( error, ( messageArray, field ) =>
    {
        if ( isEmpty( messageArray ) )
        {
            return "";
        }
        return upperFirst( field.replace( /_/g, " " ) ) + " " + head( messageArray );
    } );
}

function forgotPassword( emailAddress: string )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const body = {
            user: {
                email: emailAddress,
            },
        };

        const content = PASSWORD_RESET_SENT_TO_EMAIL.replace( "{email}", emailAddress );

        return requests.post<ForgotPasswordAPI, MeAPIData>( FORGOT_PASSWORD_URL, body )
            .then(
                ( data ) => dispatch( modalServices.openLightbox( {
                    identifierForKey: LightboxDialogIdentifierForKey.PASSWORD_RESET_EMAIL_SENT,
                    content,
                    title: PASSWORD_RESET_SENT_TO_EMAIL_TITLE,
                    hideCancel: true,
                    width: 476,
                    showCancelX: true,
                } ) ),
                ( error: DeviseErrorResponse ) => dispatch( uiActions.setEmailFormErrors( parseDeviseErrors( error ) ) ),
            );
    };
}

function resetPassword( password: string, passwordConfirmation: string, resetPasswordToken: string )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( uiActions.clearResetFormErrors() );
        const body: ResetPasswordAPI = {
            user: {
                password,
                password_confirmation: passwordConfirmation,
                reset_password_token: resetPasswordToken,
            },
        };

        return requests.put<ResetPasswordAPI, {}>( RESET_PASSWORD_URL, body )
            .then(
                ( data ) => dispatch( modalServices.openLightbox( {
                    identifierForKey: LightboxDialogIdentifierForKey.PASSWORD_RESET,
                    content: "Your password has been reset!",
                    onClose: () => history.replace( LOGIN_URL ),
                    hideCancel: true,
                    showCancelX: true,
                } ) ),
                ( error: DeviseErrorResponse ) => dispatch( uiActions.setResetFormErrors( parseDeviseErrors( error ) ) ),
            );
    };
}

function clientFacebookCreateAccount( aFacebookId: string,
                                      aFacebookAccessToken: string,
                                      aSendEmailFlag: boolean,
                                      channelTrackingParameters?: ChannelTrackingParameters,
                                      createBusiness?: boolean,
                                      onAccountCreateSuccess?: () => void )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {

        const params: FacebookCreateAccountAPI = {
            facebook_user_id: aFacebookId,
            facebook_access_token: aFacebookAccessToken,
            email_opt_out: !aSendEmailFlag,
            pro_preview: PRO_PREVIEW_ENABLED,
            create_business: createBusiness,
        };
        const body: ChannelTrackingAPI = {
            channel_tracking_parameters: channelTrackingParameters,
        };
        return requests.post<ChannelTrackingAPI, MeAPIData>( FACEBOOK_CREATE_ACCOUNT_URL, body, params )
            .then(
                ( data ) =>
                {
                    eventTracker.trackAccountCreated();
                    if ( onAccountCreateSuccess )
                    {
                        handleSuccessfulLoginWithoutRedirect( data, dispatch, ACCOUNT_CREATED, LOGIN_TYPE_VIA_FACEBOOK );
                        onAccountCreateSuccess();
                    }
                    else
                    {
                        handleSuccessfulLogin( data, dispatch, ACCOUNT_CREATED, LOGIN_TYPE_VIA_FACEBOOK );
                    }
                },
                ( error: string ) =>
                {
                    dispatch( userActions.loginFailure( error ) );
                    dispatch( modalServices.openErrorDialog( ERROR_TITLE_SORRY, THIRD_PARTY_LOGIN_ERROR_MESSAGES.FACEBOOK_CREATE ) );
                },
            );
    };
}

function clientFacebookLogin( aFacebookId: string,
                              aFacebookAccessToken: string,
                              channelTrackingParameters?: ChannelTrackingParameters,
                              createBusiness?: boolean,
)
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const params: FacebookLoginAPI = {
            facebook_user_id: aFacebookId,
            facebook_access_token: aFacebookAccessToken,
            create_business: createBusiness,
        };
        const body: ChannelTrackingAPI = {
            channel_tracking_parameters: channelTrackingParameters,
        };
        return requests.post<any, MeAPIData>( FACEBOOK_LOGIN_URL, body, params )
            .then(
                ( data ) =>
                {
                    handleSuccessfulLogin( data, dispatch, LOGGED_IN, LOGIN_TYPE_VIA_FACEBOOK );
                },
                ( error: string ) =>
                {
                    dispatch( userActions.loginFailure( error ) );
                    dispatch( modalServices.openErrorDialog( ERROR_TITLE_SORRY, THIRD_PARTY_LOGIN_ERROR_MESSAGES.FACEBOOK_LOGIN ) );
                },
            );
    };
}

function clientGoogleCreateAccount( googleIdToken: string,
                                    sendEmailFlag: boolean,
                                    channelTrackingParameters?: ChannelTrackingParameters,
                                    createBusiness?: boolean,
                                    onAccountCreateSuccess?: () => void )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {

        const params: GoogleCreateAccountAPI = {
            google_id_token: googleIdToken,
            email_opt_out: !sendEmailFlag,
            pro_preview: PRO_PREVIEW_ENABLED,
            create_business: createBusiness,
        };
        const body: ChannelTrackingAPI = {
            channel_tracking_parameters: channelTrackingParameters,
        };
        return requests.post<ChannelTrackingAPI, MeAPIData>( GOOGLE_LOGIN_URL, body, params )
            .then(
                ( data ) =>
                {
                    eventTracker.trackAccountCreated();
                    if ( onAccountCreateSuccess )
                    {
                        handleSuccessfulLoginWithoutRedirect( data, dispatch, ACCOUNT_CREATED, LOGIN_TYPE_GOOGLE );
                        onAccountCreateSuccess();
                    }
                    else
                    {
                        handleSuccessfulLogin( data, dispatch, ACCOUNT_CREATED, LOGIN_TYPE_GOOGLE );
                    }
                },
                ( error: string ) =>
                {
                    const errorMessage = `${THIRD_PARTY_LOGIN_ERROR_MESSAGES.GOOGLE_CREATE}\n${EMAIL_CUSTOMER_SUPPORT_MESSAGE}`;
                    dispatch( uiActions.setThirdPartyLoginError( { message: errorMessage, shouldShowError: true } ) );
                    dispatch( userActions.loginFailure( error ) );
                },
            );
    };
}

function clientGoogleLogin( googleIdToken: string, channelTrackingParameters?: ChannelTrackingParameters, createBusiness?: boolean )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const params: GoogleLoginAPI = {
            google_id_token: googleIdToken,
            create_business: createBusiness,
        };
        const body: ChannelTrackingAPI = {
            channel_tracking_parameters: channelTrackingParameters,
        };
        return requests.post<ChannelTrackingAPI, MeAPIData>( GOOGLE_LOGIN_URL, body, params )
            .then(
                ( data ) =>
                {
                    handleSuccessfulLogin( data, dispatch, LOGGED_IN, LOGIN_TYPE_GOOGLE );
                },
                ( error: string ) =>
                {
                    const errorMessage = `${THIRD_PARTY_LOGIN_ERROR_MESSAGES.GOOGLE_LOGIN}\n${EMAIL_CUSTOMER_SUPPORT_MESSAGE}`;
                    dispatch( uiActions.setThirdPartyLoginError( { message: errorMessage, shouldShowError: true } ) );
                    dispatch( userActions.loginFailure( error ) );
                },
            );
    };
}

function clientGoogleConnect( userSlug: string, googleIdToken: string )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const params = {
            google_id_token: googleIdToken,
        };
        return requests.post<any, MeAPIData>( buildUserUrlWithSlug( userSlug, GOOGLE_CONNECT_SUFFIX ), {}, params )
            .then(
                ( data ) =>
                {
                    const normalizedData = normalizeMeAPI( data );
                    dispatch( userActions.meSuccess( normalizedData ) );
                    eventTracker.setUserPropertiesForCurrentUser();
                    eventTracker.logEvent( GOOGLE_CONNECT_SUCCEEDED );
                    return normalizedData;
                },
                ( error: string ) =>
                {
                    handleGoogleConnectionError( dispatch, error, GOOGLE_CONNECT_ERROR_MESSAGE );
                    eventTracker.logEvent( GOOGLE_CONNECT_FAILED );
                },
            );
    };
}

function clientGoogleDisconnect( userSlug: string )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        return requests.post<any, MeAPIData>( buildUserUrlWithSlug( userSlug, GOOGLE_DISCONNECT_SUFFIX ), {} )
            .then(
                ( data ) =>
                {
                    const normalizedData = normalizeMeAPI( data );
                    dispatch( userActions.meSuccess( normalizedData ) );
                    eventTracker.setUserPropertiesForCurrentUser();
                    return normalizedData;
                },
                ( error: string ) =>
                {
                    handleGoogleConnectionError( dispatch, error, GOOGLE_DISCONNECT_ERROR_MESSAGE );
                },
            );
    };
}

function clientEpidemicSoundDisconnect( userSlug: string )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        return requests.post<any, MeAPIData>( buildUserUrlWithSlug( userSlug, EPIDEMIC_SOUND_DISCONNECT_SUFFIX ), {} )
            .then(
                ( data ) =>
                {
                    const normalizedData = normalizeMeAPI( data );
                    dispatch( userActions.meSuccess( normalizedData ) );
                    eventTracker.setUserPropertiesForCurrentUser();
                    return normalizedData;
                },
                ( error: string ) =>
                {
                    dispatch( modalServices.openErrorDialog( ERROR_TITLE_SORRY, EPIDEMIC_SOUND_DISCONNECT_ERROR_MESSAGE, true ) );
                },
            );
    };
}

function shouldShowProOnlyUpsellDialog()
{
    const skipUpsell = shouldSkipProOnlyUpsell();
    return !skipUpsell;
}

function shouldSkipProOnlyUpsell()
{
    const isSubscribedAsPayingUser = isUserPersonallySubscribed( store.getState() );
    const testDriveEnabledAndAccepted = hasAcceptedTestDriveTerms( store.getState() );
    const redirectUrl = getRedirectUrl( store.getState() );
    const isOnOrIsGoingToPurchasePage = history.location.pathname === PURCHASE_PAGE_URL || includes( redirectUrl,
        PURCHASE_PAGE_URL );
    const isOnOrIsGoingToPartnerUserLoginPage = history.location.pathname === PARTNER_USER_LOGIN_URL || includes( redirectUrl,
        PARTNER_USER_LOGIN_URL );
    const isParsingCollectionDeeplink = history.location.pathname === COLLECTION_PAGE_URL || includes( redirectUrl,
        COLLECTION_ACTION_PARAM );
    const isParsingOpenDesignDeeplink = history.location.pathname === CUSTOMIZE_PAGE_URL || includes( redirectUrl,
        OPEN_DESIGN_PARAM );
    const isUserInTeamBusiness = isUserMemberOfAnyTeamBusiness( store.getState() );
    const hasTeamInviteParam = history.location.search.includes( ACCEPT_TEAM_INVITE_PARAM );
    return isSubscribedAsPayingUser
           || testDriveEnabledAndAccepted
           || isOnOrIsGoingToPurchasePage
           || isOnOrIsGoingToPartnerUserLoginPage
           || isUserInTeamBusiness
           || hasTeamInviteParam
           || isParsingCollectionDeeplink
           || isParsingOpenDesignDeeplink;
}

function shouldShowSubscriptionOnHoldLockoutMessage()
{
    return isSubscriptionOnHold( store.getState() );
}

function redirectUserAfterLoginOrCreateAccount()
{
    // TODO initialize() does a loadMe() call, which seems like it might duplicate
    // the data returned by this call. Should we change initialize() to not call loadMe()?
    store.dispatch( startupServices.initialize() );
    const redirectUrl = getRedirectUrl( store.getState(), DEFAULT_LOGIN_REDIRECT_URL );
    history.push( redirectUrl );
    store.dispatch( uiActions.setRedirectUrl( undefined ) );
}

async function handleSuccessfulLogin( data: MeAPIData, dispatch: Dispatch<StoreState>, eventName: string, oldEventLoginType: LoginType )
{
    handleSuccessfulLoginWithoutRedirect( data, dispatch, eventName, oldEventLoginType );
    await createBusinessAndFinish( eventName, dispatch );
}

function handleSuccessfulLoginWithoutRedirect( data: MeAPIData, dispatch: Dispatch<StoreState>, eventName: string, oldEventLoginType: LoginType )
{
    eventTracker.clearApptimizeVariableCache();

    const normalizedData = normalizeMeAPI( data );
    dispatch( userActions.meSuccess( normalizedData ) );
    eventTracker.setUserIdForCurrentUser();
    eventTracker.setUserPropertiesForCurrentUser();
    eventTracker.logEvent( eventName, { loginType: oldEventLoginType, type: getEventLoginTypeFromOldLoginType( oldEventLoginType ) } );
}

async function createBusinessAndFinish( eventName: string, dispatch: Dispatch<StoreState> )
{
    if ( eventName === ACCOUNT_CREATED )
    {
        await createBusinessFromEditBusinessDataOrDefaults( dispatch );
    }
    redirectUserAfterLoginOrCreateAccount();
}

function getEventLoginTypeFromOldLoginType( oldEventLoginType: LoginType )
{
    if ( oldEventLoginType === LOGIN_TYPE_VIA_EMAIL )
    {
        return LOGIN_TYPE_EMAIL;
    }
    else if ( oldEventLoginType === LOGIN_TYPE_VIA_FACEBOOK )
    {
        return LOGIN_TYPE_FACEBOOK;
    }
    else
    {
        return oldEventLoginType;
    }
}

function getRiplConnectedFacebookGroups( socialNetworkAccountIds: number[] )
{
    socialNetworkAccountIds = filter( socialNetworkAccountIds );
    if ( socialNetworkAccountIds.length === 0 )
    {
        return {};
    }

    const state = store.getState();
    const userId = getCurrentUserId( state );
    const params: GetConnectedGroupDataRequest = {
        social_network_account_ids_list: socialNetworkAccountIds,
    };

    const url = buildUserUrl( userId, GET_CONNECTED_GROUPS_URL );
    return requests.get<GetConnectedGroupDataRequest>( url, params )
        .then( ( data ) => data, () => ({}) );
}

function clientFacebookConnect( userId: number, facebookId: number, facebookAccessToken: string )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const params = {
            facebook_user_id: facebookId,
            facebook_access_token: facebookAccessToken,
        };

        return requests.post<any, MeAPIData>( buildUserUrl( userId, FACEBOOK_CONNECT_SUFFIX ), {}, params )
            .then(
                ( data ) =>
                {
                    const normalizedData = normalizeMeAPI( data );
                    dispatch( userActions.meSuccess( normalizedData ) );
                    eventTracker.setUserPropertiesForCurrentUser();
                    return true;
                },
                ( error: string ) =>
                {
                    dispatch( userActions.connectFailure( error ) );
                    return false;
                },
            );
    };
}

function clientSocialAccountDisconnect( userId: number, accountType: SocialNetworkAccountType, accountId: number, profileId: string,
                                        businessId: number )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const params = {
            social_account_id: profileId,
            user_business_id: businessId,
        };

        return requests.post<any, MeAPIData>( buildSocialAccountDisconnectUrl( userId, accountType ), {}, params )
            .then(
                ( data ) =>
                {
                    const normalizedData = normalizeMeAPI( data );
                    dispatch( userActions.meSuccess( normalizedData ) );
                    dispatch( shareModelActions.socialNetworkAccountDisconnected( { accountType, accountId } ) );

                    eventTracker.setUserPropertiesForCurrentUser();
                    return normalizedData;
                },
                ( error: string ) =>
                {
                    dispatch( userActions.disconnectFailure( error ) );
                },
            );
    };
}

function enableSocialNetworkAccounts( aUserId: number, aSocialNetworkAccountIds: number[], aBusinessId?: number )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const params = {
            social_account_id_list: aSocialNetworkAccountIds,
            user_business_id: aBusinessId,
        };

        return requests.post<any, MeAPIData>( buildUserUrl( aUserId, ADD_BUSINESS_ACCOUNT_SUFFIX ), {}, params )
            .then(
                ( data ) =>
                {
                    const normalizedData = normalizeMeAPI( data );
                    dispatch( userActions.meSuccess( normalizedData ) );
                    eventTracker.setUserPropertiesForCurrentUser();

                    // TODO Consider changing socialNetworkAccountEnableChanged to accept an array of IDs
                    // instead of dispatching multiple actions.
                    forEach( aSocialNetworkAccountIds, ( socialNetworkAccountId ) =>
                    {
                        const payload: ShareOutputEnabledPayload = {
                            accountId: socialNetworkAccountId,
                            accountEnabled: true,
                        };
                        dispatch( shareModelActions.shareOutputEnableChanged( payload ) );
                    } );

                    return normalizedData;
                },
                ( error: string ) =>
                {
                    dispatch( userActions.connectFailure( error ) );
                },
            );
    };
}

function loadWeeklyEngagements( userId: number, userBusinessId: number )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        if ( userId )
        {
            return requests.get<WeeklyEngagementData>( buildUserUrl( userId, RECENT_ENGAGEMENTS_INFO_SUFFIX ), {
                business_id: userBusinessId,
            } ).then(
                ( data ) => dispatch( engagementsActions.weeklyBusinessSuccess( data ) ),
                ( error: string ) => dispatch( engagementsActions.weeklyBusinessFailure( error ) ),
            );
        }
        else
        {
            return dispatch( engagementsActions.weeklyBusinessFailure( MISSING_USER_ID_IN_REQUEST ) );
        }
    };
}

async function checkIfFacebookIsAccessible( allSocialNetworkAccounts: SocialNetworkAccount[], dispatch: Dispatch<StoreState> )
{
    const accountType = FACEBOOK_ACCOUNT_TYPE;
    const socialNetworks = filter( allSocialNetworkAccounts, { account_type: accountType } );
    if ( socialNetworks.length > 0 )
    {
        const accessToken = socialNetworks[0].token;
        const isAccessible = await facebookServices.verifyUserHasLoginPermissions( accessToken );
        if ( !isAccessible )
        {
            dispatch( userActions.socialNetworkAccountInaccessible( accountType ) );
        }
    }
}

async function checkIfFacebookPagesAreAccessible( allSocialNetworkAccounts: SocialNetworkAccount[], dispatch: Dispatch<StoreState> )
{
    const accountType = FACEBOOK_PAGE_ACCOUNT_TYPE;
    const socialNetworks = filter( allSocialNetworkAccounts, { account_type: accountType } );
    if ( socialNetworks.length > 0 )
    {
        const accessToken = socialNetworks[0].token;
        const isAccessible = await facebookServices.verifyUserHasPagePermissions( accessToken );
        if ( !isAccessible )
        {
            dispatch( userActions.socialNetworkAccountInaccessible( accountType ) );
        }
    }
    // TODO Check if individual pages are inaccessible
}

async function checkIfFacebookGroupsAreAccessible( allSocialNetworkAccounts: SocialNetworkAccount[], dispatch: Dispatch<StoreState> )
{
    const accountType = FACEBOOK_GROUP_ACCOUNT_TYPE;
    const socialNetworks = filter( allSocialNetworkAccounts, { account_type: accountType } );
    if ( socialNetworks.length > 0 )
    {
        const accessToken = socialNetworks[0].token;
        const isAccessible = await facebookServices.verifyUserHasGroupPermissions( accessToken );
        if ( !isAccessible )
        {
            dispatch( userActions.socialNetworkAccountInaccessible( accountType ) );
        }
    }
    // TODO Check if individual groups are inaccessible
}

function checkAllSocialNetworkAccountsAreAccessible( dispatch: Dispatch<StoreState> )
{
    const allSocialNetworkAccounts = getUserSocialNetworkAccounts( store.getState() );
    // TODO make this more elegant, possibly with polymorphism
    checkIfFacebookIsAccessible( allSocialNetworkAccounts, dispatch );
    checkIfFacebookPagesAreAccessible( allSocialNetworkAccounts, dispatch );
    checkIfFacebookGroupsAreAccessible( allSocialNetworkAccounts, dispatch );
}

function update( newData: Partial<UserState> )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const currentUserId = getCurrentUserId( store.getState() );
        const theParams = { user: newData };
        return requests.patch<{ user: Partial<UserState> }, MeAPIData>( USER_URL_PREFIX + "/" + currentUserId, theParams ).then(
            ( data ) =>
            {
                const normalizedData = normalizeMeAPI( data );
                dispatch( userActions.updateSuccess( normalizedData ) );
                eventTracker.setUserPropertiesForCurrentUser();
            },
            ( error: string ) =>
            {
                handleUserUpdateError( dispatch, error );
            },
        );
    };
}

function handleErrorMessages( errorMessages, dispatch: Dispatch<StoreState> )
{
    if ( errorMessages[0] === COULD_NOT_UPDATE_EMAIL_ERROR_MESSAGE )
    {
        dispatch( modalServices.openErrorDialog(
            ERROR_TITLE_SORRY, "There was a problem updating your email address.\n"
                               + "Please try a different email address or reach out to feedback@ripl.com for more help." ) );
    }
    else
    {
        dispatch( modalServices.openErrorDialogWithStandardFormat(
            errorMessages[0], "" ) );
    }
}

function handleUserUpdateError( dispatch: Dispatch<StoreState>, error: string )
{
    dispatch( userActions.updateFailure( error ) );

    const parsedErrors = parseDeviseErrors( error );
    const errorMessages = values( parsedErrors );
    if ( errorMessages.length > 0 )
    {
        handleErrorMessages( errorMessages, dispatch );
    }
}

function handleGoogleConnectionError( dispatch: Dispatch<StoreState>, serverError: string, clientErrorMessage: string )
{
    dispatch( userActions.updateFailure( serverError ) );

    const parsedErrors = parseDeviseErrors( serverError );
    const errorMessages = values( parsedErrors );
    if ( errorMessages.length > 0 )
    {
        handleErrorMessages( [clientErrorMessage], dispatch );
    }
}

function updatePhoto( file: File, source: string )
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        await modalServices.encloseInLoadingDialog( async () =>
        {
            if ( !file || !file.type.startsWith( "image" ) )
            {
                const fileName = file && file.name;
                const primaryText = fileName ? "Unable to upload:\n\n" + fileName : "Unable to upload the selected file.";
                dispatch( modalServices.openErrorDialogWithStandardFormat(
                    primaryText,
                    "\nYou can only upload images (.png, .jpeg, etc.)." ) );
                return;
            }

            const currentUserId = getCurrentUserId( store.getState() );
            const currentBusinessId = getCurrentBusinessId( store.getState() );
            const resizedImage = await new ImageResizer( file, { allowPng: true } ).resize();
            const photoData = { user: { photo_attributes: { image: resizedImage } } };
            dispatch( uiActions.trackProcessAsRunning( ProcessTrackingKey.UPDATE_LOGO ) );

            return requests.patchFile<UserBrandLogoAPIData, MeAPIData>(
                USER_URL_PREFIX + "/" + currentUserId + ".json", photoData,
                { business_id: currentBusinessId } ).then
            (
                ( data ) =>
                {
                    if ( source === CUSTOMIZE_PAGE )
                    {
                        eventTracker.logCustomizeLogoUploadSucceeded( source );
                    }
                    const normalizedData = normalizeMeAPI( data );
                    dispatch( userActions.updateSuccess( normalizedData ) );
                    dispatch( mixModelActions.logoOverrideClear() );
                    dispatch( userBusinessServices.loadBrandLogos() );
                    eventTracker.setUserPropertiesForCurrentUser();
                    dispatch( uiActions.trackProcessAsStopped( ProcessTrackingKey.UPDATE_LOGO ) );
                },
                ( error: string ) =>
                {
                    dispatch( userActions.updateFailure( error ) );
                    dispatch( uiActions.trackProcessAsStopped( ProcessTrackingKey.UPDATE_LOGO ) );
                },
            );
        } );
    };
}

// This combines simultaneous requests into a single network call.
let loadMePromise;

function loadMe()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        if ( loadMePromise )
        {
            return loadMePromise;
        }

        dispatch( uiActions.trackProcessAsRunning( ProcessTrackingKey.FETCHING_ME_DATA ) );
        const onFinishedFetchingMeData = () =>
        {
            loadMePromise = undefined;
            dispatch( uiActions.trackProcessAsStopped( ProcessTrackingKey.FETCHING_ME_DATA ) );
        };

        loadMePromise = requests.get<MeAPIData>( ME_URL, { pro_preview: PRO_PREVIEW_ENABLED }, true, true )
            .then(
                ( data ) =>
                {
                    const normalizedData = normalizeMeAPI( data );
                    dispatch( userActions.meSuccess( normalizedData ) );
                    // TODO Should this be waited for? Or can it be fire-and-forget?
                    checkAllSocialNetworkAccountsAreAccessible( dispatch );
                    eventTracker.setUserIdForCurrentUser();
                    eventTracker.setUserPropertiesForCurrentUser();
                    onFinishedFetchingMeData();
                },
                ( error: ExtendedErrorInfo ) =>
                {
                    dispatch( userActions.meFailure( error.reason ) );
                    onFinishedFetchingMeData();
                    if ( error && error.status === 401 )
                    {
                        dispatch( logoutServices.logout() );
                    }
                    return Promise.reject( "Failed to load user profile." );
                },
            );
        return loadMePromise;
    };
}

function buildUserUrl( userId: number, urlPart: string ): string
{
    return `${USER_URL_PREFIX}/${userId}/${urlPart}`;
}

function buildUserUrlWithSlug( userSlug: string, urlPart: string ): string
{
    return `${USER_URL_PREFIX}/${userSlug}/${urlPart}`;
}

function buildSocialAccountDisconnectUrl( userId: number, accountType: SocialNetworkAccountType )
{
    const accountTypeUrlPart = convertAccountTypeToUrlPart( accountType );
    const suffix = `client_${accountTypeUrlPart}_disconnect`;
    return buildUserUrl( userId, suffix );
}

function convertAccountTypeToUrlPart( accountType: SocialNetworkAccountType )
{
    switch ( accountType )
    {
        case FACEBOOK_INSTAGRAM_ACCOUNT_TYPE:
            return CLIENT_CONNECT_URL_PART_FACEBOOK_INSTAGRAM;
        case  FACEBOOK_PAGE_ACCOUNT_TYPE:
            return CLIENT_CONNECT_URL_PART_FACEBOOK_PAGE;
        case FACEBOOK_GROUP_ACCOUNT_TYPE:
            return CLIENT_CONNECT_URL_PART_FACEBOOK_GROUP;
        default:
            return toLower( accountType );
    }
}

function createBusinessFromEditBusinessDataOrDefaults( dispatch )
{
    const businessInfo = getEditedBusinessInfo( store.getState() );
    const userFirstName = store.getState().user.first_name;
    if ( !get( businessInfo, "name" ) && userFirstName )
    {
        const businessName = `${userFirstName}'s Business`;
        dispatch( uiActions.updateBusinessInfo( { name: businessName } ) );
    }
    const newBusinessData = getNewBusinessAPIData( store.getState() );
    return dispatch( userBusinessServices.create( newBusinessData ) );
}

function closeOrReturnToHome()
{
    const pathname = window.location.pathname;

    if ( includes( pathname, HOMEPAGE_URL ) )
    {
        history.push( HOMEPAGE_URL );
    }
    else
    {
        window.close();
    }
}

function submitTicket( emailAddress, message )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const userId = store.getState().user.id;
        const htmlMessage = message.replace( "\n", "<br>" );
        const params = {
            user_submitted_email: emailAddress,
            user_submitted_message: htmlMessage,
            feedback_text: getFeedbackText(),
            platform: "Desktop/Web App",
        };

        return requests.post<any, MeAPIData>( buildUserUrl( userId, SUBMIT_TICKET_SUFFIX ), {}, params )
            .then(
                ( data ) =>
                {
                    closeOrReturnToHome();
                },
                ( error: string ) =>
                {
                    dispatch(
                        modalServices.openErrorDialog( ERROR_TITLE_SORRY,
                            "There was an issue submitting your ticket: " + error + "\n\nPlease try again." ) );
                },
            );
    };
}

function getFeedbackText()
{
    return "client_id:app.ripl.com<br>" +
           "version:" + APP_VERSION + "<br>" +
           "user:" + store.getState().user.id + "<br>" +
           "subscription_status:" + store.getState().user.subscription_status + "<br>" +
           "browserName:" + browser.name + "<br>" +
           "browserPlatform:" + navigator.platform + "<br>" +
           "dataCookiesEnabled:" + navigator.cookieEnabled + "<br>" +
           "sizeScreenW:" + window.screen.width + "<br>" +
           "sizeScreenH:" + window.screen.height;
}

function requestDeleteAccount()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const userSlug = getUserSlug( store.getState() );

        return requests.post<any, MeAPIData>( buildUserUrlWithSlug( userSlug, REQUEST_DELETE_ACCOUNT ), {}, {} )
            .then(
                ( data ) =>
                {
                    dispatch(
                        modalServices.openDeleteAccountRequestSucceededDialog() );
                },
                ( error: string ) =>
                {
                    dispatch(
                        modalServices.openErrorDialog( "We're sorry",
                            DELETE_ACCOUNT_ERROR_MESSAGE ) );
                },
            );
    };
}

async function easyCaption( captionIdeas: string )
{
    const params = {
        caption_ideas: captionIdeas,
        business_id:  store.getState().userBusinesses.currentUserBusinessId,
    };

    return await requests.get<any>( EASY_CAPTION_URL, params ).then((data) => {
        return data.answer;
    });
}
