import { connect } from "react-redux";
import { StoreState, TextPickerCaptionData } from "../../../../_types";
import { PresetTextPicker, PresetTextPickerDispatchProps, PresetTextPickerProps } from "../PresetTextPicker";
import { getGlobalExtraCaptions, getPerSlideExtraCaptions } from "../../../../ducks/ui";
import { Dispatch } from "redux";
import { uiActions } from "../../../../actions";

const mapStateToProps = ( storeState: StoreState ): PresetTextPickerProps =>
{
    return {
        globalCaptions: getGlobalExtraCaptions( storeState ),
        perSlideCaptions: getPerSlideExtraCaptions( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): PresetTextPickerDispatchProps =>
{
    return {
        changeTextPropertiesSubPanelCaptionData: ( captionData: TextPickerCaptionData ) =>
        {
            dispatch( uiActions.setTextPropertiesSubPanelCaptionData( captionData ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( PresetTextPicker );
