import * as React from "react";
import { Post, SocialCalendarDaySlotData, SocialCalendarPostData } from "../_types";
import classNames from "classnames";
import { ASPECT_RATIO_NAMES, ASPECT_RATIO_VALUE, getTodayYYYYMMDD } from "../helpers";
import * as CollectionIcon from "../assets/img/stackOfPostsIcon.svg";
import VideoElementContainer from "./containers/VideoElement.container";

export interface SocialCalendarCellProps
{
  post: Post;
  postData: SocialCalendarPostData;
  slotData: SocialCalendarDaySlotData;
  date: string;
  isSelected: boolean;
}

export interface SocialCalendarCellDispatchProps
{
  openDesign: ( id: number, name: string, date: string ) => void;
  openCollection: ( slug: string ) => void;
}

export class SocialCalendarCell extends React.PureComponent<SocialCalendarCellProps & SocialCalendarCellDispatchProps>
{
  public render()
  {
    const postData = this.props.postData;
    const slotData = this.props.slotData;
    const post = this.props.post;
    const aspectRatioValue = postData && ASPECT_RATIO_VALUE[postData.aspect_ratio];
    const aspectRatioClass = postData && ASPECT_RATIO_NAMES[postData.aspect_ratio];
    const videoUrl = post && post.video_url;
    const imageUrl = post && post.image_url;
    const posterUrl = post && post.poster_url;

    return (
      <div className={classNames( "socialCalendarCell",
        aspectRatioClass,
        this.todayClassName(),
        {
          major: slotData.holiday_type === "major",
          selected: this.props.isSelected,
        } )}
           onClick={this.handleClick}>
        <div className="socialCalendarCellDetails">
          <div className="socialCalendarCellName">{slotData.holiday_name}</div>
          <div className="socialCalendarCellDay">
            <div>{slotData.holiday_day}</div>
          </div>
        </div>
        <VideoElementContainer
          optionClass={classNames( "socialCalendarCellVideo", aspectRatioClass )}
          videoUrl={videoUrl}
          imageUrl={imageUrl}
          posterUrl={posterUrl}
          muted={true}
          playOnMouseOver={true}
          aspectRatio={aspectRatioValue}/>
        {this.addCollectionOverlay()}
      </div>
    );
  }

  private addCollectionOverlay = () =>
  {
    if ( this.props.slotData.collection_slug !== undefined )
    {
      return (
        <div className="socialCalendarCellCollectionOverlay">
          <img src={CollectionIcon} alt="Collection Icon"/>
        </div>
      );
    }
  }

  private handleClick = () =>
  {
    if ( this.props.slotData.collection_slug !== undefined )
    {
      this.props.openCollection( this.props.slotData.collection_slug );
    }
    else if ( this.props.slotData.id !== undefined )
    {
      this.props.openDesign( this.props.slotData.id, this.props.slotData.holiday_name, this.props.date);
    }
  }
  private todayClassName = () =>
  {
    if ( this.props.date === getTodayYYYYMMDD() )
    {
      return "today";
    }
    return "";
  }
}
