import * as React from "react";
import { UserBusiness } from "../../_types";
import BrandColorPickerCommonContainer from "./containers/BrandColorPickerCommon.container";

export interface BrandSettingsColorPickerDispatchProps
{
    handleClearColor: ( userBusinessKey: keyof UserBusiness ) => void;
}

export class BrandSettingsColorPicker extends React.PureComponent<BrandSettingsColorPickerDispatchProps>
{
    public render()
    {
        return (<BrandColorPickerCommonContainer handleClearColor={this.props.handleClearColor}
                                                 containerClassName="brandSettingsColorPickerContainer"/>);
    }
}
