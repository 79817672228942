import { connect } from "react-redux";
import { SocialCalendarDay, SocialCalendarDayDispatchProps, SocialCalendarDayProps } from "../../SocialCalendarDay";
import { Dispatch } from "redux";
import { StoreState } from "../../../_types";
import { uiActions } from "../../../actions";
import { convertDateToYYYYMMDD } from "../../../helpers";
import { getSocialCalendarSelectedDate } from "../../../ducks/socialCalendar";

export interface SocialCalendarDayContainerProps
{
  date: Date;
  dayOfWeek: string;
  day: string;
  isHoliday: boolean;
}

const mapStateToProps = (state: StoreState, ownProps: SocialCalendarDayContainerProps ): SocialCalendarDayProps =>
{
  return {
    ...ownProps,
    isSelected: getSocialCalendarSelectedDate( state ) === convertDateToYYYYMMDD( ownProps.date ),
  };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): SocialCalendarDayDispatchProps =>
{
  return {
    handleDayClicked: ( date ) =>
    {
      dispatch( uiActions.socialCalendarDayClicked( convertDateToYYYYMMDD( date ) ) );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( SocialCalendarDay );
