import * as React from "react";
import Tour, { ReactourStep } from "reactour";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import { ShowYouAroundIntroDialog } from "./ShowYouAroundIntroDialog";
import {
    eventTracker,
    MOBILE_WALL_BREAKPOINT_MAX_WIDTH,
    SHOW_YOU_AROUND_DISMISSAL_SOURCE_CREATE_YOUR_OWN,
    SHOW_YOU_AROUND_DISMISSAL_SOURCE_INTRO,
    SHOW_YOU_AROUND_DISMISSAL_SOURCE_SEARCH,
    SHOW_YOU_AROUND_DISMISSAL_SOURCE_TOP_COLLECTION,
    SHOW_YOU_AROUND_DISPLAYED_CREATE_YOUR_OWN,
    SHOW_YOU_AROUND_DISPLAYED_INTRO,
    SHOW_YOU_AROUND_DISPLAYED_SEARCH,
    SHOW_YOU_AROUND_DISPLAYED_TOP_COLLECTION,
    ShowYouAroundDismissalSource,
} from "../helpers";
import classNames from "classnames";
import { ShowYouAroundSequenceStep } from "./ShowYouAroundSequenceStep";

const TOP_COLLECTION_ROW_SELECTOR = "div.homePostRowsPanel > div:nth-child(1) > div.homePostRowContents";
const SEARCH_BAR_SELECTOR = ".contentSearchBar";
const CREATE_YOUR_OWN_BANNER_SELECTOR = ".createYourOwnBannerWrap";

export interface ShowYouAroundSequenceProps
{
    onClose: () => void;
    onAfterOpen: () => void;
}

interface ShowYouAroundSequenceState
{
    hasLoaded: boolean;
    isMobileDevice: boolean;
}

interface ReactourState
{
    current: number;
}

interface ReactourPortal
{
    state: ReactourState;
}

export default class ShowYouAroundSequence extends React.PureComponent<ShowYouAroundSequenceProps, ShowYouAroundSequenceState>
{
    constructor( props )
    {
        super( props );
        this.state = {
            hasLoaded: false,
            isMobileDevice: window.innerWidth < MOBILE_WALL_BREAKPOINT_MAX_WIDTH,
        };
    }

    public componentDidMount()
    {
        window.addEventListener( "resize", this.checkIfMobile );
    }

    public componentWillUnmount()
    {
        window.removeEventListener( "resize", this.checkIfMobile );
        clearAllBodyScrollLocks();
    }

    public render()
    {
        return (
            <Tour
                ref="tourRef"
                steps={this.steps()}
                isOpen={!this.state.isMobileDevice}
                accentColor="#FFF"
                showNumber={false}
                showCloseButton={false}
                rounded={5}
                showNavigationNumber={false}
                disableInteraction={true}
                showButtons={false}
                showNavigation={false}
                closeWithMask={true}
                className={classNames( "showYouAroundDialogSequence", { hasLoaded: this.state.hasLoaded } )}
                onRequestClose={this.handleRequestClose}
                onAfterOpen={this.handleOnAfterOpen}
            />
        );
    }

    private getAfterIntroStepContent = () =>
    {
        return ShowYouAroundSequenceStep;
    }

    private handleOnAfterOpen = ( target ) =>
    {
        this.props.onAfterOpen();
        this.setState( { hasLoaded: true } );
        disableBodyScroll( target );
        eventTracker.logShowYouAroundDisplayedStepEvent( SHOW_YOU_AROUND_DISPLAYED_INTRO );
    }

    private checkIfMobile = () =>
    {
        this.setState( { isMobileDevice: window.innerWidth < MOBILE_WALL_BREAKPOINT_MAX_WIDTH } );
    }

    private handleRequestClose = () =>
    {
        const dismissalSources = [
            SHOW_YOU_AROUND_DISMISSAL_SOURCE_INTRO,
            SHOW_YOU_AROUND_DISMISSAL_SOURCE_TOP_COLLECTION,
            SHOW_YOU_AROUND_DISMISSAL_SOURCE_SEARCH,
            SHOW_YOU_AROUND_DISMISSAL_SOURCE_CREATE_YOUR_OWN,
        ];

        const currentStep = this.getCurrentStep();
        if ( dismissalSources[currentStep] )
        {
            eventTracker.logShowYouAroundDismissedEvent( dismissalSources[currentStep] as ShowYouAroundDismissalSource );
        }
        this.props.onClose();
    }

    private getCurrentStep()
    {
        const tourRef = this.refs.tourRef as any;
        if ( tourRef && "portal" in tourRef )
        {
            const portal = tourRef.portal as ReactourPortal;
            if ( portal.state )
            {
                return portal.state.current;
            }
        }
    }

    private steps()
    {
        return [
            {
                position: "center",
                content: ShowYouAroundIntroDialog,
                style: {
                    width: 476,
                    maxWidth: "unset",
                    padding: "20px 24px",
                    transition: "unset",
                },
            },
            {
                selector: TOP_COLLECTION_ROW_SELECTOR,
                content: this.getAfterIntroStepContent(),
                position: "bottom",
                style: {
                    width: 420,
                    maxWidth: "unset",
                    padding: "14px 14px",
                    marginTop: 2,
                },
                action: () =>
                {
                    eventTracker.logShowYouAroundDisplayedStepEvent( SHOW_YOU_AROUND_DISPLAYED_TOP_COLLECTION );
                },
            },
            {
                selector: SEARCH_BAR_SELECTOR,
                content: this.getAfterIntroStepContent(),
                position: "bottom",
                style: {
                    width: 420,
                    maxWidth: "unset",
                    padding: "14px 14px",
                    marginTop: 2,
                },
                action: () =>
                {
                    eventTracker.logShowYouAroundDisplayedStepEvent( SHOW_YOU_AROUND_DISPLAYED_SEARCH );
                },
            },
            {
                selector: this.createYourOwnSelector(),
                content: this.getAfterIntroStepContent(),
                position: "bottom",
                style: {
                    width: 420,
                    maxWidth: "unset",
                    padding: "14px 14px",
                    marginTop: 2,
                },
                action: () =>
                {
                    eventTracker.logShowYouAroundDisplayedStepEvent( SHOW_YOU_AROUND_DISPLAYED_CREATE_YOUR_OWN );
                },
            },
        ] as ReactourStep[];
    }

    private createYourOwnSelector()
    {
        return CREATE_YOUR_OWN_BANNER_SELECTOR;
    }
}
