import { createAction } from "redux-actions";
import { ActionCreator } from "../helpers";

const actionCreator = new ActionCreator( "EPIDEMIC_SOUND_CATALOG" );

const epidemicSoundCatalogActionsTypes = {
    load: actionCreator.generateTypes( "LOAD" ),
    clear: actionCreator.generateType( "CATALOG", "CLEAR" ),
    clearSoundTrack: actionCreator.generateType( "CATALOG", "CLEAR_SOUND_TRACK" ),
    freeTrackIdsSet: actionCreator.generateType( "FREE_TRACK_IDS", "SET" ),
};

export const epidemicSoundCatalogActions = {
    loadTracksSuccess: createAction<NormalizrData>( epidemicSoundCatalogActionsTypes.load.success ),
    loadTracksFailure: createAction<string>( epidemicSoundCatalogActionsTypes.load.failure ),
    clearCatalog: createAction( epidemicSoundCatalogActionsTypes.clear ),
    clearSoundTrack: createAction<string>( epidemicSoundCatalogActionsTypes.clearSoundTrack ),
    freeTrackIdsSet: createAction<string[]>( epidemicSoundCatalogActionsTypes.freeTrackIdsSet ),
};
