import * as React from "react";
import { TextPickerField, TextPickerFieldDispatchProps, TextPickerFieldProps } from "./textPicker/TextPickerField";
import { eventTracker } from "../../helpers";
import { TextPickerCaptionData } from "../../_types";

export interface CustomizeTextInputFieldProps extends TextPickerFieldProps
{
    supportsDeletion?: boolean;
    onChangeStylizeTextClicked?( captionData: TextPickerCaptionData ): void;
}

export interface CustomizeTextInputFieldDispatchProps extends TextPickerFieldDispatchProps
{
    clearStylizedTextButtonCoachMark();
}

export class CustomizeTextInputField extends React.PureComponent<CustomizeTextInputFieldProps & CustomizeTextInputFieldDispatchProps>
{
    public render()
    {
        return (<TextPickerField {...this.props}
                                 onRemoveTextBoxClicked={this.props.supportsDeletion && this.props.onRemoveTextBoxClicked}
                                 onChangeStylizeTextClicked={this.onChangeStylizeTextClicked}/>);
    }

    private onChangeStylizeTextClicked = ( captionData: TextPickerCaptionData ) =>
    {
        if ( captionData.id )
        {
            eventTracker.logStylizeTextPanelOpenedEvent();
            this.props.onFocus( captionData.id );
            this.props.clearStylizedTextButtonCoachMark();
            this.props.onChangeStylizeTextClicked( captionData );
        }
    }
}
