import * as React from "react";
import Button from "react-md/lib/Buttons/Button";
import { GoogleIcon } from "./svgs/GoogleIcon";

export interface GoogleDisconnectButtonProps
{
    userSlug: string;
}

export interface GoogleDisconnectButtonDispatchProps
{
    onGoogleDisconnect: ( userSlug: string ) => void;
}

export default class GoogleDisconnectButton extends React.PureComponent<GoogleDisconnectButtonProps & GoogleDisconnectButtonDispatchProps>
{

    public render()
    {
        return (
            <Button
                flat
                className="googleDisconnectButton"
                iconEl={<GoogleIcon/>}
                onClick={this.handleOnClick}>Disconnect</Button>
        );
    }

    private handleOnClick = () =>
    {
        this.props.onGoogleDisconnect( this.props.userSlug );
    }
}
