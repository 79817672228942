import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../_types";
import { BackgroundMediaControl, BackgroundMediaControlDispatchProps } from "../BackgroundMediaControl";
import { backgroundMediaServices } from "../../services";

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): BackgroundMediaControlDispatchProps =>
{
    return {
        loadBackgroundMedia: () =>
        {
            dispatch( backgroundMediaServices.loadBackgroundMedia() );
        },
    };
};

export default connect(
    () => ({}),
    mapDispatchToProps,
)( BackgroundMediaControl );
