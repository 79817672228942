import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../_types";
import { StockMediaTermsDialog, StockMediaTermsDialogDispatchProps } from "../StockMediaTermsDialog";
import { modalActions, userActions } from "../../../actions";

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): StockMediaTermsDialogDispatchProps =>
{
    return {
        handleTermsAcceptedChanged: ( acceptedTerm: boolean ) =>
        {
            dispatch( userActions.stockMediaTermsUpdated( acceptedTerm ) );
            dispatch( modalActions.allForceUpdate() );
        },
    };
};

export default connect(
    () => ({}),
    mapDispatchToProps,
)( StockMediaTermsDialog );
