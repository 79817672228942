import { connect } from "react-redux";
import { Dispatch } from "redux";
import { getCurrentBusiness, hasTeamMemberPermissionsForCurrentBusiness } from "../../ducks/userBusiness";
import { default as SharePage, SharePageDispatchProps, SharePageProps } from "../SharePage";
import {
    customizationHelper,
    CUSTOMIZE_PAGE_URL,
    Downloader,
    ERROR_TITLE_OOPS,
    errorReporting,
    eventTracker,
    getInitialShareCaption,
    history,
    HOMEPAGE_URL,
    LightboxDialogIdentifierForKey,
    POSTS_PAGE_URL,
} from "../../helpers";
import { modalServices, NO_LONGER_DRAFT_ERROR_MESSAGE, postServices } from "../../services";
import { StoreState } from "../../_types";
import {
    getCaption,
    getEasyButtonClicks,
    getEnabledSocialNetworkIds,
    getFacebookEnabled,
    getImageUrl,
    getPreviousCaption,
    getSaveToComputerEnabled,
    getScheduledSendDatetime,
    getVideoUrl,
    isAnimationOutputTypeFromState,
    isAnyYoutubeAccountEnabled,
} from "../../ducks/shareModel";
import { catalogActions, contentSearchActions, shareModelActions, uiActions, userActions } from "../../actions";
import { SocialAccountSettingsDialog } from "../SocialAccountSettingsDialog";
import { store } from "../../store";
import { hasSharedFirstProPost } from "../../ducks/user";
import { getMediaSourceList, getSelectedDesignId } from "../../ducks/mixModel";
import { isNullOrUndefined } from "util";

const mapStateToProps = ( storeState: StoreState ): SharePageProps =>
{
    return {
        currentBusiness: getCurrentBusiness( storeState ),
        user: storeState.user,
        hideAccountSettings: hasTeamMemberPermissionsForCurrentBusiness( storeState ),
    };
};

function showCannotScheduleWithoutNetworkDialog( dispatch: Dispatch<StoreState> )
{
    dispatch( modalServices.openErrorDialogWithStandardFormat(
        "Where should Ripl share?",
        "Select one or more social networks, then click Schedule." ) );
}

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): SharePageDispatchProps =>
{
    return {
        onBackButtonClick: () =>
        {
            returnToCustomize( dispatch );
        },
        onBrowserBackButtonClick: () =>
        {
            returnToCustomize( dispatch );
        },
        onShareNowButtonClick: async () =>
        {
            const storeState = store.getState();
            const easyCaptionUsed = !isNullOrUndefined( getPreviousCaption( storeState ) );
            const easyCaptionNotChanged = easyCaptionUsed && getPreviousCaption( storeState ) === getCaption( storeState );
            const easyCaptionUsageCount = getEasyButtonClicks( storeState );
            eventTracker.logShareButtonTapped( easyCaptionUsed, easyCaptionNotChanged, easyCaptionUsageCount );
            if ( allowedToShareNow( storeState ) )
            {
                if ( isAnyYoutubeAccountEnabled( store.getState() ) )
                {
                    await dispatch( modalServices.openYouTubeDetailsDialog( async () =>
                    {
                        await shareNow( dispatch );
                    } ) );
                }
                else
                {
                    await modalServices.encloseInLoadingDialog( async () =>
                    {
                        await shareNow( dispatch );
                    } );
                }
            }
            else if ( isScheduledSendWithoutNetwork( storeState ) )
            {
                showCannotScheduleWithoutNetworkDialog( dispatch );
            }
            else if ( noShareOutputsAreEnabled( storeState ) )
            {
                dispatch( modalServices.openErrorDialogWithStandardFormat(
                    "Where should Ripl share?",
                    "Select one or more social networks, then click share." ) );
            }
            else
            {
                dispatch( modalServices.openErrorDialogWithStandardFormat(
                    "",
                    "Your video is being processed, please wait until it is finished before sharing." ) );
            }
        },
        onSharingComplete: () =>
        {
            emitCompletionEventsAndRedirect( dispatch );
        },
        onOpenSocialAccountSettingsDialog: () =>
        {
            dispatch( modalServices.openLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.SOCIAL_ACCOUNT_SETTINGS,
                content: SocialAccountSettingsDialog,
                title: "Social Media Accounts",
                confirmLabel: "Done",
                hideCancel: true,
                width: 500,
            } ) );
        },
        initializeViaText: () =>
        {
            const caption = getInitialShareCaption( store.getState() );
            dispatch( shareModelActions.contentUpdated( caption ) );
        },
    };
};

async function shareNow( dispatch: Dispatch<StoreState> )
{
    try
    {
        await dispatch( postServices.updatePostWithData() );
        await dispatch( postServices.finishAndShare() );

        const storeState = store.getState();
        const saveToComputerEnabled = getSaveToComputerEnabled( storeState );
        if ( saveToComputerEnabled )
        {
            const url = getDownloadUrl( storeState );
            const downloader = new Downloader( url );
            downloader.download();
        }

        emitCompletionEventsAndRedirect( dispatch );
    }
    catch (error)
    {
        if ( error === NO_LONGER_DRAFT_ERROR_MESSAGE )
        {
            store.dispatch( modalServices.openNotADraftDialog() );
            history.replace( HOMEPAGE_URL );
        }
        else
        {
            eventTracker.logSharePostFailed( error );
            errorReporting.reportErrorToSentry( error );
            dispatch( modalServices.openErrorDialogWithStandardFormat(
                ERROR_TITLE_OOPS,
                "There was a problem sharing your post.", error ) );
        }
    }
}

function getDownloadUrl( storeState: StoreState )
{
    if ( isAnimationOutputTypeFromState( storeState ) )
    {
        return getVideoUrl( storeState );
    }
    else
    {
        return getImageUrl( storeState, true );
    }
}

function emitCompletionEventsAndRedirect( dispatch: Dispatch<StoreState> )
{
    // It doesn't hurt to always do this.
    dispatch( contentSearchActions.searchModeExited() );

    const state = store.getState();

    eventTracker.logPostCompleted( getMediaSourceList( state ) );
    eventTracker.logSharePostSucceeded( getMediaSourceList( state ) );

    if ( !hasSharedFirstProPost( state ) )
    {
        eventTracker.logFirstProSharePostSucceeded();
        dispatch( userActions.firstProPostShared() );
    }

    eventTracker.logProfileDisplayed();
    dispatch( uiActions.presentAfterShareDialogSet( true ) );
    redirectToBestPageAfterSharing();

    dispatch( catalogActions.addDesignToFrontOfTray( getSelectedDesignId( state ) ) );
    dispatch( shareModelActions.shareCompleted() );
    dispatch( uiActions.clearBusinessTypeFilter() );
    customizationHelper.cleanupCustomizeState();
}

function redirectToBestPageAfterSharing()
{
    history.replace( POSTS_PAGE_URL );
}

function allowedToShareNow( storeState: StoreState )
{
    if ( noShareOutputsAreEnabled( storeState ) )
    {
        return false;
    }

    if ( isScheduledSendWithoutNetwork( storeState ) )
    {
        return false;
    }

    if ( isAnimationOutputTypeFromState( storeState ) )
    {
        return !!getVideoUrl( storeState );
    }

    return true;
}

function noShareOutputsAreEnabled( storeState: StoreState )
{
    const facebookEnabled = getFacebookEnabled( storeState );
    const saveToComputerEnabled = getSaveToComputerEnabled( storeState );
    const enabledSocialNetworkIds = getEnabledSocialNetworkIds( storeState );
    return !(facebookEnabled || saveToComputerEnabled || enabledSocialNetworkIds.length > 0);
}

function isScheduledSendWithoutNetwork( storeState: StoreState )
{
    const facebookEnabled = getFacebookEnabled( storeState );
    const enabledSocialNetworkIds = getEnabledSocialNetworkIds( storeState );

    return getScheduledSendDatetime( storeState ) && !(facebookEnabled || enabledSocialNetworkIds.length > 0);
}

function returnToCustomize( dispatch: Dispatch<StoreState> )
{
    customizationHelper.shareCancelled( dispatch );
    history.replace( CUSTOMIZE_PAGE_URL );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( SharePage );
