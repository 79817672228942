import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../_types";
import { userServices } from "../../services";
import { getUserSlug } from "../../ducks/user";
import GoogleDisconnectButton, { GoogleDisconnectButtonDispatchProps, GoogleDisconnectButtonProps } from "../GoogleDisconnectButton";

const mapStateToProps = ( storeState: StoreState ): GoogleDisconnectButtonProps =>
{
    return {
        userSlug: getUserSlug( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): GoogleDisconnectButtonDispatchProps =>
{
    return {
        onGoogleDisconnect: ( userSlug) =>
        {
            dispatch( userServices.clientGoogleDisconnect( userSlug ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( GoogleDisconnectButton );
