import * as React from "react";
import { ColorControl } from "./ColorControl";
import { FontControl } from "./FontControl";
import { AssetControl } from "./AssetControl";
import ControlBlockContainer from "./containers/ControlBlock.container";
import { apptimizeVariables, EDITOR_TYPES, EDITOR_TYPES_TITLES } from "../helpers";
import { find, isEqual } from "lodash";
import { AspectRatio, AssetChoice, BackgroundChoice, FontData, TemplateControlData, UIEditorType } from "../_types";
import { AssetControlConfig, ControlsConfig, IControlConfig } from "../_types/api";
import * as DesignsIconDefault from "../assets/img/controls/designs_control.png";
import * as DesignsIconSelected from "../assets/img/controls/designs_control_selected.png";
import * as MediaIconDefault from "../assets/img/controls/media_control.png";
import * as MediaIconSelected from "../assets/img/controls/media_control_selected.png";
import * as TextIconDefault from "../assets/img/controls/text_control.png";
import * as TextIconSelected from "../assets/img/controls/text_control_selected.png";
import * as MusicIconDefault from "../assets/img/controls/music_control.png";
import * as MusicIconSelected from "../assets/img/controls/music_control_selected.png";
import * as SizeIconDefault from "../assets/img/controls/size_control.png";
import * as SizeIconSelected from "../assets/img/controls/size_control_selected.png";
import * as BrandSlideIcon from "../assets/img/controls/brand_slide_control.png";
import * as BrandSlideIconSelected from "../assets/img/controls/brand_slide_control_selected.png";
import * as LogoIcon from "../assets/img/controls/logo_control.png";
import * as LogoIconSelected from "../assets/img/controls/logo_control_selected.png";
import * as DesignPresets from "../assets/img/controls/presets_control.svg";
import * as DesignPresetsSelected from "../assets/img/controls/presets_control_selected.svg";
import BackgroundMediaControlContainer from "./containers/BackgroundMediaControl.container";
import { getInitialAssetChoice } from "../helpers/assetControlHelper";

export interface DesignSpecificControlsProps
{
    controlData: TemplateControlData;
    controlConfigs?: ControlsConfig;
    aspectRatio: AspectRatio;
    dynamicImage: string;
    isStaticOutputMode: boolean;
    initialControlDataForCurrentSelectedDesign: TemplateControlData;
    showPresetsControl: boolean;
    isFoundationDesignSelected: boolean;
}

export interface DesignSpecificControlsDispatchProps
{
    onControlClicked: ( editorType: UIEditorType, controlId?: string ) => void;
}

export class DesignSpecificControls
    extends React.PureComponent<DesignSpecificControlsProps & DesignSpecificControlsDispatchProps>
{
    public render()
    {
        return (
            <div className="designControls">
                <ControlBlockContainer editorType={EDITOR_TYPES.TEMPLATES_CONTROL}
                                       title="Layouts"
                                       designControlIconImages={{
                                           defaultSrc: DesignsIconDefault,
                                           selectedSrc: DesignsIconSelected,
                                       }}
                                       {...this.props}>
                </ControlBlockContainer>
                <ControlBlockContainer editorType={EDITOR_TYPES.MEDIA_CONTROL}
                                       title={EDITOR_TYPES_TITLES.MEDIA}
                                       designControlIconImages={{
                                           defaultSrc: MediaIconDefault,
                                           selectedSrc: MediaIconSelected,
                                       }}
                                       {...this.props}>
                </ControlBlockContainer>
                <ControlBlockContainer editorType={EDITOR_TYPES.TEXT_CONTROL}
                                       designControlIconImages={{
                                           defaultSrc: TextIconDefault,
                                           selectedSrc: TextIconSelected,
                                       }}
                                       {...this.props}>
                </ControlBlockContainer>
                {
                    this.props.controlConfigs && this.props.controlConfigs.controls.map( ( control ) =>
                                              {
                                                  if ( !!control.suppressWhenHaveReplacementBackgroundMedia
                                                       && control.type === EDITOR_TYPES.COLOR_CONTROL )
                                                  {
                                                      return null;
                                                  }
                                                  else if ( apptimizeVariables.shouldEnablePresetText()
                                                            && this.props.isFoundationDesignSelected
                                                            && (control.type === EDITOR_TYPES.COLOR_CONTROL ||
                                                                control.type === EDITOR_TYPES.FONT_CONTROL) )
                                                  {
                                                      return null;
                                                  }
                                                  else
                                                  {
                                                      return <ControlBlockContainer
                                                          key={control.id} editorType={EDITOR_TYPES.CONTROL}
                                                          title={control.title}
                                                          control={control}
                                                          disabled={this.shouldDisableControl( control )}
                                                          {...this.props}>
                                                          {this.createControl( control )}
                                                      </ControlBlockContainer>;
                                                  }
                                              } )
                }
                <ControlBlockContainer editorType={EDITOR_TYPES.AUDIO_CONTROL}
                                       title={EDITOR_TYPES_TITLES.AUDIO}
                                       designControlIconImages={{
                                           defaultSrc: MusicIconDefault,
                                           selectedSrc: MusicIconSelected,
                                       }}
                                       disabled={this.props.isStaticOutputMode}
                                       showAnimatedOnlyTipWhenDisabled={true}
                                       {...this.props}/>
                <ControlBlockContainer editorType={EDITOR_TYPES.ASPECT_RATIO_CONTROL}
                                       title={EDITOR_TYPES_TITLES.ASPECT_RATIO}
                                       designControlIconImages={{
                                           defaultSrc: SizeIconDefault,
                                           selectedSrc: SizeIconSelected,
                                       }}
                                       {...this.props}/>
                <ControlBlockContainer editorType={EDITOR_TYPES.LOGO_CONTROL}
                                       title={EDITOR_TYPES_TITLES.LOGO}
                                       designControlIconImages={{
                                           defaultSrc: LogoIcon,
                                           selectedSrc: LogoIconSelected,
                                       }}
                                       {...this.props}/>
                <ControlBlockContainer editorType={EDITOR_TYPES.BRAND_SLIDE_CONTROL}
                                       title={EDITOR_TYPES_TITLES.BRAND_SLIDE}
                                       designControlIconImages={{
                                           defaultSrc: BrandSlideIcon,
                                           selectedSrc: BrandSlideIconSelected,
                                       }}
                                       disabled={this.props.isStaticOutputMode}
                                       showAnimatedOnlyTipWhenDisabled={true}
                                       {...this.props}/>
                {this.props.showPresetsControl && <ControlBlockContainer editorType={EDITOR_TYPES.FOUNDATION_PRESETS_CONTROL}
                                                                         title={EDITOR_TYPES_TITLES.FOUNDATION_PRESETS}
                                                                         designControlIconImages={{
                                                                             defaultSrc: DesignPresets,
                                                                             selectedSrc: DesignPresetsSelected,
                                                                         }}
                                                                         showAnimatedOnlyTipWhenDisabled={true}
                                                                         {...this.props}/>}
                <div className="filler"/>
            </div>
        );
    }

    private createControl = ( control: IControlConfig ) =>
    {
        const value = this.props.controlData && this.props.controlData[control.id];
        if ( !value )
        {
            return;
        }

        switch ( control.type )
        {
            case EDITOR_TYPES.BACKGROUND_MEDIA_CONTROL:
                return <BackgroundMediaControlContainer value={value as BackgroundChoice}/>;
            case EDITOR_TYPES.COLOR_CONTROL:
                return <ColorControl value={value as string}/>;
            case EDITOR_TYPES.FONT_CONTROL:
                return <FontControl value={value as FontData}/>;
            case EDITOR_TYPES.ASSET_CONTROL:
            case EDITOR_TYPES.MODEL_LINKED_PHOTO_CONTROL:
            case EDITOR_TYPES.MODEL_LINKED_TEXT_CONTROL:
                const choice = this.lookUpAssetChoiceByData( value, control as AssetControlConfig );
                return <AssetControl value={choice as AssetChoice}/>;
        }
        // TODO determine correct default
        return <img src="http://via.placeholder.com/50x50"/>;
    }

    private shouldDisableControl( control: IControlConfig )
    {
        const isStaticOutputModeFlag = this.props.isStaticOutputMode;
        const controlDoesNotSupportStaticImageOutputMode = control && control.supportsStaticOutputMode === false;
        return isStaticOutputModeFlag && controlDoesNotSupportStaticImageOutputMode;
    }

    private lookUpAssetChoiceByData = ( value: any, control: AssetControlConfig ): AssetChoice =>
    {
        if ( !value )
        {
            return;
        }
        const initialAssetChoice = getInitialAssetChoice( control, this.props.initialControlDataForCurrentSelectedDesign );
        if ( initialAssetChoice && isEqual( initialAssetChoice.jsonBlob, value ) )
        {
            return initialAssetChoice;
        }
        return find( control.choices, ( choice ) => isEqual( choice.jsonBlob, value ) );
    }
}
