import { CatalogCategory, StoreState } from "../_types";
import { getCategories } from "../ducks/catalogCategories";
import { filter, head } from "lodash";

export function getSelectedDesignCatalogCategory( state: StoreState, categories: CatalogCategory[] )
{
    const { ui, catalogCategories } = state;
    return catalogCategories.idToObj[ui.designCatalogDialog.selectedCategory] || head( categories );
}

export function getDesignCatalogCategoriesToDisplay( state: StoreState )
{
    return getCategories( state );
}

export function getDesignSwitchingCatalogCategoriesToDisplay( state: StoreState )
{
    return filter( getCategories( state ), ( catalogCategory ) => catalogCategory.show_for_design_switching );
}
