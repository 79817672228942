import { RecommendedPost } from "../../_types";
import * as React from "react";
import { map } from "lodash";
import Button from "react-md/lib/Buttons/Button";
import { RecommendedPostCell } from "../RecommendedPostCell";
import * as VisibilitySensor from "react-visibility-sensor";
import { HomePostRow } from "./HomePostRow";

export interface RecommendedPostsRowProps
{
    recommendedPosts: RecommendedPost[];
    hasCurrentBusiness: boolean;
}

export interface RecommendedPostsRowDispatchProps
{
    handleCustomizePost: ( RecommendedPost ) => void;
    handleOpenCreateBusiness: () => void;
    loadRecommendations: () => void;
}

interface RecommendedPostsRowState
{
    hasFetchedData: boolean;
}

export class RecommendedPostsRow extends React.PureComponent<RecommendedPostsRowProps & RecommendedPostsRowDispatchProps, RecommendedPostsRowState>
{
    constructor( props )
    {
        super( props );
        this.state = {
            hasFetchedData: false,
        };
    }

    public render()
    {
        return (
            <VisibilitySensor
                onChange={this.handleVisibilityChange}
                partialVisibility={true}
                scrollThrottle={100}>
                {this.renderContent()}
            </VisibilitySensor>
        );
    }

    private renderContent = () =>
    {
        const renderModel = {
            posts: this.props.recommendedPosts,
            id: 0,
            name: "Weekly Recommendations",
            initialScrollToIndex: this.getInitialScrollIndexForToday(),
        };

        if ( this.props.recommendedPosts.length )
        {
            return (
                <HomePostRow {...renderModel}>
                    {map( this.props.recommendedPosts, ( recommendedPost ) =>
                    {
                        return (
                            <RecommendedPostCell key={recommendedPost.id}
                                                 handleCustomizePost={this.props.handleCustomizePost}
                                                 recommendedPost={recommendedPost}/>
                        );
                    } )}
                </HomePostRow>
            );
        }
        else
        {
            return this.renderNoBusinessOrNoPostsUI();
        }
    }

    private renderNoBusinessOrNoPostsUI()
    {
        const content = this.props.hasCurrentBusiness ?
                        this.createNoRecommendedPostsUI() :
                        this.createCreateBusinessButton();
        return (
            <div className="weeklyRecommendationsContainer">
                <div className="weeklyRecommendationsWrapper">
                    <div className="emptyContainer">
                        {content}
                    </div>
                </div>
            </div>
        );
    }

    private createCreateBusinessButton = () =>
    {
        return (
            <Button className="createBusinessBtn" primary flat swapTheming onClick={this.props.handleOpenCreateBusiness}>
                Create a business
            </Button>
        );
    }

    private createNoRecommendedPostsUI = () =>
    {
        return (<span className="noRecommendedPostText"> Refresh your browser to see recommendations </span>);
    }

    private getInitialScrollIndexForToday = () =>
    {
        const recommendedPosts = this.props.recommendedPosts;
        const todaysRecommendedPost = recommendedPosts.find( ( recommendedPost ) =>
        {
            return recommendedPost.isToday;
        } );
        return todaysRecommendedPost ? recommendedPosts.indexOf( todaysRecommendedPost ) : 0;
    }

    private handleVisibilityChange = ( isVisible ) =>
    {
        if ( isVisible && !this.state.hasFetchedData )
        {
            this.props.loadRecommendations();
            this.setState( { hasFetchedData: true } );
        }
    }
}
