import * as React from "react";
import Cell from "react-md/lib/Grids/Cell";

interface PostIntentCellProps
{
    onClick: ( slug, id ) => void;
    postIntent: PostIntentData;
}

export class PostIntentCell extends React.PureComponent<PostIntentCellProps>
{
    public render()
    {
        return <Cell size={12} className={"postIntentCell"} onClick={this.handleClick}>
            <img src={this.props.postIntent.icon_url} className={"postIntentIcon"} alt={this.props.postIntent.label}/>
            <div className={"postIntentCellTitle"}>{this.props.postIntent.label}</div>
        </Cell>;
    }

    private handleClick = () =>
    {
        return this.props.onClick( this.props.postIntent.collection_slug, this.props.postIntent.id );
    }
}
