import * as React from "react";
import * as InspireBackground from "../assets/img/inspire_background.jpg";

export const InspireBackgroundOverlay: React.FC<{}> = ( props ) =>
{
    return <div className="inspireBackgroundOverlay"
                style={{
                    backgroundImage: "url(" + InspireBackground + ")",
                }}>
        <div className="overlay">
            {props.children}
        </div>
    </div>;
};
