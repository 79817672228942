import {
    ALL_APP_INTEGRATION_SOCIAL_NETWORK_LABELS_FOR_TYPES,
    ALL_SOCIAL_NETWORK_LABELS_FOR_TYPES,
    SAVE_TO_COMPUTER_ACCOUNT_LABEL,
    SOCIAL_NETWORK_CONNECTED_ICONS,
    SOCIAL_NETWORK_NOT_CONNECTED_ICONS,
    SOCIAL_NETWORKS_CAN_CONNECT_TO,
    SOCIAL_NETWORKS_CAN_SHARE_TO,
} from "./";
import { concat, filter, find, includes } from "lodash";
import { ShareOutputDisplayProps } from "../components/ShareOutputList";
import { getEnabledSocialNetworkIds, getFacebookEnabled, getSaveToComputerEnabled, isNetworkAuthorizedToShare } from "../ducks/shareModel";
import { getAppIntegrationUserSocialNetworkAccounts, getUserSocialNetworkAccounts } from "../ducks/user";
import * as saveToComputerImg from "../assets/img/savetocomputer.png";
import {
    ITEM_DISPLAY_MODE_ADD,
    ITEM_DISPLAY_MODE_CONNECT,
    ITEM_DISPLAY_MODE_DISCONNECT,
    ITEM_DISPLAY_MODE_REFRESH,
    ITEM_DISPLAY_MODE_TOGGLE,
    ItemDisplayMode,
} from "../components/ShareOutputListItem";
import { ShareOutputType, SocialNetworkAccount, SocialNetworkAccountType, StoreState } from "../_types";
import { FACEBOOK_ACCOUNT_TYPE, SAVE_TO_COMPUTER_ACCOUNT_TYPE } from "../_types/api";
import { hasTeamMemberPermissionsForCurrentBusiness } from "../ducks/userBusiness";

export const ENABLE_MODE = "enable";
export const EDIT_MODE = "edit";
export type ShareOutputListDisplayMode = typeof ENABLE_MODE | typeof EDIT_MODE;

export function generateShareOutputDisplayProps( storeState: StoreState,
                                                 displayMode: ShareOutputListDisplayMode ): ShareOutputDisplayProps[]
{
    const serverAccounts = getUserSocialNetworkAccounts( storeState );
    const facebookEnabled = getFacebookEnabled( storeState );
    const saveToComputerEnabled = getSaveToComputerEnabled( storeState );
    const enabledSocialNetworkIds = getEnabledSocialNetworkIds( storeState );

    let shareOutputDisplayProps = getListOfSocialNetworkAccountsDisplayProps( serverAccounts,
        facebookEnabled,
        enabledSocialNetworkIds,
        displayMode );

    shareOutputDisplayProps = addMissingSocialNetworks( shareOutputDisplayProps, displayMode );

    if ( displayMode === ENABLE_MODE )
    {
        shareOutputDisplayProps.push( createShareToComputerDisplayProps( saveToComputerEnabled ) );
    }

    const sortFunction = displayMode === ENABLE_MODE ? ShareOutputItem.enableListSortCompareFunction
                                                     : ShareOutputItem.editListSortCompareFunction;
    shareOutputDisplayProps.sort( sortFunction );

    if ( hasTeamMemberPermissionsForCurrentBusiness( storeState ) )
    {
        shareOutputDisplayProps = filter( shareOutputDisplayProps, ( shareOutputItem ) => shareOutputItem.accountAccessible );
    }

    return shareOutputDisplayProps;
}

function generateAccountItemsAndEnable( filteredServerAccounts: SocialNetworkAccount[],
                                        displayMode: ShareOutputListDisplayMode,
                                        facebookEnabled: boolean, enabledSocialNetworkIds: number[] )
{
    const accounts = filteredServerAccounts.map<ShareOutputItem>( ( serverAccount ) =>
    {
        return ShareOutputItem.instantiate( {
            accountId: serverAccount.id,
            accountType: serverAccount.account_type,
            accountLabel: ALL_SOCIAL_NETWORK_LABELS_FOR_TYPES[serverAccount.account_type],
            accountConnected: true,
            accountAccessible: isNetworkAuthorizedToShare( serverAccount ),
            accountEnabled: false,
            profileId: serverAccount.network_id,
            profileName: serverAccount.name,
            profileImageUrl: serverAccount.profile_image_url,
        }, displayMode );
    } );
    accounts.forEach( ( account ) =>
    {
        account.updateEnabled( facebookEnabled, enabledSocialNetworkIds );
    } );
    return accounts;
}

function getServerAccountsFilteredForShareAccountList( serverAccounts: SocialNetworkAccount[], displayMode: string ): SocialNetworkAccount[]
{
    // Only consider enabled accounts
    let filteredServerAccounts = filter( serverAccounts, ( account ) => account.enabled );
    if ( displayMode === ENABLE_MODE )
    {
        filteredServerAccounts = filter( filteredServerAccounts,
            ( account ) => isAllowedSocialNetworkShareType( account.account_type ) );
    }
    else
    {
        filteredServerAccounts = filter( filteredServerAccounts,
            ( account ) => isAllowedSocialNetworkConnectType( account.account_type ) );
    }
    return filteredServerAccounts;
}

function getListOfSocialNetworkAccountsDisplayProps( serverAccounts: SocialNetworkAccount[],
                                                     facebookEnabled: boolean,
                                                     enabledSocialNetworkIds: number[],
                                                     displayMode: ShareOutputListDisplayMode ): ShareOutputItem[]
{
    const filteredServerAccounts = getServerAccountsFilteredForShareAccountList( serverAccounts, displayMode );
    return generateAccountItemsAndEnable( filteredServerAccounts, displayMode, facebookEnabled, enabledSocialNetworkIds );
}

function addMissingSocialNetworks( shareOutputDisplayProps: ShareOutputItem[], displayMode: ShareOutputListDisplayMode ): ShareOutputItem[]
{
    const addAllNetworksButFacebook = displayMode === EDIT_MODE;
    const missingAccounts = [];
    Object.keys( ALL_SOCIAL_NETWORK_LABELS_FOR_TYPES ).forEach( ( accountType ) =>
    {
        const match = accountTypeIsPresentInListOfShareOutputs( shareOutputDisplayProps, accountType );
        const reallyAdd = !match || (addAllNetworksButFacebook && accountType !== FACEBOOK_ACCOUNT_TYPE);

        if ( reallyAdd )
        {
            const account = ShareOutputItem.instantiate( {
                accountConnected: false,
                accountType: accountType as SocialNetworkAccountType,
                accountLabel: ALL_SOCIAL_NETWORK_LABELS_FOR_TYPES[accountType],
            }, displayMode, match );
            missingAccounts.push( account );
        }
    } );

    const combinedSocialNetworks = concat( shareOutputDisplayProps, missingAccounts );
    if ( displayMode === EDIT_MODE )
    {
        return filter( combinedSocialNetworks,
            ( account ) => isAllowedSocialNetworkConnectType( account.accountType ) );
    }
    else
    {
        return filter( combinedSocialNetworks,
            ( account ) => isAllowedSocialNetworkShareType( account.accountType ) );
    }
}

function addMissingAppIntegrations( shareOutputDisplayProps: ShareOutputItem[], displayMode: ShareOutputListDisplayMode ): ShareOutputItem[]
{
    const missingAccounts = [];
    const shouldAddMissingAccounts = displayMode === EDIT_MODE;
    const networkTypeToDisplayLabelMap = ALL_APP_INTEGRATION_SOCIAL_NETWORK_LABELS_FOR_TYPES;
    Object.keys( networkTypeToDisplayLabelMap ).forEach( ( accountType ) =>
    {
        const match = accountTypeIsPresentInListOfShareOutputs( shareOutputDisplayProps, accountType );
        const reallyAdd = !match || shouldAddMissingAccounts;

        if ( reallyAdd )
        {
            const account = ShareOutputItem.instantiate( {
                accountConnected: false,
                accountType: accountType as SocialNetworkAccountType,
                accountLabel: networkTypeToDisplayLabelMap[accountType],
            }, displayMode, match );
            missingAccounts.push( account );
        }
    } );

    return concat( shareOutputDisplayProps, missingAccounts );
}

export function generateAppIntegrationDisplayProps( storeState: StoreState,
                                                    displayMode: ShareOutputListDisplayMode ): ShareOutputDisplayProps[]
{
    const serverAccounts = getAppIntegrationUserSocialNetworkAccounts( storeState );
    const enabledSocialNetworkIds = getEnabledSocialNetworkIds( storeState );

    let shareOutputDisplayProps = getListOfAppIntegrationSocialNetworkAccountsDisplayProps( serverAccounts, enabledSocialNetworkIds );
    shareOutputDisplayProps = addMissingAppIntegrations( shareOutputDisplayProps, displayMode );

    const sortFunction = displayMode === ENABLE_MODE ? ShareOutputItem.enableListSortCompareFunction
                                                     : ShareOutputItem.editListSortCompareFunction;
    shareOutputDisplayProps.sort( sortFunction );

    return shareOutputDisplayProps;
}

function getListOfAppIntegrationSocialNetworkAccountsDisplayProps( serverAccounts: SocialNetworkAccount[],
                                                                   enabledSocialNetworkIds: number[] ): ShareOutputItem[]
{
    const filteredServerAccounts = filter( serverAccounts, ( account ) => account.enabled );
    return generateAccountItemsAndEnable( filteredServerAccounts, EDIT_MODE, false, enabledSocialNetworkIds );
}

function accountTypeIsPresentInListOfShareOutputs( shareOutputDisplayProps: ShareOutputItem[], accountType ): boolean
{
    if ( !shareOutputDisplayProps || !accountType )
    {
        return false;
    }

    const match = find( shareOutputDisplayProps, { accountType } );
    return !!match;
}

function createShareToComputerDisplayProps( saveToComputerEnabled: boolean ): ShareOutputItem
{
    return ShareOutputItem.instantiate( {
        accountType: SAVE_TO_COMPUTER_ACCOUNT_TYPE,
        accountLabel: SAVE_TO_COMPUTER_ACCOUNT_LABEL,
        accountEnabled: saveToComputerEnabled,
        shareOutputIcon: saveToComputerImg,
    } );
}

export function isAllowedSocialNetworkConnectType( socialNetworkAccountType )
{
    return includes( SOCIAL_NETWORKS_CAN_CONNECT_TO, socialNetworkAccountType );
}

export function isAllowedSocialNetworkShareType( socialNetworkAccountType )
{
    return includes( SOCIAL_NETWORKS_CAN_SHARE_TO, socialNetworkAccountType );
}

export class ShareOutputItem implements ShareOutputDisplayProps
{

    public static instantiate( account: Partial<ShareOutputItem>,
                               listDisplayMode: ShareOutputListDisplayMode = ENABLE_MODE,
                               otherAccountsExistForSameNetwork: boolean = false )
    {
        let item;
        if ( account.accountType === SAVE_TO_COMPUTER_ACCOUNT_TYPE )
        {
            item = new AlwaysConnectedShareOutputItem( account );
        }
        else if ( account.accountConnected === false )
        {
            item = new UnconnectedShareOutputItem( account, otherAccountsExistForSameNetwork );
        }
        else if ( account.accountType === FACEBOOK_ACCOUNT_TYPE )
        {
            item = new FacebookShareOutputItem( account, listDisplayMode );
        }
        else
        {
            item = new ShareOutputItem( account, listDisplayMode );
        }
        // Setting displayMode has to be done here because calculateDisplayMode is not
        // correctly overridden until the constructor is done.
        item.displayMode = item.calculateDisplayMode( listDisplayMode, otherAccountsExistForSameNetwork );
        return item;
    }

    public static enableListSortCompareFunction = ( a: ShareOutputItem, b: ShareOutputItem ) =>
    {
        // Separate connected and unconnected
        if ( a.accountConnected !== b.accountConnected )
        {
            return a.accountConnected ? -1 : 1;
        }

        // Sort by network
        if ( a.accountType !== b.accountType )
        {
            return a.baseSortIndex - b.baseSortIndex;
        }

        // Sort by label if on same (connected) network
        return a.accountLabel.localeCompare( b.accountLabel );
    }

    public static editListSortCompareFunction = ( a: ShareOutputItem, b: ShareOutputItem ) =>
    {
        // Sort by network
        if ( a.accountType !== b.accountType )
        {
            return a.baseSortIndex - b.baseSortIndex;
        }

        // Connected accounts should precede unconnected
        if ( a.accountConnected !== b.accountConnected )
        {
            return a.accountConnected ? -1 : 1;
        }

        // // Sort by label if on same (usually connected) network
        return a.accountLabel.localeCompare( b.accountLabel );
    }

    public accountId: number;
    public accountType: ShareOutputType;
    public accountLabel: string;
    public accountConnected: boolean;
    public accountEnabled: boolean;
    public accountAccessible: boolean;
    public key: string;
    public shareOutputIcon: string;
    public connectedOutputIcon: string;

    public profileId: string;
    public profileName: string;
    public profileImageUrl: string;

    public displayMode: ItemDisplayMode;
    protected baseSortIndex: number;

    constructor( account: Partial<ShareOutputItem>,
                 listDisplayMode: ShareOutputListDisplayMode = ENABLE_MODE,
                 otherAccountsExistForSameNetwork: boolean = false )
    {
        Object.assign( this, account );

        this.key = this.accountType + (this.accountId ? `-${this.accountId}` : "");
        if ( this.profileName )
        {
            this.accountLabel = this.profileName;
        }

        if ( !this.shareOutputIcon && this.accountType in SOCIAL_NETWORK_NOT_CONNECTED_ICONS )
        {
            this.shareOutputIcon = SOCIAL_NETWORK_NOT_CONNECTED_ICONS[this.accountType];
        }

        if ( !this.connectedOutputIcon && this.accountType in SOCIAL_NETWORK_CONNECTED_ICONS )
        {
            this.connectedOutputIcon = SOCIAL_NETWORK_CONNECTED_ICONS[this.accountType];
        }

        this.baseSortIndex = Object.keys( ALL_SOCIAL_NETWORK_LABELS_FOR_TYPES ).indexOf( this.accountType );
    }

    public updateEnabled = ( facebookEnabled: boolean, enabledSocialNetworkIds: number[] ) =>
    {
        if ( includes( enabledSocialNetworkIds, this.accountId ) )
        {
            this.accountEnabled = true;
        }
    }

    public calculateDisplayMode = ( listDisplayMode: ShareOutputListDisplayMode,
                                    otherAccountsExistForSameNetwork: boolean ): ItemDisplayMode =>
    {
        if ( this.accountAccessible )
        {
            if ( listDisplayMode === ENABLE_MODE )
            {
                return ITEM_DISPLAY_MODE_TOGGLE;
            }
            else
            {
                return ITEM_DISPLAY_MODE_DISCONNECT;
            }
        }
        else if ( this.accountConnected )
        {
            return ITEM_DISPLAY_MODE_REFRESH;
        }
        else if ( otherAccountsExistForSameNetwork )
        {
            return ITEM_DISPLAY_MODE_ADD;
        }
        else
        {
            return ITEM_DISPLAY_MODE_CONNECT;
        }
    }
}

class FacebookShareOutputItem extends ShareOutputItem
{
    public updateEnabled = ( facebookEnabled: boolean, enabledSocialNetworkIds: number[] ) =>
    {
        this.accountEnabled = facebookEnabled;
    }
}

class AlwaysConnectedShareOutputItem extends ShareOutputItem
{
    constructor( account: Partial<ShareOutputItem> )
    {
        super( account, undefined, undefined );
        this.accountConnected = true;
        this.accountAccessible = true;
    }

    public updateEnabled = ( facebookEnabled: boolean, enabledSocialNetworkIds: number[] ) =>
    {
        return;
    }

    public calculateDisplayMode = ( listDisplayMode: ShareOutputListDisplayMode,
                                    otherAccountsExistForSameNetwork: boolean ): ItemDisplayMode =>
    {
        return ITEM_DISPLAY_MODE_TOGGLE;
    }
}

class UnconnectedShareOutputItem extends ShareOutputItem
{
    constructor( account: Partial<ShareOutputItem>, otherAccountsExistForSameNetwork: boolean )
    {
        super( account, undefined, otherAccountsExistForSameNetwork );
        this.accountConnected = false;
        this.accountAccessible = false;
        this.accountEnabled = false;
    }

    public updateEnabled = ( facebookEnabled: boolean, enabledSocialNetworkIds: number[] ) =>
    {
        return;
    }

    public calculateDisplayMode = ( listDisplayMode: ShareOutputListDisplayMode,
                                    otherAccountsExistForSameNetwork: boolean ): ItemDisplayMode =>
    {
        if ( otherAccountsExistForSameNetwork )
        {
            return ITEM_DISPLAY_MODE_ADD;
        }
        else
        {
            return ITEM_DISPLAY_MODE_CONNECT;
        }
    }
}
