import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../_types";
import { DeleteCurrentBrandButton, DeleteCurrentBrandButtonDispatchProps, DeleteCurrentBrandButtonProps } from "../DeleteCurrentBrandButton";
import { getUserBusinessesCount, hasTeamAdminPermissionsForCurrentBusiness } from "../../../ducks/userBusiness";
import { getEditedBusinessInfo } from "../../../ducks/ui";
import { store } from "../../../store";
import { modalServices, userBusinessServices } from "../../../services";
import { uiActions } from "../../../actions";
import { LightboxDialogIdentifierForKey } from "../../../helpers";

const mapStateToProps = ( storeState: StoreState ): DeleteCurrentBrandButtonProps =>
{
    return {
        isBusinessAdmin: hasTeamAdminPermissionsForCurrentBusiness( storeState ),
        userBusinessesCount: getUserBusinessesCount( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): DeleteCurrentBrandButtonDispatchProps =>
{
    return {
        onDeleteBrandClicked: () =>
        {
            const businessInfo = getEditedBusinessInfo( store.getState() );
            dispatch( modalServices.openLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.CONFIRM_DELETE_BUSINESS,
                title: "Confirm delete business",
                subtitle: `If you delete ${businessInfo.name} you will lose any associated statistics and posts, are you sure you wish to proceed?`,
                width: 450,
                onSuccess: () =>
                {
                    dispatch( uiActions.clearBusinessInfo() );
                    dispatch( userBusinessServices.delete() );
                },
                confirmLabel: "Delete",
                hideCancel: true,
                showCancelX: true,
            } ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( DeleteCurrentBrandButton );
