import { DesignElementProperty, SlideTransitionChoice, TransitionOption } from "../../../_types";
import { map } from "lodash";
import { DESIGN_ELEMENT_PROPERTY_TYPE_LIST } from "../../../_types/api";
import { ListPicker } from "../../ListPicker";
import * as React from "react";
import { BackgroundAnimationListOption } from "../BackgroundAnimationListOption";

interface BackgroundAnimationPropertiesPanelProps
{
    properties: DesignElementProperty[];
    preferredBackgroundAnimation?: SlideTransitionChoice;
    onPreferredBackgroundAnimationSelected( backgroundAnimationChoice: SlideTransitionChoice ): void;
}

export function BackgroundAnimationPropertiesPanel( props: BackgroundAnimationPropertiesPanelProps )
{
    const choices = map( props.properties, ( designElementProperty ) =>
    {
        switch ( designElementProperty.type )
        {
            case DESIGN_ELEMENT_PROPERTY_TYPE_LIST:
                return (<ListPicker key={designElementProperty.id}>
                    <h5 className="sectionTitle">{designElementProperty.displayName}</h5>
                    {
                        map( designElementProperty.options, ( transitionOption: TransitionOption ) =>
                        {
                            return (
                                <BackgroundAnimationListOption
                                    key={transitionOption.displayName}
                                    options={transitionOption}
                                    designElementProperty={designElementProperty}
                                    preferredBackgroundAnimation={props.preferredBackgroundAnimation}
                                    onPreferredBackgroundAnimationSelected={props.onPreferredBackgroundAnimationSelected}/>);
                        } )
                    }
                </ListPicker>);
            default:
                return <>unknown type</>;
        }
    } );

    return <div className="listPicker">{choices}</div>;
}
