import { map } from "lodash";
import { StylizedTextChoiceButton } from "../StylizedTextChoiceButton";
import * as React from "react";
import { StylizedTextChoices } from "../../../_types";

interface StylizedTextChoicesPanelProps
{
    stylizedTextChoices: StylizedTextChoices;
    preferredTextStyle?: string;
    onPreferredTextStyleSelected( textStyleId: string ): void;
}

export function StylizedTextChoicesPanel( props: StylizedTextChoicesPanelProps )
{
    const styles = map( props.stylizedTextChoices.styles, ( style ) =>
    {
        const isSelected = props.preferredTextStyle === style.styleId;
        return <StylizedTextChoiceButton outerRef={null}
                                         styleId={style.styleId}
                                         status={style.status}
                                         relativeImageUrl={style.relativeImageUrl}
                                         selected={isSelected}
                                         onClick={props.onPreferredTextStyleSelected}
                                         key={style.styleId}/>;
    } );

    return <div className="stylizedTextChoicePanel">{styles}</div>;
}