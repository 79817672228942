import { connect } from "react-redux";
import { Dispatch } from "redux";
import { userServices } from "../../../services";
import { StoreState } from "../../../_types";
import NeedHelpSubPage, { NeedHelpSubPageDispatchProps, NeedHelpSubPageProps } from "../NeedhelpSubPage";

const mapStateToProps = ( storeState: StoreState ): NeedHelpSubPageProps =>
{
    return {
        user: storeState.user,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): NeedHelpSubPageDispatchProps =>
{
    return {
        handleSubmitTicket: ( emailAddress: string, description: string ) =>
        {
            dispatch( userServices.submitTicket( emailAddress, description ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( NeedHelpSubPage );
