import * as React from "react";
import { get } from "lodash";
import PriceBlock from "./containers/PriceBlock.container";
import { SavingsInPercentBadgeFromPlan } from "./SavingsInPercentBadgeFromPlan";
import SavingsInDollarsBadgeFromPlan from "./containers/SavingsInDollarsBadgeFromPlan.container";
import classNames from "classnames";

interface PriceBlockSectionProps
{
    shouldShowSimplifiedUpsell?: boolean;
    monthlyPlan: StripePlanData;
    annualPlan: StripePlanData;
    selectedPlan: StripePlanData;
    handlePlanSelected( StripePlanData );
}

export class PriceBlockSection extends React.PureComponent<PriceBlockSectionProps>
{
    public render()
    {
        return (
            <div className={classNames( "priceBlockSection" )}>
                {this.getPriceBlock( this.props.monthlyPlan )}
                {this.getPriceBlock( this.props.annualPlan )}
            </div>
        );
    }

    private getPriceBlock = ( plan: StripePlanData ) =>
    {
        return plan && (<PriceBlock key={plan.id}
                                    plan={plan}
                                    selected={plan.id === get( this.props.selectedPlan, "id" )}
                                    updatePlanSelection={this.props.handlePlanSelected}
                                    shouldShowSimplifiedUpsell={!!this.props.shouldShowSimplifiedUpsell}>
            <SavingsInPercentBadgeFromPlan plan={plan}
                                           monthlyPlan={this.props.monthlyPlan}
                                           annualPlan={this.props.annualPlan}/>
            <SavingsInDollarsBadgeFromPlan plan={plan}
                                           monthlyPlan={this.props.monthlyPlan}
                                           annualPlan={this.props.annualPlan}/>
        </PriceBlock>);
    }
}
