import * as React from "react";
import PostsPanel from "./PostsPanel";
import { Post } from "../../_types";
import { ErrorBoundary } from "../ErrorBoundary";
import EngagementGraphContainer from "../containers/EngagementGraph.container";
import NoPostsPanelContainer from "./containers/NoPostsPanelContainer";

export interface EngagementsTabProps
{
    postData: Post[];
}

export interface EngagementsTabDispatchProps
{
    loadData(): void;
}

export class EngagementsTab extends React.PureComponent<EngagementsTabProps & EngagementsTabDispatchProps>
{
    public componentDidMount()
    {
        this.props.loadData();
    }

    public render()
    {
        return (
            <div className="subNavContent engagementTabContent">
                <div className="subNavInnerWrap twoPanel">
                    {this.createContent()}
                </div>
            </div>
        );
    }

    private createContent = () =>
    {
        const postData = this.props.postData;
        if ( postData.length > 0 )
        {
            return (<ErrorBoundary errorMessage="There was a problem creating the Schedule tab. If this continues, click Restart below.">
                    <div className="engagementTabGraph">
                        <h2 className="subNavSectionH2">Weekly engagements</h2>
                        <EngagementGraphContainer/>
                    </div>
                    <div className="engagementTabColumn">
                        <h2 className="subNavSectionH2">Most engaged posts</h2>
                        <PostsPanel postData={this.props.postData}/>
                    </div>
                </ErrorBoundary>
            );
        }
        return <NoPostsPanelContainer/>;
    }
}
