import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../_types";
import { PostIntentsDialog, PostIntentsDialogDispatchProps, PostIntentsDialogProps } from "../PostIntentsDialog";
import { getPostIntentsForCurrentBusinessType } from "../../ducks/userBusiness";
import { collectionsServices, modalServices } from "../../services";
import { COLLECTION_PAGE_URL, history, POST_INTENT_SOURCE } from "../../helpers";

const mapStateToProps = ( storeState: StoreState ): PostIntentsDialogProps =>
{
    return {
        postIntents: getPostIntentsForCurrentBusinessType( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): PostIntentsDialogDispatchProps =>
{
    return {
        openCollection: async ( slug: string ) =>
        {
            dispatch( collectionsServices.openCollection( slug, POST_INTENT_SOURCE ) );
            dispatch( modalServices.closeAllLightboxes() );
            history.push( COLLECTION_PAGE_URL );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( PostIntentsDialog );
