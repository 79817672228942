export function reduceReducers( ...reducers )
{
    return ( previous, current, ...args ) =>
    {
        return reducers.reduce( ( p, r ) =>
        {
            return r.apply( undefined, [p, current].concat( args ) );
        }, previous );
    };
}
