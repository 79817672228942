import * as React from "react";
import { GraphBar } from "./GraphBar";
import { isEmpty, keys, max, values } from "lodash";
import { format } from "date-fns";
import { UserBusiness } from "../_types";

export interface EngagementGraphProps
{
    weeklyEngagements: WeeklyEngagementData;
    currentBusiness: UserBusiness;
}

export interface EngagementGraphDispatchProps
{
    loadData();
}

export class EngagementGraph extends React.PureComponent<EngagementGraphProps & EngagementGraphDispatchProps>
{
    public componentDidMount()
    {
        this.props.loadData();
    }

    public render()
    {
        const sortedDates = keys( this.props.weeklyEngagements.weekly_breakdown ).sort();
        const weeklyBreakdownData = this.props.weeklyEngagements.weekly_breakdown;
        const maxValue = max( values( weeklyBreakdownData ) ) || 1;
        return (
            <div className="engagementGraph flex column centered">
                <div className="graphContent">
                    <div className="base line"/>
                    {
                        !isEmpty( sortedDates ) &&
                        sortedDates.map( ( date, idx ) =>
                        {
                            const engagements = weeklyBreakdownData[date];
                            const goal = this.props.weeklyEngagements.engagement_goal;
                            const isCurrent = idx === sortedDates.length - 1;
                            return (
                                <GraphBar
                                    key={date}
                                    current={isCurrent}
                                    metGoal={goal <= engagements}
                                    value={engagements}
                                    maxValue={maxValue}
                                    label={this.formatDate( date, isCurrent )}
                                />
                            );
                        } )
                    }
                </div>
                <div className="week label detail">Week of</div>
            </div>
        );
    }

    private formatDate = ( dateString, isCurrent ) =>
    {
        if ( isCurrent )
        {
            return "Current";
        }
        return format( dateString, "M/DD" );
    }
}
