import * as React from "react";
import Button from "react-md/lib/Buttons/Button";
import BusinessTypeBlockContainer from "./containers/BusinessTypeBlock.container";
import Grid from "react-md/lib/Grids/Grid";
import { BusinessType } from "../_types";

export interface BusinessTypeSelectionPageProps
{
    trackBusinessPickerDisplayed?: () => void;
    businessTypes?: BusinessType[];
    customHeaderContent?: React.ReactNode;
    handleClose?: () => void;
    handleNextClicked?: () => void;
    primaryCtaButtonLabel?: string;
    selectedBusinessType?: number;
    shouldHideClose?: boolean;
    isCreateBusinessRequestInFlight?: boolean;
}

export class BusinessTypeSelectionPage extends React.PureComponent<BusinessTypeSelectionPageProps>
{
    public componentDidMount()
    {
        if ( this.props.trackBusinessPickerDisplayed )
        {
            this.props.trackBusinessPickerDisplayed();
        }
    }

    public render()
    {
        const {
            businessTypes,
            customHeaderContent,
            primaryCtaButtonLabel,
            handleClose,
            handleNextClicked,
            shouldHideClose,
        } = this.props;
        const alphabetizeBusinessTypes = businessTypes.sort( ( a, b ) => (a.name > b.name) ? 1 : -1 );
        const primaryButtonLabel = primaryCtaButtonLabel || "Next";
        const headerContent = customHeaderContent || this.createDefaultHeaderContent();
        return (
            <div className="businessTypeSelectionPage">
                <div className="dialogContainer">
                    {!shouldHideClose && <Button className="closeX"
                                                 icon={true}
                                                 iconClassName="material-icons"
                                                 iconChildren="close"
                                                 onClick={handleClose}/>}
                    {headerContent}
                    <Grid className="bodyContainer">
                        {
                            alphabetizeBusinessTypes.map( ( businessType ) =>
                                {
                                    return <BusinessTypeBlockContainer businessType={businessType} key={businessType.id}/>;
                                },
                            )
                        }
                    </Grid>
                    <div className="buttonContainer">
                        <Button flat primary swapTheming className="dialogConfirm"
                                disabled={this.shouldDisableDialogConfirm()}
                                onClick={handleNextClicked}>{primaryButtonLabel}</Button>
                    </div>
                </div>
            </div>
        );
    }

    private shouldDisableDialogConfirm = () =>
    {
        return !this.props.selectedBusinessType || this.props.isCreateBusinessRequestInFlight;
    }

    private createDefaultHeaderContent = () =>
    {
        return (
            <div className="headerContainer">
                <p className="headerText1">Tell us about your business</p>
                <p className="headerText2">Pick a category for recommended templates.<br/>You can always change it later.</p>
            </div>
        );
    }
}
