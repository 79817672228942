import * as React from "react";
import Switch from "react-md/lib/SelectionControls/Switch";
import { UserBusiness, UserState } from "../../_types";

export interface ExtrasTogglesProps
{
    editedBusinessInfo: Partial<UserBusiness>;
    user: UserState;
}

export interface ExtrasTogglesDispatchProps
{
    handleUpdateUser( data: Partial<UserState> ): void;
    handleUpdateBusiness( data: Partial<UserBusiness> ): void;
}

export class ExtrasToggles extends React.PureComponent<ExtrasTogglesProps & ExtrasTogglesDispatchProps>
{
    public render()
    {
        return (
            <div className="extrasToggles">
                <h3 id="linkShorteningLabel">Turn on Link Shortening &amp; Tracking</h3>
                <Switch
                    id="linkShortening"
                    name="linkShorteningEnabled"
                    className="settingSwitch"
                    aria-labelledby="linkShorteningLabel"
                    checked={this.props.user.shorten_links_flag}
                    onChange={this.handleLinkShorteningToggleChange}
                />
                <div className="description">
                    Shorter, unique links will be created to save space in captions, as well as enable tracking so you can see how many clicks your
                    posts get.
                </div>
                <h3 id="hideExternalPostsSwitchLabel">Turn on All Posts View</h3>
                <Switch
                    id="hideExternalPosts"
                    name="hideExternalPostsEnabled"
                    className="settingSwitch"
                    aria-labelledby="hideExternalPostsSwitchLabel"
                    checked={!this.props.editedBusinessInfo.hide_external_posts_flag}
                    onChange={this.handleHideExternalPostsToggleChange}
                />
                <div className="description">
                    Every post—even those not created in Ripl—will be in one place and easy to see at a glance.
                </div>
                {this.createRiplLinkToggleSection()}
            </div>);
    }

    private createRiplLinkToggleSection()
    {
        return (
            <>
                <h3 id="linkSwitchLabel">Turn on Ripl.com Link</h3>
                <Switch
                    id="linkSwitch"
                    name="linkEnabled"
                    className="settingSwitch"
                    aria-labelledby="linkSwitchLabel"
                    checked={!this.props.user.disable_via_ripl_flag}
                    onChange={this.handleViaRiplToggleChange}/>
                <div className="description">
                    A #MadeWithRipl hashtag and a link to the site will be included in post captions. You can still remove this on a post-by-post
                    basis.
                </div>
            </>
        );
    }
    private handleLinkShorteningToggleChange = ( value: boolean ) =>
    {
        this.props.handleUpdateUser( { shorten_links_flag: value } );
    }

    private handleHideExternalPostsToggleChange = ( value: boolean ) =>
    {
        this.props.handleUpdateBusiness( { hide_external_posts_flag: !value } );
    }

    private handleViaRiplToggleChange = ( value: boolean ) =>
    {
        this.props.handleUpdateUser( { disable_via_ripl_flag: !value } );
    }
}
