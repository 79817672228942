import * as React from "react";
import { APPTIMIZE_INITIALIZATION_MAX_WAIT_MILLIS, apptimizeWrapper, ENABLE_APPTIMIZE, ENV_CONFIG, eventTracker } from "../helpers";
import { InspireBackgroundOverlay } from "./InspireBackgroundOverlay";
import { CircularProgressWithText } from "./CircularProgressWithText";
import { store } from "../store/index";

interface ApptimizeState
{
    isApptimizeSdkReady: boolean;
}

export default class ApptimizeWrapper extends React.Component<{}, ApptimizeState>
{
    private initializationTimeoutId: any;

    constructor( props )
    {
        super( props );
        this.state = { isApptimizeSdkReady: false };
    }

    public componentDidMount()
    {
        if ( ENABLE_APPTIMIZE )
        {
            apptimizeWrapper.setOnApptimizeInitializedCallback( this.onApptimizeInitialized );
            apptimizeWrapper.setup( ENV_CONFIG.apptimizeKey );

            this.initializationTimeoutId = setTimeout( this.onApptimizeInitialized, APPTIMIZE_INITIALIZATION_MAX_WAIT_MILLIS );
        }
    }

    public componentWillUnmount()
    {
        this.stopInitializationTimeoutTimer();
    }

    public render()
    {
        if ( ENABLE_APPTIMIZE && !this.state.isApptimizeSdkReady )
        {
            return (
                <div>
                    <CircularProgressWithText className="apptimizeWrapper" progressText="Loading..."/>
                    <InspireBackgroundOverlay/>
                </div>
            );
        }
        else
        {
            return this.props.children;
        }
    }

    private onApptimizeInitialized = () =>
    {
        this.stopInitializationTimeoutTimer();

        const state = store.getState();
        const userProperties = eventTracker.getPropertiesForUser( state );
        apptimizeWrapper.setCustomAttributes( userProperties );

        this.setState( {
            isApptimizeSdkReady: true,
        } );
    }

    private stopInitializationTimeoutTimer = () =>
    {
        if ( this.initializationTimeoutId )
        {
            clearTimeout( this.initializationTimeoutId );
            this.initializationTimeoutId = null;
        }
    }
}
