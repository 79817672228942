import { CrownIcon } from "./svgs/CrownIcon";
import classNames from "classnames";
import * as React from "react";

export interface TiersExplanationTabButtonProps
{
    name: string;
    isSelected: boolean;
    isPremium?: boolean;
}

export interface TiersExplanationTabButtonDispatchProps
{
    selectTab();
}

export function TiersExplanationTabButton( props: TiersExplanationTabButtonProps & TiersExplanationTabButtonDispatchProps )
{
    const { name, isSelected, isPremium, selectTab } = props;
    return <div className="tiersTabButton" onClick={selectTab}>
        <div className="buttonContents">
            <div className="buttonTitle">{name}</div>
            {isPremium && <CrownIcon/>}
        </div>
        <div className={classNames( "selectedBar", { isSelected } )}/>
    </div>;
}
