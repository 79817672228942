import * as React from "react";
import * as cautionIcon from "../assets/img/caution_clear.png";

export const CustomizeAlertBanner: React.FC<{}> = ( props ) =>
{
    return (
        <div className="alertFrame">
            <img className="icon" src={cautionIcon} alt="caution"></img>
            <div className="alertText">
                <span id="alert">One of your images is pixelated.</span> Choose a higher-definition image to get the best ad results.
            </div>
        </div>
    );
};
