import { ActionCreator } from "../helpers";
import { createAction } from "redux-actions";

const actionCreator = new ActionCreator( "CATALOG" );

const catalogActionsTypes = {
    load: actionCreator.generateTypes( "LOAD" ),
    designTray: {
        add: actionCreator.generateType( "DESIGN_TRAY", "ADD" ),
    },
};

export const catalogActions = {
    loadCatalogSuccess: createAction<NormalizrData>( catalogActionsTypes.load.success ),
    loadCatalogFailure: createAction<string>( catalogActionsTypes.load.failure ),
    addDesignToFrontOfTray: createAction<number>( catalogActionsTypes.designTray.add ),
};
