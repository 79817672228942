import * as React from "react";
import { NON_BREAKING_SPACE } from "../helpers";
import Button from "react-md/lib/Buttons/Button";
import { CancelWinBackConfirmDispatchContainerProps } from "./containers/CancelWinBackConfirm.container";

export interface CancelWinBackConfirmDispatchProps extends CancelWinBackConfirmDispatchContainerProps
{
    handleFAQ();
}

export class CancelWinBackConfirm extends React.PureComponent<CancelWinBackConfirmDispatchProps>
{

    public render()
    {
        return (<>
            <div className="manageSubscriptionsContainer">
                <div className="winBackOfferFrame manageSubscriptionBlock">
                    <h2 className="headline">Are you sure?</h2>

                    <div className="areYouReallySureYouWantToCancelText">
                        <div className="cancelBoldPrint">
                            The plan you are on now will no longer be available if you decide to come{NON_BREAKING_SPACE}back.
                        </div>
                        <div className="cancelFaqPrint">
                            Some of the features you now enjoy may not be available to{NON_BREAKING_SPACE}you.
                            For more information check out our <span className="standardLink"
                                                                  onClick={this.props.handleFAQ}>FAQ{NON_BREAKING_SPACE}article.</span>
                        </div>
                    </div>
                    <div className="buttonWrap">
                        <Button flat
                                className="cancelButton"
                                onClick={this.props.requestCancel}>Cancel subscription</Button>

                        <Button primary flat swapTheming
                                className="primaryButton"
                                onClick={this.props.requestKeepSubscription}>Keep my subscription</Button>
                    </div>
                </div>
            </div>
        </>);
    }
}
