import { includes } from "lodash";
import { Design } from "../_types";
import { VIDEO_TYPE } from "../_types/api";

export function doesDesignSupportVideo( design: Design ): boolean
{
    const designInputsSupported = design && design.inputs_supported;
    const designMaxVideoCountSupportedIsNonZero = design && design.max_video_count_supported > 0;
    return includes( designInputsSupported, VIDEO_TYPE ) && designMaxVideoCountSupportedIsNonZero;
}
