import { connect } from "react-redux";
import { Dispatch } from "redux";
import { DialogControlProps, StoreState } from "../../_types";
import { ChangeBusinessTypeDialog, ChangeBusinessTypeDialogDispatchProps } from "../ChangeBusinessTypeDialog";
import { getEditedBusinessInfo, isBusinessTypeChanged } from "../../ducks/ui";
import { userBusinessServices } from "../../services";
import { store } from "../../store";
import { BUSINESS_TYPE_SELECTED_SOURCE_UPDATE, eventTracker } from "../../helpers";
import { getCurrentBusiness } from "../../ducks/userBusiness";
import { userBusinessActions } from "../../actions";

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: DialogControlProps ): ChangeBusinessTypeDialogDispatchProps =>
{
    return {
        handleDone: () =>
        {
            const state = store.getState();
            const editedBusinessInfo = getEditedBusinessInfo( state );

            if ( !!getCurrentBusiness( state ) && isBusinessTypeChanged( state ) )
            {
                eventTracker.logBusinessTypeSwitchedEvent( state, editedBusinessInfo.business_type_id, BUSINESS_TYPE_SELECTED_SOURCE_UPDATE );
                dispatch( userBusinessActions.businessTypeChanged() );
                dispatch( userBusinessServices.update( editedBusinessInfo ) );
            }
            ownProps.closeDialog();
        },
    };
};

export default connect(
    () => ({}),
    mapDispatchToProps,
)( ChangeBusinessTypeDialog );
