import * as React from "react";
import classNames from "classnames";
import PremiumFocusedWidePriceBlock from "./containers/PremiumFocusedWidePriceBlock.container";
import { get, map } from "lodash";
import { RIPL_TIER_PREMIUM } from "../../helpers";
import { isAnnualPlan } from "../../ducks/pricing";
import SavingsInDollarsBadgeFromPlan from "../containers/SavingsInDollarsBadgeFromPlan.container";

export interface PremiumFocusedUpsellPriceBlockSectionProps
{
    shouldShowSimplifiedUpsell?: boolean;
    plans: PlanWithTier[];
    monthlyPremiumPlan: StripePlanData;
    selectedPlan: StripePlanData;
}

export interface PlanWithTier
{
    tier: ProductTier;
    plan: StripePlanData;
}

export interface PremiumFocusedUpsellPriceBlockSectionDispatchProps
{
    handlePlanSelected( StripePlanData, ProductTier );
}

export function PremiumFocusedUpsellPriceBlockSection( props: PremiumFocusedUpsellPriceBlockSectionProps &
    PremiumFocusedUpsellPriceBlockSectionDispatchProps )
{
    return <div className={classNames( "stackedPriceBlockSection" )}>
        {map( props.plans, ( plan ) =>
        {
            const shouldShowSavings = plan.tier === RIPL_TIER_PREMIUM && isAnnualPlan( plan.plan );
            const isSelected = plan.plan.id === get( props.selectedPlan, "id" );

            return (<PremiumFocusedWidePriceBlock plan={plan.plan}
                                                  key={plan.plan.id}
                                                  selected={isSelected}
                                                  updatePlanSelection={props.handlePlanSelected}
                                                  tier={plan.tier}>
                {shouldShowSavings && <SavingsInDollarsBadgeFromPlan plan={plan.plan}
                                                                     monthlyPlan={props.monthlyPremiumPlan}
                                                                     annualPlan={plan.plan}
                                                                     shouldRenderForWideFormat={true}
                                                                     shouldAlignToRight={!isSelected}
                />}
            </PremiumFocusedWidePriceBlock>);
        } )}
    </div>;
}
