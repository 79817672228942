import { StockMediaState, StoreMigration } from "../../_types";
import { STOCK_MEDIA_SEARCH_PHOTOS_TYPE } from "../../helpers";

export const stockMediaMigrations: StoreMigration<StockMediaState> = {
    0: ( state ) =>
    {
        const { stockMediaSearchType, ...remainingProps } = state;
        return {
            ...remainingProps,
            stockMediaSearchType: stockMediaSearchType || STOCK_MEDIA_SEARCH_PHOTOS_TYPE,
        };
    },
};
