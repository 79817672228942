import { Action } from "redux-actions";
import { designPreviewsSchema, DEVELOPMENT_STATUS, ReducerCreator, updateLookupWithAction } from "../helpers";
import { catalogActions } from "../actions";
import { filter, forEach, map, values } from "lodash";
import { createDefaultDataLookup } from "./dataLookup";
import { AspectRatio, DesignPreview, DesignPreviewState, StoreState } from "../_types";
import { getDesign } from "./designs";

const defaultState: DesignPreviewState = {
    ...createDefaultDataLookup(),
    designIdToPreviewIds: {},
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( catalogActions.loadCatalogSuccess, handleCatalogLoad );
export default reducerCreator.createReducer();

function handleCatalogLoad( state: DesignPreviewState, action: Action<NormalizrData> ): DesignPreviewState
{
    const newState = updateLookupWithAction( designPreviewsSchema, action, state );
    const designIdToPreviewIds = {};
    forEach( values( newState.idToObj ), ( designPreview ) =>
    {
        const data = designIdToPreviewIds[designPreview.design_id] || [];
        data.push( designPreview.id );
        designIdToPreviewIds[designPreview.design_id] = data;
    } );
    newState.designIdToPreviewIds = designIdToPreviewIds;
    return newState;
}

export function getDesignPreviewsForDesignAndAspectRatio( state: StoreState, designId: number, aspectRatio?: AspectRatio ): DesignPreview[]
{
    const designPreviewIds = filter( state.designPreviews.designIdToPreviewIds[designId] );
    let designPreviews = filter( map( designPreviewIds, ( id ) => state.designPreviews.idToObj[id] ) );
    if ( aspectRatio )
    {
        designPreviews = filter( designPreviews, ( designPreview ) => designPreview.aspect_ratio === aspectRatio );
    }
    designPreviews = filter( designPreviews,
        ( designPreview ) => designPreview.examplePostPhotoUrl || getDesign( state, designPreview.design_id ).status === DEVELOPMENT_STATUS );
    return designPreviews;
}
