import * as React from "react";
import classNames from "classnames";

export interface ColorSwatchProps
{
    swatchHex: string;
    currentColorHex?: string;
}

export interface ColorSwatchDispatchProps
{
    onColorTapped?( hex: string );
}

export class ColorSwatch extends React.PureComponent<ColorSwatchProps & ColorSwatchDispatchProps>
{
    public render()
    {
        return (<div className={classNames( "colorSwatch", { selected: this.props.currentColorHex === this.props.swatchHex } )}
                     style={{ backgroundColor: this.props.swatchHex }}
                     onClick={this.onColorTapped}/>);
    }

    private onColorTapped = () =>
    {
        if ( this.props.onColorTapped )
        {
            this.props.onColorTapped( this.props.swatchHex );
        }
    }
}
