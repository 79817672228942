import { StoreState } from "../../../_types";
import { getCurrentBusiness } from "../../../ducks/userBusiness";
import { InspirationHeader, InspirationHeaderProps } from "../InspirationHeader";
import { connect } from "react-redux";
import { getBusinessTypeFilter } from "../../../ducks/ui";

const mapStateToProps = ( storeState: StoreState ): InspirationHeaderProps =>
{
    return {
        currentBusiness: getCurrentBusiness( storeState ),
        businessFilter: getBusinessTypeFilter( storeState ),
    };
};

export default connect(
    mapStateToProps,
)( InspirationHeader );
