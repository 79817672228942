import { connect } from "react-redux";
import { StoreState } from "../../../_types";
import {
    BRAND_CREATIVE_PAGE_URL,
    BRAND_SETTINGS_PAGE_URL,
    BRAND_USERS_PAGE_URL,
    browserUtils,
    history,
    HOMEPAGE_MY_BRAND_URL,
    SUBSCRIPTION_FLOW_SOURCE_TEAMS_TAB_CLICKED,
} from "../../../helpers";
import CreativeTabContainer from "./CreativeTab.container";
import { PageWithTabbedSubNav, PageWithTabbedSubNavProps } from "../PageWithTabbedSubNav";
import SettingsTabContainer from "./SettingsTab.container";
import UsersTabContainer from "../UsersTab/containers/UsersTab.container";
import { PageTabItem } from "../PageTabs";
import {
    isCollabraUser,
    isCurrentProductTierLegacy,
    isCurrentProductTierPremium,
    isUserPersonallySubscribed,
    isUserStripeSubscribed,
    shouldBeUpsoldToPremium,
} from "../../../ducks/user";
import {
    hasTeamAdminPermissionsForCurrentBusiness,
    hasTeamInvitesForCurrentBusiness,
    hasTeamMemberPermissionsForCurrentBusiness,
    hasTeamMembersForCurrentBusiness,
} from "../../../ducks/userBusiness";
import * as Crown from "../../../assets/img/crown-icon.svg";
import * as React from "react";
import { handleUpTierForNonStripeSubscriber, upsellServices } from "../../../services";
import { store } from "../../../store";
import { chain } from "lodash";

const additionalContainerClass = "myBrandPage";

const brandCreativesTab: PageTabItem = {
    path: BRAND_CREATIVE_PAGE_URL,
    component: CreativeTabContainer,
    name: "Creative",
    onClick: () =>
    {
        history.push( BRAND_CREATIVE_PAGE_URL );
    },
};

const brandSettingsTab: PageTabItem = {
    path: BRAND_SETTINGS_PAGE_URL,
    component: SettingsTabContainer,
    name: "Settings",
    onClick: () =>
    {
        history.push( BRAND_SETTINGS_PAGE_URL );
    },
};

const teamSettingsTab: PageTabItem = {
    path: BRAND_USERS_PAGE_URL,
    component: UsersTabContainer,
    name: "Team",
    onClick: () =>
    {
        history.push( BRAND_USERS_PAGE_URL );
    },
};

const fakeTeamSettingsTabForUpsell: PageTabItem = {
    ...brandCreativesTab,
    name: (
        <div className="fakeTeamTab">
            Team <img className="crownIcon" src={Crown} alt="Crown Icon"/>
        </div>
    ),
    path: BRAND_USERS_PAGE_URL,
    onClick: () =>
    {
        if ( !isUserPersonallySubscribed( store.getState() ) )
        {
            upsellToPremiumOnTeamsTab();
        }
        else if ( isUserStripeSubscribed( store.getState() ) )
        {
            upsellToPremiumOnTeamsTab();
        }
        else
        {
            handleUpTierForNonStripeSubscriber( store.dispatch, SUBSCRIPTION_FLOW_SOURCE_TEAMS_TAB_CLICKED );
        }
    },
};

function upsellToPremiumOnTeamsTab()
{
    brandCreativesTab.onClick();
    store.dispatch( upsellServices.displayGradientHeaderPremiumBenefitsDialog(
        SUBSCRIPTION_FLOW_SOURCE_TEAMS_TAB_CLICKED,
        "Invite team members with Ripl Premium!",
    ) );
}

function getTabItems( storeState: StoreState ): PageTabItem[]
{
    const isPremium = isCurrentProductTierPremium( storeState );
    const isLegacy = isCurrentProductTierLegacy( storeState );
    const userCanOwnTeam = isPremium || isLegacy;
    const upsellToPremium = shouldBeUpsoldToPremium( storeState );
    const isTeamAdmin = hasTeamAdminPermissionsForCurrentBusiness( storeState );
    const isTeamMember = hasTeamMemberPermissionsForCurrentBusiness( storeState );
    const hasTeamMembersOrInvites = hasTeamMembersForCurrentBusiness( storeState ) || hasTeamInvitesForCurrentBusiness( storeState );
    const shouldShowCollabraUI = isCollabraUser( storeState );
    const isMobileBrowser = browserUtils.isMobileBrowser();

    const tabs: Array<{ tab: PageTabItem, shouldShow: boolean }> = [
        {
            tab: teamSettingsTab,
            shouldShow: (userCanOwnTeam || isTeamMember || (hasTeamMembersOrInvites && userCanOwnTeam)) && !shouldShowCollabraUI && !isMobileBrowser,
        },
        {
            tab: fakeTeamSettingsTabForUpsell,
            shouldShow: upsellToPremium && isTeamAdmin && !shouldShowCollabraUI && !isMobileBrowser,
        },
        {
            tab: brandCreativesTab,
            shouldShow: !isMobileBrowser,
        },
        {
            tab: brandSettingsTab,
            shouldShow: isTeamAdmin && !shouldShowCollabraUI,
        },
    ];

    return chain( tabs )
        .filter( ( tabFilter ) => tabFilter.shouldShow )
        .map( ( tabFilter ) => tabFilter.tab )
        .value();
}

const mapStateToProps = ( storeState: StoreState ): PageWithTabbedSubNavProps =>
{
    return {
        tabItems: getTabItems( storeState ),
        additionalContainerClass,
        path: window.location.pathname,
        pageRootPath: HOMEPAGE_MY_BRAND_URL,
        shouldShowCollabraUI: isCollabraUser( storeState ),
    };
};

export default connect(
    mapStateToProps,
)( PageWithTabbedSubNav );
