import { Action } from "redux-actions";
import { EpidemicSoundSource, ReducerCreator } from "../helpers";
import { ApptimizeCacheEntry, trackingActions } from "../actions";
import { ApptimizeCache, ChannelTrackingParameters, StoreState, TrackingState } from "../_types";
import { uniq } from "lodash";

const defaultApptimizeCache: ApptimizeCache = {
    featureFlags: {},
    dynamicVariables: {},
    participatingExperimentAndVariantNames: [],
};

const defaultState: TrackingState = {
    postSessionId: null,
    subscriptionFlowSource: null,
    apptimize: defaultApptimizeCache,
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( trackingActions.postSessionStarted, handlePostSessionStarted );
reducerCreator.addAction( trackingActions.postSessionEnded, handlePostSessionEnded );
reducerCreator.addAction( trackingActions.subscriptionFlowStarted, handleSubscriptionFlowStarted );
reducerCreator.addAction( trackingActions.subscriptionFlowEnded, handleSubscriptionFlowEnded );
reducerCreator.addAction( trackingActions.cacheApptimizeFeatureFlag, handleCacheApptimizeFeatureFlag );
reducerCreator.addAction( trackingActions.cacheApptimizeDynamicVariable, handleCacheApptimizeDynamicVariable );
reducerCreator.addAction( trackingActions.cacheApptimizeParticipatingExperimentAndVariantName,
    handleCacheApptimizeParticipatingExperimentAndVariantName );
reducerCreator.addAction( trackingActions.clearFeatureFlagAndDynamicVariableCache, handleClearFeatureFlagAndDynamicVariableCache );
reducerCreator.addAction( trackingActions.updateChannelTrackingParameters, handleUpdateChannelTrackingParameters );
reducerCreator.addAction( trackingActions.countryCodeRetrieved, handleCountryCodeRetrieved );
reducerCreator.addAction( trackingActions.epidemicSoundSourceSet, handleEpidemicSoundSourceSet );
export default reducerCreator.createReducer();

function handlePostSessionStarted( state: TrackingState, action: Action<string> ): TrackingState
{
    return {
        ...state,
        postSessionId: action.payload,
    };
}

function handlePostSessionEnded( state: TrackingState ): TrackingState
{
    return {
        ...state,
        postSessionId: null,
        epidemicSoundSource: null,
    };
}

function handleSubscriptionFlowStarted( state: TrackingState, action: Action<string> ): TrackingState
{
    return {
        ...state,
        subscriptionFlowSource: action.payload,
    };
}

function handleSubscriptionFlowEnded( state: TrackingState ): TrackingState
{
    return {
        ...state,
        subscriptionFlowSource: null,
    };
}

function handleCacheApptimizeFeatureFlag( state: TrackingState, action: Action<ApptimizeCacheEntry> ): TrackingState
{
    const apptimize = state.apptimize || defaultApptimizeCache;
    const updatedFeatureFlags = {
        ...apptimize.featureFlags,
    };
    updatedFeatureFlags[action.payload.name] = action.payload.value;

    return {
        ...state,
        apptimize: {
            ...apptimize,
            featureFlags: updatedFeatureFlags,
        },
    };
}

function handleCacheApptimizeDynamicVariable( state: TrackingState, action: Action<ApptimizeCacheEntry> ): TrackingState
{
    const apptimize = state.apptimize || defaultApptimizeCache;
    const updatedDynamicVariables = {
        ...apptimize.dynamicVariables,
    };
    updatedDynamicVariables[action.payload.name] = action.payload.value;

    return {
        ...state,
        apptimize: {
            ...apptimize,
            dynamicVariables: updatedDynamicVariables,
        },
    };
}

function handleCacheApptimizeParticipatingExperimentAndVariantName( state: TrackingState, action: Action<string> ): TrackingState
{
    const apptimize = state.apptimize || defaultApptimizeCache;
    const updatedParticipatingExperimentAndVariantNames = [
        ...apptimize.participatingExperimentAndVariantNames,
        action.payload,
    ];

    return {
        ...state,
        apptimize: {
            ...apptimize,
            participatingExperimentAndVariantNames: uniq( updatedParticipatingExperimentAndVariantNames ),
        },
    };
}

function handleClearFeatureFlagAndDynamicVariableCache( state: TrackingState ): TrackingState
{
    const apptimize = state.apptimize || defaultApptimizeCache;
    return {
        ...state,
        apptimize: {
            ...apptimize,
            featureFlags: {},
            dynamicVariables: {},
        },
    };
}

function handleUpdateChannelTrackingParameters( state: TrackingState, action: Action<ChannelTrackingParameters> ): TrackingState
{
    return {
        ...state,
        channelTrackingParameters: {
            ...action.payload,
        },
    };
}

function handleCountryCodeRetrieved( state: TrackingState, action: Action<string> ): TrackingState
{
    return {
        ...state,
        countryCode: action.payload,
    };
}

function handleEpidemicSoundSourceSet( state: TrackingState, action: Action<EpidemicSoundSource> ): TrackingState
{
    return {
        ...state,
        epidemicSoundSource: action.payload,
    };
}

export const getSubscriptionFlowSource = ( state: StoreState ) => state.tracking.subscriptionFlowSource;
export const getSessionIdForPostCustomization = ( state: StoreState ) => state.tracking.postSessionId;
export const getApptimizeCache = ( state: StoreState ) => state.tracking.apptimize || defaultApptimizeCache;
export const getApptimizeFeatureFlagsCache = ( state: StoreState ) => getApptimizeCache( state ).featureFlags;
export const getCachedApptimizeFeatureFlag = ( state: StoreState, name: string ) => getApptimizeFeatureFlagsCache( state )[name];
export const getApptimizeDynamicVariablesCache = ( state: StoreState ) => getApptimizeCache( state ).dynamicVariables;
export const getCachedApptimizeDynamicVariable = ( state: StoreState, name: string ) => getApptimizeDynamicVariablesCache( state )[name];
export const getApptimizeParticipatingExperimentAndVariantNamesCache =
    ( state: StoreState ) => getApptimizeCache( state ).participatingExperimentAndVariantNames;
export const getChannelTrackingParameters = ( state: StoreState ) => state.tracking.channelTrackingParameters;
export const getCountryCode = ( state: StoreState ) => state.tracking.countryCode;
export const getEpidemicSoundSource = ( state: StoreState ) => state.tracking.epidemicSoundSource;
