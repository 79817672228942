import { CoachMarkContentFlow, CoachMarkNameKey, CoachMarkPosition, CoachMarks } from "../_types";
import * as React from "react";
import { CoachMark } from "./CoachMark";
import { COACH_MARK_NAME_KEY_REFER_A_FRIEND_BUTTON } from "../_types/api";

export interface HomePageCoachMarksProps
{
    coachMarksVisible: boolean;
    coachMarks: CoachMarks;
}

export interface HomePageCoachMarksDispatchProps
{
    showNextCoachMark: () => void;
    clearCoachMark: ( coachMarkName: CoachMarkNameKey ) => void;
}

const COACH_MARK_REFER_A_FRIEND_SELECTOR = ".giftboxIcon";

export class HomePageCoachMarks extends React.PureComponent<HomePageCoachMarksProps & HomePageCoachMarksDispatchProps>
{
    public componentDidMount()
    {
        this.props.showNextCoachMark();
    }

    public render()
    {
        return (<>
            {
                this.props.coachMarks.referAFriendButton.isVisible &&
                <CoachMark
                    key={COACH_MARK_NAME_KEY_REFER_A_FRIEND_BUTTON}
                    name={COACH_MARK_NAME_KEY_REFER_A_FRIEND_BUTTON}
                    title="Know someone who might love Ripl?"
                    message="Help them step up their marketing game by sending them an exclusive deal."
                    selector={COACH_MARK_REFER_A_FRIEND_SELECTOR}
                    position={CoachMarkPosition.BELOW}
                    zIndex={3}
                    fixed={true}
                    alignBubble={CoachMarkContentFlow.LEFT}
                    onDismiss={this.props.clearCoachMark}/>
            }
        </>);
    }
}
