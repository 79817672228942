import { MixModelState, StoreMigration } from "../../_types";
import { filter, head, map } from "lodash";
import { MP4, stringUtils, urlUtils } from "../../helpers";

export const mixModelMigrations: StoreMigration<MixModelState> = {
    6: ( state ) =>
    {
        const { originalTrimmerData, videoTrimmerDataMap, ...remainingState } = state;

        const mediaList = state.commonInputData.originalImageList || [];
        const videoMediaIds = filter( map( mediaList, ( media ) =>
        {
            if ( stringUtils.getFileExt( media.url ) === MP4 )
            {
                return urlUtils.getFilenameFromUrlString( media.url );
            }
        } ) );
        const mediaId = head( videoMediaIds );

        const newVideoTrimmerDataMap = {};
        if ( mediaId )
        {
            newVideoTrimmerDataMap[mediaId] = originalTrimmerData;
        }

        return {
            ...remainingState,
            videoTrimmerDataMap: newVideoTrimmerDataMap,
        };
    },
};
