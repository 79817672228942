import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ModalDialogs, ModalDialogsDispatchProps, ModalDialogsProps } from "../ModalDialogs";
import { modalActions } from "../../actions";
import { getDialogData, getTopMostLightbox } from "../../ducks/modals";
import { StoreState } from "../../_types";

const mapStateToProps = ( storeState: StoreState ): ModalDialogsProps =>
{
    return {
        ...getDialogData( storeState ),
        topMostLightbox: getTopMostLightbox( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): ModalDialogsDispatchProps =>
{
    return {
        handleCloseLightboxModal: ( modalIdx: number ) =>
        {
            dispatch( modalActions.lightboxDialogClosed( modalIdx ) );
        },
        handleCloseErrorModal: () =>
        {
            dispatch( modalActions.errorDialogClose() );
        },
        handleCloseLowResErrorModal: () =>
        {
            dispatch( modalActions.lowResErrorDialogClose() );
        },
        handleCloseAlertModal: () =>
        {
            dispatch( modalActions.alertDialogClose() );
        },
        handleCloseFullpageModal: ( modalIdx: number ) =>
        {
            dispatch( modalActions.fullpageDialogClose( modalIdx ) );
        },
        handleCloseLoadingDialog: () =>
        {
            dispatch( modalActions.loadingDialogClose() );
        },
        handleCloseAllFullpageModal: () =>
        {
            dispatch( modalActions.fullpageDialogCloseAll() );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( ModalDialogs );
