import { SlideTransitionChoice } from "../../_types";
import { chain } from "lodash";
import { TEXT_STYLE_STATUS_DEVELOPMENT } from "../../_types/api";
import classNames from "classnames";
import * as React from "react";

interface SlideTransitionChoiceButtonProps
{
    choice: SlideTransitionChoice;
    selected: boolean;
}

interface SlideTransitionChoiceButtonDispatchProps
{
    onPreferredSlideTransitionSelected: ( choice: SlideTransitionChoice ) => void;
}

export function SlideTransitionChoiceButton( props: SlideTransitionChoiceButtonProps & SlideTransitionChoiceButtonDispatchProps )
{
    const { choice, selected } = props;

    const formattedDisplayName = chain( choice.id )
        .replace( /-/gmi, " " )
        .capitalize()
        .value();

    const onClick = () =>
    {
        props.onPreferredSlideTransitionSelected( choice );
    };

    const devMode = choice.status === TEXT_STYLE_STATUS_DEVELOPMENT;

    return <div className={classNames( "item", { selected, devMode } )} onClick={onClick}>
        <div className="description">{formattedDisplayName}</div>
    </div>;
}
