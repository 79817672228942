import { connect } from "react-redux";
import { ErrorDialogContents, StoreState } from "../../_types";
import { ErrorDialog, ErrorDialogProps } from "../ErrorDialog";
import { isCollabraUser } from "../../ducks/user";

const mapStateToProps = ( storeState: StoreState, ownProps: ErrorDialogContents ): ErrorDialogProps =>
{
    return {
        ...ownProps,
        shouldShowCollabraUI: isCollabraUser( storeState ),
    };
};

export default connect(
    mapStateToProps,
)( ErrorDialog );
