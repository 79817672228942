import * as React from "react";
import { CSSProperties } from "react";
import { Flag, FlagTypeConfig } from "./Flag";
import { isTouchDevice, stringUtils } from "../helpers";
import classNames from "classnames";
import * as DraftImage from "../assets/img/draft_circle_overlay.png";

export interface VideoElementContainerProps
{
    imageUrl: string;
    posterUrl?: string;
    videoUrl?: string;
    muted: boolean;
    aspectRatio?: number;
    optionClass?: string;
    contentBelow?: boolean;
    flagTypeConfig?: FlagTypeConfig;
    autoplay?: boolean;
    loop?: boolean;
    controls?: boolean;
    playOnMouseOver?: boolean;
    isExternalPost?: boolean;
    playOnClick?: boolean;
}

interface VideoDataDispatchProps
{
    onClick?( e: React.MouseEvent<HTMLElement> ): void;
    onVideoStarted?(): void;
    onVideoStopped?(): void;
}

export class VideoElement extends React.PureComponent<VideoElementContainerProps & VideoDataDispatchProps>
{
    private playPromise;
    private pausePromise;

    public componentWillUnmount()
    {
        this.stopVideo();
        this.clearVideoSource();
    }

    public render()
    {
        const { aspectRatio, contentBelow, isExternalPost, flagTypeConfig, controls, onClick, posterUrl } = this.props;
        const videoUrl = stringUtils.getCDNUrl( this.props.videoUrl );
        const imageUrl = stringUtils.getCDNUrl( this.props.imageUrl );
        const posterSrc = posterUrl || imageUrl || DraftImage;
        const showControls = controls || isTouchDevice();
        const flagAspectRatio = this.getFlagAspectRatio( flagTypeConfig );
        const aspectRatioStyle = !isExternalPost ? { "--aspect-ratio": (aspectRatio || 1).toString() } : null;
        return (
            <div className={classNames( "videoElement",
                { contentBelow, clickable: !!onClick, externalPostElement: isExternalPost, draft: !imageUrl } )}
                 onClick={onClick}
                 style={aspectRatioStyle}>
                {
                    videoUrl ? this.createVideo( videoUrl, posterSrc, showControls )
                             : <img src={posterSrc}/>
                }
                {
                    flagTypeConfig && <Flag flagTypeConfig={flagTypeConfig} inlineStyle={flagAspectRatio} optionalClass="onDesign"/>
                }
            </div>
        );
    }

    private createVideo = ( videoUrl: string, posterSrc, showControls: boolean ) =>
    {
        const { muted, autoplay, optionClass, loop } = this.props;

        // Fix issue in chrome where poster needs to be present if controls on and bg-style needs to be used when controls off to prevent skewing
        const styleProps = showControls ? null : {
            backgroundImage: `url(${posterSrc}`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
        };
        const posterProp = showControls ? posterSrc : null;
        const preloadProp = showControls ? "auto" : "none";

        return (
            <video
                className={optionClass}
                ref="videoRef"
                src={videoUrl}
                muted={muted}
                autoPlay={autoplay}
                preload={preloadProp}
                loop={loop}
                style={styleProps}
                poster={posterProp}
                controls={showControls}
                onClick={this.onVideoClicked}
                onEnded={this.onVideoEnded}
                onPlay={this.handleOnPlay}
                controlsList="nodownload"
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}>
                Sorry, your browser doesn't support videos.
            </video>
        );
    }

    private getFlagAspectRatio( flagTypeConfig ): CSSProperties
    {
        if ( flagTypeConfig )
        {
            return { "--aspect-ratio": 2.6 };
        }
        return null;
    }

    private onMouseEnter = () =>
    {
        if ( this.props.playOnMouseOver )
        {
            this.playVideo();
        }
    }

    private onVideoClicked = () =>
    {
        if ( this.props.playOnClick )
        {
            this.playVideo();
        }
    }

    private onMouseLeave = () =>
    {
        if ( this.props.playOnMouseOver )
        {
            this.stopVideo();
        }
    }

    private playVideo = () =>
    {
        const { videoRef } = this.refs;
        const htmlVideo = videoRef as HTMLVideoElement;
        htmlVideo.currentTime = 0;
        if ( this.pausePromise )
        {
            this.playPromise = this.pausePromise.then( () => htmlVideo && htmlVideo.play() );
            this.pausePromise = null;
        }
        else
        {
            this.playPromise = htmlVideo.play();
        }
    }

    private handleOnPlay = () =>
    {
        if ( this.props.onVideoStarted != null )
        {
            this.props.onVideoStarted();
        }
    }

    private onVideoEnded = () =>
    {
        if ( this.props.onVideoStopped != null )
        {
            this.props.onVideoStopped();
        }
    }

    private stopVideo = () =>
    {
        const { videoRef } = this.refs;
        const htmlVideo = videoRef as HTMLVideoElement;
        if ( htmlVideo )
        {
            htmlVideo.currentTime = 0;
            if ( this.playPromise )
            {
                this.pausePromise = this.playPromise.then( () => htmlVideo && htmlVideo.pause() );
            }
            this.playPromise = null;
        }
    }

    private clearVideoSource = () =>
    {
        const { videoRef } = this.refs;
        if ( videoRef )
        {
            const htmlVideo = videoRef as HTMLVideoElement;
            htmlVideo.src = "";
        }
    }
}
