import * as React from "react";
import { CardElement } from "react-stripe-elements";
import Button from "react-md/lib/Buttons/Button";
import ReCAPTCHA from "react-google-recaptcha";
import CircularProgress from "react-md/lib/Progress/CircularProgress";
import { ReCaptchaFinePrint } from "./ReCaptchaFinePrint";
import { ENV_CONFIG } from "../helpers";

const STRIPE_CARD_ELEMENT_ID = "stripeCardElement";

export interface CardFormDispatchProps
{
    clearError(): void;
    onChange( reCaptchaToken: string ): void;
}

export interface CardFormProps
{
    disabled: boolean;
    buttonText: string;
    finePrint?: JSX.Element;
}

export class CardForm extends React.PureComponent<CardFormProps & CardFormDispatchProps>
{
    public render()
    {
        return <>
            <div className="cardElement">
                <CardElement id={STRIPE_CARD_ELEMENT_ID}/>
            </div>
            <div className="buttonContainer">
                <Button flat swapTheming primary disabled={this.props.disabled} className="large fullWidth primaryButton"
                        onClick={this.handleOnClick}>
                    {this.props.disabled &&
                     <CircularProgress className="spinner" id="spinner"/>
                    }
                    {this.props.buttonText}
                </Button>
                {this.props.finePrint}
                <ReCaptchaFinePrint/>
            </div>
            <ReCAPTCHA
                ref="reCaptchaRef"
                size="invisible"
                sitekey={ENV_CONFIG.reCaptchaKey}
                onChange={this.props.onChange}
            />
        </>;
    }

    private handleOnClick = () =>
    {
        this.props.clearError();
        const { reCaptchaRef } = this.refs;
        if ( this.isCardElementValid() && reCaptchaRef )
        {
            (reCaptchaRef as any).execute();
        }
    }

    private isCardElementValid = () =>
    {
        const theStripeCardElement = document.getElementById( STRIPE_CARD_ELEMENT_ID ) as HTMLElement;
        const theElementClassName = theStripeCardElement.className;
        if ( theElementClassName.indexOf( "StripeElement--invalid" ) >= 0
             || theElementClassName.indexOf( "StripeElement--empty" ) >= 0 )
        {
            return false;
        }
        return true;
    }
}
