import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getPremiumEpidemicSoundTrackPreview } from "../ducks/ui";
import { StoreState } from "../_types";
import { ListPicker } from "./ListPicker";
import EpidemicSoundTrackItemPreviewContainer from "./editorControls/musicPicker/containers/EpidemicSoundTrackItemPreview.container";
import withMusicPlayer, { WithMusicPlayerProps } from "./higherOrderComponents/WithMusicPlayer";

const PremiumTrackPreview = ( props: WithMusicPlayerProps ) =>
{
    const epidemicSoundTrackPreview = useSelector( ( state: StoreState ) => getPremiumEpidemicSoundTrackPreview( state ) );
    useEffect( () =>
    {
        props.stopPlayingMusic();
    }, [] );

    return (
        <div className="premiumTrackPreview">
            <ListPicker>
                <EpidemicSoundTrackItemPreviewContainer key={epidemicSoundTrackPreview.epidemic_id}
                                                        epidemicSoundTrack={epidemicSoundTrackPreview}
                                                        handleMusicClick={props.togglePlayingMusic}/>
            </ListPicker>
        </div>
    );
};
export default withMusicPlayer( PremiumTrackPreview );
