import { connect } from "react-redux";
import { Dispatch } from "redux";
import {  modalServices, subscriptionServices } from "../../services";
import { ReactStripeElements } from "react-stripe-elements";
import { PaymentFormError, StoreState } from "../../_types";
import UpdateCardForm, { UpdateCardFormDispatchProps, UpdateCardFormProps } from "../UpdateCardForm";
import { store } from "../../store";
import { uiActions } from "../../actions";
import { isCardProcessing } from "../../ducks/ui";
import { eventTracker, UpdateCardFormSource } from "../../helpers";
import { isUserStripeSubscribed } from "../../ducks/user";
import StripeProps = ReactStripeElements.StripeProps;

export interface UpdateCardFormContainerProps
{
    stripeCustomerId: string;
    source: UpdateCardFormSource;
}

const mapStateToProps = ( storeState: StoreState, ownProps: UpdateCardFormContainerProps ): UpdateCardFormProps =>
{
    return {
        ...ownProps,
        isCardProcessing: isCardProcessing( storeState ),
        isStripeSubscriber: isUserStripeSubscribed( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: UpdateCardFormContainerProps ): UpdateCardFormDispatchProps =>
{
    return {
        onUpdateCardClicked: ( stripeCustomerId: string, stripe: StripeProps, reCaptchaToken: string ) =>
        {
            dispatch( uiActions.cardProcessingSpinnerEnabled( true ) );
            return stripe.createToken().then( async ( { token, error } ) =>
                {
                    if ( error || !token )
                    {
                        const paymentFormError: PaymentFormError = {
                            message: error.message,
                            showContactSupportLink: false,
                        };

                        dispatch( uiActions.paymentFormErrorSet( paymentFormError ) );
                        dispatch( uiActions.cardProcessingSpinnerEnabled( false ) );
                        return;
                    }

                    try
                    {
                        await dispatch( subscriptionServices.updateCard( stripeCustomerId, token.id, reCaptchaToken ) );
                        await dispatch( subscriptionServices.getCardInfo() );
                        eventTracker.logPaymentMethodUpdated( ownProps.source );
                    }
                    catch (error)
                    {
                        const paymentFormError: PaymentFormError = {
                            message: "Something went wrong. Please check your card details and try again.",
                            showContactSupportLink: true,
                        };

                        dispatch( uiActions.paymentFormErrorSet( paymentFormError ) );
                        dispatch( uiActions.cardProcessingSpinnerEnabled( false ) );
                        return;
                    }

                    const state = store.getState();
                    modalServices.closeTopLightBox( dispatch, state );
                    dispatch( uiActions.cardProcessingSpinnerEnabled( false ) );
                    dispatch( uiActions.paymentMethodErrorSet( null ) );
                },
                () =>
                {
                    eventTracker.logPaymentFormStripeTokenFailed();
                    const paymentFormError: PaymentFormError = {
                        message: "Unable to process your card. Please try again",
                        showContactSupportLink: true,
                    };

                    dispatch( uiActions.paymentFormErrorSet( paymentFormError ) );
                    dispatch( uiActions.cardProcessingSpinnerEnabled( false ) );
                } );
        },
        clearError: () =>
        {
            dispatch( uiActions.paymentFormErrorClear() );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( UpdateCardForm );
