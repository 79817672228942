import * as React from "react";
import { EpidemicSoundTrack } from "../../../_types/api";
import { DetailListItem } from "../DetailListItem";
import * as playOverlayIcon from "../../../assets/img/playOverlayIcon.svg";
import * as pauseOverlayIcon from "../../../assets/img/pauseOverlayIcon.svg";

export interface EpidemicSoundTrackItemProps
{
    isSelected: boolean;
    isPlaying: boolean;
    isLoading: boolean;
    shouldBeUpsoldToPremium: boolean;
    epidemicSoundTrack: EpidemicSoundTrack;
}

export interface EpidemicSoundTrackItemDispatchProps
{
    handleEpidemicSoundTrackClick( selectedMusic: EpidemicSoundTrack );
}

export class EpidemicSoundTrackItem extends React.PureComponent<EpidemicSoundTrackItemProps & EpidemicSoundTrackItemDispatchProps>
{
    public render()
    {
        const { epidemicSoundTrack, isSelected, isLoading, isPlaying } = this.props;
        const description = `${epidemicSoundTrack.artist_name} | ${epidemicSoundTrack.genre}`;
        const iconOverlay = <img alt="play status" src={isPlaying ? pauseOverlayIcon : playOverlayIcon}/>;

        return <DetailListItem
            key={epidemicSoundTrack.epidemic_id}
            title={epidemicSoundTrack.display_name}
            description={description}
            selected={isSelected}
            loading={isLoading}
            iconSrc={epidemicSoundTrack.image}
            iconOverlay={iconOverlay}
            onClick={this.handleEpidemicSoundTrackClick}
            outlineImage={true}
            showCrown={this.shouldBeUpsoldToPremium()}
        />;
    }

    private shouldBeUpsoldToPremium = () =>
    {
        return this.props.epidemicSoundTrack.is_premium && this.props.shouldBeUpsoldToPremium;
    }
    private handleEpidemicSoundTrackClick = () =>
    {
        this.props.handleEpidemicSoundTrackClick( this.props.epidemicSoundTrack );
    }
}
