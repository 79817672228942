export const RIPL_TIER_LEGACY = "LEGACY" as ProductTier;
export const RIPL_TIER_BASE = "BASE" as ProductTier;
export const RIPL_TIER_PREMIUM = "PREMIUM" as ProductTier;
export const RIPL_TIER_NONE = "NONE" as ProductTier;

export const RIPL_TIERS_TO_UPSELL: ProductTier[] = [RIPL_TIER_BASE, RIPL_TIER_NONE];

export interface TierFeatureList<T>
{
    templates: T;
    stock_images: T;
    brand_settings: T;
    multiple_businesses: T;
    schedule_posts: T;
    premium_customer_support: T;
}

export const TIER_SUPPORTED_FEATURES_DESCRIPTIONS: TierFeatureList<string> = {
    templates: "Thousands of templates",
    stock_images: "500,000+ stock images & videos",
    brand_settings: "Configure & save brand settings",
    multiple_businesses: "Manage multiple businesses",
    schedule_posts: "Schedule social posts in advance",
    premium_customer_support: "Premium customer support",
};

export const TIER_PREMIUM_SUPPORTED_FEATURES: TierFeatureList<boolean> = {
    templates: true,
    stock_images: true,
    brand_settings: true,
    multiple_businesses: true,
    schedule_posts: true,
    premium_customer_support: true,
};

export const TIER_BASE_SUPPORTED_FEATURES: TierFeatureList<boolean> = {
    templates: true,
    stock_images: true,
    brand_settings: true,
    multiple_businesses: false,
    schedule_posts: false,
    premium_customer_support: false,
};

export interface TierTabData
{
    tier: ProductTier;
    tierName: string;
    priceFormatted: string;
    commissionRateFormatted: string;
    features: TierFeatureList<boolean>;
}
