import * as React from "react";
import { map } from "lodash";
import { AspectRatio, Design } from "../_types";
import classNames from "classnames";
import { ASPECT_RATIO_VALUE, DEVELOPMENT_STATUS } from "../helpers";
import { ErrorBoundary } from "./ErrorBoundary";
import DesignSwitchingDesignCanvasContainer from "./containers/DesignSwitchingDesignCanvas.container";

export interface DesignSwitchingCatalogPanelProps
{
    designs: Design[];
    aspectRatio: AspectRatio;
    newDesigns: number[];
    isStaticOutputMode: boolean;
}

export interface DesignSwitchingCatalogPanelDispatchProps
{
    onDesignSelected( design: Design, aspectRatio: AspectRatio ): void;
}

export class DesignSwitchingCatalogPanel extends React.PureComponent<DesignSwitchingCatalogPanelProps & DesignSwitchingCatalogPanelDispatchProps>
{
    public render()
    {
        const aspectRatioValue = ASPECT_RATIO_VALUE[this.props.aspectRatio];
        return (
            <div className="designSwitchingCatalogPanel">
                {
                    map( this.props.designs, ( design ) =>
                    {
                        const designId = design.id;
                        const isSelectedDesign = false;
                        return (
                            <div className={classNames( "designContainer", { selected: isSelectedDesign },
                                { devModeBorder: design.status === DEVELOPMENT_STATUS } )}
                                 key={`${designId}-${aspectRatioValue}`}>
                                <ErrorBoundary errorMessage="There was a problem loading this template">
                                    <DesignSwitchingDesignCanvasContainer
                                        design={design}
                                        aspectRatio={this.props.aspectRatio}
                                        lazyLoad={true}
                                        iframeId={`catalog-${designId}-${aspectRatioValue}`}
                                        isSelectedDesign={isSelectedDesign}
                                        hidePlayButton={true}
                                        onDesignClicked={this.props.onDesignSelected}
                                        animatePreviewOnHover={true}
                                        animatePreviewOnClick={false}
                                    />
                                </ErrorBoundary>
                            </div>
                        );
                    } )
                }
            </div>
        );
    }
}
