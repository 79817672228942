import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../_types";
import { DesignTray, DesignTrayDispatchProps, DesignTrayProps } from "../DesignTray";
import { getDesignTray } from "../../../ducks/catalog";
import { getDesignAspectRatio, getSelectedDesignId, hasVideoInMediaList, isStaticOutputMode } from "../../../ducks/mixModel";
import { catalogServices, modalServices } from "../../../services";
import { uiActions } from "../../../actions";
import { FullPageDialogIdentifierForKey } from "../../../helpers";
import { getBrandSlideDesign } from "../../../ducks/designs";
import DesignSwitchingCatalogDialogContainer from "../../containers/DesignSwitchingCatalogDialog.container";
import { store } from "../../../store";
import { shouldShowCoachMarkMoreLayouts } from "../../../ducks/ui";
import { COACH_MARK_NAME_KEY_MORE_LAYOUTS } from "../../../_types/api";
import { CustomizableCanvas } from "../../../helpers/customizableCanvas";

const mapStateToProps = ( storeState: StoreState ): DesignTrayProps =>
{

    return {
        designs: getDesignTray( storeState ),
        brandSlideDesign: getBrandSlideDesign( storeState ),
        aspectRatio: getDesignAspectRatio( storeState ),
        selectedDesignId: getSelectedDesignId( storeState ),
        hasVideoInMediaList: hasVideoInMediaList( storeState ),
        hidePlayButton: isStaticOutputMode( storeState ),
        shouldShowMoreLayoutsCoachMark: shouldShowCoachMarkMoreLayouts( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): DesignTrayDispatchProps =>
{
    return {
        onShowCatalogClicked: ( e ) =>
        {
            const storeState = store.getState();
            const aspectRatio = getDesignAspectRatio( storeState );

            dispatch( uiActions.designCatalogAspectRatioSet( aspectRatio ) );
            CustomizableCanvas.requestStopAnimation();

            dispatch( modalServices.openFullpageDialog(
                {
                    identifierForKey: FullPageDialogIdentifierForKey.DESIGN_CATALOG,
                    content: DesignSwitchingCatalogDialogContainer,
                }, e ) );
            dispatch( catalogServices.loadDesignControlsByCategory() );
            dispatch( uiActions.clearCoachMark( COACH_MARK_NAME_KEY_MORE_LAYOUTS ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( DesignTray );
