import { connect } from "react-redux";
import { Dispatch } from "redux";
import { SelectPriceTiersDialog, SelectPriceTiersDialogDispatchProps, SelectPriceTiersDialogProps } from "../SelectPriceTiersDialog";
import { DialogControlProps, StoreState } from "../../_types";
import { SubscriptionFlowSource } from "../../helpers";
import { modalServices } from "../../services";

interface SelectPriceTiersDialogContainerOwnProps extends DialogControlProps
{
    source: SubscriptionFlowSource;
}

const mapStateToProps = ( storeState: StoreState, ownProps: SelectPriceTiersDialogContainerOwnProps ): SelectPriceTiersDialogProps =>
{
    return {
        source: ownProps.source,
    };
};

const mapDispatchToProps = (
    dispatch: Dispatch<StoreState>,
    ownProps: SelectPriceTiersDialogContainerOwnProps,
): SelectPriceTiersDialogDispatchProps =>
{
    return {
        handleCancel()
        {
            ownProps.closeDialog();
            dispatch( modalServices.openAcceptTestDriveDialog() );
        },
        closeDialog: ownProps.closeDialog,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( SelectPriceTiersDialog );
