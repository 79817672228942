import * as React from "react";
import classNames from "classnames";
import { clearTimeParts } from "../helpers";

export interface SocialCalendarDayProps
{
  dayOfWeek: string;
  day: string;
  date: Date;
  isHoliday: boolean;
  isSelected: boolean;
}

export interface SocialCalendarDayDispatchProps
{
  handleDayClicked( date: Date );
}

export class SocialCalendarDay extends React.PureComponent<SocialCalendarDayProps & SocialCalendarDayDispatchProps>
{
  public render()
  {
    const today = clearTimeParts(new Date());
    const dayDate = clearTimeParts(this.props.date);
    const dayOfMonthCircleClasses = {
      today: dayDate.getDate() === today.getDate(),
      past: dayDate < today,
      selected: this.props.isSelected,
    };
    const dayOfMonthOutlineClasses = {
      ...dayOfMonthCircleClasses,
      hasHoliday: this.props.isHoliday,
    };

    return (
      <div className="socialCalendarDay">
        <div className="socialCalendarDayOfWeek">{this.props.day}</div>
        <div className={classNames( "socialCalendarDayOfMonth", dayOfMonthOutlineClasses )} onClick={this.handleDaySelected}>
          <div className={classNames( "socialCalendarDayOfMonthCircle", dayOfMonthCircleClasses )}>{this.props.dayOfWeek}</div>
        </div>
      </div>
    );
  }

  private handleDaySelected = () =>
  {
    this.props.handleDayClicked( this.props.date );
  }
}
