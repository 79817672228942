import { TeamInvite } from "../../../_types";
import { TableColumn, TableRow } from "react-md";
import DeleteTeamInvitationButtonContainer from "./containers/DeleteTeamInvitationButton.container";
import * as React from "react";
import ResendTeamInvitationButtonContainer from "./containers/ResendTeamInvitationButton.container";
import { UsersTabRowEmailColumn } from "./UsersTabRowEmailColumn";

interface UsersTabTableInvitationRowProps
{
    invitation: TeamInvite;
    showManageColumn: boolean;
}

export function UsersTabTableInvitationRow( props: UsersTabTableInvitationRowProps )
{
    const { invitation, showManageColumn } = props;
    return (
        <TableRow className="invitationRow">
            <TableColumn>Invited</TableColumn>
            <UsersTabRowEmailColumn person={invitation}/>
            <TableColumn className="userRoleColumn">Member</TableColumn>
            {showManageColumn && <TableColumn>
                <div className="iconButtonGroup">
                    <DeleteTeamInvitationButtonContainer invitation={invitation}/>
                    <ResendTeamInvitationButtonContainer invitation={invitation}/>
                </div>
            </TableColumn>}
        </TableRow>
    );
}
