import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../_types";
import { postServices, userServices } from "../../../services";
import { DraftTab, DraftTabDispatchProps, DraftTabProps } from "../DraftTab";
import { getDraftPosts } from "../../../ducks/draft";
import { getCurrentBusiness } from "../../../ducks/userBusiness";
import { store } from "../../../store";

const mapStateToProps = ( storeState: StoreState ): DraftTabProps =>
{
    return {
        postData: getDraftPosts( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): DraftTabDispatchProps =>
{
    return {
        loadData: async () =>
        {
            try
            {
                const state = store.getState();
                await dispatch( userServices.loadMe() );
                const currentBusiness = getCurrentBusiness( state );
                dispatch( postServices.loadDraftPosts( currentBusiness ) );
            }
            catch (error)
            {
                // Ignore errors here for now
            }
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( DraftTab );
