import * as React from "react";
import Button from "react-md/lib/Buttons/Button";
import classNames from "classnames";
import { map, size } from "lodash";

export interface PageTabItem
{
    path: string;
    name: string | JSX.Element;
    component: React.ComponentType<any>;
    onClick: () => void;
}

export interface PageTabsProps
{
    tabItems: PageTabItem[];
    path: string;
    shouldShowCollabraUI: boolean;
}

export class PageTabs extends React.PureComponent<PageTabsProps>
{
    public render()
    {
        return (
            <div className={classNames( "pageTabContainer", { collabraUser: this.props.shouldShowCollabraUI } )}>
                {size( this.props.tabItems ) > 1 &&
                 map( this.props.tabItems, ( tabItem, index ) =>
                 {
                     return (<Button key={index}
                                     flat
                                     className={classNames( "pageTab", { inactive: this.props.path !== tabItem.path } )}
                                     onClick={tabItem.onClick}>
                         {tabItem.name}
                     </Button>);
                 } )
                }
            </div>
        );
    }
}
