import * as editIcon from "../assets/img/editIcon.png";
import * as scheduledIcon from "../assets/img/scheduledIcon.png";
import {
    AssetControlType,
    BUSINESS_PRIMARY_FONT_TYPE,
    BUSINESS_SECONDARY_FONT_TYPE,
    FACEBOOK_ACCOUNT_TYPE,
    FACEBOOK_INSTAGRAM_ACCOUNT_TYPE,
    FACEBOOK_PAGE_ACCOUNT_TYPE,
    INPUT_MEDIA_TYPE,
    LINKEDIN_ACCOUNT_TYPE,
    MUSIC_INPUT_TYPE,
    PHOTO_OUTPUT_TYPE,
    TWITTER_ACCOUNT_TYPE,
    UserPickControlType,
    VIDEO_OUTPUT_TYPE,
    YOUTUBE_ACCOUNT_TYPE,
} from "../_types/api";
import { AspectRatio } from "../_types";
import ReactGA from "react-ga";

export const IS_PROD = APP_ENV === "production";
export const IS_STAGING = APP_ENV === "staging";
export const IS_RC = APP_ENV === "rc";
export const IS_DEVELOPMENT = !(IS_PROD || IS_STAGING || IS_RC);

// Look in profileReactRendering.ts for performance constants.
export const ENABLE_USAGE_TRACKING = IS_PROD || IS_STAGING || IS_RC;
export const ENABLE_ERROR_REPORTING = true;
export const ENABLE_EVENT_CONSOLE_LOGGING = IS_DEVELOPMENT;
export const ENABLE_DESIGN_COMMUNICATION_CONSOLE_LOGGING = IS_DEVELOPMENT;
export const ENABLE_MIX_MODEL_SHOULD_UPDATE_LOGGING = IS_DEVELOPMENT;
export const ENABLE_APPTIMIZE = IS_PROD || IS_STAGING || IS_RC || IS_DEVELOPMENT;
export const ENABLE_APPTIMIZE_ERROR_CONSOLE_LOGGING = IS_DEVELOPMENT;
export const APPTIMIZE_INITIALIZATION_MAX_WAIT_MILLIS = 8000;
export const ENABLE_APPSFLYER = IS_PROD;
export const PRO_PREVIEW_ENABLED = true;

export const NON_BREAKING_SPACE = "\xa0";

interface EnvConfig
{
    baseUrl: string;
    facebookAppId: string;
    googleClientId: string;
    recordBaseUrl: string;
    cdnBaseUrl: string;
    stripeKey: string;
    amplitudeKey: string;
    apptimizeKey: string;
    reCaptchaKey: string;
}

const IP_ADDRESS = "localhost"; // change to an explicit IP to test edge for example

const devConfig: EnvConfig = {
    baseUrl: `http://${IP_ADDRESS}:3000`,
    facebookAppId: IS_DEVELOPMENT ? DEV_RIPL_FACEBOOK_APP_ID : "",
    googleClientId: "753300065351-fng3p5qtujrd46k0scc3l075hpiv6u1v.apps.googleusercontent.com",
    recordBaseUrl: `http://${IP_ADDRESS}:8001`,
    cdnBaseUrl: IS_DEVELOPMENT ? DEV_CDN_BASE_URL : "",
    stripeKey: "pk_test_3ilUbFqE7ZvNpTTZ4Ogr4qXK",
    amplitudeKey: "ecbaf03c445c35171886ffe0579ce004",
    apptimizeKey: "AsqEMKAcqgPcxhg7K2C3dn6VVewqgug",
    reCaptchaKey: "6LevT8MZAAAAAGUcqWFbnrkznl0OGqW9ZXt2fIGn",
};

const stagingConfig: EnvConfig = {
    baseUrl: "https://api-stg-ripl-com.herokuapp.com",
    facebookAppId: "1629709950616745",
    googleClientId: "753300065351-fng3p5qtujrd46k0scc3l075hpiv6u1v.apps.googleusercontent.com",
    recordBaseUrl: "https://recording-stg.ripl.com",
    cdnBaseUrl: "https://dvy812vsew7p1.cloudfront.net",
    stripeKey: "pk_test_3ilUbFqE7ZvNpTTZ4Ogr4qXK",
    amplitudeKey: "ecbaf03c445c35171886ffe0579ce004",
    apptimizeKey: "AsqEMKAcqgPcxhg7K2C3dn6VVewqgug",
    reCaptchaKey: "6LevT8MZAAAAAGUcqWFbnrkznl0OGqW9ZXt2fIGn",
};

const rcConfig: EnvConfig = {
    baseUrl: "https://api.ripl.com",
    facebookAppId: "1629709950616745",
    googleClientId: "753300065351-fng3p5qtujrd46k0scc3l075hpiv6u1v.apps.googleusercontent.com",
    recordBaseUrl: "https://recording.ripl.com",
    cdnBaseUrl: "https://d3d50fwtqurvzp.cloudfront.net",
    stripeKey: "pk_live_lCKUr5XVg31tC3gAykYRy43E",
    amplitudeKey: "ecbaf03c445c35171886ffe0579ce004",
    apptimizeKey: "DD8JfNgy8bsifDvjhfgTRjk9ddnzpUs",
    reCaptchaKey: "6LdgUsMZAAAAANrl-nCzjZqHs0F_xDe8NI5ovr5N",
};

const prodConfig: EnvConfig = {
    baseUrl: "https://api.ripl.com",
    facebookAppId: "1629709950616745",
    googleClientId: "753300065351-fng3p5qtujrd46k0scc3l075hpiv6u1v.apps.googleusercontent.com",
    recordBaseUrl: "https://recording.ripl.com",
    cdnBaseUrl: "https://d3d50fwtqurvzp.cloudfront.net",
    stripeKey: "pk_live_lCKUr5XVg31tC3gAykYRy43E",
    amplitudeKey: "f0443c3fd3345a4a80d9746aa61b7d2e",
    apptimizeKey: "D2NkvFcwsvYXxeaUfR9Tme8sruePM2k",
    reCaptchaKey: "6LdgUsMZAAAAANrl-nCzjZqHs0F_xDe8NI5ovr5N",
};

export const LINKEDIN_PARTNER_ID = "1374316";
export const LINKEDIN_CONVERSION_ID = "1686524";

export const FACEBOOK_PIXEL_ID = "349548742252874";
export const GOOGLE_ANALYTICS_IDS: ReactGA.Tracker[] = [
    { trackingId: "UA-68548848-1" },
    { trackingId: "G-W8D8MH2QDB" },
];
export const PINTEREST_TAG_ID = "2612441312434";

export const PROFITWELL_AUTH_TOKEN = "40015246af3f7ba804c1755b6f085cb0";

export const TERMS_OF_USE_URL = "https://www.ripl.com/termsofuse.html";
export const PRIVACY_POLICY_URL = "https://www.ripl.com/privacypolicy.html";

export const INSTAGRAM_TUTORIAL_URL = "https://www.ripl.com/ig-business-switch.html";
export const OPEN_RIPL_FIREBASE_URL = "https://mqu8u.app.goo.gl/openRipl";

function getEnvConfiguration()
{
    switch ( APP_ENV )
    {
        case "production":
            return prodConfig;
        case "rc":
            return rcConfig;
        case "staging":
            return stagingConfig;
        default:
            return devConfig;
    }
}

export function getSentryEnvironmentString()
{
    switch ( APP_ENV )
    {
        case "production":
            return "production";
        case "rc":
            return "rc";
        case "staging":
            return "staging";
        default:
            return "development-test";
    }
}

export const ENV_CONFIG: EnvConfig = getEnvConfiguration();

export function getFacebookAppId()
{
    return ENV_CONFIG.facebookAppId;
}

export const DESIGN_CANVAS_URL = ENV_CONFIG.cdnBaseUrl + "/common/index.html";
export const DESIGN_FONTS_URL = ENV_CONFIG.cdnBaseUrl + "/common/css/globalFont.css";
export const DESIGN_FONTS_PREVIEW_IMAGES_FOLDER_URL = ENV_CONFIG.cdnBaseUrl + "/fontPreviewImages";
export const DESIGN_CANVAS_ID_PARAM = "id";
export const CATALOG_VERSION = 7;
export const CLIENT_ID = process.env.CLIENT_ID || "com.ripl.browser";

// Do not change the following img path unless if you are updating the design code reference for the logo path.
export const DESIGN_COMMON_FILE_WATERMARK_PATH = "img/watermark.png";
export const RIPL_USER_SURVEY_2023_CODE = "CWP3ZJV";
export const URLS = {
    PAGES: {
        RESET_PASSWORD: "/account/resetPassword",
        CHANGE_PASSWORD: "/account/changePassword",
    },
    FAQ: "https://help.ripl.com/support/home",
    InstagramHelp: "https://help.ripl.com/support/solutions/articles/16000082026-sharing-to-instagram-business",
    ManageSubscription: ENV_CONFIG.baseUrl + "/stripe_account_info",
    HelpUpsell: ENV_CONFIG.baseUrl + "/helpUpsell",
    HelpCancel: ENV_CONFIG.baseUrl + "/helpCancel",
    HelpCrossPlatformUpgrade: ENV_CONFIG.baseUrl + "/helpCrossPlatformUpgrade",
    ConnectAccountsFAQ: "https://help.ripl.com/support/solutions/articles/16000081327-connecting-social-media-accounts-",
    LOGOUT_TWITTER_URL: "https://www.twitter.com/logout",
    LOGOUT_LINKED_IN_URL: "https://www.linkedin.com/help/linkedin/answer/3061/signing-in-and-out-of-your-account",
    GooglePlayManageSubscription: "https://play.google.com/store/account/subscriptions",
    RiplUserSurvey2023: "https://www.research.net/r/" + RIPL_USER_SURVEY_2023_CODE + "?userId=",
    HeyAlkai: "https://app.heyalkai.com/chat?s=riplForWeb&userId=",
};

export const LOGIN_URL = "/login";
export const GRACE_PERIOD_END_URL = "/gracePeriodEnd";
export const PRO_PREVIEW_END_URL = "/proPreviewEnd";
export const HOMEPAGE_URL = "/home";
export const CUSTOMIZE_PAGE_URL = "/customize";
export const CREATE_FACEBOOK_AD_URL = "/createFacebookAd";
export const SHAREPAGE_URL = "/share";
export const SUBSCRIPTION_TERMS_URL = HOMEPAGE_URL + "/subscriptionTerms";
export const PURCHASE_PAGE_URL = "/purchase";
export const COLLECTION_PAGE_URL = "/collection";
export const MANAGE_SUBSCRIPTION_PAGE_URL = "/manageSubscription";
export const MANAGE_SUBSCRIPTION_PAGE_INTERNAL_URL = HOMEPAGE_URL + "/manage";
export const SUBSCRIPTION_ON_HOLD_URL = "/subscriptionOnHold";
export const HOMEPAGE_MY_POSTS_URL = HOMEPAGE_URL + "/myPosts";
export const HOMEPAGE_MY_BRAND_URL = HOMEPAGE_URL + "/myBrand";
export const DELETE_ACCOUNT_URL = "/deleteAccount";

export const USER_SETTINGS_URL = HOMEPAGE_URL + "/userSettings";
export const REFER_A_FRIEND_URL = HOMEPAGE_URL + "/refer";

export const CUSTOMER_SUPPORT_UNBREAKING = `Customer${NON_BREAKING_SPACE}Support`;
export const NEED_HELP_URL = "/needHelp";
export const NEED_HELP_SETTINGS_URL = HOMEPAGE_URL + "/needHelp";
export const MAX_DESIGN_ERRORS_NEED_HELP_URL = "https://help.ripl.com/support/solutions/articles/"
                                               + "16000128714-how-do-i-clear-my-browser-s-cache-cookies-";

export const POSTS_PAGE_URL = HOMEPAGE_MY_POSTS_URL + "/posts";
export const POSTS_PAGE_DRAFTS_URL = HOMEPAGE_MY_POSTS_URL + "/drafts";
export const POSTS_PAGE_SCHEDULE_URL = HOMEPAGE_MY_POSTS_URL + "/schedule";
export const POSTS_PAGE_ENGAGEMENTS_URL = HOMEPAGE_MY_POSTS_URL + "/engagements";

export const BRAND_USERS_PAGE_URL = HOMEPAGE_MY_BRAND_URL + "/users";
export const BRAND_CREATIVE_PAGE_URL = HOMEPAGE_MY_BRAND_URL + "/creative";
export const BRAND_SETTINGS_PAGE_URL = HOMEPAGE_MY_BRAND_URL + "/settings";
export const BRAND_SETTINGS_SOCIAL_NETWORK_SETTINGS_ANCHOR_ID = "socialNetworks";

export const DEFAULT_LOGIN_REDIRECT_URL = HOMEPAGE_URL;

export const COLLABRA_BLOCKED_URLS = [POSTS_PAGE_SCHEDULE_URL,
                                      POSTS_PAGE_ENGAGEMENTS_URL,
                                      BRAND_USERS_PAGE_URL,
                                      BRAND_SETTINGS_PAGE_URL,
                                      MANAGE_SUBSCRIPTION_PAGE_URL,
                                      MANAGE_SUBSCRIPTION_PAGE_INTERNAL_URL,
                                      URLS.PAGES.RESET_PASSWORD,
                                      URLS.PAGES.CHANGE_PASSWORD,
                                      SUBSCRIPTION_ON_HOLD_URL,
                                      PURCHASE_PAGE_URL,
                                      SUBSCRIPTION_TERMS_URL,
                                      CREATE_FACEBOOK_AD_URL,
                                      PRO_PREVIEW_END_URL,
                                      GRACE_PERIOD_END_URL,
                                      REFER_A_FRIEND_URL,
                                      USER_SETTINGS_URL,
                                      NEED_HELP_URL,
                                      NEED_HELP_SETTINGS_URL,
                                      DELETE_ACCOUNT_URL];

export const PARTNER_NOT_LOGGED_IN_URL = "/partner/complete";
export const PARTNER_USER_LOGIN_URL = "/partner/login";
export const SKIP_LOGIN_URLS = [URLS.PAGES.RESET_PASSWORD, PARTNER_USER_LOGIN_URL, PARTNER_NOT_LOGGED_IN_URL];

export const CANVAS_RENDER_DELAY_MS = 200;

export const SAVE_TO_COMPUTER_ACCOUNT_LABEL = "Save to Computer";

export const PRO_ONLY_WHITELISTED_URLS = [SUBSCRIPTION_TERMS_URL, PURCHASE_PAGE_URL, DELETE_ACCOUNT_URL];

export const ALL_SOCIAL_NETWORK_LABELS_FOR_TYPES = {
    Facebook: "Facebook",
    FacebookGroup: "Facebook Group",
    FacebookPage: "Facebook Page",
    Instagram: "Instagram",
    FacebookInstagram: "Instagram Business",
    LinkedIn: "LinkedIn",
    Twitter: "Twitter",
    YouTube: "YouTube",
};

export const ALL_APP_INTEGRATION_SOCIAL_NETWORK_LABELS_FOR_TYPES = {};

// See PostAPI definition to understand where these flags are used
export const POST_API_SOCIAL_NETWORK_FLAGS_MAP = {
    Instagram: "instagram_flag",
    LinkedIn: "linkedin_flag",
    Twitter: "twitter_flag",
    YouTube: "youtube_flag",
};

export const FLAG_TYPE_CONFIG = {
    FLAG_PRO: {
        text: "PRO",
        class: "proFlag",
    },
    FLAG_FREE: {
        text: "FREE",
        class: "freeFlag",
    },
    FLAG_NEW: {
        text: "NEW",
        class: "newFlag",
    },
    FLAG_SCHEDULED: {
        text: "Scheduled",
        icon: scheduledIcon,
        class: "scheduledFlag",
    },
    FLAG_EDIT: {
        text: "Edit",
        icon: editIcon,
        class: "draftFlag",
    },
};
export const DESIGN_SQUARE_ASPECT_RATIO = "1:1";

export const SQUARE_ASPECT_RATIO: AspectRatio = "square";
export const PORTRAIT_ASPECT_RATIO: AspectRatio = "9:16";
export const LANDSCAPE_ASPECT_RATIO: AspectRatio = "16:9";

export const ASPECT_RATIO_VALUE: { [aspectRatio in AspectRatio]: number } = {
    [SQUARE_ASPECT_RATIO]: 1,
    [PORTRAIT_ASPECT_RATIO]: (9 / 16),
    [LANDSCAPE_ASPECT_RATIO]: (16 / 9),
};

export const ASPECT_RATIO_NAMES: { [aspectRatio in AspectRatio]: string } = {
    [SQUARE_ASPECT_RATIO]: SQUARE_ASPECT_RATIO,
    [PORTRAIT_ASPECT_RATIO]: "portrait",
    [LANDSCAPE_ASPECT_RATIO]: "landscape",
};

export const MIN_GOOD_IMAGE_DIMENSION = 500;
export const MAX_INPUT_IMAGE_DIMENSION = 1440;
export const MAX_INPUT_IMAGE_SCALE_FACTOR = 1.15;
export const STANDARD_IMAGE_DIMENSION = 720;
export const HD_IMAGE_DIMENSION = 1080;
export const MAX_CANVAS_SIZE = 1920;
export const MASONRY_GUTTER = 16;

export const NO_COLOR_SELECTED_DEFAULT_VALUE = "#FFFFFF";
export const DEFAULT_COLOR_SUGGESTIONS = ["#1C1C1C", "#FFFFFF", "#41B8FD", "#82D85F", "#FFDD65", "#FF9864", "#F96464", "#DF64F4", "#727272",
                                          "#999999", "#105B84", "#477C25", "#CEA224", "#D15924", "#B72929", "#861DA0"];

export const STRIPE_PUBLISHABLE_KEY = ENV_CONFIG.stripeKey;

export const COUPON_CODE_PARAM = "code";
export const SCOPED_USER_PARAM = "uid";
export const PRODUCT_TYPE_PARAM = "p";
export const PRODUCT_TIER_PARAM = "t";
export const SRC_PARAM = "src";
export const CLIENT_ID_PARAM = "cid";
export const IOS_CLIENT_ID = "me.fanzo.ripl";

export const ACTION_CONTENT_SEARCH_KEY = "openSearch";
export const ACTION_COLLECTION_KEY = "openCollection";
export const ACTION_OPEN_DESIGN_KEY = "openDesign";
export const OPEN_DESIGN_POST_ID_PARAM = "i";
export const OPEN_DESIGN_PARAM = "a=" + ACTION_OPEN_DESIGN_KEY;

export const TEAM_INVITE_TOKEN_PARAM = "token";
export const FIRST_RUN_PARAM = "a=firstRun";
export const FORGOT_PASSWORD_PARAM = "a=forgotPassword";
export const ACCEPT_TEAM_INVITE_PARAM = "a=acceptTeamInvite";
export const MANAGE_SUBSCRIPTION_PARAM = "a=manageSubscription";
export const COLLECTION_ACTION_PARAM = "a=" + ACTION_COLLECTION_KEY;
export const CONTENT_SEARCH_ACTION_PARAM = "a=" + ACTION_CONTENT_SEARCH_KEY;
export const COLLECTION_SLUG_KEY = "c";
export const DESIGN_SLUG_PARAM = "d";
export const CONTENT_SEARCH_TERM_KEY = "q";
export const SURVEY_CODE_KEY = "surveyCode";
export const ALKAI_DIALOG_COHORT_KEY = "cohort";

export const PARTNER_USER_LOGIN_AUTH_TOKEN_PARAM = "token";
export const PARTNER_USER_LOGIN_ACTION_PARAM = "action";
export const PARTNER_USER_LOGIN_TEMPLATE_SLUG_PARAM = "template";
export const PARTNER_USER_LOGIN_ACTION_CREATE_POST = "create_post";

export const LANDING_PAGE_30_DAY_FREE_TRIAL_SRC = "30dayfreetrial";

export const STRIPE_MONTHLY_SKU_SUBSTRING = "monthly";
export const STRIPE_YEARLY_SKU_SUBSTRING = "yearly";

export const ANNUAL_PRODUCT_TYPE: PricingPlanType = "annual";
export const MONTHLY_PRODUCT_TYPE: PricingPlanType = "monthly";

export const FREE_30_DAY_SRC_PARAMS = [
    LANDING_PAGE_30_DAY_FREE_TRIAL_SRC,
];

export const MEDIA_LIMIT = {
    PRO: 12,
};

export const MAX_CHAR_COUNT_TWITTER = 280;
export const MAX_CHAR_COUNT_LINKED_IN = 1300;
export const MAX_CHAR_COUNT_INSTAGRAM = 2200;
export const MAX_CHAR_COUNT_YOUTUBE = 5000;
export const MAX_CHAR_COUNT_FACEBOOK = 63206;
export const MAX_HASHTAG_COUNT_INSTAGRAM = 30;

export const ERROR_TITLE_SORRY = "Sorry!";
export const ERROR_TITLE_OOPS = "Oops!";

export const DESIGN_UNABLE_TO_BE_EDITED_DIALOG_DRAFT_MESSAGE = "This post can no longer be edited.";
export const DESIGN_DESIGN_UNABLE_TO_BE_SUPPORT_VIDEO_COUNT_MESSAGE = "We are unable to copy this post because the"
                                                                      + " template no longer supports the number of videos in the post.";
export const POST_UNABLE_TO_START = "There was a problem starting your post. Please check your network connection,"
                                    + "and contact feedback@ripl.com if problems continue.";

export const EPIDEMIC_COLLECTIONS_DIALOG_TITLE = "Music";
export const ERROR_TITLE_EPIDEMIC = "Music track unavailable";
export const EPIDEMIC_SOUND_TRACK_UNABLE_TO_LOAD = "We are unable to retrieve the music track you selected. Please try again.";
export const EPIDEMIC_SOUND_TRACK_NOT_FOUND = "We continuously update songs to keep our music fresh and trendy. "
                                              + "Because of this, some tracks might become unavailable.";

export const EPIDEMIC_SOUND_TRACK_DOWNLOAD_ERRORS = {
    TRACK_NOT_FOUND: "Track was not found",
};

export const THIRD_PARTY_LOGIN_ERROR_MESSAGES = {
    FACEBOOK_LOGIN: "Unable to complete login with Facebook account.",
    FACEBOOK_CREATE: "Unable to create account with Facebook account.",
    GOOGLE_LOGIN: "Unable to complete login with Google account. ",
    GOOGLE_CREATE: "Unable to create account with Google account. ",
};

export interface PostButtonTextMap
{
    startText: string[];
    remixText: string[];
}

const inspirePostButtonTextMap: PostButtonTextMap = {
    startText: ["Let's start", "something new"],
    remixText: ["Or begin from any inspiration"],
};
const myPostsPostButtonTextMap: PostButtonTextMap = {
    startText: ["Let's start", "something new"],
    remixText: [],
};
export const POST_BUTTON_MAP = {
    INSPIRE: inspirePostButtonTextMap,
    MY_POSTS: myPostsPostButtonTextMap,
};

export const PRO_VIDEO_MAX_DURATION_SECONDS = 60;
export const PRO_VIDEO_MAX_DURATION_IN_MS = PRO_VIDEO_MAX_DURATION_SECONDS * 1000;

export const VIA_RIPL_TEXT = "via ripl.com";
export const VIA_TEXT_PREFIX = "#MadeWithRipl";

export const DEFAULT_MISSING_BUSINESS_NAME = "(Default Profile)";
export const DEFAULT_BRAND_LOGO_URL = "https://fanzo-static.s3.amazonaws.com/assets/watermark.png";

export const HOME_PAGE_ALERT_ALREADY_SUBSCRIBED = "alreadySubscribed";

/* Instagram accounts don't allow direct upload in desktop, you need to have a mobile client.
   Re-enable if they expose the api for direct sharing. */
export const SOCIAL_NETWORKS_CAN_CONNECT_TO = [FACEBOOK_ACCOUNT_TYPE,
                                               FACEBOOK_PAGE_ACCOUNT_TYPE,
                                               FACEBOOK_INSTAGRAM_ACCOUNT_TYPE,
                                               LINKEDIN_ACCOUNT_TYPE,
                                               TWITTER_ACCOUNT_TYPE,
                                               YOUTUBE_ACCOUNT_TYPE];
export const SOCIAL_NETWORKS_CAN_USE_FOR_INTEGRATION = [];

export const SOCIAL_NETWORKS_CAN_SHARE_TO = [
    /* Personal accounts are not supported as of August, 2018
    Instagram accounts don't allow direct upload in desktop, you need to have a mobile client.
    Re-enable if they expose the api for direct sharing. */
    FACEBOOK_PAGE_ACCOUNT_TYPE,
    FACEBOOK_INSTAGRAM_ACCOUNT_TYPE,
    LINKEDIN_ACCOUNT_TYPE,
    TWITTER_ACCOUNT_TYPE,
    YOUTUBE_ACCOUNT_TYPE,
];

export const SOCIAL_NETWORKS_FACEBOOK_INSTAGRAM = [
    FACEBOOK_ACCOUNT_TYPE,
    FACEBOOK_PAGE_ACCOUNT_TYPE,
    FACEBOOK_INSTAGRAM_ACCOUNT_TYPE,
];

export const ALLOWED_FONT_EXTENSIONS = [
    ".woff",
    ".woff2",
    ".ttf",
    ".eot",
    ".otf",
    ".svg",
];

export const ALLOWED_MUSIC_EXTENSIONS = [
    ".mp3",
    ".m4a",
    ".ogg",
    ".wav",
    ".aac",
    ".flac",
    ".wma",
];

export const MP4_VIDEO_TYPE = "video/mp4";

export const ALLOWED_VIDEO_INPUT_TYPE = [
    MP4_VIDEO_TYPE,
    "video/quicktime",
    "video/avi",
    "video/mpg",
    "video/mpeg",
    "video/ogg",
    "video/x-ms-wmv",
];

export const PNG_TYPE = "image/png";
export const JPEG_TYPE = "image/jpeg";

export const MP4 = "mp4";
export const JPG = "jpg";
export const JPEG = "jpeg";

export const EDIT_VIDEO_CLOSE_REASONS_SAVED = "videoSaved";
export const EDIT_VIDEO_CLOSE_REASONS_CHANGED = "changeVideo";
export const EDIT_VIDEO_CLOSE_REASONS_CANCELLED = "cancelled";

export const MAX_MUSIC_FILE_UPLOAD_SIZE_IN_BYTE = 20000000;
export const MAX_VIDEO_FILE_UPLOAD_SIZE_IN_BYTE = 200000000; // 200MB video upload size

export const MODAL_DIALOG_SMALL_HEIGHT_MATCHING_SCSS_VAR = 550;

export const DEFAULT_TRIAL_DAYS = 7;
export const DEFAULT_PAYMENT_AMOUNT = 14.99;
export const DEFAULT_PAYMENT_CURRENCY = "USD";
export const DEFAULT_MONTHLY_SKU = "ripl-pro-subscription-monthly-trial-stripe-1499";
export const DEFAULT_MONTHLY_SKU_NO_TRIAL = "ripl-pro-subscription-monthly-stripe-1499";
export const DEFAULT_ANNUAL_SKU = "ripl-pro-subscription-yearly-trial-stripe-11988";
export const DEFAULT_ANNUAL_SKU_NO_TRIAL = "ripl-pro-subscription-yearly-stripe-11988";
export const MONTHLY_SKU_WITH_30_DAY_TRIAL = "ripl-pro-subscription-monthly-trial-stripe-1499-30daytrial";
export const ANNUAL_SKU_WITH_30_DAY_TRIAL = "ripl-pro-subscription-yearly-stripe-11988-30daytrial";
export const MONTHLY_SKU_12_99 = "ripl-pro-subscription-monthly-trial-stripe-1299";
export const MONTHLY_SKU_19_99 = "ripl-pro-subscription-monthly-trial-stripe-1999";
export const YEARLY_SKU_95_88 = "ripl-pro-subscription-yearly-trial-stripe-9588";
export const MONTHLY_SKU_12_99_NO_TRIAL = "ripl-pro-subscription-monthly-stripe-1299";
export const MONTHLY_SKU_19_99_NO_TRIAL = "ripl-pro-subscription-monthly-stripe-1999";
export const YEARLY_SKU_95_88_NO_TRIAL = "ripl-pro-subscription-yearly-stripe-9588";

// Below SKU is no longer active. Used in ~2019 partnership with American Express.
export const MONTHLY_SKU_WITH_123_DAY_TRIAL = "ripl-pro-subscription-monthly-trial-stripe-1499-123trial";

export const BASE_MONTHLY_SKU_12_99_TRIAL = "ripl-pro-base-tier-subscription-monthly-trial-stripe-1299";
export const BASE_MONTHLY_SKU_12_99_NO_TRIAL = "ripl-pro-base-tier-subscription-monthly-stripe-1299";
export const BASE_YEARLY_SKU_99_99_TRIAL = "ripl-pro-base-tier-subscription-yearly-trial-stripe-9999";
export const BASE_YEARLY_SKU_99_99_NO_TRIAL = "ripl-pro-base-tier-subscription-yearly-stripe-9999";

export const PREMIUM_MONTHLY_SKU_19_99_TRIAL = "ripl-pro-premium-tier-subscription-monthly-trial-stripe-1999";
export const PREMIUM_MONTHLY_SKU_19_99_NO_TRIAL = "ripl-pro-premium-tier-subscription-monthly-stripe-1999";
export const PREMIUM_YEARLY_SKU_164_99_TRIAL = "ripl-pro-premium-tier-subscription-yearly-trial-stripe-16499";
export const PREMIUM_YEARLY_SKU_164_99_NO_TRIAL = "ripl-pro-premium-tier-subscription-yearly-stripe-16499";

export const MAX_NUMBER_OF_TIMES_TO_SHOW_CUSTOMIZE_TIP_BANNERS = 3;

export const SUBSCRIPTION_STATUS_LIFETIME = "LIFETIME";
export const SUBSCRIPTION_STATUS_SUBSCRIBED = "SUBSCRIBED";
export const SUBSCRIPTION_STATUS_TRIAL = "TRIAL";
export const SUBSCRIPTION_STATUS_PRO_PREVIEW = "PRO_PREVIEW";
export const SUBSCRIPTION_STATUS_PRO_PREVIEW_ENDED = "PRO_PREVIEW_ENDED";
export const ALL_USER_SUBSCRIBED_STATUSES = [SUBSCRIPTION_STATUS_LIFETIME,
                                             SUBSCRIPTION_STATUS_SUBSCRIBED,
                                             SUBSCRIPTION_STATUS_TRIAL,
                                             SUBSCRIPTION_STATUS_PRO_PREVIEW];

export const ALL_PAYING_USER_SUBSCRIBED_STATUSES = [SUBSCRIPTION_STATUS_LIFETIME,
                                                    SUBSCRIPTION_STATUS_SUBSCRIBED,
                                                    SUBSCRIPTION_STATUS_TRIAL];

export const FAILED_TO_UPLOAD_OUTPUT_IMAGE_TO_S3 = "Uploading cover image failed";
export const FAILED_TO_UPLOAD_OUTPUT_VIDEO_TO_S3 = "Uploading video failed";
export const FAILED_TO_UPLOAD_INPUT_MEDIA_TO_S3 = "Uploading imported media failed";
export const FAILED_TO_UPLOAD_CUSTOM_MUSIC_TO_S3 = "Uploading custom music failed";
export const FAILED_TO_UPLOAD_CUSTOM_FONT_TO_S3 = "Uploading custom font failed";

export const FAILED_TO_START_UPLOAD_OUTPUT_IMAGE = "Unable to start uploading cover image";
export const FAILED_TO_START_UPLOAD_OUTPUT_VIDEO = "Unable to start uploading video";
export const FAILED_TO_START_UPLOAD_INPUT_MEDIA = "Unable to start uploading imported media";
export const FAILED_TO_START_UPLOAD_CUSTOM_MUSIC = "Unable to start uploading custom music";
export const FAILED_TO_START_UPLOAD_CUSTOM_FONT = "Unable to start uploading custom font";

export const LIMIT_TO_MAX_VIDEO_SELECTED_ERROR_MESSAGE = "You've reached the maximum number of videos. Please remove a video to choose another.";
export const CANNOT_MULTI_SELECT_VIDEOS_ERROR_MESSAGE = "You cannot multi-select videos, please add videos one at a time.";
export const DESIGN_DOES_NOT_SUPPORT_ANY_VIDEO_ERROR_MESSAGE = "This template doesn't support adding video. "
                                                               + "To add a video, please change your template.";
export const SWITCH_DESIGN_DOES_NOT_SUPPORT_ANY_VIDEO_ERROR_MESSAGE = "We can't switch to this template because it doesn't support video. "
                                                                      + "If you want to select this template, "
                                                                      + "please remove your videos and try again.";
export const SWITCH_DESIGN_DOES_NOT_SUPPORT_CURRENT_VIDEO_COUNT_ERROR_MESSAGE = "We can't switch to this template because it "
                                                                                + "doesn't support the current number of videos you have selected.";
export const SAFARI_AUDIO_SYNC_WARNING_TOAST_TITLE = "Audio out of sync?";
export const SAFARI_AUDIO_SYNC_WARNING_TOAST_MESSAGE_ONE = "Video audio may be out of sync in safari.";
export const SAFARI_AUDIO_SYNC_WARNING_TOAST_MESSAGE_TWO = "When saved, your video and audio should play back correctly. "
                                                           + "We recommend trying a different web browser if you continue to experience issues.";

export const FAILED_TO_UPLOAD_ERROR_SOURCE_MAP = {
    [PHOTO_OUTPUT_TYPE]: FAILED_TO_UPLOAD_OUTPUT_IMAGE_TO_S3,
    [VIDEO_OUTPUT_TYPE]: FAILED_TO_UPLOAD_OUTPUT_VIDEO_TO_S3,
    [INPUT_MEDIA_TYPE]: FAILED_TO_UPLOAD_INPUT_MEDIA_TO_S3,
    [MUSIC_INPUT_TYPE]: FAILED_TO_UPLOAD_CUSTOM_MUSIC_TO_S3,
    [BUSINESS_PRIMARY_FONT_TYPE]: FAILED_TO_UPLOAD_CUSTOM_FONT_TO_S3,
    [BUSINESS_SECONDARY_FONT_TYPE]: FAILED_TO_UPLOAD_CUSTOM_FONT_TO_S3,
};

export const FAILED_TO_START_UPLOAD_ERROR_SOURCE_MAP = {
    [PHOTO_OUTPUT_TYPE]: FAILED_TO_START_UPLOAD_OUTPUT_IMAGE,
    [VIDEO_OUTPUT_TYPE]: FAILED_TO_START_UPLOAD_OUTPUT_VIDEO,
    [INPUT_MEDIA_TYPE]: FAILED_TO_START_UPLOAD_INPUT_MEDIA,
    [MUSIC_INPUT_TYPE]: FAILED_TO_START_UPLOAD_CUSTOM_MUSIC,
    [BUSINESS_PRIMARY_FONT_TYPE]: FAILED_TO_START_UPLOAD_CUSTOM_FONT,
    [BUSINESS_SECONDARY_FONT_TYPE]: FAILED_TO_START_UPLOAD_CUSTOM_FONT,
};

export const BRAND_TYPE_COLOR = "color";
export const BRAND_TYPE_FONT = "font";

export enum EDITOR_TYPES
{
    CONTROL = "control",
    TEXT_CONTROL = "text",
    COLOR_CONTROL = "color",
    BACKGROUND_MEDIA_CONTROL = "backgroundMedia",
    FONT_CONTROL = "font",
    MEDIA_CONTROL = "media",
    AUDIO_CONTROL = "audio",
    ASPECT_RATIO_CONTROL = "aspectRatio",
    BRAND_SLIDE_CONTROL = "brandSlide",
    FOUNDATION_PRESETS_CONTROL = "foundationPresets",
    LOGO_CONTROL = "logo",
    TEMPLATES_CONTROL = "templates",
    ASSET_CONTROL = "asset",
    MODEL_LINKED_PHOTO_CONTROL = "modelLinkedPhotoAsset",
    MODEL_LINKED_TEXT_CONTROL = "modelLinkedTextAsset",
}

export const USER_PICK_CONTROL_TYPES: UserPickControlType[] = [EDITOR_TYPES.MODEL_LINKED_PHOTO_CONTROL, EDITOR_TYPES.MODEL_LINKED_TEXT_CONTROL];
export const ASSET_CONTROL_TYPES: AssetControlType[] = [...USER_PICK_CONTROL_TYPES, EDITOR_TYPES.ASSET_CONTROL];
export const SUPPORTED_CONTROLS_CONFIG_TYPES = [EDITOR_TYPES.MODEL_LINKED_PHOTO_CONTROL,
                                                EDITOR_TYPES.MODEL_LINKED_TEXT_CONTROL,
                                                EDITOR_TYPES.ASSET_CONTROL,
                                                EDITOR_TYPES.COLOR_CONTROL,
                                                EDITOR_TYPES.FONT_CONTROL,
                                                EDITOR_TYPES.BACKGROUND_MEDIA_CONTROL];
export const EDITOR_TYPES_TITLES = {
    MEDIA: "Media",
    AUDIO: "Music",
    ASPECT_RATIO: "Size",
    LOGO: "Logo",
    BRAND_SLIDE: "Brand Slide",
    FOUNDATION_PRESETS: "Presets",
    TEMPLATES: "Recently used",
    TEMPLATES_ICON: "Templates",
};

export const BUSINESS_DEFAULT_NAME = "My Business";
export const BUSINESS_PROFILE_PAGE = "businessProfilePage";
export const CUSTOMIZE_PAGE = "customizePage";

export const STOCK_MEDIA_SEARCH_PHOTOS_TYPE = "photos";
export const STOCK_MEDIA_SEARCH_VIDEOS_TYPE = "videos";
export const STOCK_MEDIA_PEXELS_ATTRIBUTION = "POWERED BY PEXELS";
export const STOCK_MEDIA_PEXELS_ATTRIBUTION_LINK = "https://www.pexels.com/";

export const MEDIA_PICKER_NUMBER_OF_ITEMS_PER_ROW = 6;

export const ALL_SOURCE = "all";
export const INSPIRE_CATALOG_SOURCE = "inspireCatalog";
export const BUSINESS_ACTION_SOURCE = "businessAction";
export const COPY_POST_SOURCE = "copyOfPost";
export const COLLECTION_SOURCE = "collection";
export const DRAFT_SOURCE = "draft";
export const DEEP_LINK_SOURCE = "openDesignDeepLink";
export const SEARCH_SOURCE = "search";
export const SOCIAL_CALENDAR_COLLECTION_SOURCE = "socialCalendarCollection";
export const PARTNER_SITE_SOURCE = "partnerSite";
export const POST_INTENT_SOURCE = "postIntent";

export const AUD_CODE = "AUD";
export const CAD_CODE = "CAD";
export const CHF_CODE = "CHF";
export const EUR_CODE = "EUR";
export const GBP_CODE = "GBP";
export const INR_CODE = "INR";
export const JPY_CODE = "JPY";
export const MXN_CODE = "MXN";
export const NZD_CODE = "NZD";
export const USD_CODE = "USD";
export const CURRENCY_SELECTOR_CODES = [AUD_CODE, CAD_CODE, CHF_CODE, EUR_CODE, GBP_CODE, INR_CODE, JPY_CODE, MXN_CODE, NZD_CODE, USD_CODE];

export const VISA_CARD_TYPE = "visa";
export const MASTERCARD_CARD_TYPE = "mastercard";
export const AMEX_CARD_TYPE = "amex";
export const DISCOVER_CARD_TYPE = "discover";
export const DINERS_CLUB_CARD_TYPE = "diners";
export const JCB_CARD_TYPE = "jcb";
export const UNION_PAY_CARD_TYPE = "unionpay";

export const STRIPE_CARD_ERROR = "card_error";
export const STRIPE_PAYMENT_AUTHENTICATION_FAILED_CODE = "payment_intent_authentication_failure";

export const CHOOSE_A_FONT_TITLE = "Choose a font:";

export const MISSING_USER_ID_IN_REQUEST = "Missing user id in request";

export const DEVELOPMENT_STATUS = "DEVELOPMENT";

export const SEARCH_NO_RESULTS_TEXT = "Try using a different search term.";

export const SUBSCRIPTION_INFO_BILLING_INTERVAL_LIFETIME = "lifetime";
export const SUBSCRIPTION_INFO_BILLING_INTERVAL_MONTHLY = "monthly";
export const SUBSCRIPTION_INFO_BILLING_SOURCE_STRIPE = "stripe";
export const SUBSCRIPTION_INFO_BILLING_SOURCE_GOOGLE = "google";
export const SUBSCRIPTION_INFO_BILLING_SOURCE_APPLE = "apple";
export const MOBILE_WALL_BREAKPOINT_MAX_WIDTH = 600;

export const PASSWORD_RESET_SENT_TO_EMAIL_TITLE = "Check your email";
export const PASSWORD_RESET_SENT_TO_EMAIL = "If the email {email} is in our system, check your inbox to see a password reset link from Ripl.";

export const GDPR_CONSENT_MESSAGE = "To provide you the best Ripl service, we collect and utilize some personal data, including for example "
                                    + "Name and Email Address, as more fully described in our Privacy Policy. Our site uses Cookies. "
                                    + "By clicking accept, you consent to the use of cookies for analytics, personalized content, and ads.";
export const GDPR_CONSENT_ACCEPT_BUTTON_TEXT = "Accept & Close";

export const COOKIE_CONSENT_MESSAGE = "By using this site, you agree to the use of cookies for analytics, personalized content, and ads.";
export const COOKIE_CONSENT_ACCEPT_BUTTON_TEXT = "Got it!";
export const SUBSCRIBED_ON_OTHER_PLATFORM_DIALOG_TITLE = "You are subscribed on a different platform";
export const SUBSCRIBED_ON_ANDROID_DIALOG_TITLE = "You are subscribed on Android";
export const SUBSCRIBED_ON_IOS_DIALOG_TITLE = "You are subscribed on iOS";
export const SUBSCRIBED_OTHER_PLATFORM_DIALOG_TEXT = "We are not able to upgrade you to Premium on this platform. "
                                                     + "You will need to contact customer support for assistance.";

export const FONT_LOAD_REQUESTED_STATUS = "font_load_requested";
export const FONT_LOAD_COMPLETED_STATUS = "font_load_completed";
export const FONT_LOAD_RETRYING_STATUS = "font_load_retrying";

export const DELETE_ACCOUNT_REQUEST_SENT_MESSAGE = "We have started the process to delete your account. "
                                                   + "This process may take up to 60 days to complete.";

export const LOCALE_US = "en-US";
export const LOCALE_AUSTRALIA = "en-AU";
export const LOCALE_CANADA = "en-CA";
export const LOCALE_SWITZERLAND = "de-CH";
export const LOCALE_GERMANY = "de-DE";
export const LOCALE_GREAT_BRITAIN = "en-GB";
export const LOCALE_INDIA = "en-IN";
export const LOCALE_JAPAN = "ja";
export const LOCALE_MEXICO = "es-MX";
export const LOCALE_NEW_ZEALAND = "en-NZ";

export const REFER_A_FRIEND_LINK = "https://s.ripl.com/uDZkkE";

export const EMPTY_SYMBOL = "--";

export const enum FullPageDialogIdentifierForKey
{
    DESIGN_CATALOG,
    POST_DETAILS,
    MOBILE_VIEW_AD_PREVIEW,
    MOBILE_VIEW_AD_SUMMARY_DIALOG,
}

export const enum LightboxDialogIdentifierForKey
{
    BRAND_SETTINGS,
    NEW_BUSINESS,
    CHANGE_BUSINESS_TYPE,
    MANAGE_SUBSCRIPTION_MESSAGE,
    ACCEPT_TEST_DRIVE,
    ALREADY_A_TEAM_MEMBER,
    ALREADY_INVITED_DIALOG,
    CONFIRM_REMOVE_TEAM_MEMBER,
    CONFIRM_CANCEL_TEAM_INVITATION,
    SOCIAL_ACCOUNT_SETTINGS,
    RECONNECT_FACEBOOK,
    HOW_TO_SWITCH_FACEBOOK_USER,
    CONFIRM_MOVE_SOCIAL_NETWORK_ACCOUNT_TO_CURRENT_BUSINESS,
    CONFIRM_MOVE_FACEBOOK_SOCIAL_NETWORK_OBJECT_TO_CURRENT_BUSINESS,
    DISCONNECT_SOCIAL_NETWORK,
    YOUTUBE_SHARE_IMAGE_NOT_AVAILABLE,
    UPGRADE_STRIPE_SUBSCRIPTION_TO_ANNUAL,
    CANCEL_STRIPE_SUBSCRIPTION,
    CONFIRM_DELETE_BUSINESS,
    UNABLE_TO_START_NEW_POST,
    CONFIRM_DELETE_DRAFT_POST,
    CONFIRM_DELETE_FINISHED_POST,
    SAVE_DRAFT,
    CHOOSE_CUSTOM_FONT,
    CHOOSE_FONT_BRAND_SETTINGS,
    CHOOSE_CUSTOM_FONT_AND_SET_BUSINESS_FONT,
    EDIT_COLOR,
    CHOOSE_BRAND_COLOR,
    CHOOSE_MUSIC,
    CONFIRM_DELETE_EXTRA_CAPTION,
    MAX_ERRORS_FOR_DESIGN,
    TEAM_INVITE_ACCEPTED,
    TEAM_INVITE_SENT,
    ADD_PASSWORD,
    PASSWORD_CHANGED,
    PASSWORD_RESET,
    FACEBOOK_INSTAGRAM_WARMUP,
    FACEBOOK_INSTAGRAM_MISSING_BUSINESS_PAGE,
    FACEBOOK_INSTAGRAM_TUTORIAL,
    FACEBOOK_INSTAGRAM_PORTRAIT_SHARE_NOT_AVAILABLE,
    CONFIRM_DISCONNECT_SOCIAL_ACCOUNT,
    TRIM_VIDEO,
    STOCK_MEDIA_TERMS_AND_CONDITIONS,
    SUBSCRIPTION_CONFIRMATION,
    UPDATE_STRIPE_CARD,
    CREDIT_CARD_PAYMENT_FORM,
    ADD_CARD_FORM,
    WELCOME_BACK,
    PASSWORD_RESET_EMAIL_SENT,
    UNABLE_TO_RESUME_AD_DRAFT,
    UNABLE_TO_RESUME_HIDDEN_AD_DRAFT,
    MANAGE_LOGOS,
    CONFIRM_DELETE_LOGOS,
    SELECT_PRICE_TIERS,
    SELECT_PRICE_PLANS,
    WARMUP_ADD_NEW_BUSINESS,
    SELECT_PLAN_SECTION,
    SUBSCRIBED_ON_ANDROID,
    SUBSCRIBED_ON_OTHER_PLATFORM,
    INVITE_TEAM_MEMBERS,
    DELETE_TEAM_MEMBERS,
    DELETE_TEAM_INVITES,
    DELETE_SELF_FROM_TEAM,
    RESEND_TEAM_INVITES,
    RIPL_USER_SURVEY_PROMPT,
    TEAMS_DIALOG_ERROR,
    REPLACE_MEDIA_PICKER,
    SUBSCRIBED_ON_IOS,
    TIER_WAVE_UPSELL,
    NOT_A_DRAFT,
    EPIDEMIC_USER_COLLECTIONS,
    POST_INTENTS,
    TEMPLATE_PREVIEW,
    DELETE_ACCOUNT_CONFIRMATION,
    DELETE_ACCOUNT_SUCCESS,
    ALKAI_CROSS_PROMO,
    YOUTUBE_DETAILS,
}

export const enum LoadingDialogIdentifierForKey
{
    TRIMMING,
}

export const TEXT_PICKER_MAX_ROWS_PER_TEXT_FIELD = 6;
export const TEXT_PICKER_MAX_NUMBER_OF_CAPTIONS = 30;
export const TEXT_PICKER_CAPTION_UPDATE_DEBOUNCE_TIME_MS = 200;

export const STYLIZED_TEXT_BUTTON_CLASS = "stylizedTextButton";
export const MORE_LAYOUTS_BUTTON_CLASS = "moreLayoutsButton";
export const STYLIZED_TEXT_DEFAULT_STYLE_ID = "default";

export const IOS_ELEMENT_SCALE = 0.89;
export const WEB_ELEMENT_SCALE = 0.93;

export const ANONYMIZED_EMAIL = "email_not_found";
export const ANONYMIZED_NAME = "CLEARED";

export const ACTIVITY_KEY_MOST_RECENT_POSTS = "mostRecentPosts";
export const ACTIVITY_KEY_UNSCHEDULED_POSTS = "unscheduledPosts";

export const EPIDEMIC_SOUND_TRACK_EXPIRATION_WINDOW_MS = 60 * 60 * 6 * 1000; // 6 hours

export const KEY_CODES = {
    ENTER: "Enter",
    DELETE: "Delete",
    BACKSPACE: "Backspace",
};

export const CREATE_A_POST_BANNER_TITLE = "Have an idea in mind?";
export const CREATE_A_POST_BANNER_BUTTON_TEXT = "Share a post";
export const POST_INTENT_BANNER_TITLE = "Need a post recommendation?";
export const POST_INTENT_BANNER_BUTTON_TEXT = "View ideas";
