import * as React from "react";

export interface EpidemicSoundESConnectLinkDispatchProps
{
    onConnectClicked();
}

export class EpidemicSoundESConnectLink extends React.PureComponent<EpidemicSoundESConnectLinkDispatchProps>
{
    public render()
    {
        return (
            <>
                <a className="learnMore" onClick={this.props.onConnectClicked} target="_blank">
                    Get more music
                    <i className="material-icons">arrow_forward_ios</i>
                </a>
            </>
        );
    }
}
