import { store } from "../store";
import { Action } from "redux-actions";
import { Dispatch } from "redux";
import {
    concat,
    filter,
    find,
    findIndex,
    forEach,
    get,
    head,
    includes,
    isEqual,
    isNumber,
    keys,
    map,
    mapValues,
    merge,
    omit,
    shuffle,
    size,
    some,
    uniq,
} from "lodash";
import {
    ComputeControlPayload,
    DesignControlsJSONData,
    designsActions,
    DesignSettingsSavePayload,
    ImageUploadData,
    MediaSwapPayload,
    mixModelActions,
    MixModelStartPayload,
    postActions,
    SetStartingMixTypeAndRelatedDataPayload,
    StartingMixTypeAndRelatedData,
    uiActions,
    VideoTrimmerDataSavedPayload,
} from "../actions";
import {
    apptimizeVariables,
    ASSET_CONTROL_TYPES,
    CATALOG_VERSION,
    convertInputsArrayToKey,
    convertToBoolean,
    convertToNumber,
    EDITOR_TYPES,
    ENABLE_MIX_MODEL_SHOULD_UPDATE_LOGGING,
    eventTracker,
    generateFontDataForBrandFont,
    generateFontDataForFontName,
    hasBrandFont,
    isMusicTypeEpidemicSound,
    JPG,
    LANDSCAPE_ASPECT_RATIO,
    LightboxDialogIdentifierForKey,
    LoadingDialogIdentifierForKey,
    MP4,
    MP4_VIDEO_TYPE,
    musicHelper,
    PORTRAIT_ASPECT_RATIO,
    POST_API_SOCIAL_NETWORK_FLAGS_MAP,
    PRO_VIDEO_MAX_DURATION_SECONDS,
    ReducerCreator,
    selectVideoAudio,
    SQUARE_ASPECT_RATIO,
    stringUtils,
    SUPPORTED_CONTROLS_CONFIG_TYPES,
    urlUtils,
} from "../helpers";
import { getBrandLogo, getCurrentUser, getDevMode, getUserSocialNetworkAccounts, isContentContributor } from "./user";
import { getBrandSlideDesign, getControlsConfigsByDesignId, getControlsConfigsLookup, getDesign, getDesignIdFromSlug } from "./designs";
import { getCurrentBusiness, getCurrentBusinessId } from "./userBusiness";
import { isBefore, parse } from "date-fns";
import {
    getCaption,
    getEnabledSocialNetworkIds,
    getFacebookEnabled,
    getImageUrl,
    getOutputType,
    getSaveToComputerEnabled,
    getScheduledSendDatetime,
    getVideoUrl,
} from "./shareModel";
import { areMusicItemsEqual, getCatalogMusicById, getCustomMusicFromMusicCatalog } from "./musicCatalog";
import { createSelector } from "reselect";
import {
    AddMediaAPIResponse,
    AssetControlConfig,
    BackgroundMediaControlConfig,
    Brand,
    ColorControlConfig,
    ControlsConfig,
    ControlType,
    CUSTOM_MUSIC_CATEGORY,
    DESIGN_DISPLAY_MODE_ANIMATED_PREVIEW,
    DESIGN_DISPLAY_MODE_EDITOR,
    DESIGN_DISPLAY_MODE_PREVIEW,
    FontControlConfig,
    IControlConfig,
    IMAGE_OUTPUT_TYPE,
    INPUT_MEDIA_TYPE,
    ModelLinkedPhotoAssetControlConfig,
    ModelLinkedTextAssetControlConfig,
    MUSIC_TYPE_PERSONAL_MUSIC,
    MUSIC_TYPE_RIPL_MUSIC,
    MUSIC_TYPE_VIDEO_AUDIO,
    OUTPUT_MODE_ANIMATED,
    OUTPUT_MODE_STATIC,
    POST_TYPE_RIPL,
    PostAPI,
    PostInputMediaFile,
    PostMediaType,
    SEND_STATUS_DRAFT,
    VIDEO_AUDIO_DISPLAY_NAME,
    VIDEO_DURATION_NOT_FOUND,
} from "../_types/api";
import {
    AspectRatio,
    AssetChoice,
    BackgroundChoice,
    CommonInputData,
    ControlDataValue,
    Design,
    DesignDisplayMode,
    DesignFeatureFlags,
    DesignInput,
    DesignOutputMode,
    DesignSelectedData,
    DesignTemplateSettings,
    GlobalSettings,
    MediaSourceType,
    MixModelState,
    Music,
    MusicSelection,
    MusicType,
    OriginalTrimmerData,
    Post,
    SocialNetworkAccount,
    StoreState,
    TemplateControlData,
    TemplateFontInputData,
    TemplateInputBrandSlideData,
    TemplateInputData,
    TemplateInputDataMediaItem,
    TemplateInputEndCardData,
    UserBusiness,
    UserState,
    VideoAudioDataItem,
    VideoTrimmerCurrentData,
} from "../_types";
import { getActiveDesignMediaObject, getCustomizableCanvas, isBrandSlideTabSelected, isStockMediaUploading, isVideoTrimmerModalOpen } from "./ui";
import { controlDataWithUserChoicesUpdated } from "../helpers/userChoiceControlsHelper";
import { modalServices } from "../services";
import { getEpidemicSoundTrackByIdAsMusic } from "./epidemicSoundCatalog";
import { isShowingLoadingDialog } from "./modals";
import { getDifferencesBetweenObjects } from "../helpers/objectLoggingHelper";

const defaultCommonInputData: CommonInputData = {
    originalImageList: [],
};

const defaultTemplateInputEndCardData: TemplateInputEndCardData = {
    showLogo: false,
    shouldShowEndCardImprovements: true,
};

const defaultTemplateInputBrandSlideData: TemplateInputBrandSlideData = {
    showEndCard: false,
    showEndCardInEditor: true,
    endCardData: defaultTemplateInputEndCardData,
};

const emptyTrimmerData: OriginalTrimmerData = {
    url: null,
    time: {
        start: null,
        end: null,
    },
};

const defaultVideoTrimmerCurrentData: VideoTrimmerCurrentData = {
    videoTrimmerIsReady: false,
};

const defaultState: MixModelState = {
    commonInputData: defaultCommonInputData,
    brandSlideData: defaultTemplateInputBrandSlideData,
    templateSettingByDesignId: {},
    globalSettings: {},
    isPreview: false,
    recomputeControlData: true,
    controlDataByDesignId: {},
    aspectRatio: SQUARE_ASPECT_RATIO,
    watermarkManuallyRemoved: false,
    videoTrimmerCurrentData: defaultVideoTrimmerCurrentData,
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( mixModelActions.templateInputUpdated, handleCommonInputDataUpdate );
reducerCreator.addAction( mixModelActions.brandSlideDataUpdated, handleBrandSlideDataUpdate );
reducerCreator.addAction( mixModelActions.controlDataUpdated, handleDesignControlUpdate );
reducerCreator.addAction( mixModelActions.templateSettingsSaved, handleTemplateSettingsSave );
reducerCreator.addAction( mixModelActions.brandSettingsUpdated, handleBrandSettingsUpdated );
reducerCreator.addAction( mixModelActions.globalSettingsSaved, handleGlobalSettingsSave );
reducerCreator.addAction( mixModelActions.commonInputDataUpdated, handleCommonInputDataUpdated );
reducerCreator.addAction( mixModelActions.mixModelStarted, handleMixModelStarted );
reducerCreator.addAction( mixModelActions.mixModelCancelled, handleMixModelReset );
reducerCreator.addAction( mixModelActions.importPostDataFromUserPost, handleImportPostDataFromUserPost );
reducerCreator.addAction( mixModelActions.importPostDataFromCuratedPost, handleImportPostDataFromCuratedPost );
reducerCreator.addAction( mixModelActions.importMediaFilesFromPost, handleImportMediaFilesFromPost );
reducerCreator.addAction( mixModelActions.resumeDraft, handleResumeDraft );
reducerCreator.addAction( mixModelActions.computeControlData, handleComputeControlData );
reducerCreator.addAction( postActions.createSuccess, handlePostCreateSuccess );
reducerCreator.addAction( mixModelActions.designSelected, handleDesignSelected );
reducerCreator.addAction( mixModelActions.designHasChanged, handleDesignHasChanged );
reducerCreator.addAction( mixModelActions.startingDesignSet, handleStartingDesignSet );
reducerCreator.addAction( mixModelActions.designAspectRatioSelected, handleDesignAspectRatioSelected );
reducerCreator.addAction( mixModelActions.musicSelected, handleMusicSelected );
reducerCreator.addAction( mixModelActions.musicCleared, handleMusicCleared );
reducerCreator.addAction( mixModelActions.addMediaFileRequest, handleAddMediaFileRequest );
reducerCreator.addAction( mixModelActions.addMediaFileSuccess, handleAddMediaFileSuccess );
reducerCreator.addAction( mixModelActions.addMediaFileFailure, handleAddMediaFileFailure );
reducerCreator.addAction( designsActions.loadControlsSuccess, handleControlsLoaded );
reducerCreator.addAction( mixModelActions.watermarkRemoved, handleWatermarkRemoved );
reducerCreator.addAction( mixModelActions.watermarkEnabled, handleWatermarkEnabled );
reducerCreator.addAction( mixModelActions.mediaMove, handleMediaSwap );
reducerCreator.addAction( mixModelActions.mediaRemove, handleMediaRemove );
reducerCreator.addAction( mixModelActions.videoConvertedDataUploaded, handleVideoConvertedDataUploaded );
reducerCreator.addAction( mixModelActions.videoConvertedDataCleared, handleVideoConvertedDataCleared );
reducerCreator.addAction( mixModelActions.videoTrimmerStarted, handleVideoTrimmerStarted );
reducerCreator.addAction( mixModelActions.videoTrimmerStatusChanged, handleVideoTrimmerStatusChanged );
reducerCreator.addAction( mixModelActions.videoTrimmerClosed, handleVideoTrimmerClosed );
reducerCreator.addAction( mixModelActions.videoTrimmerOriginalDataSaved, handleVideoTrimmerOriginalDataSaved );
reducerCreator.addAction( mixModelActions.setStartingMixTypeAndRelatedData, handleStartingMixTypeSet );
reducerCreator.addAction( mixModelActions.designOutputModeSet, handleDesignOutputModeSet );
reducerCreator.addAction( mixModelActions.facebookAdModeSet, handleFacebookAdModeSet );
reducerCreator.addAction( mixModelActions.addMediaSource, handleAddMediaSource );
reducerCreator.addAction( mixModelActions.logoOverrideClear, handleLogoOverrideClear );
reducerCreator.addAction( mixModelActions.originalMusicClear, handleOriginalMusicClear );
reducerCreator.addAction( mixModelActions.applyBrandSettings, handleApplyBrandSettings );

export default reducerCreator.createReducer();

function handleCommonInputDataUpdate( state: MixModelState, action: Action<Partial<CommonInputData>> ): MixModelState
{
    const existingInputKey = getDesignInputKeyFromCommonInput( state.commonInputData );
    const commonInputData = {
        ...state.commonInputData,
        ...action.payload,
    };
    const newInputKey = getDesignInputKeyFromCommonInput( commonInputData );
    return {
        ...state,
        commonInputData,
        recomputeControlData: existingInputKey !== newInputKey,
    };
}

function handleBrandSlideDataUpdate( state: MixModelState, action: Action<Partial<TemplateInputBrandSlideData>> ): MixModelState
{
    const endCardData = {
        ...state.brandSlideData.endCardData,
        ...action.payload.endCardData,
    };

    const brandSlideData = {
        ...state.brandSlideData,
        ...action.payload,
        endCardData,
    };
    return {
        ...state,
        brandSlideData,
    };
}

function handleDesignControlUpdate( state: MixModelState, action: Action<TemplateControlData> ): MixModelState
{
    if ( !state.selectedDesignId )
    {
        return state;
    }
    const controlData = state.controlDataByDesignId[state.selectedDesignId];
    const designId = state.selectedDesignId;
    return {
        ...state,
        controlDataByDesignId: {
            ...state.controlDataByDesignId,
            [designId]: {
                ...controlData,
                ...action.payload,
            },
        },
    };
}

function handleDesignHasChanged( state: MixModelState ): MixModelState
{
    return {
        ...state,
        hasDesignChanged: true,
    };
}

function handleDesignSelected( state: MixModelState, action: Action<DesignSelectedData> ): MixModelState
{
    if ( !action.payload.design )
    {
        return state;
    }

    return {
        ...state,
        selectedDesignId: action.payload.design.id,
        aspectRatio: action.payload.aspectRatio,
    };
}

function handleStartingDesignSet( state: MixModelState, action: Action<Design> ): MixModelState
{
    if ( !action.payload )
    {
        return state;
    }

    return {
        ...state,
        startingDesignId: action.payload.id,
    };
}

function handleDesignAspectRatioSelected( state: MixModelState, action: Action<AspectRatio> ): MixModelState
{
    return {
        ...state,
        aspectRatio: action.payload,
    };
}

function handleMusicSelected( state: MixModelState, action: Action<MusicSelection> ): MixModelState
{
    return {
        musicId: null,
        musicType: null,
        customMusic: null,
        ...state,
        ...action.payload,
    };
}

function handleMusicCleared( state: MixModelState ): MixModelState
{
    return {
        ...state,
        musicId: null,
        musicType: null,
        customMusic: null,
    };
}

function handleVideoConvertedDataUploaded( state: MixModelState, action: Action<AddMediaAPIResponse> ): MixModelState
{
    const originalMusicType = state.musicType;

    const mediaObject = action.payload.media_object;
    const metadata = mediaObject.metadata;
    const containsAudio = metadata && metadata.contains_audio;

    const shouldSelectVideoAudio = !originalMusicType && containsAudio;

    return {
        ...state,
        videoConvertedData: action.payload,
        musicType: shouldSelectVideoAudio ? MUSIC_TYPE_VIDEO_AUDIO : originalMusicType,
    };
}

function handleVideoConvertedDataCleared( state: MixModelState ): MixModelState
{
    return {
        ...state,
        videoConvertedData: null,
    };
}

function handleVideoTrimmerStarted( state: MixModelState, action: Action<string> ): MixModelState
{
    let videoUrl = action.payload;
    let mediaId = urlUtils.getFilenameFromUrlString( videoUrl );

    const videoTrimmerDataMap = state.videoTrimmerDataMap || {};
    const trimmerData = get( videoTrimmerDataMap, mediaId ) || emptyTrimmerData;
    const isValidTrimmerData = convertToBoolean( trimmerData.url );

    if ( isValidTrimmerData )
    {
        videoUrl = trimmerData.url;
    }
    else
    {
        mediaId = undefined;
    }

    return {
        ...state,
        videoTrimmerCurrentData: {
            ...defaultVideoTrimmerCurrentData,
            videoUrl,
            mediaId,
        },
    };
}

function handleVideoTrimmerClosed( state: MixModelState ): MixModelState
{
    return {
        ...state,
        videoTrimmerCurrentData: {
            ...defaultVideoTrimmerCurrentData,
        },
    };
}

function handleVideoTrimmerStatusChanged( state: MixModelState, action: Action<VideoTrimmerCurrentData> ): MixModelState
{
    const fixedVideoTrimmerCurrentData = {
        ...action.payload,
        videoTrimmerIsReady: convertToBoolean( action.payload.videoTrimmerIsReady ),
        videoDurationInSeconds: convertToNumber( action.payload.videoDurationInSeconds ),
    };

    return {
        ...state,
        videoTrimmerCurrentData: {
            ...state.videoTrimmerCurrentData,
            ...fixedVideoTrimmerCurrentData,
        },
    };
}

function handleVideoTrimmerOriginalDataSaved( state: MixModelState, action: Action<VideoTrimmerDataSavedPayload> ): MixModelState
{
    const originalVideoTrimmerDataMap = state.videoTrimmerDataMap || {};
    const { mediaId, originalTrimmerData } = action.payload;
    return {
        ...state,
        videoTrimmerDataMap: {
            ...originalVideoTrimmerDataMap,
            [mediaId]: originalTrimmerData,
        },
    };
}

function isStartingMixTypeAndRelatedData( payload: SetStartingMixTypeAndRelatedDataPayload ): payload is StartingMixTypeAndRelatedData
{
    return (payload as StartingMixTypeAndRelatedData).startingMixType !== undefined;
}

function handleStartingMixTypeSet( state: MixModelState, action: Action<SetStartingMixTypeAndRelatedDataPayload> ): MixModelState
{
    const startingMixTypeAndRelatedData: StartingMixTypeAndRelatedData = isStartingMixTypeAndRelatedData( action.payload )
                                                                         ? action.payload
                                                                         : { startingMixType: action.payload };

    return {
        ...state,
        ...startingMixTypeAndRelatedData.relatedData,
        startingMixType: startingMixTypeAndRelatedData.startingMixType,
    };
}

function handleDesignOutputModeSet( state: MixModelState, action: Action<DesignOutputMode> ): MixModelState
{
    return {
        ...state,
        designOutputMode: action.payload,
    };
}

function handleFacebookAdModeSet( state: MixModelState, action: Action<boolean> ): MixModelState
{
    return {
        ...state,
        facebookAdMode: action.payload,
    };
}

function handleAddMediaSource( state: MixModelState, action: Action<MediaSourceType> ): MixModelState
{
    const prevMediaSourceList = state.mediaSourceList;
    const mediaSourceList = prevMediaSourceList || [];
    return {
        ...state,
        mediaSourceList: uniq( concat( mediaSourceList, action.payload ) ) as MediaSourceType[],
    };
}

function handleLogoOverrideClear( state: MixModelState )
{
    return {
        ...state,
        logoOverride: null,
    };
}

function handleOriginalMusicClear( state: MixModelState )
{
    return omit( state, "originalMusic" ) as MixModelState;
}

function handleTemplateSettingsSave( state: MixModelState, action: Action<DesignSettingsSavePayload> ): MixModelState
{
    const design = action.payload.design;
    if ( !design || !design.id )
    {
        return state;
    }

    const designAspectRatio = action.payload.designAspectRatio;
    const newDesignTemplateSettings = action.payload.settings;
    const currentDesignTemplateSettings = getTemplateSettingsFromMixModel( state, design, designAspectRatio );

    if ( isEqual( newDesignTemplateSettings, currentDesignTemplateSettings ) )
    {
        return state;
    }

    return {
        ...state,
        templateSettingByDesignId: {
            ...state.templateSettingByDesignId,
            [design.id]: {
                ...state.templateSettingByDesignId[design.id],
                [designAspectRatio]: newDesignTemplateSettings,
            },
        },
    };
}

function handleGlobalSettingsSave( state: MixModelState, action: Action<GlobalSettings> ): MixModelState
{
    if ( isEqual( action.payload, state.globalSettings ) )
    {
        return state;
    }
    return {
        ...state,
        globalSettings: action.payload,
    };
}

function handleCommonInputDataUpdated( state: MixModelState, action: Action<CommonInputData> ): MixModelState
{
    return {
        ...state,
        commonInputData: {
            ...state.commonInputData,
            ...action.payload,
        },
    };
}

function handleMixModelStarted( state: MixModelState, action: Action<MixModelStartPayload> ): MixModelState
{
    const user = action.payload.user;
    const currentBusiness = action.payload.currentBusiness;
    const brandSlideData = getBrandSlideDataFromBusiness( state, currentBusiness );
    const globalSettings = getGlobalSettingsFromBusiness( state, currentBusiness );
    const watermarkManuallyRemoved = !showWatermarkInitialState( user, currentBusiness );
    const mergedState: MixModelState = {
        ...defaultState,
        startingMixType: state.startingMixType,
        designOutputMode: OUTPUT_MODE_ANIMATED,
        watermarkManuallyRemoved,
        brandSlideData,
        globalSettings,
    };

    return computeControlData( mergedState, action.payload );
}

function showWatermarkInitialState( user: UserState, currentBusiness: UserBusiness )
{
    if ( currentBusiness )
    {
        return currentBusiness.show_business_logo_flag;
    }
    else if ( user )
    {
        return user.show_logo_flag;
    }
    return true;
}

function getBrandSlideDataFromBusiness( state: MixModelState, userBusiness: UserBusiness )
{
    const doesBusinessExist = convertToBoolean( userBusiness );
    if ( !doesBusinessExist )
    {
        return state.brandSlideData;
    }

    const endCardData: TemplateInputEndCardData = {
        ...state.brandSlideData.endCardData,
        showLogo: userBusiness.logo_flag,
        tagline: userBusiness.tagline,
        contact: userBusiness.contact,
        color1: userBusiness.end_card_color_one,
        color2: userBusiness.end_card_color_two,
        font1: userBusiness.end_card_font_one,
        font2: userBusiness.end_card_font_two,
        fontCSSUrl1: userBusiness.end_card_font_one_css_url,
        fontCSSUrl2: userBusiness.end_card_font_two_css_url,
    };

    const brandSlideData = {
        ...state.brandSlideData,
        showEndCard: convertToBoolean( userBusiness.show_business_end_card_flag ),
        endCardData,
    };

    return brandSlideData;
}

function getGlobalSettingsFromBusiness( state: MixModelState, userBusiness: UserBusiness )
{
    const doesBusinessExist = convertToBoolean( userBusiness );
    if ( !doesBusinessExist )
    {
        return state.globalSettings;
    }

    const globalSettings = {
        ...state.globalSettings,
        endCardLayoutPositionData: safeParseData( userBusiness.end_card_position_data, null ),
    };

    return globalSettings;
}

function handleMixModelReset(): MixModelState
{
    return {
        ...defaultState,
    };
}

function handleResumeDraft( state: MixModelState, action: Action<Post> ): MixModelState
{
    const newState = {
        ...state,
        postId: action.payload.id,
    };
    return importPostData( newState, action.payload );
}

function handleImportPostDataFromCuratedPost( state: MixModelState, action: Action<Post> ): MixModelState
{
    return importPostData( state, action.payload, true );
}

function handleImportPostDataFromUserPost( state: MixModelState, action: Action<Post> ): MixModelState
{
    return importPostData( state, action.payload );
}

function handleImportMediaFilesFromPost( state: MixModelState, action: Action<Post> ): MixModelState
{
    const newState = {
        ...state,
        commonInputData: {
            ...state.commonInputData,
            originalImageList: action.payload.input_media_files || state.commonInputData.originalImageList,
        },
    };
    return newState;
}

function handleApplyBrandSettings( state: MixModelState, action: Action<UserBusiness> )
{
    const designId: number = state.selectedDesignId;
    const storeState = store.getState();
    const controls = getControlsConfigsByDesignId( storeState, designId );
    const currentBusiness = action.payload;
    const brandStyleAppliedControlData = getMergedBrandSettingsControlData( state, controls, "" + designId, currentBusiness );

    return {
        ...state,
        controlDataByDesignId: {
            ...state.controlDataByDesignId,
            [designId]: brandStyleAppliedControlData,
        },
    };
}

function determineBusinessActionExampleIdFromPost( post: Post )
{
    if ( post.send_status === SEND_STATUS_DRAFT )
    {
        return post.business_action_example_post_id;
    }
    else
    {
        return post.id;
    }
}

function getBackgroundMediaControlInDesign( controlConfigsForDesign )
{
    return find( controlConfigsForDesign, ( controlConfig ) =>
    {
        return controlConfig.type === EDITOR_TYPES.BACKGROUND_MEDIA_CONTROL;
    } );
}

function findControlBeingReplacedByBackgroundMediaControl( backgroundMediaControlId, controlConfigsForDesign )
{
    return find( controlConfigsForDesign, ( controlData ) =>
    {
        const suppressValue = controlData.suppressWhenHaveReplacementBackgroundMedia;
        return suppressValue
               && suppressValue.idOfReplacementControl
               && (backgroundMediaControlId === suppressValue.idOfReplacementControl);
    } );
}

function constructBackgroundMediaControl( controlConfigsForDesign, parsedControlData )
{
    const designBackgroundMediaControl = getBackgroundMediaControlInDesign( controlConfigsForDesign );
    const hasBackgroundMediaControlInDesign = !!designBackgroundMediaControl;
    const hasBackgroundMediaDataInPost = !!parsedControlData.backgroundMedia;
    const replacementControl = findControlBeingReplacedByBackgroundMediaControl( EDITOR_TYPES.BACKGROUND_MEDIA_CONTROL,
        controlConfigsForDesign );
    const hasReplacementControl = !!replacementControl;

    if ( hasBackgroundMediaControlInDesign && !hasBackgroundMediaDataInPost && hasReplacementControl )
    {
        const controlIdToMigrate = replacementControl.id;
        const controlValue = parsedControlData[controlIdToMigrate];
        const hasControlValue = !!controlValue;
        if ( hasControlValue && typeof controlValue === "string" )
        {
            return {
                backgroundMedia: {
                    color: {
                        type: "hex",
                        value: controlValue,
                    },
                    media: null,
                },
            };
        }
    }
    return null;
}

function processControlData( parsedControlData, controlConfigsForDesign )
{
    const migratedBackgroundMediaControlData = constructBackgroundMediaControl( controlConfigsForDesign, parsedControlData );
    if ( migratedBackgroundMediaControlData )
    {
        return {
            ...parsedControlData,
            ...migratedBackgroundMediaControlData,
        };
    }
    return parsedControlData;
}

export function importPostShouldShowLogo( storeState: StoreState, designParsedTemplateData, isFromCuratedPost: boolean )
{
    const currentBusiness = getCurrentBusiness( storeState );
    const currentUser = getCurrentUser( storeState );

    const showLogoBusinessSettings = showWatermarkInitialState( currentUser, currentBusiness );
    const showLogoTemplateData = !parseHideWatermarkFlagFromTemplateData( designParsedTemplateData );

    if ( isFromCuratedPost )
    {
        return showLogoBusinessSettings;
    }

    return showLogoTemplateData;
}

function importPostData( state: MixModelState, post: Post, isFromCuratedPost?: boolean ): MixModelState
{
    // A note on input_media_url_list and input_media_files: input_media_files contains the
    // image info necessary to properly resume drafts, which is why this code uses it.
    // input_media_url_list is not used by this code because other logic handles images
    // when copying and starting customization for non-drafts (ex: inspirations).
    const storeState = store.getState();
    const {
        template_input_data,
        design_settings_data,
        global_settings_data,
        design_slug,
        music_settings_data,
        music_type,
        input_media_url_list,
        aspect_ratio,
    } = post;
    const designId = getDesignIdFromSlug( storeState, design_slug );
    const designParsedTemplateData = safeParseData( template_input_data );
    const designParsedTemplateSettings = safeParseData( design_settings_data );
    const globalSettings = {
        ...safeParseData( global_settings_data ),
        endCardLayoutPositionData: state.globalSettings.endCardLayoutPositionData,
    };
    const musicSettingsData = safeParseData( music_settings_data );
    const customMusic = musicHelper.isMusicTypePersonal( music_type ) ? { ...musicSettingsData, category: CUSTOM_MUSIC_CATEGORY } : null;
    const videoUrl = getFirstVideoUrlFromMediaUrlList( input_media_url_list );
    const outputMode = determineOutputMode( designParsedTemplateData.designOutputMode, post.output_type );
    const showEndCard = parseShowEndCardFromTemplateData( designParsedTemplateData );
    const logoOverride = isFromCuratedPost ? null : getLogoOverrideFromTemplateData( designParsedTemplateData );

    const brandSlideData =
        {
            ...state.brandSlideData,
            showEndCard: isFromCuratedPost ? state.brandSlideData.showEndCard : showEndCard,
        };

    const design = getDesign( storeState, designId );
    const controlConfigsForDesign = getDesignControlsConfig( storeState, design );
    const controlData = processControlData( designParsedTemplateData.controlData, controlConfigsForDesign.controls );
    const shouldShowLogo = importPostShouldShowLogo( storeState, designParsedTemplateData, isFromCuratedPost );

    return {
        ...state,
        aspectRatio: aspect_ratio,
        commonInputData: {
            originalHeadlineText: post.input_headline_text,
            originalText: post.input_body_text,
            originalImageList: post.input_media_files || state.commonInputData.originalImageList,
        },
        brandSlideData,
        globalSettings,
        initialControlDataByDesignId: {
            ...state.controlDataByDesignId,
            [designId]: controlData,
        },
        controlDataByDesignId: {
            ...state.controlDataByDesignId,
            [designId]: controlData,
        },
        templateSettingByDesignId: {
            ...state.templateSettingByDesignId,
            [designId]: {
                ...state.templateSettingByDesignId[designId],
                [aspect_ratio]: designParsedTemplateSettings,
            },
        },
        musicId: post.music_id || (musicSettingsData && musicSettingsData.id),
        musicType: music_type,
        customMusic,
        originalMusic: musicSettingsData,
        watermarkManuallyRemoved: !shouldShowLogo,
        originalTrimmerData: {
            ...state.originalTrimmerData,
            url: videoUrl,
        },
        businessActionExamplePostId: determineBusinessActionExampleIdFromPost( post ),
        designOutputMode: outputMode,
        logoOverride,
    };
}

function parseHideWatermarkFlagFromTemplateData( designParsedTemplateData ): boolean
{
    const { hideWatermark } = designParsedTemplateData;
    return typeof hideWatermark === "string" && "true" === hideWatermark;
}

function parseShowEndCardFromTemplateData( designParsedTemplateData ): boolean
{
    const { showEndCard } = designParsedTemplateData;
    return typeof showEndCard === "string" && "true" === showEndCard;
}

function getLogoOverrideFromTemplateData( designParsedTemplateData ): string
{
    const { fanzoLogoURL } = designParsedTemplateData;
    return !urlUtils.isLocalhostUrl( fanzoLogoURL ) ? fanzoLogoURL : null;
}

function determineOutputMode( designOutputMode: DesignOutputMode, postOutputType: string ): DesignOutputMode
{
    if ( designOutputMode === undefined )
    {
        return deriveDesignOutputModeFromPostOutputType( postOutputType );
    }
    return designOutputMode;
}

export function safeParseData( input: string, defaultValue: any = {} )
{
    if ( !input )
    {
        return defaultValue;
    }
    return JSON.parse( input );
}

function handleComputeControlData( state: MixModelState, action: Action<ComputeControlPayload> ): MixModelState
{
    return computeControlData( state, action.payload );
}

function computeControlData( state: MixModelState, controlsByDesignId: ComputeControlPayload ): MixModelState
{
    const { controlsConfigsLookup, currentBusiness } = controlsByDesignId;
    if ( !state.recomputeControlData )
    {
        return state;
    }
    const mergedControlDataByDesignId = mapValues( controlsConfigsLookup,
        ( controls, designId ) => getMergedControlData( state, controls, designId, currentBusiness ) );
    const controlDataByDesignId = merge( mergedControlDataByDesignId, state.controlDataByDesignId );
    return {
        ...state,
        controlDataByDesignId,
        recomputeControlData: false,
    };
}

function initializeControlData( controlsConfig: ControlsConfig, currentBusiness: UserBusiness ): TemplateControlData
{
    if ( !controlsConfig )
    {
        return;
    }
    const controlData = {};
    const supportedControls = getControlsSupported( controlsConfig.controls );
    forEach( supportedControls, ( config ) =>
    {
        const data = getDefaultValue( config, currentBusiness );

        if ( EDITOR_TYPES.BACKGROUND_MEDIA_CONTROL === config.type )
        {
            controlData[config.id] = (data as BackgroundChoice);
        }
        else if ( includes( ASSET_CONTROL_TYPES, config.type ) )
        {
            controlData[config.id] = (data as AssetChoice).jsonBlob;
        }
        else
        {
            controlData[config.id] = data;
        }
    } );
    return controlData;
}

function getBrandColorFromBusinessForColorControl( currentBusiness: UserBusiness, colorConfig: ColorControlConfig )
{
    if ( currentBusiness )
    {
        const brandValue = currentBusiness[colorConfig.brand + "_color"];
        if ( brandValue )
        {
            return brandValue;
        }
    }
}

function getBrandColorFromBusinessForBackgroundMediaControl( currentBusiness: UserBusiness, backgroundMediaConfig: BackgroundMediaControlConfig )
{
    if ( currentBusiness )
    {
        const brandValue = currentBusiness[backgroundMediaConfig.brand + "_color"];
        if ( brandValue )
        {
            return brandValue;
        }
    }
}

function getBrandFontFromBusiness( currentBusiness: UserBusiness, fontConfig: FontControlConfig )
{
    if ( currentBusiness && hasBrandFont( currentBusiness, fontConfig.brand ) )
    {
        return generateFontDataForBrandFont( currentBusiness, fontConfig.brand );
    }
}

export function getDefaultValue( config: IControlConfig | BackgroundMediaControlConfig, currentBusiness: UserBusiness ): ControlDataValue
{
    switch ( config.type )
    {
        case EDITOR_TYPES.ASSET_CONTROL:
            const defaultIndex = (config as AssetControlConfig).default;
            const choices = (config as AssetControlConfig).choices;
            if ( typeof defaultIndex === "number" && choices[defaultIndex] )
            {
                return choices[defaultIndex] as AssetChoice;
            }
            return head( shuffle( choices ) );
        case EDITOR_TYPES.COLOR_CONTROL:
            const colorConfig = config as ColorControlConfig;
            return getBrandColorFromBusinessForColorControl( currentBusiness, colorConfig ) || head( shuffle( colorConfig.default ) );
        case EDITOR_TYPES.BACKGROUND_MEDIA_CONTROL:
            const backgroundMediaConfig = config as BackgroundMediaControlConfig;
            const brandColorFromBusiness = getBrandColorFromBusinessForBackgroundMediaControl( currentBusiness, backgroundMediaConfig );
            const backgroundMediaDefault = backgroundMediaConfig.default;
            return {
                ...backgroundMediaDefault,
                color: {
                    ...backgroundMediaDefault.color,
                    value: brandColorFromBusiness || backgroundMediaDefault.color.value,
                },
            };
        case EDITOR_TYPES.FONT_CONTROL:
            const fontConfig = config as FontControlConfig;
            return getBrandFontFromBusiness( currentBusiness, fontConfig )
                   || generateFontDataForFontName( head( (config as FontControlConfig).fonts ) );
        case EDITOR_TYPES.MODEL_LINKED_PHOTO_CONTROL:
            return head( (config as ModelLinkedPhotoAssetControlConfig).choices );
        case EDITOR_TYPES.MODEL_LINKED_TEXT_CONTROL:
            return head( (config as ModelLinkedTextAssetControlConfig).choices );
        // TODO handle all config types e.g. modelLinkedTextAsset
    }
    return undefined;
}

function handlePostCreateSuccess( state: MixModelState, action: Action<NormalizrData> ): MixModelState
{
    return {
        ...state,
        postId: action.payload.result,
    };
}

// TODO Consider a better location for this, to make re-use easier
function swapItems( originalList: any[], oldIndex: number, newIndex: number, copyProperties?: string[] )
{
    if ( copyProperties && copyProperties.length > 0 )
    {
        const oldItem = originalList[oldIndex];
        const newItem = originalList[newIndex];
        copyProperties.forEach( ( propName ) =>
        {
            const newPropValue = newItem[propName];
            newItem[propName] = oldItem[propName];
            oldItem[propName] = newPropValue;
        } );
    }

    const newList = originalList.slice( 0 );
    newList.splice( newIndex, 0, newList.splice( oldIndex, 1 )[0] );
    return newList;
}

function handleMediaSwap( state: MixModelState, action: Action<MediaSwapPayload> ): MixModelState
{
    const { oldIndex, newIndex } = action.payload;
    const { commonInputData } = state;
    const { originalImageList } = commonInputData;
    const newUrlList = swapItems( originalImageList, oldIndex, newIndex, ["order"] );
    return {
        ...state,
        commonInputData: {
            ...commonInputData,
            originalImageList: newUrlList,
        },
    };
}

// TODO Consider a better location for this, to make re-use easier
function deleteItem( originalList: any[], index: number )
{
    const newList = originalList.slice( 0 );
    newList.splice( index, 1 );
    return newList;
}

function handleMediaRemove( state: MixModelState, action: Action<number> ): MixModelState
{
    const existingInputKey = getDesignInputKeyFromCommonInput( state.commonInputData );
    const { originalImageList } = state.commonInputData;
    const newFileList = deleteItem( originalImageList, action.payload );
    const commonInputData: CommonInputData = {
        ...state.commonInputData,
        originalImageList: newFileList,
    };

    const newControlData = controlDataWithUserChoicesUpdated( state, newFileList, store.getState().designs );

    const newInputKey = getDesignInputKeyFromCommonInput( commonInputData );
    return {
        ...state,
        commonInputData,
        recomputeControlData: existingInputKey !== newInputKey,
        controlDataByDesignId: {
            ...state.controlDataByDesignId,
            ...newControlData,
        },
    };
}

function handleAddMediaFileSuccess( state: MixModelState, action: Action<ImageUploadData> ): MixModelState
{
    if ( action.payload && action.payload.uploadFields )
    {
        const { id, s3_direct_url, media_type, original_file_name, media_object } = action.payload.uploadFields;
        const metadata = media_object && media_object.metadata;
        const { isLowRes } = action.payload;
        if ( media_type === INPUT_MEDIA_TYPE )
        {
            const { originalImageList } = state.commonInputData;
            const newImage: PostInputMediaFile = {
                id,
                url: s3_direct_url,
                isLowRes,
                original_file_name,
                metadata,
            };

            const newImageList = reinsertMediaAtOriginalIndex( originalImageList, action.payload, newImage );
            const commonInputData: CommonInputData = {
                ...state.commonInputData,
                originalImageList: newImageList,
            };

            const existingInputKey = getDesignInputKeyFromCommonInput( state.commonInputData );
            const newInputKey = getDesignInputKeyFromCommonInput( commonInputData );
            return {
                ...state,
                lastError: undefined,
                mediaUploads: Math.max( state.mediaUploads - 1, 0 ),
                commonInputData,
                recomputeControlData: existingInputKey !== newInputKey,
            };
        }
    }

    return state;
}

function handleAddMediaFileFailure( state: MixModelState, action: Action<PostMediaType> ): MixModelState
{
    if ( action.payload === INPUT_MEDIA_TYPE )
    {
        return {
            ...state,
            mediaUploads: Math.max( state.mediaUploads - 1, 0 ),
        };
    }
    return state;
}

function handleAddMediaFileRequest( state: MixModelState, action: Action<PostMediaType> ): MixModelState
{
    if ( action.payload === INPUT_MEDIA_TYPE )
    {
        return {
            ...state,
            mediaUploads: (state.mediaUploads || 0) + 1,
        };
    }
    return state;
}

function handleControlsLoaded( state: MixModelState, action: Action<DesignControlsJSONData> ): MixModelState
{
    const controlsData = getMergedControlData( state, action.payload.controls, action.payload.designId, action.payload.currentBusiness );
    return {
        ...state,
        controlDataByDesignId: {
            ...state.controlDataByDesignId,
            [action.payload.designId]: controlsData,
        },
    };
}

function handleBrandSettingsUpdated( state: MixModelState, action: Action<DesignControlsJSONData> ): MixModelState
{
    const controlsData = getMergedBrandSettingsControlData( state, action.payload.controls, action.payload.designId, action.payload.currentBusiness );
    return {
        ...state,
        controlDataByDesignId: {
            ...state.controlDataByDesignId,
            [action.payload.designId]: controlsData,
        },
    };
}

function handleWatermarkRemoved( state: MixModelState ): MixModelState
{
    return {
        ...state,
        watermarkManuallyRemoved: true,
    };
}

function handleWatermarkEnabled( state: MixModelState ): MixModelState
{
    return {
        ...state,
        watermarkManuallyRemoved: false,
    };
}

function reinsertMediaAtOriginalIndex( originalImageList: PostInputMediaFile[], imageUploadData: ImageUploadData, newImage: PostInputMediaFile )
{
    const { insertAtIndex, replaceAtIndex } = imageUploadData;

    const newImageList = [...originalImageList];

    if ( isNumber( insertAtIndex ) )
    {
        newImageList.splice( insertAtIndex, 0, newImage );
    }
    else if ( isNumber( replaceAtIndex ) )
    {
        newImageList[replaceAtIndex] = newImage;
    }
    else
    {
        newImageList.push( newImage );
    }

    return newImageList;
}

export function mergeCurrentAndDefaultControlData( currentControlData, defaultControlData: TemplateControlData ): TemplateControlData
{
    const safeCurrentControlData = currentControlData || {};
    return {
        ...defaultControlData,
        ...safeCurrentControlData,
    };
}

function getMergedControlData( state: MixModelState,
                               controls: ControlsConfig[],
                               designId: string,
                               currentBusiness: UserBusiness ): TemplateControlData
{
    const defaultControlData = getDefaultControlData( state, controls, designId, currentBusiness );
    const currentControlData = state.controlDataByDesignId[designId];
    return mergeCurrentAndDefaultControlData( currentControlData, defaultControlData );
}

export function getBrandSettingsControlData( controlsConfig: ControlsConfig, currentBusiness: UserBusiness )
{
    if ( !currentBusiness || !controlsConfig )
    {
        return {};
    }
    const controlData = {};
    forEach( controlsConfig.controls, ( config ) =>
    {
        switch ( config.type )
        {
            case EDITOR_TYPES.COLOR_CONTROL:
                const colorConfig = config as ColorControlConfig;
                const brandColor = getBrandColorFromBusinessForColorControl( currentBusiness, colorConfig );
                if ( brandColor )
                {
                    controlData[config.id] = brandColor;
                }
                break;
            case EDITOR_TYPES.FONT_CONTROL:
                const fontConfig = config as FontControlConfig;
                const brandFont = getBrandFontFromBusiness( currentBusiness, fontConfig );
                if ( hasBrandFont( currentBusiness, fontConfig.brand ) )
                {
                    controlData[config.id] = brandFont;
                }
        }
    } );
    return controlData;
}

function getMergedBrandSettingsControlData( state: MixModelState,
                                            controls: ControlsConfig[],
                                            designId: string,
                                            currentBusiness: UserBusiness ): TemplateControlData
{
    if ( !controls || !designId )
    {
        return;
    }
    const inputKey = getDesignInputKeyFromCommonInput( state.commonInputData );

    const controlsConfig = getControlsForKey( controls, inputKey );
    if ( !controlsConfig )
    {
        return;
    }
    const brandSettingsControlData = getBrandSettingsControlData( controlsConfig, currentBusiness );

    if ( !brandSettingsControlData )
    {
        return;
    }
    const safeCurrentControlData = state.controlDataByDesignId[designId] || {};

    return {
        ...safeCurrentControlData,
        ...brandSettingsControlData,
    };
}

function getDefaultControlData( state: MixModelState,
                                controls: ControlsConfig[],
                                designId: string,
                                currentBusiness: UserBusiness )
{
    if ( !controls || !designId )
    {
        return;
    }
    const inputKey = getDesignInputKeyFromCommonInput( state.commonInputData );
    const defaultControlData = initializeControlData( getControlsForKey( controls, inputKey ), currentBusiness );
    if ( !defaultControlData )
    {
        return;
    }
    return defaultControlData;
}

export type DesignInputDataParameters = [TemplateInputData, DesignTemplateSettings, GlobalSettings, boolean, boolean, string];

export function getEditorInputDataParameters( state: StoreState ): DesignInputDataParameters
{
    const design = getSelectedDesign( state );
    const designAspectRatio = getDesignAspectRatio( state );
    const templateSettings = getTemplateSettings( state, design, designAspectRatio );
    return buildDesignInputDataParameters( state, templateSettings, state.mixModel.globalSettings, false, design, DESIGN_DISPLAY_MODE_EDITOR );
}

export function getBrandSlideInputDataParameters( state: StoreState ): DesignInputDataParameters
{
    const design = getBrandSlideDesign( state );
    const designAspectRatio = getDesignAspectRatio( state );
    const templateSettings = getTemplateSettings( state, design, designAspectRatio );
    return buildDesignInputDataParameters( state, templateSettings, state.mixModel.globalSettings, false, design, DESIGN_DISPLAY_MODE_EDITOR );
}

export function getAnimatedPreviewInputDataParameters( state: StoreState, design: Design ): DesignInputDataParameters
{
    const designAspectRatio = getDesignAspectRatio( state );
    const templateSettings = getTemplateSettings( state, design, designAspectRatio );
    return [getTemplateInputData( state, design, true, OUTPUT_MODE_STATIC ), templateSettings,
            state.mixModel.globalSettings, false,
            false, DESIGN_DISPLAY_MODE_ANIMATED_PREVIEW];
}

export function getPreviewInputDataParameters( state: StoreState, design: Design ): DesignInputDataParameters
{
    const designAspectRatio = getDesignAspectRatio( state );
    const templateSettings = getTemplateSettings( state, design, designAspectRatio );
    return buildDesignInputDataParameters( state, templateSettings, state.mixModel.globalSettings, true, design, DESIGN_DISPLAY_MODE_PREVIEW );
}

function buildDesignInputDataParameters( state: StoreState, templateSettings: DesignTemplateSettings, globalSettings: GlobalSettings,
                                         isPreview: boolean, design: Design, designDisplayMode: DesignDisplayMode ): DesignInputDataParameters
{
    const templateInputData = getTemplateInputData( state, design, true, getDesignOutputMode( state.mixModel ) );
    return [templateInputData, templateSettings, globalSettings, isPreview,
            false, designDisplayMode];
}

export function getDesignOutputMode( mixModel: MixModelState )
{
    return mixModel.designOutputMode;
}

function getDesignFeatureFlags(): DesignFeatureFlags
{
    return {
        supportExtraCaptions: true,
        supportBackgroundMediaControl: true,
        [apptimizeVariables.SHOULD_SUPPORT_PRESET_SLIDE_TRANSITIONS]: apptimizeVariables.shouldSupportPresetSlideTransitions(),
        [apptimizeVariables.SHOULD_ENABLE_PRESET_TEXT]: apptimizeVariables.shouldEnablePresetText(),
        [apptimizeVariables.SHOULD_ENABLE_DYNAMIC_SLIDES]: apptimizeVariables.shouldEnableDynamicSlides(),
    };
}

function getBrandSlideData( mixModel: MixModelState ): TemplateInputBrandSlideData
{
    const brandSlideData: TemplateInputBrandSlideData = mixModel.brandSlideData || defaultTemplateInputBrandSlideData;
    const origEndCardData: TemplateInputEndCardData = mixModel.brandSlideData.endCardData || defaultTemplateInputEndCardData;
    const showLogo = origEndCardData.showLogo.toString();

    const endCardData = {
        ...origEndCardData,
        showLogo,
        shouldShowEndCardImprovements: origEndCardData.shouldShowEndCardImprovements.toString(),
    };
    return {
        ...brandSlideData,
        showEndCard: brandSlideData.showEndCard.toString(),
        showEndCardInEditor: brandSlideData.showEndCardInEditor.toString(),
        endCardData,
    };
}

export function getTemplateInputData( state: StoreState, design: Design, convertForDesign: boolean,
                                      designOutputMode: DesignOutputMode ): TemplateInputData
{
    if ( !design )
    {
        return;
    }

    const { mixModel } = state;
    const { ...commonInputData } = mixModel.commonInputData;
    const businessFontDefaults = getBusinessFontDefaults( state );

    const templateData: TemplateInputData = {
        ...commonInputData,
        ...businessFontDefaults,
        isRiplPro: true,
        fanzoLogoURL: getWatermarkUrl( state ),
        hideWatermark: getHideWatermark( state ),
        templateFolder: getDesignTemplateURI( design ),
        controlData: getCanvasControlData( state, design ),
        maxVideoDuration: getDesignMaxDuration( state ),
        originalImageUrlList: getDesignThumbnailUrls( state ),
        mediaList: getMediaDataForDesign( state, convertForDesign ),
        initialInputs: getDesignInputKeyFromCommonInput( mixModel.commonInputData ),
        designOutputMode,
        featureFlags: getDesignFeatureFlags(),
        devMode: getDevMode( state ),
        isContentContributor: isContentContributor( state ),
        ...getBrandSlideData( mixModel ),
    };

    // TODO if we change the backend to store isRiplPro as booleans, we should move this back into the convertForDesign
    templateData.isRiplPro = templateData.isRiplPro.toString();
    templateData.hideWatermark = templateData.hideWatermark.toString();
    if ( convertForDesign )
    {
        templateData.fanzoLogoURL = stringUtils.getCORSFriendlyUrl( templateData.fanzoLogoURL );
        templateData.originalImageUrlList = map( getDesignThumbnailUrls( state ), stringUtils.getCORSFriendlyUrl );
    }

    return templateData;
}

function getBusinessFontDefaults( state: StoreState ): TemplateFontInputData
{
    const business = getCurrentBusiness( state );

    const fontDefaults: TemplateFontInputData = {
        primaryFont: business && business.primary_font,
        secondaryFont: business && business.secondary_font,
    };

    if ( business && business.secondary_font_css_url )
    {
        fontDefaults.secondaryFont = business.secondary_font_css_url;
    }

    return fontDefaults;
}

export function getWatermarkUrl( state: StoreState )
{
    return state.mixModel.logoOverride || getBrandLogo( state );
}

export function getHideWatermark( state: StoreState )
{
    return state.mixModel.watermarkManuallyRemoved;
}

export function updateMixModelAfterInputMediaUpload( dispatch: Dispatch<StoreState>, data: ImageUploadData )
{
    const state = store.getState();
    const { uploadFields, fileSize } = data;

    const activeMediaObject = getActiveDesignMediaObject( state );
    if ( activeMediaObject )
    {
        data = {
            ...data,
            replaceAtIndex: activeMediaObject.index,
        };

        const canvas = getCustomizableCanvas( state );
        if ( canvas )
        {
            canvas.tellDesignToReplaceMedia( activeMediaObject.id, stringUtils.getFileName( data.uploadFields.s3_direct_url ) );
        }
    }

    dispatch( mixModelActions.addMediaFileSuccess( data ) );
    eventTracker.logMediaUploadSucceededEvent( state, uploadFields, fileSize );

    if ( uploadFields && uploadFields.media_type === INPUT_MEDIA_TYPE && doesPostInputMediaWithUrlContainAudio( state,
        data.uploadFields.s3_direct_url ) )
    {
        selectVideoAudio( state, dispatch );
    }

    const currentBusiness = getCurrentBusiness( state );
    dispatch( mixModelActions.computeControlData( {
        controlsConfigsLookup: getControlsConfigsLookup( state ),
        currentBusiness,
    } ) );

    if ( activeMediaObject )
    {
        modalServices.closeLightBoxesWithIdentifier( dispatch, state, LightboxDialogIdentifierForKey.REPLACE_MEDIA_PICKER );
        dispatch( uiActions.replaceMediaModeEnded() );
    }
}

export function updateMixModelAfterOutputMediaUpload( dispatch: Dispatch<StoreState>, data: ImageUploadData )
{
    const state = store.getState();
    const { uploadFields, fileSize } = data;

    dispatch( mixModelActions.addMediaFileSuccess( data ) );
    eventTracker.logMediaUploadSucceededEvent( state, uploadFields, fileSize );
}

function ensureScheduledSendDateIsAfterNow( scheduledSendDatetime: string )
{
    return isBefore( new Date(), parse( scheduledSendDatetime ) ) ? scheduledSendDatetime : null;
}

function determineMixModelMusicType( state: StoreState, selectedMusic: Music, isVideoAudio: boolean = false ): MusicType
{
    if ( selectedMusic && selectedMusic.type )
    {
        return selectedMusic.type;
    }
    else if ( selectedMusic )
    {
        const availableCustomMusic = getCustomMusicFromMusicCatalog( state );
        return areMusicItemsEqual( selectedMusic, availableCustomMusic ) ? MUSIC_TYPE_PERSONAL_MUSIC : MUSIC_TYPE_RIPL_MUSIC;
    }
    else if ( isVideoAudio || isVideoAudioSelected( state ) )
    {
        return MUSIC_TYPE_VIDEO_AUDIO;
    }

    return null;
}

export function generateMusicSelectionData( state: StoreState, selectedMusic?: Music, isVideoAudio: boolean = false ): MusicSelection
{
    const musicId = selectedMusic && selectedMusic.id;
    const musicType = determineMixModelMusicType( state, selectedMusic, isVideoAudio );
    return {
        musicId,
        musicType,
        customMusic: musicHelper.isMusicTypePersonal( musicType ) ? selectedMusic : null,
    };
}

function updateMusicSettingsData( postData: Partial<PostAPI>, state: StoreState )
{
    const musicSelection = getMixModelMusicSelection( state );
    postData.music_type = musicSelection.musicType;
    postData.music_id = musicSelection.musicId && !isMusicTypeEpidemicSound( musicSelection.musicType ) ? musicSelection.musicId as number : null;
    postData.music_settings_data = getJsonStringOfMusicSettingsData( musicSelection, state );
}

export function getOriginalMixModelMusic( state: StoreState )
{
    return state.mixModel.originalMusic;
}

function getJsonStringOfMusicSettingsData( musicSelection: MusicSelection, state: StoreState ): string
{
    const musicSettingsData = getMusicSettingsData( musicSelection, state );
    if ( musicSettingsData )
    {
        return JSON.stringify( musicSettingsData );
    }
    else
    {
        return null;
    }
}

export function getMusicSettingsData( musicSelection: MusicSelection, state: StoreState ): Music
{
    if ( musicHelper.isMusicTypeEpidemicSound( musicSelection.musicType ) )
    {
        return getEpidemicSoundMusicData( state, musicSelection );
    }
    else if ( musicHelper.isMusicTypePersonal( musicSelection.musicType ) )
    {
        return getPersonalMusicData( musicSelection );
    }
    else if ( musicHelper.isMusicTypeVideoAudio( musicSelection.musicType ) )
    {
        return getVideoAudioMusicData( state );
    }
    else if ( musicHelper.isMusicTypeRipl( musicSelection.musicType ) )
    {
        const musicChoice = getCatalogMusicById( state, musicSelection.musicId as number );
        if ( musicChoice )
        {
            return getRiplMusicData( musicSelection, state );
        }
    }
    return null;
}

export function getPostAPIData( state: StoreState ): Partial<PostAPI>
{
    const { mixModel } = state;
    const selectedDesignId = getSelectedDesignId( state );
    const designTemplateSettings = mixModel.templateSettingByDesignId[selectedDesignId];
    const postData: Partial<PostAPI> = {
        visible: true,
        aspect_ratio: mixModel.aspectRatio,
        global_settings_data: JSON.stringify( mixModel.globalSettings ),
        design_settings_data: JSON.stringify( designTemplateSettings
                                              ? designTemplateSettings[mixModel.aspectRatio] : {} ),
        catalog_version_id: CATALOG_VERSION.toString(),
        content: getCaption( state ),
        output_type: getOutputType( state ),
        post_type: POST_TYPE_RIPL,
        user_business_id: getCurrentBusinessId( state ),
        scheduled_send_at: ensureScheduledSendDateIsAfterNow( getScheduledSendDatetime( state ) ),
        business_action_example_post_id: getBusinessActionExamplePostId( state ),
    };

    updateSocialNetworkAccountFields( postData, state );
    updateMusicSettingsData( postData, state );

    const theDesign = getSelectedDesign( state );
    if ( theDesign )
    {
        postData.design_slug = theDesign.slug;
        const templateInputData = getTemplateInputData( state, theDesign, false, getDesignOutputMode( state.mixModel ) );
        if ( templateInputData )
        {
            const { originalHeadlineText, originalText } = templateInputData;
            postData.input_body_text = originalText;
            postData.input_headline_text = originalHeadlineText;
            postData.template_input_data = JSON.stringify( templateInputData );
        }

        const { originalImageList } = mixModel.commonInputData;
        if ( originalImageList )
        {
            postData.input_media_file_ids = map( originalImageList, ( inputMediaFile ) => inputMediaFile.id );
        }
    }

    const theImageUrl = getImageUrl( state );
    if ( theImageUrl )
    {
        postData.image_url = theImageUrl;
    }

    const theVideoUrl = getVideoUrl( state );
    if ( theVideoUrl )
    {
        postData.video_url = theVideoUrl;
    }
    return postData;
}

function updateSocialNetworkAccountFields( postData: Partial<PostAPI>, state: StoreState )
{
    postData.facebook_flag = getFacebookEnabled( state );

    const enabledSocialNetworkIds = getEnabledSocialNetworkIds( state ).slice();
    postData.post_to_social_network_accounts_list = enabledSocialNetworkIds;
    postData.save_to_photos_flag = getSaveToComputerEnabled( state );

    const userSocialNetworkAccounts = getUserSocialNetworkAccounts( state );
    const socialNetworkAccountFieldNamesAndValues = getFieldNamesForSocialNetworks( enabledSocialNetworkIds, userSocialNetworkAccounts );
    forEach( socialNetworkAccountFieldNamesAndValues, ( fieldNameAndValue ) =>
    {
        postData[fieldNameAndValue.flagName] = fieldNameAndValue.value;
    } );
}

interface SocialAccountFlagNamesAndValues
{
    flagName: string;
    value: boolean;
}

export function getDesignAspectRatio( state: StoreState )
{
    return state.mixModel.aspectRatio || SQUARE_ASPECT_RATIO;
}

export function getFieldNamesForSocialNetworks( enabledSocialNetworkIds: number[],
                                                userSocialNetworkAccounts: SocialNetworkAccount[] ): SocialAccountFlagNamesAndValues[]
{
    return map( POST_API_SOCIAL_NETWORK_FLAGS_MAP, ( flagName, accountType ) =>
    {
        const enabledUserSocialNetworkAccount = userSocialNetworkAccounts.find( ( socialNetworkAccount ) =>
        {
            return accountType === socialNetworkAccount.account_type &&
                   includes( enabledSocialNetworkIds, socialNetworkAccount.id );
        } );
        const value = convertToBoolean( enabledUserSocialNetworkAccount );
        return { flagName, value };
    } );
}

export const getPostId = ( state: StoreState ): number => state.mixModel.postId || null;

export function getTemplateSettings( state: StoreState, design: Design, designAspectRatio: AspectRatio ): DesignTemplateSettings
{
    return getTemplateSettingsFromMixModel( state.mixModel, design, designAspectRatio );
}

function getTemplateSettingsFromMixModel( mixModel: MixModelState, design: Design, designAspectRatio: AspectRatio ): DesignTemplateSettings
{
    if ( design && mixModel.templateSettingByDesignId[design.id] )
    {
        const templateSettingsByAspectRatio = mixModel.templateSettingByDesignId[design.id];
        return designAspectRatio && templateSettingsByAspectRatio[designAspectRatio];
    }
}

export function getGlobalSettings( state: StoreState ): GlobalSettings
{
    return state.mixModel.globalSettings;
}

export function getCanvasControlData( state: StoreState, design: Design ): TemplateControlData
{
    return state.mixModel.controlDataByDesignId[design.id] || {};
}

export function hasDataRequiredToLoad( state: StoreState, design: Design ): boolean
{
    const canvasControlData = getCanvasControlData( state, design );
    const numberOfControlsConfigDataIds = keys( canvasControlData ).length;
    return numberOfControlsConfigDataIds > 0;
}

export function getDesignTemplateURI( design: Design )
{
    return "/templates/" + stringUtils.convertDesignNameFromKebabCaseToCamelCase( design.slug );
}

export const getOriginalImageList = ( state: StoreState ) => state.mixModel.commonInputData.originalImageList;

export const getDesignThumbnailUrls = createSelector( [
    getOriginalImageList,
], ( originalImageList ) =>
{
    return map( originalImageList, ( inputMediaFile ) => inputMediaFile.url );
} );

export function getMediaDataForDesign( storeState: StoreState, convertForDesign: boolean ): TemplateInputDataMediaItem[]
{
    const originalImageList = getOriginalImageList( storeState );
    return map( originalImageList, ( inputMediaFile, index ): TemplateInputDataMediaItem =>
    {
        const mediaUrl = convertForDesign ? stringUtils.getCORSFriendlyUrl( inputMediaFile.url ) : inputMediaFile.url;
        return {
            media_url: mediaUrl,
            thumbnail_url: "",
            reduced_for_editor_url: "",
            is_video: isMediaUrlMp4Video( inputMediaFile.url ),
            index,
            post_input_media_id: inputMediaFile.id,
        };
    } );
}

export const isAnyImageLowRes = ( storeState: StoreState ): boolean =>
{
    return getOriginalImageList( storeState ).filter( ( file ) => file.isLowRes === true ).length > 0;
};

function getDesignInputKeyFromCommonInput( commonInputData: CommonInputData ): string
{
    const { originalImageList, originalText, originalHeadlineText } = commonInputData;
    const inputs: DesignInput[] = [];
    if ( originalHeadlineText )
    {
        inputs.push( "headline" );
    }
    if ( originalText )
    {
        inputs.push( "body" );
    }
    if ( originalImageList && originalImageList.length > 0 )
    {
        inputs.push( "image" );
        if ( originalImageList.length > 1 )
        {
            inputs.push( "multipleImages" );
        }
    }
    return convertInputsArrayToKey( inputs );
}

export function getControlsForKey( controlsConfigs: ControlsConfig[], designInputKey: string ): ControlsConfig
{
    if ( !controlsConfigs )
    {
        return;
    }
    for ( const controlsConfig of controlsConfigs )
    {
        if ( controlsConfig.inputs[designInputKey] )
        {
            const supportedControls = getControlsSupported( controlsConfig.controls );
            return { ...controlsConfig, controls: supportedControls };
        }
    }
    return null;
}

const getControlsSupported = ( controls ) =>
{
    return filter( controls, ( config ) =>
    {
        return includes( SUPPORTED_CONTROLS_CONFIG_TYPES, config.type );
    } );
};

export function getSelectedDesign( state: StoreState ): Design
{
    const designs = (state.designs && state.designs.idToObj) || {};
    const selectedDesignId = getSelectedDesignId( state );
    return designs && selectedDesignId && designs[selectedDesignId];
}

export function isCurrentDesignFoundation( state: StoreState ): boolean
{
    const selectedDesign = getSelectedDesign( state );
    return selectedDesign && selectedDesign.slug === "foundation" || false;
}

export function doesSelectedDesignSupportHD( state: StoreState ): boolean
{
    const selectedDesign = getSelectedDesign( state );
    return convertToBoolean( selectedDesign && selectedDesign.supports_hd );
}

export function getDesignControlsConfig( state: StoreState, design: Design ): ControlsConfig
{
    if ( !design )
    {
        return;
    }
    const designInputKey = convertInputsArrayToKey( ["headline", "body", "image", "multipleImages"] );
    const controls = getControlsConfigsByDesignId( state, design.id );
    return designInputKey && getControlsForKey( controls, designInputKey );
}

export function getInitialControlDataForCurrentSelectedDesign( state: StoreState ): TemplateControlData
{
    const design = getSelectedDesign( state );
    return getInitialControlDataForDesign( state, design );
}

function getInitialControlDataForDesign( state: StoreState, design: Design ): TemplateControlData
{
    if ( !design || !state.mixModel.initialControlDataByDesignId )
    {
        return {};
    }
    return state.mixModel.initialControlDataByDesignId[design.id] || {};
}

export function getControlDataForCurrentSelectedDesign( state: StoreState ): TemplateControlData
{
    const design = getSelectedDesign( state );
    return getControlDataForDesign( state, design );
}

export function getControlDataForDesign( state: StoreState, design: Design ): TemplateControlData
{
    if ( !design )
    {
        return {};
    }
    return state.mixModel.controlDataByDesignId[design.id];
}

export function isStaticOutputMode( state: StoreState ): boolean
{
    return getDesignOutputMode( state.mixModel ) === OUTPUT_MODE_STATIC;
}

export function isAnimatedOutputMode( state: StoreState ): boolean
{
    return getDesignOutputMode( state.mixModel ) === OUTPUT_MODE_ANIMATED;
}

export function getControlValue( state: StoreState, design: Design, controlId: string )
{
    const controlData = getControlDataForDesign( state, design );
    return controlData && controlData[controlId];
}

export function getControlIdForBrandControl( controls, type: ControlType, brand: Brand )
{
    const selectedControl = find( controls, ( control ) =>
    {
        return control.type === type && control.brand === brand;
    } );
    return selectedControl && selectedControl.id;
}

export function shouldUpdateCanvas( state: StoreState, lastMixModel: MixModelState, design: Design, isEditable: boolean )
{
    const { mixModel } = state;
    if ( lastMixModel === mixModel )
    {
        return false;
    }
    const currentSelectedDesignId = getSelectedDesignId( state );
    const lastNonDesignData = getNonDesignData( lastMixModel );
    const currentNonDesignData = getNonDesignData( mixModel );

    if ( isEqual( lastNonDesignData, currentNonDesignData ) )
    {
        const lastDesignData = getDesignData( lastMixModel );
        const currentDesignData = getDesignData( mixModel );
        const isNotEqual = !isEqual( lastDesignData, currentDesignData );
        const shouldUpdateDueToDesignDataChange = isNotEqual && (!isEditable && design.id === currentSelectedDesignId);

        if ( ENABLE_MIX_MODEL_SHOULD_UPDATE_LOGGING && shouldUpdateDueToDesignDataChange )
        {
            // tslint:disable-next-line:no-console
            console.log( "mixModel shouldUpdateCanvas due to designData change", getDifferencesBetweenObjects( lastDesignData, currentDesignData ) );
        }
        return shouldUpdateDueToDesignDataChange;
    }

    if ( shouldSuppressSoftReloadBecauseWeRelyOnHardReload( mixModel, lastMixModel ) )
    {
        return false;
    }

    const shouldUpdateDueToNonDesignDataChange = !mixModel.recomputeControlData;
    if ( ENABLE_MIX_MODEL_SHOULD_UPDATE_LOGGING && shouldUpdateDueToNonDesignDataChange )
    {
        // tslint:disable-next-line:no-console
        console.log( "mixModel shouldUpdateCanvas due to nonDesignData change",
            getDifferencesBetweenObjects( lastNonDesignData, currentNonDesignData ) );
    }
    return shouldUpdateDueToNonDesignDataChange;
}

function shouldSuppressSoftReloadBecauseWeRelyOnHardReload( currentMixModel: MixModelState, lastMixModel: MixModelState ): boolean
{
    return (lastMixModel.selectedDesignId !== currentMixModel.selectedDesignId)
           || (lastMixModel.designOutputMode !== currentMixModel.designOutputMode)
           || (lastMixModel.aspectRatio !== currentMixModel.aspectRatio);
}

function getNonDesignData( mixModelData: MixModelState )
{
    const {
        startingDesignId,
        templateSettingByDesignId,
        globalSettings,
        videoConvertedData,
        videoTrimmerCurrentData,
        originalTrimmerData,
        videoTrimmerDataMap,
        ...nonTemplateData
    } = mixModelData;
    return nonTemplateData;
}

function getDesignData( mixModelData: MixModelState )
{
    const { templateSettingByDesignId, globalSettings } = mixModelData;
    return {
        templateSettingByDesignId,
        globalSettings,
    };
}

export function setAspectRatioToTemplateSetting( designSetting: DesignTemplateSettings ): AspectRatio
{
    const designDimensions = designSetting && designSetting.baseTemplateDimensions;
    if ( !designDimensions || designDimensions.width === designDimensions.height )
    {
        return SQUARE_ASPECT_RATIO;
    }
    else if ( designDimensions.width > designDimensions.height )
    {
        return LANDSCAPE_ASPECT_RATIO;
    }
    else if ( designDimensions.width < designDimensions.height )
    {
        return PORTRAIT_ASPECT_RATIO;
    }
}

export function shouldUpdateTemplateSettings( state: StoreState,
                                              lastMixModel: MixModelState,
                                              designSettings: DesignTemplateSettings,
                                              design: Design,
                                              designAspectRatio: AspectRatio )
{
    if ( isBrandSlideTabSelected( state ) )
    {
        return false;
    }
    const oldDesignSettings = getTemplateSettingsFromMixModel( lastMixModel, design, designAspectRatio );
    return !isEqual( oldDesignSettings, designSettings );
}

export function shouldUpdateServer( state: StoreState, lastMixModel: MixModelState, isEditable: boolean )
{
    const { mixModel } = state;
    if ( isStockMediaUploading( state ) )
    {
        return false;
    }
    if ( getMediaUploads( state ) !== 0 )
    {
        return false;
    }
    if ( isVideoTrimmerModalOpen( state ) )
    {
        return false;
    }
    if ( isShowingLoadingDialog( state, LoadingDialogIdentifierForKey.TRIMMING ) )
    {
        return false;
    }
    if ( lastMixModel === mixModel )
    {
        return false;
    }
    if ( lastMixModel.selectedDesignId !== getSelectedDesignId( state ) )
    {
        return isEditable;
    }
    return !mixModel.recomputeControlData;
}

export function shouldUpdateServerForBrandSlide( state: StoreState, lastMixModel: MixModelState )
{
    const { mixModel } = state;
    if ( lastMixModel === mixModel )
    {
        return false;
    }

    return true;
}

export function getDesignMaxDuration( state: StoreState ): number
{
    return PRO_VIDEO_MAX_DURATION_SECONDS;
}

export function getMediaUrlList( state: StoreState ): string []
{
    return map( getMediaList( state ), ( imageMedia ) => imageMedia.url );
}

function isMediaUrlMp4Video( mediaUrl )
{
    return stringUtils.getFileExt( mediaUrl ) === MP4;
}

export function getIndexInMediaList( state: StoreState, filename: string ): number
{
    const uploadedMediaList = getMediaList( state );
    return findIndex( uploadedMediaList, ( media ) =>
    {
        return includes( media.url, filename );
    } );
}

export function hasVideoInMediaList( state: StoreState, mediaFilter?: ListIterator<PostInputMediaFile> ): boolean
{
    const uploadedMediaList = getFilteredMediaList( state, mediaFilter );
    return some( uploadedMediaList, ( aMedia ) => isMediaUrlMp4Video( aMedia.url ) );
}

function getFileTypeCountFromMediaList( state: StoreState, mediaFileExtension, mediaFilter?: ListIterator<PostInputMediaFile> )
{
    const uploadedMediaList = getFilteredMediaList( state, mediaFilter );
    const mediaList = filter( uploadedMediaList, ( media ) =>
    {
        return stringUtils.getFileExt( media.url ) === mediaFileExtension;
    } );
    return mediaList.length;
}

export function getFirstVideoUrlFromMediaUrlList( mediaUrlList: string[] ): string
{
    const videoUrls = getVideoUrlsFromMediaUrlList( mediaUrlList );
    return head( videoUrls );
}

export function getVideoUrlsFromMediaUrlList( mediaUrlList: string[] ): string[]
{
    return filter( mediaUrlList, ( mediaUrl ) => isMediaUrlMp4Video( mediaUrl ) );
}

export function getVideoCountFromMediaList( state: StoreState, mediaFilter?: ListIterator<PostInputMediaFile> ): number
{
    return getFileTypeCountFromMediaList( state, MP4, mediaFilter );
}

export function getImageCountFromMediaList( state: StoreState ): number
{
    return getFileTypeCountFromMediaList( state, JPG );
}

export function hasDesignReachedMaxVideoCountSupported( state: StoreState, mediaFilter?: ListIterator<PostInputMediaFile> ): boolean
{
    const videoCount = getFileTypeCountFromMediaList( state, MP4, mediaFilter );
    const currentDesign = getSelectedDesign( state );
    return currentDesign && (videoCount >= currentDesign.max_video_count_supported);
}

export function canDesignSupportVideoCount( state: StoreState, design: Design, mediaFilter?: ListIterator<PostInputMediaFile> ): boolean
{
    const videoCount = getFileTypeCountFromMediaList( state, MP4, mediaFilter );
    return design && (videoCount <= design.max_video_count_supported);
}

export function getVideoAudioURL( state: StoreState, selectedMediaId?: string ): string
{
    const postInputMediaWithAudio = getPostInputMediaWithAudio( state );

    let videoWithAudio = head( postInputMediaWithAudio );
    if ( selectedMediaId )
    {
        videoWithAudio = find( postInputMediaWithAudio,
            ( postInputMedia ) => urlUtils.getFilenameFromUrlString( postInputMedia.url ) === selectedMediaId );
    }
    return videoWithAudio && videoWithAudio.url;
}

function getEpidemicSoundMusicData( state: StoreState, musicSelection: MusicSelection ): Music
{
    return {
        ...getEpidemicSoundTrackByIdAsMusic( state, musicSelection.musicId as string ),
        startTimeInSeconds: 0,
    };
}

function getRiplMusicData( musicSelection: MusicSelection, state: StoreState ): Music
{
    return {
        ...getCatalogMusicById( state, musicSelection.musicId as number ),
        startTimeInSeconds: 0,
    };
}

function getPersonalMusicData( musicSelection: MusicSelection ): Music
{
    return {
        ...musicSelection.customMusic,
        startTimeInSeconds: 0,
    };
}

export function getVideoAudioMusicData( state: StoreState, selectedMediaId?: string ): Music
{
    return {
        display_name: VIDEO_AUDIO_DISPLAY_NAME,
        audio_url: getVideoAudioURL( state, selectedMediaId ),
        type: MUSIC_TYPE_VIDEO_AUDIO,
        video_audio_data: buildVideoAudioData( state ),
        startTimeInSeconds: 0,
    };
}

function buildVideoAudioData( state: StoreState )
{
    const data = {};
    const mediaUrlList = getMediaUrlList( state );
    const videoUrlsFromMediaUrlList = getVideoUrlsFromMediaUrlList( mediaUrlList );
    videoUrlsFromMediaUrlList.forEach( ( mediaUrl ) =>
    {
        const mediaId: string = urlUtils.getFilenameFromUrlString( mediaUrl );
        data[mediaId] = buildVideoAudioDataItem( mediaUrl );
    } );

    return data;
}

function buildVideoAudioDataItem( mediaUrl: string ): VideoAudioDataItem
{
    return {
        audio_url: mediaUrl,
        muted: false,
    };
}

export function updateVideoTrimmerUrlInState( dispatch: Dispatch<StoreState>, addMediaAPIResponse: AddMediaAPIResponse )
{
    if ( addMediaAPIResponse && addMediaAPIResponse.fields["Content-Type"] === MP4_VIDEO_TYPE )
    {
        dispatch( mixModelActions.videoTrimmerStarted( addMediaAPIResponse.s3_direct_url ) );
    }
}

export function shouldTrimVideo( state: StoreState, newTrimData: TrimmerTimeData, videoUrl: string, videoDurationInSeconds: number ): boolean
{
    const isNotFullDuration = newTrimData.start !== 0 || newTrimData.end !== Math.round( videoDurationInSeconds * 1000 );
    const doesNotHaveVideoConvertedData = !state.mixModel.videoConvertedData;
    const isDifferentFromConvertedDataVideo = state.mixModel.videoConvertedData && state.mixModel.videoConvertedData.s3_direct_url !== videoUrl;

    return isNotFullDuration || doesNotHaveVideoConvertedData || isDifferentFromConvertedDataVideo;
}

export function getVideoTrimmedDurationInMS( state: StoreState, mediaId: string ): number
{
    const trimmerData = getOriginalTrimmerData( state, mediaId );
    const videoDurationInMS = trimmerData && trimmerData.time && trimmerData.time.end - trimmerData.time.start;
    return videoDurationInMS || 0;
}

function deriveDesignOutputModeFromPostOutputType( outputType: string ): DesignOutputMode
{
    return outputType === IMAGE_OUTPUT_TYPE ? OUTPUT_MODE_STATIC : OUTPUT_MODE_ANIMATED;
}

export const getStartingMixType = ( state: StoreState ) => state.mixModel && state.mixModel.startingMixType;
export const getBusinessActionExamplePostId = ( state: StoreState ) => state.mixModel && state.mixModel.businessActionExamplePostId;
export const getSelectedDesignId = ( state: StoreState ) => state.mixModel && state.mixModel.selectedDesignId;
export const hasDesignChanged = ( state: StoreState ): boolean => state.mixModel && state.mixModel.hasDesignChanged === true;
export const getStartingDesignId = ( state: StoreState ) => state.mixModel && state.mixModel.startingDesignId;
export const getPrimaryText = ( state: StoreState ) => state.mixModel.commonInputData.originalHeadlineText;
export const getSecondaryText = ( state: StoreState ) => state.mixModel.commonInputData.originalText;
export const getMediaUploads = ( state: StoreState ) => state.mixModel.mediaUploads || 0;
export const getMediaList = ( state: StoreState ): PostInputMediaFile[] => state.mixModel.commonInputData.originalImageList || [];

export type ListIterator<T> = ( value: T ) => boolean;
export const getFilteredMediaList = ( state: StoreState, mediaFilter?: ListIterator<PostInputMediaFile> ) =>
{
    return filter( getMediaList( state ), mediaFilter );
};

export const getNumMedia = ( state: StoreState, mediaFilter?: ListIterator<PostInputMediaFile> ) =>
{
    return getMediaUploads( state ) + getFilteredMediaList( state, mediaFilter ).length;
};
export const getTotalMedia = ( state: StoreState ) => getImageCountFromMediaList( state ) + getVideoCountFromMediaList( state );

export const getMixModelMusicSelection = ( state: StoreState ): MusicSelection =>
{
    return {
        musicType: getMusicType( state ),
        musicId: getMusicId( state ),
        customMusic: getCustomMusic( state ),
    };
};
export const getMusicType = ( state: StoreState ) => state.mixModel.musicType;
export const getMusicId = ( state: StoreState ) => state.mixModel.musicId;
export const getCustomMusic = ( state: StoreState ) => state.mixModel.customMusic;
export const hasMusic = ( state: StoreState ) => convertToBoolean( getMusicId( state ) || getCustomMusic( state ) );

export const getConvertedVideoAPIData = ( state: StoreState ) => state.mixModel.videoConvertedData;
export const getVideoOriginalFileName = ( state: StoreState ) => state.mixModel.videoConvertedData
                                                                 && state.mixModel.videoConvertedData.original_file_name;
export const getVideoOriginalMediaType = ( state: StoreState ) => state.mixModel.videoConvertedData
                                                                  && state.mixModel.videoConvertedData.media_object
                                                                  && state.mixModel.videoConvertedData.media_object.media_type;
export const getVideoOriginalSource = ( state: StoreState ) => state.mixModel.videoConvertedData
                                                               && state.mixModel.videoConvertedData.media_object
                                                               && state.mixModel.videoConvertedData.media_object.source;
export const getVideoOriginalFileSize = ( state: StoreState ) => state.mixModel.videoConvertedData
                                                                 && state.mixModel.videoConvertedData.file_size;
export const isVideoAudioSelected = ( state: StoreState ) =>
{
    return doesAnyVideoContainAudio( state ) && state.mixModel.musicType === MUSIC_TYPE_VIDEO_AUDIO;
};

export const doesPostInputMediaWithUrlContainAudio = ( state: StoreState, url: string ) =>
{
    const mediaId = urlUtils.getFilenameFromUrlString( url );
    return doesVideoContainAudio( state, mediaId );
};

const getPostInputMediaWithAudio = ( state: StoreState ) =>
{
    const mediaList: PostInputMediaFile[] = getMediaList( state );
    const postInputMediaFilesWithAudio = filter( mediaList,
        ( postInputMediaFile ) => postInputMediaFile.metadata && postInputMediaFile.metadata.contains_audio );
    return postInputMediaFilesWithAudio;
};

export const doesAnyVideoContainAudio = ( state: StoreState ) =>
{
    const postInputMediaFilesWithAudio = getPostInputMediaWithAudio( state );
    return size( postInputMediaFilesWithAudio ) > 0;
};

const doesVideoContainAudio = ( state: StoreState, mediaId: string ): boolean =>
{
    const matchingPostInputMediaFile = findPostInputMediaMatchingId( state, mediaId );
    return matchingPostInputMediaFile && matchingPostInputMediaFile.metadata && matchingPostInputMediaFile.metadata.contains_audio;
};

export const hasVideoDurationMetadata = ( state: StoreState, mediaId: string ): boolean =>
{
    const videoDurationSeconds = getVideoDurationSeconds( state, mediaId );
    return videoDurationSeconds !== VIDEO_DURATION_NOT_FOUND;
};

export const getVideoDurationSeconds = ( state: StoreState, mediaId: string ): number =>
{
    const matchingPostInputMediaFile = findPostInputMediaMatchingId( state, mediaId );
    return matchingPostInputMediaFile && matchingPostInputMediaFile.metadata && matchingPostInputMediaFile.metadata.duration
           || VIDEO_DURATION_NOT_FOUND;
};

const findPostInputMediaMatchingId = ( state: StoreState, mediaId: string ): PostInputMediaFile =>
{
    const postInputMediaFiles = getMediaList( state );
    return find( postInputMediaFiles, ( postInputMediaFile ) =>
    {
        return urlUtils.getFilenameFromUrlString( postInputMediaFile.url ) === mediaId;
    } );
};

export const getVideoTrimmerCurrentData = ( state: StoreState ) => state.mixModel.videoTrimmerCurrentData || defaultVideoTrimmerCurrentData;
export const getVideoTrimmerVideoDuration = ( state: StoreState ) => getVideoTrimmerCurrentData( state ).videoDurationInSeconds;
export const getOriginalTrimmerData = ( state: StoreState, mediaId: string ): OriginalTrimmerData =>
{
    return get( state.mixModel.videoTrimmerDataMap, mediaId ) || emptyTrimmerData;
};
export const getOriginalTrimmerDataForRetrim = ( state: StoreState ): OriginalTrimmerData =>
{
    const mediaId = getVideoTrimmerCurrentData( state ).mediaId;
    return getOriginalTrimmerData( state, mediaId );
};

export const getMediaSourceList = ( state: StoreState ) => state.mixModel.mediaSourceList || [];
export const isMediaUploading = ( state: StoreState ) => getMediaUploads( state ) > 0;
export const isFacebookAd = ( state: StoreState ) => state.mixModel.facebookAdMode;

export const getShowBrandSlideFromMixModel = ( state: StoreState ): boolean =>
{
    const brandSlideData = state.mixModel.brandSlideData;
    return brandSlideData && convertToBoolean( brandSlideData.showEndCard );
};
export const getShowBrandSlideLogoFromMixModel = ( state: StoreState ): boolean =>
{
    const brandSlideData = state.mixModel.brandSlideData;
    return brandSlideData && brandSlideData.endCardData && convertToBoolean( brandSlideData.endCardData.showLogo );
};
export const getBrandSlideTaglineFromMixModel = ( state: StoreState ): string => state.mixModel.brandSlideData
                                                                                 && state.mixModel.brandSlideData.endCardData
                                                                                 && state.mixModel.brandSlideData.endCardData.tagline || "";
export const getBrandSlideContactFromMixModel = ( state: StoreState ): string => state.mixModel.brandSlideData
                                                                                 && state.mixModel.brandSlideData.endCardData
                                                                                 && state.mixModel.brandSlideData.endCardData.contact || "";
export const getEndCardDataFromBrandSlideData = ( state: StoreState ): TemplateInputEndCardData => state.mixModel.brandSlideData
                                                                                                   && state.mixModel.brandSlideData.endCardData;

export const getPropertiesForEventTracking = ( state: StoreState ) =>
{
    return {
        templateId: getSelectedDesignId( state ),
        designChanged: hasDesignChanged( state ),
        isVideoCreation: hasVideoInMediaList( state ),
        musicId: getMusicId( state ),
        riplMusicId: getMusicId( state ),
        numberOfPhotos: getImageCountFromMediaList( state ),
        numberOfVideos: getVideoCountFromMediaList( state ),
        totalMedia: getTotalMedia( state ),
        postOutputType: getOutputType( state ),
        mixOutputType: getOutputType( state ), // for compatibility with mobile clients output reported through both postOutputType and mixOutputType
        postSize: getDesignAspectRatio( state ),
        postId: getPostId( state ),
        startingMixType: getStartingMixType( state ),
        startingPostId: getBusinessActionExamplePostId( state ),
        contentSearchPostId: state.mixModel.contentSearchPostId,
        collectionPostId: state.mixModel.collectionPostId,
        collectionSlug: state.mixModel.collectionSlug,
        collectionRowIndex: state.mixModel.collectionRowIndex,
    };
};
