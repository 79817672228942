import { ENV_CONFIG } from "./";
import { dropRight, kebabCase, last, map, replace, startsWith, toLower } from "lodash";
import * as TwitterText from "twitter-text/dist";

function numberWithCommas( val: number )
{
    return val.toString().replace( /\B(?=(\d{3})+(?!\d))/g, "," );
}

const COUNT_ABBRS = ["", "K", "M", "B"];

function abbreviateNumber( count: number, decimals = 1 )
{
    const places = 0 === count ? count : Math.floor( Math.log( count ) / Math.log( 10 ) );
    const precision = Math.max( 0, decimals - places % 3 );
    const thousands = Math.floor( places / 3 );
    const result = parseFloat( (count / Math.pow( 1000, thousands )).toFixed( precision ) );
    return result + COUNT_ABBRS[thousands];
}

function getCORSFriendlyUrl( url: string )
{
    if ( !url )
    {
        return null;
    }
    if ( url.indexOf( "?" ) === -1 )
    {
        url += "?";
    }
    else
    {
        url += "&";
    }
    url += "cors=true";
    return convertS3Url( url, ENV_CONFIG.cdnBaseUrl );
}

function getCDNUrl( url: string )
{
    return convertS3Url( url, ENV_CONFIG.cdnBaseUrl );
}

function convertS3Url( url: string, baseUrl: string )
{
    if ( !url )
    {
        return url;
    }
    url = url.replace( "http:", "https:" );
    url = replaceDomainInUrl( url, "https://fanzo-photos-dev.s3-us-west-2.amazonaws.com", baseUrl + "/photos-dev" );
    url = replaceDomainInUrl( url, "https://fanzo-photos-dev.s3.us-west-2.amazonaws.com", baseUrl + "/photos-dev" );
    url = replaceDomainInUrl( url, "https://fanzo-photos-dev.s3.amazonaws.com", baseUrl + "/photos-dev" );
    url = replaceDomainInUrl( url, "https://fanzo-photos-stg.s3.amazonaws.com", baseUrl + "/photos-stg" );
    url = replaceDomainInUrl( url, "https://fanzo-photos.s3.amazonaws.com", baseUrl + "/photos" );
    return replaceDomainInUrl( url, "https://fanzo-static.s3.amazonaws.com", baseUrl + "/photos-static" );
}

function changeHttpUrlToHttps( url: string ): string
{
    return replaceDomainInUrl( url, "http://", "https://" );
}

function replaceDomainInUrl( url: string, domainToReplace: string, substitute: string )
{
    if ( url && domainToReplace && startsWith( toLower( url ), toLower( domainToReplace ) ) )
    {
        return substitute + url.substr( domainToReplace.length );
    }
    return url;
}

function getFileName( url ): string
{
    if ( url )
    {
        return last( url.split( "/" ) );
    }
    return undefined;
}

function removeQuery( url ): string
{
    if ( url )
    {
        return url.split( "?" )[0];
    }
}

function getFileNameFromS3URL( url ): string
{
    return removeQuery( getFileName( url ) );
}

function getBaseName( filename: string ): string
{
    return dropRight( splitFileName( getFileName( filename ) ), 1 ).join( "." );
}

function getFileExt( filename: string ): string
{
    return toLower( last( splitFileName( getFileName( filename ) ) ) );
}

function splitFileName( filename: string ): string[]
{
    if ( !filename )
    {
        return [];
    }
    return filename.split( "." );
}

function convertByteToMB( amount: number ): number
{
    return Math.round( amount / 1000000 );
}

function getTwitterWeightedLength( caption: string ): number
{
    caption = caption || "";
    return TwitterText.parseTweet( caption ).weightedLength;
}

function convertDesignNameFromKebabCaseToCamelCase( kebabCasedDesignName: string )
{
    const theCamelCaseSlug = kebabCasedDesignName && kebabCasedDesignName.replace( /-(\w)/g, ( v ) => v[1].toUpperCase() );
    return theCamelCaseSlug && theCamelCaseSlug.replace( /-/g, "" );
}

function convertDesignNameFromCamelCaseToKebabCaseForm( camelCasedDesignName: string )
{
    if ( camelCasedDesignName )
    {
        return kebabCase( camelCasedDesignName ).replace( /-(\d)/g, ( v: string ) => v.slice( 1 ) );
    }
    return camelCasedDesignName;
}

function convertStringArrayToLowerCase( inputArray: string[] )
{
    return map( inputArray, toLower );
}

function getUSDZeroPaddedPrice( amount: string | number )
{
    const amountAsString = amount.toString();
    const amountConverted = parseFloat( amountAsString ).toFixed( 2 );
    return "$" + amountConverted;
}

function replaceHyphensAndUnderscoresWithSpaces( value: string )
{
    return replace( value, /[-_]/g, " " );
}

function replaceSpacesWithHyphens( value: string )
{
    return replace( value, / /g, "-" );
}

function replacePeriodsWithHyphens( value: string )
{
    return replace( value, /\./g, "-" );
}

function sanitizeFileName( filename: string )
{
    const removedSpaces = replaceSpacesWithHyphens( filename );
    // Only allow through a whitelisted set of characters.
    return replace( removedSpaces, /[^a-zA-Z0-9\-.]+/g, "" );
}

function removeMillisecondsFromISODateString( isoDateString: string )
{
    return isoDateString.split( "." )[0] + "Z";
}

export const stringUtils = {
    numberWithCommas,
    abbreviateNumber,
    getCORSFriendlyUrl,
    getCDNUrl,
    changeHttpUrlToHttps,
    replaceDomainInUrl,
    getFileName,
    getFileNameFromS3URL,
    getBaseName,
    getFileExt,
    convertByteToMB,
    getTwitterWeightedLength,
    convertDesignNameFromKebabCaseToCamelCase,
    convertDesignNameFromCamelCaseToKebabCaseForm,
    convertStringArrayToLowerCase,
    getUSDZeroPaddedPrice,
    replaceHyphensAndUnderscoresWithSpaces,
    replaceSpacesWithHyphens,
    replacePeriodsWithHyphens,
    sanitizeFileName,
    removeMillisecondsFromISODateString,
};
