import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../../_types";
import { uiActions } from "../../../../actions";
import { COACH_MARK_NAME_KEY_STYLIZED_TEXT } from "../../../../_types/api";
import { TextPropertiesSubPanel, TextPropertiesSubPanelDispatchProps, TextPropertiesSubPanelProps } from "../TextPropertiesSubPanel";
import {
    getCustomizableCanvas,
    getDesignCaptionDataById,
    getDesignElementProperties,
    getFocusElement,
    getTextPropertiesSubPanelCaptionData,
} from "../../../../ducks/ui";
import { store } from "../../../../store";
import { apptimizeVariables } from "../../../../helpers";

export interface TextPropertiesSubPanelContainerProps
{
    onBackToTextClicked();
}

const mapStateToProps = ( storeState: StoreState, ownProps: TextPropertiesSubPanelContainerProps ): TextPropertiesSubPanelProps =>
{
    const textPropertiesSubPanelCaptionData = getTextPropertiesSubPanelCaptionData( storeState );
    return {
        ...ownProps,
        captionData: textPropertiesSubPanelCaptionData ? getDesignCaptionDataById( storeState, textPropertiesSubPanelCaptionData.id ) : null,
        focusElement: getFocusElement( storeState ),
        designElementProperties: getDesignElementProperties( storeState ),
        shouldEnablePresetText: apptimizeVariables.shouldEnablePresetText(),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): TextPropertiesSubPanelDispatchProps =>
{
    return {
        clearStylizedTextButtonCoachMark: () =>
        {
            dispatch( uiActions.clearCoachMark( COACH_MARK_NAME_KEY_STYLIZED_TEXT ) );
        },
        requestTextProperties: ( captionId: string ) =>
        {
            const state = store.getState();
            const canvas = getCustomizableCanvas( state );
            if ( canvas )
            {
                canvas.tellDesignToRequestElementProperties( captionId );
            }
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( TextPropertiesSubPanel );
