import * as React from "react";
import { UserBusiness } from "../../_types";
import CreateBlankPostButtonSmallContainer from "../containers/CreateBlankPostButtonSmall.container";

export interface NoPostsPanelProps
{
    currentBusiness: UserBusiness;
    noBusinessElements?: React.ReactNode;
}

export class NoPostsPanel extends React.Component<NoPostsPanelProps>
{
    public render()
    {
        return (
            <div className="noPostsPanel">
                {this.createNoPostsPanelChildren()}
            </div>
        );
    }

    private createNoPostsPanelChildren()
    {
        const currentBusiness = this.props.currentBusiness;

        if ( currentBusiness )
        {
            return (
                <div>
                    <span className="noPostsCreated">No posts created.</span>
                    <CreateBlankPostButtonSmallContainer/>
                </div>
            );
        }
        const noBusinessElements = (<span>Add a new brand to start<br/>customizing and scheduling posts.</span>);
        return (this.props.noBusinessElements || noBusinessElements);
    }
}
