import { connect } from "react-redux";
import { BusinessType, StoreState } from "../../../_types";
import { BusinessTypeFilterButton, BusinessTypeFilterButtonDispatchProps, BusinessTypeFilterButtonProps } from "../../BusinessTypeFilterButton";
import { Dispatch } from "redux";
import { inspirationsActions, uiActions } from "../../../actions";
import { collectionRowsServices, inspirationsServices } from "../../../services";
import { getCurrentBusinessId } from "../../../ducks/userBusiness";
import { store } from "../../../store";
import { getBusinessTypeFilter } from "../../../ducks/ui";

const mapStateToProps = ( state: StoreState, ownProps: BusinessTypeFilterButtonProps ): BusinessTypeFilterButtonProps => ({
    ...ownProps,
    selectedBusinessType: getBusinessTypeFilter( state ),
});

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): BusinessTypeFilterButtonDispatchProps =>
{
    return {
        handleSelectBusinessType: async ( businessType: BusinessType ) =>
        {
            const state = store.getState();
            await dispatch( uiActions.updateBusinessTypeFilter( businessType ) );
            dispatch( collectionRowsServices.loadCollectionRowsForBusinessType( businessType.id, getCurrentBusinessId( state ) ) );
            dispatch( inspirationsActions.clearInspirations() );
            dispatch( inspirationsServices.loadInspirationsForBusinessType( businessType.id, 1 ) );
        },
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( BusinessTypeFilterButton );
