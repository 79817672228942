import * as React from "react";
import { filter, first, map } from "lodash";
import { AlignTextOption, DesignElementProperties, DesignElementProperty, TextPickerCaptionData } from "../../_types";
import { ENV_CONFIG } from "../../helpers";
import classNames from "classnames";
import { DESIGN_ELEMENT_PROPERTY_TYPE_ALIGN, DesignElementPropertyType } from "../../_types/api";

export interface AlignTextChoicePanelProps
{
    captionData: TextPickerCaptionData;
    designElementProperties: DesignElementProperties;
}

export interface AlignTextChoicePanelDispatchProps
{
    tellDesignToAlignTextItem( captionId: string, type: DesignElementPropertyType, alignPropertyId: string, option: AlignTextOption );
}

interface AlignTextChoicePanelState
{
    currentAlignId: string;
}

export class AlignTextChoicePanel extends React.PureComponent<AlignTextChoicePanelProps & AlignTextChoicePanelDispatchProps,
    AlignTextChoicePanelState>
{
    public constructor( props )
    {
        super( props );
        const alignPropertyData = this.getAlignPropertyData();
        this.state = {
            currentAlignId: alignPropertyData.selected as string,
        };
    }

    public render()
    {
        const alignPropertyData = this.getAlignPropertyData();
        if ( alignPropertyData )
        {
            return <div>
                <div className="alignTextChoiceGrid">
                    {map( alignPropertyData.options,
                        ( option ) => <AlignTextChoiceButton alignOption={option} onClick={this.onAlignItemClicked}
                                                             relativeImageUrl={option.thumbnailUrl}
                                                             selected={this.state.currentAlignId === option.id}
                                                             key={option.id}/> )}
                </div>
            </div>;
        }
        return null;
    }

    private getAlignPropertyData = (): DesignElementProperty =>
    {
        const alignProperties = filter( this.props.designElementProperties.properties,
            ( property ) => property.type === DESIGN_ELEMENT_PROPERTY_TYPE_ALIGN );
        return first( alignProperties );
    }

    private onAlignItemClicked = ( selectedOption: AlignTextOption ) =>
    {
        const alignPropertyData = this.getAlignPropertyData();
        this.props.tellDesignToAlignTextItem( this.props.captionData.id, alignPropertyData.type, alignPropertyData.id, selectedOption );
        this.setState( { currentAlignId: selectedOption.id } );
    }
}

export interface AlignTextChoiceProps
{
    alignOption: AlignTextOption;
    relativeImageUrl: string;
    selected: boolean;
}

export interface AlignTextChoiceDispatchProps
{
    onClick: ( selectedOption: {} ) => void;
}

class AlignTextChoiceButton extends React.PureComponent<AlignTextChoiceProps & AlignTextChoiceDispatchProps>
{
    public render()
    {
        const displayName = this.props.alignOption.displayName;
        let imageUrl;
        if ( this.props.relativeImageUrl )
        {
            imageUrl = ENV_CONFIG.cdnBaseUrl + this.props.relativeImageUrl;
        }

        return (<div className={classNames( "alignTextChoice", "clickable", { selected: this.props.selected } )}
                     onClick={this.handleOnClick}>
            <div>
                <div className={classNames( "icon", { selected_icon: this.props.selected } )}>
                    <img src={imageUrl} alt={displayName}/>
                </div>
                <div className={classNames( "label", { selected_label: this.props.selected } )}>{displayName}</div>
            </div>
        </div>);
    }

    private handleOnClick = ( mouseEvent: React.MouseEvent ) =>
    {
        this.props.onClick( this.props.alignOption );
    }
}
