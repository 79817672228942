import { Dispatch } from "redux";
import { businessTypeActions } from "../actions";
import { normalizeBusinessTypeAPI, requests } from "../helpers";
import { StoreState } from "../_types";

export const businessTypesServices = {
    load,
};

function load()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        return requests.get( "/business_types", { retired: 0 } ).then(
            ( data: BusinessTypeAPI[] ) =>
            {
                const normalizedData = normalizeBusinessTypeAPI( data );
                dispatch( businessTypeActions.loadSuccess( normalizedData ) );
            },
            ( error ) => dispatch( businessTypeActions.loadFailure( error ) ),
        );
    };
}
