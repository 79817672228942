import { Action } from "redux-actions";
import { userActions, UserBusinessActionData, userBusinessActions } from "../actions";
import {
    businessesSchema,
    MAX_NUMBER_OF_TEAM_MEMBERS_INCLUDING_ADMIN,
    ReducerCreator,
    teamBusinessesSchema,
    updateLookupWithAction,
} from "../helpers";
import { filter, includes, isEmpty, isNil, lowerCase, map, max, maxBy, reduce, size, some, sortBy, toLower, values, without } from "lodash";
import * as defaultProfileImage from "../assets/img/defaultUserProfile.png";
import { createDefaultDataLookup } from "./dataLookup";
import { getCurrentUserId, hasLogoSetupAndOn, isCurrentBusinessAdmin } from "./user";
import { StoreState, TeamInvite, TeamMember, UserBusiness, UserBusinessState } from "../_types";
import { createSelector } from "reselect";
import { getBusinessType } from "./businessTypes";
import { getBusinessTypeFilter } from "./ui";

const defaultState: UserBusinessState = {
    ...createDefaultDataLookup(),
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addCombinedActions( [
    userActions.meSuccess,
    userActions.loginSuccess,
    userActions.updateSuccess,
], userBusinessUpdate );
reducerCreator.addAction( userBusinessActions.businessSwitched, handleBusinessSwitched );
reducerCreator.addAction( userBusinessActions.updateFailure, handleUserBusinessUpdateFailure );
reducerCreator.addAction( userBusinessActions.deleteSuccess, handleUserBusinessDeleteSuccess );

export default reducerCreator.createReducer();

function userBusinessUpdate( state: UserBusinessState, action: Action<NormalizrData> ): UserBusinessState
{
    const stateWithOwnedBusinesses = updateLookupWithAction( businessesSchema, action, state );
    const stateIncludesTeamBusinesses = updateLookupWithAction( teamBusinessesSchema, action, stateWithOwnedBusinesses, true );
    setCurrentBusinessIfNeeded( stateIncludesTeamBusinesses, action.payload.result );
    return stateIncludesTeamBusinesses;
}

function handleBusinessSwitched( state: UserBusinessState, action: Action<UserBusinessActionData> ): UserBusinessState
{
    return {
        ...state,
        currentUserBusinessId: action.payload.businessId,
    };
}

function handleUserBusinessDeleteSuccess( state: UserBusinessState, action: Action<UserBusinessActionData> ): UserBusinessState
{
    const newState = { ...state };
    const businessId = action.payload.businessId;
    delete newState.idToObj[businessId];
    newState.ids = without( newState.ids, businessId );
    if ( newState.currentUserBusinessId === businessId )
    {
        delete newState.currentUserBusinessId;
        setCurrentBusinessIfNeeded( newState, action.payload.userId );
    }
    return newState;
}

function handleUserBusinessUpdateFailure( state: UserBusinessState, action: Action<string> ): UserBusinessState
{
    // TODO handle failure
    return state;
}

function setCurrentBusinessIfNeeded( updatedState: UserBusinessState, userId: number )
{
    if ( !updatedState.currentUserBusinessId || !includes( updatedState.ids, updatedState.currentUserBusinessId ) )
    {
        const userBusinesses = values( updatedState.idToObj );
        const teamBusiness = filter( userBusinesses, ( business ) => doesBusinessSupportTeamFeatures( business ) );
        let businessId = max( updatedState.ids );
        if ( !isEmpty( teamBusiness ) )
        {
            businessId = maxBy( teamBusiness, ( business ) => business.id ).id;
        }

        updatedState.currentUserBusinessId = businessId;
    }
}

export function getBusinessProfileImage( userBusiness: UserBusiness )
{
    if ( userBusiness )
    {
        if ( userBusiness.has_logo )
        {
            return userBusiness.profile_image_url;
        }
        // TODO get profile images for user business...
    }
    return defaultProfileImage;
}

export function getCurrentBusiness( state: StoreState )
{
    return getBusinessById( state, getCurrentBusinessId( state ) );
}

function getBusinessByIdFromBusinessState( businessesState: UserBusinessState, id: number )
{
    return id && businessesState && businessesState.idToObj && businessesState.idToObj[id];
}

export function getBusinessById( state: StoreState, id: number )
{
    return getBusinessByIdFromBusinessState( state.userBusinesses, id );
}

export const getAllBusinessIds = ( state: StoreState ) => state.userBusinesses.ids;
const getAllBusinessIdsToObjs = ( state: StoreState ) => state.userBusinesses.idToObj;

export const getAllKnownBusinesses = createSelector( [
    getAllBusinessIds, getAllBusinessIdsToObjs,
], ( businessIds, businessIdsToObjs ): UserBusiness[] =>
{
    return sortBy( filter( map( businessIds, ( id ) => businessIdsToObjs[id] ) ), ( business ) => lowerCase( business.name ) );
} );

export interface BusinessWithRole
{
    business: UserBusiness;
    isCurrentUserAdmin: boolean;
}

export function getAllKnownBusinessesAndCurrentUserRole( state: StoreState ): BusinessWithRole[]
{
    const businesses = getAllKnownBusinesses( state );
    return map( businesses, ( business ) =>
        ({ business, isCurrentUserAdmin: hasTeamAdminPermissionsForBusiness( state, business ) }) );
}

export function getCurrentBusinessId( state: StoreState )
{
    if ( state.userBusinesses.currentUserBusinessId
         && includes( state.userBusinesses.ids, state.userBusinesses.currentUserBusinessId ) )
    {
        return state.userBusinesses.currentUserBusinessId;
    }
}

export function doesCurrentBusinessSupportTeamFeatures( state: StoreState )
{
    const currentBusiness = getCurrentBusiness( state );
    return doesBusinessSupportTeamFeatures( currentBusiness );
}

export function doesBusinessSupportTeamFeatures( userBusiness: UserBusiness )
{
    return !!(userBusiness && userBusiness.pro_team);
}

export function getCurrentBusinessTypeId( state: StoreState )
{
    const currentUserBusiness = getCurrentBusiness( state );
    return currentUserBusiness && currentUserBusiness.business_type_id;
}

export function getCurrentBusinessType( state: StoreState )
{
    return getBusinessType( state, getCurrentBusinessTypeId( state ) );
}

export function doesCurrentBusinessTypeHavePostIntents( state: StoreState )
{

    const currentBusinessType = getBusinessTypeFilter( state );
    return !!currentBusinessType &&
           currentBusinessType.post_intents &&
           currentBusinessType.post_intents.intents &&
           currentBusinessType.post_intents.intents.length > 0;
}

export function getPostIntentsForCurrentBusinessType( state: StoreState ): [PostIntentData]
{
    if ( doesCurrentBusinessTypeHavePostIntents( state ) )
    {
        return getBusinessTypeFilter( state ).post_intents.intents;
    }
    else
    {
        return null;
    }
}

export function getDefaultSearchTerm( state: StoreState )
{
    const currentBusiness = getCurrentBusiness( state );
    return currentBusiness && currentBusiness.default_stock_media_search_term;
}

export function hasTeamAdminPermissionsForCurrentBusiness( state: StoreState )
{
    const currentUserBusiness = getCurrentBusiness( state );
    return hasTeamAdminPermissionsForBusiness( state, currentUserBusiness );
}

export function hasTeamAdminPermissionsForBusiness( state: StoreState, business: UserBusiness )
{
    const theBusinessAdminId = business && business.admin_id;
    return theBusinessAdminId === getCurrentUserId( state );
}

export function hasTeamMemberPermissionsForCurrentBusiness( state: StoreState )
{
    return hasTeamMemberPermissionsForBusiness( state, getCurrentBusiness( state ) );
}

export function hasTeamMemberPermissionsForBusiness( state: StoreState, business: UserBusiness )
{
    return !hasTeamAdminPermissionsForBusiness( state, business );
}

export function isUserMemberOfAnyTeamBusiness( state: StoreState )
{
    return some( getAllKnownBusinesses( state ), ( business ) => hasTeamMemberPermissionsForBusiness( state, business ) );
}

export function getTeamMembers( business: UserBusiness ): TeamMember[]
{
    if ( isNil( business ) )
    {
        return [];
    }
    else
    {
        return sortBy( business.team_members || [], ( member: TeamMember ) => toLower( member.role ) );
    }
}

export function hasTeamMembers( business: UserBusiness ): boolean
{
    return size( getTeamMembers( business ) ) > 1;
}

export function hasTeamMembersForCurrentBusiness( state: StoreState ): boolean
{
    return hasTeamMembers( getCurrentBusiness( state ) );
}

export function hasTeamInvitesForCurrentBusiness( state: StoreState ): boolean
{
    return size( getTeamInvitesForCurrentBusiness( state ) ) > 0;
}

export function getTeamMembersForCurrentBusiness( state: StoreState ): TeamMember[]
{
    return getTeamMembers( getCurrentBusiness( state ) );
}

export function getOtherTeamMembers( state: StoreState, business: UserBusiness ): TeamMember[]
{
    const teamMembers = getTeamMembers( business );
    return filter( teamMembers, ( teamMember ) => getCurrentUserId( state ) !== teamMember.id );
}

export function getRemainingTeamInvites( state: StoreState ): number
{
    const teamMemberCount = getTeamMemberCountForCurrentBusiness( state );
    const pendingInvites = size( getTeamInvitesForCurrentBusiness( state ) );
    const remainingInvites = MAX_NUMBER_OF_TEAM_MEMBERS_INCLUDING_ADMIN - (pendingInvites + teamMemberCount);

    return remainingInvites > 0 ? remainingInvites : 0;
}

export function hasRemainingTeamInvites( state: StoreState ): boolean
{
    return getRemainingTeamInvites( state ) > 0;
}

export function hasOtherTeamMembers( state: StoreState, business: UserBusiness ): boolean
{
    return getOtherTeamMembers( state, business ).length > 0;
}

export function getTeamInviteEmails( state: StoreState ): string[]
{
    const currentBusiness = getCurrentBusiness( state );
    if ( currentBusiness )
    {
        return map( currentBusiness.team_invites, ( teamInvite: TeamInvite ) => toLower( teamInvite.email ) );
    }
    return [];
}

export function getTeamInvitesForCurrentBusiness( state: StoreState ): TeamInvite[]
{
    const currentBusiness = getCurrentBusiness( state );
    return currentBusiness && currentBusiness.team_invites || [];
}

export function getTeamMemberCountForCurrentBusiness( state: StoreState ): number
{
    return size( getTeamMembersForCurrentBusiness( state ) );
}

export const getUserBusinessesCount = ( state: StoreState ) => getAllKnownBusinesses( state ).length;
export const getUserBusinessPrimaryBrandColor = ( state: StoreState ) =>
{
    const currentBusiness = getCurrentBusiness( state );
    return currentBusiness && currentBusiness.primary_color;
};
export const getUserBusinessSecondaryBrandColor = ( state: StoreState ) =>
{
    const currentBusiness = getCurrentBusiness( state );
    return currentBusiness && currentBusiness.secondary_color;
};

export const getHasBrandColorsSet = ( state: StoreState ): boolean =>
{
    const userBusiness = getCurrentBusiness( state );
    return !!userBusiness && (!!userBusiness.primary_color || !!userBusiness.secondary_color);
};

export const getHasBrandFontsSet = ( state: StoreState ): boolean =>
{
    const userBusiness = getCurrentBusiness( state );
    return !!userBusiness && (!!userBusiness.primary_font
                              || !!userBusiness.primary_font_css_url
                              || !!userBusiness.secondary_font
                              || !!userBusiness.secondary_font_css_url);
};

export const hasAnyCustomBrandColorsFontsOrLogo = ( state: StoreState ): boolean =>
{
    return getHasBrandColorsSet( state ) || getHasBrandFontsSet( state ) || hasLogoSetupAndOn( state );
};

export const hasBrandSlideData = ( state: StoreState ): boolean =>
{
    const userBusiness = getCurrentBusiness( state );
    return !!userBusiness && (userBusiness.has_logo ||
                              !!userBusiness.tagline ||
                              !!userBusiness.contact ||
                              !!userBusiness.end_card_color_one ||
                              !!userBusiness.end_card_color_two ||
                              !!userBusiness.end_card_font_one ||
                              !!userBusiness.end_card_font_two ||
                              !!userBusiness.end_card_position_data);
};

export const canDeleteCurrentBusiness = ( state: StoreState ): boolean =>
{
    const userBusiness = getCurrentBusiness( state );
    return !!userBusiness && isCurrentBusinessAdmin( state ) && ownsMoreThanOneBusiness( state );
};

export const ownsMoreThanOneBusiness = ( state: StoreState ): boolean =>
{
    const businessesWithRole = getAllKnownBusinessesAndCurrentUserRole( state );

    const adminCount = reduce( businessesWithRole, ( count, businessWithRole ) =>
    {
        return count + (businessWithRole.isCurrentUserAdmin ? 1 : 0);
    }, 0 );

    return adminCount > 1;
};
