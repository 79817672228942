import { eventTracker } from "./";
import { appActions, persistor, store } from "../store";

export const INVALID_EMAIL_ADDRESS = "Invalid Email Address";

export function cleanUpAndRestartAfterError()
{
    eventTracker.clearUserId();
    eventTracker.clearUserProperties();
    persistor.purge().then( () =>
    {
        store.dispatch( appActions.reset() );
    } );
}

export const validateEmail = ( email: string ) => email && /^[^@^/]+@[^@^/]+\.[^@^/]+$/.test( email );
