import { Dispatch } from "redux";
import { store } from "../store";
import { isEmpty, trim } from "lodash";
import { designsActions, mixModelActions, modalActions, shareModelActions, uiActions, userActions } from "../actions";
import {
    AlertDialogContents,
    Design,
    EventData,
    FullPageDialogContents,
    LightboxContents,
    LoadingDialogContents,
    Post,
    SocialNetworkAccountType,
    StoreState,
} from "../_types";
import { getLightBoxCount } from "../ducks/modals";
import BrandSettingsDialogContainer from "../components/containers/BrandSettingsDialog.container";
import CreateBusinessDialogContainer from "../components/containers/CreateBusinessDialog.container";
import CircularProgress from "react-md/lib/Progress/CircularProgress";
import * as React from "react";
import {
    ALLOWED_MUSIC_EXTENSIONS,
    CustomizePostOptions,
    DELETE_ACCOUNT_REQUEST_SENT_MESSAGE,
    doesDesignSupportVideo,
    EPIDEMIC_COLLECTIONS_DIALOG_TITLE,
    ERROR_TITLE_OOPS,
    ERROR_TITLE_SORRY,
    eventTracker,
    history,
    HOMEPAGE_URL,
    IS_PROD,
    LightboxDialogIdentifierForKey,
    MANAGE_SUBSCRIPTION_PAGE_INTERNAL_URL,
    musicHelper,
    POST_UNABLE_TO_START,
    SWITCH_DESIGN_DOES_NOT_SUPPORT_ANY_VIDEO_ERROR_MESSAGE,
    SWITCH_DESIGN_DOES_NOT_SUPPORT_CURRENT_VIDEO_COUNT_ERROR_MESSAGE,
    TEMPLATE_PREVIEW_SOURCE_MERCHANDISING,
    TEMPLATE_PREVIEW_SOURCE_SOCIAL_CALENDAR,
    TemplatePreviewSources,
    URLS,
} from "../helpers";
import ChangeBusinessTypeDialogContainer from "../components/containers/ChangeBusinessTypeDialog.container";
import { AddAccountErrorDialogContent } from "../components/AddAccountErrorDialogContent";
import { RefreshWrongAccountAccountErrorDialogContent } from "../components/RefreshWrongAccountErrorDialogContent";
import { AcceptTestDriveDialog } from "../components/AcceptTestDriveDialog";
import { CircularProgressWithText } from "../components/CircularProgressWithText";
import { hasClosedPostIntents, hasSeenPostIntents, hasSeenShowYouAround, shouldBeShownProPreviewWelcomeDialog } from "../ducks/user";
import { teamDialogFactory } from "../components/teamDialogFactory";
import MediaPickerContainer from "../components/containers/MediaPicker.container";
import { hasVideoInMediaList } from "../ducks/mixModel";
// tslint:disable-next-line:import-spacing
import EpidemicSoundUserCollectionsDialogContainer
    from "../components/editorControls/musicPicker/containers/EpidemicSoundUserCollectionsDialog.container";
import { epidemicSoundCollectionActions } from "../actions/epidemicSoundCollections.actions";
import PostIntentsDialogContainer from "../components/containers/PostIntentsDialog.container";
import { doesCurrentBusinessTypeHavePostIntents } from "../ducks/userBusiness";
import TemplatePreviewDialogContainer from "../components/containers/TemplatePreviewDialog.container";
import { TEMPLATE_PREVIEW_IFRAME_ID } from "../components/TemplatePreviewBrandableView";
import { getDesignFromSlug } from "../ducks/designs";
import { catalogServices } from "./catalog.services";
import { logoutServices } from "./logout.services";
import { AlkaiCrossPromoDialogContent } from "../components/AlkaiCrossPromoDialog";
import { YouTubeDetailsDialog } from "../components/YouTubeDetailsDialog";

export const modalServices = {
    openFullpageDialog,
    openLightbox,
    openNextLightbox,
    openErrorDialog,
    openLowResErrorDialog,
    closeLowResErrorDialog,
    openErrorDialogWithStandardFormat,
    openAlertDialog,
    openConfirmationAlert,
    openChangeBusinessTypeDialog,
    openNewBusinessDialog,
    openLoadingDialog,
    openRiplSurveyDialog,
    openAlkaiCrossPromoDialog,
    openNotADraftDialog,
    closeAllLightboxes,
    closeTopLightBox,
    closeLightBoxesWithIdentifier,
    encloseInLoadingDialog,
    openErrorDialogAddAccount,
    openErrorDialogRefreshWrongAccount,
    showBrandSettings,
    showManageSubscriptionMessage,
    showHomeScreenDialogs,
    openAcceptTestDriveDialog,
    openForceCreateNewBusinessDialog,
    closeFullPageDialog,
    openTeamsErrorDialog,
    openReplaceMediaPickerDialog,
    openDesignCanNotSupportVideoCountErrorDialog,
    openInvalidMusicFileUploadError,
    openEpidemicUserCollectionsDialog,
    updateEpidemicLightboxTitle,
    openPostIntentsDialog,
    openTemplatePreviewDialogWithDesignControls,
    openTemplatePreviewDialogFromSocialCalendar,
    openDeleteAccountRequestSucceededDialog,
    openYouTubeDetailsDialog,
    openPostIntentsDialogFromHomeScreen,
};

function openFullpageDialog( data: FullPageDialogContents, e: EventData = {} )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        let { pageX, pageY } = e;
        const { changedTouches } = e;
        if ( !isEmpty( changedTouches ) )
        {
            pageX = changedTouches[0].pageX;
            pageY = changedTouches[0].pageY;
        }
        dispatch( modalActions.fullpageDialogOpen( { ...data, pageX, pageY } ) );
    };
}

function openNewBusinessDialog( additionalProps = {} )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( uiActions.clearBusinessInfo() );
        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.NEW_BUSINESS,
            width: 750,
            content: CreateBusinessDialogContainer,
            onClose: () =>
            {
                dispatch( uiActions.clearBusinessInfo() );
            },
            showCancelX: true,
            hideAlternateAction: true,
            hideConfirm: true,
            hideCancel: true,
            ...additionalProps,
        } ) );
    };
}

function openForceCreateNewBusinessDialog()
{
    const additionalProps = { modal: true, showCancelX: false };
    return openNewBusinessDialog( additionalProps );
}

function openChangeBusinessTypeDialog()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( uiActions.clearBusinessInfo() );
        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.CHANGE_BUSINESS_TYPE,
            width: 750,
            content: ChangeBusinessTypeDialogContainer,
            onClose: () => dispatch( uiActions.clearBusinessInfo() ),
            showCancelX: true,
            hideAlternateAction: true,
            hideConfirm: true,
            hideCancel: true,
        } ) );
    };
}

function openRiplSurveyDialog( userId )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.RIPL_USER_SURVEY_PROMPT,
            width: 476,
            title: "We value your feedback!",
            subtitle: "We would love to get your feedback on how Ripl can improve. "
                      + "Complete this 10-minute survey and be entered to win one of four $100 Amazon gift cards.",
            showCancelX: true,
            hideAlternateAction: true,
            hideConfirm: false,
            hideCancel: true,
            confirmLabel: "Share your thoughts",
            onSuccess: () =>
            {
                window.open( URLS.RiplUserSurvey2023 + userId );
            },
        } ) );
    };
}

function openAlkaiCrossPromoDialog( title: string, body: string, imageUrl: string, buttonText: string,
                                    cohort: string, link: string, userId: number )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.ALKAI_CROSS_PROMO,
            width: 500,
            title,
            subtitle: body,
            content: () => <AlkaiCrossPromoDialogContent imageUrl={imageUrl}/>,
            showCancelX: true,
            hideAlternateAction: true,
            hideConfirm: false,
            hideCancel: true,
            confirmLabel: buttonText,
            onSuccess: () =>
            {
                let internalFlag = "";
                if ( !IS_PROD )
                {
                    internalFlag = "&internal=true";
                }
                window.open( link + userId + "&cohort=" + cohort + internalFlag );
                eventTracker.logAlkaiCrossPromoDialogAccepted( cohort );
            },
            onCancel: () =>
            {
                eventTracker.logAlkaiCrossPromoDialogDeclined( cohort );
            },
            onClose: () =>
            {
                if ( document.getElementById( "alkaiCheckbox" ) )
                {
                    const checkbox = document.getElementById( "alkaiCheckbox" ) as HTMLInputElement;
                    if ( checkbox.checked )
                    {
                        dispatch( userActions.hasOptedOutOfAlkaiCrossPromo() );
                    }
                }
            },
        } ) );
    };
}

function openNotADraftDialog()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.NOT_A_DRAFT,
            width: 476,
            title: "Unable to continue",
            subtitle: "This post has already been saved, shared or deleted.",
            showCancelX: false,
            hideAlternateAction: true,
            hideConfirm: false,
            hideCancel: true,
            confirmLabel: "Ok",
            onSuccess: () =>
            {
                return;
            },
        } ) );
    };
}

function openDeleteAccountRequestSucceededDialog()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.DELETE_ACCOUNT_SUCCESS,
            width: 476,
            title: "We're sad to see you go",
            subtitle: DELETE_ACCOUNT_REQUEST_SENT_MESSAGE,
            showCancelX: false,
            hideAlternateAction: true,
            hideConfirm: false,
            hideCancel: true,
            confirmLabel: "Ok",
            onClose: () =>
            {
                dispatch( logoutServices.logout() );
            },
        } ) );
    };
}

function openBrandSettingsDialog( dispatch: Dispatch<StoreState>, source: string )
{
    dispatch( modalServices.openLightbox( {
        identifierForKey: LightboxDialogIdentifierForKey.BRAND_SETTINGS,
        width: 750,
        className: "brandSettingsDialogContainer",
        content: () => <BrandSettingsDialogContainer source={source}/>,
        showCancelX: true,
        hideCancel: true,
        hideConfirm: true,
        onClose: () => dispatch( uiActions.clearBusinessInfo() ),
    } ) );
}

function showBrandSettings( source?: string )
{
    eventTracker.logBrandSettingsButtonClicked( source );
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        openBrandSettingsDialog( dispatch, source );
    };
}

function openLightbox( data: LightboxContents )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalActions.lightboxDialogOpened( { ...data } ) );
    };
}

type DispatchFunc = ( dispatch: Dispatch<StoreState> ) => void;

function openNextLightbox( dataOrDispatch: LightboxContents | DispatchFunc )
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        await dispatch( modalActions.lightboxDialogClosedAll() );
        if ( typeof dataOrDispatch === "function" )
        {
            dispatch( dataOrDispatch );
        }
        else
        {
            dispatch( modalActions.lightboxDialogOpened( { ...dataOrDispatch } ) );
        }
    };
}

async function closeTopLightBox( dispatch: Dispatch<StoreState>, state: StoreState )
{
    await dispatch( modalActions.lightboxDialogClosed( getLightBoxCount( state ) - 1 ) );
}

async function closeLightBoxesWithIdentifier( dispatch: Dispatch<StoreState>,
                                              state: StoreState,
                                              identifierForKey: LightboxDialogIdentifierForKey )
{
    await dispatch( modalActions.lightboxDialogClosedByIdentifier( identifierForKey ) );
}

function closeAllLightboxes()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalActions.lightboxDialogClosedAll() );
    };
}

function closeFullPageDialog()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalActions.fullpageDialogCloseAll() );
    };
}

function openLoadingDialog( data: LoadingDialogContents )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalActions.loadingDialogOpen( { ...data } ) );
    };
}

function createStandardErrorMessage( primaryText: string, secondaryText?: string, errorText?: string )
{
    primaryText = primaryText ? `${primaryText}\n` : "";
    secondaryText = secondaryText ? `${secondaryText}\n` : "";
    errorText = errorText ? `\nError: ${errorText}\n` : "";
    return trim( primaryText + secondaryText + errorText );
}

function openErrorDialogWithStandardFormat( primaryText: string, secondaryText?: string, errorText?: string, showContactSupportUI: boolean = false,
                                            titleText?: string )
{
    const message = createStandardErrorMessage( primaryText, secondaryText, errorText );
    return openErrorDialog( titleText, message, showContactSupportUI );
}

function openErrorDialog( title: string, message: string, showContactSupportUI: boolean = false, onBeforeClose = null )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalActions.errorDialogOpen( {
            errorTitle: title,
            errorMessage: message,
            showContactSupportUI,
            onBeforeClose,
        } ) );
    };
}

function openDesignCanNotSupportVideoCountErrorDialog( state: StoreState, design: Design )
{
    const errorMessage = getErrorMessageForSwitchDesignCanNotSupportVideoCount( state, design );
    return openErrorDialog( ERROR_TITLE_OOPS,
        errorMessage );
}

function getErrorMessageForSwitchDesignCanNotSupportVideoCount( state: StoreState, design: Design )
{
    if ( !doesDesignSupportVideo( design ) && hasVideoInMediaList( state ) )
    {
        return SWITCH_DESIGN_DOES_NOT_SUPPORT_ANY_VIDEO_ERROR_MESSAGE;
    }
    else
    {
        return SWITCH_DESIGN_DOES_NOT_SUPPORT_CURRENT_VIDEO_COUNT_ERROR_MESSAGE;
    }
}

function openTeamsErrorDialog( title: string, subtitle: string )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {

        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.TEAMS_DIALOG_ERROR,
            width: null,
            showCancelX: true,
            modal: true,
            hideConfirm: true,
            hideCancel: true,
            content: teamDialogFactory.getTeamDialogWithError( title, subtitle ),
        } ) );
    };
}

function openErrorDialogAddAccount( accountType: SocialNetworkAccountType )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalActions.errorDialogOpen( {
            errorMessage: <AddAccountErrorDialogContent accountType={accountType}/>,
        } ) );
    };
}

function openErrorDialogRefreshWrongAccount( accountType: SocialNetworkAccountType )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalActions.errorDialogOpen( {
            errorMessage: <RefreshWrongAccountAccountErrorDialogContent accountType={accountType}/>,
        } ) );
    };
}

function openLowResErrorDialog( index: number, action: () => void )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalActions.lowResErrorDialogOpen( {
            visible: true,
            index,
            onReplace: action,
        } ) );
    };
}

function closeLowResErrorDialog()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalActions.lowResErrorDialogClose() );
    };
}

function openConfirmationAlert( title: string, message: string )
{
    const alertDialogContents = { title, message, showCancelX: false, modal: true };
    return openAlertDialog( alertDialogContents );
}

function openAlertDialog( alertDialogContents: AlertDialogContents )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalActions.alertDialogOpen( alertDialogContents ) );
    };
}

function encloseInLoadingDialog( enclosedAction: () => void, text?: string, width?: number, height?: number, dialogContainerClassName?: string )
{
    let content = () => <CircularProgress className="spinner large" id="spinner"/>;

    if ( text && text !== "" )
    {
        content = () => <CircularProgressWithText className="circularProgressWithBackground" progressText={text}/>;
    }

    store.dispatch( modalServices.openLoadingDialog( {
        dialogContainerClassName,
        className: "fullPageLoading",
        content,
        width,
        height,
        onShow: async () =>
        {
            try
            {
                await enclosedAction();
            }
                // tslint:disable-next-line:one-line
            finally
            {
                store.dispatch( modalActions.loadingDialogClose() );
            }
        },
    } ) );
}

function showManageSubscriptionMessage( title: string,
                                        content: string,
                                        confirmLabel: string )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.MANAGE_SUBSCRIPTION_MESSAGE,
            title,
            content,
            width: 450,
            showCancelX: true,
            hideAlternateAction: true,
            hideCancel: true,
            confirmLabel,
            onSuccess: () =>
            {
                history.push( MANAGE_SUBSCRIPTION_PAGE_INTERNAL_URL );
            },
        } ) );
    };
}

function showHomeScreenDialogs()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        if ( window.location.pathname === HOMEPAGE_URL )
        {
            const state = store.getState();
            if ( !hasSeenPostIntents( state ) )
            {
                dispatch( modalServices.openPostIntentsDialog() );
                dispatch( userActions.postIntentsDisplayed );
            }
            else if ( !hasSeenShowYouAround( state ) && hasClosedPostIntents( state ) )
            {
                dispatch( uiActions.displayShowYouAroundTour( true ) );
            }
        }
    };
}

function openAcceptTestDriveDialog()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        if ( shouldBeShownProPreviewWelcomeDialog( store.getState() ) )
        {
            dispatch( modalServices.openLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.ACCEPT_TEST_DRIVE,
                content: AcceptTestDriveDialog,
                modal: true,
                hideAlternateAction: true,
                hideCancel: true,
                showCancelX: false,
                confirmLabel: "Start free preview",
                width: 400,
                footerClassName: "testDriveShareBlockingDialogFooter",
                dialogContainerClassName: "acceptTestDriveDialogContainer",
                styleConfirmButtonAsSecondary: true,
                onSuccess: () =>
                {
                    eventTracker.logTestDriveAccepted();
                    dispatch( userActions.testDriveAccepted() );
                    dispatch( showHomeScreenDialogs() );
                },
            } ) );
        }
    };
}

function openPostIntentsDialog()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        if ( doesCurrentBusinessTypeHavePostIntents( store.getState() ) )
        {
            eventTracker.logPostIntentSelectionScreenDisplayed();
            dispatch( modalServices.openNextLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.POST_INTENTS,
                content: PostIntentsDialogContainer,
                title: "Create a post",
                modal: true,
                hideAlternateAction: true,
                hideCancel: true,
                showCancelX: true,
                hideConfirm: true,
                width: 500,
                dialogContainerClassName: "postIntentsDialogContainer",
                onCancel: () =>
                {
                    dispatch( userActions.postIntentsClosed() );

                    const state = store.getState();
                    if ( !hasSeenShowYouAround( state ) )
                    {
                        dispatch( uiActions.displayShowYouAroundTour( true ) );
                    }
                },
            } ) );
        }
    };
}

function openPostIntentsDialogFromHomeScreen()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        if ( doesCurrentBusinessTypeHavePostIntents( store.getState() ) )
        {
            eventTracker.logPostIntentSelectionScreenDisplayed();
            dispatch( modalServices.openNextLightbox( {
                identifierForKey: LightboxDialogIdentifierForKey.POST_INTENTS,
                content: PostIntentsDialogContainer,
                title: "Create a post",
                modal: true,
                hideAlternateAction: true,
                hideCancel: true,
                showCancelX: true,
                hideConfirm: true,
                width: 500,
                dialogContainerClassName: "postIntentsDialogContainer",
                onCancel: () =>
                {
                    dispatch( userActions.postIntentsClosed() );
                },
                onClose: () =>
                {
                    // prevent showYouAround (login/logout scenario)
                    dispatch( userActions.showYouAroundDisplayed() );
                },
            } ) );
        }
    };
}

function openTemplatePreviewDialogFromSocialCalendar( post: Post, customizeOptions: CustomizePostOptions,
                                                      holidayName: string, holidayDate: string,
                                                      source: TemplatePreviewSources = TEMPLATE_PREVIEW_SOURCE_SOCIAL_CALENDAR )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalServices.openTemplatePreviewDialogWithDesignControls( post, customizeOptions, source, holidayName, holidayDate ) );
    };
}

function openTemplatePreviewDialogWithDesignControls( post: Post, customizeOptions: CustomizePostOptions,
                                                      source: TemplatePreviewSources = TEMPLATE_PREVIEW_SOURCE_MERCHANDISING, holidayName?: string,
                                                      holidayDate?: string )
{
    const storeState = store.getState();
    const design = getDesignFromSlug( storeState, post.design_slug );

    return ( dispatch: Dispatch<StoreState> ) =>
    {
        catalogServices.loadDesignControlData( dispatch, design ).then( () =>
            {
                dispatch( openTemplatePreviewDialog( post, customizeOptions, source, holidayName, holidayDate ) );
            },
            () =>
            {
                dispatch( modalServices.openErrorDialog( ERROR_TITLE_SORRY, POST_UNABLE_TO_START ) );
                dispatch( designsActions.loadControlsFailure( POST_UNABLE_TO_START ) );
            },
        );
    };
}

function openTemplatePreviewDialog( post: Post, customizeOptions: CustomizePostOptions,
                                    source: TemplatePreviewSources = TEMPLATE_PREVIEW_SOURCE_MERCHANDISING, holidayName?: string,
                                    holidayDate?: string )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        eventTracker.logTemplatePreviewShown( source, post.design_slug );
        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.TEMPLATE_PREVIEW,
            content: () => <TemplatePreviewDialogContainer post={post}
                                                           customizeOptions={customizeOptions}
                                                           source={source}
                                                           holidayName={holidayName}
                                                           holidayDate={holidayDate}/>,
            modal: true,
            hideAlternateAction: true,
            hideCancel: true,
            showCancelX: true,
            hideConfirm: true,
            width: 800,
            dialogContainerClassName: "templatePreviewDialogContainer",
            onCancel: () =>
            {
                dispatch( mixModelActions.mixModelCancelled() );
                dispatch( uiActions.updateDesignLoadProgressCancelled( TEMPLATE_PREVIEW_IFRAME_ID ) );
            },
        } ) );
    };
}

function openReplaceMediaPickerDialog()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.REPLACE_MEDIA_PICKER,
            content: MediaPickerContainer,
            title: "Pick media",
            modal: true,
            hideAlternateAction: true,
            hideCancel: false,
            showCancelX: true,
            hideConfirm: true,
            width: 750,
            footerClassName: "replaceMediaPickerDialogFooter",
            dialogContainerClassName: "replaceMediaPickerDialogContainer",
        } ) );
    };
}

function openInvalidMusicFileUploadError()
{
    const errorMessage = `Can only upload the following types of music files: (${ALLOWED_MUSIC_EXTENSIONS.join( ", " )})`;
    return openErrorDialog( ERROR_TITLE_OOPS, errorMessage );
}

function openEpidemicUserCollectionsDialog()
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        musicHelper.stopPlayingAudioTrack( dispatch );
        dispatch( epidemicSoundCollectionActions.clearCollections() );
        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.EPIDEMIC_USER_COLLECTIONS,
            content: EpidemicSoundUserCollectionsDialogContainer,
            title: EPIDEMIC_COLLECTIONS_DIALOG_TITLE,
            modal: true,
            hideAlternateAction: true,
            hideCancel: true,
            showCancelX: true,
            hideConfirm: false,
            confirmLabel: "Done",
            width: 430,
            footerClassName: "epidemicUserCollectionsDialogFooter",
            dialogContainerClassName: "epidemicUserCollectionsDialogContainer",
            onClose: () =>
            {
                musicHelper.stopPlayingAudioTrack( dispatch );
            },
        } ) );
    };
}

function updateEpidemicLightboxTitle( title: string )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalActions.lightboxDialogUpdate(
            { identifierForKey: LightboxDialogIdentifierForKey.EPIDEMIC_USER_COLLECTIONS, title } ) );
    };
}

function openYouTubeDetailsDialog( enclosedAction: () => void )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.YOUTUBE_DETAILS,
            content: YouTubeDetailsDialog,
            modal: true,
            title: "YouTube Settings",
            confirmLabel: "Okay",
            hideCancel: true,
            hideAlternateAction: false,
            alternateActionLabel: "Skip",
            showCancelX: true,
            width: 500,
            onSuccess: () =>
            {
                const selectedPrivacy = (document.querySelector( "input[name=privacyRadio]:checked" ) as HTMLInputElement);
                const title = (document.getElementById( "youtubeTitle" ) as HTMLInputElement);
                dispatch( shareModelActions.youtubePrivacyUpdated( selectedPrivacy && selectedPrivacy.value || "public" ) );
                dispatch( shareModelActions.youtubeTitleUpdated( title && title.value || "" ) );
                enclosedAction();
            },
            onCancel: () =>
            {
                // do nothing
            },
            onAlternate: () =>
            {
                enclosedAction();
            },
        } ) );
    };
}
