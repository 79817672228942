import { PartnerState, StoreState, UIState } from "../_types";
import { ReducerCreator } from "../helpers";
import { partnerActions } from "../actions/partner.actions";
import { Action } from "redux-actions";

const defaultState: PartnerState = {};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( partnerActions.loginDatumIdSet, handleReferenceIdSet );
reducerCreator.addAction( partnerActions.redirectUrlSet, handleRedirectUrlSet );
reducerCreator.addAction( partnerActions.historyReferrerIndexSet, handleSetHistoryReferrerIndex );
export default reducerCreator.createReducer();

function handleReferenceIdSet( state: PartnerState, action: Action<string> ): PartnerState
{
    const referenceId = action.payload;
    return {
        ...state,
        referenceId,
    };
}

function handleRedirectUrlSet( state: PartnerState, action: Action<string> ): PartnerState
{
    const redirectUrl = action.payload;
    return {
        ...state,
        redirectUrl,
    };
}

function handleSetHistoryReferrerIndex( state: UIState, action: Action<number> ): PartnerState
{
    const historyReferrerIndex = action.payload;
    return {
        ...state,
        historyReferrerIndex,
    };
}

export const getLoginDatumId = ( state: StoreState ): string =>
{
    return state.partner.referenceId;
};

export const getRedirectUrl = ( state: StoreState ): string =>
{
    return state.partner.redirectUrl;
};

export const getHistoryReferrerIndex = ( state: StoreState ): number =>
{
    return state.partner.historyReferrerIndex;
};
