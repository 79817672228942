import * as React from "react";
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import CircularProgress from "react-md/lib/Progress/CircularProgress";
import * as InfiniteScroll from "react-infinite-scroller";
import PostsPanel from "./PostsPanel";
import { Post } from "../../_types";
import * as VisibilitySensor from "react-visibility-sensor";

export interface InspirationGridProps
{
    hasFetchedData: boolean;
    hasNextPage: boolean;
    nextPage: number;
    inspirationPosts: Post[];
}

export interface InspirationGridDispatchProps
{
    loadMoreInspirations: ( page: number ) => void;
}

interface InspirationGridState
{
    lastRequestedPage: number;
}

export class InspirationGrid extends React.Component<InspirationGridProps & InspirationGridDispatchProps, InspirationGridState>
{
    constructor( props )
    {
        super( props );
        this.state = {
            lastRequestedPage: undefined,
        };
    }

    public render()
    {
        const { hasNextPage, nextPage, inspirationPosts } = this.props;
        const pageStart = (nextPage - 1) || 1;
        return (
            <VisibilitySensor
                onChange={this.handleVisibilityChange}
                partialVisibility={true}
                scrollThrottle={100}>
                <Grid className="inspireGrid">
                    <Cell size={12} tabletSize={8} className="inspirations">
                        <InfiniteScroll
                            pageStart={pageStart}
                            loadMore={this.loadMore}
                            hasMore={hasNextPage}
                            loader={<CircularProgress key="0" className="spinner small" id="spinner"/>}
                            useWindow={true}>
                            <PostsPanel postData={inspirationPosts} showCreateBlank={false}/>
                        </InfiniteScroll>
                    </Cell>
                </Grid>
            </VisibilitySensor>
        );
    }

    private loadMore = () =>
    {
        const { nextPage } = this.props;
        const pageToLoad = nextPage || 1;
        if ( !this.wasPageAlreadyRequested( pageToLoad ) )
        {
            this.setState( { lastRequestedPage: pageToLoad } );
            this.props.loadMoreInspirations( pageToLoad );
        }
    }

    private handleVisibilityChange = ( isVisible ) =>
    {
        if ( isVisible && !this.dataWasRequestedOrLoaded() )
        {
            this.setState( { lastRequestedPage: 1 } );
            this.props.loadMoreInspirations( 1 );
        }
    }

    private wasPageAlreadyRequested = ( pageToLoad: number ) =>
    {
        return this.state.lastRequestedPage ? pageToLoad <= this.state.lastRequestedPage : false;
    }

    private dataWasRequestedOrLoaded = () =>
    {
        const dataWasRequested = !!this.state.lastRequestedPage;
        const dataWasLoaded = this.props.hasFetchedData;
        return dataWasLoaded || dataWasRequested;
    }
}
