import * as React from "react";
import { concat } from "lodash";
import Button from "react-md/lib/Buttons/Button";
import { DesignElementTabs } from "../DesignElementTabs";
import CustomizeTextInputFieldContainer from "../containers/CustomizeTextInputField.container";
import {
    CAPTION_TEXT_FIELD_NAME,
    DESIGN_ELEMENT_PROPERTY_CATEGORY_ID_ALIGNMENT,
    DESIGN_ELEMENT_PROPERTY_CATEGORY_ID_ANIMATION,
    DESIGN_ELEMENT_PROPERTY_CATEGORY_ID_COLOR,
    DESIGN_ELEMENT_PROPERTY_CATEGORY_ID_FONT,
    DESIGN_ELEMENT_PROPERTY_CATEGORY_ID_STYLE,
    DesignElementPropertyCategoryId,
} from "../../../_types/api";
import AddTextBoxButtonContainer from "../containers/AddTextBoxButton.container";
import { DesignElementProperties, DesignElementPropertyCategory, TextPickerCaptionData } from "../../../_types";
import AlignTextChoicePanelContainer from "../containers/AlignTextChoicePanel.container";
import ColorTextChoicePanelContainer from "../containers/ColorTextChoicePanel.container";
import FontPickerTextPropertiesSubPanelContainer from "../containers/FontPickerTextPropertiesSubPanel.container";
import { eventTracker } from "../../../helpers";
import { StylizedTextChoicePanelContainerProps } from "../StylizedTextChoicePanel";
import StylizedTextChoicePanelContainer from "../containers/StylizedTextChoicePanel.container";
import AnimationPickerTextPropertiesSubPanelContainer from "../containers/AnimationPickerTextPropertiesSubPanel.container";

export interface TextPropertiesSubPanelProps extends StylizedTextChoicePanelContainerProps
{
    shouldEnablePresetText: boolean;
    captionData: TextPickerCaptionData;
    focusElement: string;
    designElementProperties?: DesignElementProperties;
    onBackToTextClicked();
}

export interface TextPropertiesSubPanelDispatchProps
{
    clearStylizedTextButtonCoachMark();
    requestTextProperties( captionId: string );
}

interface TextPropertiesSubPanelState
{
    selectedCategoryId: DesignElementPropertyCategoryId;
}

export class TextPropertiesSubPanel extends React.PureComponent<TextPropertiesSubPanelProps &
    TextPropertiesSubPanelDispatchProps, TextPropertiesSubPanelState>
{
    public constructor( props )
    {
        super( props );
        this.state = {
            selectedCategoryId: DESIGN_ELEMENT_PROPERTY_CATEGORY_ID_STYLE,
        };
    }

    public componentDidMount()
    {
        eventTracker.logTextPropertiesOpened();
        if ( this.props.captionData )
        {
            this.props.requestTextProperties( this.props.captionData.id );
        }
    }

    public render()
    {
        let containerForSelectedTab = <StylizedTextChoicePanelContainer/>;
        if ( this.state.selectedCategoryId === DESIGN_ELEMENT_PROPERTY_CATEGORY_ID_ALIGNMENT )
        {
            containerForSelectedTab = <AlignTextChoicePanelContainer/>;
        }
        else if ( this.state.selectedCategoryId === DESIGN_ELEMENT_PROPERTY_CATEGORY_ID_COLOR )
        {
            containerForSelectedTab = <ColorTextChoicePanelContainer/>;
        }
        else if ( this.state.selectedCategoryId === DESIGN_ELEMENT_PROPERTY_CATEGORY_ID_FONT )
        {
            containerForSelectedTab = <FontPickerTextPropertiesSubPanelContainer/>;
        }
        else if ( this.state.selectedCategoryId === DESIGN_ELEMENT_PROPERTY_CATEGORY_ID_ANIMATION )
        {
            containerForSelectedTab = <AnimationPickerTextPropertiesSubPanelContainer/>;
        }

        return (
            <div className="textPropertiesSubPanel">
                <div className="subPanelTopSection">
                    <div className="topButtons">
                        <Button flat
                                secondary
                                className="backToText secondary outline"
                                iconChildren="arrow_back_ios"
                                onClick={this.props.onBackToTextClicked}>Back to Text</Button>
                        <AddTextBoxButtonContainer showPlusButton={true}
                                                   tooltipPosition="bottom"
                                                   shouldCreateGlobalText={this.props.shouldEnablePresetText
                                                                           && this.props.captionData
                                                                           && this.props.captionData.showOnAllSlides}/>
                    </div>
                    <div className="captionTextFieldWrap">
                        {this.props.captionData && <CustomizeTextInputFieldContainer identifier={this.props.captionData.id}
                                                                                     value={this.props.captionData.value}
                                                                                     styleId={this.props.captionData.styleId}
                                                                                     placeholder="Add your own text"
                                                                                     type={CAPTION_TEXT_FIELD_NAME}
                                                                                     focusElement={this.props.focusElement}
                                                                                     supportsDeletion={true}
                                                                                     shouldEnableStylizedText={false}/>}
                    </div>
                    <DesignElementTabs designElementCategories={this.getDesignElementCategories()}
                                       selectedCategoryId={this.state.selectedCategoryId}
                                       onSwitchCategoryId={this.onSwitchCategoryId}/>
                </div>
                <div className="selectedTabContainer">
                    {containerForSelectedTab}
                </div>
            </div>
        );
    }

    private getDesignElementCategories = (): DesignElementPropertyCategory[] =>
    {
        const hardCodedStyleCategory: DesignElementPropertyCategory = {
            id: DESIGN_ELEMENT_PROPERTY_CATEGORY_ID_STYLE,
            displayName: DESIGN_ELEMENT_PROPERTY_CATEGORY_ID_STYLE,
        };

        const categories = [hardCodedStyleCategory];

        if ( this.props.designElementProperties )
        {
            return concat( categories, this.props.designElementProperties.categories );
        }
        return categories;
    }

    private onSwitchCategoryId = ( selectedCategoryId: DesignElementPropertyCategoryId ) =>
    {
        this.setState( {
            selectedCategoryId,
        } );
    }
}
