import { Dispatch } from "redux";
import { scheduledActionsActions } from "../actions";
import { CATALOG_VERSION, ERROR_TITLE_SORRY, normalizeScheduledActionsAPI, requests } from "../helpers";
import { getCurrentBusinessId, getCurrentBusinessTypeId } from "../ducks/userBusiness";
import { format } from "date-fns";
import { ScheduledActionsWrapperAPIData } from "../_types/api";
import { StoreState } from "../_types";
import { modalServices } from "./";

export const scheduledActionServices = {
    fetch,
};

const SCHEDULED_ACTIONS_URL_PREFIX = "/scheduled_actions";

const SCHEDULED_ACTIONS_FOR_WEEKLY_RECOMMENDATIONS_URL = SCHEDULED_ACTIONS_URL_PREFIX + "/scheduled_actions_for_weekly_recommendations";

function fetch( state: StoreState, date: Date )
{
    return ( dispatch: Dispatch<StoreState> ) =>
    {
        const currentBusinessId = getCurrentBusinessId( state );
        const businessTypeId = getCurrentBusinessTypeId( state );

        // TODO wire up A/B testing via the FB categories
        const queryParams = {
            business_type_id: businessTypeId,
            business_id: currentBusinessId,
            date: format( date, "YYYY-MM-DD" ),
            catalog_version_id: CATALOG_VERSION,
        };
        return requests.get<ScheduledActionsWrapperAPIData>( SCHEDULED_ACTIONS_FOR_WEEKLY_RECOMMENDATIONS_URL, queryParams ).then(
            ( data ) =>
            {
                const normalizedData = normalizeScheduledActionsAPI( data.scheduled_actions, data.today );
                dispatch( scheduledActionsActions.loadedSuccess( normalizedData ) );
            },
            ( error: string ) =>
            {
                dispatch( scheduledActionsActions.loadedFailure( error ) );
                dispatch( modalServices.openErrorDialog( ERROR_TITLE_SORRY,
                    "Unable to load your recommended posts. Refresh your browser to see recommendations." ) );
            },
        );
    };
}
