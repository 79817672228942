import * as React from "react";

export const WaveIcon: React.FC<{}> = () =>
{
    return (
        /* tslint:disable:max-line-length */
        // @ts-ignore
        <svg id="Layer_1" className="waveIcon" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
            <path className="cls-1" d="M49.17,48.91a9.09,9.09,0,0,0,8.73-9.43"/>
            <path className="cls-1" d="M47,44.77A6.91,6.91,0,0,0,53.6,37.6"/>
            <path className="cls-1" d="M20.56,5a9.08,9.08,0,0,0-8.73,9.43"/>
            <path className="cls-1" d="M22.76,9.14a6.9,6.9,0,0,0-6.63,7.17"/>
            <path className="cls-1"
                  d="M39.27,50.1c-4.48,8.25-14,12.3-22.52,8.41S4.76,45.8,6.07,36l.81-5.27L8,24.56a3.4,3.4,0,0,1,4-2.7h0a3.4,3.4,0,0,1,2.71,4l-1.49,9.35a1.37,1.37,0,0,0,1.2,1.57h0A1.38,1.38,0,0,0,15.77,36L25.89,13.78a3.41,3.41,0,0,1,4.51-1.69h0a3.42,3.42,0,0,1,1.69,4.52L25,32.16"/>
            <path className="cls-1" d="M39.27,50.1,49.39,27.91a3.4,3.4,0,0,0-1.68-4.51h0a3.41,3.41,0,0,0-4.52,1.69l-5.8,12.73"/>
            <path className="cls-1" d="M37.39,37.82l7.53-16.51a3.41,3.41,0,0,0-1.69-4.51h0a3.4,3.4,0,0,0-4.51,1.68L31.19,35"/>
            <path className="cls-1" d="M31.19,35,40,15.62a3.39,3.39,0,0,0-1.68-4.51h0a3.4,3.4,0,0,0-4.51,1.69L25,32.16"/>
        </svg>
    );
};
