import * as React from "react";
import Button from "react-md/lib/Buttons/Button";

export interface SocialAccountSettingsButtonProps
{
    onClick?();
}

export default class SocialAccountSettingsButton extends React.PureComponent<SocialAccountSettingsButtonProps>
{

    public render()
    {
        return (
            this.createAccountSettingsButton()
        );
    }

    private createAccountSettingsButton()
    {
        return (<div>
            <Button flat secondary
                    className="addPlacesToShareBtn"
                    onClick={this.props.onClick}
                    iconBefore={true}>Add more places to share</Button>
        </div>);
    }
}
