import { connect } from "react-redux";
import { Dispatch } from "redux";
import { DialogControlProps, StoreState } from "../../_types";
import { TeamDialog, TeamDialogDispatchProps, TeamDialogProps } from "../TeamDialog";

export type TeamDialogContainerProps = TeamDialogProps & DialogControlProps;

const mapStateToProps = ( storeState: StoreState, ownProps: TeamDialogContainerProps ): TeamDialogProps =>
{
    const { closeDialog, ...otherProps } = ownProps;
    return otherProps;
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: TeamDialogContainerProps ): TeamDialogDispatchProps =>
{
    return {
        handleCancel()
        {
            ownProps.closeDialog();
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( TeamDialog );
