import * as React from "react";
import { Elements } from "react-stripe-elements";
import UpdateCardFormContainer, { UpdateCardFormContainerProps } from "./containers/UpdateCardForm.container";

export class UpdateCardFormDialog extends React.PureComponent<UpdateCardFormContainerProps>
{
    public render()
    {
        return (
            <div>
                <Elements>
                    <UpdateCardFormContainer {...this.props}/>
                </Elements>
            </div>
        );
    }
}
