import * as React from "react";
import { PROFITWELL_AUTH_TOKEN } from "../helpers";
import Profitwell from "profitwell-component";

export interface ProfitWellWrapperProps
{
    userEmail: string;
    forceUpdate: Date;
}

interface ProfitWellWrapperState
{
    hasStarted: boolean;
}

export class ProfitWellWrapper extends React.PureComponent<ProfitWellWrapperProps, ProfitWellWrapperState>
{
    constructor( props )
    {
        super( props );

        this.state = {
            hasStarted: false,
        };
    }

    public componentDidUpdate()
    {
        this.startProfitWellEngagementTracking();
    }

    public render()
    {
        return this.createInitializedProfitWellComponent();
    }

    private createInitializedProfitWellComponent()
    {
        if ( this.props.userEmail )
        {
            return (<Profitwell authToken={PROFITWELL_AUTH_TOKEN} email={this.props.userEmail}/>);
        }
        return null;
    }

    private startProfitWellEngagementTracking = () =>
    {
        const profitwell = window.profitwell;

        if ( profitwell && this.props.userEmail && !this.state.hasStarted )
        {
            profitwell( "start", { user_email: this.props.userEmail } );
            this.setState( { hasStarted: true } );
        }
    }
}
