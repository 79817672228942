import * as React from "react";
import * as InfoImage from "../assets/img/cautionIcon.png";

export interface CantRefreshButtonProps
{
    label?: string;
    className?: string;
}

export interface CantRefreshButtonDispatchProps
{
    onInfoClick?(): void;
}

export class CantRefreshButton extends React.PureComponent<CantRefreshButtonProps & CantRefreshButtonDispatchProps>
{
    public render()
    {
        return <div className={this.props.className}>
            <label className="md-selection-control-label">{this.props.label}
                <span className="standardLink" onClick={this.handleClick}>
                    <img src={InfoImage} className="md-btn md-btn--flat md-pointer--hover md-inline-block md-cell--center info-button"
                         alt="Cant Refresh" height="40px"/>
                </span>
            </label>
        </div>;
    }

    private handleClick = ( e: React.MouseEvent<HTMLAnchorElement> ) =>
    {
        e.preventDefault();
        this.props.onInfoClick();
    }
}
