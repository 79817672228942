import * as React from "react";
import { ChangeEvent } from "react";
import * as cameraIcon from "../../assets/img/cameraIcon.png";
import Switch from "react-md/lib/SelectionControls/Switch";
import CircularProgress from "react-md/lib/Progress/CircularProgress";

export interface BrandSettingsLogoProps
{
    brandLogo: string;
    showBrandLogo: boolean;
    tempLogo?: File;
    isLogoUpdating: boolean;
}

export interface BrandSettingsLogoDispatchProps
{
    handleShowBrandLogoFlag( enabled: boolean );
    handleSaveTempLogo( file: File );
    guardLogoClicked( onSubscribe: () => void );
}

export class BrandSettingsLogo extends React.PureComponent<BrandSettingsLogoProps & BrandSettingsLogoDispatchProps>
{
    public render()
    {
        return (
            <div className="brandSettingsLogoContainer">
                <div className="logoSwitchContainer">
                    <h3>Logo</h3>
                    <Switch id="logoSwitch"
                            aria-label="logo enabled"
                            name="logoEnabled"
                            className="settingSwitch"
                            checked={this.props.showBrandLogo} onChange={this.props.handleShowBrandLogoFlag}/>
                </div>
                <h4 className="description">
                    If turned on, every post will start with your logo included, with the ability to hide it.
                </h4>
                <div className="brandLogo">
                    {!this.props.isLogoUpdating && this.renderLogoImage()}
                    {this.props.isLogoUpdating && this.renderProgress()}
                </div>
            </div>
        );
    }

    private renderLogoImage()
    {
        return <>
            <div className="imageContainer" style={{ "--aspect-ratio": 1 }} onClick={this.logoClicked}>
                <img className="logoImage"
                     src={this.props.tempLogo ? URL.createObjectURL( this.props.tempLogo ) : this.props.brandLogo}
                     alt="brand logo"/>
                <div className="cameraOverlay" style={{ "--aspect-ratio": 1 }}>
                    <img className="cameraIcon" src={cameraIcon} alt="camera capture"/>
                </div>
            </div>
            <input type="file" ref="imageUpload" className="hidden" accept="image/*" id="image-file"
                   onChange={this.handleUploadTempLogo}/>
        </>;
    }

    private renderProgress()
    {
        return (<div className="progressWrap">
            <CircularProgress className="spinner small" id="spinner"/>
        </div>);
    }

    private handleUploadTempLogo = ( e: ChangeEvent<HTMLInputElement> ) =>
    {
        const imageFile = e.target.files[0];
        this.props.handleSaveTempLogo( imageFile );
    }

    private logoClicked = () =>
    {
        this.props.guardLogoClicked( () => (this.refs.imageUpload as HTMLInputElement).click() );
    }
}
