import * as React from "react";
import CookieConsent from "react-cookie-consent";
import {
    COOKIE_CONSENT_ACCEPT_BUTTON_TEXT,
    COOKIE_CONSENT_MESSAGE,
    GDPR_CONSENT_ACCEPT_BUTTON_TEXT,
    GDPR_CONSENT_MESSAGE,
    isUserInEU,
    PRIVACY_POLICY_URL,
} from "../helpers";

const GDPR_CONSENT_COOKIE_NAME = "GDPR_CONSENT";
const COOKIE_CONSENT_COOKIE_NAME = "COOKIE_CONSENT";

export interface ConsentBannerProps
{
    countryCode?: string;
}

export interface ConsentBannerDispatchProps
{
    loadUserCountry: () => void;
}

export interface ConsentBannerState
{
    gdprAccepted: boolean;
}

export class ConsentBanner extends React.PureComponent<ConsentBannerProps & ConsentBannerDispatchProps, ConsentBannerState>
{
    constructor( props )
    {
        super( props );
        this.state = {
            gdprAccepted: false,
        };
    }

    public componentDidMount(): void
    {
        if ( !this.props.countryCode )
        {
            this.props.loadUserCountry();
        }
    }

    public render()
    {
        if ( this.userShouldSeeGDPRConsentBanner() )
        {
            if ( this.state.gdprAccepted || this.gdprCookieIsSet() )
            {
                return <></>;
            }
            else
            {
                return (
                    <div className="consentOverlay">
                        <CookieConsent
                            cookieName={GDPR_CONSENT_COOKIE_NAME}
                            disableStyles={true}
                            location="bottom"
                            containerClasses="consentContainer"
                            contentClasses="consentContent"
                            buttonClasses="consentButton"
                            buttonText={GDPR_CONSENT_ACCEPT_BUTTON_TEXT}
                            onAccept={this.onGDPRConsentRequestAccepted}>
                            <p>
                                {GDPR_CONSENT_MESSAGE} {this.getPrivacyPolicyLink()}
                            </p>
                        </CookieConsent>
                    </div>
                );
            }
        }
        else
        {
            return (
                <CookieConsent
                    cookieName={COOKIE_CONSENT_COOKIE_NAME}
                    disableStyles={true}
                    location="bottom"
                    containerClasses="consentContainer"
                    contentClasses="consentContent"
                    buttonClasses="consentButton"
                    buttonText={COOKIE_CONSENT_ACCEPT_BUTTON_TEXT}>
                    <p>
                        {COOKIE_CONSENT_MESSAGE} {this.getPrivacyPolicyLink()}
                    </p>
                </CookieConsent>
            );
        }
    }

    private userShouldSeeGDPRConsentBanner = () =>
    {
        return isUserInEU( this.props.countryCode );
    }

    private gdprCookieIsSet()
    {
        return document.cookie.indexOf( GDPR_CONSENT_COOKIE_NAME ) > 0;
    }

    private onGDPRConsentRequestAccepted = () =>
    {
        this.setState( {
            gdprAccepted: true,
        } );
    }

    private getPrivacyPolicyLink = () =>
    {
        return <a className="standardLink" href={PRIVACY_POLICY_URL} target="_blank">Privacy Policy</a>;
    }
}
