import * as React from "react";
import { FontData } from "../../_types";
import { ListItemProps } from "react-md/lib/Lists";
import Autocomplete from "react-md/lib/Autocompletes/Autocomplete";
import { AutocompleteProps } from "react-md/lib/Autocompletes";
import * as Fuse from "fuse.js";
import { getDesignFontName, getFontCssUrl, getFontDisplayName, webFontLoader } from "../../helpers";
import { map } from "lodash";
import { FontPreview } from "../settingsPages/FontPreview";

export interface FontAutocompleteBaseContainerProps
{
    autocompleteValue: string;
    selectedFont: FontData;
    idBaseName: string;
    labelText?: string;
    placeholderText?: string;
}

export interface FontAutocompleteProps extends FontAutocompleteBaseContainerProps
{
    autoCompleteFonts: FontData[];
}

export interface FontAutocompleteDispatchProps
{
    onChange: ( value: string ) => void;
    onAutocomplete: ( suggestion: string ) => void;
}

interface FontAutocompleteState
{
    filteredData: ListItemProps[];
}

export class FontAutocomplete extends React.PureComponent<FontAutocompleteProps & FontAutocompleteDispatchProps, FontAutocompleteState>
{
    private readonly fontIndex: Fuse<ListItemProps>;
    private readonly unfilteredListItems: ListItemProps[];

    constructor( props )
    {
        super( props );
        this.unfilteredListItems = map( this.props.autoCompleteFonts, this.generateFontRow );
        this.fontIndex = this.createIndexer();
        this.state = {
            filteredData: this.createInitialFilterData(),
        };
        webFontLoader.loadFont( this.props.selectedFont );
    }

    public componentDidUpdate( prevProps: FontAutocompleteProps & FontAutocompleteDispatchProps )
    {
        this.loadCustomFontIfChanged( this.props.selectedFont, prevProps.selectedFont );
    }

    public render()
    {
        const value = this.determineAutocompleteValue();
        const fontFamily = this.determineFontFamilyToUse( value );
        const autocompleteProps: AutocompleteProps = {
            id: this.props.idBaseName + "_selector",
            value,
            label: this.props.labelText,
            dataValue: "primaryText",
            dataLabel: "primaryText",
            data: this.state.filteredData,
            filter: null,
            className: "fontAutocomplete",
            placeholder: this.props.placeholderText || "Type Font Name",
            style: { fontFamily },
            onChange: this.handleOnChange,
            onAutocomplete: this.handleOnAutocomplete,
        };

        return <Autocomplete
            autoComplete="off"
            {...autocompleteProps}
        />;
    }

    private determineAutocompleteValue = (): string =>
    {
        const value = this.props.autocompleteValue;

        if ( !value && value !== "" )
        {
            return this.getSelectedFontDisplayName();
        }

        return value;
    }

    private determineFontFamilyToUse = ( autocompleteValue: string ): string =>
    {
        const selectedFontDisplayName = this.getSelectedFontDisplayName();
        const isDisplayingSelectedFont = autocompleteValue === selectedFontDisplayName;
        return isDisplayingSelectedFont ? this.getSelectedFontDesignName() : undefined;
    }

    private getSelectedFontDisplayName = (): string =>
    {
        return getFontDisplayName( this.props.selectedFont ) || "";
    }

    private getSelectedFontDesignName = (): string =>
    {
        return getDesignFontName( this.props.selectedFont ) || "";
    }

    private handleOnChange = ( value: string ) =>
    {
        this.filterWithFuseJS( value );
        this.props.onChange( value );
    }

    private handleOnAutocomplete = ( value ) =>
    {
        this.props.onAutocomplete( value );
    }

    private createIndexer(): Fuse<ListItemProps>
    {
        const options: Fuse.FuseOptions<ListItemProps> = {
            keys: ["primaryText"],
        };
        return new Fuse( this.unfilteredListItems, options );
    }

    private createInitialFilterData(): ListItemProps[]
    {
        const filterText = this.props.autocompleteValue;
        if ( filterText )
        {
            return this.fontIndex.search( filterText );
        }
        else
        {
            return this.unfilteredListItems;
        }
    }

    private filterWithFuseJS = ( filterText ) =>
    {
        const indexer = this.fontIndex;
        this.setState( { filteredData: indexer.search( filterText ) } );
    };

    private generateFontRow = ( font: FontData ): ListItemProps =>
    {
        return {
            primaryTextStyle: { fontFamily: getDesignFontName( font ) },
            primaryText: getFontDisplayName( font ),
            displayName: getFontDisplayName( font ),
            designFontName: getDesignFontName( font ),
            font,
            component: FontPreview,
        };
    }

    private loadCustomFontIfChanged = ( customFont: FontData, prevCustomFont?: FontData ) =>
    {
        const customFontCssUrl = getFontCssUrl( customFont );
        const prevCustomFontCssUrl = getFontCssUrl( prevCustomFont );
        const isFontCssUrlSet = !!customFontCssUrl;
        const hasFontCssUrlChanged = customFontCssUrl !== prevCustomFontCssUrl;
        if ( isFontCssUrlSet && hasFontCssUrlChanged )
        {
            this.loadCustomFont( customFontCssUrl );
        }
    }

    private loadCustomFont( customFontCssUrl: string )
    {
        webFontLoader.loadCustomFontIfSpecified( customFontCssUrl );
    }
}
