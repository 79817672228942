import { connect } from "react-redux";
import { Dispatch } from "redux";
import { uiActions } from "../../actions";
import { Calendar, CalendarDispatchProps, CalendarProps } from "../Calendar";
import { getPriorityData } from "../../ducks/post";
import { postServices } from "../../services";
import { StoreState } from "../../_types";

const mapStateToProps = ( state: StoreState ): CalendarProps =>
{
    return {
        priorityData: getPriorityData( state ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): CalendarDispatchProps =>
{
    return {
        onMonthChange: ( month: Date ) =>
        {
            dispatch( postServices.loadPriorityData( month ) );
        },
        dateSelected: ( selectedDate: Date ) =>
        {
            dispatch( postServices.loadPostsForDate( selectedDate ) );
            dispatch( uiActions.calendarDatePicked( selectedDate ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( Calendar );
