import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../../_types";
import { modalServices } from "../../../services";
import { BusinessInfoPanel, BusinessInfoPanelDispatchProps, BusinessInfoPanelProps } from "../BusinessInfoPanel";
import { getCurrentBusiness } from "../../../ducks/userBusiness";

const mapStateToProps = ( storeState: StoreState ): BusinessInfoPanelProps =>
{
    const currentBusiness = getCurrentBusiness( storeState );
    return {
        currentBusiness,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): BusinessInfoPanelDispatchProps =>
{
    return {
        handleOpenCreateBusiness: () =>
        {
            dispatch( modalServices.openNewBusinessDialog() );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( BusinessInfoPanel );
