import * as React from "react";
import { map } from "lodash";
import { MultipleEmailPicker } from "./MultipleEmailPicker";
import { ExplanationFeatureItem } from "./ExplanationFeatureItem";
import { TEAM_BENEFITS_FEATURES_DESCRIPTIONS } from "../helpers/teamConstants";

export interface InviteTeamMembersDialogContentProps
{
    numberOfRemainingInvites: number;
}

export interface InviteTeamMembersDialogContentDispatchProps
{
    sendInvites( emails: string[] ): void;
}

export class InviteTeamMembersDialogContent extends React.PureComponent<InviteTeamMembersDialogContentProps
    & InviteTeamMembersDialogContentDispatchProps>
{
    public constructor( props )
    {
        super( props );
    }

    public render()
    {
        return (
            <div className="inviteTeamMembersDialogContainer">
                <div className="featureItemContainer">{this.renderTeamBenefitsFeatureList()}</div>
                <MultipleEmailPicker
                    submitButtonText={"Send invite"}
                    maxEmailsToPick={this.props.numberOfRemainingInvites}
                    onSubmit={this.props.sendInvites}/>
            </div>
        );
    }

    private renderTeamBenefitsFeatureList()
    {
        return map( TEAM_BENEFITS_FEATURES_DESCRIPTIONS, ( description, featureKey ) =>
            <ExplanationFeatureItem key={featureKey} isSupported={true} options={{ description, key: featureKey, isBold: false }}/> );
    }
}
