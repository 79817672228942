import * as React from "react";
import { map } from "lodash";
import { TiersExplanationTabButton } from "./TiersExplanationTabButton";
import { RIPL_TIER_PREMIUM, TierTabData } from "../helpers";

export interface TiersExplanationTabsProps
{
    tierTabs: TierTabData[];
    selectedTab: ProductTier;
}

export interface TiersExplanationTabsDispatchProps
{
    selectTab( tier: ProductTier );
}

export function TiersExplanationTabs( props: TiersExplanationTabsProps & TiersExplanationTabsDispatchProps )
{
    const { tierTabs, selectedTab, selectTab } = props;

    const tabs = map( tierTabs, ( tab, idx ) =>
        <TiersExplanationTabButton
            key={idx}
            name={tab.tierName}
            isSelected={tab.tier === selectedTab}
            isPremium={tab.tier === RIPL_TIER_PREMIUM}
            selectTab={() => selectTab( tab.tier )}
        />,
    );

    return <>{tabs}</>;
}
