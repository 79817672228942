import { store } from "../store";
import * as amplitude from "amplitude-js";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import { getAllKnownBusinesses, getCurrentBusiness } from "../ducks/userBusiness";
import {
    canUserAccessPaidFeatures,
    getCurrentProductTier,
    getCurrentUserId,
    getPartner,
    getProductSku,
    getSubscriptionStatus,
    getUserClientId,
    getUserEmail,
    getUserPromotionNames,
    getUserSocialNetworkAccounts,
    hasValidConnectionToEpidemicSound,
    isCurrentProductTierPremium,
    isFacebookChildAccountType,
    isUserPersonallySubscribed,
    isUserSubscribedMonthlyStripe,
    isUserSubscribedYearlyStripe,
    isUserTrialing,
} from "../ducks/user";
import {
    camelCase,
    concat,
    filter,
    forEach,
    fromPairs,
    includes,
    indexOf,
    isEmpty,
    isError,
    isNil,
    map,
    omitBy,
    toLower,
    toString,
    uniq,
    upperFirst,
} from "lodash";
import { getBusinessType } from "../ducks/businessTypes";
import { getCategoriesNameByDesignId } from "../ducks/catalogCategories";
import { getEnabledSocialNetworkIds, getFacebookEnabled, getSaveToComputerEnabled, isPostScheduled } from "../ducks/shareModel";
import {
  ACCOUNT_CREATED,
  ACTION_COLLECTION_KEY,
  ACTION_CONTENT_SEARCH_KEY,
  ACTION_OPEN_DESIGN_KEY,
  ALKAI_CROSS_PROMO_DIALOG_ACCEPTED,
  ALKAI_CROSS_PROMO_DIALOG_DECLINED,
  ALKAI_CROSS_PROMO_DIALOG_SHOWN,
  ALKAI_DIALOG_COHORT_KEY,
  appsflyerWrapper,
  apptimizeWrapper,
  BRAND_SETTINGS_APPLY_CHANGES_BUTTON_CLICKED,
  BRAND_SETTINGS_BUTTON_CLICKED,
  BRAND_SETTINGS_COLOR_APPLIED,
  BRAND_SETTINGS_COLOR_SQUARE_CLICKED,
  BRAND_SETTINGS_FONT_APPLIED,
  BUSINESS_NAME_ENTERED,
  BUSINESS_PICKER_DISPLAYED,
  BUSINESS_TYPE_SELECTED,
  BUSINESS_TYPE_SWITCHED,
  BusinessPickerSource,
  BusinessTypeSelectedSource,
  CANCEL_SUBSCRIPTION_CONFIRMED,
  CANCEL_SUBSCRIPTION_SURVEY_DISPLAYED,
  CANCEL_SUBSCRIPTION_SURVEY_RESPONSE,
  CANCEL_SUBSCRIPTION_TAPPED,
  CATALOG_ASPECT_RATIO_SWITCHED,
  CATALOG_CLOSED,
  CATALOG_OPENED,
  CATALOG_SWITCHED,
  CATALOG_TEMPLATE_SELECTED,
  CHANGED_SIZE_FROM_CUSTOMIZE,
  COLLECTION_POST_CLICKED,
  COLLECTION_ROW_POST_CLICKED,
  COLLECTION_ROW_SEE_ALL_TAPPED,
  CONTENT_SEARCH_RESULT_CLICKED,
  CONTENT_SEARCHED,
  CONVERT_VIDEO_FAILED,
  CONVERT_VIDEO_STARTED,
  CREDIT_CARD_DIALOG_DECLINED,
  CURRENCY_CHANGED,
  CURRENCY_INITIALIZED,
  CUSTOMIZE_HIDE_LOGO_BUTTON_CLICKED,
  CUSTOMIZE_LOGO_CLICKED,
  CUSTOMIZE_LOGO_UPLOAD_CLICKED,
  CUSTOMIZE_LOGO_UPLOAD_SUCCEEDED,
  CUSTOMIZE_NEXT_FOR_AD_TAPPED,
  CUSTOMIZE_NEXT_TAPPED,
  CUSTOMIZE_SHOW_LOGO_BUTTON_CLICKED,
  CUSTOMIZED_POST_FAILED,
  CUSTOMIZED_POST_STARTED,
  DESIGN_CONTROL_CHANGED,
  DOWNLOAD_MUSIC_FAILED,
  EASY_CAPTION_BUTTON_CLICKED,
  EDIT_VIDEO_CLOSED,
  EDIT_VIDEO_OPENED,
  ENABLE_ERROR_REPORTING,
  ENABLE_EVENT_CONSOLE_LOGGING,
  ENABLE_USAGE_TRACKING,
  errorReporting,
  EXTRA_CAPTION_ADDED,
  EXTRA_CAPTION_REMOVED,
  EYEBROW_CLICKED,
  EYEDROPPER_CLICKED,
  FACEBOOK_EVENTS,
  FACEBOOK_PIXEL_ID,
  FAILED_TO_CONNECT_SOCIAL_ACCOUNT_EVENT_NAME_PREFIX,
  FAILED_TO_UPLOAD_ERROR_SOURCE_MAP,
  FIRST_PRO_SHARE_POST_SUCCEEDED,
  FIRST_RUN_INSPIRE_DISPLAYED,
  FONT_SELECTED,
  FONT_SELECTED_FROM_CONTROL,
  FONT_SELECTED_FROM_MORE_FONTS,
  GET_MORE_MUSIC_CLICKED,
  GOOGLE_ANALYTICS_IDS,
  GOOGLE_CONVERSION_MONTHLY_SUBSCRIPTION_TAG,
  GOOGLE_CONVERSION_PREMIUM_MONTHLY_SUBSCRIPTION_TAG,
  GOOGLE_CONVERSION_PREMIUM_YEARLY_SUBSCRIPTION_TAG,
  GOOGLE_CONVERSION_YEARLY_SUBSCRIPTION_TAG,
  INSPIRE_POST_SELECTED_EVENT,
  LINKEDIN_CONVERSION_ID,
  LINKEDIN_PARTNER_ID,
  LOGO_TOGGLE_SWITCHED,
  LOGO_UPLOAD_CLICKED,
  LOGO_UPLOAD_SUCCEEDED,
  MAIN_DESIGN_MAX_ERRORS_REACHED_EVENT,
  MANAGE_SUBSCRIPTION_DISPLAYED,
  MANAGE_SUBSCRIPTION_TAPPED,
  MEDIA_UPLOAD_FAILED,
  MEDIA_UPLOAD_SUCCEEDED,
  MORE_FONTS_TAPPED,
  MUSIC_ITEM_SELECTED,
  NEW_POST_FLOW_STARTED,
  NUMBER_OF_EXTRA_CAPTIONS,
  OPEN_DESIGN_FROM_DEEP_LINK_FAILED,
  OPEN_POST_FROM_DEEP_LINK_FAILED,
  OUTPUT_CHOICE_NEXT_TAPPED,
  OUTPUT_CHOICE_SHOWN,
  OUTPUT_TOGGLE_BUTTON_TAPPED,
  PAGINATION_BUTTON_CLICKED,
  PAYMENT_FORM_STRIPE_TOKEN_FAILED,
  PAYMENT_METHOD_UPDATED,
  PICK_MUSIC,
  PINTEREST_TAG_ID,
  pinterestTag,
  PLAY_BUTTON_CLICKED,
  POST_COMPLETED,
  POST_FLOW_BACK_SELECTED,
  POST_FLOW_CANCELLED,
  POST_INTENT_SELECTION_SCREEN_DISPLAYED,
  POST_INTENT_TAPPED,
  POST_INTENT_TEMPLATE_SCREEN_DISPLAYED,
  PROFILE_FACEBOOK_CONNECT_TAPPED_EVENT,
  PROFILE_FACEBOOK_GROUPS_CONNECT_TAPPED_EVENT,
  PROFILE_FACEBOOK_INSTAGRAM_CONNECT_TAPPED_EVENT,
  PROFILE_FACEBOOK_PAGES_CONNECT_TAPPED_EVENT,
  PROFILE_INSTAGRAM_PERSONAL_CONNECT_TAPPED_EVENT,
  PROFILE_LINKEDIN_CONNECT_TAPPED_EVENT,
  PROFILE_TWITTER_CONNECT_TAPPED_EVENT,
  PROFILE_YOUTUBE_CONNECT_TAPPED_EVENT,
  PURCHASE_WARM_UP_ACCEPTED,
  PURCHASE_WARM_UP_DECLINED,
  PURCHASE_WARM_UP_DISPLAYED,
  RECOMMENDED_POST_SELECTED,
  RECORDING_COMPLETED_EVENT,
  REFER_A_FRIEND_DISPLAYED,
  RIPL_USER_SURVEY_2023_SHOWN,
  SAVE_TO_DRAFT_STARTED,
  SCHEDULE_SEND_CALENDAR_DISPLAYED,
  SERVER_SIDE_RECORDING_FAILED,
  SERVER_SIDE_RECORDING_STARTED,
  SHARE_BUTTON_TAPPED,
  SHARE_POST_FAILED,
  SHARE_POST_SUCCEEDED,
  SHARE_VIEW_OPENED,
  SHOW_YOU_AROUND_DISMISSED,
  SHOW_YOU_AROUND_DONE_TAPPED,
  ShowYouAroundDismissalSource,
  ShowYouAroundDisplayedEventName,
  SignOutSource,
  SOCIAL_ACCOUNT_CONNECTED_EVENT_NAME_SUFFIX,
  SocialAccountConnectedSource,
  STOCK_MEDIA_CLICKED,
  STOCK_MEDIA_SEARCHED,
  stringUtils,
  STYLIZE_TEXT_CHANGED_EVENT,
  STYLIZE_TEXT_PANEL_OPENED_EVENT,
  STYLIZED_TEXT_DEFAULT_STYLE_ID,
  SUBSCRIPTION_FLOW_STEP_COMPLETED,
  SUBSCRIPTION_FLOW_STEP_TRACKING_NAME_PRO_WARMUP,
  SUBSCRIPTION_FLOW_STEP_TRACKING_NAME_TRY_PRO,
  SUBSCRIPTION_ON_HOLD_LOCKOUT_MANAGE_SUBSCRIPTION_BUTTON_TAPPED,
  SUBSCRIPTION_ON_HOLD_LOCKOUT_MESSAGE_SHOWN,
  SUBSCRIPTION_ON_HOLD_LOCKOUT_REFRESH_BUTTON_TAPPED,
  SUBSCRIPTION_SUCCEEDED,
  SUBSCRIPTION_TIER_SELECTED,
  SUBSCRIPTION_UP_TIER_BLOCKED,
  SubscriptionEventName,
  SURVEY_CODE_KEY,
  TEAM_INVITE_ACCEPTED,
  TEAM_INVITE_SENT,
  TEMPLATE_PREVIEW_APPLY_BRAND_STYLES_SWITCH_TOGGLED,
  TEMPLATE_PREVIEW_CUSTOMIZE_TAPPED,
  TEMPLATE_PREVIEW_SHOWN,
  TEMPLATE_SELECTED,
  TemplatePreviewSources,
  TEST_DRIVE_ACCEPTED,
  TEXT_PROPERTIES_CHANGED_EVENT,
  TEXT_PROPERTIES_OPENED_EVENT,
  TEXT_PROPERTIES_TAB_OPENED_EVENT,
  TRIM_VIDEO_ENDED,
  TRIM_VIDEO_FAILED,
  URL_OPENED_EVENT,
  USER_CLICKED_SIGN_OUT,
  VIDEO_FILE_SIZE_EXCEED_LIMIT,
  WEB_NAV_ACCOUNT_DISPLAYED,
  WEB_NAV_BRAND_DISPLAYED,
  WEB_NAV_CALENDAR_DISPLAYED,
  WEB_NAV_DRAFT_OPENED,
  WEB_NAV_DRAFTS_DISPLAYED,
  WEB_NAV_INSPIRE_DISPLAYED,
  WEB_NAV_LEARN_DISPLAYED,
  WEB_NAV_POST_DETAILS_COPIED,
  WEB_NAV_POST_DETAILS_DISPLAYED,
  WEB_NAV_PROFILE_DISPLAYED,
  WEB_NAV_PROFILE_POST_DISPLAYED,
  WIN_BACK_OFFER_ACCEPT_BUTTON_TAPPED,
  WIN_BACK_OFFER_APPLIED,
  WIN_BACK_OFFER_DECLINED,
  WIN_BACK_OFFER_DISPLAYED,
} from "./";
import { getMusicTitle } from "../ducks/musicCatalog";
import { getDesignNameByDesignId, getDesignSlugByDesignId } from "../ducks/designs";
import {
    getApptimizeParticipatingExperimentAndVariantNamesCache,
    getEpidemicSoundSource,
    getSessionIdForPostCustomization,
    getSubscriptionFlowSource,
} from "../ducks/tracking";
import {
    getDesignAspectRatio,
    getMusicId,
    getMusicType,
    getPostId,
    getPrimaryText,
    getPropertiesForEventTracking,
    getSecondaryText,
    getSelectedDesignId,
    getStartingDesignId,
    getVideoTrimmedDurationInMS,
    getVideoTrimmerVideoDuration,
    isFacebookAd,
} from "../ducks/mixModel";
import * as Raven from "raven-js";
import { getFreeDesignIds, getFreemiumDesignIds } from "../ducks/catalog";
import { getSelectedPaymentAmount, getSelectedPaymentCurrency, getSelectedPaymentSku, getSelectedPlan } from "../ducks/pricing";
import {
    AspectRatio,
    BusinessType,
    CatalogCategory,
    Design,
    DesignOutputMode,
    ExtraCaptionStoreData,
    FontData,
    MediaSourceType,
    OauthAccountType,
    OutputType,
    Post,
    SocialNetworkAccount,
    SocialNetworkAccountType,
    StoreState,
} from "../_types";
import {
    DesignElementPropertyCategoryId,
    DesignElementPropertyType,
    FACEBOOK_ACCOUNT_TYPE,
    FACEBOOK_GROUP_ACCOUNT_TYPE,
    FACEBOOK_INSTAGRAM_ACCOUNT_TYPE,
    FACEBOOK_INSTAGRAM_FEED_SHARE_METHOD_NAME,
    FACEBOOK_PAGE_ACCOUNT_TYPE,
    INSTAGRAM_ACCOUNT_TYPE,
    LINKEDIN_ACCOUNT_TYPE,
    SAVE_TO_COMPUTER_ACCOUNT_TYPE,
    STRIPE_PLAN_TYPE_ANNUAL,
    SupportedNoticeActionName,
    TWITTER_ACCOUNT_TYPE,
    YOUTUBE_ACCOUNT_TYPE,
} from "../_types/api";
import { trackingActions } from "../actions";
import LinkedInTag from "react-linkedin-insight";
import { getDesignCurrentSlideIndex } from "../ducks/ui";
import { EventTrackerSurveyReportingData } from "../components/settingsPages/CancelSurvey";
import { airshipServices } from "../services";
import { getEpidemicSoundTrackName } from "../ducks/epidemicSoundCatalog";

const AMPLITUDE_USER_PROPERTY_NULL = "-";

export const eventTracker = {
    AMPLITUDE_USER_PROPERTY_NULL,
    reportLinkedInPurchaseConversion,
    initializeFacebookTracking,
    initializePinterestTracking,
    initializeGoogleAnalyticsTracking,
    initializeApptimizeParticipatedEvents,
    clearApptimizeVariableCache,
    initializeAppVersion,
    setUserIdForCurrentUser,
    clearUserId,
    setUserPropertiesForCurrentUser,
    clearUserProperties,
    clearUserProperty,
    getPropertiesForUser,
    logEvent,
    logEyedropperClicked,
    logUserClickedSignOutEvent,
    logBusinessTypeSwitchedEvent,
    logBusinessPickerDisplayed,
    logBusinessTypeSelected,
    logBusinessNameEntered,
    logNewPostFlowStartedEvent,
    logInspirePostSelectedEvent,
    logCustomizedPostStartedEvent,
    logCustomizedPostFailedEvent,
    logOutputChoiceShownEvent,
    logOutputChoiceNextTappedEvent,
    logOutputToggleButtonTapped,
    logDesignControlChanged,
    logCustomizeNextTappedEvent,
    logCustomizeNextForAdTappedEvent,
    logManageSubscriptionTapped,
    logManageSubscriptionDisplayed,
    logCancelSubscriptionTapped,
    logCancelSubscriptionSurveyDisplayed,
    logCancelSubscriptionSurveyMultipleResponses,
    logCancelSubscriptionConfirmed,
    logCurrencyInitialized,
    logCurrencyChanged,
    logPostFlowBackSelectedEvent,
    logPostFlowCancelledByBrowserUnload,
    logPostFlowCancelledByUser,
    logShareViewOpened,
    logEasyCaptionButtonClicked,
    logShareButtonTapped,
    logSaveToDraftStarted,
    logScheduleSendCalendarDisplayed,
    logFirstProSharePostSucceeded,
    logPostCompleted,
    logSharePostSucceeded,
    logSharePostFailed,
    logOpenUrlForCollection,
    logOpenUrlForContentSearch,
    logOpenUrlForDesign,
    logOpenPostFromDeeplinkFailed,
    logOpenDesignFromDeeplinkFailed,
    logCatalogOpened,
    logCatalogClosed,
    logMainDesignMaxErrorsReached,
    logConvertVideoStarted,
    logEditVideoOpened,
    logEditVideoClosed,
    logTrimVideoEnded,
    logConvertVideoFailed,
    logTrimVideoFailed,
    logCatalogSwitched,
    logCatalogAspectRatioSwitched,
    logTemplateSelected,
    logCatalogTemplateSelected,
    logSocialAccountConnected,
    logSocialAccountFailedToConnect,
    logSubscriptionEvent,
    logPurchaseWarmupDisplayed,
    logPurchaseWarmupAccepted,
    logPurchaseWarmupDeclined,
    logSubscriptionTierSelected,
    logSubscriptionUpTierBlocked,
    logCreditCardDialogDeclined,
    logPaymentMethodUpdated,
    reportGooglePurchaseConversion,
    logToApptimize,
    logTeamInviteAccepted,
    logTeamInviteSent,
    logRecordingStarted,
    logRecordingFailed,
    logRecordingCompleted,
    logRecommendedPostSelected,
    logVideoFileSizeExceedLimit,
    logMediaUploadSucceededEvent,
    getUploadSourceForMediaType,
    logMediaUploadFailedEvent,
    logFontSelected,
    logMoreFontsTapped,
    logFontSelectedFromMoreFonts,
    logFontSelectedFromControl,
    trackAccountCreated,
    logCustomizeLogoClicked,
    logCustomizeShowLogoButtonClicked,
    logCustomizeHideLogoButtonClicked,
    logCustomizeLogoUploadClicked,
    logCustomizeLogoUploadSucceeded,
    logPlayButtonClicked,
    logPaginationButtonClicked,
    logBrandSettingsButtonClicked,
    logBrandSettingsApplyChangesButtonClicked,
    logLogoToggleSwitched,
    logLogoUploadClicked,
    logBrandSettingsColorSquareClicked,
    logLogoUploadSucceeded,
    logBrandSettingsColorApplied,
    logBrandSettingsFontApplied,
    logCollectionPostClicked,
    logContentSearched,
    logContentSearchClicked,
    logStockMediaSearched,
    logStockMediaClicked,
    logPostDetailsDisplayed,
    logPostDetailsCopied,
    logDraftOpened,
    logFirstRunInspireDisplayed,
    logInspireDisplayed,
    logProfileDisplayed,
    logMyBrandDisplayed,
    logMyAccountDisplayed,
    logProfilePostDisplayed,
    logDraftsDisplayed,
    logCalendarDisplayed,
    logLearnDisplayed,
    logEyebrowClicked,
    logSubscriptionOnHoldLockoutMessageShown,
    logSubscriptionOnHoldLockoutManageSubscriptionButtonTapped,
    logSubscriptionOnHoldLockoutRefreshButtonTapped,
    logWinBackOfferDisplayed,
    logWinBackOfferAcceptButtonTapped,
    logWinBackOfferApplied,
    logWinBackOfferDeclined,
    logTestDriveAccepted,
    logCollectionRowPostClicked,
    logCollectionRowSeeAllTapped,
    logExtraCaptionAddedEvent,
    logExtraCaptionRemovedEvent,
    logShowYouAroundDisplayedStepEvent,
    logShowYouAroundDismissedEvent,
    logShowYouAroundDoneTappedEvent,
    logReferAFriendDisplayedEvent,
    logSurveyShown,
    logAlkaiCrossPromoDialogShown,
    logAlkaiCrossPromoDialogAccepted,
    logAlkaiCrossPromoDialogDeclined,
    logPaymentFormStripeTokenFailed,
    logStylizeTextChangedEvent,
    logStylizeTextPanelOpenedEvent,
    logTextPropertiesChanged,
    logTextPropertiesOpened,
    logTextPropertiesTabOpened,
    sendAirshipCustomEvent,
    getShareMethods,
    logGetMoreMusicClicked,
    logDownloadMusicFailed,
    logPostIntentSelectionScreenDisplayed,
    logPostIntentTapped,
    logPostIntentTemplateScreenDisplayed,
    logTemplatePreviewShown,
    logTemplatePreviewCustomizeTapped,
    logTemplatePreviewApplyBrandStylesSwitchToggled,
    logMusicItemSelected,
    logPickMusic,
    logChangedSizeFromCustomize,
    logSocialNetworkConnectClicked,
};

interface BusinessTypeSelectedEventProperties
{
    businessTypeId: number;
    businessTypeName: string;
    source: BusinessTypeSelectedSource;
}

interface ExperimentInfo
{
    experimentNamesUserEnrolled?: string[];
    experimentAndVariantNamesEnrolled?: string[];
    experimentNamesUserParticipated?: string[];
    experimentAndVariantNamesParticipated?: string[];
}

interface FacebookEventAttributionProperty
{
    external_id: number;
}

interface UserProperties extends ExperimentInfo
{
    riplUserId: number;
    postSessionId?: string;
    productSku?: string;
    isRiplProUser: boolean;
    isPro: boolean;
    subscriptionStatus?: SubscriptionStatus;
    promotions: string[];
    isDesktop: boolean;
    businessId?: number;
    businessName?: string;
    businessTypeName?: string;
    allBusinessNames: string[];
    connectedSocialAccountTypes: SocialNetworkAccountType[];
    partner?: string;
}

interface ShowYouAroundDismissedEventProperties
{
    source: ShowYouAroundDismissalSource;
}

function reportLinkedInPurchaseConversion()
{
    LinkedInTag.init( LINKEDIN_PARTNER_ID );
    LinkedInTag.track( LINKEDIN_CONVERSION_ID );
}
function initializeFacebookTracking()
{
    if ( ENABLE_USAGE_TRACKING )
    {
        const email = getUserEmail( store.getState() );
        const userId = getCurrentUserId( store.getState() );
        const advancedMatching = { em: email, external_id: userId };
        ReactPixel.init( FACEBOOK_PIXEL_ID, advancedMatching );
        ReactPixel.pageView();
    }
}

function initializePinterestTracking()
{
    if ( ENABLE_USAGE_TRACKING )
    {
        pinterestTag.init( PINTEREST_TAG_ID );
        pinterestTag.pageView();
    }
}

function initializeGoogleAnalyticsTracking()
{
    const theTrackers: ReactGA.Tracker[] = GOOGLE_ANALYTICS_IDS;
    ReactGA.initialize( theTrackers );
    ReactGA.pageview( window.location.pathname + window.location.search );
}

function initializeApptimizeParticipatedEvents()
{
    clearApptimizeCustomAttributes();

    apptimizeWrapper.setOnParticipatedInExperimentCallback( ( variantInfo: ApptimizeVariantInfo ) =>
    {
        const experimentAndVariantName = `${variantInfo.getExperimentName()}-${variantInfo.getVariantName()}`;
        const exportExperimentInfo = {
            experimentName: variantInfo.getExperimentName(),
            variantName: variantInfo.getVariantName(),
            nameAndVariation: experimentAndVariantName,
            experimentId: variantInfo.getExperimentId(),
            variantId: variantInfo.getVariantId(),
        };

        if ( !includes( getApptimizeParticipatingExperimentAndVariantNamesCache( store.getState() ), experimentAndVariantName ) )
        {
            const userProperties = apptimizeWrapper.getCustomAttributes() as UserProperties;
            if ( !includes( userProperties.experimentAndVariantNamesParticipated, experimentAndVariantName ) )
            {
                store.dispatch( trackingActions.cacheApptimizeParticipatingExperimentAndVariantName( experimentAndVariantName ) );
                logEvent( "participated", exportExperimentInfo );
                setUserPropertiesForCurrentUser();
            }
        }
    } );

    apptimizeWrapper.setOnMetadataUpdatedCallback( () =>
    {
        clearApptimizeVariableCache();
    } );

    apptimizeWrapper.setOnEnrolledInExperimentCallback( () =>
    {
        clearApptimizeVariableCache();
    } );

    apptimizeWrapper.setOnUnenrolledInExperimentCallback( () =>
    {
        clearApptimizeVariableCache();
    } );
}

function clearApptimizeVariableCache()
{
    store.dispatch( trackingActions.clearFeatureFlagAndDynamicVariableCache() );
}

function setUserIdForCurrentUser()
{
    const userId = getCurrentUserId( store.getState() );
    if ( userId )
    {
        setUserId( "" + userId );
    }
}

function setUserPropertiesForCurrentUser()
{
    const state = store.getState();
    const userProperties = getPropertiesForUser( state );
    updateCacheOfApptimizeParticipatingExperimentAndVariantNames( userProperties.experimentAndVariantNamesParticipated );
    setUserProperties( userProperties );
    setUserPropertiesForSentry( userProperties );
}

function setUserPropertiesForSentry( userProperties: UserProperties )
{
    if ( ENABLE_ERROR_REPORTING )
    {
        const {
            riplUserId,
            postSessionId,
            productSku,
            isRiplProUser,
            businessId,
            businessName,
            businessTypeName,
        } = userProperties;

        Raven.setUserContext( {
            id: riplUserId,
            postSessionId,
            productSku,
            isRiplProUser,
            businessId,
            businessName,
            businessTypeName,
        } );

        Raven.setTagsContext( {
            userId: riplUserId,
            productSku,
            isRiplProUser,
            businessId,
        } );
    }
}

function initializeAppVersion()
{
    setAmplitudeVersionName( APP_VERSION );
    setApptimizeAppVersion( APP_VERSION );
}

function setUserId( userId: string )
{
    setAmplitudeUserId( userId );
    setApptimizeCustomerUserId( userId );
    setPilotTargetingId( userId );
    clearApptimizeVariableCache();
    initializeFacebookTracking();
}

function clearUserId()
{
    setAmplitudeUserId( null );
    setApptimizeCustomerUserId( null );
    setPilotTargetingId( null );
    clearApptimizeVariableCache();
}

function setUserProperties( userProperties: UserProperties )
{
    setAmplitudeUserProperties( userProperties );
    setApptimizeCustomAttributes( userProperties );
    setAppsflyerUserProperties( userProperties );
}

function clearUserProperties()
{
    clearAmplitudeUserProperties();
    clearApptimizeCustomAttributes();
}

function clearUserProperty( name: string )
{
    clearAmplitudeUserProperty( name );
}

function sendAmplitudeTracking( eventName: string, eventProperties: object )
{
    if ( amplitude.getInstance() )
    {
        amplitude.getInstance().logEvent( eventName, eventProperties );
    }
}

function sendReactPixelTracking( eventName: string, eventProperties?: object )
{
    const lifecycleEvent = getFacebookLifecycleEvent( eventName, eventProperties );
    const state = store.getState();
    const userProperties = getPropertiesForUser( state );
    const facebookEventAttributionProperty = getFacebookEventAttributionProperty();
    const combinedProperties = { ...eventProperties, ...userProperties, ...facebookEventAttributionProperty };

    if ( lifecycleEvent )
    {
        ReactPixel.track( lifecycleEvent, combinedProperties );
    }
    ReactPixel.trackCustom( eventName, combinedProperties );
}

function sendPinterestTagTracking( eventName: string )
{
    if ( eventName === SUBSCRIPTION_SUCCEEDED )
    {
        pinterestTag.trackAddToCart( 75, 1, "USD" );
    }
    if ( eventName === ACCOUNT_CREATED )
    {
        pinterestTag.trackSignUp();
    }
}

function sendGoogleAnalyticsTracking( eventName: string )
{
    ReactGA.ga( "send", "event", eventName );
}

function getFacebookLifecycleEvent( eventName: string, eventProperties?: any ): string
{
    const facebookEventFromAmplitudeMap = {
        [ACCOUNT_CREATED]: FACEBOOK_EVENTS.COMPLETE_REGISTRATION,
        [SUBSCRIPTION_SUCCEEDED]: FACEBOOK_EVENTS.PURCHASE,
    };

    const subscriptionStepMap = {
        [SUBSCRIPTION_FLOW_STEP_TRACKING_NAME_TRY_PRO]: FACEBOOK_EVENTS.ADD_TO_CART,
        [SUBSCRIPTION_FLOW_STEP_TRACKING_NAME_PRO_WARMUP]: FACEBOOK_EVENTS.LEAD,
    };

    if ( eventName === SUBSCRIPTION_FLOW_STEP_COMPLETED )
    {
        return subscriptionStepMap[eventProperties.stepName];
    }
    else
    {
        return facebookEventFromAmplitudeMap[eventName];
    }
}

function mergeAllEventProperties( eventProperties?: object )
{
    eventProperties = eventProperties || {};
    return {
        ...eventProperties,
        browserSize: getBrowserSize(),
        clientId: getUserClientId( store.getState() ),
    };
}

function logEvent( eventName: string, eventProperties?: object )
{
    const allEventProperties = mergeAllEventProperties( eventProperties );
    const filteredEventProperties = omitBy( allEventProperties, isNil );

    try
    {
        if ( ENABLE_USAGE_TRACKING )
        {
            sendReactPixelTracking( eventName, filteredEventProperties );
            sendGoogleAnalyticsTracking( eventName );
            sendPinterestTagTracking( eventName );
            sendAmplitudeTracking( eventName, allEventProperties );
        }
        else if ( ENABLE_EVENT_CONSOLE_LOGGING )
        {
            // tslint:disable-next-line:no-console
            console.log( `trackingEvent: ${eventName} withProperties`, allEventProperties );
        }
    }
    catch (e)
    {
        errorReporting.reportErrorToSentry( e );
    }
}

function logEventWithError( eventName: string, error: any, eventProperties?: object )
{
    const errorInfo = getErrorInfo( error );
    const mergedEventProperties: object = { ...eventProperties, ...errorInfo };
    logEvent( eventName, mergedEventProperties );
}

function logUserClickedSignOutEvent( source: SignOutSource )
{
    logEvent( USER_CLICKED_SIGN_OUT, { source } );
}

function logBusinessTypeSwitchedEvent( state: StoreState,
                                       businessTypeId: number,
                                       source: BusinessTypeSelectedSource )
{
    const businessType = getBusinessType( state, businessTypeId );
    const businessTypeName = businessType.name;
    const eventProperties: BusinessTypeSelectedEventProperties = {
        businessTypeId,
        businessTypeName,
        source,
    };

    logEvent( BUSINESS_TYPE_SWITCHED, eventProperties );
}

function logBusinessPickerDisplayed( source: BusinessPickerSource )
{
    logEvent( BUSINESS_PICKER_DISPLAYED, { source } );
}

function logBusinessTypeSelected( source: BusinessPickerSource, businessType: BusinessType )
{
    logEvent( BUSINESS_TYPE_SELECTED, { source, businessTypeId: businessType.id, businessTypeName: businessType.name } );
}

function logBusinessNameEntered( source: BusinessPickerSource )
{
    logEvent( BUSINESS_NAME_ENTERED, { source } );
}

function logSocialAccountConnected( accountType: OauthAccountType,
                                    source: SocialAccountConnectedSource )
{
    const eventName = generateConnectedEventNameForSocialAccount( accountType );
    logEvent( eventName, { source } );
}

function logSocialAccountFailedToConnect( accountType: OauthAccountType, source: SocialAccountConnectedSource )
{
    const eventName = generateFailedToConnectEventNameForSocialAccount( accountType );
    logEvent( eventName, { source } );
}

function generateConnectedEventNameForSocialAccount( accountType: OauthAccountType )
{
    return convertAccountTypeToEventNamePart( accountType ) + SOCIAL_ACCOUNT_CONNECTED_EVENT_NAME_SUFFIX;
}

function generateFailedToConnectEventNameForSocialAccount( accountType: OauthAccountType )
{
    return FAILED_TO_CONNECT_SOCIAL_ACCOUNT_EVENT_NAME_PREFIX + upperFirst( camelCase( accountType ) );
}

function convertAccountTypeToEventNamePart( accountType: OauthAccountType )
{
    return camelCase( accountType );
}

function logNewPostFlowStartedEvent( source: string )
{
    logEvent( NEW_POST_FLOW_STARTED, { source } );
}

function logInspirePostSelectedEvent( source: string, sourceInspirationPostId: number )
{
    const state = store.getState();
    const allProperties = {
        examplePostInspirationSource: source,
        examplePostDesignName: getDesignNameByDesignId( state, getSelectedDesignId( state ) ),
        examplePostAspectRatio: getDesignAspectRatio( state ),
        examplePostPostId: sourceInspirationPostId,
    };
    logEvent( INSPIRE_POST_SELECTED_EVENT, allProperties );
}

function logRecommendedPostSelected( recommendedPostDate: string, actionName: string )
{
    const state = store.getState();
    const allProperties = {
        examplePostDesignName: getDesignNameByDesignId( state, getSelectedDesignId( state ) ),
        examplePostAspectRatio: getDesignAspectRatio( state ),
        examplePostPostId: getPostId( state ),
        selectedActionName: actionName,
        recommendedPostDate,
    };
    logEvent( RECOMMENDED_POST_SELECTED, allProperties );
}

function logCustomizedPostStartedEvent()
{
    logEvent( CUSTOMIZED_POST_STARTED, getCustomizationAndSharingEventProperties() );
}

function logOutputChoiceShownEvent()
{
    logEvent( OUTPUT_CHOICE_SHOWN );
}

function logOutputChoiceNextTappedEvent( type: OutputType )
{
    logEvent( OUTPUT_CHOICE_NEXT_TAPPED, { type } );
}

function logOutputToggleButtonTapped( outputMode: DesignOutputMode )
{
    const mixedModelProperties = getCustomizationAndSharingEventProperties();

    const allProperties = {
        ...mixedModelProperties,
        outputMode,
    };

    logEvent( OUTPUT_TOGGLE_BUTTON_TAPPED, allProperties );
}

function logDesignControlChanged( editorType )
{
    const allProperties = {
        type: editorType,
        ...getCustomizationAndSharingEventProperties(),
    };
    logEvent( DESIGN_CONTROL_CHANGED, allProperties );
}

function logMediaUploadSucceededEvent( state, mediaData, fileSize )
{
    const eventProperties = {
        mediaType: mediaData && mediaData.media_type,
        mediaFileName: mediaData && mediaData.original_file_name,
        mediaUrl: mediaData && mediaData.s3_direct_url,
        fileSize,
        postId: getPostId( state ),
    };
    logEvent( MEDIA_UPLOAD_SUCCEEDED, eventProperties );
}

function logCustomizeNextTappedEvent()
{
    const eventProperties = {
        currentSlideIndex: getDesignCurrentSlideIndex( store.getState() ),
        ...getCustomizationAndSharingEventProperties(),
    };
    logEvent( CUSTOMIZE_NEXT_TAPPED, eventProperties );
}

function logCustomizeNextForAdTappedEvent()
{
    const eventProperties = {
        currentSlideIndex: getDesignCurrentSlideIndex( store.getState() ),
        ...getCustomizationAndSharingEventProperties(),
    };
    logEvent( CUSTOMIZE_NEXT_FOR_AD_TAPPED, eventProperties );
}

function getUploadSourceForMediaType( mediaDataType )
{
    return mediaDataType && FAILED_TO_UPLOAD_ERROR_SOURCE_MAP[mediaDataType];
}

function logMediaUploadFailedEvent( state, mediaData, fileSize, errorReason, errorSource )
{
    const eventProperties = {
        postId: getPostId( state ),
        mediaType: mediaData && mediaData.media_type,
        mediaFileName: mediaData && mediaData.original_file_name,
        mediaUrl: mediaData && mediaData.s3_direct_url,
        fileSize,
        errorReason,
        errorSource,
    };
    logEvent( MEDIA_UPLOAD_FAILED, eventProperties );
}

function logCustomizedPostFailedEvent( error: any )
{
    logEvent( CUSTOMIZED_POST_FAILED, { error } );
}

function logFontSelected( font: FontData )
{
    logEvent( FONT_SELECTED, font );
}

function logMoreFontsTapped()
{
    logEvent( MORE_FONTS_TAPPED );
}

function logFontSelectedFromMoreFonts( font: FontData )
{
    logEvent( FONT_SELECTED_FROM_MORE_FONTS, font );
}

function logFontSelectedFromControl( font: FontData )
{
    logEvent( FONT_SELECTED_FROM_CONTROL, font );
}

function logPostFlowBackSelectedEvent()
{
    logEvent( POST_FLOW_BACK_SELECTED, getCustomizationAndSharingEventProperties() );
}

function logPostFlowCancelledByBrowserUnload()
{
    logPostFlowCancelled( "BROWSER_UNLOAD" );
}

function logPostFlowCancelledByUser()
{
    logPostFlowCancelled( "USER" );
}

function logPostFlowCancelled( cause )
{
    const eventProperties =
        {
            ...getCustomizationAndSharingEventProperties(),
            cause,
        };
    logEvent( POST_FLOW_CANCELLED, eventProperties );
}

function logCustomizeLogoClicked()
{
    logEvent( CUSTOMIZE_LOGO_CLICKED );
}

function logCustomizeShowLogoButtonClicked()
{
    logEvent( CUSTOMIZE_SHOW_LOGO_BUTTON_CLICKED );
}

function logCustomizeHideLogoButtonClicked()
{
    logEvent( CUSTOMIZE_HIDE_LOGO_BUTTON_CLICKED );
}

function logCustomizeLogoUploadClicked()
{
    logEvent( CUSTOMIZE_LOGO_UPLOAD_CLICKED );
}

function logLogoUploadClicked( source: string )
{
    logEvent( LOGO_UPLOAD_CLICKED, { source } );
}

function logEyedropperClicked()
{
    logEvent( EYEDROPPER_CLICKED );
}

function logCustomizeLogoUploadSucceeded( source )
{
    logEvent( CUSTOMIZE_LOGO_UPLOAD_SUCCEEDED, { source } );
}

function logPlayButtonClicked()
{
    logEvent( PLAY_BUTTON_CLICKED );
}

function logPaginationButtonClicked()
{
    logEvent( PAGINATION_BUTTON_CLICKED );
}

function logShareViewOpened()
{
    logEvent( SHARE_VIEW_OPENED, getCustomizationAndSharingEventProperties() );
}

function logEasyCaptionButtonClicked(timesClicked: number)
{
    logEvent( EASY_CAPTION_BUTTON_CLICKED, { timesClicked } );
}

function logShareButtonTapped( easyCaptionUsed: boolean = null, easyCaptionNotChanged: boolean = null, easyCaptionUsageCount: number = null)
{
    const mixModelProperties = getCustomizationAndSharingEventProperties();
    const allProperties = {
        magicCaptionUsed: easyCaptionUsed,
        magicCaptionNotChanged: easyCaptionNotChanged,
        magicCaptionUsageCount: easyCaptionUsageCount,
        ...mixModelProperties,
    };
    logEvent( SHARE_BUTTON_TAPPED, allProperties );
}

function logTeamInviteAccepted( e?: string )
{
    logEvent( TEAM_INVITE_ACCEPTED, { success: !e, error: e } );
}

function logTeamInviteSent( e?: string )
{
    logEvent( TEAM_INVITE_SENT, { success: !e, error: e } );
}

function logStylizeTextPanelOpenedEvent()
{
    const mixModelProperties = getCustomizationAndSharingEventProperties();
    logEvent( STYLIZE_TEXT_PANEL_OPENED_EVENT, mixModelProperties );
}

function logStylizeTextChangedEvent( styleId: string )
{
    const mixModelProperties = getCustomizationAndSharingEventProperties();
    const allProperties = {
        styleId,
        ...mixModelProperties,
    };
    logEvent( STYLIZE_TEXT_CHANGED_EVENT, allProperties );
}

function logTextPropertiesOpened()
{
    const mixModelProperties = getCustomizationAndSharingEventProperties();
    logEvent( TEXT_PROPERTIES_OPENED_EVENT, mixModelProperties );
}

function logTextPropertiesTabOpened( textPropertyCategoryId: DesignElementPropertyCategoryId )
{
    const mixModelProperties = getCustomizationAndSharingEventProperties();
    const allProperties = {
        ...mixModelProperties,
        textPropertyCategoryId,
    };
    logEvent( TEXT_PROPERTIES_TAB_OPENED_EVENT, allProperties );
}

function logTextPropertiesChanged( textPropertyType: DesignElementPropertyType )
{
    const mixModelProperties = getCustomizationAndSharingEventProperties();
    const allProperties = {
        ...mixModelProperties,
        textPropertyType,
    };
    logEvent( TEXT_PROPERTIES_CHANGED_EVENT, allProperties );
}

function logRecordingStarted()
{
    logEvent( SERVER_SIDE_RECORDING_STARTED, getCustomizationAndSharingEventProperties() );
}

function logRecordingCompleted( recordingTime: number )
{
    const mixModelProperties = getCustomizationAndSharingEventProperties();
    const allProperties = {
        success: true,
        recordingTimeInSeconds: recordingTime,
        ...mixModelProperties,
    };

    logEvent( RECORDING_COMPLETED_EVENT, allProperties );
}

function logRecordingFailed( error, serverError: boolean )
{
    const mixModelProperties = getCustomizationAndSharingEventProperties();
    const allProperties = {
        serverError,
        ...mixModelProperties,
    };

    logEventWithError( SERVER_SIDE_RECORDING_FAILED, error, allProperties );
}

function logSaveToDraftStarted()
{
    logEvent( SAVE_TO_DRAFT_STARTED, getCustomizationAndSharingEventProperties() );
}

function logScheduleSendCalendarDisplayed()
{
    logEvent( SCHEDULE_SEND_CALENDAR_DISPLAYED, getCustomizationAndSharingEventProperties() );
}

function logPostCompleted( mediaSourceList: MediaSourceType[] )
{
    const allProperties = {
        ...getCustomizationAndSharingEventProperties( true ),
        photosSourceList: mediaSourceList,
    };
    logEvent( POST_COMPLETED, allProperties );
}

function logSharePostSucceeded( mediaSourceList: MediaSourceType[] )
{
    const allProperties = {
        ...getCustomizationAndSharingEventProperties( true ),
        photosSourceList: mediaSourceList,
    };
    logEvent( SHARE_POST_SUCCEEDED, allProperties );
    logToApptimize( SHARE_POST_SUCCEEDED );
}

function logFirstProSharePostSucceeded()
{
    logEvent( FIRST_PRO_SHARE_POST_SUCCEEDED, getCustomizationAndSharingEventProperties( true ) );
}

function logSharePostFailed( error: any )
{
    logEventWithError( SHARE_POST_FAILED, error, getCustomizationAndSharingEventProperties( true ) );
}

function logOpenUrlForCollection( collectionSlug: string )
{
    const eventProperties = {
        collectionSlug,
        type: ACTION_COLLECTION_KEY,
    };
    logUrlOpened( eventProperties );
}

function logOpenUrlForContentSearch( searchTerm: string )
{
    const eventProperties = {
        searchTerm,
        type: ACTION_CONTENT_SEARCH_KEY,
    };
    logUrlOpened( eventProperties );
}

function logOpenUrlForDesign( incomingPostId?: string, designSlugFromUrl?: string )
{
    let eventProperties: object = {
        type: ACTION_OPEN_DESIGN_KEY,
    };

    if ( incomingPostId )
    {
        eventProperties = {
            ...eventProperties,
            postId: incomingPostId,
        };
    }

    if ( designSlugFromUrl )
    {
        eventProperties = {
            ...eventProperties,
            template: designSlugFromUrl,
        };
    }

    logUrlOpened( eventProperties );
}

function logUrlOpened( eventProperties )
{
    logEvent( URL_OPENED_EVENT, eventProperties );
}

function logOpenPostFromDeeplinkFailed( error: any )
{
    logEventWithError( OPEN_POST_FROM_DEEP_LINK_FAILED, error );
}

function logOpenDesignFromDeeplinkFailed( error: any )
{
    logEventWithError( OPEN_DESIGN_FROM_DEEP_LINK_FAILED, error );
}

function logCatalogOpened()
{
    logEvent( CATALOG_OPENED, getCustomizationAndSharingEventProperties() );
}

function logCatalogClosed()
{
    logEvent( CATALOG_CLOSED, getCustomizationAndSharingEventProperties() );
}

function logSubscriptionEvent( subscriptionEventName: SubscriptionEventName, eventProperties: object = {}, source?: string )
{
    const state = store.getState();
    const sku = getSelectedPaymentSku( state );
    const amount = getSelectedPaymentAmount( state );
    const allProperties = {
        productSku: sku,
        source: source || getSubscriptionFlowSource( state ),
        value: amount && amount.toString(),
        currency: getSelectedPaymentCurrency( state ),
        ...eventProperties,
    };
    logEvent( subscriptionEventName, allProperties );

    if ( subscriptionEventName === SUBSCRIPTION_SUCCEEDED )
    {
        const eventName: string = getPurchaseEventName( state );
        logEvent( eventName, allProperties );
    }
}

function getPurchaseEventName( state: StoreState )
{
    const trialOrPurchase: string = isUserTrialing( state ) ? "trial_" : "purchase_";
    return (trialOrPurchase + getCurrentProductTier( state ) + "_" + getPlanInterval( getSelectedPlan( state ) ) + "_webapp").toLowerCase();
}

function getPlanInterval( plan: StripePlanData )
{
    if ( plan )
    {
        if ( plan.type === STRIPE_PLAN_TYPE_ANNUAL )
        {
            return "yearly";
        }
        return "monthly";
    }
    return "";
}

function logPurchaseWarmupDisplayed( source: string )
{
    logEvent( PURCHASE_WARM_UP_DISPLAYED, { source } );
}

function logPurchaseWarmupAccepted( source: string )
{
    logSubscriptionEvent( PURCHASE_WARM_UP_ACCEPTED, {}, source );
}

function logPurchaseWarmupDeclined( source: string )
{
    logSubscriptionEvent( PURCHASE_WARM_UP_DECLINED, {}, source );
}

function logSubscriptionTierSelected( source: string, selectedTier: string )
{
    logEvent( SUBSCRIPTION_TIER_SELECTED, {
            source: source || getSubscriptionFlowSource( store.getState() ),
            tier_id: selectedTier,
        },
    );
}

function logSubscriptionUpTierBlocked( source: string )
{
    logEvent( SUBSCRIPTION_UP_TIER_BLOCKED, {
        source: source || getSubscriptionFlowSource( store.getState() ),
    } );
}

function logCreditCardDialogDeclined()
{
    logEvent( CREDIT_CARD_DIALOG_DECLINED, { source: getSubscriptionFlowSource( store.getState() ) } );
}

function logPaymentMethodUpdated( source: string )
{
    logEvent( PAYMENT_METHOD_UPDATED, { source } );
}

function logMainDesignMaxErrorsReached( error )
{
    try
    {
        const parameters = {};
        if ( !!error )
        {
            const parsedError = JSON.parse( error );
            for ( const key in parsedError )
            {
                if ( parsedError.hasOwnProperty( key ) )
                {
                    let value = parsedError[key];
                    if ( key === "template" )
                    {
                        value = stringUtils.convertDesignNameFromCamelCaseToKebabCaseForm( value );
                    }
                    parameters[key] = value;
                }
            }
        }

        logEvent( MAIN_DESIGN_MAX_ERRORS_REACHED_EVENT, { ...parameters, ...getCustomizationAndSharingEventProperties() } );
    }
    catch (error)
    {
        errorReporting.reportErrorToSentry( error );
    }
}

function logVideoFileSizeExceedLimit( fileSizeLimit, userUploadedFileSize )
{
    logEvent( VIDEO_FILE_SIZE_EXCEED_LIMIT, { fileSizeLimit, userUploadedFileSize } );
}

function logConvertVideoStarted()
{
    logEvent( CONVERT_VIDEO_STARTED );
}

function logConvertVideoFailed()
{
    logEvent( CONVERT_VIDEO_FAILED );
}

function logTrimVideoFailed()
{
    logEvent( TRIM_VIDEO_FAILED );
}

function logEditVideoOpened()
{
    const state = store.getState();
    const videoDurationInSeconds = getVideoTrimmerVideoDuration( state );
    logEvent( EDIT_VIDEO_OPENED, {
        videoDurationInSeconds,
    } );
}

function logEditVideoClosed( reasonClosed, videoWasTrimmed )
{
    const state = store.getState();
    const videoDurationInSeconds = getVideoTrimmerVideoDuration( state );
    logEvent( EDIT_VIDEO_CLOSED, {
        reasonClosed,
        videoDurationInSeconds,
        videoWasTrimmed,
    } );
}

function logTrimVideoEnded( mediaId: string )
{
    const state = store.getState();
    const videoDurationInSeconds = getVideoTrimmerVideoDuration( state );
    const videoTrimDurationInSeconds = getVideoTrimmedDurationInMS( state, mediaId ) / 1000;
    logEvent( TRIM_VIDEO_ENDED, {
        videoTrimDurationInSeconds,
        videoDurationInSeconds,
        videoWasTrimmed: true,
    } );
}

function logCatalogSwitched( categorySelected: CatalogCategory )
{
    const eventProperties = { ...getCustomizationAndSharingEventProperties(), categorySelected: categorySelected.name };
    logEvent( CATALOG_SWITCHED, eventProperties );
}

function logCatalogAspectRatioSwitched( aspectRatio: AspectRatio )
{
    logEvent( CATALOG_ASPECT_RATIO_SWITCHED, { aspectRatio } );
}

function logTemplateSelected()
{
    logEvent( TEMPLATE_SELECTED, getCustomizationAndSharingEventProperties() );
}

function logCatalogTemplateSelected( design: Design )
{
    const customizationAndSharingEventProperties = getCustomizationAndSharingEventProperties();
    const allProperties = {
        ...customizationAndSharingEventProperties,
        catalogDesignSelected: design.slug,
    };
    logEvent( CATALOG_TEMPLATE_SELECTED, allProperties );
}

function logBrandSettingsButtonClicked( source: string )
{
    logEvent( BRAND_SETTINGS_BUTTON_CLICKED, { source } );
}

function logBrandSettingsApplyChangesButtonClicked()
{
    logEvent( BRAND_SETTINGS_APPLY_CHANGES_BUTTON_CLICKED );
}

function logLogoToggleSwitched( status: boolean, source: string )
{
    logEvent( LOGO_TOGGLE_SWITCHED, { status, source } );
}

function logBrandSettingsColorSquareClicked()
{
    logEvent( BRAND_SETTINGS_COLOR_SQUARE_CLICKED );
}

function logLogoUploadSucceeded( source: string )
{
    logEvent( LOGO_UPLOAD_SUCCEEDED, { source } );
}

function logBrandSettingsColorApplied( source: string, type: string )
{
    logEvent( BRAND_SETTINGS_COLOR_APPLIED, { source, type } );
}

function logBrandSettingsFontApplied( source: string, type: string )
{
    logEvent( BRAND_SETTINGS_FONT_APPLIED, { source, type } );
}

function logContentSearched( searchTerm: string, totalMatches: number )
{
    const state = store.getState();
    const business = getCurrentBusiness( state );
    const eventProperties = {
        searchTerm,
        totalMatches,
        businessType: business && business.business_type_name,
    };
    logEvent( CONTENT_SEARCHED, eventProperties );
}

function logContentSearchClicked( aspectRatio: AspectRatio, outputType: string, contentSearchPostId: number, designSlug: string )
{
    const eventProperties = {
        aspectRatio,
        outputType,
        contentSearchPostId,
        designSlug,
    };
    logEvent( CONTENT_SEARCH_RESULT_CLICKED, eventProperties );
}

function logCollectionPostClicked( aspectRatio: AspectRatio,
                                   outputType: string,
                                   collectionPostId: number,
                                   designSlug: string,
                                   collectionSlug: string )
{
    const eventProperties = {
        aspectRatio,
        outputType,
        designSlug,
        collectionPostId,
        collectionSlug,
    };
    logEvent( COLLECTION_POST_CLICKED, eventProperties );
}

function logStockMediaSearched( searchTerm: string, mediaType: string, isUserInitiatedSearch: boolean )
{
    const state = store.getState();
    const business = getCurrentBusiness( state );
    const eventProperties = {
        searchTerm,
        businessType: business && business.business_type_name,
        mediaType,
        isUserInitiatedSearch,
    };
    logEvent( STOCK_MEDIA_SEARCHED, eventProperties );
}

function logStockMediaClicked( mediaID: string, mediaType: string )
{
    const state = store.getState();
    const business = getCurrentBusiness( state );
    const eventProperties = {
        mediaID,
        businessType: business && business.business_type_name,
        mediaType,
    };
    logEvent( STOCK_MEDIA_CLICKED, eventProperties );
}

function logPostDetailsDisplayed( postId: number )
{
    const eventProperties = {
        postId,
    };
    logEvent( WEB_NAV_POST_DETAILS_DISPLAYED, eventProperties );
}

export interface EventParamsPostDetailsCopied
{
    postId: number;
}

function logPostDetailsCopied( postDetailsCopiedParams: EventParamsPostDetailsCopied )
{
    logEvent( WEB_NAV_POST_DETAILS_COPIED, postDetailsCopiedParams );
}

function logDraftOpened( postId: number )
{
    const eventProperties = {
        postId,
    };
    logEvent( WEB_NAV_DRAFT_OPENED, eventProperties );
}

function logInspireDisplayed()
{
    logEvent( WEB_NAV_INSPIRE_DISPLAYED );
}

function logFirstRunInspireDisplayed()
{
    logEvent( FIRST_RUN_INSPIRE_DISPLAYED );
}

function logProfileDisplayed()
{
    logEvent( WEB_NAV_PROFILE_DISPLAYED );
}

function logMyBrandDisplayed()
{
    logEvent( WEB_NAV_BRAND_DISPLAYED );
}

function logMyAccountDisplayed()
{
    logEvent( WEB_NAV_ACCOUNT_DISPLAYED );
}

function logProfilePostDisplayed()
{
    logEvent( WEB_NAV_PROFILE_POST_DISPLAYED );
}

function logDraftsDisplayed()
{
    logEvent( WEB_NAV_DRAFTS_DISPLAYED );
}

function logCalendarDisplayed()
{
    logEvent( WEB_NAV_CALENDAR_DISPLAYED );
}

function logLearnDisplayed()
{
    logEvent( WEB_NAV_LEARN_DISPLAYED );
}

function logEyebrowClicked( actionName: SupportedNoticeActionName )
{
    logEvent( EYEBROW_CLICKED, { actionName } );
}

function logSubscriptionOnHoldLockoutMessageShown()
{
    logEvent( SUBSCRIPTION_ON_HOLD_LOCKOUT_MESSAGE_SHOWN );
}

function logSubscriptionOnHoldLockoutManageSubscriptionButtonTapped()
{
    logEvent( SUBSCRIPTION_ON_HOLD_LOCKOUT_MANAGE_SUBSCRIPTION_BUTTON_TAPPED );
}

function logSubscriptionOnHoldLockoutRefreshButtonTapped()
{
    logEvent( SUBSCRIPTION_ON_HOLD_LOCKOUT_REFRESH_BUTTON_TAPPED );
}

function logManageSubscriptionTapped()
{
    logEvent( MANAGE_SUBSCRIPTION_TAPPED );
}

function logManageSubscriptionDisplayed()
{
    logEvent( MANAGE_SUBSCRIPTION_DISPLAYED );
}

function logCancelSubscriptionTapped()
{
    logEvent( CANCEL_SUBSCRIPTION_TAPPED );
}

function logCancelSubscriptionSurveyDisplayed()
{
    logEvent( CANCEL_SUBSCRIPTION_SURVEY_DISPLAYED );
}

function logCancelSubscriptionSurveyMultipleResponses( eventTrackerSurveyDatum: EventTrackerSurveyReportingData[] )
{
    forEach( eventTrackerSurveyDatum, ( eventTrackerSurveyData ) =>
    {
        logCancelSubscriptionSurveyResponse( eventTrackerSurveyData );
    } );
}

function logCancelSubscriptionSurveyResponse( eventTrackerSurveyData: EventTrackerSurveyReportingData )
{
    logEvent( CANCEL_SUBSCRIPTION_SURVEY_RESPONSE, { ...eventTrackerSurveyData } );
}

function logCancelSubscriptionConfirmed()
{
    logEvent( CANCEL_SUBSCRIPTION_CONFIRMED );
}

function logCurrencyChanged( currencyCode: CurrencyCode )
{
    logEvent( CURRENCY_CHANGED, { currencyCode } );
}

function logCurrencyInitialized( currencyCode: CurrencyCode )
{
    logEvent( CURRENCY_INITIALIZED, { currencyCode } );
}

function logWinBackOfferDisplayed()
{
    logEvent( WIN_BACK_OFFER_DISPLAYED );
}

function logWinBackOfferAcceptButtonTapped( planId: string )
{
    logEvent( WIN_BACK_OFFER_ACCEPT_BUTTON_TAPPED, { productSku: planId } );
}

function logWinBackOfferApplied( planId: string )
{
    logEvent( WIN_BACK_OFFER_APPLIED, { productSku: planId } );
}

function logWinBackOfferDeclined()
{
    logEvent( WIN_BACK_OFFER_DECLINED );
}

function logTestDriveAccepted()
{
    logEvent( TEST_DRIVE_ACCEPTED );
}

function logCollectionRowPostClicked( post: Post,
                                      collectionSlug: string = "unknown",
                                      collectionRowIndex: number,
                                      sourceSelectionIndex: number,
                                      sourceTotalItems: number )
{
    const eventProperties = {
        aspectRatio: post.aspect_ratio,
        outputType: post.output_type,
        designSlug: post.design_slug,
        collectionPostId: post.id,
        collectionSlug,
        collectionRowIndex,
        sourceSelectionIndex,
        sourceTotalItems,
    };
    logEvent( COLLECTION_ROW_POST_CLICKED, eventProperties );
}

function logCollectionRowSeeAllTapped( collectionRowIndex: number, collectionSlug: string )
{
    logEvent( COLLECTION_ROW_SEE_ALL_TAPPED, { collectionRowIndex, collectionSlug } );
}

function hasExtraCaptionsWithNonDefaultTextStyle()
{
    const state = store.getState();
    const { globalSettings } = state.mixModel;

    if ( globalSettings )
    {
        const { extraCaptionStore } = globalSettings;
        if ( extraCaptionStore )
        {
            const results = filter( extraCaptionStore, ( extraCaptionStoreData: ExtraCaptionStoreData ) =>
            {
                return extraCaptionStoreData.renderStyle && (extraCaptionStoreData.renderStyle !== STYLIZED_TEXT_DEFAULT_STYLE_ID);
            } );
            return results.length > 0;
        }
    }
    return false;
}

function getNumberOfExtraCaptionsEventProps()
{
    const extraCaptionIds = [];
    const state = store.getState();
    const { globalSettings } = state.mixModel;

    if ( globalSettings )
    {
        const { slideIds } = globalSettings;
        if ( slideIds )
        {
            map( slideIds, ( globalSlideData ) =>
            {
                const { extraCaptionData } = globalSlideData;
                if ( extraCaptionData )
                {
                    for ( const extraCaptionId in extraCaptionData )
                    {
                        if ( extraCaptionData.hasOwnProperty( extraCaptionId ) && !includes( extraCaptionIds, extraCaptionId ) )
                        {
                            extraCaptionIds.push( extraCaptionId );
                        }
                    }
                }
            } );
        }
    }

    return fromPairs( [
        [NUMBER_OF_EXTRA_CAPTIONS, extraCaptionIds.length],
    ] );
}

function logExtraCaptionAddedEvent()
{
    logEvent( EXTRA_CAPTION_ADDED, getNumberOfExtraCaptionsEventProps() );
}

function logExtraCaptionRemovedEvent()
{
    logEvent( EXTRA_CAPTION_REMOVED, getNumberOfExtraCaptionsEventProps() );
}

function logShowYouAroundDisplayedStepEvent( event: ShowYouAroundDisplayedEventName )
{
    logEvent( event );
}

function logShowYouAroundDismissedEvent( source: ShowYouAroundDismissalSource )
{
    const eventProperties: ShowYouAroundDismissedEventProperties = { source };
    logEvent( SHOW_YOU_AROUND_DISMISSED, eventProperties );
}

function logShowYouAroundDoneTappedEvent()
{
    logEvent( SHOW_YOU_AROUND_DONE_TAPPED );
}

function logReferAFriendDisplayedEvent()
{
    logEvent( REFER_A_FRIEND_DISPLAYED );
}

function logSurveyShown( surveyCode: string )
{
    logEvent( RIPL_USER_SURVEY_2023_SHOWN, getSurveyEventProperties( surveyCode ) );
}

function getSurveyEventProperties( surveyCode: string )
{
    return { [SURVEY_CODE_KEY]: surveyCode };
}

function logAlkaiCrossPromoDialogShown( cohort: string )
{
  logEvent( ALKAI_CROSS_PROMO_DIALOG_SHOWN, getAlkaiCrossPromoDialogEventProperties( cohort ) );
}

function logAlkaiCrossPromoDialogAccepted( cohort: string )
{
  logEvent( ALKAI_CROSS_PROMO_DIALOG_ACCEPTED, getAlkaiCrossPromoDialogEventProperties( cohort ) );
}

function logAlkaiCrossPromoDialogDeclined( cohort: string )
{
  logEvent( ALKAI_CROSS_PROMO_DIALOG_DECLINED, getAlkaiCrossPromoDialogEventProperties( cohort ) );
}

function getAlkaiCrossPromoDialogEventProperties( cohort: string )
{
  return { [ALKAI_DIALOG_COHORT_KEY]: cohort };
}

function logPaymentFormStripeTokenFailed()
{
    logEvent( PAYMENT_FORM_STRIPE_TOKEN_FAILED );
}

function logGetMoreMusicClicked( state: StoreState )
{
    const properties = {
        connected: hasValidConnectionToEpidemicSound( state ),
    };
    logEvent( GET_MORE_MUSIC_CLICKED, properties );
}

function logDownloadMusicFailed()
{
    const mixModelProperties = getCustomizationAndSharingEventProperties();
    logEvent( DOWNLOAD_MUSIC_FAILED, mixModelProperties );
}

function logMusicItemSelected()
{
    const mixModelProperties = getCustomizationAndSharingEventProperties();
    logEvent( MUSIC_ITEM_SELECTED, mixModelProperties );
}

function logPickMusic()
{
    const mixModelProperties = getCustomizationAndSharingEventProperties();
    logEvent( PICK_MUSIC, mixModelProperties );
}

function logPostIntentSelectionScreenDisplayed()
{
    const properties = {
        launchedFrom: "firstRun",
    };
    logEvent( POST_INTENT_SELECTION_SCREEN_DISPLAYED, properties );
}

function logPostIntentTapped( slug: string, id: string )
{
    const properties = {
        collectionSlug: slug,
        paramId: id,
    };
    logEvent( POST_INTENT_TAPPED, properties );
}

function logPostIntentTemplateScreenDisplayed( name: string )
{
    const properties = {
        collectionName: name,
    };
    logEvent( POST_INTENT_TEMPLATE_SCREEN_DISPLAYED, properties );
}

function logTemplatePreviewShown( source: TemplatePreviewSources, designSlug: string )
{
    const properties = {
        source,
        templatePreviewDesign: designSlug,
    };

    logEvent( TEMPLATE_PREVIEW_SHOWN, properties );
}

function logTemplatePreviewCustomizeTapped( source: TemplatePreviewSources, designSlug: string, brandStylesApplied: boolean )
{
    const properties = {
        source,
        designSlug,
        brandStylesApplied,
    };

    logEvent( TEMPLATE_PREVIEW_CUSTOMIZE_TAPPED, properties );
}

function logTemplatePreviewApplyBrandStylesSwitchToggled( source: TemplatePreviewSources, designSlug: string )
{
    const properties = {
        source,
        designSlug,
    };

    logEvent( TEMPLATE_PREVIEW_APPLY_BRAND_STYLES_SWITCH_TOGGLED, properties );
}

function logChangedSizeFromCustomize( aspectRatio: string, aspectRatioPrevious: string )
{
    const properties = {
        aspectRatio,
        aspectRatioPrevious,
    };

    logEvent( CHANGED_SIZE_FROM_CUSTOMIZE, properties );
}

function logSocialNetworkConnectClicked( accountType: SocialNetworkAccountType )
{
    if ( accountType === FACEBOOK_ACCOUNT_TYPE )
    {
        logEvent( PROFILE_FACEBOOK_CONNECT_TAPPED_EVENT );
    }
    else if ( accountType === FACEBOOK_GROUP_ACCOUNT_TYPE )
    {
        logEvent( PROFILE_FACEBOOK_GROUPS_CONNECT_TAPPED_EVENT );
    }
    else if ( accountType === FACEBOOK_PAGE_ACCOUNT_TYPE )
    {
        logEvent( PROFILE_FACEBOOK_PAGES_CONNECT_TAPPED_EVENT );
    }
    else if ( accountType === FACEBOOK_INSTAGRAM_ACCOUNT_TYPE )
    {
        logEvent( PROFILE_FACEBOOK_INSTAGRAM_CONNECT_TAPPED_EVENT );
    }
    else if ( accountType === INSTAGRAM_ACCOUNT_TYPE )
    {
        logEvent( PROFILE_INSTAGRAM_PERSONAL_CONNECT_TAPPED_EVENT );
    }
    else if ( accountType === LINKEDIN_ACCOUNT_TYPE )
    {
        logEvent( PROFILE_LINKEDIN_CONNECT_TAPPED_EVENT );
    }
    else if ( accountType === TWITTER_ACCOUNT_TYPE )
    {
        logEvent( PROFILE_TWITTER_CONNECT_TAPPED_EVENT );
    }
    else if ( accountType === YOUTUBE_ACCOUNT_TYPE )
    {
        logEvent( PROFILE_YOUTUBE_CONNECT_TAPPED_EVENT );
    }
}

function sendAirshipCustomEvent( eventName: string )
{
    store.dispatch( airshipServices.sendCustomEvent( eventName ) );
}

function getBrowserSize()
{
    return {
        browserWidth: window.innerWidth,
        browserHeight: window.innerHeight,
        browserAspectRatio: Math.round( (window.innerWidth / window.innerHeight) * 100 ) / 100,
    };
}

function getCustomizationAndSharingEventProperties( includeAdProps: boolean = false )
{
    const state = store.getState();
    const mixModelEventProperties = getPropertiesForEventTracking( state );
    const designEventProperties = getDesignEventProperties( state );
    const numberOfExtraCaptionsProps = getNumberOfExtraCaptionsEventProps();
    const hasCaptionsWithNonDefaultTextStyle = hasExtraCaptionsWithNonDefaultTextStyle();
    const postFlowUniqueId = getPostSessionId();
    const designTextProperties = getDesignTextPropertiesPresence( state );
    const musicProperties = getMusicProperties( state );

    let postEventProperties;
    if ( includeAdProps )
    {
        postEventProperties = getPostEventProperties( state );
    }
    else
    {
        postEventProperties = getSocialNetworkPostEventProperties( state );
    }

    // if the property value is null, we have not implemented the event action yet.
    return {
        actionsExamplePostID: null,
        businessActionId: null,
        suggestedActionName: null,
        ...mixModelEventProperties,
        ...designEventProperties,
        ...postEventProperties,
        ...numberOfExtraCaptionsProps,
        ...designTextProperties,
        ...musicProperties,
        hasCaptionsWithNonDefaultTextStyle,
        postFlowUniqueId,
    };
}

function getMusicProperties( state: StoreState )
{
    const designEventProperties = getDesignEventProperties( state );
    const epidemicSoundTrackName = getEpidemicSoundTrackName( state );
    const epidemicSource = !!epidemicSoundTrackName ? getEpidemicSoundSource( state ) : null;

    const musicTitle = !!epidemicSoundTrackName ? epidemicSoundTrackName : designEventProperties.musicTitle;
    const musicId = getMusicId( state );
    const musicType = getMusicType( state );

    return {
        musicId,
        musicType,
        musicTitle,
        epidemicSource,
    };
}

function getDesignEventProperties( state: StoreState )
{
    const selectedDesignId = getSelectedDesignId( state );
    const startingDesignId = getStartingDesignId( state );
    const designSlug = getDesignSlugByDesignId( state, selectedDesignId );
    const startingTemplate = getDesignSlugByDesignId( state, startingDesignId );
    return {
        isFree: includes( getFreeDesignIds( state ), selectedDesignId ),
        isFreemium: includes( getFreemiumDesignIds( state ), selectedDesignId ),
        musicTitle: getMusicTitle( state ),
        newCatalogCategoryName: getCategoriesNameByDesignId( state, selectedDesignId ),
        template: designSlug,
        startingTemplate,
        designSlug,
    };
}

function getDesignTextPropertiesPresence( state: StoreState )
{
    return {
        hasPrimaryText: !isEmpty( getPrimaryText( state ) ),
        hasSecondaryText: !isEmpty( getSecondaryText( state ) ),
    };
}

function getSocialNetworkPostEventProperties( state: StoreState )
{
    return {
        isPostScheduled: isPostScheduled( state ),
        isRiplProUser: canUserAccessPaidFeatures( state ),
        socialNetworksToShareTo: getEnabledSocialNetworkAccountTypes( state ),
        shareMethod: getShareMethods( state ),
        isAd: isFacebookAd( state ),
    };
}

function getShareMethods( state: StoreState )
{
    const enabledSocialNetworkAccountTypes = getEnabledSocialNetworkAccountTypes( state );
    const shareMethods = stringUtils.convertStringArrayToLowerCase( enabledSocialNetworkAccountTypes );
    const stringToReplace = toLower( FACEBOOK_INSTAGRAM_ACCOUNT_TYPE );
    if ( includes( shareMethods, stringToReplace ) )
    {
        shareMethods.splice( indexOf( shareMethods, stringToReplace ), 1 );
        shareMethods.push( FACEBOOK_INSTAGRAM_FEED_SHARE_METHOD_NAME );
    }

    return shareMethods;
}

function getPostEventProperties( state: StoreState )
{
    return getSocialNetworkPostEventProperties( state );
}

function getEnabledSocialNetworkAccountTypes( state )
{
    const enabledSocialNetworkIds = getEnabledSocialNetworkIds( state );
    const userSocialNetworkAccounts = getUserSocialNetworkAccounts( state );
    const socialMediaSelectedAccountArray = [];

    if ( getFacebookEnabled( state ) )
    {
        socialMediaSelectedAccountArray.push( FACEBOOK_ACCOUNT_TYPE );
    }
    if ( getSaveToComputerEnabled( state ) )
    {
        socialMediaSelectedAccountArray.push( SAVE_TO_COMPUTER_ACCOUNT_TYPE );
    }

    const enabledSocialNetworkAccountTypes = map( filter( userSocialNetworkAccounts, ( socialNetworkAccount ) =>
    {
        return includes( enabledSocialNetworkIds, socialNetworkAccount.id );
    } ), "account_type" );

    return uniq( concat( socialMediaSelectedAccountArray, enabledSocialNetworkAccountTypes ) );
}

function getConnectedSocialMediaAccountTypes( state )
{
    const userSocialNetworkAccounts = getUserSocialNetworkAccounts( state );
    return getAccountTypesForSocialNetworkAccounts( userSocialNetworkAccounts );
}

function getAccountTypesForSocialNetworkAccounts( socialNetworkAccounts: SocialNetworkAccount[] )
{
    const socialNetworkAccountTypes = map( socialNetworkAccounts, ( socialNetworkAccount ) =>
    {
        const accountType = socialNetworkAccount.account_type;
        if ( isFacebookChildAccountType( accountType ) )
        {
            return FACEBOOK_ACCOUNT_TYPE;
        }

        return accountType;
    } );
    return uniq( socialNetworkAccountTypes );
}

function getFacebookEventAttributionProperty(): FacebookEventAttributionProperty
{
    return {
        external_id: getCurrentUserId( store.getState() ),
    };
}

function getPropertiesForUser( state: StoreState ): UserProperties
{
    // TODO Figure out how to clear an individual property. If a user has at least one
    // business and then deletes all businesses, the businessName, businessTypeName, and allBusinessName
    // will probably be incorrect.

    const business = getCurrentBusiness( state );
    const experimentInfo = getApptimizeExperimentInfo() || {};
    return {
        riplUserId: getCurrentUserId( state ),
        postSessionId: getSessionIdForPostCustomization( state ),
        productSku: getPropertyForAmplitude( getProductSku( state ) ),
        isRiplProUser: isUserPersonallySubscribed( state ),
        isPro: isUserPersonallySubscribed( state ),
        subscriptionStatus: getSubscriptionStatus( state ),
        promotions: getUserPromotionNames( state ),
        isDesktop: false,
        businessId: business && business.id,
        businessName: business && business.name,
        businessTypeName: business && business.business_type_name,
        allBusinessNames: getAllBusinessNames( state ),
        connectedSocialAccountTypes: getConnectedSocialMediaAccountTypes( state ),
        partner: getPropertyForAmplitude( getPartner( state ) ),
        ...experimentInfo,
    };
}

function getApptimizeExperimentInfo(): ExperimentInfo
{
    const testInfos = apptimizeWrapper.getVariantInfo();
    if ( !testInfos || testInfos.length === 0 )
    {
        return null;
    }

    const experimentNamesUserEnrolled = [];
    const experimentAndVariantNamesEnrolled = [];
    const experimentNamesUserParticipated = [];
    const experimentAndVariantNamesParticipated = [];

    forEach( testInfos, ( variantInfo ) =>
    {
        const experimentEnrolled = variantInfo.getExperimentName();
        const experimentAndVariantNameEnrolled = `${variantInfo.getExperimentName()}-${variantInfo.getVariantName()}`;

        experimentNamesUserEnrolled.push( experimentEnrolled );
        experimentAndVariantNamesEnrolled.push( experimentAndVariantNameEnrolled );

        if ( variantInfo.getUserHasParticipated() )
        {
            const experimentParticipated = variantInfo.getExperimentName();
            const experimentAndVariantNameParticipated = `${variantInfo.getExperimentName()}-${variantInfo.getVariantName()}`;
            experimentNamesUserParticipated.push( experimentParticipated );
            experimentAndVariantNamesParticipated.push( experimentAndVariantNameParticipated );
        }
    } );

    return {
        experimentNamesUserEnrolled,
        experimentAndVariantNamesEnrolled,
        experimentNamesUserParticipated,
        experimentAndVariantNamesParticipated,
    };
}

function getPostSessionId()
{
    const state = store.getState();
    return getSessionIdForPostCustomization( state );
}

function getAllBusinessNames( state: StoreState )
{
    const businesses = getAllKnownBusinesses( state );
    return map( businesses, "name" );
}

function getPropertyForAmplitude( userProperty: string )
{
    return userProperty || AMPLITUDE_USER_PROPERTY_NULL;
}

function trackAccountCreated()
{
    appsflyerWrapper.trackRegistrationEvent();
    reportGoogleLoginConversion();
}

function reportGoogleLoginConversion()
{
    reportGoogleTagEvent( "AW-875425320/fQofCJXLr4gBEKjct6ED" );
}

function reportGooglePurchaseConversion()
{
    const state = store.getState();

    if ( isUserSubscribedMonthlyStripe( state ) )
    {
        reportGoogleTagEvent( isCurrentProductTierPremium( state ) ?
                              GOOGLE_CONVERSION_PREMIUM_MONTHLY_SUBSCRIPTION_TAG : GOOGLE_CONVERSION_MONTHLY_SUBSCRIPTION_TAG );
    }
    else if ( isUserSubscribedYearlyStripe( state ) )
    {
        reportGoogleTagEvent( isCurrentProductTierPremium( state ) ?
                              GOOGLE_CONVERSION_PREMIUM_YEARLY_SUBSCRIPTION_TAG : GOOGLE_CONVERSION_YEARLY_SUBSCRIPTION_TAG );
    }
}

function reportGoogleTagEvent( aConversionEventNumber: string )
{
    if ( ENABLE_USAGE_TRACKING && window.gtag )
    {
        window.gtag( "event", "conversion", { send_to: aConversionEventNumber } );
    }
}

function setApptimizeAppVersion( appVersion: string )
{
    apptimizeWrapper.setAppVersion( appVersion );
}

function setApptimizeCustomerUserId( userId: string )
{
    apptimizeWrapper.setCustomerUserId( userId );
}

function setPilotTargetingId( userId: string )
{
    apptimizeWrapper.setPilotTargetingId( userId );
}

function setApptimizeCustomAttributes( userProperties: UserProperties )
{
    apptimizeWrapper.setCustomAttributes( userProperties );
}

function logToApptimize( eventName: string )
{
    apptimizeWrapper.track( eventName );
}

function setAmplitudeVersionName( appVersion: string )
{
    if ( ENABLE_USAGE_TRACKING && amplitude.getInstance() )
    {
        amplitude.getInstance().setVersionName( appVersion );
    }
}

function setAmplitudeUserId( userId: string )
{
    if ( ENABLE_USAGE_TRACKING && amplitude.getInstance() )
    {
        amplitude.getInstance().setUserId( userId );
    }
}

function setAmplitudeUserProperties( userProperties: UserProperties )
{
    if ( ENABLE_USAGE_TRACKING && amplitude.getInstance() )
    {
        amplitude.getInstance().setUserProperties( userProperties );
    }
}

function setAppsflyerUserProperties( userProperties: UserProperties )
{
    if ( ENABLE_USAGE_TRACKING && userProperties )
    {
        const userIdString = toString( userProperties.riplUserId );
        appsflyerWrapper.identifyUser( userIdString );
    }
}

function clearAmplitudeUserProperties()
{
    if ( ENABLE_USAGE_TRACKING && amplitude.getInstance() )
    {
        amplitude.getInstance().clearUserProperties();
    }
}

function clearAmplitudeUserProperty( name: string )
{
    if ( ENABLE_USAGE_TRACKING && amplitude.getInstance() )
    {
        const amplitudeClient = amplitude.getInstance();
        const identify = new amplitudeClient.Identify();
        identify.unset( name );
        amplitudeClient.identify( identify );
    }
}

function clearApptimizeCustomAttributes()
{
    apptimizeWrapper.setCustomAttributes( {} );
}

function updateCacheOfApptimizeParticipatingExperimentAndVariantNames( experimentAndVariantNamesParticipated: string[] )
{
    forEach( experimentAndVariantNamesParticipated, ( experimentAndVariantName ) =>
    {
        store.dispatch( trackingActions.cacheApptimizeParticipatingExperimentAndVariantName( experimentAndVariantName ) );
    } );
}

function getErrorInfo( error: any )
{
    if ( error )
    {
        if ( isError( error ) )
        {
            return {
                errorMessage: error.message,
                errorStack: error.stack,
            };
        }
        else
        {
            return {
                errorMessage: error,
            };
        }
    }
}
