import { Action } from "redux-actions";
import { map } from "lodash";
import { postActions, UserBusinessActionData, userBusinessActions } from "../actions";
import { postsSchema, ReducerCreator, updateLookupWithAction } from "../helpers";
import { createDefaultDataLookup } from "./dataLookup";
import { MostEngagedPostState, StoreState } from "../_types";

const defaultState: MostEngagedPostState = {
    ...createDefaultDataLookup(),
    activity: [],
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( postActions.loadMostEngagedPostSuccess, handleLoad );
reducerCreator.addCombinedActions( [userBusinessActions.businessSwitched,
                                    userBusinessActions.deleteSuccess],
    handleBusinessSwitchedOrDeleted );
export default reducerCreator.createReducer();

function handleBusinessSwitchedOrDeleted( state: MostEngagedPostState, action: Action<UserBusinessActionData> ): MostEngagedPostState
{
    return {
        ...defaultState,
    };
}

function handleLoad( state: MostEngagedPostState, action: Action<NormalizrData> ): MostEngagedPostState
{
    const updatedState = updateLookupWithAction( postsSchema, action, state, false );
    updatedState.activity = action.payload.result || [];
    return updatedState;
}

export const getMostEngagedPostsSorted = ( state: StoreState ) =>
{
    return map( state.mostEngagedPosts.activity, ( id ) => state.mostEngagedPosts.idToObj[id]);
};
