import { StoreState, SurveyState } from "../_types";
import { ReducerCreator } from "../helpers";
import { surveyActions } from "../actions";
import { Action } from "redux-actions";
import { filter, map, shuffle } from "lodash";
import { SurveyAPIData } from "../_types/api/survey";

const defaultState: SurveyState = {
    id: null,
    page_id: null,
    questions: null,
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( surveyActions.surveyLoadSucceeded, handleSurveyLoadSucceeded );

export default reducerCreator.createReducer();

function handleSurveyLoadSucceeded( state: SurveyState, action: Action<SurveyAPIData> ): SurveyState
{
    const questionsRandomized = map( action.payload.questions, randomizeChoicesForQuestion );
    const payloadDataRandomized = {
        ...action.payload,
        questions: questionsRandomized,
    };

    return {
        ...state,
        ...payloadDataRandomized,
    };
}

function randomizeChoicesForQuestion( question )
{
    const choices = question.answers.choices;
    question.answers.choices = shuffle( filter( choices, { visible: true } ) );
    return question;
}

export const getPageId = ( state: StoreState ) => state.survey.page_id;
export const getSurveyQuestions = ( state: StoreState ) => state.survey.questions || [];
