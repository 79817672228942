import { detect, detectOS } from "detect-browser";

const isAndroidBrowser = () =>
{
    return detectOS( window.navigator.userAgent ) === "Android OS";
};

const isiOSBrowser = () =>
{
    return detectOS( window.navigator.userAgent ) === "iOS";
};

const isBlackberryOSBrowser = () =>
{
    return detectOS( window.navigator.userAgent ) === "BlackBerry OS";
};

const isWindowsMobileBrowser = () =>
{
    return detectOS( window.navigator.userAgent ) === "Windows Mobile";
};

const isAmazonOSBrowser = () =>
{
    return detectOS( window.navigator.userAgent ) === "Amazon OS";
};

const isSafariBrowser = () =>
{
    return detect( window.navigator.userAgent ).name === "safari";
};

const isMobileBrowser = () =>
{
    return isAndroidBrowser() || isiOSBrowser() || isBlackberryOSBrowser() || isWindowsMobileBrowser() || isAmazonOSBrowser();
};

export const browserUtils = {
    isAndroidBrowser,
    isiOSBrowser,
    isBlackberryOSBrowser,
    isWindowsMobileBrowser,
    isAmazonOSBrowser,
    isMobileBrowser,
    isSafariBrowser,
};
