import * as React from "react";
import TextField from "react-md/lib/TextFields/TextField";
import Button from "react-md/lib/Buttons/Button";

export interface AddBusinessNameContainerProps
{
    name?: string;
}

export interface AddBusinessNameProps extends AddBusinessNameContainerProps
{
    isBrandNameChanged: boolean;
}

export interface AddBusinessNameDispatchProps
{
    onFieldChanged: ( businessName: string ) => void;
    handleBrandNameUpdate: () => void;
}

export class AddBusinessName extends React.PureComponent<AddBusinessNameProps & AddBusinessNameDispatchProps>
{
    public render()
    {
        const businessNamePlaceHolder = "Brand name";
        const businessNameErrorText = "Brand name is required.";

        return (
            <div>
                <h3>Brand name *</h3>
                <TextField
                    required
                    floating
                    id="business-name"
                    className="businessTextField"
                    placeholder={businessNamePlaceHolder}
                    fullWidth={false}
                    value={this.props.name || ""}
                    errorText={businessNameErrorText}
                    ref="textFieldRef"
                    onChange={this.handleOnChange}
                />
                <Button className="updateButton"
                        flat
                        primary
                        disabled={!this.props.isBrandNameChanged}
                        onClick={this.props.handleBrandNameUpdate}>Update</Button>
            </div>
        );
    }

    public componentDidMount(): void
    {
        const { textFieldRef } = this.refs;
        if ( textFieldRef )
        {
            (textFieldRef as any).focus();
        }
    }

    private handleOnChange = ( content: string | number ) =>
    {
        this.props.onFieldChanged( content as string );
    }
}
