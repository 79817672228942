import * as React from "react";
import { replace, toLower, upperFirst } from "lodash";
import { ColorValue, UserBusiness } from "../../_types";
import { ColorBoxEditable } from "./ColorBoxEditable";

export interface BrandColorPickerProps
{
    containerClassName?: string;
    businessData: Partial<UserBusiness>;
}

export interface BrandColorPickerDispatchProps
{
    openDialog( userBusinessKey: keyof UserBusiness, value: ColorValue );
    clearColor( userBusinessKey: keyof UserBusiness );
}

export interface ColorPickerOptionProps extends BrandColorPickerProps
{
    colorField;
}

class ColorPickerOption extends React.PureComponent<ColorPickerOptionProps & BrandColorPickerDispatchProps>
{
    public render()
    {
        const color = this.getColorValueFromProps();
        return (
            <ColorBoxEditable title={this.transformColorFieldIntoTitle()}
                              color={color}
                              handleOpen={this.handleOpen}
                              handleColorBoxClicked={this.handleOpen}
                              showAdditionalEditIcon={true}/>);
    }

    private getColorValueFromProps = () =>
    {
        const { colorField, businessData } = this.props;
        return businessData && (businessData[colorField] as ColorValue);
    }

    private transformColorFieldIntoTitle = () =>
    {
        const word = replace( toLower( this.props.colorField ), "_", " " );
        return upperFirst( word );
    }
    private handleOpen = () =>
    {
        this.props.openDialog( this.props.colorField, this.getColorValueFromProps() );
    }
}

const colorFields: Array<(keyof UserBusiness)> = ["primary_color", "secondary_color"];

export class BrandColorPicker extends React.PureComponent<BrandColorPickerProps & BrandColorPickerDispatchProps>
{
    public render()
    {
        const containerClassName = this.props.containerClassName || "";
        return (
            <div className={containerClassName}>
                <div className="brandColorPicker">
                    {
                        colorFields.map( ( colorField ) =>
                        {
                            return <ColorPickerOption key={colorField} colorField={colorField} {...this.props} />;
                        } )
                    }
                </div>
            </div>
        );
    }
}
