import * as React from "react";
import { FormEvent } from "react";
import TextField from "react-md/lib/TextFields/TextField";
import Button from "react-md/lib/Buttons/Button";
import { EmailFormData } from "../_types";
import { INVALID_EMAIL_ADDRESS, validateEmail } from "../helpers";

export interface BusinessNameEmailPasswordInputPanelProps extends EmailFormData
{
    onContinueClicked?: ( emailAddress: string, password: string ) => void;
    onForgotLogin?: () => void;
    handleBackClicked?: () => void;
    hidePassword?: boolean;
    primaryButtonText?: string;
}

export interface BusinessNameEmailPasswordInputPanelDispatchProps
{
    handlePasswordUpdate( value: string );
    handleEmailUpdate( value: string );
    handleEmailError( value: string );
}

export class BusinessNameEmailPasswordInputPanel extends React.PureComponent<BusinessNameEmailPasswordInputPanelProps &
    BusinessNameEmailPasswordInputPanelDispatchProps>
{
    public render()
    {
        const { email, hidePassword, passwordError, password, onForgotLogin, handleBackClicked, children, primaryButtonText } = this.props;
        const emailError = this.props.emailError || this.props.error;
        return (
            <form className="emailPasswordInputPanel" onSubmit={this.handleEmailLogin}>
                <TextField
                    id="log-in-email"
                    type="email"
                    placeholder="Your Email Address"
                    className="loginInput"
                    required
                    error={emailError ? true : undefined}
                    errorText={emailError}
                    value={email}
                    onChange={this.updateEmail}/>
                {
                    hidePassword || <TextField
                                     id="log-in-pwd"
                                     type="password"
                                     placeholder="Create a Password"
                                     className="loginInput"
                                     required
                                     error={passwordError ? true : undefined}
                                     errorText={passwordError}
                                     value={password}
                                     onChange={this.updatePassword}/>
                }
                {
                    onForgotLogin &&
                    <div className="text center"><span className="standardLink" onClick={onForgotLogin}>Forgot password?</span></div>
                }
                {children}
                <div className="emailPasswordButtons">
                    <Button flat primary
                            className="large backBtn"
                            type="button" onClick={handleBackClicked}>Back</Button>
                    <Button flat primary swapTheming
                            className="large continueBtn"
                            type="submit">{primaryButtonText || "Finish"}</Button>
                </div>
            </form>
        );
    }

    private handleEmailLogin = ( e: FormEvent<HTMLFormElement> ) =>
    {
        e.preventDefault();
        if ( validateEmail( this.props.email ) )
        {
            this.props.onContinueClicked( this.props.email, this.props.password );
        }
        else
        {
            this.props.handleEmailError( INVALID_EMAIL_ADDRESS );
        }
    }

    private updateEmail = ( content: string | number ) =>
    {
        this.props.handleEmailUpdate( content as string );
    }

    private updatePassword = ( content: string | number ) =>
    {
        this.props.handlePasswordUpdate( content as string );
    }
}
