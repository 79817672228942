import * as React from "react";
import TextField from "react-md/lib/TextFields/TextField";
import FontIcon from "react-md/lib/FontIcons/FontIcon";
import { isEmpty, trim } from "lodash";

export interface ContentSearchBarProps
{
    lastRequestedSearchTerm: string;
    isSearching: boolean;
}

export interface ContentSearchBarDispatchProps
{
    onSearchSubmitted: ( searchTerm: string ) => void;
}

interface ContentSearchBarState
{
    searchTerm: string;
    lastSearchTerm: string;
}

export class ContentSearchBar extends React.Component<ContentSearchBarProps & ContentSearchBarDispatchProps, ContentSearchBarState>
{
    constructor( props )
    {
        super( props );
        this.state = {
            searchTerm: "",
            lastSearchTerm: "",
        };
    }

    public render()
    {
        return (
            <form className="contentSearchBarContainer" onSubmit={this.handleOnSubmit}>
                <div className="inputWrap">
                    <TextField
                        id="content-search-bar"
                        className="contentSearchBar"
                        value={this.state.searchTerm}
                        placeholder="Search for templates"
                        onChange={this.handleOnChange}/>
                    <FontIcon className="search">search</FontIcon>
                    {this.showClear()}
                </div>
            </form>
        );
    }

    private showClear()
    {
        if ( trim( this.state.searchTerm ) )
        {
            return <FontIcon className="clear" onClick={this.handleClearClicked}>clear</FontIcon>;
        }
    }

    private handleClearClicked = () =>
    {
        this.setState( { searchTerm: "" } );
    }

    private handleOnChange = ( searchTerm: string | number ) =>
    {
        this.setState( { searchTerm: searchTerm as string } );
    }

    private handleOnSubmit = ( e ) =>
    {
        e.preventDefault();
        if ( this.canRequestSearch() )
        {
            this.search();
        }
    }

    private canRequestSearch()
    {
        return !this.props.isSearching
               && !isEmpty( trim( this.state.searchTerm ) )
               && (
                   this.state.lastSearchTerm !== this.state.searchTerm
                   || this.props.lastRequestedSearchTerm !== this.state.searchTerm
               );
    }

    private search = () =>
    {
        this.props.onSearchSubmitted( this.state.searchTerm );
        this.setState( {
            lastSearchTerm: this.state.searchTerm,
        } );
    }
}
