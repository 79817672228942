import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../_types";
import { shareModelActions } from "../../actions";
import { getDefaultViaText } from "../../helpers";
import { PostMediaCaption, PostMediaCaptionDispatchProps, PostMediaCaptionProps } from "../PostMediaCaption";
import {
    getActualCharacterCount,
    getCaption,
    getExceedsCharacterLimitError,
    getExceedsHashtagLimitError,
    getMaxCharacterCount,
    shouldShowCharacterCount,
} from "../../ducks/shareModel";
import { isShowingLightbox } from "../../ducks/modals";

const mapStateToProps = ( storeState: StoreState ): PostMediaCaptionProps =>
{
    return {
        caption: getCaption( storeState ),
        suffixText: getDefaultViaText(),
        shouldShowCharacterCount: shouldShowCharacterCount( storeState ),
        isShowingDialog: isShowingLightbox( storeState ),
        exceedsHashtagLimitError: getExceedsHashtagLimitError( storeState ),
        exceedsCharacterLimitError: getExceedsCharacterLimitError( storeState ),
        actualCharacterCount: getActualCharacterCount( storeState ),
        maxCharacterCount: getMaxCharacterCount( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): PostMediaCaptionDispatchProps =>
{
    return {
        onCaptionUpdated: ( value: string ) =>
        {
            dispatch( shareModelActions.contentUpdated( value ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( PostMediaCaption );
