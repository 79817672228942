import { find, isEmpty, reject } from "lodash";
import { addSeconds, format, isFuture, isPast } from "date-fns";
import {
    FACEBOOK_GROUP_ACCOUNT_TYPE,
    FACEBOOK_INSTAGRAM_ACCOUNT_TYPE,
    FACEBOOK_PAGE_ACCOUNT_TYPE,
    INSTAGRAM_ACCOUNT_TYPE,
    LEGACY_POST_TYPE_EXTERNAL,
    SEND_STATUS_DRAFT,
    SEND_STATUS_ERROR,
    SEND_STATUS_POSTED,
    SEND_STATUS_PROCESSING,
    SEND_STATUS_SCHEDULED,
    SEND_STATUS_SENDING,
    SEND_STATUS_SENT,
    SEND_STATUS_UNSCHEDULED,
    SEND_STATUS_UPLOADED,
} from "../_types/api";
import { Post, SocialNetworkAccountType } from "../_types";
import { CustomizePostOptions } from "./customizationHelper";

export const postHelper = {
    getStatusText,
    getStatusClassFromPostModel,
    getDisplayablePostDateBasedOnStatus,
    isDraft,
    isInspirationPost,
    isContentSearchPost,
    isCollectionPost,
    isScheduled,
    isUnscheduled,
    isUserPost,
    mustCustomizePost,
};

const STATUS_TEXT = {
    SENT: "Sent",
    POSTED: "Posted",
    SCHEDULED: "Scheduled",
    ERRORED: "Failed",
    MISSED: "Missed",
    PROCESSING: "Processing",
    SAVED: "Saved",
    UNSCHEDULED: "Unscheduled",
};

const INSTAGRAM_MATCH_MAKING_GRACE_PERIOD_SECONDS = 1800;

function getStatusText( post: Post )
{
    if ( isErrored( post ) )
    {
        return STATUS_TEXT.ERRORED;
    }
    else if ( isMissed( post ) )
    {
        return STATUS_TEXT.MISSED;
    }
    else if ( isScheduled( post ) )
    {
        return STATUS_TEXT.SCHEDULED;
    }
    else if ( wasSent( post ) )
    {
        return STATUS_TEXT.SENT;
    }
    else if ( wasPosted( post ) )
    {
        return STATUS_TEXT.POSTED;
    }
    else if ( isProcessing( post ) )
    {
        return STATUS_TEXT.PROCESSING;
    }
    else if ( isSaved( post ) )
    {
        return STATUS_TEXT.SAVED;
    }
    else if ( isUnscheduled( post ) )
    {
        return STATUS_TEXT.UNSCHEDULED;
    }
    return "";
}

function wasSharedToSocialNetwork( post: Post )
{
    return (wasSharedToFacebook( post )
            || wasSharedToFacebookPages( post )
            || wasSharedToFacebookGroups( post )
            || wasSharedToInstagramBusiness( post )
            || wasSharedToInstagram( post )
            || wasSharedToTwitter( post )
            || wasSharedToLinkedIn( post )
            || wasSharedToYouTube( post )
            || post.type === LEGACY_POST_TYPE_EXTERNAL);
}

function wasUploaded( post: Post )
{
    return post && post.send_status === SEND_STATUS_UPLOADED;
}

function wasSent( post: Post )
{
    return post && post.send_status === SEND_STATUS_SENT;
}

function wasPosted( post: Post )
{
    return post && post.send_status === SEND_STATUS_POSTED;
}

function isErrored( post: Post )
{
    return post && post.send_status === SEND_STATUS_ERROR;
}

function isMissed( post: Post )
{
    return post && post.scheduled_send_at
           && wasSharedToInstagram( post )
           && isScheduledDateInPastWithInstagramGracePeriod( post )
           && hasInstagramPostWithMissingExternalIds( post );
}

function isSending( post: Post )
{
    return post && post.send_status === SEND_STATUS_SENDING;
}

function isProcessing( post: Post )
{
    return post &&
           (post.send_status === SEND_STATUS_PROCESSING
            || (post.send_status === SEND_STATUS_UPLOADED && wasSharedToSocialNetwork( post ))
            || isSending( post ));
}

function isSaved( post: Post )
{
    return (isUploaded( post ) && !wasSharedToSocialNetwork( post ));
}

function isUploaded( post: Post )
{
    return post && post.send_status === SEND_STATUS_UPLOADED;
}

function isDraft( post: Post )
{
    return post && post.send_status === SEND_STATUS_DRAFT;
}

function isInspirationPost( post: Post )
{
    return post && post.is_inspirational_post;
}

function isContentSearchPost( post: Post )
{
    return post && post.is_content_search_post;
}

function isCollectionPost( post: Post )
{
    return post && post.is_collection_post;
}

function isUserPost( post: Post, options: CustomizePostOptions )
{
    return post && !(isCollectionPost( post ) ||
                     isContentSearchPost( post ) ||
                     isInspirationPost( post ) ||
                     options.isRecommendedPost ||
                     options.isDeepLinkPost ||
                     options.isFromPartnerSite);
}

function isScheduled( post: Post )
{
    return post
           && post.send_status === SEND_STATUS_SCHEDULED
           && !!post.scheduled_send_at && isScheduledPostWaitingToFinish( post );
}

function isUnscheduled( post: Post )
{
    return post && post.send_status === SEND_STATUS_UNSCHEDULED;
}

function isScheduledPostWaitingToFinish( post: Post )
{
    if ( wasSharedToInstagram( post ) )
    {
        return !isScheduledDateInPastWithInstagramGracePeriod( post );
    }
    return post && isFuture( post.scheduled_send_at );
}

function isScheduledDateInPastWithInstagramGracePeriod( post: Post )
{
    return post && isPast( addSeconds( post.scheduled_send_at, INSTAGRAM_MATCH_MAKING_GRACE_PERIOD_SECONDS ) );
}

function wasSharedToFacebook( post: Post )
{
    return post && post.facebook_flag;
}

function wasSharedToFacebookPages( post: Post )
{
    return post && !isEmpty( post.facebook_pages_list ) || doesSocialNetworkPostsInclude( post, FACEBOOK_PAGE_ACCOUNT_TYPE );
}

function wasSharedToFacebookGroups( post: Post )
{
    return doesSocialNetworkPostsInclude( post, FACEBOOK_GROUP_ACCOUNT_TYPE );
}

function wasSharedToInstagramBusiness( post: Post )
{
    return doesSocialNetworkPostsInclude( post, FACEBOOK_INSTAGRAM_ACCOUNT_TYPE );
}

function wasSharedToInstagram( post: Post )
{
    return post && post.instagram_flag;
}

function wasSharedToTwitter( post: Post )
{
    return post && post.twitter_flag;
}

function wasSharedToLinkedIn( post: Post )
{
    return post && post.linkedin_flag;
}

function wasSharedToYouTube( post: Post )
{
    return post && post.youtube_flag;
}

function doesSocialNetworkPostsInclude( post: Post, accountType: SocialNetworkAccountType )
{
    return post && find( post.social_network_posts, { network: accountType } );
}

function hasInstagramPostWithMissingExternalIds( post: Post )
{
    return post && find( reject( post.social_network_posts, ( socialNetworkPost ) => socialNetworkPost.external_id ),
        { network: INSTAGRAM_ACCOUNT_TYPE } );
}

function getStatusClassFromPostModel( post: Post )
{
    if ( isErrored( post ) || isMissed( post ) )
    {
        return "error";
    }
    else if ( isScheduled( post ) )
    {
        return "scheduled";
    }
    else if ( wasSent( post ) || wasPosted( post ) || isSaved( post ) )
    {
        return "sent";
    }

    return;
}

function getDisplayablePostDateBasedOnStatus( post: Post )
{
    const postDateBasedOnStatus = getDateBasedOnStatus( post );
    if ( postDateBasedOnStatus )
    {
        return formatDate( postDateBasedOnStatus );
    }
    return null;
}

function getDateBasedOnStatus( post: Post ): string
{
    if ( post )
    {
        if ( isProcessing( post ) || wasUploaded( post ) || wasSent( post ) || wasPosted( post ) || isErrored( post ) )
        {
            return post.last_sent_at;
        }
        if ( isScheduled( post ) )
        {
            return post.scheduled_send_at;
        }
        return post.created_at;
    }
}

function formatDate( date: string )
{
    return format( date, "MMM. DD YYYY, hh:mm a" );
}

function mustCustomizePost( post: Post )
{
    return isInspirationPost( post )
           || isDraft( post )
           || isContentSearchPost( post )
           || isCollectionPost( post );
}
