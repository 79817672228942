import { connect } from "react-redux";
import { Dispatch } from "redux";
import { shareModelActions } from "../../actions";
import { EasyCaptionData, StoreState } from "../../_types";
import { EasyCaption, EasyCaptionDispatchProps, EasyCaptionProps } from "../EasyCaption";
import { getCaption, getEasyButtonClicks, getPreviousCaption } from "../../ducks/shareModel";
import { getPrimaryText, getSecondaryText } from "../../ducks/mixModel";
import { modalServices } from "../../services";

const mapStateToProps = ( state: StoreState ): EasyCaptionProps =>
{
    return {
        caption: getCaption( state ),
        headline: getPrimaryText( state ),
        body: getSecondaryText( state ),
        previousCaption: getPreviousCaption( state ),
        clicks: getEasyButtonClicks( state ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): EasyCaptionDispatchProps =>
{
    return {
        handleCaptionUpdate: ( caption: string, clicks: number ) =>
        {
            const easyCaptionData: EasyCaptionData = {
                caption,
                clicks,
            };
            dispatch( shareModelActions.easyCaptionUpdated( easyCaptionData ) );
            dispatch( shareModelActions.contentUpdated( caption ) );
        },
        handleNoCaptionIdeas: () =>
        {
            dispatch( modalServices.openAlertDialog( { message: "Easy Caption requires headline text, body text or text in the caption area" } ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( EasyCaption );
