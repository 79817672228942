import { connect } from "react-redux";
import { Dispatch } from "redux";
import { uiActions } from "../../actions";
import { CanvasUpdater } from "../../helpers";
import DesignCanvas, { DesignCanvasDispatchProps, DesignCanvasProps } from "../DesignCanvas";
import { getHideWatermark, getWatermarkUrl, hasDataRequiredToLoad } from "../../ducks/mixModel";
import { AspectRatio, Design, StoreState } from "../../_types";
import { getCatalogUpdatedAtCacheBuster } from "../../ducks/catalog";

interface DesignSwitchingDesignCanvasContainerProps
{
    iframeId: string;
    design: Design;
    aspectRatio: AspectRatio;
    lazyLoad: boolean;
    isSelectedDesign: boolean;
    hidePlayButton: boolean;
    animatePreviewOnHover: boolean;
    animatePreviewOnClick: boolean;
    onDesignClicked( design: Design, aspectRatio: AspectRatio ): void;
}

const mapStateToProps = ( state: StoreState, ownProps: DesignSwitchingDesignCanvasContainerProps ): DesignCanvasProps =>
{
    const {
        iframeId,
        design,
        aspectRatio,
        lazyLoad,
        isSelectedDesign,
        hidePlayButton,
        animatePreviewOnHover,
        animatePreviewOnClick,
    } = { ...ownProps };

    return ({
        iframeId,
        design,
        aspectRatio,
        lazyLoad,
        isSelectedDesign,
        hidePlayButton,
        animatePreviewOnHover,
        animatePreviewOnClick,
        editable: false,
        hasDataRequiredToLoad: hasDataRequiredToLoad( state, ownProps.design ),
        watermarkUrl: getWatermarkUrl( state ),
        hideWatermark: getHideWatermark( state ),
        catalogUpdatedAt: getCatalogUpdatedAtCacheBuster( state ),
    });
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownprops: DesignSwitchingDesignCanvasContainerProps ): DesignCanvasDispatchProps =>
{
    return {
        registerCanvas: ( canvasUpdater: CanvasUpdater ) =>
        {
            dispatch( uiActions.registerCanvasUpdater( canvasUpdater ) );
        },
        onDesignClicked: ownprops.onDesignClicked,
        startDesignLoadProgress( iframeId: string )
        {
            dispatch( uiActions.updateDesignLoadProgressStarted( iframeId ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( DesignCanvas );
