import { store } from "../store";
import { getCustomizableCanvas } from "../ducks/ui";
import { ICanvasUpdater } from "../_types/canvasUpdaterTypes";

export class CustomizableCanvas
{
    public static requestMediaObjectsForActivePage()
    {
        const canvas = this.getCanvas();
        if ( canvas )
        {
            canvas.tellDesignToRequestMediaObjectsForActivePage();
        }
    }

    public static requestStopAnimation()
    {
        const canvas = this.getCanvas();
        if ( canvas )
        {
            canvas.tellDesignToStopAnimation();
        }
    }

    private static getCanvas(): ICanvasUpdater
    {
        const state = store.getState();
        return getCustomizableCanvas( state );
    }
}
