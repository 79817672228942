import { connect } from "react-redux";
import { Dispatch } from "redux";
import { uiActions, userActions } from "../../actions";
import { FacebookGroupAddRiplDialog, FacebookGroupAddRiplDialogDispatchProps, FacebookGroupAddRiplDialogProps } from "../FacebookGroupAddRiplDialog";
import { userServices } from "../../services";
import { SocialNetworkAccount, StoreState } from "../../_types";
import { FACEBOOK_GROUP_ACCOUNT_TYPE } from "../../_types/api";

interface FacebookGroupAddRiplDialogContainerProps
{
    onComplete?: () => void;
}

const mapStateToProps = ( storeState: StoreState, ownProps: FacebookGroupAddRiplDialogContainerProps ): FacebookGroupAddRiplDialogProps =>
{
    return {
        ...ownProps,
        facebookGroupAddRiplDialogData: storeState.ui.facebookGroupAddRiplDialogData,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): FacebookGroupAddRiplDialogDispatchProps =>
{
    return {
        handleCloseModal: () =>
        {
            dispatch( uiActions.facebookGroupAddRiplModalClose() );
        },
        handleFacebookGroupAddedConfirm: async ( account: SocialNetworkAccount ) =>
        {
            const accountId = account && account.id;
            const data: any = await userServices.getRiplConnectedFacebookGroups( [accountId] );
            const isGroupConnected = account && data[account.network_id];
            if ( isGroupConnected )
            {
                dispatch( userActions.socialNetworkAccountHasPermissions( Object.keys( data ) ) );
                dispatch( uiActions.facebookPickerModalUpdateAccount( FACEBOOK_GROUP_ACCOUNT_TYPE ) );
                dispatch( uiActions.facebookGroupAddRiplModalClose() );
            }
            else
            {
                alert( "It looks like Ripl hasn't been added to that group, please try again or click on the 'X'" );
            }
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( FacebookGroupAddRiplDialog );
