import * as React from "react";
import { WaveIcon } from "./svgs/WaveIcon";

export const AcceptTestDriveDialog: React.FC<{}> = ( props ) =>
{
    return (
        <div className="acceptTestDriveDialog">
            <WaveIcon/>
            <h2 className="acceptTestDriveDialogTitle">Welcome to Ripl</h2>
            <div>
                Take a sneak peek of our premium features. To unlock sharing, saving, and scheduling your creations, subscribe to Ripl.
            </div>
        </div>
    );
};
