import * as React from "react";
import { generateFontDataForBrandFonts, generateFontDataForFontName, generateFontDataForFontNameOrCssUrl, getFontDisplayName } from "../../helpers";
import { filter, first, head, map, sortBy } from "lodash";
import {
    DesignElementProperties,
    DesignElementProperty,
    EditorDataValue,
    EventData,
    FontData,
    FontTextOption,
    TextPickerCaptionData,
    UserBusiness,
} from "../../_types";
import { DESIGN_ELEMENT_PROPERTY_TYPE_FONT, FontControlConfig } from "../../_types/api";
import { FontPicker } from "./FontPicker";
import classNames from "classnames";

export interface FontPickerTextPropertiesSubPanelProps
{
    captionData: TextPickerCaptionData;
    designElementProperties: DesignElementProperties;
    currentBusiness: UserBusiness;
    fontValue: EditorDataValue;
    fontControl: FontControlConfig;
    isContentContributor: boolean;
}

export interface FontPickerTextPropertiesSubPanelDispatchProps
{
    onMoreFontsClick( captionId: string, type: string, fontPropertyId: string );
    tellDesignToApplyFont( captionId: string, type: string, fontPropertyId: string, option: FontTextOption );
    handleEditFontClicked( font: FontData, controlId: string );
    handleClearFontClicked( font: FontData );
}

export class FontPickerTextPropertiesSubPanel
    extends React.PureComponent<FontPickerTextPropertiesSubPanelProps & FontPickerTextPropertiesSubPanelDispatchProps>
{
    public render()
    {
        const selectedFontData = this.getSelectedFontData();
        const designFontData = this.generateFontDataForDesignFonts( this.props.fontControl as FontControlConfig );
        const brandFonts = generateFontDataForBrandFonts( this.props.currentBusiness );
        const disableEditingBrandFonts: boolean = !this.props.isContentContributor;
        return (
            <div className={classNames( "listPicker", "fontPickerSubpanel" )}>
                <FontPicker
                    selectedFont={selectedFontData}
                    designFonts={designFontData}
                    brandFonts={brandFonts}
                    controlId={this.props.fontControl.id}
                    onFontSelected={this.onFontSelected}
                    onMoreClicked={this.onMoreClicked}
                    disableEditingBrandFonts={disableEditingBrandFonts}
                    onEditFontClicked={this.onEditFontClicked}
                    onClearFontClicked={this.onClearFontClicked}/>
            </div>
        );
    }

    private onMoreClicked = ( e: EventData ) =>
    {
        const fontPropertyData = this.getFontPropertyData();
        this.props.onMoreFontsClick( this.props.captionData.id, fontPropertyData.type, fontPropertyData.id );
    }

    private generateFontDataForDesignFonts( fontControlConfig: FontControlConfig ): FontData[]
    {
        const fonts: string[] = filter( fontControlConfig.fonts );
        const fontData: FontData[] = map( fonts, ( f ) => generateFontDataForFontName( f ) );
        return filter( fontData );
    }

    private onFontSelected = ( selectedFont: FontData ) =>
    {
        const fontPropertyData = this.getFontPropertyData();
        this.props.tellDesignToApplyFont( this.props.captionData.id, fontPropertyData.type, fontPropertyData.id, selectedFont );
    }

    private getFontPropertyData(): DesignElementProperty
    {
        const fontProperties = filter( this.props.designElementProperties.properties,
            ( property ) => property.type === DESIGN_ELEMENT_PROPERTY_TYPE_FONT );
        return first( fontProperties );
    }

    private getSelectedFontData(): FontData
    {
        const selectedFontData = this.getFontPropertyData().selected as FontData;
        if ( getFontDisplayName( selectedFontData ) )
        {
            return selectedFontData;
        }
        else
        {
            return generateFontDataForFontNameOrCssUrl( selectedFontData.designFontName, undefined );
        }
    }

    private onEditFontClicked = ( font: FontData ) =>
    {
        this.props.handleEditFontClicked( font, null );
    }

    private onClearFontClicked = ( font: FontData ) =>
    {
        const selectedFontData = this.getSelectedFontData();
        if ( font.designFontName === selectedFontData.designFontName )
        {
            this.selectFirstValidFont();
        }
        this.props.handleClearFontClicked( font );
    }

    private selectFirstValidFont()
    {
        const designFontData = this.generateFontDataForDesignFonts( this.props.fontControl );
        const sortedFontData = sortBy( designFontData, getFontDisplayName );
        const selectedFont = head( sortedFontData );
        this.onFontSelected( selectedFont );
    }

}
