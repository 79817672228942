import * as React from "react";
import Beforeunload from "react-beforeunload";
import { store } from "../store";
import { NavigationHeader } from "./NavigationHeader";
import CustomizableDesignContainer from "./containers/CustomizableDesign.container";
import DesignSpecificControls from "./containers/DesignSpecificControls.container";
import Measure, { BoundingRect, ContentRect } from "react-measure";
import { EDITOR_TYPES, eventTracker, getCanvasDimensions } from "../helpers";
import ControlPickerContainer from "./containers/ControlPicker.container";
import ZoomControlContainer from "./containers/ZoomControl.container";
import ArtBoardControlContainer from "./containers/ArtBoardControl.container";
import { ErrorBoundary } from "./ErrorBoundary";
import Snackbar from "react-md/lib/Snackbars/SnackbarContainer";
import { getBrandSlideCanvas, getCustomizableCanvas } from "../ducks/ui";
import AudioPlayer from "./containers/AudioPlayer.container";
import { AspectRatio, Design, UIEditorType, UserBusiness, UserState } from "../_types";
import * as TabImage from "../assets/img/pulltab_customize.png";
import classNames from "classnames";
import CustomizeTipBannerContainer from "./containers/CustomizeTipBanner.container";
import { debounce, isEmpty } from "lodash";
import { CustomizeAlertBanner } from "./CustomizeAlertBanner";
import BrandSlideDesignContainer from "./containers/BrandSlideDesign.container";
import * as CollabraPlusRiplLogo from "../assets/img/collabra_plus_ripl_logo_one_line.png";
import SlideSummaryPanelContainer from "./containers/SlideSummaryPanel.container";

export interface CustomizePageProps
{
    currentBusiness: UserBusiness;
    user: UserState;
    showEditor: boolean;
    trayDesigns: Design[];
    editorType: UIEditorType;
    aspectRatio: AspectRatio;
    saving: boolean;
    selectedDesignId: number;
    hasVideoInMediaList: boolean;
    isWaitingToLoad: boolean;
    shouldShowNextButtonCoachMark: boolean;
    hasUsedTrial: boolean;
    shouldShowTipBanner: boolean;
    shouldShowSlideControl: boolean;
    shouldShowLowResImageAlert: boolean;
    shouldShowNavigationDropDownMenu: boolean;
    shouldShowBrandSlideInMixModel: boolean;
    isCurrentBusinessTeamMember: boolean;
    isCollabraUser: boolean;
    historyReferrerIndex: number;
}

export interface CustomizePageDispatchProps
{
    onNextButtonClick();
    onBackButtonClick();
    onPageLoaded();
    onPageUnloaded();
    outputTypePickedSuccess();
    onDeselectAllControls();
    onShowBrandSettingsDialog();
    setFacebookAdModeAndSegueNextPage( facebookAdMode: boolean );
    onBrowserBackButtonClick();
}

interface CustomizePageState
{
    dimensions?: BoundingRect;
    toasts: any[];
}

export default class CustomizePage extends React.PureComponent<CustomizePageProps & CustomizePageDispatchProps, CustomizePageState>
{
    private notifyDesignOfWindowResizeDebounced;

    constructor( props )
    {
        super( props );
        this.state = {
            toasts: [],
        };
        this.notifyDesignOfWindowResizeDebounced = debounce( ( contentRect: ContentRect ) =>
        {
            const canvas = this.isBrandSlideControlSelected() ? getBrandSlideCanvas( store.getState() ) : getCustomizableCanvas( store.getState() );
            if ( canvas )
            {
                canvas.tellDesignHandleWindowResize();
            }
        }, 100 );
    }

    public componentDidMount()
    {
        window.onpopstate = () =>
        {
            this.props.onBrowserBackButtonClick();
        };
        this.props.onPageLoaded();
    }

    public componentWillUnmount()
    {
        this.notifyDesignOfWindowResizeDebounced.cancel();
        this.props.onPageUnloaded();
    }

    public dismissToast = () =>
    {
        const [, ...toasts] = this.state.toasts;
        this.setState( { toasts } );
    }

    public ensureToast = () =>
    {
        if ( this.state.toasts.length === 0 )
        {
            const toasts = [];
            toasts.push( { text: "Saving..." } );

            this.setState( { toasts } );
        }
    }

    public componentDidUpdate()
    {
        if ( this.props.saving )
        {
            this.ensureToast();
        }
    }

    public render()
    {
        return (
            <Beforeunload onBeforeunload={this.onBeforeunload}>
                <div className="customizePage">
                    <ErrorBoundary errorMessage="There was a problem creating the customize screen. Please restart to continue."
                                   showResetButton={true}>
                        <AudioPlayer/>
                        {this.renderHeader()}
                        <div className="page">
                            <DesignSpecificControls/>
                            {
                                this.createControlPicker( this.props )
                            }
                            <div className="editorSpace">
                                {this.props.shouldShowTipBanner && <CustomizeTipBannerContainer/>}
                                {this.props.shouldShowTipBanner && this.props.shouldShowLowResImageAlert && <CustomizeAlertBanner/>}
                                <Measure bounds onResize={this.handleWindowResize}>
                                    {( { measureRef } ) =>
                                    {
                                        return (<div className="designEditorWrap" ref={measureRef}>
                                            {this.props.showEditor && this.createEditor()}
                                        </div>);
                                    }}
                                </Measure>
                                {this.props.shouldShowSlideControl && <SlideSummaryPanelContainer/>}
                            </div>
                        </div>
                        <Snackbar
                            className="saveSnackBar"
                            toasts={this.state.toasts}
                            autohide={true}
                            onDismiss={this.dismissToast}
                            transitionName=""/>
                    </ErrorBoundary>
                </div>
            </Beforeunload>
        );
    }

    private renderHeader()
    {
        if ( this.props.isCollabraUser )
        {
            return (
                <NavigationHeader pageTitleImage={CollabraPlusRiplLogo} {...this.props}/>
            );
        }

        return (
            <NavigationHeader pageTitle="Customize" {...this.props}/>
        );
    }

    private createControlPicker( props )
    {
        const editorTypeClassName = this.getEditorTypeClassName( props.editorType );
        const pickerClassNames = classNames( "sideBar", editorTypeClassName,
            {
                shouldShowSideBar: !!props.editorType,
            } );
        return (
            <div className={pickerClassNames}>
                <ControlPickerContainer/>
                <div className="collapseArrow" onClick={props.onDeselectAllControls}>
                    <img src={TabImage}/>
                    <i className="material-icons">arrow_back_ios</i>
                </div>
            </div>
        );
    }

    private getEditorTypeClassName( editorType )
    {
        if ( !isEmpty( editorType ) && editorType.length > 1 )
        {
            return `controlType${editorType.charAt( 0 ).toUpperCase() + editorType.slice( 1 )}`;
        }
        else
        {
            return null;
        }
    }

    private calculateCanvasWidth( aspectRatio: AspectRatio, dimensions: BoundingRect ): number
    {
        const canvasBounding = getCanvasDimensions( aspectRatio, dimensions );
        if ( canvasBounding )
        {
            return canvasBounding.width;
        }
    }

    private createEditor = () =>
    {
        const canvasWidth = this.calculateCanvasWidth( this.props.aspectRatio, this.state.dimensions );
        return (
            <>
                <div className="designEditor" style={canvasWidth && { width: canvasWidth }}>
                    <ErrorBoundary errorMessage="There was a problem showing this template">
                        {
                            !this.isBrandSlideControlSelected() && <CustomizableDesignContainer/>
                        }
                        {
                            this.isBrandSlideControlSelected() && <BrandSlideDesignContainer className={
                                                                   classNames( { disabled: !this.props.shouldShowBrandSlideInMixModel } )
                                                               }/>
                        }
                    </ErrorBoundary>
                    <ZoomControlContainer/>
                </div>
                <ArtBoardControlContainer/>
            </>

        );
    }

    private onBeforeunload = () =>
    {
        eventTracker.logPostFlowCancelledByBrowserUnload();
    }

    private handleWindowResize = ( contentRect: ContentRect ) =>
    {
        this.setState( { dimensions: contentRect.bounds } );
        this.notifyDesignOfWindowResizeDebounced( contentRect );
    }

    private isBrandSlideControlSelected = () =>
    {
        return this.props.editorType === EDITOR_TYPES.BRAND_SLIDE_CONTROL;
    }
}
