import { connect } from "react-redux";
import { Dispatch } from "redux";
import { store } from "../../store";
import PaymentForm, { PaymentFormDispatchProps, PaymentFormProps } from "../PaymentForm";
import { IN_APP_PURCHASE_COMPLETED_EVENT_NAME, modalServices, pricingServices, subscriptionServices } from "../../services";
import { ReactStripeElements } from "react-stripe-elements";
import {
    eventTracker,
    SUBSCRIPTION_ATTEMPT_FAILED,
    SUBSCRIPTION_FLOW_STEP_COMPLETED,
    SUBSCRIPTION_FLOW_STEP_TRACKING_NAME_PAYMENT_FORM,
    SUBSCRIPTION_SUCCEEDED,
} from "../../helpers";
import { pricingActions, trackingActions, uiActions } from "../../actions";
import { getSelectedPlan } from "../../ducks/pricing";
import { PaymentFormError, StoreState } from "../../_types";
import { getTopMostDialogOnSuccess } from "../../ducks/modals";
import { isCardProcessing } from "../../ducks/ui";
import { getUserCurrencyCode } from "../../ducks/user";
import StripeProps = ReactStripeElements.StripeProps;

interface PaymentFormContainerProps
{
    showGradientVersion: boolean;
}

const mapStateToProps = ( storeState: StoreState ): PaymentFormProps =>
{
    return {
        selectedPlan: getSelectedPlan( storeState ),
        isCardProcessing: isCardProcessing( storeState ),
        selectedCurrencyCode: getUserCurrencyCode( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: PaymentFormContainerProps ): PaymentFormDispatchProps =>
{
    const clearPricingPresets = () =>
    {
        dispatch( pricingActions.updateCouponCode( undefined ) );
        dispatch( pricingActions.updateSrcCode( undefined ) );
        dispatch( pricingActions.updateProductType( undefined ) );
    };

    return {
        onSubscribeWithStripe: ( stripe: StripeProps, sku: string, reCaptchaToken: string ) =>
        {
            dispatch( uiActions.cardProcessingSpinnerEnabled( true ) );
            return stripe.createToken().then( async ( { token, error } ) =>
                {
                    if ( error || !token )
                    {
                        const paymentFormError: PaymentFormError = {
                            message: error.message,
                            showContactSupportLink: false,
                        };

                        dispatch( uiActions.paymentFormErrorSet( paymentFormError ) );
                        dispatch( uiActions.cardProcessingSpinnerEnabled( false ) );
                        eventTracker.logSubscriptionEvent( SUBSCRIPTION_ATTEMPT_FAILED );
                        return;
                    }

                    await clearPricingPresets();

                    try
                    {
                        await dispatch( subscriptionServices.subscribeWithStripe( token.id, sku, reCaptchaToken ) );
                    }
                    catch (error)
                    {
                        eventTracker.logSubscriptionEvent( SUBSCRIPTION_ATTEMPT_FAILED );
                        const paymentFormError: PaymentFormError = {
                            message: "Something went wrong. Please check your card details and try again.",
                            showContactSupportLink: true,
                        };

                        dispatch( uiActions.paymentFormErrorSet( paymentFormError ) );
                        dispatch( uiActions.cardProcessingSpinnerEnabled( false ) );
                        return;
                    }

                    const state = store.getState();
                    const eventProperties = {
                        stepIndex: 2,
                        stepName: SUBSCRIPTION_FLOW_STEP_TRACKING_NAME_PAYMENT_FORM,
                    };
                    eventTracker.logSubscriptionEvent( SUBSCRIPTION_FLOW_STEP_COMPLETED, eventProperties );
                    eventTracker.logSubscriptionEvent( SUBSCRIPTION_SUCCEEDED );
                    eventTracker.sendAirshipCustomEvent( IN_APP_PURCHASE_COMPLETED_EVENT_NAME );
                    eventTracker.logToApptimize( SUBSCRIPTION_SUCCEEDED );
                    eventTracker.reportGooglePurchaseConversion();
                    eventTracker.reportLinkedInPurchaseConversion();
                    const onSuccess = getTopMostDialogOnSuccess( store.getState() );
                    if ( onSuccess )
                    {
                        onSuccess();
                    }
                    await modalServices.closeTopLightBox( dispatch, state );

                    dispatch( pricingServices.showPurchaseConfirmationDialog( ownProps.showGradientVersion, () =>
                    {
                        dispatch( trackingActions.subscriptionFlowEnded() );

                        dispatch( modalServices.showHomeScreenDialogs() );
                    } ) );

                    dispatch( uiActions.cardProcessingSpinnerEnabled( false ) );
                },
                () =>
                {
                    eventTracker.logPaymentFormStripeTokenFailed();
                    const paymentFormError: PaymentFormError = {
                        message: "Unable to process your card. Please try again",
                        showContactSupportLink: true,
                    };

                    dispatch( uiActions.paymentFormErrorSet( paymentFormError ) );
                    eventTracker.logSubscriptionEvent( SUBSCRIPTION_ATTEMPT_FAILED );
                    dispatch( uiActions.cardProcessingSpinnerEnabled( false ) );
                } );
        },
        clearError: () =>
        {
            dispatch( uiActions.paymentFormErrorClear() );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( PaymentForm );
