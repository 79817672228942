import * as React from "react";
import { MouseEvent } from "react";
import { ShareOutputToggle } from "./ShareOutputToggle";
import { ConnectButton } from "./ConnectButton";
import { RefreshButton } from "./RefreshButton";
import ListItemControl from "react-md/lib/Lists/ListItemControl";
import Avatar from "react-md/lib/Avatars/Avatar";
import { DisconnectButton } from "./DisconnectButton";
import {
    ALL_SOCIAL_NETWORK_LABELS_FOR_TYPES,
    apptimizeVariables,
    eventTracker,
    SAVE_TO_COMPUTER_ACCOUNT_LABEL,
    SOCIAL_NETWORKS_FACEBOOK_INSTAGRAM,
    SocialAccountConnectedSource,
    stringUtils,
} from "../helpers";
import { ShareOutputType, SocialNetworkAccountType } from "../_types";
import { hasTeamMemberPermissionsForCurrentBusiness } from "../ducks/userBusiness";
import { store } from "../store";
import { includes, values } from "lodash";
import Tooltipped from "react-md/lib/Tooltips/Tooltipped";

export const ITEM_DISPLAY_MODE_CONNECT = "connect";
export const ITEM_DISPLAY_MODE_REFRESH = "refresh";
export const ITEM_DISPLAY_MODE_TOGGLE = "toggle";
export const ITEM_DISPLAY_MODE_ADD = "add";
export const ITEM_DISPLAY_MODE_DISCONNECT = "disconnect";
export declare type ItemDisplayMode = typeof ITEM_DISPLAY_MODE_CONNECT | typeof ITEM_DISPLAY_MODE_REFRESH |
    typeof ITEM_DISPLAY_MODE_TOGGLE | typeof ITEM_DISPLAY_MODE_ADD | typeof ITEM_DISPLAY_MODE_DISCONNECT;

// TODO Move unused fields to ShareOutputItem or ShareOutputDisplayProps
export interface ShareOutputListItemProps
{
    accountId: number;
    accountType: ShareOutputType;
    accountLabel: string;
    accountConnected: boolean;
    accountAccessible: boolean;
    accountEnabled: boolean;
    profileId: string;
    profileImageUrl: string;
    shareOutputIcon: string;
    connectedOutputIcon: string;
    displayMode: ItemDisplayMode;
    trackingSource?: SocialAccountConnectedSource;
}

export interface ShareOutputListItemDispatchProps
{
    onSocialAccountConnectRequested: ( accountType: SocialNetworkAccountType ) => void;
    onShareOutputEnableChanged?: ( accountType: ShareOutputType, accountId: number, accountEnabled: boolean ) => void;
    onSocialAccountRefreshRequested: ( accountType: SocialNetworkAccountType, accountId: number ) => void;
    onSocialAccountDisconnectRequested?: ( accountType: SocialNetworkAccountType, accountId: number, profileId: string ) => void;
    showConnectDisabledAlert?: () => void;
}

export class ShareOutputListItem extends React.PureComponent<ShareOutputListItemProps & ShareOutputListItemDispatchProps>
{
    public render()
    {
        return (
            <Tooltipped label={this.getTooltip()}  position="top" tooltipClassName="tooltip">
                <div className="socialAccountListItemWrap">
                    <ListItemControl className="socialAccountListItem"
                             leftAvatar={this.makeAccountIcon()}
                             secondaryAction={this.determineControlToDisplay( this.props )}/>
                </div>
            </Tooltipped>
        );
    }

    private determineControlToDisplay = ( props: ShareOutputListItemProps & ShareOutputListItemDispatchProps ) =>
    {
        const accountType = props.accountType as SocialNetworkAccountType;
        if ( hasTeamMemberPermissionsForCurrentBusiness( store.getState() ) && props.displayMode !== ITEM_DISPLAY_MODE_TOGGLE )
        {
            return <></>;
        }

        switch ( props.displayMode )
        {
            case ITEM_DISPLAY_MODE_CONNECT:
                return (<ConnectButton
                    label={props.accountLabel}
                    className="connectBtn"
                    accountType={accountType}
                    trackingSource={props.trackingSource}
                    showInstagramSetupModalFirst={true}
                    onClick={this.handleAccountConnectRequested}
                />);
            case ITEM_DISPLAY_MODE_REFRESH:
                return (<RefreshButton
                    label={props.accountLabel}
                    className="refreshBtn"
                    accountType={accountType}
                    accountId={this.props.accountId}
                    onClick={this.handleAccountRefreshRequested}
                />);
            case ITEM_DISPLAY_MODE_TOGGLE:
                const id = props.accountType + (props.accountId ? `-${props.accountId}` : "");
                return (<ShareOutputToggle
                    label={props.accountLabel}
                    className="shareToggle"
                    id={id}
                    enabled={this.allowEnablingShareToAccount() && props.accountEnabled}
                    togglingDisabled={this.shouldTogglingSharingToAccountBeDisabled()}
                    onChange={this.handleAccountEnableChanged}
                    />);
            case ITEM_DISPLAY_MODE_ADD:
                return (<ConnectButton
                    label={props.accountLabel}
                    className="addBtn"
                    buttonText="Add"
                    accountType={accountType}
                    trackingSource={props.trackingSource}
                    showInstagramSetupModalFirst={false}
                    onClick={this.handleAccountConnectRequested}
                />);
            case ITEM_DISPLAY_MODE_DISCONNECT:
                return (<DisconnectButton
                    label={props.accountLabel}
                    className="disconnectBtn"
                    accountType={accountType}
                    onClick={this.handleAccountDisconnectRequested}
                />);
        }
    }

    private handleAccountEnableChanged = ( result: boolean | number | string, event: Event ) =>
    {
        if ( !this.shouldEnableConnect() )
        {
            this.props.showConnectDisabledAlert();
            return;
        }
        this.props.onShareOutputEnableChanged( this.props.accountType, this.props.accountId, !!result );
    }

    private handleAccountConnectRequested = ( event: MouseEvent<HTMLElement> ) =>
    {
        eventTracker.logSocialNetworkConnectClicked( this.props.accountType as SocialNetworkAccountType );

        this.props.onSocialAccountConnectRequested( this.props.accountType as SocialNetworkAccountType );
    }

    private handleAccountDisconnectRequested = ( event: MouseEvent<HTMLElement> ) =>
    {
        this.props.onSocialAccountDisconnectRequested( this.props.accountType as SocialNetworkAccountType,
            this.props.accountId,
            this.props.profileId );
    }

    private handleAccountRefreshRequested = ( event: MouseEvent<HTMLElement> ) =>
    {
        this.props.onSocialAccountRefreshRequested( this.props.accountType as SocialNetworkAccountType, this.props.accountId );
    }

    private makeAccountIcon = () =>
    {
        if ( this.props.profileImageUrl )
        {
            const profileImageUrl = stringUtils.changeHttpUrlToHttps( this.props.profileImageUrl );
            return (
                <div className="accountIcon">
                    <Avatar src={profileImageUrl} role="presentation"/>
                    <img src={this.props.connectedOutputIcon} className="network"/>
                </div>
            );
        }
        return <Avatar src={this.props.shareOutputIcon} role="presentation" className={this.props.accountType}/>;
    }

    private isFacebookSocialNetwork()
    {
        return includes( SOCIAL_NETWORKS_FACEBOOK_INSTAGRAM, this.props.accountType );
    }

    private allowEnablingShareToAccount()
    {
        if ( this.isFacebookSocialNetwork() )
        {
            return !this.shouldDisableFacebookShare();
        }
        return true;
    }

    private shouldTogglingSharingToAccountBeDisabled()
    {
        if ( this.isFacebookSocialNetwork() )
        {
            return this.shouldDisableFacebookShare();
        }
        return false;
    }

    private shouldDisableFacebookShare()
    {
        return apptimizeVariables.shouldDisableFacebookShare();
    }

    private shouldEnableConnect()
    {
        if ( this.isFacebookSocialNetwork() )
        {
            return !apptimizeVariables.shouldDisableFacebookConnect();
        }
        return true;
    }

    private getTooltip()
    {
        if (!this.isDefaultShareLabel(this.props.accountLabel) )
        {
            return this.props.accountLabel;
        }
        return null;
    }

    private isDefaultShareLabel(shareLabel: string)
    {
        return shareLabel === SAVE_TO_COMPUTER_ACCOUNT_LABEL ||
               includes(values(ALL_SOCIAL_NETWORK_LABELS_FOR_TYPES), shareLabel);
    }
}
