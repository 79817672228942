import * as React from "react";
import classNames from "classnames";
interface SavingsBadgeProps
{
    renderForWideFormat?: boolean;
}
export class SavingsBadge extends React.PureComponent<SavingsBadgeProps>
{
    public render()
    {
        return (
            <div className={classNames( "savingsBadge testDrive", { renderForWideFormat: this.props.renderForWideFormat } )}>
                <div className={classNames( "savingsOuterCircle", { renderForWideFormat: this.props.renderForWideFormat } )}/>
                <div className={classNames( "savingsCallout", { renderForWideFormat: this.props.renderForWideFormat } )}>
                    <div className={classNames( "savingsInnerWrap", { renderForWideFormat: this.props.renderForWideFormat } )}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}
