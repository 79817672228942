import { StoreState } from "../../_types";
import { connect } from "react-redux";
import { getSelectedManageBusinessLogos } from "../../ducks/ui";
import { ConfirmDeleteLogosDialog, ConfirmDeleteLogosDialogProps } from "../ConfirmDeleteLogosDialog";

const mapStateToProps = ( storeState: StoreState ): ConfirmDeleteLogosDialogProps =>
{
    return {
        selectedLogos: getSelectedManageBusinessLogos(storeState),
    };
};

export default connect(
    mapStateToProps,
)( ConfirmDeleteLogosDialog );
