import { connect } from "react-redux";
import { Dispatch } from "redux";
import { store } from "../../store";
import { getEditorControl, getEditorControlValue, getEditorType, getFontAutocompleteData, isProcessRunning, } from "../../ducks/ui";
import { ControlPicker, ControlPickerDispatchProps, ControlPickerProps } from "../ControlPicker";
import { mixModelActions, uiActions } from "../../actions";
import { getDesignAspectRatio, getInitialControlDataForCurrentSelectedDesign } from "../../ducks/mixModel";
import {
    CHOOSE_A_FONT_TITLE,
    eventTracker,
    getFontCssUrlFieldForBrand,
    getFontFieldForBrand,
    getFontUrlFieldForBrand,
    LightboxDialogIdentifierForKey,
    openBrandFontPicker,
} from "../../helpers";
import { modalServices, userBusinessServices } from "../../services";
import MoreFontsDialogContainer, { MORE_FONTS_DIALOG_CLASS_NAME } from "../editorControls/containers/MoreFontsDialog.container";
import { AspectRatio, ControlDataValue, FontData, StoreState } from "../../_types";
import { getCurrentBusiness } from "../../ducks/userBusiness";
import { CUSTOMIZABLE_DESIGN_ID } from "./CustomizableDesign.container";

const mapStateToProps = ( storeState: StoreState ): ControlPickerProps =>
{
    return {
        value: getEditorControlValue( storeState ),
        control: getEditorControl( storeState ),
        editorType: getEditorType( storeState ),
        aspectRatio: getDesignAspectRatio( storeState ),
        currentBusiness: getCurrentBusiness( storeState ),
        initialControlDataForCurrentSelectedDesign: getInitialControlDataForCurrentSelectedDesign( storeState ),
        isDesignLoading: isProcessRunning( storeState, CUSTOMIZABLE_DESIGN_ID ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): ControlPickerDispatchProps =>
{
    return {
        onValueSelected: ( controlId: string, value: ControlDataValue ) =>
        {
            dispatch( mixModelActions.controlDataUpdated( { [controlId]: value } ) );
        },
        onAspectRatioSelected: ( aspectRatio: AspectRatio ) =>
        {
            dispatch( mixModelActions.designAspectRatioSelected( aspectRatio ) );
        },
        onMoreFontsClick: async ( controlId: string ) =>
        {
            eventTracker.logMoreFontsTapped();
            const value = getEditorControlValue( store.getState() );
            await openChooseFont( dispatch, controlId, value as FontData );
        },
        onShowBrandSettingsDialog: ( source: string ) =>
        {
            dispatch( modalServices.showBrandSettings( source ) );
        },
        handleEditFontClicked: async ( font: FontData, controlId: string ) =>
        {
            await openChooseFontAndSetBusinessFont( dispatch, font, controlId );
        },
        handleClearFontClicked: async ( font: FontData ) =>
        {
            await dispatch( uiActions.updateBusinessInfo( {
                [getFontFieldForBrand( font.type )]: null,
                [getFontUrlFieldForBrand( font.type )]: null,
                [getFontCssUrlFieldForBrand( font.type )]: null,
            } ) );
            await dispatch( userBusinessServices.update( {
                [getFontFieldForBrand( font.type )]: null,
                [getFontUrlFieldForBrand( font.type )]: null,
                [getFontCssUrlFieldForBrand( font.type )]: null,
            } ) );
        },
    };
};

async function openChooseFont( dispatch: Dispatch<StoreState>, controlId: string, font: FontData )
{
    await dispatch( uiActions.updateFontFontAutocomplete( font ) );
    dispatch( modalServices.openLightbox( {
        identifierForKey: LightboxDialogIdentifierForKey.CHOOSE_CUSTOM_FONT,
        title: CHOOSE_A_FONT_TITLE,
        showCancelX: true,
        hideCancel: true,
        className: MORE_FONTS_DIALOG_CLASS_NAME,
        content: MoreFontsDialogContainer,
        width: 500,
        onSuccess: () =>
        {
            const selectedFont = getFontAutocompleteData( store.getState() ).font;
            if ( selectedFont )
            {
                dispatch( mixModelActions.controlDataUpdated( { [controlId]: selectedFont } ) );
                eventTracker.logFontSelectedFromMoreFonts( selectedFont );
            }
        },
    } ) );
}

async function openChooseFontAndSetBusinessFont( dispatch: Dispatch<StoreState>, font: FontData, controlId: string )
{
    await dispatch( uiActions.updateFontFontAutocomplete( font ) );
    openBrandFontPicker( dispatch, font.type, controlId );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( ControlPicker );
