import { Dispatch } from "redux";
import { musicCatalogActions } from "../actions";
import { normalizeMusicAPI, requests } from "../helpers";
import { StoreState } from "../_types";

export const musicCatalogServices = {
    load,
};

const MUSIC_URL = "/musics";

function load()
{
    return async ( dispatch: Dispatch<StoreState> ) =>
    {
        try
        {
            const data = await requests.get<MusicAPI[]>( MUSIC_URL );
            const normalizedData = normalizeMusicAPI( data );
            dispatch( musicCatalogActions.loadCatalogSuccess( normalizedData ) );
        }
        catch (error)
        {
            dispatch( musicCatalogActions.loadCatalogFailure( error ) );
        }
    };
}
