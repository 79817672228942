import * as React from "react";
import { includes, isEqual, map, remove } from "lodash";
import { BrandLogo } from "../_types";
import ManageBrandLogoContainer from "./settingsPages/containers/ManageBrandLogo.container";

export interface ManageLogosDialogProps
{
    logos: BrandLogo[];
    selectedLogos: BrandLogo[];
}

export interface ManageLogosDialogDispatchProps
{
    onSelectionChange: ( selectedLogoIds: BrandLogo[] ) => void;
}

export class ManageLogosDialog extends React.PureComponent<ManageLogosDialogProps & ManageLogosDialogDispatchProps>
{
    public render()
    {
        return (
            <div className="manageLogosDialogContent">
                <div className="logosWrap">
                    {
                        map( this.props.logos, ( logo: BrandLogo ) =>
                        {
                            const isSelected = includes( this.props.selectedLogos, logo );
                            return <ManageBrandLogoContainer key={logo.id}
                                                             brandLogo={logo}
                                                             isSelected={isSelected}
                                                             onLogoClick={this.onLogoClick}/>;
                        } )
                    }
                </div>
            </div>
        );
    }

    private onLogoClick = ( brandLogo: BrandLogo ) =>
    {
        const newLogosSelection = [...this.props.selectedLogos];
        const isCurrentlySelected = includes( newLogosSelection, brandLogo );

        if ( isCurrentlySelected )
        {
            remove( newLogosSelection, ( item ) => isEqual( item, brandLogo ) );
        }
        else
        {
            newLogosSelection.push( brandLogo );
        }
        this.props.onSelectionChange( newLogosSelection );
    }
}
