import { connect } from "react-redux";
import { Dispatch, Unsubscribe } from "redux";
import * as React from "react";
import { store } from "../../store";
import { forEach, head, throttle } from "lodash";
import { getCurrentBusiness, hasTeamMemberPermissionsForCurrentBusiness } from "../../ducks/userBusiness";
import {
    apptimizeVariables,
    COLLECTION_PAGE_URL,
    customizationHelper,
    CUSTOMIZE_PAGE_NO_SNAPHOT_CANVAS,
    EDITOR_TYPES,
    ERROR_TITLE_OOPS,
    errorReporting,
    eventTracker,
    history,
    HOMEPAGE_URL,
    LightboxDialogIdentifierForKey,
    SHAREPAGE_URL,
    SQUARE_ASPECT_RATIO,
    stringUtils,
    SUBSCRIPTION_FLOW_SOURCE_CUSTOMIZE_NEXT_BUTTON,
    webFontLoader,
} from "../../helpers";
import { modalServices, NO_LONGER_DRAFT_ERROR_MESSAGE, postServices, upsellServices, userBusinessServices } from "../../services";
import CustomizePage, { CustomizePageDispatchProps, CustomizePageProps } from "../CustomizePage";
import { getControlsConfigsLookup } from "../../ducks/designs";
import {
    getCustomizableCanvas,
    getEditorType,
    isBrandSlideTabSelected,
    isBusinessUpdateInProgress,
    isPostUpdateInProgress,
    isStockMediaUploading,
    shouldCurrentControlShowSlideSummaryPanel,
    shouldShowTipBannersForPost,
} from "../../ducks/ui";
import {
    catalogActions,
    ImageUploadData,
    mixModelActions,
    modalActions,
    shareModelActions,
    stockMediaActions,
    trackingActions,
    uiActions,
} from "../../actions";
import {
    doesSelectedDesignSupportHD,
    getDesignAspectRatio,
    getMediaUrlList,
    getOriginalTrimmerData,
    getPostId,
    getSelectedDesignId,
    getShowBrandSlideFromMixModel,
    getStartingDesignId,
    getVideoDurationSeconds,
    getVideoUrlsFromMediaUrlList,
    hasMusic,
    hasVideoDurationMetadata,
    hasVideoInMediaList,
    isAnyImageLowRes,
    isCurrentDesignFoundation,
    isMediaUploading,
    isStaticOutputMode,
    shouldUpdateServer,
    shouldUpdateServerForBrandSlide,
    updateMixModelAfterOutputMediaUpload,
} from "../../ducks/mixModel";
import { v4 as uuid } from "uuid";
import { getDesignTray } from "../../ducks/catalog";
import { getSessionIdForPostCustomization } from "../../ducks/tracking";
// Note: blueimp-canvas-to-blob applies a polyfill for canvas.toBlob(), which is used in ImagePicker.container. You can find more info here:
// https://github.com/blueimp/JavaScript-Canvas-to-Blob
import * as dataURLtoBlob from "blueimp-canvas-to-blob";
import {
    canUserAccessPaidFeatures,
    getAllFacebookAndFacebookInstagramTypeSocialNetworkAccounts,
    isCollabraUser,
    isUserLoggedIn,
} from "../../ducks/user";
import { MediaData, MixModelState, StoreState } from "../../_types";
import { AddMediaAPIResponse, ANIMATION_OUTPUT_TYPE, HD_ANIMATION_OUTPUT_TYPE, IMAGE_OUTPUT_TYPE, PHOTO_OUTPUT_TYPE } from "../../_types/api";
import CircularProgress from "react-md/lib/Progress/CircularProgress";
import { getCollectionSlug } from "../../ducks/collections";
import { hasUsedTrial } from "../../ducks/pricing";
import { videoTrimmerServices } from "../../services/videoTrimmer.services";
import { designFrameServices } from "../../services/designFrame.services";
import { epidemicSoundCatalogServices } from "../../services/epidemicSoundCatalog.services";
import { getHistoryReferrerIndex } from "../../ducks/partner";

const mapStateToProps = ( storeState: StoreState ): CustomizePageProps =>
{
    const currentBusiness = getCurrentBusiness( storeState );
    const postId = getPostId( storeState );
    return {
        currentBusiness,
        user: storeState.user,
        trayDesigns: getDesignTray( storeState ),
        showEditor: !!getSelectedDesignId( storeState ),
        editorType: getEditorType( storeState ),
        aspectRatio: getDesignAspectRatio( storeState ),
        saving: isPostUpdateInProgress( storeState ) || isBusinessUpdateInProgress( storeState ),
        selectedDesignId: getSelectedDesignId( storeState ),
        hasVideoInMediaList: hasVideoInMediaList( storeState ),
        isWaitingToLoad: isMediaUploading( storeState ) || isStockMediaUploading( storeState ),
        shouldShowNextButtonCoachMark: !canUserAccessPaidFeatures( storeState ),
        hasUsedTrial: hasUsedTrial( storeState ),
        shouldShowLowResImageAlert: isAnyImageLowRes( storeState ),
        shouldShowTipBanner: shouldShowTipBannersForPost( storeState, postId ),
        shouldShowSlideControl: apptimizeVariables.shouldEnableDynamicSlides()
                                && isCurrentDesignFoundation( storeState )
                                && shouldCurrentControlShowSlideSummaryPanel( storeState ),
        shouldShowNavigationDropDownMenu: false,
        shouldShowBrandSlideInMixModel: getShowBrandSlideFromMixModel( storeState ),
        isCurrentBusinessTeamMember: hasTeamMemberPermissionsForCurrentBusiness( storeState ),
        isCollabraUser: isCollabraUser( storeState ),
        historyReferrerIndex: getHistoryReferrerIndex( storeState ),
    };
};

interface AutoSaveData
{
    lastSavedMixModel?: MixModelState;
    storeListenerUnsubscriber?: Unsubscribe;
}

const AUTO_SAVE_DELAY_MS = 10000;
const autoSaveData: AutoSaveData = {};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): CustomizePageDispatchProps =>
{
    const onNextButtonClick = async () =>
    {
        const state = store.getState();

        dispatch( uiActions.clearAudioPlayerMusicTrack() );
        dispatch( uiActions.replaceMediaModeEnded() );

        if ( isBrandSlideTabSelected( state ) )
        {
            dispatch( userBusinessServices.updateBrandSlide() );
            dispatch( uiActions.controlEditorSwitched( { editorType: EDITOR_TYPES.TEMPLATES_CONTROL } ) );
        }
        else if ( !canUserAccessPaidFeatures( state ) )
        {
            showProOnlyLockoutDialog( dispatch );
            return;
        }
        else
        {
            eventTracker.logCustomizeNextTappedEvent();
            eventTracker.logOutputChoiceShownEvent();

            if ( doesSelectedDesignSupportHD( state ) )
            {
                await dispatch( shareModelActions.outputTypeUpdated( HD_ANIMATION_OUTPUT_TYPE ) );
            }
            else
            {
                await dispatch( shareModelActions.outputTypeUpdated( ANIMATION_OUTPUT_TYPE ) );
            }

            if ( !doesSelectedDesignSupportHD( state ) && hasMusic( state ) )
            {
                await dispatch( shareModelActions.outputTypeUpdated( ANIMATION_OUTPUT_TYPE ) );
                onOutputTypePickedSuccess();
            }
            else if ( isStaticOutputMode( state ) )
            {
                await dispatch( shareModelActions.outputTypeUpdated( IMAGE_OUTPUT_TYPE ) );
                onOutputTypePickedSuccess();
            }
            else
            {
                await dispatch( shareModelActions.outputTypeUpdated( HD_ANIMATION_OUTPUT_TYPE ) );
                onOutputTypePickedSuccess();
            }
        }
    };

    return {
        onBackButtonClick: () =>
        {
            const state = store.getState();
            eventTracker.logPostFlowBackSelectedEvent();
            if ( isCollabraUser( state ) )
            {
                const referrerPageLocationInHistory = getHistoryReferrerIndex( state ) - history.length;
                history.go( referrerPageLocationInHistory );
            }
            else if ( isBrandSlideTabSelected( state ) )
            {
                dispatch( userBusinessServices.updateBrandSlide() );
                dispatch( uiActions.controlEditorSwitched( { editorType: EDITOR_TYPES.TEMPLATES_CONTROL } ) );
            }
            else
            {
                dispatch( modalServices.openLightbox( {
                    identifierForKey: LightboxDialogIdentifierForKey.SAVE_DRAFT,
                    title: "Save Draft",
                    subtitle: "We will save it to your Drafts section in the My posts screen to edit and share later.",
                    content: null,
                    hideAlternateAction: false,
                    hideCancel: true,
                    showCancelX: true,
                    alternateActionLabel: "Discard",
                    confirmLabel: "Save",
                    width: 450,
                    onSuccess: async () =>
                    {
                        await modalServices.encloseInLoadingDialog( async () =>
                        {
                            dispatch( catalogActions.addDesignToFrontOfTray( getSelectedDesignId( state ) ) );
                            await saveDraftAndReloadDrafts( dispatch );
                            cleanupAndRedirectToPreviousPage();
                        } );
                    },
                    onAlternate: async () =>
                    {
                        // TODO Extract this to a named function for better readability
                        try
                        {
                            await dispatch( postServices.deleteDraft( getPostId( state ) ) );
                        }
                        catch (error)
                        {
                            errorReporting.reportErrorToSentry( error );
                            dispatch( modalServices.openErrorDialogWithStandardFormat(
                                "Failed to discard draft",
                                "Try again later." ) );
                        }

                        cleanupAndRedirectToPreviousPage();
                    },
                } ) );
            }
        },
        setFacebookAdModeAndSegueNextPage: async ( facebookAdMode: boolean ) =>
        {
            await dispatch( mixModelActions.facebookAdModeSet( facebookAdMode ) );
            await onNextButtonClick();
        },
        onNextButtonClick,
        onPageLoaded: () =>
        {
            const state = store.getState();
            if ( !isUserLoggedIn( state ) )
            {
                return;
            }

            const currentBusiness = getCurrentBusiness( state );
            if ( currentBusiness )
            {
                webFontLoader.loadCustomFontIfSpecified( currentBusiness.primary_font_css_url );
                webFontLoader.loadCustomFontIfSpecified( currentBusiness.secondary_font_css_url );
            }

            if ( !getSessionIdForPostCustomization( state ) )
            {
                const postSessionId = uuid();
                dispatch( trackingActions.postSessionStarted( postSessionId ) );

                eventTracker.setUserPropertiesForCurrentUser();
                eventTracker.logCustomizedPostStartedEvent();

                dispatch( stockMediaActions.searchResultsCleared() );
            }

            dispatch( uiActions.replaceMediaModeEnded() );

            const design = head( getDesignTray( state ) );
            if ( design && !getSelectedDesignId( state ) )
            {
                dispatch( mixModelActions.designSelected( { design, aspectRatio: SQUARE_ASPECT_RATIO } ) );

                if ( getEditorType( state ) !== EDITOR_TYPES.MEDIA_CONTROL )
                {
                    dispatch( uiActions.controlEditorSwitched( { editorType: EDITOR_TYPES.MEDIA_CONTROL } ) );
                }
            }
            else if ( getEditorType( state ) !== EDITOR_TYPES.MEDIA_CONTROL )
            {
                dispatch( uiActions.controlEditorSwitched( { editorType: EDITOR_TYPES.MEDIA_CONTROL } ) );
            }

            if ( design && !getStartingDesignId( state ) )
            {
                dispatch( mixModelActions.startingDesignSet( design ) );
            }

            if ( !getPostId( state ) )
            {
                const controlsConfigsLookup = getControlsConfigsLookup( state );
                const { user } = state;
                if ( apptimizeVariables.shouldDisableFacebookShare() )
                {
                    const facebookAndInstagramSocialNetworkAccounts = getAllFacebookAndFacebookInstagramTypeSocialNetworkAccounts( state );
                    dispatch( shareModelActions.facebookInstagramSocialNetworksDisabled( { facebookAndInstagramSocialNetworkAccounts } ) );
                }
                dispatch( mixModelActions.mixModelStarted( {
                    currentBusiness,
                    controlsConfigsLookup,
                    user,
                } ) );
                dispatch( postServices.createDraft() );
            }

            buildMissingTrimmerData( dispatch, state );

            dispatch( uiActions.shouldShowDefaultStockMediaSearchResultsUpdated( true ) );
            setupAutoSaveListeners();
            dispatch( designFrameServices.loadDesignFrames() );

            dispatch( epidemicSoundCatalogServices.refreshCurrentMixMusicIfEpidemicSoundTrack() );
        },
        onPageUnloaded: () =>
        {
            removeAutoSaveListeners();
        },
        onDeselectAllControls: () =>
        {
            dispatch( uiActions.deselectAllControls() );
        },
        outputTypePickedSuccess: onOutputTypePickedSuccess,
        onShowBrandSettingsDialog: () =>
        {
            dispatch( modalServices.showBrandSettings( "brand button" ) );
        },
        onBrowserBackButtonClick: () =>
        {
            const state = store.getState();
            modalServices.closeLightBoxesWithIdentifier( dispatch, state, LightboxDialogIdentifierForKey.REPLACE_MEDIA_PICKER );
        },
    };
};

function buildMissingTrimmerData( dispatch: Dispatch<StoreState>, state: StoreState )
{
    const mediaUrlList = getMediaUrlList( state );
    const videoUrls = getVideoUrlsFromMediaUrlList( mediaUrlList );

    forEach( videoUrls, ( videoUrl ) =>
    {
        const mediaId = stringUtils.getFileName( videoUrl );
        const trimmerData = getOriginalTrimmerData( state, mediaId );
        const didNotFindExistingTrimmerData = trimmerData.url === null;

        if ( didNotFindExistingTrimmerData )
        {
            if ( hasVideoDurationMetadata( state, mediaId ) )
            {
                const videoDurationSeconds = getVideoDurationSeconds( state, mediaId );
                videoTrimmerServices.setDefaultTrimmerData( dispatch, videoUrl, videoDurationSeconds );
            }
            else
            {
                makeVideoDomElementAndExtractMetadata( dispatch, videoUrl );
            }
        }
    } );
}

function makeVideoDomElementAndExtractMetadata( dispatch: Dispatch<StoreState>, videoUrl: string )
{
    const video = document.createElement( "video" );
    video.preload = "metadata";
    video.onloadedmetadata = () =>
    {
        videoTrimmerServices.setDefaultTrimmerData( dispatch, videoUrl, video.duration );
    };

    video.src = videoUrl;
}

async function saveDraftAndReloadDrafts( dispatch: Dispatch<StoreState> )
{
    try
    {
        await saveDraft();

        const state = store.getState();
        const currentBusiness = getCurrentBusiness( state );
        await dispatch( postServices.loadDraftPosts( currentBusiness ) );
    }
    catch (error)
    {
        errorReporting.reportErrorToSentry( error );
    }
}

async function saveDraft()
{
    eventTracker.logSaveToDraftStarted();
    try
    {
        const snapShotDataUrl: string = await getSnapshotDataUrl();
        await sendSnapshotToServer( snapShotDataUrl );
        await store.dispatch( postServices.updatePostWithData() );
    }
    catch (error)
    {
        if ( error === NO_LONGER_DRAFT_ERROR_MESSAGE )
        {
            store.dispatch( modalServices.openNotADraftDialog() );
            history.replace( HOMEPAGE_URL );
        }
        else
        {
            errorReporting.reportErrorToSentry( error );
            store.dispatch( modalServices.openErrorDialog( ERROR_TITLE_OOPS, "There was a problem saving your draft." ) );
        }
    }
}

function getRedirectUrl()
{
    let redirectUrl = HOMEPAGE_URL;

    const state = store.getState();
    const collectionSlug = getCollectionSlug( state );
    if ( !!collectionSlug )
    {
        redirectUrl = COLLECTION_PAGE_URL;
    }
    return redirectUrl;
}

function redirectToBestPreviousPage()
{
    const redirectUrl = getRedirectUrl();
    history.replace( redirectUrl );
}

function cleanupAndRedirectToPreviousPage()
{
    eventTracker.logPostFlowCancelledByUser();
    customizationHelper.cleanupCustomizeState();
    redirectToBestPreviousPage();
}

async function getSnapshotDataUrl(): Promise<any>
{
    const state = store.getState();
    const canvas = getCustomizableCanvas( state );
    if ( !canvas )
    {
        throw new Error( CUSTOMIZE_PAGE_NO_SNAPHOT_CANVAS );
    }

    await canvas.tellDesignToSaveSettings();
    return await canvas.getSnapshot();
}

function onOutputTypePickedSuccess()
{
    store.dispatch( modalServices.openLoadingDialog( {
        content: () => <CircularProgress className="spinner large" id="spinner"/>,
        className: "fullPageLoading",
        onShow: async () =>
        {
            try
            {
                const snapShotDataUrl: string = await getSnapshotDataUrl();
                await sendSnapshotToServer( snapShotDataUrl );
                await store.dispatch( postServices.updatePostWithData() );
                eventTracker.logShareViewOpened();
                history.replace( SHAREPAGE_URL );
            }
            catch (error)
            {
                if ( error === NO_LONGER_DRAFT_ERROR_MESSAGE )
                {
                    store.dispatch( modalServices.openNotADraftDialog() );
                    history.replace( HOMEPAGE_URL );
                }
                else
                {
                    errorReporting.reportErrorToSentry( error );
                    store.dispatch( modalServices.openErrorDialog( ERROR_TITLE_OOPS,
                        "There was a problem saving your post. Please email feedback@ripl.com if you continue to have trouble." ) );
                }
            }
                // tslint:disable-next-line:one-line
            finally
            {
                store.dispatch( modalActions.loadingDialogClose() );
            }
        },
    } ) );
}

function sendSnapshotToServer( aSnapshotDataUrl: string ): Promise<any>
{
    const blob = dataURLtoBlob( aSnapshotDataUrl );
    const photoMediaData: MediaData = {
        filename: "postPhoto.png",
        file: blob,
        fileSize: blob && blob.size,
        type: PHOTO_OUTPUT_TYPE,
    };
    return store.dispatch( postServices.uploadPostMediaToS3( photoMediaData, afterUploadOutputImage ) );
}

function afterUploadOutputImage( dispatch, data: AddMediaAPIResponse, mediaData: MediaData )
{
    if ( data )
    {
        const newData: ImageUploadData = {
            uploadFields: data,
            isLowRes: mediaData.isLowRes,
            fileSize: mediaData.fileSize,
        };
        updateMixModelAfterOutputMediaUpload( dispatch, newData );
    }
}

function setupAutoSaveListeners()
{
    autoSaveData.lastSavedMixModel = store.getState().mixModel;
    autoSaveData.storeListenerUnsubscriber = store.subscribe( throttle( autoSave, AUTO_SAVE_DELAY_MS ) );
}

function autoSave()
{
    const state = store.getState();
    const postId = getPostId( state );
    if ( postId && autoSaveData.storeListenerUnsubscriber )
    {
        const isBrandSlideSelected = isBrandSlideTabSelected( state );
        if ( isBrandSlideSelected
             && shouldUpdateServerForBrandSlide( state, autoSaveData.lastSavedMixModel ) )
        {
            autoSaveData.lastSavedMixModel = state.mixModel;
            store.dispatch( userBusinessServices.updateBrandSlide() );
        }
        else if ( !isBrandSlideSelected
                  && shouldUpdateServer( state, autoSaveData.lastSavedMixModel, true ) )
        {
            autoSaveData.lastSavedMixModel = state.mixModel;
            store.dispatch( postServices.updatePostWithData() ).catch( ( error: any ) =>
                {
                    if ( error === NO_LONGER_DRAFT_ERROR_MESSAGE )
                    {
                        store.dispatch( modalServices.openNotADraftDialog() );
                        history.replace( HOMEPAGE_URL );
                    }
                },
            );
        }
    }
}

function removeAutoSaveListeners()
{
    const { storeListenerUnsubscriber } = autoSaveData;
    if ( storeListenerUnsubscriber )
    {
        storeListenerUnsubscriber();
        autoSaveData.storeListenerUnsubscriber = undefined;
    }
}

function showProOnlyLockoutDialog( dispatch: Dispatch<StoreState> )
{
    dispatch( upsellServices.displayTryProNoWarmup( SUBSCRIPTION_FLOW_SOURCE_CUSTOMIZE_NEXT_BUTTON ) );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( CustomizePage );
