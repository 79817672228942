import * as React from "react";

export const HomeIcon: React.FC<{}> = () =>
{
    return (
        /* tslint:disable:max-line-length */
        // @ts-ignore
        <svg className="newNavStackedIcon home" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 61">
            <polygon
                points="40.28 51.35 40.28 35.48 21.21 35.48 21.21 51.35 21.21 58 2.29 58 2.29 20.66 30 2 57.7 20.66 57.7 58 40.28 58 40.28 51.35"/>
        </svg>
    );
};
