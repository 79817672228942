import { connect } from "react-redux";
import { Dispatch } from "redux";
import { uiActions } from "../../../actions";
import { getFonts } from "../../../ducks/fonts";
import { FontData, StoreState } from "../../../_types";
import { getFontAutocompleteData } from "../../../ducks/ui";
import { MoreFontsDialog, MoreFontsDialogDispatchProps, MoreFontsDialogProps } from "../../editorControls/MoreFontsDialog";

const mapStateToProps = ( storeState: StoreState ): MoreFontsDialogProps =>
{
    return {
        fonts: getFonts( storeState ),
        currentFont: getFontAutocompleteData( storeState ).font,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): MoreFontsDialogDispatchProps =>
{
    return {
        updateFontSelection: ( font: FontData ) =>
        {
            dispatch( uiActions.updateFontFontAutocomplete( font ) );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( MoreFontsDialog );
