import * as React from "react";

const ENABLE_UNNECESSARY_REACT_RENDER_LOGS = false;
if ( ENABLE_UNNECESSARY_REACT_RENDER_LOGS )
{
    // For unclear reasons, this needs to be a require() instead of an import.
    // See setup instructions at https://github.com/welldone-software/why-did-you-render
    // tslint:disable-next-line:no-var-requires
    const whyDidYouRender = require( "@welldone-software/why-did-you-render" );
    whyDidYouRender( React, {
        trackAllPureComponents: true,
        // Other useful options are
        // logOnDifferentValues: true,
        //        Will log all renders, not just unnecessary ones (ones that have the same input values).
        // onlyLogs: true,
        //        Will log to the console with "simple" logs instead of groups, so it is easier to search + filter.
        // For option documentation, see https://github.com/welldone-software/why-did-you-render#options
    } );
}
