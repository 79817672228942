import * as React from "react";
import * as InfoImage from "../assets/img/cautionIconYellow.png";

export const LowResErrorDialog: React.FC = () =>
{
    return (
        <div className="lowResDialogHeader">
            <div className="lowResHeaderRow">
                <img src={InfoImage}/>
                LOW RESOLUTION
            </div>
            <div>
                Your image quality is too low. Please replace it with a larger photo (500px x 500px min).
            </div>
        </div>
    );
};
