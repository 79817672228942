import * as React from "react";
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import { InspireBackgroundOverlay } from "./InspireBackgroundOverlay";
import Button from "react-md/lib/Buttons/Button";
import { SadfaceIcon } from "./svgs/SadfaceIcon";
import CircularProgress from "react-md/lib/Progress/CircularProgress";
import { eventTracker, URLS } from "../helpers";

export interface SubscriptionOnHoldLockoutMessageProps
{
    isRefreshingData: boolean;
}

export interface SubscriptionOnHoldLockoutMessageDispatchProps
{
    refreshSubscriptionData: () => void;
}

export default class SubscriptionOnHoldLockoutMessage
    extends React.PureComponent<SubscriptionOnHoldLockoutMessageProps & SubscriptionOnHoldLockoutMessageDispatchProps>
{
    public componentDidMount()
    {
        eventTracker.logSubscriptionOnHoldLockoutMessageShown();
    }

    public render()
    {
        return (
            <Grid noSpacing={true}>
                <Cell size={12}>
                    <div className="subscriptionOnHoldLockout">
                        <InspireBackgroundOverlay>
                            {this.createLockoutMessageDialog()}
                        </InspireBackgroundOverlay>
                    </div>
                </Cell>
            </Grid>
        );
    }

    private createSpinner()
    {
        if ( this.props.isRefreshingData )
        {
            return (
                <div className="spinnerOverlayContainer">
                    <div>Checking...</div>
                    <CircularProgress className="spinner" id="spinner"/>
                </div>
            );
        }
    }

    private createLockoutMessageDialog()
    {
        return (
            <Grid className="lockoutDialog" noSpacing={true}>
                <Cell phoneSize={12} tabletSize={8} desktopSize={12} desktopOffset={12}>
                    <SadfaceIcon/>
                    <div className="lockoutTitle">Uh oh!</div>
                    <div className="lockoutText">
                        The Google Play Store has put a hold on your Ripl subscription. Please update your payment information to access Ripl.
                    </div>
                    <div className="buttonWrap">
                        <Button flat primary
                                className="large outline refreshBtn"
                                onClick={this.onRefreshButtonClicked}>Try again</Button>
                        <Button flat primary swapTheming
                                className="large manageSubscriptionBtn"
                                onClick={this.onUpdatePaymentButtonClicked}>Update payment info</Button>
                    </div>
                </Cell>
                {this.createSpinner()}
            </Grid>
        );
    }

    private onRefreshButtonClicked = () =>
    {
        if ( !this.props.isRefreshingData )
        {
            eventTracker.logSubscriptionOnHoldLockoutRefreshButtonTapped();
            this.props.refreshSubscriptionData();
        }
    }

    private onUpdatePaymentButtonClicked()
    {
        eventTracker.logSubscriptionOnHoldLockoutManageSubscriptionButtonTapped();
        window.open( URLS.GooglePlayManageSubscription, "google_subscriptions" );
    }
}
