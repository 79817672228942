import * as React from "react";
import { SortableContainer } from "react-sortable-hoc";
import { Media } from "./Media";
import { MediaInfoProps } from "./MediaPicker";
import { map } from "lodash";

interface MediaPickerGridProps
{
    items: MediaInfoProps[];
    onDeleted: ( index: number ) => void;
    onCaution: ( index: number ) => void;
    onSelected: ( index: number ) => void;
    selectedIndex: number;
    onMediaEdit: ( onMediaEdit: string ) => void;
}

class MediaPickerGridImpl extends React.PureComponent<MediaPickerGridProps>
{
    public render()
    {
        const items = this.props.items;
        return (
            <ul>
                {map( items, ( item, index ) =>
                    <Media key={`image-${item.mediaUrlString}`}
                           index={index}
                           src={item.mediaUrlString}
                           itemIndex={index}
                           selected={this.props.selectedIndex === index}
                           lowQuality={item.lowQuality}
                           onSelected={this.onSelected}
                           onDeleted={this.onDeleted}
                           onCaution={this.onCaution}
                           onMediaEdit={this.props.onMediaEdit}
                    /> )}
                {this.props.children}
            </ul>
        );
    }

    private onSelected = ( newIndex ) =>
    {
        this.props.onSelected( newIndex );
    };

    private onDeleted = ( index ) =>
    {
        this.props.onDeleted( index );
    };

    private onCaution = ( index ) =>
    {
        this.props.onCaution( index );
    };
}

export const MediaPickerGrid = SortableContainer<MediaPickerGridProps>( MediaPickerGridImpl );
