import * as React from "react";
import BusinessSwitcher from "../containers/BusinessSwitcher.container";
import { UserBusiness } from "../../_types";
import * as RiplLogoSymbolMarkImage from "../../assets/img/ripl_logo_symbol_mark.png";
import classNames from "classnames";
import Button from "react-md/lib/Buttons/Button";

export interface BusinessInfoPanelProps
{
    currentBusiness: UserBusiness;
}

export interface BusinessInfoPanelDispatchProps
{
    handleOpenCreateBusiness: () => void;
}

export class BusinessInfoPanel extends React.Component<BusinessInfoPanelProps & BusinessInfoPanelDispatchProps>
{
    public render()
    {
        const currentBusiness = this.props.currentBusiness;

        return (
            <div className="businessInfoPanel">
                {this.getBusinessImage()}
                <div className="businessInfo">
                    {this.getBusinessPicker()}
                    <div className="businessType">{currentBusiness && currentBusiness.business_type_name}</div>
                </div>
            </div>
        );
    }

    private getBusinessImage()
    {
        const currentBusiness = this.props.currentBusiness;
        const businessImageSrc = currentBusiness ? currentBusiness.profile_image_url : RiplLogoSymbolMarkImage;
        return (<img className={classNames( "businessLogo businessLogoImage", { defaultBusinessLogo: !currentBusiness } )} src={businessImageSrc}/>);
    }

    private getBusinessPicker()
    {
        const currentBusiness = this.props.currentBusiness;
        return (currentBusiness ? <BusinessSwitcher/> : this.getCreateBrandButton());
    }

    private getCreateBrandButton()
    {
        return (
            <Button flat swapTheming primary className="createBrandButton" onClick={this.props.handleOpenCreateBusiness}>Create brand</Button>
        );
    }
}
