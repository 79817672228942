import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ChangeBusinessType, ChangeBusinessTypeDispatchProps, ChangeBusinessTypeProps } from "../ChangeBusinessType";
import { getCurrentBusinessTypeId } from "../../../ducks/userBusiness";
import { getTypes } from "../../../ducks/businessTypes";
import { StoreState } from "../../../_types";
import { modalServices } from "../../../services";

const mapStateToProps = ( storeState: StoreState ): ChangeBusinessTypeProps =>
{
    return {
        businessTypes: getTypes( storeState ),
        currentBusinessTypeId: getCurrentBusinessTypeId( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): ChangeBusinessTypeDispatchProps =>
{
    return {
        handleChangeBusinessType: () =>
        {
            dispatch( modalServices.openChangeBusinessTypeDialog() );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( ChangeBusinessType );
