import * as React from "react";
import { size } from "lodash";
import { EpidemicSoundCollectionDetailAPI, EpidemicSoundTrack } from "../../../_types/api";
import { ListPicker } from "../../ListPicker";
import EpidemicAudioTracksListWithInfiniteScrollContainer from "./containers/EpidemicAudioTracksListWithInfiniteScroll.container";
import EpidemicCollectionsListContainer from "./containers/EpidemicCollectionsList.container";
import Button from "react-md/lib/Buttons/Button";
import { MusicByEpidemicSound } from "./MusicByEpidemicSound";
import EpidemicSoundESDisconnectButtonContainer from "./containers/EpidemicSoundESDisconnectButton.container";
import { WithMusicPlayerProps } from "../../higherOrderComponents/WithMusicPlayer";
import { Music } from "../../../_types";

export interface EpidemicSoundUserCollectionsDialogProps
{
    epidemicCollections: EpidemicSoundCollectionDetailAPI[];
    isLoadingEpidemicSoundUserCollectionsInProgress: boolean;
    activeCollectionTracks: EpidemicSoundTrack[];
}

export interface EpidemicSoundUserCollectionsDialogDispatchProps
{
    loadCollections();
    clearActiveCollectionTracks();
    setEpidemicSoundSource();
}

interface EpidemicSoundUserCollectionsDialogState
{
    collectionScrollTopPosition: number;
}

export class EpidemicSoundUserCollectionsDialog
    extends React.PureComponent<EpidemicSoundUserCollectionsDialogProps & EpidemicSoundUserCollectionsDialogDispatchProps & WithMusicPlayerProps,
        EpidemicSoundUserCollectionsDialogState>
{
    constructor( props )
    {
        super( props );
        this.state = { collectionScrollTopPosition: 0 };
    }

    public componentDidMount()
    {
        this.props.loadCollections();
    }

    public render()
    {
        return (
            <>
                {this.renderBackButton()}
                <ListPicker loading={this.shouldShowLoader()} hasCustomListInnerWrap={true}>
                    {this.renderListContent()}
                </ListPicker>
                <MusicByEpidemicSound children={this.getEpidemicSoundESDisconnectLink()} class="dialog"/>
            </>
        );
    }

    private getEpidemicSoundESDisconnectLink = () =>
    {
        return <EpidemicSoundESDisconnectButtonContainer/>;
    }

    private handleSetScrollPosition = ( collectionScrollTopPosition: number ) =>
    {
        this.setState( { collectionScrollTopPosition } );
    }

    private shouldShowLoader = () =>
    {
        return this.props.isLoadingEpidemicSoundUserCollectionsInProgress && size( this.props.epidemicCollections ) === 0;
    }

    private renderBackButton = () =>
    {
        if ( this.hasActiveCollection() )
        {
            return (<Button className="backButton"
                            icon={true}
                            iconClassName="material-icons"
                            iconChildren="arrow_back_ios"
                            onClick={this.handleBackButtonClick}/>);
        }
        return null;
    }

    private renderListContent = () =>
    {
        if ( this.hasActiveCollection() )
        {
            return (<EpidemicAudioTracksListWithInfiniteScrollContainer musicList={this.props.activeCollectionTracks}
                                                                        handleMusicClick={this.setTrackingSourceAndHandleMusicClick}/>);
        }
        else
        {
            return (<EpidemicCollectionsListContainer epidemicCollections={this.props.epidemicCollections}
                                                      handleSetScrollPosition={this.handleSetScrollPosition}
                                                      scrollTopPosition={this.state.collectionScrollTopPosition}/>);
        }
    }

    private setTrackingSourceAndHandleMusicClick = ( music?: Music ) =>
    {
        this.props.setEpidemicSoundSource();
        this.props.handleMusicClick( music );
    }

    private hasActiveCollection = () =>
    {
        return size( this.props.activeCollectionTracks ) > 0;
    }

    private handleBackButtonClick = () =>
    {
        this.props.stopPlayingMusic();
        this.props.clearActiveCollectionTracks();
    }
}
