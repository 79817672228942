import * as React from "react";
import * as RiplLogo from "../assets/img/ripl_logo_white.png";
import Button from "react-md/lib/Buttons/Button";
import PricePlanRadioButtonContainer from "./containers/PricePlanRadioButton.container";
import { get } from "lodash";
import * as Crown from "../assets/img/crown-icon.svg";
import CurrencySelectorContainer from "./containers/CurrencySelector.container";
import { pricingTiersUIHelper, RIPL_TIER_PREMIUM } from "../helpers";
import { store } from "../store";
import { pricingActions } from "../actions";

export interface SelectPricePlansDialogProps
{
    monthlyPlan?: StripePlanData;
    annualPlan?: StripePlanData;
    selectedPlan?: StripePlanData;
    selectedTier?: ProductTier;
    hasUsedTrial?: boolean;
    shouldShowExchangeRates: boolean;
}

export interface SelectPricePlansDialogDispatchProps
{
    showPaymentDialog();
    backToTierSelection();
    handleCancel();
}

export class SelectPricePlansDialog extends React.PureComponent<SelectPricePlansDialogProps & SelectPricePlansDialogDispatchProps>
{
    constructor( props )
    {
        super( props );
        this.state = {};
    }

    public render()
    {
        const shouldShowCrown = this.props.selectedTier === RIPL_TIER_PREMIUM;
        const buttonText = this.getButtonText();
        return (
            <div className="selectPricePlansDialog">
                <div className="leftPanel">
                    <div className="callToActionBackground">
                        <img src={RiplLogo}/>
                        <div className="callToActionText">
                            Choose the Ripl subscription that’s right for your business
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="planInfoArea">
                        <div className="selectPlansHeader">
                            {this.props.shouldShowExchangeRates && <CurrencySelectorContainer/>}
                            <div className="skipButton clickable" onClick={this.props.handleCancel}>Maybe later</div>
                        </div>
                        {shouldShowCrown && <div>
                            <img src={Crown} alt="Crown"/>
                        </div>}
                        <h1 className="text title thin">Ripl {this.getTitle()} Plan</h1>
                        <div className="priceRadioButtonList">
                            {this.getPricePlanRadioButton( this.props.monthlyPlan )}
                            {this.getPricePlanRadioButton( this.props.annualPlan )}
                        </div>
                        <div className="backToCompareText clickable" onClick={this.handleBackToCompareClicked}>Go back to compare plan options</div>
                    </div>
                    <Button flat className="startTrialButton"
                            onClick={this.props.showPaymentDialog}>{buttonText}</Button>
                </div>
            </div>
        );
    }

    private getTitle()
    {
        return pricingTiersUIHelper.getTierDisplayText( this.props.selectedTier );
    }

    private getPricePlanRadioButton( plan: StripePlanData )
    {
        return plan && <PricePlanRadioButtonContainer
            selectPlan={this.selectPlan}
            plan={plan}
            showAsMonthlyRate={true}
            selected={plan.id === get( this.props.selectedPlan, "id" )}/>;
    }

    private handleBackToCompareClicked = () =>
    {
        this.props.backToTierSelection();
    }

    private selectPlan = ( plan: StripePlanData ) =>
    {
        store.dispatch( pricingActions.setPlan( plan.id ) );
    }

    private getButtonText = () =>
    {
        return this.props.hasUsedTrial ? "Subscribe now" : "Start 7-day free trial";
    }

}
