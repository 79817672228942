// Copyright Ripl Inc. 2019. All rights reserved.

/////////////////////////////////////////////////
/////////////////////////////////////////////////
/// V I D E O // T R I M M E R // O B J E C T ///
/////////////////////////////////////////////////
/////////////////////////////////////////////////
const {showSafariAudioSyncWarningToast} = require( "../helpers/toastPresenter" );
const {toMMSS} = require( "../helpers/dateUtils" );

var STATE_PAUSED = "paused";
var STATE_PLAYING = "playing";
var STATE_SEEKING_WHEN_PREVIOUSLY_PLAYING = "seeking_when_previously_playing";
var STATE_SEEKING_WHEN_PREVIOUSLY_PAUSED = "seeking_when_previously_paused";

var VideoTrimmer = function ( aVideoUrl, aContainerHtmlElement, configArgs )
{
    this.maxClipDuration = 60000;
    this.defaultMaxDuration = 30000;
    this.minClipDuration = 1000;
    this.trimmerControlsHeight = 100;
    this.layoutPadding = 20;
    this.durationLabelPadding = this.layoutPadding * 1.25;
    this.audioOnImageUrl = null;

    this.videoUrl = aVideoUrl;

    this.container = aContainerHtmlElement;

    this.animationFrameRequest = -1;

    this.onVideoTrimmerReady = null;
    this.onVideoTrimmerFailed = null;
    this.renderStack = [];

    if ( configArgs )
    {
        for ( var arg in configArgs )
        {
            this[arg] = configArgs[arg];
        }
    }

    this.setup();
};

module.exports = {VideoTrimmer};

VideoTrimmer.prototype.onInitComplete = function ()
{
    this.startRenderLoop();
    if ( typeof this.onVideoTrimmerReady === 'function' )
    {
        this.onVideoTrimmerReady( this );
    }
};

VideoTrimmer.prototype.cleanUp = function ()
{
    if ( this.videoClipObject )
    {
        this.videoClipObject.cleanUp();
    }
    if ( this.interactionDelegate )
    {
        this.interactionDelegate.cleanUp();
    }
    this.stopRenderLoop();

    if ( this.video )
    {
        this.video.src = null;
    }

    if ( this.canvas )
    {
        this.canvas.width = 1;
        this.canvas.height = 1;
    }

    if ( this.timelineTrimmerObject && this.timelineTrimmerObject.timelineImage )
    {
        this.timelineTrimmerObject.timelineImage.width = 1;
        this.timelineTrimmerObject.timelineImage.height = 1;
    }

    this.videoUrl = null;
};

VideoTrimmer.prototype.onTrimSelectionCompleted = function ()
{
};

VideoTrimmer.prototype.onTrimSelectionDragged = function ()
{
};

VideoTrimmer.prototype.setup = function ()
{
    this.setupCanvas();
    this.setupInteraction();
    this.loadVideo( createDelegate( this, this.setupStackObjectsAfterVideoLoads ),
        createDelegate( this, this.handleVideoFailed ) );
};

VideoTrimmer.prototype.reloadVideo = function ()
{
    this.loadVideo( createDelegate( this, this.setupStackObjectsAfterVideoLoads ),
        createDelegate( this, this.handleVideoFailed ) );
};

VideoTrimmer.prototype.setupInteraction = function ()
{
    var theInteractionDelegate = new InteractionDelegate(
        this.canvas,
        {
            "start": createDelegate( this, this.pointHitTest )
        },
        {
            interactionScale: 1
        }
    );

    this.interactionDelegate = theInteractionDelegate;
};

VideoTrimmer.prototype.getTrimmerData = function ()
{
    if ( this.timelineTrimmerObject )
    {
        var theTrimmerData = this.timelineTrimmerObject.getTrimmerData();
        return {
            start: Math.floor( theTrimmerData.start * 1000 ),
            end: Math.floor( theTrimmerData.end * 1000 )
        };
    }
};

VideoTrimmer.prototype.getFullVideoDurationInSeconds = function ()
{
    return this.videoClipObject && this.videoClipObject.getFullDuration();
};

VideoTrimmer.prototype.getTrimmedVideoDurationInSeconds = function ()
{
    return this.videoClipObject && (this.videoClipObject.getClipDuration() / 1000);
};

VideoTrimmer.prototype.stopRenderLoop = function ()
{
    window.cancelAnimationFrame( this.animationFrameRequest );
    this.renderNextFrameDelegate = null;
};

VideoTrimmer.prototype.startRenderLoop = function ()
{
    this.stopRenderLoop();

    this.lastRenderTime = new Date().getTime();
    this.renderNextFrameDelegate = createDelegate( this, this.renderNextFrame );

    this.timelineTrimmerObject.playVideoFromTrimPosition();

    this.renderNextFrame();
};

VideoTrimmer.prototype.renderNextFrame = function ()
{
    var time = new Date().getTime();
    var elapsedTimeBetweenFrames = time - this.lastRenderTime;
    this.lastRenderTime = time;

    this.compositeCtx.clearRect( 0, 0, this.canvas.width, this.canvas.height );

    for ( var i = 0; i < this.renderStack.length; i++ )
    {
        var item = this.renderStack[i];
        item.updateForElapsedTime( elapsedTimeBetweenFrames );
        item.renderToCanvas( this.compositeCtx );
    }

    this.animationFrameRequest = window.requestAnimationFrame( this.renderNextFrameDelegate );
};

VideoTrimmer.prototype.setupCanvas = function ()
{
    var theWidth = 0, theHeight = 0;

    if ( this.container )
    {
        theWidth = this.container.offsetWidth;
        theHeight = this.container.offsetHeight;
    }
    else // go with window dimensions
    {
        if ( typeof (window.innerWidth) == 'number' )
        {
            //Non-IE
            theWidth = window.innerWidth;
            theHeight = window.innerHeight;
        }
        else if ( document.documentElement && (document.documentElement.clientWidth || document.documentElement.clientHeight) )
        {
            //IE 6+ in 'standards compliant mode'
            theWidth = document.documentElement.clientWidth;
            theHeight = document.documentElement.clientHeight;
        }
        else if ( document.body && (document.body.clientWidth || document.body.clientHeight) )
        {
            //IE 4 compatible
            theWidth = document.body.clientWidth;
            theHeight = document.body.clientHeight;
        }
    }

    ////////////////////////////
    // setup canvas element ////
    ////////////////////////////
    var theCanvasId = 'composite_canvas';

    var canvas = document.getElementById( theCanvasId );
    var theCanvasIsInDom = canvas;

    if ( !theCanvasIsInDom )
    {
        canvas = document.createElement( 'canvas' );
        canvas.setAttribute( 'id', 'composite_canvas' );
        canvas.style.position = 'absolute';
        canvas.style.top = "0px";
        canvas.style.left = "0px";
        canvas.style.width = "100%";
        canvas.style.height = "100%";
        if ( this.container )
        {
            this.container.appendChild( canvas );
        }
        else
        {
            document.body.appendChild( canvas );
        }
        this.canvas = canvas;
        this.compositeCtx = canvas.getContext( '2d' );
    }

    canvas.width = theWidth;
    canvas.height = theHeight;
};

VideoTrimmer.prototype.loadVideo = function ( onVideoReadyCallback, onVideoFailedCallback )
{
    var self = this;
    var onVideoHttpRequestComplete = function ()
    {
        this.oncanplaythrough = null;
        self.container.appendChild( this );
        self.video = this;
        onVideoReadyCallback();
    };

    var req = new XMLHttpRequest();
    req.open( 'GET', this.videoUrl, true );
    req.responseType = 'blob';

    req.onload = function ()
    {
        // Onload is triggered even on 404
        // so we need to check the status code
        if ( this.status === 200 )
        {
            var theVideo = document.createElement( 'video' );

            // attributes
            theVideo.muted = true;
            theVideo.controls = false;
            theVideo.autoplay = false;
            theVideo.loop = true;
            theVideo.setAttribute( "type", "video/mp4" );
            // style stuffs
            // NOTE: some browsers require the source video to be in the DOM for the canvas to access its rendered context
            theVideo.style.position = "absolute";
            theVideo.style.top = "0px";
            theVideo.style.left = "0px";
            theVideo.style.width = "1px";
            theVideo.style.height = "1px";
            theVideo.style.zIndex = -1;
            theVideo.style.opacity = 0;
            theVideo.style.pointerEvents = "none";

            var videoBlob = this.response;
            theVideo.oncanplaythrough = onVideoHttpRequestComplete;
            theVideo.src = (window.webkitURL || window.URL).createObjectURL( videoBlob );
            theVideo.load();
        }
        else
        {
            onVideoFailedCallback();
        }
    };

    req.onprogress = function ( event )
    {
        // todo: send progress to view
        // console.log( "VIDEO LOADING: ", event.loaded / event.total );
    };
    req.onerror = function ( event )
    {
        onVideoFailedCallback();
    };

    req.send();
};

VideoTrimmer.prototype.updateOnWindowResize = function ()
{
    this.setupCanvas();
    this.interactionDelegate.updateSurfaceOffset();
    this.muteButton.updateOnWindowResize( this );
    this.videoClipObject.updateOnWindowResize( this );
    this.timelineTrimmerObject.updateOnWindowResize( this );
    this.durationLabel.updateOnWindowResize( this );
};

VideoTrimmer.prototype.updateClipDuration = function ( aMaxDuration, aDefaultDuration, aStartTime, anEndTime )
{
    this.maxClipDuration = aMaxDuration * 1000;
    this.defaultMaxDuration = aDefaultDuration * 1000;
    this.clipStartTime = 0;
    this.clipEndTime = aMaxDuration;
    this.timelineTrimmerObject.trimmerData.start = aStartTime || 0;
    this.timelineTrimmerObject.trimmerData.end = anEndTime || aDefaultDuration;
    this.timelineTrimmerObject.maxClipDuration = aMaxDuration * 1000;
    this.timelineTrimmerObject.updateOnWindowResize( this );
    this.durationLabel.setDuration( this.timelineTrimmerObject.trimmerData.duration );
};

VideoTrimmer.prototype.handleVideoFailed = function ()
{
    if ( typeof this.onVideoTrimmerFailed === 'function' )
    {
        this.onVideoTrimmerFailed( this );
    }

}
VideoTrimmer.prototype.setupStackObjectsAfterVideoLoads = function ()
{
    this.renderStack = [];

    this.videoClipObject = this.setupVideoClipObject();
    this.renderStack.push( this.videoClipObject );

    this.muteButton = this.setupMuteButtonObject();
    this.renderStack.push( this.muteButton );

    var theTrimmedDurationInSeconds = this.getTrimmedVideoDurationInSeconds();
    this.durationLabel = this.setupDurationLabel( theTrimmedDurationInSeconds );
    this.renderStack.push( this.durationLabel );

    this.timelineTrimmerObject = this.setupTrimmerTimelineObject();
    this.renderStack.push( this.timelineTrimmerObject );

    var theNumberOfSnapshotImages = 8;
    this.createVideoTimelineSnapshots(
        theNumberOfSnapshotImages,
        createDelegate( this, this.onInitComplete )
    );
};

VideoTrimmer.prototype.setupVideoClipObject = function ()
{
    var theCalculatedParts = this.calculateFrameAndSizeForVideoClip();

    var theVideoClipObject = new VideoClip(
        {
            frame: {
                color: "#ffffff",
                x: this.layoutPadding,
                y: this.layoutPadding,
                width: theCalculatedParts.frameWidth,
                height: theCalculatedParts.frameHeight
            },
            video: theCalculatedParts.videoEl,
            width: theCalculatedParts.width,
            height: theCalculatedParts.height,
            x: theCalculatedParts.x,
            y: theCalculatedParts.y,
            clipStartTime: this.trimStartTimeMs || 0,
            clipEndTime: this.trimEndTimeMs || Math.min( this.defaultMaxDuration, theCalculatedParts.videoEl.duration * 1000 ),
            updateOnWindowResize: function ( aVideoTrimmer )
            {
                var theRecalculatedParts = aVideoTrimmer.calculateFrameAndSizeForVideoClip();
                this.frame.width = theRecalculatedParts.frameWidth;
                this.frame.height = theRecalculatedParts.frameHeight;

                this.x = theRecalculatedParts.x;
                this.y = theRecalculatedParts.y;
                this.width = theRecalculatedParts.width;
                this.height = theRecalculatedParts.height;

                this.canvas.width = this.width;
                this.canvas.height = this.height;
            },
        } );

    return theVideoClipObject;
};

VideoTrimmer.prototype.calculateFrameAndSizeForVideoClip = function ()
{
    var videoEl = this.video;
    var compositeCanvas = this.canvas;
    var videoSlotHeight = compositeCanvas.height - this.trimmerControlsHeight - this.layoutPadding * 3;
    var videoSlotWidth = compositeCanvas.width - this.layoutPadding * 2;

    var shouldVideoFitHeight = videoSlotWidth / videoSlotHeight > videoEl.videoWidth / videoEl.videoHeight;
    var videoScale = shouldVideoFitHeight ?
                     videoSlotHeight / videoEl.videoHeight :
                     videoSlotWidth / videoEl.videoWidth;

    var scaledWidth = Math.floor( videoEl.videoWidth * videoScale ),
        scaledHeight = Math.floor( videoEl.videoHeight * videoScale ),
        x = this.layoutPadding + (videoSlotWidth / 2 - scaledWidth / 2),
        y = this.layoutPadding + (videoSlotHeight / 2 - scaledHeight / 2);

    return {
        frameWidth: videoSlotWidth,
        frameHeight: videoSlotHeight,
        width: scaledWidth,
        height: scaledHeight,
        videoEl: videoEl,
        x: x,
        y: y,
    };
};

VideoTrimmer.prototype.setupMuteButtonObject = function ()
{
    var muteButtonDimension = 40;

    var theMuteButton = new MuteButton(
        {
            x: this.canvas.width - this.layoutPadding - muteButtonDimension,
            y: this.layoutPadding,
            width: muteButtonDimension,
            height: muteButtonDimension,
            videoClipObject: this.videoClipObject,
            mutedImageUrl: this.audioMutedImageUrl,
            unmutedImageUrl: this.audioOnImageUrl,
            audioNoneImageUrl: this.audioNoneImageUrl,
        } );

    theMuteButton.updateOnWindowResize = function ( aVideoTrimmer )
    {
        this.x = aVideoTrimmer.canvas.width - aVideoTrimmer.layoutPadding - muteButtonDimension;
    }
    return theMuteButton;
};

VideoTrimmer.prototype.setupTrimmerTimelineObject = function ()
{
    var compositeCanvas = this.canvas;

    var theTimelineTrimmerObject = new TrimmerTimeline(
        {
            x: this.layoutPadding,
            y: compositeCanvas.height - this.trimmerControlsHeight - this.layoutPadding,
            width: compositeCanvas.width - this.layoutPadding * 2,
            height: this.trimmerControlsHeight - 5,
            videoClipObject: this.videoClipObject,
            durationLabel: this.durationLabel,
            maxClipDuration: this.maxClipDuration,
            minClipDuration: this.minClipDuration,
            onTrimSelectionCompleted: this.onTrimSelectionCompleted,
            onTrimSelectionDragged: this.onTrimSelectionDragged,
        } );

    theTimelineTrimmerObject.updateOnWindowResize = function ( aVideoTrimmer )
    {
        this.x = aVideoTrimmer.layoutPadding;
        this.y = aVideoTrimmer.canvas.height - aVideoTrimmer.trimmerControlsHeight - aVideoTrimmer.layoutPadding;
        this.width = aVideoTrimmer.canvas.width - aVideoTrimmer.layoutPadding * 2;
        this.height = aVideoTrimmer.trimmerControlsHeight - 5;
        this.initTrimmerPositions( this.trimmerData.start, this.trimmerData.end );
    }

    return theTimelineTrimmerObject;
};

VideoTrimmer.prototype.createVideoTimelineSnapshots = function ( aNumberOfFramesToCapture, onCompleteCallback )
{
    var timelineTrimmer = this.timelineTrimmerObject;
    var videoClip = this.videoClipObject;

    var theCaptureCanvas = document.createElement( 'canvas' );
    theCaptureCanvas.width = timelineTrimmer.width;
    theCaptureCanvas.height = timelineTrimmer.height;
    var captureCtx = theCaptureCanvas.getContext( '2d' );

    var timeIncrement = videoClip.getFullDuration() / aNumberOfFramesToCapture;
    var theTimelineImageFrameSpacing = theCaptureCanvas.width / aNumberOfFramesToCapture;

    var setVideoClipTime = createDelegate( videoClip, videoClip.setClipTime );

    var onLoopComplete = function ()
    {
        videoClip.removeAfterRenderListener();
        timelineTrimmer.timelineImage = theCaptureCanvas;

        if ( typeof onCompleteCallback === 'function' )
        {
            onCompleteCallback();
        }
    };

    var captureIndex = aNumberOfFramesToCapture;
    var captureImageFromVideoTimeline = function ()
    {
        if ( captureIndex )
        {
            captureIndex--;
            var theVideoTimeInSeconds = captureIndex * timeIncrement;
            setVideoClipTime( theVideoTimeInSeconds );
        }
        else
        {
            onLoopComplete();
        }
    };

    videoClip.addAfterRenderListener(
        function ( aFrameImage )
        {
            var theThumbnailScale = theCaptureCanvas.height / aFrameImage.height;
            var scaledFrameWidth = aFrameImage.width * theThumbnailScale;
            var scaledFrameHeight = aFrameImage.height * theThumbnailScale;
            var widthOffset = (theTimelineImageFrameSpacing - scaledFrameWidth);

            captureCtx.drawImage(
                aFrameImage,
                widthOffset + (theTimelineImageFrameSpacing * captureIndex),
                0,
                scaledFrameWidth,
                scaledFrameHeight
            );
            captureImageFromVideoTimeline();
        } );

    captureImageFromVideoTimeline();
};

VideoTrimmer.prototype.pointHitTest = function ( aPoint )
{
    let results = null;
    for ( let i = this.renderStack.length - 1; i >= 0; i-- )
    {
        var item = this.renderStack[i];
        if ( typeof item.pointHitTest === 'function' )
        {
            results = item.pointHitTest( aPoint );
            if ( results )
            {
                item.handleInteractionStart( aPoint, this.interactionDelegate, results );
                break;
            }
        }
    }
};

VideoTrimmer.prototype.setupDurationLabel = function ( aDuration )
{
    var compositeCanvas = this.canvas;

    var theDurationLabel = new DurationLabel(
        {
            x: compositeCanvas.width / 2,
            y: compositeCanvas.height - this.trimmerControlsHeight - this.durationLabelPadding,
            duration: aDuration
        } );

    theDurationLabel.updateOnWindowResize = function ( aVideoTrimmer )
    {
        this.x = aVideoTrimmer.canvas.width / 2;
        this.y = aVideoTrimmer.canvas.height - aVideoTrimmer.trimmerControlsHeight - aVideoTrimmer.durationLabelPadding;
    }

    return theDurationLabel;
}

///////////////////////////////////////////
///////////////////////////////////////////
/// V I D E O // C L I P // O B J E C T ///
///////////////////////////////////////////
///////////////////////////////////////////

window.VideoClip = function ( args )
{
    this.video = null;

    this.clipStartTime = 0;
    this.clipEndTime = Infinity;

    this.state = STATE_PAUSED;

    var videoEl = args.video;

    this.x = 0;
    this.y = 0;
    this.width = videoEl.videoWidth;
    this.height = videoEl.videoHeight;

    this.playPauseOverlayTime = -1;
    this.overlayAnimationDuration = 800;
    this.shouldShowPlayPauseOverlay = false;
    this.shouldAnimatePlayPauseOverlay = false;

    if ( args )
    {
        for ( var arg in args )
        {
            this[arg] = args[arg];
        }
    }

    this.canvas = document.createElement( 'canvas' );
    this.canvas.width = this.width;
    this.canvas.height = this.height;
    this.ctx = this.canvas.getContext( '2d' );

    this.ctx.fillAll( "#000000" );

    this.clipEndTime = Math.min( this.clipEndTime, this.video.duration * 1000 );

    this.setClipTime( this.convertTime( this.clipStartTime ) );
};

VideoClip.prototype.cleanUp = function ()
{
    this.pause();
    this.video.ontimeupdate = null;
    this.video.parentElement.removeChild( this.video );
    if ( this.canvas )
    {
        this.canvas.width = 1;
        this.canvas.height = 1;
    }
};

VideoClip.prototype.renderToCanvas = function ( aCanvasContext )
{
    if ( this.frame )
    {
        aCanvasContext.fillStyle = this.frame.color;
        aCanvasContext.fillRect( this.frame.x, this.frame.y, this.frame.width, this.frame.height );
    }

    aCanvasContext.drawImage(
        this.canvas,
        this.x,
        this.y,
        this.width,
        this.height
    );

    if ( this.shouldShowPlayPauseOverlay )
    {
        this.renderPlayPauseOverlayToCanvas( aCanvasContext );
    }
};

VideoClip.prototype.renderPlayPauseOverlayToCanvas = function ( aCanvasContext )
{
    if ( this.playPauseOverlayTime >= this.overlayAnimationDuration )
    {
        this.playPauseOverlayTime = -1;
        this.shouldShowPlayPauseOverlay = false;
        this.shouldAnimatePlayPauseOverlay = false;
        return;
    }

    aCanvasContext.globalAlpha = 1 - (this.playPauseOverlayTime / this.overlayAnimationDuration);

    var centerX = this.x + this.width / 2;
    var centerY = this.y + this.height / 2;
    var radius = 50;

    aCanvasContext.fillStyle = "rgba(0,0,0,0.7)";
    aCanvasContext.beginPath();
    aCanvasContext.arc( centerX, centerY, radius, 0, Math.PI * 2 );
    aCanvasContext.fill();

    aCanvasContext.fillStyle = "#FFFFFF";
    aCanvasContext.beginPath();
    if ( !this.isPlaying() )
    {
        var cX = centerX + radius * 0.15;
        var dim = radius / 2;
        aCanvasContext.moveTo( cX - dim, centerY - dim );
        aCanvasContext.lineTo( cX + dim * 0.85, centerY );
        aCanvasContext.lineTo( cX - dim, centerY + dim );
        aCanvasContext.closePath();
    }
    else
    {
        var offset = radius * 0.1;
        var dim = radius / 5;
        aCanvasContext.fillRect( centerX - dim - offset, centerY - dim * 2, dim, dim * 4 );
        aCanvasContext.fillRect( centerX + offset, centerY - dim * 2, dim, dim * 4 );
    }
    aCanvasContext.fill();

    aCanvasContext.globalAlpha = 1;
};

VideoClip.prototype.renderSelf = function ()
{
    this.ctx.drawImage( this.video, 0, 0, this.width, this.height );
};

VideoClip.prototype.addAfterRenderListener = function ( onVideoFrameReadyCallback )
{
    this.video.ontimeupdate = createDelegate( this, this.onVideoTimeUpdate );
    this.onVideoFrameReady = onVideoFrameReadyCallback;
};

VideoClip.prototype.removeAfterRenderListener = function ()
{
    this.video.ontimeupdate = null;
    this.onVideoFrameReady = null;
};

VideoClip.prototype.onVideoTimeUpdate = function ()
{
    this.renderSelf();
    this.onVideoFrameReady( this.canvas );
};

VideoClip.prototype.getFullDuration = function ()
{
    return this.video.duration;
};

VideoClip.prototype.getClipDuration = function ()
{
    return (this.clipEndTime - this.clipStartTime) || 0;
};

VideoClip.prototype.getClipTime = function ()
{
    return this.video.currentTime;
};

VideoClip.prototype.setClipTime = function ( aTime )
{
    this.video.currentTime = aTime;
};

VideoClip.prototype.convertTime = function ( aTimeMs )
{
    return Math.ceil( aTimeMs ) / 1000;
};

VideoClip.prototype.setState = function ( aState )
{
    this.state = aState;
};

VideoClip.prototype.getState = function ()
{
    return this.state;
};

VideoClip.prototype.play = function ()
{
    var videoPlayheadTime = this.getClipTime() * 1000;
    if ( videoPlayheadTime + 100 >= this.clipEndTime )
    {
        var clipStartTime = this.convertTime( this.clipStartTime );
        this.setClipTime( clipStartTime );
    }

    this.setState( STATE_PLAYING );
    this.video.play();
};

VideoClip.prototype.pause = function ()
{
    this.setState( STATE_PAUSED );
    this.video.pause();
};

VideoClip.prototype.beginSeek = function ()
{
    if ( this.isPaused() )
    {
        this.setState( STATE_SEEKING_WHEN_PREVIOUSLY_PAUSED );
    }
    else if ( this.isPlaying() )
    {
        this.video.pause();
        this.setState( STATE_SEEKING_WHEN_PREVIOUSLY_PLAYING );
    }
};

VideoClip.prototype.showPlayPauseOverlay = function ()
{
    this.shouldShowPlayPauseOverlay = true;
    this.playPauseOverlayTime = 0;
};

VideoClip.prototype.isPaused = function ()
{
    return this.getState() === STATE_PAUSED;
};

VideoClip.prototype.isSeeking = function ()
{
    return this.isSeekingWhenPreviouslyPlaying() || this.isSeekingWhenPreviouslyPaused();
};

VideoClip.prototype.isSeekingWhenPreviouslyPaused = function ()
{
    return this.getState() === STATE_SEEKING_WHEN_PREVIOUSLY_PAUSED;
};

VideoClip.prototype.isSeekingWhenPreviouslyPlaying = function ()
{
    return this.getState() === STATE_SEEKING_WHEN_PREVIOUSLY_PLAYING;
};

VideoClip.prototype.isPlaying = function ()
{
    return this.getState() === STATE_PLAYING;
};

VideoClip.prototype.togglePlayPause = function ()
{
    if ( this.isPlaying() )
    {
        this.pause();
        this.shouldAnimatePlayPauseOverlay = false;
    }
    else
    {
        this.play();
        this.shouldAnimatePlayPauseOverlay = true;
    }
    this.showPlayPauseOverlay();
};

VideoClip.prototype.updateForElapsedTime = function ( elapsedTimeBetweenFrames )
{
    var videoPlayheadTime = this.getClipTime() * 1000;

    if ( this.isPlaying() )
    {
        var time;

        if ( videoPlayheadTime >= this.clipEndTime )
        {
            time = this.convertTime( this.clipStartTime );
            this.setClipTime( time );
        }
    }

    this.renderSelf();

    ///////////////////////////

    if ( this.showPlayPauseOverlay && this.shouldAnimatePlayPauseOverlay )
    {
        this.playPauseOverlayTime += elapsedTimeBetweenFrames;
    }
};

VideoClip.prototype.pointHitTest = function ( aPoint )
{
    return aPoint.x > this.x && aPoint.x < this.x + this.width && aPoint.y > this.y && aPoint.y < this.y + this.height;
};

VideoClip.prototype.handleInteractionStart = function ()
{
    this.togglePlayPause();
};

VideoClip.prototype.hasAudio = function ()
{
    if ( !this.video )
    {
        return false;
    }
    else
    {
        return this.video.mozHasAudio ||
               Boolean( this.video.webkitAudioDecodedByteCount ) ||
               Boolean( this.video.audioTracks && this.video.audioTracks.length );
    }
};

///////////////////////////////////////////
///////////////////////////////////////////
/// T I M E L I N E // O B J E C T ////////
///////////////////////////////////////////
///////////////////////////////////////////

var PointerHandle = function ( args )
{
    this.x = 0;
    this.min = 0;
    this.max = 0;
    this.playheadOffset = 0;
    this.targets = [this];

    for ( var arg in args )
    {
        this[arg] = args[arg]
    }
};

window.TrimmerTimeline = function ( args )
{
    this.x = 0;
    this.y = 0;
    this.width = 100;
    this.height = 50;

    this.trimmerWidth = 15;
    this.trimmerFillStyle = "#464DE1";
    this.activeTrimmerFillStyle = "#464DE1";

    this.fillStyle = "#777777";
    this.playheadColor = "#FFFFFF";

    this.unselectOverlayColor = 'rgba(0,0,0,0.5)';

    this.playheadHandleDim = 8;

    this.videoClipObject = null;

    this.durationLabel = null;

    if ( args )
    {
        for ( var arg in args )
        {
            this[arg] = args[arg];
        }
    }

    this.playheadPosition = new PointerHandle( {} );
    this.trimmer_start = new PointerHandle( {playheadOffset: this.trimmerWidth} );
    this.trimmer_end = new PointerHandle( {playheadOffset: 0} );

    this.initTrimmerPositions();
};

TrimmerTimeline.prototype.initTrimmerPositions = function ( aClipStartTime, aClipEndTime )
{
    var fullDuration = this.videoClipObject.getFullDuration() * 1000;
    var startTime = aClipStartTime * 1000 || this.videoClipObject.clipStartTime;
    var endTime = aClipEndTime * 1000 || this.videoClipObject.clipEndTime;

    this.msPerPixel = this.width / fullDuration;

    this.trimmer_start.x = this.x + ((startTime / fullDuration) * this.width) - this.trimmer_start.playheadOffset;
    this.trimmer_end.x = this.x + ((endTime / fullDuration) * this.width) - this.trimmer_end.playheadOffset;

    this.trimmer_combined = new PointerHandle( {playheadOffset: this.trimmer_start.playheadOffset} );
    this.trimmer_combined.targets = [
        this.trimmer_combined,
        this.trimmer_start,
        this.trimmer_end
    ];

    this.updateHandleBounds();
    this.updateTrimmerData();
};

TrimmerTimeline.prototype.getVideoTimelineValueFromHandle = function ( aHandleObject )
{
    var theRelativePlayheadPosition = (aHandleObject.x + aHandleObject.playheadOffset - this.x) / this.width;
    return this.videoClipObject.getFullDuration() * theRelativePlayheadPosition;
};

TrimmerTimeline.prototype.getTrimmerData = function ()
{
    var startTime = this.getVideoTimelineValueFromHandle( this.trimmer_start );
    var endTime = this.getVideoTimelineValueFromHandle( this.trimmer_end );
    var duration = (endTime - startTime) * 10.0 / 10.0;

    return {
        start: startTime,
        end: endTime,
        duration: duration
    };
};

TrimmerTimeline.prototype.updateTrimmerData = function ()
{
    this.trimmerData = this.getTrimmerData();
};

TrimmerTimeline.prototype.updateVideoClipRangeToTrimmerHandlePositions = function ()
{
    this.videoClipObject.clipStartTime = this.getVideoTimelineValueFromHandle( this.trimmer_start ) * 1000;
    this.videoClipObject.clipEndTime = this.getVideoTimelineValueFromHandle( this.trimmer_end ) * 1000;
};

TrimmerTimeline.prototype.updateHandleBounds = function ()
{
    var theTrimmerData = this.trimmerData || this.getTrimmerData();
    var expandableTime = Math.max( this.maxClipDuration - ((theTrimmerData.end - theTrimmerData.start) * 1000), 0 );
    var expandableDistanceInPixels = expandableTime * this.msPerPixel;

    var minHandleDistanceInPixels = this.trimmerWidth + this.minClipDuration * this.msPerPixel;

    this.trimmer_start.min = Math.max( this.x - this.trimmerWidth, this.trimmer_start.x - expandableDistanceInPixels );
    this.trimmer_start.max = this.trimmer_end.x - minHandleDistanceInPixels;
    this.trimmer_end.min = this.trimmer_start.x + minHandleDistanceInPixels;
    this.trimmer_end.max = Math.min( this.x + this.width, this.trimmer_end.x + expandableDistanceInPixels );

    this.trimmer_combined.x = this.trimmer_start.x;
    this.trimmer_combined.min = this.x - this.trimmer_combined.playheadOffset;
    this.trimmer_combined.max = this.x + this.width - (this.trimmer_end.x - this.trimmer_start.x);
};

TrimmerTimeline.prototype.updatePlayheadPosition = function ( aCanvasContext )
{
    var clipTime = this.videoClipObject.getClipTime();
    var fullDuration = this.videoClipObject.getFullDuration();
    this.playheadPosition = this.x + ((clipTime / fullDuration) * this.width);
};

TrimmerTimeline.prototype.updateForElapsedTime = function ( elapsedTimeBetweenFrames )
{
    this.updatePlayheadPosition( elapsedTimeBetweenFrames );
};

function getFormattedDurationTimeString( theDuration )
{
    let theFormattedDuration = toMMSS(theDuration);
    return `Length: ${theFormattedDuration}`
}

TrimmerTimeline.prototype.renderClipDurationToCanvas = function ( aCanvasContext )
{
    if ( this.videoClipObject.isSeeking() )
    {
        var fontSize = 12;
        var padding = 5;
        var theTrimmerData = this.trimmerData || this.getTrimmerData();
        var theDurationAsFormattedString = getFormattedDurationTimeString( theTrimmerData.duration );

        aCanvasContext.textBaseline = "alphebetic";
        aCanvasContext.font = fontSize + "px sans-serif";
        var theText = theDurationAsFormattedString;
        var theRenderedTextWidth = aCanvasContext.measureText( theText ).width;
        var theLabelBackgroundWidth = theRenderedTextWidth + padding * 2;
        var x1 = this.trimmer_end.x - theLabelBackgroundWidth - padding;
        var y1 = this.y + padding;
        var y2 = y1 + fontSize + padding * 2;

        aCanvasContext.fillStyle = "rgba(0,0,0,0.7)";
        aCanvasContext.fillRect( x1, y1, theLabelBackgroundWidth, y2 - y1 );

        aCanvasContext.fillStyle = "#FFFFFF";
        aCanvasContext.fillText( theText, x1 + (theLabelBackgroundWidth / 2), y2 - padding * 1.4 );
    }
};

TrimmerTimeline.prototype.renderTrimmersToCanvas = function ( aCanvasContext )
{
    aCanvasContext.fillStyle = this.unselectOverlayColor;

    if ( this.trimmer_start.x > this.x )
    {
        aCanvasContext.fillRect( this.x, this.y, this.trimmer_start.x - this.x, this.height );
    }
    if ( this.trimmer_end.x < this.x + this.width - this.trimmerWidth )
    {
        aCanvasContext.fillRect( this.trimmer_end.x, this.y, (this.width + this.x) - (this.trimmer_end.x), this.height );
    }

    var margin = 1;
    var radius = 5;

    aCanvasContext.strokeStyle = this.activeTrimmerFillStyle;
    aCanvasContext.lineWidth = 3;
    aCanvasContext.strokeRect( this.trimmer_start.x + this.trimmerWidth, this.y + margin,
        this.trimmer_end.x - this.trimmer_start.x - this.trimmerWidth, this.height - margin * 2 );

    aCanvasContext.fillStyle = this.activeHandle == this.trimmer_start ?
                               this.activeTrimmerFillStyle :
                               this.trimmerFillStyle;
    this.traceLeftHandle( aCanvasContext, margin, radius );
    aCanvasContext.fill();

    aCanvasContext.fillStyle = this.activeHandle == this.trimmer_end ?
                               this.activeTrimmerFillStyle :
                               this.trimmerFillStyle;
    this.traceRightHandle( aCanvasContext, margin, radius );
    aCanvasContext.fill();
};

TrimmerTimeline.prototype.traceLeftHandle = function ( aCanvasContext, aMargin, aRadius )
{
    var x1 = this.trimmer_start.x;
    var x2 = x1 + this.trimmerWidth;
    var y1 = this.y - aMargin;
    var y2 = this.y + this.height + aMargin;
    aCanvasContext.beginPath();
    aCanvasContext.moveTo( x2, y1 );
    aCanvasContext.lineTo( x2, y2 );
    aCanvasContext.lineTo( x1 + aRadius, y2 );
    aCanvasContext.arcTo( x1, y2, x1, y2 - aRadius, aRadius );
    aCanvasContext.lineTo( x1, y1 + aRadius );
    aCanvasContext.arcTo( x1, y1, x1 + aRadius, y1, aRadius );
    aCanvasContext.closePath();

}

TrimmerTimeline.prototype.traceRightHandle = function ( aCanvasContext, aMargin, aRadius )
{
    var x1 = this.trimmer_end.x;
    var x2 = x1 + this.trimmerWidth;
    var y1 = this.y - aMargin;
    var y2 = this.y + this.height + aMargin;
    aCanvasContext.beginPath();
    aCanvasContext.moveTo( x1, y1 );
    aCanvasContext.lineTo( x1, y2 );
    aCanvasContext.lineTo( x2 - aRadius, y2 );
    aCanvasContext.arcTo( x2, y2, x2, y2 - aRadius, aRadius );
    aCanvasContext.lineTo( x2, y1 + aRadius );
    aCanvasContext.arcTo( x2, y1, x2 - aRadius, y1, aRadius );
    aCanvasContext.closePath();

}

TrimmerTimeline.prototype.renderPlayheadToCanvas = function ( aCanvasContext )
{
    var thePosition = this.playheadPosition;
    var y2 = this.y + this.height;

    aCanvasContext.beginPath();
    aCanvasContext.moveTo( thePosition, this.y );
    aCanvasContext.lineTo( thePosition, y2 );

    aCanvasContext.strokeStyle = this.playheadColor;
    aCanvasContext.lineWidth = 1;
    aCanvasContext.stroke();

    aCanvasContext.beginPath();
    aCanvasContext.moveTo( thePosition, y2 );
    aCanvasContext.lineTo( thePosition + this.playheadHandleDim, y2 + this.playheadHandleDim * 1.5 );
    aCanvasContext.lineTo( thePosition - this.playheadHandleDim, y2 + this.playheadHandleDim * 1.5 );
    aCanvasContext.closePath();
    aCanvasContext.fillStyle = this.playheadColor;
    aCanvasContext.fill();
};

TrimmerTimeline.prototype.renderToCanvas = function ( aCanvasContext )
{
    aCanvasContext.fillStyle = this.fillStyle;
    aCanvasContext.fillRect( this.x, this.y, this.width, this.height );

    if ( this.timelineImage )
    {
        aCanvasContext.drawImage( this.timelineImage, this.x, this.y, this.width, this.height );
    }

    this.renderPlayheadToCanvas( aCanvasContext );
    this.renderTrimmersToCanvas( aCanvasContext );
    this.renderClipDurationToCanvas( aCanvasContext );
};

TrimmerTimeline.prototype.pointHitTest = function ( aPoint )
{
    if ( aPoint.y > this.y && aPoint.y < this.y + this.height )
    {
        if ( // left trimmer
            aPoint.x > this.trimmer_start.x &&
            aPoint.x < this.trimmer_start.x + this.trimmerWidth )
        {
            return this.trimmer_start;
        }
        else if ( // right trimmer
            aPoint.x > this.trimmer_end.x &&
            aPoint.x < this.trimmer_end.x + this.trimmerWidth )
        {
            return this.trimmer_end;
        }
        else if ( // middle
            aPoint.x > this.trimmer_start.x + this.trimmerWidth &&
            aPoint.x < this.trimmer_end.x )
        {
            return this.trimmer_combined;
        }
    }

    return false;
};

TrimmerTimeline.prototype.handleInteractionStart = function ( aPoint, anInteractionDelegate, hitResults )
{
    this.videoClipObject.beginSeek();

    this.activeHandle = hitResults;
    aPoint.targetOriginX = this.activeHandle.x;
    this.startPointData = aPoint;

    anInteractionDelegate.setCallbackStackItem( 'move', createDelegate( this, this.handleTrimmerDrag ) );
    anInteractionDelegate.setCallbackStackItem( 'end', createDelegate( this, this.handleTrimmerDragEnd ) );
};

TrimmerTimeline.prototype.handleTrimmerDrag = function ( aPoint )
{
    var dx = aPoint.x - this.startPointData.x;
    var constrainedDelta = Math.min( Math.max( this.startPointData.targetOriginX + dx, this.activeHandle.min ), this.activeHandle.max )
                           - this.activeHandle.x;

    for ( var i = 0; i < this.activeHandle.targets.length; i++ )
    {
        this.activeHandle.targets[i].x += constrainedDelta;
    }

    var theClipTime = this.getVideoTimelineValueFromHandle( this.activeHandle );

    this.videoClipObject.setClipTime( theClipTime );

    this.updateTrimmerData();

    let shouldReportTrimDurationChanged = (this.activeHandle === this.trimmer_start ||
                                           this.activeHandle === this.trimmer_end) &&
                                          typeof this.onTrimSelectionDragged === "function";
    if ( shouldReportTrimDurationChanged )
    {
        var trimmerData = this.trimmerData || this.getTrimmerData();

        this.onTrimSelectionDragged( Math.floor( (trimmerData.end - trimmerData.start) * 1000 ) );
        this.durationLabel.setDuration( trimmerData.duration );
    }
};

TrimmerTimeline.prototype.handleTrimmerDragEnd = function ( aPoint )
{
    var didTrimChange = this.activeHandle == this.trimmer_start || this.activeHandle == this.trimmer_end;
    this.activeHandle = null;
    this.startPointData = null;
    this.updateVideoClipRangeToTrimmerHandlePositions();
    this.updateHandleBounds();

    if ( typeof this.onTrimSelectionCompleted === "function" )
    {
        this.onTrimSelectionCompleted();
    }

    if ( this.videoClipObject.isSeekingWhenPreviouslyPlaying() )
    {
        if ( didTrimChange )
        {
            this.playVideoFromTrimPosition();
        }
        else
        {
            this.videoClipObject.play();
        }
    }
    else
    {
        this.videoClipObject.setState( STATE_PAUSED );
    }
};

TrimmerTimeline.prototype.playVideoFromTrimPosition = function ()
{
    this.videoClipObject.setClipTime( this.getVideoTimelineValueFromHandle( this.trimmer_start ) );
    this.videoClipObject.play();
};

///////////////////////////////////////////
///////////////////////////////////////////
/// M U T E // B U T T O N ////////////////
///////////////////////////////////////////
///////////////////////////////////////////

var MuteButton = function ( args )
{
    this.x = 0;
    this.y = 0;
    this.width = 50;
    this.height = 50;

    this.videoClipObject = null;

    for ( var arg in args )
    {
        this[arg] = args[arg];
    }

    if ( this.mutedImageUrl && this.unmutedImageUrl )
    {
        this.mutedImage = new Image();
        this.mutedImage.src = this.mutedImageUrl;

        this.unmutedImage = new Image();
        this.unmutedImage.src = this.unmutedImageUrl;
    }
    if ( this.audioNoneImageUrl )
    {
        this.audioNoneImage = new Image();
        this.audioNoneImage.src = this.audioNoneImageUrl;
    }
}

MuteButton.prototype.renderToCanvas = function ( aCanvasContext )
{
    if ( this.videoClipObject.isPlaying() )
    {
        var videoIsMuted = this.videoClipObject.video.muted;
        var videoHasAudio = this.videoClipObject.hasAudio();
        if ( !videoHasAudio )
        {
            aCanvasContext.drawImage(
                this.audioNoneImage,
                this.x,
                this.y,
                this.width,
                this.height
            );
        }
        else if ( videoIsMuted && this.mutedImage && this.mutedImage.width )
        {
            aCanvasContext.drawImage(
                this.mutedImage,
                this.x,
                this.y,
                this.width,
                this.height
            );
        }
        else if ( !videoIsMuted && this.unmutedImage && this.unmutedImage.width )
        {
            aCanvasContext.drawImage(
                this.unmutedImage,
                this.x,
                this.y,
                this.width,
                this.height
            );
        }
    }
};

MuteButton.prototype.pointHitTest = function ( aPoint )
{
    return aPoint.x > this.x && aPoint.x < this.x + this.width && aPoint.y > this.y && aPoint.y < this.y + this.height;
};

MuteButton.prototype.handleInteractionStart = function ()
{
    this.toggleVideoMute();
};

MuteButton.prototype.toggleVideoMute = function ()
{
    const shouldMute = !this.videoClipObject.video.muted;
    this.videoClipObject.video.muted = shouldMute;

    if ( !shouldMute )
    {
        showSafariAudioSyncWarningToast();
    }
};

MuteButton.prototype.updateForElapsedTime = function ( elapsedTimeBetweenFrames )
{
    // do nothing
};

///////////////////////////////////////////
///////////////////////////////////////////
/// D U R A T I O N // L A B E L ////////////////
///////////////////////////////////////////
///////////////////////////////////////////

var DurationLabel = function ( args )
{
    this.x = 0;
    this.y = 0;
    this.duration = 0;

    if ( args )
    {
        for ( var arg in args )
        {
            this[arg] = args[arg];
        }
    }
}

DurationLabel.prototype.renderToCanvas = function ( aCanvasContext )
{
    var text = getFormattedDurationTimeString( this.duration );
    aCanvasContext.font = "15px Montserrat";
    aCanvasContext.fillStyle = "black";
    aCanvasContext.textAlign = "center";

    aCanvasContext.fillText( text, this.x, this.y );
};

DurationLabel.prototype.updateForElapsedTime = function ( elapsedTimeBetweenFrames )
{
    // do nothing
};

DurationLabel.prototype.setDuration = function ( aDuration )
{
    this.duration = aDuration;
};

///////////////////////////////////////
///////////////////////////////////////
/// H E L P E R // F U N C T I O N S //
///////////////////////////////////////
///////////////////////////////////////

function createDelegate( aScope, aFunction )
{
    return function ()
    {
        return aFunction.apply( aScope, arguments );
    };
}

function createExtendedDelegate( aScope, aFunction, anArgumentArray )
{
    return function ()
    {
        return aFunction.apply( aScope, anArgumentArray );
    };
}

function getDistanceBetweenPoints( p1, p2 )
{
    return p1 && p2 ?
           Math.sqrt( Math.pow( p2.x - p1.x, 2 ) + Math.pow( p2.y - p1.y, 2 ) ) :
           0;
}

function getAngleBetweenPoints( p1, p2 )
{
    return Math.atan2( p2.x - p1.x, p1.y - p2.y );
}

if ( window.CanvasRenderingContext2D )
{

    CanvasRenderingContext2D.prototype.clearAll = function ()
    {
        this.clearRect( 0, 0, this.canvas.width, this.canvas.height );
    };

    CanvasRenderingContext2D.prototype.fillAll = function ( aColor )
    {
        this.globalAlpha = 1;
        this.globalCompositeOperation = 'source-over';
        this.fillStyle = aColor || '#000000';
        this.fillRect( 0, 0, this.canvas.width, this.canvas.height );
    };

    CanvasRenderingContext2D.prototype.roundRect = function ( x, y, w, h, rad )
    {
        rad = isNaN( rad ) ? 5 : rad;
        this.beginPath();
        this.moveTo( x + rad, y );
        this.arcTo( x + w, y, x + w, y + h, rad );
        this.arcTo( x + w, y + h, x, y + h, rad );
        this.arcTo( x, y + h, x, y, rad );
        this.arcTo( x, y, x + w, y, rad );
    };

    CanvasRenderingContext2D.prototype.fillRoundRect = function ( x, y, w, h, rad )
    {
        this.roundRect( x, y, w, h, rad );
        this.fill();
    };
}

/////////////////////////////////////////
/////////////////////////////////////////

(function ( aFallbackFrameRate )
{
    var theRequestAnimationFrame = window.requestAnimationFrame ||
                                   window.webkitRequestAnimationFrame ||
                                   window.mozRequestAnimationFrame ||
                                   window.oRequestAnimationFrame ||
                                   window.msRequestAnimationFrame;

    var theCancelAnimationFrame = window.cancelAnimationFrame ||
                                  window.webkitCancelAnimationFrame ||
                                  window.mozCancelAnimationFrame ||
                                  window.oCancelAnimationFrame ||
                                  window.msCancelAnimationFrame;

    if ( theRequestAnimationFrame )
    {
        window.requestAnimationFrame = theRequestAnimationFrame;
        window.cancelAnimationFrame = theCancelAnimationFrame;
    }
    else
    {
        var theFallbackFramerate = aFallbackFrameRate ? aFallbackFrameRate : 60;
        var theTimeoutDuration = 1000 / theFallbackFramerate;
        window.requestAnimationFrame = function ( aCallback )
        {
            var id = window.setTimeout( aCallback, theTimeoutDuration );
            return id;
        };
        window.cancelAnimationFrame = function ( anId )
        {
            clearTimeout( anId );
        };
    }
})();

//////////////////////////////////////////////////
//////////////////////////////////////////////////
/// I N T E R A C T I O N // D E L E G A T E /////
//////////////////////////////////////////////////
//////////////////////////////////////////////////

var CLICK_TIME_THRESHOLD = 250;
var EXTENDED_HOLD_TIME = 800;

var InteractionDelegate = function ( aTargetElement, aResetCallbackStackObject, args )
{
    this.surface = aTargetElement;
    this.resetState = aResetCallbackStackObject;
    this.lastClick = -1;
    this.interactionHoldTimeout = -1;
    this.interactionScale = 1;
    this.clearCallbackStack();

    this.mouseDownDelegate = createDelegate( this, this.mouseDown );
    this.mouseMoveDelegate = createDelegate( this, this.mouseMove );
    this.mouseUpDelegate = createDelegate( this, this.mouseUp );
    this.touchStartDelegate = createDelegate( this, this.touchStart );
    this.touchMoveDelegate = createDelegate( this, this.touchMove );
    this.touchEndDelegate = createDelegate( this, this.touchEnd );
    this.mouseEnterDelegate = createDelegate( this, this.mouseEnter );
    this.mouseLeaveDelegate = createDelegate( this, this.mouseLeave );

    if ( this.surface && this.resetState )
    {
        this.updateSurfaceOffset();
        this.resetInteraction( this.resetState );

        aTargetElement.addEventListener( 'mousedown', this.mouseDownDelegate );
        aTargetElement.addEventListener( 'mousemove', this.mouseMoveDelegate );
        aTargetElement.addEventListener( 'mouseup', this.mouseUpDelegate );
        aTargetElement.addEventListener( 'touchstart', this.touchStartDelegate );
        aTargetElement.addEventListener( 'touchmove', this.touchMoveDelegate );
        aTargetElement.addEventListener( 'touchend', this.touchEndDelegate );
        aTargetElement.addEventListener( 'mouseenter', this.mouseEnterDelegate );
        aTargetElement.addEventListener( 'mouseleave', this.mouseLeaveDelegate );

        // this.onResizeDelegate = createDelegate( this, this.updateSurfaceOffset );
        // window.addEventListener('resize', this.onResizeDelegate );
    }

    for ( var arg in args )
    {
        this[arg] = args[arg];
    }
};

InteractionDelegate.prototype.cleanUp = function ()
{
    window.removeEventListener( 'resize', this.onResizeDelegate );
    this.surface.removeEventListener( 'mousedown', this.mouseDownDelegate );
    this.surface.removeEventListener( 'mousemove', this.mouseMoveDelegate );
    this.surface.removeEventListener( 'mouseup', this.mouseUpDelegate );
    this.surface.removeEventListener( 'touchstart', this.touchStartDelegate );
    this.surface.removeEventListener( 'touchmove', this.touchMoveDelegate );
    this.surface.removeEventListener( 'touchend', this.touchEndDelegate );
    this.surface.removeEventListener( 'mouseenter', this.mouseEnterDelegate );
    this.surface.removeEventListener( 'mouseleave', this.mouseLeaveDelegate );
    this.surface = null;
    this.resetState = null;
    clearTimeout( this.interactionHoldTimeout );
    this.clearCallbackStack();
};

InteractionDelegate.prototype.updateSurfaceOffset = function ()
{
    var offset = this.surface.getBoundingClientRect();
    this.surfaceOffset = {
        x: offset.left + window.pageXOffset,
        y: offset.top + window.pageYOffset,
        width: offset.width,
        height: offset.height
    };
};

InteractionDelegate.prototype.resetInteraction = function ( aResetCallbackConfiguration )
{
    this.callback( 'reset', this.lastPointData );

    this.startPointData = null;
    this.lastPointData = null;
    clearTimeout( this.interactionHoldTimeout );
    this.clearCallbackStack();

    for ( var item in aResetCallbackConfiguration )
    {
        this.setCallbackStackItem( item, aResetCallbackConfiguration[item] );
    }
};

InteractionDelegate.prototype.clearCallbackStack = function ()
{
    this.callbackStack = {
        start: [],
        move: [],
        end: [],
        rollOut: [],
        gestureStart: [],
        gestureChange: [],
        gestureEnd: [],
        gestureBailOut: [],
        click: [],
        extendedHold: [],
        reset: []
    };
};

InteractionDelegate.prototype.setCallbackStackItem = function ( aStackKey, aStackObject )
{
    if ( typeof aStackObject === 'function' )
    {
        this.addItemToStack( aStackKey, aStackObject );
    }
    else if ( Array.isArray( aStackObject ) )
    {
        for ( var i = 0; i < aStackObject.length; i++ )
        {
            this.setCallbackStackItem( aStackKey, aStackObject[i] );
        }
    }
};

InteractionDelegate.prototype.removeCallbackStackItem = function ( aStackKey, aStackCallback )
{
    var theStackIndex = this.callbackStack[aStackKey].indexOf( aStackCallback );
    if ( theStackIndex !== -1 )
    {
        this.callbackStack[aStackKey].splice( theStackIndex, 1 );
    }
};

InteractionDelegate.prototype.addItemToStack = function ( aStackKey, aStackCallback )
{
    if ( this.callbackStack[aStackKey].indexOf( aStackCallback ) === -1 )
    {
        this.callbackStack[aStackKey].push( aStackCallback );
    }
};

//////////////// E V E N T /// P A R S E R S ///////////////////////////////////////////

InteractionDelegate.prototype.getMouseEventPosition = function ( anEvent )
{
    return {
        x: (anEvent.pageX / this.interactionScale) - this.surfaceOffset.x,
        y: (anEvent.pageY / this.interactionScale) - this.surfaceOffset.y,
        time: anEvent.timeStamp
    };
};

InteractionDelegate.prototype.getTouchEventPosition = function ( anEvent, allowDefault )
{
    if ( !allowDefault )
    {
        anEvent.preventDefault();
    }

    if ( anEvent.touches.length > 1 )
    {
        return this.getMultiTouchEventPosition( anEvent );
    }
    else
    {
        return this.getSingleTouchEventPosition( anEvent );
    }
};

InteractionDelegate.prototype.getSingleTouchEventPosition = function ( anEvent )
{
    return {
        x: (anEvent.changedTouches[0].pageX / this.interactionScale) - this.surfaceOffset.x,
        y: (anEvent.changedTouches[0].pageY / this.interactionScale) - this.surfaceOffset.y,
        time: anEvent.timeStamp
    };
};

InteractionDelegate.prototype.getMultiTouchEventPosition = function ( anEvent )
{
    return {
        x1: (anEvent.touches[0].pageX / this.interactionScale) - this.surfaceOffset.x,
        y1: (anEvent.touches[0].pageY / this.interactionScale) - this.surfaceOffset.y,
        x2: (anEvent.touches[1].pageX / this.interactionScale) - this.surfaceOffset.x,
        y2: (anEvent.touches[1].pageY / this.interactionScale) - this.surfaceOffset.y,
        x: (anEvent.pageX / this.interactionScale) - this.surfaceOffset.x,
        y: (anEvent.pageY / this.interactionScale) - this.surfaceOffset.y,
        time: anEvent.timeStamp
    };
};

////////////// E V E N T /// H A N D L E R S //////////////////////////////////////

InteractionDelegate.prototype.mouseDown = function ( anEvent )
{
    this.handleInteractionStart( this.getMouseEventPosition( anEvent ) );
};

InteractionDelegate.prototype.mouseMove = function ( anEvent )
{
    this.handleInteractionMove( this.getMouseEventPosition( anEvent ) );
};

InteractionDelegate.prototype.mouseUp = function ( anEvent )
{
    this.handleInteractionEnd( this.getMouseEventPosition( anEvent ) );
};

InteractionDelegate.prototype.mouseEnter = function ( anEvent )
{
    // do nothing for now
};

InteractionDelegate.prototype.mouseLeave = function ( anEvent )
{
    if ( anEvent.which )
    {
        this.mouseUp( anEvent );
    }
};

InteractionDelegate.prototype.touchStart = function ( anEvent )
{
    if ( anEvent.touches.length > 2 )
    {
        this.handleGestureBailOut( this.getTouchEventPosition( anEvent ) );
        this.resetInteraction( this.resetState );
        return false;
    }

    var targetElementIsInput = anEvent.target.nodeName === 'INPUT' || anEvent.target.nodeName === 'TEXTAREA';

    if ( anEvent.touches.length === 1 )
    {
        this.handleInteractionStart( this.getTouchEventPosition( anEvent, targetElementIsInput ) );
    }
    else if ( anEvent.touches.length === 2 )
    {
        clearTimeout( this.interactionHoldTimeout );
        this.handleGestureStart( this.getTouchEventPosition( anEvent ) );
    }

    if ( !targetElementIsInput && document.activeElement.nodeName === 'INPUT' || document.activeElement.nodeName === 'TEXTAREA' )
    {
        document.activeElement.blur();
    }
};

InteractionDelegate.prototype.touchMove = function ( anEvent )
{
    if ( anEvent.touches.length === 1 )
    {
        this.handleInteractionMove( this.getTouchEventPosition( anEvent ) );
    }
    else if ( anEvent.touches.length === 2 )
    {
        this.handleGestureChange( this.getTouchEventPosition( anEvent ) );
    }
};

InteractionDelegate.prototype.touchEnd = function ( anEvent )
{
    if ( anEvent.touches.length === 1 )
    {
        // this parsing gets the touches list rather than the default 'changedTouches' list for releasing 1 of 2 touches
        var theTouchEvent = this.getMouseEventPosition( anEvent.touches[0] );
        theTouchEvent.time = anEvent.timeStamp;
        this.handleGestureEnd( theTouchEvent );
    }
    else if ( anEvent.touches.length === 0 )
    {
        var simultaneousMultiTouchEnd = !this.startPointData;
        if ( !simultaneousMultiTouchEnd )
        {
            var theEventPoint = this.getTouchEventPosition( anEvent );
            var lastInteractionWasMultiTouch = !!this.lastPointData.x2;
            if ( lastInteractionWasMultiTouch )
            {
                this.handleGestureEnd( theEventPoint );
            }
            this.handleInteractionEnd( theEventPoint );
        }
    }
};

////////////// I N T E R A C T I O N /// H A N D L E R S //////////////////////////

InteractionDelegate.prototype.callback = function ( aStackKey, aPointData )
{
    var theStack = this.callbackStack[aStackKey];
    for ( var i = 0; i < theStack.length; i++ )
    {
        if ( theStack[i]( aPointData, this ) )
        {
            break;
        }
    }
    this.lastPointData = aPointData;
};

InteractionDelegate.prototype.handleInteractionStart = function ( aPoint )
{
    this.startPointData = aPoint;
    this.callback( 'start', aPoint );

    clearTimeout( this.interactionHoldTimeout );
    this.interactionHoldTimeout = setTimeout( createExtendedDelegate( this, this.handleExtendedHold, [aPoint] ), EXTENDED_HOLD_TIME );
};

InteractionDelegate.prototype.handleInteractionMove = function ( aPoint )
{
    this.callback( 'move', aPoint );
};

InteractionDelegate.prototype.handleInteractionEnd = function ( aPoint )
{
    if ( this.startPointData &&
         aPoint.time - this.startPointData.time < CLICK_TIME_THRESHOLD &&
         getDistanceBetweenPoints( this.startPointData, aPoint ) < 10 )
    {
        this.handleClickInteraction( aPoint );
    }
    this.callback( 'end', aPoint );
    this.resetInteraction( this.resetState );
};

InteractionDelegate.prototype.handleGestureStart = function ( aGesturePoints )
{
    var touch1 = {x: aGesturePoints.x1, y: aGesturePoints.y1};
    var touch2 = {x: aGesturePoints.x2, y: aGesturePoints.y2};

    this.startPointData = aGesturePoints;
    this.startPointData.gesture = {
        distance: getDistanceBetweenPoints( touch1, touch2 ),
        angle: getAngleBetweenPoints( touch1, touch2 )
    };

    this.callback( 'gestureStart', aGesturePoints );
};

InteractionDelegate.prototype.handleGestureChange = function ( aGesturePoints )
{
    if ( !this.callbackStack.gestureChange.length )
    {
        return false;
    }

    var theGestureData = aGesturePoints;
    var touch1 = {x: theGestureData.x1, y: theGestureData.y1};
    var touch2 = {x: theGestureData.x2, y: theGestureData.y2};

    var distance = getDistanceBetweenPoints( touch1, touch2 );
    var angle = getAngleBetweenPoints( touch1, touch2 );

    theGestureData.distance = distance - this.startPointData.gesture.distance; // / this.startPointData.gesture.distance;
    theGestureData.rotation = angle - this.startPointData.gesture.angle;

    this.callback( 'gestureChange', theGestureData );
};

InteractionDelegate.prototype.handleGestureEnd = function ( aGesturePoints )
{
    this.callback( 'gestureEnd', aGesturePoints );
};

InteractionDelegate.prototype.handleGestureBailOut = function ( aPoint )
{
    this.callback( 'gestureBailOut', aPoint );
};

InteractionDelegate.prototype.handleClickInteraction = function ( aPoint )
{
    this.callback( 'click', aPoint );
    this.lastClick = aPoint;
};

InteractionDelegate.prototype.handleExtendedHold = function ( aPoint )
{
    if ( this.startPointData &&
         getDistanceBetweenPoints( this.startPointData, this.lastPointData ) < 5 )
    {
        this.callback( 'extendedHold', aPoint );
    }
};
