import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
    getControlDataForDesign,
    getDesignAspectRatio,
    getDesignControlsConfig,
    getInitialControlDataForCurrentSelectedDesign,
    getSelectedDesign,
    isCurrentDesignFoundation,
    isStaticOutputMode,
} from "../../ducks/mixModel";
import { DesignSpecificControls, DesignSpecificControlsDispatchProps, DesignSpecificControlsProps } from "../DesignSpecificControls";
import * as speakerIcon from "../../assets/img/speakerIcon.png";
import { getAudioPlayerMusicTrack } from "../../ducks/ui";
import { StoreState, UIEditorType } from "../../_types";
import { mixModelServices } from "../../services";
import { isContentContributor } from "../../ducks/user";
import { apptimizeVariables } from "../../helpers";

const mapStateToProps = ( storeState: StoreState ): DesignSpecificControlsProps =>
{
    const design = getSelectedDesign( storeState );
    const isFoundationDesignSelected = isCurrentDesignFoundation( storeState );

    return {
        controlData: getControlDataForDesign( storeState, design ),
        controlConfigs: getDesignControlsConfig( storeState, design ),
        aspectRatio: getDesignAspectRatio( storeState ),
        dynamicImage: getAudioPlayerMusicTrack( storeState ) ? speakerIcon : undefined,
        isStaticOutputMode: isStaticOutputMode( storeState ),
        initialControlDataForCurrentSelectedDesign: getInitialControlDataForCurrentSelectedDesign( storeState ),
        showPresetsControl: isContentContributor( storeState ) && apptimizeVariables.shouldEnableTextAnimations() && isFoundationDesignSelected,
        isFoundationDesignSelected: isCurrentDesignFoundation( storeState ),
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): DesignSpecificControlsDispatchProps =>
{
    return {
        onControlClicked: ( editorType: UIEditorType, controlId?: string ) =>
        {
            mixModelServices.changeDesignControl( editorType, controlId );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( DesignSpecificControls );
