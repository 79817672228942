import { createAction } from "redux-actions";
import { ActionCreator } from "../helpers";
import { LinkState } from "../_types";

const actionCreator = new ActionCreator( "EPIDEMIC_SOUND_COLLECTIONS" );

const epidemicSoundCollectionsActionTypes = {
    load: actionCreator.generateTypes( "LOAD" ),
    clear: actionCreator.generateType( "COLLECTIONS", "CLEAR" ),
    activeCollectionIdSet: actionCreator.generateType( "ACTIVE_COLLECTION_ID", "SET" ),
    activeCollectionIdClear: actionCreator.generateType( "ACTIVE_COLLECTION_ID", "CLEAR" ),
    activeCollectionTrackIdsSet: actionCreator.generateType( "ACTIVE_COLLECTION_TRACK_IDS", "SET" ),
    activeCollectionTrackIdsClear: actionCreator.generateType( "ACTIVE_COLLECTION_TRACK_IDS", "CLEAR" ),
};

export interface EpidemicSoundCollectionsPayload
{
    data: NormalizrData;
    links: LinkState;
}

export const epidemicSoundCollectionActions = {
    loadCollectionsSuccess: createAction<EpidemicSoundCollectionsPayload>( epidemicSoundCollectionsActionTypes.load.success ),
    loadCollectionsFailure: createAction<string>( epidemicSoundCollectionsActionTypes.load.failure ),
    clearCollections: createAction( epidemicSoundCollectionsActionTypes.clear ),
    activeCollectionIdSet: createAction<string>( epidemicSoundCollectionsActionTypes.activeCollectionIdSet ),
    activeCollectionIdClear: createAction( epidemicSoundCollectionsActionTypes.activeCollectionIdClear ),
    activeCollectionTrackIdsSet: createAction<string[]>( epidemicSoundCollectionsActionTypes.activeCollectionTrackIdsSet ),
    activeCollectionTrackIdsClear: createAction( epidemicSoundCollectionsActionTypes.activeCollectionTrackIdsClear ),
};
