import { applyMiddleware, combineReducers, compose, createStore, Reducer, StoreEnhancer, StoreEnhancerStoreCreator } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { reducers } from "../ducks";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { ActionCreator, IS_DEVELOPMENT, IS_STAGING, LOGIN_URL } from "../helpers";
import { createMigrate, PersistConfig, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage/index";
import { createAction } from "redux-actions";
import {
    mixModelMigrations,
    rootMigrations,
    shareModelMigrations,
    stockMediaMigrations,
    trackingMigrations,
    uiMigrations,
} from "./migrations";
import { LoginState, StoreState } from "../_types";
import { composeWithDevTools } from "redux-devtools-extension";
import { createBrowserHistory } from "history";

const STORE_VERSION = 8;
// @ts-ignore
const isTesting = global.IS_TESTING === true;
const persistConfig: PersistConfig = {
    key: "root",
    version: STORE_VERSION,
    storage,
    blacklist: ["ui", "mixModel", "shareModel", "router", "modals", "tracking", "pricing"],
    migrate: createMigrate( rootMigrations ),
};

const { mixModel, shareModel, stockMedia, ui, tracking, ...remainingReducers } = reducers;
const history = createBrowserHistory();

let initialLocation = null;
if ( !isTesting )
{
    initialLocation = history.location;
    history.replace( LOGIN_URL ); // GROW-13226, temporary fix
}

const rootReducer = combineReducers<StoreState>( {
    router: connectRouter( history ),
    ...remainingReducers,
    stockMedia: persistReducer( {
        key: "stockMedia",
        version: STORE_VERSION,
        storage,
        migrate: createMigrate( stockMediaMigrations ),
    }, stockMedia ),
    mixModel: persistReducer( {
        key: "mixModel",
        version: STORE_VERSION,
        storage,
        blacklist: ["imageData", "mediaUploads"],
        migrate: createMigrate( mixModelMigrations ),
    }, mixModel ),
    shareModel: persistReducer( {
        key: "shareModel",
        version: STORE_VERSION,
        storage,
        blacklist: ["snapshotUrl"],
        migrate: createMigrate( shareModelMigrations ),
    }, shareModel ),
    ui: persistReducer( {
        key: "ui",
        version: STORE_VERSION,
        storage,
        whitelist: [
            "customizeTipBanners",
            "hasSeenAfterShareDialog",
            "hasSeenRiplUserSurvey2023",
            "coachMarks",
        ],
        migrate: createMigrate( uiMigrations ),
    }, ui ),
    tracking: persistReducer( {
        key: "tracking",
        version: STORE_VERSION,
        storage,
        migrate: createMigrate( trackingMigrations ),
    }, tracking ),
} );

if ( initialLocation )
{
    history.replace( initialLocation ); // GROW-13226, temporary fix
}

const persistedReducer = persistReducer( persistConfig, rootReducer );

const actionCreator = new ActionCreator( "APP" );
const appActionsTypes = {
    state: {
        reset: actionCreator.generateType( "STATE", "RESET" ),
    },
};
export const appActions = {
    reset: createAction( appActionsTypes.state.reset ),
};

function resetEnhancer(): StoreEnhancer<StoreState>
{
    return ( next: StoreEnhancerStoreCreator<StoreState> ) => ( reducer: Reducer<StoreState>, initialState ) =>
    {
        const resetType = appActionsTypes.state.reset;

        const enhanceReducer = ( state, action ) =>
        {
            if ( action.type === resetType )
            {
                const { _persist } = state;
                const login: LoginState = { hasLoggedInBefore: true };
                state = { _persist, login };
            }
            return reducer( state, action );
        };

        return next( enhanceReducer, initialState );
    };
}

const middlewares = [thunkMiddleware, routerMiddleware( history )];

if ( !isTesting && (IS_STAGING || IS_DEVELOPMENT) )
{
    const loggerMiddleware = createLogger();
    middlewares.push( loggerMiddleware );
}

const enhancersForLocalDevelopment = IS_DEVELOPMENT ? composeWithDevTools( { trace: true } ) : ( f ) => f;

const enhancedCreateStore = compose<StoreEnhancerStoreCreator<StoreState>>(
    enhancersForLocalDevelopment(
        applyMiddleware( ...middlewares ),
    ),
    resetEnhancer(),
)
( createStore );

export const store = enhancedCreateStore( persistedReducer );
export const persistor = persistStore( store );
