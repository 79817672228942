import { connect } from "react-redux";
import { Dispatch } from "redux";
import { BrandLogo, StoreState } from "../../../_types";
import { RecentBrandLogo, RecentBrandLogoDispatchProps, RecentBrandLogoProps } from "../../settingsPages/RecentBrandLogo";
import { userBusinessServices } from "../../../services";
import { mixModelActions } from "../../../actions";
import { getWatermarkUrl } from "../../../ducks/mixModel";

interface RecentBrandLogoContainerProps
{
    brandLogo: BrandLogo;
    onBrandLogoSwitched();
}

const mapStateToProps = ( storeState: StoreState, ownProps: RecentBrandLogoContainerProps ): RecentBrandLogoProps =>
{
    const { brandLogo } = { ...ownProps };
    const waterMarkUrl = getWatermarkUrl(storeState);
    return {
        brandLogo,
        isSelected: brandLogo.url === waterMarkUrl,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: RecentBrandLogoContainerProps ): RecentBrandLogoDispatchProps =>
{
    return {
        handleLogoClicked( brandLogo: BrandLogo )
        {
            dispatch( userBusinessServices.update( { photo_id: brandLogo.id } ) );
            dispatch( mixModelActions.watermarkEnabled() );
            if ( ownProps.onBrandLogoSwitched )
            {
                ownProps.onBrandLogoSwitched();
            }
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( RecentBrandLogo );
