import { connect } from "react-redux";
import { Dispatch } from "redux";
import { BrandLogo, StoreState } from "../../../_types";
import { RecentBrandLogo, RecentBrandLogoDispatchProps, RecentBrandLogoProps } from "../RecentBrandLogo";

interface ManageBrandLogoContainerProps
{
    brandLogo: BrandLogo;
    isSelected: boolean;
    onLogoClick( brandLogo: BrandLogo );
}

const mapStateToProps = ( storeState: StoreState, ownProps: ManageBrandLogoContainerProps ): RecentBrandLogoProps =>
{
    const { brandLogo, isSelected } = { ...ownProps };
    return {
        brandLogo,
        isSelected,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState>, ownProps: ManageBrandLogoContainerProps ): RecentBrandLogoDispatchProps =>
{
    return {
        handleLogoClicked: ownProps.onLogoClick,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( RecentBrandLogo );
