import { Action } from "redux-actions";
import { catalogActions, ControlsConfigsLookup, DesignControlsJSONData, designsActions } from "../actions";
import { designsSchema, ReducerCreator, updateLookupWithAction } from "../helpers";
import { defaults, find, first, size, values } from "lodash";
import { createDefaultDataLookup } from "./dataLookup";
import { Design, DesignState, StoreState } from "../_types";
import { ControlsConfig } from "../_types/api";

const defaultState: DesignState = {
    ...createDefaultDataLookup(),
    controlsConfigsByDesignId: {},
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( catalogActions.loadCatalogSuccess, updateDesignData );
reducerCreator.addAction( designsActions.loadControlsSuccess, handleControlsLoaded );
export default reducerCreator.createReducer();

function updateDesignData( state: DesignState, action: Action<NormalizrData> ): DesignState
{
    return updateLookupWithAction( designsSchema, action, state );
}

function handleControlsLoaded( state: DesignState, action: Action<DesignControlsJSONData> ): DesignState
{
    const { designId } = action.payload;
    return {
        ...state,
        controlsConfigsByDesignId: {
            ...state.controlsConfigsByDesignId,
            [designId]: action.payload.controls,
        },
    };
}

export function getDesign( state: StoreState, designId: number ): Design
{
    if ( designId && state.designs && state.designs.idToObj )
    {
        return state.designs.idToObj[designId];
    }
}

export function getBrandSlideDesign( state: StoreState ): Design
{
    const endCardDesignId = first( state.catalog.endCards );
    return getDesign( state, endCardDesignId );
}

export function getControlsConfigsByDesignId( state: StoreState, designId: number ): ControlsConfig[]
{
    return state.designs.controlsConfigsByDesignId[designId];
}

export function getControlsConfigsLookup( state: StoreState ): ControlsConfigsLookup
{
    return state.designs.controlsConfigsByDesignId;
}

export function getDesignIdFromSlug( state: StoreState, designSlug: string ): number
{
    const design = getDesignFromSlug( state, designSlug );
    if ( design )
    {
        return design.id;
    }
}

export function getDesignFromSlug( state: StoreState, designSlug: string ): Design
{
    return defaults( {}, find( values( state.designs.idToObj ), { slug: designSlug } ) );
}

export function isDesignFree( state: StoreState, designSlug: string ): boolean
{
    const design = getDesignFromSlug( state, designSlug );
    if ( design )
    {
        return design.free_flag;
    }
    return false;
}

export function getDesignNameByDesignId( state: StoreState, designId: number )
{
    const design = getDesign( state, designId );
    if ( design )
    {
        return design.name;
    }
}

export function getDesignSlugByDesignId( state: StoreState, designId: number )
{
    const design = getDesign( state, designId );
    if ( design )
    {
        return design.slug;
    }
}

export const designsHaveLoaded = ( state: StoreState ): boolean => size( state.designs.ids ) > 0;
