import * as React from "react";
import DialogContainer from "react-md/lib/Dialogs/DialogContainer";
import Button from "react-md/lib/Buttons/Button";
import DialogTitle from "react-md/lib/Dialogs/DialogTitle";
import { FacebookGroupAddRiplDialogData, SocialNetworkAccount } from "../_types";

export interface FacebookGroupAddRiplDialogProps
{
    facebookGroupAddRiplDialogData: FacebookGroupAddRiplDialogData;
    onComplete?: () => void;
}

export interface FacebookGroupAddRiplDialogDispatchProps
{
    handleCloseModal: () => void;
    handleFacebookGroupAddedConfirm: ( account: SocialNetworkAccount ) => void;
}

interface FacebookGroupAddRiplDialogState
{
    actions: DialogAction[];
}

interface DialogAction
{
    onClick: () => void;
    primary: boolean;
    children: string;
    className: string;
    swapTheming: boolean;
}

/* tslint:disable:max-line-length */
export class FacebookGroupAddRiplDialog extends React.PureComponent<FacebookGroupAddRiplDialogProps & FacebookGroupAddRiplDialogDispatchProps, FacebookGroupAddRiplDialogState>
{
    private verifyGroupAction: DialogAction;

    constructor( props )
    {
        super( props );
        this.verifyGroupAction = {
            onClick: this.onConfirmClicked,
            primary: true,
            swapTheming: true,
            className: "dialogConfirm",
            children: "Confirm",
        };

        this.state = {
            actions: [this.verifyGroupAction],
        };
    }

    public render()
    {
        return (
            <DialogContainer
                id="facebook-group-add-ripl"
                className="facebookGroupAddRipl"
                visible={this.isModalOpen( this.props )}
                modal
                width={450}
                title={this.createTitleComponent()}
                onHide={this.props.handleCloseModal}
                actions={this.state.actions}
                focusOnMount={false}>
                {this.createAddRiplInstructions()}
            </DialogContainer>
        );
    }

    private onConfirmClicked = () =>
    {
        this.props.handleFacebookGroupAddedConfirm( this.props.facebookGroupAddRiplDialogData.facebookGroupAccount );
    }

    private createTitleComponent = () =>
    {
        return (
            <div>
                <Button className="closeX"
                        icon={true}
                        iconClassName="material-icons"
                        iconChildren="close"
                        onClick={this.props.handleCloseModal}/>
                <DialogTitle children="Connect Facebook Group"/>
            </div>
        );
    }

    private isModalOpen = ( props ) =>
    {
        return props.facebookGroupAddRiplDialogData
               && props.facebookGroupAddRiplDialogData.hasOwnProperty( "isOpen" )
               && props.facebookGroupAddRiplDialogData.isOpen;
    }

    private createAddRiplInstructions()
    {
        return (
            <div>
                <h3 className="instructionsIntro">In order to connect a group, Facebook requires you to add the Ripl App to your group settings.</h3>
                <h3 className="instructionsIntro">To add the Ripl App to your group, follow the steps below on your desktop:</h3>
                <ol className="instructions">
                    <li>From your news feed, click 'Groups' in the left menu and select your group.</li>
                    <li>Click 'Settings' or 'Group Settings' in the left-hand menu.</li>
                    <li>Scroll to the bottom of the page and click the pencil icon next to 'Apps' under 'Manage Advanced Settings'.</li>
                    <li>Select 'Add Apps'.</li>
                    <li>Search for 'Ripl' in the left-hand menu, click the Ripl App in the search results, then click 'Add'.</li>
                </ol>
            </div>
        );
    }
}
