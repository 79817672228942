import { connect } from "react-redux";
import { Dispatch } from "redux";
import { getEditedBusinessInfo } from "../../../ducks/ui";
import { StoreState } from "../../../_types";
import { BrandSettingsFontPicker, BrandSettingsFontPickerDispatchProps, BrandSettingsFontPickerProps } from "../BrandSettingsFontPicker";
import { getFontCssUrlFieldForBrand, getFontFieldForBrand, getFontUrlFieldForBrand } from "../../../helpers";
import { store } from "../../../store";
import {
    Brand,
    BRAND_SLIDE_FONT_ONE_FIELD,
    BRAND_SLIDE_FONT_ONE_URL_FIELD,
    BRAND_SLIDE_FONT_TWO_FIELD,
    BRAND_SLIDE_FONT_TWO_URL_FIELD,
    PRIMARY,
    SECONDARY,
} from "../../../_types/api";
import { getCurrentBusiness } from "../../../ducks/userBusiness";
import { userBusinessServices } from "../../../services";
import { uiActions } from "../../../actions";

const mapStateToProps = ( storeState: StoreState ): BrandSettingsFontPickerProps =>
{
    return {
        businessData: getEditedBusinessInfo( storeState ),
        showHeader: false,
    };
};

const mapDispatchToProps = ( dispatch: Dispatch<StoreState> ): BrandSettingsFontPickerDispatchProps =>
{
    return {
        onUpdatedFont: async () =>
        {
            const state = store.getState();
            await handleBrandFontUpdate( state, dispatch );
            dispatch( uiActions.clearBusinessInfo() );
            dispatch( userBusinessServices.loadBrandLogos() );
        },
    };
};

async function handleBrandFontUpdate( state, dispatch: Dispatch<StoreState> )
{
    await updateBrandFont( state, dispatch, PRIMARY, BRAND_SLIDE_FONT_ONE_FIELD, BRAND_SLIDE_FONT_ONE_URL_FIELD );
    await updateBrandFont( state, dispatch, SECONDARY, BRAND_SLIDE_FONT_TWO_FIELD, BRAND_SLIDE_FONT_TWO_URL_FIELD );
}

async function updateBrandFont( state, dispatch: Dispatch<StoreState>, brand: Brand, brandSlideFontKey: string, brandSlideFontUrlKey: string )
{
    const brandFontField = getFontFieldForBrand( brand );
    const brandFontUrlField = getFontUrlFieldForBrand( brand );
    const brandFontCssUrlField = getFontCssUrlFieldForBrand( brand );

    const currentBusiness = getCurrentBusiness( state );
    const editedBusiness = getEditedBusinessInfo( state );

    const initialFontName = currentBusiness[brandFontField];
    const newFontName = editedBusiness[brandFontField];
    const newFontUrl = editedBusiness[brandFontUrlField];
    const newFontCssUrl = editedBusiness[brandFontCssUrlField];

    if ( newFontName !== initialFontName )
    {
        const hasExistingBrandSlideFontSet = !!currentBusiness[brandSlideFontKey];
        const brandSlideFont = {
            [brandSlideFontKey]: hasExistingBrandSlideFontSet ? currentBusiness[brandSlideFontKey] : newFontName,
            [brandSlideFontUrlKey]: hasExistingBrandSlideFontSet ? currentBusiness[brandSlideFontUrlKey] : newFontUrl,
        };

        const newFontDataForBusinessUpdate = {
            [brandFontField]: newFontName,
            [brandFontUrlField]: newFontUrl,
            [brandFontCssUrlField]: newFontCssUrl,
            ...brandSlideFont,
        };
        await dispatch( userBusinessServices.update( newFontDataForBusinessUpdate ) );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( BrandSettingsFontPicker );
