import * as React from "react";
// @ts-ignore
import * as GiftboxConfettiAnimatedGif from "../../assets/img/giftbox-confetti.gif";
import * as GiftboxConfettiStatic from "../../assets/img/giftbox-confetti-static.png";
import TextField from "react-md/lib/TextFields/TextField";
import classNames from "classnames";
import { REFER_A_FRIEND_LINK } from "../../helpers";
import FadeIn from "../animations/FadeIn";

interface ReferAFriendPageState
{
    copied: boolean;
}

export interface ReferAFriendPageDispatchProps
{
    clearReferAFriendCoachMark: () => void;
}

export default class ReferAFriendPage extends React.PureComponent<ReferAFriendPageDispatchProps, ReferAFriendPageState>
{
    constructor( props )
    {
        super( props );
        this.state = { copied: false };
    }

    public componentDidMount()
    {
        this.props.clearReferAFriendCoachMark();
    }

    public render()
    {
        return (
            <div className="referAFriendPage">
                <img className="giftbox" src={this.state.copied ? GiftboxConfettiAnimatedGif : GiftboxConfettiStatic}/>
                <h1>Share Ripl with a friend</h1>
                <h3>Refer a small business owner and give them an<br/>exclusive deal on a Ripl subscription.</h3>
                <p className="callout">Copy the link below & share!</p>

                <div className="referUrlContainer" onClick={this.handleLinkCopied}>
                    <TextField
                        id="referAFriendLink"
                        className="referUrlTextField"
                        floating
                        value={REFER_A_FRIEND_LINK}
                        disabled={true}/>
                    <div className={classNames( "copyLinkCTA",
                        { copiedLink: this.state.copied } )}>
                        {this.state.copied ? "copied" : "copy"}
                    </div>
                </div>
                {this.state.copied &&
                 <FadeIn duration={500}>
                     <div className="copiedAlert">
                        <span className="copiedAlertText">Link copied. Simply paste this link in an email, text, or <br/>
                            direct message. Your friends are lucky to have you!</span>
                     </div>
                 </FadeIn>}
            </div>
        );
    }

    private handleLinkCopied = () =>
    {
        this.setState( { copied: true } );
        // @ts-ignore
        navigator.clipboard.writeText( REFER_A_FRIEND_LINK );
    }

}
