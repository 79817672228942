import { store } from "../store";
import {
    addCommonQueryParams,
    ENV_CONFIG,
    ERROR_TITLE_SORRY,
    eventTracker,
    LightboxDialogIdentifierForKey,
    normalizeMeAPI,
    railsStringify,
    SocialAccountConnectedSource,
} from "./";
import { getAvailableSocialNetworkAccounts, getCurrentUserId, hasValidConnectionToEpidemicSound } from "../ducks/user";
import { filter, includes, last, sortBy, toLower } from "lodash";
import { userActions } from "../actions";
import { modalServices, userServices } from "../services";
import { getCurrentBusinessId } from "../ducks/userBusiness";
import { OauthAccountType, SocialNetworkAccount, SocialNetworkAccountType } from "../_types";
import { EPIDEMIC_SOUND_ACCOUNT_TYPE, TWITTER_ACCOUNT_TYPE, YOUTUBE_ACCOUNT_TYPE } from "../_types/api";

export const oauth = {
    connectViaWindow,
    refreshViaWindow,
    cleanupListener,
};

export const AUTH_TYPE_CONNECT = "CONNECT_AUTH";
export const AUTH_TYPE_REFRESH = "REFRESH_AUTH";

export type AuthType = typeof AUTH_TYPE_CONNECT |
    typeof AUTH_TYPE_REFRESH;

// NOTE: The window.open() call currently limits the number of open windows to one,
// so the listener management code only allows one listener.

let registeredListener;

function cleanupListener()
{
    if ( registeredListener )
    {
        window.removeEventListener( "message", registeredListener );
    }
}

function refreshViaWindow( accountIdRequestedToBeRefreshed: number, accountType: SocialNetworkAccountType,
                           trackingSource: SocialAccountConnectedSource,
                           onCompleteCallback?: () => void )
{
    launchOauthViaWindow( accountType, trackingSource, AUTH_TYPE_REFRESH, accountIdRequestedToBeRefreshed, onCompleteCallback );
}

function connectViaWindow( accountType: OauthAccountType,
                           trackingSource: SocialAccountConnectedSource,
                           onCompleteCallback?: () => void )
{
    launchOauthViaWindow( accountType, trackingSource, AUTH_TYPE_CONNECT, null, onCompleteCallback );
}

function launchOauthViaWindow( accountType: OauthAccountType,
                               trackingSource: SocialAccountConnectedSource,
                               authType: AuthType,
                               accountIdRequestedToBeRefreshed: number,
                               onCompleteCallback?: () => void )
{
    const oauthWindow = window.open( getOauthUrl( accountType ),
        "socialNetworkOauthWindow" );
    if ( oauthWindow )
    {
        cleanupListener();
        const listener = messageListener( oauthWindow, accountType, authType, accountIdRequestedToBeRefreshed, onCompleteCallback, trackingSource );
        window.addEventListener( "message", listener, false );

        registeredListener = listener;
    }
    else
    {
        // TODO: handle popup BLOCKED!
    }
}

function getOauthUrl( accountType: OauthAccountType )
{
    const userId = getCurrentUserId( store.getState() );
    let url = `${ENV_CONFIG.baseUrl}/users/${userId}/client_${toLower( accountType )}_connect`;
    if ( includes( [TWITTER_ACCOUNT_TYPE, YOUTUBE_ACCOUNT_TYPE], accountType ) )
    {
        url += "_oauth";
    }

    const accountTypeSpecificQueryParams = getAccountTypeSpecificQueryParams( accountType );

    const queryParams = {
        force_login: true,
        ...accountTypeSpecificQueryParams,
    };

    url += "?" + railsStringify( addCommonQueryParams( queryParams ) );
    return url;
}

function getAccountTypeSpecificQueryParams( accountType )
{
    return {};
}

interface OauthMessage
{
    data: MeAPIData | string;
    origin: string;
}

const messageListener = ( popup: Window,
                          accountType: OauthAccountType,
                          authType: AuthType,
                          accountIdRequestedToBeRefreshed: number,
                          onCompleteCallback: () => void,
                          trackingSource: SocialAccountConnectedSource ) =>
{
    return ( event: OauthMessage ) =>
    {
        if ( event.origin !== ENV_CONFIG.baseUrl )
        {
            return;
        }

        popup.close();
        cleanupListener();

        const dispatch = store.dispatch;

        if ( typeof event.data === "string" )
        {
            eventTracker.logSocialAccountFailedToConnect(accountType, trackingSource );
            return dispatch( modalServices.openErrorDialog( ERROR_TITLE_SORRY, event.data ) );
        }

        if ( event && event.data && event.data.user )
        {
            const normalizedData = normalizeMeAPI( event.data );
            dispatch( userActions.meSuccess( normalizedData ) );

            eventTracker.setUserPropertiesForCurrentUser();
            eventTracker.logSocialAccountConnected( accountType, trackingSource );

            if ( accountType === EPIDEMIC_SOUND_ACCOUNT_TYPE )
            {
                if ( hasValidConnectionToEpidemicSound( store.getState() ) )
                {
                    dispatch( modalServices.openEpidemicUserCollectionsDialog() );
                }
                else
                {
                    dispatch( modalServices.openErrorDialogWithStandardFormat(
                        "An unknown error occurred while connecting your account", null, null, true ) );
                }
            }
            else
            {
                // we should determine this by account id available on server but currently not being sent down
                const accountJustAuthenticated = determineAccountJustAuthedByUpdatedTimestamp( accountType );
                if ( accountJustAuthenticated && authType === AUTH_TYPE_CONNECT )
                {
                    enableAccountOrAlertForConnect( accountJustAuthenticated, onCompleteCallback );
                }
                else if ( accountJustAuthenticated && authType === AUTH_TYPE_REFRESH )
                {
                    enableAccountOrAlertForRefresh( accountJustAuthenticated, accountIdRequestedToBeRefreshed, onCompleteCallback );
                }
                else
                {
                    eventTracker.logSocialAccountFailedToConnect(accountType, trackingSource );
                    dispatch( modalServices.openErrorDialogWithStandardFormat(
                        "An unknown error occurred while connecting your account", null, null, true ) );
                }
            }
        }
    };
};

function enableAccountOrAlertForConnect( accountJustAuthenticated: SocialNetworkAccount, onCompleteCallback: () => void )
{
    const state = store.getState();
    const userId = getCurrentUserId( state );
    const dispatch = store.dispatch;
    const currentBusinessId = getCurrentBusinessId( state );

    if ( currentBusinessId === accountJustAuthenticated.user_business_id )
    {
        dispatch( modalServices.openErrorDialogAddAccount( accountJustAuthenticated.account_type ) );
    }
    else if ( !accountJustAuthenticated.user_business_id )
    {
        dispatch( userServices.enableSocialNetworkAccounts( userId, [accountJustAuthenticated.id], currentBusinessId ) );
        if ( onCompleteCallback )
        {
            onCompleteCallback();
        }
    }
    else
    {
        const message = `You've already connected "${accountJustAuthenticated.name}" to another business, `
                        + `do you want to switch "${accountJustAuthenticated.name}" to this business?`;
        dispatch( modalServices.openLightbox( {
            identifierForKey: LightboxDialogIdentifierForKey.CONFIRM_MOVE_SOCIAL_NETWORK_ACCOUNT_TO_CURRENT_BUSINESS,
            content: message,
            title: "",
            confirmLabel: "Switch",
            width: 500,
            onSuccess: () =>
            {
                dispatch( userServices.enableSocialNetworkAccounts( userId, [accountJustAuthenticated.id], currentBusinessId ) );

                if ( onCompleteCallback )
                {
                    onCompleteCallback();
                }
            },
        } ) );
    }
}

function enableAccountOrAlertForRefresh( accountJustAuthenticated: SocialNetworkAccount, accountIdRequestedToBeRefreshed: number,
                                         onCompleteCallback: () => void )
{
    const state = store.getState();
    const userId = getCurrentUserId( state );
    const dispatch = store.dispatch;
    const currentBusinessId = getCurrentBusinessId( state );

    if ( accountJustAuthenticated.id !== accountIdRequestedToBeRefreshed )
    {
        dispatch( modalServices.openErrorDialogRefreshWrongAccount( accountJustAuthenticated.account_type ) );
    }
    else if ( currentBusinessId === accountJustAuthenticated.user_business_id )
    {
        dispatch( userServices.enableSocialNetworkAccounts( userId, [accountJustAuthenticated.id], currentBusinessId ) );
        if ( onCompleteCallback )
        {
            onCompleteCallback();
        }
    }
    else
    {
        dispatch( modalServices.openErrorDialogWithStandardFormat(
            "An unknown error occurred while refreshing your account", null, null, true ) );
    }
}

function determineAccountJustAuthedByUpdatedTimestamp( accountType: SocialNetworkAccountType )
{
    const state = store.getState();
    const accounts = getAvailableSocialNetworkAccounts( state );
    const accountsToEnable = filter( accounts, ( account ) => account.account_type === accountType );
    return last( sortBy( accountsToEnable, "updated_at" ) );
}
