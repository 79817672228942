import { Action } from "redux-actions";
import { conditionalMap, LightboxDialogIdentifierForKey, LoadingDialogIdentifierForKey, ReducerCreator } from "../helpers";
import { modalActions } from "../actions";
import {
    AlertDialogContents,
    DialogContents,
    ErrorDialogContents,
    FullPageDialogContents,
    LightboxContents,
    LoadingDialogContents,
    LowResErrorDialogContents,
    ModalState,
    StoreState,
} from "../_types";
import { filter, last, map, some } from "lodash";

const defaultLightboxContent: LightboxContents = {
    width: 280,
    identifierForKey: undefined,
    title: undefined,
    onClose: undefined,
    onSuccess: undefined,
    content: undefined,
    visible: false,
    confirmLabel: "Ok",
    cancelLabel: "Cancel",
    closeDialogBeforeCallbacks: false,
    hideAlternateAction: true,
};

const defaultLoadingDialogContent: LoadingDialogContents = {
    title: undefined,
    onClose: undefined,
    content: undefined,
    visible: false,
};

const defaultErrorDialogContents: ErrorDialogContents = {
    errorMessage: "",
    showContactSupportUI: false,
    showReportToSentryUI: false,
    visible: false,
    kbArticleUrl: "",
};

const defaultAlertDialogContents: AlertDialogContents = {
    title: "",
    message: "",
    showCancelX: true,
    width: null,
    modal: false,
    visible: false,
};

const defaultState: ModalState = {
    lightboxContents: [],
    loadingDialogContent: defaultLoadingDialogContent,
    errorDialogContents: defaultErrorDialogContents,
    alertDialogContents: defaultAlertDialogContents,
    lowResErrorDialogContents: { visible: false },
    fullPageContents: [],
};

const reducerCreator = new ReducerCreator( defaultState );
reducerCreator.addAction( modalActions.errorDialogOpen, handleErrorDialogOpen );
reducerCreator.addAction( modalActions.errorDialogClose, handleErrorDialogClose );
reducerCreator.addAction( modalActions.lowResErrorDialogOpen, handleLowResErrorDialogOpen );
reducerCreator.addAction( modalActions.lowResErrorDialogClose, handleLowResErrorDialogClose );
reducerCreator.addAction( modalActions.alertDialogOpen, handleAlertDialogOpen );
reducerCreator.addAction( modalActions.alertDialogClose, handleAlertDialogClose );
reducerCreator.addAction( modalActions.lightboxDialogUpdate, handleLightboxDialogUpdate );
reducerCreator.addAction( modalActions.lightboxDialogOpened, handleLightboxDialogOpened );
reducerCreator.addAction( modalActions.lightboxDialogClosed, handleLightboxDialogClosed );
reducerCreator.addAction( modalActions.lightboxDialogClosedByIdentifier, handleLightboxDialogClosedByIdentifier );
reducerCreator.addAction( modalActions.lightboxDialogClosedAll, handleLightboxDialogClosedAll );
reducerCreator.addAction( modalActions.fullpageDialogOpen, handleFullpageDialogOpen );
reducerCreator.addAction( modalActions.fullpageDialogClose, handleFullpageDialogClose );
reducerCreator.addAction( modalActions.fullpageDialogCloseAll, handleFullpageDialogCloseAll );
reducerCreator.addAction( modalActions.loadingDialogOpen, handleLoadingDialogOpen );
reducerCreator.addAction( modalActions.loadingDialogClose, handleLoadingDialogClose );
reducerCreator.addAction( modalActions.allForceUpdate, handleAllForceUpdate );

export default reducerCreator.createReducer();

function handleAllForceUpdate( state: ModalState ): ModalState
{
    const { forceUpdateCounter } = state;
    const newForceUpdateCounter = forceUpdateCounter ? forceUpdateCounter + 1 : 1;
    return {
        ...state,
        forceUpdateCounter: newForceUpdateCounter,
    };
}

function handleErrorDialogOpen( state: ModalState, action: Action<ErrorDialogContents> ): ModalState
{
    return {
        ...state,
        errorDialogContents: {
            ...action.payload,
            visible: true,
        },
    };
}

function handleErrorDialogClose( state: ModalState ): ModalState
{
    return {
        ...state,
        errorDialogContents: defaultErrorDialogContents,
    };
}

function handleLowResErrorDialogOpen( state: ModalState, action: Action<LowResErrorDialogContents> ): ModalState
{
    return {
        ...state,
        lowResErrorDialogContents: {
            ...action.payload,
        },
    };
}

function handleLowResErrorDialogClose( state: ModalState ): ModalState
{
    return {
        ...state,
        lowResErrorDialogContents: {
            visible: false,
        },
    };
}

function handleAlertDialogOpen( state: ModalState, action: Action<AlertDialogContents> ): ModalState
{
    return {
        ...state,
        alertDialogContents: {
            ...defaultAlertDialogContents,
            ...action.payload,
            visible: true,
        },
    };
}

function handleAlertDialogClose( state: ModalState ): ModalState
{
    return {
        ...state,
        alertDialogContents: {
            ...defaultAlertDialogContents,
        },
    };
}

function handleLightboxDialogUpdate( state: ModalState, action: Action<LightboxContents> ): ModalState
{
    const lightboxContents = map( state.lightboxContents, ( dialog: LightboxContents ) =>
    {
        if ( action.payload.identifierForKey === dialog.identifierForKey )
        {
            return {
                ...dialog,
                ...action.payload,
            };
        }
        return { ...dialog };
    } );

    return {
        ...state,
        lightboxContents,
    };
}

function handleLightboxDialogOpened( state: ModalState, action: Action<LightboxContents> ): ModalState
{
    const openDialogs = state.lightboxContents.filter( ( dialog ) => dialog.visible );
    return {
        ...state,
        lightboxContents: openDialogs.concat( {
            ...defaultLightboxContent,
            ...action.payload,
            visible: true,
        } ),
    };
}

function handleLightboxDialogClosed( state: ModalState, action: Action<number> ): ModalState
{
    const dialog = state.lightboxContents[action.payload];
    if ( !dialog || !dialog.visible )
    {
        return state;
    }
    const lightboxContents = [...state.lightboxContents];
    lightboxContents[action.payload] = changeVisibleToFalse( dialog );
    return {
        ...state,
        lightboxContents,
    };
}

function handleLightboxDialogClosedByIdentifier( state: ModalState, action: Action<LightboxDialogIdentifierForKey> ): ModalState
{
    const filterForMatchingLightboxes = ( dialog: LightboxContents ) => dialog && dialog.identifierForKey === action.payload;
    const lightboxContents = conditionalMap( state.lightboxContents, changeVisibleToFalse, filterForMatchingLightboxes );
    return {
        ...state,
        lightboxContents,
    };
}

function handleLightboxDialogClosedAll( state: ModalState ): ModalState
{
    const lightboxContents = map( state.lightboxContents, changeVisibleToFalse );
    return {
        ...state,
        lightboxContents,
    };
}

function changeVisibleToFalse( dialog: LightboxContents )
{
    if ( dialog.visible )
    {
        return {
            ...dialog,
            visible: false,
        };
    }

    return dialog;
}

function handleLoadingDialogOpen( state: ModalState, action: Action<DialogContents> ): ModalState
{
    return {
        ...state,
        loadingDialogContent: {
            ...defaultLoadingDialogContent,
            ...action.payload,
            visible: true,
        },
    };
}

function handleLoadingDialogClose( state: ModalState ): ModalState
{
    return {
        ...state,
        loadingDialogContent: {
            ...state.loadingDialogContent,
            visible: false,
        },
    };
}

function handleFullpageDialogOpen( state: ModalState, action: Action<FullPageDialogContents> ): ModalState
{
    const openDialogs = state.fullPageContents.filter( ( dialog ) => dialog.visible );
    return {
        ...state,
        fullPageContents: openDialogs.concat( {
            ...action.payload,
            visible: true,
        } ),
    };
}

function handleFullpageDialogClose( state: ModalState, action: Action<number> ): ModalState
{
    const dialog = state.fullPageContents[action.payload];
    if ( !dialog || !dialog.visible )
    {
        return state;
    }
    const fullPageContents = [...state.fullPageContents];
    fullPageContents[action.payload] = {
        ...dialog,
        visible: false,
    };
    return {
        ...state,
        fullPageContents,
    };
}

function handleFullpageDialogCloseAll( state: ModalState ): ModalState
{
    const fullPageContents = map( state.fullPageContents, ( dialog: FullPageDialogContents ) =>
    {
        return {
            ...dialog,
            visible: false,
        };
    } );

    return {
        ...state,
        fullPageContents,
    };
}

export function getDialogData( state: StoreState )
{
    return state.modals;
}

export function getTopMostDialogOnSuccess( state: StoreState )
{
    const topMostDialog = last( state.modals.lightboxContents );
    return topMostDialog && topMostDialog.onSuccess;
}

export function getTopMostLightbox( state: StoreState )
{
    return last( state.modals.lightboxContents );
}

export function isShowingFullpageDialog( state: StoreState )
{
    const matches = filter( state.modals.fullPageContents, ( fullPageDialogContents: FullPageDialogContents ) =>
    {
        return fullPageDialogContents && fullPageDialogContents.visible;
    } );

    return matches.length > 0;
}

export function moreMusicBeingShown( state: StoreState ): boolean
{
    const matches = filter( state.modals.lightboxContents, ( dialog: LightboxContents ) =>
    {
        return dialog && dialog.visible && dialog.className === "moreMusicDialog";
    } );

    return matches.length === 1;
}

export const getLightBoxCount = ( state: StoreState ): number => state.modals.lightboxContents && state.modals.lightboxContents.length;
export const isShowingLightbox = ( state: StoreState ): boolean =>
{
    return some( state.modals.lightboxContents, ( lightbox: LightboxContents ) => lightbox.visible );
};
export const isShowingLoadingDialog = ( state: StoreState, identifierForKey: LoadingDialogIdentifierForKey ): boolean =>
{
    return state.modals.loadingDialogContent
           && state.modals.loadingDialogContent.identifierForKey === identifierForKey
           && state.modals.loadingDialogContent.visible;
};
