import * as React from "react";
import { MAX_DESIGN_ERRORS_NEED_HELP_URL } from "../../helpers";

export default class MaxDesignErrorReached extends React.PureComponent
{
    public render()
    {
        return (
            <div>
                <p>There was a problem loading your template. Please refresh your browser or try a different template. If the issue persists, refer to
                   the FAQ article <a className="standardLink" href={MAX_DESIGN_ERRORS_NEED_HELP_URL} target="_blank">here</a> for troubleshooting steps.</p>
            </div>
        );
    }
}
