import { createAction } from "redux-actions";
import { ActionCreator } from "../helpers";
import { EasyCaptionData, OutputType, RecordingCompleteData, SocialNetworkAccount, SocialNetworkAccountType } from "../_types";

const actionCreator = new ActionCreator( "SHARE_MODEL" );

const shareModelActionsTypes = {
    videoRecording: {
        completed: actionCreator.generateType( "VIDEO_RECORDING", "COMPLETED" ),
    },
    content: {
        updated: actionCreator.generateType( "CONTENT", "UPDATED" ),
    },
    easyCaption: {
        updated: actionCreator.generateType( "EASY_CAPTION", "UPDATED" ),
    },
    snapshot: actionCreator.generateTypes( "SNAPSHOT" ),
    shareOutput: {
        enableChanged: actionCreator.generateType( "SHARE_OUTPUT", "ENABLE_CHANGED" ),
    },
    scheduledSendDate: {
        updated: actionCreator.generateType( "SCHEDULED_SEND_DATE", "UPDATED" ),
    },
    outputType: {
        updated: actionCreator.generateType( "OUTPUT_TYPE", "UPDATED" ),
    },
    share: {
        completed: actionCreator.generateType( "SHARE", "COMPLETED" ),
        cancelled: actionCreator.generateType( "SHARE", "CANCELLED" ),
    },
    socialNetworkAccount: {
        disconnected: actionCreator.generateType( "SOCIAL_NETWORK_ACCOUNT", "DISCONNECTED" ),
    },
    facebookAndInstagramSocialNetworks: {
        disabled: actionCreator.generateType( "FACEBOOK_AND_INSTAGRAM_SOCIAL_NETWORKS", "DISABLED" ),
    },
    youtubeTitle: {
        updated: actionCreator.generateType( "YOUTUBE_TITLE", "UPDATED" ),
    },
    youtubePrivacy: {
        updated: actionCreator.generateType( "YOUTUBE_PRIVACY", "UPDATED" ),
    },
};

export interface ShareOutputEnabledPayload
{
    facebookEnabled?: boolean;
    saveToComputerEnabled?: boolean;
    accountId: number;
    accountEnabled: boolean;
}

export interface FacebookAndInstagramSocialNetworkAccountsDisabledPayload
{
    facebookAndInstagramSocialNetworkAccounts: SocialNetworkAccount[];
}

export interface SocialNetworkAccountDisconnectedPayload
{
    accountType: SocialNetworkAccountType;
    accountId?: number;
}

export const shareModelActions = {
    videoRecordingCompleted: createAction<RecordingCompleteData>( shareModelActionsTypes.videoRecording.completed ),
    contentUpdated: createAction<string>( shareModelActionsTypes.content.updated ),
    easyCaptionUpdated: createAction<EasyCaptionData>( shareModelActionsTypes.easyCaption.updated ),
    snapshotRequest: createAction( shareModelActionsTypes.snapshot.request ),
    snapshotSuccess: createAction<string>( shareModelActionsTypes.snapshot.success ),
    snapshotFailure: createAction<string>( shareModelActionsTypes.snapshot.failure ),
    shareOutputEnableChanged: createAction<ShareOutputEnabledPayload>( shareModelActionsTypes.shareOutput.enableChanged ),
    scheduledSendDateUpdated: createAction<string>( shareModelActionsTypes.scheduledSendDate.updated ),
    outputTypeUpdated: createAction<OutputType>( shareModelActionsTypes.outputType.updated ),
    shareCompleted: createAction( shareModelActionsTypes.share.completed ),
    shareCancelled: createAction( shareModelActionsTypes.share.cancelled ),
    socialNetworkAccountDisconnected: createAction<SocialNetworkAccountDisconnectedPayload>(
        shareModelActionsTypes.socialNetworkAccount.disconnected ),
    facebookInstagramSocialNetworksDisabled: createAction<FacebookAndInstagramSocialNetworkAccountsDisabledPayload>(
        shareModelActionsTypes.facebookAndInstagramSocialNetworks.disabled ),
    youtubeTitleUpdated: createAction<string>( shareModelActionsTypes.youtubeTitle.updated ),
    youtubePrivacyUpdated: createAction<string>( shareModelActionsTypes.youtubePrivacy.updated ),
};
