import * as React from "react";
import { Route, Switch } from "react-router";
import { find, map, size } from "lodash";
import BusinessInfoPanelContainer from "./containers/BusinessInfoPanel.container";
import { PageTabItem, PageTabs } from "./PageTabs";
import classNames from "classnames";
import { history } from "../../helpers";

export interface PageWithTabbedSubNavProps
{
    tabItems: PageTabItem[];
    path: string;
    additionalContainerClass?: string;
    pageRootPath?: string;
    shouldShowCollabraUI: boolean;
}

export class PageWithTabbedSubNav extends React.PureComponent<PageWithTabbedSubNavProps>
{
    public componentDidUpdate( prevProps: Readonly<PageWithTabbedSubNavProps> )
    {
        if ( this.shouldSelectFirstTabByDefault() )
        {
            history.push( this.props.tabItems[0].path );
        }
    }

    public render()
    {
        return (
            <div className={classNames( "pageNewNav", this.props.additionalContainerClass )}>
                {!this.props.shouldShowCollabraUI && <BusinessInfoPanelContainer/>}
                {this.createTabs()}
                <Switch>
                    {
                        map( this.props.tabItems, ( navItem ) =>
                        {
                            return <Route {...navItem} key={navItem.path}/>;
                        } )
                    }
                </Switch>
            </div>
        );
    }

    private createTabs = () =>
    {
        return (
            <PageTabs {...this.props} />
        );
    }

    private shouldSelectFirstTabByDefault()
    {
        const hasTabs = size(this.props.tabItems) > 0;
        if (hasTabs)
        {
            const noTabMatchesCurrentPath = !find( this.props.tabItems, { path: window.location.pathname } );
            const isUserStayingOnCurrentPage = this.props.pageRootPath && window.location.pathname.includes( this.props.pageRootPath );

            return noTabMatchesCurrentPath && isUserStayingOnCurrentPage;
        }
        return false;
    }
}
