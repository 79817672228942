import * as React from "react";
import { ReactChild } from "react";
import VideoElementContainer from "./containers/VideoElement.container";
import { ASPECT_RATIO_VALUE } from "../helpers";
import { Post } from "../_types";
import { LEGACY_POST_TYPE_EXTERNAL } from "../_types/api";

export interface PostPreviewProps
{
    post: Post;
    autoplay?: boolean;
    contentBelow?: boolean;
    children?: ReactChild;
    controls?: boolean;
    playOnMouseOver?: boolean;
}

export interface PostPreviewDispatchProps
{
    primaryAction?: ( post: Post, e: React.MouseEvent<HTMLElement> ) => void;
}

export class PostPreview extends React.PureComponent<PostPreviewProps & PostPreviewDispatchProps>
{
    public render()
    {
        const { post, controls, contentBelow, autoplay, children } = this.props;
        let { playOnMouseOver } = this.props;
        if ( typeof playOnMouseOver === "undefined" )
        {
            playOnMouseOver = !autoplay;
        }
        const onClick = this.props.primaryAction ? this.handleOnClick : null;
        return (
            <div className="postPreview">
                <VideoElementContainer
                    videoUrl={post.video_url}
                    imageUrl={post.image_url}
                    posterUrl={post.poster_url}
                    aspectRatio={ASPECT_RATIO_VALUE[post.aspect_ratio]}
                    muted={true}
                    isExternalPost={post.type === LEGACY_POST_TYPE_EXTERNAL}
                    controls={controls}
                    contentBelow={contentBelow}
                    autoplay={autoplay}
                    playOnMouseOver={playOnMouseOver}
                    onClick={onClick}
                />
                {children}
            </div>
        );
    }

    private handleOnClick = ( e: React.MouseEvent<HTMLElement> ) =>
    {
        if ( this.props.primaryAction )
        {
            this.props.primaryAction( this.props.post, e );
        }
    }
}
